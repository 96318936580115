import React from 'react';
import { BaseInputProps, SmartTagsTextInputWithLoad } from 'ui-builder';
import { ProjectType, TaskType, useSmartTagsService } from 'nekst-api';

interface EmailPlanTaskTitleInputProps extends BaseInputProps {
  projectType: ProjectType | undefined,
}

export function EmailPlanTaskTitleInput(props: EmailPlanTaskTitleInputProps) {
  const smartTagsService = useSmartTagsService();

  return (
    <SmartTagsTextInputWithLoad
      getTagsMethod={async () => {
        if (props.projectType) {
          return smartTagsService.getSmartTags(props.projectType, TaskType.EMAIL);
        } else {
          return [];
        }
      }}
      dependencies={[props.projectType]}
      source="name"
    />
  );
}
