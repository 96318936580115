import React, { ReactElement } from 'react';

export type ApiTokenData = {
  isTokenSet: () => boolean,
  getApiHeaders: () => Record<string, string>,
  setToken: (token: string | undefined) => Promise<void>,
}

const AuthenticationTokenReactContext = React.createContext<ApiTokenData>({
  isTokenSet: () => false,
  getApiHeaders: () => ({}),
  setToken: async () => {},
});

export interface TokenStorageInterface {
  saveToken: (token: string | undefined) => Promise<void>,
  getToken: () => Promise<string | null>,
  isTokenSet: () => Promise<boolean>,
}

export function useTokenContext(): ApiTokenData {
  return React.useContext(AuthenticationTokenReactContext);
}

export function AuthenticationTokenContext(
  props: {
    data: ApiTokenData,
    children: ReactElement | ReactElement[],
  }
) {
  return (
    <AuthenticationTokenReactContext.Provider value={props.data}>
      {props.children}
    </AuthenticationTokenReactContext.Provider>
  );
}
