import React from 'react';
import { useShowContext } from 'ui-builder';
import { AbstractTask } from 'nekst-api';

import styles from './IsPrivateField.module.scss';

export default function IsPrivateField() {
  const context = useShowContext<AbstractTask>();

  if (context.data?.isPrivate) {
    return (
      <i className={styles.icon}>
        &nbsp;
      </i>
    );
  } else {
    return null;
  }
}
