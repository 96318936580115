import { useEffect } from 'react';
import { useAuthenticationContext } from 'nekst-api';
import * as Sentry from '@sentry/react';

export default function UserTracker() {
  const authenticationContext = useAuthenticationContext();

  useEffect(() => {
    if (authenticationContext?.user?.id) {
      Sentry.setUser({
        id: authenticationContext.user.id,
        email: authenticationContext.user.profile.primaryEmail,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [authenticationContext?.user?.id])

  return null;
}
