import { InputLabelLayoutProps, useFormContext } from 'ui-builder';
import React from 'react';

export default function InputLabelLayout(props: InputLabelLayoutProps) {

  const formContext = useFormContext();

  const showRequiredIcon = props.showRequiredIcon !== undefined ? props.showRequiredIcon : true;

  if (!props.hideLabel) {

    const className = [
      props.className,
      props.bold ? 'bold' : '',
    ];

    return (
      <label className={className.join(' ')} data-key={props.testID}>
          <span className="label-text">
            {props.label}
            &nbsp;
            {props.afterLabelElement}
            {showRequiredIcon
              && formContext.isFieldRequired
              && formContext.isFieldRequired(props.name)
              && (<span style={{ color: 'red' }}>*</span>)}
          </span>
      </label>
    );
  } else {
    return null;
  }

}
