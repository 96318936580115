import React from 'react';
import { Person, PersonPermission } from 'nekst-api';
import { Button, ButtonColor, ButtonSize } from 'ui-builder';
import { useTeamMemberNavigator } from '../teamMemberNavigator';
import { useAuthorizationChecker } from 'authorization-scope';

export default function OpenHistoryPageButton(
  props: {
    person: Person,
  },
) {
  const teamMemberNavigator = useTeamMemberNavigator();

  const { isGranted } = useAuthorizationChecker();

  if (isGranted(PersonPermission.READ_HISTORY)) {
    return (
      <Button
        text="History"
        isFilled
        color={ButtonColor.BLUE}
        size={ButtonSize.MEDIUM}
        onClick={async () => teamMemberNavigator.openHistoryPage(props.person.id)}
      />
    );
  } else {
    return null;
  }
}
