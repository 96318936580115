import React, { useEffect } from 'react';
import { ProjectType, TaskType, useProjectsService, useSmartTagsService } from 'nekst-api';
import { BaseInputProps, SmartTagsWysiwyg, Tag, useFormContextRequired } from 'ui-builder';

interface BaseSmartTagsWysiwygProps extends BaseInputProps {
  projectId?: number | ((data: Record<string, any>) => number | undefined),
  projectType?: ProjectType,
  taskType: TaskType
  hideControls?: boolean,
}

function BaseSmartTagsWysiwyg(props: BaseSmartTagsWysiwygProps) {
  const [availableTags, setAvailableTags] = React.useState<Tag[]>([]);

  const formContext = useFormContextRequired();
  const smartTagsService = useSmartTagsService();
  const projectsService = useProjectsService();

  const data = formContext.data || {};

  let projectIdValue: number | undefined;

  if (typeof props.projectId === 'function') {
    projectIdValue = props.projectId(data);
  } else {
    projectIdValue = props.projectId;
  }

  useEffect(() => {
    async function loadTags() {
      if (props.projectType) {
        const tags = await smartTagsService.getSmartTags(
          props.projectType,
          TaskType.EMAIL,
          projectIdValue
        );
        setAvailableTags(tags);
      } else if (projectIdValue) {
        const projectType = await projectsService.getTypeByProjectId(projectIdValue);

        const tags = await smartTagsService.getSmartTags(
          projectType,
          props.taskType,
          projectIdValue
        );

        setAvailableTags(tags);
      } else {
        setAvailableTags([]);
      }
    }

    loadTags();
  }, [projectIdValue, props.projectType]);

  return (
    <SmartTagsWysiwyg
      {...props}
      availableTags={availableTags}
      hideControls={props.hideControls}
    />
  );
}

interface SmartTagsWysiwygProps extends BaseInputProps {
  projectId?: number | ((data: Record<string, any>) => number | undefined),
  projectType?: ProjectType,
}

export function EmailTaskSmartTagsWysiwyg(
  props: SmartTagsWysiwygProps,
) {
  return (
    <BaseSmartTagsWysiwyg
      {...props}
      taskType={TaskType.EMAIL}
      projectType={props.projectType}
      projectId={props.projectId}
    />
  );
}

export function SmsTaskSmartTagsWysiwyg(
  props: SmartTagsWysiwygProps,
) {
  return (
    <BaseSmartTagsWysiwyg
      {...props}
      taskType={TaskType.SMS}
      projectType={props.projectType}
      projectId={props.projectId}
      hideControls
    />
  );
}
