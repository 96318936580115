import useProjectsApi from '../projectsApi';

export type LinkResponse = {
  link: string
}

export default function useICalLinkService() {
  const projectsApi = useProjectsApi();

  const getProjectICalFileLink = async (projectId: number) => {
    return await projectsApi.get(`/projects/${projectId}/icallink`) as LinkResponse;
  };

  const getAllTasksICalFileLink = async () => {
    return await projectsApi.get('/ical/icallink') as LinkResponse;
  };

  return {
    getProjectICalFileLink,
    getAllTasksICalFileLink,
  };
}
