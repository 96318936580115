import React from 'react';
import useLaunchProjectNavigator from './launchProjectNavigator';
import BaseSelectGroupsPage from './Shared/BaseSelectGroupsPage';
import LaunchProjectSteps, { LaunchProjectStepId } from './LaunchProjectSteps';

export default function SelectGroupsPage() {


  const launchProjectNavigator = useLaunchProjectNavigator();

  return (
    <BaseSelectGroupsPage
      onPrevClicked={() => {
        launchProjectNavigator.openUpdateProjectPage();
      }}
      onNextClicked={() => {
        launchProjectNavigator.openKeyDateTasksPage();
      }}
      roadmap={<LaunchProjectSteps currentStep={LaunchProjectStepId.GROUPS} />}
    />
  );
}
