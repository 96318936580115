import React from 'react';
import ShowMoreTextWrapper from '../../../shared/widgets/ShowMoreTextWrapper';
import { CommentData } from 'nekst-api';

interface Props {
  comment: CommentData;
}

export default function CommentText(props: Props) {
  return (
    <span><ShowMoreTextWrapper text={`"${props.comment.text}"`} /></span>
  );
}
