import { useBackendApi } from "./backendApi";

export function useFrontendLoggerService() {

  const nekstApi = useBackendApi();

  const logError = async (error: Error) => {
    try {
      await nekstApi.post('/monitoring/frontenderror', {
        message: error.message,
        stack: error.stack,
      });
    } catch {
      // eslint-disable-next-line no-console
      console.error('Failed to log frontend error', error.message, error.stack);
    }
  };

  return {
    logError,
  };
}
