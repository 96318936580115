
import { useEventsPublisher } from 'event-bus';
import { FileDto, useBackendApi } from 'nekst-api';

export interface DocumentLabel {
  id: number,
  name: string,
  fileId?: number
  _file: FileDto,
}

export interface CreateDocumentLabelRequest {
  name: string;
}

export interface UpdateDocumentLabelRequest {
  name: string;
  fileId?: number;
}

export const LABELS_UPDATED = 'LABELS_UPDATED';

export default function useDocumentLabelsService() {
  const nekstApi = useBackendApi();

  const eventPublisher = useEventsPublisher();

  const getLabels = async () => {
    return await nekstApi.get('/documentlabels') as DocumentLabel[];
  };

  const publishEvent = () => {
    eventPublisher.publish(LABELS_UPDATED, {});
  };

  const createLabel = async (request: CreateDocumentLabelRequest) => {
    const result = await nekstApi.post('/documentlabels', request) as DocumentLabel;

    publishEvent();
    return result;
  };

  const updateLabel = async (id: number, request: UpdateDocumentLabelRequest) => {
    const result = await nekstApi.put(`/documentlabels/${id}`, request) as DocumentLabel;

    publishEvent();
    return result;
  };

  const deleteLabel = async (id: number) => {
    await nekstApi.delete(`/documentlabels/${id}`);

    publishEvent();
  };

  const renameFile = async (labelId: number, fileName: string) => {
    const result = await nekstApi.put(`/documentlabels/${labelId}/file/name`, {
      fileName,
    }) as DocumentLabel;

    publishEvent();

    return result;
  };

  return {
    createLabel,
    getLabels,
    updateLabel,
    deleteLabel,
    renameFile,
  };
}
