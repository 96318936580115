import React from 'react';
import { NotificationType } from 'nekst-api';
import { isReactNative, SimpleBlock } from 'ui-builder';

import webStyles from './NotificationListItem.module.scss';

import { ImageSourcePropType } from 'react-native';
import { Image } from 'react-native-ui-lib';

import commentIcon from './icons/commentIcon.svg';
import keyDateIcon from './icons/keyDateIcon.svg';
import keyDateBigIcon from './icons/keyDateBigIcon.svg';
import projectAssignmentIcon from './icons/projectAssignmentIcon.svg';
import reminderIcon from './icons/reminderIcon.svg';
import typeChangeIcon from './icons/typeChangeIcon.svg';
import mentionIcon from './icons/mentionIcon.svg';

import { mobileStyles } from './notificationIconStyles.mobile';

function NotificationIconWeb(
  props: {
    type: NotificationType,
    withAvatar: boolean,
  }
) {

  let iconClass;

  switch (props.type) {
    case NotificationType.TASK_COMMENT_CREATED:
      iconClass = webStyles.comment;
      break;
    case NotificationType.PROJECT_NOTE_CREATED:
      iconClass = webStyles.note;
      break;
    case NotificationType.PROJECT_ASSIGNMENT_CREATED:
      iconClass = webStyles.projectAssignment;
      break;
    case NotificationType.KEY_DATE_REMINDER:
      iconClass = webStyles.keyDate;
      break;
    case NotificationType.TASK_DUE_DATE_REMINDER:
      iconClass = webStyles.reminder;
      break;
    case NotificationType.KEY_DATE_COMPLETED:
      iconClass = webStyles.completed;
      break;
    case NotificationType.PROJECT_STATUS_UPDATED:
      iconClass = webStyles.statusUpdate;
      break;
    case NotificationType.PROJECT_TYPE_UPDATED:
      iconClass = webStyles.typeUpdate;
      break;
    case NotificationType.MENTIONED_IN_COMMENT:
    case NotificationType.MENTIONED_IN_NOTE:
      iconClass = webStyles.mention;
      break;
  }

  return (
    <SimpleBlock
      className={[webStyles.icon, iconClass].join(' ')}
    >

    </SimpleBlock>
  );
}


function NotificationIconMobile(
  props: {
    type: NotificationType,
    withAvatar: boolean,
  }
) {
  let iconSource: any | undefined = undefined;

  switch (props.type) {
    case NotificationType.TASK_COMMENT_CREATED:
      iconSource = commentIcon;
      break;
    case NotificationType.PROJECT_NOTE_CREATED:
      iconSource = commentIcon;
      break;
    case NotificationType.PROJECT_ASSIGNMENT_CREATED:
      iconSource = projectAssignmentIcon;
      break;
    case NotificationType.KEY_DATE_REMINDER:
      iconSource = keyDateBigIcon;
      break;
    case NotificationType.TASK_DUE_DATE_REMINDER:
      iconSource = reminderIcon;
      break;
    case NotificationType.KEY_DATE_COMPLETED:
      iconSource = keyDateIcon;
      break;
    case NotificationType.PROJECT_STATUS_UPDATED:
      iconSource = typeChangeIcon;
      break;
    case NotificationType.PROJECT_TYPE_UPDATED:
      iconSource = typeChangeIcon;
      break;
    case NotificationType.MENTIONED_IN_COMMENT:
    case NotificationType.MENTIONED_IN_NOTE:
      iconSource = mentionIcon;
      break;
  }

  if (iconSource) {

    return (
      <SimpleBlock style={{
        alignItems: 'center',
        justifyContent: 'center',
        ...props.withAvatar ? mobileStyles.iconWithAvatar : {},
      }}>
        <Image
          source={iconSource as ImageSourcePropType}
          // @ts-ignore
          style={mobileStyles.iconColor}
        />
      </SimpleBlock>

    )
  } else {
    return null;
  }
}

export default function NotificationIcon(
  props: {
    type: NotificationType,
    withAvatar: boolean,
  }
) {
  if (isReactNative()) {
    return <NotificationIconMobile {...props} />;
  } else {
    return <NotificationIconWeb {...props} />;
  }
}
