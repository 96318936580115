import React, {
  ReactElement,
  useContext, useEffect,
  useMemo,
  useState,
} from 'react';
import {
  EventData, Plan,
  PLAN_UPDATED,
  ProjectType,
  useEventsSubscriber,
  usePlansService
} from 'nekst-api';
import PlanTasksList from '../tasks/List';
import { LoadingAnimation, MassUpdate } from 'ui-builder';
import { PlanAuthorizationScope, PlanPermission } from 'authorization-scope';

export interface ViewPlanProps {
  id: number;
}

export type PlanContextValue = {
  projectType?: ProjectType
  detailsFieldsSetId?: number
}

const ProjectTypeAndSetIdReactContext = React.createContext<PlanContextValue | undefined>(undefined);

export function ProjectTypeContext(props: {
  projectType: ProjectType,
  detailsFieldsSetId?: number,
  children: ListOrSingle<ReactElement>,
}) {
  const contextValue = useMemo(() => {
    return {
      projectType: props.projectType,
      detailsFieldsSetId: props.detailsFieldsSetId,
    };
  }, [props.projectType, props.detailsFieldsSetId]);

  return (
    <ProjectTypeAndSetIdReactContext.Provider value={contextValue}>
      {props.children}
    </ProjectTypeAndSetIdReactContext.Provider>
  );
}

export function useProjectTypeAndSetIdContext() {
  return useContext(ProjectTypeAndSetIdReactContext);
}

export function useProjectTypeAndSetIdContextRequired() {
  const context = useContext(ProjectTypeAndSetIdReactContext);

  if (!context) {
    throw new Error('usePlanContextRequired must be used within a PlanContext');
  }

  return context;
}

export default function ViewPlan(props: ViewPlanProps) {
  const plansService = usePlansService();

  const [plan, setPlan] = useState<Plan>();

  async function updatePlanData() {
    const planData = await plansService.getById(props.id);
    setPlan(planData);
  }

  useEventsSubscriber(
    'ViewPlan',
    {
      [PLAN_UPDATED]: (eventData: EventData) => {
        if (eventData.data.id === props.id) {
          setPlan(eventData.data);
        }
      },
    },
  );

  useEffect(() => {
    updatePlanData();
  }, [props.id]);


  if (plan) {
    return (
      <MassUpdate>
        <ProjectTypeContext projectType={plan.projectType} detailsFieldsSetId={plan.detailsFieldsSetId}>
          <PlanAuthorizationScope id={props.id} permissionToCheck={PlanPermission.READ}>
            <PlanTasksList
              planId={props.id}
              planName={plan.name}
              planType={plan.projectType}
              detailsFieldsSetName={plan._detailsFieldsSet?.name}
            />
          </PlanAuthorizationScope>
        </ProjectTypeContext>

      </MassUpdate>
    );
  } else {
    return (
      <LoadingAnimation />
    );
  }

}
