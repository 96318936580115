import { useFormContextRequired, useShowContextRequired } from 'ui-builder';
import { PersonPhoneNumber } from 'nekst-api';
import { get } from 'lodash';

export function usePhoneNumberSelection(source: string) {
  const formContext = useFormContextRequired();

  const showContext = useShowContextRequired<PersonPhoneNumber>();

  const value = get(showContext.data!, source);

  let phoneNumbers: PersonPhoneNumber[];
  if (formContext.data && formContext.data.profile) {
    phoneNumbers = formContext.data!.profile.phoneNumbers as PersonPhoneNumber[];
  } else {
    phoneNumbers = [];
  }

  // @ts-ignore
  const index = showContext.data!.__index;

  const onChanged = () => {
    const newValue = phoneNumbers.map((number, itemIndex) => {
      return {
        ...number,
        [source]: itemIndex === index,
      };
    });

    formContext.onChangeCallback!({
      'profile.phoneNumbers': newValue,
    });
  };

  return {
    onChanged,
    value,
    id: showContext.data!.id
  };
}
