import React, { useState } from 'react';
import { TeamMember, useTeamMemberHistoryService, useTeamMembersService } from 'nekst-api';
import { useParams } from 'react-router-dom';
import SinglePersonPage from '../../../people/shared/SinglePersonPage';
import OpenShowProfilePageButton from '../../../people/Update/OpenShowProfilePageButton';
import HistoryEventsList from '../../../people/shared/HistoryEventsList';
import useDataLoader from 'data-loader';
import { LoadingAnimation } from 'ui-builder';
import TeamMemberBreadcrumbs from '../TeamMemberBreadcrumbs';
import { Breadcrumb } from '../../../shared/web/layout/Breadcrumbs';

export default function TeamMemberHistoryPage(
  props: {
    breadcrumbsComponent?: React.FC<{ teamMember: TeamMember }>,
  }
) {
  const teamMembersService = useTeamMembersService();
  const teamMemberHistoryService = useTeamMemberHistoryService();

  const [teamMember, setTeamMember] = useState<TeamMember>();

  const params = useParams<{ id: string }>();
  const id = params.id as unknown as number;

  useDataLoader(
    async () => {
      return await teamMembersService.getById(id);
    },
    setTeamMember,
    [id],
  );

  if (teamMember) {
    return (
      <>
        {props.breadcrumbsComponent
          ? <props.breadcrumbsComponent teamMember={teamMember} />
          : (
          <TeamMemberBreadcrumbs teamMember={teamMember}>
            <Breadcrumb text="History" />
          </TeamMemberBreadcrumbs>
        )}
        <SinglePersonPage
          headerRight={<OpenShowProfilePageButton person={teamMember} />}
          person={teamMember}
        >
          <HistoryEventsList
            getEventsFunc={
              (filter, nextPageToken) => teamMemberHistoryService.getHistoryEvents(
                teamMember.id,
                filter,
                nextPageToken as number,
              )
            }
          />
        </SinglePersonPage>
      </>
    );
  } else {
    return (<LoadingAnimation />);
  }
}
