import React, { ReactNode } from 'react';
import {
  AfterSubmitFunc,
  Button,
  ButtonColor,
  ButtonSize,
  FormBlock,
  IconType,
  IfFormData,
  InputLayoutIcon,
  isReactNative,
  RelativeRow,
  SubmitButton,
  TextInput, useFormContext,
} from 'ui-builder';
import {
  CreateNewContactRequest,
  Name,
  PersonProfile,
  usePeopleService,
  useProjectAssignmentsService, ValidationError
} from 'nekst-api';
import {
  InformationMessage,
  PhoneNumbersInput,
  ProjectRolesMultiDropdown
} from 'features/nekst-widgets';
import SearchContactTextInput from './SearchContactTextInput';
import { BasePersonFormTemplate } from '../../../People';

export type AddTransactionPartyRequest = {
  personId: number,
  rolesIds: number[],
  profile: PersonProfile,
}

function SelectedPersonInformationWidget(
  props: {
    initialData?: AddTransactionPartyRequest | CreateNewContactRequest,
  }
) {

  const formContext = useFormContext<AddTransactionPartyRequest | CreateNewContactRequest>();

  return (
    <IfFormData conditionFunc={(data) => !!data.personId}>
      <InformationMessage withoutMargin>
        This contact is already in the system. Changes in the form below will update their profile.
        To select another contact or create a new one, click
        {' '}
        <Button
          onClick={() => {
            formContext.onChangeCallback!({
              ...props.initialData || {
                'profile.name.firstName': '',
                'profile.name.lastName': '',
                'profile.phoneNumbers': [],
                'profile.primaryEmail': '',
                'profile.alternateEmail': '',
              },
              personId: undefined,
            });
          }}
          isFilled
          isFlat
          size={ButtonSize.SMALL}
          text="here"
        />
      </InformationMessage>
    </IfFormData>
  );
}

interface AddPersonToTransactionFormProps {
  className?: string;
  projectId: number;
  afterSubmitFunc?: AfterSubmitFunc<void>;
  title?: ReactNode
  data?: CreateNewContactRequest | AddTransactionPartyRequest
  buttons?: ReactNode,
  children?: ReactNode,
  hideSelectedPersonInformationWidget?: boolean,
}

export function AddPersonToTransactionForm(props: AddPersonToTransactionFormProps) {
  const projectAssignmentsService = useProjectAssignmentsService();

  const peopleService = usePeopleService();

  const getTitle = () => {
    if (props.title) {
      return props.title;
    } else {
      return !isReactNative() ? 'Add Person to Transaction' : undefined;
    }
  };

  return (
    <BasePersonFormTemplate<CreateNewContactRequest | AddTransactionPartyRequest, void>
      submitFormFunc={async (data) => {
        if ('personId' in data && data.personId) {

          const existingRecord = await peopleService.getById(data.personId);

          await peopleService.update(data.personId, {
            ...existingRecord,
            profile: {
              ...existingRecord.profile,
              primaryEmail: data.profile.primaryEmail,
              alternateEmail: data.profile.alternateEmail,
              phoneNumbers: data.profile.phoneNumbers,
              companyName: data.profile.companyName,
            },
          });

          await projectAssignmentsService.addTransactionParty(
            props.projectId,
            {
              personId: data.personId,
              rolesIds: data.rolesIds,
            },
          );
        } else {
          try {
            await projectAssignmentsService.createNewContact(
              props.projectId,
              data as CreateNewContactRequest,
            );
          } catch (e) {
            if (e instanceof ValidationError) {
              throw new ValidationError({
                ...e.errors,
                'profile.primaryEmail': e.errors?.['profile.primaryEmail']
                  ? e.errors['profile.primaryEmail'].map((errorMessage) => {
                    if (errorMessage.includes('unique email')) {
                      return 'This contact already exists. Please type the first or last name and select from the dropdown.';
                    } else {
                      return errorMessage;
                    }
                  }) : [],
              });
            } else {
              throw e;
            }
          }

        }
      }}
      afterSubmitFunc={props.afterSubmitFunc}
      className={props.className}
      validationSchema={projectAssignmentsService.getCreateNewContactValidationSchema()}
      buttons={(
        props.buttons || <SubmitButton
          text="Add"
          key="add-transaction-party-button"
          color={ButtonColor.BLUE}
          iconType={IconType.PLUS}
        />
      )}
      initialData={props.data || {
        profile: {
          name: {
            firstName: '',
            lastName: '',
          } as Name,
          primaryEmail: '',
          phoneNumbers: [],
        },
        rolesIds: [],
      }}
      title={getTitle()}
      clearAfterSubmit
    >
      <FormBlock>
        {props.children}
        {isReactNative() ? (
          <>
            <TextInput
              source="profile.name.firstName"
              label="First Name"
              icon={InputLayoutIcon.PERSON}
            />
            <TextInput
              source="profile.name.lastName"
              label="Last Name"
              icon={InputLayoutIcon.PERSON}
            />
          </>
        ) : (
          <>
            <RelativeRow weights={[6, 6]}>
              <SearchContactTextInput source="profile.name.firstName" label="First Name"
                                      isDisabled={(data) => !!data.personId} />
              <SearchContactTextInput source="profile.name.lastName" label="Last Name"
                                      isDisabled={(data) => !!data.personId} />
            </RelativeRow>
            {!props.hideSelectedPersonInformationWidget && (<SelectedPersonInformationWidget initialData={props.data} />)}
          </>
        )}
        <RelativeRow weights={isReactNative() ? [12] : [6, 6]}>
          <TextInput
            source="profile.primaryEmail"
            label="Primary Email"
            icon={InputLayoutIcon.EMAIL}
            isDisabled={!!props.data?.profile.primaryEmail}
          />
          <TextInput source="profile.alternateEmail" label="Alternate Email"
                     icon={InputLayoutIcon.EMAIL} />
        </RelativeRow>
        <RelativeRow weights={isReactNative() ? [12] : [6, 6]}>
          <TextInput
            source="profile.companyName"
            label="Company Name"
            isDisabled={!!props.data?.profile.companyName}
          />
        </RelativeRow>
        <PhoneNumbersInput
          source="profile.phoneNumbers"
          label="Phone Numbers"
          openCreateForm
        />
        <RelativeRow weights={[6, 6]}>
          <ProjectRolesMultiDropdown
            source="rolesIds"
            label="Role(s)"
            icon={InputLayoutIcon.ASSIGNMENT}
            hideTeamMembersOnlyRoles
          />
        </RelativeRow>
      </FormBlock>
    </BasePersonFormTemplate>
  );
}
