import React from 'react';
import { Person, PersonPermission } from 'nekst-api';
import { Button, ButtonColor, ButtonSize } from 'ui-builder';
import usePeopleNavigator from '../peopleNavigator';
import useAuthorizationChecker from '../../shared/authorization/authorizationChecker';

export default function OpenHistoryPageButton(
  props: {
    person: Person,
  },
) {
  const peopleNavigator = usePeopleNavigator();
  const { isGranted } = useAuthorizationChecker();

  if (isGranted(PersonPermission.READ_HISTORY)) {
    return (
      <Button
        text="History"
        isFilled
        color={ButtonColor.BLUE}
        size={ButtonSize.MEDIUM}
        onClick={async () => peopleNavigator.openViewHistoryPage(props.person.id)}
      />
    );
  } else {
    return null;
  }
}
