import {
  useAllTasksService,
  AbstractTask,
  EventData,
  useEventsSubscriber,
  TASK_CREATED,
  TASK_DELETED,
  TASK_UPDATED,
  TaskDeletedEvent,
  TaskEvent,
  TASK_COMPLETED,
  TaskCompletedEvent,
  TASKS_MASS_COMPLETED,
  TasksMassCompletedEvent,
  TASKS_MASS_DELETED,
  TasksMassDeletedEvent,
  TASKS_MASS_UPDATED_EVENT,
  TasksMassUpdatedEvent,
  TASKS_MASS_SET_DUE_DATE,
  TasksMassSetDueDateEvent,
  EMAIL_TASK_SENT,
  SMS_TASK_SENT, TASK_COMMENTS_UPDATED, TASK_UNCOMPLETED,
} from 'nekst-api';
import { useListContextRequired } from 'ui-builder';
import { sortTasksIncludingDependentTasks } from './tasksListSortHelper';

export function ListDataUpdaterOnTasksEvents() {
  const tasksService = useAllTasksService();
  const listContext = useListContextRequired<AbstractTask>();

  const listItems = (listContext.data || []) as AbstractTask[];

  const updateListData = (idsToRemove: number[], itemsToAdd: AbstractTask[]) => {
    const idsToRemoveMap: Record<number, true> = idsToRemove.reduce((prev, current) => ({
      ...prev,
      [current]: true,
    }), {});

    const newData = listItems.filter((item) => !idsToRemoveMap[item.id]);
    newData.push(...itemsToAdd);

    const updatedData = sortTasksIncludingDependentTasks(newData);

    const ids = [
      ...idsToRemove,
      ...itemsToAdd.map((i) => i.id),
    ];

    listContext.updateItemsVersions(ids);
    listContext._setData(updatedData);
  };

  async function onTaskCompleted(eventData: EventData) {
    const event = eventData as TaskCompletedEvent;
    const updatedTaskId = event.id;

    if (listItems && listItems.length) {
      const updatedTask = listItems.find((item) => item.id === updatedTaskId);

      if (updatedTask) {
        listContext._setLoading(true);

        const rootTaskId = updatedTask._rootTaskId || updatedTask.id;

        const childTasksIds = listItems
          .filter((item) => item._rootTaskId === rootTaskId)
          .map((item) => item.id);

        if (childTasksIds.length) {
          const updatedChildTasks = await tasksService.getTasks(
            {
              ids: [
                rootTaskId,
                ...childTasksIds,
              ],
            },
            {
              limit: 300,
              offset: 0,
            },
          );
          updateListData(
            [
              rootTaskId,
              ...childTasksIds,
            ],
            updatedChildTasks.data,
          );
        } else {
          updateListData([rootTaskId], [eventData.data]);
        }
        listContext._setLoading!(false);
      }
    }
  }

  async function onTaskCreated(eventData: EventData) {
    const event = eventData as TaskEvent;
    updateListData([], [event.data]);
  }

  async function onTaskUpdated(eventData: EventData) {
    const event = eventData as TaskEvent;
    updateListData([event.id], [event.data]);
  }

  function getRootTaskIdForTask(id: number) {
    const task = listItems?.find((item) => item.id === id);
    if (task) {
      return task._rootTaskId || task.id;
    } else {
      return null;
    }
  }

  function getTasksByRootTaskId(rootTaskId: number) {
    return listItems
      ?.filter((item) => item.id === rootTaskId || item._rootTaskId === rootTaskId)
      .map((item) => item.id) || [];
  }

  async function onTaskDeleted(eventData: EventData) {
    const event = eventData as TaskDeletedEvent;
    const task = listItems?.find((item) => item.id === event.id);

    if (task && (task._hasDependentTasks || (task.parentTaskRelation?.parentTaskId))) {
      const rootTaskId = task._rootTaskId || task.id;

      const tasksToUpdateIds = listItems
        ?.filter((item) => item.id === rootTaskId || item._rootTaskId === rootTaskId)
        .map((item) => item.id) || [];

      if (tasksToUpdateIds.length) {
        const tasksToUpdate = await tasksService.getTasks(
          {
            ids: tasksToUpdateIds,
          },
          {
            limit: 100,
            offset: 0,
          },
        );

        updateListData(tasksToUpdateIds, tasksToUpdate.data);
      }
    } else {
      updateListData([event.id], []);
    }
  }

  async function onMassUpdateAction(ids: number[]) {
    const tasksToDeleteIds: number[] = [];
    const rootTasksIdsToUpdate: number[] = [];

    ids.forEach((id: number) => {
      const rootTaskId = getRootTaskIdForTask(id);

      if (rootTaskId) {
        tasksToDeleteIds.push(
          ...getTasksByRootTaskId(rootTaskId),
        );

        rootTasksIdsToUpdate.push(rootTaskId);
      }
    });

    if (rootTasksIdsToUpdate.length) {
      const tasksToUpdate = await tasksService.getTasks(
        {
          rootTasksIds: rootTasksIdsToUpdate,
        },
        {
          limit: 500,
          offset: 0,
        },
      );

      updateListData(tasksToDeleteIds, tasksToUpdate.data);
    }
  }

  async function onTaskMassDeleted(eventData: EventData) {
    const event = eventData as TasksMassDeletedEvent;

    await onMassUpdateAction(event.ids);
  }

  async function onMassUpdated(eventData: EventData) {
    const event = eventData as TasksMassUpdatedEvent;
    await onMassUpdateAction(event.ids);
  }

  async function onMassCompleted(eventData: EventData) {
    const event = eventData as TasksMassCompletedEvent;
    await onMassUpdateAction(event.ids);
  }

  async function onMassSetDueDate(eventData: EventData) {
    const event = eventData as TasksMassSetDueDateEvent;
    await onMassUpdateAction(event.ids);
  }

  useEventsSubscriber(
    'TasksList',
    {
      [TASK_COMPLETED]: onTaskCompleted,
      [TASK_UNCOMPLETED]: onTaskCompleted,
      [TASK_CREATED]: onTaskCreated,
      [TASK_DELETED]: onTaskDeleted,
      [TASK_UPDATED]: onTaskUpdated,
      [EMAIL_TASK_SENT]: onTaskUpdated,
      [SMS_TASK_SENT]: onTaskUpdated,
      [TASK_COMMENTS_UPDATED]: onTaskUpdated,
      [TASKS_MASS_DELETED]: onTaskMassDeleted,
      [TASKS_MASS_UPDATED_EVENT]: onMassUpdated,
      [TASKS_MASS_COMPLETED]: onMassCompleted,
      [TASKS_MASS_SET_DUE_DATE]: onMassSetDueDate,
    },
    [
      listContext.version,
    ],
  );

  return null;
}
