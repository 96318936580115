import React from 'react';
import { List, ListDataRefresher } from 'ui-builder';
import useDocumentLabelsService, { LABELS_UPDATED } from './documentLabelsService';
import TextField from '../../../shared/uibuilder/show/field/TextField';
import GridLayout from '../../../shared/uibuilder/list/GridLayout';
import Cell from '../../../shared/uibuilder/list/Cell';
import UpdateLabelButton from './UpdateLabelButton';
import DeleteLabelButton from './DeleteLabelButton';
import DocumentField from '../../../shared/file/DocumentField';
import RenameLabelFileButton from './RenameLabelFileButton';

export default function DocumentLabelsList() {
  const documentLabelService = useDocumentLabelsService();
  return (
    <List
      getDataFunc={documentLabelService.getLabels}
    >
      <GridLayout weights={[5, 5, 2]} noDataMessage="No document labels created">
        <TextField label="Name" source="name" />
        <Cell label="Universal Document">
          <DocumentField source="fileId" label="Document" fileDataSource="_file" />
          <RenameLabelFileButton />
        </Cell>
        <Cell label="Actiions">
          <UpdateLabelButton />
          <DeleteLabelButton />
        </Cell>
      </GridLayout>
      <ListDataRefresher eventName={LABELS_UPDATED} />
    </List>
  );
}
