import React from 'react';

interface PDFViewerProps {
  pdfUrl: string;
  fileName: string,
}


// eslint-disable-next-line react/function-component-definition
export const PDFViewer: React.FC<PDFViewerProps> = ({
  pdfUrl,
  fileName,
}) => {
  return (
    <iframe
      style={{
        width: '100%',
        height: '100%',
      }}
      src={`/pdf/web/viewer.html?file=${encodeURIComponent(pdfUrl)}&fileName=${encodeURIComponent(fileName)}`}
      title="Preview Document"
    />
  );
};
