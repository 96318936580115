import React from 'react';
import { OpenFormIconButton } from 'features/nekst-widgets';
import { AfterSubmitFunc, useShowContextRequired } from 'ui-builder';
import { TasksGroup, UpdateTaskGroupRequest, useTasksGroupsService } from 'nekst-api';
import BaseTasksGroupForm from './BaseTasksGroupForm';

function UpdateGroupForm(
  props: {
    afterSubmitFunc?: AfterSubmitFunc,
  }
) {
  const tasksGroupsService = useTasksGroupsService();

  const showContext = useShowContextRequired<TasksGroup>();

  return (
    <BaseTasksGroupForm
      submitFormFunc={async (data) => {
        return await tasksGroupsService.updateTaskGroup(showContext.data!.id, data as UpdateTaskGroupRequest);
      }}
      initialData={showContext.data!}
      title="Update Tasks Group"
      afterSubmitFunc={props.afterSubmitFunc}
    />
  )
}

export function UpdateTasksGroupButton() {
  return (
    <OpenFormIconButton
      form={<UpdateGroupForm />}
    />
  );
}
