import React from 'react';
import {
  PROJECT_ROLE_CREATED_EVENT,
  PROJECT_ROLE_DELETED_EVENT,
  PROJECT_ROLE_UPDATED_EVENT,
  useProjectRolesService
} from 'nekst-api';
import { List, ListDataRefresher } from 'ui-builder';
import GridLayout from '../../shared/uibuilder/list/GridLayout';
import TextField from '../../shared/uibuilder/show/field/TextField';
import Cell from '../../shared/uibuilder/list/Cell';
import UpdateRoleButton from './UpdateRoleButton';
import DeleteRoleButton from './DeleteRoleButton';
import InnerBlock from '../../shared/web/layout/InnerBlock';
import CreateProjectRoleButton from './CreateProjectRoleButton';

export default function RolesList() {
  const projectRolesService = useProjectRolesService();

  return (
    <InnerBlock
      title="Custom Roles"
      headerRight={<CreateProjectRoleButton />}
    >
      <List getDataFunc={projectRolesService.getAllTeamRoles}>
        <ListDataRefresher eventName={PROJECT_ROLE_UPDATED_EVENT} />
        <ListDataRefresher eventName={PROJECT_ROLE_DELETED_EVENT} />
        <ListDataRefresher eventName={PROJECT_ROLE_CREATED_EVENT} />
        <GridLayout weights={[10, 2]}>
          <TextField source="name" label="Role Name" />
          <Cell label="Actions">
            <>
              <UpdateRoleButton />
              <DeleteRoleButton />
            </>
          </Cell>
        </GridLayout>
      </List>
    </InnerBlock>
  );
}
