import React, { useEffect } from 'react';
import { useAuthenticationContext } from 'nekst-api';

function UserListInAppMessagingWidget(props: { token: string }) {
  useEffect(() => {
    const insertScript = (src: string, id: string) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      if (id) {
        script.setAttribute('data-userlist', id);
      }
      document.body.appendChild(script);
    };

    const insertInlineScript = () => {
      const script = document.createElement('script');
      script.innerHTML = `window.userlist=window.userlist||function(){(userlist.q=userlist.q||[]).push(arguments)};`;
      document.body.appendChild(script);
    };

    // Insert the inline script
    insertInlineScript();

    // Insert the external script with the user token
    insertScript('https://js.userlist.com/v1', props.token);

    // Cleanup function to remove scripts when component unmounts
    return () => {
      const scripts = document.querySelectorAll('script[data-userlist], script[innerHTML^="window.userlist"]');
      scripts.forEach(script => script.remove());
    };
  }, []);

  return null;
}

export default function UserListInAppMessaging() {

  const authenticationContext = useAuthenticationContext();

  const user = authenticationContext?.user;

  if (user) {
    return  <UserListInAppMessagingWidget token={user._userListToken} />;
  } else {
    return null;
  }
}
