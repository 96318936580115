import React from 'react';
import { ProjectDetailsFieldType } from 'nekst-api';
import Dropdown from '../../../shared/uibuilder/form/input/Dropdown';
import useProjectDetailsFieldTypeHelper from '../projectDetailsFieldTypeHelper';
import useInputHelper from '../../../shared/uibuilder/form/input/inputHelper';
import BaseInputLayout from '../../../shared/uibuilder/form/layout/BaseInputLayout';

export default function ProjectDetailsFieldTypeDropdown(
  props: {
    source: string,
    label: string,
  },
) {
  return (
    <Dropdown
      source={props.source}
      label={props.label}
      options={[
        {
          value: ProjectDetailsFieldType.SHORT_TEXT,
          label: 'Text',
        },
        {
          value: ProjectDetailsFieldType.PRICE,
          label: 'Currency',
        },
        {
          value: ProjectDetailsFieldType.DATE,
          label: 'Date',
        },
      ]}
    />
  );
}

export function ProjectDetailsFieldTypeFormField(props: { source: string, label: string, value?: ProjectDetailsFieldType }) {
  const inputHelper = useInputHelper<ProjectDetailsFieldType>(props);

  const projectDetailsFieldTypeHelper = useProjectDetailsFieldTypeHelper();

  return (
    <BaseInputLayout name={props.source} label={props.label}>
      <i>
        {projectDetailsFieldTypeHelper.getLabel(inputHelper.getValue())}
      </i>
    </BaseInputLayout>
  );
}
