/* istanbul ignore file */
import React from 'react';
import { BaseInputProps, BaseInputLayoutProps, InputLayoutIcon } from './types';
import { useNewUiTheme } from '../../newUiTheme';
import { useInputHelper } from './inputHelper';
import { KeyboardType } from 'react-native';
import { useFormContext } from '../FormContext';

export interface TextLayoutProps<ValueType = string>
  extends BaseInputLayoutProps<ValueType> {
  icon?: InputLayoutIcon,
  inputType?: string,
  mobile_keyboardType?: KeyboardType,
  mobile_textContentType?:
    | 'none'
    | 'URL'
    | 'addressCity'
    | 'addressCityAndState'
    | 'addressState'
    | 'countryName'
    | 'creditCardNumber'
    | 'emailAddress'
    | 'familyName'
    | 'fullStreetAddress'
    | 'givenName'
    | 'jobTitle'
    | 'location'
    | 'middleName'
    | 'name'
    | 'namePrefix'
    | 'nameSuffix'
    | 'nickname'
    | 'organizationName'
    | 'postalCode'
    | 'streetAddressLine1'
    | 'streetAddressLine2'
    | 'sublocality'
    | 'telephoneNumber'
    | 'username'
    | 'password'
    | 'newPassword'
    | 'oneTimeCode'
    | undefined;
  min?: number,
  max?: number
}

export interface TextInputProps extends BaseInputProps {
  icon?: InputLayoutIcon
  mobile_keyboardType?: KeyboardType,
  mobile_textContentType?:
    | 'none'
    | 'URL'
    | 'addressCity'
    | 'addressCityAndState'
    | 'addressState'
    | 'countryName'
    | 'creditCardNumber'
    | 'emailAddress'
    | 'familyName'
    | 'fullStreetAddress'
    | 'givenName'
    | 'jobTitle'
    | 'location'
    | 'middleName'
    | 'name'
    | 'namePrefix'
    | 'nameSuffix'
    | 'nickname'
    | 'organizationName'
    | 'postalCode'
    | 'streetAddressLine1'
    | 'streetAddressLine2'
    | 'sublocality'
    | 'telephoneNumber'
    | 'username'
    | 'password'
    | 'newPassword'
    | 'oneTimeCode'
    | undefined;
}

export function TextInput(props: TextInputProps) {

  const uiTheme = useNewUiTheme();

  const inputHelper = useInputHelper(props);

  return (
    <uiTheme.inputs.TextLayout
      {...inputHelper.getBaseInputLayoutProps()}
      icon={props.icon}
      mobile_keyboardType={props.mobile_keyboardType}
      mobile_textContentType={props.mobile_textContentType}
    />
  );
}
