import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { If, List, ListDataRefresher } from 'ui-builder';
import {
  PROJECT_DETAILS_FIELDS_UPDATED,
  ProjectDetailsField,
  ProjectDetailsFieldType,
  ProjectType,
  TeamPermission,
  useProjectDetailsFieldsService
} from 'nekst-api';
import { GridLayoutWithSort } from '../../../shared/uibuilder/list/GridLayout';
import TextField from '../../../shared/uibuilder/show/field/TextField';
import ProjectDetailsFieldTypeField from './ProjectDetailsFieldTypeField';
import Cell from '../../../shared/uibuilder/list/Cell';
import UpdateProjectDetailsFieldButton from '../CreateUpdate/UpdateProjectDetailsFieldButton';
import ProjectDetailsFieldVisibilityField from './ProjectDetailsFieldVisibilityField';
import DeleteProjectDetailsFieldButton from './DeleteProjectDetailsFieldButton';

import Checkbox from '../../../shared/uibuilder/form/input/Checkbox';
import { withAccessCheck } from 'authorization-scope';
import DetailsFieldsTabs from '../shared/DetailsFieldsTabs';
import SetProjectDatesFieldsForm from './SetProjectDatesFieldsForm';

function ShowHiddenFieldsToggle(
  props: {
    value: boolean,
    setValue: (newValue: boolean) => void
  },
) {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <Checkbox
        source="showHiddenFields"
        value={props.value}
        label="Show Hidden Detail Boxes"
        onChangeCallback={() => props.setValue(!props.value)}
      />
    </div>
  );
}

function ProjectDetailsFieldsList() {
  const [showHiddenDetailBoxes, setShowHiddenDetailBoxes] = useState(false);

  const { projectType: projectTypeParam } = useParams<{ projectType: string }>();

  const projectDetailsFieldsService = useProjectDetailsFieldsService();

  const projectType = projectTypeParam?.toUpperCase() as ProjectType | undefined;

  const service = useProjectDetailsFieldsService();

  if (projectType) {
    const fields = (
      <GridLayoutWithSort<ProjectDetailsField>
        orderingConfiguration={{
          isEnabled: true,
          updateOrderFunc: async (ids: number[]) => {
            await service.setOrder(projectType, ids);
          },
          isItemDraggable: (item: ProjectDetailsField) => {
            return item.fieldType !== ProjectDetailsFieldType.LONG_TEXT;
          },
          getOrderGroupFunc: (item) => {
            return item.fieldType !== ProjectDetailsFieldType.LONG_TEXT ? 'edited' : 'not_edited';
          },
        }}
      >
        <TextField source="name" label="Detail Boxes" />
        <ProjectDetailsFieldTypeField source="fieldType" label="Type" />
        <Cell label="Actions">
          <UpdateProjectDetailsFieldButton projectType={projectType} />
          <If<ProjectDetailsField> conditionFunc={(data) => data.isPreexisting}>
            <ProjectDetailsFieldVisibilityField source="isHidden" />
          </If>
          <If<ProjectDetailsField> conditionFunc={(data) => !data.isPreexisting}>
            <DeleteProjectDetailsFieldButton projectType={projectType} />
          </If>
        </Cell>
      </GridLayoutWithSort>
    );

    return (
     <>
        <DetailsFieldsTabs projectType={projectType} currentSetId={0} />
        <List
          getDataFunc={async () => {
            const result = await projectDetailsFieldsService.getFields(projectType, true);

            return result.filter((item) => {
              if (showHiddenDetailBoxes) {
                return true;
              } else {
                return !item.isHidden;
              }
            });
          }}
          dependencies={[projectType, showHiddenDetailBoxes]}
        >
          <SetProjectDatesFieldsForm projectType={projectType} />
          <ShowHiddenFieldsToggle
            value={showHiddenDetailBoxes}
            setValue={setShowHiddenDetailBoxes}
          />
          {fields}
          <ListDataRefresher eventName={PROJECT_DETAILS_FIELDS_UPDATED} />
        </List>
      </>
    );
  } else {
    return null;
  }
}

export default withAccessCheck(ProjectDetailsFieldsList, TeamPermission.MANAGE_PROJECT_DETAILS_FIELDS);
