import { useNavigate } from 'react-router-dom';

export const LOGIN_PAGE_LINK = '/login';

export const RESET_PASSWORD_PAGE_LINK = '/resetpassword';

export default function useLoginNavigator() {

  const navigate = useNavigate();
  const openLoginPage = () => {
    navigate(LOGIN_PAGE_LINK);
  };

  const openResetPasswordPage = () => {
    navigate(RESET_PASSWORD_PAGE_LINK);
  };

  return {
    openLoginPage,
    openResetPasswordPage,
  };
}
