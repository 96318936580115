import { AfterSubmitFunc, FormTemplate, TextInput } from 'ui-builder';
import {
  DetailsFieldsSet,
  FileType,
  UpdateDetailsSetFieldRequest,
  useDetailsFieldsSetsService
} from 'nekst-api';
import React from 'react';
import FileUploaderInput from '../../../../shared/file/FileUploaderInput';

export default function UpdateDetailsFieldsSetForm(
  props: {
    afterSubmitFunc?: AfterSubmitFunc,
    detailsSet: DetailsFieldsSet,
  }
) {
  const setsService = useDetailsFieldsSetsService();

  return (
    <FormTemplate<UpdateDetailsSetFieldRequest, DetailsFieldsSet>
      title={`Update Set "${props.detailsSet.name}"`}
      submitFormFunc={async (data) => {
        return setsService.updateSet(props.detailsSet.id, data);
      }}
      afterSubmitFunc={props.afterSubmitFunc}
      initialData={props.detailsSet}
      validationSchema={{
        name: {
          type: 'string',
          constraints: {
            required: true,
            maxLength: 40,
          },
        },
      }}
    >
      <TextInput source="name" label="Name" />
      <FileUploaderInput
        fileType={FileType.PROJECT_DOCUMENT}
        fileDataSource="_contractDocumentExampleFile"
        source="contractDocumentExampleFileId"
        label="Contract Document Exmaple"
        hint="A contract document example is utilized for training AI systems to comprehend the content and structure of contractual agreements."
      />
    </FormTemplate>
  );
}
