import React from 'react';
import { BlockLayoutProps } from 'ui-builder';

export default function BaseBlockLayout(props: BlockLayoutProps) {
  return (
    <div
      className={props.className}
      onClick={props.onClick}
      style={props.backgroundColor ? { backgroundColor: props.backgroundColor } : {}}
      title={props.title}
    >
      {props.children}
    </div>
  );
}

export function FormBlockLayout(props: BlockLayoutProps) {

  const classes = [
    props.className || '',
    'form-rows'
  ];
  return (
    <BaseBlockLayout
      className={classes.join(' ')}
      onClick={props.onClick}
      backgroundColor={props.backgroundColor}
      title={props.title}
    >
      {props.children}
    </BaseBlockLayout>
  );
}
