import React from 'react';
import { BaseInputProps, LoadingAnimation, Right, SmartTagsTextInput, Tag } from 'ui-builder';
import { ProjectType, useSmartTagsService } from 'nekst-api';
import useDataLoader from 'data-loader';
import HowToUseSmartTagsLink from '../../../shared/uibuilder/form/HowToUseSmartTagsLink';

export interface TitleSmartTagsInputProps extends BaseInputProps<string> {
  projectType: ProjectType,
}

export default function PortalTitleInput(props: TitleSmartTagsInputProps) {
  const smartTagsService = useSmartTagsService();

  const [smartTags, setSmartTags] = React.useState<Tag[]>();

  useDataLoader(
    async () => {
      const tags = await smartTagsService.getSmartTags(props.projectType);
      return tags.filter((tag) => !tag.isDeprecated);

    },
    setSmartTags,
    props.projectType,
  );

  if (smartTags) {
    return (
      <>
        <Right><HowToUseSmartTagsLink /></Right>
        <SmartTagsTextInput
          availableTags={smartTags}
          {...props}
        />
      </>
    );
  } else {
    return <LoadingAnimation />;
  }
}
