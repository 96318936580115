import React from 'react';
import BaseCircleButton from './BaseCircleButton';
import styles from './Buttons.module.scss';

export default function CancelCircleButton(
  props: {
    onClick: () => Promise<void>,
  },
) {
  return (
    <BaseCircleButton className={styles.cancel} onClick={props.onClick} />
  );
}
