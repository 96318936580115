import React from 'react';
import RecordLayout, { RecordLayoutProps } from './RecordLayout';
import PersonLink from './PersonLink';
import NoteText from './NoteText';
import { HistoryEventIconType } from './HistoryEventIcon';
import { BaseHistoryEntryData, NoteData } from 'nekst-api';

interface ProjectNoteCreatedData extends BaseHistoryEntryData {
  note: NoteData,
}

export default function ProjectNoteCreatedRecordLayout(
  props: RecordLayoutProps<ProjectNoteCreatedData>,
) {
  return (
    <RecordLayout
      text={(
        <>
          <PersonLink person={props.record.data.person!} />
          {' added the note '}
          <NoteText note={props.record.data.note} />
          .
        </>
      )}
      date={props.record.when}
      iconType={HistoryEventIconType.NOTE_CREATED}
    />
  );
}
