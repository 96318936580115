import {
  FormFieldsData, LoadingAnimation,
  ShowContext,
  SimpleBlock, Text,
  useListContext,
  VisibilityState
} from 'ui-builder';
import React, { ReactElement } from 'react';
import { ViewStyle } from 'react-native';
import { AppSpacings } from 'ui-library-mobile-theme/commonStyles';
import { get } from 'lodash';

export interface RowRepeaterProps<DataType = FormFieldsData> {
  children: ReactElement,
  blockClassName?: string,
  groupClassName?: string | ((data: DataType) => string),
  loadMoreButtonClassName?: string,
  idField?: string,
  parentIdField?: string
  noDataMessage?: string
  getKeyFunc?: (row: FormFieldsData) => string;
  dataKey?: string
  style?: ViewStyle,
}

export interface RowRepeaterLayoutProps extends RowRepeaterProps {
  data: FormFieldsData[];
  level: number;
  isLoading?: boolean;
  parentId?: number;
  getKeyFunc?: (row: FormFieldsData) => string;
  style?: ViewStyle
}

function RowRepeaterLayout(props: RowRepeaterLayoutProps) {
  const idField = (props && props.idField) || 'id';

  const listContext = useListContext();

  function filterFunc(item: FormFieldsData) {
    if (props.parentId) {
      return item?.parentTaskRelation?.parentTaskId === props.parentId;
    } else {
      return !item?.parentTaskRelation?.parentTaskId;
    }
  }

  let data: FormFieldsData[];
  if (props.parentIdField) {
    data = props.data ? props.data.filter(filterFunc) : [];
  } else {
    data = props.data || [];
  }

  function getGroupClassName(item: FormFieldsData) {
    if (typeof props.groupClassName === 'function') {
      return (props.groupClassName(item));
    } else {
      return props.groupClassName;
    }
  }

  if (data.length > 0) {
    let visibleItemNumber = 0;

    const showRows = (rows: FormFieldsData[]) => {
      return rows.map((rowData: FormFieldsData, index) => {

        let visibilityState;
        if ('id' in rowData) {
          visibilityState = listContext.getVisibilityState!(rowData.id);
        } else {
          visibilityState = VisibilityState.VISIBLE;
        }

        const key = props.getKeyFunc ? props.getKeyFunc(rowData) : `row-${get(rowData, idField)}`;

        if (visibilityState !== VisibilityState.HIDDEN) {
          visibleItemNumber += 1;
          // eslint-disable-next-line react/jsx-no-constructed-context-values
          /* eslint-disable react/jsx-props-no-spreading */
          return (
            <SimpleBlock
              className={`${getGroupClassName(rowData)} ${visibleItemNumber % 2 === 0 && props.level === 0 ? 'even' : ''}`}
              key={key}
              style={props.style}
            >
              <ShowContext
                data={{
                  ...rowData,
                  __index: index,
                }}
              >
                {React.cloneElement(props.children)}
              </ShowContext>
              {props.parentIdField && (
                <RowRepeaterLayout
                  {...props}
                  parentId={rowData.id}
                  key={`row-${rowData[idField]}-children`}
                  level={props.level + 1}
                  style={props.style}
                >
                  {props.children}
                </RowRepeaterLayout>
              )}
            </SimpleBlock>
          );
        } else {
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return null;
        }
      });
    };

    return (
      <SimpleBlock
        testID={props.level === 0 ? props.dataKey : undefined}
        className={
          `${props.blockClassName || ''} ${props.isLoading ? 'loading' : ''} level${props.level}`
        }
        style={props.style}
      >
        {showRows(data)}
      </SimpleBlock>
    );
  } else {
    if (props.isLoading) {
      return (<LoadingAnimation />);
    } else {
      return null;
    }

  }
}

export function RowRepeater(props: RowRepeaterProps) {
  const listContext = useListContext();

  const data = listContext.data || [];

  if (listContext.isLoading || listContext.hasVisibleItems!()) {
    return (
      <RowRepeaterLayout
        dataKey={props.dataKey}
        data={data}
        level={0}
        isLoading={listContext.isLoading}
        parentIdField={props.parentIdField}
        idField={props.idField}
        blockClassName={props.blockClassName}
        groupClassName={props.groupClassName}
        loadMoreButtonClassName={props.loadMoreButtonClassName}
        getKeyFunc={props.getKeyFunc}
        style={props.style}
      >
        {props.children}
      </RowRepeaterLayout>
    );
  } else {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <SimpleBlock
        className="no-messages"
        style={{
          paddingHorizontal: AppSpacings.pageHorizontalPadding,
          paddingVertical: AppSpacings.pageVerticalPadding,
        }}
      >
        <Text>{props.noDataMessage}</Text>
      </SimpleBlock>
    );
  }
}
