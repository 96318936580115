import React, { ReactNode, useState } from 'react';
import Layout from './Layout/index';
import { ContactAssignments, PersonProfile, ShortProfile, usePeopleService } from 'nekst-api';
import { UserLogoSize } from './Layout/types';
import { ViewStyle } from 'react-native';
import { LoadingAnimation, TextField } from 'ui-builder';
import useDataLoader from 'data-loader';
import { PhoneNumberWithExtensionField } from '../PhoneNumbersInput';

interface AssigneeWidgetProps {
  contactAssignments: ContactAssignments;
}

export function AssigneeWidget(
  props: AssigneeWidgetProps,
) {
  return (
    <Layout
      profile={props.contactAssignments.profile}
      roles={props.contactAssignments.roles}
    />
  );
}

export interface PersonWidgetProps {
  profile: ShortProfile | PersonProfile;
  onClick?: () => void,
  withName?: boolean;
  size?: UserLogoSize;
  badge?: string;
  additionalInfo?: ReactNode | ReactNode[],
  style?: ViewStyle,
}

export function PersonWidget(props: PersonWidgetProps) {
  return (
    <Layout
      profile={props.profile}
      logoSize={props.size}
      badge={props.badge}
      withName={props.withName}
      onClick={props.onClick}
      additionalInfo={props.additionalInfo}
      style={props.style}
    />
  );
}

export function PersonWidgetWithLoad(props: { id: number }) {
  const peopleService = usePeopleService();

  const [profile, setProfile] = useState<ShortProfile | PersonProfile>();

  useDataLoader(
    async () => {
      const person = await peopleService.getById(props.id);
      return person.profile;
    },
    setProfile,
    props.id
  );

  const phoneNumber = profile?.phoneNumbers[0];

  if (profile) {
    return (
      <PersonWidget
        profile={profile}
        withName
        additionalInfo={[
          <TextField key={`profile-email`} source="primaryEmail" value={profile.primaryEmail} />,
          phoneNumber ? <PhoneNumberWithExtensionField
            key={`profile-phone`}
            value={profile.phoneNumbers[0].number}
            extension={profile.phoneNumbers[0].extension}
          /> : null,
        ]}
      />
    );
  } else {
    return (<LoadingAnimation />);
  }
}
