import React from 'react';
import { useFormContext } from 'ui-builder';
import { AbstractTask } from 'nekst-api';
import TaskCommentsList from './TaskCommentsList';
import { IsVisibleFunc } from 'ui-builder';

export interface TaskCommentsListInputProps {
  // eslint-disable-next-line react/no-unused-prop-types
  isVisible?: boolean | IsVisibleFunc;
}

// noinspection JSUnusedLocalSymbols
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function TaskCommentsListInput(props: TaskCommentsListInputProps) {
  const formContext = useFormContext<AbstractTask>();

  if (formContext.data?.id) {
    return (
      <TaskCommentsList
        taskId={formContext.data.id}
      />
    );
  } else {
    return null;
  }
}
