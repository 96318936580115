import React from 'react';
import {
  Button,
  ButtonColor,
  ButtonSize,
  useMassUpdateServiceRequired,
  usePopupManager,
} from 'ui-builder';
import DeleteForm from './DeleteForm';

export default function DeleteButton() {
  const popupManager = usePopupManager();
  const massUpdateService = useMassUpdateServiceRequired();

  return (
    <Button
      text="Delete"
      size={ButtonSize.SMALL}
      isDisabled={massUpdateService.getCheckedIds().length === 0}
      isFlat
      isFilled
      color={ButtonColor.RED}
      onClick={async () => {
        popupManager.openPopup(
          <DeleteForm />,
        );
      }}
    />
  );
}
