import React, { ReactElement } from 'react';
import { PhoneNumberView, useShowContext } from 'ui-builder';
import { get } from 'lodash';
import { TextStyle } from 'react-native';

interface PhoneNumberWithExtensionFieldProps {
  source?: string;
  extensionSource?: string;
  value?: string;
  extension?: string;
  noNumberText?: string | ReactElement;
  style?: TextStyle,
}

export function PhoneNumberWithExtensionField(props: PhoneNumberWithExtensionFieldProps) {
  const showContext = useShowContext();

  const data = showContext.data!;

  let number: string;
  if (props.source) {
    number = get(data, props.source) || '';
  } else {
    number = props.value || '';
  }

  let extension: string;
  if (props.extensionSource) {
    extension = get(data, props.extensionSource) || '';
  } else {
    extension = props.extension || '';
  }

  return (
    <PhoneNumberView
      number={number}
      extension={extension}
      noNumberText={props.noNumberText}
      style={props.style}
    />
  );
}
