import React from 'react';

import styles from './History.module.scss';
import Record from './Record';
import { HistoryEntry } from 'nekst-api';

interface Props {
  date: string;
  entries: HistoryEntry[];
}

export default function HistoryDayGroup(props: Props) {
  return (
    <div className={styles.dayBlock}>
      <div className={styles.title}>{props.date}</div>
      {props.entries.map((historyEvent) => {
        let key = `history-event-${historyEvent.event}-${historyEvent.when.getTime()}-${historyEvent.data.project?.id}-${historyEvent.data.task?.id}`;
        key = key.replace(/\s/g, '');

        return (
          <Record
            event={historyEvent}
            key={key}
          />
        );
      })}
    </div>
  );
}
