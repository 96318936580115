import { useAuthenticationLoader } from '../../../authentication/AuthenticationContext';
import { TeamMemberHistoryFilter, useTeamMemberHistoryService } from 'nekst-api';

export default function useMyHistoryService() {
  const { currentUser } = useAuthenticationLoader();

  const peopleHistoryService = useTeamMemberHistoryService();

  const getHistoryEvents = async (
    filter: TeamMemberHistoryFilter | null,
    timeFrom?: number,
  ) => {
    return peopleHistoryService.getHistoryEvents(
      currentUser.id,
      filter,
      timeFrom,
    );
  };

  return {
    getHistoryEvents,
  };
}
