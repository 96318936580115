import React from 'react';

import styles from './DueDateField.module.scss';
import { usePlanTaskDateFieldLogic } from 'features/tasks-forms';

export default function DueDateField() {
  const planTaskDateFieldLogic = usePlanTaskDateFieldLogic();

  const value = planTaskDateFieldLogic.getFieldText();

  if (value) {

    return (
      <div className={styles.field}>
        <i className={styles.icon}>&nbsp;</i>
        <div className={styles.text}>
          {value}
        </div>
      </div>
    );
  } else {
    return null;
  }
}
