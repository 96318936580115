import React, { useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import { BaseInputProps } from '../uibuilder/form/input';
import useInputHelper from '../uibuilder/form/input/inputHelper';
import BaseInputLayout from '../uibuilder/form/layout/BaseInputLayout';
import { ValidationError } from 'request-sender';
import { useFormContext } from 'ui-builder';
import FilePreview, { FileData } from './FilePreview';
import IconButton, { IconType } from '../widgets/IconButton';
import { FileDto, FileType, useFileService } from 'nekst-api';

export interface FileUploaderInputProps extends BaseInputProps<number> {
  fileType: FileType,
  fileDataSource: string,
}

export default function FileUploaderInput(
  props: FileUploaderInputProps,
) {

  const helper = useInputHelper(props);

  const fileService = useFileService();

  const formContext = useFormContext();

  const [progress, setProgress] = useState<number>();

  const fileData = get(formContext.data, props.fileDataSource) as FileDto | undefined;

  const [chosenFile, setChosenFile] = useState<FileData | undefined>(
    fileData ? {
      name: fileData.name,
      type: fileData.mimeType,
    } : undefined,
  );

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => setProgress(undefined), 1000);
    }
  }, [progress]);

  const handleFileChosen = async (e: any) => {
    const file = e.target.files.length > 0 ? e.target.files[0] : null;

    formContext.__setErrors!({});

    if (file) {
      try {
        formContext.setDisabled!(true);

        const result = await fileService.uploadFile(
          file,
          props.fileType,
          file.name,
          setProgress,
        );

        setChosenFile({
          name: file.name,
          type: file.type,
        });

        helper.getOnChangeCallback()({
          target: {
            value: result.id,
          },
        });

      } catch (error: any) {
        if (error instanceof ValidationError) {
          formContext.__setErrors!(error.errors);
        }
        ref.current!.value = '';
      } finally {
        formContext.setDisabled!(false);
      }
    }
  };

  return (
    <BaseInputLayout {...helper.getBaseInputLayoutProps()}>
      <input
        ref={ref}
        type="file"
        name={props.source}
        onChange={handleFileChosen}
      />
      <>
        {progress && progress < 100 && (
          <div className="upload-progress">
            {`Uploading... ${progress}%`}
          </div>
        )}
        {progress === 100 && (
          <div className="upload-progress">
            File Uploaded
          </div>
        )}
        {chosenFile && (
          <div>
            <FilePreview data={chosenFile} />
            <IconButton
              onClick={() => {
                setChosenFile(undefined);
                helper.getOnChangeCallback()({
                  target: {
                    value: undefined,
                  },
                });

                ref.current!.value = '';
              }}
              type={IconType.TRASH_CAN}
              title="Delete"
            />
          </div>
        )}

      </>
    </BaseInputLayout>
  );
}
