import React, { ReactElement, useContext } from 'react';
import styles from '../History.module.scss';
import HistoryEventIcon, { HistoryEventIconType } from './HistoryEventIcon';
import { HistoryEntry } from 'nekst-api';

export interface WrapperLayoutProps {
  text: ReactElement;
  date: Date;
  iconType: HistoryEventIconType;
}

export interface RecordLayoutProps<DataType = Record<string, any>> {
  record: HistoryEntry<DataType>,
}

function DefaultRecordLayout(props: WrapperLayoutProps) {
  const getTime = (date: Date) => {
    const hours = date.getHours() % 12;
    const part = date.getHours() > 12 ? 'pm' : 'am';

    const formatNumber = (value: number) => {
      if (value < 10) {
        return `0${value}`;
      } else {
        return value;
      }
    };
    return `${formatNumber(hours)}:${formatNumber(date.getMinutes())}${part}`;
  };

  return (
    <div className={styles.record}>
      <HistoryEventIcon iconType={props.iconType} />
      <div className={styles.text}>
        {props.text}
      </div>
      <div className={styles.date}>
        {getTime(props.date)}
      </div>
    </div>
  );
}

export interface RecordLayoutContextData {
  layout: (props: WrapperLayoutProps) => JSX.Element;
  isInTaskContext: boolean;
}

export const RecordLayoutReactContext = React.createContext<RecordLayoutContextData>({
  layout: DefaultRecordLayout,
  isInTaskContext: false,
});

export function useRecordLayoutContext() {
  return useContext<RecordLayoutContextData>(RecordLayoutReactContext);
}

export default function RecordLayout(props: WrapperLayoutProps) {
  const context = useRecordLayoutContext();

  const Layout = context.layout;

  return (
    <Layout
      text={props.text}
      date={props.date}
      iconType={props.iconType}
    />
  );
}
