import React from 'react';
import useProjectDocumentsService from './projectDocumentsService';
import DocumentTemplateDropdown from './DocumentTemplateDropdown';
import { Button, AfterSubmitFunc, ButtonColor, FormTemplate, IconType } from 'ui-builder';
import { usePopupFormManager } from 'features/nekst-widgets';
import { ProjectPermission, useAuthorizationChecker } from 'authorization-scope';

export default function ApplyTemplateForm(
  props: {
    projectId: number,
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {
  const projectDocumentsService = useProjectDocumentsService();
  return (
    <FormTemplate<{ templateId: number }>
      title="Apply Template"
      submitFormFunc={async (data) => {
        await projectDocumentsService.applyTemplate(props.projectId, data.templateId);
        return data;
      }}
      afterSubmitFunc={props.afterSubmitFunc}
    >
      <DocumentTemplateDropdown source="templateId" label="Template" />
    </FormTemplate>
  );
}

export function ApplyTemplateButton(props: {
  projectId: number,
}) {
  const popupManager = usePopupFormManager();

  const { isGranted } = useAuthorizationChecker();

  if (isGranted(ProjectPermission.CREATE_DOCUMENTS)) {
    return (
      <Button
        text="Apply Template"
        iconType={IconType.PLUS}
        color={ButtonColor.GRAY}
        onClick={() => popupManager.openForm(<ApplyTemplateForm projectId={props.projectId} />)}
      />
    );
  }
}
