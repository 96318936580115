import React, { ReactElement } from 'react';
import {
  AfterSubmitFunc,
  FormLayoutProps,
} from 'ui-builder';
import { UpdateProjectForm as BaseUpdateProjectForm } from 'features/tasks-forms';
import useLaunchProjectNavigator from '../launchProjectNavigator';

export interface UpdateProjectFormProps {
  buttons?: ReactElement;
  className?: string;
  projectId: number;
  afterSubmitFunc?: AfterSubmitFunc;
  layout?: ReactComponent<FormLayoutProps>;
  title?: string;
  checkDateUpdates?: boolean;
}

export default function UpdateProjectForm(props: UpdateProjectFormProps) {
  const navigator = useLaunchProjectNavigator();
  return (
    <BaseUpdateProjectForm
      {...props}
      openLaunchProjectPageFunc={(projectId) => navigator.openSecondStep(projectId)}
    />
  );
}
