export default function useConstraintValidatorHelper<ConfigType>() {

  const createHelper = (
    object: Record<string, any>,
    fieldName: string,
    config: any,
  ) => {

    const getValue = () => {
      return object[fieldName];
    };

    const hasValue = (): boolean => {
      return object[fieldName] !== undefined && object[fieldName] !== null && object[fieldName] !== '';
    };

    const getConstraintValue = () => {
      if (typeof config === 'object') {
        return config.value as ConfigType;
      } else {
        return config as ConfigType;
      }
    };

    return {
      getValue,
      hasValue,
      getConstraintValue,
    };
  };

  return {
    createHelper,
  };
}
