import { useBackendApi } from "../Api/backendApi";

export type ApplicationConfiguration = {
  crispWebsiteId: string
  isChatEnabled: boolean
}

export function useConfigService() {
  const nekstApi = useBackendApi();

  const getConfig = async () => {
    return await nekstApi.get('/config') as ApplicationConfiguration;
  };

  return {
    getConfig,
  };
}
