import React from 'react';
import { SubscriptionPlanId } from 'nekst-api';

export default function useSubscriptionPlanFeaturesHelper() {
  const getFeatures = (id: SubscriptionPlanId) => {
    switch (id) {
      case SubscriptionPlanId.AGENT_LITE:
        return [
          'Up to 5 Total Transactions',
          'Intuitive Task Management System',
          'Calendar Integration',
          'Unlimited Contacts',
          '4 Pre-loaded Plans',
          'Unlimited Custom Plans',
          'Email Notifications',
          'Support: Chat',
        ];
      case SubscriptionPlanId.AGENT_PRO:
        return [
          <>
            <b>Unlimited</b>
            {' '}
            Transactions
          </>,
          'Intuitive Task Management System',
          'Calendar Integration',
          'Unlimited Contacts',
          '4 Pre-loaded Plans',
          'Unlimited Custom Plans',
          'Email Notifications',
          'Support: Chat',
        ];
      case SubscriptionPlanId.TEAM_PRO:
        return [
          <>
            <b>Unlimited</b>
            {' '}
            Transactions
          </>,
          'Intuitive Task Management System',
          <>
            Assign Tasks to
            {' '}
            <b>Any Party</b>
          </>,
          'Calendar Integration',
          'Unlimited Contacts',
          '4 Pre-loaded Plans',
          'Unlimited Custom Plans',
          'Email Notifications',
          'Support: Chat & Phone',
        ];
      default:
        return [];
    }
  };

  return {
    getFeatures,
  };
}
