import React from 'react';
import { TasksGroupFilterContainer } from './TasksGroupFilter';

export default function PlanFilterContainer() {
  return (
    <>
      <div id="filter1" />
      <div id="filter2" />
      <div id="details-box-dates" />
      <TasksGroupFilterContainer />
    </>
  );
}
