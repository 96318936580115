import { useEffect, useRef, useState } from 'react';
import { useFormContextRequired } from 'ui-builder';
import { AbstractTask, ContactAssignments, useProjectAssignmentsService } from 'nekst-api';

export default function useProjectAssignmentsHelper(
  onUpdate?: () => void,
) {
  const didMount = useRef(false);

  const formContext = useFormContextRequired<AbstractTask>();

  const projectAssignmentsService = useProjectAssignmentsService(
    true,
  );

  const [projectAssignments, setProjectAssignments] = useState<ContactAssignments[]>();

  const projectId = formContext.data?.projectId || 0;

  useEffect(() => {
    const loadAssignments = async () => {
      try {
        if (projectId) {
          const newAssignments = await projectAssignmentsService.getContactAssignments(
            projectId,
          );
          setProjectAssignments(newAssignments);
        } else {
          setProjectAssignments([]);
        }
      } catch (e) {
        setProjectAssignments([]);
      }
    };

    loadAssignments();

  }, [projectId]);

  useEffect(() => {
    if (didMount.current) {
      if (onUpdate) {
        onUpdate();
      }
    } else if (projectId) {
      didMount.current = true;
    }
  }, [projectId]);

  return {
    projectAssignments,
  };
}
