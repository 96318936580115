import React, {
  ReactElement,
  useEffect,
  useState,
} from 'react';
import {
  useFormContext,
} from 'ui-builder';
import {
  useProjectAssignmentsService,
  ContactAssignments,
  TeamRole,
} from 'nekst-api';
import { useProjectContext } from '../../../../ProjectContext';
import RelativeRow, {
  Cell,
  EmptyCell,
} from '../../../../../../shared/web/uitheme/form/RelativeRow';
import useUiTheme from '../../../../../../shared/uibuilder/uiTheme';

import styles from './AssigneeFilter.module.scss';
import { AdvancedFilterData } from './AdvancedFilterForm';
import CheckboxLayout from '../../../../../../shared/web/uitheme/input/CheckboxLayout';
import AssignmentNameWithRoles from '../../../shared/AssignmentNameWithRoles';
import ErrorsWrapper from '../../../../../../shared/uibuilder/form/input/layout/ErrorsWrapper';

export interface AssigneeRowLayoutProps {
  name: string | ReactElement;
  isChecked: boolean;
  changeFunc: (value: boolean) => void;
}

export type AssigneeFilterValue = {
  selectAll: boolean,
  selected: number[]
};

export function AssigneeRowLayout(props: AssigneeRowLayoutProps) {

  const uiTheme = useUiTheme();

  return (
    <RelativeRow weights={[8, 4]} rowClassName={styles.row}>
      <span className={styles.label}>{props.name}</span>
      <Cell centered>
        <uiTheme.inputs.CheckboxLayout
          name="includedCheckbox"
          value={props.isChecked}
          onChangeCallback={() => {
            props.changeFunc(!props.isChecked);
          }}
          isVisible
          isDisabled={false}
        />
      </Cell>
    </RelativeRow>
  );
}

/**
 * Depending on where the hook is used it returns the assignments of the current project
 * (if it's used in the scope of project context) or it loads all the assignments.
 */
export function  useAssignmentsLoader() {
  const projectContext = useProjectContext();

  const projectAssignmentService = useProjectAssignmentsService(
    true,
  );

  const [assignments, setAssignments] = useState<ContactAssignments[]>(
    projectContext.assignments || [],
  );

  useEffect(() => {
    const loadAssignments = async () => {
      if (projectContext.assignments) {
        setAssignments(
          projectContext.assignments,
        );
      } else {
        const allAssignments = await projectAssignmentService.getActiveProjectsAssignments();

        setAssignments(
          allAssignments
            .filter((v) => (v.profile.teamRole && [
              TeamRole.OWNER,
              TeamRole.ADMINISTRATOR,
              TeamRole.MEMBER,
            ].includes(v.profile.teamRole))),
        );
      }
    };

    loadAssignments();
  }, [projectContext.assignments?.length || 0]);

  return { assignments };
}

export function AssigneeFilterForm() {
  const formContext = useFormContext<AdvancedFilterData>();

  const filterData = formContext.data!.assigneesFilter || [];

  const { assignments: allTaskAssignees } = useAssignmentsLoader();

  function isAssigned(userId: number) {
    if (filterData.selectAll) {
      return true;
    } else {
      return !!filterData.selected.find((id) => id === userId);
    }
  }

  function selectAssignee(userId: number) {
    if (!isAssigned(userId)) {
      let newValue = {
        selected: [...filterData.selected],
        selectAll: false,
      };
      newValue.selected.push(userId);

      if (newValue.selected.length === allTaskAssignees.length) {
        newValue = {
          selectAll: true,
          selected: [],
        };
      }

      formContext.onChangeCallback!({
        assigneesFilter: newValue,
      });
    }
  }

  function unselectAssignee(userId: number) {
    if (isAssigned(userId)) {
      let currentlySelected;

      if (filterData.selected && filterData.selected.length > 0) {
        currentlySelected = filterData.selected;
      } else {
        currentlySelected = allTaskAssignees
          .map((a) => a.personId!);
      }

      if (currentlySelected.length > 1) {
        const indexOf = currentlySelected.findIndex((item) => item === userId);

        const newValue = {
          selected: [...currentlySelected],
          selectAll: false,
        };
        newValue.selected.splice(indexOf, 1);

        formContext.onChangeCallback!({
          assigneesFilter: newValue,
        });
      }
    }
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function SelectAllControl() {
    return (
      <div className={styles.selectAllContainer}>
        <CheckboxLayout
          name="selectAll"
          value={filterData.selectAll}
          onChangeCallback={() => {
            if (formContext.data!.assigneesFilter.selectAll) {
              formContext.onChangeCallback!({
                assigneesFilter: {
                  selectAll: false,
                  selected: [],
                },
              });
            } else {
              formContext.onChangeCallback!({
                assigneesFilter: {
                  selectAll: true,
                  selected: [],
                },
              });
            }
          }}
          label="Include All"
          isVisible
          isDisabled={false}
        />
      </div>
    );
  }

  return (
    <ErrorsWrapper name="assigneesFilter">
      <>
        <div className={styles.titleRow}>
          <h4>TASKS ASSIGNED TO</h4>
          <SelectAllControl />
        </div>
        <RelativeRow weights={[8, 4]} rowClassName={`${styles.row} ${styles.header}`}>
          <EmptyCell />
          <Cell centered>Included</Cell>
        </RelativeRow>
        {allTaskAssignees
          .filter((v) => (v.profile.teamRole && v.isAssignable && [
            TeamRole.OWNER,
            TeamRole.ADMINISTRATOR,
            TeamRole.MEMBER].includes(v.profile.teamRole)))
          .map((assignment) => {

            const personId = assignment.personId!;

            return (
              <AssigneeRowLayout
                name={<AssignmentNameWithRoles assignment={assignment} />}
                isChecked={isAssigned(personId!)}
                changeFunc={(value: boolean) => {
                  if (value) {
                    selectAssignee(personId);
                  } else {
                    unselectAssignee(personId);
                  }
                }}
              />
            );
          })}
      </>
    </ErrorsWrapper>
  );
}
