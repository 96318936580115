import React from 'react';
import { Text } from './Text';
import { ReactElement } from 'react';
import { TextStyle } from 'react-native';

function format(value: string, pattern: string) {
  let i = 0;
  const v = value.toString();
  // eslint-disable-next-line no-plusplus
  return pattern.replace(/#/g, () => v[i++]);
}

function formatNumber(sourceValue: string): string {
  if (/^[0-9]{10}$/.test(sourceValue)) {
    return format(sourceValue, '(###) ###-####');
  } else {
    return sourceValue;
  }
}

export function formatPhoneNumber(
  number?: string,
  extension: string | undefined = undefined,
  noDataMessage = '-',
): string {
  if (number) {
    if (extension) {
      return `${formatNumber(number)} ext. ${extension}`;
    } else {
      return formatNumber(number);
    }
  } else {
    return noDataMessage;
  }
}

export function PhoneNumberView(
  props: {
    style?: TextStyle
    number?: string,
    extension?: string,
    noNumberText?: string | ReactElement,
  }
) {
  return (
    <Text style={props.style}>
      {formatPhoneNumber(props.number, props.extension, props.noNumberText as string)}
    </Text>
  );
}
