import {
  EmailRecipientType,
  EmailTask,
} from 'nekst-api';
import React, { useState } from 'react';
import { LoadingAnimation } from 'ui-builder';
import useDataLoader from 'data-loader';
import { EmailPreviewResponse, useEmailPreviewService } from './emailPreviewService';
import Layout from './Layout';

export function EmailPreview(
  props: {
    task: EmailTask,
  }
) {
  const task = props.task;

  const [emailPreview, setEmailPreview] = useState<EmailPreviewResponse>();

  const previewService = useEmailPreviewService();

  useDataLoader(
    async () => previewService.getEmailPreview(task.id),
    (response: EmailPreviewResponse) => {
      setEmailPreview(response);
    },
  );

  let sendFrom: string;

  if (!task._sender) {
    sendFrom = 'you';
  } else {
    sendFrom = task._sender.name.fullName;
  }

  const recipients = task.recipients.filter(
    (item) => item.type === EmailRecipientType.RECIPIENT && item._profile,
  );

  const cc = task.recipients.filter(
    (item) => item.type === EmailRecipientType.CC && item._profile,
  );

  const bcc = task.recipients.filter(
    (item) => item.type === EmailRecipientType.BCC && item._profile,
  );

  if (emailPreview) {
    return (
      <Layout
        preview={emailPreview}
        recipients={recipients}
        cc={cc}
        bcc={bcc}
        taskName={task._renderedName}
        projectName={task._project.name}
        sendFromName={sendFrom}
      />
    );
  } else {
    return (
      <LoadingAnimation />
    );
  }

}
