/* istanbul ignore file */
import React from 'react';
import { Button, ButtonColor, ButtonSize, usePopupManager } from 'ui-builder';

/* eslint-disable react/no-unused-prop-types */
export interface ClosePopupButtonProps {
  text?: string;
  size?: ButtonSize;
  color?: ButtonColor;
  styles?: string[];
  isDisabled?: boolean;
}

export function ClosePopupButton(props: ClosePopupButtonProps) {
  const text = props.text || 'Go Back';
  const popupManager = usePopupManager();

  const size = props.size || ButtonSize.BIG;

  const color = props.color || ButtonColor.GRAY;

  return (
    <Button
      text={text}
      onClick={async () => popupManager.closePopup()}
      size={size}
      color={color}
    />
  );
}
