import React from 'react';
import { MassCompleteErrorAction, useTasksCompletionService } from 'nekst-api';
import {
  Button,
  ButtonColor,
  ButtonSize,
  isReactNative,
  useMassUpdateServiceRequired,
  usePopupManager,
} from 'ui-builder';

export default function ForceMassCompleteButton() {
  const tasksCompletionService = useTasksCompletionService();

  const massUpdateService = useMassUpdateServiceRequired();

  const checkedIds = massUpdateService.getCheckedIds();

  const popupManager = usePopupManager();

  const submit = async () => {
    await tasksCompletionService.massComplete(checkedIds, MassCompleteErrorAction.FORCE);
    popupManager.closePopup();
  };

  return (
    <Button

      text={isReactNative() ? "Complete" : "Do Not Send SMS & Complete"}
      color={ButtonColor.YELLOW}
      onClick={submit}
      size={isReactNative() ? ButtonSize.MEDIUM : ButtonSize.BIG}
    />
  );
}
