import React, { ReactElement } from 'react';
import Text from '../../../../shared/uibuilder/form/input/Text';
import Row3x3x6x from '../../../../shared/web/uitheme/form/Row3x3x6x';
import Checkbox from '../../../../shared/uibuilder/form/input/Checkbox';
import RelativeRow from '../../../../shared/web/uitheme/form/RelativeRow';
import Separator from '../../../../shared/web/uitheme/form/Separator';
import SmsRecipientsInput from './SmsRecipientsInput';
import { ValidationSchema } from '../../../../shared/uibuilder/validation';
import SmsSmartTagsWysiwyg from '../../../../shared/widgets/SmartTags/SmsSmartTagsWysiwyg';
import { useTasksSmartTagsHelper } from '../../../../shared/smarttags/SmartTagsContext';
import SmsSmartTagsContext from '../../../../shared/smarttags/SmsSmartTagsContext';
import { useProjectTypeAndSetIdContextRequired } from '../../../view/ViewPlan';
import {
  DueDateConfigInput,
  PlanParentTaskRelationInput,
} from 'features/tasks-forms';
import { AfterSubmitFunc, FormTemplate, SubmitFormFunc } from 'ui-builder';
import { PlanTask } from 'nekst-api';
import { PlanPermission, useAuthorizationChecker } from 'authorization-scope';

export interface BaseSmsTaskFormProps {
  title: string,
  submitFormFunc: SubmitFormFunc<PlanTask>,
  afterSubmitFunc?: AfterSubmitFunc,
  validationSchema: ValidationSchema,
  buttons?: ReactElement,
  initialData?: PlanTask
  data?: PlanTask
}

function SmsTaskForm(props: BaseSmsTaskFormProps) {
  const { isGranted } = useAuthorizationChecker();

  const { formData } = useTasksSmartTagsHelper(props.data);

  return (
    <FormTemplate<PlanTask>
      getDataFunc={formData ? async () => formData : undefined}
      submitFormFunc={props.submitFormFunc}
      afterSubmitFunc={props.afterSubmitFunc}
      validationSchema={props.validationSchema}
      title={props.title}
      buttons={props.buttons}
      initialData={props.initialData}
      isDisabled={!isGranted(PlanPermission.UPDATE)}
    >
      <Text source="name" label="Task Name" />
      <Row3x3x6x>
        <Checkbox source="isKeyDate" label="Key Date" />
        <Checkbox source="isPrivate" label="Private" />
        <Checkbox source="isDuringBusinessWeek" label="Only During Business Week" />
        <Checkbox source="isAutoSent" label="Autosend" />
      </Row3x3x6x>
      <RelativeRow
        weights={[7, 1, 4]}
        rowClassName="form-row"
        isVisible={(data) => !data?.parentTaskRelation}
      >
        <DueDateConfigInput source="dueDateConfig" label="Due" />
      </RelativeRow>
      <RelativeRow
        weights={[8, 4]}
        rowClassName="form-row"
        isVisible={(data) => !!data?.parentTaskRelation}
      >
        <PlanParentTaskRelationInput source="parentTaskRelation" label="Due" />
      </RelativeRow>
      <Separator />
      <SmsSmartTagsWysiwyg source="description" label="SMS Text" />
      <Separator />
      <SmsRecipientsInput />
    </FormTemplate>
  );
}

export default function BaseSmsTaskForm(props: BaseSmsTaskFormProps) {
  const planContext = useProjectTypeAndSetIdContextRequired();
  return (
    <SmsSmartTagsContext projectType={planContext.projectType}>
      <SmsTaskForm
        {...props}
      />
    </SmsSmartTagsContext>
  );
}
