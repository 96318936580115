import React from 'react';
import { ButtonColor, ButtonLayoutProps, ButtonSize } from 'ui-builder';
import cssStyles from './Button.module.scss';

export default function ButtonLayout(props: ButtonLayoutProps) {
  const isDisabled = () => {
    return !!props.isDisabled;
  };

  const color = props.color || ButtonColor.GREEN;
  const size = props.size || ButtonSize.MEDIUM;

  const stylesValues = [];

  stylesValues.push(`btn-${size.toLowerCase()}`);
  stylesValues.push(`btn-${color}`);
  if (props.isFilled) {
    stylesValues.push('btn-filled');
  }

  if (props.isFlat) {
    stylesValues.push('btn-flat');
  }

  if (props.isDisabled) {
    stylesValues.push('btn-disabled');
  }

  if (props.iconType) {
    stylesValues.push(`btn-${props.iconType}`);
  }

  return (
    <button
      type={props.isSubmitButton ? 'submit' : 'button'}
      className={`${cssStyles.button} ${stylesValues.join(' ')} ${props.customClass || ''} ${props.isLoading ? cssStyles.loading : ''}`}
      onClick={(event) => {
        event.preventDefault();
        if (props.onClick) {
          props.onClick();
        }
      }}
      value={props.text}
      disabled={isDisabled()}
      ref={props.elementRef}
      title={props.title}
      data-key={props.dataKey}
      data-test={props.dataKey}
    >
      {props.isLoading && (
        <i
          className="fa fa-spinner fa-spin"
          style={{
            marginLeft: '0.5rem',
            marginRight: '0.5rem',
            display: 'inline-block',
          }}
        />
      )}
      {props.text}
    </button>
  );
}
