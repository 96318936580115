import React from 'react';
import Button, { ButtonColor, ButtonSize, OnClickFunc } from '../../shared/web/button/Button';
import Left from '../../shared/web/Left';
import Right from '../../shared/web/Right';

export interface ButtonProps {
  onClick: OnClickFunc,
}

export function PreviousButton(props: ButtonProps) {
  return (
    <Left>
      <Button
        text=" < &nbsp;Previous"
        size={ButtonSize.BIG}
        isFilled
        onClick={props.onClick}
        color={ButtonColor.BLUE}
      />
    </Left>
  );
}

export function NextButton(props: ButtonProps) {
  return (
    <Right>
      <Button
        text="Next &nbsp;> "
        size={ButtonSize.BIG}
        isFilled
        color={ButtonColor.BLUE}
        onClick={props.onClick}
      />
    </Right>
  );
}
