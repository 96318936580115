import React, { ReactElement, useState } from 'react';

import styles from './DetailsFieldsList.module.scss';

import {
  useProjectDetailsService,
  PROJECT_DETAILS_UPDATED,
  ProjectDetailsFieldType,
  ProjectDetailsFieldValue, ShortTaskDto, TeamPermission,
} from 'nekst-api';
import { DateField, List, ListDataRefresher, useShowContext } from 'ui-builder';
import InnerBlock from '../../shared/web/layout/InnerBlock';
import PriceField from '../../shared/uibuilder/show/field/PriceField';
import Right from '../../shared/web/Right';
import OverflowHidden from '../../shared/widgets/OverflowHidden';
import { useProjectContext } from '../view/ProjectContext';
import useSettingsNavigator from '../../settings/settingsNavigator';
import { useOpenUpdateFormHelper } from '../view/tasks/CreateUpdate/OpenUpdateFormField';
import useProjectTasksService from '../view/tasks/projectTasksService';
import LoadingAnimation from '../../shared/LoadingAnimation';
import { RowRepeater } from 'features/nekst-widgets';
import { EditDetailsButton, ParseDataFieldWidget } from 'features/tasks-forms';
import { useAuthorizationChecker } from 'authorization-scope';

interface Props {
  projectId: number;
  hideEditDetailsBoxedLink?: boolean;
  hideTitle?: boolean;
}

interface DetailsFieldListRowLayoutProps {
  title: string;
  afterTitle?: ReactElement;
  value: string | ReactElement | null;
  linkedTask?: ShortTaskDto,
}

function LinkedTaskIcon(props: {
  task: ShortTaskDto
}) {
  const openUpdateHelper = useOpenUpdateFormHelper();
  const projectTaskService = useProjectTasksService();

  const [loading, setLoading] = useState(false);

  const openTask = async () => {
    if (!loading) {
      setLoading(true);
      try {
        const task = await projectTaskService.getTask(props.task.id);
        openUpdateHelper.openForm(task);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <i
      className={[styles.linked, loading ? styles.loading : ''].join(' ')}
      onClick={openTask}
    >
      {loading && <LoadingAnimation />}
      &nbsp;
    </i>
  );
}

function DetailsFieldListRowLayout(props: DetailsFieldListRowLayoutProps) {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <div className={styles.inner}>
      <div className={styles.title}>
        {props.title}
        {props.afterTitle}
      </div>
      <div className={styles.value}>
        {props.value || '-'}
        {props.linkedTask && (
          <div className={styles.linkedTaskName}>{`(${props.linkedTask.name})`}</div>
        )}
      </div>
      {props.linkedTask && (<LinkedTaskIcon task={props.linkedTask} />)}
    </div>
  );
}

function DetailsFieldsListRow() {
  const showContext = useShowContext<ProjectDetailsFieldValue>();

  const data = showContext.data!;

  let value;

  switch (data.setting.fieldType) {
    case ProjectDetailsFieldType.DATE:
      value = (
        <DateField
          prefix={data.approximate ? 'approx.' : undefined}
          source="value"
          noValueText={data.linkedTask ? 'No Due Date' : '-'}
        />
      );
      break;
    case ProjectDetailsFieldType.PRICE:
      value = data.value ? <PriceField source="value" /> : null;
      break;
    default:
      value = data.value as string;
  }
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <DetailsFieldListRowLayout
      title={data.setting.name}
      afterTitle={<ParseDataFieldWidget source={`detailsFields.${data.setting.id}`} />}
      value={value}
      linkedTask={data.linkedTask}
    />
  );
}

function ManageDetailsBoxesListLink() {
  const projectContext = useProjectContext();

  const projectType = projectContext.data?.type;

  const settingsNavigator = useSettingsNavigator();

  const { isGranted } = useAuthorizationChecker();

  if (isGranted(TeamPermission.MANAGE_PROJECT_DETAILS_FIELDS)) {
    if (projectType) {
      return (
        <OverflowHidden>
          <Right>
            <a
              style={{ fontSize: '1.3rem' }}
              href={settingsNavigator.getProjectDetailsLink(projectType)}
              onClick={(event) => {
                event.preventDefault();
                settingsNavigator.openProjectDetailsFieldPage(projectType);
              }}
            >
              Create/Edit Detail Boxes
            </a>
          </Right>
        </OverflowHidden>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export default function DetailsFieldsList(props: Props) {
  const projectDetailsService = useProjectDetailsService();

  return (
    <InnerBlock
      dataKey="edit-details"
      title={!props.hideTitle ? 'Important Dates and Details' : ''}
      headerRight={(
        <EditDetailsButton projectId={props.projectId} />
      )}
    >
      {!props.hideEditDetailsBoxedLink ? <ManageDetailsBoxesListLink /> : null}
      <List
        getDataFunc={async () => projectDetailsService.getProjectDetails(props.projectId)}
      >
        <ListDataRefresher eventName={PROJECT_DETAILS_UPDATED} />
        <RowRepeater
          idField="setting.id"
          blockClassName={styles.list}
          groupClassName={(data) => {
            let result = styles.item;

            if (data.setting.fieldType === ProjectDetailsFieldType.LONG_TEXT) {
              result += ` ${styles.long}`;
            }

            return result;
          }}
        >
          <DetailsFieldsListRow />
        </RowRepeater>
      </List>
    </InnerBlock>
  );
}
