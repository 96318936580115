import React from 'react';
import DatePicker from 'react-datepicker';
import { DateInputLayoutProps } from 'ui-builder';

import 'react-datepicker/dist/react-datepicker.css';
import './DateInputLayout.module.scss';
import useUiTheme from '../../../uiTheme';

function DateInputLayout(props: DateInputLayoutProps) {
  const uiTheme = useUiTheme();

  let value;

  if (props.value) {
    if (typeof props.value === 'string') {
      value = new Date(props.value);
    } else {
      value = props.value as Date;
    }
  } else {
    value = null;
  }

  return (
    <uiTheme.forms.BaseInputLayout
      label={props.label}
      afterLabelElement={props.afterLabelElement}
      name={props.name}
      errors={props.errors}
      hideLabel={props.hideLabel}
      showRequiredIcon={props.showRequiredIcon}
    >
      <DatePicker
        placeholderText={props.placeholder}
        selected={value}
        disabled={props.isDisabled}
        onChange={(date: Date | null) => {
          props.onChangeCallback({
            target: {
              value: date,
            },
          });
        }}
        minDate={props.minDate}
      />
    </uiTheme.forms.BaseInputLayout>
  );
}

export default DateInputLayout;
