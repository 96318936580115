import {
  Address,
  Email,
  Name,
  PhoneNumber,
  Url,
} from './commonTypes';
import { ProjectAssignment } from './projectModuleTypes';
import { FieldName } from 'ui-builder';
import { ServiceProviderGroup } from '../serviceProviderService';

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum TeamRole {
  CONTACT = 'CONTACT',
  OWNER = 'OWNER',
  ADMINISTRATOR = 'ADMINISTRATOR',
  MEMBER = 'MEMBER',
  INVITED_USER = 'INVITED_USER',
}

export function isTeamMember(role: TeamRole) {
  return [TeamRole.ADMINISTRATOR, TeamRole.OWNER, TeamRole.MEMBER].includes(role);
}

export function getTeamRoleName(role: TeamRole) {
  switch (role) {
    case TeamRole.CONTACT:
      return 'Contact';
    case TeamRole.OWNER:
      return 'Team Owner';
    case TeamRole.ADMINISTRATOR:
      return 'Team Administrator';
    case TeamRole.MEMBER:
      return 'Team Member';
    case TeamRole.INVITED_USER:
      return 'Invited User';
    default:
      return '';
  }
}

export type ShortRole = {
  id: number,
  name: string,
  isAssignable: boolean,
};

export type ShortProfile = {
  id: number,
  name: Name,
  primaryEmail?: Email,
  website?: undefined,
  companyName?: string,
  phoneNumbers: PersonPhoneNumber[],
  teamRole?: TeamRole
  _phoneNumberForSms?: PersonPhoneNumber
  _avatarUrl?: {
    resized: string,
    full: string,
  }
}

export enum PersonPhoneNumberType {
  WORK = 'WORK',
  HOME = 'HOME',
  MOBILE = 'MOBILE',
  FAX = 'FAX'
}

export enum OptInStatus {
  NOT_SENT = 'NOT_SENT',
  WAITING_FOR_CONSENT = 'WAITING_FOR_CONSENT',
  CONSENT_RECEIVED = 'CONSENT_RECEIVED',
  OPTED_OUT = 'OPTED_OUT'
}

export type PersonPhoneNumber = {
  id: number,
  number: string
  extension?: string,
  type: PersonPhoneNumberType,
  optInStatus?: OptInStatus
  isPrimary: boolean
  isUsedForSms: boolean
  optInSendingLockedTill?: Date,
  order?: number,
}

export type PersonProfile = {
  name: Name,
  primaryEmail: Email,
  alternateEmail?: Email,
  companyName?: string,
  address?: Address,
  primaryPhoneNumber?: PersonPhoneNumber,
  phoneNumbers: PersonPhoneNumber[],
  alternatePhone?: PhoneNumber,
  fax?: PhoneNumber,
  website?: Url,
  emailSignature?: string

  _avatarUrl?: {
    resized: string,
    full: string,
  }
}

export type User = {
  id: number,
  isAccountActive: boolean,
  isDailyMailEnabled: boolean,
  canInvitationBeResent: boolean,
}

export type Person = {
  id: number,
  profile: PersonProfile,
  teamRole: TeamRole,
  hasActiveAccount: boolean,
  _assignments?: ProjectAssignment[]
  _user: User
  serviceProvidersIds: number[]
  _serviceProviders: ServiceProviderGroup[],
};

export enum IntroTourStatus {
  CANCELED = 'CANCELED',
  PASSED = 'PASSED',
  NOT_PASSED = 'NOT_PASSED',
}

export interface AuthenticatedPerson extends Person {
  teamId: number,
  _crispEmailSignature: string,
  _crispTokenId: string
  _userListToken: string
  _introTours: Record<string, IntroTourStatus>
}

export type Limit = {
  limit: number,
  offset: number,
}

export type OrderBy = {
  field: FieldName,
  direction: OrderDirection,
}

export interface PageResponse<DataType> {
  data: DataType[],
  total: number
  limit: Limit
}
