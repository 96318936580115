import React from 'react';
import { SubmitButton, AfterSubmitFunc } from 'ui-builder';
import { ProjectType } from 'nekst-api';
import useProjectTasksService, { AbstractTask, SmsTask } from '../../projectTasksService';
import BaseSmsTaskForm from './BaseSmsTaskForm';
import SmartTagsUpdater from '../../../../../plans/tasks/CreateUpdate/SmartTagsUpdater';

export interface CreateEmailTaskFormProps {
  afterSubmitFunc?: AfterSubmitFunc;
  parentTask?: AbstractTask;
  projectId?: number;
  projectType?: ProjectType;
}

export default function CreateSmsTaskForm(props: CreateEmailTaskFormProps) {
  const projectTasksService = useProjectTasksService();

  let initialData = {
    projectId: props.projectId,
  } as SmsTask;

  if (props.parentTask) {
    initialData = {
      ...initialData,
      parentTaskRelation: {
        parentTaskId: props.parentTask.id,
        parentTaskName: props.parentTask.name,
      },
    } as SmsTask;
  }

  return (
    <BaseSmsTaskForm
      submitFormFunc={
        (formData) => {
          return projectTasksService.createSmsTask(formData.projectId, formData);
        }
      }
      title="Add SMS Task"
      buttons={<SubmitButton text="Save task" key="save" />}
      afterSubmitFunc={props.afterSubmitFunc}
      initialData={initialData}
      showProjectDropdown={!props.projectId}
      projectType={props.projectType}
    >
      <SmartTagsUpdater />
    </BaseSmsTaskForm>
  );
}
