import React from 'react';
import { BaseInputProps, useInputHelper } from 'ui-builder';
import Layout from './Layout';

interface ColorPickerProps extends BaseInputProps {

}

export function ColorPicker(props: ColorPickerProps) {

  const inputHelper = useInputHelper(props);

  return (
    <Layout
      {...inputHelper.getBaseInputLayoutProps()}
    />
  )
}
