import usePlansApi from '../../plansApi';

export default function useMassDeleteService() {
  const plansApi = usePlansApi();

  const massDelete = async (ids: number[]) => {
    await plansApi.put('/plantasks/bulk/delete', {
      ids,
    });
  };
  return {
    massDelete,
  };
}
