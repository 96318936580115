import React from 'react';
import { useShowContext } from 'ui-builder';
import { Project } from 'nekst-api';
import { ShowMoreTextField } from '../../../shared/widgets/ShowMoreTextWrapper';

export default function TextDetailsField(
  props: {
    // eslint-disable-next-line react/no-unused-prop-types
    label: string,
    // eslint-disable-next-line react/no-unused-prop-types
    sortName?: string,
    fieldId: number,
  },
) {
  const context = useShowContext<Project>();

  const details = context.data!.statistic!.details;

  const field = details
    .find((d) => d.setting.id === props.fieldId);

  const result = field?.value as string || '';

  if (result) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return (
      <ShowMoreTextField
        source={props.fieldId.toString()}
        value={result}
        maxLength={40}
      />
    );
  } else {
    return <>-</>;
  }
}
