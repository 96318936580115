import { AbstractTask } from 'nekst-api';

function compareTasks(task1: AbstractTask, task2: AbstractTask) {
  if (!task1.dueDate?.date && task2.dueDate?.date) {
    return 1;
  } else if (task1.dueDate?.date && !task2.dueDate?.date) {
    return -1;
  } else if (!task1.dueDate?.date && !task2.dueDate?.date) {
    let compareResult = 0;

    if (task1.parentTaskRelation?.parentTaskId && task1.parentTaskRelation.parentTaskId === task2.parentTaskRelation?.parentTaskId) {
      compareResult = task1.parentTaskRelation.days - task2.parentTaskRelation.days;
    }

    if (compareResult !== 0) {
      return compareResult;
    } else {
      if (task1.order !== task2.order) {
        return task1.order - task2.order;
      } else {
        return task1.name.localeCompare(task2.name);
      }
    }
  } else {

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const result = task1.dueDate!.date.getTime() - task2.dueDate!.date.getTime();
    if (result !== 0) {
      return result;
    } else if (task1.order !== task2.order) {
      return task1.order - task2.order;
    } else {
      return task1.name.localeCompare(task2.name);
    }
  }
}

export function sortTasksIncludingDependentTasks(tasks: AbstractTask[]): AbstractTask[] {

  const newTasks = [...tasks].sort(compareTasks);

  const dependentTasksMap: Record<number, AbstractTask[]> = {};

  newTasks.forEach((task) => {
    const parentTaskId = task.parentTaskRelation?.parentTaskId;
    if (parentTaskId) {
      dependentTasksMap[parentTaskId] = [
        ...dependentTasksMap[parentTaskId] || [],
        task,
      ];
    }
  });

  const usedIdsMap: Record<number, true> = {};

  const getSortedDependentTasks = (parentTaskId: number, dependencyLevel: number) => {
    const result: AbstractTask[] = [];
    if (dependentTasksMap[parentTaskId]) {
      dependentTasksMap[parentTaskId].forEach((item) => {
        if (!usedIdsMap[item.id]) {
          item._dependencyLevel = dependencyLevel;
          usedIdsMap[item.id] = true;
          result.push(item);
          result.push(...getSortedDependentTasks(item.id, dependencyLevel + 1));
        }
      });
    }

    return result;
  };

  const result: AbstractTask[] = [];

  newTasks
  .filter((item) => !item.parentTaskRelation?.parentTaskId)
  .forEach((rootTask) => {
    rootTask._dependencyLevel = 0;
    result.push(rootTask);
    result.push(...getSortedDependentTasks(rootTask.id, 1));
  });

  return result;
}
