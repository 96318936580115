import React, { createContext, useState } from 'react';
import { ProjectContractParseResult, useAiLauncherService } from 'nekst-api';
import useDataLoader from 'data-loader';
import { LoadingAnimation } from 'ui-builder';

export type ParseContractContextData = {
  parseResult: ProjectContractParseResult,
  removeContact: (internalId: number) => Promise<void>,
}

const ParseContractDataReactContext = createContext<ParseContractContextData | undefined>(undefined);

export function useParseContractContext() {
  return React.useContext(ParseContractDataReactContext);
}

export function useParseContractContextRequired() {
  const context = useParseContractContext();
  if (!context) {
    throw new Error('useParseContractContextRequired must be used within a ParseContractDataContext');
  }
  return context;
}

export function ParseContractDataContext(
  props: {
    children: React.ReactNode;
    projectId: number,
  }
) {
  const [parseResult, setParseResult] = useState<ProjectContractParseResult>();

  const aiLauncherService = useAiLauncherService();

  useDataLoader(
    () => aiLauncherService.getContractParseResult(props.projectId),
    setParseResult,
  );

  const removeContact = async (internalId: number) => {
    if (parseResult) {
      await aiLauncherService.removeContact(props.projectId, internalId);

      const newPeople = parseResult.people.map((item) => {
        if (item.internalId === internalId) {
          return {
            ...item,
            processed: true
          };
        } else {
          return item;
        }
      });

      setParseResult({
        ...parseResult,
        people: newPeople,
      });
    }
  };

  if (parseResult) {
    return (
      <ParseContractDataReactContext.Provider
        value={{
          parseResult,
          removeContact,
        }}
      >
        {props.children}
      </ParseContractDataReactContext.Provider>
    );
  } else {
    return (<LoadingAnimation />);
  }

}
