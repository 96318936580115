import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { CheckboxGroup, ClearOnDestruct, Option, useFormContext } from 'ui-builder';
import LoadingAnimation from '../../shared/LoadingAnimation';
import MultiDropdown from '../../shared/uibuilder/form/input/MultiDropdown';
import { useServiceProvidersContext } from './ServiceProvidersContext';
import usePeopleService from '../../people/peopleService';
import styles from './ClientPortalConfigurationForm.module.scss';
import { ServiceProviderLink } from './clientPortalConfigurationService';

function ServiceProvidersCheckboxesGroup(
  props: {
    serviceProviderId: number,
    source: string,
    label: string
  },
) {
  const [options, setOptions] = useState<Option<number>[]>();

  const peopleService = usePeopleService();

  async function loadOptions() {
    const people = await peopleService.getPeople(
      {
        serviceProvidersGroupsIds: [props.serviceProviderId],
      },
      {
        limit: 100,
        offset: 0,
      },
    );

    setOptions(people.data.map(
      (person) => {
        let label = person.profile.name.fullName;

        if (person.profile.companyName) {
          label += `, ${person.profile.companyName}`;
        }

        return {
          label,
          value: person.id,
        };
      },
    ));
  }

  useEffect(() => {
    loadOptions();
  }, []);

  if (options) {
    return (
      <>
        <CheckboxGroup
          className={styles.serviceProvidersCheckboxesGroup}
          options={options}
          label={props.label}
          source={`${props.source}`}
        />
        <ClearOnDestruct source={props.source} />
      </>
    );
  } else {
    return <LoadingAnimation />;
  }
}

export function useServiceProvidersCheckboxesGroupMapper() {
  const toApiView = (data: Record<number, number[]>) => {
    const result: ServiceProviderLink[] = [];

    Object.entries(data)
      .filter(([, ids]) => !!ids)
      .forEach(([providerIdKey, peopleIds]) => {
        const providerId = parseInt(providerIdKey, 10);

        peopleIds.forEach((newPersonId) => {
          result.push({
            serviceProviderGroupId: providerId,
            personId: newPersonId,
          });
        });
      });

    return result;
  };

  const reduceServiceProvidersFunc = (
    prevServiceProvider: Record<number, number[]>,
    link: ServiceProviderLink,
  ) => {
    const newResult = { ...prevServiceProvider };

    if (!newResult[link.serviceProviderGroupId]) {
      newResult[link.serviceProviderGroupId] = [];
    }

    newResult[link.serviceProviderGroupId].push(link.personId);

    return newResult;
  };

  const toFormView = (data: ServiceProviderLink[]) => {
    return data
      .reduce(reduceServiceProvidersFunc, {});
  }

  return {
    toApiView,
    toFormView,
  }
}

export default function ServiceProvidersGroupSelector(
  props: {
    source: string,
    label?: string,
  },
) {
  const [selectedProviders, setSelectedProviders] = useState<number[]>();
  const [init, setInit] = useState(false);

  const formContext = useFormContext<FormData>();
  useEffect(() => {
    if (formContext.data && !init) {
      const data = get(formContext.data!, props.source) || {};
      const result = Object.keys(data)
        .filter((item) => !!data[item])
        .map((item) => parseInt(item, 10));

      setSelectedProviders(result);
      setInit(true);
    }
  }, [formContext.data]);

  const serviceProvidersContext = useServiceProvidersContext();

  if (!serviceProvidersContext.loading) {
    if (serviceProvidersContext.providers.length) {
      return (
        <>
          <MultiDropdown
            source="service"
            label={props.label}
            value={selectedProviders}
            options={serviceProvidersContext.options}
            onChangeCallback={(data) => {
              setSelectedProviders(data.service);
            }}
          />
          {selectedProviders?.map((provider) => {
            return (
              <ServiceProvidersCheckboxesGroup
                serviceProviderId={provider}
                source={`${props.source}.${provider}`}
                key={`${props.source}.${provider}`}
                label={serviceProvidersContext.getGroupById(provider)?.name || ''}
              />
            );
          })}
        </>
      );
    } else {
      return null;
    }
  } else {
    return (
      <LoadingAnimation />
    );
  }
}
