import React from 'react';
import { useNewUiTheme } from '../../newUiTheme';
import { useInputHelper } from './inputHelper';
import { BaseInputLayoutProps, BaseInputProps, InputLayoutIcon } from './types';

export interface TextAreaProps extends BaseInputProps {
  icon?: InputLayoutIcon
  small?: boolean,
}

export interface TextAreaLayoutProps extends BaseInputLayoutProps {
  icon?: InputLayoutIcon,
  small?: boolean,
}

export function TextArea(props: TextAreaProps) {
  const helper = useInputHelper(props);
  const uiTheme = useNewUiTheme();

  return (
    <uiTheme.inputs.TextAreaLayout
      {...helper.getBaseInputLayoutProps()}
      icon={props.icon}
      small={props.small}
    />
  );
}
