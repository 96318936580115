import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import styles from './EditDetailsForm.module.scss';
import { LinkedTaskField } from './LinkedTaskField';
import { ProjectPermission, useAuthorizationChecker } from 'authorization-scope';
import { usePopupFormManager } from 'features/nekst-widgets';
import {
  AfterSubmitFunc,
  Button,
  ButtonColor,
  ButtonSize,
  DateInput,
  FormTemplate,
  IconType,
  ifReactNative,
  InputLayoutIcon,
  isReactNative,
  NewThemeOverrider,
  PriceInput,
  RelativeRow,
  SimpleBlock,
  Text,
  TextArea,
  TextInput, useShowContextRequired,
} from 'ui-builder';
import {
  Project,
  ProjectDetailsFieldType,
  ProjectDetailsFieldValue,
  ShortTaskDto,
  useProjectDetailsService
} from 'nekst-api';
import { Spacings } from 'react-native-ui-lib';
import { AppSpacings, commonStyles } from 'ui-library-mobile-theme/commonStyles';

interface Props {
  projectId: number;
  afterSubmitFunc?: AfterSubmitFunc;
  cancelFunc?: () => Promise<void>;
}

enum FormFieldType {
  LINKED_TASK = 'linked_task',
  DATE = 'date',
  PRICE = 'price',
  TEXT = 'text',
  TEXTAREA = 'textarea'
}

type FormFieldConfiguration = {
  label: string,
  linkedTask?: ShortTaskDto,
  type: FormFieldType
  id: number
}

interface FormInputsBuilderProps {
  fields: FormFieldConfiguration[];
}

function FormInputsBuilder(props: FormInputsBuilderProps) {
  const result: ReactElement[] = props.fields.map(
    (fieldConfig) => {
      let input;
      const source = `fields.${fieldConfig.id}.value`;

      switch (fieldConfig.type) {
        case FormFieldType.DATE:
          input = (
            <DateInput
              source={source}
              label={fieldConfig.label}
              hideLabel
              dataKey={`field: ${fieldConfig.label}`}
              key={source}
            />
          );
          break;
        case FormFieldType.TEXTAREA:
          input = (
            <TextArea
              source={source}
              label={fieldConfig.label}
              hideLabel
              key={source}
              icon={InputLayoutIcon.TEXT}
              dataKey={`field: ${fieldConfig.label}`}
            />
          );
          break;
        case FormFieldType.PRICE:
          input = (
            <PriceInput
              source={source}
              label={fieldConfig.label}
              hideLabel
              key={source}
              icon={InputLayoutIcon.PRICE}
              dataKey={`field: ${fieldConfig.label}`}
            />
          );
          break;
        case FormFieldType.LINKED_TASK:
          input = (
            <LinkedTaskField
              fieldId={fieldConfig.id}
              label={fieldConfig.label}
              key={source}
              dataKey={`field: ${fieldConfig.label}`}
            />
          );
          break;
        default:
          input = (
            <TextInput
              source={source}
              label={fieldConfig.label}
              hideLabel
              key={source}
              icon={InputLayoutIcon.TEXT}
              dataKey={`field: ${fieldConfig.label}`}
            />
          );
      }

      return (
        <RelativeRow weights={ifReactNative([12], [3, 9])} rowClassName={styles.row} key={source}>
          <SimpleBlock className={styles.label}>
            <Text style={{ ...commonStyles.text, ...commonStyles.bold }}>{fieldConfig.label}</Text>
          </SimpleBlock>
          <SimpleBlock>
            {input}
          </SimpleBlock>
        </RelativeRow>
      );
    },
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {result}
    </>
  );
}

function mapType(fieldValue: ProjectDetailsFieldValue): FormFieldType {
  if (fieldValue.linkedTask) {
    return FormFieldType.LINKED_TASK;
  } else {
    const type = fieldValue.setting.fieldType;
    switch (type) {
      case ProjectDetailsFieldType.PRICE:
        return FormFieldType.PRICE;
      case ProjectDetailsFieldType.DATE:
        return FormFieldType.DATE;
      case ProjectDetailsFieldType.LONG_TEXT:
        return FormFieldType.TEXTAREA;
      case ProjectDetailsFieldType.SHORT_TEXT:
        return FormFieldType.TEXT;
      default:
        return FormFieldType.TEXT;
    }
  }
}

interface EditDetailsFormThemeProps {
  children: ReactElement | ReactElement[];
}

function SimpleInputLayout(props: { children: ReactNode }) {
  return props.children;
}

function EditDetailsFormTheme(props: EditDetailsFormThemeProps) {
  return (
    <NewThemeOverrider
      theme={{
        forms: {
          // @ts-ignore
          BaseInputLayout: SimpleInputLayout,
        },
      }}
    >
      {props.children}
    </NewThemeOverrider>
  );
}

function EditDetailsFormHeader() {
  if (!isReactNative()) {
    return (
      <RelativeRow weights={[3, 9]} rowClassName={styles.header}>
        <Text className={styles.columnName}>Field</Text>
        <Text className={styles.columnName}>Value</Text>
      </RelativeRow>
    );
  } else {
    return null;
  }
}

type EditDetailsFormDataType = {
  fields: Record<string, { value: string | number | Date }>
};

export function EditDetailsForm(props: Props) {
  const [fieldValues, setFieldValues] = useState<ProjectDetailsFieldValue[]>([]);

  const detailsService = useProjectDetailsService();

  useEffect(() => {
    async function loadDetails() {
      const values = await detailsService.getProjectDetails(props.projectId);
      setFieldValues(values);
    }

    loadDetails();
  }, []);

  const showContext = useShowContextRequired<Project>();

  if (fieldValues.length) {
    return (
      <EditDetailsFormTheme>
        <FormTemplate<EditDetailsFormDataType>
          title={`${showContext.data!.name} - Edit Fields`}
          submitFormFunc={
            async (data) => {
              const request: Record<string, string | number | Date> = {};

              Object.entries(data.fields)
                .forEach(([k, v]) => {
                  request[k] = v.value;
                });

              await detailsService.updateProjectDetails(props.projectId, request);

              return data;
            }
          }
          getDataFunc={async () => {
            const result: Record<string, {
              value: string | number | Date,
              linkedTask?: ShortTaskDto,
            }> = {};

            fieldValues.forEach((fieldValue) => {
              result[fieldValue.setting.id] = {
                value: fieldValue.value || '',
                linkedTask: fieldValue.linkedTask,
              };
            });

            return {
              fields: result,
            };
          }}
          afterSubmitFunc={props.afterSubmitFunc}
          cancelFunc={props.cancelFunc}
        >
          <EditDetailsFormHeader />
          <SimpleBlock
            className={styles.container}
            style={{
              gap: Spacings.s2,
              paddingHorizontal: AppSpacings.pageHorizontalPadding,
            }}
          >
            <FormInputsBuilder
              fields={
                fieldValues.map((fieldValue) => ({
                  label: fieldValue.setting.name,
                  id: fieldValue.setting.id,
                  linkedTask: fieldValue.linkedTask,
                  type: mapType(fieldValue),
                }))
              }
            />
          </SimpleBlock>
        </FormTemplate>
      </EditDetailsFormTheme>
    );
  } else {
    return null;
  }
}

EditDetailsForm.prototype.defaultProps = {
  afterSubmitFunc: undefined,
};

interface EditDetailsButtonProps {
  projectId: number;
}

export function EditDetailsButton(props: EditDetailsButtonProps) {
  const popupManager = usePopupFormManager();

  const { isGranted } = useAuthorizationChecker();

  if (isGranted(ProjectPermission.UPDATE_DETAILS)) {
    return (
      <Button
        text="Edit"
        size={ButtonSize.BIG}
        color={ButtonColor.BLUE}
        isFilled={false}
        iconType={IconType.PENCIL}
        onClick={
          async () => {
            popupManager.openForm(<EditDetailsForm projectId={props.projectId} />);
          }
        }
      />
    );
  } else {
    return null;
  }
}
