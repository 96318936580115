import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  SubmitButton,
} from 'ui-builder';
import {
  getSignUpValidationSchema,
} from 'features/auth-feature';
import {
  useSignUpService,
  SignUpRequest,
  SubscriptionCreationResultType,
} from 'nekst-api';

import styles from './SignUp.module.scss';
import Row6x6x from '../shared/web/uitheme/form/Row6x6x';
import FormTemplate from '../shared/uibuilder/form/FormTemplate';
import Text from '../shared/uibuilder/form/input/Text';
import FormRows from '../shared/uibuilder/form/FormRows';
import SubscriptionPriceInput from './SubscriptionPriceInput';
import ApplyRulesCheckbox from './ApplyRulesCheckbox';
import { Cell } from '../shared/web/uitheme/form/RelativeRow';
import Password from '../shared/uibuilder/form/input/Password';
import PageTitle from '../shared/web/PageTitle';
import useSettingsNavigator from '../settings/settingsNavigator';
import { Separator } from 'features/nekst-widgets';

function SignInNavigator() {

  const navigate = useNavigate();
  return (
    <div
      style={{ marginBottom: '2rem' }}
    >
      Have an account?
      {' '}
      <a
        href="/login"
        onClick={(event) => {
          event.preventDefault();
          navigate('/link');
        }}
      >
        Sign in.
      </a>
    </div>
  );
}

function getSuccessRedirectUrl() {
  const location = window.location;
  return `${location.protocol}//${location.hostname}:${location.port}/settings/profile`;
}

export default function SignUpPage() {
  const signUpService = useSignUpService();

  const settingsNavigator = useSettingsNavigator();

  return (
    <>
      <PageTitle text="Sign Up" />
      <h2>Sign Up</h2>
      <SignInNavigator />
      <FormTemplate<SignUpRequest>
        className={styles.form}
        submitFormFunc={async (data) => {
          const result = await signUpService.signUp(
            data,
            getSuccessRedirectUrl(),
            (window as any).Rewardful?.referral,
          );

          const flowType = result.subscriptionCreationResult.type;

          if (flowType === SubscriptionCreationResultType.SUBSCRIPTION_CREATED) {
            settingsNavigator.openUpdateProfilePage();
          } else {
            (window as any).location.href = result.subscriptionCreationResult.url;
          }

          return data;
        }}
        validationSchema={getSignUpValidationSchema()}
        buttons={null}
      >
        <Row6x6x>
          <SubscriptionPriceInput />
          <Cell className={styles.rightColumn}>
            <FormRows>
              <Row6x6x>
                <Text source="name.firstName" label="First Name" />
                <Text source="name.lastName" label="Last Name" />
              </Row6x6x>
              <Text source="email" label="Email" />
              <Text source="_email2" label="Repeat Email" />
              <Text source="companyName" label="Company" />
              <Row6x6x>
                <Password
                  source="password"
                  label="Password"
                  hint="Password strength: minimum 8 characters, including 1 uppercase, 1 number, and 1 special symbol."
                />
                <Password source="_password2" label="Repeat password" />
              </Row6x6x>
              <Separator />
              <ApplyRulesCheckbox />
              <SubmitButton text="Create Account" />
            </FormRows>
          </Cell>
        </Row6x6x>
      </FormTemplate>
    </>
  );
}
