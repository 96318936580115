import React, { useEffect, useMemo, useState } from 'react';
import {
  useListContext,
  useFormContext,
  useShowContext,
  ListDataRefresher,
} from 'ui-builder';
import {
  AbstractTask,
  CommentSeenStatus,
  TaskPermission,
  EMAIL_TASK_SENT,
  SMS_TASK_SENT,
  useTaskCommentsService,
  CommentRequest,
  CommentPermission,
  COMMENTS_UPDATED_EVENT,
  Comment, useProjectTaskUpdater, HistoryEntry,
} from 'nekst-api';

import AddCommentForm from '../../../../../shared/widgets/CommentsList/AddCommentForm';

import useCommentsSeenService from './commentsSeenService';
import {
  CommentsAndEventsList,
} from '../../../../../shared/widgets/CommentsList/CommentsList';
import useProjectTaskHistoryService from '../../projectTaskHistoryService';
import Record from '../../../../history/Record';
import { RowLayout } from '../../../../../shared/widgets/CommentsList/CommentRow';
import {
  RecordLayoutReactContext,
  WrapperLayoutProps,
} from '../../../../history/RecordLayout/RecordLayout';
import HistoryEventIcon from '../../../../history/RecordLayout/HistoryEventIcon';

export interface TaskCommentsListProps {
  taskId: number;
}

function CommentsSeenTracker() {
  const [tracked, setTracked] = useState<boolean>(false);

  const listContext = useListContext<Comment>();

  const commentSeenService = useCommentsSeenService();

  const formContext = useFormContext<AbstractTask>();

  const task = formContext.data!;

  const comments = listContext.data || [];

  useEffect(() => {
    if (!tracked && comments.length > 0 && task.commentSeenStatus) {
      commentSeenService.trackCommentsSeen(task.id);
      setTracked(true);

      task.commentSeenStatus = CommentSeenStatus.READ;
    }
  }, [listContext.data?.length]);

  return null;
}

function CommentsTitle() {
  const listContext = useListContext();

  let title = 'Comments & Details';

  if (!listContext.isLoading) {
    title += ` (${listContext.data!.length})`;
  }

  return (<h3>{title}</h3>);
}

function EventInTaskFormWrapper(props: WrapperLayoutProps) {
  return (
    <RowLayout
      icon={
        <HistoryEventIcon iconType={props.iconType} size="BIG" />
      }
      mainBlockContent={props.text}
      dateSource="when"
    />
  );
}

function TaskEventRow() {
  const showContext = useShowContext<HistoryEntry>();

  return (
    <Record event={showContext.data!} />
  );
}

export default function TaskCommentsList(props: TaskCommentsListProps) {
  const taskCommentsService = useTaskCommentsService();
  const taskHistoryService = useProjectTaskHistoryService();

  const formContext = useFormContext<AbstractTask>();

  const task = formContext.data;

  const contextValue = useMemo(() => ({
    layout: EventInTaskFormWrapper,
    isInTaskContext: true,
  }), []);

  const projectTaskUpdater = useProjectTaskUpdater();

  if (task?.id) {
    const hasPermission = (permission: TaskPermission) => {
      return task.permissions.includes(permission);
    };

    if (hasPermission(TaskPermission.READ_COMMENTS)) {
      const getDataFunc = async () => {
        const responses = await Promise.all([
          taskCommentsService.getTaskComments(props.taskId),
          taskHistoryService.getAllTasksEvents(props.taskId),
        ]);

        const comments = responses[0].map((item: Comment) => {
          return {
            ...item,
            _type: 'COMMENT',
            _when: item.createdAt,
          };
        });

        const events = responses[1].map((item: HistoryEntry) => ({
          ...item,
          _type: 'EVENT',
          _when: item.when,
        }));

        return [
          ...events,
          ...comments,
        ].sort((a, b) => {
          return b._when.getTime() - a._when.getTime();
        });
      };


      return (
        <RecordLayoutReactContext.Provider value={contextValue}>
          <CommentsAndEventsList<Comment, CommentRequest>
            getDataFunc={getDataFunc}
            title={<CommentsTitle />}
            updateItemFunc={async (id: number, data: CommentRequest) => {
              return await taskCommentsService.updateComment(
                id,
                data,
              ) as Comment;
            }}
            deleteItemFunc={async (id: number) => {
              await taskCommentsService.deleteComment(id);
              await projectTaskUpdater.publishCommentsUpdatedEvent(props.taskId);
            }}
            updatePermission={CommentPermission.UPDATE}
            deletePermission={CommentPermission.DELETE}
            messages={{
              textInputLabel: 'Enter Comment Here',
              noDataText: 'The task has no comments.',
            }}
            eventRow={<TaskEventRow />}
          >
            <>
              {hasPermission(TaskPermission.CREATE_COMMENT) && (
                <AddCommentForm<Comment, CommentRequest>
                  submitFunc={async (data: CommentRequest) => {
                    return await taskCommentsService.createComment(task!.id, data) as Comment;
                  }}
                  afterSubmitFunc={async () => {
                    await projectTaskUpdater.publishCommentsUpdatedEvent(props.taskId);
                  }}
                />
              )}
              <CommentsSeenTracker />
              <ListDataRefresher eventName={COMMENTS_UPDATED_EVENT} />
              <ListDataRefresher eventName={EMAIL_TASK_SENT} />
              <ListDataRefresher eventName={SMS_TASK_SENT} />
            </>
          </CommentsAndEventsList>
        </RecordLayoutReactContext.Provider>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
}
