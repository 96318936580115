import React from 'react';

import styles from './Buttons.module.scss';

export enum Direction {
  NEXT = 'NEXT',
  PREV = 'PREV',
}

export default function NavigationButton(
  props: {
    onClick: () => void,
    direction: Direction
  },
) {
  let text;

  if (props.direction === Direction.NEXT) {
    text = <>&gt;</>;
  } else {
    text = <>&lt;</>;
  }

  return (
    <button type="button" onClick={props.onClick} className={styles.button}>{text}</button>
  );
}
