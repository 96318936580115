import { useEventsPublisher } from 'event-bus';
import { Person, TeamRole } from './Types';
import { useBackendApi } from './backendApi';

export const CONTACTS_MASS_DELETED = 'CONTACTS_MASS_DELETED';

export interface ContactsMassDeletedEvent {
  ids: number[];
}

export function isPersonCanBeMassDeleted(person: Person) {
  return ![
    TeamRole.OWNER,
    TeamRole.ADMINISTRATOR,
    TeamRole.MEMBER,
  ].includes(person.teamRole);
}

export function usePeopleMassDeleteService() {

  const nekstApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const massDelete = async (ids: number[]) => {
    await nekstApi.put('/people/bulk/delete', {
      ids,
    });

    eventsPublisher.publish(CONTACTS_MASS_DELETED, { ids } as ContactsMassDeletedEvent);
  };

  return {
    massDelete,
  };
}
