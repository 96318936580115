import React from 'react';

import MassDeleteButton from './Delete/MassDeleteButton';
import SetDueDateButton from './SetDueDate/SetDueDateButton';
import MakeKeyDateButton from './KeyDate/MakeKeyDateButton';
import MassUpdateControlsLayout from '../../shared/widgets/MassUpdate/MassUpdateControlsLayout';
import MakePrivateButton from './Private/MakePrivateButton';
import AssignToRolesButton from './AssignToRoles/AssignToRolesButton';
import SetGroupButton from './SetGroup/SetGroupButton';

export default function MassUpdateControls() {
  return (
    <MassUpdateControlsLayout>
      <>
        <SetDueDateButton />
        <MassDeleteButton />
        <MakeKeyDateButton />
        <MakePrivateButton />
        <AssignToRolesButton />
        <SetGroupButton />
      </>
    </MassUpdateControlsLayout>
  );
}
