import React from 'react';
import { FormButtonLayoutProps } from 'ui-builder';

function BaseButtonLayout(props: FormButtonLayoutProps) {
  const {
    onClick,
    text,
  } = props;

  return (
    <input type="submit" onClick={onClick} value={text} />
  );
}

export default BaseButtonLayout;
