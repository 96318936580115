import React from 'react';
import {
  If, SimpleBlock,
  useMassUpdateServiceRequired,
  useRowVisibilityHelper,
  useShowContext,
} from 'ui-builder';
import {
  CommentsNumberField,
  DueDateField,
  EmailField,
  EmailRecipientsField,
  SmsField,
  SmsRecipientsField,
  TaskAssignmentsField,
} from 'features/tasks-list-feature';
import TaskRowLayout from '../../../../shared/widgets/TaskRowLayout';
import IsKeyDateField from '../../../../plans/tasks/List/IsKeyDateField';
import DependentTaskDueDate from './DependentTaskDueDate';
import OpenUpdateFormField from '../CreateUpdate/OpenUpdateFormField';
import styles from '../../../../shared/widgets/TaskRowLayout.module.scss';
import SelectForMassUpdateCheckbox
  from '../../../../shared/widgets/MassUpdate/SelectForMassUpdateCheckbox';
import CompletedField from './CompletedField';
import OpenProjectField from './OpenProjectField';
import CompleteTaskCheckbox from '../shared/completion/CompleteTaskCheckbox';
import IsPrivateField from '../../../../plans/tasks/List/IsPrivateField';
import { AbstractTask, TaskStatus, TaskType } from 'nekst-api';
import { SendEmailButton, SendSmsButton } from 'features/tasks-forms';
import TasksGroupField from '../../../../plans/tasks/List/TasksGroupField';

export function Checkbox() {
  const massUpdate = useMassUpdateServiceRequired();

  if (massUpdate.isEnabled()) {
    return (<SelectForMassUpdateCheckbox />);
  } else {
    return (<CompleteTaskCheckbox />);
  }
}

interface Props {
  showProjectName?: boolean;
}

export default function TaskRow(props: Props) {
  const visibilityHelper = useRowVisibilityHelper();

  const {
    data: task,
    isLoading,
  } = useShowContext<AbstractTask>();

  return (
    <TaskRowLayout
      isDisabled={visibilityHelper.isDisabled()}
      isLoading={isLoading}
      checkbox={
        <Checkbox />
      }
      isCollapsed={!!task?.parentTaskRelation}
      isCompleted={task?.status === TaskStatus.COMPLETED}
      title={(
        <>
          <EmailField />
          <SmsField />
          <OpenUpdateFormField />
        </>
      )}
      titleAnnotation={(props.showProjectName && (<OpenProjectField />))}
      leftLine2={(
        <>
          <TaskAssignmentsField source="assignments" />
          <If conditionFunc={(data) => data.type === TaskType.EMAIL}>
            <EmailRecipientsField />
          </If>
          <If conditionFunc={(data) => data.type === TaskType.SMS}>
            <SmsRecipientsField />
          </If>
        </>
      )}
      rightLine1={(
        <>
          <If conditionFunc={(item) => item.status !== TaskStatus.COMPLETED}>
            <SendEmailButton className={styles.sendButton} />
            <SendSmsButton className={styles.sendButton} />
            <DueDateField />
            <If conditionFunc={(item) => !item.dueDate}>
              <DependentTaskDueDate />
            </If>
          </If>
          <If
            conditionFunc={
              (item) => item.status === TaskStatus.COMPLETED && item.completedAt
            }
          >
            <CompletedField />
          </If>
        </>
      )}
      rightLine2={(
        <SimpleBlock className={styles.iconsBlock}>
          <TasksGroupField />
          <IsPrivateField />
          <CommentsNumberField />
          <IsKeyDateField source="isKeyDate" />
        </SimpleBlock>
      )}
    />
  );
}

TaskRow.prototype.defaultProps = {
  showProjectName: false,
};
