import React from 'react';
import { useAllTasksService } from 'nekst-api';
import CalendarListLayout from './CalendarListLayout';
import { List } from 'ui-builder';

export default function CalendarTasksView() {
  const tasksService = useAllTasksService();

  return (
    // @ts-ignore
    <List getDataFunc={tasksService.getTasks}>
      <CalendarListLayout />
    </List>
  );
}
