import { ConstraintValidator, ConstraintValue } from '../index';
import useConstraintValidatorHelper from './constaintValidatorHelper';

export interface MinLengthConstraintValidator extends ConstraintValidator<number> {
}

export default function useMinLengthConstraintValidator(): MinLengthConstraintValidator {

  const helperFactory = useConstraintValidatorHelper<number>();

  const getName = () => {
    return 'minLength';
  };

  const validate = (
    object: Record<string, any>,
    fieldName: string,
    constraintConfiguration: ConstraintValue<number>,
  ): boolean => {

    const helper = helperFactory.createHelper(
      object,
      fieldName,
      constraintConfiguration,
    );
    const value = helper.getValue();

    if (helper.hasValue()) {
      const constraintValue = helper.getConstraintValue();
      if (typeof value === 'string') {
        return value.length >= constraintValue;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const getErrorMessage = (config: ConstraintValue<number>) => {
    return (typeof config === 'object' && config.message)
      || `Field length should not be less than ${config}`;
  };

  return {
    getName,
    validate,
    getErrorMessage,
  };
}
