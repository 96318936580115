import useProjectsApi from '../../../projects/projectsApi';
import { ValidationSchema } from '../../../shared/uibuilder/validation';

export interface UpdatePasswordRequest {
  oldPassword: string,
  newPassword: string,
}

export default function usePasswordService() {
  const api = useProjectsApi();

  const updatePassword = async (request: UpdatePasswordRequest) => {
    await api.put('/identity/password', request);
  };

  const getUpdatePasswordValidationSchema = () => {
    return {
      oldPassword: {
        type: 'string',
        constraints: {
          required: true,
        },
      },
      newPassword: {
        type: 'string',
        constraints: {
          required: true,
          minLength: 8,
        },
      },
      newPassword2: {
        type: 'string',
        constraints: {
          required: true,
          match: {
            value: 'newPassword',
            message: 'The entered passwords don\'t match',
          },
        },
      },
    } as ValidationSchema;
  };

  return {
    updatePassword,
    getUpdatePasswordValidationSchema,
  };
}
