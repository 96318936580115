import { AbstractTask, TasksFilter } from 'nekst-api';
import { useFilterContext, VisibilityState } from 'ui-builder';
import { AdvancedListFilterService } from './types';

export type DueDateFilterValue = Date | [Date | null, Date | null] | null;

// initially the filter by the due date wasn't implemented and
// null value was used for all tasks
// this is a special value for No Due Date filter in order not to rewrite filter functionality
export const NO_DUE_DATE_VALUE: [null, null] = [null, null];

function getStartOfTheDay(date: Nullable<Date>) {
  if (date) {
    const result = new Date(date.getTime());
    result.setHours(0);
    result.setMinutes(0);
    result.setSeconds(0);
    result.setMilliseconds(0);
    return result;
  } else {
    return null;
  }
}

function getEndOfTheDay(date: Nullable<Date>) {
  if (date) {
    const result = new Date(date.getTime());
    result.setHours(23);
    result.setMinutes(59);
    result.setSeconds(59);
    result.setMilliseconds(999);
    return result;
  } else {
    return null;
  }
}

function compareDates(date1: Nullable<Date>, date2: Nullable<Date>) {
  if (date1 && !date2) {
    return 1;
  } else if (!date1 && date2) {
    return -1;
  } else if (date1 && date2) {
    return date1.getTime() - date2.getTime();
  } else {
    return 0;
  }
}

export const DUE_DATE_FILTER_NAME = 'DUE_DATE';

export function useDueDateFilterService():
  AdvancedListFilterService<DueDateFilterValue> {

  const filterContext = useFilterContext<TasksFilter>();

  const filterValue = filterContext.filterValue!;

  const getFilterValue = (): DueDateFilterValue => {

    if (filterValue.dueDateFrom || filterValue.dueDateTo) {
      return [
        filterValue.dueDateFrom || null,
        filterValue.dueDateTo || null,
      ];
    } else if (filterValue.noDueDate) {
      return NO_DUE_DATE_VALUE;
    } else {
      return null;
    }
  };

  const toContextForm = (value: DueDateFilterValue | undefined): Partial<TasksFilter> => {
    let dueDateFrom: Date | undefined;
    let dueDateTo: Date | undefined;
    let noDueDate = false;

    if (value) {
      if (Array.isArray(value)) {
        if (value[0] || value[1]) {
          dueDateFrom = value[0] || undefined;
          dueDateTo = value[1] || undefined;
        } else {
          noDueDate = true;
        }
      } else {
        dueDateFrom = value;
        dueDateTo = value;
      }
    }

    return {
      dueDateFrom,
      dueDateTo,
      noDueDate,
    };
  };

  const setFilterValue = (value: DueDateFilterValue) => {
    filterContext.setFilterValue({
      ...filterValue,
      ...toContextForm(value),
    });
  };

  const filterFunc = (data: AbstractTask) => {
    const filter = getFilterValue();
    if (filter) {
      let dateFrom;
      let dateTo;
      if (Array.isArray(filter)) {
        dateFrom = getStartOfTheDay(filter[0]);
        dateTo = getEndOfTheDay(filter[1]);
      } else {
        dateFrom = getStartOfTheDay(filter);
        dateTo = getEndOfTheDay(filter);
      }

      if (data.dueDate?.date) {
        const comparedDate = data.dueDate.date;

        const moreThanLeft = compareDates(dateFrom, comparedDate);
        const lessThanRight = compareDates(comparedDate, dateTo);
        return moreThanLeft <= 0 && lessThanRight <= 0
          ? VisibilityState.VISIBLE
          : VisibilityState.HIDDEN;
      } else if (!dateFrom && !dateTo && !data.parentTaskRelation) {
        return VisibilityState.VISIBLE;
      } else if (data.parentTaskRelation) {
        return VisibilityState.VISIBLE;
      } else {
        return VisibilityState.HIDDEN;
      }
    } else {
      return VisibilityState.VISIBLE;
    }
  };

  return {
    getName: () => DUE_DATE_FILTER_NAME,
    getFilterValue,
    getDefaultValue: () => [getStartOfTheDay(new Date()), getStartOfTheDay(new Date())],
    setFilterValue,
    applyFilter: filterFunc,
    toContextForm,
  };
}
