import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckboxInput, SubmitButton } from 'ui-builder';
import { TeamRole } from 'nekst-api';
import SetPasswordInput from './SetPasswordInput';
import FormTemplate from '../../../shared/uibuilder/form/FormTemplate';
import useCompleteAccountService, { CompleteAccountRequest } from './completeAccountService';
import { UpdatePersonFormFields } from 'features/tasks-forms';
import { Separator } from 'features/nekst-widgets';
import { useAuthenticationLoader } from '../../../authentication/AuthenticationContext';
import useUserSessionStorage from '../../../login/userSessionStorage';

function ApplyRulesCheckbox() {
  return (
    <CheckboxInput
      source="_applyRules"
      label={(
        <>
          By creating an account, I accept Nekst App
          {' '}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="http://nekst.com/terms" target="_blank" tabIndex={-1} rel="noreferrer">
            Terms of Service
          </a>
          {' '}
          and
          {' '}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="http://nekst.com/privacy" target="_blank" tabIndex={-1} rel="noreferrer">
            Privacy Policy
          </a>
          .
        </>
      )}
    />
  );
}

export default function CompleteAccountForm() {
  const completeAccountService = useCompleteAccountService();

  const authenticationContext = useAuthenticationLoader();

  const sessionStorage = useUserSessionStorage();

  const navigate = useNavigate();

  return (
    <FormTemplate<CompleteAccountRequest>
      buttons={<SubmitButton text="Create Account" />}
      submitFormFunc={async (data) => {
        const token = await completeAccountService.completeAccount(data);
        sessionStorage.saveToken(token);
        setTimeout(() => navigate('/tasks'), 100);
        return data;
      }}
      initialData={{
        profile: {
          ...authenticationContext.currentUser.profile,
        },
        setPasswordRequest: {
          password: '',
        },
      }}
      validationSchema={
        completeAccountService.getCompleteAccountValidationSchema(TeamRole.MEMBER)
      }
    >
      <UpdatePersonFormFields person={authenticationContext.currentUser} />
      <Separator />
      <SetPasswordInput source="setPasswordRequest" />
      <Separator />
      <h5>Communication Preferences</h5>
      <CheckboxInput
        source="isDailyMailEnabled"
        label="Email - Click For a Daily Summary of Your Current, Past Due and Next Day Tasks"
      />
      <Separator />
      <ApplyRulesCheckbox />
    </FormTemplate>
  );
}
