import React from 'react';
import { useFormContext } from 'ui-builder';
import { Comment } from 'nekst-api';
import ApplyCircleButton from '../ApplyCircleButton';

export default function SubmitCommentButton() {
  const formContext = useFormContext<Comment>();

  return (
    <ApplyCircleButton<Comment>
      submitFunc={async () => {
        await formContext.submitForm!();
      }}
    />
  );
}
