import { useBackendApi } from './backendApi';

export function useStripeCustomerPortalService() {
  const nekstApi = useBackendApi();

  const getBillingPageUrl = () => {
    const location = window.location;
    return `${location.protocol}//${location.hostname}:${location.port}/settings/billing`;
  };

  const createUpdateSubscriptionLink = async (): Promise<string> => {
    const returnUrl = `${getBillingPageUrl()}?refresh=1`;

    const result = await nekstApi.post('/billing/stripeportal/updatesubscription', {
      returnUrl,
    }) as { url: string };

    return result.url;
  };

  const createUpdatePaymentMethodLink = async (): Promise<string> => {
    const returnUrl = `${getBillingPageUrl()}?refresh=1`;

    const result = await nekstApi.post('/billing/stripeportal/updatepaymentmethod', {
      returnUrl,
    }) as { url: string };

    return result.url;
  };

  const createPortalAccessLink = async (): Promise<string> => {
    const returnUrl = `${getBillingPageUrl()}?refresh=1`;

    const result = await nekstApi.post('/billing/stripeportal/portalaccess', {
      returnUrl,
    }) as { url: string };

    return result.url;
  };

  return {
    createUpdateSubscriptionLink,
    createUpdatePaymentMethodLink,
    createPortalAccessLink,
  };
}
