import { BaseInputLayoutProps, BaseInputProps } from './types';

import React from 'react';
import { useInputHelper } from './inputHelper';
import { useNewUiTheme } from '../../newUiTheme';

export interface RadioButtonProps extends BaseInputProps<boolean> {
  groupValue: string | number;
}

export interface RadioButtonLayoutProps extends BaseInputLayoutProps<boolean> {
  groupValue: string | number;
}

export function RadioButton(props: RadioButtonProps) {
  const helper = useInputHelper<boolean>(props);

  const uiTheme = useNewUiTheme();

  return (
    <uiTheme.inputs.RadioButtonLayout
      groupValue={props.groupValue}
      isVisible
      name={helper.getSource()}
      value={Boolean(props.value) ?? helper.getValue()}
      onChangeCallback={helper.getOnChangeCallback()}
      label={helper.getLabel()}
      title={props.title}
      isDisabled={helper.getIsDisabled()}
      hint={props.hint}
      hideLabel={props.hideLabel}
    />
  );
}
