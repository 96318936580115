import React, { ReactNode, useContext } from 'react';

const FormFieldsReactContext = React.createContext<any | undefined>(undefined);

export function useFormInputsContext<DataType = any>() {
  return useContext<DataType>(FormFieldsReactContext);
}

export function FormInputsContext<DataType = any>(
  props: {
    children: ReactNode,
    contextData: DataType
  }
) {
  return (
    <FormFieldsReactContext.Provider value={props.contextData}>
      {props.children}
    </FormFieldsReactContext.Provider>
  );
}
