import React from 'react';
import { FormRowLayoutProps } from '../index';
import { FormRowsGroupLayoutProps } from 'ui-builder';

function FormRowLayout(props: FormRowLayoutProps) {
  return (
    <div className="form-row" data-key={props.dataKey}>
      {props.children}
    </div>
  );
}

export function FormRowsGroupLayout(props: FormRowsGroupLayoutProps) {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <div className="form-rows">
      {props.children}
    </div>
  );
}

export default FormRowLayout;
