import React from 'react';
import { useTasksGroupsFilterService } from 'features/tasks-forms';
import BorderedBox from '../../../../shared/web/layout/BorderedBox';
import { CheckboxGroup, FormTemplate, LoadingAnimation } from 'ui-builder';
import { createPortal } from 'react-dom';

import styles from './AssigneeFilter.module.scss';
import { TasksGroupIcon } from 'features/nekst-widgets';
import { ToggledFeature, withToggleWrapper } from 'feature-toggle';

export function TasksGroupFilterContainer() {
  return (
    <div id={'tasksGroupFilter'}></div>
  );
}

export function TasksGroupsCheckboxGroup() {
  const tasksGroupsFilterService = useTasksGroupsFilterService();

  const options = tasksGroupsFilterService.getOptions()
    .map((o) => ({
      ...o,
      label: <TasksGroupIcon group={o._data!} withName />
    }));

  if (options.length) {
    return (
      <CheckboxGroup
        options={options}
        value={tasksGroupsFilterService.getFilterValue()}
        source="tasksGroupsIds"
        isDisabled={options.length === 1}
        hideLabel
      />
    );
  } else {
    return (<LoadingAnimation />);
  }

}

export function TasksGroupFilterForm() {
  const tasksGroupsFilterService = useTasksGroupsFilterService();

  return (
    <FormTemplate<{ tasksGroupsIds: number[] }>
      submitFormFunc={async (data) => {
        tasksGroupsFilterService.setFilterValue(data.tasksGroupsIds);
        return data;
      }}
      submitOnEachChange
      buttons={null}
    >
      <TasksGroupsCheckboxGroup />
    </FormTemplate>
  );
}

function TasksGroupFilter() {

  const container = document.getElementById('tasksGroupFilter');

  if (container) {
    return createPortal(
      <BorderedBox title="Groups" className={styles.filter}>
        <TasksGroupFilter />
      </BorderedBox>,
      container
    );
  } else {
    return null;
  }
}

export default withToggleWrapper(TasksGroupFilter, ToggledFeature.TASKS_GROUPS);
