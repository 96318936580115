import React, { ReactElement, useEffect } from 'react';
import {
  AccessDeniedError,
  TasksFilter,
} from 'nekst-api';
import {
  Limit,
  PageResponse,
  useListContext,
} from 'ui-builder';
import {
  AllTasksList,
  MassUpdateCheckbox,
  ProjectTasksNameFilter,
} from 'features/tasks-list-feature';
import useProjectTasksService, { AbstractTask } from '../projectTasksService';
import styles from '../../../../shared/widgets/TaskRowLayout.module.scss';
import TaskRow from './TaskRow';
import DueDateFilters from './filter/DueDate/DueDateFilters';
import FilterWidgets from './filter/FilterWidgets';
import Right from '../../../../shared/web/Right';
import { AdvancedFilterPopupButton } from './filter/Advanced/AdvancedFilterForm';
import InnerBlock from '../../../../shared/web/layout/InnerBlock';

import listStyles from './TaskList.module.scss';
import MassUpdateControls from './MassUpdate/MassUpdateControls';
import CalendarListLayout from '../../../../calendar/CalendarListLayout';
import useTasksNavigator from '../tasksNavigator';
import { useOpenUpdateFormHelper } from '../CreateUpdate/OpenUpdateFormField';
import PaginationWrapper from '../../../../shared/uibuilder/list/PaginationWrapper';
import CalendarViewTour from '../../../../calendar/CalendarViewTour';
import TaskIdParamRemover from './TaskIdParamRemover';
import { RowRepeater } from 'features/nekst-widgets';

export enum ViewOption {
  LIST = 'LIST',
  CALENDAR = 'CALENDAR'
}

interface Props {
  getDataFunc: (filter: TasksFilter, limit: Limit) => Promise<PageResponse<AbstractTask>>,
  showProjectName?: boolean
  view: ViewOption
  title: string | ReactElement
  showStatusFilter?: boolean,
}

function ListView(props: {
  showProjectName?: boolean,
  showStatusFilterWidget: boolean,
}) {
  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <MassUpdateCheckbox />
      </div>
      <FilterWidgets showStatusWidget={props.showStatusFilterWidget} />
      <MassUpdateControls />
      <DueDateFilters />
      <RowRepeater
        idField="id"
        blockClassName={`${styles.block} ${styles.withoutTopBorder}`}
        groupClassName={styles.group}
        loadMoreButtonClassName={styles.loadMore}
        parentIdField="parentTaskRelation.parentTaskId"
        noDataMessage="No tasks matching current filter criteria"
      >
        <TaskRow showProjectName={props.showProjectName} />
      </RowRepeater>
    </>
  );
}

function CalendarView() {
  return (
    <>
      <FilterWidgets showDueDateWidget={false} />
      <CalendarListLayout />
      <CalendarViewTour />
    </>
  );
}

function TaskFormOpener() {
  const tasksNavigator = useTasksNavigator();
  const listContext = useListContext<AbstractTask>();

  const openHelper = useOpenUpdateFormHelper();

  const taskService = useProjectTasksService();

  const taskId = tasksNavigator.getTaskId();

  async function openTask() {
    let task = listContext.data?.find((item) => item.id === taskId);

    if (!task) {
      try {
        task = await taskService.getTask(taskId!);
      } catch (e) {
        if (e instanceof AccessDeniedError) {
          tasksNavigator.removeTaskId();
        } else {
          throw e;
        }
      }
    }

    if (task) {
      openHelper.openForm(
        task,
      );
    }
  }

  useEffect(() => {
    if (taskId) {
      openTask();
    }
  }, [listContext.version, taskId]);

  return null;
}

export default function TasksList(props: Props) {

  return (
    <AllTasksList
      getDataFunc={props.getDataFunc}
      byPage={props.view === ViewOption.LIST ? 30 : 200}
      filterOverride={
        props.view === ViewOption.CALENDAR
          ? {
            dueDateFrom: undefined,
            dueDateTo: undefined
          }
          : {}
      }
    >
      <>
        <TaskIdParamRemover />
        <TaskFormOpener />
        <PaginationWrapper>
          <InnerBlock
            title={props.title}
            headerRight={(
              <div data-key="advanced-filter-block" style={{ overflow: 'hidden' }}>
                <Right>
                  <AdvancedFilterPopupButton
                    showStatusFilter={!!props.showStatusFilter}
                  />
                </Right>
                <Right className={listStyles.nameFilter}><ProjectTasksNameFilter /></Right>
              </div>
            )}
            showHeaderBorder
            className={listStyles.taskList}
          >
            {props.view === ViewOption.LIST && (
              <ListView
                showProjectName={props.showProjectName}
                showStatusFilterWidget={!!props.showStatusFilter}
              />
            )}
            {props.view === ViewOption.CALENDAR && (
              <CalendarView />
            )}
          </InnerBlock>
        </PaginationWrapper>
      </>
    </AllTasksList>
  );
}

TasksList.prototype.defaultProps = {
  showProjectName: false,
};
