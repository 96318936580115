import React from 'react';
import { Option, BaseInputLayoutProps, BaseInputProps, InputLayoutIcon } from './types';
import { InputHelper, useInputHelper } from './inputHelper';
import { useNewUiTheme } from '../../newUiTheme';

export type OptionsGroup = {
  label: string,
  options: Option[],
}

interface DropdownHelper extends InputHelper<string | number> {

}

function useDropdownHelper<ValueType extends string | number = number>(
  props: DropdownProps<ValueType>,
): DropdownHelper {
  return useInputHelper<ValueType>(props);
}

export interface DropdownProps<
  ValueType extends string | number | boolean = number
> extends BaseInputProps<ValueType> {
  options: Option<ValueType>[] | OptionsGroup[];
  isClearable?: boolean;
  icon?: InputLayoutIcon;
  mobile_hideIcon?: boolean;
}

export interface DropdownLayoutProps extends BaseInputLayoutProps<string | number> {
  options?: Option[] | OptionsGroup[],
  isClearable?: boolean
  icon?: InputLayoutIcon;
  mobile_hideIcon?: boolean
}

export function Dropdown<ValueType extends string | number = number>(props: DropdownProps<ValueType>) {
  const helper = useDropdownHelper<ValueType>(props);
  const uiTheme = useNewUiTheme();

  return (
    <uiTheme.inputs.BaseDropdownLayout
      {...helper.getBaseInputLayoutProps()}
      options={props.options}
      isClearable={props.isClearable}
      hideLabel={props.hideLabel}
      icon={props.icon}
    />
  );
}
