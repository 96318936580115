import { useNavigate } from 'react-router-dom';

export default function useAiTrainerNavigator() {
  const navigate = useNavigate();

  const openEntriesList = () => {
    navigate('/admin/aitrainer');
  };

  const openCreatePage = () => {
    navigate('/admin/aitrainer/create');
  };

  const openUpdatePage = (id: number) => {
    navigate(`/admin/aitrainer/entries/${id}`);
  };

  return {
    openEntriesList,
    openCreatePage,
    openUpdatePage,
  };
}
