import React from 'react';
import { SubmitButton, AfterSubmitFunc, usePopupManager } from 'ui-builder';
import BaseSmsTaskForm from './BaseSmsTaskForm';
import { PopupForm } from 'features/nekst-widgets';
import { PlanTask, PlanTaskParentTaskRelation, usePlanTasksService } from 'nekst-api';

export interface CreateSmsPlanTaskFormProps {
  planId: number,
  afterSubmitFunc?: AfterSubmitFunc,
  parentTask?: PlanTask
}

export function CreateSmsTaskForm(props: CreateSmsPlanTaskFormProps) {
  const planTaskService = usePlanTasksService();

  let initialData;
  if (props.parentTask) {
    initialData = {
      parentTaskRelation: {
        parentTaskId: props.parentTask.id,
        parentTaskName: props.parentTask.name,
      } as PlanTaskParentTaskRelation,
    } as PlanTask;
  } else {
    initialData = {} as PlanTask;
  }

  return (
    <BaseSmsTaskForm
      title={props.parentTask ? 'Add Dependent SMS Task' : 'Add SMS Task'}
      submitFormFunc={
        (data) => {
          return planTaskService.createSmsTask(props.planId, data);
        }
      }
      afterSubmitFunc={props.afterSubmitFunc}
      validationSchema={planTaskService.getSmsTaskValidationSchema()}
      buttons={<SubmitButton text="Save task" key="save" />}
      initialData={initialData}
    />
  );
}

export default function useCreateSmsTaskForm() {
  const popupManager = usePopupManager();

  const openForm = (planId: number) => {
    popupManager.openPopup(
      <PopupForm>
        <CreateSmsTaskForm planId={planId} />
      </PopupForm>,
    );
  };

  return {
    openForm,
  };
}
