import {
  OptInStatus, Person,
  ShortProfile,
  TeamRole,
  useAuthenticationContext,
  useBackendApi
} from 'nekst-api';
import React, { useState } from 'react';
import Layout from './Layout';

import { ButtonState } from './types';

function useRequestOptInService() {
  const nekstApi = useBackendApi();

  const requestOptIn = async (personId: number) => {
    await nekstApi.post(`/messaging/optin/${personId}`);
  };

  return {
    requestOptIn,
  };
}

export function RequestOptInButton(
  props: {
    person: ShortProfile,
  },
) {
  const [requestResult, setRequestResult] = useState<ButtonState>();

  const [loading, setLoading] = useState(false);

  const requestOptInService = useRequestOptInService();

  const phoneNumberForSms = props.person._phoneNumberForSms;

  const optInStatus = phoneNumberForSms?.optInStatus;

  const authenticationContext = useAuthenticationContext();
  const teamRole = authenticationContext!.user.teamRole;

  if ([TeamRole.OWNER, TeamRole.ADMINISTRATOR].includes(teamRole)) {
    if (
      optInStatus
      && [OptInStatus.WAITING_FOR_CONSENT, OptInStatus.NOT_SENT].includes(optInStatus)
    ) {

      let state: ButtonState;
      const requestFunc = async () => {
        if (!loading) {
          setLoading(true);
          try {
            await requestOptInService.requestOptIn(props.person.id);
            setRequestResult(ButtonState.REQUESTED);
          } catch (e) {
            setRequestResult(ButtonState.ERROR);
          } finally {
            setLoading(false);
          }
        }
      };

      const lockedTill = phoneNumberForSms?.optInSendingLockedTill;

      if (!lockedTill) {
        if (loading) {
          state = ButtonState.LOADING;
        } else {
          if (requestResult) {
            state = requestResult;
          } else {
            state = ButtonState.DEFAULT;
          }
        }
      } else {
        state = ButtonState.LOCKED;
      }

      return (
        <Layout state={state} onClick={requestFunc} />
      )
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function RequestOptInButtonFullPerson(
  props: {
    person: Person,
}
) {
  const shortProfile: ShortProfile = {
    id: props.person.id,
    name: props.person.profile.name,
    primaryEmail: props.person.profile.primaryEmail,
    phoneNumbers: props.person.profile.phoneNumbers,
    _phoneNumberForSms: props.person.profile.phoneNumbers.find((phoneNumber) => phoneNumber.isUsedForSms),
  }

  return (<RequestOptInButton person={shortProfile} />);
}
