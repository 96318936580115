import React from 'react';
import { AfterSubmitFunc, FormTemplate, TextInput } from 'ui-builder';
import { ProjectRoleDropdown } from 'features/nekst-widgets';
import { PersonParseResult } from 'nekst-api';

export default function BasePersonParseResultForm(
  props: {
    data?: Partial<PersonParseResult>,
    submitFormFunc: (data: PersonParseResult) => Promise<PersonParseResult>,
    title: string,
    afterSubmitFunc?: AfterSubmitFunc,
  }
) {
  return (
    <FormTemplate<PersonParseResult>
      initialData={props.data}
      title={props.title}
      submitFormFunc={props.submitFormFunc}
      afterSubmitFunc={props.afterSubmitFunc}
      validationSchema={{
        firstName: {
          type: 'string',
          constraints: {
            required: true
          }
        },
        lastName: {
          type: 'string',
          constraints: {
            required: true
          }
        }
      }}
    >
      <TextInput source="firstName" label="First Name" />
      <TextInput source="lastName" label="Last Name" />
      <TextInput source="companyName" label="Company Name" />
      <TextInput source="addressLine1" label="Address Line 1" />
      <TextInput source="addressLine2" label="Address Line 2" />
      <TextInput source="city" label="City" />
      <TextInput source="state" label="State" />
      <TextInput source="zip" label="Zip" />
      <TextInput source="phoneNumber" label="Phone Number" />
      <TextInput source="email" label="Email" />
      <ProjectRoleDropdown source="roleId" label="Role" />
    </FormTemplate>
  );
}
