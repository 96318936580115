import { useBackendApi } from './backendApi';
import { Permission, ShortProfile } from './Types';
import { useEventsPublisher } from 'event-bus';
import { useObjectDatesMapper } from './Helper';

export enum CommentPermission {
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface Comment {
  id: number;
  taskId: number,
  authorId: number;
  createdAt: Date;
  text: string;
  permissions: Permission[];
  _author: ShortProfile;
}

export interface CommentRequest {
  text: string;
}

export interface CreateCommentRequest extends CommentRequest {

}

export interface UpdateCommentRequest extends CommentRequest {

}

export const COMMENTS_UPDATED_EVENT = 'COMMENTS_UPDATED';

export function useTaskCommentsService() {
  const projectsApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const onCommentsUpdated = () => {
    eventsPublisher.publish(COMMENTS_UPDATED_EVENT, {});
  };

  const {
    mapSingle,
    mapList,
  } = useObjectDatesMapper<Comment>([], ['createdAt', 'updatedAt']);

  const createComment = async (taskId: number, request: CreateCommentRequest) => {
    const result = await projectsApi.post(`/tasks/${taskId}/comments`, {
      text: request.text,
    });
    onCommentsUpdated();
    return mapSingle(result);
  };

  const updateComment = async (commentId: number, request: UpdateCommentRequest) => {
    const result = await projectsApi.put(`/comments/${commentId}`, {
      text: request.text,
    });
    onCommentsUpdated();
    return mapSingle(result);
  };

  const deleteComment = async (commentId: number) => {
    await projectsApi.delete(`/comments/${commentId}`);
    onCommentsUpdated();
  };

  const getTaskComments = async (taskId: number): Promise<Comment[]> => {
    const result = await projectsApi.get(`/tasks/${taskId}/comments`);

    return mapList(result);
  };

  const getLatestComments = async () => {
    const result = await projectsApi.get('/comments/latest');
    return mapList(result);
  };

  const getLatestProjectComments = async (projectId: number) => {
    const result = await projectsApi.get(`/projects/${projectId}/comments/latest`);

    return mapList(result);
  };

  return {
    createComment,
    updateComment,
    deleteComment,
    getTaskComments,
    getLatestComments,
    getLatestProjectComments,
  };
}
