import { useFilterContext, VisibilityState } from 'ui-builder';
import { AbstractTask, TasksFilter, useAuthenticationContext } from 'nekst-api';
import { AdvancedListFilterService } from './types';

export type AssigneeFilterValue = {
  selectAll: boolean,
  selected: number[]
};

export const ASSIGNEE_FILTER_NAME = 'ASSIGNEE';

export function useDefaultAssigneeFilterValue() {
  const authenticationContext = useAuthenticationContext();

  const user = authenticationContext?.user;

  const defaultValue = user ? {
    selectAll: false,
    selected: [
      user.id,
    ],
  } : {
    selected: [],
    selectAll: true,
  };

  return {
    defaultValue,
  };
}

export function useAssigneeFilterService(): AdvancedListFilterService<AssigneeFilterValue> {

  const filterContext = useFilterContext<TasksFilter>();

  const filterValue = filterContext.filterValue;

  function isInitialized() {
    return !!filterValue;
  }

  const { defaultValue } = useDefaultAssigneeFilterValue();

  const getFilterValue = () => {

    if (isInitialized()) {
      if (filterValue?.assigneesIds !== undefined) {
        return {
          selectAll: false,
          selected: filterValue?.assigneesIds,
        };
      } else {
        return {
          selected: [],
          selectAll: true,
        };
      }
    } else {
      return defaultValue;
    }
  };

  const toContextForm = (value: AssigneeFilterValue | undefined): Partial<TasksFilter> => {
    if (value) {
      let newValue;
      if (value.selectAll) {
        newValue = undefined;
      } else {
        newValue = value.selected;
      }

      return {
        assigneesIds: newValue,
      };
    } else {
      return {
        assigneesIds: undefined,
      };
    }
  };

  const setFilterValue = (value: AssigneeFilterValue) => {
    const contextForm = toContextForm(value);
    filterContext.setFilterValue!({
      ...filterValue,
      ...contextForm,
    });
  };

  const filterFunc = (
    data: AbstractTask,
  ) => {
    const value = getFilterValue();
    if (value.selectAll) {
      return VisibilityState.VISIBLE;
    } else {
      return data.assignments.find(
        (item) => item.personId && value.selected.includes(item.personId),
      ) ? VisibilityState.VISIBLE : VisibilityState.HIDDEN;
    }
  };

  return {
    getName: () => ASSIGNEE_FILTER_NAME,
    getDefaultValue: () => defaultValue,
    getFilterValue,
    setFilterValue,
    applyFilter: filterFunc,
    toContextForm,
  };
}
