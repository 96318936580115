/* istanbul ignore file */

import React from 'react';
import { useFormContext } from 'ui-builder';
import { BaseInputLayoutProps } from '../index';
import ErrorsWrapper from '../input/layout/ErrorsWrapper';

function BaseInputLayout(props: BaseInputLayoutProps) {
  let result;

  const formContext = useFormContext();

  const showRequiredIcon = props.showRequiredIcon !== undefined ? props.showRequiredIcon : true;

  if (props.showLabel === undefined || props.showLabel) {
    result = (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label className={props.className} data-key={props.dataKey}>
        {!props.hideLabel && (
          <span className="label-text">
            {props.label}
            {props.afterLabelElement}
            &nbsp;
            {showRequiredIcon
              && formContext.isFieldRequired
              && formContext.isFieldRequired(props.name)
              && (<span style={{ color: 'red' }}>*</span>)}
          </span>
        )}
        {props.children}
        {props.hint && (
          <div className="hint">
            {props.hint}
          </div>
        )}
      </label>
    );
  } else {
    result = props.children;
  }

  return (
    <ErrorsWrapper name={props.name} errors={props.errors}>
      {result}
    </ErrorsWrapper>
  );
}

export default BaseInputLayout;
