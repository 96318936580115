import React from 'react';
import { Plan, usePlansService } from 'nekst-api';
import { FormFieldsData, FormTemplate, TextInput } from 'ui-builder';

export interface RenamePlanFormProps {
  id: number;
  afterSubmitFunc?: (_data: FormFieldsData | void) => Promise<void>,
}

export function RenamePlanForm(props: RenamePlanFormProps) {
  const planService = usePlansService();

  return (
    <FormTemplate<Plan>
      title="Rename plan"
      getDataFunc={() => planService.getById(props.id)}
      validationSchema={planService.getCreateValidationSchema()}
      submitFormFunc={async (data) => {
        return planService.rename(props.id, data);
      }}
      afterSubmitFunc={props.afterSubmitFunc}
    >
      <TextInput source="name" label="Please Enter a Name for This Action Plan" />
    </FormTemplate>
  );
}
