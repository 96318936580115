/* istanbul ignore file */
import React from 'react';
import { FormButtonProps } from './index';
import { useFormContext } from '../FormContext';
import { useNewUiTheme } from '../../newUiTheme';

export function CancelButton(props: FormButtonProps) {
  const { clearForm } = useFormContext();

  const uiTheme = useNewUiTheme();

  return (
    <uiTheme.buttons.CancelButtonLayout
      text={props.text || ''}
      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
      onClick={props.onClick || clearForm!}
      isDisabled={false}
      isLoading={false}
    />
  );
}
