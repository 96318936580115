import { useBackendApi } from './backendApi';
import { ProjectType, TaskType } from './Types';
import { useCacheService } from './cacheService';

enum TagType {
  TAG = 'tag',
  MENTION = 'mention',
}

type Tag = {
  id: string,
  value: string,
  type?: TagType,
  isDeprecated: boolean
}

export function replaceTagsIdsWithValues(input: string, availableTags: Tag[]): string {
  let result = input;

  if (result.includes('#') || result.includes('@')) {
    const tags = result.match(/[#|@]([\w\\.]+)/g) || [];

    const uniqueTags: string[] = Array.from(new Set(tags));

    uniqueTags.forEach((tag) => {
      const startSymbol = tag[0];
      const tagName = tag.substring(1);
      const tagObject = availableTags.find((t) => t.id === tagName);

      if (tagObject) {
        result = result.replace(
          new RegExp(tag.replace(/\./g, '\\.'), 'g'),
          `${startSymbol}${tagObject.value}`
        );
      }
    });
  }

  return result;
}

export function useSmartTagsService() {
  const nekstApi = useBackendApi();

  const cacheService = useCacheService('smart-tags-cache');

  const getSmartTags = async (
    projectType?: ProjectType,
    taskType?: TaskType,
    projectId?: number,
    tagType?: TagType | 'all',
  ) => {

    const allTags = await cacheService.get<Tag[]>(`smart-tags-${projectType}-${taskType}-${projectId}`, async (cacheItem) => {
      cacheItem.setExpireInSeconds(60);

      return await nekstApi.get(`/data/smarttags?taskType=${taskType || ''}&projectType=${projectType || ''}&projectId=${projectId || ''}`) as Tag[];
    });

    if (tagType === 'all') {
      return allTags;
    } else {
      const desiredTagType = tagType || TagType.TAG;

      return allTags.filter((tag) => tag.type === desiredTagType);
    }
  };

  return {
    getSmartTags,
  };
}
