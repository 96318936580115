import React from 'react';
import ReactDOM from 'react-dom';
import { useDueDateCounterService, useDueDateFilterService } from 'features/tasks-list-feature';
import Calendar from '../../../../../../shared/widgets/Calendar';

import styles from './CalendarFilter.module.scss';

const CONTAINER_ID = 'calendar_filter';

export function CalendarFilterContainer() {
  return (
    <div id={CONTAINER_ID} />
  );
}

export default function CalendarFilter() {
  const filterService = useDueDateFilterService();

  const filterValue = filterService.getFilterValue();

  const container = document.getElementById(CONTAINER_ID);

  const dueDateCounterService = useDueDateCounterService()!;

  if (container) {
    return ReactDOM.createPortal(
      (
        <Calendar
          value={filterValue || null}
          onChangeCallback={(date: Date) => {
            filterService.setFilterValue(date);
          }}
          dayClassNameFunc={(date) => {
            return dueDateCounterService.getNumberOfTasksAtDate(date) > 0 ? styles.hasDates : '';
          }}
        />
      ),
      container,
    );
  } else {
    return null;
  }
}
