export type Name = {
  firstName: string,
  lastName: string,
  fullName: string
}

export type Permission = string;

export type PhoneNumber = string;

export type Email = string;

export type Url = string;

export type Address = {
  country?: string,
  state?: string,
  city?: string,
  addressLine1?: string,
  addressLine2?: string,
  zip?: string
}

export enum TaskType {
  EMAIL = 'EMAIL',
  STANDARD = 'STANDARD',
  SMS = 'SMS'
}
