import React from 'react';
import { BlockProps } from './blocksTypes';
import { useNewUiTheme } from '../newUiTheme';

export function Center(props: BlockProps) {
  const uiTheme = useNewUiTheme();

  return (
    <uiTheme.common.CenterLayout {...props}>
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      <>
        {props.children}
      </>
    </uiTheme.common.CenterLayout>
  );
}
