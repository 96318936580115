import React from 'react';
import { AfterSubmitFunc, FormTemplate } from 'ui-builder';
import { Plan, usePlansService } from 'nekst-api';
import { DetailFieldsSetDropdown } from '../DetailFieldsSetDropdown';

export function UpdatePlanSetForm(props: {
  id: number,
  afterSubmitFunc?: AfterSubmitFunc,
}) {
  const plansService = usePlansService();

  return (
    <FormTemplate<Plan>
      title="Change Detail Boxes Set"
      submitFormFunc={(data) => {
        return plansService.updateSet(props.id, data.detailsFieldsSetId);
      }}
      getDataFunc={() => plansService.getById(props.id)}
      afterSubmitFunc={props.afterSubmitFunc}
    >
      <DetailFieldsSetDropdown
        projectTypeSource="projectType"
        source="detailsFieldsSetId"
        label="Details Boxes Set"
      />
    </FormTemplate>
  );
}
