import {
  ListFilterService,
  Option,
  useListContextRequired,
  VisibilityState
} from 'ui-builder';
import {
  PlanTask,
  TASKS_GROUP_ASSIGNED,
  TasksGroup,
  useEventsSubscriber
} from 'nekst-api';
import { PlanTasksFilter } from '../types';
import { useMemo } from 'react';

interface TasksGroupsFilterService extends ListFilterService<number[], PlanTask> {
  getOptions: () => Option<number, TasksGroup>[],
  isSelected: (groupId: number) => boolean,
}

export const NO_GROUP_LABEL = 'No Group';

export function useTasksGroupsFilterService(): TasksGroupsFilterService {

  const listContext = useListContextRequired<PlanTask, PlanTasksFilter>();

  const sortOptions = (a: Option<number, TasksGroup>, b: Option<number, TasksGroup>) => {
    if (!a._data!.id) {
      return 1;
    } else if (!b._data!.id) {
      return -1;
    } else {
      return a._data!.name.localeCompare(b._data!.name);
    }
  }

  const options = useMemo(() => {
    const resultMap: Record<number, Option<number>> = {};

    listContext.data.forEach((item) => {
      const groupId = item.groupId || 0;

      if (!resultMap[groupId]) {
        resultMap[groupId] = {
          value: groupId,
          label: item._group?.name || NO_GROUP_LABEL,
          _data: item._group || {
            id: 0,
            name: NO_GROUP_LABEL,
          }
        };
      }
    });

    const r = Object.values(resultMap);
    r.sort(sortOptions);

    return r;
  }, [listContext.version])

  const getOptions = () => {
    return options;
  };

  const getDefaultValue = () => {
    return getOptions().map((o) => o.value);
  };

  const getFilterValue = () => {
    return listContext.filterValues?.tasksGroupsIds || getDefaultValue();
  };

  const setFilterValue = (value: number[]) => {
    listContext.setFilterValues({
      ...listContext.filterValues || {},
      tasksGroupsIds: value,
    });
  };

  const applyFilter = (data: PlanTask) => {
    const filter = getFilterValue();

    if (filter && filter.length > 0) {
      return filter.includes(data.groupId || 0) ? VisibilityState.VISIBLE : VisibilityState.HIDDEN;
    } else {
      return VisibilityState.VISIBLE;
    }
  };

  const isSelected = (groupId: number) => {
    const filterValue = getFilterValue();
    return filterValue.length === 0 || filterValue.includes(groupId);
  }

  useEventsSubscriber(
    'tasksGroupsFilterService',
    {
      [TASKS_GROUP_ASSIGNED]: (eventData) => {
        const currentValue = getFilterValue();
        const groupId = eventData.groupId;

        if (currentValue.length && !currentValue.includes(groupId)) {
          setFilterValue([...currentValue, groupId]);
        }
      }
    },
    [JSON.stringify(getFilterValue())],
  );

  return {
    getName: () => 'tasksGroups',
    getDefaultValue,
    getFilterValue,
    setFilterValue,
    applyFilter,
    getOptions,
    isSelected,
  }
}
