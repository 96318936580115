import React from 'react';

import {
  ProjectAssignmentType,
} from 'nekst-api';
import { SingleContactAssignmentWidget } from './SingleContactAssignmentsWidget';

import styles from './ProjectAssignmentsList.module.scss';
import { useProjectContext } from '../../view/ProjectContext';
import { AssignedRolesHelper } from '../assignedRolesHelper';
import MissingRolesAssignments from '../../shared/MissingRolesAssignments';

export interface ProjectAssignmentsListProps {
  projectId: number,
  assignedRolesHelper: AssignedRolesHelper,
}

export interface RoleAssignment {
  id: number,
  roleId: number,
  roleName: string,
  isEditable: boolean
  isAssignable: boolean
}

export default function ProjectAssignmentsList(
  props: ProjectAssignmentsListProps,
) {
  const { assignments } = useProjectContext();

  const transactionParties = assignments.filter(
    (v) => v.type === ProjectAssignmentType.TRANSACTION_PARTY,
  );

  if (assignments.length > 0) {
    return (
      <>
        <div className={styles.rows}>
          <h4>Team Members</h4>

          {assignments
            .filter((assignment) => [
              ProjectAssignmentType.TASK_ASSIGNMENT,
              ProjectAssignmentType.VIEWER,
              ProjectAssignmentType.EDITOR,
            ].includes(assignment.type))
            .map((assignment) => {
              return (
                <SingleContactAssignmentWidget
                  projectId={props.projectId}
                  contactAssignments={assignment}
                  key={`assignment-${assignment.personId}`}
                />
              );
            })}
        </div>
        <MissingRolesAssignments
          usedRoles={props.assignedRolesHelper.getRolesAssignedToTasks()}
          projectId={props.projectId}
        />
        <div className={styles.rows}>
          <h4>Transaction parties</h4>
          {transactionParties.length > 0 && transactionParties
            .map((assignment) => {
              return (
                <SingleContactAssignmentWidget
                  projectId={props.projectId}
                  contactAssignments={assignment}
                  key={`assignment-${assignment.personId}`}
                />
              );
            })}
          {transactionParties.length === 0 && (
            <div>No transaction parties</div>
          )}
        </div>
      </>
    );
  } else {
    return null;
  }
}
