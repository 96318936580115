import { ProjectStatus } from "../Types";

export function useProjectStatusHelper() {
  const getLabel = (projectStatus: ProjectStatus) => {
    const map = {
      [ProjectStatus.ACTIVE]: 'Active',
      [ProjectStatus.ARCHIVED]: 'Archived',
      [ProjectStatus.CLOSED]: 'Closed',
      [ProjectStatus.CLOSED_PAID]: 'Closed Paid',
    };

    return map[projectStatus];
  };

  return {
    getLabel,
  };
}
