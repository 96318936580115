import React from 'react';
import { InformationMessageLayoutProps, MessageType } from '../types';
import styles from './InformationMessage.module.scss';

export default function InformationMessageLayoutWeb(
  props: InformationMessageLayoutProps
) {

  let typeClassName;

  switch (props.type) {
    case MessageType.WARNING:
      typeClassName = styles.warning;
      break;
    case MessageType.INFO:
      typeClassName = styles.info;
      break;
    case MessageType.ERROR:
      typeClassName = styles.error;
      break;
  }

  return (
    <div className={`${styles.message} ${typeClassName} ${props.className || ''} ${props.withoutMargin && styles.withoutMargin}`}>
      {props.children}
    </div>
  );
}
