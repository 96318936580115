import React from 'react';
import { get } from 'lodash';

import { useShowContext } from '../uibuilder/show/ShowReactContext';

export interface Props {
  source: string
}

export default function EmailField(props: Props) {
  const { data } = useShowContext();

  const value = get(data, props.source);

  return (
    <a href={`mailto:${value}`}>
      {value}
    </a>
  );
}
