import React, {
  ReactElement,
  useContext,
} from 'react';
import {
  FormFieldsData,
  ShowContext,
  useListContext,
  VisibilityState
} from 'ui-builder';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import { CSS } from '@dnd-kit/utilities';

import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { useOrderingContext } from './OrderedList';
import styles from './RowRepeaterWithSort.module.scss';
import { RowRepeaterProps } from 'features/nekst-widgets';

export interface RowRepeaterLayoutProps extends RowRepeaterProps {
  data: FormFieldsData[];
  level: number;
  isLoading?: boolean;
  parentId?: number;
}

export function OrderingWrapper(
  props: {
    children: ReactElement | (ReactElement | null)[],
    items: { id: number }[],
  },
) {
  const ordering = useOrderingContext();
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 200,
      },
    }),
    useSensor(TouchSensor),
  );

  if (props.items.length > 1) {
    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={async (event: DragEndEvent) => {
          const {
            active,
            over,
          } = event;

          if (over?.id && active.id !== over?.id) {
            const ids = props.items.map((item) => item.id);

            const oldIndex = ids.indexOf(active.id as number);
            const newIndex = ids.indexOf(over.id as number);

            const newSort = arrayMove(ids, oldIndex, newIndex);
            await ordering.updateOrderFunc(newSort);
          }
        }}
      >
        <SortableContext items={props.items} strategy={verticalListSortingStrategy}>
          {props.children}
        </SortableContext>
      </DndContext>
    );
  } else {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {props.children}
      </>
    );
  }
}

type SortableRowContextData = {
  setDisabled: (value: boolean) => void,
}
const SortableRowContext = React.createContext<SortableRowContextData>({
  setDisabled: () => {
    // ignored
  },
});

export const useSortableRowContext = () => useContext(SortableRowContext);

function SortableRow(
  props: {
    children: ReactElement | ReactElement[],
    className: string,
    id: string | number,
  },
) {
  const ordering = useOrderingContext();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: props.id,
    disabled: !ordering.isEnabled,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const rowClasses = [
    props.className,
    styles.row,
  ];

  return (
    <div
      className={rowClasses.join(' ')}
      style={style}
      ref={setNodeRef}
    >
      {ordering.isEnabled && (
        <div className={styles.draggableElement} {...attributes} {...listeners}>&nbsp;</div>
      )}
      {props.children}
    </div>
  );
}

function RowRepeaterLayout(props: RowRepeaterLayoutProps) {
  const idField = (props && props.idField) || 'id';

  const listContext = useListContext();

  const orderingContext = useOrderingContext();

  function filterFunc(item: FormFieldsData) {
    if (props.parentId) {
      return item?.parentTaskRelation?.parentTaskId === props.parentId;
    } else {
      return !item?.parentTaskRelation?.parentTaskId;
    }
  }

  let data: FormFieldsData[];
  if (props.parentIdField) {
    data = props.data ? props.data.filter(filterFunc) : [];
  } else {
    data = props.data || [];
  }

  function getGroupClassName(item: FormFieldsData) {
    if (typeof props.groupClassName === 'function') {
      return (props.groupClassName(item));
    } else {
      return props.groupClassName;
    }
  }

  if (data.length > 0) {
    let visibleItemNumber = 0;

    const showRows = (rows: FormFieldsData[]) => {
      return rows.map((rowData: FormFieldsData) => {
        const visibilityState = listContext.getVisibilityState!(rowData.id);
        if (visibilityState !== VisibilityState.HIDDEN) {
          visibleItemNumber += 1;
          // eslint-disable-next-line react/jsx-no-constructed-context-values
          /* eslint-disable react/jsx-props-no-spreading */
          return (
            <SortableRow
              id={rowData[idField]}
              className={`${getGroupClassName(rowData)} ${visibleItemNumber % 2 === 0 && props.level === 0 ? 'even' : ''}`}
              key={`row-${rowData[idField]}`}
            >
              <React.Fragment key={`row-${rowData[idField]}`}>
                <ShowContext data={rowData}>
                  {React.cloneElement(props.children)}
                </ShowContext>
                {props.parentIdField && (
                  <RowRepeaterLayout
                    {...props}
                    parentId={rowData.id}
                    key={`row-${rowData[idField]}-children`}
                    level={props.level + 1}
                  >
                    {props.children}
                  </RowRepeaterLayout>
                )}
              </React.Fragment>
            </SortableRow>
          );

        } else {
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return null;
        }
      });
    };

    const groups = data!.reduce((
      previous: { [key: string]: FormFieldsData[] },
      current: FormFieldsData,
    ) => {
      const group = orderingContext.getOrderGroupFunc!(current);

      const result = {
        ...previous,
      };

      if (!result[group]) {
        result[group] = [];
      }

      result[group].push(current);

      return result;
    }, {});

    return (
      <div
        className={
          `${props.blockClassName || ''} ${props.isLoading ? 'loading' : ''} level${props.level}`
        }
      >
        {
          Object.keys(groups)
            .map((key) => (
              <OrderingWrapper
                // @ts-ignore
                items={groups[key]}
                key={`group-${key}`}
              >
                {showRows(groups[key])}
              </OrderingWrapper>
            ))
        }
      </div>
    );
  } else {
    return null;
  }
}

export default function RowRepeaterWithSort(props: RowRepeaterProps) {
  const listContext = useListContext();

  const data = listContext.data || [];

  if (listContext.isLoading || listContext.hasVisibleItems!()) {
    return (
      <RowRepeaterLayout
        data={data}
        level={0}
        isLoading={listContext.isLoading}
        parentIdField={props.parentIdField}
        idField={props.idField}
        blockClassName={props.blockClassName}
        groupClassName={props.groupClassName}
        loadMoreButtonClassName={props.loadMoreButtonClassName}
      >
        {props.children}
      </RowRepeaterLayout>
    );
  } else {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <div className="no-messages">
        {props.noDataMessage}
      </div>
    );
  }
}
