import React from 'react';
import { ReactNode } from 'react';

import { SimpleBlock, Text } from 'ui-builder';

import styles from './FormText.module.scss';

export function FormText(
  props: {
    children: ReactNode
  }
) {
  return (
    <SimpleBlock className={styles.message}>
      <Text>
        {props.children}
      </Text>
    </SimpleBlock>
  )
}
