import Resizer from 'react-image-file-resizer';
import useImageHelper from './imageHelper';

export enum ImageResizerOutput {
  BASE64 = 'base64',
  BLOB = 'blob',
}

export default function useImageResizer() {

  const imageHelper = useImageHelper();
  const resizeImage = async (
    file: Blob,
    width: number,
    height: number,
    output: ImageResizerOutput,
  ) => {
    const originalSize = await imageHelper.getImageSize(file);

    const widthRate = width / originalSize.width;

    const heightRate = height / originalSize.height;

    const rate = Math.max(widthRate, heightRate);

    if (rate < 1) {
      return new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          originalSize.width * rate,
          originalSize.height * rate,
          'JPEG',
          100,
          0,
          (uri) => {
            resolve(uri as Blob);
          },
          output,
        );
      });
    } else if (output === ImageResizerOutput.BLOB) {
      return file;
    } else {
      return imageHelper.toDataUrl(file);
    }
  };

  return {
    resizeImage,
  };
}
