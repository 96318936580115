/* istanbul ignore file */
import React from 'react';
import { FormButtonLayoutProps } from 'ui-builder';
import Button, { ButtonColor, ButtonSize } from '../../button/Button';

function SubmitButtonLayout(props: FormButtonLayoutProps) {
  return (
    <Button
      isFilled={props.isFilled}
      text={props.text}
      size={props.size || ButtonSize.BIG}
      color={props.color || ButtonColor.GREEN}
      iconType={props.iconType}
      onClick={props.onClick}
      dataKey={props.dataKey}
      isDisabled={props.isDisabled}
      isSubmitButton
    />
  );
}

export default SubmitButtonLayout;
