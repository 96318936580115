import { useNavigate, useParams } from 'react-router-dom';

export default function useLaunchFromContractNavigator() {

  const navigator = useNavigate();

  const params = useParams();

  const getProjectId = (projectId?: number) => {
    return projectId || parseInt(params.id!, 10);
  };

  const openUpdateProjectPage = (projectId?: number) => {
    navigator(`/transactions/${getProjectId(projectId)}/launchfromcontract/update`);
  };

  const openVerifyContractDataPage = (projectId?: number) => {
    navigator(`/transactions/${getProjectId(projectId)}/launchfromcontract/verify`);
  }

  const openEditDetailsBoxesPage = (projectId?: number) => {
    navigator(`/transactions/${getProjectId(projectId)}/launchfromcontract/detailsboxes`);
  }

  const openSelectGroupsPage = (projectId?: number) => {
    navigator(`/transactions/${getProjectId(projectId)}/launchfromcontract/groups`);
  }

  const openKeyDatesPage = (projectId?: number) => {
    navigator(`/transactions/${getProjectId(projectId)}/launchfromcontract/keydates`);
  }

  const openAllTasksPage = (projectId?: number) => {
    navigator(`/transactions/${getProjectId(projectId)}/launchfromcontract/tasks`);
  }

  const openProjectPage = (projectId?: number) => {
    navigator(`/transactions/${getProjectId(projectId)}?afterLaunch=1`);
  }

  const openTransactionPartiesPage = (projectId?: number) => {
    navigator(`/transactions/${getProjectId(projectId)}/launchfromcontract/parties`);
  }

  return {
    openUpdateProjectPage,
    openEditDetailsBoxesPage,
    openSelectGroupsPage,
    openKeyDatesPage,
    openAllTasksPage,
    openProjectPage,
    openTransactionPartiesPage,
    openVerifyContractDataPage,
  };
}
