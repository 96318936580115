import { BaseInputProps, Dropdown, useFormContext } from 'ui-builder';
import { Plan, usePlansService } from 'nekst-api';
import React, { useState } from 'react';
import { get } from 'lodash';
import useDataLoader from 'data-loader';

export interface ProjectPlanOfTypeDropdownProps extends BaseInputProps<number> {
  projectTypeSource: string;
  withContractTemplateAssigned?: boolean;
}

export function ProjectPlanOfTypeDropdown(props: ProjectPlanOfTypeDropdownProps) {
  const planService = usePlansService();

  const [plans, setPlans] = useState<Plan[]>();

  const formContext = useFormContext();

  const projectType = get(formContext.data, props.projectTypeSource);

  const { loading } = useDataLoader(
    async () => {
      if (projectType) {
        const allPlans = await planService.getAllTeamPlans(projectType);

        return allPlans
          .filter((item) => {
            if (props.withContractTemplateAssigned) {
              return !!item._detailsFieldsSet?.contractTemplateId;
            } else {
              return true;
            }
          })
      } else {
        return [];
      }
    },
    setPlans,
    projectType,
  );

  let placeholder;

  if (!loading) {
    if (plans?.length) {
      placeholder = 'Choose Plan';
    } else if (projectType) {
      placeholder = 'No plans available for chosen Transaction Type';
    } else {
      placeholder = 'Please select a Transaction Type first';
    }
  } else {
    placeholder = 'Loading...';
  }

  const getLabel = (item: Plan) => {
    let name = item.name;

    if (item._detailsFieldsSet) {
      name += ` (${item._detailsFieldsSet.name})`;
    }

    return name;
  }

  return (
    <Dropdown
      {...props}
      options={
        !loading && plans
          ? plans.map((item) => ({
            value: item.id,
            label: getLabel(item),
          }))
          : []
      }
      placeholder={placeholder}
    />
  );
}
