import { useListContextRequired } from 'ui-builder';
import { PlanTask, usePlanTasksService } from 'nekst-api';
import { useMemo } from 'react';

export function useTasksGroupsHelper() {

  const listContext = useListContextRequired<PlanTask>();

  const planTasksService = usePlanTasksService();

  const groups = useMemo(function () {
    let result = Object.values(
      listContext.data.reduce((acc, task) => {
        const groupId = task.groupId || 0;

        if (!acc[groupId]) {
          acc[groupId] = [];
        }
        acc[groupId].push(task);
        return acc;
      }, {} as Record<number, PlanTask[]>)
    );

    result = result.sort((a, b) => {
      const aGroupId = a[0].groupId || 0;

      const bGroupId = b[0].groupId || 0;

      if (aGroupId === 0) {
        return 1;
      } else if (bGroupId === 0) {
        return -1;
      } else {
        return planTasksService.getTasksCompareFunc()(a[0], b[0]);
      }
    });

    return result;
  }, [listContext.version]);

  return {
    groups,
    loading: listContext.isLoading,
  };
}
