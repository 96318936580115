import React, { useMemo } from 'react';
import {
  useProjectTasksMassAssignService,
  AbstractTask,
  ContactAssignments,
  ProjectAssignmentType,
} from 'nekst-api';
import { ValidationSchema } from 'validation-schema';
import {
  FormTemplate,
  AfterSubmitFunc,
  FormFieldsData,
  useMassUpdateServiceRequired, CheckboxGroup,
} from 'ui-builder';
import { useProjectContext } from '../../../../ProjectContext';
import { PopupText, Separator } from 'features/nekst-widgets';
import { useAuthenticationLoader } from '../../../../../../authentication/AuthenticationContext';

export interface AssignToFormProps {
  afterSubmitFunc?: AfterSubmitFunc,
}

export default function AssignToForm(props: AssignToFormProps) {

  const { assignments } = useProjectContext();

  const { currentUser } = useAuthenticationLoader();

  const massAssignService = useProjectTasksMassAssignService();
  const massUpdateService = useMassUpdateServiceRequired();

  const getFullName = (assignment: ContactAssignments) => {
    let result = `${assignment.profile.name.firstName} ${assignment.profile.name.lastName}`;
    if (assignment.personId === currentUser.id) {
      result += ' (You)';
    }

    return result;
  };

  const taskAssigneesOptions = useMemo(() => {
    return assignments
      .filter((assignment) => assignment.type === ProjectAssignmentType.TASK_ASSIGNMENT)
      .map((assignment) => ({
        value: assignment.personId!,
        label: getFullName(assignment),
      }));
  }, [assignments.length]);

  const validationSchema: ValidationSchema = {
    usersIds: {
      type: 'array',
      constraints: {
        required: {
          value: true,
          message: 'At least one team member must be selected to submit the form',
        },
      },
    },
  };

  const submitFunc = async (data: FormFieldsData) => {
    return await massAssignService.massAssign(
      massUpdateService.getCheckedIds(),
      data.usersIds as number[],
    ) as AbstractTask[];
  };

  return (
    <FormTemplate
      title="Assign Tasks To"
      submitFormFunc={submitFunc}
      afterSubmitFunc={props.afterSubmitFunc}
      validationSchema={validationSchema}
    >
      <PopupText>
        Select team members to be assigned the selected tasks.
      </PopupText>
      <Separator />
      <CheckboxGroup
        options={taskAssigneesOptions}
        hideLabel
        source="usersIds"
      />
    </FormTemplate>
  );
}
