import React from 'react';
import { useShowContext } from '../../../shared/uibuilder/show/ShowReactContext';

import styles from './IsKeyDate.module.scss';

export interface IsKeyDateFieldProps {
  source: string
}

export default function IsKeyDateField(props: IsKeyDateFieldProps) {
  const context = useShowContext();

  if (context.data) {
    const value = context.data[props.source] as boolean;

    if (value) {
      return (
        <i className={styles.icon} title="Key Date Task">
          &nbsp;
        </i>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
}
