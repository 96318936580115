import React from 'react';

import styles from './VisibilityField.module.scss';

export default function VisibilityFieldLayout(
  props: {
    value: boolean
  },
) {

  return (
    <span
      className={`${styles.field} ${props.value ? styles.hidden : styles.visible}`}
      title={props.value ? 'Hidden' : 'Visible'}
    >
      &nbsp;
    </span>
  );
}
