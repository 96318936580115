import React from 'react';
import { SmsOptInStatusLayoutProps } from '../types';

import styles from './SmsOptInStatusLayout.module.scss';
import { RequestOptInButton } from 'features/nekst-widgets';

export default function SmsOptInStatusLayoutWeb(props: SmsOptInStatusLayoutProps) {
  return (
    <div className={styles.optInStatus}>
      <div className={styles[props.messageType]}>
        {props.message}
      </div>
      {props.showRequestOptInButton && (<RequestOptInButton person={props.recipient} />)}
    </div>
  );
}
