import React from 'react';
import { BaseHistoryEntryData, PersonData, ProjectAssignmentType, RoleData } from 'nekst-api';
import RecordLayout, { RecordLayoutProps } from './RecordLayout';
import PersonLink from './PersonLink';
import ProjectRoleLink from './ProjectRoleLink';
import useTextHelper from './pageDefiner';
import ProjectLink from './ProjectLink';
import { HistoryEventIconType } from './HistoryEventIcon';

interface ProjectAssignmentCreatedData extends BaseHistoryEntryData {
  assignment: {
    person: PersonData,
    role: RoleData,
    type: ProjectAssignmentType,
  };
}

export default function ProjectAssignmentCreatedRecordLayout(
  props: RecordLayoutProps<ProjectAssignmentCreatedData>,
) {
  const textHelper = useTextHelper();
  return (
    <RecordLayout
      text={(
        <>
          <PersonLink person={props.record.data.assignment.person} />
          {' was added under the role of '}
          <ProjectRoleLink role={props.record.data.assignment.role} />
          {' by '}
          <PersonLink person={props.record.data.person!} />
          {!textHelper.isProjectHistoryPage() && (
            <>
              {' ('}
              <ProjectLink project={props.record.data.project} />
              )
            </>
          )}
          .
        </>
      )}
      date={props.record.when}
      iconType={HistoryEventIconType.ASSIGNMENT_CREATED}
    />
  );
}
