/* istanbul ignore file */
import React, { ReactElement } from 'react';

import styles from './BorderedBox.module.scss';
import Right from '../Right';

export interface BorderedBoxProps {
  title: string
  children: ListOrSingle<ReactElement | boolean>
  className?: string
  headerRight?: ReactElement,
  dataKey?: string
}

function BorderedBox(props: BorderedBoxProps) {
  return (
    <div className={`${styles.box} ${props.className || ''}`} data-key={props.dataKey}>
      {props.headerRight && (
        <Right>
          {props.headerRight}
        </Right>
      )}
      <div className={styles.title}>
        {props.title}
      </div>
      <div className={styles.content}>
        {props.children}
      </div>
    </div>
  );
}

export default BorderedBox;
