import { ValidationSchema } from '../../../shared/uibuilder/validation';

export default function useServiceProviderValidationSchema() {
  return {
    name: {
      type: 'string',
      constraints: {
        required: true,
        minLength: 3,
        maxLength: 50,
      },
    },
  } as ValidationSchema;
}
