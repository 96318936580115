import React, { ReactElement } from 'react';

import styles from './StepProgressBar.module.scss';

export type Step = {
  id: number,
  name: string,
  onClick: () => void,
  disabled?: boolean,
}

export interface StepProgressBarProps {
  steps: Step[];
  currentStep: number;
}

enum StepState {
  NOT_ACTIVE = 'notActive',
  ACTIVE = 'active',
  COMPLETED = 'completed'
}

export interface StepCircleProps {
  name: string;
  state: StepState;
  disabled?: boolean;

  onClick: () => void,
}

function StepCircle(props: StepCircleProps) {
  return (
    <button type="button" className={styles.circleBlock} disabled={props.disabled}
            onClick={props.onClick}>
      <div className={styles.title}>
        {props.name}
      </div>
      <div className={`${styles.circle}`}>
        &nbsp;
      </div>
      <div className={`${styles.inner} ${props.state}`}>
        &nbsp;
      </div>
    </button>
  );
}

export default function StepProgressBar(props: StepProgressBarProps) {
  const items: ReactElement[] = [];

  props.steps.forEach((step: Step, index: number) => {
    function getState() {
      if (step.id < props.currentStep) {
        return StepState.COMPLETED;
      } else if (step.id === props.currentStep) {
        return StepState.ACTIVE;
      } else {
        return StepState.NOT_ACTIVE;
      }
    }

    items.push(
      <StepCircle
        name={step.name}
        state={getState()}
        // eslint-disable-next-line react/no-array-index-key
        key={`step${step.id}`}
        onClick={step.onClick}
        disabled={step.disabled}
      />,
    );
  });

  const currentIndex = props.steps.findIndex((step) => step.id === props.currentStep);

  return (
    <div className={`${styles.block} total${props.steps.length}`}>
      {items}
      <div className={`${styles.line} current${currentIndex + 1}`}>
        <div className={styles.innerLine}>
          &nbsp;
        </div>
      </div>
    </div>
  );
}
