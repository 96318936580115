import { BadRequestError } from 'request-sender';
import { useBackendApi } from './backendApi';

export enum ExistingTasksDecision {
  REMOVE = 'REMOVE',
  LEFT_AS_IS = 'LEFT_AS_IS'
}

export interface LaunchProjectService {
  launch: (
    projectId: number,
    decision?: ExistingTasksDecision,
  ) => Promise<void>;
}

export class NoDecisionMadeError extends Error {
  constructor() {
    super('');
    Object.setPrototypeOf(this, NoDecisionMadeError.prototype);
  }
}

export function useLaunchProjectService(): LaunchProjectService {
  const projectsApi = useBackendApi();

  const launch = async (
    projectId: number,
    decision?: ExistingTasksDecision,
  ) => {
    try {
      await projectsApi.post(
        `/projects/${projectId}/launch`,
        decision ? {
          existingTasksDecision: decision,
        } : undefined,
      );
    } catch (e) {
      if (e instanceof BadRequestError) {
        if (e.getErrorCode() === 'NO_DECISION_MADE') {
          throw new NoDecisionMadeError();
        } else {
          throw e;
        }
      } else {
        throw e;
      }
    }
  };

  return {
    launch,
  };
}
