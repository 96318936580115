import { usePopupManager } from 'ui-builder';
import { useEventsSubscriber, TASK_COMPLETED } from 'nekst-api';

export default function ClosePopupOnTaskCompletion() {

  const popupManager = usePopupManager();

  useEventsSubscriber(
    'ClosePopupOnTaskCompletion',
    {
      [TASK_COMPLETED]: () => popupManager.closePopup(true),
    },
  );

  return null;
}
