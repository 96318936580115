import {
  HistoryEntry,
  HistoryEvent
} from './projectHistoryService';
import { useBackendApi } from './backendApi';
import { useObjectDatesMapper } from './Helper';

export interface TeamMemberHistoryFilter {
  eventTypes: {
    selectAll: boolean,
    selected: HistoryEvent[]
  };
  text?: string;
}

interface LoadMorePaginationResponse<DataType> {
  data: DataType[],
  nextPageToken: number
}

export function useTeamMemberHistoryService() {
  const backendApi = useBackendApi();

  const { mapList } = useObjectDatesMapper<HistoryEntry>([], ['when']);

  const getHistoryEvents = async (
    personId: number,
    filter: TeamMemberHistoryFilter | null,
    timeFrom?: number,
  ) => {
    const requestParams = [];
    if (filter?.eventTypes?.selected.length) {
      requestParams.push(`types=${filter.eventTypes.selected.join(',')}`);
    }

    if (filter?.text) {
      requestParams.push(`text=${encodeURIComponent(filter.text)}`);
    }

    if (timeFrom) {
      requestParams.push(`timeStampFrom=${timeFrom}`);
    }

    const response = await backendApi.get(
      `/people/${personId}/history?${requestParams.join('&')}`,
    ) as LoadMorePaginationResponse<Record<string, any>>;

    return {
      data: mapList(response.data),
      nextPageToken: response.nextPageToken,
    };
  };

  return {
    getHistoryEvents,
  };
}
