/* istanbul ignore file */
import React, { ReactNode } from 'react';
import { ErrorsWrapper, FormLayoutProps, FormRows, useFormContext } from 'ui-builder';
import { Popup } from '../../../Popup';
import { usePopupLayoutContextRequired } from '../PopupLayoutContext';

export interface PopupFormLayoutProps extends FormLayoutProps {
}

function PopupFormLayout(props: PopupFormLayoutProps) {
  const formContext = useFormContext();

  const context = usePopupLayoutContextRequired();

  let buttons: ReactNode;
  if (!formContext.isDisabled!()) {
    buttons = props.buttons;
  } else {
    buttons = undefined;
  }

  return (
    <form className={`form dark ${props.className}`} autoComplete="chrome-off">
      <Popup
        title={props.title || ''}
        content={
          <ErrorsWrapper name="__form">
            <FormRows>{props.children}</FormRows>
          </ErrorsWrapper>
        }
        buttons={buttons}
        isCentered={context.isCentered || false}
        isClosable={context.isClosable}
      />
    </form>
  );
}

export default PopupFormLayout;
