import React from 'react';
import MainBlock, { ColumnPosition } from '../shared/web/layout/MainBlock';
import CalendarTasksView from './CalendarTasksView';

export default function CalendarPage() {
  return (
    <MainBlock
      title="Calendar"
      columnContent={<>&nbsp;</>}
      columnPosition={ColumnPosition.RIGHT}
    >
      <CalendarTasksView />
    </MainBlock>
  );
}
