import React from 'react';
import { Outlet } from 'react-router-dom';
import HttpErrorsBoundary from './HttpErrorsBoundary';

export default function ErrorBoundaryLayout() {
  return (
    <HttpErrorsBoundary>
      <Outlet />
    </HttpErrorsBoundary>
  );
}
