import React from 'react';
import { ToggleWrapper } from './ToggleWrapper';
import { ToggledFeature } from './featureToggleService';

export function TasksGroupsToggleWrapper(
  props: {
    children: React.ReactNode;
  }
) {
  return (
    <ToggleWrapper feature={ToggledFeature.TASKS_GROUPS}>
      {props.children}
    </ToggleWrapper>
  )
}
