import React from 'react';
import useAiTrainerService, { AiTrainingEntry } from '../aiTrainerService';
import useDataLoader from 'data-loader';
import { useParams } from 'react-router-dom';
import InnerBlock from '../../../../shared/web/layout/InnerBlock';
import { ViewParseResultWidget } from '../ViewParseResultWidget/ViewParseResultWidget';
import { LoadingAnimation } from 'ui-builder';
import { AccessChecker } from 'authorization-scope';
import { TeamPermission } from 'nekst-api';
import AiTrainerBreadcrumbs from '../AiTrainerBreadcrumbs';
import { Breadcrumb } from '../../../../shared/web/layout/Breadcrumbs';

export default function UpdateTrainingEntryPage() {
  const [entry, setEntry] = React.useState<AiTrainingEntry>();

  const aiTrainerService = useAiTrainerService();

  const params = useParams<{ id: string }>();

  const id = parseInt(params.id!, 10);

  useDataLoader(
    async () => {
      return await aiTrainerService.getEntry(id);
    },
    setEntry,
    id,
  );

  if (entry) {
    return (
      <AccessChecker permissionToCheck={TeamPermission.MANAGE_AI_TRAINER}>
        <AiTrainerBreadcrumbs>
          <Breadcrumb text={`Update AI training entry ${entry.fileName} (id: ${entry.id})`} />
        </AiTrainerBreadcrumbs>
        <InnerBlock title={`Update AI training entry ${entry.fileName}`} className="nekst">
          <ViewParseResultWidget
            entry={entry}
            onSubmitFunc={async (updatedEntry) => {
              await aiTrainerService.updateEntry(id, {
                people: updatedEntry.people,
                prompts: updatedEntry.prompts,
                comment: updatedEntry.comment,
                usedFor: updatedEntry.usedFor,
              });
            }}
          />
        </InnerBlock>
      </AccessChecker>
    )
  } else {
    return (<LoadingAnimation />);
  }
}
