import { useBlocker } from 'react-router';
import { useCallback, useEffect, useRef } from 'react';
import { Location, useBeforeUnload } from 'react-router-dom';

type BlockerFunction = (args: {
  currentLocation: Location;
  nextLocation: Location;
}) => boolean;

function usePrompt(onLocationChange: BlockerFunction) {
  const blocker = useBlocker(onLocationChange);
  const prevState = useRef(blocker.state);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);
}

interface BlockerFunctionParams {
  nextLocation: Location,
}

export function BlockerWidget(props: { isSaved: boolean }) {
  useBeforeUnload(
    useCallback(
      (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = '';
      },
      [],
    ),
    { capture: true },
  );

  const onLocationChange = useCallback(
    ({ nextLocation }: BlockerFunctionParams) => {
      if (!props.isSaved) {
        return !window.confirm(
          'By exiting before saving the form, all changes (or new entry) will be lost.',
        );
      } else {
        return false;
      }
    },
    [props.isSaved],
  );

  usePrompt(onLocationChange);

  return null;
}
