import React from 'react';
import { get } from 'lodash';
import { useShowContext } from '../../shared/uibuilder/show/ShowReactContext';
import { formatBytes } from '../../files/fileSizeHelper';

export default function FileSizeField(
  props: {
    source: string,
    // eslint-disable-next-line react/no-unused-prop-types
    label: string,
  },
) {
  const showContext = useShowContext();

  const value = get(showContext.data, props.source);

  if (value) {
    return (<>{formatBytes(value)}</>);
  } else {
    return (<>-</>);
  }
}
