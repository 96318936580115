function useStateStorage<T>(key: string) {
  const getValue = (defaultValue: any) => {
    const serialized = localStorage.getItem(key);

    let value;
    if (serialized != null) {
      value = JSON.parse(serialized) as T;
    } else {
      value = defaultValue;
    }

    return value;
  };

  const setValue = (newValue: T | null) => {
    localStorage.setItem(key, JSON.stringify(newValue));
  };

  return {
    getValue,
    setValue,
  };
}

export default useStateStorage;
