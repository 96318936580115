import { HistoryEntry, useObjectDatesMapper } from 'nekst-api';
import useProjectsApi from '../../projectsApi';

export default function useProjectTaskHistoryService() {

  const projectsApi = useProjectsApi();

  const datesMapper = useObjectDatesMapper<HistoryEntry>([], ['when']);
  const getAllTasksEvents = async (taskId: number) => {
    const result = await projectsApi.get(`/tasks/${taskId}/history`);

    return datesMapper.mapList(result);
  };

  return {
    getAllTasksEvents,
  };
}
