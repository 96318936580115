import React from 'react';
import {
  PaymentMethodProvider,
  SubscriptionStatus,
  TeamPermission,
  useBillingService
} from 'nekst-api';
import { useBillingContext } from './BillingPageContext';
import { useAuthorizationChecker } from 'authorization-scope';
import { Button, ButtonColor, Text } from 'ui-builder';

import styles from './BillingSettings.module.scss';

export default function OpenBillingPortalButton() {
  const billingService = useBillingService();

  const { isGranted } = useAuthorizationChecker();

  const billingData = useBillingContext()
    .getData();

  const isSubscriptionExist = billingData.teamBilling.status !== SubscriptionStatus.NOT_FOUND;

  if (isGranted(TeamPermission.MANAGE_BILLING) && isSubscriptionExist) {

    if (billingData.teamBilling.paymentMethodProvider === PaymentMethodProvider.STRIPE || !billingData.teamBilling.paymentMethodProvider) {
      const isSubscriptionActive = billingData?.teamBilling.status === SubscriptionStatus.ACTIVE;

      return (
        <Button
          text="Manage Subscription & Billing Information"
          color={isSubscriptionActive ? ButtonColor.GREEN : ButtonColor.YELLOW}
          isFilled
          onClick={async () => {
            window.location.href = await billingService.createBillingPortalSession();
          }}
        />
      );
    } else {
      return (
        <Text className={styles.message}>
          Your subscription is managed by the iOS App Store. Manage your subscriptions via your iOS device.
        </Text>
      );
    }
  } else {
    return null;
  }
}
