import React from 'react';

import { ViewPlanProps } from './ViewPlan';
import usePlanTaskForms from '../tasks/CreateUpdate/planTaskForms';
import { ButtonSize, OptionSelectionButton } from 'ui-builder';
import { TaskType, TeamPermission } from 'nekst-api';
import { PlanPermission, useAuthorizationChecker } from 'authorization-scope';

export default function CreateTasksButton(props: ViewPlanProps) {
  const { isGranted } = useAuthorizationChecker();

  const planTaskForms = usePlanTaskForms();

  if (isGranted(PlanPermission.UPDATE)) {
    return (
      <OptionSelectionButton<TaskType>
        text="Add task"
        options={[
          {
            value: TaskType.STANDARD,
            label: 'Add standard task',
            onSelected: () => {
              planTaskForms.openCreateStandardTaskForm(props.id);
            }
          },
          {
            value: TaskType.EMAIL,
            label: 'Add email task',
            onSelected: () => {
              planTaskForms.openCreateEmailTaskForm(props.id);
            },
          },
          isGranted(TeamPermission.USE_SMS_TASKS_FEATURE) && {
            value: TaskType.SMS,
            label: 'Add SMS task',
            onSelected: () => {
              planTaskForms.openCreateSmsTaskForm(props.id);
            }
          }
        ]}
        size={ButtonSize.MEDIUM}
      />
    );

  } else {
    return null;
  }
}
