import React from 'react';
import {
  TextLayoutProps,
  TextInputProps,
  InputLayoutIcon,
  TextInput,
  useInputHelper,
} from 'ui-builder';
import BaseInputLayout from '../layout/BaseInputLayout';

export {
  InputLayoutIcon,
};

export type {
  TextLayoutProps,
  TextInputProps,
};

export default TextInput;

export function TextFormField(props: {
  source: string,
  label: string,
  value?: string
}) {
  const helper = useInputHelper<string>(props);
  return (
    <BaseInputLayout name={props.source} label={props.label}>
      <i>
        {helper.getValue()}
      </i>
    </BaseInputLayout>
  );
}
