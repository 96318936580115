import { useEventsPublisher } from 'nekst-api';
import useStateStorage from '../shared/storage/stateStorage';

export const ACCESS_TOKEN_UPDATED = 'ACCESS_TOKEN_UPDATED';

export default function useUserSessionStorage() {
  const stateStorage = useStateStorage<string>('session');

  const impersonatedStateStorage = useStateStorage<string>('impersonated_session');

  const eventsPublisher = useEventsPublisher();
  const saveToken = (newValue: string) => {
    stateStorage.setValue(newValue);
    eventsPublisher.publish(ACCESS_TOKEN_UPDATED, {});
  };

  const saveImpersonationToken = (newValue: string) => {
    impersonatedStateStorage.setValue(newValue);
    eventsPublisher.publish(ACCESS_TOKEN_UPDATED, {});
  };

  const getToken = (): string | null => {
    return impersonatedStateStorage.getValue(null) || stateStorage.getValue(null);
  };

  const hasToken = (): boolean => {
    return !!getToken();
  };

  const hasImpersonatedToken = (): boolean => {
    return impersonatedStateStorage.getValue(null);
  };

  const removeToken = () => {
    if (hasImpersonatedToken()) {
      impersonatedStateStorage.setValue(null);
    } else {
      stateStorage.setValue(null);
    }
    eventsPublisher.publish(ACCESS_TOKEN_UPDATED, {});
  };

  return {
    saveToken,
    saveImpersonationToken,
    getToken,
    hasToken,
    hasImpersonatedToken,
    removeToken,
  };
}
