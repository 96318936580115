import React, { ReactElement } from 'react';
import { useShowContext } from 'ui-builder';
import { Project, ProjectStatus } from 'nekst-api';
import ProjectIsArchivedMessage from './ProjectIsArchivedMessage';

export interface ArchivedProjectCheckerProps {
  children: ReactElement | ReactElement[];
}

export default function ArchivedProjectChecker(props: ArchivedProjectCheckerProps) {
  const { data } = useShowContext<Project>();

  if (data) {
    if (data.status !== ProjectStatus.ARCHIVED) {
      return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {props.children}
        </>
      );
    } else {
      return (
        <ProjectIsArchivedMessage />
      );
    }
  } else {
    return null;
  }
}
