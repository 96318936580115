import React from 'react';
import styles from './Buttons.module.scss';

export default function BaseCircleButton(props: {
  className: string,
  onClick: () => Promise<void>,
}) {
  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <span
      className={`${styles.circleButton} ${props.className}`}
      onClick={props.onClick}
    >
      &nbsp;
    </span>
  );
}
