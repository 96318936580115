import React from 'react';
import { Route } from 'react-router-dom';
import { ApplicationModule } from '../shared/applicationModule';
import CalendarPage from './CalendarPage';
import { CalendarColors } from './CalendarListLayout';

export default function useCalendarModule(): ApplicationModule {
  const getRouter = () => {
    return (
      <>
        <Route path="/calendar" element={<CalendarPage />} />
        <Route path="/test/calendarcolors" element={<CalendarColors />} />
      </>
    );
  };

  return {
    getRouter,
    name: 'calendar',
  };
}
