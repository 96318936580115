import React from 'react';
import useSetDueDateForm from './SetDueDateForm';
import {
  ButtonColor,
  ButtonSize,
  Button,
  useMassUpdateServiceRequired
} from 'ui-builder';

export default function SetDueDateButton() {
  const form = useSetDueDateForm();

  const massUpdateService = useMassUpdateServiceRequired();

  return (
    <Button
      text="Due"
      isDisabled={massUpdateService.getCheckedIds().length === 0}
      onClick={async () => {
        form.openForm();
      }}
      color={ButtonColor.GREEN}
      isFilled
      size={ButtonSize.SMALL}
      isFlat
    />
  );
}
