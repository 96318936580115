import React, { ReactElement } from 'react';
import { usePopupManager } from 'ui-builder';

import { PopupFormProps } from './types';
import Layout from './Layout';
import PopupLayoutContext from './Layout/PopupLayoutContext';

export function PopupForm(
  props: PopupFormProps
) {
  return (
    <PopupLayoutContext isCentered={props.isCentered} isClosable={props.isClosable}>
      <Layout {...props} />
    </PopupLayoutContext>
  );
}

export function usePopupFormManager() {
  const popupManager = usePopupManager();

  const openForm = (form: ReactElement, isCentered?: boolean, openSecondPopup?: boolean) => {
    popupManager.openPopup(
      <PopupForm isCentered={isCentered} isClosable={true}>
        {form}
      </PopupForm>,
      openSecondPopup,
    );
  };

  const openNotClosableForm = (form: ReactElement, isCentered?: boolean) => {
    popupManager.openPopup(
      <PopupForm isCentered={isCentered} isClosable={false}>
        {form}
      </PopupForm>,
    );

  };

  const closePopup = () => {
    popupManager.closePopup();
  };

  return {
    openForm,
    openNotClosableForm,
    closePopup,
  };
}
