import { useCacheService } from 'nekst-api';
import useProjectsApi from './projectsApi';
import Record from './history/Record';

export default function useTeamConfigurationService() {

  const nekstApi = useProjectsApi();

  const cacheService = useCacheService('TeamConfigurationService');

  const getConfiguration = async () => {
    return cacheService.get<Record<string, any>>(
      'configuration',
      async () => {
        return await nekstApi.get('/teamconfiguration') as Record<string, any>;
      },
    );
  };

  const updateConfiguration = async (configuration: Record<string, any>) => {
    const result = await nekstApi.patch('/teamconfiguration', configuration) as Record<any, any>;

    cacheService.clear('configuration');

    return result;
  };

  return {
    getConfiguration,
    updateConfiguration,
  };
}
