import React from 'react';
import {
  Button,
  ButtonSize,
  List,
  TextField,
  usePopupManager,
  useShowContextRequired
} from 'ui-builder';
import GridLayout from '../../../../shared/uibuilder/list/GridLayout';
import Cell from '../../../../shared/uibuilder/list/Cell';
import {
  ConfirmDeletePopup,
  IconButton, OpenFormIconButton,
  IconType,
  usePopupFormManager
} from 'features/nekst-widgets';
import { useAiTrainingEntryContext } from './ViewParseResultWidget';
import UpdatePersonParseResultForm from './PersonParseResult/UpdatePersonParseResultForm';
import AddPersonParseResultForm from './PersonParseResult/AddPersonParseResultForm';
import { EditContextButton } from './PreviewDocument';
import InnerBlock from '../../../../shared/web/layout/InnerBlock';
import { useProjectRolesLoader } from 'features/tasks-forms';
import { get } from 'lodash';
import { PersonParseResult } from 'nekst-api';

function AddressField(props: { label: string }) {
  const showContext = useShowContextRequired<PersonParseResult>();

  const personData = showContext.data!;

  return [
    personData.addressLine1,
    personData.addressLine2,
    personData.city,
    personData.state,
    personData.zip
  ].filter((item) => !!item)
    .join(', ');
}

function UpdatePersonButton() {
  const personContext = useShowContextRequired<PersonParseResult>();

  return (
    <OpenFormIconButton
      form={<UpdatePersonParseResultForm
        data={personContext.data!}
      />}
    />
  );
}

function UpdatePersonContextButton() {
  const entryContext = useAiTrainingEntryContext();

  const showContext = useShowContextRequired<PersonParseResult>();

  const person = showContext.data!;

  return (
    <EditContextButton
      fileText={entryContext.entry.fileText}
      usedRows={showContext.data!.context}
      onContextUpdated={(newValue) => {
        entryContext.updateContact(
          person.firstName,
          person.lastName,
          {
            ...showContext.data!,
            context: newValue,
          }
        );
      }}
    />
  );

}

function DeletePersonButton() {
  const popupManager = usePopupManager();

  const entryContext = useAiTrainingEntryContext();

  const personContext = useShowContextRequired<PersonParseResult>();

  return (
    <IconButton
      onClick={() => {
        popupManager.openPopup(
          <ConfirmDeletePopup
            deleteFunc={async () => {
              entryContext.deleteContact(
                personContext.data!.firstName,
                personContext.data!.lastName
              );
            }}
            message="Are you sure you want to delete this person?"
          />
        );
      }}
      type={IconType.X}
    />
  );
}

function AddNewPersonButton() {
  const popupFormManager = usePopupFormManager();

  return (
    <Button
      text="+ Add New Person"
      onClick={() => {
        popupFormManager.openForm(
          <AddPersonParseResultForm />
        );
      }}
      isFlat
      isFilled
      size={ButtonSize.SMALL}
    />
  );
}

function ProjectRoleField(props: { source: string, label?: string }) {

  const { roles } = useProjectRolesLoader();

  const showContext = useShowContextRequired();

  const value = get(showContext.data, props.source);

  return value ? roles.find((role) => role.id === value)?.name : '';
}

export default function ParsedPeopleList() {
  const entryContext = useAiTrainingEntryContext();
  return (
    <InnerBlock
      headerSize={4}
      title="Transaction Parties"
      headerRight={<AddNewPersonButton />}
    >
      <List
        getDataFunc={async () => entryContext.entry.people.map((item) => ({
          ...item,
        }))}
        dependencies={[JSON.stringify(entryContext.entry.people)]}
      >
        <GridLayout>
          <TextField source="firstName" />
          <TextField source="lastName" />
          <AddressField label="Address" />
          <TextField source="phoneNumber" />
          <TextField source="companyName" />
          <TextField source="website" />
          <TextField source="email" />
          <ProjectRoleField source="roleId" label="Role" />
          <Cell label="Actions">
            <UpdatePersonButton />
            <UpdatePersonContextButton />
            <DeletePersonButton />
          </Cell>
        </GridLayout>
      </List>
    </InnerBlock>

  );
}
