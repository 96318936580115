import React, { ReactElement } from 'react';

export interface TableDataGridHeadProps {
  children: ReactElement[] | ReactElement;
}

function TableDataGridHead(props: TableDataGridHeadProps) {

  const headCells = props.children && React.Children.toArray(props.children)
    .map((child) => {
      if (React.isValidElement(child)) {
        return (<th key={child.props.source}>{child.props.label}</th>);
      } else {
        return null;
      }
    });

  return (<thead><tr>{headCells}</tr></thead>);
}

export default TableDataGridHead;
