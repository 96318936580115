import React from 'react';
import { SubmitButton, ButtonSize } from 'ui-builder';
import UpdateProjectForm from '../launch/CreateUpdateProject/UpdateProjectForm';

import styles from './UpdateProjectForm.module.scss';
import TextButton from '../history/RecordLayout/TextButton';
import useLaunchProjectNavigator from '../launch/launchProjectNavigator';

interface Props {
  projectId: number,
}

function OpenUpdateProjectPageButton() {
  const launchNavigator = useLaunchProjectNavigator();
  return (
    <TextButton
      text="Edit Launch"
      onClick={() => launchNavigator.openUpdateProjectPage()}
      dataKey="edit-launch-button"
    />
  );
}

export default function Form(props: Props) {
  return (
    <UpdateProjectForm
      title="Transaction Setup"
      projectId={props.projectId}
      buttons={(
        <>
          <SubmitButton text="Update" size={ButtonSize.MEDIUM} />
          <OpenUpdateProjectPageButton />
        </>
      )}
      checkDateUpdates
      className={styles.detailsUpdateForm}
    />
  );
}
