import { ConstraintValidator, ConstraintValue } from '../index';
import useConstraintValidatorHelper from './constaintValidatorHelper';

export interface RegexConstraintValidator extends ConstraintValidator<string> {
}

export default function useRegexConstraintValidator(): RegexConstraintValidator {

  const helperFactory = useConstraintValidatorHelper<string | RegExp>();

  const validate = (
    object: Record<string, any>,
    fieldName: string,
    constraintConfiguration: ConstraintValue<string>,
  ): boolean => {

    const helper = helperFactory.createHelper(
      object,
      fieldName,
      constraintConfiguration,
    );
    const value = helper.getValue();

    if (helper.hasValue()) {
      const constraintValue = helper.getConstraintValue();

      const re = new RegExp(constraintValue);

      if (typeof value === 'string') {
        return !!value.match(re);
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const getErrorMessage = (config: ConstraintValue<string>) => {
    return (typeof config === 'object' && config.message)
      || 'The field value doesn\'t match required format';
  };

  return {
    getName: () => 'regex',
    validate,
    getErrorMessage,
  };
}
