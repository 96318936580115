import { EmailPreviewLayoutProps } from '../types';
import { EmailRecipient } from 'nekst-api';

import DOMPurify from 'dompurify';
import React from 'react';

import styles from './EmailPreview.module.scss';

export default function EmailPreviewLayoutWeb(props: EmailPreviewLayoutProps) {
  const emailPreview = props.preview;

  const { recipients, cc, bcc } = props;
  return (
    <>
     <span>
        Email will be sent from
       {' '}
       {props.sendFromName}
       .
       {' '}
       (To change the sender, go back and update the task.)
        <br />
        <br />
        Please review the email message preview below before sending:
        <br />
        <br />
      </span>
      <div className={styles.emailPreview}>
        <div className={styles.from}>
          <b>{emailPreview.sender.name.fullName}</b>
          {' '}
          &lt;
          {emailPreview.sender.primaryEmail}
          &gt;
        </div>
        <div className={styles.to}>
          To:
          {' '}
          {
            recipients.map(
              (item: EmailRecipient) => {
                return (
                  <span className={styles.recipient}>
                    <b>{item._profile!.name.fullName}</b>
                    {' '}
                    &lt;
                    {item._profile?.primaryEmail}
                    &gt;
                  </span>
                );
              },
            )
          }
        </div>
        {!!cc.length && (
          <div className={styles.cc}>
            Cc:
            {' '}
            {
              cc.map(
                (item: EmailRecipient) => {
                  return (
                    <span className={styles.recipient}>
                      <b>{item._profile!.name.fullName}</b>
                      {' '}
                      &lt;
                      {item._profile?.primaryEmail}
                      &gt;
                    </span>
                  );
                },
              )
            }
          </div>
        )}
        {!!bcc.length && (
          <div className={styles.bcc}>
            Bcc:
            {' '}
            {
              bcc.map(
                (item: EmailRecipient) => {
                  return (
                    <span className={styles.recipient}>
                      <b>{item._profile!.name.fullName}</b>
                      {' '}
                      &lt;
                      {item._profile?.primaryEmail}
                      &gt;
                    </span>
                  );
                },
              )
            }
          </div>
        )}
        <div className={styles.subject}>
          Subject:
          {' '}
          {`${props.taskName} - ${props.projectName}`}
        </div>
        <div
          className={styles.body}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(emailPreview.preview) }}
        />
      </div>
    </>

  );

}
