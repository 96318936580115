import { ContractTemplate } from 'nekst-api';

export default function useExampleContentHelper(
  template: ContractTemplate,
) {


  const getExampleContent = (lines: number[]) => {
    const allLines = template._documentExampleFileText.split('\n');

    return lines
      .sort((a, b) => a - b)
      .map((line, index) => {

        let result = '';
        if (index > 0 && lines[index - 1] < line - 5) {
          result += '..........\n';
          result += '..........\n';
          result += '..........\n';
        }

        return result + allLines[line - 1];
      })
      .join('\n');
  }

  return {
    getExampleContent,
  }
}
