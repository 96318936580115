import { useLocation, useNavigate } from 'react-router-dom';
import { AbstractTask, TASK_DELETED } from './projectTasksService';
import { useEventsSubscriber } from 'event-bus';

export default function useTasksNavigator() {
  const {
    search,
    pathname,
  } = useLocation();

  const navigate = useNavigate();

  const getOpenTaskUri = (taskId: number) => {
    return `${pathname}?taskId=${taskId}`;
  }

  const openTask = (taskId: number) => {
    const params = new URLSearchParams(search);
    params.set('taskId', taskId.toString());
    navigate(getOpenTaskUri(taskId));
  };

  const openProjectTask = (task: AbstractTask) => {
    navigate(`/transactions/${task.projectId}?taskId=${task.id}`);
  };

  const getTaskId = () => {
    const param = new URLSearchParams(search).get('taskId');

    if (param) {
      return parseInt(param, 10);
    } else {
      return null;
    }
  };

  const removeTaskId = () => {
    const params = new URLSearchParams(search);
    params.delete('taskId');
    navigate(pathname);
  }

  useEventsSubscriber('useTasksNavigator', {
    [TASK_DELETED]: (eventData) => {
      const currentTaskId = getTaskId();

      if (currentTaskId === eventData.id) {
        removeTaskId();
      }
    }
  }, [search]);

  return {
    getOpenTaskUri,
    openTask,
    openProjectTask,
    getTaskId,
    removeTaskId,
  };
}
