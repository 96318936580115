import React from 'react';
import { MassUpdateCheckboxLayoutProps } from './types';
import styles from './MassUpdateCheckbox.module.scss';
import { CheckboxInput } from 'ui-builder';

export default function MassUpdateCheckboxLayoutWeb(
  props: MassUpdateCheckboxLayoutProps
) {
  return (
    <CheckboxInput
      source={props.name}
      className={styles.checkbox}
      value={props.value}
      onChangeCallback={props.onChange}
      dataKey="mass-update"
    />
  )
}
