import React from 'react';
import { BlockLayoutProps } from 'ui-builder';
import Left from '../../../web/Left';

export default function LeftLayout(props: BlockLayoutProps) {
  return (
    <Left>
      {props.children}
    </Left>
  );
}
