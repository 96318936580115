import {
  Limit,
  OrderBy,
  PageResponse,
  Permission,
  Project,
  PROJECT_UPDATED,
  ProjectFilter,
  ProjectIdWithName,
  PROJECTS_UPDATED_IN_BULK,
  ProjectStatus,
  ProjectType,
  ProjectUpdateOption,
  SortByByTypes,
} from './Types';
import { ValidationSchema } from 'validation-schema';
import { useObjectDatesMapper } from './Helper';
import {
  useProjectDetailsDateHelper,
} from './projectDetailsService';
import { useBackendApi } from './backendApi';
import { useEventsPublisher } from 'event-bus';
import { useCacheService } from './cacheService';

export interface ProjectsService {
  create: (request: Project) => Promise<Project>,
  update: (
    projectId: number,
    request: Project,
    updateOptions?: {
      onStartDateUpdated?: ProjectUpdateOption,
      onEndDateUpdated?: ProjectUpdateOption,
    },
  ) => Promise<Project>,
  setProjectStatus: (
    projectId: number,
    status: ProjectStatus,
  ) => Promise<Project>,
  getProjects: (
    filter: ProjectFilter,
    limit: Limit,
    withStatistic: boolean,
    sortBy?: OrderBy,
  ) => Promise<PageResponse<Project>>,
  getProjectNames: () => Promise<ProjectIdWithName[]>,
  getTypeByProjectId: (id: number) => Promise<ProjectType | undefined>,
  getFirstPagesByTypes:
    (filter: ProjectFilter, sortBy: SortByByTypes, limit: number)
      => Promise<Record<ProjectType, PageResponse<Project>>>
  getById: (id: number) => Promise<Project>,
  getCreateValidationSchema: () => ValidationSchema,
  getPermissions: (projectId: number) => Promise<Permission[]>,
  archiveAllProjects: (excludeIds: number[]) => Promise<void>,
}

type FormFieldsData = Record<string, any>;

export interface ProjectUpdatedEvent {
  id: number,
  data: Project,
}

export function useProjectFilterHelper() {
  const getUriQuery = (
    filter: ProjectFilter,
    limit?: Limit,
  ) => {
    const result = [];

    if (!filter.types.selectAll && filter.types.selected.length > 0) {
      result.push(`types=${filter.types.selected.join(',')}`);
    }

    if (filter.status) {
      result.push(`status=${filter.status}`);
    }

    if (filter.nameContains && filter.nameContains.trim().length > 2) {
      result.push(`name=${encodeURIComponent(filter.nameContains.trim())}`);
    }

    if (!filter.assignedTo.selectAll && filter.assignedTo.selected.length > 0) {
      result.push(`associatedWith=${filter.assignedTo.selected.join(',')}`);
    }

    if (filter.withPastDueOnly) {
      result.push('withPastDueOnly=1');
    }

    if (limit?.limit) {
      result.push(`limit=${limit.limit}`);
    }

    if (limit?.offset) {
      result.push(
        `offset=${limit.offset}`,
      );
    }

    return result.join('&');
  };

  return {
    getUriQuery,
  };
}

function useProjectDateHelper() {
  const {
    mapSingle: baseMapSingle,
  } = useObjectDatesMapper<Project>(
    [
      'startDate',
      'endDate',
      'statistic.nextKeyDate.dueDate.date',
      'statistic.nextKeyDate.dueDate.time',
    ],
    [],
  );

  const detailsDateHelper = useProjectDetailsDateHelper();

  const mapSingle = (item: FormFieldsData) => {
    const data = baseMapSingle(item);

    if (data.statistic?.details) {
      data.statistic.details = detailsDateHelper.mapList(data.statistic.details);
    }

    return data;
  };

  const mapList = (items: FormFieldsData[]) => {
    return items.map(mapSingle);
  };

  return {
    mapList,
    mapSingle,
  };
}

export enum ProjectPermission {
  CREATE_TASK_ASSIGNEE = 'Project:CREATE_TASK_ASSIGNEE',
  READ_ASSIGNMENTS = 'Project:READ_ASSIGNMENTS',
  MANAGE_PLAN = 'Project:MANAGE_PLAN',
  MANAGE_ASSIGNMENTS = 'Project:MANAGE_ASSIGNMENTS',
  READ_TASKS = 'Project:READ_TASKS',
  CREATE_TASKS = 'Project:CREATE_TASKS',
  UPDATE_TASKS = 'Project:UPDATE_TASKS',
  UPDATE = 'Project:UPDATE',
  READ_DETAILS = 'Project:READ_DETAILS',
  UPDATE_DETAILS = 'Project:UPDATE_DETAILS',
  CREATE_NOTE = 'Project:CREATE_NOTE',
  READ_HISTORY = 'Project:READ_HISTORY',
  CREATE_DOCUMENTS = 'Project:CREATE_DOCUMENTS',
  ASSIGN_NOTE_TO_CLIENT_PORTAL = 'Project:ASSIGN_NOTE_TO_CLIENT_PORTAL',
  MANAGE_CLIENT_PORTAL_CONFIGURATION = 'Project:MANAGE_CLIENT_PORTAL_CONFIGURATION',
  READ_CLIENT_PORTAL_CONFIGURATION = 'Project:READ_CLIENT_PORTAL_CONFIGURATION',
}

export function useProjectsService(): ProjectsService {
  const projectsApi = useBackendApi();

  const filterHelper = useProjectFilterHelper();

  const eventsPublisher = useEventsPublisher();

  const cacheService = useCacheService('projectsService');

  const {
    mapSingle,
    mapList,
  } = useProjectDateHelper();

  const getUriParameters = (
    filter: ProjectFilter,
    limit?: Limit,
    withStatistic = true,
  ): string => {

    let str = filterHelper.getUriQuery(filter, limit);
    if (withStatistic) {
      str += '&withStatistic=1';
    }

    const today = new Date();
    const todayDateString = today.toISOString()
      .split('T')[0];

    str += `&todayDate=${todayDateString}`;

    return str;
  };

  const getProjects = async (
    filter: ProjectFilter,
    limit: Limit,
    withStatistic: boolean,
    sortBy?: OrderBy,
  ) => {
    const orderByStr = sortBy ? `&orderByField=${sortBy.field}&orderDirection=${sortBy.direction}` : '';

    const result = await projectsApi.get(
      `/projects?${getUriParameters(filter, limit, withStatistic)}${orderByStr}`
      ,
    ) as PageResponse<FormFieldsData>;

    return {
      ...result,
      data: mapList(result.data),
    } as PageResponse<Project>;
  };

  const getProjectNames = async () => {
    return cacheService.get<Project[]>('projectNames', async (cacheItem) => {
      cacheItem.setExpireInSeconds(180);
      return await projectsApi.get('/projects/names') as Project[];
    });
  };

  const getTypeByProjectId = async (projectId: number) => {
    const names = await getProjectNames();

    return names.find((item) => item.id === projectId)?.type;
  };

  const create = async (request: Project) => {
    request.status = ProjectStatus.ACTIVE;
    const response = await projectsApi.post('/projects', request);

    return mapSingle(response);
  };

  const getById = async (id: number) => {
    return mapSingle(await projectsApi.get(`/projects/${id}`));
  };

  const update = async (
    id: number,
    data: Project,
    updateOptions?: {
      onStartDateUpdated?: ProjectUpdateOption,
      onEndDateUpdated?: ProjectUpdateOption,
    },
  ) => {
    const response = await projectsApi.put(
      `/projects/${id}`,
      {
        ...data,
        updateOptions,
      },
    );

    const result = mapSingle(response);
    eventsPublisher.publish(PROJECT_UPDATED, { id, data: result });

    return result;
  };

  const setProjectStatus = async (
    id: number,
    status: ProjectStatus,
  ) => {
    const response = await projectsApi.put(
      `/projects/${id}/status`,
      {
        status,
      },
    );

    const result =  mapSingle(response);
    eventsPublisher.publish(PROJECT_UPDATED, { id, data: result });

    return result;
  };

  const getFirstPagesByTypes = async (
    filter: ProjectFilter,
    orderBy: SortByByTypes,
    limit: number,
  ) => {
    let orderByStr = '';

    Object.keys(orderBy)
      .forEach((type) => {
        const fieldName = Object.keys(orderBy[type])[0];
        const direction = orderBy[type][fieldName];

        orderByStr += `&orderBy[${type.toLowerCase()}][${fieldName}]=${direction}`;
      });

    const rawData = await projectsApi.get(
      `/projects/bytypes?${getUriParameters(filter)}${orderByStr}&limit=${limit}`
      ,
    ) as Record<ProjectType, PageResponse<FormFieldsData>>;

    const result: Record<string, PageResponse<Project>> = {};

    Object.entries(rawData)
      .forEach(([key, pageResponse]) => {
        result[key] = {
          ...pageResponse,
          data: mapList(pageResponse.data),
        };
      });

    return result;
  };

  const getCreateValidationSchema = () => {

    return {
      name: {
        type: 'string',
        constraints: {
          required: true,
        },
      },
      type: {
        type: 'int',
        constraints: {
          required: true,
        },
      },
      planId: {
        type: 'int',
        constraints: {
          required: true,
        },
      },
      startDate: {
        type: 'string',
        constraints: {
          required: true,
        },
      },
      endDate: {
        type: 'string',
        constraints: {
          required: true,
        },
      },
    } as ValidationSchema;
  };

  const getPermissions = async (projectId: number) => {
    return await projectsApi.get(`/projects/${projectId}/permissions`) as Permission[];
  };

  const archiveAllProjects = async (excludeIds: number[]) => {
    const result = await projectsApi.post('/projects/archive', {
      excludeIds,
    });

    eventsPublisher.publish(PROJECTS_UPDATED_IN_BULK, {});

    return result;
  };

  return {
    create,
    getCreateValidationSchema,
    getProjects,
    getProjectNames,
    getTypeByProjectId,
    getFirstPagesByTypes,
    getById,
    update,
    setProjectStatus,
    getPermissions,
    archiveAllProjects,
  };
}
