import {
  PlanTask,
  ProjectDetailsField,
  ProjectDetailsFieldType,
  ProjectType,
  useProjectDetailsFieldsService
} from 'nekst-api';
import {
  Dropdown,
  LoadingAnimation,
  Option, useFormContextRequired,
  useFormInputsContext,
  useInputHelper,
  useListContext, useListContextRequired
} from 'ui-builder';
import React, { useCallback, useMemo, useState } from 'react';
import useDataLoader from 'data-loader';
import { get } from 'lodash';

export interface PlanLinkedDetailInputContext {
  usedDetailsFieldsIds?: number[],
}

export function usePlanLinkedDetailFieldDropdownContext() {
  const listContext = useListContext<PlanTask>();

  const fieldsContext = useFormInputsContext<PlanLinkedDetailInputContext>();

  const providedValue = fieldsContext?.usedDetailsFieldsIds;

  const getUsedDetailsFieldsIds = useCallback(() => {
    return providedValue || listContext.data
      ?.filter((task) => !!task.linkedFieldId)
      .map((task) => task.linkedFieldId!) || [];
  }, [listContext.version]);

  return {
    getUsedDetailsFieldsIds,
  };
}

function useDetailsBoxesHelper(
  projectType: ProjectType,
  detailFieldsSetId: number | undefined,
  parentTaskIdSource: string,
  selectedFieldId?: number,
) {
  const fieldContext = usePlanLinkedDetailFieldDropdownContext();
  const [detailFields, setDetailFields] = useState<ProjectDetailsField[]>();
  const detailsFieldService = useProjectDetailsFieldsService();

  const listContext = useListContextRequired<PlanTask>();

  const formContext = useFormContextRequired();

  const parentTaskId = get(formContext.data, parentTaskIdSource);

  const parentTask: PlanTask | undefined = useMemo(
    () => listContext.data.find((task) => task.id === parentTaskId),
    [parentTaskId]
  );

  const usedFieldsMap = useMemo<Record<number, true>>(() => {
    return fieldContext.getUsedDetailsFieldsIds()
      .reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {} as Record<number, true>);
  }, [JSON.stringify(fieldContext.getUsedDetailsFieldsIds())]);

  const { loading } = useDataLoader(
    async () => {
      if (detailFieldsSetId) {
        return detailsFieldService.getFieldsInSet(detailFieldsSetId);
      } else {
        return detailsFieldService.getFields(projectType);
      }
    },
    setDetailFields,
    detailFieldsSetId,
    projectType,
  );

  const options = useMemo<Option[]>(() => {
    const filterBasedOnDetailsFieldsHierarchy = (field: ProjectDetailsField) => {
      if (detailFieldsSetId) {
        if (parentTask) {
          return parentTask.linkedFieldId === field._parentField?.id;
        } else {
          return !field._parentField || field._parentField.isProjectStartDate || field.isProjectEndDate;
        }
      } else {
        return true;
      }
    }

    if (detailFields?.length) {
      return detailFields
        .filter((item) => !usedFieldsMap[item.id] || (item.id === selectedFieldId))
        .filter((item) => item.fieldType === ProjectDetailsFieldType.DATE)
        .filter(filterBasedOnDetailsFieldsHierarchy)
        .map((field) => ({
          label: field.name,
          value: field.id,
        }));
    } else {
      return [];
    }
  }, [detailFields, Object.keys(usedFieldsMap).length, parentTaskId]);

  return {
    options,
    loading,
  };
}

function BaseLinkedDetailFieldDropdown(
  props: {
    projectType?: ProjectType,
    detailsFieldsSetId?: number,
    parentTaskIdSource: string,
    source: string,
    label: string,
  },
) {
  const inputHelper = useInputHelper<number>(props);

  const {
    options,
    loading,
  } = useDetailsBoxesHelper(
    props.projectType || ProjectType.LISTING,
    props.detailsFieldsSetId,
    props.parentTaskIdSource,
    inputHelper.getValue(),
  );

  if (!loading) {
    if (options.length) {
      return (
        <Dropdown
          source={props.source}
          label={props.label}
          options={options}
          isDisabled={inputHelper.getIsDisabled() || options.length === 0}
          placeholder={options.length === 0 ? 'No available detail boxes' : 'Select a detail box'}
        />
      );
    } else {
      return null;
    }
  } else {
    return (<LoadingAnimation isBig={false} />);
  }
}

export function PlanLinkedFieldDropdown(
  props: {
    projectType?: ProjectType,
    detailFieldsSetId?: number,
    parentTaskIdSource: string,
    source: string,
    label: string,
  },
) {
  return (
    <BaseLinkedDetailFieldDropdown {...props} />
  );
}
