import React from 'react';
import { BaseInputLayoutProps, BaseInputProps, Option } from './index';
import useInputHelper from './inputHelper';
import MultiDropdownLayout from '../../../web/uitheme/input/MultiDropdownLayout';

export interface MultiDropdownProps extends BaseInputProps<string[] | number[]> {
  options?: Option[],
}

export interface MultiDropdownLayoutProps<DataType = string | number>
  extends BaseInputLayoutProps<DataType[]> {
  options?: Option<DataType>[],
}

export default function MultiDropdown(props: MultiDropdownProps) {
  const helper = useInputHelper<string[] | number[]>(props);

  return (
    <MultiDropdownLayout
      {...helper.getBaseInputLayoutProps()}
      hideLabel={props.hideLabel}
      options={props.options}
    />
  );
}
