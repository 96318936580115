import React from 'react';

import { useShowContext, useListContext } from 'ui-builder';
import { AbstractTask } from 'nekst-api';

import styles from './DueDateField.module.scss';

function getParentTaskName(parentTaskId: number | null, data: AbstractTask[]) {
  if (parentTaskId) {
    return data.find((item) => item.id === parentTaskId)?.name;
  } else {
    return '';
  }
}

export default function DependentTaskDueDate() {
  const { data } = useShowContext<AbstractTask>();

  const { data: items } = useListContext<AbstractTask>();

  if (data?.parentTaskRelation) {
    const parentTaskName = getParentTaskName(data.parentTaskRelation.parentTaskId, items!);

    const { days } = data.parentTaskRelation;

    const dayString = days === 0 || days > 1 ? 'days' : 'day';
    const str = `Due ${data.parentTaskRelation.days} ${dayString} after ${parentTaskName}`;
    return (
      <div className={`${styles.dueDateField}`}>
        <i className={styles.icon}>
          &nbsp;
        </i>
        {str}
      </div>
    );
  } else {
    return null;
  }
}
