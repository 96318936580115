import React, { useState } from 'react';
import {
  SubmitButton,
  ButtonColor,
} from 'ui-builder';
import useClientPortalService, { ClientPortalConfiguration } from './clientPortalService';
import FormTemplate from '../../../shared/uibuilder/form/FormTemplate';
import Text from '../../../shared/uibuilder/form/input/Text';
import useDataLoader from '../../../shared/uibuilder/form/dataLoader';
import LoadingAnimation from '../../../shared/LoadingAnimation';

export default function ClientPortalConfigurationForm() {
  const service = useClientPortalService();

  const [currentConfiguration, setCurrentConfiguration] = useState<ClientPortalConfiguration>();

  useDataLoader(
    service.getConfiguration,
    setCurrentConfiguration,
  );

  if (currentConfiguration) {
    return (
      <FormTemplate<ClientPortalConfiguration>
        submitFormFunc={async (data) => {
          if (data.subdomain !== currentConfiguration?.subdomain) {
            const result = await service.updateConfiguration(data);
            setCurrentConfiguration(result);

            return result;
          } else {
            return data;
          }
        }}
        initialData={currentConfiguration}
        validationSchema={{
          subdomain: {
            type: 'string',
            constraints: {
              required: true,
              minLength: 4,
              maxLength: 15,
              regex: {
                value: /^[0-9a-z]+$/g,
              },
            },
          },
        }}
        buttons={
          <SubmitButton text="Update" color={ButtonColor.YELLOW} />
        }
      >
        <div>
          You current client portal URL is
          {' '}
          {`${currentConfiguration.subdomain}.nekst.com.`}

        </div>
        <br />
        <Text
          source="subdomain"
          label="Client Portal Subdomain"
          hint="Minimum 4 characters. Use only lowercase letters (a-z) and numbers (0-9). No spaces or special characters."
        />
        <div style={{ color: '#f2ab30' }}>
          Please be aware, altering this URL will invalidate all previously shared client portal
          links.
        </div>
      </FormTemplate>
    );
  } else {
    return <LoadingAnimation />;
  }
}
