import React, { useState } from 'react';
import { useFormContext } from '../FormContext';
import { useNewUiTheme } from '../../newUiTheme';
import { FormButtonProps } from './index';

export function SubmitButton(props: FormButtonProps) {
  const { submitForm, isSubmitDisabled } = useFormContext();

  const uiTheme = useNewUiTheme();

  const size = props.size;

  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    if (loading) {
      return;
    }

    try {
      setLoading(true);
      await submitForm?.();
    } finally {
      setLoading(false);
    }
  }

  return (
    <uiTheme.buttons.SubmitButtonLayout
      text={props.text || 'Submit'}
      onClick={onClick}
      isDisabled={isSubmitDisabled?.() || false}
      isLoading={loading}
      color={props.color}
      size={size}
      isFilled={props.isFilled}
      iconType={props.iconType}
      dataKey={props.dataKey}
    />
  );
}
