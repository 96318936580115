import React from 'react';
import {
  useDueDateCounterService,
  FilterOptionType,
  useDueDateFilterOption,
} from 'features/tasks-list-feature';
import styles from '../../DueDateFilter.module.scss';

export interface FilterOptionProps {
  label: string;
  className: string;
  value: FilterOptionType;
}

function FilterOption(props: FilterOptionProps) {
  const filterOption = useDueDateFilterOption(props.value);

  const classNames = [
    styles.filterOption,
    props.className,
    filterOption.isActive() ? styles.active : '',
  ];

  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={classNames.join(' ')} onClick={filterOption.onClick}>
      {props.label.toUpperCase()}
      {` (${filterOption.getCount()})`}
    </div>
  );
}

export default function DueDateFilter() {

  const dueDateCounterService = useDueDateCounterService()!;

  return (
    <div className={`${styles.block} ${dueDateCounterService.loading && 'loading'} dueDateFilters`}>
      <div className={styles.inner}>
        <FilterOption
          label="Today"
          className={styles.today}
          value={FilterOptionType.TODAY}
        />
        <FilterOption
          label="Past Due"
          className={styles.pastDue}
          value={FilterOptionType.PAST_DUE}
        />
        <FilterOption
          label="No Due Date"
          className={styles.noDueDate}
          value={FilterOptionType.NO_DUE_DATE}
        />
        <FilterOption
          label="All"
          className={styles.all}
          value={FilterOptionType.ALL_TASKS}
        />
      </div>
    </div>
  );

}
