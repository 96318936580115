import {
  Dropdown,
  NumberInput,
  RelativeRow,
  useFormContextRequired
} from 'ui-builder';
import { DueDateWhen } from 'nekst-api';
import React from 'react';
import { Spacings } from 'react-native-ui-lib';

export interface DueDateConfigInputProps {
  source: string;
  label: string;
  isDisabled?: boolean | ((_data: any) => boolean);
}

export function DueDateConfigInput(props: DueDateConfigInputProps) {

  const {
    data: formData,
    onChangeCallback,
  } = useFormContextRequired();

  const onUpdate = (diff: { [key: string]: any }) => {
    const oldValue = formData![props.source] || {
      days: undefined,
      when: undefined,
    };

    const newValue = {
      ...oldValue,
      ...diff,
    };

    if (newValue.days !== undefined || newValue.when) {
      onChangeCallback!({
        [props.source]: newValue,
      });
    } else {
      onChangeCallback!({
        [props.source]: undefined,
      });
    }
  };

  const onChangeDays = (update: { [key: string]: any }) => {
    onUpdate({
      days: Object.values(update)[0],
    });
  };

  const onChangeWhen = (update: { [key: string]: any }) => {
    onUpdate({
      when: Object.values(update)[0],
    });
  };

  return (
    <RelativeRow
      weights={[4, 8]}
      rowStyle={{
        gap: Spacings.s4
      }}
    >
      <NumberInput
        source={`${props.source}.days`}
        label={props.label}
        onChangeCallback={onChangeDays}
        showRequiredIcon={false}
        isDisabled={props.isDisabled}
      />
      <Dropdown
        options={[
          {
            value: DueDateWhen.BEFORE_START_DATE,
            label: 'Before Start Date',
          },
          {
            value: DueDateWhen.AFTER_START_DATE,
            label: 'After Start Date',
          },
          {
            value: DueDateWhen.BEFORE_END_DATE,
            label: 'Before End Date',
          },
          {
            value: DueDateWhen.AFTER_END_DATE,
            label: 'After End Date',
          },
        ]}
        source={`${props.source}.when`}
        label="Days"
        onChangeCallback={onChangeWhen}
        showRequiredIcon={false}
        isDisabled={props.isDisabled}
      />
    </RelativeRow>
  );
}
