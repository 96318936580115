import React, {
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import AliceCarousel, { EventObject } from 'react-alice-carousel';
import {
  useDateHelper,
  useEventsSubscriber, AbstractTask,
  TASK_CREATED,
  TASK_DELETED,
  TASK_UPDATED,
  TaskStatus,
  useProjectTasksService,
  TASK_COMPLETED,
} from 'nekst-api';

import styles from './KeyDatesWidget.module.scss';

import 'react-alice-carousel/lib/alice-carousel.css';
import { useOpenUpdateFormHelper } from '../tasks/CreateUpdate/OpenUpdateFormField';
import { DateField, LoadingAnimation } from 'ui-builder';

function Name(props: { task: AbstractTask }) {
  const openPopupHelper = useOpenUpdateFormHelper();

  return (
    <div
      className={styles.name}
    >
      <a
        href="#"
        className={styles.inner}
        onClick={(event) => {
          event.preventDefault();
          openPopupHelper.openForm(props.task);
        }}
      >
        {props.task._renderedName}
      </a>
    </div>
  );
}

function DueDateField(props: { task: AbstractTask }) {
  const dateHelper = useDateHelper();

  let text;

  if (props.task.dueDate) {
    text = dateHelper.toString(props.task.dueDate.date);
  } else if (props.task.parentTaskRelation && props.task._approximateDueDate) {
    text = `Approx. ${dateHelper.toString(props.task._approximateDueDate)}`;
  } else {
    text = 'No Due Date';
  }

  return (<span title={text}>{text}</span>);
}

function KeyDateItem(props: { task: AbstractTask }) {

  return (
    <div
      className={[
        styles.item,
        props.task.status === TaskStatus.COMPLETED ? styles.completed : '',
      ].join(' ')}
    >
      <div className={styles.status}>
        <span className={styles.icon}>&nbsp;</span>
      </div>
      <Name task={props.task} />
      {(props.task.status !== TaskStatus.COMPLETED) && (
        <div className={styles.date}>
          <DueDateField task={props.task} />
        </div>
      )}
      {(props.task.status === TaskStatus.COMPLETED) && (
        <div className={styles.date}>
          Completed:
          {' '}
          <DateField value={props.task.completedAt!} source="" label="Completed:" />
        </div>
      )}
    </div>
  );
}

function Carousel(
  props: {
    widgets: ReactElement[],
    initialItem: number,
  },
) {

  const ref = useRef<AliceCarousel>(null);

  const [currentItem, setCurrentItem] = useState(props.initialItem);

  const [prevDisabled, setPrevDisabled] = useState(true);

  const [nextDisabled, setNextDisabled] = useState(false);

  const [buttonsVisible, setButtonsVisible] = useState(false);

  const slideNext = () => {
    if (ref.current) {
      ref.current.slideNext();
    }
  };

  const slidePrev = () => {
    if (ref.current) {
      ref.current.slidePrev();
    }
  };

  const sync = (event: EventObject) => {
    const maxValue = props.widgets.length - event.itemsInSlide;
    const item = Math.min(event.item, maxValue);

    setCurrentItem(item);
    setPrevDisabled(item <= 0);
    setNextDisabled(item >= maxValue);

    setButtonsVisible(!event.isPrevSlideDisabled || !event.isNextSlideDisabled);

    return event;
  };

  useEffect(() => {
    if (ref.current) {
      const event: EventObject = {
        item: ref.current.state.activeIndex,
        isNextSlideDisabled:
          ref.current.state.activeIndex
          + ref.current.state.itemsInSlide >= props.widgets.length,
        isPrevSlideDisabled: ref.current.state.activeIndex <= 0,
        itemsInSlide: ref.current.state.itemsInSlide,
        type: 'action',
        slide: 0,
      };

      sync(event);
    }

  }, [props.widgets.length]);

  if (props.widgets.length > 0) {
    return (
      <div className={styles.widget}>
        <div className={styles.carouselRow}>
          {buttonsVisible && (
            <>
              <button
                type="button"
                className={styles.prevButton}
                onClick={slidePrev}
                disabled={prevDisabled}
              >
                &nbsp;
              </button>
              <button
                type="button"
                className={styles.nextButton}
                onClick={slideNext}
                disabled={nextDisabled}
              >
                &nbsp;
              </button>
            </>
          )}
          <AliceCarousel
            ref={ref}
            animationDuration={200}
            activeIndex={currentItem}
            mouseTracking
            disableDotsControls
            disableButtonsControls
            items={props.widgets}
            responsive={{
              0: {
                items: 5,
                itemsFit: 'contain',
              },
            }}
            onInitialized={sync}
            onSlideChanged={sync}
            onResized={sync}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
}

function useTasksSorter() {
  const getSorted = (keyDates: AbstractTask[]) => {
    return keyDates
      .sort((task1, task2) => {
        // the following hack is needed to display dependent tasks after their parents in case
        // the dependent task was 0 days after the parent task completion
        const task1DueDate = task1.dueDate?.date;
        const task2DueDate = task2.dueDate?.date;
        if (task1DueDate) {
          task1DueDate.setHours(23, 59);
        }
        if (task2DueDate) {
          task2DueDate.setHours(23, 59);
        }

        const task1Date = task1.completedAt || task1DueDate || task1._approximateDueDate;
        const task2Date = task2.completedAt || task2DueDate || task2._approximateDueDate;

        if (task1Date && task2Date) {
          return task1Date.getTime() - task2Date.getTime();
        } else if (!task1Date && task2Date) {
          return 1;
        } else if (task1Date && !task2Date) {
          return -1;
        } else {
          return task1.name.localeCompare(task2.name);
        }
      });
  };

  return {
    getSorted,
  };
}

export default function KeyDatesWidget(props: { projectId: number }) {
  const tasksSorter = useTasksSorter();
  const tasksService = useProjectTasksService();

  const projectId = props.projectId;

  const [keyDatesData, setKeyDatesData] = useState<{
    widgets: ReactElement[],
    total: number,
    completed: number,
    firstIncomplete: number,
  }>();

  const [loading, setLoading] = useState(false);

  async function loadKeyDatesData() {
    setLoading(true);
    try {
      const keyDates = (await tasksService.getAllTasks(
        projectId,
        {
          showKeyDatesOnly: true,
        },
        {
          limit: 100,
          offset: 0,
        },
      )).data;

      const data = {
        widgets: tasksSorter.getSorted(keyDates)
          .map((item) => (<KeyDateItem task={item} />)),
        total: keyDates.length,
        completed: keyDates.filter((item) => item.status === TaskStatus.COMPLETED).length,
        firstIncomplete: keyDates.findIndex(
          (item) => item.status !== TaskStatus.COMPLETED,
        ),
      };

      setKeyDatesData(data);
    } finally {
      setLoading(false);
    }
  }

  useEventsSubscriber(
    'KeyDatesWidget',
    {
      [TASK_CREATED]: loadKeyDatesData,
      [TASK_UPDATED]: loadKeyDatesData,
      [TASK_DELETED]: loadKeyDatesData,
      [TASK_COMPLETED]: loadKeyDatesData,
    },
    [],
    loadKeyDatesData,
  );

  if (keyDatesData) {
    if (keyDatesData.widgets.length) {
      return (
        <div className={`${styles.widget} ${loading && 'loading'}`}>
          <h3>
            Key Dates (
            {keyDatesData.completed}
            {' / '}
            {keyDatesData.total}
            )
          </h3>

          <div>
            <Carousel widgets={keyDatesData.widgets} initialItem={keyDatesData.firstIncomplete} />
          </div>
        </div>
      );
    } else {
      return null;
    }
  } else {
    return <LoadingAnimation />;
  }
}
