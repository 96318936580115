import React, { ReactElement } from 'react';
import { NewUiTheme } from 'ui-builder';
import useDefaultThemeProvider from './defaultThemeProvider';
import { UiTheme } from '../uiTheme';
import BaseDropdownLayout from '../form/input/layout/BaseDropdownLayout';
import MultiSelectDropdownLayout from './form/input/MultiSelectDropdownLayout';
import WysiwygLayout from './form/input/WysiwygLayout';
import SmartTagsWysiwygLayout from './form/input/SmartTagsWysiwygLayout';
import InputLabelLayout from './form/input/InputLabelLayout';
import RadioButtonLayout from '../form/input/layout/RadioButtonLayout';
import SmartTagsTextInputLayout from './form/input/SmartTagsTextInputLayout';
import SmartTagsTextAreaLayout from './form/input/SmartTagsTextAreaLayout';

export default function NekstWebTheme(
  props: {
    children: ListOrSingle<ReactElement>,
  },
) {
  const themeProvider = useDefaultThemeProvider();

  const defaultTheme = themeProvider.getDefaultTheme();

  return (
    <UiTheme value={defaultTheme}>
      <NewUiTheme
        value={{
          inputs: {
            ErrorsWrapperLayout: defaultTheme.inputs.ErrorsWrapperLayout,
            TextLayout: defaultTheme.inputs.TextLayout,
            PriceLayout: defaultTheme.inputs.PriceLayout,
            PasswordLayout: defaultTheme.inputs.PasswordLayout,
            CheckboxLayout: defaultTheme.inputs.CheckboxLayout,
            CheckboxGroupLayout: defaultTheme.inputs.CheckboxGroupLayout,
            CheckboxGroupWithSelectAllLayout: defaultTheme.inputs.CheckboxGroupWithSelectAllLayout,
            BaseDropdownLayout,
            DateInputLayout: defaultTheme.inputs.DateInputLayout,
            TimeInputLayout: defaultTheme.inputs.TimeInputLayout,
            TextAreaLayout: defaultTheme.inputs.TextAreaLayout,
            MultiSelectDropdownLayout,
            WysiwygLayout,
            SmartTagsWysiwygLayout,
            SmartTagsTextInputLayout,
            SmartTagsTextAreaLayout,
            InputLabelLayout,
            RadioButtonLayout,
            RadioButtonsGroupLayout: defaultTheme.inputs.RadioButtonsGroupLayout,
          },
          forms: defaultTheme.forms,
          buttons: defaultTheme.buttons,
          common: defaultTheme.common,
          show: defaultTheme.show,
          fields: defaultTheme.fields,
          widgets: defaultTheme.widgets,
        }}
      >
        {props.children}
      </NewUiTheme>
    </UiTheme>
  );
}
