import {
  BaseInputProps, CheckboxInput,
  IfFormData, ifReactNative,
  RelativeRow,
  useFormContext,
  useInputHelper
} from 'ui-builder';
import { Person, TeamRole } from 'nekst-api';
import React, { useEffect, useState } from 'react';
import { ServiceProvidersMultiDropdown } from '../../ServiceProviders';

export interface ServiceProvidersInputProps extends BaseInputProps<number[]> {

}

export function ServiceProvidersInput(
  props: ServiceProvidersInputProps,
) {
  const inputHelper = useInputHelper<number[]>(props);

  const formContext = useFormContext<Person>();

  const value = inputHelper.getValue();

  const [isEnabled, setIsEnabled] = useState(
    value && value.length > 0,
  );

  const clearValue = () => {
    formContext.onChangeCallback!({
      [props.source]: [],
    });
  };

  useEffect(() => {
    if (!isEnabled && value) {
      clearValue();
    }
  }, [isEnabled]);

  return (
    <IfFormData conditionFunc={(data) => data.teamRole === TeamRole.CONTACT}>
      <RelativeRow weights={ifReactNative([12], [4, 8])}>
        <CheckboxInput
          source="_isCheckboxChecked"
          value={isEnabled}
          label="Service Provider"
          onChangeCallback={() => setIsEnabled((prev) => !prev)}
        />
        {isEnabled && (<ServiceProvidersMultiDropdown {...props} />)}
      </RelativeRow>
    </IfFormData>
  );
}
