import React, {
  ReactElement,
  useRef,
  useState,
} from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import styles from './ScrollableY.module.scss';

export interface ScrollableYProps {
  children: ReactElement | ReactElement[];
  showViewAll?: boolean;
  maxHeight: number;
}

export default function ScrollableY(props: ScrollableYProps) {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const [showViewAll, setShowViewAll] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  useResizeObserver(ref, () => {
    if (ref.current) {
      setShowViewAll(ref.current.scrollHeight > ref.current.clientHeight);
    }
  });

  // eslint-disable-next-line react/no-unstable-nested-components
  function ViewAllToggler() {
    let text;
    if (collapsed) {
      text = 'Expand';
    } else {
      text = 'Collapse';
    }

    return (
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <span
        onClick={() => setCollapsed((prev) => !prev)}
        className={styles.viewAll}
      >
        {text}
      </span>
    );
  }

  return (
    <>
      <div
        className={styles.block}
        style={{
          maxHeight: collapsed ? `${props.maxHeight}rem` : 'none',
        }}
        ref={ref}
      >
        {props.children}
      </div>
      {props.showViewAll && (!collapsed || showViewAll) ? <ViewAllToggler /> : null}
    </>

  );
}
