import React from 'react';
import { List, ListDataRefresher } from 'ui-builder';
import InnerBlock from '../../shared/web/layout/InnerBlock';
import TextField from '../../shared/uibuilder/show/field/TextField';
import GridLayout from '../../shared/uibuilder/list/GridLayout';
import Cell from '../../shared/uibuilder/list/Cell';
import UpdateServiceProviderButton from './CreateUpdate/UpdateServiceProviderButton';
import CreateServiceProviderButton from './CreateUpdate/CreateServiceProviderButton';
import DeleteServiceProviderButton from './DeleteServiceProviderButton';
import { SERVICE_PROVIDERS_UPDATED, useServiceProvidersGroupsService } from 'nekst-api';

export default function ServiceProvidersList() {
  const service = useServiceProvidersGroupsService();
  return (
    <InnerBlock
      title="Service Providers"
      headerRight={<CreateServiceProviderButton />}
    >
      <List getDataFunc={service.getAll}>
        <ListDataRefresher eventName={SERVICE_PROVIDERS_UPDATED} />
        <GridLayout weights={[10, 2]}>
          <TextField label="Service Providers" source="name" />
          <Cell label="Actions">
            <UpdateServiceProviderButton />
            <DeleteServiceProviderButton />
          </Cell>
        </GridLayout>
      </List>
    </InnerBlock>
  );
}
