import React from 'react';
import { useNewUiTheme } from '../newUiTheme';

export function Error403() {
  const uiTheme = useNewUiTheme();

  return (
    <uiTheme.common.Error403Layout />
  )
}
