import React, { ReactElement } from 'react';
import { PersonPermission, TeamRole } from 'nekst-api';
import { useParams } from 'react-router-dom';
import usePeopleService, { Person } from '../peopleService';
import DataLoader from '../../shared/DataLoader';
import Row6x6x from '../../shared/web/uitheme/form/Row6x6x';
import ManageAssignmentsList from './ManageAssignmentsList';
import RemovePersonButton from '../Delete/RemovePersonButton';
import PersonNotesList from '../Notes/PersonNotesList';
import styles from './PersonUpdatePage.module.scss';
import SinglePersonPage from '../shared/SinglePersonPage';
import OpenHistoryPageButton from '../History/OpenHistoryPageButton';
import Separator from '../../shared/web/uitheme/form/Separator';
import { PeopleBreadcrumbs } from '../List/PeopleListPage';
import { Breadcrumb } from '../../shared/web/layout/Breadcrumbs';

import AccessChecker from '../../shared/authorization/AccessChecker';
import { ResendInvitationButton, UpdatePersonForm } from 'features/tasks-forms';
import { PersonAuthorizationScope } from 'authorization-scope';

export function PersonBreadcrumbs(
  props: {
    children?: ReactElement,
    person: Person,
  },
) {
  return (
    <PeopleBreadcrumbs>
      <Breadcrumb text={props.person.profile.name.fullName} href={`/people/${props.person.id}`}>
        {props.children}
      </Breadcrumb>
    </PeopleBreadcrumbs>
  );
}

function hasHistory(person: Person) {
  return [
    TeamRole.ADMINISTRATOR,
    TeamRole.OWNER,
    TeamRole.MEMBER,
  ].includes(person.teamRole);
}

export default function PersonUpdatePage() {
  const peopleService = usePeopleService();

  const personId = useParams().id! as unknown as number;

  return (
    <PersonAuthorizationScope id={personId}>
      <DataLoader<Person> getDataFunc={async () => peopleService.getById(personId)}>
        {(person) => {
          return (
            <>
              <PersonBreadcrumbs person={person} />
              <SinglePersonPage
                headerRight={(
                  <>
                    {hasHistory(person) && (
                      <>
                        <OpenHistoryPageButton person={person} />
                        <ResendInvitationButton teamMember={person} />
                      </>
                    )}

                    <RemovePersonButton personId={person.id} />
                  </>
                )}
                person={person}
              >
                <Row6x6x>
                  <div className={styles.leftColumn}>
                    <UpdatePersonForm person={person} />
                  </div>
                  <div className={styles.rightColumn}>
                    <AccessChecker
                      permissionToCheck={PersonPermission.READ_NOTES}
                      show403Error={false}
                    >
                      <PersonNotesList personId={person.id} />
                      <Separator />
                    </AccessChecker>
                    <ManageAssignmentsList assignments={person._assignments!} />
                  </div>
                </Row6x6x>
              </SinglePersonPage>
            </>
          );
        }}
      </DataLoader>
    </PersonAuthorizationScope>
  );
}
