import React from 'react';
import { usePopupManager } from 'ui-builder';
import { ConfirmDeletePopup } from 'features/nekst-widgets';
import { usePlanTasksService } from 'nekst-api';

export interface DeletePlanTaskPopupProps {
  taskId: number
  title: string
}

export function DeletePlanTaskPopup(props: DeletePlanTaskPopupProps) {
  const planTasksService = usePlanTasksService();

  return (
    <ConfirmDeletePopup
      deleteFunc={() => planTasksService.deleteTask(props.taskId)}
      message={`Are you sure you want to delete task "${props.title}"?`}
    />
  );
}

export default function useDeletePlanTaskPopupManager() {
  const popupManager = usePopupManager();

  const openPopup = (taskId: number, taskTitle: string) => {
    popupManager.openPopup(
      <DeletePlanTaskPopup taskId={taskId} title={taskTitle} />,
    );
  };

  return {
    openPopup,
  };
}
