import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import useDataLoader from 'data-loader';
import { PopupContext } from '../Popup';
import { LoadingAnimation } from '../Common';

export interface ShowContextData<DataType = Record<string, any>> {
  data: Nullable<DataType>
  updateData: (data: DataType) => void,
  isLoading: boolean,
  setLoading: (loading: boolean) => void
  version: number,
}

const ShowReactContext = React.createContext<Partial<ShowContextData<any>>>({});

export function useShowContext<DataType = Record<string, any>>() {
  return useContext<Partial<ShowContextData<DataType>>>(ShowReactContext);
}

export function useShowContextRequired<DataType = Record<string, any>>() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const result = useContext<ShowContextData<DataType>>(ShowReactContext);

  if (!result) {
    throw new Error('The element may be used withing ShowContext only');
  }

  return result;
}

export interface ShowContextProps<DataType> {
  children: ReactNode,
  getDataFunc?: () => Promise<DataType>
  data?: DataType
  dependencies?: any[]
}

export function ShowContext<DataType = Record<string, any>>(props: ShowContextProps<DataType>) {
  const [data, setData] = useState<DataType>(props.data || {} as DataType);

  const {
    loading,
    setLoading,
  } = useDataLoader(
    props.getDataFunc,
    setData,
    ...props.dependencies || [],
  );

  const [version, setVersion] = useState<number>(0);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setVersion((prev) => prev + 1);
    }
  }, [JSON.stringify(props.data)]);

  const contextData = useMemo<ShowContextData<DataType>>(() => ({
    data,
    updateData: (newValue: DataType) => {
      setData(newValue);
      setVersion((prev) => prev + 1);
    },
    isLoading: loading,
    setLoading,
    version,
  }), [version, data, setData, loading]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (Object.keys(data).length > 0) {
    return (
      <ShowReactContext.Provider value={contextData}>
        <PopupContext>
          {props.children}
        </PopupContext>
      </ShowReactContext.Provider>
    );
  } else {
    return <LoadingAnimation isBig /> ;
  }
}
