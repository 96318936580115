import useProjectsApi from '../../../../projectsApi';

export interface CommentsSeenService {
  trackCommentsSeen: (taskId: number) => Promise<void>
}

export default function useCommentsSeenService() {
  const projectsApi = useProjectsApi();

  const trackCommentsSeen = async (taskId: number) => {
    await projectsApi.post(`/tasks/${taskId}/viewcomments`);
  };

  return {
    trackCommentsSeen,
  };
}
