import React from 'react';

import { get } from 'lodash';
import { useShowContext } from '../uibuilder/show/ShowReactContext';
import { Name } from '../commonTypes';

export interface Props {
  source: string
}

export default function FullNameField(props: Props) {
  const { data } = useShowContext();

  const name = get(data, props.source) as Name;

  const fullName = `${name.firstName} ${name.lastName}`;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {fullName}
    </>
  );
}
