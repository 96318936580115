import React, { useState } from 'react';
import {
  CommentPermission,
  ProjectType, TeamPermission,
} from 'nekst-api';
import useDefaultPortalNotesService, {
  DEFAULT_NOTES_UPDATED,
  DefaultPortalNote,
  DefaultPortalNoteRequest
} from './defaultPortalNotesService';
import CommentsList from '../../../shared/widgets/CommentsList/CommentsList';
import {
  Button,
  ButtonColor,
  ButtonSize,
  IconType, ListDataRefresher,
  RelativeRow,
  TextField
} from 'ui-builder';
import { useAuthorizationChecker } from 'authorization-scope';
import OverflowHidden from '../../../shared/widgets/OverflowHidden';
import Right from '../../../shared/web/Right';
import AddCommentForm from '../../../shared/widgets/CommentsList/AddCommentForm';

function Title(props: { projectType: ProjectType }) {
  const [showForm, setShowForm] = useState<boolean>();

  const projectNotesService = useDefaultPortalNotesService();

  const { isGranted } = useAuthorizationChecker();

  return (
    <>
      <OverflowHidden>
        <Right>
          {isGranted(TeamPermission.MANAGE_CLIENT_PORTAL_CONFIGURATION) && (
            <Button
              text="Add"
              size={ButtonSize.SMALL}
              color={ButtonColor.BLUE}
              isFilled
              onClick={() => {
                setShowForm((prev) => !prev);
              }}
              iconType={IconType.PLUS}
            />
          )}
        </Right>
        <h3>Notes</h3>
      </OverflowHidden>
      {showForm && (
        <AddCommentForm
          submitFunc={async (data: DefaultPortalNoteRequest) => {
            return await projectNotesService.createNote(props.projectType, data) as DefaultPortalNote;
          }}
          afterSubmitFunc={async () => {
            setShowForm(false);
          }}
          closeFormFunc={() => setShowForm(false)}
        />
      )}
    </>
  );
}

export default function DefaultPortalNotesList(
  props: {
    projectType: ProjectType,
  }
) {
  const service = useDefaultPortalNotesService();

  return (
    <CommentsList<DefaultPortalNote, DefaultPortalNoteRequest>
      dataKey="project-notes"
      getDataFunc={
        async () => {
          const result = await service.getAllNotes(props.projectType);

          return result.map((note) => ({
            ...note,
            _author: {
              name: {
                firstName: 'Transaction',
                lastName: 'Creator',
              },
              _avatarUrl: undefined,
            },
          })) as DefaultPortalNote[];
        }
      }
      title={<Title projectType={props.projectType} />}
      updateItemFunc={async (id: number, request: DefaultPortalNoteRequest) => {
        return await service.updateNote(id, request);
      }}
      deleteItemFunc={service.deleteNote}
      deletePermission={CommentPermission.DELETE}
      updatePermission={CommentPermission.UPDATE}
      messages={{
        noDataText: 'There are no default client portal notes',
        textInputLabel: 'Enter Note Here',
      }}
      viewFields={(
        <RelativeRow weights={[10, 2]}>
          <TextField source="text" />
        </RelativeRow>
      )}
      dateTimeText="transaction launching time"
      dependencies={[props.projectType]}
    >
      <ListDataRefresher eventName={DEFAULT_NOTES_UPDATED} />
    </CommentsList>
  );
}
