import React, { useEffect, useState } from 'react';
import { ToolbarProps, View, Views } from 'react-big-calendar';
import NavigationButton, { Direction } from './NavigationButton';

import styles from './CalendarPage.module.scss';
import Dropdown from '../shared/uibuilder/form/input/Dropdown';

function SelectViewDropdown(
  props: {
    setViewFunc: (view: View) => void,
  },
) {
  const [value, setValue] = useState<View>(Views.MONTH);

  useEffect(() => {
    props.setViewFunc(value);
  });

  return (
    <Dropdown
      options={[
        {
          label: 'Month',
          value: Views.MONTH,
        },
        {
          label: 'Week',
          value: Views.WEEK,
        },
      ]}
      source="view"
      hideLabel
      value={value}
      onChangeCallback={(data) => {
        setValue(data.view);
      }}
      isClearable={false}
    />
  );
}

export default function CustomToolbar(
  props: ToolbarProps,
) {
  return (
    <div className={styles.toolbar}>
      <div>
        <NavigationButton direction={Direction.PREV} onClick={() => props.onNavigate('PREV')} />
        <NavigationButton direction={Direction.NEXT} onClick={() => props.onNavigate('NEXT')} />
      </div>
      <span className={styles.title}>{props.label}</span>
      <div className="form">
        <SelectViewDropdown
          setViewFunc={(view) => props.onView(view)}
        />
      </div>
    </div>
  );
}
