import { PersonParseResult, ShortProfile } from 'nekst-api';
import { PersonWidget, UserLogoSize } from 'features/nekst-widgets';
import {
  Button,
  ButtonColor,
  Header3,
  RelativeRow,
  Row6x6x,
  SimpleBlock,
  TextInput,
} from 'ui-builder';
import React from 'react';

import styles from './FoundContactsWidget.module.scss';

export function FoundContactsWidget(props: {
  parsedPerson: PersonParseResult,
  onProfileSelected: (profile: ShortProfile) => void,
}) {
  return (
    <>
      <Row6x6x>
        <TextInput
          source="firstName"
          label="First Name"
          isDisabled
          value={props.parsedPerson.firstName}
        />
        <TextInput
          source="lastName"
          label="Last Name"
          isDisabled
          value={props.parsedPerson.lastName}
        />
      </Row6x6x>
      <SimpleBlock>
        <Header3>Matching Contacts</Header3>
        <SimpleBlock className={styles.matchingContacts}>
          {props.parsedPerson.foundMatches.map((match) => (
            <RelativeRow weights={[8, 4]} key={`match-${match.id}`} rowClassName={styles.row}>
              <PersonWidget
                profile={match}
                withName
                size={UserLogoSize.BIG}
                additionalInfo={[
                  ...match.companyName ? [match.companyName] : [],
                  ...match.primaryEmail ? [match.primaryEmail] : [],
                ]}
              />
              <Button
                text="Select Contact"
                onClick={() => props.onProfileSelected(match)}
                color={ButtonColor.BLUE}
                isFilled
              />
            </RelativeRow>
          ))}
        </SimpleBlock>
      </SimpleBlock>
    </>

  );
}
