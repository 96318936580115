import React from 'react';
import { WysiwygProps } from 'ui-builder';
import SmartTagsWysiwyg from '../../uibuilder/form/input/SmartTagsWysiwyg';

export default function SmsSmartTagsWysiwyg(props: WysiwygProps) {
  return (
    <SmartTagsWysiwyg
      {...props}
      hideControls
    />
  );
}
