import { ProjectType } from 'nekst-api';

export function useProjectTypeHelper() {
  const orderMap = {
    [ProjectType.PENDING_BUYER]: 0,
    [ProjectType.PENDING_SELLER]: 10,
    [ProjectType.PENDING_DUAL]: 20,

    [ProjectType.LISTING]: 30,
    [ProjectType.ACTIVE_BUYER]: 40,
    [ProjectType.OTHER]: 50,
  };
  const getLabel = (type: ProjectType) => {
    const map = {
      [ProjectType.LISTING]: 'Listings',
      [ProjectType.PENDING_BUYER]: 'Pending Buyers',
      [ProjectType.PENDING_SELLER]: 'Pending Sellers',
      [ProjectType.PENDING_DUAL]: 'Pending Dual',
      [ProjectType.ACTIVE_BUYER]: 'Active Buyers',
      [ProjectType.OTHER]: 'Other',
    };

    return map[type];
  };

  const compare = (type1: ProjectType, type2: ProjectType) => {
    return orderMap[type1] - orderMap[type2];
  };

  return {
    getLabel,
    compare,
  };
}
