import React from 'react';
import { useProjectTasksSetDueDateService } from 'nekst-api';
import {
  FormTemplate,
  AfterSubmitFunc,
  DateInput,
  useMassUpdateServiceRequired, CheckboxInput,
} from 'ui-builder';

type FormData = {
  dueDate?: Date,
  setNoDueDate: boolean,
};

export function SetDueDateForm(
  props: {
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {
  const massSetDueDateService = useProjectTasksSetDueDateService();

  const massUpdateService = useMassUpdateServiceRequired();

  return (
    <FormTemplate<FormData>
      title="Set Due Date"
      submitFormFunc={async (data) => {
        await massSetDueDateService.massSetDueDate(
          massUpdateService.getCheckedIds(),
          data.setNoDueDate ? undefined : data.dueDate,
        );

        return data;
      }}
      afterSubmitFunc={props.afterSubmitFunc}
      validationSchema={{
        dueDate: {
          type: 'string',
          constraints: {
            required: {
              value: true,
              when: {
                setNoDueDate: {
                  empty: true,
                }
              }
            },
          },
        },
      }}
    >
      <DateInput
        source="dueDate"
        label="Update selected tasks to all have the due date of"
        minDate={new Date()}
        isDisabled={(data) => data.setNoDueDate}
      />
      <CheckboxInput source="setNoDueDate" label="No Due Date" />
    </FormTemplate>
  );
}
