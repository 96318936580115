import React from 'react';
import { HeaderLayoutProps } from 'ui-builder';

export function Header1Layout(props: HeaderLayoutProps) {
    return (
        <h1 className={props.className}>{props.text}</h1>
    )
}

export function Header2Layout(props: HeaderLayoutProps) {
    return (
        <h2 className={props.className}>{props.text}</h2>
    )
}

export function Header3Layout(props: HeaderLayoutProps) {
    return (
        <h3 className={props.className}>{props.text}</h3>
    )
}

export function Header4Layout(props: HeaderLayoutProps) {
    return (
        <h4 className={props.className}>{props.text}</h4>
    )
}

export function Header5Layout(props: HeaderLayoutProps) {
    return (
        <h5 className={props.className}>{props.text}</h5>
    )
}

export function Header6Layout(props: HeaderLayoutProps) {
    return (
        <h6 className={props.className}>{props.text}</h6>
    )
}
