import {useDateHelper} from './dateHelper';
import {TaskType, TasksFilter} from '../Types';

export function useTasksFilterHelper() {
  const dateHelper = useDateHelper();

  const formatDate = (date: Date) => {
    return dateHelper.toYmd(date);
  };

  const buildQueryString = (filter: TasksFilter) => {
    const filters = [];

    if (filter.dueDateFrom) {
      filters.push(`dueDateFrom=${formatDate(filter.dueDateFrom)}`);
    }

    if (filter.dueDateTo) {
      filters.push(`dueDateTo=${formatDate(filter.dueDateTo)}`);
    }

    if (filter.nameContains) {
      filters.push(`query=${encodeURIComponent(filter.nameContains)}`);
    }

    if (filter.showActive && !filter.showCompleted) {
      filters.push('isCompleted=0');
    } else if (!filter.showActive && filter.showCompleted) {
      filters.push('isCompleted=1');
    }

    if (filter.assigneesIds) {
      filters.push(`assignedTo=${filter.assigneesIds.join(',')}`);
    }

    if (filter.showKeyDatesOnly) {
      filters.push('showKeyDatesOnly=1');
    }

    if (!filter.showEmailTasks || !filter.showStandardTasks || !filter.showEmailTasks) {
      const types = [];

      if (filter.showStandardTasks) {
        types.push(TaskType.STANDARD);
      }

      if (filter.showEmailTasks) {
        types.push(TaskType.EMAIL);
      }

      if (filter.showSmsTasks) {
        types.push(TaskType.SMS);
      }

      if (types.length) {
        filters.push(`types=${types.join(',')}`);
      }
    }

    if (filter.rootTasksIds) {
      filters.push(`rootTasksIds=${filter.rootTasksIds.join(',')}`);
    }

    if (filter.noDueDate) {
      filters.push('dueDateSet=0');
    }

    if (filter.ids) {
      filters.push(`ids=${filter.ids.join(',')}`);
    }

    if (filter.isDependentOnStartDate) {
      filters.push('isDependentOnStartDate=1');
    }

    if (filter.isDependentOnEndDate) {
      filters.push('isDependentOnEndDate=1');
    }

    if (filter.tasksGroupsIds) {
      filters.push(`groupsIds=${filter.tasksGroupsIds.join(',')}`);
    }

    if (filters) {
      return filters.join('&');
    } else {
      return '';
    }
  };

  return {
    buildQueryString,
  };
}
