import React from 'react';
import { useMassUpdateServiceRequired, usePopupManager } from 'ui-builder';
import { useEventsPublisher } from 'event-bus';
import useMassDeleteService from './deleteService';
import { MassUpdateFormProps } from '../../../shared/widgets/MassUpdate';
import { ConfirmDeletePopup } from 'features/nekst-widgets';
import { PLAN_TASKS_UPDATED } from 'nekst-api';

function DeleteForm(props: MassUpdateFormProps) {
  const deleteService = useMassDeleteService();

  const eventsPublisher = useEventsPublisher();

  return (
    <ConfirmDeletePopup
      title="Mass Update: Delete Tasks"
      deleteFunc={
        async () => {
          await deleteService.massDelete(props.ids);
          eventsPublisher.publish(PLAN_TASKS_UPDATED, {});
        }
      }
      message={'Please "Confirm" to Delete Selected Tasks'}
    />
  );
}

export default function useDeleteForm() {
  const popupManager = usePopupManager();

  const massUpdateService = useMassUpdateServiceRequired();

  const openForm = () => {
    popupManager.openPopup(
      <DeleteForm ids={massUpdateService.getCheckedIds()} />,
    );
  };

  return {
    openForm,
  };
}
