import React from 'react';
import OnboardingTour from '../../shared/onboarding/OnboardingTour';

export default function ProjectsListPageTour() {
  return (
    <OnboardingTour
      tourId="PropertiesList"
      steps={[
        {
          selector: '[data-key="projects-status-filter"]',
          content: 'View and manage Active, Closed and Archived Transactions.',
        },
        {
          selector: '[data-key="projects-filter-button"]',
          content: 'Display transactions based on Team Member or Type',
        },
        {
          selector: '[data-key="projects-aggregated-info"]',
          content: 'Get a quick snapshot of upcoming Commission income',
        },
        {
          selector: 'th[data-key="next-key-date-field"]',
          content: 'Instantly see the next major step in each transaction.',
        },
        {
          selector: '[data-key="closing-date-field"]',
          highlightedSelectors: ['[data-key="closing-date-field"]', '[data-key="commission-field"]'],
          content: 'Sort by any column using data that is pulled in from the Transaction Details page.',
        },
      ]}
    />
  );
}
