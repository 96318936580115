import React from 'react';
import { ProjectRole, useProjectAssignmentsService, useProjectRolesService } from 'nekst-api';
import { FormTemplate, AfterSubmitFunc } from 'ui-builder';
import TeamMembersCheckboxes from './TeamMembersCheckboxes';
import DataLoader from '../../shared/DataLoader';
import { usePopupFormManager } from 'features/nekst-widgets';

type DataType = {
  teamMembersIds: number[],
}

export default function AssignTeamMemberRoleForm(
  props: {
    roleId: number,
    projectId: number,
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {
  const projectRolesService = useProjectRolesService();

  const projectAssignmentsService = useProjectAssignmentsService();

  const createAssignments = async (teamMembersIds: number[]) => {
    const promises: Promise<any>[] = [];

    teamMembersIds.forEach((id) => {
      promises.push(projectAssignmentsService.addTaskAssignee(
        props.projectId,
        {
          rolesIds: [props.roleId],
          personId: id,
        },
      ));
    });

    await Promise.all(promises);
  };

  return (
    <DataLoader<ProjectRole>
      getDataFunc={async () => {
        return projectRolesService.getById(props.roleId);
      }}
    >
      {(data: ProjectRole) => {
        return (
          <FormTemplate<DataType>
            title={`Assign ${data.name}`}
            submitFormFunc={async (formData) => {
              await createAssignments(formData.teamMembersIds);

              return formData;
            }}
            afterSubmitFunc={props.afterSubmitFunc}
          >
            <TeamMembersCheckboxes />
          </FormTemplate>
        );
      }}
    </DataLoader>
  );
}

export function useAssignProjectRoleForm() {
  const popupManager = usePopupFormManager();

  const openForm = (projectId: number, roleId: number) => {
    popupManager.openForm(<AssignTeamMemberRoleForm roleId={roleId} projectId={projectId} />);
  };

  return {
    openForm,
  };
}
