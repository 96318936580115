import React from 'react';
import { BaseHistoryEntryData, ProjectStatus, useProjectStatusHelper } from 'nekst-api';
import RecordLayout, { RecordLayoutProps } from './RecordLayout';
import ProjectLink from './ProjectLink';
import PersonLink from './PersonLink';
import { HistoryEventIconType } from './HistoryEventIcon';

interface ProjectStatusUpdatedData extends BaseHistoryEntryData {
  oldStatus: ProjectStatus,
  newStatus: ProjectStatus,
}

export default function ProjectStatusUpdatedRecordLayout(
  props: RecordLayoutProps<ProjectStatusUpdatedData>,
) {
  const newStatus = props.record.data.newStatus;

  const projectStatusHelper = useProjectStatusHelper();

  return (
    <RecordLayout
      text={(
        <>
          <ProjectLink project={props.record.data.project} />
          {` has been changed to ${projectStatusHelper.getLabel(newStatus)} status`}
          {' by '}
          <PersonLink person={props.record.data.person!} />
          .
        </>
      )}
      date={props.record.when}
      iconType={HistoryEventIconType.STATUS_UPDATED}
    />
  );
}
