import React from 'react';
import { ExistingTasksDecision, NoDecisionMadeError, useLaunchProjectService } from 'nekst-api';
import { Button, ButtonColor, ButtonSize, usePopupManager } from 'ui-builder';
import { Popup, PopupText, useErrorPopup } from 'features/nekst-widgets';

interface LaunchButtonProps {
  projectId: number,
  openProjectPageFunc: (
    projectId: number,
    afterLaunch?: boolean
  ) => void,
}

export function LaunchProjectAndRemoveOldTasksButton(props: LaunchButtonProps) {
  const launchProjectService = useLaunchProjectService();

  const popupManager = usePopupManager();

  const errorPopup = useErrorPopup();

  const submit = async () => {
    try {
      await launchProjectService.launch(props.projectId, ExistingTasksDecision.REMOVE);
      popupManager.closePopup();
      props.openProjectPageFunc(props.projectId, true);
    } catch (e) {
      errorPopup.openPopup(e as Error);
    }
  };

  return (
    <Button text="Removed" color={ButtonColor.GREEN} onClick={submit} size={ButtonSize.MEDIUM} />
  );
}

export function LaunchProjectAndNotRemoveOldTasksButton(props: LaunchButtonProps) {
  const launchProjectService = useLaunchProjectService();

  const popupManager = usePopupManager();

  const errorPopup = useErrorPopup();

  const submit = async () => {

    try {
      await launchProjectService.launch(props.projectId, ExistingTasksDecision.LEFT_AS_IS);
      popupManager.closePopup();
      props.openProjectPageFunc(props.projectId, true);
    } catch (e) {
      errorPopup.openPopup(e as Error);
    }
  };

  return (
    <Button text="Left as-is" color={ButtonColor.YELLOW} onClick={submit} size={ButtonSize.MEDIUM} />
  );
}

export function LaunchProjectButton(props: LaunchButtonProps & {
  size?: ButtonSize,
}) {
  const launchProjectService = useLaunchProjectService();

  const popupManager = usePopupManager();

  const openDecisionPopup = () => {
    popupManager.openPopup(
      <Popup
        title="Transaction already has tasks"
        content={(
          <PopupText>
            New tasks will be applied to this transaction. Tasks from the previous plan should be:
          </PopupText>
        )}
        buttons={(
          <>
            <LaunchProjectAndRemoveOldTasksButton
              projectId={props.projectId}
              openProjectPageFunc={props.openProjectPageFunc}
            />
            <LaunchProjectAndNotRemoveOldTasksButton
              projectId={props.projectId}
              openProjectPageFunc={props.openProjectPageFunc}
            />
          </>
        )}
      />,
    );
  };

  const submit = async () => {
    try {
      await launchProjectService.launch(props.projectId);
      props.openProjectPageFunc(props.projectId, true);
    } catch (e) {
      if (e instanceof NoDecisionMadeError) {
        openDecisionPopup();
      } else {
        throw e;
      }
    }
  };

  return (
    <Button
      text="Launch Transaction"
      size={props.size || ButtonSize.BIG}
      isFilled
      dataKey="launch-project-button"
      color={ButtonColor.GREEN}
      onClick={submit}
    />
  );
}
