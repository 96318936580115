/* istanbul ignore file */
import React, { ReactElement, useEffect } from 'react';

import WebFont from 'webfontloader';
import styles from './BaseLayout.module.scss';
import NekstUiTheme from '../uitheme/NekstUiTheme';
import Header from '../header/Header';
import { BreadcrumbsPortal } from './Breadcrumbs';
import { PopupContext } from 'ui-builder';
import UpdateCheckerWidget from '../../update/UpdateCheckerWidget';
import useFeatureToggleService from 'feature-toggle';

export interface BaseLayoutProps {
  children: ListOrSingle<ReactElement | string>;
}

function BaseLayout(props: BaseLayoutProps) {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['PT Sans', 'Roboto', 'Be Vietnam Pro', 'Inter'],
      },
    });
  });

  const featureToggleService = useFeatureToggleService();

  return (
    <NekstUiTheme>
      <div className={
        [
          'nekst',
          featureToggleService.isNewUiEnabled() ? 'newUi' : '',
        ].join(' ')
      }>
        <PopupContext>
          <div className={styles.headerBlock}>
            <div className={styles.blockContent}>
              <Header />
            </div>
          </div>
          <BreadcrumbsPortal />
          <UpdateCheckerWidget />
          <div className={styles.mainBlock}>
            <div className={styles.blockContent}>
              {props.children}
            </div>
          </div>
        </PopupContext>
      </div>
    </NekstUiTheme>
  );
}

export default BaseLayout;
