/* istanbul ignore file */
import React from 'react';
import { FormButtonLayoutProps } from 'ui-builder';
import Button, { ButtonColor, ButtonSize } from '../../button/Button';

function CancelButtonLayout(props: FormButtonLayoutProps) {
  return (
    <Button
      text={props.text}
      onClick={props.onClick}
      color={ButtonColor.RED}
      size={ButtonSize.BIG}
    />
  );
}

export default CancelButtonLayout;
