import React from 'react';
import { BaseFieldLayoutProps, BaseFieldProps } from './types';
import { useNewUiTheme } from '../newUiTheme';
import { useFieldHelper } from './fieldHelper';

export enum TextFieldIcon {
  SEARCH = 'search',
}

export interface TextFieldLayoutProps extends BaseFieldLayoutProps {
  numberOfLines?: number,
  icon?: TextFieldIcon,
}

export interface TextFieldProps extends BaseFieldProps {
  numberOfLines?: number,
  icon?: TextFieldIcon,
}

export function TextField(
  props: TextFieldProps,
) {
  const uiTheme = useNewUiTheme();
  const fieldHelper = useFieldHelper(props);

  return (
    <uiTheme.fields.TextFieldLayout
      {...fieldHelper.getBaseLayoutProps()}
      numberOfLines={props.numberOfLines}
      icon={props.icon}
    />
  );
}
