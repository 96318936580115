import {
  useObjectDatesMapper,
  useEventsPublisher,
  useBackendApi,
  Permission,
  DownloadFileDto, FileDto
} from 'nekst-api';

import { DocumentLabel } from '../../settings/documentstorage/labels/documentLabelsService';
import {
  DocumentsTemplate,
} from '../../settings/documentstorage/templates/documentsTemplatesService';

export type ProjectDocument = {
  id: number,
  fileId?: number
  labelId?: number
  templateId?: number
  isUniversalDocument: boolean
  _file: FileDto,
  _label: DocumentLabel,
  _template: DocumentsTemplate,
  _permissions: Permission[]
}

export enum ProjectDocumentPermission {
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  SET_FILE = 'SET_FILE',
  DOWNLOAD = 'DOWNLOAD',
}

export const DOCUMENTS_UPDATED = 'DOCUMENTS_UPDATED';

export default function useProjectDocumentsService() {
  const nekstApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const dateMapper = useObjectDatesMapper<ProjectDocument>(
    [],
    ['uploadedAt'],
  );

  const getProjectDocuments = async (projectId: number) => {
    const result = await nekstApi.get(`/projects/${projectId}/documents`);

    return dateMapper.mapList(result);
  };

  function publishEvent() {
    eventsPublisher.publish(DOCUMENTS_UPDATED, {});
  }

  const applyTemplate = async (projectId: number, templateId: number) => {
    await nekstApi.post(`/projects/${projectId}/documents/applytemplate`, {
      templateId,
    });
    publishEvent();
  };

  const deleteProjectDocument = async (documentId: number) => {
    await nekstApi.delete(`/projectdocuments/${documentId}`);

    publishEvent();
  };

  const getDownloadLink = async (documentId: number) => {
    return await nekstApi.get(`/projectdocuments/${documentId}/downloadlink`) as DownloadFileDto;
  };

  const setFile = async (documentId: number, fileId?: number) => {
    const result = await nekstApi.put(`/projectdocuments/${documentId}/file`, {
      fileId,
    });

    publishEvent();

    return dateMapper.mapSingle(result);
  };

  const createDocument = async (projectId: number, fileId: number) => {
    const result = await nekstApi.post(`/projects/${projectId}/documents`, {
      fileId,
    });
    publishEvent();

    return dateMapper.mapSingle(result);
  };

  const addLabels = async (projectId: number, labelsIds: number[]) => {
    await nekstApi.post(`/projects/${projectId}/documents/labels`, {
      labelsIds,
    });
    publishEvent();
  };

  const renameFile = async (documentId: number, name: string) => {
    await nekstApi.put(`/projectdocuments/${documentId}/file/name`, {
      fileName: name,
    });
    publishEvent();
  };

  const bulkDelete = async (ids: number[]) => {
    await nekstApi.put('/projectdocuments/bulk/delete', {
      ids,
    });

    publishEvent();
  };

  const bulkDownload = async (ids: number[]) => {
    return await nekstApi.put('/projectdocuments/bulk/download', {
      ids,
    }) as DownloadFileDto[];
  };

  const getSharingUrl = async (documentId: number) => {
    const result = await nekstApi.get(`/projectdocuments/${documentId}/sharingurl`) as {
      link: string
    };

    return result.link;
  };

  const assignLabelToDocument = async (documentId: number, labelId: number) => {
    const result = await nekstApi.put(`/projectdocuments/${documentId}/label`, {
      labelId,
    });

    publishEvent();

    return dateMapper.mapSingle(result);
  };

  return {
    getProjectDocuments,
    applyTemplate,
    deleteProjectDocument,
    getDownloadLink,
    createDocument,
    setFile,
    assignLabelToDocument,
    addLabels,
    renameFile,
    bulkDelete,
    bulkDownload,
    getSharingUrl,
  };
}
