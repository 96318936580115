import { ProjectType } from 'nekst-api';
import React from 'react';
import { BaseInputProps, Dropdown, MultiSelectDropdown, Option } from 'ui-builder';

export interface ProjectTypeDropdownProps extends BaseInputProps {
  additionalOptions?: Option<string>[];
}

function getOptions() {
  return [
    {
      value: ProjectType.LISTING,
      label: 'Listing',
    },
    {
      value: ProjectType.PENDING_BUYER,
      label: 'Pending Buyer',
    },
    {
      value: ProjectType.PENDING_SELLER,
      label: 'Pending Seller',
    },
    {
      value: ProjectType.PENDING_DUAL,
      label: 'Pending Dual',
    },
    {
      value: ProjectType.ACTIVE_BUYER,
      label: 'Active Buyer',
    },
    {
      value: ProjectType.OTHER,
      label: 'Other',
    },
  ];

}

export function ProjectTypeDropdown(props: ProjectTypeDropdownProps) {
  const options = [
    ...getOptions(),
    ...props.additionalOptions ? props.additionalOptions : [],
  ];

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Dropdown<string>
      {...props}
      options={options}
    />
  );
}

export function ProjectTypeMultiSelectDropdown(props: BaseInputProps<ProjectType[]>) {
  const options  = getOptions();

  return (
    <MultiSelectDropdown options={options} {...props} />
  )
}
