import { useLocation, useNavigate } from 'react-router-dom';
import { useEventsSubscriber } from 'nekst-api';
import { POPUP_CLOSED } from 'ui-builder';

export default function TaskIdParamRemover() {
  const location = useLocation();
  const navigate = useNavigate();

  useEventsSubscriber(
    'TasksList',
    {
      [POPUP_CLOSED]: () => {
        const params = new URLSearchParams(location.search);

        params.delete('taskId');
        navigate(`${location.pathname}?${params.toString()}`);
      },
    },
    [location.search],
  );

  return null;
}
