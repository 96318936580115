import React from 'react';
import { Person, PersonPermission, usePeopleService } from 'nekst-api';
import { useAuthorizationChecker } from 'authorization-scope';
import { AfterSubmitFunc, SubmitButton } from 'ui-builder';
import { BasePersonFormTemplate } from './Shared/BasePersonFormTemplate';
import { UpdatePersonFormFields } from './UpdatePersonFormFields';

export function UpdatePersonForm(
  props: {
    person: Person,
    afterSubmitFunc?: AfterSubmitFunc<Person>,
  },
) {
  const peopleService = usePeopleService();

  const { isGranted } = useAuthorizationChecker();

  return (
    <BasePersonFormTemplate<Person>
      submitFormFunc={async (data) => peopleService.update(props.person.id, data)}
      afterSubmitFunc={props.afterSubmitFunc}
      initialData={props.person}
      buttons={<SubmitButton text="Save" />}
      validationSchema={(data) => peopleService.getUpdateValidationSchema(data.teamRole)}
      isDisabled={!isGranted(PersonPermission.UPDATE)}
      title={props.person.profile.name.fullName}
    >
      <UpdatePersonFormFields person={props.person} />
    </BasePersonFormTemplate>
  );
}
