import React from 'react';
import { HistoryEntry, HistoryEvent, ProjectHistoryFilter, TeamPermission } from 'nekst-api';
import { ListFilterService, useListContext, VisibilityState } from 'ui-builder';
import FilterIncludedExcludedInput from '../../../shared/widgets/FilterIncludedExcludedInput';
import FilterWidgetLayout from '../../view/tasks/list/filter/FilterWidgetLayout';
import useAuthorizationChecker from '../../../shared/authorization/authorizationChecker';

export const EVENT_FILTER_NAME = 'EVENT_FILTER';

function useHistoryEventHelper() {
  const map = {
    [HistoryEvent.TASK_COMPLETED]: 'Completed Task',
    [HistoryEvent.EMAIL_SENT]: 'Emails Were Sent',
    [HistoryEvent.PROJECT_ASSIGNMENT_CREATED]: 'A contact was added',
    [HistoryEvent.PROJECT_ASSIGNMENT_REMOVED]: 'A contact was removed',
    [HistoryEvent.PROJECT_CREATED]: 'A new transaction is created',
    [HistoryEvent.PROJECT_STATUS_UPDATED]: 'A transaction status is changed',
    [HistoryEvent.PROJECT_TYPE_UPDATED]: 'A transaction type is changed',
    [HistoryEvent.PROJECT_LAUNCHED]: 'A checklist is added',
    [HistoryEvent.TASK_COMMENT_CREATED]: 'A comment is created',
    [HistoryEvent.TASK_CREATED]: 'A task is created',
    [HistoryEvent.TASK_REOPENED]: 'A comment is reopened',
    [HistoryEvent.PROJECT_NOTE_CREATED]: 'A note is created',
    [HistoryEvent.SMS_SENT]: 'SMS messages were sent',
    [HistoryEvent.SMS_SENT_AS_EMAIL]: 'SMS message was sent as email',
    [HistoryEvent.EMAIL_OPENED]: 'Email is opened',
  };

  const getName = (event: HistoryEvent): string => {
    return map[event];
  };
  return {
    getName,
  };
}

export function HistoryEventNameFilter() {

  const { isGranted } = useAuthorizationChecker();

  const events = [
    HistoryEvent.TASK_COMPLETED,
    HistoryEvent.EMAIL_SENT,
    HistoryEvent.PROJECT_ASSIGNMENT_CREATED,
    HistoryEvent.PROJECT_ASSIGNMENT_REMOVED,
    HistoryEvent.PROJECT_CREATED,
    HistoryEvent.PROJECT_STATUS_UPDATED,
    HistoryEvent.PROJECT_TYPE_UPDATED,
    HistoryEvent.PROJECT_LAUNCHED,
    HistoryEvent.TASK_COMMENT_CREATED,
    HistoryEvent.PROJECT_NOTE_CREATED,
  ];

  if (isGranted(TeamPermission.USE_SMS_TASKS_FEATURE)) {
    events.push(HistoryEvent.SMS_SENT);
  }

  const helper = useHistoryEventHelper();

  const options = events.map((e) => ({
    value: e,
    label: helper.getName(e),
  }));

  return (
    <FilterIncludedExcludedInput
      options={options}
      filterName="eventTypes"
      title="Show Events"
    />
  );
}

export function EventTypeFilterWidget() {
  const listContext = useListContext<HistoryEntry, ProjectHistoryFilter>();

  const filterValue = listContext.filterValues?.eventTypes || {
    selectAll: true,
    selected: [],
  };

  const helper = useHistoryEventHelper();

  const resetFilter = () => {
    listContext.setFilterValues!({
      ...listContext.filterValues!,
      eventTypes: {
        selected: [],
        selectAll: true,
      },
    });
  };

  if (filterValue.selected.length) {
    return (
      <FilterWidgetLayout
        name="Events"
        text={
          filterValue.selected.map((id) => helper.getName(id))
            .join(', ')
        }
        onClick={resetFilter}
      />
    );
  } else {
    return null;
  }
}

export default function useEventTypeFilter(): ListFilterService {
  const listContext = useListContext<HistoryEntry, ProjectHistoryFilter>();

  const defaultValue = {
    selectAll: true,
    selected: [],
  };

  const getFilterValue = () => {
    return listContext.filterValues?.eventTypes || defaultValue;
  };

  const setFilterValue = (value: { selectAll: boolean, selected: HistoryEvent[] }) => {
    listContext.setFilterValues!({
      ...listContext.filterValues! || {},
      eventTypes: value,
    });
  };

  const filterFunc = (
    data: HistoryEntry,
  ) => {
    const filterValue = getFilterValue();
    if (filterValue.selectAll) {
      return VisibilityState.VISIBLE;
    } else {
      return filterValue.selected.includes(data.event)
        ? VisibilityState.VISIBLE
        : VisibilityState.HIDDEN;
    }
  };

  return {
    getName: () => EVENT_FILTER_NAME,
    setFilterValue,
    getFilterValue,
    getDefaultValue: () => defaultValue,
    applyFilter: filterFunc,
  };
}
