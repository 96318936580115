import React from 'react';
import { WysiwygLayoutProps } from 'ui-builder';
import QuillWysiwygLayout from '../../../form/input/layout/QuillWysiwygLayout';

export default function WysiwygLayout(props: WysiwygLayoutProps) {
  return (
    <QuillWysiwygLayout
      {...props}
    />
  );
}
