/* istanbul ignore file */
import React, { ReactElement } from 'react';
import { UiThemeOverrider } from '../../uibuilder/uiTheme';

import './NekstUiTheme.scss';
import CheckboxLayout from './input/CheckboxLayout';
import DropdownLayout from './input/DropdownLayout';
import SubmitButtonLayout from './buttons/SubmitButtonLayout';
import CancelButtonLayout from './buttons/CancelButtonLayout';
import ShowLayout from './show/ShowLayout';

export interface NekstUiThemeProps {
  children: ReactElement | ReactElement[];
}

function NekstUiTheme(props: NekstUiThemeProps) {
  const uiTheme = {
    inputs: {
      CheckboxLayout,
      BaseDropdownLayout: DropdownLayout,
    },
    buttons: {
      SubmitButtonLayout,
      CancelButtonLayout,
    },
    show: {
      ShowLayout,
    },
  };

  return (
    <UiThemeOverrider theme={uiTheme}>
      {props.children}
    </UiThemeOverrider>
  );
}

export default NekstUiTheme;
