import React, { ReactElement } from 'react';
import useAuthorFilter from './filter/authorFilter';
import ClientSideFilter from '../../shared/filter/ClientSideFilter';
import useEventTypeFilter from './filter/eventTypeFilter';
import FilterWidgets from './filter/FilterWidgets';
import useTextFilter from './filter/textFilter';

export default function ProjectHistoryEventWrapper(
  props: { children: ReactElement },
) {
  return (
    <ClientSideFilter
      filters={[
        useAuthorFilter(),
        useEventTypeFilter(),
        useTextFilter(),
      ]}
      noDataMatchingFilterMessage="No events matching the filter criteria."
    >
      <FilterWidgets />
      {props.children}
    </ClientSideFilter>
  );
}
