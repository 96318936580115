import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { Button, ButtonColor, ButtonSize, useFormContext } from 'ui-builder';
import { ClientConfigurationFormData } from './ClientPortalConfigurationForm';
import { ClientPortalConfiguration } from './clientPortalConfigurationService';

export function OpenPortalPreviewPortal() {
  return (<span id="open-portal-preview" />);
}

export default function OpenPortalPreview(props: {
  data?: ClientPortalConfiguration & { _isNew?: boolean },
}) {
  const formData = useFormContext<ClientConfigurationFormData>();

  const data = formData.data || props.data;

  const onClick = () => {
    window.open(`${data?._portalUrl}?preview=1`, '_blank');
  };

  const container = document.getElementById('open-portal-preview');

  let element: ReactElement | null = null;

  if (!data?._isNew) {
    element = (
      <Button
        color={ButtonColor.BLUE}
        size={ButtonSize.MEDIUM}
        text="Preview Portal"
        isFilled={false}
        onClick={onClick}
      />
    );
  }

  if (container) {
    return ReactDOM.createPortal(
      element,
      container,
    );
  } else {
    return element;
  }
}
