import React, { ReactElement } from 'react';

export default function Label(
  props: {
    label?: string | ReactElement,
  },
) {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {props.label && (
        <div className="label">
          <span className="label-text">{props.label}</span>
        </div>
      )}
    </>
  );
}
