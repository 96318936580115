export interface WhenResolver {
  isApplicable: (_object: Record<string, any>, _constraintSchema: any) => boolean;
}

function hasWhenConditions(constraintSchema: any): boolean {
  return typeof constraintSchema === 'object' && 'when' in constraintSchema
    && typeof constraintSchema.when === 'object';
}

function isEqual(value: any, expectedValue: any): boolean {
  return value === expectedValue;
}

function isNotEqual(value: any, expectedValue: any): boolean {
  return value !== expectedValue;
}

function isIn(value: any, lookedValues: any[]): boolean {
  return lookedValues.includes(value);
}

function isEmpty(value: any): boolean {
  return !value;
}

function isNotEmpty(value: any): boolean {
  return !!value && (!Array.isArray(value) || value.length > 0);
}

function useWhenResolver(): WhenResolver {
  const isApplicable = (object: Record<string, any>, constraintSchema: any) => {
    if (hasWhenConditions(constraintSchema)) {
      let result = true;
      Object.entries(constraintSchema.when)
        .forEach(([fieldName, conditions]) => {
          const value = object[fieldName];
          Object.entries(conditions as object)
            .forEach(([expression, expressionValue]) => {
              let expressionResult;

              switch (expression) {
                case 'eq':
                  expressionResult = isEqual(value, expressionValue);
                  break;
                case 'neq':
                  expressionResult = isNotEqual(value, expressionValue);
                  break;
                case 'in':
                  expressionResult = isIn(value, expressionValue);
                  break;
                case 'empty':
                  expressionResult = isEmpty(value);
                  break;
                case 'notEmpty':
                  expressionResult = isNotEmpty(value);
                  break;
                default:
                  expressionResult = true;
              }

              if (!expressionResult) {
                result = false;
              }
            });
        });

      return result;
    } else {
      return true;
    }
  };

  return {
    isApplicable,
  };
}

export default useWhenResolver;
