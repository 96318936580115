import { useFormContext } from 'ui-builder';
import { Permission, useAuthorizationScopeContext } from './authorizationScopeContext';
import React from 'react';

export function useAuthorizationChecker() {
  const context = useAuthorizationScopeContext();

  const { data: formContextData } = useFormContext();

  const isGranted = (permission: Permission): boolean => {
    const permissions = context || [];
    return permissions.permissions.includes(permission)
      || (formContextData?.permissions && formContextData.permissions.includes(permission));
  };

  return {
    isGranted,
    isLoaded: context.isLoaded,
  };
}

export function withAccessCheck<T>(
  WrappedComponent: React.ComponentType<T>,
  permission: Permission
) {
  return (props: T) => {
    const { isGranted, isLoaded } = useAuthorizationChecker();

    if (isLoaded) {
      if (isGranted(permission)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        return <WrappedComponent {...props} />;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
}
