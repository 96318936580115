import React from 'react';

import AssignmentsFieldLayout, { AssignmentInfo } from '../../../shared/widgets/AssignmentsFieldLayout';
import { FormFieldsData, useShowContext } from 'ui-builder';
import { PlanTask } from 'nekst-api';
import { useProjectRolesLoader } from 'features/tasks-forms';

export interface AssignmentsFieldProps {
  source?: string;
  label?: string;
  filterFunc?: (data: FormFieldsData) => boolean;
}

export default function AssignmentsField(props: AssignmentsFieldProps) {
  const context = useShowContext<PlanTask>();

  const {
    getRoleById,
    isLoaded,
  } = useProjectRolesLoader();

  if (props.source && isLoaded()) {
    const contextData = context.data!;

    // @ts-ignore
    let value = contextData[props.source] as {
      projectRoleId: number,
      id: number
    }[];

    if (props.filterFunc) {
      value = value.filter(props.filterFunc);
    }

    const assignments: AssignmentInfo[] = value.map((r) => {
      return {
        id: `${contextData.id}-${r.projectRoleId}`,
        name: getRoleById(r.projectRoleId)!.name,
      };
    });

    if (assignments.length) {
      return (
        <AssignmentsFieldLayout
          label={props.label}
          assignments={assignments}
        />
      );
    } else {
      return null;
    }

  } else {
    return null;
  }
}
