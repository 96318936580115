import React from 'react';

import styles from './Buttons.module.scss';
import { useFormContext } from 'ui-builder';
import BaseCircleButton from './BaseCircleButton';

export default function ApplyCircleButton<Type>(props: {
  submitFunc: (item: Type) => Promise<void>
}) {
  const formContext = useFormContext<Type>();

  return (
    <BaseCircleButton
      className={styles.apply}
      onClick={() => props.submitFunc(formContext.data!)}
    />
  );
}
