import React from 'react';
import { useHover, useLayer } from 'react-laag';
import { usePopupManager } from 'ui-builder';
import { TeamPermission, useAuthenticationContext, useLogoutService } from 'nekst-api';

import styles from './UserInformation.module.scss';
import useUserSessionStorage from '../../../login/userSessionStorage';
import useSettingsNavigator from '../../../settings/settingsNavigator';
import useAuthorizationChecker from '../../authorization/authorizationChecker';
import { ConfirmDeletePopup, ImageWidget, Separator } from 'features/nekst-widgets';

function HeaderWidget() {
  const authenticationContext = useAuthenticationContext();

  const user = authenticationContext?.user;

  if (user) {
    const name = user.profile.name;
    const fullName = name.fullName;
    const abbreviation = `${name.firstName.charAt(0)}${name.lastName.charAt(0)}`;

    let logoComponent;

    if (user.profile._avatarUrl) {
      logoComponent = (
        <ImageWidget src={user.profile._avatarUrl.resized} className={styles.logo} />);
    } else {
      logoComponent = (
        <div className={styles.logo}>
          {abbreviation.toUpperCase()}
        </div>
      );
    }

    return (
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <>
        {logoComponent}
        <div className={styles.name}>
          {fullName}
        </div>
      </>
    );
  } else {
    return null;
  }
}

function MenuItem(props: {
  text: string,
  className?: string,
  uri: string,
  onClick: () => void,
}) {
  const classes = [
    styles.item,
    props.className,
  ];
  return (
    <a
      href={props.uri}
      className={classes.join(' ')}
      onClick={(event) => {
        event.preventDefault();
        props.onClick();
      }}
      data-test="user-menu-item"
      style={{
        textDecoration: 'none',
      }}

    >
      {props.text}
    </a>
  );
}

function ConfirmLogoutPopup() {
  const logoutService = useLogoutService();
  return (
    <ConfirmDeletePopup
      deleteFunc={async () => {
        await logoutService.logout();
      }}
      message="Are you sure you want to logout?"
    />
  );
}

function LogoutItem() {
  const popupManager = usePopupManager();

  return (
    <MenuItem
      uri="#"
      text="Logout"
      className={styles.logout}
      onClick={() => popupManager.openPopup(<ConfirmLogoutPopup />)}
    />
  );
}

function UserInformationMenu() {
  const settingsNavigator = useSettingsNavigator();

  const { isGranted } = useAuthorizationChecker();

  return (
    <div className={styles.menu}>
      <MenuItem
        text="Profile"
        onClick={settingsNavigator.openUpdateProfilePage}
        uri={settingsNavigator.getProfileLink()}
      />
      {isGranted(TeamPermission.MANAGE_BILLING) && (
        <MenuItem
          text="Billing"
          onClick={settingsNavigator.openBillingPage}
          uri={settingsNavigator.getBillingPageLink()}
        />
      )}
      <MenuItem
        text="Notifications"
        onClick={settingsNavigator.openNotificationsSettingsPage}
        uri={settingsNavigator.getNotificationsSettingsLink()}
      />
      {isGranted(TeamPermission.ACCESS_SETUP) && (
        <>
          <Separator marginSize="small" />
          <MenuItem
            text="Setup"
            onClick={settingsNavigator.openCustomRolesList}
            uri={settingsNavigator.getCustomRolesLink()}
          />
        </>
      )}
      <Separator marginSize="small" />
      <MenuItem
        text="Support"
        uri="https://help.nekst.com"
        onClick={() => {
          window.open('https://help.nekst.com', '_blank')!
            .focus();
        }}
      />
      <LogoutItem />
    </div>
  );
}

export default function UserInformation() {

  const userSessionStorage = useUserSessionStorage();

  const [isOpen, hoverProps] = useHover({
    delayLeave: 50,
    hideOnScroll: true,
  });

  const isActive = window.location.pathname.startsWith('/me');

  const {
    renderLayer,
    triggerProps,
    layerProps,
  } = useLayer({
    isOpen,
    placement: 'bottom-center',
    overflowContainer: false,
    triggerOffset: 1,
    containerOffset: 0,
    auto: false,
    arrowOffset: 0,
    possiblePlacements: [
      'bottom-center',
    ],
  });

  if (userSessionStorage.hasToken()) {
    return (
      <div
        data-key="user-information-block"
        data-test="user-information-block"
        {...hoverProps}
        ref={triggerProps.ref}
        className={`${styles.userInformation} ${isActive && styles.active} ${isOpen && styles.hovered}`}
      >
        <HeaderWidget />
        {isOpen && renderLayer(
          <div {...layerProps} data-key="user-menu">
            <UserInformationMenu />
          </div>,
        )}
      </div>
    );
  } else {
    return null;
  }
}
