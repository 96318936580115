import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { AuthenticationError } from 'request-sender';
import { Error403, LoadingAnimation, SimpleBlock } from 'ui-builder';
import { useAuthorizationChecker } from './authorizationChecker';
import {
  AuthorizationScopeContext,
  AuthorizationScopeData,
  Permission,
  useAuthorizationScopeContext
} from './authorizationScopeContext';


export type GetPermissionsFunc<IdType extends number | undefined = number> = (
  _id: IdType,
) => Promise<Permission[]>;

export interface AuthorizationScopeProps<IdType extends number | undefined = number> {
  getPermissionsFunc: GetPermissionsFunc<IdType>,
  children: ReactNode,
  permissionToCheck?: string,
  scopeName: string,
  id: IdType
  dependencies?: unknown[],
  loadingView?: ReactElement,
}

export interface AccessCheckerProps {
  permissionToCheck: string,
  children: ReactNode
  show403Error?: boolean,
  loadingView?: ReactElement,
}

export function AccessChecker(props: AccessCheckerProps) {
  const {
    isGranted,
    isLoaded,
  } = useAuthorizationChecker();

  const show403Error = props.show403Error !== undefined ? props.show403Error : false;

  /* eslint-disable react/jsx-no-useless-fragment */
  if (isLoaded) {
    if (isGranted(props.permissionToCheck)) {
      return (
        <>
          {props.children}
        </>
      );
    } else if (show403Error) {
      return <Error403 />;
    } else {
      return null;
    }
  } else {
    return props.loadingView || (
      <SimpleBlock>
        <LoadingAnimation />
      </SimpleBlock>
    );
  }
}


export function AuthorizationScope<IdType extends number | undefined = number>(
  props: AuthorizationScopeProps<IdType>,
): ReactElement {
  const [contextData, setContextData] = useState<AuthorizationScopeData>({
    isLoaded: false,
    permissions: [],
  });

  const [
    scopeData,
    setScopeData,
  ] = useState<Nullable<Permission[]>>(null);

  const parentContextData = useAuthorizationScopeContext();

  const dependenciesString = JSON.stringify(props.dependencies);

  useEffect(() => {
    const loadPermissions = async () => {
      try {
        const permissions = await props.getPermissionsFunc(
          props.id,
        );
        setScopeData(permissions.map((permission) => `${props.scopeName}:${permission}`));
      } catch (e) {
        if (e instanceof AuthenticationError) {
          setScopeData([]);
        } else {
          throw e;
        }
      }
    };

    loadPermissions();
  }, [
    props,
    props.id,
    props.scopeName,
    dependenciesString,
  ]);

  useEffect(() => {
    if (scopeData != null && parentContextData.isLoaded) {
      setContextData({
        isLoaded: true,
        permissions: scopeData.concat(parentContextData.permissions || []),
      });
    }
  }, [
    scopeData,
    parentContextData.isLoaded,
    setContextData,
    parentContextData.permissions,
  ]);

  let children;

  if (contextData.isLoaded) {
    if (props.permissionToCheck) {
      children = (
        <AccessChecker permissionToCheck={props.permissionToCheck} loadingView={props.loadingView}>
          {props.children}
        </AccessChecker>
      );
    } else {
      children = props.children;
    }
  } else {
    children = props.loadingView || (<LoadingAnimation isBig />);
  }

  return (
    <AuthorizationScopeContext.Provider value={contextData}>
      {children}
    </AuthorizationScopeContext.Provider>
  );
}

