import React, { useEffect } from 'react';
import { AfterSubmitFunc, FormTemplate, useFormContext } from 'ui-builder';
import { ProjectPlanOfTypeDropdown, ProjectTypeDropdown } from 'features/tasks-forms';
import FileUploaderInput from '../../../shared/file/FileUploaderInput';
import {
  CreateProjectFromContractRequest, FileType,
  Project,
  useProjectFromContractCreator
} from 'nekst-api';
import useLaunchFromContractNavigator from './launchFromContractNavigator';
import { InformationMessage } from 'features/nekst-widgets';

import styles from './LoaderWidget.module.scss';

function LoaderWidget() {
  const messages = [
    'Using AI to extract Key Dates',
    'Pulling Transaction Party Information',
    'Linking To Detail Boxes'
  ];

  const formContext = useFormContext();

  const [isVisible, setIsVisible] = React.useState(false);

  const [messageIndex, setMessageIndex] = React.useState(0);

  const [numberOfDots, setNumberOfDots] = React.useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMessageIndex((prev) => {
        if (prev < messages.length - 1) {
          return prev + 1;
        } else {
          return prev;
        }
      });
    }, 15 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (formContext.loading) {
      setTimeout(() => {
        setIsVisible(true);
      }, 200);
    } else {
      setIsVisible(false);
    }
  }, [formContext.loading]);

  useEffect(() => {
    const interval = setInterval(() => {
      setNumberOfDots((prev) => {
        if (prev < 3) {
          return prev + 1;
        } else {
          return 1;
        }
      });
    }, 500);

    return () => {
      clearInterval(interval);
    };
  })

  if (isVisible) {
    return (
      <InformationMessage>
        The document is being processes. This may take 1-2 minutes. Please wait and do not refresh the page.
        <br />
        <br />
        <span className={styles.message}>{messages[messageIndex]}{Array(numberOfDots).fill('.').join('')}</span>
      </InformationMessage>
    );
  } else {
    return null;
  }
}

export default function ImportFromContractForm(
  props: {
    afterSubmitFunc?: AfterSubmitFunc,
  }
) {

  const projectCreator = useProjectFromContractCreator();

  const navigator = useLaunchFromContractNavigator();

  return (
    <FormTemplate<CreateProjectFromContractRequest, Project>
      title="Import from contract"
      submitFormFunc={async (request: CreateProjectFromContractRequest) => {
        return await projectCreator.createProjectFromContract(request);
      }}
      afterSubmitFunc={async (data) => {
        navigator.openVerifyContractDataPage(data.id);

        await props.afterSubmitFunc?.(data);
      }}
      validationSchema={{
        type: {
          type: 'string',
          constraints: {
            required: true,
          }
        },
        planId: {
          type: 'int',
          constraints: {
            required: true,
          }
        },
        fileId: {
          type: 'int',
          constraints: {
            required: true,
          }
        },
      }}
    >
      <ProjectTypeDropdown source="type" />
      <ProjectPlanOfTypeDropdown
        projectTypeSource="type"
        source="planId"
        label="Action Plan"
        withContractTemplateAssigned
      />
      <FileUploaderInput
        fileType={FileType.PROJECT_DOCUMENT}
        fileDataSource="_file"
        source="fileId"
        label="Contract Document"
      />
      <LoaderWidget />
    </FormTemplate>
  );
}
