import React from 'react';
import { ContactAssignments, useProjectAssignmentsService } from 'nekst-api';
import { BaseInputProps, LoadingAnimation, MultiSelectDropdown } from 'ui-builder';
import useDataLoader from 'data-loader';

interface EmailRecipientsInputProps extends BaseInputProps<number[]> {
  projectId: number;
}

export default function EmailRecipientsInput(props: EmailRecipientsInputProps) {

  const projectAssignmentsService = useProjectAssignmentsService();

  const [assignments, setAssignments] = React.useState<ContactAssignments[]>();

  useDataLoader(
    async () => projectAssignmentsService.getContactAssignments(props.projectId),
    setAssignments,
    props.projectId,
  );

  if (assignments) {
    const options = assignments.map((assignment) => ({
      value: assignment.personId,
      label: assignment.profile.name.fullName,
    }));

    return (
      <MultiSelectDropdown
        options={options}
        source={props.source}
        placeholder={props.placeholder}
        label={props.label}
      />
    );
  } else {
    return (
      <LoadingAnimation />
    )
  }
}
