import React from 'react';
import { useLoginService, LoginRequest } from 'nekst-api';
import {
  getLoginFormValidationSchema,
} from 'features/auth-feature';
import { SubmitButton, FormTemplate } from 'ui-builder';
import { useNavigate } from 'react-router-dom';
import Text from '../../shared/uibuilder/form/input/Text';
import Password from '../../shared/uibuilder/form/input/Password';

import styles from '../Login.module.scss';
import Right from '../../shared/web/Right';
import useLoginNavigator from '../loginNavigator';

function ForgotPasswordLink() {
  const loginNavigator = useLoginNavigator();

  return (
    <a
      href="/resetpassword"
      onClick={(event) => {
        loginNavigator.openResetPasswordPage();
        event.preventDefault();
      }}
    >
      Forgot Password?
    </a>
  );
}

function SignUpNavigator() {

  const navigate = useNavigate();
  return (
    <span>
      or
      {' '}
      <a
        href="/signup"
        onClick={(event) => {
          event.preventDefault();
          navigate('/signup');
        }}
      >
        Sign Up
      </a>
    </span>
  );
}

export default function LoginForm() {
  const loginService = useLoginService();

  return (
    <FormTemplate<LoginRequest>
      title="Log In"
      submitFormFunc={async (data) => {
        await loginService.login(data);
        return data;
      }}
      buttons={(
        <>
          <SubmitButton text="Log In" />
          <SignUpNavigator />
        </>
      )}
      validationSchema={getLoginFormValidationSchema()}
      className={styles.form}
    >
      <Text source="username" label="Email" />
      <Password
        source="password"
        label={(
          <span>
            <Right>
              <ForgotPasswordLink />
            </Right>
            Password
          </span>
        )}
      />
    </FormTemplate>
  );
}
