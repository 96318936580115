import React, { ReactNode } from 'react';

export interface LeftProps {
  children: ReactNode;
}

export default function Left({ children }: LeftProps) {
  return (
    <div
      style={{
        float: 'left',
      }}
    >
      {children}
    </div>
  );
}
