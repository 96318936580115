import { useEventsPublisher } from "event-bus";
import { useBackendApi } from "./backendApi";
import { useTasksResponseMapper } from "./Helper";
import { SmsTask } from "./Types";

export const SMS_TASK_SENT = 'SMS_TASK_SENT';

export function useSendSmsTaskService() {
  const projectsApi = useBackendApi();

  const { mapSingle } = useTasksResponseMapper();

  const eventsPublisher = useEventsPublisher();

  const sendSms = async (taskId: number) => {
    const result = await projectsApi.post(`/tasks/${taskId}/sendsms`);

    const task = mapSingle(result) as SmsTask;

    eventsPublisher.publish(SMS_TASK_SENT, {
      id: task.id,
      data: task,
    });

    return task;
  };

  return {
    sendSms,
  };
}
