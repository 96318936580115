import { usePlansService } from 'nekst-api';
import { ConfirmDeletePopup } from 'features/nekst-widgets';
import React from 'react';

export interface DeletePlanFormProps {
  id: number;
  afterSubmitFunc?: () => Promise<void>,
}

export function DeletePlanForm(props: DeletePlanFormProps) {
  const plansService = usePlansService();

  const deleteFunc = async () => {
    await plansService.delete(props.id);
    await props.afterSubmitFunc?.();
  };

  return (
    <ConfirmDeletePopup
      deleteFunc={deleteFunc}
      message="Are you sure you want to delete this plan?"
    />
  );
}
