import React from 'react';
import BaseTasksPage from './BaseTasksPage';
import useLaunchProjectNavigator from './launchProjectNavigator';
import LaunchProjectSteps, { LaunchProjectStepId } from './LaunchProjectSteps';

export default function ManageAllTasksPage() {
  const navigator = useLaunchProjectNavigator();

  return (
    <BaseTasksPage
      roadmap={<LaunchProjectSteps currentStep={LaunchProjectStepId.ALL_TASKS} />}
      onPrevClicked={navigator.openKeyDateTasksPage}
      onNextClicked={navigator.openTransactionPartiesPage}
    />
  );
}
