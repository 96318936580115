import React from 'react';
import { ButtonSize, OptionSelectionButton, usePopupManager } from 'ui-builder';
import { ConfirmDeletePopup, Popup, usePopupFormManager } from 'features/nekst-widgets';
import UpdateDetailsFieldsSetForm from '../CreateUpdate/UpdateDetailsFieldsSetForm';
import ManageAiTrainingDataWidget from '../../aitraining/ManageAiTrainingDataWidget';
import { DetailsFieldsSet, useDetailsFieldsSetsService } from 'nekst-api';
import useSettingsNavigator from '../../../settingsNavigator';

export default function EditSetButton(
  props: {
    set: DetailsFieldsSet,
  }
) {
  const popupFormManager = usePopupFormManager();

  const popupManager = usePopupManager();

  const setService = useDetailsFieldsSetsService();

  const settingsNavigator = useSettingsNavigator();

  return (
    <OptionSelectionButton
      text="Edit"
      size={ButtonSize.SMALL}
      options={[
        ...props.set.contractTemplateId ? [{
          label: 'AI Training',
          onSelected: () => {
            popupManager.openPopup(
              <Popup
                isBig
                title="AI Training"
                content={(<ManageAiTrainingDataWidget
                  set={props.set}
                />)}
              />
            );
          },
          value: 'ai_training'
        }] : [],
        {
          label: "Rename",
          onSelected: () => {
            popupFormManager.openForm(<UpdateDetailsFieldsSetForm detailsSet={props.set} />);
          },
          value: 'name'
        },
        {
          label: 'Delete Set',
          onSelected: () => {
            popupManager.openPopup(
              <ConfirmDeletePopup
                deleteFunc={async () => {
                  await setService.deleteSet(props.set.id);
                  settingsNavigator.openProjectDetailsFieldPage(props.set.projectType);
                }}
                message={`Are you sure you want to delete "${props.set.name}" set?`}
              />
            );
          },
          value: 'delete',
        }
      ]}
    />
  );
}
