import React, { ReactElement, useEffect, useState } from 'react';
import { StepType, TourProvider, useTour } from '@reactour/tour';
import { Permission, TourStatus, useAuthenticationContext, useOnboardingService } from 'nekst-api';
import { useAuthorizationChecker } from 'authorization-scope';
import { useSearchParams } from 'react-router-dom';

export interface Step extends StepType {
  permission?: ListOrSingle<Permission>,
}

function OnboardingTourStarter(
  props: {
    tourId: string
  },
) {
  const onboardingService = useOnboardingService();

  const status = onboardingService.getTourStatus(props.tourId);

  const { setIsOpen } = useTour();

  const [searchParams] = useSearchParams();

  const openTour = !!searchParams.get('openTour');

  useEffect(() => {
    if (openTour || status === TourStatus.NOT_PASSED) {
      setIsOpen(true);
    }
  }, [status]);

  return null;
}

function OnboardingTour(
  props: {
    // eslint-disable-next-line react/no-unused-prop-types
    tourId: string,
    steps: Step[],
    children?: ListOrSingle<ReactElement>
  },
) {
  const authenticationContext = useAuthenticationContext();

  const user = authenticationContext?.user;

  const onboardingService = useOnboardingService();

  const setPassed = async () => {
    await onboardingService.setTourStatus(props.tourId, TourStatus.PASSED);
  };

  const {
    isGranted,
    isLoaded,
  } = useAuthorizationChecker();

  if (user && isLoaded) {
    const availableSteps = props.steps.filter((v) => {
      let result: boolean;
      if (v.permission) {
        const permissions: Permission[] = Array.isArray(v.permission)
          ? v.permission as Permission[]
          : [v.permission];

        const notGrantedPermission = permissions
          .find((item: Permission) => !isGranted(item));

        result = !notGrantedPermission;
      } else {
        result = true;
      }

      if (result) {
        const selector = v.selector;

        if (typeof selector === 'string') {
          const elem = document.querySelector(selector);

          return !!elem;
        } else {
          return true;
        }
      } else {
        return false;
      }
    });

    return (
      <TourProvider
        steps={availableSteps}
        disableInteraction
        onClickMask={({
          setCurrentStep,
          currentStep,
          steps,
          setIsOpen,
        }) => {
          if (steps) {
            if (currentStep === steps.length - 1) {
              setIsOpen(false);
              setPassed();
            }
            setCurrentStep((s) => (s === steps.length - 1 ? 0 : s + 1));
          }
        }}
        onClickClose={({
          setCurrentStep,
          currentStep,
          steps,
          setIsOpen,
        }) => {
          if (steps) {
            if (currentStep === steps.length - 1) {
              setIsOpen(false);
              setPassed();
            }
            setCurrentStep((s) => (s === steps.length - 1 ? 0 : s + 1));
          }
        }}
      >
        {props.children}
        <OnboardingTourStarter tourId={props.tourId} />
      </TourProvider>
    );
  } else {
    return null;
  }
}

function withDelayHoc<T>(Component: React.ComponentType<T>, delay: number) {
  return (props: T) => {
    const [isRendered, setIsRendered] = useState(false);

    useEffect(() => {
      const timeout = setTimeout(() => {
        setIsRendered(true);
      }, delay);

      return () => {
        clearTimeout(timeout);
      };
    }, []);

    // @ts-ignore
    return isRendered ? <Component {...props} /> : null;
  };
}

export default withDelayHoc(OnboardingTour, 1000);
