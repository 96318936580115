import React from 'react';
import { get } from 'lodash';
import { useShowContext } from 'ui-builder';
import { ProjectDetailsFieldType } from 'nekst-api';
import useProjectDetailsFieldTypeHelper from '../projectDetailsFieldTypeHelper';

export default function ProjectDetailsFieldTypeField(
  props: {
    source: string,
    // eslint-disable-next-line react/no-unused-prop-types
    label?: string,
  },
) {
  const helper = useProjectDetailsFieldTypeHelper();

  const value = get(useShowContext().data, props.source);

  if (value) {
    return <>{helper.getLabel(value as ProjectDetailsFieldType)}</>;
  } else {
    return <>-</>;
  }

}
