import React from 'react';
import { AfterSubmitFunc, FormTemplate, TextInput } from 'ui-builder';
import { BaseTaskGroupRequest, TasksGroup } from 'nekst-api';
import { ColorPicker } from 'features/nekst-widgets';

export default function BaseTasksGroupForm(
  props: {
    afterSubmitFunc?: AfterSubmitFunc,
    title?: string,
    submitFormFunc: (data: BaseTaskGroupRequest) => Promise<TasksGroup>,
    initialData?: BaseTaskGroupRequest,
  }
) {
  return (
    <FormTemplate<BaseTaskGroupRequest, TasksGroup>
      submitFormFunc={async (data) => {
        return await props.submitFormFunc(data);
      }}
      afterSubmitFunc={props.afterSubmitFunc}
      title={props.title}
      initialData={props.initialData || {
        color: '#000000'
      }}
      validationSchema={{
        name: {
          type: 'string',
          constraints: {
            required: true,
          }
        },
        color: {
          type: 'string',
          constraints: {
            required: true,
            minLength: {
              value: 7,
              message: 'Color must be a hex color code'
            },
            maxLength: {
              value: 7,
              message: 'Color must be a hex color code'
            }
          }
        }
      }}
    >
      <TextInput source="name" label="Group Name" />
      <ColorPicker source="color" label="Color" />
    </FormTemplate>
  );
}
