import usePlansApi from '../../plansApi';

export default function useKeyDateService() {
  const plansApi = usePlansApi();

  const markAsKeyDate = async (ids: number[]) => {
    await plansApi.put('/plantasks/bulk/keydate', {
      ids,
    });
  };

  return {
    markAsKeyDate,
  };
}
