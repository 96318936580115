import React from 'react';
import { useNewUiTheme } from '../../newUiTheme';
import { useInputHelper } from './inputHelper';
import { BaseInputProps } from './types';
import { ReactElement } from 'react';

export interface InputLabelLayoutProps {
  label?: string | ReactElement,
  afterLabelElement?: string | ReactElement,
  hideLabel?: boolean,
  name: string,
  testID?: string,
  className?: string,
  showRequiredIcon?: boolean
  bold?: boolean,
}

export function InputLabel(props: BaseInputProps<any> & {
  showRequiredIcon?: boolean,
  bold?: boolean,
}) {
  const uiTheme = useNewUiTheme();

  const inputHelper = useInputHelper(props);

  if (props.hideLabel) {
    return null;
  } else {
    return (
      <uiTheme.inputs.InputLabelLayout
        label={inputHelper.getLabel()}
        afterLabelElement={inputHelper.getAfterLabelElement()}
        hideLabel={props.hideLabel}
        name={inputHelper.getSource()}
        testID={props.dataKey}
        className={inputHelper.getClassName()}
        bold={props.bold}
      />
    );
  }
}
