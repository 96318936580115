import React from 'react';
import OnboardingTour from '../shared/onboarding/OnboardingTour';

export default function CalendarViewTour() {
  return (
    <OnboardingTour
      tourId="CalendarTasksView"
      steps={[
        {
          selector: '[data-key="advanced-filter-block"]',
          content: (
            <>
              Quickly find tasks or isolate a transaction using the search bar. Use the filter for
              different task views.
              (Click the &quot;Filter&quot; button to proceed to the next step)
            </>
          ),
        },
        {
          selector: '[data-key="advanced-filter-button"]',
          highlightedSelectors: ['[data-key="popup"]'],
          mutationObservables: ['[data-key="popup"]'],
          resizeObservables: ['[data-key="popup"]'],
          content: (
            <div>
              <p>Display tasks by team member, Key Dates or task type.</p>
              <img
                src="/img/img_tasks_filter.png"
                alt="Advanced filter"
                style={{
                  maxWidth: '35rem',
                }}
              />
            </div>
          ),
        },
        {
          selector: '[data-key="export-for-ical-button"]',
          content: ' Link your Nekst tasks to your Google, Outlook, iOS or other online calendar.',
        },
        {
          selector: '.nekst-dropdown__control',
          content: (
            <div>
              <p>Switch between Month and Weekly task views.</p>
              <img src="/img/img_switch_calendar_view.png" alt="Switch Calendar View" />
            </div>
          ),
        },
      ]}
    />
  );
}
