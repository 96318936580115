import React from 'react';
import { SettingsBreadcrumbs } from '../../settingsModule';
import { Breadcrumb } from '../../../shared/web/layout/Breadcrumbs';
import InnerBlock from '../../../shared/web/layout/InnerBlock';
import DocumentLabelsList from './DocumentLabelsList';
import { CreateDocumentLabelButton } from './CreateDocumentLabelForm';

export default function DocumentLabelsPage() {
  return (
    <>
      <SettingsBreadcrumbs>
        <Breadcrumb text="Document Labels" />
      </SettingsBreadcrumbs>
      <InnerBlock
        title="Document Labels"
        headerRight={
          <CreateDocumentLabelButton />
        }
      >
        <DocumentLabelsList />
      </InnerBlock>
    </>
  );
}
