import React, { useMemo, useState } from 'react';
import useProjectAssignmentsHelper from '../../projectAssignmentsLoader';
import {
  ContactAssignments,
  EmailTask,
  ProjectAssignmentType,
  ProjectRole,
  useProjectRolesService
} from 'nekst-api';
import useDataLoader from 'data-loader';
import { Option, useFormContextRequired, useNewUiTheme } from 'ui-builder';

export function SendFromUserInput() {
  const { projectAssignments } = useProjectAssignmentsHelper();

  const [roles, setRoles] = useState<ProjectRole[]>();

  const projectRolesService = useProjectRolesService();

  useDataLoader(
    projectRolesService.getAllTeamRoles,
    setRoles
  );

  const formContext = useFormContextRequired<EmailTask>();

  const data = formContext.data!;

  const senderRoleId = data.senderRoleId;

  const senderUserId = data.senderId;

  function getUserLabel(assignment: ContactAssignments) {
    const rolesNames = assignment.roles.map((r) => r.roleName)
      .join(', ');
    return `${assignment.profile.name.fullName} - ${rolesNames}`;
  }

  function getRoleById(roleId: number) {
    return roles && roles.find((r) => r.id === roleId);
  }

  const options = useMemo(() => {
    if (roles && projectAssignments) {
      const result = projectAssignments
        .filter((item) => item.type === ProjectAssignmentType.TASK_ASSIGNMENT)
        .map((item) => ({
          value: `person:${item.personId}`,
          label: getUserLabel(item),
        } as Option));

      if (senderRoleId) {
        result.push({
          value: `role:${senderRoleId}`,
          label: getRoleById(senderRoleId)!.name,
        });
      }

      return result;
    } else {
      return [];
    }
  }, [projectAssignments?.length, roles?.length]);

  const uiTheme = useNewUiTheme();

  let value = null;
  if (senderUserId) {
    value = `person:${senderUserId}`;
  } else if (senderRoleId) {
    value = `role:${senderRoleId}`;
  }

  const onChangeCallback = (event: any) => {
    const newValue = event.target.value;

    let newUserId;
    let newRoleId;

    if (newValue.startsWith('person:')) {
      newUserId = parseInt(newValue.replace('person:', ''), 10);
    } else if (newValue.startsWith('role:')) {
      newRoleId = parseInt(newValue.replace('role:', ''), 10);
    }

    if (formContext) {
      formContext.onChangeCallback({
        senderId: newUserId,
        senderRoleId: newRoleId,
      });
    }
  };

  return (
    <uiTheme.inputs.BaseDropdownLayout
      name="sendFromUser"
      value={value}
      onChangeCallback={onChangeCallback}
      isVisible
      isDisabled={options.length === 0}
      options={options}
      placeholder="Whoever Clicks Send"
      dataKey="sendFromUser"
      label="Send From"
    />
  );

}
