import React from 'react';
import {
  FormTemplate,
  AfterSubmitFunc,
  Button,
  ButtonSize,
  ButtonColor, TextInput, usePopupManager,
} from 'ui-builder';
import {
  useTasksCompletionService,
  EmailData,
  AbstractTask,
  SmsTask,
} from 'nekst-api';
import EmailRecipientsInput from './EmailRecipientsInput';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { EmailTaskSmartTagsWysiwyg } from 'features/tasks-forms';
import { PopupForm } from 'features/nekst-widgets';

function SendSmsAsEmailForm(
  props: {
    task: AbstractTask,
    recipientsIds: number[],
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {
  const completionService = useTasksCompletionService();

  return (
    <FormTemplate<EmailData>
      initialData={{
        subject: `${props.task.name} - ${props.task._project.name}`,
        text: props.task.description!,
        recipientsIds: props.recipientsIds || [],
      }}
      title="Send SMS as email"
      submitFormFunc={async (emailData) => {
        await completionService.sendAsEmailAndComplete(props.task.id, emailData);
        return emailData;
      }}
      validationSchema={completionService.getEmailValidationSchema()}
      afterSubmitFunc={props.afterSubmitFunc}
    >
      <TextInput source="subject" label="Subject" />
      <EmailRecipientsInput
        projectId={props.task.projectId}
        source="recipientsIds"
        label="Email Recipients"
      />
      <EmailTaskSmartTagsWysiwyg
        source="text"
        label="Message"
        projectId={props.task.projectId}
        projectType={props.task._project.type}
      />
    </FormTemplate>
  );
}

export default function SendSmsAsEmailButton(
  props: {
    task: SmsTask,
    notDeliveredToIds: number[]
  },
) {
  const popupManager = usePopupManager();

  return (
    <Button
      text="Send as email"
      color={ButtonColor.GREEN}
      onClick={async () => popupManager.openPopup(
        <PopupForm isCentered>
          <SendSmsAsEmailForm task={props.task} recipientsIds={props.notDeliveredToIds} />
        </PopupForm>,
        true,
        )}
      size={ButtonSize.BIG}
    />
  );
}
