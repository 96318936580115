import React from 'react';
import {
  Button, ButtonColor, ButtonSize, isReactNative,
  ItemsList,
  LoadingAnimation,
  SimpleBlock,
  Text,
  usePopupManager
} from 'ui-builder';
import {
  CompletionErrorCause,
  CompletionErrorCode,
  SmsTaskNotDeliveredError,
  SmsTaskNotSentError,
  AbstractTask,
  SmsTask,
  useProjectAssignmentsService,
  ContactAssignments, useTasksCompletionService,
} from 'nekst-api';
import { Popup } from 'features/nekst-widgets';
import useDataLoader from 'data-loader';
import SendSmsAsEmailButton from './ActionButtons/SendSmsAsEmailButton';
import ForceCompleteButton from './ActionButtons/ForceCompleteButton';
import IgnoreErrorsMassCompleteButton from './ActionButtons/IgnoreErrorsMassCompleteButton';
import ForceMassCompleteButton from './ActionButtons/ForceMassCompleteButton';

function SendSmsAndCompleteButton(props: { task: SmsTask }) {
  const tasksCompletionService = useTasksCompletionService();

  const taskCompletionErrorHandler = useTaskCompletionErrorHandler();

  const popupManager = usePopupManager();

  const submit = async () => {
    try {
      await tasksCompletionService.sendSmsAndCompleteTask(props.task.id);
      popupManager.closePopup();
    } catch (e) {
      popupManager.closePopup();
      taskCompletionErrorHandler.handleError(props.task, e);
    }
  };

  return (
    <Button
      text="Send SMS & complete"
      color={ButtonColor.GREEN}
      onClick={submit}
      size={isReactNative() ? ButtonSize.MEDIUM : ButtonSize.BIG}
    />
  );
}


function TaskCompletionErrorHandler(
  props: { task: SmsTask },
) {
  return (
    <Popup
      title="Task wasn't sent"
      content={(
        <Text className="message">
          You&apos;re trying to complete the SMS task that was not sent. Please choose the action:
        </Text>
      )}
      buttons={(
        <>
          <SendSmsAndCompleteButton task={props.task} />
          <ForceCompleteButton id={props.task.id} />
        </>
      )}
    />
  );
}

function ConfirmCompletionWithoutDeliveringPopup(props: {
  task: SmsTask,
  notDeliveredToIds: number[],
}) {
  const projectAssignmentsService = useProjectAssignmentsService();

  const [projectAssigments, setProjectAssigments] = React.useState<ContactAssignments[]>();

  useDataLoader(
    async () => projectAssignmentsService.getContactAssignments(props.task.projectId),
    setProjectAssigments,
    props.task.projectId,
  );

  const getRecipientName = (id: number) => {
    if (projectAssigments) {
      return projectAssigments
      .find((r) => r.personId === id)?.profile.name.fullName;
    } else {
      return undefined;
    }
  };

  const names = props.notDeliveredToIds.map((id) => {
    const name = getRecipientName(id);
    if (name) {
      return name;
    } else {
      return '';
    }
  });

  return (
    <Popup
      title="SMS is not delivered"
      content={(
        <>
          {projectAssigments && (
            <SimpleBlock className="message">

              <SimpleBlock>
                <Text>SMS was not delivered to the following recipients:</Text>
              </SimpleBlock>
              <ItemsList
                items={names}
              />
              <SimpleBlock>
                <Text>Please choose the action:</Text>
              </SimpleBlock>
            </SimpleBlock>
          )}
          {!projectAssigments && (<LoadingAnimation />)}
        </>
      )}
      buttons={(
        <>
          <SendSmsAsEmailButton
            task={props.task}
            notDeliveredToIds={props.notDeliveredToIds}
          />
          <ForceCompleteButton id={props.task.id} />
        </>
      )}
    />
  );
}

function ConfirmMassCompletePopup(
  props: {
    causes: CompletionErrorCause[],
  },
) {
  return (
    <Popup
      title="Some tasks cannot be completed"
      content={(
        <SimpleBlock className="message">
          <>
            <Text>Some tasks cannot be completed due to the following reasons:</Text>
            <ItemsList
              items={props.causes.map((cause) => {
                const name = cause.taskName;

                let reason;

                switch (cause.errorCode) {
                  case CompletionErrorCode.SMS_TASK_NOT_SENT_CODE:
                    reason = '- SMS wasn\'t sent';
                    break;
                  case CompletionErrorCode.SMS_TASK_NOT_DELIVERED_CODE:
                    reason = '- SMS wasn\'t delivered to some recipients';
                    break;
                  default:
                    reason = '';
                }
                return `${name} ${reason}`;
              })}
            />
          </>
        </SimpleBlock>
      )}
      buttons={(
        <>
          <IgnoreErrorsMassCompleteButton />
          <ForceMassCompleteButton />
        </>
      )}
    />
  );
}

export function useTaskCompletionErrorHandler() {
  const popupManager = usePopupManager();

  const openConfirmCompletionWithoutSending = (task: SmsTask) => {
    popupManager.openPopup(
      <TaskCompletionErrorHandler task={task} />,
      true,
    );
  };

  const openConfirmCompletionWithoutDeliveringPopup = (
    task: SmsTask,
    notDeliveredToIds: number[],
  ) => {
    popupManager.openPopup(
      <ConfirmCompletionWithoutDeliveringPopup
        task={task}
        notDeliveredToIds={notDeliveredToIds}
      />,
      true,
    );
  };

  const openConfirmMassCompletePopup = (causes: CompletionErrorCause[]) => {
    popupManager.openPopup(
      <ConfirmMassCompletePopup causes={causes} />,
      true,
    );
  };

  const handleError = (task: AbstractTask, e: any) => {
    if (e instanceof SmsTaskNotSentError) {
      openConfirmCompletionWithoutSending(task as SmsTask);
    } else if (e instanceof SmsTaskNotDeliveredError) {
      openConfirmCompletionWithoutDeliveringPopup(
        task as SmsTask,
        e.getNotDeliveredToIds(),
      );
    } else {
      throw e;
    }
  };

  return {
    handleError,
    openConfirmCompletionWithoutSending,
    openConfirmCompletionWithoutDeliveringPopup,
    openConfirmMassCompletePopup,
  };
}
