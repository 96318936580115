import React, { useState } from 'react';
import useProjectDocumentsService from '../documents/projectDocumentsService';
import { Option } from '../../shared/uibuilder/form/input';
import useDataLoader from '../../shared/uibuilder/form/dataLoader';
import CheckboxGroup from '../../shared/uibuilder/form/input/CheckboxGroup';
import LoadingAnimation from '../../shared/LoadingAnimation';

export default function DocumentsCheckboxGroup(
  props: { projectId: number },
) {
  const documentsService = useProjectDocumentsService();

  const [options, setOptions] = useState<Option<number>[]>();

  useDataLoader(
    async () => {
      const documents = await documentsService.getProjectDocuments(props.projectId);

      return documents.map((document) => {
        return {
          value: document.id,
          label: document._label?.name || '(without label)',
          hint: document?._file?.name || '',
        };
      });
    },
    setOptions,
  );

  if (options) {
    return (
      <CheckboxGroup
        options={options}
        label="Documents To Include"
        source="documentsIds"
        weights={[3, 3, 3, 3]}
      />
    );
  } else {
    return <LoadingAnimation />;
  }
}
