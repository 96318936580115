import {
  BaseInputProps,
  NumberInput,
  RelativeRow,
  TextInput,
  useInputHelper
} from 'ui-builder';
import { ParentTaskRelation } from 'nekst-api';
import React from 'react';

interface DisabledTaskNameInputProps extends BaseInputProps<ParentTaskRelation> {

}

function DisabledTaskNameInput(props: DisabledTaskNameInputProps) {
  const helper = useInputHelper<ParentTaskRelation>(props);

  return (
    <TextInput
      label={props.label}
      source={`${props.source}.name`}
      value={helper.getValue().parentTaskName}
      onChangeCallback={() => {
        // ignored
      }}
      isDisabled
    />
  );
}

export function ProjectParentTaskRelationInput(props: {
  source: string;
  label: string;
}) {
  return (
    <RelativeRow weights={[3, 9]}>
      <NumberInput source={`${props.source}.days`} label={props.label} />
      <DisabledTaskNameInput
        source={`${props.source}`}
        label={'Days After This Task Is Marked Complete'}
      />
    </RelativeRow>
  );
}
