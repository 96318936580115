import React from 'react';
import { TextLinkLayoutProps } from 'ui-builder';

export default function TextLinkLayout(props: TextLinkLayoutProps) {
  return (
    <a
      onClick={(event) => {
        event.preventDefault();
        props.onClick?.();
      }}
      href={props.href}
      className={props.className}
      title={props.title}
    >
      {props.children}
    </a>
  );
}
