import React from 'react';

// @ts-ignore
import ReactCalendar, { CalendarTileProperties } from 'react-calendar';

import './Calendar.scss';

export type CalendarValue = Date | [Date | null, Date | null] | undefined | null;

export interface CalendarProps {
  value?: CalendarValue;
  onChangeCallback: (date: Date) => void;
  dayClassNameFunc?: (date: Date) => string;
}

export default function Calendar(props: CalendarProps) {
  let dayClassNameFunc;

  if (props.dayClassNameFunc !== undefined) {
    dayClassNameFunc = (tileProperties: CalendarTileProperties) => {
      return props.dayClassNameFunc!(tileProperties.date);
    };
  }

  return (
    <ReactCalendar
      locale="en-US"
      nextLabel=">"
      prevLabel="<"
      prev2Label={null}
      next2Label={null}
      showDoubleView={false}
      value={props.value}
      formatShortWeekday={(_: any, date: Date) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return days[date.getDay()].charAt(0);
      }}
      tileClassName={dayClassNameFunc}
      onChange={(data: Date) => {
        props.onChangeCallback(data);
      }}
    />
  );
}
