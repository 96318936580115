import React from 'react';
import { get } from 'lodash';
import { Text, usePriceFormatter, useShowContext } from 'ui-builder';
import BaseFieldProps from './BaseFieldProps';

export interface PriceFieldProps extends BaseFieldProps<number> {

}

export default function PriceField(props: PriceFieldProps) {
  const context = useShowContext();

  const priceFormatter = usePriceFormatter();

  let value;
  if (props.source) {
    value = get(context.data, props.source);
  } else {
    value = props.value;
  }

  if (value) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <Text>
        {priceFormatter.format(value)}
      </Text>
    );
  } else {
    return null;
  }
}
