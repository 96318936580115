import React from 'react';
import RecordLayout, { RecordLayoutProps } from './RecordLayout';
import TaskLink from './TaskLink';
import PersonLink from './PersonLink';
import { TaskType } from '../../../shared/commonTypes';
import { HistoryEventIconType } from './HistoryEventIcon';
import { BaseHistoryEntryData, TaskData } from 'nekst-api';

interface TaskCreatedData extends BaseHistoryEntryData {
  task: TaskData,
}

export default function TaskCreatedRecordLayout(props: RecordLayoutProps<TaskCreatedData>) {
  return (
    <RecordLayout
      text={(
        <>
          <PersonLink person={props.record.data.person!} />
          {props.record.data.task.type === TaskType.EMAIL ? ' created an email task' : ' created a task '}
          <TaskLink
            task={props.record.data.task}
            project={props.record.data.project}
          />
          .
        </>
      )}
      date={props.record.when}
      iconType={HistoryEventIconType.TASK_CREATED}
    />
  );
}
