import { POPUP_DATA_UPDATED, POPUP_OPENED, PopupProps } from './types';
import { usePopupManager } from 'ui-builder';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { EventData, useEventsPublisher, useEventsSubscriber } from 'event-bus';
import useWindowDimensionsGetter from './windowDimensionsGetter';
import styles from './Popup.module.scss';

export interface PopupOpenedEvent extends EventData {
  id: string,
}

export default function PopupLayoutWeb(props: PopupProps) {
  const popupManager = usePopupManager();

  const ref = useRef<HTMLDivElement>(null);

  const buttonsRef = useRef<HTMLDivElement>(null);

  const overlayRef = useRef<HTMLDivElement>(null);

  const [fixButtons, setFixButtons] = useState<boolean>(false);

  const [showShadow, setShowShadow] = useState<boolean>(true);

  const eventsPublisher = useEventsPublisher();

  const {
    height: windowHeight,
    width: windowWidth,
  } = useWindowDimensionsGetter();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!props.isCentered) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'unset';
      };
    } else {
      return () => {
        // empty
      };
    }
  }, []);

  function centerPopup() {
    if (props.isCentered) {
      const height = ref.current?.clientHeight;

      if (height && ref.current) {
        ref.current.style.marginTop = `${-(height / 2)}px`;
      }
    }
  }

  useEffect(() => {
    if (props.isCentered) {
      centerPopup();
    }
  }, [ref.current?.clientHeight]);

  useEffect(() => {
    setTimeout(() => {
      centerPopup();
    });
  }, []);

  function doUpdate() {
    if (ref.current) {
      if ((ref.current.clientHeight + 90) > windowHeight && windowHeight > 700) {
        setFixButtons(true);
      } else {
        setFixButtons(false);
      }
    }
  }

  useEventsSubscriber(
    'Popup',
    {
      [POPUP_DATA_UPDATED]: doUpdate,
    },
    [windowHeight, windowWidth, ref.current?.offsetLeft || 0],
    doUpdate,
  );

  useEffect(() => {
    if (ref.current && buttonsRef.current) {
      if (fixButtons) {
        buttonsRef.current.style.left = `${ref.current.offsetLeft}px`;
      } else {
        buttonsRef.current.style.left = 'auto';
      }
    }
  }, [fixButtons, windowHeight, windowWidth]);

  function checkIfButtonShadowNeeded() {
    if (ref.current && overlayRef.current && ((ref.current.clientHeight + 60 - windowHeight) <= overlayRef.current.scrollTop + 5)) {
      setShowShadow(false);
    } else {
      setShowShadow(true);
    }
  }


  const [clickedInOverlay, setClickedInOverlay] = useState<boolean>(false);


  useEffect(() => {
    if (overlayRef.current) {
      overlayRef.current.addEventListener('scroll', checkIfButtonShadowNeeded);
    }

    if (ref.current) {
      ref.current.addEventListener('resize', checkIfButtonShadowNeeded);
    }

    document.addEventListener('resize', checkIfButtonShadowNeeded);

    return () => {
      if (overlayRef.current) {
        overlayRef.current.removeEventListener('scroll', checkIfButtonShadowNeeded);
      }

      if (ref.current) {
        ref.current.removeEventListener('resize', checkIfButtonShadowNeeded);
      }

      document.removeEventListener('resize', checkIfButtonShadowNeeded);
    };
  }, [overlayRef.current?.scrollTop, ref.current]);

  const popupClasses = [
    styles.popup,
    props.isCentered ? 'centered' : '',
    fixButtons && props.buttons ? styles.buttonsFixed : '',
    !showShadow ? styles.withoutShadow : '',
    props.isBig ? styles.big : '',
  ];
  useEffect(() => {
    if (props.popupId) {
      eventsPublisher.publish(POPUP_OPENED, {
        id: props.popupId,
      });
    }
  }, []);

  useEffect(() => {
    if (props.isBig) {
      ref.current!.style.height = `${Math.max(windowHeight - 100, 800)}px`;
    }
  }, [windowHeight]);

  const handleMouseDown = useCallback((event: MouseEvent) => {
    if (overlayRef.current === event.target) {
      setClickedInOverlay(true);
    }
  }, [ref.current]);

  const handleMouseUp = useCallback(() => {
     setClickedInOverlay(false);
  }, []);

  useEffect(() => {
    if (ref.current) {
      document.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('mouseup', handleMouseUp);

      return () => {
        document.removeEventListener('mousedown', handleMouseDown);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    } else {
      return undefined;
    }
  }, [ref.current]);
  /* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
  return (
    <div
      className={styles.overlay}
      ref={overlayRef}
      onMouseUp={(event) => {
        if (event.target === overlayRef.current && clickedInOverlay && props.isClosable) {
          popupManager.closePopup();
        }
        setClickedInOverlay(false);
      }}
      data-key="popup"
    >
      <div
        className={popupClasses.join(' ')}
        ref={ref}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className={styles.header}>
          {props.isClosable && (<button
            type="button"
            className={styles.closeButton}
            onClick={() => popupManager.closePopup()}
            data-key="popup-close-button"
          >
            &nbsp;
          </button>)}
          <span>{props.title}</span>
        </div>
        <div className={[styles.main, props.isLong ? styles.long : ''].join(' ')}>
          <div className={styles.content} data-key="popup-content">
            {props.content}
          </div>
          {props.buttons && (
            <div className={styles.buttons} ref={buttonsRef}>
              {props.buttons}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
