import React from 'react';
import {
  useSendEmailTaskService,
  EmailRecipientType,
  EmailTask,
  SendingStatus,
  TaskPermission,
  TaskType,
} from 'nekst-api';
import {
  Button,
  ButtonColor,
  ButtonSize,
  usePopupManager,
  useShowContext,
  useFormContext,
} from 'ui-builder';

import { EmailPreview } from './EmailPreview';
import { ClosePopupButton, Popup } from 'features/nekst-widgets';
import { useAuthorizationChecker } from 'authorization-scope';

function SendFromPopup(
  props: {
    task: EmailTask,
    onSent?: () => void,
  },
) {
  const task = props.task;

  const popupManager = usePopupManager();

  const sendEmailService = useSendEmailTaskService();

  const hasRecipient = !!task.recipients.find(
    (item) => !!item.personId && (item.type === EmailRecipientType.RECIPIENT),
  );

  return (
    <Popup
      title="Send Email"
      content={<EmailPreview task={task} />}
      buttons={(
        <>
          <Button
            text="Send"
            color={ButtonColor.GREEN}
            isDisabled={!hasRecipient}
            title={!hasRecipient ? 'Recipient not set for Email Task. Please specify a recipient before sending' : undefined}
            onClick={async () => {
              const response = await sendEmailService.sendEmail(
                task.id,
              );

              if (response.emailSendingStatus === SendingStatus.SENT) {
                props.onSent?.();
              }

              popupManager.closePopup();
            }}
            size={ButtonSize.MEDIUM}
          />
          <ClosePopupButton
            text="Go Back"
            color={ButtonColor.GRAY}
            size={ButtonSize.MEDIUM}
          />
        </>
      )}
    />
  );
}

export function SendEmailButton(
  props: {
    className?: string,
    addPreviewPrefix?: boolean,
  },
) {
  const popupManager = usePopupManager();

  const formContext = useFormContext<EmailTask>();

  const { data: formData } = formContext;

  const { data: showData } = useShowContext<EmailTask>();

  const data = formData || showData;

  const { isGranted } = useAuthorizationChecker();

  const [isSent, setIsSent] = React.useState(formData?.emailSendingStatus === SendingStatus.SENT);

  if (data?.id
    && data?.permissions.includes(TaskPermission.SEND_EMAIL)
    && data?.type === TaskType.EMAIL
  ) {
    let buttonText;
    const isNotSent = data!.emailSendingStatus === SendingStatus.NOT_SENT && !isSent;
    if (isNotSent) {
      buttonText = 'Send';
    } else {
      buttonText = 'Re-Send';
    }

    buttonText = `Preview & ${buttonText}`;

    return (
      <Button
        customClass={props.className}
        text={buttonText}
        color={isNotSent ? ButtonColor.GREEN : ButtonColor.YELLOW}
        isFilled
        size={ButtonSize.SMALL}
        onClick={
          async () => {
            if (isGranted(TaskPermission.UPDATE) && formContext?.submitForm) {
              const updateDataResult = await formContext.submitForm!(false);

              if (!updateDataResult) {
                return;
              }
            }

            popupManager.openPopup(
              <SendFromPopup
                task={data}
                onSent={() => {
                  setIsSent(true);
                }}
              />,
              true,
            );
          }
        }
      />
    );
  } else {
    return null;
  }
}
