import React from 'react';
import {
  ButtonColor,
  ButtonSize,
  Button,
  useMassUpdateServiceRequired
} from 'ui-builder';
import {
  usePopupFormManager,
} from 'features/nekst-widgets';
import AssignToForm from './AssignToForm';

export default function AssignToButton() {
  const massUpdateService = useMassUpdateServiceRequired();

  const popupManager = usePopupFormManager();

  return (
    <Button
      text="Assign To"
      color={ButtonColor.BLUE}
      size={ButtonSize.SMALL}
      isFlat
      isFilled
      isDisabled={massUpdateService.getCheckedIds().length === 0}
      onClick={async () => {
        popupManager.openForm(<AssignToForm />);
      }}
    />
  );
}
