import React, { useState } from 'react';
import {
  useProjectAssignmentsService,
  ProjectAssignmentType,
} from 'nekst-api';
import { BaseInputProps, LoadingAnimation, RadioButtonsGroup } from 'ui-builder';
import useDataLoader from 'data-loader';

export interface TeamMembersRadioButtonsProps extends BaseInputProps<number> {
  projectId: number,
  withNoneOption?: boolean;
}

export default function TeamMembersRadioButtons(props: TeamMembersRadioButtonsProps) {
  const projectAssignmentService = useProjectAssignmentsService();

  const [options, setOptions] = useState<{
    label: string,
    value: number,
  }[]>();

  useDataLoader(
    async () => {
      const assignments = await projectAssignmentService.getContactAssignments(props.projectId);

      const result = assignments
        .filter(
          (item) => [
            ProjectAssignmentType.TASK_ASSIGNMENT,
            ProjectAssignmentType.TRANSACTION_PARTY,
          ].includes(item.type),
        )
        .map((item) => {
          let description = item.type === ProjectAssignmentType.TASK_ASSIGNMENT
            ? 'Team Member: '
            : 'Transaction Party: ';

          description += item.roles.map((r) => r.roleName)
            .join(', ');

          return {
            value: item.personId,
            label: item.profile.name.fullName,
            description,
          };
        });

      if (props.withNoneOption) {
        result.push({
          label: 'None',
          value: 0,
          description: '',
        });
      }

      return result;
    },
    setOptions,
  );

  if (options) {
    return (
      <RadioButtonsGroup<number> options={options} {...props} />
    );
  } else {
    return (<LoadingAnimation />);
  }
}
