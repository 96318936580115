import React from 'react';
import { AfterSubmitFunc, FormTemplate, TextArea, TextInput } from 'ui-builder';
import FieldTypeDropdown from '../../Shared/FieldTypeDropdown';
import { PromptParseResult } from 'nekst-api';

export default function BaseTaskParseResultForm(
  props: {
    title: string,
    submitFormFunc: (data: PromptParseResult) => Promise<void>,
    afterSubmitFunc?: AfterSubmitFunc,
    initialData?: Partial<PromptParseResult>,
  }
) {
  return (
    <FormTemplate<PromptParseResult>
      submitFormFunc={async (data) => {
        await props.submitFormFunc(data);
        return data;
      }}
      afterSubmitFunc={props.afterSubmitFunc}
      initialData={props.initialData}
      validationSchema={{
        id: {
          type: 'string',
          constraints: {
            required: true
          }
        },
        type: {
          type: 'string',
          constraints: {
            required: true
          }
        },
        prompt: {
          type: 'string',
          constraints: {
            required: true
          }
        }
      }}
      title={props.title}
    >
      <TextInput
        source="id"
        label="ID"
        isDisabled={props.initialData?.id !== undefined}
      />
      <FieldTypeDropdown
        source="type"
        label="Return value type"
        isDisabled={props.initialData?.id !== undefined}
      />
      <TextArea source="prompt" label="Prompt" />
      <TextInput source="value" label="Value" />
    </FormTemplate>
  );
}
