import React, {
  ReactElement, ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useEventsPublisher } from 'event-bus';
import { SimpleBlock } from '../Common';

export interface PopupManager {
  openPopup: (popup: ReactElement, openSecondPopup?: boolean) => void,
  closePopup: (closeAllPopups?: boolean) => void,
  isOpen: () => boolean,
}

const PopupReactContext = React.createContext<Nullable<PopupManager>>(null);

export interface PopupWrapperProps {
  children: ReactNode;
}

function HideWrapper(
  props: {
    children: ReactElement,
    hide: boolean,
  },
) {
  return (
    <SimpleBlock
      style={{
        display: props.hide ? 'none' : undefined,
      }}
      className={props.hide ? 'hidden' : undefined}
    >
      {props.children}
    </SimpleBlock>
  );
}

export const POPUP_CLOSED = 'POPUP_CLOSED';

export function PopupContext(props: PopupWrapperProps) {
  const [popup, setPopup] = useState<Nullable<ReactElement>>(null);

  const [popup2, setPopup2] = useState<Nullable<ReactElement>>(null);

  const eventsPublisher = useEventsPublisher();

  const openPopup = (newPopup: ReactElement, openSecondPopup = false) => {
    if (popup === null || !openSecondPopup) {
      setPopup(newPopup);
    } else {
      setPopup2(newPopup);
    }
  };

  const closePopup = (closeAllPopups?: boolean) => {
    if (popup2 !== null) {
      setTimeout(() => {
        setPopup2(null);
        if (closeAllPopups) {
          setPopup(null);
        }
      }, 10);
    } else {
      setTimeout(() => {
        setPopup(null);
        eventsPublisher.publish(POPUP_CLOSED, {});
      }, 10);
    }
  };

  const isOpen = () => {
    return !!(popup || popup2);
  };

  const contextValue = useMemo(() => ({
    closePopup,
    openPopup,
    isOpen,
  }), [popup, popup2]);

  return (
    <PopupReactContext.Provider value={contextValue}>
      {props.children}
      {popup && (<HideWrapper hide={!!popup2}>{popup}</HideWrapper>)}
      {popup2}
    </PopupReactContext.Provider>
  );
}

export function usePopupManager(): PopupManager {
  const result = useContext(PopupReactContext);

  if (result != null) {
    return result;
  } else {
    throw new Error('usePopupManager hook may be used inside PopupWrapper component only');
  }
}
