import React, { useEffect } from 'react';
import {
  FormTemplate,
  AfterSubmitFunc,
  ButtonColor,
  ButtonSize,
  IconType,
  SubmitButton,
  Button,
} from 'ui-builder';
import Separator from '../../../../../../shared/web/uitheme/form/Separator';
import { StatusFilterForm, StatusFilterValue } from './statusFilterService';
import { TypeFilterForm, TypeFilterValue } from './typeFilterService';
import {
  AssigneeFilterForm,
  AssigneeFilterValue,
} from './assigneeFilterService';
import useAdvancedFilterService from './advancedFilterService';
import {
  useEventsPublisher,
  useEventsSubscriber,
} from 'event-bus';
import { usePopupFormManager } from 'features/nekst-widgets';
import { POPUP_OPENED } from 'features/nekst-widgets';

export type AdvancedFilterData = {
  statusFilter: StatusFilterValue,
  typeFilter: TypeFilterValue,
  assigneesFilter: AssigneeFilterValue,
};

export interface AdvancedFilterFormProps {
  afterSubmitFunc?: AfterSubmitFunc<void>,
  showStatusFilter: boolean,
}

function ResetFilterButton() {
  const advancedFilterService = useAdvancedFilterService();

  const popupManager = usePopupFormManager();

  return (
    <Button
      text="Reset"
      color={ButtonColor.GRAY}
      isFilled
      size={ButtonSize.BIG}
      onClick={async () => {
        advancedFilterService.resetFilter();
        popupManager.closePopup();
      }}
    />
  );
}

export default function AdvancedFilterForm(props: AdvancedFilterFormProps) {
  const advancedFilterService = useAdvancedFilterService();

  const submitFormFunc = async (data: AdvancedFilterData) => {
    advancedFilterService.setFilterValue(data);
  };

  const initialData = advancedFilterService.getFilterValue()! as AdvancedFilterData;

  const eventsPublisher = useEventsPublisher();

  useEffect(() => {
    eventsPublisher.publish(POPUP_OPENED, {
      id: 'tasksFilter',
    });
  });

  return (
    <FormTemplate<AdvancedFilterData, void>
      title="Filter"
      submitFormFunc={submitFormFunc}
      initialData={initialData}
      afterSubmitFunc={props.afterSubmitFunc}
      validationSchema={{
        assigneesFilter: {
          type: 'object',
          constraints: {
            required: {
              value: true,
              message: 'Please select at least one team member',
            },
          },
          properties: {},
        },
      }}
      buttons={(
        <>
          <SubmitButton text="Submit" />
          <ResetFilterButton />
        </>
      )}
    >
      {props.showStatusFilter && (
        <>
          <StatusFilterForm />
          <Separator isBold />
        </>
      )}
      <AssigneeFilterForm />
      <Separator isBold />
      <TypeFilterForm />
    </FormTemplate>
  );
}

export function AdvancedFilterPopupButton(props: {
  showStatusFilter: boolean
}) {
  const popupManager = usePopupFormManager();

  useEventsSubscriber(
    'AdvancedFilterPopupButton',
    {
      DESCRIBE_ADVANCED_FILTER: () => {
        popupManager.openForm(<AdvancedFilterForm showStatusFilter={props.showStatusFilter} />);
      },
    },
  );

  return (
    <Button
      dataKey="advanced-filter-button"
      text="Filter"
      isFilled={false}
      color={ButtonColor.BLUE}
      size={ButtonSize.BIG}
      iconType={IconType.FILTER}
      onClick={() => {
        popupManager.openForm(<AdvancedFilterForm showStatusFilter={props.showStatusFilter} />);
      }}
    />
  );
}
