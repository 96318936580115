import React, { useEffect, useMemo, useState } from 'react';
import { BaseInputProps, Dropdown, useFormContextRequired } from 'ui-builder';
import { useDetailsFieldsSetsService } from 'nekst-api';
import { useDataLoaderNew } from 'data-loader';
import { get } from 'lodash';

interface DetailsFieldsSetDropdownProps extends BaseInputProps<number> {
  projectTypeSource: string,
}


export function DetailFieldsSetDropdown(
  props: DetailsFieldsSetDropdownProps,
) {
  const setService = useDetailsFieldsSetsService();

  const { data: allSets } = useDataLoaderNew(setService.getAllSets);

  const formContext = useFormContextRequired();

  const [init, setInit] = useState(false);

  const projectType = get(formContext.data!, props.projectTypeSource);

  const options = useMemo(() => {
    if (allSets && projectType) {
      return allSets.filter((set) => set.projectType === projectType)
        .map((set) => ({
        value: set.id,
        label: set.name,
      }));
    } else {
      return [];
    }
  }, [projectType, allSets]);

  useEffect(() => {
    if (init) {
      formContext.onChangeCallback({
        [props.source]: undefined,
      })
    } else {
      setInit(true);
    }
  }, [projectType]);

  return (
    <Dropdown
      {...props}
      options={options}
      isDisabled={options.length === 0}
      placeholder={options.length === 0 ? 'No sets available' : 'Select a set'}
    />
  );
}
