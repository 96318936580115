import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import useDataLoader from 'data-loader';
import { ListReactContext } from './ListContext';
import { VisibilityState } from './types';
import { useFilterContext } from './FilterContext';
import { PopupContext } from '../Popup';

export type Limit = {
  limit: number,
  offset: number,
}

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type FieldName = string;

export type OrderBy = {
  field: FieldName,
  direction: OrderDirection,
}

export interface PageResponse<DataType> {
  data: DataType[],
  total: number
  limit: Limit
}

type GetDataFunc<DataType, FilterType> = (
  filter: FilterType,
  limit: Limit,
  orderBy?: OrderBy,
) => Promise<PageResponse<DataType>>;

/* eslint-disable react/no-unused-prop-types */
interface ListWithPaginationProps<DataType extends { id: number }, FilterType> {
  getDataFunc: GetDataFunc<DataType, FilterType>,
  children: JSX.Element | JSX.Element[],
  byPage?: number
  firstPageData?: PageResponse<DataType>,
  initialSortBy?: OrderBy,
  onSortChanged?: (field: string, direction: OrderDirection) => void,
}

function useListWithPagination<DataType extends { id: number }, FilterType>(
  props: ListWithPaginationProps<DataType, FilterType>,
) {
  const [data, setData] = useState<DataType[]>(props.firstPageData?.data || []);

  const [idsMap, setIdsMap] = useState<Record<number, true>>({});

  const [limit, setLimit] = useState<Limit>({
    limit: props.byPage || 30,
    offset: 0,
  });
  const [total, setTotal] = useState<number>(0);

  const [orderByField, setOrderByField] = useState<string | null>(
    props.initialSortBy?.field || null,
  );
  const [orderDirection, setOrderDirection] = useState<OrderDirection | null>(
    props.initialSortBy?.direction || null,
  );

  const [dependencyValue, setDependencyValue] = useState(0);

  const [version, setVersion] = useState(0);

  const [force, setForce] = useState(false);

  const [initialized, setInitialized] = useState(false);

  const filterContext = useFilterContext<FilterType>();

  const [
    itemsVersions,
    setItemsVersionsBase
  ] = useState<Record<number, number>>({});

  const getDataMethod = async () => {
    if (!initialized) {
      setInitialized(true);
    }

    if (props.firstPageData && limit.offset === 0 && !force) {
      setForce(false);
      return props.firstPageData;
    } else {
      const result = await props.getDataFunc(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        filterContext.filterValue!,
        limit,
        orderByField && orderDirection ? {
          field: orderByField,
          direction: orderDirection,
        } as OrderBy : undefined,
      ) as PageResponse<DataType>;

      setForce(false);
      return result;
    }
  };

  const hasId = (id: number) => {
    return idsMap[id];
  };

  const setDataMethod = (newPage: PageResponse<DataType>) => {
    if (newPage.limit.offset === 0) {
      setData(newPage.data);

      setIdsMap(() => {
        const result: Record<number, true> = {};
        newPage.data.forEach((item) => {
          result[item.id] = true;
        });

        return result;
      });
    } else {
      const newItems = newPage.data.filter((item) => !hasId(item.id));

      setData([
        ...data,
        ...newItems,
      ]);

      setIdsMap((prevState) => {
        const result = {
          ...prevState,
        };

        newItems.forEach((item) => {
          result[item.id] = true;
        });

        return result;
      });
    }
    setTotal(newPage.total);

    setVersion((prev) => prev + 1);
  };

  const {
    loading,
    setLoading,
  } = useDataLoader<PageResponse<DataType>>(
    getDataMethod,
    setDataMethod,
    dependencyValue,
  );

  useEffect(() => {
    if (initialized) {
      setLimit({
        limit: props.byPage || 10,
        offset: 0,
      });
      setDependencyValue((prev) => prev + 1);
    }
  }, [
    JSON.stringify(props.firstPageData),
    !props.firstPageData ? JSON.stringify(filterContext?.filterValue) : null,
  ]);

  const refreshData = () => {
    setLimit({
      limit: props.byPage || 10,
      offset: 0,
    });

    setItemsVersionsBase({});

    setForce(true);
    setDependencyValue((prev) => prev + 1);

    if (props.onSortChanged && orderByField && orderDirection) {
      props.onSortChanged(orderByField, orderDirection);
    }
  };

  useEffect(() => {
    if (initialized) {
      refreshData();
    }
  }, [orderByField, orderDirection]);

  useEffect(() => {
    if (initialized && limit.offset !== 0) {
      setDependencyValue((prev) => prev + 1);
    }
  }, [
    JSON.stringify(limit),
  ]);

  const loadNextPage = () => {
    if (!loading) {
      setLimit({
        limit: limit.limit,
        offset: limit.offset + limit.limit,
      });
    }
  };

  const hasMorePages = () => {
    return (limit.limit + limit.offset) < total;
  };

  const sort = (field: string, direction: OrderDirection) => {
    setOrderByField(field);
    setOrderDirection(direction);
  };

  const _setData = (value: DataType[]) => {
    setData(value);
    setVersion((prev) => prev + 1);
  };

  const _setLoading = (value: boolean) => {
    setLoading(value);
  };

  const getVisibilityState = () => VisibilityState.VISIBLE;

  const getItemVersion = (id: number) => itemsVersions[id] || 0;

  const updateItemsVersions = (ids: number[]) => {
    const newVersions: Record<number, number> = {};

    ids.forEach((id) => {
      newVersions[id] = getItemVersion(id) + 1;
    });

    const newValue = {
    ...itemsVersions,
    ...newVersions,
    };

    setItemsVersionsBase(newValue);
  };

  const hasVisibleItems = () => {
    return data.length > 0;
  }

  return useMemo(() => ({
    data,
    loadNextPage,
    hasMorePages,
    isLoading: loading,
    version,
    total,
    orderByField,
    orderDirection,
    sort,
    refreshData,
    hasId,
    getVisibilityState,
    updateItemsVersions,
    getItemVersion,
    hasVisibleItems,
    _setData,
    _setLoading,
  }), [version, loading]);
}

export type ListDataContextData<DataType> = {
  data: DataType[],
  isLoading: boolean
};

export type ListPaginationData = {
  hasMorePages: () => boolean,
  loadNextPage: () => void,
  isLoading: boolean,
}

export function ListWithPagination<DataType extends { id: number }, FilterType>(
  props: ListWithPaginationProps<DataType, FilterType>,
) {
  const list = useListWithPagination(props);
  return (
    <ListReactContext.Provider value={list}>
      <PopupContext>
        {props.children}
      </PopupContext>
    </ListReactContext.Provider>
  );
}
