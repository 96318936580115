import { FieldValidationSchema, ValidationSchema } from './index';
import useConstraintValidatorProvider from './contraint';
import useRequiredConstraintValidator from './contraint/required';
import useWhenResolver from './contraint/whenResolver';

type ValidationError = string;

export type ValidationErrors = {
  [fieldName: string]: ValidationError[]
}

export interface Validator {
  validate: (_object: any, _schema: ValidationSchema) => Promise<ValidationErrors>;
}

function useValidator(): Validator {
  const constraintValidatorProvider = useConstraintValidatorProvider();

  const requiredConstraintValidator = useRequiredConstraintValidator();

  const whenResolver = useWhenResolver();

  const isApplicableConstraint = (object: Record<string, any>, constraintSchema: any) => {
    return whenResolver.isApplicable(object, constraintSchema);
  };

  const getFieldErrors = (
    object: Record<string, any>,
    fieldName: string,
    fieldSchema: FieldValidationSchema,
  ) => {
    const result: string[] = [];
    Object.entries(fieldSchema.constraints)
      .forEach(([constraintName, constraintSchema]) => {
        const constrainValidator = constraintValidatorProvider.getByName(constraintName);
        if (isApplicableConstraint(object, constraintSchema)) {
          const isValid = constrainValidator.validate(object, fieldName, constraintSchema);
          if (!isValid) {
            result.push(constrainValidator.getErrorMessage(constraintSchema));
          }
        }
      });

    return result;
  };

  function isObjectRequired(
    formFields: Record<string, any>,
    fieldValidationSchema: FieldValidationSchema,
  ) {
    return fieldValidationSchema.constraints.required
      && requiredConstraintValidator.isRequired(
        formFields,
        fieldValidationSchema.constraints.required,
      );
  }

  const doValidate = (object: Record<string, any>, schema: ValidationSchema, keyPrefix = '') => {
    let result: ValidationErrors = {};

    Object.entries(schema)
      .forEach(([fieldName, fieldValidationSchema]) => {
        const fieldErrors = getFieldErrors(object, fieldName, fieldValidationSchema);

        if (fieldErrors.length > 0) {
          result[`${keyPrefix}${fieldName}`] = fieldErrors;
        }

        if (fieldValidationSchema.type === 'object'
          && (object[fieldName] || isObjectRequired(object, fieldValidationSchema))) {
          const objectErrors = doValidate(
            object[fieldName] || {},
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            fieldValidationSchema.properties!,
            `${keyPrefix}${fieldName}.`,
          );
          result = {
            ...result,
            ...objectErrors,
          };
        }

        if (fieldValidationSchema.type === 'array'
          && (object[fieldName] || isObjectRequired(object, fieldValidationSchema))
          && fieldValidationSchema.arrayItemSchema
        ) {
          (object[fieldName] || []).forEach((arrayItem: object, arrayIndex: number) => {
            const itemErrors = doValidate(
              arrayItem || {},
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              fieldValidationSchema.arrayItemSchema!,
              `${keyPrefix}${fieldName}[${arrayIndex}].`,
            );

            result = {
              ...result,
              ...itemErrors,
            };
          });
        }
      });

    return result;
  };

  const validate = async (object: Record<string, any>, schema: ValidationSchema) => {
    return doValidate(object, schema);
  };

  return {
    validate,
  };
}

export default useValidator;
