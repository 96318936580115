import {
  DateInput,
  ErrorsWrapper, isReactNative,
  RelativeRow,
  TimeInput,
  useFormContextRequired,
} from 'ui-builder';
import React from 'react';

export function DueDateInput(
  props: {
    showTimeInput?: boolean,
  }
) {
  const formContext = useFormContextRequired();

  const onUpdate = (diff: { [key: string]: any }) => {
    const oldValue = formContext.data?.dueDate || {
      date: undefined,
      time: undefined,
    };

    const newValue = {
      ...oldValue,
      ...diff,
    };

    if (newValue.date || newValue.time) {
      formContext.onChangeCallback({
        dueDate: newValue,
      });
    } else {
      formContext.onChangeCallback({
        dueDate: undefined,
      });
    }
  };

  const onDateUpdate = (update: { [key: string]: any }) => {
    onUpdate({
      date: Object.values(update)[0],
    });
  };

  const onTimeUpdate = (update: { [key: string]: any }) => {
    onUpdate({
      time: Object.values(update)[0],
    });
  };

  return (
    <ErrorsWrapper name="dueDate">
      <RelativeRow weights={[7, 5]}>
        <DateInput
          source="dueDate.date"
          label="Due"
          onChangeCallback={onDateUpdate}
          showRequiredIcon={false}
          placeholder={isReactNative() ? 'Select date' : undefined}
        />
        {props.showTimeInput && (
          <TimeInput source="dueDate.time" placeholder="Time (optional)"
                     onChangeCallback={onTimeUpdate}
                     label=" " />
        )}
      </RelativeRow>
    </ErrorsWrapper>
  );
}
