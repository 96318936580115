import React from 'react';
import PageLink from './PageLink';
import { RoleData } from 'nekst-api';

interface Props {
  role: RoleData,
}

export default function ProjectRoleLink(props: Props) {
  return (
    <PageLink
      text={props.role.name}
      isDeleted={props.role.isDeleted}
      action={() => {
        // ignored
      }}
    />
  );
}
