import React from 'react';
import { AbstractTask } from '../projectTasksService';
import CompleteTaskCheckbox from '../shared/completion/CompleteTaskCheckbox';

import styles from './NameWithCompleteCheckbox.module.scss';

export default function NameWithCompleteCheckbox(props: { data: AbstractTask }) {
  return (
    <>
      <CompleteTaskCheckbox />
      {props.data._renderedName}
      <span className={styles.projectName}>
        {' - '}
        {props.data._project.name}
      </span>
    </>
  );
}
