import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useFilterContext } from 'ui-builder';
import { ProjectFilter } from 'nekst-api';
import { InputLayoutIcon } from 'ui-builder';
import TextLayout from '../../shared/uibuilder/form/input/layout/TextLayout';

import styles from './ProjectListPage.module.scss';

export default function ProjectNameFilter() {
  const filterContext = useFilterContext<ProjectFilter>();

  const [value, setValue] = useState<string | undefined>(filterContext.filterValue?.nameContains);

  const updateFilter = useCallback(
    debounce((text: string | undefined) => filterContext.setFilterValue({
      ...filterContext.filterValue!,
      nameContains: text,
    }), 500),
    [
      JSON.stringify(filterContext.filterValue),
    ],
  );

  useEffect(() => {
    updateFilter(value);
  }, [value]);

  return (
    <TextLayout
      value={value || ''}
      name="nameFilter"
      onChangeCallback={(event) => {
        setValue(event.target.value);
      }}
      isVisible
      placeholder="Transaction Name"
      isDisabled={false}
      className={styles.nameFilter}
      icon={InputLayoutIcon.SEARCH}
      hideLabel
    />
  );
}
