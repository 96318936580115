import React from 'react';

import { HistoryEntry, HistoryEvent } from 'nekst-api';
import styles from './History.module.scss';
import ProjectCreatedRecordLayout from './RecordLayout/ProjectCreatedRecordLayout';
import TaskCompletedRecordLayout from './RecordLayout/TaskCompletedRecordLayout';
import TaskReopenedRecordLayout from './RecordLayout/TaskReopenedRecordLayout';
import ProjectTypeUpdatedRecordLayout from './RecordLayout/ProjectTypeUpdatedRecordLayout';
import ProjectStatusUpdatedRecordLayout from './RecordLayout/ProjectStatusUpdatedRecordLayout';
import ProjectAssignmentCreatedRecordLayout
  from './RecordLayout/ProjectAssignmentCreatedRecordLayout';
import ProjectLaunchedRecordLayout from './RecordLayout/ProjectLaunchedRecordLayout';
import ProjectNoteCreatedRecordLayout from './RecordLayout/ProjectNoteCreatedRecordLayout';
import EmailSentRecordLayout from './RecordLayout/EmailSentRecordLayout';
import TaskCreatedRecordLayout from './RecordLayout/TaskCreatedRecordLayout';
import CommentCreatedRecordLayout from './RecordLayout/CommentCreatedRecordLayout';
import ProjectAssignmentRemovedRecordLayout
  from './RecordLayout/ProjectAssignmentRemovedRecordLayout';
import SmsSentRecordLayout from './RecordLayout/SmsSentRecordLayout';
import SmsSentAsEmailLayout from './RecordLayout/SmsSentAsEmailLayout';
import EmailIsOpenedRecordLayout from './RecordLayout/EmailIsOpenedRecordLayout';

interface Props {
  event: HistoryEntry;
}

export default function Record(props: Props) {

  const map = {
    [HistoryEvent.PROJECT_CREATED]: ProjectCreatedRecordLayout,
    [HistoryEvent.TASK_COMPLETED]: TaskCompletedRecordLayout,
    [HistoryEvent.TASK_COMMENT_CREATED]: CommentCreatedRecordLayout,
    [HistoryEvent.TASK_REOPENED]: TaskReopenedRecordLayout,
    [HistoryEvent.TASK_CREATED]: TaskCreatedRecordLayout,
    [HistoryEvent.PROJECT_TYPE_UPDATED]: ProjectTypeUpdatedRecordLayout,
    [HistoryEvent.PROJECT_STATUS_UPDATED]: ProjectStatusUpdatedRecordLayout,
    [HistoryEvent.PROJECT_ASSIGNMENT_CREATED]: ProjectAssignmentCreatedRecordLayout,
    [HistoryEvent.PROJECT_ASSIGNMENT_REMOVED]: ProjectAssignmentRemovedRecordLayout,
    [HistoryEvent.PROJECT_LAUNCHED]: ProjectLaunchedRecordLayout,
    [HistoryEvent.PROJECT_NOTE_CREATED]: ProjectNoteCreatedRecordLayout,
    [HistoryEvent.EMAIL_SENT]: EmailSentRecordLayout,
    [HistoryEvent.SMS_SENT]: SmsSentRecordLayout,
    [HistoryEvent.SMS_SENT_AS_EMAIL]: SmsSentAsEmailLayout,
    [HistoryEvent.EMAIL_OPENED]: EmailIsOpenedRecordLayout,
  };

  if (props.event.event in map) {
    // @ts-ignore
    const Layout = map[props.event.event];

    return (
      // @ts-ignore
      <Layout record={props.event} />
    );
  } else {
    return (
      <div className={styles.record}>
        {`${props.event.event} - ${props.event.when} - ${JSON.stringify(props.event.data)}`}
      </div>
    );
  }
}
