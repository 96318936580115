import { PersonProfile, RoleAssignment, ShortProfile } from 'nekst-api';
import { ViewStyle } from 'react-native';
import React from 'react';

export enum UserLogoSize {
  BIG = 'big',
  MIDDLE = 'middle',
}

export interface AssigneeWidgetLayoutProps {
  profile: ShortProfile | PersonProfile,
  onClick?: () => void,
  roles?: RoleAssignment[],
  withName?: boolean,
  logoSize?: UserLogoSize,
  badge?: string,
  style?: ViewStyle,
  additionalInfo?: React.ReactNode | React.ReactNode[],
}
