import { useEventsPublisher } from 'event-bus';
import { useBackendApi } from './backendApi';
import { useAuthenticationContextRequired } from '../Authentication/AuthenticationContext';
import { Person } from './Types';

function isReactNative() {
  if (typeof jest !== 'undefined') {
    return process.env.RN_ENV === 'react-native'; // You can set this env variable in your test setup if necessary
  } else {
    return typeof navigator !== 'undefined' && navigator.product === 'ReactNative';
  }
}

function dataUriToBlob(dataURI: string) {
  const splitDataURI = dataURI.split(',');
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export const AVATAR_UPDATED = 'AVATAR_UPDATED';

export interface AvatarUpdatedEvent {
  data: Person,
}

export function useAvatarService() {
  const nekstApi = useBackendApi();

  const authenticationContext = useAuthenticationContextRequired();

  const eventsPublisher = useEventsPublisher();

  const isCurrentUser = (personId: number) => {
    return personId === authenticationContext.user.id;
  };

  const getUri = (personId: number) => {
    return isCurrentUser(personId)
      ? '/me/avatar'
      : `/people/${personId}/avatar`;
  };

  const uploadAvatar = async (personId: number, avatarData: string) => {

    const formData = new FormData();

    let blob;

    if (isReactNative()) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      blob = { uri: avatarData, name: 'media', type: 'image/jpeg' };
    } else {
      blob = dataUriToBlob(avatarData);
    }

    formData.append('file', blob, 'avatar.jpg');

    const person = await nekstApi.post(getUri(personId), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });


    if (isCurrentUser(personId)) {
      authenticationContext.setUser(person);
    }


    eventsPublisher.publish(AVATAR_UPDATED, {
      id: person.id,
      data: person,
    });

  };

  const removeAvatar = async (personId: number) => {
    const person = await nekstApi.delete(getUri(personId));

    if (isCurrentUser(personId)) {
      authenticationContext.setUser(person);
    }

    eventsPublisher.publish(AVATAR_UPDATED, {
      id: person.id,
      data: person,
    });
  };

  return {
    uploadAvatar,
    removeAvatar,
  };
}
