import React from 'react';
import { useAuthorizationChecker } from 'authorization-scope';
import { usePopupFormManager } from 'features/nekst-widgets';
import CreateStandardTaskForm from './Standard/CreateStandardTaskForm';
import CreateEmailTaskForm from './Email/CreateEmailTaskForm';
import { ButtonSize, OptionSelectionButton, useFormContext } from 'ui-builder';
import CreateSmsTaskForm from './Sms/CreateSmsTaskForm';
import { AbstractTask, TaskType, TeamPermission } from 'nekst-api';

export default function CreateDependentTaskButton() {

  const popupManager = usePopupFormManager();
  const { data } = useFormContext<AbstractTask>();

  const { isGranted } = useAuthorizationChecker();
  return (
    <OptionSelectionButton<TaskType>
      text="Create Dependent Task"
      options={[
        {
          value: TaskType.STANDARD,
          label: 'Add standard task',
          onSelected: () => {
            popupManager.openForm(
              <CreateStandardTaskForm
                projectId={data!._project.id}
                parentTask={data!}
              />,
            );
          }
        },
        {
          value: TaskType.EMAIL,
          label: 'Add email task',
          onSelected: () => {
            popupManager.openForm(
              <CreateEmailTaskForm
                projectId={data!._project.id}
                parentTask={data!}
              />
            );
          },
        },
        isGranted(TeamPermission.USE_SMS_TASKS_FEATURE) && {
          value: TaskType.SMS,
          label: 'Add SMS task',
          onSelected: () => {
            popupManager.openForm(
              <CreateSmsTaskForm
                projectId={data!._project.id}
                parentTask={data!}
              />
            );
          },
        },
      ]}
      size={ButtonSize.BIG}
    />
  );
}
