import {
  AbstractTask,
  TasksFilter,
} from 'nekst-api';

import {
  VisibilityState,
  ListFilterService,
  useFilterContext,
} from 'ui-builder';

export const NAME_FILTER_NAME = 'NAME';

export function isAllTasksPage() {
  if (typeof navigator !== 'undefined' && navigator.product === 'ReactNative') {
    return true;
  } else {
    return ['/tasks', '/calendar'].includes(window.location.pathname);
  }
}

export function useNameFilterService(): ListFilterService {
  const filterContext = useFilterContext<TasksFilter>();

  const getFilterValue = () => {
    return filterContext.filterValue?.nameContains;
  };

  const setFilterValue = (name: string) => {
    const newValue = {
      ...filterContext.filterValue || {},
      nameContains: name,
    };

    filterContext.setFilterValue(newValue);
  };

  const filterFunc = (data: AbstractTask) => {
    const filterValue = getFilterValue();

    if (filterValue && filterValue.length > 1) {
      const taskNameMatches = data._renderedName.toLowerCase()
        .includes(
          filterValue.toLowerCase(),
        );

      const projectNameMatches = isAllTasksPage()
        ? data._project.name.toLowerCase()
          .includes(filterValue.toLowerCase())
        : false;

      return (taskNameMatches || projectNameMatches)
        ? VisibilityState.VISIBLE
        : VisibilityState.HIDDEN;
    } else {
      return VisibilityState.VISIBLE;
    }
  };

  return {
    getFilterValue,
    setFilterValue,
    getDefaultValue: () => null,
    getName: () => NAME_FILTER_NAME,
    applyFilter: filterFunc,
  };
}
