import React from 'react';
import { PasswordLayoutProps } from '../Password';
import useUiTheme from '../../../uiTheme';

export default function PasswordLayout(props: PasswordLayoutProps) {

  const uiTheme = useUiTheme();

  return (
    <uiTheme.forms.BaseInputLayout
      label={props.label}
      name={props.name}
      errors={props.errors}
      showLabel={props.showLabel}
      className={props.className}
      hideLabel={props.hideLabel}
      hint={props.hint}
    >
      <input
        disabled={props.isDisabled}
        name={props.name}
        autoComplete="chrome-off"
        placeholder={props.placeholder}
        type="password"
        value={props.value || ''}
        onChange={props.onChangeCallback}
        onFocus={props.onFocusCallback}
        onBlur={props.onBlurCallback}
        data-lpignore="true"
      />
    </uiTheme.forms.BaseInputLayout>
  );
}
