/* istanbul ignore file */
import React, { ReactElement } from 'react';
import {
  BaseInputLayoutProps,
  BaseInputProps
} from './types';
import { useInputHelper } from './inputHelper';
import { useNewUiTheme } from '../../newUiTheme';

export interface CheckboxInputProps extends BaseInputProps<boolean> {
  isHighlighted?: boolean,
  labelComponent?: ReactElement,
}

export interface CheckboxInputLayoutProps extends BaseInputLayoutProps<boolean> {
  isHighlighted?: boolean,
  labelComponent?: ReactElement,
}

export function CheckboxInput(props: CheckboxInputProps) {
  const uiTheme = useNewUiTheme();

  const inputHelper = useInputHelper<boolean>(props);

  return (
    <uiTheme.inputs.CheckboxLayout
      {...inputHelper.getBaseInputLayoutProps()}
      isHighlighted={props.isHighlighted}
      labelComponent={props.labelComponent}
    />
  );
}
