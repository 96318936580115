
import {
  Button,
  ButtonColor,
  ButtonSize,
  Center,
  PopupContext,
  Right, TextArea,
  usePopupManager,
} from 'ui-builder';
import { PDFViewer, Popup, Separator } from 'features/nekst-widgets';
import React, { createContext, useContext, useEffect } from 'react';
import { PreviewDocument } from './PreviewDocument';
import useAiTrainerService, { AiTrainingEntry } from '../aiTrainerService';
import ParsedPeopleList from './ParsedPeopleList';
import { ParsedTasksList } from './ParsedTasksList';
import { getLineTitle } from './getLineTitleHelper';
import useAiTrainerNavigator from '../aiTrainerNavigator';
import { BlockerWidget } from '../BlockerWidget';
import UsedForDropdown from '../Shared/UsedForDropdown';
import { PersonParseResult, PromptParseResult } from 'nekst-api';

function ShowParsedDocument() {
  const parseContext = useAiTrainingEntryContext();
  const popupManager = usePopupManager();

  const usedRows = {} as Record<string, any[]>;

  parseContext.entry.people.forEach((person) => {
    person.context.forEach((rowNumber) => {
      if (!usedRows[rowNumber]) {
        usedRows[rowNumber] = [];
      }

      usedRows[rowNumber].push(getLineTitle(person));
    });
  });

  parseContext.entry.prompts.forEach((task) => {
    task.context.forEach((rowNumber) => {
      if (!usedRows[rowNumber]) {
        usedRows[rowNumber] = [];
      }

      usedRows[rowNumber].push(getLineTitle(task));
    });
  });

  return (
    <Button
      text="Show parsed document"
      onClick={async () => {
        popupManager.openPopup(
          <PreviewDocument
            usedRows={Object.keys(usedRows)
              .map((key) => parseInt(key, 10))}
            getLineInfo={(row) => usedRows[row]}
            fileText={parseContext.entry.fileText}
          />);
      }}
    />
  );
}


function ShowOriginalDocumentButton() {
  const aiTrainerService = useAiTrainerService();


  const parseContext = useAiTrainingEntryContext();

  const popupManager = usePopupManager();

  return (
    <Button
      text="Show Original Document"
      onClick={async () => {
        const link = await aiTrainerService.getOriginalDocumentDownloadLink(parseContext.entry.id);

        popupManager.openPopup(
          <Popup
            title="View document"
            content={<PDFViewer pdfUrl={link} fileName={parseContext.entry.fileName} />}
            isBig
          />
        )
      }}
    />
  )
}

const PdfParseResultContext = createContext<{
  entry: AiTrainingEntry,
  updateContact: (firstName: string, lastName: string, newData: PersonParseResult) => void,
  deleteContact: (firstName: string, lastName: string) => void,
  addContact: (newData: PersonParseResult) => void,
  updateTask: (taskId: string, newData: PromptParseResult) => void,
  deleteTask: (taskId: string) => void,
  addTask: (newData: PromptParseResult) => void,
  version: number,
} | undefined>(undefined);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useAiTrainingEntryContext() {
  const contextData = useContext(PdfParseResultContext);

  if (!contextData) {
    throw new Error('Component must be used within PdfParseResultContext');
  }

  return contextData;
}

function ResultSubmitButton(
  props: {
    onSubmitFunc: (data: AiTrainingEntry) => Promise<void>,
    buttonText: string,
  }
) {

  const entryContext = useAiTrainingEntryContext();
  return (
    <Button
      text={props.buttonText}
      size={ButtonSize.BIG}
      onClick={async () => {
        await props.onSubmitFunc(entryContext.entry);
      }}
    />
  );
}

export function ViewParseResultWidget(
  props: {
    entry: AiTrainingEntry,
    onSubmitFunc: (data: AiTrainingEntry) => Promise<void>,
  }
) {

  const aiTrainerNavigator = useAiTrainerNavigator();

  const [entry, setEntry] = React.useState<AiTrainingEntry>(props.entry);

  const [savedEntry, setSavedEntry] = React.useState<string>(JSON.stringify(props.entry));

  const [version, setVersion] = React.useState(0);

  const updateContact = (firstName: string, lastName: string, newData: PersonParseResult) => {
    const newPeople = entry.people.map((item) => {
      if (item.firstName === firstName && item.lastName === lastName) {
        return newData;
      }

      return item;
    });

    setEntry({
      ...entry,

      people: newPeople,
    });
  };

  const updateTask = (taskId: string, newData: PromptParseResult) => {
    const newTasks = entry.prompts.map((item) => {
      if (item.id === taskId) {
        return newData;
      }

      return item;
    });

    setEntry({
      ...entry,
      prompts: newTasks,
    });
  };

  const addTask = (newData: PromptParseResult) => {
    setEntry({
      ...entry,
      prompts: [
        ...entry.prompts,
        newData,
      ],
    });
  };

  const addContact = (newData: PersonParseResult) => {
    setEntry({
      ...entry,
      people: [
        ...entry.people,
        newData,
      ],
    });
  };

  const deleteContact = (firstName: string, lastName: string) => {
    const newPeople = entry.people.filter((item) => item.firstName !== firstName || item.lastName !== lastName);

    setEntry({
      ...entry,
      people: newPeople,
    });
  };

  const deleteTask = (taskId: string) => {
    const newTasks = entry.prompts.filter((item) => item.id !== taskId);

    setEntry({
      ...entry,
      prompts: newTasks,
    });
  };

  useEffect(() => {
    setVersion((prev) => prev + 1);
  }, [JSON.stringify(entry)]);

  return (
    <PdfParseResultContext.Provider
      value={{
        entry,
        updateContact,
        addContact,
        deleteContact,
        updateTask,
        addTask,
        deleteTask,
        version,
      }}
    >
      <PopupContext>
        <div className="form">
          <ShowParsedDocument />
          &nbsp;
          <ShowOriginalDocumentButton />

          <Right>
            <Button
              text="Go Back"
              color={ButtonColor.RED}
              onClick={async () => {
                aiTrainerNavigator.openEntriesList();
              }}
            />
          </Right>
          <Separator />
          <UsedForDropdown
            source="usedFor"
            value={entry.usedFor}
            onChangeCallback={(data) => {
              setEntry({
                ...entry,
                usedFor: data.usedFor,
              });
            }}
          />
          <Separator />
          <ParsedPeopleList />
          <Separator />
          <ParsedTasksList />
        </div>
      </PopupContext>
      <TextArea
        source="comment"
        value={entry.comment}
        onChangeCallback={(data) => {
          setEntry({
            ...entry,
            comment: data['comment'],
          });
        }}
      />
      <Center>
        <ResultSubmitButton
          onSubmitFunc={(newData) => {
            const result = props.onSubmitFunc(newData);
            setSavedEntry(JSON.stringify(newData));
            return result;
          }}
          buttonText={props.entry.id ? 'Update' : 'Create'}
        />
      </Center>
      {props.entry.id && (<BlockerWidget isSaved={savedEntry === JSON.stringify(entry)} />)}
    </PdfParseResultContext.Provider>
  );
}
