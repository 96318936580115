import React, { useState } from 'react';
import useDocumentsTemplatesService, {
  DocumentsTemplate,
} from '../../settings/documentstorage/templates/documentsTemplatesService'
import { BaseInputProps, Dropdown, LoadingAnimation } from 'ui-builder';
import useDataLoader from 'data-loader';
import { PopupText } from 'features/nekst-widgets';

export interface DocumentTemplateDropdownProps extends BaseInputProps<number> {

}

export default function DocumentTemplateDropdown(props: DocumentTemplateDropdownProps) {
  const service = useDocumentsTemplatesService();

  const [
    templates,
    setTemplates,
  ] = useState<DocumentsTemplate[]>();

  useDataLoader(
    service.getDocumentsTemplates,
    setTemplates,
  );

  if (templates) {
    if (templates.length) {
      return (
        <Dropdown
          options={templates.map((item) => ({
            label: item.name,
            value: item.id,
          }))}
          {...props}
        />
      );
    } else {
      return (
        <PopupText>
          No document templates created.
        </PopupText>
      );
    }
  } else {
    return <LoadingAnimation />;
  }
}
