import React from 'react';
import { get } from 'lodash';
import BaseFieldProps from '../../../shared/uibuilder/show/field/BaseFieldProps';
import { useShowContext } from '../../../shared/uibuilder/show/ShowReactContext';
import { DocumentLabel } from './documentLabelsService';

export default function DocumentLabelsField(props: BaseFieldProps) {

  const showContext = useShowContext();

  const labels = get(showContext.data!, props.source!) as DocumentLabel[];

  return (
    <span>
      {
        labels.map((item) => item.name)
          .join(', ')
      }
    </span>
  );
}
