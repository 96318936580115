import { useShowContext } from 'ui-builder';
import { SmsTask, TaskType } from 'nekst-api';
import React from 'react';
import { BaseTaskRowAssignmentWidget } from 'features/nekst-widgets';

export function SmsRecipientsField() {
  const { data } = useShowContext<SmsTask>();

  if (data?.type === TaskType.SMS) {
    const value = data?.recipients || [];

    const formatted = value
      .filter((v) => !!v.personId)
      .map((item) => {
        return {
          id: item.id,
          name: item._profile?.name.fullName || '',
        };
      });

    if (formatted.length > 0) {
      return (
        <BaseTaskRowAssignmentWidget
          source="recipients"
          filterFunc={(item) => (item.personId)}
          label="To:"
          getNameFunc={(item) => item._profile?.name.fullName || ''}
          getImageFunc={(item) => item._profile?._avatarUrl?.resized}
        />
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
}
