import React from 'react';
import { Route } from 'react-router-dom';
import { ApplicationModule } from '../../shared/applicationModule';
import CurrentUserHistory from './history/CurrentUserHistory';
import CompleteProfilePage from './CompleteAccount/CompleteProfilePage';

export default function useMeModule(): ApplicationModule {

  const getRouter = () => {
    return (
      <>
        <Route
          path="/me/history"
          element={<CurrentUserHistory />}
        />
        <Route
          path="/me/invitation/:code"
          element={<CompleteProfilePage />}
        />
      </>
    );
  };

  return {
    getRouter,
    name: '/me',
  };
}
