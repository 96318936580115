import React from 'react';
import { useAiTrainingEntryContext } from '../ViewParseResultWidget';
import BaseTaskParseResultForm from './BaseTaskParseResultForm';
import { AfterSubmitFunc } from 'ui-builder';
import { PromptParseResult } from 'nekst-api';

export default function UpdateTaskParseResultForm(props: {
  data: PromptParseResult,
  afterSubmitFunc?: AfterSubmitFunc
}) {
  const entryContext = useAiTrainingEntryContext();

  return (
    <BaseTaskParseResultForm
      title="Update result"
      submitFormFunc={async (data) => {
        entryContext.updateTask(props.data.id, data);
      }}
      initialData={props.data}
      afterSubmitFunc={props.afterSubmitFunc}
    />
  );
}
