import { ReactNode } from 'react';

export enum TooltipIconType {
  HELP = 'help',
}

export interface TooltipIconLayoutProps {
  tooltipContent: ReactNode,
  iconType: TooltipIconType,
}
