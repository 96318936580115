import React from 'react';
import useProjectDocumentsService, { ProjectDocument } from './projectDocumentsService';
import { DownloadFileDto } from 'nekst-api';
import { useShowContext } from 'ui-builder';
import { IconButton, IconType } from 'features/nekst-widgets';

async function downloadFile(file: DownloadFileDto) {
  const {
    downloadUrl,
    fileName,
  } = file;
  return new Promise((resolve, reject) => {
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = fileName;

    link.addEventListener('load', () => {
      URL.revokeObjectURL(link.href);
      link.remove();
      resolve(null);
    });

    link.addEventListener('error', () => {
      reject(new Error('Failed to download file.'));
    });

    document.body.appendChild(link);
    link.click();

  });
}

export default function DownloadDocumentButton() {
  const documentsService = useProjectDocumentsService();

  const showContext = useShowContext<ProjectDocument>();

  const document = showContext.data!;

  if (document.fileId) {
    return (
      <IconButton
        onClick={async () => {
          const data = await documentsService.getDownloadLink(document.id);
          await downloadFile(data);
        }}
        type={IconType.DOWNLOAD}
        title="Download Document"
      />
    );
  } else {
    return null;
  }
}
