import React from 'react';
import { Person } from 'nekst-api';
import { PersonBreadcrumbs } from '../Update/PersonUpdatePage';
import { Breadcrumb } from '../../shared/web/layout/Breadcrumbs';
import TeamMemberHistoryPage from '../../settings/teammembers/history/TeamMemberHistoryPage';

function PersonHistoryBreadcrumbs(
  props: {
    person: Person,
  },
) {
  return (
    <PersonBreadcrumbs person={props.person}>
      <Breadcrumb text="History" />
    </PersonBreadcrumbs>
  );
}

export default function PersonHistoryPage() {
  return (
    <TeamMemberHistoryPage
      breadcrumbsComponent={(props) => (
        <PersonHistoryBreadcrumbs person={props.teamMember} />
      )}
    />
  );
}
