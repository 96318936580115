import React from 'react';
import {
  CheckboxInput,
  useMassUpdateServiceRequired,
  useShowContext
} from 'ui-builder';

export default function SelectForMassUpdateCheckbox() {
  const massUpdateService = useMassUpdateServiceRequired();
  const showContext = useShowContext();

  const data = showContext.data!;

  if (massUpdateService.isEnabled()) {
    return (
      <CheckboxInput
        source="isChecked"
        label=""
        value={massUpdateService.isChecked(data.id)}
        onChangeCallback={() => {
          massUpdateService.toggleChecked(data.id);
        }}
      />
    );
  } else {
    return null;
  }
}
