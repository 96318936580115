import React from 'react';
import useProjectDocumentsService, {
  ProjectDocument,
  ProjectDocumentPermission,
} from './projectDocumentsService';
import Row6x6x from '../../shared/web/uitheme/form/Row6x6x';
import RenameFileInput from '../../shared/widgets/RenameFileInput';
import { AfterSubmitFunc, FormTemplate, useShowContext } from 'ui-builder';
import { OpenFormIconButton } from 'features/nekst-widgets';

function RenameFileForm(props: {
  data: ProjectDocument,
  afterSubmitFunc?: AfterSubmitFunc,
}) {
  const documentService = useProjectDocumentsService();
  return (
    <FormTemplate<{ fileName: string }>
      title="Rename File"
      submitFormFunc={async (data) => {
        await documentService.renameFile(props.data.id, data.fileName);

        return data;
      }}
      initialData={{
        fileName: props.data._file!.name,
      }}
      validationSchema={{
        fileName: {
          type: 'string',
          constraints: {
            required: true,
            regex: {
              value: /^[0-9a-z()\\-]+\.[a-zA-Z0-9]+$/g,
              message: 'Invalid file name',
            },
          },
        },
      }}
      afterSubmitFunc={props.afterSubmitFunc}
    >
      <Row6x6x>
        <RenameFileInput source="fileName" />
      </Row6x6x>
    </FormTemplate>
  );
}

export default function RenameFileButton() {
  const showContext = useShowContext<ProjectDocument>();

  const data = showContext.data!;

  if (data.fileId && data._permissions.includes(ProjectDocumentPermission.UPDATE)) {
    return (
      <OpenFormIconButton
        form={<RenameFileForm data={data} />}
        title="Rename File"
      />
    );
  } else {
    return null;
  }

}
