import React from 'react';
import { BaseFieldProps, TextField } from 'ui-builder';
import { getTeamRoleName, TeamRole } from 'nekst-api';

export function TeamRoleField(props: BaseFieldProps) {

  return (
    <TextField
      {...props}
      formatValueFunc={(value?: string) => {
        if (value) {
          return getTeamRoleName(value as TeamRole);
        } else {
          return value;
        }
      }}
    />
  )
}
