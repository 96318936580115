import { BaseInputProps, Dropdown, Option } from 'ui-builder';
import React, { useEffect, useState } from 'react';
import { useProjectRolesService } from 'nekst-api';

export interface SendFromDropdownProps extends BaseInputProps<number> {
}

export function SendFromDropdown(props: SendFromDropdownProps) {
  const [options, setOptions] = useState<Option[]>([]);

  const projectRolesService = useProjectRolesService();

  const loadProjectRoles = async () => {
    const projectRoles = await projectRolesService.getAllTeamRoles();

    setOptions(projectRoles.map((role) => ({
      value: role.id,
      label: role.name,
    })));
  };

  useEffect(() => {
    loadProjectRoles();
  }, []);

  return (
    <Dropdown
      {...props}
      options={options}
      placeholder="Whoever Clicks Send"
    />
  );
}
