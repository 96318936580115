import { BaseFieldProps } from "./types";
import { get } from 'lodash';
import React from 'react';
import { useShowContextRequired } from "../Show";
import { Text } from '../Common';

export enum DateFormat {
  FULL,
  SHORT,
}

export interface DateFieldProps extends BaseFieldProps<Date> {
  format?: DateFormat,
  noValueText?: string
  prefix?: string
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function getFullFormatView(date: Date) {
  return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}

function getShortFormatView(date: Date) {
  const dd = String(date.getDate());
  const mm = String(date.getMonth() + 1);
  const yyyy = date.getFullYear()
    .toString()
    .substring(2);

  return `${mm}/${dd}/${yyyy}`;
}

export function DateField(props: DateFieldProps) {
  const context = useShowContextRequired();

  const format = props.format || DateFormat.FULL;

  let obj;
  if (props.source) {
    if (context.data) {
      obj = get(context.data, props.source);
    } else {
      obj = null;
    }
  } else {
    obj = props.value;
  }

  if (obj) {

    let result;

    if (format === DateFormat.FULL) {
      result = getFullFormatView(obj);
    } else {
      result = getShortFormatView(obj);
    }

    return (
      // eslint-disable-next-line react/jsx-no-undef,react/jsx-no-useless-fragment
      <Text>
        {props.prefix ? `${props.prefix} ` : ''}
        {result}
      </Text>
    );
  } else {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return props.noValueText ? (<Text>{props.noValueText}</Text>) : null;
  }
}
