// eslint-disable react/jsx-props-no-spreading
import React from 'react';
import { AutocompleteInput, Option } from './AutocompleteInput';
import { BaseInputProps, useFormContext } from 'ui-builder';
import { Name, Person, PersonPhoneNumber, usePeopleService } from 'nekst-api';

export interface SearchContactTextInputProps extends BaseInputProps {
}

interface ShortContactProfile {
  name: Name,
  primaryEmail?: string,
  alternateEmail?: string,
  phoneNumbers: PersonPhoneNumber[],
  companyName?: string
}

interface ContactOption extends Option {
  profile: ShortContactProfile,
  id: number,
}

function useContactsSearch() {
  const contactsService = usePeopleService();

  const search = async (query: string) => {
    const contacts = await contactsService.search(query);

    return contacts.map((contact: Person) => {
      return {
        value: contact.id,
        label: `${contact.profile.name.firstName} ${contact.profile.name.lastName}`,
        profile: contact.profile,
        id: contact.id,
      } as ContactOption;
    });
  };

  return {
    search,
  };
}

export default function SearchContactTextInput(props: SearchContactTextInputProps) {
  const formContext = useFormContext();

  const searchService = useContactsSearch();

  const onChangeCallback = (event: any) => {
    formContext.onChangeCallback!({
      [props.source]: event.target.value,
      personId: null,
      "profile.phoneNumbers":  (formContext.data?.profile.phoneNumbers || []).map((phoneNumber: PersonPhoneNumber) => {
        return {
          ...phoneNumber,
          id: undefined,
        };
      })
    });
  };

  const selectOptionFunc = (option: ContactOption) => {
    const currentData = formContext.data!;

    const newFormData = {
      personId: option.id,
      profile: {
        name: {
          firstName: option.profile.name.firstName,
          lastName: option.profile.name.lastName,
        },
        companyName: option.profile.companyName,
        primaryEmail: option.profile.primaryEmail,
        alternateEmail: option.profile.alternateEmail,
        phoneNumbers: option.profile.phoneNumbers,
      },
      rolesIds: currentData.rolesIds || [],
    };

    formContext.setFormData!(newFormData);
  };

  return (
    <AutocompleteInput<ContactOption>
      {...props}
      loadSuggestions={searchService.search}
      onChangeCallback={onChangeCallback}
      selectOptionFunc={selectOptionFunc}
    />
  );
}
