import { Text, useShowContext } from 'ui-builder';
import { get } from 'lodash';
import { PersonPhoneNumberType } from 'nekst-api';
import React from 'react';
import { TextStyle } from 'react-native';

interface PhoneNumberTypeFieldProps {
  source: string;
  style?: TextStyle,
}

export function PhoneNumberTypeField(props: PhoneNumberTypeFieldProps) {

  const showContext = useShowContext();
  const value = get(showContext.data!, props.source);

  const map = {
    [PersonPhoneNumberType.HOME]: 'Home',
    [PersonPhoneNumberType.WORK]: 'Work',
    [PersonPhoneNumberType.MOBILE]: 'Mobile',
    [PersonPhoneNumberType.FAX]: 'Fax',
  };

  // @ts-ignore
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <Text style={props.style}>{map[value] || ''}</Text>;
}
