import React from 'react';
import { useDateHelper } from 'nekst-api';
import MainBlock from '../../shared/web/layout/MainBlock';
import AllTasksList from './AllTasksList';
import {
  CalendarFilterContainer,
} from '../../projects/view/tasks/list/filter/DueDate/CalendarFilter';
import CreateTaskButton from '../../projects/view/tasks/CreateUpdate/CreateTaskButton';
import CreateProjectButton from '../../projects/launch/CreateUpdateProject/CreateProjectButton';
import LatestComments from './LatestComments';
import {
  Breadcrumb,
  BreadcrumbsWrapper,
} from '../../shared/web/layout/Breadcrumbs';
import AllTasksPageTour from './AllTasksPageTour';

export default function AllTasksListPage() {

  const dateHelper = useDateHelper();

  return (
    <>
      <BreadcrumbsWrapper>
        <Breadcrumb text="Tasks" />
      </BreadcrumbsWrapper>
      <MainBlock
        title={dateHelper.toString(new Date())}
        columnContent={(
          <>
            <CalendarFilterContainer />
            <LatestComments />
          </>
        )}
        headerRight={(
          <>
            <CreateProjectButton />
            <CreateTaskButton />
          </>
        )}
      >
        <AllTasksList />
      </MainBlock>
      <AllTasksPageTour />
    </>
  );
}
