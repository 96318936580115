import { useBackendApi } from './backendApi';
import { useEventsPublisher } from 'event-bus';

export interface TasksGroup {
  id: number;
  name: string;
  color: string;
}

export interface BaseTaskGroupRequest {
  name: string;
  color: string;
}

export interface CreateTaskGroupRequest  extends BaseTaskGroupRequest {

}

export interface UpdateTaskGroupRequest extends BaseTaskGroupRequest {

}

export const TASKS_GROUP_CREATED = 'TASKS_GROUP_CREATED';
export const TASKS_GROUP_UPDATED = 'TASKS_GROUP_UPDATED';
export const TASKS_GROUP_DELETED = 'TASKS_GROUP_DELETED';

export function useTasksGroupsService() {
  const backendApi = useBackendApi();
  const eventsPublisher = useEventsPublisher();

  const getTasksGroups = async () => {
    let result = await backendApi.get('/tasksgroups') as TasksGroup[];

    result = result.sort((a, b) => a.name.localeCompare(b.name));

    return result;
  };

  const createTaskGroup = async (request: CreateTaskGroupRequest) => {
    const result = await backendApi.post('/tasksgroups', request) as TasksGroup;
    eventsPublisher.publish(TASKS_GROUP_CREATED, {
      id: result.id,
    });

    return result;
  };

  const updateTaskGroup = async (id: number, request: CreateTaskGroupRequest) => {
    const result = await backendApi.put(`/tasksgroups/${id}`, request) as TasksGroup;

    eventsPublisher.publish(TASKS_GROUP_UPDATED, {
      id,
    })

    return result;
  };

  const deleteTaskGroup = async (id: number) => {
    await backendApi.delete(`/tasksgroups/${id}`);

    eventsPublisher.publish(TASKS_GROUP_DELETED, {
      id,
    })
  };

  return {
    getTasksGroups,
    createTaskGroup,
    updateTaskGroup,
    deleteTaskGroup,
  };
}
