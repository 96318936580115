import React from 'react';
import { useProjectListConfigurationContext } from './ProjectsListConfigurationContext';
import './ByPageDropdown.scss';
import { useAuthorizationChecker } from 'authorization-scope';
import { TeamPermission } from 'nekst-api';
import { Dropdown } from 'ui-builder';

export default function ByPageDropdown() {
  const projectsListConfiguration = useProjectListConfigurationContext();

  const byPage = projectsListConfiguration.configuration?.byPage || 10;

  const { isGranted } = useAuthorizationChecker();

  if (isGranted(TeamPermission.MANAGE_TEAM_CONFIGURATION)) {
    return (
      <div
        className="form byPageDropdown"
      >
        <Dropdown
          isClearable={false}
          source="byPage"
          value={byPage}
          options={[
            {
              value: 10,
              label: '10',
            },
            {
              value: 30,
              label: '30',
            },
            {
              value: 50,
              label: '50',
            },
            {
              value: 10000,
              label: 'No Limit',
            },
          ]}
          onChangeCallback={(value) => {
            projectsListConfiguration.setByPage(value.byPage);
          }}
        />
      </div>
    );
  } else {
    return null;
  }

}
