import React, { ReactNode } from 'react';

import Layout from './Layout';

export function PaginationWrapper(
  props: {
    children: ReactNode,
  },
) {
  return (
    <Layout>
      {props.children}
    </Layout>
  );
}
