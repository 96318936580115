import React from 'react';
import OnboardingTour, { Step } from '../../shared/onboarding/OnboardingTour';
import { TeamPermission } from 'nekst-api';

export default function AllTasksPageTour() {
  const clickIfFound = (selector: string): void => {
    const element = document.querySelector(selector);
    if (element) {
      (element as HTMLElement).click();
    }
  };

  const steps: Step[] = [
    {
      selector: '[data-key="user-information-block"]',
      highlightedSelectors: ['[data-key="user-menu"]'],
      content: (
        <div>
          <p>
            Modify your profile or access the different settings to help customize your
            account.
          </p>
          <img src="/img/img_profile_menu.png" alt="Profile Menu" />
        </div>
      ),
    },
    {
      selector: '[data-key="add-new-project"]',
      content: 'Add a New Transaction and to apply a new action plan and add to your daily to-do list.',
      permission: TeamPermission.CREATE_PROJECT,
    },
    {
      selector: '[data-key="add-task-button"]',
      content: (
        <div>
          <p>
            Add a Task to any transaction. A Standard Task, Email Task or SMS Task can be added
            quickly.
          </p>
          <img src="/img/img_add_task_form.png" alt="Add Task Menu" />
        </div>
      ),
      permission: TeamPermission.CREATE_PROJECT_TASKS,
    },
    {
      selector: '.dueDateFilters',
      content: 'View Tasks due Today, Past Due, have No Due Date or see a list of ALL Tasks for every transaction.',
    },
    {
      selector: '[data-key="advanced-filter-block"]',
      content: (
        <>
          Quickly find tasks or isolate a transaction using the search bar. Use the filter for
          different task views.
          (Click the &quot;Filter&quot; button to proceed to the next step)
        </>
      ),
    },
    {
      selector: '[data-key="advanced-filter-button"]',
      highlightedSelectors: ['[data-key="popup"]'],
      mutationObservables: ['[data-key="popup"]'],
      resizeObservables: ['[data-key="popup"]'],
      content: (
        <div>
          <p>Display tasks by team member, Key Dates or task type.</p>
          <img
            src="/img/img_tasks_filter.png"
            alt="Advanced filter"
            style={{
              maxWidth: '35rem',
            }}
          />
        </div>
      ),
      actionAfter: () => {
        clickIfFound('[data-key="popup-close-button"]');
      },
    },
    {
      selector: '[data-key="mass-update"]',
      content: 'Use Mass Update to Re-Assign, Complete or Delete multiple tasks quickly.',
      permission: [TeamPermission.USE_MASS_UPDATE_FEATURE, TeamPermission.MANAGE_TASKS],
    },
  ];

  return (
    <OnboardingTour
      tourId="AllTasksPage"
      steps={steps}
    />
  );
}
