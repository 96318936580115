import React from 'react';
import Button, { ButtonColor, ButtonSize } from '../../../shared/web/button/Button';
import useProjectDocumentsService from '../projectDocumentsService';
import { downloadFile } from '../../../files/downloadFileHelper';
import { useMassUpdateServiceRequired } from 'ui-builder';
import { DownloadFileDto } from 'nekst-api';

async function downloadFilesInParallel(files: DownloadFileDto[]): Promise<void> {
  // eslint-disable-next-line no-restricted-syntax
  for (const file of files) {
    // eslint-disable-next-line no-await-in-loop
    await downloadFile(file);
  }
}

export default function DownloadButton() {
  const service = useProjectDocumentsService();

  const massUpdateService = useMassUpdateServiceRequired();

  const onClick = async () => {
    const links = await service.bulkDownload(massUpdateService.getCheckedIds());

    downloadFilesInParallel(links);
  };

  return (
    <Button
      text="Download"
      size={ButtonSize.SMALL}
      color={ButtonColor.BLUE}
      onClick={onClick}
      isFilled
      isFlat
    />
  );
}
