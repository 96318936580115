import React from 'react';
import {
  NotificationsSettings,
  NotificationType, TeamPermission,
  TeamRole,
  useAuthenticationContext,
  useNotificationsSettingsService,
  useNotificationTypeHelper
} from 'nekst-api';
import {
  CheckboxInput, FormRows,
  FormTemplate,
  Header5,
  IfFormData, isReactNative,
  LoadingAnimation, RelativeRow,
  SimpleBlock,
  SubmitButton,
  Text
} from 'ui-builder';
import useDataLoader from 'data-loader';
import { useAuthorizationChecker } from 'authorization-scope';
import { Spacings } from 'react-native-ui-lib';

export function NotificationsSettingsForm(
  props: {
    hideTitle?: boolean
  }
) {
  const notificationsSettingsService = useNotificationsSettingsService();

  const [initialData, setInitialData] = React.useState<NotificationsSettings>();

  useDataLoader(
    notificationsSettingsService.getSettings,
    setInitialData
  );

  const authenticationContext = useAuthenticationContext();

  const teamRole = authenticationContext?.user.teamRole;

  const { isGranted } = useAuthorizationChecker();

  const types = [
    NotificationType.KEY_DATE_REMINDER,
    NotificationType.TASK_DUE_DATE_REMINDER,
  ];

  if (isGranted(TeamPermission.USE_TEAM_MEMBERS_FEATURE)) {
    types.push(
      NotificationType.PROJECT_STATUS_UPDATED,
    );

    if (teamRole !== TeamRole.OWNER) {
      types.push(
        NotificationType.PROJECT_ASSIGNMENT_CREATED
      );
    }

    types.push(
      NotificationType.PROJECT_NOTE_CREATED,
      NotificationType.PROJECT_TYPE_UPDATED,
      NotificationType.TASK_COMMENT_CREATED,
      NotificationType.KEY_DATE_COMPLETED,
      NotificationType.MENTIONED_IN_COMMENT,
      NotificationType.MENTIONED_IN_NOTE
    );
  }

  const notificationTypeHelper = useNotificationTypeHelper();

  const options = types.map(type => ({
    value: type,
    label: notificationTypeHelper.getLabel(type)
  }));

  if (initialData) {
    return (
      <FormTemplate<NotificationsSettings>
        title={!props.hideTitle ? 'Notifications Settings' : undefined}
        initialData={initialData}
        submitFormFunc={notificationsSettingsService.saveSettings}
        buttons={(
          <SubmitButton text="Update" />
        )}
      >
        <FormRows>
          <Text>Customize your notifications to stay updated on project changes and key
            deadlines.</Text>
          {options.map(option => (
            <SimpleBlock key={`reminder-option-${option.value}`} style={{
              marginBottom: Spacings.s2,
              marginTop: Spacings.s2
            }}>
              <Header5>{option.label}</Header5>
              <RelativeRow weights={[3, 9]} rowStyle={{ gap: Spacings.s10 }}>
                <CheckboxInput
                  source={`${option.value}.enabled`}
                  label="Enabled"
                />
                <IfFormData conditionFunc={(data) => {
                  return !!data[option.value]?.enabled;
                }}>
                  <CheckboxInput
                    source={`${option.value}.pushNotificationsEnabled`}
                    label={isReactNative() ? 'Push Notifications' : 'Send Mobile App Push Notifications'}
                  />
                </IfFormData>
              </RelativeRow>
            </SimpleBlock>
          ))}
        </FormRows>

      </FormTemplate>
    );
  } else {
    return (<LoadingAnimation />);
  }
}
