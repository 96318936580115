import { useEventsPublisher, useEventsSubscriber } from 'event-bus';
import { useBackendApi } from './backendApi';
import { LOGGED_IN } from './loginService';
import { LOGGED_OUT } from './logoutService';
import { BadRequestError } from 'request-sender';
import { ProjectRole } from './Types';

export interface PlanUsage {
  type: 'PLAN',
  plan: {
    id: number,
    name: string,
  };
}

export interface ProjectAssignmentUsage {
  type: 'PROJECT_ASSIGNMENT',
  project: {
    id: number,
    name: string,
  },
  person: {
    id: number,
    fullName: string,
  }
}

export interface ProjectTaskAssignmentUsage {
  type: 'TASK_ASSIGNMENT';
  project: {
    id: number,
    name: string,
  };
}

export interface CreateProjectRoleRequest {
  name: string;
}

export interface UpdateProjectRoleRequest {
  name: string,
}

export const PROJECT_ROLE_UPDATED_EVENT = 'PROJECT_ROLE_UPDATED';

export const PROJECT_ROLE_CREATED_EVENT = 'PROJECT_ROLE_CREATED';

export const PROJECT_ROLE_DELETED_EVENT = 'PROJECT_ROLE_DELETED';

export class RoleHasAssignmentsError extends Error {

  private assignments: any[];

  constructor(assignments: (PlanUsage | ProjectAssignmentUsage | ProjectTaskAssignmentUsage)[]) {
    super('');
    this.assignments = assignments;
    Object.setPrototypeOf(this, RoleHasAssignmentsError.prototype);
  }

  getAssignments() {
    return this.assignments;
  }
}

export function useProjectRolesService() {
  const api = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const clearRolesCache = () => {
    (window as any)._nekst_getRolesPromise = undefined;
  };

  useEventsSubscriber(
    'projectRolesService',
    {
      [LOGGED_OUT]: clearRolesCache,
      [LOGGED_IN]: clearRolesCache,
    },
  );

  const getSortedList = async () => {
    const roles = await api.get('/projectroles');
    roles.sort((r1: any, r2: any) => {
      if (r1.order !== r2.order) {
        return r1.order - r2.order;
      } else {
        return r1.name.localeCompare(r2.name);
      }
    });

    return roles;
  };

  const getAllTeamRoles = async (): Promise<ProjectRole[]> => {
    if (!(window as any)._nekst_getRolesPromise) {
      (window as any)._nekst_getRolesPromise = getSortedList();
    }

    return (window as any)._nekst_getRolesPromise;
  };

  const getById = async (id: number) => {
    const allRoles = await getAllTeamRoles();
    return allRoles.find((role) => role.id === id)!;
  };

  const createRole = async (request: CreateProjectRoleRequest) => {
    const result = await api.post('/projectroles', request) as ProjectRole;

    eventsPublisher.publish(PROJECT_ROLE_CREATED_EVENT, {
      id: result.id,
    });
    clearRolesCache();

    return result;
  };

  const updateRole = async (id: number, request: UpdateProjectRoleRequest) => {
    const result = await api.put(`/projectroles/${id}`, request) as ProjectRole;

    eventsPublisher.publish(PROJECT_ROLE_UPDATED_EVENT, {
      id: result.id,
    });
    clearRolesCache();

    return result;
  };

  const deleteRole = async (id: number) => {
    try {
      await api.delete(`/projectroles/${id}`);

      eventsPublisher.publish(PROJECT_ROLE_DELETED_EVENT, {
        id,
      });
      clearRolesCache();
    } catch (e) {
      if (e instanceof BadRequestError) {
        if (e.getErrorCode() === 'ROLE_HAS_ASSIGNMENTS') {
          throw new RoleHasAssignmentsError(e.getResponseField('assignments', []) as any[]);
        }
      } else {
        throw e;
      }
    }
  };

  return {
    getAllTeamRoles,
    getById,
    createRole,
    updateRole,
    deleteRole,
  };
}
