import React from 'react';
import { SmsTask } from 'nekst-api';
import { useState } from 'react';
import useDataLoader from 'data-loader';
import { SmsTaskPreview, useSmsTaskPreviewService } from '../smsTaskPreviewService';
import { LoadingAnimation } from 'ui-builder';
import Layout from './Layout';


export function SendSmsPreview(
  props: {
    task: SmsTask,
  }
) {

  const [smsTaskPreviews, setSmsTaskPreviews] = useState<SmsTaskPreview[]>();

  const smsTaskPreviewService = useSmsTaskPreviewService();

  useDataLoader(
    () => smsTaskPreviewService.getSmsTaskPreview(props.task.id),
    setSmsTaskPreviews,
  );

  if (smsTaskPreviews) {
    return (
      <Layout previews={smsTaskPreviews} />
    );
  } else {
    return (<LoadingAnimation />);
  }
}
