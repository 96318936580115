import React from 'react';
import {
  SubmitButton,
  FormTemplate,
  AfterSubmitFunc,
  useListContext,
  ButtonColor, ButtonSize, IconType, Button
} from 'ui-builder';
import Separator from '../../../shared/web/uitheme/form/Separator';
import { EventAuthorFilter } from './authorFilter';
import { HistoryEventNameFilter } from './eventTypeFilter';
import { usePopupFormManager } from 'features/nekst-widgets';
import { HistoryEntry, ProjectHistoryFilter } from 'nekst-api';

function ResetFilterButton() {

  const popupManager = usePopupFormManager();
  const listContext = useListContext<HistoryEntry, ProjectHistoryFilter>();

  return (
    <Button
      text="Reset"
      color={ButtonColor.GRAY}
      isFilled
      size={ButtonSize.BIG}
      onClick={async () => {
        listContext.setFilterValues!({
          authors: {
            selected: [],
            selectAll: true,
          },
          eventTypes: {
            selected: [],
            selectAll: true,
          },
          text: null,
        });
        popupManager.closePopup();
      }}
    />
  );
}

export default function HistoryFilterForm(props: {
  afterSubmitFunc?: AfterSubmitFunc<ProjectHistoryFilter>
  showAuthorFilter?: boolean
}) {
  const listContext = useListContext<HistoryEntry, ProjectHistoryFilter>();

  let showAuthorFilter = props.showAuthorFilter;
  if (showAuthorFilter === undefined) {
    showAuthorFilter = true;
  }

  return (
    <FormTemplate<ProjectHistoryFilter>
      title="Filter"
      buttons={(
        <>
          <SubmitButton text="Submit" />
          <ResetFilterButton />
        </>
      )}
      initialData={listContext.filterValues || {} as ProjectHistoryFilter}
      submitFormFunc={async (newValue) => {
        listContext.setFilterValues!(newValue);
        return newValue;
      }}
      afterSubmitFunc={props.afterSubmitFunc}
    >
      {showAuthorFilter && (
        <>
          <EventAuthorFilter />
          <Separator />
        </>
      )}
      <HistoryEventNameFilter />
    </FormTemplate>
  );
}

export function OpenHistoryFilterButton(props: {
  showAuthorFilter?: boolean
}) {
  const popupManager = usePopupFormManager();
  return (
    <Button
      text="Filter"
      size={ButtonSize.MEDIUM}
      color={ButtonColor.BLUE}
      isFilled={false}
      iconType={IconType.FILTER}
      onClick={() => popupManager.openForm(
        <HistoryFilterForm showAuthorFilter={props.showAuthorFilter} />,
      )}
    />
  );
}
