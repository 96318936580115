import { useEffect } from 'react';
import { useFormContext } from './FormContext';

export function ClearOnDestruct(
  props: { source: string },
) {
  const formContext = useFormContext();

  useEffect(() => {
    return () => {
      if (formContext.onChangeCallback) {
        formContext.onChangeCallback({
          [props.source]: undefined,
        });
      }
    };
  }, []);

  return null;
}
