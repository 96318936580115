import React from 'react';
import { ProjectStatus } from 'nekst-api';
import { useProjectStatisticContextRequired } from 'features/tasks-forms';

export default function StatisticTotalPropertiesField(props: { status: ProjectStatus }) {

  const statistic = useProjectStatisticContextRequired().statistic!;

  const byStatus = statistic.totalByStatus;

  // @ts-ignore
  const total = byStatus[props.status.toLowerCase()] as unknown as number;
  return (
    <>
      {total}
      {total === 1 ? ' transaction' : ' transactions'}
    </>
  );
}
