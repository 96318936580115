import { useNavigate } from 'react-router-dom';

export function useTeamMemberNavigator() {
  const navigate = useNavigate();

  const TEAM_MEMBERS = '/settings/members';

  const getUpdateMemberPageLink = (id: number) => `${TEAM_MEMBERS}/${id}`;

  const openUpdateMemberPage = (id: number) => navigate(getUpdateMemberPageLink(id));

  const getHistoryPageLink = (id: number) => `${TEAM_MEMBERS}/${id}/history`;

  const openHistoryPage = (teamMemberId: number) => {
    navigate(getHistoryPageLink(teamMemberId));
  };


  return {
    getUpdateMemberPageLink,
    openUpdateMemberPage,
    getHistoryPageLink,
    openHistoryPage,
  };
}
