import { useBackendApi } from "./backendApi";
import { useCacheService } from './cacheService';
import { useEventsSubscriber } from 'event-bus';
import { PROJECT_ASSIGNMENTS_CHANGED } from './projectAssignmentsService';

export function useMentionOptionsService() {
  const backendApi = useBackendApi();

  const cacheService = useCacheService('mention-options-cache');

  useEventsSubscriber('mentionOptionsService', {
    [PROJECT_ASSIGNMENTS_CHANGED]: (eventData) => cacheService.clear(`mention-options-${eventData.projectId}`),
  })

  const getMentionOptions = async (projectId: number) => {
    return cacheService.get(`mention-options-${projectId}`, async (cacheItem) => {
      cacheItem.setExpireInSeconds(120);
      return await backendApi.get(`/projects/${projectId}/mentionoptions`);
    });

  }

  return {
    getMentionOptions,
  }
}
