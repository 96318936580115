import React from 'react';

import styles from '../History.module.scss';

export default function TextButton(
  props: {
    text: string,
    onClick: () => void,
    dataKey?: string,
  },
) {
  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <span
      onClick={props.onClick}
      className={styles.showAllTasks}
      data-key={props.dataKey}
    >
      {props.text}
    </span>
  );
}
