import React, { ReactElement } from 'react';
import { SettingsBreadcrumbs } from '../../settingsModule';
import { Breadcrumb } from '../../../shared/web/layout/Breadcrumbs';

export default function AiTrainerBreadcrumbs(
  props: {
    children?: ReactElement,
  },
) {
  return (
   <SettingsBreadcrumbs>
      <Breadcrumb text="AI Trainer" href="/admin/aitrainer">
        {props.children}
      </Breadcrumb>
   </SettingsBreadcrumbs>
  );
}
