import { IconButtonProps } from '../types';
import React from 'react';

import styles from './IconButton.module.scss';

export default function IconButtonLayoutWeb(props: IconButtonProps) {
  const classes = [
    styles.button,
    styles[props.type],
    props.className || '',
    props.disabled && styles.disabled,
    props.size === 'big' && styles.big
  ];

  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <i
      title={props.title}
      className={classes.join(' ')}
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        props.onClick();
      }}
    >
      &nbsp;
    </i>
  );
}
