import React from 'react';
import { useShowContext } from 'ui-builder';
import { Person } from 'nekst-api';

export default function MostRecentPropertyField() {
  const showContext = useShowContext<Person>();
  const person = showContext.data!;

  const recentAssignment = person._assignments
    ? person._assignments[person._assignments.length - 1]
    : null;

  if (recentAssignment) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{recentAssignment._project.name}</>;
  } else {
    return <>-</>;
  }
}
