import useProjectsApi from '../../../projects/projectsApi';

export type ClientPortalConfiguration = {
  subdomain: string
}

export default function useClientPortalService() {
  const nekstApi = useProjectsApi();

  const getConfiguration = async () => {
    return await nekstApi.get('/settings/clientportal') as ClientPortalConfiguration;
  };

  const updateConfiguration = async (request: ClientPortalConfiguration) => {
    return await nekstApi.put('/settings/clientportal', request) as ClientPortalConfiguration;
  };

  return {
    getConfiguration,
    updateConfiguration,
  };
}
