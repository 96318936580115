import { ButtonSize, NewLine, SimpleBlock, Text, TextLink, usePopupManager } from 'ui-builder';
import { ClosePopupButton, ConfirmDeletePopup, Popup, PopupText } from 'features/nekst-widgets';
import React from 'react';
import { HasActiveIosSubscriptionError, useDeleteAccountService } from 'nekst-api';
import { Colors } from 'react-native-ui-lib';
import { commonStyles } from 'ui-library-mobile-theme/commonStyles';

export function ConfirmDeleteAccountPopup() {
  const deleteAccountService = useDeleteAccountService();

  const popupManager = usePopupManager();

  return (
    <ConfirmDeletePopup
      deleteFunc={async () => {
        try {
          await deleteAccountService.deleteAccount();
        } catch (e: any) {
          if (e instanceof HasActiveIosSubscriptionError) {
            popupManager.openPopup(
              <Popup
                title="Important Notice Regarding Your Account Cancellation and Subscription"
                content={
                  <PopupText align="left">
                    <SimpleBlock>
                      <Text>
                        We've noticed that your subscription is managed through the iOS App Store.
                        To ensure a smooth cancellation process, please follow these steps:

                        <NewLine />
                        <NewLine />
                        <Text>
                          1) Cancel Your Subscription in the iOS App Store: Begin by canceling your
                          subscription directly through the iOS App Store. This is a necessary first
                          step, as canceling your account with us does not automatically cancel your
                          App Store subscription.
                        </Text>
                        <NewLine />
                        <NewLine />
                        2) Wait for the Subscription to Expire: After canceling the subscription,
                        please wait until the current subscription period has expired. This ensures
                        that you won't face any unintended charges or service interruptions.
                        <NewLine />
                        <NewLine />
                        3) Cancel Your Account: Once your subscription has expired, you can proceed
                        to cancel your account with us.
                        <NewLine />
                        <NewLine />
                        Alternatively, if you need any assistance or have questions, feel free to
                        contact our support team. We're here to help you through every step of the
                        process.
                      </Text>
                    </SimpleBlock>

                  </PopupText>
                }
                buttons={<ClosePopupButton size={ButtonSize.SMALL} />}
              />,
              true
            );
          } else {
            throw e;
          }
        }
      }}
      title="Cancel Account"
      message="Are you sure you would like to cancel your account?"
    />
  );
}

export function DeleteAccountButton() {
  const popupManager = usePopupManager();
  return (
    <Text style={{
      ...commonStyles.text,
      fontSize: 13,
      lineHeight: 15,
      alignItems: 'center',
    }}>
      To delete your account,
      {' '}
      <TextLink
        onClick={() => {
          popupManager.openPopup(
            <ConfirmDeleteAccountPopup />,
          );
        }}
        labelStyle={{
          color: Colors.$textNeutralLight,
          fontSize: 13,
          lineHeight: 15
        }}
        style={{
          alignItems: 'center'
        }}
      >
        click here
      </TextLink>
      .
    </Text>

  );
}
