import {useEventsPublisher } from 'event-bus';
import { useBackendApi } from './backendApi';

export const INVITATION_RESENT = 'INVITATION_RESENT';

export function useResendInvitationService() {
  const nekstApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const resendInvitation = async (userId: number) => {
    await nekstApi.post(`/users/${userId}/invitations`);
    eventsPublisher.publish(INVITATION_RESENT, { userId });
  };

  return {
    resendInvitation,
  };
}
