import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';

import usePlanTasksNavigator from '../planTasksNavigator';
import DetailBoxesDatesWidgetLayout, {
  DetailBoxesDatesWidgetItem,
} from '../../../shared/widgets/DetailBoxesDates/DetailBoxesDatesWidgetLayout';
import { useListContext, useShowContext } from 'ui-builder';
import {
  Plan,
  PLAN_PROJECT_TYPE_UPDATED,
  PlanTask, ProjectDetailsField,
  useEventsSubscriber,
  useProjectDetailsFieldsService
} from 'nekst-api';

export default function DetailBoxesDatesWidget() {
  const showContext = useShowContext<Plan>();
  const projectDetailsService = useProjectDetailsFieldsService();

  const listContext = useListContext<PlanTask>();
  const planTasksNavigator = usePlanTasksNavigator();

  const [datesOrderMap, setDatesOrderMap] = useState<Record<number, ProjectDetailsField>>();

  const [loading, setLoading] = useState(false);

  async function refreshDatesOrderMap() {
    if (showContext.data?.projectType) {
      setLoading(true);

      try {
        const fields = await projectDetailsService.getFields(showContext.data!.projectType);

        const result: Record<number, ProjectDetailsField> = {};
        fields.forEach((item) => {
          if (!item.isHidden) {
            result[item.id] = item;
          }
        });

        setDatesOrderMap(result);
      } finally {
        setLoading(false);
      }
    }
  }

  useEventsSubscriber(
    'DetailBoxesDatesWidget',
    {
      [PLAN_PROJECT_TYPE_UPDATED]: refreshDatesOrderMap,
    },
  );

  useEffect(() => {
    refreshDatesOrderMap();
  }, [showContext.data?.projectType]);

  const dateLinks = useMemo<DetailBoxesDatesWidgetItem[]>(() => {
    if (datesOrderMap) {
      return listContext.data
        ?.filter((item) => !!item.linkedFieldId)
        ?.filter((item) => item.linkedFieldId! in datesOrderMap)
        .sort((item1, item2) => {
          const item1Order = datesOrderMap[item1.linkedFieldId!]?.order || 0;
          const item2Order = datesOrderMap[item2.linkedFieldId!]?.order || 0;

          return item1Order - item2Order;
        })
        .map((item) => ({
          id: item.id,
          name: item.name,
          linkedField: item._linkedField!,
          openEditTaskForm: () => planTasksNavigator.openEditTaskForm(item),
        })) || [];
    } else {
      return [];
    }
  }, [listContext.version, datesOrderMap, showContext.data?.projectType]);

  if (dateLinks.length > 0) {
    return (
      <DetailBoxesDatesWidgetLayout fields={dateLinks} loading={loading} />
    );
  } else {
    return null;
  }
}

export function DetailBoxesDatesWidgetPortalPlacer() {
  const listContext = useListContext();

  if (listContext.data) {
    const container = document.getElementById('details-box-dates');

    if (container) {
      return (
        ReactDOM.createPortal(
          <DetailBoxesDatesWidget />,
          container,
        )
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
}
