/* istanbul ignore file */

import React from 'react';
import styles from './InformationBox.module.scss';

export interface InformationBoxProps {
  text: string;
}

function InformationBox(props: InformationBoxProps) {
  return (
    <div className={styles.informationBox}>
      {props.text}
    </div>
  );
}

export default InformationBox;
