import React, { ReactElement } from 'react';
import { Button, ButtonColor, ButtonSize, isReactNative, Text, usePopupManager } from 'ui-builder';
import { Popup } from '../Popup';
import styles from '../ConfirmDeletePopup/ConfirmPopup.module.scss';

export interface ConfirmPositiveActionPopupProps {
  title: string
  message: string | ReactElement,
  submitFormFunc: () => Promise<void>,
}

export function ConfirmPositiveActionPopup(props: ConfirmPositiveActionPopupProps) {
  const popupManager = usePopupManager();

  const submitFunc = async () => {
    await props.submitFormFunc();
    popupManager.closePopup();
  };

  return (
    <Popup
      title={props.title}
      content={
        <Text
          className={styles.message}
        >
          {props.message}
        </Text>
      }
      buttons={(
        <Button
          key="confirm"
          text="Confirm"
          onClick={submitFunc}
          color={ButtonColor.GREEN}
          dataKey="confirm-button"
          size={isReactNative() ? ButtonSize.MEDIUM : ButtonSize.BIG}
        />
      )}
    />
  );
}
