import React, { ReactElement } from 'react';

export interface Props {
  children: ReactElement | ReactElement[];
}

export default function OverflowHidden(props: Props) {
  return (
    <div style={{ overflow: 'hidden' }}>
      {props.children}
    </div>
  );
}
