import React, { ReactElement, useMemo, useState } from 'react';
import {
  ListFilterService,
  ListReactContext,
  useListContext,
  VisibilityState,
  aggregateFilters,
} from 'ui-builder';

interface Props {
  children: ReactElement | ReactElement[],
  filters: ListFilterService[],
  noDataMessage?: string | ReactElement,
  noDataMatchingFilterMessage?: string | ReactElement,
}

export default function ClientSideFilter<DataType, FilterType>(props: Props) {

  const listContext = useListContext<DataType, FilterType>();

  const allFilters = aggregateFilters(props.filters);

  const [version, setVersion] = useState(0);

  const contextValue = useMemo(() => {
    const { data } = listContext;

    const newData: DataType[] = [];

    data?.forEach((item: DataType) => {
      if (allFilters.applyFilter(item, data) === VisibilityState.VISIBLE) {
        newData.push(item);
      }
    });

    setVersion((prevState) => prevState + 1);

    return {
      ...listContext,
      data: newData,
      version,
    };
  }, [JSON.stringify(listContext.filterValues), listContext.version]);

  if (contextValue.data.length) {
    return (
      <ListReactContext.Provider value={contextValue}>
        {props.children}
      </ListReactContext.Provider>
    );
  } else {
    let message;
    if (listContext.data?.length) {
      message = props.noDataMatchingFilterMessage;
    } else {
      message = props.noDataMessage;
    }

    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>{message}</>
    );
  }

}
