import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useDataLoader from '../shared/uibuilder/form/dataLoader';
import useUnsubscribeService from './unsubscribeService';
import LoadingAnimation from '../shared/LoadingAnimation';

export default function UnsubscribePage() {
  const [loaded, setLoaded] = useState(false);

  const unsubscribeService = useUnsubscribeService();

  const { token } = useParams<{ token: string }>();

  useDataLoader(
    () => unsubscribeService.unsubscribe(token!),
    () => setLoaded(true),
  );

  if (loaded) {
    return (
      <>
        <h2>Unsubscribe</h2>
        <p>
          You have been successfully unsubscribed from receiving emails from Nekst.
          You can enable email notifications again in your account profile settings at any time.
        </p>
      </>
    );
  } else {
    return (
      <LoadingAnimation isBig />
    );
  }
}
