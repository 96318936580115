import { useEffect, useRef, useState } from 'react';
import { useProjectAssignmentsService, ContactAssignments, AbstractTask } from 'nekst-api';
import { useFormContext } from 'ui-builder';
import { useProjectContext } from '../../ProjectContext';

export default function useProjectAssignmentsHelper(
  onUpdate?: () => void,
) {
  const didMount = useRef(false);

  const formContext = useFormContext<AbstractTask>();

  const projectContext = useProjectContext();

  const projectAssignmentsService = useProjectAssignmentsService(
    true,
  );

  const [projectAssignments, setProjectAssignments] = useState<ContactAssignments[]>(
    projectContext.assignments || [],
  );

  useEffect(() => {
    if (projectContext.assignments) {
      setProjectAssignments(projectContext.assignments);
    } else {
      const loadAssignments = async () => {
        try {
          if (formContext.data!.projectId) {
            const newAssignments = await projectAssignmentsService.getContactAssignments(
              formContext.data!.projectId,
            );
            setProjectAssignments(newAssignments);
          } else {
            setProjectAssignments([]);
          }
        } catch (e) {
          setProjectAssignments([]);
        }
      };

      loadAssignments();
    }
  }, [formContext.data?.projectId || 0, projectContext.assignments?.length || 0]);

  useEffect(() => {
    if (didMount.current) {
      if (onUpdate) {
        onUpdate();
      }
    } else if (formContext.data?.projectId) {
      didMount.current = true;
    }
  }, [formContext.data?.projectId]);

  return {
    projectAssignments,
  };
}
