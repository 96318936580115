export default function useFacebookEventsPublisher() {
  const onSignUp = () => {
    if ((window as any).fbq) {
      (window as any).fbq('track', 'StartTrial');
    }
  };

  return {
    onSignUp,
  };
}
