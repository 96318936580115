import React from 'react';

import styles from './FilterWidget.module.scss';

export interface FilterWidgetLayoutProps {
  name: string
  text: string
  onClick: () => void
}

export default function FilterWidgetLayout(props: FilterWidgetLayoutProps) {
  const name = props.name ? `${props.name}: ` : '';
  return (
    <span className={styles.container} title={props.text}>
      <span className={styles.inner}>
        <b>{name}</b>
        &nbsp;
        {props.text}
      </span>
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <i className={styles.removeControl} onClick={props.onClick}>&nbsp;</i>
    </span>
  );
}
