import {
  PersonPermission,
  TeamMember,
  TeamPermission,
  useResendInvitationService
} from 'nekst-api';
import { ConfirmPositiveActionPopup, IconButton, IconType } from 'features/nekst-widgets';
import React from 'react';
import {
  Button,
  ButtonColor,
  ButtonSize, useListContext,
  usePopupManager,
  useShowContextRequired
} from 'ui-builder';
import { useAuthorizationChecker } from 'authorization-scope';

function ResendInvitationPopup(
  props: {
    teamMember: TeamMember,
  },
) {
  const message = `By clicking "Confirm", the invitation email will be sent to ${props.teamMember.profile.name.fullName}.`;

  const service = useResendInvitationService();

  return (
    <ConfirmPositiveActionPopup
      title="Resend Invitation Email"
      message={message}
      submitFormFunc={async () => service.resendInvitation(props.teamMember._user.id)}
    />
  );
}

function useResendInvitationLogic(teamMember: TeamMember) {

  const popupManager = usePopupManager();

  const listContext = useListContext();

  const isWithinList = listContext !== undefined;

  const { isGranted } = useAuthorizationChecker();

  const isDisabled = !teamMember._user.canInvitationBeResent;

  const handleAction = () => {
    if (!isDisabled) {
      popupManager.openPopup(
        <ResendInvitationPopup teamMember={teamMember} />
      )
    }

  };

  const renderIfGranted = (widget: React.ReactNode) => {
    let granted: boolean;

    if (isWithinList) {
      granted = isGranted(TeamPermission.MANAGE_TEAM_MEMBERS)
        && !teamMember._user.isAccountActive;
    } else {
      granted = isGranted(PersonPermission.SEND_INVITATION);
    }

    if (granted) {
      return widget;
    } else {
      return null;
    }
  };

  return {
    handleAction,
    renderIfGranted,
    isDisabled,
  };
}

export function ResendInvitationButton(
  props: {
    teamMember: TeamMember,
  }
) {
  const buttonLogic = useResendInvitationLogic(props.teamMember);

  return buttonLogic.renderIfGranted(
    <Button
      color={ButtonColor.BLUE}
      isFilled
      size={ButtonSize.MEDIUM}
      text="Re-send invitation"
      isDisabled={buttonLogic.isDisabled}
      title={
        buttonLogic.isDisabled
          ? 'It\'s not possible to send invitations more often than once in an hour'
          : undefined
      }
      onClick={buttonLogic.handleAction}
    />
  );
}

export function ResendInvitationIconButton() {

  const showContext = useShowContextRequired<TeamMember>();

  const buttonLogic = useResendInvitationLogic(showContext.data!);

  return buttonLogic.renderIfGranted(
    <IconButton
      onClick={buttonLogic.handleAction}
      type={IconType.EMAIL}
      disabled={buttonLogic.isDisabled}
      title={buttonLogic.isDisabled ? 'Invitation can be sent once in an hour' : 'Resend invitation email'}
    />
  );
}
