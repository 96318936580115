import { ViewStyle } from 'react-native';

export enum ButtonColor {
  BLUE = 'blue',
  BLUE_LIGHT = 'blueLight',
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
  GRAY = 'gray',
  WHITE = 'white',
}

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
}

export enum IconType {
  ARROW_DOWN = 'arrow_down',
  ARROW_UP = 'arrow_up',
  ARROW_RIGHT = 'arrow_right',
  PLUS = 'plus',
  FILTER = 'filter',
  PENCIL = 'pencil',
  UPLOAD = 'upload',
}

export type OnClickFunc = () => Promise<void> | void;


export interface ButtonProps {
  text: string;
  onClick?: OnClickFunc;
  isDisabled?: boolean;
  customClass?: string;
  elementRef?: any;
  color?: ButtonColor;
  size?: ButtonSize;
  iconType?: IconType;
  isFlat?: boolean;
  isFilled?: boolean;
  title?: string;
  isSubmitButton?: boolean,
  dataKey?: string
  style?: ViewStyle;
}

export interface ButtonLayoutProps {
  text: string;
  onClick?: OnClickFunc;
  isDisabled?: boolean;
  customClass?: string;
  elementRef?: any;
  color?: ButtonColor;
  size?: ButtonSize;
  iconType?: IconType;
  isFlat?: boolean;
  isFilled?: boolean;
  title?: string;
  isSubmitButton?: boolean,
  isLoading: boolean
  dataKey?: string
  style?: ViewStyle,
}
