import { BaseInputProps, LoadingAnimation, MultiSelectDropdown, Option } from 'ui-builder';
import { useProjectsService } from 'nekst-api';
import React, { useState } from 'react';
import useDataLoader from 'data-loader';

interface ProjectMultiDropdownProps extends BaseInputProps<number[]> {
  max?: number,
}

export function ProjectsMultiDropdown(props: ProjectMultiDropdownProps) {
  const projectsService = useProjectsService();

  const [options, setOptions] = useState<Option[]>();

  useDataLoader(
    async () => {
      const rawData = await projectsService.getProjectNames();
      return rawData.map((namePair) => ({
        value: namePair.id,
        label: namePair.name,
      }));
    },
    setOptions,
  );

  if (options) {
    return (
      <MultiSelectDropdown options={options} {...props} />
    );
  } else {
    return (<LoadingAnimation />);
  }
}
