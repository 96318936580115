import React from 'react';
import {
  Button,
  ButtonColor,
  ButtonSize,
  useMassUpdateServiceRequired,
  usePopupManager
} from 'ui-builder';
import { MassCompleteForm } from 'features/tasks-list-feature';

export default function CompleteButton() {
  const massUpdateService = useMassUpdateServiceRequired();

  const popupManager = usePopupManager();

  const onClick = async () => {
    popupManager.openPopup(
      <MassCompleteForm />,
    );
  };

  return (
    <Button
      text="Complete"
      isDisabled={massUpdateService.getCheckedIds().length === 0}
      color={ButtonColor.GREEN}
      size={ButtonSize.SMALL}
      isFlat
      isFilled
      onClick={onClick}
    />
  );
}
