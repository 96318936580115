import React, { ReactElement, useState } from 'react';
import { SubmitButton } from 'ui-builder';
import { useResetPasswordService } from 'nekst-api';

import FormTemplate from '../../shared/uibuilder/form/FormTemplate';
import Text from '../../shared/uibuilder/form/input/Text';

import styles from '../Login.module.scss';
import BackToLoginLink from '../Login/BackToLoginLink';
import PageTitle from '../../shared/web/PageTitle';
import { InformationMessage } from 'features/nekst-widgets';

function ResetPasswordPageForm() {

  const [notificationMessage, setNotificationMessage] = useState<
    string | ReactElement | null
  >(null);

  const resetPasswordService = useResetPasswordService();
  return (
    <FormTemplate<{ username: string }>
      title="Reset Password"
      submitFormFunc={async (data) => {
        await resetPasswordService.requestReset(data.username);

        const message = (
          <div>
            {'A password reset link has been sent to your email address '}
            {data.username}
            . Please click on the link in the email to reset your password and regain access to your
            account.
          </div>
        );

        setNotificationMessage(message);
        return data;
      }}
      validationSchema={{
        username: {
          type: 'string',
          constraints: {
            required: true,
            email: true,
          },
        },
      }}
      className={styles.form}
      buttons={(
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <SubmitButton text="Reset Password" />
          <BackToLoginLink />
        </div>
      )}
      clearAfterSubmit
    >
      {notificationMessage ? <InformationMessage>{notificationMessage}</InformationMessage> : null}
      <Text source="username" label="Email" />
    </FormTemplate>
  );
}

export default function RequestResetPasswordPage() {
  return (
    <div className={styles.page}>
      <PageTitle text="Reset Password" />
      <ResetPasswordPageForm />
    </div>
  );
}
