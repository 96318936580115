import { ShortProfile, useBackendApi } from 'nekst-api';

export interface EmailPreviewResponse {
  preview: string;
  sender: ShortProfile;
}

export function useEmailPreviewService() {
  const nekstApi = useBackendApi();

  const getEmailPreview = async (taskId: number) => {
    return await nekstApi.get(`/tasks/${taskId}/emailpreview`) as EmailPreviewResponse;
  };

  return {
    getEmailPreview,
  };
}
