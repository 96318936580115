import React from 'react';
import { BaseInputLayoutProps } from '../index';
import useUiTheme from '../../../uiTheme';
import { useFormContext } from '../../FormContext';

import styles from './TextAreaLayout.module.scss';

export interface TextAreaLayoutProps extends BaseInputLayoutProps {
  small?: boolean,
}

function TextAreaLayout(props: TextAreaLayoutProps) {
  const uiTheme = useUiTheme();

  const formContext = useFormContext();

  const maxLength = formContext.getConstraintValue
    ? formContext.getConstraintValue(props.name, 'maxLength')
    : undefined;

  return (
    <uiTheme.forms.BaseInputLayout
      name={props.name}
      label={props.label}
      errors={props.errors}
      hideLabel={props.hideLabel}
      showRequiredIcon={props.showRequiredIcon}
      className={props.className}
      hint={props.hint}
    >
      <textarea
        name={props.name}
        onChange={props.onChangeCallback}
        value={props.value || ''}
        placeholder={props.placeholder}
        disabled={props.isDisabled}
        maxLength={maxLength || undefined}
        className={props.small ? styles.small : ''}
      />
      {maxLength && (
        <span className="input-hint">
          {(props.value || '').length}
          /
          {maxLength}
        </span>
      )}
    </uiTheme.forms.BaseInputLayout>
  );
}

export default TextAreaLayout;
