import { useBackendApi } from './backendApi';
import { useEventsPublisher } from 'event-bus';

export type ProjectPlan = {
  id: number
  name: string
  hasGroups: boolean
  sourcePlanId: number
  excludedGroupsIds: number[]
}

export interface ProjectPlanService {
  getPlanByProjectId: (projectId: number) => Promise<ProjectPlan>;
}

export const INCLUDED_GROUPS_CHANGED = 'INCLUDED_GROUPS_CHANGED';

export function useProjectPlanService() {
  const backendApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const getPlanByProjectId = async (projectId: number) => {
    return await backendApi.get(`/projects/${projectId}/plan`) as ProjectPlan;
  };

  const excludeGroupFromPlan = async (projectId: number, groupId: number) => {
    await backendApi.delete(`/projects/${projectId}/plan/tasksgroups/${groupId}`);
    eventsPublisher.publish(INCLUDED_GROUPS_CHANGED, { projectId });
  };

  const includeGroupToPlan = async (projectId: number, groupId: number) => {
    await backendApi.post(`/projects/${projectId}/plan/tasksgroups/${groupId}`);
    eventsPublisher.publish(INCLUDED_GROUPS_CHANGED, { projectId });
  };

  return {
    getPlanByProjectId,
    excludeGroupFromPlan,
    includeGroupToPlan,
  };
}
