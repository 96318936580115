import { useBackendApi } from "./backendApi";
import { FileDto } from "./fileService";
import { ProjectType } from './Types';


export type ContractTemplate = {
  id: number,
  documentExampleFileId: number,
  projectType: ProjectType,
  setId: number,
  _documentExampleFile?: FileDto,
  _documentExampleFileText: string
}

export function useContractTemplatesService() {
  const backendApi = useBackendApi();

  const getContractTemplate = async (id: number): Promise<ContractTemplate> => {
    return await backendApi.get(`/contracttemplates/${id}`) as ContractTemplate;
  }

  return {
    getContractTemplate,
  }
}
