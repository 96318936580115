import React from 'react';
import { TasksGroup } from 'nekst-api';
import { SimpleBlock, Text } from 'ui-builder';

import styles from './TasksGroupIcon.module.scss';

export function TasksGroupIcon(
  props: {
    group: TasksGroup,
    withName?: boolean
  }
) {
  return (
    <SimpleBlock className={[
      styles.outer,
      props.withName ? styles.withName : ''
    ].join(' ')}>
      {props.group.id ? (
        <div
          className={styles.icon}
          style={{
            backgroundColor: props.group.color,
          }}
          title={props.group.name}
        >
          {props.group.name[0].toUpperCase()}
        </div>
      ) : null}
      {props.withName && (
        <Text>
          {props.group.name}
        </Text>
      )}
    </SimpleBlock>
  );
}
