import React from 'react';
import CreateProjectForm from './CreateProjectForm';
import {
  Button,
  ButtonColor,
  ButtonSize,
  IconType,
  OptionSelectionButton
} from 'ui-builder';
import { useAuthorizationChecker } from 'authorization-scope';
import { TeamPermission } from 'nekst-api';
import { usePopupFormManager } from 'features/nekst-widgets';
import useFeatureToggleService from 'feature-toggle';
import ImportFromContractForm from '../FromContract/ImportFromContractForm';

function ActiveCreateProjectButton() {
  const popupManager = usePopupFormManager();

  const openManualForm = () => {
    popupManager.openForm(
      <CreateProjectForm title="Add New Transaction" />,
    );
  };

  const importFromContractForm = () => {
    popupManager.openForm(
      <ImportFromContractForm />
    );
  }

  const featureToggleService = useFeatureToggleService();

  const isLaunchingFromContractEnabled = featureToggleService.isLaunchingFromContractEnabled();

  if (!isLaunchingFromContractEnabled) {
    return (
      <Button
        color={ButtonColor.BLUE}
        size={ButtonSize.MEDIUM}
        text="Add New Transaction"
        isFilled
        iconType={IconType.PLUS}
        onClick={openManualForm}
        dataKey="add-new-project"
      />
    );
  } else {
    return (
      <OptionSelectionButton<string>
        options={[
          {
            label: 'Manually',
            value: 'manually',
            onSelected: openManualForm,
          },
          {
            label: 'Import from Contract',
            value: 'import_from_contract',
            onSelected: importFromContractForm,
          }
        ]}
        text="Add New Transaction"
        size={ButtonSize.MEDIUM}
      />
    );
  }

}

function ProjectLimitReachedButton() {
  return (
    <Button
      color={ButtonColor.BLUE}
      size={ButtonSize.MEDIUM}
      text="Add New Transaction"
      isFilled
      iconType={IconType.PLUS}
      isDisabled
      title="You reached the transactions limit. Update your plan to create a new transaction."
    />
  );
}

export default function CreateProjectButton() {
  const { isGranted } = useAuthorizationChecker();

  if (isGranted(TeamPermission.ACCESS_PROJECT_CREATION)) {
    if (isGranted(TeamPermission.CREATE_PROJECT)) {
      return (<ActiveCreateProjectButton />);
    } else {
      return (<ProjectLimitReachedButton />);
    }
  } else {
    return null;
  }
}
