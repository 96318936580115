import React, { ReactElement, useState } from 'react';
import { useProjectsService, Project, ProjectPermission } from 'nekst-api';
import { useParams } from 'react-router-dom';
import InnerBlock from '../../shared/web/layout/InnerBlock';
import ClientPortalConfigurationForm, { FormButtonsPortal } from './ClientPortalConfigurationForm';
import useDataLoader from '../../shared/uibuilder/form/dataLoader';
import LoadingAnimation from '../../shared/LoadingAnimation';
import TextField from '../../shared/uibuilder/show/field/TextField';
import ProjectPagesButtons from '../view/ProjectPagesButtons';
import MainBlock from '../../shared/web/layout/MainBlock';
import { ProjectBreadcrumb } from '../view/ProjectContext';
import { Breadcrumb } from '../../shared/web/layout/Breadcrumbs';
import CopyClientPortalLinkButton, { CopyClientPortalLinkPortal } from './CopyClientPortalLinkButton';
import OpenPortalPreview, { OpenPortalPreviewPortal } from './OpenPortalPreview';
import { ProjectTypeField } from 'features/tasks-forms';
import { useAuthorizationChecker } from 'authorization-scope';
import useClientPortalConfigurationService, {
  ClientPortalConfiguration
} from './clientPortalConfigurationService';
import { SimpleBlock } from 'ui-builder';

function ClientPortalConfigurationBreadcrumbs() {
  return (
    <ProjectBreadcrumb>
      <Breadcrumb text="Client Portal Configuration" />
    </ProjectBreadcrumb>
  );
}

function ClientPortalConfigurationPageManagerView() {
  const params = useParams();

  const [project, setProject] = useState<Project>();
  const projectService = useProjectsService();

  useDataLoader(
    () => projectService.getById(parseInt(params.id!, 10)),
    setProject,
  );

  if (project) {
    return (
      <>
        <ClientPortalConfigurationBreadcrumbs />
        <MainBlock
          title={<TextField source="name" />}
          titleLine2={<ProjectTypeField />}
          headerRight={(
            <>
              <FormButtonsPortal />
              <OpenPortalPreviewPortal />
              <CopyClientPortalLinkPortal />
              <ProjectPagesButtons />
            </>
          )}
        >
          <InnerBlock title="Client Portal Configuration">
            <ClientPortalConfigurationForm project={project} />
          </InnerBlock>
        </MainBlock>
      </>
    );
  } else {
    return <LoadingAnimation isBig />;
  }
}

function ClientPortalConfigurationPageMember() {
  const params = useParams();

  const [project, setProject] = useState<Project>();

  const projectService = useProjectsService();

  useDataLoader(
    () => projectService.getById(parseInt(params.id!, 10)),
    setProject,
  );


  const clientPortalConfigurationService = useClientPortalConfigurationService();
  const [clientPortalConfiguration, setClientPortalConfiguration] = useState<ClientPortalConfiguration>();

  useDataLoader(
    () => clientPortalConfigurationService.getConfiguration(parseInt(params.id!, 10)),
    setClientPortalConfiguration,
  );

  let content: ReactElement;

  if (clientPortalConfiguration) {
    content = (
      <SimpleBlock>

        {clientPortalConfiguration.enabled && (
          <>
            Client Portal is enabled for this project. You can preview how it looks and copy the link to share with clients.
            <br />
            <br />
            <OpenPortalPreview data={clientPortalConfiguration} />
            <br />
            <br />
            <CopyClientPortalLinkButton data={clientPortalConfiguration} />
            <br />
          </>

        )}
      </SimpleBlock>
    )
  } else {
    content = (<LoadingAnimation />);
  }

  if (project) {
    return (
      <>
        <ClientPortalConfigurationBreadcrumbs />
        <MainBlock
          title={<TextField source="name" />}
          titleLine2={<ProjectTypeField />}
        >
          <InnerBlock title="Client Portal">
            {content}
          </InnerBlock>
        </MainBlock>
      </>
    );
  } else {
    return <LoadingAnimation isBig />;
  }
}

export default function ClientPortalConfigurationPage() {
  const { isGranted } = useAuthorizationChecker();

  if (isGranted(ProjectPermission.MANAGE_CLIENT_PORTAL_CONFIGURATION)) {
    return <ClientPortalConfigurationPageManagerView />;
  } else {
    return <ClientPortalConfigurationPageMember />;
  }
}
