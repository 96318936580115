import {
  AfterSubmitFunc,
  Button,
  ButtonColor,
  ButtonSize,
  Dropdown,
  FormTemplate,
  IconType,
  LoadingAnimation, Option,
  SimpleBlock,
  TextInput,
  useFormContextRequired,
  useMassUpdateServiceRequired,
} from 'ui-builder';
import React, { useState } from 'react';
import {
  CreateTaskGroupRequest,
  PlanTask,
  TASKS_GROUP_CREATED,
  TasksGroup,
  useEventsSubscriber, useSetPlanTasksGroupService,
  useTasksGroupsService
} from 'nekst-api';
import useDataLoader from 'data-loader';

import styles from './SetGroup.module.scss';

import { ColorPicker, TasksGroupIcon, usePopupFormManager } from 'features/nekst-widgets';
import { TasksGroupsToggleWrapper, ToggledFeature, withToggleWrapper } from 'feature-toggle';

function CreateGroupForm(
  props: {
    afterSubmitFunc?: AfterSubmitFunc<TasksGroup>
  }
) {
  const tasksGroupsService = useTasksGroupsService();
  return (
    <FormTemplate<CreateTaskGroupRequest, TasksGroup>
      submitFormFunc={async (data) => {
        return await tasksGroupsService.createTaskGroup(data);
      }}
      afterSubmitFunc={props.afterSubmitFunc}
      title="Create Tasks Group"
      initialData={{
        color: '#000000'
      }}
      validationSchema={{
        name: {
          type: 'string',
          constraints: {
            required: true,
          }
        },
        color: {
          type: 'string',
          constraints: {
            required: true,
            minLength: {
              value: 7,
              message: 'Color must be a hex color code'
            },
            maxLength: {
              value: 7,
              message: 'Color must be a hex color code'
            }
          }
        }
      }}
    >
      <TextInput source="name" label="Group Name" />
      <ColorPicker source="color" label="Color" />
    </FormTemplate>
  );
}

function useTasksGroupsLoader() {
  const tasksGroupsService = useTasksGroupsService();
  const [tasksGroups, setTasksGroups] = useState<TasksGroup[]>();

  const [version, setVersion] = useState(0);

  useEventsSubscriber(
    'tasksGroupLoader',
    {
      [TASKS_GROUP_CREATED]: () => setVersion(version + 1)
    }
  );

  useDataLoader(
    tasksGroupsService.getTasksGroups,
    setTasksGroups,
    version
  );

  return {
    tasksGroups,
  };
}

function CreateGroupButton() {
  const popupManager = usePopupFormManager();

  const formContext = useFormContextRequired<PlanTask>();

  const setCreatedGroupAsValueAfterSubmit = async (data: TasksGroup) => {
    formContext.onChangeCallback({
      groupId: data.id,
    });
  };

  return (
    <Button
      text="Create New Group"
      onClick={() => {
        popupManager.openForm(
          <CreateGroupForm
            afterSubmitFunc={setCreatedGroupAsValueAfterSubmit}
          />,
          false,
          true,
        );
      }}
      color={ButtonColor.BLUE}
      isFilled
      size={ButtonSize.SMALL}
      iconType={IconType.PLUS}
    />
  );
}

function TaskGroupsOptionsWidget() {
  const { tasksGroups } = useTasksGroupsLoader();

  if (tasksGroups) {
    if (tasksGroups.length > 0) {

      const options: Option[] = tasksGroups.map((group) => {
        return {
          label: <TasksGroupIcon group={group} withName />,
          value: group.id
        };
      });

      // as first item

      options.unshift({
        label: 'No Group (remove from current group)',
        value: 0
      });

      return (
        <SimpleBlock className={styles.block}>
          <Dropdown
            label="Group"
            source="groupId"
            options={options}
          />
          <br />
          <CreateGroupButton />
        </SimpleBlock>
      );
    } else {
      return (
        <SimpleBlock className={styles.block}>
          <div className={styles.item}>
            No groups available. You may create a new one.
          </div>
          <br />
          <CreateGroupButton />
        </SimpleBlock>
      );
    }

  } else {
    return (<LoadingAnimation />);
  }
}

function SelectOptionGroupForm(
  props: {
    ids: number[],
    afterSubmitFunc?: AfterSubmitFunc<{ groupId: number }>
  }
) {
  const setGroupService = useSetPlanTasksGroupService();

  return (
    <FormTemplate<{ groupId: number }>
      submitFormFunc={async (data) => {
        await setGroupService.setGroup(
          props.ids,
          data.groupId !== 0 ? data.groupId : null,
        );

        return data;
      }}
      afterSubmitFunc={props.afterSubmitFunc}
      title="Select Group"
      initialData={{
        groupId: 0
      }}
    >
      <TaskGroupsOptionsWidget />
    </FormTemplate>
  );
}

function isPlansPage() {
  return window.location.pathname.includes('/plans/');
}

function SetGroupButton() {
  const massUpdateService = useMassUpdateServiceRequired();

  const popupManager = usePopupFormManager();

  if (isPlansPage()) {
    return (
      <TasksGroupsToggleWrapper>
        <Button
          text="Group"
          isDisabled={massUpdateService.getCheckedIds().length === 0}
          onClick={async () => {
            popupManager.openForm(
              <SelectOptionGroupForm ids={massUpdateService.getCheckedIds()} />,
            );
          }}
          color={ButtonColor.GREEN}
          isFilled
          size={ButtonSize.SMALL}
          isFlat
        />
      </TasksGroupsToggleWrapper>
    );
  } else {
    return null;
  }
}

export default withToggleWrapper(SetGroupButton, ToggledFeature.TASKS_GROUPS);
