import React from 'react';
import { useParams } from 'react-router-dom';
import {
  DetailsFieldsSet,
  FIELD_IN_SET_UPDATED,
  ProjectDetailsField,
  ProjectType,
  UpdateFieldInSetRequest,
  useDetailsFieldsSetsService,
  useProjectDetailsFieldsService
} from 'nekst-api';
import {
  AfterSubmitFunc,
  CheckboxInput,
  FormTemplate,
  List,
  ListDataRefresher,
  Right,
  TextField,
  useShowContextRequired
} from 'ui-builder';
import GridLayout from '../../../../shared/uibuilder/list/GridLayout';
import DetailsFieldsTabs from '../../shared/DetailsFieldsTabs';
import ProjectDetailsFieldTypeField from '../../List/ProjectDetailsFieldTypeField';
import Cell from '../../../../shared/uibuilder/list/Cell';
import {
  IconType,
  OpenFormIconButton,
} from 'features/nekst-widgets';

import styles from './DetailsFieldsSetPage.module.scss';
import { useDetailsFieldsSetsContextRequired } from '../../shared/DetailsFieldsSetsContext';
import { ToggledFeature, withToggleWrapper } from 'feature-toggle';
import VisibilityFieldLayout from '../../shared/VisibilityFieldLayout';
import Left from '../../../../shared/web/Left';
import ParentFieldDropdown from './ParentFieldDropdown';
import EditSetButton from './EditSetButton';

function UpdateFieldInSetForm(
  props: {
    set: DetailsFieldsSet,
    afterSubmitFunc?: AfterSubmitFunc,
  }
) {
  const showContext = useShowContextRequired<ProjectDetailsField>();

  const setService = useDetailsFieldsSetsService();
  return (
    <FormTemplate<UpdateFieldInSetRequest>
      title={`Update "${showContext.data!.name}" in "${props.set.name}" set`}
      submitFormFunc={async (data) => {
        await setService.updateFieldInSet(props.set.id, showContext.data!.id, data);
        return data;
      }}
      initialData={{
        includeToSet: !showContext.data!.isExcludedFromSet,
        parentFieldId: showContext.data!.parentFieldId,
      }}
      afterSubmitFunc={props.afterSubmitFunc}
    >
      <CheckboxInput
        source="includeToSet"
        label="Include in Set"
      />
      <ParentFieldDropdown />
    </FormTemplate>
  );
}

function UpdateFieldInSetButton(props: {
  set: DetailsFieldsSet,
}) {

  return (
    <OpenFormIconButton
      form={<UpdateFieldInSetForm set={props.set} />}
      type={IconType.PENCIL}
    />
  );
}

function FieldInSetVisibilityField() {

  const showContext = useShowContextRequired<ProjectDetailsField>();

  const value = showContext.data!.isExcludedFromSet;

  return (
    <VisibilityFieldLayout value={value} />
  );
}

export function DetailsFieldsSetPage() {

  const [showExcluded, setShowExcluded] = React.useState(false);

  const params = useParams<{
    setId: string,
    projectType: string,
  }>();

  const setId = parseInt(params.setId!, 10);
  const projectType = params.projectType?.toUpperCase() as ProjectType | undefined;

  const detailsFieldsService = useProjectDetailsFieldsService();

  const { sets } = useDetailsFieldsSetsContextRequired();

  const set = sets.find((item) => item.id === setId);

  if (projectType && set) {
    return (
      <>
        <DetailsFieldsTabs projectType={projectType} currentSetId={setId} />
        <div>
          <Left>
            <CheckboxInput
              source="showExcluded"
              label="Show Hidden Detail Boxes"
              onChangeCallback={() => setShowExcluded(!showExcluded)}
              value={showExcluded}
            />
          </Left>
          <Right className={styles.setManageButtons}>
            <EditSetButton set={set} />
          </Right>
        </div>
        <List
          getDataFunc={async () => detailsFieldsService.getFieldsInSet(setId, showExcluded)}
          dependencies={[setId, showExcluded]}
        >
          <GridLayout>
            <TextField source="name" label="Detail Boxes" />
            <ProjectDetailsFieldTypeField source="fieldType" label="Type" />
            <TextField source="_parentField.name" label="Parent Box" noDataMessage="-" />
            <Cell label="Actions">
              <UpdateFieldInSetButton set={set} />
              <FieldInSetVisibilityField />
            </Cell>
          </GridLayout>
          <ListDataRefresher eventName={FIELD_IN_SET_UPDATED} />
        </List>
      </>
    );
  } else {
    return null;
  }
}

export default withToggleWrapper(DetailsFieldsSetPage, ToggledFeature.DETAILS_FIELD_SETS);
