import React from 'react';
import {
  BaseInputProps,
  CheckboxGroup,
  LoadingAnimation,
  Option,
  Text,
  TextLink
} from 'ui-builder';
import useDataLoader from 'data-loader';
import useDocumentLabelsService from '../../documentstorage/labels/documentLabelsService';
import useSettingsNavigator from '../../settingsNavigator';

interface DocumentLabelsCheckboxGroupProps extends BaseInputProps<number[]> {

}

export default function DocumentLabelsCheckboxGroup(
  props: DocumentLabelsCheckboxGroupProps
) {

  const [options, setOptions] = React.useState<Option<number>[]>([]);

  const documentLabelsService = useDocumentLabelsService();

  const settingsNavigator = useSettingsNavigator();

  useDataLoader(
    async () => {
      const labels = await documentLabelsService.getLabels();

      return labels
        .filter((label) => !!label._file)
        .map((label) => ({
          label: `${label.name} - ${label._file.name}`,
          value: label.id,
        }));
    },
    setOptions
  );

  if (options) {
    return (
      <CheckboxGroup
        options={options}
        {...props}
        noOptionsMessage={(
          <Text>
            You do not have universal documents. You can create them
            {' '}
            <TextLink
              onClick={() => {
                settingsNavigator.openDocumentLabelsPage();
              }}
              href={settingsNavigator.getDocumentLabelsLink()}
            >
              here
            </TextLink>.
          </Text>
        )}
      />
    );
  } else {
    return (<LoadingAnimation />);
  }
}
