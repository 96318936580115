import React from 'react';
import { ButtonColor, SubmitButton } from 'ui-builder';
import CreateProjectForm from './CreateProjectForm';

import styles from './CreateProjectPage.module.scss';
import LaunchProjectSteps, { LaunchProjectStepId } from '../LaunchProjectSteps';
import Separator from '../../../shared/web/uitheme/form/Separator';
import { Breadcrumb } from '../../../shared/web/layout/Breadcrumbs';
import { ProjectsListBreadcrumbs } from '../../list/ProjectsListPage';

function CreateProjectBreadcrumbs() {
  return (
    <ProjectsListBreadcrumbs>
      <Breadcrumb text="Create" href="/transactions/new" />
    </ProjectsListBreadcrumbs>
  );
}

export default function CreateProjectPage() {

  return (
    <>
      <CreateProjectBreadcrumbs />
      <h1>Create new transaction</h1>
      <Separator />
      <LaunchProjectSteps currentStep={LaunchProjectStepId.DETAILS} disabled />
      <CreateProjectForm
        className={styles.page}
        buttons={(
          <SubmitButton
            text="Next  &nbsp; >"
            isFilled
            color={ButtonColor.BLUE}
            key="submit"
          />
        )}
      />
    </>
  );
}
