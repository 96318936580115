import { PopupFormProps } from '../../types';
import { NewThemeOverrider, usePopupManager } from 'ui-builder';

import SubmitPopupButtonLayout   from './SubmitPopupButtonLayout';
import React, { useCallback } from 'react';
import { ClosePopupButton } from '../../../Popup';
import PopupFormLayout from './NotCenteredPopupFormLayout';

export default function PopupFormLayoutWeb(props: PopupFormProps) {
  const popupManager = usePopupManager();

  const themeOverriderValue = {
    forms: {
      FormLayout: PopupFormLayout,
    },
    buttons: {
      CancelButtonLayout: ClosePopupButton,
      SubmitButtonLayout: SubmitPopupButtonLayout,
    },
  };

  const afterSubmitFunc = useCallback((data: any) => {
    if (props.children.props.afterSubmitFunc) {
      props.children.props.afterSubmitFunc(data);
    }

    popupManager.closePopup();
  }, []);

  return (
    <NewThemeOverrider
      theme={themeOverriderValue}
    >
      {React.cloneElement(props.children, {
        afterSubmitFunc,
      })}
    </NewThemeOverrider>
  );
}
