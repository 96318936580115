import React from 'react';

export default function TextLink(
  props: {
    uri: string,
    action: () => void
    children: string,
    className?: string
  },
) {
  return (
    <a
      href={props.uri}
      onClick={(event) => {
        event.preventDefault();

        props.action();
      }}
      className={props.className}
    >
      {props.children}
    </a>
  );
}
