import { useBackendApi } from './backendApi';
import { useTasksResponseMapper, useTasksFilterHelper } from './Helper';
import { TasksFilter, AbstractTask, Limit, PageResponse } from './Types';

export function useAllTasksService() {
  const projectsApi = useBackendApi();

  const { mapList } = useTasksResponseMapper();

  const filterHelper = useTasksFilterHelper();

  const getTasks = async (
    filter: TasksFilter,
    limit: Limit,
  ): Promise<PageResponse<AbstractTask>> => {
    const filterString = filterHelper.buildQueryString(filter);

    const response = await projectsApi
      .get(`/tasks?limit=${limit.limit}&offset=${limit.offset}&showCompleted=0&${filterString}`);

    return {
      ...response,
      data: mapList(response.data),
    };
  };

  return {
    getTasks,
  };
}
