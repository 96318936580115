import React from 'react';
import CheckboxGroup from '../../shared/uibuilder/form/input/CheckboxGroup';
import usePeopleService, { Person } from '../../people/peopleService';
import DataLoader from '../../shared/DataLoader';

export default function TeamMembersCheckboxes() {
  const peopleService = usePeopleService();
  return (
    <DataLoader<Person[]> getDataFunc={peopleService.getTeamMembers}>
      {(data: Person[]) => {
        return (
          <CheckboxGroup
            options={data.map((contact) => {
              return {
                value: contact.id,
                label: contact.profile.name.fullName,
              };
            })}
            label="Team Members"
            source="teamMembersIds"
          />
        );
      }}
    </DataLoader>
  );
}
