import React, { useEffect } from 'react';
import { useParseContractContext } from 'features/tasks-forms';
import {
  PlanTask, ProjectDetailsField, useAiLauncherService, usePlanTasksService,
  useProjectDetailsFieldsService,
} from 'nekst-api';
import { useProjectContext } from '../../../view/ProjectContext';
import {
  List, LoadingAnimation,
  useShowContextRequired,
} from 'ui-builder';
import GridLayout from '../../../../shared/uibuilder/list/GridLayout';
import Cell from '../../../../shared/uibuilder/list/Cell';
import { UpdateParseValueButton } from './UpdateParseValueButton';
import { EntryType, VerifyDataEntry } from './types';
import { ParsedValueField } from './ParsedValueField';
import LaunchFromContractSteps, { LaunchFromContractStepId } from '../LaunchFromContractSteps';
import { NextButton } from '../../Buttons';
import ShowContractPieceButton from './ShowContractPieceButton';
import { useParams } from 'react-router-dom';
import useLaunchFromContractNavigator from '../launchFromContractNavigator';

function useVerifyDataEntriesLoader() {
  const projectContext = useProjectContext();

  const projectType = projectContext.data?.type;

  const detailsFieldsService = useProjectDetailsFieldsService();

  const parsedContractContext = useParseContractContext();

  const [planTasks, setPlanTasks] = React.useState<PlanTask[]>();

  const [verifyDataEntries, setVerifyDataEntries] = React.useState<VerifyDataEntry[]>();

  const planTaskService = usePlanTasksService();

  useEffect(() => {
    if (projectContext.data?.planId) {
      planTaskService.getPlanTasks(projectContext.data.planId)
        .then((tasks) => {
          setPlanTasks(tasks);
        });
    }
  }, [projectContext.data?.planId]);

  const getData = async () => {
    if (projectType && parsedContractContext && planTasks) {

      const detailsFieldsItems = (await detailsFieldsService.getFieldsInSet(projectContext.data!.detailsFieldsSetId!))
        .map((detailsField) => {
          let name = detailsField.name;
          if (detailsField.isProjectStartDate) {
            name += ' (Start Date)';
          } else if (detailsField.isProjectEndDate) {
            name += ' (End Date)';
          }

          return {
            detailsField,
            name,
            primary: detailsField.isProjectStartDate || detailsField.isProjectEndDate,
            parsedValue: parsedContractContext.parseResult.detailsFields[detailsField.id],
            relatedTask: planTasks.find((item) => item.linkedFieldId === detailsField.id),
          };
        });
      detailsFieldsItems.sort((a, b) => {
        const getOrder = (detailsField: ProjectDetailsField) => {
          if (detailsField.isProjectStartDate) {
            return -1000001;
          } else if (detailsField.isProjectEndDate) {
            return -1000000;
          } else {
            return detailsField.order;
          }
        };

        return getOrder(a.detailsField) - getOrder(b.detailsField);
      });

      return [
        {
          type: EntryType.NAME,
          name: 'Transaction Name',
          parsedValue: parsedContractContext.parseResult.name,
          primary: true,
        },
        ...detailsFieldsItems
      ] as VerifyDataEntry[];
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (parsedContractContext && projectType && planTasks) {
      getData()
        .then((data) => {
          setVerifyDataEntries(data);
        });
    }
  }, [projectType, parsedContractContext, planTasks]);

  return {
    verifyDataEntries,
    planTasks,
  };
}

function DetailBoxNameField() {
  const showContext = useShowContextRequired<VerifyDataEntry>();

  const data = showContext.data!;

  if (data.primary) {
    return (<b>{data.name}</b>);
  } else {
    return (<>{data.name}</>);
  }
}

export default function ValidateDefinedContractDataPage() {

  const { verifyDataEntries } = useVerifyDataEntriesLoader();

  const params = useParams<{id: string}>();

  const projectId = parseInt(params.id!, 10);

  const aiLauncherService = useAiLauncherService();

  const launchFromContractNavigator = useLaunchFromContractNavigator();

  if (verifyDataEntries) {
    return (
      <>
        <LaunchFromContractSteps currentStep={LaunchFromContractStepId.VERIFY_DATA} />
        <div
          style={{
            marginBottom: '4rem',
            overflow: 'hidden',
          }}
        >
          <NextButton
            onClick={async () => {
              await aiLauncherService.applyDefinedData(projectId);

              launchFromContractNavigator.openSelectGroupsPage();
            }}
            key="next-button"
          />
        </div>
        <List
          getDataFunc={async () => verifyDataEntries}
        >
          <GridLayout>
            <Cell label="Detail Box Name">
              <DetailBoxNameField />
            </Cell>
            <Cell label="Defined value">
              <ParsedValueField />
            </Cell>
            <Cell label="Actions">
              <UpdateParseValueButton projectId={projectId} />
              <ShowContractPieceButton />
            </Cell>
          </GridLayout>
        </List>
      </>
    );
  } else {
    return (<LoadingAnimation />);
  }
}
