import React from 'react';
import { BaseInputProps, BaseInputLayoutProps } from './types';
import { useNewUiTheme } from '../../newUiTheme';
import { useInputHelper } from './inputHelper';

export interface DateInputProps extends BaseInputProps<Date> {
  minDate?: Date,
}

export interface DateInputLayoutProps extends BaseInputLayoutProps<Date> {
  minDate?: Date;
}

export function DateInput(props: DateInputProps) {
  const uiTheme = useNewUiTheme();
  const helper = useInputHelper<Date>(props);

  return (
    <uiTheme.inputs.DateInputLayout
      {...helper.getBaseInputLayoutProps()}
      minDate={props.minDate}
    />
  );
}
