import { Person, useAvatarService } from 'nekst-api';

import styles from './AvatarInput.module.scss';
import {
  AfterSubmitFunc,
  BaseInputProps,
  ErrorsWrapper, FormTemplate,
  RelativeRow,
  useFormContext,
  useInputHelper
} from 'ui-builder';
import React, { useRef, useState } from 'react';
import Avatar from 'react-avatar-edit';
import useImageResizer, { ImageResizerOutput } from 'image-resizer';
import {
  ConfirmDeletePopup,
  IconButton, IconType,
  ImageWidget,
  usePopupFormManager
} from 'features/nekst-widgets';

interface AvatarInputProps extends BaseInputProps {
}

interface EditAvatarInputWidgetProps extends AvatarInputProps {
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function EditAvatarInputWidget(props: EditAvatarInputWidgetProps) {
  const [avatar, setAvatar] = useState<string | null>(null);

  const [isValid, setValid] = useState(true);

  const formContext = useFormContext();

  const editor = useRef<Avatar>(null);

  const helper = useInputHelper(props);

  const imageResizer = useImageResizer();

  const resizeFile = async (file: Blob): Promise<string> => {
    return await imageResizer.resizeImage(
      file,
      300,
      300,
      ImageResizerOutput.BASE64,
    ) as string;
  };

  const onCrop = async (preview: string) => {
    const response = await fetch(preview);
    const resized = await resizeFile(await response.blob());

    helper.getOnChangeCallback()({
      target: {
        value: resized,
      },
    });

    setAvatar(resized);
  };

  const setErrors = (errors: Record<string, string[]>) => {
    formContext.__setErrors!(errors);
  };

  const clearErrors = () => {
    setErrors({});
  };

  return (
    <ErrorsWrapper name={props.source}>
      <RelativeRow weights={[6, 6]} cellClassName={styles.cell}>
        <Avatar
          ref={editor}
          width={250}
          height={250}
          onCrop={onCrop}
          exportAsSquare
          exportQuality={1}
          labelStyle={{
            color: '#385b70',
            fontWeight: 'bold',
            cursor: 'pointer',
            fontSize: '2rem',
          }}
          onBeforeFileLoad={(elem) => {
            const fileReader = new FileReader();

            fileReader.onload = () => {
              const img = new Image();

              img.onload = () => {
                const errors = [];

                if (img.width < 300) {
                  errors.push('Please ensure that your image has a width of at least 300 pixels.');
                }

                if (img.height < 300) {
                  errors.push('Please ensure that your image has a height of at least 300 pixels.');
                }

                if (errors.length > 0) {
                  setErrors({
                    file: errors,
                  });
                  setValid(false);
                } else {
                  clearErrors();
                  setValid(true);
                }
              };

              img.src = fileReader.result as string;
            };

            fileReader.readAsDataURL(elem.target.files![0]);
          }}
          onClose={() => {
            setAvatar(null);
            clearErrors();
          }}
        />
        {(avatar && isValid) ? (
          <div>
            <div>
              <img
                src={avatar || undefined}
                alt="Avatar"
                style={{
                  borderRadius: '50%',
                  width: '25rem',
                }}
              />
            </div>
          </div>
        ) : <div />}
      </RelativeRow>
    </ErrorsWrapper>
  );
}

function EditAvatarForm(props: {
  personId: number,
  afterSubmitFunc?: AfterSubmitFunc,
}) {
  const avatarService = useAvatarService();
  return (
    <FormTemplate<{ file: string }>
      title="Upload Profile Photo"
      submitFormFunc={async (data) => {
        await avatarService.uploadAvatar(props.personId, data.file);
        return data;
      }}
      afterSubmitFunc={props.afterSubmitFunc}
    >
      <EditAvatarInputWidget source="file" />
    </FormTemplate>
  );
}

function ConfirmDeleteAvatarPopup(
  props: {
    personId: number,
  },
) {
  const avatarService = useAvatarService();

  return (
    <ConfirmDeletePopup
      deleteFunc={() => avatarService.removeAvatar(props.personId)}
      message="Are you sure you want to delete the photo?"
    />
  );
}

function useAvatarPopups() {
  const popupManager = usePopupFormManager();

  const openUploadForm = (personId: number) => {
    popupManager.openForm(<EditAvatarForm personId={personId} />);
  };

  const openConfirmDeletePopup = (personId: number) => {
    popupManager.openForm(<ConfirmDeleteAvatarPopup personId={personId} />);
  };

  return {
    openUploadForm,
    openConfirmDeletePopup,
  };
}

export default function AvatarInputLayoutWeb(props: {
  person: Person,
}) {
  const existingAvatarUrl = props.person.profile._avatarUrl;

  const form = useAvatarPopups();

  let component;

  if (existingAvatarUrl) {
    component = (
      <>
        <ImageWidget src={existingAvatarUrl.full} alt="Avatar" className={styles.existingAvatar} />
        <IconButton
          onClick={() => form.openConfirmDeletePopup(props.person.id)}
          type={IconType.X}
          className={styles.deleteButton}
          title="Delete Avatar"
        />
      </>
    );
  } else {
    component = (
      <div className={styles.noAvatarBlock}>
        <div className={styles.image}>&nbsp;</div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {component}
      <div className={styles.overlay}>
        <button
          className={styles.uploadPhotoButton}
          type="button"
          onClick={() => form.openUploadForm(props.person.id)}
        >
          &nbsp;
        </button>
      </div>
    </div>
  );
}
