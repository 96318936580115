import React from 'react';
import { SubmitButton } from 'ui-builder';
import useAuthorizationChecker from '../../../../shared/authorization/authorizationChecker';
import Left from '../../../../shared/web/Left';
import CreateDependentTaskButton from './CreateDependentTaskButton';
import Right from '../../../../shared/web/Right';
import { TaskPermission } from '../projectTasksService';
import DeleteTaskButton from './DeleteTaskButton';

export default function UpdateTaskButtons() {
  const { isGranted } = useAuthorizationChecker();

  if (isGranted(TaskPermission.UPDATE)) {
    return (
      <>
        <Left>
          <SubmitButton text="Update task" key="save" />
          <CreateDependentTaskButton />
        </Left>
        <Right>
          <DeleteTaskButton />
        </Right>
      </>
    );
  } else {
    return null;
  }
}
