import React from 'react';
import FormTemplate, { AfterSubmitFunc } from '../../shared/uibuilder/form/FormTemplate';
import Text from '../../shared/uibuilder/form/input/Text';
import { OpenFormIconButton } from 'features/nekst-widgets';
import {
  ProjectRole,
  ProjectRolePermission,
  UpdateProjectRoleRequest,
  useProjectRolesService
} from 'nekst-api';
import { useShowContext } from 'ui-builder';

function UpdateRoleForm(
  props: {
    role: ProjectRole,
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {
  const projectRolesService = useProjectRolesService();

  return (
    <FormTemplate<UpdateProjectRoleRequest, ProjectRole>
      title={`Update role - ${props.role.name}`}
      initialData={{
        name: props.role.name,
      }}
      submitFormFunc={(request) => projectRolesService.updateRole(
        props.role.id,
        request,
      )}
      validationSchema={{
        name: {
          type: 'string',
          constraints: {
            required: true,
            maxLength: 100,
            minLength: 3,
          },
        },
      }}
      afterSubmitFunc={props.afterSubmitFunc}
    >
      <Text source="name" />
    </FormTemplate>
  );
}

export default function UpdateRoleButton() {
  const showContext = useShowContext<ProjectRole>();

  const data = showContext.data!;

  if (data._permissions.includes(ProjectRolePermission.UPDATE)) {
    return (
      <OpenFormIconButton
        form={<UpdateRoleForm role={data} />}
        title="Update Role"
      />
    );
  } else {
    return null;
  }
}
