import { MassCompleteNotPossibleError, useTasksCompletionService } from 'nekst-api';
import { useMassUpdateServiceRequired } from 'ui-builder';
import { ConfirmPositiveActionPopup } from 'features/nekst-widgets';
import React from 'react';
import { useTaskCompletionErrorHandler } from '../Completion';

export function MassCompleteForm() {
  const completeService = useTasksCompletionService();
  const massUpdateService = useMassUpdateServiceRequired();

  const taskCompletionErrorHandler = useTaskCompletionErrorHandler();

  return (
    <ConfirmPositiveActionPopup
      title="Mass Update: Complete"
      message={'Click "Confirm" to complete the Selected Tasks'}
      submitFormFunc={async () => {
        try {
          await completeService.massComplete(massUpdateService.getCheckedIds());
        } catch (e) {
          if (e instanceof MassCompleteNotPossibleError) {
            taskCompletionErrorHandler.openConfirmMassCompletePopup(e.getCauses());
          }
        }
      }}
    />
  );
}
