import React from 'react';
import Button, { ButtonColor, ButtonSize } from '../../../shared/web/button/Button';
import useMeNavigator from '../meNavigator';

export default function OpenUpdateProfilePageButton() {
  const meNavigator = useMeNavigator();
  return (
    <Button
      text="Profile"
      size={ButtonSize.MEDIUM}
      color={ButtonColor.BLUE}
      isFilled
      onClick={meNavigator.openUpdateProfilePage}
    />
  );
}
