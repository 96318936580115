import React, { useEffect } from 'react';
import {
  ContactAssignments,
  ProjectAssignmentType,
  SmsRecipient,
  SmsTask,
} from 'nekst-api';
import { useFormContext } from 'ui-builder';
import useProjectAssignmentsHelper from '../projectAssignmentsHelper';
import AssignmentNameWithRoles from '../../shared/AssignmentNameWithRoles';
import ErrorsWrapper from '../../../../../shared/uibuilder/form/input/layout/ErrorsWrapper';
import SmsTransactionPartiesInputLayout, { Choice } from './SmsTransactionPartiesInputLayout';

function getId(assignment: ContactAssignments) {
  if (assignment.personId) {
    return `person:${assignment.personId}`;
  } else {
    return '';
  }
}

function isAssignable(assignment: ContactAssignments) {
  return assignment.type === ProjectAssignmentType.TASK_ASSIGNMENT;
}

function tryGetId(assignmentId: string, prefix: string) {
  if (assignmentId.startsWith(prefix)) {
    return parseInt(assignmentId.replace(prefix, ''), 10);
  } else {
    return null;
  }
}

function tryGetPersonId(assignmentId: string) {
  return tryGetId(assignmentId, 'person:');
}

function tryGetRoleId(assignmentId: string) {
  return tryGetId(assignmentId, 'role:');
}

function getReferencesObject(assignmentId: string) {
  const result: Record<string, number | null> = {
    personId: null,
    roleId: null,
  };

  if (tryGetPersonId(assignmentId)) {
    result.personId = tryGetPersonId(assignmentId);
  } else if (tryGetRoleId(assignmentId)) {
    result.roleId = tryGetRoleId(assignmentId);
  }

  return result;
}

function findIndex(assignmentId: string, entries: any[]) {

  const searchObject = getReferencesObject(assignmentId);

  return entries.findIndex(
    (assignment) => {
      return (searchObject.personId && assignment.personId === searchObject.personId)
        || (!searchObject.personId
          && searchObject.roleId
          && assignment.roleId === searchObject.roleId
        );
    },
  );
}

export default function SmsTaskAssignmentsInput() {
  const formContext = useFormContext<SmsTask>();

  const { projectAssignments } = useProjectAssignmentsHelper(() => {
    formContext.onChangeCallback!({
      assignments: [],
      recipients: [],
    });
  });

  const taskAssignments = formContext.data!.assignments! || [];

  const smsRecipients = formContext.data!.recipients || [];

  function findAssignmentIndex(assignmentId: string) {
    return findIndex(assignmentId, taskAssignments);
  }

  function findRecipientIndex(assignmentId: string) {
    return findIndex(assignmentId, smsRecipients);
  }

  function isAssigned(assignmentId: string) {
    return findAssignmentIndex(assignmentId) !== -1;
  }

  function isSmsRecipient(assignmentId: string) {
    return findRecipientIndex(assignmentId) !== -1;
  }

  const onAssignmentChangedCallback = (assignmentId: string) => {
    const newValue = [
      ...taskAssignments,
    ];

    const index = findAssignmentIndex(assignmentId);
    if (index !== -1) {
      newValue.splice(index, 1);
    } else {
      newValue.push({
        ...getReferencesObject(assignmentId),
      });
    }

    formContext.onChangeCallback!({
      assignments: newValue,
    });
  };

  const onRecipientChangedCallback = (assignmentId: string) => {
    const newValue: SmsRecipient[] = [
      ...smsRecipients,
    ];

    const index = findRecipientIndex(assignmentId);
    if (index !== -1) {
      newValue.splice(index, 1);
    } else {
      newValue.push({
        ...getReferencesObject(assignmentId),
      });
    }

    formContext.onChangeCallback!({
      recipients: newValue,
    });

  };

  const choices: Choice[] = [];

  projectAssignments
    .sort((a1: ContactAssignments, a2: ContactAssignments) => {
      const weights = {
        [ProjectAssignmentType.TASK_ASSIGNMENT]: 0,
        [ProjectAssignmentType.EDITOR]: 1,
        [ProjectAssignmentType.VIEWER]: 2,
        [ProjectAssignmentType.TRANSACTION_PARTY]: 3,
      };

      return weights[a1.type] - weights[a2.type];
    })
    .forEach(
      (assignment) => {
        const assignmentId = getId(assignment);

        choices.push({
          id: assignmentId,
          name: (<AssignmentNameWithRoles assignment={assignment} />),
          title: assignment.roles.map((r) => r.roleName)
            .join(', '),
          isAssigned: isAssigned(assignmentId),
          isAssignable: isAssignable(assignment),
          isSmsRecipient: isSmsRecipient(assignmentId),
          phoneNumberForSms: assignment.profile._phoneNumberForSms,
        });
      },
    );

  // add to choices roles who which has no assignments
  taskAssignments
    .filter((v) => !v.personId && v.roleId)
    .forEach((v) => {
      const roleId = v.roleId;
      const hasAssignmentWithThisRole = projectAssignments
        .filter((a) => a.type === ProjectAssignmentType.TASK_ASSIGNMENT)
        .find((a) => a.roles.find((r) => r.roleId === roleId));

      if (!hasAssignmentWithThisRole) {
        choices.push({
          id: `role:${roleId}`,
          name: v._role!.name,
          isAssigned: true,
          isAssignable: true,
          isSmsRecipient: isSmsRecipient(`role:${roleId}`),
        });
      }
    });

  useEffect(() => {
    if (projectAssignments.length && !taskAssignments.find((assignment) => !!assignment.personId)) {
      const defaultAssignee = projectAssignments.find((v) => v.isAssignable)!;
      onAssignmentChangedCallback(`person:${defaultAssignee.personId}`);
    }
  }, [taskAssignments.length, projectAssignments.length]);

  return (
    <ErrorsWrapper name="recipients">
      <SmsTransactionPartiesInputLayout
        choices={choices}
        isDisabled={formContext!.isDisabled!()}
        numberOfSelectedOptions={1}
        onAssignmentChangedCallback={onAssignmentChangedCallback}
        onSmsRecipientChangedCallback={onRecipientChangedCallback}
      />
    </ErrorsWrapper>
  );
}
