import React from 'react';
import { ProjectPermission, useAuthorizationChecker } from 'authorization-scope';
import MainBlock from '../../shared/web/layout/MainBlock';
import TextField from '../../shared/uibuilder/show/field/TextField';
import { ProjectTypeField } from 'features/tasks-forms';
import { ProjectBreadcrumb, useProjectContext } from '../view/ProjectContext';
import { ProjectStatus, useProjectsService } from 'nekst-api';
import { AfterSubmitFunc, Dropdown, FormTemplate } from 'ui-builder';
import { usePopupFormManager } from 'features/nekst-widgets';

function UnArchiveProjectForm(
  props: {
    afterSubmitFunc?: AfterSubmitFunc,
  }
) {
  const projectService = useProjectsService();

  const projectContext = useProjectContext();

  return (
    <FormTemplate<{ status: ProjectStatus }>
      title="Unarchive Project"
      submitFormFunc={async (values) => {
        await projectService.setProjectStatus(
          projectContext.data!.id,
          values.status
        );

        return values;
      }}
      initialData={{
        status: ProjectStatus.ACTIVE,
      }}
      validationSchema={{
        status: {
          type: 'string',
          constraints: {
            required: true,
          }
        },
      }}
      afterSubmitFunc={props.afterSubmitFunc}
    >
      <Dropdown
        options={[
          {
            value: ProjectStatus.ACTIVE,
            label: 'Active',
          },
          {
            value: ProjectStatus.CLOSED,
            label: 'Closed',
          }
        ]}
        source="status"
      />
    </FormTemplate>
  );

}

export default function ProjectIsArchivedMessage() {

  const formPopupManager = usePopupFormManager();

  const link = (
    /* eslint-disable jsx-a11y/anchor-is-valid */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    <a
      onClick={() => {
        formPopupManager.openForm(<UnArchiveProjectForm />);
      }}
    >click here</a>
  );

  const { isGranted } = useAuthorizationChecker();

  let content;

  if (isGranted(ProjectPermission.UPDATE)) {
    content = (
      <div>
        This transaction is in an Archived status. To un-archive the transaction,
        &nbsp;
        {link}
      </div>
    );
  } else {
    content = (
      <div>
        This transaction is in Archived status. The update transaction feature is unavailable
        because you have reached the transaction limit. If you would like to access the project
        data,
        you may consider upgrading your team&apos;s subscription to a plan that better suits
        your needs.
      </div>
    );
  }

  return (
    <MainBlock
      title={<TextField source="name" />}
      titleLine2={<ProjectTypeField />}
    >
      <ProjectBreadcrumb />
      {content}
    </MainBlock>
  );

}
