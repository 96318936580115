import {
  ButtonColor,
  ButtonSize,
  IconType,
  ButtonProps,
  OnClickFunc,
  Button,
} from 'ui-builder';

export { ButtonColor, ButtonSize, IconType };
export type { ButtonProps, OnClickFunc };

export default Button;
