import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonColor,
  ButtonSize,
  IconType,
  OptionSelectionButtonLayoutProps,
  usePopupManager
} from 'ui-builder';
import { Arrow, useHover, useLayer } from 'react-laag';
import styles from './OptionSelectionButtonLayout.module.scss';

export default function OptionSelectionButtonLayout(
  props: OptionSelectionButtonLayoutProps,
) {
  const [isOpen, hoverProps] = useHover(
    {
      delayLeave: 50,
      hideOnScroll: true,
    },
  );

  const [isInPopup, setInPopup] = useState<boolean>(false);
  const popupManager = usePopupManager();

  const {
    renderLayer,
    triggerProps,
    layerProps,
    arrowProps,
  } = useLayer({
    isOpen,
    placement: 'bottom-end',
    overflowContainer: false,
    triggerOffset: 10,
    containerOffset: 12,
    auto: true,
    possiblePlacements: [
      'bottom-start',
      'bottom-end',
      'bottom-center',
      'top-start',
      'top-center',
      'top-end',
    ],
  });

  const size = props.size || ButtonSize.BIG;

  useEffect(() => {
    setInPopup(popupManager.isOpen());
  }, []);

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div
      className={`${styles.dropdown} ${size}`}
      {...hoverProps}
    >
      <Button
        text={`${props.text}`}
        size={size}
        isFilled
        color={ButtonColor.BLUE}
        iconType={IconType.ARROW_DOWN}
        elementRef={triggerProps.ref}
        dataKey={props.dataKey}
      />
      {isOpen && renderLayer(
        <div
          {...layerProps}
          className={`${styles.contentWrapper} ${isInPopup ? styles.inPopup : ''}`}
        >
          <div
            className={styles.content}
          >
            {props.options.map((option) => {
              return (
                <button
                  key={`option-${option.value}`}
                  type="button"
                  onClick={() => option.onSelected()}
                  disabled={option.disabled}
                  title={option.title}
                >
                  {option.label}
                </button>
              );
            })}
          </div>
          <Arrow onPointerEnterCapture={undefined}
                 onPointerLeaveCapture={undefined}
                 {...arrowProps}
          />
        </div>,
      )}
    </div>
  );
}
