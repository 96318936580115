import { useEventsSubscriber } from 'event-bus';
import { useAuthenticationContext } from '../Authentication/AuthenticationContext';
import { LOGGED_OUT } from './logoutService';
import { useApiConfiguration } from './ApiConfigurationContext';

type CacheConfiguration = {
  expiresAt?: number,
}

export class CacheItemManager {
  _expiresAt?: Date;

  // eslint-disable-next-line class-methods-use-this
  _createExpireDate(seconds: number): Date {
    const date = new Date();
    date.setSeconds(date.getSeconds() + seconds);
    return date;
  }

  setExpireInSeconds(value: number) {

    this._expiresAt = this._createExpireDate(value);
  }

  getCacheConfiguration(): CacheConfiguration {
    const expiresAt = this._expiresAt
      ? this._expiresAt.getTime()
      : this._createExpireDate(60)
        .getTime();
    return {
      expiresAt,
    };
  }
}

type CacheConfig<T> = {
  value: T,
  config: CacheConfiguration,
  userId?: number,
};

export interface CacheService {
  get: <T = any> (key: string, func: (cacheItem: CacheItemManager) => Promise<T>) => Promise<T>,
  clear: (key: string) => void,
}

export function useCacheService(cacheName: string): CacheService {

  const apiConfiguration = useApiConfiguration();

  const cacheRepository = apiConfiguration.cacheRepository;

  const authenticationContext = useAuthenticationContext();

  const user = authenticationContext?.user;

  const getKey = (key: string) => `${cacheName}-${key}`;

  useEventsSubscriber(
    'cacheService',
    {
      [LOGGED_OUT]: () => localStorage.clear(),
    },
  );

  const set = async (key: string, value: any, config: CacheConfiguration) => {
    const cacheValue = {
      value,
      config,
      userId: user?.id,
    };

    await cacheRepository.set(getKey(key), JSON.stringify(cacheValue));
  };

  async function get<T = any>(key: string, func: (cacheItem: CacheItemManager) => Promise<T>) {

    const value = await cacheRepository.get(getKey(key));

    if (value != null) {
      const item = JSON.parse(value) as CacheConfig<T>;

      const currentDate = new Date();

      const isActual = !item.config.expiresAt || currentDate.getTime() < item.config.expiresAt;

      const belongsToCurrentUser = user && item.userId === user?.id;

      if (isActual && belongsToCurrentUser) {
        return item.value;
      } else {
        const cacheItem = new CacheItemManager();
        const newValue = await func(cacheItem);
        await set(key, newValue, cacheItem.getCacheConfiguration());

        return newValue as T;
      }
    } else {
      const cacheItem = new CacheItemManager();
      const newValue = await func(cacheItem);
      await set(key, newValue, cacheItem.getCacheConfiguration());

      return newValue as T;
    }
  }

  const clear = async (key: string) => {
    await cacheRepository.remove(getKey(key));
  };

  return {
    get,
    clear,
  };
}
