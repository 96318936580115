import React from 'react';
import {
  AfterSubmitFunc,
  AsyncMultiSelectDropdown,
  FormTemplate,
  LoadingAnimation,
  TextInput
} from 'ui-builder';
import {
  CreateDetailsSetFieldRequest,
  DetailsFieldsSet, FileType, ProjectDetailsField,
  ProjectType,
  useDetailsFieldsSetsService,
  useProjectDetailsFieldsService
} from 'nekst-api';
import { useDataLoaderNew } from 'data-loader';
import FileUploaderInput from '../../../../shared/file/FileUploaderInput';

function DetailsFieldsMultiDropdown(props: {
  projectType: ProjectType,
}) {
  const detailsFieldsService = useProjectDetailsFieldsService();

  return (
    <AsyncMultiSelectDropdown
      loadOptionsFunc={async () => {
        return (await detailsFieldsService.getFields(props.projectType))
          .map((field) => ({
            value: field.id,
            label: field.name
          }))
      }}
      source="includedFieldsIds"
      label="Include Boxes"
    />
  )
}

export default function CreateDetailsFieldsSetForm(
  props: {
    afterSubmitFunc?: AfterSubmitFunc,
    projectType: ProjectType
  }
) {
  const setsService = useDetailsFieldsSetsService();

  const detailsFieldsService = useProjectDetailsFieldsService();


  const { data: fields } = useDataLoaderNew<ProjectDetailsField[]>(
    () => detailsFieldsService.getFields(props.projectType),
  );

  if (fields) {
    return (
      <FormTemplate<CreateDetailsSetFieldRequest, DetailsFieldsSet>
        title="Create Detail Box Set"
        submitFormFunc={async (data) => {
          return setsService.createSet(data);
        }}
        afterSubmitFunc={props.afterSubmitFunc}
        initialData={{
          projectType: props.projectType,
          includedFieldsIds: fields.map((item) => item.id),
        }}
        validationSchema={{
          name: {
            type: 'string',
            constraints: {
              required: true,
              maxLength: 40,
            },
          },
          includedFieldsIds: {
            type: 'array',
            constraints: {
              required: true,
            },
          },
        }}
      >
        <TextInput source="name" label="Name" />
        <DetailsFieldsMultiDropdown projectType={props.projectType} />
        <FileUploaderInput
          fileType={FileType.PROJECT_DOCUMENT}
          fileDataSource="_contractDocumentExampleFile"
          source="contractDocumentExampleFileId"
          label="Contract Document Exmaple"
          hint="A contract document example is utilized for training AI systems to comprehend the content and structure of contractual agreements."
        />
      </FormTemplate>
    );
  } else {
    return (<LoadingAnimation />);
  }
}
