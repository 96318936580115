
import React from 'react';
import { BaseInputLayoutProps, BaseInputProps } from './types';
import { useInputHelper } from './inputHelper';
import { useNewUiTheme } from '../../newUiTheme';

export interface PriceInputProps extends BaseInputProps<number> {

}

export interface PriceLayoutProps extends BaseInputLayoutProps<number> {

}

export function PriceInput(props: PriceInputProps) {
  const helper = useInputHelper<number>(props);
  const uiTheme = useNewUiTheme();

  return (
    <uiTheme.inputs.PriceLayout
      {...helper.getBaseInputLayoutProps()}
    />
  );
}
