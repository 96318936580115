import { useBackendApi } from "./backendApi";
import { useLogoutService } from "./logoutService";
import { BadRequestError } from 'request-sender';

export class HasActiveIosSubscriptionError extends Error {
  constructor() {
    super();

    Object.setPrototypeOf(this, HasActiveIosSubscriptionError.prototype);
  }

  getMessage() {
    return this.message;
  }
}

const IOS_SUBSCRIPTION_ACTIVE_CODE = 'IOS_SUBSCRIPTION_IS_ACTIVE';

export function useDeleteAccountService() {
  const nekstApi = useBackendApi();

  const logoutService = useLogoutService();

  const deleteAccount = async () => {
    try {
      await nekstApi.delete('/me');
      await logoutService.logout();
    } catch (e) {
      if (e instanceof BadRequestError) {
        if (e.getResponseField<string>('code', '') === IOS_SUBSCRIPTION_ACTIVE_CODE) {
          throw new HasActiveIosSubscriptionError();
        } else {
          throw e;
        }
      } else {
        throw e;
      }
    }
  };

  return {
    deleteAccount,
  };
}
