import React from 'react';
import { BlockLayoutProps } from 'ui-builder';

export default function CenterLayout(props: BlockLayoutProps) {
  return (
    <div style={{ textAlign: 'center' }} className={props.className}>
      {props.children}
    </div>
  );
}
