import { PersonParseResult, PromptParseResult } from 'nekst-api';

export function getLineTitle(
  data: PersonParseResult | PromptParseResult,
) {
  if ('firstName' in data) {
    return {
      type: 'TRANSACTION_PARTY',
      data
    };
  } else {
    const taskParseResult = data as PromptParseResult;

    return {
      type: 'DETAILS_FIELD',
      data: {
        id: taskParseResult.id,
        type: taskParseResult.type,
        prompt: taskParseResult.prompt,
        value: taskParseResult.value,
      }
    };
  }
}
