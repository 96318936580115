import { useBackendApi } from './backendApi';
import { useEventsPublisher } from 'event-bus';

export interface MassDeleteService {
  massDelete: (ids: number[]) => Promise<void>,
}

export const TASKS_MASS_DELETED = 'TASKS_MASS_DELETED';

export interface TasksMassDeletedEvent {
  ids: number[],
}

export function useProjectTasksMassDeleteService(): MassDeleteService {
  const projectsApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const massDelete = async (ids: number[]) => {
    await projectsApi.put('/tasks/bulk/delete', {
      ids,
    });

    eventsPublisher.publish<TasksMassDeletedEvent>(TASKS_MASS_DELETED, {
      ids,
    });
  };

  return {
    massDelete,
  };
}
