import { ReactNode } from 'react';

export enum MessageType {
  WARNING = 'warning',
  INFO = 'info',
  ERROR = 'error',
}

export interface InformationMessageLayoutProps {
  children: ReactNode
  type: MessageType
  className?: string
  withoutMargin?: boolean
}

export interface InformationMessageProps {
  children: ReactNode
  className?: string
  withoutMargin?: boolean
}
