import React from 'react';
import { useParams } from 'react-router-dom';
import { ButtonColor, SubmitButton, } from 'ui-builder';
import LaunchProjectSteps, { LaunchProjectStepId } from '../LaunchProjectSteps';

import styles from './CreateProjectPage.module.scss';
import UpdateProjectForm from './UpdateProjectForm';
import useLaunchProjectNavigator from '../launchProjectNavigator';
import useFeatureToggleService from 'feature-toggle';

export default function UpdateProjectPage() {
  const params = useParams();

  const projectId = parseInt(params.id!, 10);

  const navigator = useLaunchProjectNavigator();

  const featureToggleService = useFeatureToggleService();

  return (
    <>
      <LaunchProjectSteps currentStep={LaunchProjectStepId.DETAILS} />
      <UpdateProjectForm
        className={styles.page}
        buttons={
          <SubmitButton text="Next  &nbsp; >" isFilled color={ButtonColor.BLUE} key="submit" />
        }
        afterSubmitFunc={async () => {
          if (featureToggleService.isTasksGroupsEnabled()) {
            navigator.openGroupsPage();
          } else {
            navigator.openKeyDateTasksPage();
          }
        }}
        projectId={projectId}
      />
    </>
  );
}
