import React from 'react';
import InformationBox from '../../../../shared/web/layout/InformationBox';
import { PreviousButton } from '../../Buttons';
import { PopupContext, RelativeRow, Right } from 'ui-builder';
import {
  AddPersonToTransactionForm,
  LaunchProjectButton,
} from 'features/tasks-forms';
import CurrentTransactionPartiesForm
  from '../../CurrentTransactionPartiesForm/CurrentTransactionPartiesForm';
import useLaunchFromContractNavigator from '../launchFromContractNavigator';
import { useParams } from 'react-router-dom';
import LaunchFromContractSteps, { LaunchFromContractStepId } from '../LaunchFromContractSteps';
import { ShowLaunchPopups } from './ProcessIdentifiedContactsPopups';

  import styles from '../../AddTransactionParties.module.scss';

export default function TransactionPartiesPage() {

  const params = useParams();
  const getProjectId = (projectId?: number) => {
    return projectId || parseInt(params.id!, 10);
  };

  const navigator = useLaunchFromContractNavigator();

  return (
    <PopupContext>
      <InformationBox
        text="Assign Team Members To Roles with tasks. Add other parties to the transaction. Then Complete Setup!"
      />
      <LaunchFromContractSteps currentStep={LaunchFromContractStepId.TRANSACTION_PARTIES} />
      <div
        style={{
          marginBottom: '4rem',
          overflow: 'hidden',
        }}
      >
        <PreviousButton onClick={navigator.openAllTasksPage} />
        <Right>
          <LaunchProjectButton
            projectId={getProjectId()}
            openProjectPageFunc={navigator.openProjectPage}
          />
        </Right>
      </div>
      <RelativeRow weights={[6, 6]}>
        <AddPersonToTransactionForm
          projectId={getProjectId()}
          className={styles.block}
        />
        <CurrentTransactionPartiesForm projectId={getProjectId()} />
      </RelativeRow>
      <ShowLaunchPopups projectId={getProjectId()} />
    </PopupContext>
  );
}
