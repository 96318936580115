export enum ButtonState {
  DEFAULT ='default',
  LOADING = 'loading',
  REQUESTED = 'requested',
  ERROR = 'error',
  LOCKED = 'locked',
}

export interface RequestOptInButtonLayoutProps {
  href?: string,
  onClick?: () => Promise<void>,
  state: ButtonState,
}
