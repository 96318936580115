import React from 'react';
import {
  isAllTasksPage,
} from './nameFilterService';

import { ViewStyle } from 'react-native';
import { TextFilterInput } from 'features/nekst-widgets';

export function ProjectTasksNameFilter(
  props: {
    style?: ViewStyle,
  },
) {
  const placeholder = isAllTasksPage()
    ? 'Enter Task or Transaction Name'
    : 'Enter Task Name';

  return (
    <TextFilterInput
      source="nameContains"
      placeholder={placeholder}
      style={props.style}
      testID="name-filter"
    />
  );
}
