import React from 'react';
import { useShowContext } from 'ui-builder';
import { Person, TeamRole } from 'nekst-api';
import usePeopleNavigator from '../peopleNavigator';
import LinkField from '../../shared/uibuilder/show/field/LinkField';

export default function PersonLinkField() {
  const showContext = useShowContext<Person>();

  const peopleNavigator = usePeopleNavigator();
  const person = showContext.data!;
  let fullName = person.profile.name.fullName;
  if (fullName.trim().length === 0) {
    fullName = 'N/A';
  }

  let role;
  if (person.teamRole === TeamRole.OWNER) {
    role = 'Team Owner';
  } else if (person.teamRole === TeamRole.ADMINISTRATOR) {
    role = 'Team Administrator';
  } else if (person.teamRole === TeamRole.MEMBER) {
    role = 'Team Member';
  } else if (person._serviceProviders.length > 0) {
    role = `Service Provider: ${person._serviceProviders.map((sp) => sp.name).join(', ')}`;
  }

  if (role) {
    fullName += ` (${role})`;
  }

  return (
    <LinkField
      uri={peopleNavigator.getViewPersonPageLink(person.id)}
      onClick={() => peopleNavigator.openViewPersonPage(person.id)}
      value={fullName}
    />
  );
}
