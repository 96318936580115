import { ProjectType, ShortProfile, useBackendApi, useEventsPublisher } from 'nekst-api';

export type DefaultPortalNoteRequest = {
  text: string,
}

export enum DefaultPortalNotePermission {
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

export type DefaultPortalNote = {
  id: number
  text: string,
  createdAt: Date,
  authorId: number,
  _author: ShortProfile
  permissions: DefaultPortalNotePermission[]
}

export const DEFAULT_NOTES_UPDATED = 'DEFAULT_NOTES_UPDATED';

export default function useDefaultPortalNotesService() {
  const backendApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const getAllNotes = async (type: ProjectType) => {
    const result = await backendApi.get('/settings/clientportal/' + type + '/notes') as DefaultPortalNote[];

    return result.map(note => {
      return {
        ...note,
        permissions: [
          DefaultPortalNotePermission.UPDATE,
          DefaultPortalNotePermission.DELETE
        ]
      }
    });
  };

  const _publishEvent = () => {
    eventsPublisher.publish(DEFAULT_NOTES_UPDATED, {});
  }

  const createNote = async (type: ProjectType, note: DefaultPortalNoteRequest) => {
    const result = await backendApi.post('/settings/clientportal/' + type + '/notes', note) as DefaultPortalNote;
    _publishEvent();

    return result;
  };

  const updateNote = async (noteId: number, note: DefaultPortalNoteRequest) => {
    const result = await backendApi.put('/settings/clientportal/notes/' + noteId, note) as DefaultPortalNote;
    _publishEvent();

    return result;
  };

  const deleteNote = async (noteId: number) => {
    await backendApi.delete('/settings/clientportal/notes/' + noteId);
    _publishEvent();
  }

  return {
    getAllNotes,
    createNote,
    updateNote,
    deleteNote,
  };
}
