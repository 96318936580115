import { useNavigate } from 'react-router-dom';
import { ProjectType } from 'nekst-api';

export default function useSettingsNavigator() {
  const navigate = useNavigate();
  const getCustomRolesLink = () => '/settings/roles';

  const getProfileLink = () => '/settings/profile';

  const getUpdatePasswordPageLink = () => '/settings/password';

  const getBillingPageLink = () => '/settings/billing';

  const getProjectDetailsLink = (projectType: ProjectType) => `/settings/detailboxes/${projectType.toLowerCase()}`;

  const openUpdateProfilePage = () => navigate(getProfileLink());

  const openUpdatePasswordPage = () => navigate(getUpdatePasswordPageLink());

  const openBillingPage = () => navigate(getBillingPageLink());

  const openCustomRolesList = () => navigate(getCustomRolesLink());

  const isBillingPage = () => window.location.pathname.startsWith(getBillingPageLink());

  const openProjectDetailsFieldPage = (projectType: ProjectType) => navigate(
    getProjectDetailsLink(projectType),
  );

  const getDetailsSetLink = (projectType: ProjectType, setId: number) => `/settings/detailboxes/${projectType.toLowerCase()}/${setId}`;

  const openDetailsSetPage = (projectType: ProjectType, setId: number) => navigate(getDetailsSetLink(projectType, setId));

  const getClientPortalSettingsLink = () => '/settings/clientportal';

  const openClientPortalSettingsPage = () => navigate(getClientPortalSettingsLink());

  const getServiceProvidersLink = () => '/settings/serviceproviders';

  const openServiceProvidersPage = () => navigate(getServiceProvidersLink());

  const getImpersonateUserLink = () => '/admin/users';

  const openImpersonateUserLinkPage = () => navigate(getImpersonateUserLink());

  const getDocumentLabelsLink = () => '/settings/documentlabels';

  const openDocumentLabelsPage = () => navigate(getDocumentLabelsLink());

  const getDocumentsTemplatesLink = () => '/settings/documentstemplates';

  const openDocumentsTemplatesPage = () => navigate(getDocumentsTemplatesLink());

  const getNotificationsSettingsLink = () => '/settings/notifications';

  const openNotificationsSettingsPage = () => navigate(getNotificationsSettingsLink());


  const getDefaultPortalConfigurationLink = (projectType: ProjectType) => `/settings/clientportal/${projectType.toLowerCase()}/default`;

  const getMembersManagementLink = () => '/settings/members';

  const openMembersManagementPage = () => navigate(getMembersManagementLink());

  const openUpdateMemberPage = (id: number) => navigate(`${getMembersManagementLink()}/${id}`);

  const getTasksGroupsManagementLink = () => '/settings/tasksgroups';

  const openTasksGroupsManagementPage = () => navigate(getTasksGroupsManagementLink());

  const getAiTrainingLink = () => '/settings/aitraining';

  const openAiTrainingPage = () => navigate(getAiTrainingLink());

  const getAiTemplateManagingLink = (templateId: number) => `/settings/aitraining/${templateId}`;

  const openAiTemplateManagingPage = (templateId: number) => navigate(getAiTemplateManagingLink(templateId));

  return {
    getProfileLink,
    getUpdatePasswordPageLink,
    getCustomRolesLink,
    getBillingPageLink,
    getProjectDetailsLink,
    openUpdateProfilePage,
    openUpdatePasswordPage,
    openBillingPage,
    openCustomRolesList,
    openProjectDetailsFieldPage,
    isBillingPage,
    getClientPortalSettingsLink,
    openClientPortalSettingsPage,
    getServiceProvidersLink,
    openServiceProvidersPage,
    getImpersonateUserLink,
    openImpersonateUserLinkPage,
    getDocumentLabelsLink,
    openDocumentLabelsPage,
    getDocumentsTemplatesLink,
    openDocumentsTemplatesPage,
    getDefaultPortalConfigurationLink,
    getNotificationsSettingsLink,
    openNotificationsSettingsPage,
    getMembersManagementLink,
    openMembersManagementPage,
    openUpdateMemberPage,
    getTasksGroupsManagementLink,
    openTasksGroupsManagementPage,
    getAiTrainingLink,
    openAiTrainingPage,
    getAiTemplateManagingLink,
    openAiTemplateManagingPage,
    getDetailsSetLink,
    openDetailsSetPage,
  };
}
