import { useCallback, useEffect, useState } from 'react';
import { useEventsPublisher } from 'event-bus';
import { NOTIFICATIONS_UPDATE_INTERVAL_TRIGGER_EVENT } from 'features/tasks-forms';

let interval: NodeJS.Timeout | null = null;

export default function UnreadCountUpdater() {

  const UPDATE_INTERVAL_SECONDS = 60;

  const [windowIsActive, setWindowIsActive] = useState(true);

  const onFocus = useCallback(() => {
    setWindowIsActive(true);
  }, []);

  const onBlur = useCallback(() => {
    setWindowIsActive(false);
  }, []);

  const eventsPublisher = useEventsPublisher();

  useEffect(() => {
    if (windowIsActive) {
      interval = setInterval(() => {
        eventsPublisher.publish(NOTIFICATIONS_UPDATE_INTERVAL_TRIGGER_EVENT, {});
      }, UPDATE_INTERVAL_SECONDS * 1000);
    } else {
      clearInterval(interval!);
      interval = null;
    }

    return () => {
      if (interval !== null) {
        clearInterval(interval);
      }
    };
  }, [windowIsActive]);

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return null;
}
