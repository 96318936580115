import React from 'react';
import { FormLayoutProps } from './types';
import { useNewUiTheme } from '../newUiTheme';

interface FormLayoutHelperProps extends FormLayoutProps {
  layout?: ReactComponent<FormLayoutProps>;
}

export function FormLayout(
  props: FormLayoutHelperProps
) {
  const uiTheme = useNewUiTheme();

  const Layout = props.layout || uiTheme.forms.FormLayout;

  return (
    <Layout {...props}>
      {props.children}
    </Layout>
  );
}
