import React, { ReactElement } from 'react';

import { AbstractTask, TaskType } from 'nekst-api';
import {
  EmailField,
  EmailRecipientsField,
  SmsField,
  SmsRecipientsField,
  TaskAssignmentsField
} from 'features/tasks-list-feature';
import { DateField, If, usePopupManager } from 'ui-builder';
import { Arrow, useHover, useLayer } from 'react-laag';
import styles from './TaskPopover.module.scss';
import TextField from '../shared/uibuilder/show/field/TextField';
import Separator from '../shared/web/uitheme/form/Separator';
import IsKeyDateField from '../plans/tasks/List/IsKeyDateField';
import DeleteTaskButton from './DeleteTaskButton';
import OpenTaskButton from './OpenTaskButton';
import { CompleteTaskButton, SendEmailButton, SendSmsButton } from 'features/tasks-forms';
import TasksGroupField from '../plans/tasks/List/TasksGroupField';

export default function TaskPopover() {

  return (
    <div className={styles.popover}>
      <div className={styles.popoverTitle}>
        <EmailField />
        <SmsField />
        <TextField source="name" className={styles.popoverTitle} />
      </div>
      <div className={styles.popoverTitleLine2}>
        <TextField source="_project.name" />
      </div>
      <Separator />
      <If<AbstractTask>
        conditionFunc={
          (data) => [TaskType.EMAIL, TaskType.SMS].includes(data.type)
        }
      >
        <div
          className={styles.row}
          style={{
            float: 'right',
            transform: 'translateY(-1.2rem)',
          }}
        >
          <SendSmsButton />
          <SendEmailButton />
        </div>
      </If>
      <div className={styles.row}>
        <span className={styles.label}>Due:</span>
        <DateField source="dueDate.date" />
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Assigned to:</div>
        <TaskAssignmentsField source="assignments" />
      </div>
      <If<AbstractTask>
        conditionFunc={
          (data) => [TaskType.EMAIL, TaskType.SMS].includes(data.type)
        }
      >
        <div className={styles.row}>
          <div className={styles.label}>Recipients:</div>
          <EmailRecipientsField />
          <SmsRecipientsField />
        </div>
      </If>
      <div className={styles.iconsRow}>
        <If conditionFunc={(data) => data.isKeyDate}>
          <div className={`${styles.keyDate}`}>
            <IsKeyDateField source="isKeyDate" />
          </div>
        </If>
        <TasksGroupField />
      </div>
      <div className={styles.buttons}>
        <CompleteTaskButton />
        <DeleteTaskButton />
        <OpenTaskButton />
      </div>
    </div>
  );
}

export function TaskPopoverWrapper(
  props: {
    children: ReactElement,
    className?: string
  },
) {
  const [isOpen, hoverProps] = useHover({
    delayLeave: 50,
    hideOnScroll: true,
  });

  const popupManager = usePopupManager();

  const {
    renderLayer,
    triggerProps,
    layerProps,
    arrowProps,
  } = useLayer({
    isOpen,
    placement: 'left-center',
    overflowContainer: false,
    triggerOffset: 10,
    containerOffset: 12,
    auto: true,
    possiblePlacements: [
      'left-center',
      'left-start',
      'left-end',
      'right-center',
    ],
  });


  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      style={{
        zIndex: 10,
      }}
      className={styles.wrapper}
      {...hoverProps}
      ref={triggerProps.ref}
      onMouseDown={(event) => event.stopPropagation()}
    >
      {props.children}
      {(isOpen && !popupManager.isOpen()) && renderLayer(
        <div {...layerProps}>
          <TaskPopover />
          <Arrow onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} {...arrowProps} />
        </div>,
      )}
    </div>
  );
}
