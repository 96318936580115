import React from 'react';

import {
  CheckboxInput, RelativeRow,
} from 'ui-builder';
import { useAuthorizationChecker } from 'authorization-scope';
import { TeamPermission } from 'nekst-api';

export type TypeFilterValue = {
  showStandardTasks: boolean,
  showEmailTasks: boolean,
  showSmsTasks: boolean,
  showKeyDatesOnly: boolean
}

export function TypeFilterForm() {
  const { isGranted } = useAuthorizationChecker();

  return (
    <>
      <h4>OPTIONS</h4>
      <RelativeRow weights={[6, 6]}>
        <CheckboxInput source="typeFilter.showStandardTasks" />
        {
          isGranted(TeamPermission.USE_SMS_TASKS_FEATURE)
            ? (
              <CheckboxInput
                source="typeFilter.showSmsTasks"
                label="Show SMS Tasks"
              />
            )
            : null
        }
      </RelativeRow>
      <RelativeRow weights={[6, 6]}>
        <CheckboxInput source="typeFilter.showEmailTasks" label="Show Email Tasks" />
        <CheckboxInput source="typeFilter.showKeyDatesOnly" />
      </RelativeRow>
    </>
  );
}
