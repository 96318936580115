import React, { useState } from 'react';
import { ListDataRefresher } from 'ui-builder';
import { PersonPermission } from 'nekst-api';
import CommentsList from '../../shared/widgets/CommentsList/CommentsList';
import usePersonNotesService, {
  PERSON_NOTES_UPDATED,
  PersonNote,
  PersonNoteRequest,
} from './personNotesService';
import useAuthorizationChecker from '../../shared/authorization/authorizationChecker';
import OverflowHidden from '../../shared/widgets/OverflowHidden';
import Right from '../../shared/web/Right';
import Button, { ButtonColor, ButtonSize, IconType } from '../../shared/web/button/Button';
import AddCommentForm from '../../shared/widgets/CommentsList/AddCommentForm';

function Title(props: { personId: number }) {
  const [showForm, setShowForm] = useState<boolean>();

  const personNotesService = usePersonNotesService();

  const { isGranted } = useAuthorizationChecker();

  return (
    <>
      <OverflowHidden>
        <Right>
          {isGranted(PersonPermission.CREATE_NOTE) && (
            <Button
              text="Add"
              size={ButtonSize.SMALL}
              color={ButtonColor.BLUE}
              isFilled
              onClick={() => {
                setShowForm((prev) => !prev);
              }}
              iconType={IconType.PLUS}
            />
          )}
        </Right>
        <h3>Notes</h3>
      </OverflowHidden>
      {showForm && (
        <AddCommentForm<PersonNote, PersonNoteRequest>
          submitFunc={async (data: PersonNoteRequest) => {
            return await personNotesService.createNote(props.personId, data) as PersonNote;
          }}
          afterSubmitFunc={async () => {
            setShowForm(false);
          }}
          closeFormFunc={() => setShowForm(false)}
        />
      )}
    </>
  );
}

export default function PersonNotesList(props: {
  personId: number
}) {

  const personNotesService = usePersonNotesService();
  return (
    <CommentsList
      getDataFunc={() => personNotesService.getPersonNotes(props.personId)}
      title={<Title personId={props.personId} />}
      deleteItemFunc={(id: number) => personNotesService.deleteNote(id)}
      updateItemFunc={(id, data) => personNotesService.updateNote(id, data)}
      deletePermission="DELETE"
      updatePermission="UPDATE"
      messages={{
        textInputLabel: 'Enter Note Here',
        noDataText: 'There are no notes related to the person.',
      }}
    >
      <ListDataRefresher eventName={PERSON_NOTES_UPDATED} />
    </CommentsList>
  );
}
