import React, { useState } from 'react';
import _ from 'lodash';
import { Dropdown } from './Dropdown';
import { BaseInputProps, Option } from './index';
import useDataLoader from 'data-loader';
import { LoadingAnimation } from '../../Common';

interface Props extends BaseInputProps<number> {
  getDataFunc: () => Promise<{ [key: string]: any }[]>;
  onOptionChanged?: (option: Option | null) => void,
  idField?: string;
  labelField?: string;
  source: string
}

export function AsyncDropdown(props: Props) {
  const idField = props.idField || 'id';
  const labelField = props.labelField || 'name';

  const [data, setData] = useState<{ [key: string]: any }[]>();

  useDataLoader(
    props.getDataFunc,
    setData,
  );

  if (data) {
    const options = data.map((v) => ({
      value: v[idField] as number,
      label: v[labelField] as string,
      _data: v,
    }));

    return (
      <Dropdown
        options={options}
        {..._.omit(props, 'idField', 'labelField')}
        source={props.source}
        placeholder={options.length ? props.placeholder : 'Loading...'}
      />
    );
  } else {
    return (<LoadingAnimation />)
  }
}
