import React, { useRef } from 'react';
import DataLoader from '../../../../shared/DataLoader';
import ErrorsWrapper from '../../../../shared/uibuilder/form/input/layout/ErrorsWrapper';
import styles from '../TransactionParties.module.scss';
import RelativeRow, { Cell, EmptyCell } from '../../../../shared/web/uitheme/form/RelativeRow';
import BriefcaseCheckbox from '../BriefcaseCheckbox';
import Checkbox from '../../../../shared/uibuilder/form/input/Checkbox';
import useTransactionPartiesField from '../transactionPartiesField';
import { useFormContext } from 'ui-builder';
import { PlanTask, ProjectRole, useProjectRolesService } from 'nekst-api';

function useSmsRecipientsField() {
  const formContext = useFormContext<PlanTask>();

  const isRoleAssigned = (id: number) => {
    const currentValue = formContext.data?.smsRecipients || [];
    return !!currentValue.find((item) => item.projectRoleId === id);
  };

  const changeValue = (id: number) => {
    let newValue = formContext.data?.smsRecipients || [];
    if (!isRoleAssigned(id)) {
      newValue.push({
        projectRoleId: id,
        _projectRole: {
          id,
          name: '',
        }
      });
    } else {
      newValue = newValue.filter((item) => item.projectRoleId !== id);
    }

    formContext.onChangeCallback!({
      smsRecipients: newValue,
    });
  };

  return {
    isRoleAssigned,
    changeValue,
  };
}

function SmsRecipientsInputWidget(props: { projectRoles: ProjectRole[], label?: string }) {
  const ref = useRef<HTMLDivElement>(null);

  const transactionPartiesField = useTransactionPartiesField(props.projectRoles);
  const smsRecipientsField = useSmsRecipientsField();

  return (
    <ErrorsWrapper name="smsRecipients">
      <ErrorsWrapper name="transactionParties">
        <div className={styles.block} ref={ref}>
          <div className={styles.title}>
            {props.label}
          </div>
          <div className={styles.table}>
            <RelativeRow
              weights={[6, 3, 3]}
              rowClassName={styles.tableHead}
              cellClassName={styles.cell}
            >
              <Cell>
                Assigned To:
              </Cell>
              <EmptyCell />
              <Cell centered>
                Receive SMS:
              </Cell>
            </RelativeRow>
            <div className={styles.tableBody}>
              {props.projectRoles.map(
                (projectRole) => {
                  return (
                    <RelativeRow
                      key={`projectRole-${projectRole.id}`}
                      weights={[6, 3, 3]}
                      rowClassName={styles.row}
                      cellClassName={styles.cell}
                    >
                      {projectRole.isAssignable ? (
                        <BriefcaseCheckbox
                          source={`assigned.${projectRole.id}`}
                          label={projectRole.name}
                          value={transactionPartiesField.isRoleAssigned(projectRole.id)}
                          onChangeCallback={() => {
                            transactionPartiesField.changeValue(projectRole.id);
                          }}
                        />
                      ) : (<Cell className={styles.noAssignment}>{projectRole.name}</Cell>)}
                      <EmptyCell />
                      <Cell centered className={styles.checkboxCell}>
                        <Checkbox
                          source={`receiveSms.${projectRole.id}`}
                          label=""
                          value={smsRecipientsField.isRoleAssigned(projectRole.id)}
                          onChangeCallback={() => {
                            smsRecipientsField.changeValue(projectRole.id);
                          }}
                        />
                      </Cell>
                    </RelativeRow>
                  );
                },
              )}
            </div>
          </div>
        </div>
      </ErrorsWrapper>
    </ErrorsWrapper>
  );
}

export default function SmsRecipientsInput() {
  const projectRolesService = useProjectRolesService();

  return (
    <DataLoader getDataFunc={projectRolesService.getAllTeamRoles}>
      {(projectRoles) => {
        return (<SmsRecipientsInputWidget projectRoles={projectRoles} label="Receive SMS" />);
      }}
    </DataLoader>
  );

}
