import React from 'react';

import useSettingsNavigator from '../settingsNavigator';
import { SubscriptionComplianceWarning } from 'features/tasks-forms';

export default function () {
  const settingsNavigator = useSettingsNavigator();

  return (
    <SubscriptionComplianceWarning
      openBillingFunc={settingsNavigator.openBillingPage}
      isBillingPageFunc={settingsNavigator.isBillingPage}
    />
  );
}
