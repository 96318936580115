import React, { FunctionComponent, ReactElement, useState } from 'react';
import { AuthenticationError } from 'request-sender';
import { useEventsSubscriber } from 'event-bus';
import { ApiTokenData, useTokenContext } from './index';
import { AuthenticatedPerson, CURRENT_USER_PROFILE_UPDATED } from '../Api';
import useAuthenticationApiService from '../Api/authenticationService';

export type AuthenticationContextData = {
  user: AuthenticatedPerson,
  setUser: (data: AuthenticatedPerson) => void,
}

const AuthenticationReactContext = React.createContext<AuthenticationContextData | null>(null);

export type NotAuthenticatedViewProps = {
  tokenContext: ApiTokenData,
  isErrorLoadingData: boolean,
  isLoading: boolean,
  children: ReactElement | ReactElement[],
}

export interface AuthenticationContextProps {
  children: ReactElement | ReactElement[];
  notAuthenticatedView: FunctionComponent<NotAuthenticatedViewProps>,
}

export function useAuthenticationContext() {
  return React.useContext(AuthenticationReactContext);
}

export function useAuthenticationContextRequired() {
  const result =  React.useContext(AuthenticationReactContext);

  if (!result) {
    throw new Error('component must be used within AuthenticationContext');
  }

  return result;
}

export function AuthenticationContext(
  props: AuthenticationContextProps,
) {
  const tokenContext = useTokenContext();

  const [currentUser, setCurrentUser] = useState<AuthenticatedPerson>();
  const [errorOccurred, setErrorOccurred] = useState(false);

  const authenticationService = useAuthenticationApiService();

  const [isLoading, setLoading] = useState(true);


  async function loadCurrentUser() {
    try {
      setLoading(true);
      if (tokenContext.isTokenSet()) {
        try {
          const user = await authenticationService.getCurrentUser();
          setCurrentUser(user);
        } catch (e) {
          if (e instanceof AuthenticationError) {
            await tokenContext.setToken(undefined);
          }
          setErrorOccurred(true);
        }
      } else {
        setCurrentUser(undefined);
      }
    } finally {
      setLoading(false);
    }
  }

  useEventsSubscriber(
    'AuthenticationContext',
    {
      [CURRENT_USER_PROFILE_UPDATED]: loadCurrentUser,
    },
    [JSON.stringify(tokenContext.getApiHeaders())],
    () => loadCurrentUser(),
  );

  if (currentUser && tokenContext.isTokenSet()) {
    return (
      <AuthenticationReactContext.Provider
        value={{
          user: currentUser,
          setUser: setCurrentUser,
        }}
      >
        {props.children}
      </AuthenticationReactContext.Provider>
    );
  } else {
    return (
      <props.notAuthenticatedView
        tokenContext={tokenContext}
        isErrorLoadingData={errorOccurred}
        isLoading={isLoading}
      >
        {props.children}
      </props.notAuthenticatedView>
    );
  }
}
