import React, { useState } from 'react';
import { BaseInputProps } from './index';
import { IconButton, IconType } from 'features/nekst-widgets';
import { useFormContext, useInputHelper } from 'ui-builder';
import BaseInputLayout from '../layout/BaseInputLayout';
import { get } from 'lodash';
import { ValidationError } from 'request-sender';
import { FileType, readImageAsDataUrl, useFileService } from 'nekst-api';

export interface ImageUploaderProps extends BaseInputProps {
  fileType: FileType,
  previewSource: string
}

function ImageClearer(props: {
  source: string,
  onClear: () => void,
}) {
  const formContext = useFormContext();
  return (
    <IconButton
      title="Delete"
      type={IconType.TRASH_CAN}
      onClick={() => {
        formContext.onChangeCallback!({
          [props.source]: undefined,
        });

        props.onClear();
      }}
    />
  );
}

export default function InstantImageUploaderInput(props: ImageUploaderProps) {
  const helper = useInputHelper<string>(props);

  const formContext = useFormContext();

  const [preview, setPreview] = useState<string | undefined>(
    get(formContext.data, props.previewSource)?.resized as string
  );

  const fileService = useFileService();

  const handleFileChosen = async (e: any) => {
    const file = e.target.files.length > 0 ? e.target.files[0] : null;

    if (file) {
      try {
        const newFile = await fileService.uploadFile(
          file,
          FileType.PROJECT_PHOTO,
          file.name,
        );

        formContext.onChangeCallback!({
          [props.source]: newFile.id,
        });

        setPreview(
          await readImageAsDataUrl(file)
        );
      } catch (err) {
        if (err instanceof ValidationError) {
          formContext.__setErrors!({
            [props.source]: err.errors.fileId
          });
        } else {
          formContext.__setErrors!({
            [props.source]: [
              'Unknown error on file uploading. Please try again.'
            ]
          })
        }
      }
    }
  };

  return (
    <BaseInputLayout {...helper.getBaseInputLayoutProps()}>
      <input
        type="file"
        name={props.source}
        onChange={handleFileChosen}
        accept="image/png, image/jpeg"
      />
      {!!preview && (
        <>
          <div className="input-preview">
            <img src={preview} alt="Preview" />
          </div>
          <ImageClearer
            source={props.source}
            onClear={() => {
              setPreview(undefined);
            }}
          />
        </>
      )}
    </BaseInputLayout>
  );
}
