import { useNavigate } from 'react-router-dom';

export default function useMeNavigator() {

  const profilePageUrl = '/me/profile';
  const navigate = useNavigate();

  const openUpdateProfilePage = () => {
    navigate(profilePageUrl);
  };

  const openMyHistoryPage = () => {
    navigate('/me/history');
  };

  const isUpdateProfilePage = () => {
    return window.location.pathname === profilePageUrl;
  };

  const isInvitationPage = () => {
    return window.location.pathname.startsWith('/me/invitation');
  };

  return {
    openUpdateProfilePage,
    isUpdateProfilePage,
    isInvitationPage,
    openMyHistoryPage,
  };
}
