import { useBackendApi } from "./backendApi";
import { useEventsPublisher } from 'event-bus';
import { PLAN_TASKS_UPDATED } from "./planTasksService";

export const TASKS_GROUP_ASSIGNED = 'TASKS_GROUP_ASSIGNED';

export function useSetPlanTasksGroupService() {

  const backendApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const setGroup = async (ids: number[], groupId: number | null) => {
    await backendApi.put('/plantasks/bulk/group', {
      ids,
      groupId,
    });

    eventsPublisher.publish(PLAN_TASKS_UPDATED, {});

    if (groupId) {
      eventsPublisher.publish(TASKS_GROUP_ASSIGNED, {
        groupId,
      });
    }
  }

  return {
    setGroup,
  };
}
