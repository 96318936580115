import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import ProjectContext from './view/ProjectContext';
import ProjectAuthorizationScope, { ProjectPermission } from './view/ProjectAuthorizationScope';
import AccessChecker from '../shared/authorization/AccessChecker';

export default function ProjectWrapper() {
  const params = useParams();

  const id = parseInt(params.id!, 10);

  return (
    <ProjectAuthorizationScope id={id}>
      <AccessChecker permissionToCheck={ProjectPermission.READ_TASKS}>
        <ProjectContext projectId={id}>
          <Outlet />
        </ProjectContext>
      </AccessChecker>
    </ProjectAuthorizationScope>
  );
}
