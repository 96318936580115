import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useNewUiTheme } from '../../newUiTheme';
import { useInputHelper } from './inputHelper';
import { WysiwygLayoutProps, WysiwygProps } from './Wysiwyg';

export enum TagType {
  TAG = 'tag',
  MENTION = 'mention',
}

export type Tag = {
  id: string,
  value: string
  isDeprecated: boolean,
  type?: TagType,
}

export interface SmartTagsWysiwygLayoutProps extends WysiwygLayoutProps {
  availableTags: Tag[],
  hideHowToUseLink?: boolean,
}

interface SmartTagsWysiwygProps extends WysiwygProps {
  hideHowToUseLink?: boolean,
  availableTags: Tag[],
}

export function useSmartTagsHelper() {
  const removeUnexistingTags = (
    text: string,
    availableTags: Tag[],
  ) => {
    const mentionPattern = /<span class="mention[^"]+?".+?data\Sid="([^"]+)".+?#<\/span>.+?<\/span>.?<\/span>/g;

    let newText = text;
    let match;

    while ((match = mentionPattern.exec(text)) !== null) {
      const tagId = match[1];

      const exists = availableTags.some(item => item.id === tagId);

      if (!exists) {
        // Replace the whole mention span with an empty string
        newText = newText.replace(match[0], '');
      }
    }

    return newText;
  };

  return {
    removeUnexistingTags,
  };
}

export function SmartTagsWysiwyg(props: SmartTagsWysiwygProps) {
  const uiTheme = useNewUiTheme();

  const smartTagsHelper = useSmartTagsHelper();

  const inputHelper = useInputHelper(props);

  const [tagsRemoved, setTagsRemoved] = useState(false);

  const value = inputHelper.getValue();

  useEffect(() => {
    if (!tagsRemoved && props.availableTags.length > 0) {
      const newText = smartTagsHelper.removeUnexistingTags(
        value,
        props.availableTags,
      );

      if (newText !== value) {
        inputHelper.getOnChangeCallback()({
          target: {
            value: newText,
          },
        });

        setTagsRemoved(true);
      }

    }
  }, [value, !!props.availableTags.length]);

  const notDeprecatedTags = useMemo(
    () => props.availableTags.filter((tag) => !tag.isDeprecated),
    [props.availableTags.length],
  );

  return (
    <uiTheme.inputs.SmartTagsWysiwygLayout
      {...inputHelper.getBaseInputLayoutProps()}
      hideControls={props.hideControls}
      disableHotkeys={props.disableHotkeys}
      availableTags={notDeprecatedTags}
    />
  );
}
