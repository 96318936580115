import { AbstractTask } from './Types';
import { useBackendApi } from './backendApi';
import { useEventsPublisher } from 'event-bus';

export interface MassAssignService {
  massAssign: (ids: number[], usersIds: number[]) => Promise<AbstractTask[]>;
}

export const TASKS_MASS_UPDATED_EVENT = 'TASKS_MASS_UPDATED_EVENT';

export interface TasksMassUpdatedEvent {
  ids: number[],
  data: AbstractTask[],
}

export function useProjectTasksMassAssignService(): MassAssignService {
  const projectsApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const massAssign = async (ids: number[], usersIds: number[]) => {
    const result = await projectsApi.put('/tasks/bulk/assignments', {
      ids,
      usersIds,
    }) as AbstractTask[];

    eventsPublisher.publish<TasksMassUpdatedEvent>(TASKS_MASS_UPDATED_EVENT, {
      ids,
      data: result,
    });

    return result;
  };

  return {
    massAssign,
  };
}
