import { ConstraintValidator, ConstraintValue } from '../index';
import useConstraintValidatorHelper from './constaintValidatorHelper';

export interface EmailListConstraintValidator extends ConstraintValidator<string> {

}

export default function useEmailListConstraintValidator(): EmailListConstraintValidator {
  const getErrorMessage = () => {
    return 'Field contains not valid email address';
  };

  const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  const helperFactory = useConstraintValidatorHelper<string>();

  const validate = (
    object: Record<string, any>,
    fieldName: string,
    constraintConfiguration: ConstraintValue<string>,
  ): boolean => {
    const helper = helperFactory.createHelper(
      object,
      fieldName,
      constraintConfiguration,
    );
    const value = helper.getValue();

    if (value) {
      const notMatchingPatternEmails = value
        .split(',')
        .map((item: string) => item.trim())
        .filter((item: string) => item.length > 0)
        .filter((item: string) => !item.match(re));

      return notMatchingPatternEmails.length === 0;
    } else {
      return true;
    }
  };

  return {
    getName: () => 'emailList',
    getErrorMessage,
    validate,
  };
}
