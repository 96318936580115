import React from 'react';
import { BaseInputProps } from './index';
import useInputHelper from './inputHelper';
import BaseInputLayout from '../layout/BaseInputLayout';
import IconButton, { IconType } from '../../../widgets/IconButton';
import { Popup, PopupText } from 'features/nekst-widgets';
import { useFormContext, usePopupManager } from 'ui-builder';

export interface ImageUploaderProps extends BaseInputProps {
  minWidth?: number,
  minHeight?: number,
}

function FileValidationErrorPopup(
  props: {
    message: string,
  },
) {
  return (
    <Popup
      title="Image Validation Issue"
      content={<PopupText>{props.message}</PopupText>}
    />
  );
}

function ImageClearer(props: {
  source: string,
}) {
  const formContext = useFormContext();
  return (
    <IconButton
      title="Delete"
      type={IconType.TRASH_CAN}
      onClick={() => {
        formContext.onChangeCallback!({
          [props.source]: undefined,
        });
      }}
    />
  );
}

export default function ImageUploaderInput(props: ImageUploaderProps) {
  const helper = useInputHelper<string>(props);

  const value = helper.getValue();

  const popupManager = usePopupManager();

  const validateFile = async (file: string): Promise<string[]> => {
    return new Promise((resolve, reject) => {
      if (props.minHeight || props.minWidth) {
        const image = new Image();

        // Set up an event listener for when the Image has finished loading
        image.onload = () => {
          // Access the dimensions of the loaded image
          const width = image.width;
          const height = image.height;
          const errors: string[] = [];

          if (props.minWidth && props.minWidth > width) {
            errors.push(
              `Please ensure that your image has a width of at least ${props.minWidth} pixels.`,
            );
          }

          if (props.minHeight && props.minHeight > height) {
            errors.push(
              `Please ensure that your image has a height of at least ${props.minHeight} pixels.`,
            );
          }

          resolve(errors);
        };

        image.onerror = reject;

        // Set the source of the Image object as the result of the FileReader
        image.src = file;
      } else {
        resolve([]);
      }
    });
  };

  const handleFileChosen = (e: any) => {
    const file = e.target.files.length > 0 ? e.target.files[0] : null;

    if (file) {
      const fileReader = new FileReader();
      fileReader.onloadend = async () => {
        const errors = await validateFile(fileReader.result as string);

        if (errors.length === 0) {
          helper.getOnChangeCallback()({
            target: {
              value: fileReader.result,
            },
          });
        } else {
          popupManager.openPopup(<FileValidationErrorPopup message={errors.join('. ')} />);
        }
      };

      fileReader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <BaseInputLayout {...helper.getBaseInputLayoutProps()}>
      <input
        type="file"
        name={props.source}
        onChange={handleFileChosen}
        accept="image/png, image/jpeg"
      />
      {value && (
        <>
          <div className="input-preview">
            <img src={value} alt="Preview" />
          </div>
          <ImageClearer source={props.source} />
        </>
      )}
    </BaseInputLayout>
  );
}
