import React, { useCallback, useEffect } from 'react';
import {
  BillingInvoice,
  BillingInvoiceLine,
  SeatUsageInfo,
  TeamPermission,
  UpdateSeatLimitRequest, useDateHelper,
  useSeatUsageService
} from 'nekst-api';
import { useAuthorizationChecker } from 'authorization-scope';
import useDataLoader from 'data-loader';
import {
  AfterSubmitFunc,
  CheckboxInput,
  FormTemplate,
  Header5,
  LoadingAnimation,
  NumberInput,
  RelativeRow,
  Right,
  Row6x6x,
  SimpleBlock,
  useFormContextRequired,
} from 'ui-builder';
import {
  IconButton,
  IconType,
  InformationMessage,
  Separator, TooltipIcon, TooltipIconType,
  usePopupFormManager
} from 'features/nekst-widgets';

import styles from './TeamMembersSeatsWidget.module.scss';
import { debounce } from 'lodash';
import useFeatureToggleService from 'feature-toggle';
import { useSeatUsageLoader } from 'features/tasks-forms';

function formatPrice(price: number) {

  if (price < 0) {
    return `-$${Math.abs(price)
      .toFixed(2)}`;
  } else {
    return `$${price.toFixed(2)}`;
  }
}

function InvoiceLinesList(
  props: {
    title: string,
    lines: BillingInvoiceLine[],
    addPerMonth?: boolean,
  }
) {
  const getTotal = (lines: BillingInvoiceLine[]) => {
    let result = 0;
    for (const line of lines) {
      result += line.amount;
    }

    return result;
  };

  const perMonth = props.addPerMonth ? '/month' : '';

  const totalPrice = getTotal(props.lines);

  const priceNote = totalPrice < 0 ? ' (this amount will be credited towards future invoices)' : '';

  return (
    <SimpleBlock>
      <Header5>{props.title}</Header5>
      {props.lines.map((line) => {
        return (
          <RelativeRow key={line.description} weights={[8, 4]} rowClassName={styles.invoiceLine}>
            <span><b><i>{line.description}</i></b></span>
            <span>{formatPrice(line.amount)}{perMonth}</span>
          </RelativeRow>
        );
      })}
      <p>
        <span style={{
          display: 'inline-block',
          marginRight: '1rem'
        }}><b>Total:</b></span> {formatPrice(totalPrice)}{perMonth}{priceNote}
      </p>
    </SimpleBlock>
  );

}

function InvoicePreview(
  props: {
    currentSeatUsage: SeatUsageInfo,
  }
) {
  const seatUsageService = useSeatUsageService();
  const dateHelper = useDateHelper();

  const formContext = useFormContextRequired<UpdateSeatLimitRequest>();

  const [currentFormData, setCurrentFormData] = React.useState<UpdateSeatLimitRequest>(
    formContext.data!
  );

  const [invoice, setInvoice] = React.useState<BillingInvoice>();

  const isDifferentThanInitial = props.currentSeatUsage.limits.teamMembersLimit !== currentFormData.membersSeats
    || props.currentSeatUsage.limits.teamAdministratorsLimit !== currentFormData.administratorsSeats;

  const isSet = currentFormData.membersSeats !== undefined && currentFormData.administratorsSeats !== undefined;

  const shouldBeInvoiced = isDifferentThanInitial && isSet;

  const { loading } = useDataLoader(
    async () => {
      if (shouldBeInvoiced) {
        return seatUsageService.getFutureInvoice(currentFormData);
      } else {
        return undefined;
      }
    },
    setInvoice,
    currentFormData
  );

  useEffect(() => {
    formContext.setSubmitDisabled(loading);
  }, [loading]);

  const updateCurrentFormData = useCallback(
    debounce((newFormData: UpdateSeatLimitRequest) => setCurrentFormData(newFormData), 500),
    [],
  );

  useEffect(() => {
    updateCurrentFormData(formContext.data!);
  }, [
    formContext.data?.membersSeats,
    formContext.data?.administratorsSeats,
  ]);

  if (loading) {
    return <LoadingAnimation />;
  } else if (invoice) {
    const todaysLineItems = invoice.invoiceLines.filter((line) => {
      return line.period.start.getTime() < new Date().getTime();
    });

    const futureLineItems = invoice.invoiceLines.filter((line) => {
      return line.period.start.getTime() >= new Date().getTime();
    });

    if (futureLineItems.length) {
      return (
        <>
          <Separator />
          <SimpleBlock className="gap1">
            <InvoiceLinesList title="Amount due today" lines={todaysLineItems} />
            <InvoiceLinesList
              title={`What you'll pay monthly starting ${dateHelper.toString(futureLineItems[0].period.start)}`}
              lines={futureLineItems} addPerMonth />
          </SimpleBlock>
        </>
      );
    } else {
      return (
        <>
          <Separator />
          <SimpleBlock className="gap1">
            <InvoiceLinesList title="Subscription Price" lines={todaysLineItems}
                              addPerMonth />
          </SimpleBlock>
        </>
      );
    }

  } else {
    return null;
  }
}

function SubscriptionChangeAgreementCheckbox() {
  return (
    <CheckboxInput
      source="_agreement"
      label={
        <>
          I agree to make changes to my subscription and I have read and agree to Nekst
          {' '}
          <a href="http://nekst.com/terms" target="_blank" tabIndex={-1} rel="noreferrer">
            Terms of Service
          </a>
          {' '}
          and
          {' '}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="http://nekst.com/privacy" target="_blank" tabIndex={-1} rel="noreferrer">
            Privacy Policy
          </a>
        </>
      }
    />
  );
}

export function UpdateSeatsForm(
  props: {
    seatUsageInfo: SeatUsageInfo;
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {
  const seatUsageService = useSeatUsageService();

  const limits = props.seatUsageInfo.limits;
  const usage = props.seatUsageInfo.usage;

  return (
    <FormTemplate<UpdateSeatLimitRequest, SeatUsageInfo>
      title="Manage Seats"
      submitFormFunc={async (data) => {
        return await seatUsageService.updateSeatLimit(data);
      }}
      initialData={{
        membersSeats: Math.max(
          limits.teamMembersLimit,
          usage.teamMembersSeatsUsed
        ),
        administratorsSeats: Math.max(
          limits.teamAdministratorsLimit,
          usage.teamAdministratorsSeatsUsed
        ),
      }}
      afterSubmitFunc={props.afterSubmitFunc}
      validationSchema={{
        membersSeats: {
          type: 'int',
          constraints: {
            min: {
              value: props.seatUsageInfo.usage.teamMembersSeatsUsed,
              message: `Currently you have ${props.seatUsageInfo.usage.teamMembersSeatsUsed} members seats used. You can't decrease the limit below this number.`,
            },
            required: true,
          },

        },
        administratorsSeats: {
          type: 'int',
          constraints: {
            min: {
              value: props.seatUsageInfo.usage.teamAdministratorsSeatsUsed,
              message: `Currently you have ${props.seatUsageInfo.usage.teamAdministratorsSeatsUsed} administrators seats used. You can't decrease the limit below this number.`,
            },
            required: true,
          }
        },
        _agreement: {
          type: 'int',
          constraints: {
            required: {
              value: true,
              message: 'Please accept the terms and conditions to proceed with subscription update',
            },
          }
        }
      }}
    >
      <NumberInput
        source="membersSeats"
        label={`Team Member Seats ($${props.seatUsageInfo.memberSeatPrice}/month)`}
        hint="Can be assigned tasks, see transaction details. Cannot create new transactions or manage action plans"
        min={props.seatUsageInfo.usage.teamMembersSeatsUsed}
      />
      <NumberInput
        source="administratorsSeats"
        label={`Team Administrator Seats ($${props.seatUsageInfo.administratorSeatPrice}/month)`}
        hint="Can create transactions, assign tasks, add/edit team members and action plans"
        min={props.seatUsageInfo.usage.teamAdministratorsSeatsUsed}
      />
      <InvoicePreview currentSeatUsage={props.seatUsageInfo} />
      <Separator />
      <SubscriptionChangeAgreementCheckbox />
    </FormTemplate>
  );
}

function TeamMembersSeatsWidgetImplementation() {

  const popupManager = usePopupFormManager();
  const { isGranted } = useAuthorizationChecker();

  const { seatUsage } = useSeatUsageLoader();

  if (seatUsage) {
    const usage = seatUsage.usage;
    const limits = seatUsage.limits;

    return (
      <InformationMessage>
        {isGranted(TeamPermission.MANAGE_TEAM_MEMBERS) && (
          <Right>
            <IconButton
              onClick={() => {
                popupManager.openForm(
                  <UpdateSeatsForm seatUsageInfo={seatUsage} />,
                );
              }}
              type={IconType.GEAR}
            />
          </Right>
        )}
        <Row6x6x useFlex>
          <SimpleBlock>
            <Header5>
              Team Members Seats
              <TooltipIcon
                tooltipContent={(
                  <div>
                    Can be assigned tasks, see transaction details. Cannot create new transactions
                    or manage action plans
                    <br />
                    <br />
                    (${seatUsage.memberSeatPrice} per seat / month)
                  </div>
                )}
                iconType={TooltipIconType.HELP}
              />
            </Header5>
            <p>Seats Included: {usage.freeMembersSeatsUsed}/{limits.freeMembersSeatsLimit} used</p>
            <p>Additional
              Seats: {usage.teamMembersSeatsUsed}/{limits.teamMembersLimit} used</p>
          </SimpleBlock>
          <SimpleBlock>
            <Header5>
              Administrators Seats
              <TooltipIcon
                tooltipContent={(
                  <div>
                    Can create transactions, assign tasks, add/edit team members and action plans
                    <br />
                    <br />
                    (${seatUsage.administratorSeatPrice} per seat / month)
                  </div>
                )}
                iconType={TooltipIconType.HELP}
              />
            </Header5>
            <p>Seats Included: {usage.freeAdministratorsSeatsUsed}/{limits.freeAdministratorsSeatsLimit} used</p>
            <p>Additional
              Seats: {usage.teamAdministratorsSeatsUsed}/{limits.teamAdministratorsLimit} used
            </p>
          </SimpleBlock>
        </Row6x6x>


      </InformationMessage>
    );
  } else {
    return null;
  }

}

export default function TeamMembersSeatsWidget() {
  const { isGranted } = useAuthorizationChecker();

  const featureToggleService = useFeatureToggleService();

  if (isGranted(TeamPermission.ACCESS_TEAM_MEMBERS_MANAGEMENT)
    && featureToggleService.isTeamMembersManagementSeatsEnabled()) {
    return <TeamMembersSeatsWidgetImplementation />;
  } else {
    return null;
  }
}
