import React, { ReactElement } from 'react';
import { ProjectType, SmsTask, useProjectTasksService } from 'nekst-api';
import {
  AfterSubmitFunc,
  CheckboxInput,
  FormTemplate,
  RelativeRow,
  Right,
  TextInput
} from 'ui-builder';
import { ProjectParentTaskRelationInput, SendSmsButton } from 'features/tasks-forms';

import ProjectDropdown from '../ProjectDropdown';
import Row3x3x6x from '../../../../../shared/web/uitheme/form/Row3x3x6x';
import SendDate from '../Email/SendDate';
import Row8x4x from '../../../../../shared/web/uitheme/form/Row8x4x';
import Separator from '../../../../../shared/web/uitheme/form/Separator';
import TaskCommentsListInput from '../comments/TaskCommentsListInput';
import SmsTaskAssignmentsInput from './SmsTaskAssignmentInput';
import OverflowHidden from '../../../../../shared/widgets/OverflowHidden';
import SmsSmartTagsWysiwyg from '../../../../../shared/widgets/SmartTags/SmsSmartTagsWysiwyg';
import SmsSmartTagsContext from '../../../../../shared/smarttags/SmsSmartTagsContext';
import { useTasksSmartTagsHelper } from '../../../../../shared/smarttags/SmartTagsContext';
import { ProjectIdContext } from 'features/nekst-widgets';

export interface BaseSmsTaskFormProps {
  submitFormFunc: (data: SmsTask) => Promise<SmsTask>;
  afterSubmitFunc?: AfterSubmitFunc,
  data?: SmsTask;
  title: string | ReactElement;
  buttons: ReactElement
  initialData?: SmsTask,
  isDisabled?: boolean | ((data: SmsTask) => boolean)
  showProjectDropdown?: boolean,
  children?: ReactElement,
  // eslint-disable-next-line react/no-unused-prop-types
  projectType?: ProjectType
}

function SmsTaskForm(props: BaseSmsTaskFormProps) {
  const projectTasksService = useProjectTasksService();

  const isDisabled = props.isDisabled ?? false;

  const showProjectDropdown = props.showProjectDropdown || false;

  const { formData } = useTasksSmartTagsHelper(props.data);

  return (
    <ProjectIdContext projectId={props.initialData?.projectId || 0}>
      <FormTemplate<SmsTask>
        title={props.title}
        submitFormFunc={props.submitFormFunc}
        afterSubmitFunc={props.afterSubmitFunc}
        getDataFunc={formData ? async () => formData! : undefined}
        buttons={props.buttons}
        isDisabled={isDisabled}
        initialData={props.initialData}
        validationSchema={projectTasksService.getSmsTaskValidationSchema()}
      >
        {showProjectDropdown && (<ProjectDropdown source="projectId" />)}
        <TextInput source="name" label="Task Name" />
        <Row3x3x6x>
          <CheckboxInput source="isKeyDate" label="Key Date" />
          <CheckboxInput source="isPrivate" label="Private" />
          <CheckboxInput source="isAutoSent" label="Autosend" />
        </Row3x3x6x>
        <RelativeRow
          weights={[7, 5]}
          isVisible={(data) => !data.parentTaskRelation || data.dueDate}
        >
          <RelativeRow weights={[5, 7]}>
            <SendDate />
          </RelativeRow>
        </RelativeRow>
        <Row8x4x
          isVisible={(data) => !!data.parentTaskRelation && !data.dueDate}
        >
          <ProjectParentTaskRelationInput source="parentTaskRelation" label="Send" />
        </Row8x4x>
        <Separator />
        <SmsSmartTagsWysiwyg source="description" label="SMS message" />
        <OverflowHidden>
          <Right isVisible={(data) => data.id}>
            <SendSmsButton addPreviewPrefix />
          </Right>
        </OverflowHidden>
        <Separator />
        <SmsTaskAssignmentsInput />
        <Separator isVisible={(data) => data.id} />
        <TaskCommentsListInput isVisible={(data) => data.id} />
        {props.children || null}
      </FormTemplate>
    </ProjectIdContext>
  );
}

export default function BaseSmsTaskForm(props: BaseSmsTaskFormProps) {
  return (
    <SmsSmartTagsContext
      projectType={props.data?._project.type || props.projectType}
      projectId={props.data?.projectId}
    >
      <SmsTaskForm {...props} />
    </SmsSmartTagsContext>
  );
}
