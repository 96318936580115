import React from 'react';
import DatePicker from 'react-datepicker';
import { TimeInputLayoutProps } from 'ui-builder';
import useUiTheme from '../../../uiTheme';

export default function TimeInputLayout(props: TimeInputLayoutProps) {
  const uiTheme = useUiTheme();

  let value;

  if (props.value) {
    value = props.value;
  } else {
    value = null;
  }

  return (
    <uiTheme.forms.BaseInputLayout label={props.label} name={props.name} errors={props.errors}>
      <DatePicker
        placeholderText={props.placeholder}
        selected={value}
        disabled={props.isDisabled}
        onChange={(date: Date) => {
          props.onChangeCallback({
            target: {
              value: date,
            },
          });
        }}
        showTimeSelect
        showTimeSelectOnly
        dateFormat="hh:mm aa"
      />
    </uiTheme.forms.BaseInputLayout>
  );
}
