import React from 'react';
import ErrorsWrapper from './ErrorsWrapper';
import { CheckboxLayoutProps } from '../Checkbox';

function CheckboxLayout(props: CheckboxLayoutProps) {

  return (
    <ErrorsWrapper name={props.name} errors={props.errors}>
      <>
        {props.label}
        <div>
          <input
            name={props.name}
            type="checkbox"
            placeholder={props.placeholder}
            checked={props.value || false}
            onChange={(event) => {
              if (props.onChangeCallback) {
                props.onChangeCallback({
                  target: {
                    value: event.target.checked,
                  },
                });
              }
            }}
          />
        </div>
      </>
    </ErrorsWrapper>
  );
}

export default CheckboxLayout;
