import React from 'react';
import BaseEmailTaskForm from './BaseEmailTaskForm';
import UpdateTaskButtons from '../UpdateTaskButtons';
import { AfterSubmitFunc } from 'ui-builder';
import { PlanTask, usePlanTasksService } from 'nekst-api';

export interface UpdateEmailTaskFormProps {
  afterSubmitFunc?: AfterSubmitFunc,
  data: PlanTask
}

export default function UpdateEmailTaskForm(props: UpdateEmailTaskFormProps) {
  const planTasksService = usePlanTasksService();
  return (
    <BaseEmailTaskForm
      title={props.data.name}
      data={props.data}
      submitFormFunc={(data) => planTasksService.updateTask(data.id, data)}
      afterSubmitFunc={props.afterSubmitFunc}
      validationSchema={planTasksService.getEmailTaskValidationSchema()}
      buttons={<UpdateTaskButtons />}
    />
  );
}
