import React from 'react';
import PageLink from './PageLink';
import useTextHelper from './pageDefiner';
import { ProjectData, TaskData } from 'nekst-api';

interface Props {
  task: TaskData
  project: ProjectData,
}

export default function TaskLink(props: Props) {
  const textHelper = useTextHelper();

  return (
    <PageLink
      text={textHelper.addProjectName(props.task.name, props.project)}
      action={
        () => {
          // ignored
        }
      }
      isDeleted={props.task.isDeleted}
    />
  );

}
