export const Colors = {
  rgba: () => null,
};

export const View = () => null;

export const Text = () => null;

export const Spacings = {};

export const Button = () => null;

export const Picker = () => null;

export const Image = () => null;

export const DateTimePicker = () => null;

export const Checkbox = () => null;

export const TextField = () => null;

export const Typography = {};

export const ExpandableOverlay = () => null;

export const Incubator = {};

export const Toast = () => null;

export const mobileStyles = {};

export default {};
