import React from 'react';
import IconButton, { IconType } from '../../shared/widgets/IconButton';
import useProjectDocumentsService, {
  ProjectDocument,
  ProjectDocumentPermission,
} from './projectDocumentsService';
import { usePopupManager, useShowContext } from 'ui-builder';
import { ConfirmDeletePopup } from 'features/nekst-widgets';

function DeleteFilePopup(props: {
  data: ProjectDocument,
}) {
  const documentService = useProjectDocumentsService();
  return (
    <ConfirmDeletePopup
      deleteFunc={async () => {
        await documentService.setFile(props.data.id, undefined);
      }}
      message={`Are you sure you want to delete file "${props.data._file.name}"?`}
    />
  );
}

export default function DeleteFileButton() {

  const popupManager = usePopupManager();

  const showContext = useShowContext<ProjectDocument>();

  const document = showContext.data!;

  if (document.fileId && document._permissions.includes(ProjectDocumentPermission.SET_FILE)) {
    return (
      <IconButton
        type={IconType.TRASH_CAN}
        onClick={async () => {
          popupManager.openPopup(<DeleteFilePopup data={document} />);
        }}
        title="Delete File"
      />
    );
  } else {
    return null;
  }
}
