/* istanbul ignore file */
import React from 'react';
import Select, { components, InputProps } from 'react-select';
import { DropdownLayoutProps, Option, OptionsGroup } from 'ui-builder';

import useUiTheme from '../../../uibuilder/uiTheme';

import './DropdownLayout.scss';

function Input({ ...rest }: InputProps) {
  return (<components.Input {...rest} autoComplete="chrome-off" />);
}

function DropdownLayout(props: DropdownLayoutProps) {
  const uiTheme = useUiTheme();

  const areOptionsGrouped = () => {
    return props.options && props.options?.length > 0 && Array.isArray(props.options[0].options);
  };

  const getCurrentValue = () => {
    if (areOptionsGrouped()) {
      let result;
      (props.options as OptionsGroup[])
        .forEach((group) => {
          group.options.forEach((option) => {
            if (option.value === props.value) {
              result = option;
            }
          });
        });
      return result;
    } else {
      return (props.options as Option[])
        .find((option) => option.value === props.value) || undefined;
    }
  };

  const isClearable = props.isClearable !== undefined ? props.isClearable : true;


  return (
    <uiTheme.forms.BaseInputLayout
      name={props.name}
      errors={props.errors}
      label={props.label}
      className={props.className}
      hideLabel={props.hideLabel}
      showRequiredIcon={props.showRequiredIcon}
    >
      <Select
        name={props.name}
        className="nekst-dropdown"
        classNamePrefix="nekst-dropdown"
        options={props.options}
        isClearable={isClearable}
        value={getCurrentValue() || ''}
        placeholder={props.placeholder}
        isDisabled={props.isDisabled}
        onChange={(newValue: any) => {
          props.onChangeCallback({
            target: {
              value: newValue ? newValue.value : null,
            },
          });
        }}
        components={{
          IndicatorSeparator: () => null,
          // @ts-ignore
          Input,
        }}
        data-testid={`${props.name}-dropdown`}
        data-test={`${props.name}-dropdown`}
      />
    </uiTheme.forms.BaseInputLayout>
  );
}

export default DropdownLayout;
