import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { ProjectType } from 'nekst-api';
import { useProjectTypeHelper } from 'features/tasks-forms';
import InnerBlock from '../../../shared/web/layout/InnerBlock';
import CreateProjectDetailsFieldButton from '../CreateUpdate/CreateProjectDetailsFieldButton';
import PageTitle from '../../../shared/web/PageTitle';
import DetailsFieldsSetsContext from './DetailsFieldsSetsContext';

export default function DetailsFieldsPageWrapper() {
  const { projectType: projectTypeParam } = useParams<{ projectType: string }>();
  const projectType = projectTypeParam?.toUpperCase() as ProjectType | undefined;

  const projectTypeHelper = useProjectTypeHelper();

  if (projectType) {
    const typeLabel = projectTypeHelper.getLabel(projectType);

    const pageTitle = `Transaction Details Setup - ${typeLabel}`;

    return (
      <DetailsFieldsSetsContext>
        <InnerBlock
          title={pageTitle}
          headerRight={<CreateProjectDetailsFieldButton projectType={projectType} />}
        >
          <PageTitle text={pageTitle} />
          <Outlet />
        </InnerBlock>
      </DetailsFieldsSetsContext>
    );
  } else {
    return null;
  }
}
