import React, { useState } from 'react';
import {
  useProjectAssignmentsService,
  ProjectAssignment,
} from 'nekst-api';
import useDataLoader from 'data-loader';
import {
  LoadingAnimation,
  BaseInputProps,
} from 'ui-builder';
import { Option } from '../../shared/uibuilder/form/input';
import CheckboxGroup from '../../shared/uibuilder/form/input/CheckboxGroup';

export interface ContactsCheckboxGroupProps extends BaseInputProps<number[]> {
  projectId: number,
}

export default function ContactsCheckboxGroup(props: ContactsCheckboxGroupProps) {
  const projectAssignmentsService = useProjectAssignmentsService(false);

  const [options, setOptions] = useState<Option<number>[]>();

  useDataLoader(
    async () => {
      const projectAssignments = await projectAssignmentsService.getProjectAssignments(
        props.projectId,
      );

      const byPerson = projectAssignments
        .filter((assignment) => !!assignment.personId)
        .reduce((prev: Record<number, ProjectAssignment[]>, current: ProjectAssignment) => {
          const result = {
            ...prev,
          };
          const personId = current.personId!;

          if (!result[personId]) {
            result[personId] = [];
          }

          result[personId].push(current);

          return result;
        }, {});

      return Object.entries(byPerson)
        .map(([, assignments]) => {
          return ({
            label: assignments[0]._person.name.fullName,
            value: assignments[0]._person.id,
            hint: assignments.reduce((prev: string[], current: ProjectAssignment) => {
              const result = [
                ...prev,
              ];

              result.push(current._role.name);

              return result;
            }, [])
              .join(', '),
          });
        });
    },
    setOptions,
    props.projectId,
  );

  if (options) {
    return (
      <CheckboxGroup options={options} {...props} weights={[3, 3, 3, 3]} />
    );
  } else {
    return (<LoadingAnimation />);
  }
}
