import React from 'react';
import { useFilterContext } from 'ui-builder';
import { ProjectFilter } from 'nekst-api';
import { useProjectTypeHelper } from 'features/tasks-forms';
import FilterWidgetLayout from '../view/tasks/list/filter/FilterWidgetLayout';
import DataLoader from '../../shared/DataLoader';
import usePeopleService from '../../people/peopleService';

function PropertyTypeWidget() {
  const filterContext = useFilterContext<ProjectFilter>();

  const projectTypeHelper = useProjectTypeHelper();

  const text = filterContext
    .filterValue
    ?.types
    .selected
    .map((v) => projectTypeHelper.getLabel(v))
    .join(', ');

  if (text) {
    return (
      <FilterWidgetLayout
        name="Types"
        text={text}
        onClick={() => filterContext.setFilterValue({
          ...filterContext.filterValue!,
          types: {
            selectAll: true,
            selected: [],
          },
        })}
      />
    );
  } else {
    return null;
  }
}

function AssignedToWidget() {
  const filterContext = useFilterContext<ProjectFilter>();

  const peopleService = usePeopleService();

  const selected = filterContext.filterValue?.assignedTo.selected || [];

  if (filterContext.filterValue?.assignedTo.selected.length) {
    return (
      <DataLoader getDataFunc={peopleService.getTeamMembers}>
        {(teamMembers) => {
          const getFullName = (id: number) => {
            return teamMembers.find((v) => v.id === id)!.profile.name.fullName;
          };

          const text = selected
            .map((v) => getFullName(v))
            .join(', ');

          return (
            <FilterWidgetLayout
              name="Assigned To"
              text={text}
              onClick={() => filterContext.setFilterValue({
                ...filterContext.filterValue!,
                assignedTo: {
                  selectAll: true,
                  selected: [],
                },
              })}
            />
          );
        }}
      </DataLoader>
    );
  } else {
    return null;
  }
}

function WithPastDueOnlyWidget() {
  const filterContext = useFilterContext<ProjectFilter>();

  if (filterContext.filterValue?.withPastDueOnly) {
    return (
      <FilterWidgetLayout
        name=""
        text="With Past Due Tasks Only"
        onClick={() => filterContext.setFilterValue({
          ...filterContext.filterValue!,
          withPastDueOnly: false,
        })}
      />
    );
  } else {
    return null;
  }
}

export default function FilterWidgets() {
  return (
    <>
      <AssignedToWidget />
      <PropertyTypeWidget />
      <WithPastDueOnlyWidget />
    </>
  );
}
