import { usePopupManager } from 'ui-builder';
import { ContactAssignments, useProjectAssignmentsService } from 'nekst-api';
import { ConfirmDeletePopup } from 'features/nekst-widgets';
import React from 'react';

export function useDeleteContactAssignmentsLogic() {
  const popupManager = usePopupManager();

  const projectAssignmentsService = useProjectAssignmentsService();

  const hasEditableAssignments = (contactAssignments: ContactAssignments) => {
    return contactAssignments.roles
      .find((item) => item.isEditable);
  };

  const getDeleteFunc = (projectId: number, contactAssignments: ContactAssignments) => {
    if (hasEditableAssignments(contactAssignments)) {

      const deleteFunc = async () => {
        const ids = contactAssignments
          .roles
          .filter((item) => item.isEditable)
          .map((item) => item.id);

        await projectAssignmentsService.deleteAssignments(projectId, ids);
      };

      const { name } = contactAssignments.profile;
      const fullName = `${name.firstName} ${name.lastName}`;

      const isOwner = !!contactAssignments.roles.find((r) => !r.isEditable);

      let message: string;
      if (isOwner) {
        message = `Are you sure you want to unassign all roles (except Manager) from ${fullName}?`;
      } else {
        message = `Are you sure you want to unassign ${fullName} from the project?`;
      }

      return () => {
        popupManager.openPopup(
          <ConfirmDeletePopup
            deleteFunc={deleteFunc}
            message={message}
          />,
        );
      };
    } else {
      return () => {
        // do nothing
      };
    }
  };

  return {
    hasEditableAssignments,
    getDeleteFunc,
  };
}
