import React from 'react';
import { useAuthenticationContext } from 'nekst-api';
import { AvatarInput } from 'features/tasks-forms';

export default function UpdateProfileAvatarForm() {

  const authenticationContext = useAuthenticationContext()!;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <AvatarInput person={authenticationContext.user} />
    </div>

  );
}
