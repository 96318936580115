import React, { ReactNode } from 'react';
import { FileType, ProjectType } from 'nekst-api';
import useDefaultPortalConfigurationService, {
  DefaultPortalConfiguration
} from './defaultPortalConfigurationService';
import useDataLoader from 'data-loader';
import {
  ButtonColor,
  ButtonSize,
  CheckboxInput,
  FormTemplate,
  LoadingAnimation,
  SubmitButton
} from 'ui-builder';
import DetailBoxesCheckboxGroup from './DetailBoxesCheckboxGroup';
import { Separator } from 'features/nekst-widgets';
import ServiceProvidersGroupSelector, {
  useServiceProvidersCheckboxesGroupMapper
} from '../../../projects/clientportal/ServiceProvidersGroupSelector';
import ServiceProvidersContext from '../../../projects/clientportal/ServiceProvidersContext';
import DocumentLabelsCheckboxGroup from './DocumentLabelsCheckboxGroup';
import PortalTitleInput from './PortalTitleInput';
import { createPortal } from 'react-dom';
import InstantImageUploaderInput
  from '../../../shared/uibuilder/form/input/InstantImageUploaderInput';

type FormType = Omit<DefaultPortalConfiguration, 'serviceProvidersLinks'> & {
  serviceProvidersLinks: Record<number, number[]>
};

function useFormMapper() {
  const checkboxesGroupMapper = useServiceProvidersCheckboxesGroupMapper();

  const toFormView = (data: DefaultPortalConfiguration) => {
    return {
      ...data,
      serviceProvidersLinks: checkboxesGroupMapper.toFormView(data.serviceProvidersLinks),
    };
  };

  const toApiView = (data: FormType) => {
    return {
      ...data,
      serviceProvidersLinks: checkboxesGroupMapper.toApiView(data.serviceProvidersLinks),
    };
  };

  return {
    toFormView,
    toApiView
  };
}

export function ConfigurationFormButtonPortal() {
  return (<div id="portal-configuration-form-button-portal" />)
}

function ConfigurationFormButtonPortalWrapper(
  props: {
    children: ReactNode,
  }
) {
  const container = document.getElementById('portal-configuration-form-button-portal');

  if (container) {
    return createPortal(props.children, container);
  } else {
    return props.children;
  }

}

export default function PortalConfigurationForm(props: {
  projectType: ProjectType
}) {
  const [portalConfiguration, setPortalConfiguration] = React.useState<DefaultPortalConfiguration>();

  const service = useDefaultPortalConfigurationService();

  const formMapper = useFormMapper();

  useDataLoader(
    async () => {
      setPortalConfiguration(undefined);
      return await service.getPortalConfiguration(props.projectType);
    },
    setPortalConfiguration,
    props.projectType
  );

  if (portalConfiguration) {
    return (
      <ServiceProvidersContext>
        <FormTemplate<FormType>
          title="Client Portal Configuration"
          submitFormFunc={async (data) => {
            const result = await service.updatePortalConfiguration(
              props.projectType,
              formMapper.toApiView(data)
            );

            return formMapper.toFormView(result);
          }}
          initialData={formMapper.toFormView(portalConfiguration)}
          buttons={(
            <ConfigurationFormButtonPortalWrapper>
              <SubmitButton text="Save" color={ButtonColor.BLUE} isFilled size={ButtonSize.MEDIUM} />
            </ConfigurationFormButtonPortalWrapper>
          )}
        >
          <CheckboxInput source="isEnabled" label="Client Portal Enabled" />
          <PortalTitleInput source="title" label="Title" projectType={props.projectType} />
          <InstantImageUploaderInput
            source="photoId"
            previewSource="_photo"
            label="Transaction Photo"
            fileType={FileType.PROJECT_PHOTO}
            hint="Please ensure that the uploaded file has a minimum width of 1000 pixels and a minimum height of 450 pixels."
          />
          <DetailBoxesCheckboxGroup
            projectType={props.projectType}
            label="Detail Boxes"
            source="detailsFieldsIds"
          />
          <Separator />
          <div>Contacts to display</div>
          <CheckboxInput source="showAllTransactionParties" label="Add All Contacts By Default" />
          <Separator />
          <DocumentLabelsCheckboxGroup source="documentLabelsIds" label="Documents To Include" />
          <Separator />
          <ServiceProvidersGroupSelector
            source="serviceProvidersLinks"
            label="Additional Service Providers To Include"
          />
        </FormTemplate>
      </ServiceProvidersContext>

    );
  } else {
    return (<LoadingAnimation />);
  }

}
