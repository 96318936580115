import React from 'react';
import { useShowContextRequired } from 'ui-builder';
import { TasksGroup } from 'nekst-api';

import { TasksGroupIcon } from 'features/nekst-widgets';
import { ToggledFeature, withToggleWrapper } from 'feature-toggle';

function TasksGroupField() {

  const showContext = useShowContextRequired<{
    _group?: TasksGroup
  }>();

  const group = showContext.data?._group;

  if (group) {
    return (
      <TasksGroupIcon group={group} />
    );
  } else {
    return null;
  }
}


export default withToggleWrapper(TasksGroupField, ToggledFeature.TASKS_GROUPS);

