import React from 'react';
import { ColorPickerLayoutProps } from '../types';
import { ErrorsWrapper, InputLabel } from 'ui-builder';
import { HexColorPicker } from 'react-colorful';
import { getDefaultColors } from './defaultColorsHelper';

import styles from './ColorPickerLayout.module.scss';

function DefaultColorChooser(
  props: {
    currentValue: string,
    color: string,
    onChosen: (newValue: string) => void
  }
) {
  return (
    <div
      onClick={() => {
        props.onChosen(props.color);
      }}
      className={styles.defaultColorPicker}
      style={{
        backgroundColor: props.color,
      }}
    >
      &nbsp;
    </div>
  );
}

export default function ColorPickerLayoutWeb(
  props: ColorPickerLayoutProps
) {

  return (
    <ErrorsWrapper name={props.name} errors={props.errors}>
      <InputLabel source={props.name} label={props.label} hideLabel={props.hideLabel} />
      <div
        className={styles.widget}
      >

        <HexColorPicker
          color={props.value || '#000000'}
          onChange={(newValue) => {
            props.onChangeCallback({ target: { value: newValue } });
          }}
          style={{
            float: 'left',
            marginRight: '2rem'
          }}
        />

        <div className={styles.defaultColors}>
          {getDefaultColors()
            .map((defaultColor) => (
              <DefaultColorChooser
                currentValue={props.value || '#000000'}
                color={defaultColor}
                onChosen={
                  (newValue) => {
                    props.onChangeCallback({ target: { value: newValue } });
                  }
                }
              />
            ))}
        </div>

      </div>

    </ErrorsWrapper>
  );
}
