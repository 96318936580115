import React from 'react';
import Button, { ButtonColor, ButtonSize } from '../../shared/web/button/Button';
import { Person } from '../peopleService';
import usePeopleNavigator from '../peopleNavigator';

export default function OpenShowProfilePageButton(
  props: {
    person: Person,
  },
) {
  const peopleNavigator = usePeopleNavigator();
  return (
    <Button
      text="Profile"
      onClick={async () => peopleNavigator.openViewPersonPage(props.person.id)}
      color={ButtonColor.BLUE}
      isFilled
      size={ButtonSize.MEDIUM}
    />
  );
}
