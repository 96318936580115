import { ConstraintValidator, ConstraintValue } from '../index';
import useWhenResolver from './whenResolver';

interface RequiredConstraintValidator extends ConstraintValidator<boolean> {
  isRequired: (_object: Record<string, any>, _config: any) => boolean,
}

function useRequiredConstraintValidator(): RequiredConstraintValidator {
  const whenResolver = useWhenResolver();

  const isRequired = (object: Record<string, any>, config: ConstraintValue): boolean => {
    if (whenResolver.isApplicable(object, config)) {
      let result;
      if (typeof config === 'object') {
        result = config.value;
      } else {
        result = config;
      }

      return result;
    } else {
      return false;
    }
  };

  const validate = (
    object: Record<string, any>,
    fieldName: string,
    config: any,
  ): boolean => {
    if (isRequired(object, config)) {
      const value = object[fieldName];
      const type = typeof value;

      let result;

      switch (type) {
        case 'undefined':
          result = false;
          break;
        case 'object':
          if (value && ('selectAll' in value && 'selected' in value)) {
            result = value.selectAll || value.selected.length > 0;
          } else {
            result = !!value
              && JSON.stringify(value) !== '{}'
              && JSON.stringify(value) !== '[]';
          }
          break;
        case 'bigint':
        case 'number':
          result = true;
          break;
        case 'boolean':
          result = !!value;
          break;
        default:
          result = value !== undefined && value !== null && value.trim().length > 0
            && value.replace(/<\/?[^>]+(>|$)/g, '');
      }

      return result;
    } else {
      return true;
    }
  };

  const getErrorMessage = (config: ConstraintValue) => {
    return (typeof config === 'object' && config.message) || 'Field is required';
  };

  return {
    isRequired,
    getName: () => 'required',
    getErrorMessage,
    validate,
  };
}

export default useRequiredConstraintValidator;
