import { useBackendApi } from './backendApi';
import { useEventsPublisher } from 'event-bus';
import { useObjectDatesMapper } from './Helper';

export enum SubscriptionPlanId {
  AGENT_LITE = 'AGENT_LITE',
  AGENT_LITE_PLUS = 'AGENT_LITE_PLUS',
  AGENT_PRO = 'AGENT_PRO',
  TEAM_PRO = 'TEAM_PRO',
}

export enum IntervalType {
  MONTH = 'month',
  YEAR = 'year'
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  PAST_DUE = 'past_due',
  UNPAID = 'unpaid',
  TRIALING = 'trialing',
  PAUSED = 'paused',
  CANCELED = 'canceled',
  NOT_FOUND = 'not_found',
  ERROR = 'error',
}

export interface SubscriptionPrice {
  id: string;
  amount: number;
  recurringInterval: IntervalType;
}

export interface SubscriptionPlan {
  id: SubscriptionPlanId,
  name: string,
  prices: SubscriptionPrice[]
}

export interface PriceDto {
  value: number,
  currency: string
}

export interface SubscriptionProduct {
  billingInterval: IntervalType,
  price: PriceDto,
  providerProductId: string,
}

export interface PlatformSubscriptionPlan {
  id: SubscriptionPlanId
  name: string,
  isPaid: boolean,
  products: SubscriptionProduct[],
}

export enum PaymentMethodProvider {
  STRIPE = 'stripe',
  IOS = 'ios',
}

export interface TeamBilling {
  planId?: SubscriptionPlanId,
  planName?: string,
  status: SubscriptionStatus,
  isCompliantWithSubscription: boolean,
  complianceGracePeriodEndsAt?: Date,
  price?: PriceDto
  billingInterval?: IntervalType,
  paymentMethodProvider?: PaymentMethodProvider,
  currentPeriodEndAt?: Date,
  notifyAboutPlanDowngrade: boolean,
  renewalInfo?: {
    isEnabled: boolean,
    subscriptionId?: SubscriptionPlanId,
    subscriptionName?: string,
    billingInterval?: IntervalType,
  },
  uniqueId: string
}

export interface BillingData {
  teamBilling: TeamBilling,
  availablePlans: SubscriptionPlan[]
}

export enum SubscriptionCreationResultType {
  SUBSCRIPTION_CREATED = 'SUBSCRIPTION_CREATED',
  CHECKOUT_SESSION_CREATED = 'CHECKOUT_SESSION_CREATED',
}

export interface SubscriptionCreationResult {
  type: SubscriptionCreationResultType,
  url?: string,
}

export const BILLING_PLAN_UPDATED = 'BILLING_PLAN_UPDATED';

export enum ComplianceCheck {
  TEAM_MEMBERS_COUNT = 'TEAM_MEMBERS_COUNT',
  PROJECTS_COUNT = 'PROJECTS_COUNT',
}

export interface TeamMembersCountError {
  membersCount: number,
  membersLimit: number,
  adminsCount: number,
  adminsLimit: number
  checkId: ComplianceCheck.TEAM_MEMBERS_COUNT
  isNotPaid: boolean,
}

export interface ProjectsCountError {
  currentCount: number,
  limit: number,
  checkId: ComplianceCheck.PROJECTS_COUNT

}

export type ComplianceError = TeamMembersCountError | ProjectsCountError;

export enum AppPlatform {
  WEB = 'web',
  ANDROID = 'android',
  IOS = 'ios',
}

export type ValidateMobilePaymentMethodResult = {
  isValid: boolean,
  belongsToCurrentTeam: boolean,
  isLinkedToAnotherTeam: boolean,
  teamHasOtherPaymentMethod: boolean
}

interface GooglePlayUpdateSubscriptionRequest {
  purchaseTokenAndroid?: string;
}

interface GooglePlayCancelSubscriptionRequest {
  sku?: string;
}

export function useBillingService() {
  const nekstApi = useBackendApi();

  const eventPublisher = useEventsPublisher();

  const { mapSingle } = useObjectDatesMapper<TeamBilling>(
    [],
    ['currentPeriodEndAt', 'complianceGracePeriodEndsAt'],
  );


  const getCurrentUserBillingData = async (refresh: boolean) => {
    const result = await nekstApi.get(`/me/billing?refresh=${refresh ? 1 : 0}`);

    if (refresh) {
      eventPublisher.publish(BILLING_PLAN_UPDATED, {});
    }

    return mapSingle(result);
  };


  const refreshBillingData = async () => {
    await getCurrentUserBillingData(true);
  };

  const getSubscriptionPlans = async (): Promise<SubscriptionPlan[]> => {
    return await nekstApi.get('/billing/subscriptionplans') as SubscriptionPlan[];
  };

  const getSubscriptionPlansForPlatform = async (platform: string): Promise<PlatformSubscriptionPlan[]> => {
    return await nekstApi.get(`/billing/subscriptionplans/${platform}`) as PlatformSubscriptionPlan[];
  };

  const getBillingData = async (refresh: boolean): Promise<BillingData> => {
    const data = await Promise.all([
      getCurrentUserBillingData(refresh),
      getSubscriptionPlans(),
    ]);

    return {
      teamBilling: data[0],
      availablePlans: data[1],
    };
  };

  const getBillingPageUrl = () => {
    const location = window.location;
    return `${location.protocol}//${location.hostname}:${location.port}/settings/billing`;
  };

  const createBillingPortalSession = async (): Promise<string> => {
    const returnUrl = `${getBillingPageUrl()}?refresh=1`;
    const result = await nekstApi.post('/billing/portallink', {
      returnUrl,
    }) as {
      url: string,
    };

    return result.url;
  };

  const createSubscription = async (priceId: string): Promise<SubscriptionCreationResult> => {
    const successReturnUrl = `${getBillingPageUrl()}?subscriptionCreated=1&refresh=1`;
    const cancelUrl = `${getBillingPageUrl()}?&refresh=1`;

    return await nekstApi.post('/billing/subscriptions', {
      priceId,
      successReturnUrl,
      cancelUrl,
    }) as SubscriptionCreationResult;
  };

  const trackIosSubscriptionUpdate = async (
    transactionId: string,
    receipt: string
  ): Promise<TeamBilling> => {
    const result = await nekstApi.post('/billing/subscriptions/ios', {
      transactionId,
      receipt,
    }) as TeamBilling;

    eventPublisher.publish(BILLING_PLAN_UPDATED, {});

    return result;
  };

  const trackGooglePlaySubscriptionUpdate = async (
    purchaseToken: string,
  ): Promise<TeamBilling> => {
    const result = await nekstApi.post('/billing/subscriptions/googleplay', {
      purchaseToken,
    }) as TeamBilling;

    eventPublisher.publish(BILLING_PLAN_UPDATED, {});

    return result;
  };

  const getComplianceErrors = async (): Promise<ComplianceError[]> => {
    return await nekstApi.get('/billing/compliance') as ComplianceError[];
  };

  const validateAppleAccount = async (
    transactionId: string,
    receipt: string,
  ): Promise<ValidateMobilePaymentMethodResult> => {
    return await nekstApi.post('/billing/subscriptions/ios/validateappleaccount', {
      transactionId,
      receipt,
    });
  };

  const validateGooglePlayAccount = async (
    purchaseToken: string,
  ): Promise<ValidateMobilePaymentMethodResult> => {
    return await nekstApi.post('/billing/subscriptions/googleplay/validate', {
      purchaseToken,
    });
  };

  const getRequestSubscriptionPayload = async () => {
    return await nekstApi.get('/billing/subscriptions/googleplay/updatepayload') as GooglePlayUpdateSubscriptionRequest;
  };

  const getCancelSubscriptionPayload = async () => {
    return await nekstApi.get('/billing/subscriptions/googleplay/cancelpayload') as GooglePlayCancelSubscriptionRequest;
  }

  return {
    getBillingData,
    getCurrentUserBillingData,
    getSubscriptionPlans,
    getSubscriptionPlansForPlatform,
    refreshBillingData,
    createBillingPortalSession,
    createSubscription,
    trackIosSubscriptionUpdate,
    trackGooglePlaySubscriptionUpdate,
    getComplianceErrors,
    validateAppleAccount,
    validateGooglePlayAccount,
    getRequestSubscriptionPayload,
    getCancelSubscriptionPayload,
  };
}
