import React, { ReactElement, useMemo } from 'react';
import {
  ShowContext,
  useListContext,
  VisibilityState,
  useFilterContext, PopupContext,
} from 'ui-builder';
import { AbstractTask, TasksFilter } from 'nekst-api';
import moment from 'moment/moment';
import {
  Calendar,
  Event,
  EventWrapperProps,
  momentLocalizer,
  Views,
} from 'react-big-calendar';

import CustomToolbar from './CustomToolbar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { TaskPopoverWrapper } from './TaskPopover';
import styles from './CalendarPage.module.scss';

interface CalendarEvent extends Event {
  task: AbstractTask,
  backgroundColor: string,
}

const mLocalizer = momentLocalizer(moment);

interface CustomWrapperProps extends EventWrapperProps<CalendarEvent> {
  children: ReactElement,
}

function CustomEventWrapper(props: CustomWrapperProps) {
  return (
    <ShowContext data={props.event.task!}>
      <PopupContext>
        <TaskPopoverWrapper>
          <div
            className={styles.eventWrapper}
            style={{ backgroundColor: props.event.backgroundColor }}
          >
            {props.children}
          </div>
        </TaskPopoverWrapper>
      </PopupContext>
    </ShowContext>
  );
}

function luminance(color: string) {
  const rgb = color.match(/\d+/g)!.map((n: string) => parseInt(n, 10));
  return rgb.map((c: number) => {
    // eslint-disable-next-line no-param-reassign
    c /= 255;
    return c <= 0.03928 ? c / 12.92 : ((c + 0.055) / 1.055) ** 2.4;
  })
    .reduce((acc, val) => acc + val * (val < 0.03928 ? 12.92 : 1.055 ** 2.4 - 0.055));
}

function contrastRatio(color1: string, color2: string) {
  const l1 = luminance(color1) + 0.05;
  const l2 = luminance(color2) + 0.05;
  return l1 > l2 ? l1 / l2 : l2 / l1;
}

function useProjectsColorCodingHelper() {

  const defaultColors = ['rgb(83,172,97)', 'rgb(25,127,155)', 'rgb(68,56,3)', 'rgb(10,28,75)', 'rgb(74,114,56)', 'rgb(76,76,76)', 'rgb(138,34,22)', 'rgb(71,107,46)', 'rgb(141,115,20)', 'rgb(129,69,136)', 'rgb(62,67,106)', 'rgb(74,93,29)', 'rgb(69,101,99)', 'rgb(30,150,124)', 'rgb(40,139,20)', 'rgb(41,6,141)', 'rgb(164,30,3)', 'rgb(167,6,85)', 'rgb(64,1,180)', 'rgb(124,82,1)', 'rgb(21,147,24)', 'rgb(21,13,28)', 'rgb(159,60,58)', 'rgb(140,82,65)', 'rgb(54,124,44)', 'rgb(76,1,143)', 'rgb(8,33,169)', 'rgb(27,89,133)', 'rgb(93,110,159)', 'rgb(90,117,113)', 'rgb(57,70,156)', 'rgb(128,30,8)', 'rgb(0,121,80)', 'rgb(46,113,147)', 'rgb(140,67,12)', 'rgb(50,26,141)', 'rgb(159,4,86)', 'rgb(4,21,129)', 'rgb(96,85,166)', 'rgb(113,85,136)', 'rgb(0,73,48)', 'rgb(90,36,12)', 'rgb(82,101,170)', 'rgb(182,89,77)', 'rgb(35,66,100)', 'rgb(90,152,10)', 'rgb(43,80,9)', 'rgb(106,24,75)', 'rgb(116,143,27)', 'rgb(2,7,152)', 'rgb(185,72,75)', 'rgb(97,76,151)', 'rgb(22,92,107)', 'rgb(83,122,132)', 'rgb(141,69,6)', 'rgb(54,50,90)', 'rgb(0,123,66)', 'rgb(51,173,15)', 'rgb(3,26,49)', 'rgb(164,17,80)', 'rgb(106,72,19)', 'rgb(163,56,78)', 'rgb(119,5,113)', 'rgb(29,172,109)', 'rgb(119,156,14)', 'rgb(128,103,110)', 'rgb(50,61,42)', 'rgb(62,60,147)', 'rgb(5,129,110)', 'rgb(15,118,12)', 'rgb(44,92,21)', 'rgb(15,87,156)', 'rgb(10,10,116)', 'rgb(25,93,142)', 'rgb(38,96,176)', 'rgb(70,72,143)', 'rgb(97,47,106)', 'rgb(138,113,62)', 'rgb(136,111,20)', 'rgb(137,8,15)', 'rgb(87,102,6)', 'rgb(189,5,59)', 'rgb(153,83,106)', 'rgb(17,85,35)', 'rgb(31,93,45)', 'rgb(54,123,45)', 'rgb(92,56,132)', 'rgb(23,140,5)', 'rgb(102,19,70)', 'rgb(0,49,123)', 'rgb(107,148,59)', 'rgb(78,1,124)', 'rgb(130,62,24)', 'rgb(143,93,81)', 'rgb(67,26,66)', 'rgb(31,96,94)', 'rgb(3,25,144)', 'rgb(67,89,107)', 'rgb(22,95,78)', 'rgb(1,39,144)'];

  const rgbColors = defaultColors.map((color) => {
    if (color.startsWith('#')) {
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);
      return `rgb(${r},${g},${b})`;
    } else if (color.startsWith('rgb(')) {
      return color;
    } else {
      throw new Error(`Invalid color format: ${color}`);
    }
  });

  const getColorForTask = (task: AbstractTask) => {
    return rgbColors[task.projectId % 100];
  };

  return {
    getColorForTask,
  };
}

export function CalendarColors() {

  const ids = Array.from(
    Array(100)
      .keys(),
  );

  const codingHelper = useProjectsColorCodingHelper();

  return (
    <>
      {ids.map((id) => {
        const color = codingHelper.getColorForTask({ projectId: id } as AbstractTask);
        return (
          <div
            style={{
              backgroundColor: color,
              color: 'white',
              fontSize: '13px',
              height: '17px',
            }}
          >
            Example of task name
            {' '}
            {codingHelper.getColorForTask({ projectId: id } as AbstractTask)}
            {' '}
            {`contrast ratio - ${contrastRatio(color, 'rgb(255,255,255)')}`}
          </div>
        );
      })}
    </>
  );
}

export default function CalendarListLayout() {
  const listContext = useListContext<AbstractTask>();

  const colorsHelper = useProjectsColorCodingHelper();

  const filterContext = useFilterContext<TasksFilter>();

  const events = useMemo<CalendarEvent[]>(() => {
    return listContext
      .data!
      .filter((item) => listContext.getVisibilityState!(item.id) === VisibilityState.VISIBLE)
      .filter((item) => !!item.dueDate)
      .map((item) => ({
        task: item,
        title: `${item.name} - ${item._project.name}`,
        start: new Date(item.dueDate!.date),
        end: new Date(item.dueDate!.date),
        allDay: true,
        id: item.id,
        backgroundColor: colorsHelper.getColorForTask(item),
      }));
  }, [listContext.version, JSON.stringify(filterContext.filterValue)]);

  return (
    <div style={{ height: '80rem' }}>
      <Calendar
        className={listContext.isLoading ? 'loading' : undefined}
        components={{
          // @ts-ignore
          eventWrapper: CustomEventWrapper,
          toolbar: CustomToolbar,
          timeGutterWrapper: () => null,
          timeGutterHeader: () => null,
          dayColumnWrapper: () => null,
        }}
        defaultDate={new Date()}
        events={events}
        localizer={mLocalizer}
        views={[Views.MONTH, Views.WEEK]}
        defaultView={Views.WEEK}
        onRangeChange={(range) => {
          let dateFrom: Date;
          let dateTo: Date;

          if (Array.isArray(range)) {
            dateFrom = range[0];
            dateTo = range[range.length - 1];
          } else {
            dateFrom = range.start;
            dateTo = range.end;
          }

          filterContext.setFilterValue({
            ...filterContext.filterValue!,
            dueDateFrom: dateFrom,
            dueDateTo: dateTo,
          });
        }}
        popup
      />
    </div>
  );
}
