import { useObjectDatesMapper } from "./Helper";
import { useBackendApi } from './backendApi';
import { TaskType } from './Types';

export enum HistoryEvent {
  TASK_CREATED = 'TASK_CREATED',
  PROJECT_CREATED = 'PROJECT_CREATED',
  PROJECT_LAUNCHED = 'PROJECT_LAUNCHED',

  PROJECT_ASSIGNMENT_CREATED = 'PROJECT_ASSIGNMENT_CREATED',
  PROJECT_ASSIGNMENT_REMOVED = 'PROJECT_ASSIGNMENT_REMOVED',

  TASK_COMPLETED = 'TASK_COMPLETED',
  TASK_REOPENED = 'TASK_REOPENED',

  EMAIL_SENT = 'EMAIL_SENT',
  EMAIL_OPENED = 'EMAIL_OPENED',
  SMS_SENT = 'SMS_SENT',
  SMS_SENT_AS_EMAIL = 'SMS_SENT_AS_EMAIL',

  TASK_COMMENT_CREATED = 'COMMENT_CREATED',
  PROJECT_NOTE_CREATED = 'PROJECT_NOTE_CREATED',

  PROJECT_TYPE_UPDATED = 'PROJECT_TYPE_UPDATED',
  PROJECT_STATUS_UPDATED = 'PROJECT_STATUS_UPDATED',
}

export interface PersonData {
  id: number;
  fullName: string;
  isDeleted: boolean;
}

export interface ProjectData {
  id: number;
  name: string;
  isDeleted: false;
}

export interface TaskData {
  id: number;
  name: string;
  type: TaskType;
  isPrivate: boolean;
  isKeyDate: boolean;
  isDeleted: boolean;
}

export interface RoleData {
  id: number,
  name: string,
  isDeleted: boolean
}

export interface CommentData {
  id: number;
  text: string;
  isDeleted: boolean;
}

export interface NoteData {
  id: number;
  text: string;
  isDeleted: boolean;
}

export interface PlanData {
  id: number,
  name: string,
  isDeleted: boolean
}

export interface BaseHistoryEntryData {
  person?: PersonData,
  project: ProjectData,
}

export interface HistoryEntry<DataType = Record<string, any>> {
  event: HistoryEvent,
  when: Date,
  data: DataType
}

export interface ProjectHistoryFilter {
  authors: {
    selectAll: boolean,
    selected: number[],
  },
  eventTypes: {
    selectAll: boolean,
    selected: HistoryEvent[]
  },
  text: string | null,
}

export function useProjectHistoryService() {
  const projectApi = useBackendApi();

  const { mapList } = useObjectDatesMapper<HistoryEntry>([], ['when']);

  const getProjectHistoryEvents = async (projectId: number) => {
    const entries = await projectApi.get(`/projects/${projectId}/history`);

    return mapList(entries)
      .filter((v: HistoryEntry) => ![HistoryEvent.TASK_CREATED, HistoryEvent.TASK_REOPENED]
        .includes(v.event));
  };

  return {
    getProjectHistoryEvents,
  };
}
