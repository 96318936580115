import React, { useEffect, useRef, useState } from 'react';

import styles from '../TransactionParties.module.scss';
import { BaseInputProps } from '../../../../shared/uibuilder/form/input';
import ErrorsWrapper from '../../../../shared/uibuilder/form/input/layout/ErrorsWrapper';
import RelativeRow, { Cell, EmptyCell } from '../../../../shared/web/uitheme/form/RelativeRow';
import Checkbox from '../../../../shared/uibuilder/form/input/Checkbox';
import BriefcaseCheckbox from '../BriefcaseCheckbox';
import useTransactionPartiesField from '../transactionPartiesField';
import { FormFieldsData, useFormContext } from 'ui-builder';
import { ProjectRole, useProjectRolesService } from 'nekst-api';

type TransactionPartyDto = {
  projectRoleId: number,
}

function useEmailRecipientsField(projectRoles: any[] | undefined) {
  const formContext = useFormContext();

  const getData = (): FormFieldsData => {
    return formContext.data || {};
  };

  const [value, setValue] = useState<Record<number, string | null>>({});

  const getValue = () => {
    return value || {};
  };

  const mapToUiView = (): any => {
    if (getData() && getData().emailRecipients && getData().emailRecipients.length > 0) {
      const result: Record<number, string | null> = {};
      getData()
        .emailRecipients
        .forEach((recipient: any) => {
          result[recipient.projectRoleId] = recipient.type;
        });

      setValue(result);
    }
  };

  const mapToApiView = (data: any): any => {
    const result: any[] = [];

    Object.entries(data as Record<number, string | undefined>)
      .forEach(
        ([id, v]) => {
          if (v) {
            result.push({
              projectRoleId: parseInt(id, 10),
              type: v,
            });
          }
        },
      );

    return result;
  };

  const changeValue = (id: number, type: string | undefined) => {
    const currentValue = getValue();
    if (type) {
      currentValue[id] = type;
    } else {
      delete currentValue[id];
    }

    formContext.onChangeCallback!({
      emailRecipients: mapToApiView(currentValue),
    });
  };

  const isSelected = (id: number, type: string): boolean => {
    return value[id] === type;
  };

  useEffect(
    () => {
      mapToUiView();
    },
    [JSON.stringify(getData().emailRecipients), JSON.stringify(projectRoles)],
  );

  return {
    changeValue,
    isSelected,
  };
}

export default function EmailTransactionPartiesInput(props: BaseInputProps<TransactionPartyDto[]>) {
  const projectRolesService = useProjectRolesService();
  const [projectRoles, setProjectRoles] = useState<FormFieldsData[]>();

  const ref = useRef<HTMLDivElement>(null);

  const transactionPartiesField = useTransactionPartiesField(projectRoles as ProjectRole[]);
  const emailRecipientsField = useEmailRecipientsField(projectRoles);

  const loadRoles = async () => {
    const roles = await projectRolesService.getAllTeamRoles();

    setProjectRoles(roles);
  };

  useEffect(() => {
    loadRoles();
  }, []);

  useEffect(() => {
    ref.current?.addEventListener('mousewheel', (e) => {
      e.stopPropagation();
    });
  });

  if (projectRoles) {
    return (
      <ErrorsWrapper name="emailRecipients">
        <ErrorsWrapper name="transactionParties">
          <div className={styles.block} ref={ref}>
            <div className={styles.title}>
              {props.label}
              &nbsp;
              (
              {transactionPartiesField.getAssigneesNumber()}
              )
            </div>

            <div className={styles.table}>
              <RelativeRow
                weights={[4, 1, 3, 2, 2]}
                rowClassName={styles.tableHead}
                cellClassName={styles.cell}
              >
                <Cell>
                  Assigned To:
                </Cell>
                <EmptyCell />
                <Cell centered>
                  Receive Email
                </Cell>
                <Cell centered>
                  CC
                </Cell>
                <Cell centered>
                  BCC
                </Cell>
              </RelativeRow>
              <div className={styles.tableBody}>
                {projectRoles.map(
                  (projectRole) => {
                    return (
                      <RelativeRow
                        key={`projectRole-${projectRole.id}`}
                        weights={[4, 1, 3, 2, 2]}
                        rowClassName={styles.row}
                        cellClassName={styles.cell}
                      >
                        {projectRole.isAssignable ? (
                          <BriefcaseCheckbox
                            source={`assigned.${projectRole.id}`}
                            label={projectRole.name}
                            value={transactionPartiesField.isRoleAssigned(projectRole.id)}
                            isDisabled={props.isDisabled}
                            onChangeCallback={() => {
                              transactionPartiesField.changeValue(projectRole.id);
                            }}
                          />
                        ) : (<Cell className={styles.noAssignment}>{projectRole.name}</Cell>)}
                        <EmptyCell />
                        <Cell centered className={styles.checkboxCell}>
                          <Checkbox
                            source={`receiveEmail.${projectRole.id}`}
                            label=""
                            value={emailRecipientsField.isSelected(projectRole.id, 'RECIPIENT')}
                            onChangeCallback={(data) => {
                              const selected = data[`receiveEmail.${projectRole.id}`];
                              if (selected) {
                                emailRecipientsField.changeValue(
                                  projectRole.id,
                                  'RECIPIENT',
                                );
                              } else {
                                emailRecipientsField.changeValue(
                                  projectRole.id,
                                  undefined,
                                );
                              }
                            }}
                          />
                        </Cell>
                        <Cell centered className={styles.checkboxCell}>
                          <Checkbox
                            source={`cc.${projectRole.id}`}
                            label=""
                            value={emailRecipientsField.isSelected(projectRole.id, 'CC')}
                            onChangeCallback={(data) => {
                              const selected = data[`cc.${projectRole.id}`];
                              if (selected) {
                                emailRecipientsField.changeValue(
                                  projectRole.id,
                                  'CC',
                                );
                              } else {
                                emailRecipientsField.changeValue(
                                  projectRole.id,
                                  undefined,
                                );
                              }
                            }}
                          />
                        </Cell>
                        <Cell centered className={styles.checkboxCell}>
                          <Checkbox
                            source={`bcc.${projectRole.id}`}
                            label=""
                            value={emailRecipientsField.isSelected(projectRole.id, 'BCC')}
                            onChangeCallback={(data) => {
                              const selected = data[`bcc.${projectRole.id}`];
                              if (selected) {
                                emailRecipientsField.changeValue(
                                  projectRole.id,
                                  'BCC',
                                );
                              } else {
                                emailRecipientsField.changeValue(
                                  projectRole.id,
                                  undefined,
                                );
                              }
                            }}
                          />
                        </Cell>
                      </RelativeRow>
                    );
                  },
                )}
              </div>
            </div>
          </div>
        </ErrorsWrapper>
      </ErrorsWrapper>
    );
  } else {
    return null;
  }
}
