import { useEffect, useMemo, useState } from 'react';
import { PlanTask, ProjectRole, usePlanTasksService, useProjectPlanService } from 'nekst-api';
import { useProjectRolesLoader } from 'features/tasks-forms';

export interface AssignedRolesHelper {
  getRolesAssignedToTasks: () => ProjectRole[],
  getEmailRecipientRoles: () => ProjectRole[],
  getSmsRecipientRoles: () => ProjectRole[],
}

export default function useAssignedRolesHelper(
  projectId: number,
): AssignedRolesHelper {
  const [tasks, setTasks] = useState<PlanTask[]>([]);

  const projectPlanService = useProjectPlanService();

  const planTasksService = usePlanTasksService();

  useEffect(() => {
    async function loadTasks() {
      const p = await projectPlanService.getPlanByProjectId(projectId);
      const t = await planTasksService.getPlanTasks(p.id);
      setTasks(t);
    }

    loadTasks();
  }, []);

  const { roles } = useProjectRolesLoader();

  const [
    rolesAssignedToTasks,
    rolesReceivingEmails,
    rolesReceivingSms,
  ] = useMemo(() => {
    const assignedRolesMap: Record<number, true> = [];
    const receivingEmailsMap: Record<number, true> = [];
    const receivingSmsMap: Record<number, true> = [];
    if (roles.length > 0) {
      tasks.forEach((task: PlanTask) => {
        task.transactionParties.forEach((v) => {
          assignedRolesMap[v.projectRoleId] = true;
        });

        (task.emailRecipients || []).forEach((v) => {
          receivingEmailsMap[v.projectRoleId] = true;
        });

        (task.smsRecipients || []).forEach((v) => {
          receivingSmsMap[v.projectRoleId] = true;
        });

        if (task.sendFromRoleId) {
          receivingEmailsMap[task.sendFromRoleId] = true;
        }
      });

      const rolesWithAssignees = roles.filter((role) => assignedRolesMap[role.id]);

      const emailRecipientRoles = roles.filter((role) => receivingEmailsMap[role.id]);

      const smsRecipientRoles = roles.filter((role) => receivingSmsMap[role.id]);

      return [rolesWithAssignees, emailRecipientRoles, smsRecipientRoles];
    } else {
      return [[], [], []];
    }
  }, [roles.length, tasks.length]);

  const getRolesAssignedToTasks = () => {
    return rolesAssignedToTasks;
  };

  const getEmailRecipientRoles = () => {
    return rolesReceivingEmails;
  };

  const getSmsRecipientRoles = () => {
    return rolesReceivingSms;
  };

  return {
    getRolesAssignedToTasks,
    getEmailRecipientRoles,
    getSmsRecipientRoles,
  };
}
