import React, { ReactNode } from 'react';
import { VisibilityStateClientSideFilter } from 'ui-builder';
import { usePlanTasksTypeFilterService } from './planTasksTypeFilterService';
import { usePlanTaskAssigneeFilterService } from './planTasksAssigneeFilterService';
import { usePlanTasksSmsRecipientFilterService } from './planTasksSmsEmailRecipientFilterService';
import { usePlanTasksNameFilterService } from './planTasksNameFilterService';
import { usePlanTasksExclusionFilterService } from './planTasksExclusionFilterService';
import { useTasksGroupsFilterService } from './tasksGroupsFilterService';

interface PlanTasksClientSideFilterProps {
  children: ReactNode;
}

export function PlanTasksClientSideFilter(props: PlanTasksClientSideFilterProps) {
  return (
    <VisibilityStateClientSideFilter
      filters={[
        usePlanTasksTypeFilterService(),
        usePlanTaskAssigneeFilterService(),
        usePlanTasksSmsRecipientFilterService(),
        usePlanTasksNameFilterService(),
        usePlanTasksExclusionFilterService(),
        useTasksGroupsFilterService(),
        usePlanTasksExclusionFilterService(),
      ]}
      parentIdField="parentTaskRelation.parentTaskId"
    >
      {props.children}
    </VisibilityStateClientSideFilter>
  );
}

