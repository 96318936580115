import React, { useEffect } from 'react';
import { TasksGroup } from 'nekst-api';
import { useProjectContext } from '../../../../ProjectContext';
import { CheckboxGroup, FormTemplate, Option } from 'ui-builder';
import BorderedBox from '../../../../../../shared/web/layout/BorderedBox';
import useUsedTasksGroupsService from './usedTasksGroupsService';
import useTasksGroupsFilterService from './tasksGroupsFilterService';
import { NO_GROUP_LABEL } from 'features/tasks-forms';
import { TasksGroupIcon } from 'features/nekst-widgets';
import { createPortal } from 'react-dom';

import styles from './TasksGroupFilter.module.scss';
import { ToggledFeature, withToggleWrapper } from 'feature-toggle';

export function TasksGroupFilterContainer() {
  return (
    <div id="tasks_group_filter" />
  );
}

function InContainerRenderer(
  props: { children: React.ReactNode }
) {
  const container = document.getElementById('tasks_group_filter');

  if (container) {
    return createPortal(
      props.children,
      container
    );
  } else {
    return null;
  }
}

function TasksGroupFilter() {
  const service = useUsedTasksGroupsService();
  const projectContext = useProjectContext();

  const [tasksGroups, setTasksGroups] = React.useState<TasksGroup[]>();

  const projectId = projectContext.data?.id;

  const filterService = useTasksGroupsFilterService();

  useEffect(() => {
    async function loadTasksGroups() {
      const result = await service.getUsedTasksGroupsService(projectId!);
      setTasksGroups(result);
    }

    if (projectId) {
      loadTasksGroups();
    }
  }, [projectId]);

  let resultElement;

  if (tasksGroups && tasksGroups?.length > 0) {
    const options: Option<number>[] = tasksGroups.map((group) => ({
      value: group.id,
      label: <TasksGroupIcon group={group} withName />,
      _data: group,
    }));

    options.push({
      value: 0,
      label: NO_GROUP_LABEL,
    });

    const getValue = () => {
      const filterValue = filterService.getFilterValue() || [];

      if (filterValue.length === 0) {
        return options.map((o) => o.value);
      } else {
        return filterValue;
      }
    }

    resultElement = (
      <BorderedBox title="Groups" className={styles.filter}>
        <FormTemplate<{ tasksGroupsIds: number[] }>
          submitFormFunc={async (values) => {
            filterService.setFilterValue(values.tasksGroupsIds);
            return values;
          }}
          buttons={null}
          submitOnEachChange
          initialData={{
            tasksGroupsIds: getValue(),
          }}
        >
          <CheckboxGroup
            options={options}
            source="tasksGroupsIds"
            hideLabel
          />
        </FormTemplate>
      </BorderedBox>
    );
  } else {
    resultElement = null;
  }

  return (
    <InContainerRenderer>
      {resultElement}
    </InContainerRenderer>
  )
}

export default withToggleWrapper(TasksGroupFilter, ToggledFeature.TASKS_GROUPS);
