import React from 'react';
import { useStripeCustomerPortalService } from 'nekst-api';
import { ButtonSize, OptionSelectionButton } from 'ui-builder';

export function useStripePortalNavigator() {
  const stripeCustomerPortal = useStripeCustomerPortalService();

  return {
    navigateToUpdateSubscription: async () => {
      window.location.href = await stripeCustomerPortal.createUpdateSubscriptionLink();
    },
    navigateToUpdatePaymentMethod: async () => {
      window.location.href = await stripeCustomerPortal.createUpdatePaymentMethodLink();
    },
    navigateToBillingInformation: async () => {
      window.location.href = await stripeCustomerPortal.createPortalAccessLink();
    }
  };
}

export default function ManageSubscriptionOptionsButton() {
  const stripeCustomerPortal = useStripeCustomerPortalService();

  return (
    <OptionSelectionButton
      text="Manage Subscription"
      options={[
        {
          value: 'update-subscription',
          label: 'Update Subscription Plan',
          onSelected: async () => {
            window.location.href = await stripeCustomerPortal.createUpdateSubscriptionLink();
          }
        },
        {

          value: 'update-payment-method',
          label: 'Update Payment Method',
          onSelected: async () => {
            window.location.href = await stripeCustomerPortal.createUpdatePaymentMethodLink();
          }
        },
        {
          value: 'access-portal',
          label: 'View Billing Information',
          onSelected: async () => {
            window.location.href = await stripeCustomerPortal.createPortalAccessLink();
          }
        }
      ]}
      size={ButtonSize.MEDIUM}
    />
  );
}
