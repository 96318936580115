import { ReactNode, useEffect, useState } from 'react';
import { PaymentError, ValidationError } from 'request-sender';
import { FormContextData, FormFieldsData } from './FormContext';
import _, { set } from 'lodash';
import useValidator, {
  FieldValidationSchema,
  ValidationErrors,
  ValidationSchema
} from 'validation-schema';
import { ViewStyle } from 'react-native';
import { FormLayoutProps } from './types';
import { useEventsPublisher } from 'event-bus';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export interface AfterSubmitProps<ExecuteType, RedirectType = string> {
  redirect?: RedirectType;
  message?: string;
  execute?: ExecuteType;
}

function cloneDeep(object: any) {
  return _.cloneDeep(object);
}

export type GetDataFunc<DataType = FormFieldsData> = Nullable<() => Promise<DataType>>;
export type SubmitFormFunc<DataType = FormFieldsData, ResponseType = DataType>
  = (values: DataType) => Promise<ResponseType>;
export type AfterSubmitFunc<DataType = FormFieldsData> = (data: DataType) => Promise<void>;

export interface FormAttributes<DataType = FormFieldsData, ResponseType = DataType> {
  initialData?: Partial<DataType>;
  children: ReactNode
  getDataFunc?: GetDataFunc<DataType>;
  beforeValidationFunc?: (data: DataType) => DataType,
  submitFormFunc: SubmitFormFunc<DataType, ResponseType>,
  afterSubmitFunc?: AfterSubmitFunc<ResponseType>,
  validationSchema?: ValidationSchema | ((data: DataType) => ValidationSchema),
  clearAfterSubmit?: boolean,
  buttons?: ReactNode,
  title?: ReactNode,
  className?: string,
  containerStyle?: ViewStyle,
  formBodyStyle?: ViewStyle,
  buttonsBlockStyle?: ViewStyle,
  submitOnEachChange?: boolean,
  isDisabled?: boolean | ((data: DataType) => boolean)
  layout?: ReactComponent<FormLayoutProps>,
  testID?: string,
  updateFormDataAfterSubmit?: boolean,
  cancelFunc?: () => Promise<void>,
  dependencies?: any[],
}

export const VALIDATION_ERROR_OCCURRED = 'VALIDATION_ERROR_OCCURRED';

/**
 * Hook includes common logic of form components.
 *
 * @param formAttributes
 */
function useForm<DataType = FormFieldsData, ReponseType = DataType>(
  formAttributes: FormAttributes<DataType, ReponseType>,
): FormContextData<DataType> {
  const [_rendered, setRendered] = useState(false);

  const [disabledAutoSubmit, setDisableAutoSubmit] = useState(false);

  const {
    initialData,
  } = formAttributes;

  const validator = useValidator();

  const eventsPublisher = useEventsPublisher();

  /**
   * Contains form data.
   */
  const [data, setData] = useState<DataType | null>((initialData || {}) as DataType);

  const [disabled, setDisabled] = useState<boolean>(false);

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const [errors, setErrors] = useState<ValidationErrors>({});

  const [loading, setLoading] = useState(false);

  const shouldSubmitOnEachChange = () => {
    return !!formAttributes.submitOnEachChange;
  };

  const { submitFormFunc } = formAttributes;

  useEffect(() => {
    setData((initialData || {}) as DataType);
  }, formAttributes.dependencies || []);

  const onChangeCallback = (values: FormFieldsData): void => {

    setData((prevData) => {
      const copyPrev = cloneDeep(prevData);

      Object.entries(values)
        .forEach((entry) => {
          const [source, value] = entry;
          set(copyPrev, source, value);
        });

      return copyPrev;
    });
  };

  useEffect(() => {
    setRendered(true);
  }, []);


  useEffect(() => {
    if (typeof formAttributes.isDisabled === 'boolean') {
      setDisabled(formAttributes.isDisabled!)
    }
  }, [formAttributes.isDisabled])

  const getValidationSchema = () => {
    if (formAttributes.validationSchema) {
      if (typeof formAttributes.validationSchema === 'function') {
        return formAttributes.validationSchema(data!);
      } else {
        return formAttributes.validationSchema;
      }
    } else {
      return null;
    }
  };

  const isValid = async (submittedData: DataType): Promise<boolean> => {
    const schema = getValidationSchema();
    if (schema) {
      const result = await validator.validate(submittedData, schema);

      setErrors(result);
      if (Object.keys(result).length === 0) {
        return true;
      } else {
        eventsPublisher.publish(VALIDATION_ERROR_OCCURRED, result);
        return false;
      }
    } else {
      return true;
    }
  };

  const clearForm = async () => {
    setData({} as DataType);
  };

  const shouldClearAfterSubmit = () => {
    return formAttributes.clearAfterSubmit || false;
  };

  /**
   * Function triggers form submission.
   */
  const submitForm = async (callAfterSubmit = true) => {
    let submittedData;
    if (formAttributes.beforeValidationFunc) {
      submittedData = formAttributes.beforeValidationFunc(data!);
    } else {
      submittedData = data!;
    }
    if (await isValid(submittedData)) {
      setLoading(true);

      try {
        const response = await submitFormFunc(submittedData);

        if (callAfterSubmit && formAttributes.afterSubmitFunc !== undefined) {
          await formAttributes.afterSubmitFunc(response);
        }

        if (formAttributes.updateFormDataAfterSubmit) {
          setData(response as DataType);
        }

        if (shouldClearAfterSubmit()) {
          clearForm();
        }

        return true;
      } catch (e) {
        if (e instanceof ValidationError) {
          setErrors(e.errors);
          eventsPublisher.publish(VALIDATION_ERROR_OCCURRED, e.errors);
        } else if (e instanceof PaymentError) {
          setErrors({
            __form: ['Payment error occurred. Please update your payment method.']
          })
        } else {
          setErrors({
            __form: ['Unknown error occurred. Please try again later or contact support']
          })
          // eslint-disable-next-line no-console
          console.error(e);
        }

        return false;
        // eslint-disable-next-line no-empty
      } finally {
        setLoading(false);
      }
    } else {
      return false;
    }

  };

  useEffect(() => {
    if (_rendered && shouldSubmitOnEachChange() && !disabledAutoSubmit) {
      submitForm();
    }

    setDisableAutoSubmit(false);

    if (typeof formAttributes.isDisabled === 'function') {
      setDisabled(formAttributes.isDisabled(data!));
    }
  }, [JSON.stringify(data)]);

  const setFormData = (formData: DataType) => {
    setData(formData);
    setDisableAutoSubmit(true);
  };

  const getFieldErrors = (source: string): string[] => {
    return errors[source] || [];
  };

  const isDisabled = () => {
    return disabled;
  };

  const getConstraintValue = (fieldName: string, constraintName: string) => {
    const validationSchema = getValidationSchema();
    if (validationSchema) {
      const chunks = fieldName.split('.');

      let fieldSchema: FieldValidationSchema | undefined = validationSchema[chunks[0]];

      let i = 1;
      while (!!fieldSchema && i < chunks.length) {
        if (fieldSchema.properties) {
          fieldSchema = fieldSchema.properties[chunks[i]];
        } else {
          fieldSchema = undefined;
        }
        i += 1;
      }

      if (fieldSchema) {
        if (typeof fieldSchema.constraints[constraintName] === 'object') {
          return fieldSchema.constraints[constraintName].value;
        } else {
          return fieldSchema.constraints[constraintName];
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const isFieldRequired = (fieldName: string) => {
    return getConstraintValue(fieldName, 'required');
  };

  const __setErrors = (value: Record<string, string[]>) => {
    setErrors(value);
  };

  const isSubmitDisabled = () => {
    return submitDisabled || isDisabled();
  }

  return {
    data,
    loading,
    onChangeCallback,
    setFormData,
    submitForm,
    getFieldErrors,
    clearForm,
    isDisabled,
    setDisabled,
    getConstraintValue,
    isFieldRequired,
    setSubmitDisabled,
    isSubmitDisabled,
    __setErrors,
  };
}

export { useForm };
