import React, { useEffect } from 'react';
import { ProjectDetailsField, ProjectDetailsFieldType } from 'nekst-api';
import {
  CheckboxInput,
  DateInput,
  Dropdown,
  InputLabel,
  NumberInput,
  RelativeRow,
  TextArea,
  TextInput,
  useFormContextRequired
} from 'ui-builder';

function isNotPresentValue(value: string | null | undefined): boolean {
  return value?.toLowerCase() === 'not present' || value?.toLowerCase() === 'n/a';
}

function DateExampleValueInput() {
  const formContext = useFormContextRequired();

  const data = formContext.data;

  let value;
  if (data?.exampleValue === 'not present') {
    value = null;
  } else  {
    value = data?.exampleValue ? new Date(data?.exampleValue) : undefined;
  }

  return (
    <DateInput
      source="exampleValue"
      label="Example Value"
      value={value}
      isDisabled={data?.noExampleValue}
    />
  )
}

function DependsFieldValue() {
  const formContext = useFormContextRequired();

  const data = formContext.data;

  const parts = !isNotPresentValue(data?.exampleValue) ? (data?.exampleValue ?? '').split(',') : [];

  const [when, setWhen] = React.useState<string>(parts[1] ?? undefined);

  const [days, setDays] = React.useState<number | undefined>(parts[0] ? parseInt(parts[0]) : undefined);

  useEffect(() => {
    if (days !== undefined && when !== undefined) {
      formContext.onChangeCallback({
        exampleValue: `${days},${when}`,
      });
    }
  }, [when, days]);

  return (
    <div style={{ maxWidth: '30rem' }}>
      <InputLabel source="exampleValue" label="Example Value (days before/after parent box)" />
      <RelativeRow weights={[5, 7]}>
        <NumberInput
          source="days"
          hideLabel
          value={days}
          onChangeCallback={(newData) => {
            setDays(newData['days']);
          }}
          isDisabled={data?.noExampleValue}
        />
        <Dropdown
          options={[
            {
              label: 'Before',
              value: 'before'
            },
            {
              label: 'After',
              value: 'after'
            },
          ]}
          source="when"
          onChangeCallback={(newData) => {
            setWhen(newData['when']);
          }}
          value={when}
          hideLabel
          isClearable={false}
          isDisabled={data?.noExampleValue}
        />
      </RelativeRow>
    </div>

  );
}

export default function ExampleValueInput(
  props: {
    field: ProjectDetailsField,
  }
) {


  const formContext = useFormContextRequired();

  const getInputElement = () => {
    if (props.field.fieldType === ProjectDetailsFieldType.LONG_TEXT) {
      return (
        <TextArea source="exampleValue" label="Example Value" small isDisabled={formContext.data?.noExampleValue} />
      );
    } else if (props.field.fieldType === ProjectDetailsFieldType.SHORT_TEXT) {
      return (
        <TextInput source="exampleValue" label="Example Value" isDisabled={formContext.data?.noExampleValue} />
      );
    } else if (props.field.fieldType === ProjectDetailsFieldType.DATE) {
      if (props.field.parentFieldId) {
        return (
          <DependsFieldValue />
        );
      } else {
        return (
          <DateExampleValueInput />
        );
      }
    } else if (props.field.fieldType === ProjectDetailsFieldType.PRICE) {
      return (
        <NumberInput source="exampleValue" label="Example Value" />
      );
    } else {
      return null;
    }
  };

  const inputElement = getInputElement();

  const [previousValue, setPreviousValue] = React.useState<Date | string | undefined>(undefined);


  useEffect(() => {
    if(formContext.data?.noExampleValue) {
      setPreviousValue(formContext.data?.exampleValue);
      formContext.onChangeCallback({
        exampleValue: 'not present',
      });
    } else {
      if (isNotPresentValue(formContext.data?.exampleValue)) {
        formContext.onChangeCallback({
          exampleValue: previousValue,
        });
      }
    }
  }, [formContext.data?.noExampleValue]);

  useEffect(() => {
    if (isNotPresentValue(formContext.data?.exampleValue)) {
      formContext.onChangeCallback({
        noExampleValue: true,
      });
    }
  }, []);

  return (
    <>
      {inputElement}
      <div style={{
        display: 'flex',
        gap: '1.5rem',
      }}>
        or
        {' '}
        <CheckboxInput source="noExampleValue" label="not present in the example" />
      </div>
    </>
  );
}
