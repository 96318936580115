import React from 'react';
import CreateForm from './CreateForm';
import { useAuthorizationChecker } from 'authorization-scope';
import { Button, ButtonColor, ButtonSize, IconType } from 'ui-builder';
import { TeamPermission } from 'nekst-api';
import { usePopupFormManager } from 'features/nekst-widgets';

export default function CreatePlanButton() {
  const popupManager = usePopupFormManager();

  const { isGranted } = useAuthorizationChecker();

  const openCreatePlanForm = async () => {
    popupManager.openForm(
      <CreateForm />,
      true,
    );
  };

  if (isGranted(TeamPermission.CREATE_PLAN)) {
    return (
      <Button
        text="Create new plan"
        onClick={openCreatePlanForm}
        size={ButtonSize.BIG}
        isFilled
        color={ButtonColor.BLUE}
        iconType={IconType.PLUS}
      />
    );
  } else {
    return null;
  }
}
