import React, { ReactElement, useContext, useMemo } from 'react';
import {
  CheckboxInputLayoutProps,
  FormButtonLayoutProps,
  FormLayoutProps,
  FormRowLayoutProps,
  FormRowsGroupLayoutProps,
  PasswordLayoutProps,
  TextLayoutProps,
} from './Form';
import { ButtonLayoutProps } from './Button';
import {
  BlockLayoutProps,
  HeaderLayoutProps,
  SimpleTextLayoutProps,
  TextLinkLayoutProps,
} from './Common';
import { ShowLayoutProps } from './Show';
import { LoadingAnimationLayoutProps } from './Common/LoadingAnimation';
import { TextFieldLayoutProps } from './Fields/TextField';
import { CheckboxGroupWithSelectAllLayoutProps } from './Form/Input/CheckboxGroupWithSelectAll';
import { CheckboxGroupLayoutProps } from './Form/Input/CheckboxGroup';
import { AvatarLayoutProps } from './Widgets/Avatar';
import { DropdownLayoutProps } from './Form/Input/Dropdown';
import { DateInputLayoutProps } from './Form/Input/DateInput';
import { TimeInputLayoutProps } from './Form/Input/TimeInput';
import { RelativeRowCellLayoutProps, RelativeRowGroupLayoutProps, } from './Common/RelativeRow';
import { ErrorsWrapperLayoutProps } from './Form/Input/ErrorsWrapper';
import { TextAreaLayoutProps } from './Form/Input/TextArea';
import { MultiSelectDropdownLayoutProps } from './Form/Input/MultiSelectDropdown';
import { WysiwygLayoutProps } from './Form/Input/Wysiwyg';
import { SmartTagsWysiwygLayoutProps } from './Form/Input/SmartTagsWysiwyg';
import { DateTimeFieldLayoutProps } from './Fields/DateTimeField';
import { ItemsListLayoutProps } from './Common/ItemsList';
import { OptionSelectionButtonLayoutProps } from './Widgets/OptionSelectionButton';
import { InputLabelLayoutProps } from './Form/Input/InputLabel';
import { RadioButtonLayoutProps } from './Form/Input/RadioButton';
import {
  RadioButtonsGroupLayoutProps,
} from './Form/Input/RadioButtonsGroup';
import { PriceLayoutProps } from './Form/Input/PriceInput';
import { SmartTagsTextInputLayoutProps } from './Form/Input/SmartTagsTextInput';
import { SmartTagsTextAreaLayoutProps } from './Form/Input/SmartTagsTextArea';

export interface ButtonsTheme {
  DefaultButtonLayout: ReactComponent<ButtonLayoutProps>
  SubmitButtonLayout: ReactComponent<FormButtonLayoutProps>,
  CancelButtonLayout: ReactComponent<FormButtonLayoutProps>,
}

export interface FormsThemeType {
  FormRowLayout: ReactComponent<FormRowLayoutProps>,
  FormRowsGroupLayout: ReactComponent<FormRowsGroupLayoutProps>,
  FormLayout: ReactComponent<FormLayoutProps>,
}

export interface InputsThemeType {
  ErrorsWrapperLayout: ReactComponent<ErrorsWrapperLayoutProps>,
  TextLayout: ReactComponent<TextLayoutProps>,
  PriceLayout: ReactComponent<PriceLayoutProps>,
  PasswordLayout: ReactComponent<PasswordLayoutProps>,
  CheckboxLayout: ReactComponent<CheckboxInputLayoutProps>,
  CheckboxGroupWithSelectAllLayout: ReactComponent<CheckboxGroupWithSelectAllLayoutProps>
  CheckboxGroupLayout: ReactComponent<CheckboxGroupLayoutProps<any>>
  BaseDropdownLayout: ReactComponent<DropdownLayoutProps>,
  MultiSelectDropdownLayout: ReactComponent<MultiSelectDropdownLayoutProps<any>>
  DateInputLayout: ReactComponent<DateInputLayoutProps>,
  TimeInputLayout: ReactComponent<TimeInputLayoutProps>,
  TextAreaLayout: ReactComponent<TextAreaLayoutProps>,
  WysiwygLayout: ReactComponent<WysiwygLayoutProps>,
  SmartTagsWysiwygLayout: ReactComponent<SmartTagsWysiwygLayoutProps>,
  SmartTagsTextInputLayout: ReactComponent<SmartTagsTextInputLayoutProps>,
  SmartTagsTextAreaLayout: ReactComponent<SmartTagsTextAreaLayoutProps>,
  InputLabelLayout: ReactComponent<InputLabelLayoutProps>,
  RadioButtonLayout: ReactComponent<RadioButtonLayoutProps>,
  RadioButtonsGroupLayout: ReactComponent<RadioButtonsGroupLayoutProps>
}

export interface CommonThemeType {
  TextLinkLayout: ReactComponent<TextLinkLayoutProps>,
  TextLayout: ReactComponent<SimpleTextLayoutProps>,
  RightLayout: ReactComponent<BlockLayoutProps>,
  LeftLayout: ReactComponent<BlockLayoutProps>,
  CenterLayout: ReactComponent<BlockLayoutProps>,
  LoadingAnimationLayout: ReactComponent<LoadingAnimationLayoutProps>,
  Header1Layout: ReactComponent<HeaderLayoutProps>
  Header2Layout: ReactComponent<HeaderLayoutProps>
  Header3Layout: ReactComponent<HeaderLayoutProps>
  Header4Layout: ReactComponent<HeaderLayoutProps>
  Header5Layout: ReactComponent<HeaderLayoutProps>
  Header6Layout: ReactComponent<HeaderLayoutProps>
  Error403Layout: ReactComponent<any>,
  RelativeRowGroupLayout: ReactComponent<RelativeRowGroupLayoutProps>,
  RelativeRowCellLayout: ReactComponent<RelativeRowCellLayoutProps>,
  FormBlockLayout: ReactComponent<BlockLayoutProps>,
  SimpleBlockLayout: ReactComponent<BlockLayoutProps>,
}

export interface ShowTheme {
  ShowLayout: ReactComponent<ShowLayoutProps>,
}

export interface FieldsTheme {
  TextFieldLayout: ReactComponent<TextFieldLayoutProps>,
  DateTimeFieldLayout: ReactComponent<DateTimeFieldLayoutProps>,
}

export interface WidgetsTheme {
  AvatarLayout: ReactComponent<AvatarLayoutProps>,
  ItemsListLayout: ReactComponent<ItemsListLayoutProps>,
  OptionSelectionButtonLayout: ReactComponent<OptionSelectionButtonLayoutProps>,
}

export interface NewUiTheme {
  inputs: InputsThemeType,
  forms: FormsThemeType,
  buttons: ButtonsTheme,
  common: CommonThemeType,
  show: ShowTheme,
  fields: FieldsTheme,
  widgets: WidgetsTheme,
}

const FormUiThemeContext = React.createContext<
  NewUiTheme | undefined
>(undefined);

const FormUiThemeOverriderContext = React.createContext<{
  inputs?: Partial<InputsThemeType>,
  forms?: Partial<FormsThemeType>,
  buttons?: Partial<ButtonsTheme>,
  common?: Partial<CommonThemeType>,
  show?: Partial<ShowTheme>,
  fields?: Partial<FieldsTheme>,
  widgets?: Partial<WidgetsTheme>,
}>({});

export function useNewUiTheme(): NewUiTheme {
  const mainContext = useContext(FormUiThemeContext);
  const overrider = useContext(FormUiThemeOverriderContext);

  if (!mainContext) {
    throw new Error('No UI theme provider');
  }

  return {
    inputs: {
      ...mainContext.inputs,
      ...overrider.inputs || {},
    },
    forms: {
      ...mainContext.forms,
      ...overrider.forms || {},
    },
    buttons: {
      ...mainContext.buttons,
      ...overrider.buttons || {},
    },
    common: {
      ...mainContext.common,
      ...overrider.common || {},
    },
    show: {
      ...mainContext.show,
      ...overrider.show || {},
    },
    fields: {
      ...mainContext.fields,
      ...overrider.fields || {},
    },
    widgets: {
      ...mainContext.widgets,
      ...overrider.widgets || {},
    }
  };
}

export function NewUiTheme(
  props: {
    value: NewUiTheme,
    children: ListOrSingle<ReactElement>,
  },
) {
  return (
    <FormUiThemeContext.Provider value={props.value}>
      {props.children}
    </FormUiThemeContext.Provider>
  );
}

function mergeObjects(obj1?: object, obj2?: object) {
  const o1 = obj1 !== undefined ? obj1 : {};
  const o2 = obj2 !== undefined ? obj2 : {};

  return {
    ...o1,
    ...o2,
  };
}

export function NewThemeOverrider(props: {
  theme: {
    forms?: Partial<FormsThemeType>,
    buttons?: Partial<ButtonsTheme>,
    inputs?: Partial<InputsThemeType>,
    common?: Partial<CommonThemeType>,
    show?: Partial<ShowTheme>,
    fields?: Partial<FieldsTheme>,
    widgets?: Partial<WidgetsTheme>,
  },
  children: ListOrSingle<ReactElement>,
}) {
  const previousOverrider = useContext(FormUiThemeOverriderContext);

  const newValue = useMemo(() => ({
    forms: mergeObjects(previousOverrider.forms, props.theme.forms),
    buttons: mergeObjects(previousOverrider.buttons, props.theme.buttons),
    inputs: mergeObjects(previousOverrider.inputs, props.theme.inputs),
    common: mergeObjects(previousOverrider.common, props.theme.common),
    show: mergeObjects(previousOverrider.show, props.theme.show),
    fields: mergeObjects(previousOverrider.fields, props.theme.fields),
    widgets: mergeObjects(previousOverrider.widgets, props.theme.widgets),
  }), []);

  return (
    <FormUiThemeOverriderContext.Provider value={newValue}>
      {props.children}
    </FormUiThemeOverriderContext.Provider>
  );
}
