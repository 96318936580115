import React from 'react';
import { useProjectStatisticContextRequired } from 'features/tasks-forms';

export default function StatisticCountField(
  props: {
    period: string,
  },
) {
  const statistic = useProjectStatisticContextRequired().statistic!;

  const value = statistic
    .aggregatedStatistic.find((v) => v.period === props.period)?.count;

  if (value !== undefined) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>{value}</>
    );
  } else {
    return <>-</>;
  }
}
