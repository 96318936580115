import React from 'react';
import { ProjectFilter, ProjectType, TeamPermission } from 'nekst-api';
import {
  useFilterContext,
  FormTemplate,
  AfterSubmitFunc,
  CheckboxInput,
  ButtonColor,
  ButtonSize,
  IconType,
  Button,
} from 'ui-builder';
import FilterIncludedExcludedInput from '../../shared/widgets/FilterIncludedExcludedInput';
import Separator from '../../shared/web/uitheme/form/Separator';
import usePeopleService from '../../people/peopleService';
import DataLoader from '../../shared/DataLoader';
import { useAuthorizationChecker } from 'authorization-scope';
import { useProjectTypeHelper } from 'features/tasks-forms';
import { usePopupFormManager } from 'features/nekst-widgets';

type FilterFormType = {
  types: {
    selectAll: boolean,
    selected: ProjectType[],
  },
  assignedTo: {
    selectAll: boolean,
    selected: number[],
  },
  withPastDueOnly: boolean
}

function ProjectTypesInput() {
  const projectTypeHelper = useProjectTypeHelper();
  const types = [
    ProjectType.PENDING_SELLER,
    ProjectType.PENDING_BUYER,
    ProjectType.PENDING_DUAL,
    ProjectType.LISTING,
    ProjectType.ACTIVE_BUYER,
    ProjectType.OTHER,
  ];
  return (
    <FilterIncludedExcludedInput
      options={types.map((t) => ({
        label: projectTypeHelper.getLabel(t),
        value: t,
      }))}
      filterName="types"
      title="Transaction Type"
    />
  );
}

function TeamMemberInput() {
  const peopleService = usePeopleService();

  return (
    <DataLoader getDataFunc={peopleService.getTeamMembers}>
      {(teamMembers) => (
        <FilterIncludedExcludedInput
          options={teamMembers.map((t) => ({
            label: t.profile.name.fullName,
            value: t.id,
          }))}
          filterName="assignedTo"
          title="Team Members"
        />
      )}
    </DataLoader>
  );
}

export default function ProjectsFilterForm(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: {
    // eslint-disable-next-line react/no-unused-prop-types
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {
  const filterContext = useFilterContext<ProjectFilter>();
  const { isGranted } = useAuthorizationChecker();

  return (
    <FormTemplate<FilterFormType>
      title="Filter"
      initialData={filterContext.filterValue as FilterFormType}
      submitFormFunc={async (newValue: FilterFormType) => {
        filterContext.setFilterValue({
          ...filterContext.filterValue,
          ...newValue,
        });
        return newValue;
      }}
      afterSubmitFunc={props.afterSubmitFunc}
      validationSchema={{
        types: {
          type: 'object',
          constraints: {
            required: {
              value: true,
              message: 'Please select at least one type.',
            },
          },
          properties: {},
        },
        assignedTo: {
          type: 'object',
          constraints: {
            required: {
              value: true,
              message: 'Please select at least one team member.',
            },
          },
          properties: {},
        },
      }}
    >
      <ProjectTypesInput />
      <Separator />
      {isGranted(TeamPermission.HAS_TEAM) && (
        <>
          <TeamMemberInput />
          <Separator />
        </>
      )}
      <h4>Options</h4>
      <CheckboxInput source="withPastDueOnly" label="Show Only Transactions With Past Due Tasks" />
    </FormTemplate>
  );
}

export function OpenProjectsFilterButton() {
  const popupManager = usePopupFormManager();

  return (
    <Button
      dataKey="projects-filter-button"
      text="Filter"
      isFilled={false}
      color={ButtonColor.BLUE}
      size={ButtonSize.MEDIUM}
      iconType={IconType.FILTER}
      onClick={() => popupManager.openForm(<ProjectsFilterForm />)}
    />
  );
}
