import {
  Person,
  UpdateOwnProfileRequest,
  useAuthenticationContextRequired,
  usePeopleService
} from 'nekst-api';
import { InformationMessage, Separator } from 'features/nekst-widgets';
import React from 'react';
import {
  CheckboxInput,
  FormBlock,
  FormTemplate,
  Header5,
  SubmitButton,
  TextArea
} from 'ui-builder';
import { UpdatePersonFormFields } from '../People';

function CompleteAccountMessage() {
  const authenticationContext = useAuthenticationContextRequired();

  if (authenticationContext && !authenticationContext.user.hasActiveAccount) {
    return (
      <InformationMessage>
        Thank you for registering! To complete your profile, please fill out the form below.
      </InformationMessage>
    );
  } else {
    return null;
  }
}

export function UpdateProfileForm(
  props: {
    hideTitle?: boolean,
    onAccountCompleted?: () => void,
  }
) {
  const authenticationContext = useAuthenticationContextRequired();
  const peopleService = usePeopleService();

  const isAccountCompleted = authenticationContext.user.hasActiveAccount;

  const teamRole = authenticationContext.user.teamRole;

  return (
    <FormTemplate<UpdateOwnProfileRequest, Person>
      submitFormFunc={async (data) => {
        const result = await peopleService.updateOwnProfile(data);

        if (!isAccountCompleted) {
          props.onAccountCompleted?.();
        }

        return result;
      }}
      initialData={{
        profile: authenticationContext.user.profile,
        isDailyMailEnabled: authenticationContext.user._user.isDailyMailEnabled,
      }}
      buttons={(
        <SubmitButton text="Update" />
      )}
      title={!props.hideTitle ? 'Profile' : undefined}
      validationSchema={() => peopleService.getUpdateValidationSchema(teamRole)}
    >
      <CompleteAccountMessage />
      <UpdatePersonFormFields person={authenticationContext.user} />
      <FormBlock>
        <Separator />
        <TextArea source="profile.emailSignature" label="Email Signature" />
        <Separator />
        <Header5>Communication Preferences</Header5>
        <CheckboxInput
          source="isDailyMailEnabled"
          label="Email - Click For a Daily Summary of Your Current, Past Due and Next Day Tasks"
        />
      </FormBlock>

    </FormTemplate>
  );
}
