import React, {
  ReactElement,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useBillingService, BillingData, SubscriptionStatus } from 'nekst-api';
import useDataLoader from 'data-loader';

import LoadingAnimation from '../../shared/LoadingAnimation';

export interface BillingDataService {
  getData: () => BillingData,
  refresh: () => void,
}

const BillingPageReactContext = React.createContext<BillingDataService>({
  getData: () => ({
    teamBilling: {
      status: SubscriptionStatus.NOT_FOUND,
      isCompliantWithSubscription: true,
      notifyAboutPlanDowngrade: false,
      uniqueId: '',
    },
    availablePlans: [],
  }),
  refresh: () => {
    // ignored
  },
});

export function useBillingContext() {
  return useContext(BillingPageReactContext);
}

export default function BillingPageContext(
  props: {
    children: ListOrSingle<ReactElement>,
  },
) {
  const billingService = useBillingService();

  const [billingData, setBillingData] = useState<BillingData>();

  const [searchParams] = useSearchParams();

  const refresh = !!searchParams.get('refresh');

  const [version, setVersion] = useState(0);

  useDataLoader(
    () => billingService.getBillingData(refresh),
    setBillingData,
    version,
  );

  const contextValue = useMemo<BillingDataService>(() => ({
    getData: () => billingData || {} as BillingData,
    refresh: () => setVersion((prev) => prev + 1),
  }), [JSON.stringify(billingData)]);

  if (billingData) {
    return (
      <BillingPageReactContext.Provider value={contextValue}>
        {props.children}
      </BillingPageReactContext.Provider>
    );
  } else {
    return <LoadingAnimation />;
  }
}
