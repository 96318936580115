import React, { ReactElement, useContext } from 'react';

export interface CacheRepository {
  set: (key: string, value: string) => Promise<void>,
  get: (key: string) => Promise<string | null>,
  remove: (key: string) => Promise<void>,
  clear: () => Promise<void>
}

export interface DeviceDataRepository {
  set: (key: string, value: string) => void,
  get: (key: string) => string | undefined,
  remove: (key: string) => void,
}

type ApiConfigurationContextData = {
  backendUrl: string,
  cacheRepository: CacheRepository,
  deviceDataRepository?: DeviceDataRepository,
}

const ApiConfigurationReactContext = React.createContext<
  ApiConfigurationContextData | null
>(null);

export function ApiConfigurationContext(
  props: {
    data: ApiConfigurationContextData,
    children: ReactElement | ReactElement[],
  },
) {
  return (
    <ApiConfigurationReactContext.Provider value={props.data}>
      {props.children}
    </ApiConfigurationReactContext.Provider>
  );
}

export function useApiConfiguration() {
  const result =  useContext(ApiConfigurationReactContext);

  if (!result) {
    throw new Error('BackendConfigurationContext must be used');
  }

  return result;
}
