import BaseInputLayout from '../../../../shared/uibuilder/form/layout/BaseInputLayout';
import { BaseInputProps, useFormContextRequired, useInputHelper } from 'ui-builder';
import React from 'react';

interface FileInputProps extends BaseInputProps {
  fileNameSource: string
}

async function getFileData(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      // Extract the base64 part of the data URL
      const base64String = reader.result as string;
      const base64Data = base64String.split(',')[1]; // Split by comma and get the second part, which is the actual base64 data
      resolve(base64Data);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    // Read the file as a data URL
    reader.readAsDataURL(file);
  });
}

export default function FileInput(
  props: FileInputProps
) {
  const inputHelper = useInputHelper(props);

  const formContext = useFormContextRequired();

  const setFile = async (file: File|null) => {
    if (file) {
      const fileData = await getFileData(file);

      formContext.onChangeCallback({
        [props.source]: fileData,
        [props.fileNameSource]: file.name,
      });

    } else {
      formContext.onChangeCallback({
        [props.source]: null,
        [props.fileNameSource]: null,
      });
    }
  };

  return (
    <BaseInputLayout {...inputHelper.getBaseInputLayoutProps()}>
      <input
        type="file"
        onChange={(event) => {
          if (event.target.files && event.target.files.length > 0) {
            setFile(event.target.files![0]);
          } else {
            setFile(null);
          }
        }}
      />
    </BaseInputLayout>
  );
}
