import React, { ReactElement } from 'react';
import { AfterSubmitFunc, CancelButton, SubmitButton } from 'ui-builder';
import { Project, useProjectsService } from 'nekst-api';
import { BaseProjectForm } from './BaseProjectForm';

export interface CreateProjectFormProps {
  buttons?: ReactElement;
  className?: string;
  title?: string;
  afterSubmitFunc?: AfterSubmitFunc<Project>,
  openNextLaunchingPage?: (projectId: number) => void;
}

export function CreateProjectForm(props: CreateProjectFormProps) {
  const projectsService = useProjectsService();

  const buttons = props.buttons || (
    <>
      <SubmitButton text="Add transaction" key="save" />
      <CancelButton text="Cancel" key="cancel" />
    </>
  );

  return (
    <BaseProjectForm
      title={props.title}
      submitFormFunc={async (data: Project) => {
        return await projectsService.create(data);
      }}
      afterSubmitFunc={async (
        data,
      ) => {
        await props.afterSubmitFunc?.(data);
      }}
      buttons={buttons}
      className={props.className}
    />
  );
}
