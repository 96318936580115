import React from 'react';
import TextButton from '../projects/history/RecordLayout/TextButton';
import { useOpenUpdateFormHelper } from '../projects/view/tasks/CreateUpdate/OpenUpdateFormField';
import { useShowContext } from '../shared/uibuilder/show/ShowReactContext';
import { AbstractTask } from '../projects/view/tasks/projectTasksService';

export default function OpenTaskButton() {

  const openTaskHelper = useOpenUpdateFormHelper();
  const showContext = useShowContext<AbstractTask>();

  return (
    <TextButton
      text="Go to task"
      onClick={() => {
        openTaskHelper.openForm(showContext.data!);
      }}
    />
  );
}
