import React, { ReactElement, ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route, RouterProvider,
  Routes,
  useParams,
} from 'react-router-dom';
import {
  ApiTokenContext,
  TokenStorageInterface,
  InvitationTokenContext,
  ApiConfigurationContext,
  CacheRepository, ConfigurationContext, DeviceDataRepository,
} from 'nekst-api';
import './App.scss';
import BaseLayout from './shared/web/layout/BaseLayout';
import usePlansModule from './plans/plansModule';
import useProjectsModule from './projects/projectsModule';
import AuthenticationContext from './authentication/AuthenticationContext';
import useTasksModule from './tasks/tasksModule';
import useMeModule from './people/me/meModule';
import usePeopleModule from './people/peopleModule';
import CompleteProfilePage from './people/me/CompleteAccount/CompleteProfilePage';
import useCalendarModule from './calendar/calendarModule';
import useSettingsModule from './settings/settingsModule';
import useLoginModule from './login/loginModule';
import { ApplicationModule } from './shared/applicationModule';
import EventBusContext from './shared/events/EventBusContext';
import SubscriptionComplianceWarning from './settings/billing/SubscriptionComplianceWarning';
import useSignUpModule from './signup/signUpModule';
import useDailyMailModule from './dailymail/dailyMailModule';
import CrispChat from './thirdpartyscripts/crisp/CrispChat';
import PageTitle from './shared/web/PageTitle';
import HttpErrorsBoundary from './shared/uibuilder/HttpErrorsBoundary';
import ImpersonationUserWarning
  from './settings/administration/impersonateusers/ImpersonationUserWarning';
import DownloadFilePage from './files/download/DownloadFilePage';
import ErrorBoundaryLayout from './shared/uibuilder/ErrorBoundaryLayout';
import NekstWebTheme from './shared/uibuilder/webtheme/NekstWebTheme';
import useUserSessionStorage from './login/userSessionStorage';
import { TeamAuthorizationScope } from 'authorization-scope';
import Cookies from 'js-cookie';
import useSettingsNavigator from './settings/settingsNavigator';
import UserListInAppMessaging from './thirdpartyscripts/userlist/UserListInAppMessaging';

import './shared/monitoring/init';
import UserTracker from './shared/monitoring/UserTracker';
import { openDB } from 'idb';

interface AppProps {
  // eslint-disable-next-line react/no-unused-prop-types
  basename?: string;
  wrapper?: (props: { children: ListOrSingle<ReactElement> }) => JSX.Element;
}

export function useAllModules() {
  return [
    usePlansModule(),
    useProjectsModule(),
    useTasksModule(),
    useMeModule(),
    usePeopleModule(),
    useCalendarModule(),
    useSettingsModule(),
    useLoginModule(),
    useSignUpModule(),
    useDailyMailModule(),
  ];
}

function useWebAppTokenStorage(): TokenStorageInterface {
  const userSessionStorage = useUserSessionStorage();

  const saveToken = async (token: string | undefined) => {
    if (token) {
      userSessionStorage.saveToken(token);
    } else {
      userSessionStorage.removeToken();
    }
  };

  const getToken = async () => {
    return userSessionStorage.getToken();
  };

  const isTokenSet = async () => {
    return userSessionStorage.hasToken();
  };

  return {
    saveToken,
    getToken,
    isTokenSet,
  };
}

async function initDB() {
  return openDB('cache-db', 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('cache')) {
        db.createObjectStore('cache');
      }
    },
  });
}

function useCacheRepository(): CacheRepository {
  const get = async (key: string) => {
    const db = await initDB();
    return db.get('cache', key);
  };

  const set = async (key: string, value: string) => {
    const db = await initDB();
    await db.put('cache', value, key);
  };

  const remove = async (key: string) => {
    const db = await initDB();
    await db.delete('cache', key);
  };

  const clear = async () => {
    const db = await initDB();
    await db.clear('cache');
  };

  return {
    get,
    set,
    remove,
    clear,
  };
}


function useDeviceDataRepository(): DeviceDataRepository {
  const get = (key: string) => {
    return Cookies.get(key);
  };

  const set = (key: string, value: string) => {
    Cookies.set(key, value);
  };

  const remove = (key: string) => {
    Cookies.remove(key);
  };

  return {
    get,
    set,
    remove,
  };
}

function useWebAppInvitationTokenStorage(): TokenStorageInterface {
  const { code } = useParams<{ code: string }>();

  return {
    saveToken: async () => {
      throw new Error('Invitation token cannot be updated');
    },
    getToken: async () => {
      return code || '';
    },
    isTokenSet: async () => !!code,
  };
}

function InvitationTokenContextWrapper(props: { children: ReactElement }) {
  const invitationTokenStorage = useWebAppInvitationTokenStorage();

  return (
    <InvitationTokenContext tokenStorage={invitationTokenStorage}>
      {props.children}
    </InvitationTokenContext>
  );
}

function WebTeamAuthorizationScope(
  props: { children: ReactNode }
) {

  const settingsNavigator = useSettingsNavigator();

  return (
    <TeamAuthorizationScope openBillingPageFunc={settingsNavigator.openBillingPage}>
      {props.children}
    </TeamAuthorizationScope>
  );
}

function BaseApplication(props: AppProps) {

  const modules: ApplicationModule[] = useAllModules();

  const Layout = props.wrapper || BaseLayout;

  const webAppTokenStorage = useWebAppTokenStorage();
  const cacheRepository = useCacheRepository();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<ErrorBoundaryLayout />}>
        <Route path="/" element={<Navigate to="/tasks" />} />
        <Route
          path="/me/invitation/:code"
          element={(
            <InvitationTokenContextWrapper>
              <AuthenticationContext>
                <UserTracker />
                <WebTeamAuthorizationScope>
                  <Layout>
                    <HttpErrorsBoundary>
                      <CompleteProfilePage />
                    </HttpErrorsBoundary>
                  </Layout>
                </WebTeamAuthorizationScope>
              </AuthenticationContext>
            </InvitationTokenContextWrapper>
          )}
        />
        <Route
          path="/download/:code"
          element={(
            <Layout>
              <HttpErrorsBoundary>
                <DownloadFilePage />
              </HttpErrorsBoundary>
            </Layout>
          )}
        />
        <Route
          path="*"
          element={(
            <ApiTokenContext tokenStorage={webAppTokenStorage}>
              <AuthenticationContext>
                <UserTracker />
                <WebTeamAuthorizationScope>
                  <Layout>
                    <HttpErrorsBoundary>
                      <ImpersonationUserWarning />
                      <SubscriptionComplianceWarning />
                      <Routes>
                        <Route element={<ErrorBoundaryLayout />}>
                          {
                            modules.map((module) => (
                              <React.Fragment key={module.name}>
                                {module.getRouter()}
                              </React.Fragment>
                            ))
                          }
                        </Route>
                      </Routes>
                    </HttpErrorsBoundary>
                  </Layout>
                </WebTeamAuthorizationScope>
                <CrispChat />
                <UserListInAppMessaging />
              </AuthenticationContext>
            </ApiTokenContext>
          )}
        />
      </Route>,
    ),
  );

  return (
    <ApiConfigurationContext
      data={{
        cacheRepository,

        backendUrl: process.env.REACT_APP_API_URL as string,
        deviceDataRepository: useDeviceDataRepository(),
      }}
    >
      <NekstWebTheme>
        <EventBusContext>
          <HttpErrorsBoundary>
            <ConfigurationContext>
              <HelmetProvider>
                <PageTitle text="" />
                <RouterProvider router={router} />
              </HelmetProvider>
            </ConfigurationContext>
          </HttpErrorsBoundary>
        </EventBusContext>
      </NekstWebTheme>
    </ApiConfigurationContext>
  );
}

function App() {
  return (<BaseApplication />);
}

export default App;
