import React from 'react';
import { AbstractTask, useProjectTasksService } from 'nekst-api';
import {
  Button,
  ButtonColor,
  ButtonSize,
  useFormContext,
  usePopupManager
} from 'ui-builder';
import { ConfirmDeletePopup } from 'features/nekst-widgets';

function DeleteTaskPopup(props: { taskId: number, taskName: string }) {
  const projectTasksService = useProjectTasksService();

  const popupManager = usePopupManager();
  return (
    <ConfirmDeletePopup
      deleteFunc={async () => {
        await projectTasksService.deleteTask(props.taskId);
        popupManager.closePopup();
      }}
      message={`Are you sure you want to delete task "${props.taskName}"? This action cannot be undone.`}
    />
  );
}

export default function DeleteTaskButton() {

  const popupManager = usePopupManager();

  const { data } = useFormContext<AbstractTask>();

  return (
    <Button
      text="Delete task"
      onClick={async () => {
        popupManager.openPopup(
          <DeleteTaskPopup taskId={data!.id} taskName={data!.name} />,
          true,
        );
      }}
      size={ButtonSize.BIG}
      color={ButtonColor.RED}
    />
  );
}
