import {
  BaseInputProps,
  DateInput,
  Dropdown,
  FormTemplate,
  NumberInput,
  RelativeRow,
  TextArea,
  TextInput,
  useListContextRequired,
  useShowContextRequired,
  useFormContextRequired,
  SimpleBlock, AfterSubmitFunc,
} from 'ui-builder';
import { ProjectDetailsFieldType, RelativeDateOffset, useAiLauncherService } from 'nekst-api';
import { OpenFormIconButton } from 'features/nekst-widgets';
import React from 'react';
import { EntryType, VerifyDataEntry } from './types';

import styles from './ValidateData.module.scss';

function RelativeDateOffsetInput(props: BaseInputProps<RelativeDateOffset>) {
  const listContext = useListContextRequired<VerifyDataEntry>();

  const formContext = useFormContextRequired<{
    value: RelativeDateOffset
  }>();

  const fieldName = listContext.data!
    .find((item) => item.detailsField?.id === formContext.data!.value?.dependsOnFieldId)?.name;

  return (
    <SimpleBlock>
      Set Up offset from {fieldName}:<br /><br />
      <RelativeRow weights={[3, 3, 6]}>
        <NumberInput source="value.days" label="Calendar Days" />
        <Dropdown
          options={[
            {
              label: 'Before',
              value: 'before'
            },
            {
              label: 'After',
              value: 'after'
            }
          ]}
          source="value.when"
          isClearable={false}
        />
      </RelativeRow>
    </SimpleBlock>

  );
}

function UpdateParseValueForm(props: {
  projectId: number
  data: VerifyDataEntry,
  afterSubmitFunc?: AfterSubmitFunc,
}) {

  const listContext = useListContextRequired<VerifyDataEntry>();

  const projectId = props.projectId;

  let Widget;

  const aiLauncherService = useAiLauncherService();

  const updateData = async (value: string | number | Date | RelativeDateOffset | null | undefined) => {
    if (props.data.type === EntryType.NAME) {
      return aiLauncherService.updateName(projectId, value as string);
    } else {

      const detailsField = props.data.detailsField!;
      if (detailsField.fieldType === ProjectDetailsFieldType.PRICE) {
        return aiLauncherService.updateNumberFieldValue(projectId, detailsField.id, value as number | undefined);
      } else if (detailsField.fieldType === ProjectDetailsFieldType.DATE) {
        if (props.data.parsedValue?.type === 'relative_date_offset') {
          return aiLauncherService.updateRelativeDateOffsetValue(projectId, detailsField.id, value as RelativeDateOffset | undefined);
        } else {
          return aiLauncherService.updateDateFieldValue(projectId, detailsField.id, value as Date | undefined);
        }
      } else {
        return aiLauncherService.updateStringFieldValue(projectId, detailsField.id, value as string | undefined);
      }
    }
  }

  if (props.data.type === EntryType.NAME) {
    Widget = TextInput;
  } else {
    const detailsField = props.data.detailsField!;

    if (detailsField.fieldType === ProjectDetailsFieldType.PRICE) {
      Widget = NumberInput;
    } else if (detailsField.fieldType === ProjectDetailsFieldType.LONG_TEXT) {
      Widget = TextArea;
    } else if (detailsField.fieldType === ProjectDetailsFieldType.DATE) {
      if (props.data.parsedValue?.type === 'relative_date_offset') {
        Widget = RelativeDateOffsetInput;
      } else {
        Widget = DateInput;
      }
    } else {
      Widget = TextInput;
    }
  }

  return (
    <FormTemplate<{ value: string | number | Date | RelativeDateOffset | null | undefined }>
      title={`Update "${props.data.name}" value`}
      submitFormFunc={async (request) => {
        await updateData(request.value);

        const listData = listContext.data.map((item) => {
          if (item.type === props.data.type && item.detailsField?.id === props.data.detailsField?.id) {
            return {
              ...item,
              parsedValue: {
                ...item.parsedValue,
                value: request.value,
              }
            } as VerifyDataEntry;
          } else {
            return item;
          }
        });

        listContext._setData(listData);

        return request;
      }}
      initialData={{
        value: props.data.parsedValue?.value,
      }}
      afterSubmitFunc={props.afterSubmitFunc}
    >
      <Widget source="value" label={props.data.name} />
    </FormTemplate>
  );
}

export function UpdateParseValueButton(props: {projectId: number}) {
  const showContext = useShowContextRequired<VerifyDataEntry>();

  return (
    <OpenFormIconButton
      form={<UpdateParseValueForm data={showContext.data!} projectId={props.projectId} />}
      className={styles.editButton}
    />
  );

}
