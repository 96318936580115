import { Permission } from './Types';
import { useBackendApi } from './backendApi';

export interface TeamService {
  getPermissions: () => Promise<Permission[]>
}

export enum TeamPermission {
  CREATE_PLAN = 'Team:CREATE_PLAN',
  CREATE_PROJECT = 'Team:CREATE_PROJECT',
  ACCESS_PROJECT_CREATION = 'Team:ACCESS_PROJECT_CREATION',
  CREATE_PROJECT_TASKS = 'Team:CREATE_PROJECT_TASKS',
  ASSIGN_TASKS_TO_OTHER_ROLES = 'Team:ASSIGN_TASKS_TO_OTHER_ROLES',
  READ_PLANS = 'Team:READ_PLANS',
  READ_CONTACTS = 'Team:READ_CONTACTS',
  USE_MASS_UPDATE_FEATURE = 'Team:USE_MASS_UPDATE_FEATURE',
  USE_SMS_TASKS_FEATURE = 'Team:USE_SMS_TASKS_FEATURE',
  USE_TEAM_MEMBERS_FEATURE = 'Team:USE_TEAM_MEMBERS_FEATURE',
  USE_SMART_TAGS = 'Team:USE_SMART_TAGS',
  HAS_TEAM = 'Team:HAS_TEAM',
  CREATE_TEAM_MEMBER = 'Team:CREATE_TEAM_MEMBER',

  MANAGE_ROLES = 'Team:MANAGE_ROLES',

  MANAGE_BILLING = 'Team:MANAGE_BILLING',

  READ_TASKS = 'Team:READ_TASKS',

  MANAGE_TASKS = 'Team:MANAGE_TASKS',

  ACCESS_FUNCTIONALITY = 'Team:ACCESS_FUNCTIONALITY',

  COMPLIANT_WITH_SUBSCRIPTION = 'Team:COMPLIANT_WITH_SUBSCRIPTION',

  MANAGE_PROJECT_DETAILS_FIELDS = 'Team:MANAGE_PROJECT_DETAILS_FIELDS',
  MANAGE_TEAM_CONFIGURATION = 'Team:MANAGE_TEAM_CONFIGURATION',
  MANAGE_SERVICE_PROVIDERS = 'Team:MANAGE_SERVICE_PROVIDERS',

  MANAGE_CLIENT_PORTAL_CONFIGURATION = 'Team:MANAGE_CLIENT_PORTAL_CONFIGURATION',

  MANAGE_PORTAL = 'Team:MANAGE_PORTAL',

  MANAGE_DOCUMENT_LABELS = 'Team:MANAGE_DOCUMENT_LABELS',

  MANAGE_DOCUMENT_TEMPLATES = 'Team:MANAGE_DOCUMENT_TEMPLATES',

  MANAGE_AI_TRAINER = 'Team:MANAGE_AI_TRAINER',

  ACCESS_TEAM_MEMBERS_MANAGEMENT = 'Team:ACCESS_TEAM_MEMBERS_MANAGEMENT',
  DELETE_TEAM_MEMBERS = 'Team:DELETE_TEAM_MEMBERS',

  MANAGE_TEAM_MEMBERS = 'Team:MANAGE_TEAM_MEMBERS',

  ACCESS_SETUP = 'Team:ACCESS_SETUP',
}

export function useTeamService(): TeamService {
  const api = useBackendApi();

  const getPermissions = () => {
    return api.get('/permissions');
  };

  return {
    getPermissions,
  };
}
