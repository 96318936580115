import { FormTemplate, TextInput } from 'ui-builder';
import { Plan, usePlansService } from 'nekst-api';
import { FormText, Separator } from 'features/nekst-widgets';
import React from 'react';

export interface CopyPlanFormProps {
  id: number;
  afterSubmitFunc?: (_data: Plan) => Promise<void>,
}

export function CopyPlanForm(props: CopyPlanFormProps) {
  const planService = usePlansService();

  const afterSubmitFunc = async (data: Plan) => {
    props.afterSubmitFunc?.(data);
  };

  return (
    <FormTemplate
      title="Copy"
      validationSchema={{
        name: {
          type: 'string',
          constraints: {
            required: true,
          },
        },
      }}
      submitFormFunc={async (data) => {
        return planService.copy(props.id, data.name);
      }}
      afterSubmitFunc={afterSubmitFunc}
    >
      <FormText>
        Enter a new action plan name.
      </FormText>
      <Separator />
      <TextInput source="name" label="Plan name" />
    </FormTemplate>
  );
}
