import React, { useState } from 'react';
import { BaseInputProps } from '../../../shared/uibuilder/form/input';
import useDocumentLabelsService, { DocumentLabel } from './documentLabelsService';
import useDataLoader from '../../../shared/uibuilder/form/dataLoader';
import LoadingAnimation from '../../../shared/LoadingAnimation';
import { MultiSelectDropdown } from 'ui-builder';

export interface DocumentsLabelDropdownProps extends BaseInputProps<number[]> {
  excludeIds?: number[],
}

export default function DocumentLabelsDropdown(props: DocumentsLabelDropdownProps) {
  const service = useDocumentLabelsService();

  const [labels, setLabels] = useState<DocumentLabel[]>();

  useDataLoader(
    service.getLabels,
    setLabels,
  );

  if (labels) {
    const options = labels
      .filter((item) => !props.excludeIds?.includes(item.id))
      .map((item) => ({
        label: item.name,
        value: item.id,
      }));

    return (
      <MultiSelectDropdown
        {...props}
        options={options}
        onOptionCreate={
          async (value: string) => {
            const label = await service.createLabel({
              name: value,
            });

            return {
              value: label.id,
              label: label.name,
            };
          }
        }
        placeholder="Select a label or enter the name of a new one..."
      />
    );
  } else {
    return <LoadingAnimation />;
  }
}
