import React from 'react';
import { Route } from 'react-router-dom';
import { ApplicationModule } from '../shared/applicationModule';
import UnsubscribePage from './UnsubscribePage';

export default function useDailyMailModule(): ApplicationModule {
  const getRouter = () => {
    return (
      <Route
        path="/unsubscribe/:token"
        element={
          <UnsubscribePage />
        }
      />
    );
  };

  return {
    getRouter,
    name: 'daily_mail',
  };
}
