import React from 'react';
import styles from '../PhoneNumbersInput.module.scss';
import { IconButton, IconType } from '../../IconButton';
import {
  OptInStatus,
  PersonPhoneNumber,
  PersonPhoneNumberType,
  TeamPermission
} from 'nekst-api';
import {
  If, RadioButton,
  RelativeRow, SimpleBlock,
  Text,
  useShowContext,
  useShowContextRequired,
} from 'ui-builder';

import { PhoneNumberWithExtensionField } from '../PhoneNumberWithExtensionField';
import { PhoneNumberTypeField } from '../PhoneNumberTypeField';
import { useAuthorizationChecker } from 'authorization-scope';
import { ListRowLayoutProps } from './types';
import { usePhoneNumberSelection } from './phoneNumberSelection';

function IsPrimaryRadio(props: {
  source: string
  isDisabled: boolean
}) {
  const selection = usePhoneNumberSelection('isPrimary');

  return (
    <RadioButton
      hideLabel
      groupValue={selection.id}
      source={props.source}
      isDisabled={props.isDisabled}
      onChangeCallback={selection.onChanged}
      value={selection.value}
    />
  );
}

function IsSmsRadio(props: {
  source: string,
  isDisabled: boolean
}) {
  const selection = usePhoneNumberSelection('isUsedForSms');

  const showContext = useShowContextRequired<PersonPhoneNumber>();
  const isDisabled = (!/^[0-9]{10}$/.test(showContext.data!.number));

  const hint = isDisabled
    ? 'The radio button for this phone number is disabled because the value doesn\'t match required format: (XXX) XXX-XXXX.'
    : undefined;

  return (
    <RadioButton
      hideLabel
      groupValue={selection.id}
      source={props.source}
      value={selection.value}
      title={hint}
      isDisabled={isDisabled}
      onChangeCallback={selection.onChanged}
    />
  );

}

function OptInStatusField() {
  const { data } = useShowContext<PersonPhoneNumber>();

  const map = {
    [OptInStatus.WAITING_FOR_CONSENT]: styles.waiting,
    [OptInStatus.CONSENT_RECEIVED]: styles.optedIn,
    [OptInStatus.OPTED_OUT]: styles.optedOut,
  };

  const messages = {
    [OptInStatus.WAITING_FOR_CONSENT]: 'The phone number is pending opt-in confirmation. The sms messages will not be sent to it until opt-in confirmation is received.',
    [OptInStatus.CONSENT_RECEIVED]: 'Opt-in confirmed. The phone number is now registered and ready to receive SMS messages from Nekst.',
    [OptInStatus.OPTED_OUT]: 'The phone number is currently opted-out from receiving messages from Nekst.',
  };

  const status = data!.optInStatus;

  if (status !== OptInStatus.NOT_SENT) {
    const statusClassName = map[status!];

    const title = messages[status!];
    return (
      // @ts-ignore
      <Text className={`${styles.optInStatus} ${statusClassName}`} title={title}>
        &nbsp;
      </Text>
    );
  } else {
    return null;
  }
}

export default function ListRowLayoutWeb(
  props: ListRowLayoutProps,
) {

  const { isGranted } = useAuthorizationChecker();

  return (
    <RelativeRow weights={[4, 2, 2, 2, 2]} rowClassName={styles.row}>
      <PhoneNumberWithExtensionField source="number" extensionSource="extension" />

      <SimpleBlock>
        <PhoneNumberTypeField source="type" />
        {isGranted(TeamPermission.USE_SMS_TASKS_FEATURE) && (<OptInStatusField />)}
      </SimpleBlock>
      <IsPrimaryRadio source="isPrimary" isDisabled={props.isDisabled} />
      {isGranted(TeamPermission.USE_SMS_TASKS_FEATURE) && (
        <If
          conditionFunc={(number) => number.type === PersonPhoneNumberType.MOBILE}
          failureContent={<Text>&nbsp;</Text>}
        >
          <IsSmsRadio
            source="isSms"
            isDisabled={props.isDisabled}
          />
        </If>
      )}
      {!props.isDisabled && (
        <IconButton onClick={() => props.removeItemFunc()} type={IconType.X}
                    title="Delete Number" />
      )}
    </RelativeRow>
  );
}
