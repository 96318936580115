import React, { useState } from 'react';
import RecordLayout, { RecordLayoutProps } from './RecordLayout';
import PersonLink from './PersonLink';
import PlanLink from './PlanLink';

import styles from '../History.module.scss';
import { HistoryEventIconType } from './HistoryEventIcon';
import { BaseHistoryEntryData, PlanData, TaskData } from 'nekst-api';

interface ProjectLaunchedData extends BaseHistoryEntryData {
  plan: PlanData,
  tasks: TaskData[],
}

function ShowTasksButton(props: {
  show: boolean,
  onClick: () => void,
}) {
  const text = props.show ? 'Hide Tasks' : 'Show Tasks';

  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <span onClick={props.onClick} className={styles.showAllTasks}>{text}</span>
  );
}

function PlanTasksList(
  props: {
    tasks: TaskData[],
  },
) {
  return (
    <ul className={styles.tasksList}>
      {props.tasks.map((t, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={`task-${index}`}>{t.name}</li>
      ))}
    </ul>
  );
}

export default function ProjectLaunchedRecordLayout(props: RecordLayoutProps<ProjectLaunchedData>) {
  const [showTasks, setShowTasks] = useState(false);

  return (
    <RecordLayout
      text={(
        <>
          <PersonLink person={props.record.data.person!} />
          {' applied the plan titled '}
          <PlanLink plan={props.record.data.plan} project={props.record.data.project} />
          .
          <ShowTasksButton
            show={showTasks}
            onClick={
              () => setShowTasks((prev) => !prev)
            }
          />
          {showTasks && (<PlanTasksList tasks={props.record.data.tasks} />)}
        </>
      )}
      date={props.record.when}
      iconType={HistoryEventIconType.PROJECT_LAUNCHED}
    />
  );
}
