import React from 'react';
import Layout from './Layout';

export function ProfileMention(
  props: {
    id: number,
    text: string,
  },
) {
  return (
    <Layout text={props.text} id={props.id} />
  );
}
