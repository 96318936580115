import React, { useMemo } from 'react';
import { List, ListDataRefresher, Show, useShowContext, } from 'ui-builder';

import PlanTaskRow from './PlanTaskRow';

import styles from '../../../shared/widgets/TaskRowLayout.module.scss';
import SelectAllUpdater from '../../../shared/widgets/MassUpdate/SelectAllUpdater';
import TypeFilter from './Filter/TypeFilter';
import AssigneeFilter from './Filter/AssigneeFilter';
import { OrderedListWrapper } from './OrderedList';
import RowRepeaterWithSort from './RowRepeaterWithSort';
import SmsEmailRecipientFilter from './Filter/SmsEmailRecipientFilter';
import CreateTasksButton from '../../view/CreateTasksButton';
import MassUpdateControls from '../../MassUpdate/MassUpdateControls';
import { PlansListBreadcrumbs } from '../../List';
import { Breadcrumb } from '../../../shared/web/layout/Breadcrumbs';
import { DetailBoxesDatesWidgetPortalPlacer } from './DetailBoxesDatesWidget';
import { MassUpdateCheckbox } from 'features/tasks-list-feature';
import {
  Plan,
  PLAN_TASKS_UPDATED,
  PlanTask, ProjectType, replaceTagsIdsWithValues,
  TaskType,
  usePlansService,
  usePlanTasksService,
  useSmartTagsService
} from 'nekst-api';
import { PlanPermission, useAuthorizationChecker } from 'authorization-scope';
import {
  EditPlanButton,
  PlanProjectTypeSetter,
  PlanTasksClientSideFilter, useProjectTypeHelper
} from 'features/tasks-forms';
import { useNavigate } from 'react-router-dom';

export interface PlanTasksListProps {
  planId: number;
  planName: string;
  planType?: ProjectType;
  detailsFieldsSetName?: string;
}

function PlanBreadcrumbs() {
  const showContext = useShowContext<Plan>();

  return showContext.data ? (
    <PlansListBreadcrumbs>
      <Breadcrumb text={showContext.data.name} />
    </PlansListBreadcrumbs>
  ) : <PlansListBreadcrumbs />;
}

export default function PlanTasksList(props: PlanTasksListProps) {
  const planTasksService = usePlanTasksService();
  const projectTypeHelper = useProjectTypeHelper();

  const { isGranted } = useAuthorizationChecker();

  const plansService = usePlansService();

  const smartTagsService = useSmartTagsService();

  const navigate = useNavigate();


  const getDataFunc = async () => {

    const tags = await smartTagsService.getSmartTags(props.planType, TaskType.EMAIL);

    const tasks = await planTasksService.getPlanTasks(props.planId);
    return tasks
      .map((singleTask) => {
        return {
          ...singleTask,
          _renderedName: singleTask.type === TaskType.EMAIL ? replaceTagsIdsWithValues(singleTask.name, tags) : singleTask.name,
        };
      })
      .sort(planTasksService.getTasksCompareFunc());
  };

  const titleLine2 = useMemo(() => {
    if (props.planType) {
      let result = projectTypeHelper.getLabel(props.planType);

      if (props.detailsFieldsSetName) {
        result += ` - ${props.detailsFieldsSetName}`;
      }

      return result;
    } else {
      return undefined;
    }
  }, [props.planType, props.detailsFieldsSetName]);

  return (
    <List
      getDataFunc={getDataFunc}
      dependencies={[props.planId]}
    >
      <Show
        id={props.planId}
        title={props.planName}
        titleLine2={titleLine2}
        getDataFunc={plansService.getById}
        buttons={[
          <MassUpdateCheckbox key="massupdate" />,
          <CreateTasksButton key="createtasks" id={props.planId} />,
          <EditPlanButton
            key="editplan"
            id={props.planId}
            goToPlansListFunc={() => {
              navigate('/plans');
            }}
            goToPlanFunc={(plan) => {
              navigate(`/plans/${plan.id}`);
            }}
          />,
        ]}
      >
        <PlanBreadcrumbs />
        <MassUpdateControls />
        <PlanProjectTypeSetter />
        <PlanTasksClientSideFilter>
          <TypeFilter />
          <AssigneeFilter />
          <SmsEmailRecipientFilter />
          <DetailBoxesDatesWidgetPortalPlacer />
          <ListDataRefresher eventName={PLAN_TASKS_UPDATED} />
          <SelectAllUpdater />
          <OrderedListWrapper
            orderingConfig={{
              getOrderGroupFunc: (item: PlanTask) => {
                let groupName;
                if (item.dueDateConfig) {
                  groupName = `dueDate-${item.dueDateConfig?.days?.toString()}-${item.dueDateConfig?.when}`;
                } else {
                  groupName = `parentTask-${item.parentTaskRelation?.days}-${item.parentTaskRelation?.parentTaskId}`;
                }
                return groupName;
              },
              updateOrderFunc: planTasksService.setOrder,
              isEnabled: isGranted(PlanPermission.UPDATE),
            }}
          >
            <RowRepeaterWithSort
              idField="id"
              blockClassName={styles.block}
              groupClassName={styles.group}
              parentIdField="parentTaskRelation.parentTaskId"
              noDataMessage="No tasks matching current filter criteria"
            >
              <PlanTaskRow />
            </RowRepeaterWithSort>
          </OrderedListWrapper>
        </PlanTasksClientSideFilter>
      </Show>
    </List>
  );
}
