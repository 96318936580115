import React, { ReactElement, useContext, useState } from 'react';
import { ApplicationConfiguration, useConfigService } from './configService';
import useDataLoader from 'data-loader';

interface MobileAppConfiguration extends ApplicationConfiguration {
  isSubscriptionsManagementEnabled: boolean,
  isPlansEnabled: boolean,
}

const ConfigurationReactContext = React.createContext<MobileAppConfiguration>({
  isChatEnabled: false,
  crispWebsiteId: '',
  isSubscriptionsManagementEnabled: false,
  isPlansEnabled: false,
});

export function useConfigurationContext() {
  return useContext(ConfigurationReactContext);
}

export function ConfigurationContext(
  props: {
    children: ReactElement | ReactElement[],
  },
) {
  const [config, setConfig] = useState<MobileAppConfiguration>({
    isChatEnabled: false,
    crispWebsiteId: '',
    isSubscriptionsManagementEnabled: false,
    isPlansEnabled: false,
  });

  const configService = useConfigService();

  useDataLoader(
    configService.getConfig,
    (value) => setConfig({
      ...value,
      isSubscriptionsManagementEnabled: process.env.EXPO_PUBLIC_SUBSCRIPTIONS_MANAGEMENT_ENABLED === '1',
      isPlansEnabled: process.env.EXPO_PUBLIC_PLANS_ENABLED === '1',
    }),
  );

  return (
    <ConfigurationReactContext.Provider value={config}>
      {props.children}
    </ConfigurationReactContext.Provider>
  );
}
