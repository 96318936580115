import React from 'react';
import { ProjectPermission, useAuthorizationChecker } from 'authorization-scope';
import { Button, ButtonColor, ButtonSize, IconType } from 'ui-builder';
import { TeamMembersAssignmentsForm } from 'features/tasks-forms';
import { usePopupFormManager } from 'features/nekst-widgets';

export interface AddTeamMemberButtonProps {
  projectId: number;
  title?: string;
  size?: ButtonSize;
  isFilled?: boolean;
}

export default function AddTeamMemberButton(
  props: AddTeamMemberButtonProps,
) {
  const popupManager = usePopupFormManager();

  const { isGranted } = useAuthorizationChecker();

  const openPopup = () => {
    popupManager.openForm(
      <TeamMembersAssignmentsForm projectId={props.projectId} />,
    );
  };

  if (isGranted(ProjectPermission.CREATE_TASK_ASSIGNEE)) {
    return (
      <Button
        text={props.title || 'Add team member'}
        iconType={IconType.PLUS}
        size={props.size || ButtonSize.MEDIUM}
        color={ButtonColor.BLUE}
        isFilled={props.isFilled || false}
        onClick={openPopup}
      />
    );
  } else {
    return null;
  }
}
