import { ReactElement, ReactNode } from 'react';

export interface PopupProps {
  title: ReactNode;
  isCentered?: boolean;
  isClosable?: boolean;
  isBig?: boolean,
  isLong?: boolean
  isSecondPopup?: boolean;
  content: ReactElement | ReactElement[];
  buttons?: ReactNode;
  popupId?: string,
}

export const POPUP_DATA_UPDATED = 'POPUP_DATA_UPDATED';

export const POPUP_OPENED = 'POPUP_OPENED';
