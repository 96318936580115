import React from 'react';
import useServiceProviderValidationSchema from './serviceProviderValidationSchema';
import { usePopupFormManager } from 'features/nekst-widgets';
import {
  AfterSubmitFunc,
  ButtonColor,
  ButtonSize,
  FormTemplate,
  IconType,
  TextInput,
  Button,
} from 'ui-builder';
import { ServiceProviderRequest, useServiceProvidersGroupsService } from 'nekst-api';

function CreateServiceProviderForm(
  props: {
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {
  const serviceProviderService = useServiceProvidersGroupsService();

  return (
    <FormTemplate<ServiceProviderRequest>
      submitFormFunc={(data) => serviceProviderService.create(data)}
      afterSubmitFunc={props.afterSubmitFunc}
      title="Create Service Provider"
      validationSchema={useServiceProviderValidationSchema()}
    >
      <TextInput source="name" label="Name" />
    </FormTemplate>
  );
}

export default function CreateServiceProviderButton() {

  const popupManager = usePopupFormManager();

  return (
    <Button
      text="Create Service Provider"
      color={ButtonColor.BLUE}
      size={ButtonSize.MEDIUM}
      isFilled
      onClick={() => popupManager.openForm(<CreateServiceProviderForm />)}
      iconType={IconType.PLUS}
    />
  );
}
