import React from 'react';
import useDataLoader from 'data-loader';

function useUpdateCheckerService() {
  const getLastDeployedVersion = async () => {
    const rand = Math.random();
    return fetch(`/build.txt?rand=${rand}`)
      .then((content) => content.text())
      .then((text) => {
        const versionMatch = text.match(/Version: (.*)/);
        return versionMatch ? versionMatch[1] : null;
      });
  };

  const getCurrentVersion = () => {
    return process.env.REACT_APP_VERSION as string | null;
  }

  const isNewMinorVersionAvailable = async () => {
    const currentVersion = getCurrentVersion();

    let lastDeployedVersion;
    try {
      lastDeployedVersion = await getLastDeployedVersion();
    } catch (e) {
      return false;
    }

    if (!lastDeployedVersion || !currentVersion) {
      return false;
    }

    const [currentMajor, currentMinor] = currentVersion
      .split('.')
      .map((item) => parseInt(item, 10));
    const [deployedMajor, deployedMinor] = lastDeployedVersion
      .split('.')
      .map((item) => parseInt(item, 10));

    return currentMajor < deployedMajor || (currentMajor === deployedMajor && currentMinor < deployedMinor);
  }

  return {
    getLastDeployedVersion,
    isNewMinorVersionAvailable,
  };
}

export default function UpdateCheckerWidget() {
  const service = useUpdateCheckerService();

  const [isNewVersionAvailable, setIsNewVersionAvailable] = React.useState<boolean>(false);

  const [timerIncrement, setTimerIncrement] = React.useState<number>(0);

  useDataLoader(
    service.isNewMinorVersionAvailable,
    setIsNewVersionAvailable,
    timerIncrement,
  );

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimerIncrement((prev) => prev + 1);
    }, 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);


  if (isNewVersionAvailable) {
    return (
      <div
        style={{
          backgroundColor: '#f2ab30',
          paddingTop: '10px',
          paddingBottom: '10px',
          textAlign: 'center',
          color: 'white',
          fontSize: '1.5rem',
          fontWeight: 'bold',
        }}
      >
        A newer version of the application is available. Please refresh the page to get fixes and improvements.
      </div>
    );
  } else {
    return null;
  }

}
