import React from 'react';
import RecordLayout, { RecordLayoutProps } from './RecordLayout';
import PersonLink from './PersonLink';
import TaskLink from './TaskLink';
import { HistoryEventIconType } from './HistoryEventIcon';
import { BaseHistoryEntryData, TaskData } from 'nekst-api';

interface TaskReopenedRecordData extends BaseHistoryEntryData {
  task: TaskData,
}

export default function TaskReopenedRecordLayout(props: RecordLayoutProps<TaskReopenedRecordData>) {
  return (
    <RecordLayout
      text={(
        <>
          <TaskLink task={props.record.data.task} project={props.record.data.project} />
          {' was reopened by '}
          <PersonLink person={props.record.data.person!} />
          .
        </>
      )}
      date={props.record.when}
      iconType={HistoryEventIconType.TASK_REOPENED}
    />
  );
}
