import React from 'react';

import styles from './FilePreview.module.scss';

export type FileData = {
  type: string,
  name: string,
}

export default function FilePreview(
  props: {
    data?: FileData,
  },
) {
  if (props.data) {
    return (
      <div className={styles.preview}>
        {props.data.name}
      </div>
    );
  } else {
    return null;
  }
}
