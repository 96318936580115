import React from 'react';
import { List, ListDataRefresher } from 'ui-builder';
import useDocumentsTemplatesService, {
  DOCUMENTS_TEMPLATES_UPDATED,
} from './documentsTemplatesService';
import GridLayout from '../../../shared/uibuilder/list/GridLayout';
import TextField from '../../../shared/uibuilder/show/field/TextField';
import DocumentLabelsField from '../labels/DocumentLabelsField';
import Cell from '../../../shared/uibuilder/list/Cell';
import { UpdateDocumentsTemplateButton } from './UpdateDocumentsTemplateForm';
import DeleteTemplateButton from './DeleteTemplateButton';
import CopyTemplateButton from './CopyTemplateButton';

export default function DocumentsTemplatesList() {
  const service = useDocumentsTemplatesService();
  return (
    <List
      getDataFunc={service.getDocumentsTemplates}
    >
      <GridLayout noDataMessage="No documents templates created">
        <TextField source="name" />
        <DocumentLabelsField source="_labels" label="Labels" />
        <Cell label="Actions">
          <UpdateDocumentsTemplateButton />
          <CopyTemplateButton />
          <DeleteTemplateButton />
        </Cell>
      </GridLayout>
      <ListDataRefresher eventName={DOCUMENTS_TEMPLATES_UPDATED} />
    </List>
  );
}
