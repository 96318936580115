import React from 'react';

type PopupLayoutReactContextType = {
  isCentered: boolean,
  isClosable: boolean,
};

const PopupLayoutReactContext = React.createContext<PopupLayoutReactContextType>({
  isCentered: false,
  isClosable: false,
});

export function usePopupLayoutContextRequired() {
  return React.useContext(PopupLayoutReactContext);
}

export default function PopupLayoutContext(
  props: {
    isCentered?: boolean,
    isClosable?: boolean,
    children: React.ReactNode,
  }
) {
  return (
    <PopupLayoutReactContext.Provider
      value={{
        isCentered: props.isCentered || false,
        isClosable: props.isClosable !== undefined ? props.isClosable : true,
      }}
    >
      {props.children}
    </PopupLayoutReactContext.Provider>
  );
}
