import React from 'react';
import useDocumentLabelsService, { DocumentLabel } from './documentLabelsService';
import RenameFileInput from '../../../shared/widgets/RenameFileInput';
import Row6x6x from '../../../shared/web/uitheme/form/Row6x6x';
import { AfterSubmitFunc, FormTemplate, useShowContext } from 'ui-builder';
import { OpenFormIconButton } from 'features/nekst-widgets';

function RenameLabelFileForm(props: {
  data: DocumentLabel,
  afterSubmitFunc?: AfterSubmitFunc,
}) {
  const documentLabelService = useDocumentLabelsService();
  return (
    <FormTemplate<{ fileName: string }>
      title="Rename File"
      submitFormFunc={async (data) => {
        await documentLabelService.renameFile(
          props.data.id,
          data.fileName,
        );
        return data;
      }}
      initialData={{
        fileName: props.data._file.name,
      }}
      validationSchema={{
        fileName: {
          type: 'string',
          constraints: {
            required: true,
            regex: {
              value: /^[0-9a-z()\\-]+\.[a-zA-Z0-9]+$/g,
              message: 'Invalid file name',
            },
          },
        },
      }}
      afterSubmitFunc={props.afterSubmitFunc}
    >
      <Row6x6x>
        <RenameFileInput source="fileName" />
      </Row6x6x>
    </FormTemplate>
  );
}

export default function RenameLabelFileButton() {

  const showContext = useShowContext<DocumentLabel>();

  const label = showContext.data!;

  if (label.fileId) {
    return (
      <OpenFormIconButton
        form={<RenameLabelFileForm data={label} />}
        title="Rename Label"
      />
    );
  } else {
    return null;
  }

}
