import { useListContext, useMassUpdateServiceRequired, usePopupManager } from 'ui-builder';
import { isPersonCanBeMassDeleted, Person, usePeopleMassDeleteService } from 'nekst-api';
import { ConfirmDeletePopup } from 'features/nekst-widgets';
import React from 'react';

function DeleteContactsPopup(props: {
  ids: number[],
}) {
  const massDeleteService = usePeopleMassDeleteService();

  return (
    <ConfirmDeletePopup
      deleteFunc={async () => {
        await massDeleteService.massDelete(props.ids);
      }}
      message="This will remove the contacts information from any associated transactions. This action cannot be undone."
    />
  );
}

export function usePeopleMassDeleteUseCase() {
  const popupManager = usePopupManager();

  const massUpdateService = useMassUpdateServiceRequired();

  const selectedIds = massUpdateService.getCheckedIds();

  const listContext = useListContext<Person>();

  const contactsToDeleteIds = selectedIds
    .filter((id) => {
      const person = listContext.data?.find((item) => item.id === id);

      return person && isPersonCanBeMassDeleted(person);
    });

  const handleAction = () => {
    popupManager.openPopup(<DeleteContactsPopup ids={contactsToDeleteIds} />);
  };

  return {
    handleAction,
    isButtonEnabled: contactsToDeleteIds.length > 0,
  }

}
