import React from 'react';
import { IconButton, IconType } from 'features/nekst-widgets';
import { useShowContextRequired } from 'ui-builder';
import { VerifyDataEntry } from './types';

export default function ShowContractPieceButton() {
  const showContext = useShowContextRequired<VerifyDataEntry>();

  const data = showContext.data!;

  if (data.parsedValue?.value) {
    return (
      <IconButton
        type={IconType.MAGNIFYING}
        onClick={() => {
          // ignore
        }}
        title="Coming soon..."
      />
    );
  } else {
    return null;
  }
}
