import React, { useEffect, useState } from 'react';
import { LoadingAnimation, useFilterContext } from 'ui-builder';
import { PeopleFilter, ServiceProviderGroup, useServiceProvidersGroupsService } from 'nekst-api';
import FilterWidgetLayout from '../../projects/view/tasks/list/filter/FilterWidgetLayout';

function TeamMembersWidget() {
  const filterContext = useFilterContext<PeopleFilter>();

  const filterValue = filterContext.filterValue;

  if (filterValue?.showTeamMembersOnly) {
    return (
      <FilterWidgetLayout
        name="Type"
        text="Team Members"
        onClick={() => filterContext.setFilterValue({
          ...filterContext.filterValue!,
          showTeamMembersOnly: false,
        })}
      />
    );
  } else {
    return null;
  }
}

function ServiceProvidersWidget() {
  const filterContext = useFilterContext<PeopleFilter>();

  const filterValue = filterContext.filterValue;

  const [providers, setProviders] = useState<ServiceProviderGroup[]>();

  const serviceProviderService = useServiceProvidersGroupsService();

  async function loadProviders() {
    setProviders(await serviceProviderService.getAll());
  }

  useEffect(() => {
    loadProviders();
  }, []);

  if (filterValue?.showServiceProvidersOnly) {
    if (filterValue.serviceProvidersGroupsIds?.length) {
      if (providers) {
        const names = filterValue.serviceProvidersGroupsIds
          .map((id) => providers.find((provider) => provider.id === id))
          .filter((provider) => !!provider)
          .map((provider) => provider!.name)
          .join(', ');

        return (
          <FilterWidgetLayout
            name="Type"
            text={`Service Providers: ${names}`}
            onClick={() => filterContext.setFilterValue({
              ...filterContext.filterValue!,
              showServiceProvidersOnly: false,
              serviceProvidersGroupsIds: undefined,
            })}
          />
        );
      } else {
        loadProviders();
        return (<LoadingAnimation />);
      }
    } else {
      return (
        <FilterWidgetLayout
          name="Type"
          text="Service Providers: All"
          onClick={() => filterContext.setFilterValue({
            ...filterContext.filterValue!,
            showServiceProvidersOnly: false,
            serviceProvidersGroupsIds: undefined,
          })}
        />
      );
    }
  } else {
    return null;
  }
}

export default function FilterWidgets() {
  return (
    <>
      <TeamMembersWidget />
      <ServiceProvidersWidget />
    </>
  );
}
