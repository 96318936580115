import React, { ReactElement } from 'react';
import { Permission, ShortProfile } from 'nekst-api';
import { FormTemplate, Right, TextArea, useShowContext } from 'ui-builder';
import DefaultLayout from '../../uibuilder/form/layout/DefaultLayout';
import { UiThemeOverrider } from '../../uibuilder/uiTheme';
import SubmitCommentButton from './SubmitCommentButton';
import CancelCircleButton from '../CancelCircleButton';
import { TextAreaWithProjectMemberMention, useProjectId } from 'features/nekst-widgets';

export interface CommentRequestType {
  text: string;
}

export interface CommentType {
  id: number,
  permissions: Permission[],
  text: string
  authorId: number
  _author: ShortProfile,
  createdAt: Date,
}

export interface EditCommentFormProps<
  DataType extends CommentType = CommentType,
  RequestType extends CommentRequestType = CommentRequestType
> {
  afterSubmitFunc: (data: DataType) => void;
  updateItemFunc: (id: number, data: RequestType) => Promise<DataType>;
  closeFormFunc: () => void,
  children?: ReactElement,
}

export default function EditCommentForm<
  DataType extends CommentType = CommentType,
  RequestType extends CommentRequestType = CommentRequestType,
>(props: EditCommentFormProps<DataType, RequestType>) {
  const showContext = useShowContext<DataType>();

  const initialData = showContext.data! as unknown as RequestType;

  const projectId = useProjectId();

  const textInput = projectId
    ? (
      <TextAreaWithProjectMemberMention
        source="text"
        hideLabel
        showRequiredIcon={false}
      />
    )
    : (
      <TextArea
        source="text"
        hideLabel
        showRequiredIcon={false}
      />
    );

  return (
    <UiThemeOverrider
      theme={{
        forms: {
          FormLayout: DefaultLayout,
        },
      }}
    >
      <FormTemplate<RequestType, DataType>
        submitFormFunc={async (data) => {
          return props.updateItemFunc(
            showContext.data!.id as number,
            data,
          );
        }}
        afterSubmitFunc={async (data) => props.afterSubmitFunc(data!)}
        initialData={initialData}
        validationSchema={({
          text: {
            type: 'string',
            constraints: {
              required: true,
            },
          },
        })}
        buttons={(
          <Right>
            <SubmitCommentButton />
            <CancelCircleButton onClick={async () => props.closeFormFunc()} />
          </Right>
        )}
      >
        {textInput}
        {props.children}
      </FormTemplate>
    </UiThemeOverrider>
  );
}
