import React from 'react';

import { PaginationWrapperLayoutProps } from '../types';
import {
  Button,
  ButtonColor,
  ButtonSize,
  LoadingAnimation,
  SimpleBlock,
  useListContextRequired
} from 'ui-builder';

import styles from './PaginationWrapper.module.scss';

export default function PaginationWrapperWeb(props: PaginationWrapperLayoutProps) {

  const listContext = useListContextRequired();

  return (
    <>
      {props.children}
      {listContext.hasMorePages!() ? (
        <SimpleBlock className={styles.loadMoreButtonWrapper}>
          {listContext.isLoading ? (<LoadingAnimation isBig={false} />) : (
            <Button
              color={ButtonColor.BLUE}
              text="Show More"
              size={ButtonSize.SMALL}
              onClick={() => {
                listContext.loadNextPage!();
              }}
              isDisabled={listContext.isLoading}
            />
          )}
        </SimpleBlock>
      ) : null}
    </>
  )
}
