import React from 'react';
import { SubmitButton, AfterSubmitFunc } from 'ui-builder';
import BaseStandardTaskForm from './BaseStandardTaskForm';
import useProjectTasksService, { AbstractTask, StandardTask } from '../../projectTasksService';

export interface CreateStandardTaskFormProps {
  afterSubmitFunc?: AfterSubmitFunc;
  parentTask?: AbstractTask;
  projectId?: number;
}

export default function CreateStandardTaskForm(props: CreateStandardTaskFormProps) {
  const projectTasksService = useProjectTasksService();

  let initialData = {
    projectId: props.projectId,
  } as StandardTask;

  if (props.parentTask) {
    initialData = {
      ...initialData,
      parentTaskRelation: {
        parentTaskId: props.parentTask.id,
        parentTaskName: props.parentTask.name,
      },
    } as StandardTask;
  }

  return (
    <BaseStandardTaskForm
      submitFormFunc={
        (data) => {
          const projectId = props.projectId || data.projectId;
          return projectTasksService.createStandardTask(projectId, data);
        }
      }
      afterSubmitFunc={props.afterSubmitFunc}
      initialData={initialData}
      title="Add Task"
      buttons={<SubmitButton text="Save task" key="save" />}
      showProjectDropdown={!props.projectId}
    />
  );
}
