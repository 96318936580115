import {
  Permission,
  ShortProfile,
  useBackendApi,
  useEventsPublisher,
  useObjectDatesMapper,
} from 'nekst-api';


export interface PersonNote {
  id: number,
  authorId: number,
  text: string,
  createdAt: Date,
  updatedAt: Date,
  permissions: Permission[],
  _author: ShortProfile,
}

export interface PersonNoteRequest {
  text: string,
}

export interface UpdateNoteRequest extends PersonNoteRequest {

}

export interface CreateNoteRequest extends PersonNoteRequest {

}

export const PERSON_NOTES_UPDATED = 'PERSON_NOTES_UPDATED';

export default function usePersonNotesService() {
  const contactsApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const onNotesUpdated = () => {
    eventsPublisher.publish(PERSON_NOTES_UPDATED, {});
  };

  const {
    mapSingle,
    mapList,
  } = useObjectDatesMapper<PersonNote>([], [
    'createdAt',
    'updatedAt',
  ]);

  const getPersonNotes = async (personId: number) => {
    const result = await contactsApi.get(`/people/${personId}/notes`);
    return mapList(result);
  };

  const createNote = async (personId: number, data: CreateNoteRequest) => {
    const result = await contactsApi.post(`/people/${personId}/notes`, {
      text: data.text,
    });

    onNotesUpdated();
    return mapSingle(result);
  };

  const updateNote = async (id: number, data: UpdateNoteRequest) => {
    const result = await contactsApi.put(`/peoplenotes/${id}`, {
      text: data.text,
    });
    onNotesUpdated();

    return mapSingle(result);
  };

  const deleteNote = async (id: number) => {
    await contactsApi.delete(`/peoplenotes/${id}`);
    onNotesUpdated();
  };

  return {
    createNote,
    getPersonNotes,
    updateNote,
    deleteNote,
  };
}
