import React from 'react';
import { BaseInputProps } from './types';
import { useInputHelper } from './inputHelper';
import { useNewUiTheme } from '../../newUiTheme';

export interface NumberProps extends BaseInputProps<number> {
  min?: number,
  max?: number
}

export function NumberInput(props: NumberProps) {
  const helper = useInputHelper<number>(props);

  const uiTheme = useNewUiTheme();

  return (
    <uiTheme.inputs.TextLayout
      {...helper.getBaseInputLayoutProps()}
      value={helper.getValue()?.toString() || null}
      onChangeCallback={(event) => {
        const stringValue = event.target.value;

        let value;

        if (stringValue) {
          value = parseInt(stringValue.replace(/\D/g, ''), 10);
        } else {
          value = undefined;
        }

        helper.getOnChangeCallback()({
          target: {
            value,
          },
        });
      }}
      mobile_keyboardType="number-pad"
      inputType="number"
      min={props.min}
      max={props.max}
    />
  );
}
