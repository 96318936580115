import React from 'react';
import { ItemsListLayoutProps } from 'ui-builder';

export default function ItemsListLayout(
  props: ItemsListLayoutProps,
) {
  return (
    <ul className={props.className}>
      {props.items.map((item) => (
        <li
          key={`item-list-${item}`}
          className={props.itemClassName}
        >
          {item}
        </li>
      ))}
    </ul>
  );
}
