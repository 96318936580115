import { ListFilterService, useListContext, VisibilityState } from 'ui-builder';
import { EVENT_FILTER_NAME } from './eventTypeFilter';
import { HistoryEntry, ProjectHistoryFilter } from 'nekst-api';

export default function useTextFilter(): ListFilterService {
  const listContext = useListContext<HistoryEntry, ProjectHistoryFilter>();

  const defaultValue = null;

  const getFilterValue = () => {
    return listContext.filterValues?.text || defaultValue;
  };

  const setFilterValue = (value: string | null) => {
    listContext.setFilterValues!({
      ...listContext.filterValues! || {},
      text: value,
    });
  };

  const filterFunc = (
    data: HistoryEntry,
  ) => {
    const filterValue = getFilterValue();
    if (filterValue && filterValue.length >= 2) {
      return JSON.stringify(data.data)
        .toLowerCase()
        .includes(filterValue.toLowerCase())
        ? VisibilityState.VISIBLE
        : VisibilityState.HIDDEN;
    } else {
      return VisibilityState.VISIBLE;
    }
  };

  return {
    getName: () => EVENT_FILTER_NAME,
    setFilterValue,
    getFilterValue,
    getDefaultValue: () => defaultValue,
    applyFilter: filterFunc,
  };
}
