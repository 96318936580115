import React from 'react';
import { Route } from 'react-router-dom';
import { ApplicationModule } from '../shared/applicationModule';
import SignUpPage from './SignUpPage';

export default function useSignUpModule(): ApplicationModule {
  const getRouter = () => {
    return (
      <Route
        path="/signup"
        element={(<SignUpPage />)}
      />
    );
  };

  return {
    name: 'signup',
    getRouter,
  };
}
