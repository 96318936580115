import React from 'react';
import usePlanTaskForms from './planTaskForms';
import { ButtonSize, OptionSelectionButton, useFormContext } from 'ui-builder';
import { PlanTask, TaskType, TeamPermission } from 'nekst-api';
import { useAuthorizationChecker } from 'authorization-scope';

export default function CreateDependentTaskButton() {
  const planTaskForms = usePlanTaskForms();

  const formContext = useFormContext();

  const parentTask = formContext.data as PlanTask;

  const { isGranted } = useAuthorizationChecker();

  return (
    <OptionSelectionButton<TaskType>
      text="Create Dependent Task"
      options={[
        {
          value: TaskType.STANDARD,
          label: "Add standard task",
          onSelected: () => {
            planTaskForms.openCreateStandardTaskForm(
              parentTask!.planId,
              parentTask!,
            );
          },
        },
        {
          value: TaskType.EMAIL,
          label: "Add email task",
          onSelected: () => {
            planTaskForms.openCreateEmailTaskForm(
              parentTask!.planId,
              parentTask!,
            );
          }
        },
        isGranted(TeamPermission.USE_SMS_TASKS_FEATURE) && {
          value: TaskType.SMS,
          label: "Add SMS task",
          onSelected: () => {
            planTaskForms.openCreateSmsTaskForm(
              parentTask!.planId,
              parentTask!,
            );
          }
        },
      ]}
      size={ButtonSize.BIG}
    />
  );
}
