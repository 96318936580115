import React from 'react';
import useMakeKeyDateForm from './MakeKeyDateForm';
import {
  Button,
  ButtonColor,
  ButtonSize,
  useMassUpdateServiceRequired
} from 'ui-builder';

export default function MakeKeyDateButton() {
  const massUpdateService = useMassUpdateServiceRequired();

  const form = useMakeKeyDateForm();

  return (
    <Button
      text="Key Date"
      isDisabled={massUpdateService.getCheckedIds().length === 0}
      onClick={async () => {
        form.openForm();
      }}
      color={ButtonColor.YELLOW}
      size={ButtonSize.SMALL}
      isFlat
      isFilled
    />
  );
}
