import { useFilterContext, VisibilityState } from 'ui-builder';
import { AbstractTask, TasksFilter, TaskStatus } from 'nekst-api';
import {
  AdvancedListFilterService
} from './types';

const STATUS_FILTER_NAME = 'STATUS';

export type StatusFilterValue = {
  showActive: boolean,
  showCompleted: boolean
}

export function useStatusFilterService(): AdvancedListFilterService<StatusFilterValue> {
  const filterContext = useFilterContext<TasksFilter>();

  const filterValue = filterContext.filterValue!;

  const defaultValue = {
    showActive: true,
    showCompleted: false,
  };

  const isInitialized = () => {
    return filterValue.showActive !== undefined
      || filterValue.showCompleted !== undefined;
  };

  const getFilterValue = (): StatusFilterValue => {
    if (isInitialized()) {
      return {
        showActive: !!filterValue.showActive,
        showCompleted: !!filterValue.showCompleted,
      };
    } else {
      return defaultValue;
    }
  };

  const toContextForm = (value: StatusFilterValue | undefined) => {
    return {
      ...value || {},
    };
  };

  const setFilterValue = (value: StatusFilterValue | undefined) => {
    filterContext.setFilterValue({
      ...filterValue,
      ...toContextForm(value),
    });
  };

  const filterFunc = (
    data: AbstractTask,
  ) => {
    let result = VisibilityState.VISIBLE;
    const value = getFilterValue();

    if (value.showCompleted || value.showActive) {
      if (!value.showActive) {
        if (data.status !== TaskStatus.COMPLETED) {
          result = VisibilityState.HIDDEN;
        }
      }

      if (!value.showCompleted) {
        if (data.status === TaskStatus.COMPLETED) {
          result = VisibilityState.HIDDEN;
        }
      }
    }

    return result;
  };

  return {
    getName: () => STATUS_FILTER_NAME,
    getDefaultValue: () => defaultValue,
    getFilterValue,
    setFilterValue,
    applyFilter: filterFunc,
    toContextForm,
  };
}
