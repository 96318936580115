import { Dropdown } from 'ui-builder';
import React from 'react';

export default function FieldTypeDropdown(
  props: { source: string, label?: string, isDisabled?: boolean }
) {
  return (
    <Dropdown
      source={props.source}
      label={props.label}
      isDisabled={props.isDisabled}
      options={[
        {
          label: 'Text',
          value: 'S'
        },
        {
          label: 'Boolean',
          value: 'B'
        },
        {
          label: 'Date',
          value: 'D'
        },
        {
          label: 'Number',
          value: 'N'
        },
      ]}
    />
  );
}
