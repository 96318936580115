import React from 'react';
import { SettingsBreadcrumbs } from '../../settingsModule';
import { Breadcrumb } from '../../../shared/web/layout/Breadcrumbs';
import InnerBlock from '../../../shared/web/layout/InnerBlock';
import DocumentsTemplatesList from './DocumentsTemplatesList';
import { CreateDocumentsTemplateButton } from './CreateDocumentsTemplateForm';

export default function DocumentsTemplatesPage() {
  return (
    <>
      <SettingsBreadcrumbs>
        <Breadcrumb text="Documents Templates" />
      </SettingsBreadcrumbs>
      <InnerBlock
        title="Documents Templates"
        headerRight={<CreateDocumentsTemplateButton />}
      >
        <DocumentsTemplatesList />
      </InnerBlock>
    </>
  );
}
