import React, { useMemo } from 'react';
import BorderedBox from '../../../shared/web/layout/BorderedBox';
import { useProjectContext } from '../ProjectContext';
import {
  ContactAssignments,
  ProjectAssignmentType, ProjectPermission,
} from 'nekst-api';
import { Button, ButtonColor, ButtonSize, IconType, If, List, useShowContext } from 'ui-builder';
import UserLogoField from '../../../shared/widgets/UserLogoField';
import RolesNamesField from '../../../shared/widgets/RolesNamesField';
import EmailField from '../../../shared/widgets/EmailField';
import UserLinkField from '../../../shared/widgets/UserLinkField';
import TextField from '../../../shared/uibuilder/show/field/TextField';

import styles from './ProjectMembersList.module.scss';
import AddTeamMemberButton from '../../launch/CurrentTransactionPartiesForm/AddTeamMemberButton';
import DeleteContactButton from './DeleteContactButton';
import AccessChecker from '../../../shared/authorization/AccessChecker';
import ViewAllWrapper from '../../../shared/widgets/ViewAllWrapper';
import LogoAndDataLayout from '../../../shared/widgets/LogoAndDataLayout';
import { RowRepeater, usePopupFormManager } from 'features/nekst-widgets';
import { AddPersonToTransactionForm } from 'features/tasks-forms';

interface MemberRowProps {
  projectId: number;
}

function MemberRow(props: MemberRowProps) {
  const { data } = useShowContext<ContactAssignments>();

  return (
    <LogoAndDataLayout logo={<UserLogoField source="profile" />}>
      <>
        <div className={styles.name}>
          <If conditionFunc={(item) => item.personId}>
            <UserLinkField
              userIdSource="personId"
              nameSource="profile.name"
            />
          </If>
        </div>
        <div className={styles.roles}>
          <RolesNamesField source="roles" />
        </div>
        <div className={styles.email}>
          <EmailField source="profile.primaryEmail" />
        </div>
        <div className={styles.phone}>
          <TextField source="profile.phoneNumbers[0].number" />
        </div>
        <div className={styles.topRight}>
          <DeleteContactButton contactAssignments={data!} projectId={props.projectId} />
        </div>
      </>

    </LogoAndDataLayout>
  );
}

function AddTransactionPartyButton() {
  const popupManager = usePopupFormManager();

  const { data } = useProjectContext();

  return (
    <Button
      text="Add"
      iconType={IconType.PLUS}
      isFilled
      size={ButtonSize.SMALL}
      color={ButtonColor.BLUE}
      onClick={() => {
        popupManager.openForm(<AddPersonToTransactionForm projectId={data!.id} />);
      }}
    />
  );
}

export function TransactionPartiesList() {
  const {
    assignments,
    data,
  } = useProjectContext();

  const members = useMemo(() => {
    return assignments.filter(
      (item) => [
        ProjectAssignmentType.TRANSACTION_PARTY,
      ].includes(item.type),
    );
  }, [JSON.stringify(assignments)]);

  return (
    <BorderedBox
      dataKey="project-transaction-parties"
      title={`Transaction Parties (${members.length})`}
      headerRight={(
        <AccessChecker
          permissionToCheck={ProjectPermission.MANAGE_ASSIGNMENTS}
          show403Error={false}
        >
          <AddTransactionPartyButton />
        </AccessChecker>
      )}
      className={styles.box}
    >
      <>
        {members.length > 0 && (
          <List
            getDataFunc={async () => members}
            dependencies={[JSON.stringify(members)]}
          >
            <ViewAllWrapper numberOfItems={3}>
              <RowRepeater>
                <MemberRow projectId={data!.id} />
              </RowRepeater>
            </ViewAllWrapper>
          </List>
        )}
        {members.length === 0 && (
          <div style={{ marginTop: '2rem' }}>No transaction parties</div>
        )}
      </>
    </BorderedBox>
  );
}

export default function ProjectMembersList() {
  const {
    assignments,
    data,
  } = useProjectContext();

  const members = useMemo(() => {
    return assignments.filter(
      (item) => [
        ProjectAssignmentType.TASK_ASSIGNMENT,
        ProjectAssignmentType.EDITOR,
        ProjectAssignmentType.VIEWER,
      ].includes(item.type),
    );
  }, [assignments.map((v) => v.roles)
    .flat().length]);

  if (members.length > 0) {
    return (
      <BorderedBox
        dataKey="project-team-members"
        title={`Team Members (${members.length})`}
        headerRight={(
          <AccessChecker
            permissionToCheck={ProjectPermission.MANAGE_ASSIGNMENTS}
            show403Error={false}
          >
            <AddTeamMemberButton
              projectId={data!.id}
              title="Add / Update"
              size={ButtonSize.SMALL}
              isFilled
            />
          </AccessChecker>
        )}
        className={styles.box}
      >
        <List
          getDataFunc={async () => members}
          dependencies={[JSON.stringify(members)]}
        >
          <ViewAllWrapper numberOfItems={3}>
            <RowRepeater idField="personId">
              <MemberRow projectId={data!.id} />
            </RowRepeater>
          </ViewAllWrapper>
        </List>
      </BorderedBox>
    );
  } else {
    return null;
  }
}
