import React from 'react';
import BaseTagsInputLayout from './BaseTagsInputLayout';
import { SmartTagsTextAreaLayoutProps } from 'ui-builder';


export default function SmartTagsTextAreaLayout(props: SmartTagsTextAreaLayoutProps) {
  return (
    <BaseTagsInputLayout
      {...props}
      isMultiLine
    />
  );
}
