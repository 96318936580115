import { useEffect, useMemo, useState } from 'react';
import {
  ListFilterService, Option,
  useListContextRequired,
  VisibilityState
} from 'ui-builder';
import { PlanTask, ProjectRole, TaskType } from 'nekst-api';
import { PlanTasksFilter } from '../types';
import { useProjectRolesLoader } from '../../Shared';

const SMS_EMAIL_RECIPIENT_TYPE = 'SMS_EMAIL_RECIPIENT';

export type SmsEmailRecipientFilterService = ListFilterService<number[], PlanTask> & {
  getOptions: () => Option<number>[];
};

export function usePlanTasksSmsRecipientFilterService(): SmsEmailRecipientFilterService {
  const listContext = useListContextRequired<PlanTask, PlanTasksFilter>();

  const { roles } = useProjectRolesLoader();

  const [usedRoles, setUsedRoles] = useState<ProjectRole[]>([]);

  const defaultValue = useMemo(() => {
    return roles.map((r) => r.id);
  }, [roles.length]);

  const getFilterValue = () => {
    if (listContext.filterValues?.recipientsRolesIds !== undefined) {
      return listContext.filterValues.recipientsRolesIds;
    } else {
      return defaultValue;
    }
  };

  const setFilterValue = (value: number[]) => {
    listContext.setFilterValues!({
      recipientsRolesIds: value,
    });
  };

  const applyFilter = (data: PlanTask) => {
    const filter = getFilterValue();
    if (filter && filter.length > 0) {
      let result = VisibilityState.HIDDEN;

      if (data.type === TaskType.EMAIL) {
        (data.emailRecipients || []).forEach((item) => {
          if (filter.includes(item.projectRoleId)) {
            result = VisibilityState.VISIBLE;
          }
        });
      } else if (data.type === TaskType.SMS) {
        (data.smsRecipients || []).forEach((item) => {
          if (filter.includes(item.projectRoleId)) {
            result = VisibilityState.VISIBLE;
          }
        });
      } else {
        result = VisibilityState.VISIBLE;
      }

      return result;
    } else {
      return VisibilityState.VISIBLE;
    }
  };

  useEffect(() => {
    const data = listContext.data || [];

    const result: Record<number, boolean> = {};

    data.forEach((item: PlanTask) => {
      if (item.type === TaskType.EMAIL) {
        (item.emailRecipients || []).forEach((recipient: any) => {
          result[recipient.projectRoleId] = true;
        });
      }

      if (item.type === TaskType.SMS) {
        (item.smsRecipients || []).forEach((recipient: any) => {
          result[recipient.projectRoleId] = true;
        });
      }
    });

    const rolesResult: ProjectRole[] = [];

    roles.forEach((role) => {
      if (result[role.id]) {
        rolesResult.push(role);
      }
    });

    setUsedRoles(rolesResult);
  }, [JSON.stringify(listContext.data), Object.entries(roles).length]);

  const getOptions = () => {
    return usedRoles
      .map((role) => {
        return {
          value: role.id,
          label: role.name,
        } as Option<number>;
      });
  };

  return {
    getName: () => SMS_EMAIL_RECIPIENT_TYPE,
    getFilterValue,
    setFilterValue,
    getDefaultValue: () => defaultValue,
    applyFilter,
    getOptions,
  };
}
