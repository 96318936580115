import React from 'react';
import {
  PlanUsage,
  ProjectAssignmentUsage,
  ProjectRole,
  ProjectRolePermission,
  ProjectTaskAssignmentUsage,
  RoleHasAssignmentsError,
  useProjectRolesService,
} from 'nekst-api';
import { ButtonColor, usePopupManager, useShowContext } from 'ui-builder';
import {
  ClosePopupButton,
  ConfirmDeletePopup,
  IconButton,
  IconType,
  Popup,
  PopupText
} from 'features/nekst-widgets';

function UsedRoleAssignmentsPopup(
  props: {
    assignments: (ProjectAssignmentUsage | ProjectTaskAssignmentUsage | PlanUsage)[],
  },
) {
  return (
    <Popup
      title="Remove Role"
      content={(
        <PopupText align="left">
          <>
            The role you are removing is currently active in the following plans/transactions. You
            must re-assign the tasks to a new role and un-assign this role from receiving
            email/followup messages before you can remove the role:
            <ul>
              {props.assignments.map((assignment) => {
                let result;

                switch (assignment.type) {
                  case 'PLAN':
                    result = (
                      <>
                        Tasks in the plan
                        {' '}
                        <b>{assignment.plan.name}</b>
                      </>
                    );
                    break;
                  case 'PROJECT_ASSIGNMENT':
                    result = (
                      <>
                        The role of the user
                        {' '}
                        <b>{assignment.person.fullName}</b>
                        {' in the transaction '}
                        <b>{assignment.project.name}</b>
                      </>
                    );
                    break;
                  case 'TASK_ASSIGNMENT':
                    result = (
                      <>
                        The tasks in the transaction
                        {' '}
                        <b>{assignment.project.name}</b>
                      </>
                    );
                    break;
                  default:
                    result = null;
                }

                return (<li>{result}</li>);
              })}
            </ul>
          </>
        </PopupText>
      )}
      buttons={<ClosePopupButton text="Go Back" color={ButtonColor.GRAY} />}
    />
  );
}

function DeleteRolePopup(props: {
  role: ProjectRole,
}) {

  const projectRolesService = useProjectRolesService();

  const popupManager = usePopupManager();
  const onClick = async () => {
    try {
      await projectRolesService.deleteRole(props.role.id);
    } catch (e) {
      if (e instanceof RoleHasAssignmentsError) {
        popupManager.openPopup(
          <UsedRoleAssignmentsPopup assignments={e.getAssignments()} />,
        );
      }

      throw e;
    }
  };

  return (
    <ConfirmDeletePopup
      deleteFunc={onClick}
      message={`Are you sure you want to delete role "${props.role.name}"?`}
    />
  );
}

export default function DeleteRoleButton() {

  const showContext = useShowContext<ProjectRole>();

  const role = showContext.data!;

  const popupManager = usePopupManager();

  if (role._permissions.includes(ProjectRolePermission.DELETE)) {
    const onClick = () => {
      popupManager.openPopup(<DeleteRolePopup role={role} />);
    };
    return (
      <IconButton onClick={onClick} type={IconType.TRASH_CAN} title="Delete Role" />
    );
  } else {
    return null;
  }

}
