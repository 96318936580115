import React from 'react';
import { CheckboxLayoutProps } from '../../../shared/uibuilder/form/input/Checkbox';
import styles from './TransactionParties.module.scss';

export default function BriefcaseCheckboxLayout(props: CheckboxLayoutProps) {

  const getValue = () => props.value || false;

  const invertValue = () => {
    props.onChangeCallback({
      target: {
        value: !getValue(),
      },
    });
  };

  return (
    <span title={props.title}>
      {/* eslint-disable jsx-a11y/click-events-have-key-events,
        jsx-a11y/no-static-element-interactions */}
      <i
        className={`${styles.checkbox} ${props.value ? styles.checked : ''} ${props.isDisabled ? styles.disabled : ''}`}
        onClick={() => invertValue()}
      >
        &nbsp;
      </i>
      {props.label}
    </span>
  );
}
