import {
  Person,
  ProjectAssignment,
  ProjectAssignmentType,
  usePeopleService,
  useProjectAssignmentsService
} from 'nekst-api';
import {
  AfterSubmitFunc,
  CheckboxInput,
  FormBlock,
  FormTemplate,
  InputLayoutIcon,
  isReactNative,
  LoadingAnimation,
  RelativeRow,
  SimpleBlock,
  SubmitButton,
  useFormContext
} from 'ui-builder';
import React, { useState } from 'react';
import { PersonWidget, ProjectRolesMultiDropdown } from 'features/nekst-widgets';
import useDataLoader from 'data-loader';

import styles from './TeamMembersForm.module.scss';
import { Colors, Spacings } from 'react-native-ui-lib';
import { AppSpacings } from 'ui-library-mobile-theme/commonStyles';

type ProjectAssignmentRequest = {
  personId: number,
  roleId: number,
  type: ProjectAssignmentType
  isEditable: boolean,
}

function TeamMemberAssignmentsInput(
  props: {
    teamMember: Person,
  },
) {
  const formContext = useFormContext<{ assignments: ProjectAssignmentRequest[] }>();

  const data = formContext.data!;
  const memberAssignments = data.assignments.filter(
    (assignment) => assignment.personId === props.teamMember.id,
  );

  const [checked, setChecked] = useState(memberAssignments.length > 0);
  const isRequiredAssignee = !!memberAssignments.find(
    (assignment) => !assignment.isEditable,
  );

  const notEditableRoleId = memberAssignments.find(
    (item) => !item.isEditable,
  )?.roleId;

  const onRolesChanged = (newRolesIds: number[]) => {
    const newData = data.assignments.filter(
      (item) => !item.isEditable || (item.personId !== props.teamMember.id),
    );

    const newAssignments: ProjectAssignmentRequest[] = newRolesIds
      .filter((id) => id !== notEditableRoleId)
      .map((id) => ({
        roleId: id,
        personId: props.teamMember.id,
        type: ProjectAssignmentType.TASK_ASSIGNMENT,
        isEditable: true,
      }));

    newData.push(
      ...newAssignments,
    );

    formContext.onChangeCallback!({
      assignments: newData,
    });
  };

  const isDisabled = isRequiredAssignee;

  const toggleEnabled = () => {
    if (checked && !isDisabled) {
      const newData = data.assignments.filter((item) => item.personId !== props.teamMember.id);

      formContext.onChangeCallback!({
        assignments: newData,
      });
      setChecked(false);
    } else {
      setChecked(true);
    }
  };

  if (isReactNative()) {
    return (
      <SimpleBlock
        style={{
          paddingBottom: Spacings.s2,
          gap: Spacings.s2,
          borderBottomWidth: 1,
          borderBottomColor: Colors.$outlineDefault,
          paddingHorizontal: AppSpacings.pageHorizontalPadding,
        }}
        testID="team-member-assignment-row"
      >
        <RelativeRow
          weights={[1, 11]}
          rowStyle={{
            alignItems: 'center'
          }}
        >
          <CheckboxInput
            source="_enabled"
            label=""
            value={checked}
            isDisabled={isDisabled}
            onChangeCallback={toggleEnabled}
            className={styles.checkbox}
          />
          <PersonWidget
            profile={props.teamMember.profile}
            withName
            onClick={!isDisabled ? toggleEnabled : undefined}
          />
        </RelativeRow>
        {checked && (
          <ProjectRolesMultiDropdown
            label=""
            hideLabel
            source="rolesIds"
            value={memberAssignments.map((item) => item.roleId)}
            onChangeCallback={(newData) => {
              onRolesChanged(newData.rolesIds);
            }}
            notEditableIds={notEditableRoleId ? [notEditableRoleId] : undefined}
            icon={InputLayoutIcon.ASSIGNMENT}
          />
        )}
      </SimpleBlock>
    );

  } else {
    return (
      <RelativeRow weights={[1, 5, 6]} rowClassName={styles.row}>
        <CheckboxInput
          source="_enabled"
          label=""
          value={checked}
          isDisabled={isDisabled}
          onChangeCallback={toggleEnabled}
          className={styles.checkbox}
        />
        <PersonWidget
          profile={props.teamMember.profile}
          withName
        />
        {checked && (
          <ProjectRolesMultiDropdown
            label=""
            hideLabel
            source="rolesIds"
            value={memberAssignments.map((item) => item.roleId)}
            onChangeCallback={(newData) => {
              onRolesChanged(newData.rolesIds);
            }}
            notEditableIds={notEditableRoleId ? [notEditableRoleId] : undefined}
          />
        )}
      </RelativeRow>
    );
  }
}

export function TeamMembersAssignmentsForm(props: {
  projectId: number,
  afterSubmitFunc?: AfterSubmitFunc,
  initialTaskAssignees?: ProjectAssignment[],
}) {
  const peopleService = usePeopleService();

  const projectAssignmentsService = useProjectAssignmentsService();

  const [taskAssignees, setTaskAssignees] = useState<ProjectAssignment[] | undefined>();

  const [teamMembers, setTeamMembers] = useState<Person[]>();

  useDataLoader(
    () => projectAssignmentsService.getTaskAssignments(props.projectId),
    async (loadedAssignments) => {
      setTaskAssignees([
        ...loadedAssignments,
        ...props.initialTaskAssignees || [],
      ]);
    },
  );

  useDataLoader(
    peopleService.getTeamMembers,
    setTeamMembers,
  );

  if (taskAssignees && teamMembers) {
    return (
      <FormTemplate<{ assignments: ProjectAssignmentRequest[] }, ProjectAssignmentRequest[]>
        title={isReactNative() ? undefined : 'Add team members and assign roles'}
        submitFormFunc={async (data) => {
          return projectAssignmentsService.setTaskAssignees(props.projectId, data.assignments);
        }}
        buttons={<SubmitButton text="Save" />}
        initialData={{
          assignments: taskAssignees,
        }}
        afterSubmitFunc={props.afterSubmitFunc}
      >
        <FormBlock
          style={{
            gap: Spacings.s3,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {teamMembers.map((member) => (
            <TeamMemberAssignmentsInput
              teamMember={member}
              key={`assignment-member-${member.id}`}
            />
          ))}
        </FormBlock>
      </FormTemplate>
    );
  } else {
    return <LoadingAnimation isBig />;
  }
}
