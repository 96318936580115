import { useBackendApi } from './backendApi';
import { Project } from './Types';

export interface CreateProjectFromContractRequest {

}

export function useProjectFromContractCreator() {

  const backendApi = useBackendApi();

  const createProjectFromContract = async (request: CreateProjectFromContractRequest) => {
    return await backendApi.post('/projects/fromcontract', request) as Project;
  };

  return {
    createProjectFromContract,
  }
}
