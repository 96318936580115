import {
  PasswordLayoutProps,
  PasswordInputProps,
  PasswordInput,
} from 'ui-builder';

export type {
  PasswordLayoutProps,
  PasswordInputProps,
};

export default PasswordInput;
