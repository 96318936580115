import React from 'react';

import { useAssigneeFilterService } from 'features/tasks-list-feature';
import FilterWidgetLayout from '../FilterWidgetLayout';
import { useAuthenticationLoader } from '../../../../../../authentication/AuthenticationContext';
import { useAssignmentsLoader } from './assigneeFilterService';

export default function AssigneesWidget() {
  const assigneeFilterService = useAssigneeFilterService();

  const { assignments: projectAssignments } = useAssignmentsLoader();

  const currentValue = assigneeFilterService.getFilterValue();

  const { currentUser } = useAuthenticationLoader();

  const getNameByUserId = (personId: number) => {
    if (personId === currentUser.id) {
      return 'You';
    } else {
      const assignment = projectAssignments
        .find((item) => item.personId === personId);

      if (assignment) {
        return `${assignment.profile.name.firstName} ${assignment.profile.name.lastName}`;
      } else {
        return null;
      }
    }
  };

  if (currentValue) {
    const resetValue = () => {
      assigneeFilterService.setFilterValue({
        selectAll: true,
        selected: [],
      });
    };

    if (!currentValue.selectAll) {
      const names = currentValue.selected.map((personId) => getNameByUserId(personId));

      return (
        <FilterWidgetLayout name="Assignees" text={names.join(', ')} onClick={resetValue} />
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
}
