import React from 'react';
import {
  useEventsSubscriber,
  EmailTask,
  TaskPermission,
  useProjectTasksService,
  EMAIL_TASK_SENT,
} from 'nekst-api';
import {
  AfterSubmitFunc,
  useFormContext,
} from 'ui-builder';

import UpdateTaskButtons from '../UpdateTaskButtons';
import BaseEmailTaskForm from './BaseEmailTaskForm';
import NameWithCompleteCheckbox from '../NameWithCompleteCheckbox';

import ClosePopupOnTaskCompletion from '../ClosePopupOnTaskCompletion';

export interface UpdateStandardTaskFormProps {
  data: EmailTask;
  afterSubmitFunc?: AfterSubmitFunc;
}

function EmailTaskDataUpdater() {
  const formContext = useFormContext();

  useEventsSubscriber(
    'EmailTaskDataUpdater',
    {
      [EMAIL_TASK_SENT]: (event) => {
        if (formContext.data!.id === event.id) {
          formContext.setFormData!(event.data);
        }
      },
    },
  );

  return null;
}

export default function UpdateEmailTaskForm(props: UpdateStandardTaskFormProps) {
  const projectTasksService = useProjectTasksService();

  return (
    <BaseEmailTaskForm
      afterSubmitFunc={props.afterSubmitFunc}
      submitFormFunc={async (formData) => {
        const result = await projectTasksService.updateTask(props.data.id, formData);
        return result as EmailTask;
      }}
      data={props.data}
      initialData={props.data}
      title={<NameWithCompleteCheckbox data={props.data} />}
      buttons={<UpdateTaskButtons />}
      isDisabled={(data) => {
        return !data.permissions?.includes(TaskPermission.UPDATE);
      }}
    >
      <>
        <EmailTaskDataUpdater />
        <ClosePopupOnTaskCompletion />
      </>
    </BaseEmailTaskForm>
  );
}
