import React from 'react';

import { ProjectPermission } from 'nekst-api';
import MassUpdateControlsLayout
  from '../../../../../shared/widgets/MassUpdate/MassUpdateControlsLayout';
import CompleteButton from './Complete/CompleteButton';
import DeleteButton from './Delete/DeleteButton';
import AssignToButton from './AssignTo/AssignToButton';
import useAuthorizationChecker from '../../../../../shared/authorization/authorizationChecker';
import SetDueDateButton from './SetDueDate/SetDueDateButton';
import { TeamPermission } from 'nekst-api';

export default function MassUpdateControls() {
  const { isGranted } = useAuthorizationChecker();
  return (
    <MassUpdateControlsLayout>
      {isGranted(ProjectPermission.UPDATE) && isGranted(TeamPermission.USE_TEAM_MEMBERS_FEATURE)
        && <AssignToButton />}
      <CompleteButton />
      <SetDueDateButton />
      <DeleteButton />
    </MassUpdateControlsLayout>
  );
}
