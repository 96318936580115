import React from 'react';
import { BaseInputProps, MultiSelectDropdown } from 'ui-builder';

export function getDefaultAiModel() {
  return 'gpt-4o';
}

export default function SelectAiModelDropdown(props: BaseInputProps<string[]>) {
  return (
    <MultiSelectDropdown
      options={[
        {
          label: 'GPT-4o (Not Trained)',
          value: 'gpt-4o'
        },
        {
          label: 'GPT-4 Not Trained',
          value: 'gpt-4-1106-preview'
        },
        {
          label: 'GPT-3.5 Trained',
          value: 'ft:gpt-3.5-turbo-0125:nekst::989U3N5c'
        },
        {
          label: 'GPT-3.5 Not Trained',
          value: 'gpt-3.5-turbo-0125'
        },
      ]}
      {...props}
      max={2}
    />

  );
}
