import { PastDueDatesByMember, ProjectFilter, TotalTasks } from './Types';
import { useBackendApi } from './backendApi';
import { useProjectFilterHelper } from './projectsService';

export type AggregatedStatistic = {
  count: number,
  commission: number
  period: string
}

export type TotalByStatuses = {
  active: number
  closed: number
  archived: number
}

export type ProjectsStatistic = {
  totalByStatus: TotalByStatuses,
  totalTasks: TotalTasks,
  pastDueTasks: PastDueDatesByMember[],
  aggregatedStatistic: AggregatedStatistic[]
}

export  function useProjectStatisticService() {
  const projectApi = useBackendApi();

  const projectFilterHelper = useProjectFilterHelper();

  const getStatistic = async (filter: ProjectFilter) => {
    return await projectApi.get(`/projects/statistics?${projectFilterHelper.getUriQuery(filter)}`) as ProjectsStatistic;
  };

  return {
    getStatistic,
  };
}
