import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Logo.module.scss';
import useFeatureToggleService from 'feature-toggle';

export default function Logo() {
  const navigate = useNavigate();

  const goToTasksPage = () => {
    if (window.location.pathname !== '/tasks') {
      navigate('/tasks');
    }
  };

  const featureToggleService = useFeatureToggleService();

  const logoPath = featureToggleService.isNewUiEnabled() ? '/nekst_logo.new.svg' : '/nekst_logo.svg';

  return (
    <a
      href="/tasks"
      className={styles.logo}
      onClick={(event) => {
        event.preventDefault();
        goToTasksPage();
      }}
    >
      <img src={logoPath} alt="logo" style={{ width: '11.4rem'}} />
    </a>
  );
}
