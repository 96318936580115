import React from 'react';
import { get } from 'lodash';

import { useShowContext } from 'ui-builder';
import FullNameField from './FullNameField';

import styles from './UserLinkField.module.scss';

export interface Props {
  userIdSource: string;
  nameSource: string;
}

export default function UserLinkField(props: Props) {
  const { data } = useShowContext();

  const value = get(data, props.userIdSource) as number;

  const getUserPageLink = () => {
    return `/people/${value}`;
  }

  const openUserPage = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    (window as any).location = getUserPageLink();
  };

  if (value) {
    return (
      <a
        onClick={openUserPage}
        className={styles.link}
        href={getUserPageLink()}
      >
        <FullNameField source={props.nameSource} />
      </a>
    );
  } else {
    return null;
  }

}
