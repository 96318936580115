import React from 'react';
import InnerBlock from '../../shared/web/layout/InnerBlock';
import TeamMembersList from './TeamMembersList';
import { AccessChecker, useAuthorizationChecker } from 'authorization-scope';
import { TeamPermission } from 'nekst-api';
import { SeatUsageContext  } from 'features/tasks-forms';
import { usePopupFormManager } from 'features/nekst-widgets';
import { Button, ButtonColor, IconType } from 'ui-builder';
import { TeamMembersBreadcrumbs } from './TeamMembersBreadcrumbs';
import { useTeamMemberNavigator } from './teamMemberNavigator';
import TeamMembersSeatsWidget from './seat/TeamMembersSeatsWidget';
import {
  TeamMembersManagementSeatsToggleWrapper,
} from 'feature-toggle';
import CreateTeamMemberForm from './CreateTeamMemberForm';



function CreateTeamMemberButton() {
  const popupFormManager = usePopupFormManager();

  const teamMemberNavigator = useTeamMemberNavigator();

  return (
    <Button
      text="Add Team Member"
      iconType={IconType.PLUS}
      onClick={() => {
        popupFormManager.openForm(
          <CreateTeamMemberForm
            afterSubmitFunc={async (teamMember) => {
              teamMemberNavigator.openUpdateMemberPage(teamMember.id);
            }}
          />,
        );
      }}
      color={ButtonColor.BLUE}
      isFilled
    />
  );
}

export default function TeamMembersPage() {
  const { isGranted } = useAuthorizationChecker();
  return (
    <AccessChecker permissionToCheck={TeamPermission.ACCESS_TEAM_MEMBERS_MANAGEMENT}
                   show403Error={false}>
      <TeamMembersBreadcrumbs />
      <SeatUsageContext>
        <InnerBlock
          title="Team Members Management"
          headerRight={isGranted(TeamPermission.MANAGE_TEAM_MEMBERS) ?
            <CreateTeamMemberButton /> : undefined}
        >
          <TeamMembersManagementSeatsToggleWrapper>
            <TeamMembersSeatsWidget />
          </TeamMembersManagementSeatsToggleWrapper>
          <TeamMembersList />
        </InnerBlock>
      </SeatUsageContext>
    </AccessChecker>
  );
}
