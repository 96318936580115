import { Project, ProjectDetailsFieldType, ProjectType } from './Types';
import { useBackendApi } from './backendApi';
import { useEventsPublisher, useEventsSubscriber } from 'event-bus';
import { useCacheService } from './cacheService';
import { PROJECT_DATES_FIELDS_UPDATED } from './projectDatesFieldsService';

export interface ProjectDetailsField {
  id: number
  name: string
  fieldType: ProjectDetailsFieldType
  order: number,
  prompt: string,
  isHidden: boolean
  isPreexisting: boolean
  isProjectStartDate: boolean
  isProjectEndDate: boolean
  isExcludedFromSet: boolean
  parentFieldId?: number,
  _parentField?: {
    id: number,
    name: string,
    fieldType: ProjectDetailsFieldType,
    isProjectStartDate: boolean,
    isProjectEndDate: boolean,
  },
}

export interface CreateProjectDetailsFieldRequest {
  name: string;
  fieldType: ProjectDetailsFieldType;
  order: number;
}

export interface UpdateProjectDetailsFieldRequest {
  name: string,
  prompt?: string,
}

export interface UpdatePredefinedFieldRequest {
  isHidden: boolean,
  prompt?: string,
  [key: string]: any
}

export interface SetHiddenRequest {
  isHidden: boolean,

  [key: string]: any
}

export const PROJECT_DETAILS_FIELDS_UPDATED = 'PROJECT_DETAILS_FIELDS_UPDATED';

export const PROJECT_DETAILS_FIELD_ORDER_UPDATED = 'PROJECT_DETAILS_FIELDS_ORDER_UPDATES';

export function useProjectDetailsFieldsService() {
  const nekstApi = useBackendApi();
  const eventsPublisher = useEventsPublisher();

  const cacheService = useCacheService('ProjectDetailsFieldsService');

  function clearProjectTypeCache(projectType: ProjectType) {
    cacheService.clear(`fields-${projectType}`);
  }

  useEventsSubscriber(
    'ProjectDetailsFieldsService',
    {
      [PROJECT_DETAILS_FIELDS_UPDATED]: (eventData) => {
        clearProjectTypeCache(eventData.projectType);
      },
      [PROJECT_DETAILS_FIELD_ORDER_UPDATED]: (eventData) => {
        clearProjectTypeCache(eventData.projectType);
      },
      [PROJECT_DATES_FIELDS_UPDATED]: (eventData) => {
        clearProjectTypeCache(eventData.projectType);
      }
    },
  );

  const getFields = async (
    projectType: ProjectType,
    returnHidden = false,
  ) => {
    const result = await cacheService.get<ProjectDetailsField[]>(`fields-${projectType}`, async (cacheItem) => {
      cacheItem.setExpireInSeconds(300);
      return await nekstApi.get(`/settings/projects/${projectType.toLowerCase()}/detailfields`) as ProjectDetailsField[];
    });

    if (returnHidden) {
      return result;
    } else {
      return result.filter((item) => !item.isHidden);
    }
  };

  const createField = async (
    projectType: ProjectType,
    request: CreateProjectDetailsFieldRequest,
  ) => {
    const result = await nekstApi.post(`/settings/projects/${projectType.toLowerCase()}/detailfields`, request) as ProjectDetailsField;

    eventsPublisher.publish(PROJECT_DETAILS_FIELDS_UPDATED, {
      projectType,
    });
    return result;
  };

  const updateField = async (
    projectType: ProjectType,
    fieldId: number,
    request: UpdateProjectDetailsFieldRequest,
  ) => {
    const result = await nekstApi.put(`/settings/projects/${projectType.toLowerCase()}/detailfields/${fieldId}`, request) as ProjectDetailsField;

    eventsPublisher.publish(PROJECT_DETAILS_FIELDS_UPDATED, {
      projectType,
    });
    return result;
  };

  const updatePredefinedField = async (
    projectType: ProjectType,
    fieldId: number,
    request: UpdatePredefinedFieldRequest,
  ) => {
    const result = await nekstApi.put(`/settings/projects/${projectType.toLowerCase()}/detailfields/global/${fieldId}`, request) as ProjectDetailsField;

    eventsPublisher.publish(PROJECT_DETAILS_FIELDS_UPDATED, {
      projectType,
    });
    return result;
  };

  const setHidden = async (
    projectType: ProjectType,
    fieldId: number,
    request: SetHiddenRequest,
  ) => {
    const result = await nekstApi.put(`/settings/projects/${projectType.toLowerCase()}/detailfields/${fieldId}/hidden`, request) as ProjectDetailsField;

    eventsPublisher.publish(PROJECT_DETAILS_FIELDS_UPDATED, {
      projectType,
    });

    return result;
  };

  const deleteField = async (
    projectType: ProjectType,
    fieldId: number,
  ) => {
    const result = await nekstApi.delete(`/settings/projects/${projectType.toLowerCase()}/detailfields/${fieldId}`) as void;

    eventsPublisher.publish(PROJECT_DETAILS_FIELDS_UPDATED, {
      projectType,
    });

    return result;
  };

  const getFieldUsages = async (
    fieldId: number,
  ) => {
    return await nekstApi.get(`/projects/details/fields/${fieldId}/usages`) as Project[];

  };

  const setOrder = async (
    projectType: ProjectType,
    ids: number[],
  ) => {
    const result = await nekstApi.put(`/settings/projects/${projectType}/detailfields/bulk/order`, {
      ids,
    }) as ProjectDetailsField[];

    eventsPublisher.publish(PROJECT_DETAILS_FIELD_ORDER_UPDATED, {
      projectType,
    });
    return result;
  };

  const getFieldsInSet = async (
    setId: number,
    returnHidden = false
  ) => {
    return await nekstApi.get(`/detailsfields/sets/${setId}/fields?returnHidden=${returnHidden}`) as ProjectDetailsField[];
  }

  const __clearCache = () => {
    // clear cache for all project types
    Object.values(ProjectType)
      .forEach((projectType) => {
        clearProjectTypeCache(projectType);
      });
  };

  return {
    getFields,
    createField,
    updateField,
    updatePredefinedField,
    setHidden,
    deleteField,
    getFieldUsages,
    setOrder,
    getFieldsInSet,
    __clearCache,
  };
}
