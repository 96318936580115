import React from 'react';
import {
  Button,
  ButtonSize,
  ButtonColor,
} from 'ui-builder';
import MassUpdateControlsLayout from '../../../shared/widgets/MassUpdate/MassUpdateControlsLayout';
import { usePeopleMassDeleteUseCase } from 'features/tasks-forms';

export function DeleteButton() {
  const massDeleteLogic = usePeopleMassDeleteUseCase();

  return (
    <Button
      text="Delete"
      isDisabled={!massDeleteLogic.isButtonEnabled}
      isFlat
      isFilled
      size={ButtonSize.SMALL}
      color={ButtonColor.RED}
      onClick={massDeleteLogic.handleAction}
    />
  );
}

export default function MassUpdateControls() {
  return (
    <MassUpdateControlsLayout>
      <DeleteButton />
    </MassUpdateControlsLayout>
  );
}
