import { ListFilterService, useListContext, VisibilityState } from 'ui-builder';
import { PlanTask, TaskType } from 'nekst-api';
import { PlanTasksFilter } from '../types';

export const TYPE_FILTER_NAME = 'TYPE';

export type PlanTasksTypeFilterValue = {
  showStandardTasks: boolean,
  showEmailTasks: boolean,
  showSmsTasks: boolean,
  showKeyDatesOnly: boolean,
  showPrivateOnly: boolean,
}

export function usePlanTasksTypeFilterService(): ListFilterService<PlanTasksTypeFilterValue, PlanTask> {
  const listContext = useListContext<PlanTask, PlanTasksFilter>();

  const defaultValue = {
    showStandardTasks: true,
    showEmailTasks: true,
    showSmsTasks: true,
    showKeyDatesOnly: false,
    showPrivateOnly: false,
  } as PlanTasksTypeFilterValue;

  const isInitialized = () => {
    return listContext.filterValues?.showStandardTasks !== undefined
      || listContext.filterValues?.showEmailTasks !== undefined
      || listContext.filterValues?.showSmsTasks !== undefined
      || listContext.filterValues?.showPrivateOnly !== undefined
      || listContext.filterValues?.showKeyDatesOnly !== undefined;
  };

  const getFilterValue = () => {
    if (isInitialized()) {
      return {
        showStandardTasks: listContext.filterValues?.showStandardTasks,
        showEmailTasks: listContext.filterValues?.showEmailTasks,
        showSmsTasks: listContext.filterValues?.showSmsTasks,
        showPrivateOnly: listContext.filterValues?.showPrivateOnly,
        showKeyDatesOnly: listContext.filterValues?.showKeyDatesOnly,
      } as PlanTasksTypeFilterValue;
    } else {
      return defaultValue;
    }
  };

  const setFilterValue = (value: PlanTasksTypeFilterValue) => {
    listContext.setFilterValues!(value);
  };

  const applyFilter = (data: PlanTask) => {
    let result = VisibilityState.HIDDEN;

    const filterValue = getFilterValue();

    if (filterValue.showStandardTasks && data.type === TaskType.STANDARD) {
      result = VisibilityState.VISIBLE;
    } else if (filterValue.showEmailTasks && data.type === TaskType.EMAIL) {
      result = VisibilityState.VISIBLE;
    } else if (filterValue.showSmsTasks && data.type === TaskType.SMS) {
      result = VisibilityState.VISIBLE;
    }

    if (filterValue.showKeyDatesOnly && !data.isKeyDate) {
      result = VisibilityState.HIDDEN;
    }

    if (filterValue.showPrivateOnly && !data.isPrivate) {
      result = VisibilityState.HIDDEN;
    }

    return result;
  };

  return {
    getName: () => TYPE_FILTER_NAME,
    getDefaultValue: () => defaultValue,
    getFilterValue,
    setFilterValue,
    applyFilter,
  };
}
