import { useBackendApi } from './backendApi';
import { NotificationType } from './notificationsService';

export type NotificationsSettings = {
  [key in NotificationType]: {
    enabled: boolean;
    pushNotificationsEnabled: boolean;
  };
};

export function useNotificationsSettingsService() {
  const backendApi = useBackendApi();

  const getSettings = async () => {
    return await backendApi.get('/notifications/settings') as NotificationsSettings;
  }

  const saveSettings = async (settings: NotificationsSettings) => {
    return await backendApi.patch('/notifications/settings', {
      configuration: settings
    });
  }

  return {
    getSettings,
    saveSettings
  }
}
