import React, { ReactElement } from 'react';
import { useNewUiTheme } from "../../newUiTheme";
import { useInputHelper } from "./inputHelper";
import { BaseInputProps } from "./types";

export type RadioButtonOption<DataType = string> = {
  value: DataType,
  label: string,
  description?: string
  title?: string
  disabled?: boolean
}

export interface RadioButtonsGroupProps<Type> extends BaseInputProps<Type> {
  options: RadioButtonOption<Type>[];
}

export interface RadioButtonsGroupLayoutProps<Type extends string | number = string | number> {
  label: string | ReactElement,
  hideLabel?: boolean,
  name: string,
  value?: Type,
  errors?: string[],
  options: RadioButtonOption<Type>[],
}

export function RadioButtonsGroup<Type extends string | number>(props: RadioButtonsGroupProps<Type>) {
  const helper = useInputHelper<Type>(props);
  const uiTheme = useNewUiTheme();

  return (
    <uiTheme.inputs.RadioButtonsGroupLayout
      name={helper.getSource()}
      errors={helper.getErrors()}
      label={helper.getLabel()}
      value={helper.getValue()}
      options={props.options}
      hideLabel={props.hideLabel}
    />
  );
}
