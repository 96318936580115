import React from 'react';
import Button, { ButtonColor, ButtonSize, IconType } from '../../shared/web/button/Button';
import { useCreatePersonForm } from './CreatePersonForm';

export default function CreateContactButton() {
  const form = useCreatePersonForm();

  return (
    <Button
      text="Add contact"
      onClick={() => form.open()}
      size={ButtonSize.MEDIUM}
      isFilled
      color={ButtonColor.BLUE}
      iconType={IconType.PLUS}
    />
  );
}
