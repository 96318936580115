import { PeopleFilter, usePeopleFilterHelper, useBackendApi } from 'nekst-api';

export interface PeopleStatistic {
  total: number,
  inLast30Days: number
  totalActive: number
}

export default function usePeopleStatisticService() {

  const contactsApi = useBackendApi();

  const peopleFilterHelper = usePeopleFilterHelper();

  const getPeopleStatistic = async (filter?: PeopleFilter) => {
    const query = peopleFilterHelper.getUriQuery(filter);
    return await contactsApi.get(`/people/statistic?${query}`) as PeopleStatistic;
  };

  return {
    getPeopleStatistic,
  };
}
