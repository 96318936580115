import { BaseFieldProps } from './types';
import { useShowContext } from '../Show';
import { get } from 'lodash';

export function useFieldHelper<DataType = string>(
  props: BaseFieldProps<DataType>,
) {
  const showContext = useShowContext();

  const getLabel = () => {
    return props.label;
  };

  const getValue = () => {
    let result;
    if (props.value) {
      result = props.value;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      result = get(showContext.data!, props.source) as DataType | undefined;
    }

    if (props.formatValueFunc) {
      return props.formatValueFunc(result);
    } else {
      return result;
    }
  };

  const getStyle = () => {
    return props.style;
  };

  const getClassName = () => {
    return props.className;
  }

  const getBaseLayoutProps = () => {
    return {
      label: getLabel(),
      value: getValue(),
      style: getStyle(),
      className: getClassName(),
      noDataMessage: props.noDataMessage,
    };
  };

  return {
    getLabel,
    getValue,
    getBaseLayoutProps,
  };
}
