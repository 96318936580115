import {
  ListFilterService,
  Option,
  useListContextRequired,
  VisibilityState
} from 'ui-builder';
import { PlanTask, ProjectRole } from 'nekst-api';
import { useEffect, useMemo, useState } from 'react';
import { PlanTasksFilter } from '../types';
import { useProjectRolesLoader } from '../../Shared';

export const ASSIGNEE_FILTER_TYPE = 'ASSIGNEE';

export type PlanTaskAssigneeFilterService = ListFilterService<number[], PlanTask> & {
  getOptions: () => Option<number>[],
}

export function usePlanTaskAssigneeFilterService(): PlanTaskAssigneeFilterService {

  const [usedRoles, setUsedRoles] = useState<ProjectRole[]>([]);

  const listContext = useListContextRequired<PlanTask, PlanTasksFilter>();

  const { roles } = useProjectRolesLoader();

  useEffect(() => {
    const data = listContext.data || [];

    const result: Record<number, boolean> = {};

    data.forEach((item: PlanTask) => {
      (item.transactionParties || []).forEach((transactionParty: any) => {
        result[transactionParty.projectRoleId] = true;
      });
    });

    const rolesResult: ProjectRole[] = [];

    roles.forEach((role) => {
      if (result[role.id]) {
        rolesResult.push(role);
      }
    });

    setUsedRoles(rolesResult);
  }, [JSON.stringify(listContext.data), Object.entries(roles).length]);

  const defaultValue = useMemo(() => {
    return roles.filter((r) => r.isAssignable)
      .map((r) => r.id);
  }, [roles.length]);

  const getFilterValue = () => {
    if (listContext.filterValues?.assignedRolesIds !== undefined) {
      return listContext.filterValues.assignedRolesIds;
    } else {
      return defaultValue;
    }
  };

  const setFilterValue = (value: number[]) => {
    listContext.setFilterValues!({
      assignedRolesIds: value,
    });
  };

  const applyFilter = (data: PlanTask) => {
    const filter = getFilterValue();
    if (filter && filter.length > 0) {
      let result = VisibilityState.HIDDEN;

      data.transactionParties.forEach((party: any) => {
        if (filter.includes(party.projectRoleId)) {
          result = VisibilityState.VISIBLE;
        }
      });

      return result;
    } else {
      return VisibilityState.VISIBLE;
    }
  };

  const getOptions = () => {
    return usedRoles
      .map((role) => {
        return {
          value: role.id,
          label: role.name,
        } as Option<number>;
      });
  };

  return {
    getName: () => ASSIGNEE_FILTER_TYPE,
    getFilterValue,
    setFilterValue,
    getDefaultValue: () => defaultValue,
    applyFilter,
    getOptions,
  };
}
