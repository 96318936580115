import React from 'react';
import { useShowContextRequired } from 'ui-builder';
import { AbstractTask, CommentSeenStatus } from 'nekst-api';
import Layout from './Layout';

export function CommentsNumberField() {
  const showContext = useShowContextRequired<AbstractTask>();

  const data = showContext.data;

  if (data) {
    return (
      <Layout
        numberOfComments={data.numberOfComments}
        hasUnseenComments={data.commentSeenStatus === CommentSeenStatus.UNREAD}
      />
    );
  } else {
    return null;
  }
}
