import { Platform, StyleSheet } from 'react-native';
import { Colors, Spacings } from 'react-native-ui-lib';

const AppSpacings = {
  pageHorizontalPadding: Spacings.s4,
  pageVerticalPadding: Spacings.s5,
  listRowHorizontalPadding: Spacings.s4,
  listRowVerticalPadding: Spacings.s2,
  pageHeaderMargin: Spacings.s5,
}

const commonStyles = StyleSheet.create({
  blockHeader: {
    fontFamily: 'AlbertSans_700Bold',
    fontSize: 17,
    lineHeight: 19,
    color: Colors.$textPrimary,
  },
  text: {
    fontFamily: 'PTSans_400Regular',
    color: Colors.$textDefault,
  },
  bold: {
    fontFamily: 'PTSans_700Bold',
  },
  pressed: {
    opacity: 0.8,
  },
  screen: {
    padding: Spacings.s3,
  },
  formBlock: {
    paddingLeft: Spacings.s6,
    paddingRight: Spacings.s6,
  },
  grey: {
    backgroundColor: Colors.rgba(Colors.$backgroundNeutralHeavy, 0.3),
    paddingTop: Spacings.s6,
    paddingBottom: Spacings.s6,
    borderTopLeftRadius: Spacings.s6,
    borderTopRightRadius: Spacings.s6,
    marginTop: Spacings.s3,
  },
  shadowTop: {
    backgroundColor: Colors.$backgroundNeutralIdle,
    ...Platform.select({
      ios: {
        shadowColor: 'black',
        shadowOffset: { width: 0, height: -10 }, // negative value for top shadow
        shadowOpacity: 0.1,
        shadowRadius: 6,
      },
      android: {
        elevation: 20,
      },
    }),
  },
  shadowTopAndBottom: {
    backgroundColor: Colors.$backgroundNeutralIdle,
    ...Platform.select({
      ios: {
        shadowColor: 'black',
        shadowOffset: { width: 0, height: -10 }, // Negative value for top shadow
        shadowOpacity: 0.1,
        shadowRadius: 6,
      },
      android: {
        elevation: 20, // Elevation for bottom shadow
      },
    }),
    borderBottomWidth: 10, // Adjust the value as needed for the bottom shadow
    borderBottomColor: 'rgba(0, 0, 0, 0.1)', // Adjust the color and opacity as needed
  },
  blockShadow: {
    shadowColor: Colors.grey60,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    elevation: 2, // Only for Android
    backgroundColor: Colors.white,
  },
  listRow: {
    flexDirection: 'row',
    width: '100%',
    paddingHorizontal: AppSpacings.pageHorizontalPadding,
    paddingVertical: AppSpacings.pageVerticalPadding,
    borderBottomWidth: 1,
    borderBottomColor: Colors.$outlineDefault,
  }
});

export {
  commonStyles,
  AppSpacings,
};
