import React from 'react';
import useUserSessionStorage from '../../../login/userSessionStorage';
import { WarningMessage } from 'features/nekst-widgets';
import { useAuthenticationContext } from 'nekst-api';

export default function ImpersonationUserWarning() {
  const sessionService = useUserSessionStorage();

  const authenticationContext = useAuthenticationContext();
  const userFullName = authenticationContext?.user.profile.name.fullName;

  if (sessionService.hasImpersonatedToken()) {
    return (
      <WarningMessage>
        {'Please be aware that you are currently impersonating the account of '}
        {userFullName!}
        {'  for testing purposes. Click "Logout" to stop impersonation.'}
      </WarningMessage>
    );
  } else {
    return null;
  }
}
