import { ConstraintValidator, ConstraintValue } from '../index';
import useConstraintValidatorHelper from './constaintValidatorHelper';

export interface EmailConstraintValidator extends ConstraintValidator<string> {
}

export default function useEmailConstraintValidator(): EmailConstraintValidator {

  const helperFactory = useConstraintValidatorHelper<number>();

  const validate = (
    object: Record<string, any>,
    fieldName: string,
    constraintConfiguration: ConstraintValue<string>,
  ): boolean => {

    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    const helper = helperFactory.createHelper(
      object,
      fieldName,
      constraintConfiguration,
    );
    const value = helper.getValue();

    if (helper.hasValue()) {
      return value.match(re);
    } else {
      return true;
    }
  };

  const getErrorMessage = (config: ConstraintValue<string>) => {
    return (typeof config === 'object' && config.message)
      || 'The field value should be a valid email';
  };

  return {
    getName: () => 'email',
    validate,
    getErrorMessage,
  };
}
