import { useEffect, useState } from 'react';
import { FormFieldsData, useFormContext } from 'ui-builder';
import { ProjectRole } from 'nekst-api';

export default function useTransactionPartiesField(projectRoles: ProjectRole[]) {

  const formContext = useFormContext();

  const getData = (): FormFieldsData => {
    return formContext.data || {};
  };

  const [value, setValue] = useState<Record<number, boolean>>({});

  const getValue = () => {
    return value || {};
  };

  const getManagerRoleId = (): number => {
    return projectRoles?.find((role) => role.isOwnerRole)!.id;
  };

  const mapToApiView = (data: any): any => {
    const result: any[] = [];

    Object.entries(data as Record<number, boolean>)
      .forEach(
        ([id, v]) => {
          if (v) {
            result.push({
              projectRoleId: parseInt(id, 10),
            });
          }
        },
      );

    return result;
  };

  const getAssigneesNumber = () => {
    return Object
      .entries(getValue())
      .filter(([, v]) => v)
      .length;
  };

  const changeValue = (id: number) => {
    const currentValue = getValue();
    currentValue[id] = !currentValue[id];

    formContext.onChangeCallback!({
      transactionParties: mapToApiView(currentValue),
    });
  };

  const mapToUiView = (): any => {
    if (projectRoles) {
      if (getData() && getData().transactionParties && getData().transactionParties.length > 0) {
        const result: Record<number, boolean> = {};
        getData()
          .transactionParties
          .forEach((party: any) => {
            result[party.projectRoleId] = true;
          });

        setValue(result);
      } else {
        changeValue(getManagerRoleId());
      }
    }
  };

  useEffect(
    () => {
      mapToUiView();
    },
    [JSON.stringify(getData().transactionParties), JSON.stringify(projectRoles)],
  );

  const isRoleAssigned = (id: number): boolean => {
    return getValue() && value[id];
  };

  return {
    changeValue,
    isRoleAssigned,
    getAssigneesNumber,
  };
}
