import { ListFilterService, useListContextRequired, VisibilityState } from 'ui-builder';
import { PlanTask } from 'nekst-api';
import { PlanTasksFilter } from '../types';

const NAME = 'NAME';

export function usePlanTasksNameFilterService(): ListFilterService<string, PlanTask & {
  _renderedName: string;
}> {

  const listContext = useListContextRequired<PlanTask, PlanTasksFilter>();

  const defaultValue = undefined;

  const getFilterValue = () => {
    if (listContext.filterValues?.nameContains !== undefined) {
      return listContext.filterValues.nameContains;
    } else {
      return defaultValue;
    }
  };

  const setFilterValue = (value: string) => {
    listContext.setFilterValues!({
      nameContains: value,
    });
  };

  const applyFilter = (data: PlanTask & { _renderedName: string }) => {
    const filter = getFilterValue();
    if (filter && filter.length > 0) {
      let result = VisibilityState.HIDDEN;

      if (data._renderedName?.toLowerCase()
        .includes(filter.toLowerCase())) {
        result = VisibilityState.VISIBLE;
      }

      return result;
    } else {
      return VisibilityState.VISIBLE;
    }
  };

  return {
    getName: () => NAME,
    getFilterValue,
    setFilterValue,
    getDefaultValue: () => defaultValue,
    applyFilter,
  };
}
