import React from 'react';
import Button, { ButtonColor, ButtonSize } from '../shared/web/button/Button';
import { useShowContext } from '../shared/uibuilder/show/ShowReactContext';
import { AbstractTask, TaskPermission } from '../projects/view/tasks/projectTasksService';
import useDeleteTaskPopupManager from '../projects/view/tasks/list/DeleteTaskPopup';

export default function DeleteTaskButton() {

  const deletePopupManager = useDeleteTaskPopupManager();

  const showContext = useShowContext<AbstractTask>();

  if (showContext.data!.permissions.includes(TaskPermission.DELETE)) {
    return (
      <Button
        text="Delete"
        color={ButtonColor.RED}
        size={ButtonSize.MEDIUM}
        onClick={async () => {
          await deletePopupManager.openPopup(
            showContext.data!.id,
            showContext.data!.name,
          );
        }}
      />
    );
  } else {
    return null;
  }
}
