import {
  DueDateFilterValue,
  NO_DUE_DATE_VALUE,
  useDueDateFilterService
} from './dueDateFilterService';

import {
  useDueDateCounterService
} from '../List/DueDateCounter';

export enum FilterOptionType {
  PAST_DUE = 'PAST_DUE',
  TODAY = 'TODAY',
  NO_DUE_DATE = 'NO_DUE_DATE',
  ALL_TASKS = 'ALL_TASKS',
}

function formatDate(date: Date) {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
}

export function useDueDateFilterOption(
  type: FilterOptionType
) {

  const filterService = useDueDateFilterService();

  const currentValue = filterService.getFilterValue();

  const dueDateCounterService = useDueDateCounterService();

  const getValue = (): DueDateFilterValue => {
    if (type === FilterOptionType.TODAY) {
      return [formatDate(new Date()), formatDate(new Date())];
    } else if (type === FilterOptionType.PAST_DUE) {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);

      return [null, formatDate(yesterday)];
    } else if (type === FilterOptionType.NO_DUE_DATE) {
      return NO_DUE_DATE_VALUE;
    } else {
      return null;
    }
  };

  const onClick = () => {
    filterService.setFilterValue(getValue());
  };

  const isActive = () => {
    if (currentValue && getValue()) {
      return currentValue.toString() === getValue()!.toString();
    } else {
      return currentValue === getValue();
    }
  };

  const getCount = () => {
    if (type === FilterOptionType.TODAY) {
      return dueDateCounterService.getNumberOfTodayTasks();
    } else if (type === FilterOptionType.PAST_DUE) {
      return dueDateCounterService.getNumberOfPastDueTasks();
    } else if (type === FilterOptionType.ALL_TASKS) {
      return dueDateCounterService.getTotalNumberOfTasks();
    } else if (type === FilterOptionType.NO_DUE_DATE) {
      return dueDateCounterService.getNumberOfNoDueDateTasks();
    } else {
      return 0;
    }
  };

  return {
    onClick,
    isActive,
    getCount,
  };
}
