import React from 'react';
import {
  AccessDeniedError,
  ResourceArchivedError,
  ResourceNotFoundError,
} from 'nekst-api';

import * as Sentry from '@sentry/react';

export interface HttpErrorsBoundaryProps {
  children: React.ReactNode;
}

export const useAsyncError = () => {
  const [, setError] = React.useState();
  return React.useCallback(
    (e: any) => {
      setError(() => {
        throw e;
      });
    },
    [setError],
  );
};

export default function HttpErrorsBoundary(props: HttpErrorsBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      dialogOptions={{
        lang: 'en',
      }}
      fallback={({ error }) => {

        let message;
        if (error instanceof ResourceNotFoundError) {
          message = 'The resource you\'ve requested is not found.';
        } else if (error instanceof AccessDeniedError) {
          message = 'Access denied.';
        } else if (error instanceof ResourceArchivedError) {
          message = 'The page you are trying to access is no longer available, as the code has expired. Please contact your agent for further information and assistance.';
        } else {
          message = 'Something went wrong. Try reload the page.';
        }

        return (
          <div
            className="error-message"
            style={{
              fontSize: '1.8rem',
              width: '50%',
            }}
          >
            {message}
          </div>
        );

      }}
    >
      {props.children}
    </Sentry.ErrorBoundary>
  );
}
