import React from 'react';
import Checkbox from '../shared/uibuilder/form/input/Checkbox';

export default function ApplyRulesCheckbox() {
  return (
    <div className="form-row">
      <Checkbox
        source="_applyRules"
        label={(
          <>
            By creating an account, I accept Nekst App
            {' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="http://nekst.com/terms"
              target="_blank"
              rel="noreferrer"
              tabIndex={-1}
            >
              Terms of Service
            </a>
            {' '}
            and
            {' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="http://nekst.com/privacy" target="_blank" rel="noreferrer" tabIndex={-1}>Privacy Policy</a>
            .
          </>
        )}
      />
    </div>
  );
}
