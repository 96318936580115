import React from 'react';
import { SubscriptionPlanId, SubscriptionStatus, TeamPermission } from 'nekst-api';
import { useBillingContext } from './BillingPageContext';
import { useAuthorizationChecker } from 'authorization-scope';
import { ErrorMessage, WarningMessage } from 'features/nekst-widgets';
import { useStripePortalNavigator } from './ManageSubscriptionOptionsButton';
import { TextLink } from 'ui-builder';

function OwnerErrorMessage(
  props: {
    status: SubscriptionStatus,
  },
) {
  const clientPortalNavigator = useStripePortalNavigator();

  if (props.status === SubscriptionStatus.NOT_FOUND) {
    return (
      <ErrorMessage>
        It looks like you don&apos;t have an active subscription. Please select a subscription plan
        from the list below and subscribe to unlock Nekst&apos;s full functionality.
      </ErrorMessage>
    );
  } else {
    return (
      <WarningMessage>
        There&apos;s an issue with your subscription. Please click
        {' '}
        <TextLink
          onClick={() => clientPortalNavigator.navigateToBillingInformation()}
        >here</TextLink>
        {' '}
        to resolve it or contact us for assistance.
      </WarningMessage>
    );
  }
}

function MemberErrorMessage(
  props: {
    status: SubscriptionStatus,
  },
) {
  if (props.status === SubscriptionStatus.NOT_FOUND) {
    return (
      <ErrorMessage>
        Looks like your team doesn&apos;t have an active subscription.
        Please contact your team owner to resolve this issue.
      </ErrorMessage>
    );
  } else {
    return (
      <ErrorMessage>
        It seems there is an issue with your team&apos;s subscription. Please get in touch with your
        team owner to resolve the matter.
      </ErrorMessage>
    );
  }
}


function UnsupportedSubscriptionMessage() {
  const currentSubscriptionPlanName = useBillingContext()
    .getData().teamBilling.planName || '';
  return (
    <WarningMessage>
      We&apos;re sorry, but the subscription type you&apos;re currently using (
      {currentSubscriptionPlanName}
      ) is
      no longer supported by Nekst. Please click the &quot;Update Subscription Plan&quot; button to select a new subscription plan. If you have any questions,
      please don&apos;t hesitate to contact us.
    </WarningMessage>
  );
}

function isSupportedSubscription(currentPlanId: string) {
  return [
    SubscriptionPlanId.TEAM_PRO.toString(),
    SubscriptionPlanId.AGENT_PRO.toString(),
    SubscriptionPlanId.AGENT_LITE.toString(),
    SubscriptionPlanId.AGENT_LITE_PLUS.toString(),
  ].includes(currentPlanId);
}

export default function BillingErrorMessage() {
  const teamBilling = useBillingContext()
    .getData().teamBilling;

  const { isGranted } = useAuthorizationChecker();

  if (teamBilling.status === SubscriptionStatus.ACTIVE) {
    if (isGranted(TeamPermission.ACCESS_FUNCTIONALITY)) {
      return null;
    } else if (teamBilling.planId && !isSupportedSubscription(teamBilling.planId)) {
      return (<UnsupportedSubscriptionMessage />);
    } else {
      return null;
    }
  }
  if (isGranted(TeamPermission.MANAGE_BILLING)) {
    return (<OwnerErrorMessage status={teamBilling.status} />);
  } else {
    return (<MemberErrorMessage status={teamBilling.status} />);
  }
}
