import React from 'react';
import { BaseInputProps, Dropdown } from 'ui-builder';
import { TrainingEntryUsedFor } from '../aiTrainerService';

export default function UsedForDropdown(props: BaseInputProps) {
  return (
    <Dropdown
      {...props}
      options={[
        {
          label: 'Training',
          value: TrainingEntryUsedFor.TRAINING
        },
        {
          label: 'Validation',
          value: TrainingEntryUsedFor.VALIDATION
        }
      ]}
    />
  );
}
