import React, { ReactElement } from 'react';
import styles from '../../plans/tasks/CreateUpdate/TransactionParties.module.scss';
import RelativeRow, { Cell } from '../web/uitheme/form/RelativeRow';
import ReminderChooser from '../../plans/tasks/CreateUpdate/Standard/ReminderChooser';
import { OnChangeCallbackType } from '../uibuilder/form/input';
import LoadingAnimation from '../LoadingAnimation';

export type TransactionPartyViewModel<IdFields> = {
  id: IdFields
  remindDays?: number
}

export type Choice<IdFields> = {
  id: IdFields
  name: string | ReactElement
}

export interface TransactionPartiesInputLayoutProps<ApiType = any, IdFields = { id: number }> {
  label?: string | ReactElement
  isDisabled?: boolean
  findFunc: (id: IdFields, data: ApiType) => boolean,
  toApiModel: (data: TransactionPartyViewModel<IdFields>) => ApiType
  value: ApiType[]
  choices: Choice<IdFields>[],
  onChangeCallback: OnChangeCallbackType,
  // eslint-disable-next-line react/no-unused-prop-types
  source: string
}

export interface RemindDaysAware {
  remindDays?: number;
}

export default function TransactionPartiesInputLayout<ApiType extends RemindDaysAware = any,
  IdFields = { id: number },
>(
  props: TransactionPartiesInputLayoutProps<ApiType, IdFields>,
) {
  function isChecked(id: IdFields) {
    return !!props.value.find((data: any) => props.findFunc(id, data));
  }

  function changeValue(value: ApiType[]) {
    props.onChangeCallback({
      target: {
        value,
      },
    });
  }

  function invertValue(id: IdFields) {
    const newValue = [
      ...props.value,
    ];

    if (isChecked(id)) {
      const index = newValue.findIndex((data: any) => props.findFunc(id, data));

      if (index !== -1) {
        newValue.splice(index, 1);
      }
    } else {
      newValue.push(props.toApiModel({
        id,
      }));
    }

    changeValue(newValue);
  }

  function setRemindDays(id: IdFields, remindDays?: number) {
    const newValue = [
      ...props.value,
    ] as ApiType[];

    const index = newValue.findIndex((data: any) => props.findFunc(id, data));
    newValue[index].remindDays = remindDays;

    changeValue(newValue);
  }

  function getRemindDays(id: IdFields) {
    const index = props.value.findIndex((data: any) => props.findFunc(id, data));
    if (index !== -1) {
      return props.value[index].remindDays;
    } else {
      return undefined;
    }
  }

  const label = `${props.label} (${props.value.length})`;

  return (
    <div className={`${styles.block} ${props.isDisabled ? styles.disabled : ''}`}>
      <h3>
        {label}
      </h3>

      {!!props.choices.length && (
        <div className={styles.table}>

          <RelativeRow
            weights={[6, 6]}
            rowClassName={styles.tableHead}
            cellClassName={styles.cell}
          >
            <Cell>
              Assigned To:
            </Cell>
            <Cell>
              Remind In:
            </Cell>
          </RelativeRow>
          <div className={styles.tableBody}>
            {props.choices.map(
              (choice) => {
                return (
                  <RelativeRow
                    key={`choice-${JSON.stringify(choice.id)}`}
                    weights={[6, 6]}
                    rowClassName={styles.row}
                    cellClassName={styles.cell}
                  >
                    <Cell>
                      <>
                        {/* eslint-disable-next-line max-len */}
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <i
                          className={`${styles.checkbox} ${isChecked(choice.id) ? styles.checked : ''} ${props.isDisabled ? styles.disabled : ''}`}
                          onClick={() => invertValue(choice.id)}
                        >
                          &nbsp;
                        </i>
                        {choice.name}
                      </>
                    </Cell>
                    <Cell>
                      {isChecked(choice.id) && (
                        <ReminderChooser
                          label=""
                          onChangeCallback={(data) => {
                            setRemindDays(choice.id, data.remindDays);
                          }}
                          value={getRemindDays(choice.id)}
                          source="remindDays"
                        />
                      )}
                    </Cell>
                  </RelativeRow>
                );
              },
            )}
          </div>
        </div>
      )}
      {!props.choices.length && (<LoadingAnimation />)}
    </div>
  );
}
