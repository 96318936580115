import {
  TeamMembersAssignmentsForm,
  useParseContractContext,
} from 'features/tasks-forms';
import React, { useEffect } from 'react';
import { usePopupFormManager } from 'features/nekst-widgets';
import {
  ProjectAssignment,
  TeamRole
} from 'nekst-api';
import AddParsedContactsWidget from './AddParsedContactsWidget';


export function ShowLaunchPopups(
  props: {
    projectId: number,
  },
) {
  const parseContext = useParseContractContext();

  const [teamMembersProcessed, setTeamMembersProcessed] = React.useState(false);
  const [transactionPartiesProcessed, setTransactionPartiesProcessed] = React.useState(false);

  const popupFormManager = usePopupFormManager();

  const showPopup = () => {
    if (parseContext) {
      const people = parseContext.parseResult.people.filter(
        (person) => !person.processed
      );

      const teamMembers = people.filter(
        (person) => !!person.foundMatches.find((match) => [
          TeamRole.ADMINISTRATOR,
          TeamRole.MEMBER,
          TeamRole.OWNER,
        ].includes(match.teamRole || TeamRole.CONTACT))
      );

      const contacts = people.filter(
        (person) => !!person.foundMatches
      );

      if (teamMembers.length > 0 && !teamMembersProcessed) {
        popupFormManager.openForm(
          <TeamMembersAssignmentsForm
            projectId={props.projectId}
            afterSubmitFunc={async () => {
              const promises = teamMembers.map(async (person) => {
                await parseContext.removeContact(person.internalId);
              });

              await Promise.all(promises);

              setTeamMembersProcessed(true);
            }}
            initialTaskAssignees={
            teamMembers.map((person) => ({
              personId: person.foundMatches.find((match) => [
                TeamRole.ADMINISTRATOR,
                TeamRole.MEMBER
              ].includes(match.teamRole || TeamRole.CONTACT))!.id,
              roleId: person.roleId,
              isEditable: true,
            })) as ProjectAssignment[]}
          />,
        );
      } else if (contacts.length > 0 && !transactionPartiesProcessed) {
        popupFormManager.openForm(
          <AddParsedContactsWidget
            projectId={props.projectId}
            people={contacts}
            onAllProcessed={() => {
              popupFormManager.closePopup();
              setTransactionPartiesProcessed(true);
            }}
          />,
        );
      }
    }
  };

  useEffect(() => {
    showPopup();
  }, [
    parseContext,
    teamMembersProcessed,
    transactionPartiesProcessed,
  ]);

  return null;
}
