import React, { ReactNode } from 'react';
import { CreatePersonRequest, Person, TeamPermission, TeamRole, usePeopleService } from 'nekst-api';
import { useAuthorizationChecker } from 'authorization-scope';
import {
  AfterSubmitFunc,
  FormBlock,
  FormRows,
  IfFormData, ifReactNative,
  InputLayoutIcon,
  isReactNative,
  RelativeRow,
  Row6x6x,
  TextInput
} from 'ui-builder';
import { PhoneNumbersInput, ProjectRoleDropdown, Separator } from 'features/nekst-widgets';
import { BasePersonFormTemplate } from './Shared/BasePersonFormTemplate';
import { AddressInput, ServiceProvidersInput, TeamRoleRadio } from '../Shared';
import { ProjectsMultiDropdown } from '../../Shared';
import useFeatureToggleService from 'feature-toggle';

function FormRow(
  props: {
    children: ReactNode,
  }
) {
  if (isReactNative()) {
    return props.children;
  } else {
    return (
      <Row6x6x>
        {props.children}
      </Row6x6x>
    );
  }
}

export function CreatePersonForm(
  props: {
    afterSubmitFunc?: AfterSubmitFunc<Person>,
    initialData?: CreatePersonRequest,
  }
) {
  const peopleService = usePeopleService();
  const { isGranted } = useAuthorizationChecker();

  const featureToggleService = useFeatureToggleService();

  const isTeamMembersManagementEnabled = featureToggleService.isTeamMembersManagementEnabled();

  return (
    <BasePersonFormTemplate<CreatePersonRequest, Person>
      title="Add Contact"
      submitFormFunc={async (data) => {
        const sentRequest = {
          ...data,
        } as CreatePersonRequest;

        if (data.teamRole === TeamRole.CONTACT) {
          // @ts-ignore
          sentRequest.teamRole = undefined;
        }

        return peopleService.create(sentRequest);
      }}
      afterSubmitFunc={props.afterSubmitFunc}
      validationSchema={(data) => peopleService.getCreateValidationSchema(data.teamRole)}
      initialData={{
        teamRole: TeamRole.CONTACT,
        ...props.initialData || {},
      } as CreatePersonRequest}
    >
      <FormBlock>
        <FormRow>
          <TextInput
            source="profile.name.firstName"
            label="First Name"
            icon={InputLayoutIcon.PERSON} />
          <TextInput
            source="profile.name.lastName"
            label="Last Name"
            icon={InputLayoutIcon.PERSON}
          />
        </FormRow>
        <FormRow>
          <TextInput
            source="profile.primaryEmail"
            label="Primary Email"
            icon={InputLayoutIcon.EMAIL}
          />
          <TextInput
            source="profile.alternateEmail"
            label="Alternate Email"
            icon={InputLayoutIcon.EMAIL}
          />
        </FormRow>
        <FormRow>
          <TextInput
            source="profile.companyName"
            label="Company Name"
            icon={InputLayoutIcon.WORK}
          />
          <TextInput
            source="profile.website"
            label="Website"
            icon={InputLayoutIcon.WEB}
          />
        </FormRow>
        <FormRows>
          <PhoneNumbersInput source="profile.phoneNumbers" label="Phone Numbers" openCreateForm />
        </FormRows>
        <Separator />
        <AddressInput source="profile.address" />
        {!isTeamMembersManagementEnabled && isGranted(TeamPermission.HAS_TEAM) && isGranted(TeamPermission.CREATE_TEAM_MEMBER) && (
          <>
            <Separator />
            <TeamRoleRadio source="teamRole" hideLabel />
            <IfFormData<CreatePersonRequest>
              conditionFunc={
                (data) => (!!data.teamRole && [
                  TeamRole.MEMBER,
                  TeamRole.ADMINISTRATOR,
                ].includes(data.teamRole))
              }
              onUnmount={(callback) => {
                callback({
                  projectsAssignment: undefined,
                });
              }}
            >
              <Separator />
              <RelativeRow weights={ifReactNative([12], [8, 4])}>
                <ProjectsMultiDropdown source="projectsAssignment.projectsIds"
                                       label="Transactions" />
                <IfFormData<CreatePersonRequest>
                  conditionFunc={
                    (data) => !!data.projectsAssignment?.projectsIds.length
                  }
                >
                  <ProjectRoleDropdown source="projectsAssignment.roleId" label="Role" />
                </IfFormData>
              </RelativeRow>
            </IfFormData>
          </>
        )}
        <IfFormData<CreatePersonRequest>
          conditionFunc={(data) => data.teamRole === TeamRole.CONTACT}
          onUnmount={(callback) => {
            callback({
              serviceProvidersIds: undefined,
            });
          }}
        >
          <Separator />
          <ServiceProvidersInput source="serviceProvidersIds" label="Service Providers" />
        </IfFormData>
      </FormBlock>
    </BasePersonFormTemplate>
  );
}
