import React, { ReactNode, useState } from 'react';
import InformationBox from '../../../shared/web/layout/InformationBox';

import PlanFilterContainer from '../../../plans/tasks/List/Filter/PlanFilterContainer';
import {
  CheckboxInput,
  Header4,
  List,
  MassUpdate,
  SimpleBlock,
} from 'ui-builder';
import MainBlock from '../../../shared/web/layout/MainBlock';
import useProjectPlanTaskService from '../projectPlanTaskService';
import { useProjectContext } from '../../view/ProjectContext';
import {
  PlanTask,
  replaceTagsIdsWithValues, TasksGroup,
  TaskType, useProjectPlanService,
  useSmartTagsService
} from 'nekst-api';

import PlanTaskRow from '../../../plans/tasks/List/PlanTaskRow';

import styles from '../SelectGroupPage.module.scss';
import { NextButton, PreviousButton } from '../Buttons';
import { NO_GROUP_LABEL, useTasksGroupsHelper } from 'features/tasks-forms';
import { InformationMessage, RowRepeater, TasksGroupIcon } from 'features/nekst-widgets';

function TasksGroupWidget(
  props: {
    group?: TasksGroup,
    tasks: PlanTask[]
  }
) {
  const [loading, setLoading] = useState(false);

  const projectContext = useProjectContext();

  const projectPlanService = useProjectPlanService();

  const initialValue = !props.group || !projectContext.plan!.excludedGroupsIds.includes(
    props.group.id
  );

  const [included, setIncluded] = useState(
    initialValue
  );

  const updateGroup = async (include: boolean) => {
    if (!loading) {
      setLoading(true);
      setIncluded(include);
      try {
        if (include) {
          await projectPlanService.includeGroupToPlan(projectContext.data!.id, props.group!.id);
        } else {
          await projectPlanService.excludeGroupFromPlan(projectContext.data!.id, props.group!.id);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <SimpleBlock className={[
      styles.group,
      !included ? styles.excluded : '',
      loading ? styles.loading : '',
    ].join(' ')}>
      <SimpleBlock className={styles.groupName}>
        <Header4>
          {props.group
            ? (
              <>
                <CheckboxInput
                  source="groupId"
                  hideLabel
                  value={included}
                  onChangeCallback={(event) => {
                    const value = !!event['groupId'];

                    updateGroup(value);
                  }}
                  isDisabled={loading}
                  className={styles.checkbox}
                />
                <TasksGroupIcon group={props.group} withName />
              </>)
            : NO_GROUP_LABEL}
        </Header4>
      </SimpleBlock>
      <SimpleBlock className={styles.tasks}>
        <List getDataFunc={async () => props.tasks}>
          <RowRepeater
            idField="id"
            blockClassName={`${styles.block} ${styles.withoutTopBorder}`}
            loadMoreButtonClassName={styles.loadMore}
            parentIdField="parentTaskRelation.parentTaskId"
            noDataMessage="No tasks matching current filter criteria"
          >
            <PlanTaskRow
              shortView
            />
          </RowRepeater>
        </List>
      </SimpleBlock>
    </SimpleBlock>
  );
}

function ByGroupListLayout() {
  const {
    groups,
    loading
  } = useTasksGroupsHelper();

  if (groups.length > 1) {
    return (
      <>
        {groups.map((group) => {
          return (
            <TasksGroupWidget tasks={group} key={group[0].groupId} group={group[0]._group} />
          );
        })}
      </>
    );
  } else {
    if (!loading) {
      return (
        <InformationMessage>
          This Action Plan does not contain any Groups of tasks.
        </InformationMessage>
      );
    } else {
      return null;
    }
  }
}

export default function BaseSelectGroupsPage(
  props: {
    onPrevClicked: () => void,
    onNextClicked: () => void,
    roadmap: ReactNode,
  }
) {
  const planTasksService = useProjectPlanTaskService();

  const projectContext = useProjectContext();

  const smartTagsService = useSmartTagsService();

  const getDataFunc = async () => {
    const result = await planTasksService.getTasksByPlanId(projectContext.plan!.id);

    const tags = await smartTagsService.getSmartTags(projectContext.data!.type, TaskType.EMAIL);

    return result
      .map((task) => {
        return {
          ...task,
          _renderedName: task.type === TaskType.EMAIL ? replaceTagsIdsWithValues(task.name, tags) : task.name,
        };
      });
  };

  return (
    <>
      <InformationBox
        text="Uncheck the groups below to exclude their tasks from this transaction."
      />
      {props.roadmap}
      <div
        style={{
          marginBottom: '4rem',
          overflow: 'hidden',
        }}
      >
        <PreviousButton
          onClick={() => {
            props.onPrevClicked();
          }}
          key="previous-button"
        />
        <NextButton
          onClick={() => {
            props.onNextClicked();
          }}
          key="next-button"
        />
      </div>
      {projectContext.plan && (<MainBlock
        title=""
        columnContent={<PlanFilterContainer />}
      >
        <MassUpdate>
          <List
            getDataFunc={getDataFunc}
          >
            <ByGroupListLayout />
          </List>
        </MassUpdate>
      </MainBlock>)}
    </>
  );
}
