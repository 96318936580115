import React, { ReactElement, useEffect, useState } from 'react';

import { FormFieldsData, OnFormDataChangedCallbackType, useFormContext } from './FormContext';

interface IfProps<DataType = FormFieldsData> {
  conditionFunc: (data: DataType) => boolean,
  children: ReactElement | ReactElement[]
  onUnmount?: (onChangeCallback: OnFormDataChangedCallbackType<DataType>) => void;
}

export function IfFormData<DataType = FormFieldsData>(props: IfProps<DataType>) {
  const formContext = useFormContext<DataType>();

  const isVisible = props.conditionFunc((formContext.data || {}) as DataType);

  const [visibleState, setVisibleState] = useState(isVisible);

  useEffect(() => {
    if (!visibleState && props.onUnmount && formContext.onChangeCallback) {
      props.onUnmount(formContext.onChangeCallback);
    }

    setVisibleState(isVisible);
  }, [isVisible]);

  if (isVisible) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {props.children}
      </>
    );
  } else {
    return null;
  }
}
