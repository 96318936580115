import { useShowContextRequired } from 'ui-builder';
import { DueDateWhen, PlanTask } from 'nekst-api';


function whenToString(when: string) {
  switch (when) {
    case DueDateWhen.BEFORE_START_DATE:
      return 'before start date';
    case DueDateWhen.AFTER_START_DATE:
      return 'after start date';
    case DueDateWhen.BEFORE_END_DATE:
      return 'before end date';
    case DueDateWhen.AFTER_END_DATE:
      return 'after end date';
    default:
      return 'after ';
  }
}

export function usePlanTaskDateFieldLogic() {
  const context = useShowContextRequired<PlanTask>();

  const getFieldText = () => {
    if (context.data) {
      const dueDate = context.data.dueDateConfig;
      const parentTaskRelation = context.data.parentTaskRelation;

      let resultString: string;

      const getDaysString = (number: number) => {
        return number === 1 ? 'day' : 'days';
      };

      if (dueDate) {
        resultString = `${dueDate.days} ${getDaysString(dueDate.days)} ${whenToString(dueDate.when)}`;
      } else if (parentTaskRelation) {
        resultString = `${parentTaskRelation.days} days after ${parentTaskRelation.parentTaskName}`;
      } else {
        resultString = 'No Due Date';
      }

      return resultString;
    } else {
      return null;
    }
  };

  return {
    getFieldText,
  };
}
