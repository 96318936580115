import React from 'react';
import { SettingsBreadcrumbs } from '../settingsModule';
import { Breadcrumb } from '../../shared/web/layout/Breadcrumbs';
import Row8x4x from '../../shared/web/uitheme/form/Row8x4x';
import { NotificationsSettingsForm } from 'features/tasks-forms';

export default function NotificationsSettingsPage() {
  return (
    <>
      <SettingsBreadcrumbs>
        <Breadcrumb text="Notifications Settings" />
      </SettingsBreadcrumbs>
      <Row8x4x className="no-clear">
        <NotificationsSettingsForm />
      </Row8x4x>
    </>
  );
}
