import React from 'react';
import { usePriceFormatter, useShowContext } from 'ui-builder';
import { Project } from 'nekst-api';

export default function PriceDetailsField(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: {
    // eslint-disable-next-line react/no-unused-prop-types
    label: string,
    // eslint-disable-next-line react/no-unused-prop-types
    sortName?: string,
    fieldName?: string,
    fieldId?: number
  },
) {
  const showContext = useShowContext<Project>();

  const priceFormatter = usePriceFormatter();

  const data = showContext.data!;

  const details = data.statistic!.details;

  const price = details
    .find((d) => d.setting.name === props.fieldName || d.setting.id === props.fieldId)
    ?.value as number | null;

  if (price) {
    return (
      <span>{priceFormatter.format(price)}</span>
    );
  } else {
    return (<>-</>);
  }
}
