import React from 'react';
import { BaseInputProps } from '../../../shared/uibuilder/form/input';
import Row6x6x from '../../../shared/web/uitheme/form/Row6x6x';
import Password from '../../../shared/uibuilder/form/input/Password';

interface SetPasswordInputProps extends BaseInputProps {

}

export default function SetPasswordInput(props: SetPasswordInputProps) {
  return (
    <Row6x6x>
      <Password source={`${props.source}.password`} label="Password" />
      <Password source={`${props.source}.password2`} label="Repeat password" />
    </Row6x6x>
  );
}
