import React, { useMemo, useState } from 'react';
import { useShowContext, useFormContext } from 'ui-builder';
import {
  AbstractTask,
  TaskPermission,
  TaskStatus,
  useTasksCompletionService,
} from 'nekst-api';
import styles from '../../../../../shared/widgets/TaskRowLayout.module.scss';
import CheckboxLayout from '../../../../../shared/web/uitheme/input/CheckboxLayout';
import { useTaskCompletionErrorHandler } from 'features/tasks-list-feature';

export default function CompleteTaskCheckbox() {
  const showContext = useShowContext<AbstractTask>();

  const formContext = useFormContext<AbstractTask>();

  const taskCompletionErrorHandler = useTaskCompletionErrorHandler();

  const tasksCompletionService = useTasksCompletionService();

  const task = formContext.data || showContext.data;

  const [newValue, setNewValue] = useState<boolean | undefined>();

  const setLoading = (loading: boolean) => {
    if (showContext.setLoading) {
      showContext.setLoading(loading);
    }
  };

  const value = useMemo(() => {
    if (newValue !== undefined) {
      return newValue;
    } else {
      return task!.status === TaskStatus.COMPLETED;
    }
  }, [newValue, task?.status]);

  if (task?.id) {
    const hasPermission = (permission: TaskPermission) => {
      return task.permissions.includes(permission);
    };

    const isDisabled = (
      task.status === TaskStatus.COMPLETED && !hasPermission(TaskPermission.UNCOMPLETE)
    ) || (
      task.status !== TaskStatus.COMPLETED && !hasPermission(TaskPermission.COMPLETE)
    );

    const changeCallback = async () => {
      const newTaskStatus = task.status !== TaskStatus.COMPLETED;

      if (newTaskStatus) {
        setNewValue(true);
        setLoading(true);

        try {
          await tasksCompletionService.completeTask(task.id, false);
        } catch (e) {
          setNewValue(false);
          taskCompletionErrorHandler.handleError(task, e);
        }

        setLoading(false);
      } else {
        setNewValue(false);
        setLoading(true);
        await tasksCompletionService.unCompleteTask(task.id);
        setLoading(false);
      }
    };

    let className;

    if (task.status === TaskStatus.PARTIALLY_COMPLETED && !newValue) {
      className = styles.partiallyCompleted;
    }

    return (
      <CheckboxLayout
        name="complete"
        value={value}
        onChangeCallback={changeCallback}
        isVisible
        className={className}
        isDisabled={isDisabled}
      />
    );
  } else {
    return null;
  }

}
