import React from 'react';
import { get } from 'lodash';

import { ProjectDetailsField } from 'nekst-api';
import { useShowContext } from 'ui-builder';
import VisibilityFieldLayout from '../shared/VisibilityFieldLayout';

export default function ProjectDetailsFieldVisibilityField(
  props: {
    source: string,
  },
) {
  const showContext = useShowContext<ProjectDetailsField>();

  const fieldValue = !!get(showContext.data, props.source);

  return (
    <VisibilityFieldLayout value={fieldValue} />
  );
}
