import React from 'react';
import useProjectDocumentsService, {
  ProjectDocument,
  ProjectDocumentPermission
} from './projectDocumentsService';
import FileUploaderInput from '../../shared/file/FileUploaderInput';
import IconButton, { IconType } from '../../shared/widgets/IconButton';
import { AfterSubmitFunc, FormTemplate, useFormContext, useShowContext } from 'ui-builder';
import { usePopupFormManager } from 'features/nekst-widgets';
import { FileType } from 'nekst-api';

type UploadDocumentFormData = { fileId: number, _labelName: string };

function LabelName() {
  const formContext = useFormContext<UploadDocumentFormData>();

  const labelName = formContext.data?._labelName;

  if (labelName) {
    return (
      <div
        style={{
          marginBottom: '0.5rem',
        }}
      >
        <b>Label:</b>
        {' '}
        {labelName}
      </div>
    );
  } else {
    return null;
  }
}

function UploadDocumentForm(props: {
  data: ProjectDocument,
  afterSubmitFunc?: AfterSubmitFunc,
}) {
  const documentService = useProjectDocumentsService();
  return (
    <FormTemplate<UploadDocumentFormData>
      title="Upload Document"
      submitFormFunc={async (data) => {
        await documentService.setFile(props.data.id, data.fileId);
        return data;
      }}
      afterSubmitFunc={props.afterSubmitFunc}
      initialData={{
        _labelName: props.data._label?.name,
      } as UploadDocumentFormData}
    >
      <LabelName />
      <FileUploaderInput
        fileType={FileType.PROJECT_DOCUMENT}
        fileDataSource="_file"
        source="fileId"
        label="Document File"
      />
    </FormTemplate>
  );
}

export default function UploadDocumentButton() {
  const popupManager = usePopupFormManager();

  const showContext = useShowContext<ProjectDocument>();

  if (showContext.data!._permissions.includes(ProjectDocumentPermission.SET_FILE)) {
    return (
      <IconButton
        type={IconType.UPLOAD}
        onClick={() => {
          popupManager.openForm(<UploadDocumentForm data={showContext.data!} />);
        }}
        title="Upload Document"
      />
    );
  } else {
    return null;
  }
}
