import { useNavigate, useParams } from 'react-router-dom';

export default function useProjectNavigator() {
  const navigator = useNavigate();
  const params = useParams();

  const getProjectId = (projectId?: number) => {
    return projectId || parseInt(params.id!, 10);
  };

  const openProjectsPage = () => {
    navigator('/transactions');
  };

  const getTaskPageLink = (projectId?: number) => {
    return `/transactions/${getProjectId(projectId)}`;
  };

  const openTasksPage = (projectId?: number, taskId?: number) => {
    let link = getTaskPageLink(projectId);
    if (taskId) {
      link += `?taskId=${taskId}`;
    }
    navigator(link);
  };

  const openDetailsPage = (projectId?: number) => {
    navigator(`/transactions/${getProjectId(projectId)}/details`);
  };

  const openHistoryPage = (projectId?: number) => {
    navigator(`/transactions/${getProjectId(projectId)}/history`);
  };

  const openCalendarPage = (projectId?: number) => {
    navigator(`/transactions/${getProjectId(projectId)}/calendar`);
  };

  const openClientPortalConfigPage = (projectId?: number) => {
    navigator(`/transactions/${getProjectId(projectId)}/clientportal`);
  };

  const openDocumentsPage = (projectId?: number) => {
    navigator(`/transactions/${getProjectId(projectId)}/documents`);
  };

  const isCalendarPage = () => window.location.pathname.match(/(\/new)?\/transactions\/[0-9]+\/calendar/);

  const isHistoryPage = () => window.location.pathname.match(/(\/new)?\/transactions\/[0-9]+\/history/);

  const isDetailsPage = () => window.location.pathname.match(/(\/new)?\/transactions\/[0-9]+\/details/);

  const isTasksPage = () => window.location.pathname.match(/(\/new)?\/transactions\/[0-9]+$/);

  const isClientPortalConfigPage = () => window.location.pathname.match(
    /(\/new)?\/transactions\/[0-9]+\/clientportal/,
  );

  const isDocumentsPage = () => window.location.pathname.match(
    /\/transactions\/[0-9]+\/documents/,
  );

  return {
    openProjectsPage,
    getTaskPageLink,
    openTasksPage,
    isTasksPage,
    openDetailsPage,
    isDetailsPage,
    openHistoryPage,
    isHistoryPage,
    openCalendarPage,
    isCalendarPage,
    openClientPortalConfigPage,
    isClientPortalConfigPage,
    openDocumentsPage,
    isDocumentsPage,
  };
}
