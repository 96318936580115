import React, { ReactElement, useMemo, useState } from 'react';
import { ApiTokenData, AuthenticationTokenContext, TokenStorageInterface } from './index';
import useDataLoader from 'data-loader';

export function InvitationTokenContext(
  props: {
    children: ReactElement | ReactElement[],
    tokenStorage: TokenStorageInterface
  }
) {
  const [code, setCode] = useState<string | null>();

  const [init, setInit] = useState(false);

  useDataLoader(
    props.tokenStorage.getToken,
    (code) => {
      setCode(code);
      setInit(true);
    },
  );

  const contextValue = useMemo<ApiTokenData | null>(() => {
    if (code) {
      return {
        getApiHeaders: () => ({
          'X-NEKST-INVITATION-TOKEN': code,
        }),
        isTokenSet: () => true,
        setToken: () => {
          throw new Error('Token cannot be saved');
        }
      };
    } else {
      if (init) {
        throw new Error('code is not defined');
      } else {
        return null;
      }
    }
  }, [code, init]);

  if (contextValue) {
    return (
      <AuthenticationTokenContext
        data={contextValue}
      >
        {props.children}
      </AuthenticationTokenContext>
    );
  } else {
    return null;
  }
}
