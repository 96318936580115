import React from 'react';
import { useNavigate } from 'react-router-dom';
import AdminPortalAccessChecker from '../../../team/AdminPortalAccessChecker';
import { Breadcrumb } from '../../../shared/web/layout/Breadcrumbs';
import { SettingsBreadcrumbs } from '../../settingsModule';
import InnerBlock from '../../../shared/web/layout/InnerBlock';
import useImpersonateUsersService from './impersonateUserService';
import Row6x6x from '../../../shared/web/uitheme/form/Row6x6x';
import { FormTemplate, NumberInput } from 'ui-builder';

function ImpersonateUsersForm() {
  const service = useImpersonateUsersService();

  const navigate = useNavigate();
  return (
    <Row6x6x className="no-clear">
      <FormTemplate<{ userId: number }>
        submitFormFunc={async (data) => {
          await service.impersonateUser(data.userId);

          return data;
        }}
        afterSubmitFunc={async () => {
          navigate('/tasks');
        }}
        validationSchema={{
          userId: {
            type: 'int',
            constraints: {
              required: true,
            },
          },
        }}
      >
        <NumberInput source="userId" label="User Id" />
      </FormTemplate>
    </Row6x6x>
  );
}

export default function ImpersonateUsersPage() {
  return (
    <AdminPortalAccessChecker show403Error>
      <SettingsBreadcrumbs>
        <Breadcrumb text="Impersonate Users" />
      </SettingsBreadcrumbs>
      <InnerBlock title="Impersonate Users">
        <ImpersonateUsersForm />
      </InnerBlock>
    </AdminPortalAccessChecker>
  );
}
