import React from 'react';
import { isReactNative } from '../isReactNative';
import { Text } from './Text';

export function NewLine() {
  if (isReactNative()) {
    return <Text>{"\n"}</Text>;
  } else {
    return (<br />);
  }
}
