import {
  ListFilterService,
  useFilterContext,
  VisibilityState
} from 'ui-builder';
import { AbstractTask, TasksFilter } from 'nekst-api';

export default function useTasksGroupsFilterService(): ListFilterService<number[], AbstractTask> {

  const filterContext = useFilterContext<TasksFilter>();


  const getFilterValue = () => {
    return filterContext.filterValue?.tasksGroupsIds || [];
  };

  const setFilterValue = (value: number[]) => {
    filterContext.setFilterValue({
      ...filterContext.filterValue || {},
      tasksGroupsIds: value,
    })
  };

  const isSelected = (groupId: number) => {
    const currentValue = getFilterValue();

    return currentValue.length === 0 || currentValue.includes(groupId);
  };

  const applyFilter = (task: AbstractTask) => {
    const groupId = task.groupId || 0;

    if (isSelected(groupId)) {
      return VisibilityState.VISIBLE;
    } else {
      return VisibilityState.HIDDEN;
    }
  };

  return {
    getName: () => 'TASKS_GROUPS',
    getDefaultValue: () => [],
    getFilterValue,
    setFilterValue,
    applyFilter
  }
}
