import React, { useMemo } from 'react';
import { ProjectAssignmentType, ProjectRole } from 'nekst-api';
import { useProjectContext } from '../view/ProjectContext';
import { useAssignProjectRoleForm } from './AssignTeamMemberRoleForm';

import styles from './MissingRolesAssignments.module.scss';
import { Button, ButtonColor, ButtonSize, IconType } from 'ui-builder';

export default function MissingRolesAssignments(
  props: {
    projectId: number,
    usedRoles: ProjectRole[],
  },
) {
  const { assignments } = useProjectContext();

  const form = useAssignProjectRoleForm();

  const notAssignedRoles = useMemo(() => {
    return props.usedRoles
      .filter(
        (role) => !assignments
          .filter((a) => a.type === ProjectAssignmentType.TASK_ASSIGNMENT)
          .find(
            (a) => a.roles.find((r) => r.roleId === role.id),
          ),
      );
  }, [assignments.length, props.usedRoles.length]);

  return (
    <div className={styles.wrapper}>
      {notAssignedRoles.map((role) => (
        <Button
          text={role.name}
          size={ButtonSize.SMALL}
          color={ButtonColor.BLUE}
          onClick={() => form.openForm(props.projectId, role.id)}
          iconType={IconType.PLUS}
          customClass={styles.link}
          key={`missing-role-${role.id}`}
        />
      ))}
    </div>
  );
}
