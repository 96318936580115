import React from 'react';
import { DateInput, useFormContext } from 'ui-builder';

export default function SendDate() {

  const formContext = useFormContext();

  const onChange = (update: { [key: string]: any }) => {
    const value = Object.values(update)[0];

    if (value) {
      formContext.onChangeCallback!({
        dueDate: {
          date: value,
        },
      });
    } else {
      formContext.onChangeCallback!({
        dueDate: undefined,
      });
    }
  };

  return (
    <DateInput
      source="dueDate.date"
      label="Send Date"
      onChangeCallback={onChange}
      showRequiredIcon={false}
    />
  );
}
