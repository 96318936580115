/* istanbul ignore file */

import React from 'react';
import { ErrorsWrapper, useFormContext } from 'ui-builder';
import FormRows from '../FormRows';
import { FormLayoutProps } from '../index';

import styles from './DefaultLayout.module.scss';

function DefaultLayout({
  children,
  buttons,
  className,
  title,
  testID,
  isSubmitting,
}: FormLayoutProps) {

  let titleElement;

  if (typeof title === 'string') {
    titleElement = (<h3 className=" form-title">{title}</h3>);
  } else if (title) {
    titleElement = title;
  }

  const formContext = useFormContext();

  const isDisabled = formContext.isDisabled ? formContext.isDisabled() : false;

  return (
    <form
      data-key={testID}
      className={`form ${className} ${isSubmitting ? styles.submitting : ''}`}
      autoComplete="chrome-off"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
      {titleElement}
      <ErrorsWrapper name="__form">
        <div className={`form-rows ${(!buttons) ? 'no-buttons' : ''}`}>
          <FormRows>
            {children}
          </FormRows>
        </div>
      </ErrorsWrapper>
      {buttons && !isDisabled && (
        <div className=" buttons">
          {buttons}
        </div>
      )}
    </form>
  );
}

export default DefaultLayout;
