import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Button, ButtonColor, ButtonSize, useFormContext, usePopupManager } from 'ui-builder';
import { Popup, PopupText } from 'features/nekst-widgets';
import { Project, useProjectDetailsService } from 'nekst-api';
import useDataLoader from 'data-loader';

function ConfirmButton() {
  const popupManager = usePopupManager();

  return (
    <Button
      text="Confirm"
      color={ButtonColor.YELLOW}
      size={ButtonSize.BIG}
      onClick={() => popupManager.closePopup()}
    />
  );
}

function GoBackButton(props: {
  onClick: () => void,
}) {
  const popupManager = usePopupManager();

  return (
    <Button
      text="Go Back"
      color={ButtonColor.GRAY}
      size={ButtonSize.BIG}
      onClick={() => {
        props.onClick();
        popupManager.closePopup();
      }}
    />
  );
}

function NotificationPopup(
  props: {
    cancelActionFunc: () => void,
  },
) {
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <Popup
      title="Transaction Type Changing"
      content={(
        <PopupText>
          You are modifying the &quot;Type&quot; associated with this transaction.
          If you proceed, all tasks connections to the detail boxes will be removed.
        </PopupText>
      )}
      buttons={(
        <>
          <ConfirmButton />
          <GoBackButton onClick={props.cancelActionFunc} />
        </>
      )}
    />
  );
}

function LinkedProjectDetailsChecker(props: {
  children: ReactElement,
}) {
  const [hasLinks, setHasLinks] = useState(false);

  const formContext = useFormContext<Project>();

  const projectDetailsService = useProjectDetailsService();

  const checkLinksToDetailsFields = async () => {
    if (formContext.data?.id) {
      const fields = await projectDetailsService.getProjectDetails(formContext.data!.id);
      return !!fields.find((item) => !!item.linkedTaskId);
    } else {
      return false;
    }
  };

  useDataLoader(
    checkLinksToDetailsFields,
    setHasLinks,
  );

  if (hasLinks) {
    return props.children;
  } else {
    return null;
  }
}

function PlanValueClearer() {
  const formContext = useFormContext<Project>();

  const initialPlanId = useMemo(() => formContext.data?.planId, []);
  const initialType = useMemo(() => formContext.data?.type, []);

  const isChangedValue = () => {
    const project = formContext.data!;

    return project.planId !== initialPlanId || project.type !== initialType;
  };

  useEffect(() => {
    if (isChangedValue()) {
      formContext.onChangeCallback!({
        planId: undefined,
      });
    }
  }, [formContext.data?.type]);

  return null;
}

function TypeChangeWarner() {
  const [triggered, setTriggered] = useState(false);

  const popupManager = usePopupManager();

  const formContext = useFormContext<Project>();

  const initialPlanId = useMemo(() => formContext.data?.planId, []);
  const initialType = useMemo(() => formContext.data?.type, []);

  const projectType = formContext.data?.type;

  const openPopup = () => {
    popupManager.openPopup(
      <NotificationPopup
        cancelActionFunc={() => {
          setTriggered(false);
          formContext.onChangeCallback!({
            type: initialType,
            planId: initialPlanId,
          });
        }}
      />,
    );
  };

  useEffect(() => {
    if (projectType && projectType !== initialType) {
      if (!triggered) {
        setTriggered(true);
        openPopup();
      }
    }
  }, [projectType]);

  return null;
}

export function ProjectTypeChangeBehavior() {
  return (
    <>
      <PlanValueClearer />
      <LinkedProjectDetailsChecker>
        <TypeChangeWarner />
      </LinkedProjectDetailsChecker>
    </>
  );
}
