import React from 'react';
import { AsyncMultiSelectDropdown, BaseInputProps, Option } from 'ui-builder';
import { useProjectRolesService } from 'nekst-api';

export interface ProjectRolesDropdownProps extends BaseInputProps<number[]> {
  notEditableIds?: number[],
  hideTeamMembersOnlyRoles?: boolean,
}

export function ProjectRolesMultiDropdown(props: ProjectRolesDropdownProps) {
  const projectRolesService = useProjectRolesService();

  const loadOptionsFunc = async () => {
    const roles = await projectRolesService.getAllTeamRoles();

    return roles
      .filter((role) => !props.hideTeamMembersOnlyRoles || !['Editor', 'Viewer'].includes(role.name))
      .map((role) => {
      return {
        value: role.id,
        label: role.name,
      } as Option;
    });
  };

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <AsyncMultiSelectDropdown
      {...props}
      loadOptionsFunc={loadOptionsFunc}
      notEditableIds={props.notEditableIds}
    />
  );
}
