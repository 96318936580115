import {
  PersonPermission,
  TeamMember,
  TeamPermission,
  TeamRole,
  useTeamMembersService
} from 'nekst-api';
import { ConfirmDeletePopup, IconButton, IconType } from 'features/nekst-widgets';
import React, { ReactNode } from 'react';
import { useAuthorizationChecker } from 'authorization-scope';
import {
  Button,
  ButtonColor,
  ButtonSize, useListContext,
  usePopupManager,
  useShowContext,
  useShowContextRequired
} from 'ui-builder';

function ConfirmPersonDeletePopup(props: {
  teamMemberId: number,
  afterDeleteFunc?: () => void,
}) {
  const teamMembersService = useTeamMembersService();

  const message = 'By clicking "Confirm," the selected team member will be permanently removed from your team and all associated transactions.';

  return (
    <ConfirmDeletePopup
      deleteFunc={async () => {
        await teamMembersService.deleteTeamMember(props.teamMemberId);
      }}
      message={message}
    />
  );
}

function useDeleteTeamMemberButton(teamMemberId: number) {
  const { isGranted } = useAuthorizationChecker();

  const popupManager = usePopupManager();

  const showContext = useShowContext<TeamMember>();

  const listContext = useListContext();

  const isWithinList = listContext !== undefined
    && showContext.data?.teamRole !== undefined;

  const isOwner = showContext.data?.teamRole === TeamRole.OWNER;

  const handleAction = () => {
    popupManager.openPopup(
      <ConfirmPersonDeletePopup
        teamMemberId={teamMemberId}
      />,
    );
  };

  const displayIfGranted = (widget: ReactNode) => {
    let grantedResult: boolean;

    if (isWithinList) {
      grantedResult = isGranted(TeamPermission.DELETE_TEAM_MEMBERS) && !isOwner;
    } else {
      grantedResult = isGranted(PersonPermission.DELETE);
    }

    if (grantedResult) {
      return widget;
    } else {
      return null;
    }
  };

  return {
    handleAction,
    displayIfGranted,
  }
}

export function DeleteTeamMemberButton(
  props: {
    teamMemberId: number,
  },
) {
  const buttonLogic = useDeleteTeamMemberButton(props.teamMemberId);

  return buttonLogic.displayIfGranted(
    <Button
      text="Remove"
      color={ButtonColor.RED}
      size={ButtonSize.MEDIUM}
      isFilled
      onClick={buttonLogic.handleAction}
      title="Delete team member"
    />
  );
}

export function DeleteTeamMemberIconButton() {
  const showContext = useShowContextRequired<TeamMember>();

  const buttonLogic = useDeleteTeamMemberButton(showContext.data!.id);

  return buttonLogic.displayIfGranted(
    <IconButton onClick={buttonLogic.handleAction} type={IconType.TRASH_CAN} title="Delete team member" />
  )
}
