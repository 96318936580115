import React, { useState } from 'react';
import {
  Button,
  ButtonColor,
  ButtonSize, CheckboxInput,
  IconType,
  ListDataRefresher,
  RelativeRow,
} from 'ui-builder';
import {
  CommentPermission, PROJECT_NOTES_UPDATED_EVENT,
  ProjectNote,
  ProjectNoteRequest, ProjectPermission,
  useProjectNotesService
} from 'nekst-api';
import CommentsList from '../../shared/widgets/CommentsList/CommentsList';
import Right from '../../shared/web/Right';
import AddCommentForm from '../../shared/widgets/CommentsList/AddCommentForm';
import OverflowHidden from '../../shared/widgets/OverflowHidden';
import Left from '../../shared/web/Left';
import IsAddedToClientPortalField from './IsAddedToClientPortalField';
import { useAuthorizationChecker } from 'authorization-scope';
import { ProjectIdContext, TextWithLinksField } from 'features/nekst-widgets';

interface Props {
  projectId: number;
}

function ProjectNoteAdditionalInputs() {
  const { isGranted } = useAuthorizationChecker();

  if (isGranted(ProjectPermission.ASSIGN_NOTE_TO_CLIENT_PORTAL)) {
    return (
      <Left>
        <CheckboxInput
          source="isAddedToClientPortal"
          label="Add this note to the Client Portal"
        />
      </Left>
    );
  } else {
    return null;
  }
}

function Title(props: Props) {
  const [showForm, setShowForm] = useState<boolean>();

  const projectNotesService = useProjectNotesService();

  const { isGranted } = useAuthorizationChecker();

  return (
    <>
      <OverflowHidden>
        <Right>
          {isGranted(ProjectPermission.CREATE_NOTE) && (
            <Button
              text="Add"
              size={ButtonSize.SMALL}
              color={ButtonColor.BLUE}
              isFilled
              onClick={() => {
                setShowForm((prev) => !prev);
              }}
              iconType={IconType.PLUS}
            />
          )}
        </Right>
        <h3>Notes</h3>
      </OverflowHidden>
      {showForm && (
        <AddCommentForm
          submitFunc={async (data: ProjectNoteRequest) => {
            return await projectNotesService.createNote(props.projectId, data) as ProjectNote;
          }}
          afterSubmitFunc={async () => {
            setShowForm(false);
          }}
          closeFormFunc={() => setShowForm(false)}
          additionalInputs={<ProjectNoteAdditionalInputs />}
        />
      )}
    </>
  );
}

export default function ProjectNotesList(props: Props) {
  const projectNotesService = useProjectNotesService();

  return (
    <ProjectIdContext projectId={props.projectId}>
      <CommentsList<ProjectNote, ProjectNoteRequest>
        dataKey="project-notes"
        getDataFunc={
          async () => {
            return await projectNotesService.getProjectNotes(props.projectId) as ProjectNote[];
          }
        }
        title={<Title projectId={props.projectId} />}
        updateItemFunc={async (id: number, request: ProjectNoteRequest) => {
          return await projectNotesService.updateNote(id, request) as ProjectNote;
        }}
        deleteItemFunc={projectNotesService.deleteNote}
        deletePermission={CommentPermission.DELETE}
        updatePermission={CommentPermission.UPDATE}
        messages={{
          noDataText: 'There are no notes related to the transaction',
          textInputLabel: 'Enter Note Here',
        }}
        additionalInputs={<ProjectNoteAdditionalInputs />}
        viewFields={(
          <RelativeRow weights={[10, 2]}>
            <TextWithLinksField source="_renderedText" />
            <IsAddedToClientPortalField />
          </RelativeRow>
        )}
      >
        <ListDataRefresher eventName={PROJECT_NOTES_UPDATED_EVENT} />
      </CommentsList>
    </ProjectIdContext>
  );
}
