import {
  CreateNewContactRequest,
  PersonParseResult,
  PersonPhoneNumberType,
  PersonProfile,
} from 'nekst-api';
import React, { ReactNode, useEffect } from 'react';
import {
  AddPersonToTransactionForm,
  AddTransactionPartyRequest, ParseDataFieldWidget,
  useParseContractContextRequired
} from 'features/tasks-forms';
import {
  Button,
  ButtonColor,
  ButtonSize,
  IconType as ButtonIconType,
  LoadingAnimation,
  SimpleBlock,
  SubmitButton
} from 'ui-builder';
import styles from '../../AddTransactionParties.module.scss';
import { IconButton, IconType, Popup, usePopupFormManager } from 'features/nekst-widgets';
import { FoundContactsWidget } from './FoundContactsWidget';
import Left from '../../../../shared/web/Left';

function SwitchBetweenPeopleControls(
  props: {
    total: number,
    currentItem: number,
    setEditedNumberFunc: (editedNumber: number) => void,
  }
) {
  return (
    <>
      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}>
        <IconButton
          onClick={() => {
            if (props.currentItem > 0) {
              props.setEditedNumberFunc(props.currentItem - 1);
            }
          }}
          type={IconType.PREV}
          disabled={props.currentItem <= 0}
        />
        <span style={{
          fontWeight: 'bold',
          fontSize: '2rem'
        }}>{props.currentItem + 1}/{props.total}</span>
        <IconButton
          onClick={() => {
            if (props.currentItem < props.total - 1) {
              props.setEditedNumberFunc(props.currentItem + 1);
            }
          }}
          type={IconType.NEXT}
          disabled={props.currentItem >= props.total - 1}
        />
      </div>
    </>
  );
}

function FullyRerenderWhenChanged(props: {
  children: ReactNode,
  dependencies: any[],
}) {
  const [shouldBeRendered, setShouldBeRendered] = React.useState(false);

  const rerender = () => {
    setShouldBeRendered(false);
    setTimeout(() => {
      setShouldBeRendered(true);
    });
  };

  useEffect(() => {
    rerender();
  }, props.dependencies);

  if (shouldBeRendered) {
    return <>{props.children}</>;
  } else {
    return null;
  }
}

function SelectedPersonName(
  props: {
    editedNumber: number,
    person: PersonParseResult,
  }
) {
  return (
    <ParseDataFieldWidget source={`people.${props.editedNumber}`} />
  );
}

export default function AddParsedContactsWidget(
  props: {
    projectId: number,
    people: PersonParseResult[],
    onAllProcessed: () => void,
  },
) {
  const [people, setPeople] = React.useState<PersonParseResult[]>(props.people);

  const context = useParseContractContextRequired();

  const [editedNumber, setEditedNumber] = React.useState<number>(0);

  const [request, setRequest] = React.useState<CreateNewContactRequest | AddTransactionPartyRequest>();

  const popupManager = usePopupFormManager();

  useEffect(() => {
    setEditedNumber(Math.min(editedNumber, people.length - 1));
  }, [editedNumber, people.length]);

  const title = (
    <>
      Add Person To Transaction
    </>
  );

  useEffect(() => {
    if (people.length === 0) {
      props.onAllProcessed();
    }
  }, [people.length]);

  const createNewContactFromParsedData = () => {
    const parsedPerson = props.people[editedNumber];

    const profile: PersonProfile = {
      name: {
        firstName: parsedPerson.firstName,
        lastName: parsedPerson.lastName,
      },
      phoneNumbers: parsedPerson.phoneNumber ? [
        {
          number: parsedPerson.phoneNumber,
          type: PersonPhoneNumberType.MOBILE,
        }
      ] : [],
      companyName: parsedPerson.companyName,
      primaryEmail: parsedPerson.email,
    } as PersonProfile;

    const rolesIds = parsedPerson.roleId ? [parsedPerson.roleId] : [];

    setRequest({
      profile,
      rolesIds,
    } as CreateNewContactRequest);
  }

  const selectWidget = () => {
    const parsedPerson = props.people[editedNumber];

    if (!parsedPerson) {
      popupManager.closePopup();
      return null;
    }

    if (parsedPerson.foundMatches.length === 0) {
      createNewContactFromParsedData();
    } else {
      setRequest(undefined);
    }
  }

  useEffect(() => {
    selectWidget();
  }, [editedNumber]);

  if (editedNumber !== undefined) {
    const parsedPerson = props.people[editedNumber];

    if (!parsedPerson) {
      popupManager.closePopup();
      return null;
    }

    const removePerson = async (internalId: number) => {
      await context.removeContact(internalId);

      setPeople(
        people.filter((person) => person.internalId !== internalId)
      );

      selectWidget();
    };

    if (parsedPerson.foundMatches.length > 0 && !request) {
      return (
        <Popup
          title={title}
          content={(
            <>
              <SimpleBlock>
                <SelectedPersonName editedNumber={editedNumber} person={people[editedNumber]} />
                <Left>
                  <SwitchBetweenPeopleControls
                    total={people.length}
                    currentItem={editedNumber}
                    setEditedNumberFunc={setEditedNumber}
                  />
                </Left>
              </SimpleBlock>
              <FoundContactsWidget
                parsedPerson={parsedPerson}
                onProfileSelected={(choice) => {
                  setRequest({
                    profile:  {
                      name: choice.name,
                      phoneNumbers: choice.phoneNumbers,
                      companyName: choice.companyName,
                      primaryEmail: choice.primaryEmail || '',
                    },
                    rolesIds: parsedPerson.roleId ? [parsedPerson.roleId] : [],
                    personId: choice.id,
                  })
                }}
              />
              <div style={{
                display: 'flex',
                gap: '1rem',
                justifyContent: 'center'
              }}>
                <Button
                  text="Create New Contact"
                  color={ButtonColor.GREEN}
                  isFilled
                  onClick={() => {
                    createNewContactFromParsedData();
                  }}
                />
                <Button
                  text="Do not add this contact"
                  color={ButtonColor.GRAY}
                  size={ButtonSize.MEDIUM}
                  onClick={async () => {
                    await removePerson(parsedPerson.internalId);
                  }}
                />
              </div>
            </>
          )}
        />
      );
    } else {
      return (
        <FullyRerenderWhenChanged dependencies={[
          JSON.stringify(people[editedNumber]),
        ]}>
          <AddPersonToTransactionForm
            projectId={props.projectId}
            className={styles.block}
            title={title}
            data={request}
            afterSubmitFunc={async () => {
              await removePerson(parsedPerson.internalId);
            }}
            buttons={(
              <>
                <SubmitButton
                  text="Add"
                  key="add-transaction-party-button"
                  color={ButtonColor.BLUE}
                  iconType={ButtonIconType.PLUS}
                />
                <Button
                  text="Remove"
                  color={ButtonColor.GRAY}
                  size={ButtonSize.BIG}
                  onClick={async () => {
                    await removePerson(parsedPerson.internalId);
                  }}
                />
              </>
            )}
            hideSelectedPersonInformationWidget
          >
            <SimpleBlock>
              <SelectedPersonName editedNumber={editedNumber} person={people[editedNumber]} />
              <Left>
                <SwitchBetweenPeopleControls
                  total={people.length}
                  currentItem={editedNumber}
                  setEditedNumberFunc={setEditedNumber}
                />
              </Left>
            </SimpleBlock>
          </AddPersonToTransactionForm>
        </FullyRerenderWhenChanged>
      );
    }
  } else {
    return (<LoadingAnimation />);
  }
}
