import React, { useCallback, useEffect, useMemo } from 'react';
import { WysiwygLayoutProps, WysiwygProps } from 'ui-builder';
import QuillWysiwygLayout from './layout/QuillWysiwygLayout';
import useInputHelper from './inputHelper';
import { useSmartTagsContext } from '../../../smarttags/SmartTagsContext';
import HowToUseSmartTagsLink from '../HowToUseSmartTagsLink';

export type Tag = {
  id: string,
  value: string
  isDeprecated: boolean,
}

interface SmartTagsWysiwygLayoutProps extends WysiwygLayoutProps {
  availableTags: Tag[],
  hideHowToUseLink?: boolean,
}

function SmartTagsQuillLayoutWrapper(props: SmartTagsWysiwygLayoutProps) {

  const notDeprecatedTags = useMemo(
    () => props.availableTags.filter((tag) => !tag.isDeprecated),
    [props.availableTags.length],
  );

  useEffect(() => {
    (window as any).smartTags = notDeprecatedTags;
  }, [notDeprecatedTags.length]);

  const modules = {
    mention: {
      allowedChars: /^[A-Za-z\\.]*$/,
      dataAttributes: ['id', 'value', 'existing'],
      mentionDenotationChars: ['#'],
      source: useCallback((searchTerm: string, renderList: any, mentionChar: string) => {
        if (mentionChar === '#') {
          const values = (window as any).smartTags || [];
          if (searchTerm.length === 0) {
            renderList(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i += 1) {
              if (values[i].value.toLowerCase()
                .indexOf(searchTerm.toLowerCase()) !== -1) {
                matches.push(values[i]);
              }
            }
            renderList(matches, searchTerm);
          }
        }
      }, []),
    },
  };

  return (
    <>
      {!props.hideHowToUseLink && (
        <HowToUseSmartTagsLink />
      )}
      <QuillWysiwygLayout
        {...props}
        modules={modules}
      />
    </>
  );
}

interface SmartTagsWysiwygProps extends WysiwygProps {
  hideHowToUseLink?: boolean,
}

export default function SmartTagsWysiwyg(props: SmartTagsWysiwygProps) {
  const helper = useInputHelper(props);

  const smartTagsContext = useSmartTagsContext();

  return (
    <SmartTagsQuillLayoutWrapper
      {...helper.getBaseInputLayoutProps()}
      availableTags={smartTagsContext.availableTags}
      hideControls={props.hideControls}
      hideHowToUseLink={props.hideHowToUseLink}
    />
  );

}
