import React from 'react';

import { useDateHelper } from 'nekst-api';
import { useDueDateFilterService } from 'features/tasks-list-feature';
import FilterWidgetLayout from '../FilterWidgetLayout';

export default function DueDateWidget() {
  const filterService = useDueDateFilterService();

  const filterValue = filterService.getFilterValue();

  const dateHelper = useDateHelper();

  function getText() {
    if (filterValue) {
      if (Array.isArray(filterValue)) {
        const from = filterValue[0];
        const to = filterValue[1];

        if (from && to) {
          if (from.getTime() !== to.getTime()) {
            return `${dateHelper.toString(from)} - ${dateHelper.toString(to)}`;
          } else {
            return `${dateHelper.toString(from)}`;
          }
        } else if (!from && to) {
          return `Before ${dateHelper.toString(to)}`;
        } else if (from && !to) {
          return `From ${dateHelper.toString(from)}`;
        } else {
          return null;
        }
      } else {
        return dateHelper.toString(filterValue);
      }
    } else {
      return null;
    }
  }

  const text = getText();

  if (text) {
    return (
      <FilterWidgetLayout
        name="Due Date"
        text={text}
        onClick={() => filterService.setFilterValue(null)}
      />
    );
  } else {
    return null;
  }
}
