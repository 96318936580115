import { useEventsPublisher } from 'event-bus';
import { ValidationSchema } from 'validation-schema';
import { FormFieldsData } from 'ui-builder';
import { ShortProjectRole, TaskType } from './Types';
import { useBackendApi } from './backendApi';
import { TasksGroup } from './tasksGroupsService';


export enum DueDateWhen {
  BEFORE_START_DATE = 'before_start',
  AFTER_START_DATE = 'after_start',
  BEFORE_END_DATE = 'before_end',
  AFTER_END_DATE = 'after_end',
}

export type DueDateConfig = {
  days: number,
  when: DueDateWhen
}

export type PlanTaskParentTaskRelation = {
  days: number,
  parentTaskId: number
  parentTaskName: string
}

export type TransactionParty = {
  projectRoleId: number,
  remindDays?: number
  _projectRole: ShortProjectRole
}

export enum RecipientType {
  RECIPIENT = 'RECIPIENT',
  CC = 'CC',
  BCC = 'BCC',
}

export type PlanTaskEmailRecipient = {
  projectRoleId: number,
  type: RecipientType,
  _projectRole: ShortProjectRole
}

export type PlanTaskSmsRecipient = {
  projectRoleId: number
  _projectRole: ShortProjectRole
}

export type PlanTask = {
  id: number,
  planId: number,
  name: string,
  type: TaskType,
  dueDateConfig?: DueDateConfig,
  isKeyDate: boolean,
  isPrivate: boolean,
  isDuringBusinessWeek: boolean,
  description: string,
  transactionParties: TransactionParty[],
  order: number,
  sendFromRoleId?: number,
  isAutoSent: boolean,
  emailRecipients: PlanTaskEmailRecipient[],
  smsRecipients: PlanTaskSmsRecipient[],
  parentTaskRelation?: PlanTaskParentTaskRelation
  linkedFieldId?: number
  _linkedField?: {
    id: number
    name: string,
  }
  groupId?: number,
  _group?: TasksGroup
  excluded: boolean
}

export interface PlanTasksService {
  getPlanTasks: (planId: number) => Promise<PlanTask[]>,
  createStandardTask: (planId: number, data: PlanTask) => Promise<PlanTask>,
  createEmailTask: (planId: number, data: PlanTask) => Promise<PlanTask>,
  createSmsTask: (planId: number, data: PlanTask) => Promise<PlanTask>,
  updateTask: (taskId: number, data: PlanTask) => Promise<PlanTask>,
  getStandardTaskValidationSchema: () => ValidationSchema,
  getEmailTaskValidationSchema: () => ValidationSchema,
  getSmsTaskValidationSchema: () => ValidationSchema,
  deleteTask: (taskId: number) => Promise<void>,
  getTasksCompareFunc: () => (task1: PlanTask, task2: PlanTask) => number,
  setOrder: (ids: number[]) => Promise<void>;
}

export const PLAN_TASKS_UPDATED = 'PLAN_TASKS_UPDATED';

const NO_DUE_DATE_WEIGHT = 2;

export function usePlanTasksService(): PlanTasksService {
  const api = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const onPlanTasksUpdated = (planId: number) => {
    eventsPublisher.publish(PLAN_TASKS_UPDATED, {
      planId,
    });
  };


  const createStandardTask = async (planId: number, data: PlanTask) => {
    const result = await api.post(`/plans/${planId}/tasks/standard`, data);

    onPlanTasksUpdated(planId);

    return result;
  };

  const createEmailTask = async (planId: number, data: PlanTask) => {
    const result = await api.post(`/plans/${planId}/tasks/email`, data);

    onPlanTasksUpdated(planId);

    return result;
  };

  const createSmsTask = async (planId: number, data: PlanTask) => {
    const result = await api.post(`/plans/${planId}/tasks/sms`, data);

    onPlanTasksUpdated(planId);

    return result;
  };

  const updateTask = async (taskId: number, data: FormFieldsData) => {
    const result = await api.put(`/plantasks/${taskId}`, data);
    onPlanTasksUpdated(result.planId);
    return result;
  };

  const getStandardTaskValidationSchema = () => {
    return {
      name: {
        type: 'string',
        constraints: {
          required: true,
        },
      },
      dueDateConfig: {
        type: 'object',
        constraints: {},
        properties: {
          days: {
            type: 'int',
            constraints: {
              required: {
                value: true,
                message: 'Field is required',
              },
            },
          },
          when: {
            type: 'string',
            constraints: {
              required: {
                value: true,
                message: 'Field is required',
              },
            },
          },
        },
      },
      parentTaskRelation: {
        type: 'object',
        properties: {
          days: {
            type: 'int',
            constraints: {
              required: true,
            },
          },
          parentTaskId: {
            type: 'int',
            constraints: {
              required: true,
            },
          },
        },
        constraints: {},
      },
      transactionParties: {
        type: 'array',
        constraints: {
          required: {
            value: true,
            message: 'At least one transaction party must be selected',
          },
        },
      },
    } as ValidationSchema;
  };

  const getEmailTaskValidationSchema = () => {
    return {
      name: {
        type: 'string',
        constraints: {
          required: true,
        },
      },
      dueDateConfig: {
        type: 'object',
        constraints: {},
        properties: {
          days: {
            type: 'int',
            constraints: {
              required: {
                value: true,
                message: 'Field is required',
              },
            },
          },
          when: {
            type: 'string',
            constraints: {
              required: {
                value: true,
                message: 'Field is required',
              },
            },
          },
        },
      },
      parentTaskRelation: {
        type: 'object',
        properties: {
          days: {
            type: 'int',
            constraints: {
              required: true,
            },
          },
          parentTaskId: {
            type: 'int',
            constraints: {
              required: true,
            },
          },
        },
        constraints: {},
      },
      transactionParties: {
        type: 'array',
        constraints: {
          required: {
            value: true,
            message: 'At least one transaction party must be selected',
          },
        },
      },
      emailRecipients: {
        type: 'array',
        constraints: {
          filteredRequired: {
            value: true,
            message: 'At least one email recipient must be selected',
            filter: (item: any) => item.type === 'RECIPIENT',
          },
        },
      },
    } as ValidationSchema;
  };

  const getSmsTaskValidationSchema = () => {
    return {
      name: {
        type: 'string',
        constraints: {
          required: true,
        },
      },
      description: {
        type: 'html',
        constraints: {
          required: true,
        },
      },
      smsRecipients: {
        type: 'array',
        constraints: {
          required: {
            value: true,
            message: 'At least one SMS recipient must be selected',
          },
        },
      },
      dueDateConfig: {
        type: 'object',
        constraints: {},
        properties: {
          days: {
            type: 'int',
            constraints: {
              required: {
                value: true,
                message: 'Field is required',
              },
            },
          },
          when: {
            type: 'string',
            constraints: {
              required: {
                value: true,
                message: 'Field is required',
              },
            },
          },
        },
      },
      parentTaskRelation: {
        type: 'object',
        properties: {
          days: {
            type: 'int',
            constraints: {
              required: true,
            },
          },
          parentTaskId: {
            type: 'int',
            constraints: {
              required: true,
            },
          },
        },
        constraints: {},
      },
      transactionParties: {
        type: 'array',
        constraints: {
          required: {
            value: true,
            message: 'At least one transaction party must be selected',
          },
        },
      },
    } as ValidationSchema;
  };

  const deleteTask = async (taskId: number) => {
    await api.delete(`/plantasks/${taskId}`);
    eventsPublisher.publish(PLAN_TASKS_UPDATED, {});
  };


  const getTasksCompareFunc = (task1: PlanTask, task2: PlanTask) => {
    const whenWeights = {
      [DueDateWhen.BEFORE_START_DATE]: -4,
      [DueDateWhen.AFTER_START_DATE]: -3,
      [DueDateWhen.BEFORE_END_DATE]: -2,
      [DueDateWhen.AFTER_END_DATE]: -1,
    };

    const dueDateConfig1 = task1.dueDateConfig as DueDateConfig;
    const dueDateConfig2 = task2.dueDateConfig as DueDateConfig;

    let weight1;
    if (dueDateConfig1) {
      weight1 = whenWeights[dueDateConfig1.when];
    } else {
      weight1 = NO_DUE_DATE_WEIGHT;
    }

    let weight2;
    if (dueDateConfig2) {
      weight2 = whenWeights[dueDateConfig2.when];
    } else {
      weight2 = NO_DUE_DATE_WEIGHT;
    }

    const days1 = dueDateConfig1?.days || task1.parentTaskRelation?.days || 0;
    const days2 = dueDateConfig2?.days || task2.parentTaskRelation?.days || 0;

    const isBeforeDate = dueDateConfig1 && dueDateConfig1.when.startsWith('before_');

    if (weight1 && weight2 && (weight1 !== weight2)) {
      return weight1 - weight2;
    } else if (days1 !== days2) {
      if (isBeforeDate) {
        return days2 - days1;
      } else {
        return days1 - days2;
      }
    } else if (task1.order !== task2.order) {
      return task1.order - task2.order;
    } else {
      return task1.name.localeCompare(task2.name);
    }
  };


  const getPlanTasks = async (planId: number) => {
    const result = await api.get(`/plans/${planId}/tasks`) as PlanTask[];
    result.sort(getTasksCompareFunc);

    return result;
  };

  const setOrder = async (ids: number[]) => {
    return api.put('/plantasks/bulk/order', {
      ids,
    });
  };

  return {
    getPlanTasks,
    createStandardTask,
    createEmailTask,
    createSmsTask,
    getStandardTaskValidationSchema,
    getEmailTaskValidationSchema,
    getSmsTaskValidationSchema,
    updateTask,
    deleteTask,
    setOrder,
    getTasksCompareFunc: () => getTasksCompareFunc,
  };
}
