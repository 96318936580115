import React, { useEffect, useState } from 'react';
import {
  ProjectDetailsField,
  ProjectType,
  TeamPermission,
  useProjectDetailsFieldsService
} from 'nekst-api';
import { ProjectTypeDropdown } from 'features/tasks-forms';
import InnerBlock from '../../../../shared/web/layout/InnerBlock';
import InputForm from './InputForm';
import useAiTrainerService, {
  AiTrainingEntry,
  CreateAiTrainingEntryRequest
} from '../aiTrainerService';
import { ViewParseResultWidget } from '../ViewParseResultWidget/ViewParseResultWidget';
import { Separator } from 'features/nekst-widgets';
import useAiTrainerNavigator from '../aiTrainerNavigator';
import { AccessChecker } from 'authorization-scope';
import AiTrainerBreadcrumbs from '../AiTrainerBreadcrumbs';
import { Breadcrumb } from '../../../../shared/web/layout/Breadcrumbs';
import { BlockerWidget } from '../BlockerWidget';
import { RelativeRow } from 'ui-builder';

function ParseModeView(
  props: {
    setParseResultFunc: (result: CreateAiTrainingEntryRequest[]) => void
  }
) {

  const [projectType, setProjectType] = useState<ProjectType>();

  const [detailsFields, setDetailsFields] = useState<Nullable<ProjectDetailsField[]>>();

  const detailsFieldsService = useProjectDetailsFieldsService();

  const navigator = useAiTrainerNavigator();

  async function loadDetailsFields() {
    if (projectType) {
      const fields = await detailsFieldsService.getFields(projectType);
      setDetailsFields(fields);
    } else {
      setDetailsFields(undefined);
    }
  }

  useEffect(() => {
    loadDetailsFields();
  }, [projectType]);

  return (
    <div className="form">
      <ProjectTypeDropdown
        source="projectType"
        onChangeCallback={(event) => {
          setProjectType(event.projectType);
        }}
        value={projectType}
        isDisabled={projectType !== undefined}
        label="Select Transaction Type"
      />
      {detailsFields && (
        <>
          <Separator />
          <InputForm
            projectType={projectType!}
            detailsFields={detailsFields}
            setPdfParseResultFunc={async (result) => {
              props.setParseResultFunc(result);
            }}
            cancelFunc={() => {
              navigator.openEntriesList();
            }}
          />
        </>
      )}
    </div>
  );
}

export default function CreateTrainingEntryPage() {

  const [editedEntries, setEditedEntries] = useState<CreateAiTrainingEntryRequest[]>();

  const aiTrainerService = useAiTrainerService();

  const aiTrainerNavigator = useAiTrainerNavigator();

  return (
    <AccessChecker permissionToCheck={TeamPermission.MANAGE_AI_TRAINER}>
      <AiTrainerBreadcrumbs>
        <Breadcrumb text="Create new entry" />
      </AiTrainerBreadcrumbs>
      <InnerBlock title="Create new AI training entry" className="nekst">
        {!editedEntries && <ParseModeView setParseResultFunc={setEditedEntries} />}
        {editedEntries && (
          <div style={{
            position: 'absolute',
            width: '95vw',
            left: 0,
            paddingLeft: 30,
            paddingRight: 30
          }}>
            <RelativeRow weights={[6, 6]}>
              {editedEntries.map((editedEntry, index) => (
                <>
                  <h5>Results for model {editedEntry._model}</h5>
                  <ViewParseResultWidget
                    entry={{
                      fileText: editedEntry.fileText,
                      people: editedEntry.people,
                      prompts: editedEntry.prompts,
                    } as AiTrainingEntry}
                    onSubmitFunc={async (data) => {
                      await aiTrainerService.createEntry({
                        ...editedEntry,
                        ...data,
                      });

                      aiTrainerNavigator.openEntriesList();
                    }}
                    key={index}
                  />
                </>

              ))}
            </RelativeRow>
          </div>

        )}
      </InnerBlock>

      <BlockerWidget isSaved={false} />
    </AccessChecker>
  );
}
