import React, { useCallback, useEffect, useRef } from 'react';
import { useLayer } from 'react-laag';
import { NotificationsList, useNotificationsBell } from 'features/tasks-forms';

import styles from './NotificationBell.module.scss';
import useProjectNavigator from '../projects/projectNavigator';
import { Badge } from 'features/nekst-widgets';
import UnreadCountUpdater from './UnreadCountUpdater';
import { useAuthenticationContext } from 'nekst-api';

function NotificationsBellLayout(
  props: { unreadCount: number | undefined }
) {
  const [isOpen, setIsOpen] = React.useState(false);

  const projectNavigator = useProjectNavigator();

  const {
    triggerProps,
    layerProps,
    renderLayer
  } = useLayer({
    isOpen,
    placement: 'bottom-start',
    overflowContainer: false,
    triggerOffset: 1,
    containerOffset: 0,
    auto: true,
    possiblePlacements: [
      'bottom-start',
      'bottom-end'
    ],
    container: 'notificationsContainer',
  });

  const listRef = useRef<HTMLDivElement>(null);

  const hideNotifications = useCallback((event: any) => {
    if ((listRef.current
        && !listRef.current.contains(event.target as Node))
      || (event.target as Node).nodeName.toLowerCase() !== 'button'
    ) {
      setIsOpen(false);
    }
  }, [listRef.current]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        document.addEventListener('click', hideNotifications);
      }, 100);
    } else {
      document.removeEventListener('click', hideNotifications);
    }

    return () => {
      document.removeEventListener('click', hideNotifications);
    };
  }, [isOpen, hideNotifications]);

  return (
    <div
      className={[styles.bellWidget, isOpen && styles.hovered].join(' ')}
      ref={triggerProps.ref}

    >
      <div
        className={styles.bellOuterContainer}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div
          className={styles.bellContainer}
        >
          <div className={styles.bell} />
          {props.unreadCount ? (<Badge number={props.unreadCount} />) : null}
        </div>
      </div>
      <div ref={listRef} id="notificationsContainer">
        {isOpen && renderLayer(
          <div
            {...layerProps}
            className={styles.notificationsList}
          >
            <NotificationsList
              openProjectAndTaskFunc={(projectId, taskId) => {
                projectNavigator.openTasksPage(projectId, taskId);
              }}
              openProjectFunc={(projectId) => {
                projectNavigator.openTasksPage(projectId);
              }}
              openProjectNotesFunc={(projectId) => {
                projectNavigator.openDetailsPage(projectId);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function NotificationBellImpl() {
  const service = useNotificationsBell();

  return (
    <>
      <NotificationsBellLayout
        unreadCount={service.unreadCount}
      />
      <UnreadCountUpdater />
    </>
  );
}

export default function NotificationsBell() {
  const authenticationContext = useAuthenticationContext();

  if (authenticationContext) {
    return (
      <NotificationBellImpl />
    );
  } else {
    return null;
  }
}
