import React from 'react';
import { useAuthorizationChecker } from 'authorization-scope';
import {
  AfterSubmitFunc,
  FormTemplate, IfFormData,
  RadioButtonOption,
  RadioButtonsGroup,
  useFilterContext
} from 'ui-builder';
import { PeopleFilter, TeamPermission } from 'nekst-api';
import { ServiceProvidersMultiDropdown } from '../../ServiceProviders';
import useFeatureToggleService from 'feature-toggle';

type PeopleFilterFormType = {
  personType: '' | 'TEAM_MEMBER' | 'SERVICE_PROVIDER',
  serviceProvidersGroupsIds?: number[]
}

function ContactTypeRadioButtons(props: {
  source: string,
}) {
  const { isGranted } = useAuthorizationChecker();

  const featureToggleService = useFeatureToggleService();

  const teamMembersManagementEnabled = featureToggleService.isTeamMembersManagementEnabled();

  const options: (RadioButtonOption | false)[] = [
    {
      value: '',
      label: 'All Contacts',
    },
    !teamMembersManagementEnabled && isGranted(TeamPermission.USE_TEAM_MEMBERS_FEATURE) && {
      value: 'TEAM_MEMBER',
      label: 'Team Members Only',
    },
    {
      value: 'SERVICE_PROVIDER',
      label: 'Service Providers Only',
    },
  ];

  return (
    <RadioButtonsGroup
      options={options.filter((item) => !!item) as RadioButtonOption[]}
      source={props.source}
      label="Contact Type"
    />
  );
}

export function PeopleFilterForm(props: {
  afterSubmitFunc?: AfterSubmitFunc,
}) {
  const filterContext = useFilterContext<PeopleFilter>();

  const filterValue = filterContext.filterValue;

  let personType: '' | 'TEAM_MEMBER' | 'SERVICE_PROVIDER' = '';

  if (filterValue?.showTeamMembersOnly) {
    personType = 'TEAM_MEMBER';
  } else if (filterValue?.showServiceProvidersOnly) {
    personType = 'SERVICE_PROVIDER';
  }

  const initialData: PeopleFilterFormType = {
    personType,
    serviceProvidersGroupsIds: filterValue?.serviceProvidersGroupsIds,
  };

  return (
    <FormTemplate<PeopleFilterFormType>
      submitFormFunc={async (data) => {
        const diff: Partial<PeopleFilter> = {
          showServiceProvidersOnly: data.personType === 'SERVICE_PROVIDER',
          showTeamMembersOnly: data.personType === 'TEAM_MEMBER',
          serviceProvidersGroupsIds: data.personType === 'SERVICE_PROVIDER' ? data.serviceProvidersGroupsIds : undefined,
        };

        const newFilterValue = {
          ...filterContext.filterValue!,
          ...diff,
        };

        filterContext.setFilterValue(newFilterValue);

        return data;
      }}
      initialData={initialData}
      afterSubmitFunc={props.afterSubmitFunc}
      title="Filter Contacts"
    >
      <ContactTypeRadioButtons source="personType" />
      <IfFormData<PeopleFilterFormType>
        conditionFunc={(data) => data.personType === 'SERVICE_PROVIDER'}
      >
        <ServiceProvidersMultiDropdown
          source="serviceProvidersGroupsIds"
          label="Service Providers Groups"
          placeholder="Show All"
        />
      </IfFormData>
    </FormTemplate>
  );
}
