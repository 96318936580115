import React from 'react';
import useLaunchFromContractNavigator from './launchFromContractNavigator';
import BaseSelectGroupsPage from '../Shared/BaseSelectGroupsPage';
import LaunchFromContractSteps, { LaunchFromContractStepId } from './LaunchFromContractSteps';

export default function SelectGroupsPage() {
  const navigator = useLaunchFromContractNavigator();

  return (
    <BaseSelectGroupsPage
      onPrevClicked={() => {
        navigator.openEditDetailsBoxesPage();
      }}
      onNextClicked={() => {
        navigator.openKeyDatesPage();
      }}
      roadmap={<LaunchFromContractSteps currentStep={LaunchFromContractStepId.GROUPS} />}
    />
  );
}
