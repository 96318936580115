import React from 'react';
import { ContactAssignments } from 'nekst-api';

import styles from './AssignmentNameWithRoles.module.scss';
import { useAuthenticationLoader } from '../../../../authentication/AuthenticationContext';

interface Props {
  assignment: ContactAssignments;
}

export function RolesLayout(props: Props) {
  const roleNames = props.assignment.roles.map((r) => r.roleName)
    .filter((value, index, self) => self.indexOf(value) === index);

  let element;
  if (roleNames.length === 1) {
    element = (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>{roleNames[0]}</>
    );
  } else {
    element = (
      <span className={styles.rounded} title={roleNames.join(', ')}>{roleNames.length}</span>
    );
  }

  return (
    <div className={styles.roles}>
      {element}
    </div>
  );
}

export default function AssignmentNameWithRoles(props: Props) {
  const { currentUser } = useAuthenticationLoader();

  let fullName = props.assignment.profile.name.fullName;

  if (currentUser.id === props.assignment.personId) {
    fullName += ' (You)';
  }

  return (
    <>
      {fullName}
      <RolesLayout assignment={props.assignment} />
    </>
  );
}
