import React from 'react';
import { get } from 'lodash';
import { useShowContext } from 'ui-builder';
import { Project, ShortTaskDto } from 'nekst-api';

import styles from './NextKeyDateField.module.scss';
import useDueDateHelper from '../../view/tasks/list/dueDateHelper';

export default function NextKeyDateField(
  props: {
    // eslint-disable-next-line react/no-unused-prop-types
    label: string,
    source: string,
    dataKey?: string
  },
) {

  const dueDateHelper = useDueDateHelper();

  const showContext = useShowContext<Project>();

  const data = showContext.data!;

  const value = get(data, props.source) as ShortTaskDto | null;

  if (value) {
    return (
      <div data-key={props.dataKey}>
        <div className={styles.name} title={value.name}>{value.name}</div>
        <div className={styles.dueDate}>
          {dueDateHelper.getStringValue(value.dueDate)}
        </div>
      </div>
    );
  } else {
    return (<>-</>);
  }

}
