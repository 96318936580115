import React from 'react';
import { NoteData } from 'nekst-api';

interface Props {
  note: NoteData;
}

export default function NoteText(props: Props) {
  return (
    <span><b>{`"${props.note.text}"`}</b></span>
  );
}
