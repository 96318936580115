import React from 'react';
import {
  ParsePrompt,
  PdfParseResult,
  ProjectDetailsField,
  ProjectDetailsFieldType,
  ProjectType,
  PromptParseResult,
  useAiLauncherService,
  useProjectDetailsFieldsService
} from 'nekst-api';
import { FormTemplate, TextArea, TextInput, TextLink } from 'ui-builder';
import { Separator } from 'features/nekst-widgets';
import Row1x2x from '../../../../shared/web/uitheme/form/Row1x2x';
import FileInput from './FileInput';
import { CreateAiTrainingEntryRequest, TrainingEntryUsedFor } from '../aiTrainerService';
import FieldTypeDropdown from '../Shared/FieldTypeDropdown';
import UsedForDropdown from '../Shared/UsedForDropdown';
import SelectAiModelDropdown, { getDefaultAiModel } from './SelectAiModelDropdown';

function getFieldType(value: ProjectDetailsFieldType) {
  switch (value) {
    case ProjectDetailsFieldType.DATE:
      return 'D';
    case ProjectDetailsFieldType.LONG_TEXT:
    case ProjectDetailsFieldType.SHORT_TEXT:
      return 'S';
    case ProjectDetailsFieldType.PRICE:
      return 'N';
  }
}

export type InputFormDataType = {
  prompts: Record<string, { type: 'D' | 'S' | 'N' | 'B', prompt: string }>,
  file: string,
  fileName: string,
  comment: string
  usedFor: TrainingEntryUsedFor
  aiModel: string[],
}

export default function InputForm(
  props: {
    projectType: ProjectType,
    detailsFields: ProjectDetailsField[],
    setPdfParseResultFunc: (data: CreateAiTrainingEntryRequest[]) => void,
    cancelFunc: () => void,
  }
) {
  const [numberOfCustomInputs, setNumberOfCustomInputs] = React.useState(1);

  const aiLauncherService = useAiLauncherService();

  const projectDetailsService = useProjectDetailsFieldsService();

  return (
    <FormTemplate<InputFormDataType, PdfParseResult[]>
      submitFormFunc={async (data) => {
        const prompts: ParsePrompt[] = [];

        Object.entries(data.prompts)
          .forEach(([key, value]) => {
            prompts.push({
              id: key,
              prompt: value.prompt,
              type: value.type,
            });
          });

        const allResult = await Promise
          .all(
            data.aiModel.map(async (aiModel) => {
              const request = {
                file: data.file,
                prompts,
                aiModel,
              };

              return await aiLauncherService.parseDocument(request);
            })
          );

        props.setPdfParseResultFunc(
          allResult.map((result, index) => ({
            projectType: props.projectType,
            fileName: data.fileName,
            file: data.file,
            fileText: result.fileText,
            usedFor: data.usedFor,
            comment: '',
            people: result.people,
            prompts: prompts.map((prompt) => {
              const resultItem = result.tasks.find((task) => task.id === prompt.id);
              return {
                ...prompt,
                value: resultItem?.value,
                context: resultItem?.context || [],
              } as PromptParseResult;
            }),
            _model: data.aiModel[index],
          }))
        );

        return allResult;
      }}
      initialData={{
        prompts: props.detailsFields.reduce((acc, field) => {
          acc[`f:${field.id}`] = {
            type: getFieldType(field.fieldType),
            prompt: field.prompt,
          };
          return acc;
        }, {} as Record<string, { type: 'D' | 'S' | 'N' | 'B', prompt: string }>),
        usedFor: TrainingEntryUsedFor.TRAINING,
        aiModel: [getDefaultAiModel()],
      }}
      validationSchema={{
        file: {
          type: 'string',
          constraints: {
            required: true,
          }
        },
        usedFor: {
          type: 'string',
          constraints: {
            required: true,
          }
        },
        aiModel: {
          type: 'array',
          constraints: {
            required: true,
          }
        },
      }}
      cancelFunc={async () => {
        props.cancelFunc();
      }}
      afterSubmitFunc={async () => {
        projectDetailsService.__clearCache();
      }}
    >
      <SelectAiModelDropdown source="aiModel" label="AI Model(s)" />
      <UsedForDropdown source="usedFor" />
      <FileInput source="file" fileNameSource="fileName" label="Document" />
      <Separator />
      <TextArea source="comment" label="Comment" />
      <Separator />
      <h3>Prompts for details fields</h3>
      {props.detailsFields.map((field) => {
        return (
          <TextInput
            source={`prompts.f:${field.id}.prompt`}
            label={field.name}
            key={field.id}
            placeholder={field.prompt}
          />
        );
      })}
      <Separator />
      <h3>Custom Prompts</h3>
      {Array.from({ length: numberOfCustomInputs })
        .map((_, index) => {
          return (
            <Row1x2x>
              <FieldTypeDropdown source={`prompts.c:${index}.type`} />
              <TextInput
                source={`prompts.c:${index}.prompt`}
                label={`Custom prompt ${index + 1}`}
                key={`c:${index}`}
                placeholder={`Define custom prompt ${index + 1}`}
              />
            </Row1x2x>
          );
        })}
      <TextLink
        onClick={() => {
          setNumberOfCustomInputs(numberOfCustomInputs + 1);
        }}
      >
        + Add custom prompt
      </TextLink>
    </FormTemplate>
  );
}
