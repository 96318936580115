/* istanbul ignore file */
import React from 'react';
import { FormRowsProps } from '../../../uibuilder/form/FormRows';
import RelativeRow from './RelativeRow';

export default function Row3x3x6x(props: FormRowsProps) {
  return (
    <RelativeRow
      weights={[3, 3, 6]}
      rowClassName="form-row"
    >
      {props.children}
    </RelativeRow>
  );
}
