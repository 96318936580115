import React, { ReactElement } from 'react';
import { get } from 'lodash';
import { ParseValue } from 'nekst-api';

import styles from './ParseDataFieldWrapper.module.scss';
import {
  useParseContractContext,
} from './ParseContractDataContext';
import { IconButton, IconType } from 'features/nekst-widgets';
import { usePopupManager } from 'ui-builder';
import { ViewContextWidget } from './PreviewDocument';

export function ParseDataFieldWidget(
  props: {
    source: string
  }
) {
  const parseContractContext = useParseContractContext();

  const popupManager = usePopupManager();

  if (!parseContractContext) {
    return null;
  }

  const { parseResult } = parseContractContext;

  const value = get(parseResult, props.source) as ParseValue;

  return value.context.length ? (
    <IconButton
      type={IconType.AI}
      onClick={() => {
        popupManager.openPopup(
          <ViewContextWidget fileText={parseResult.fileText} usedRows={value.context} />,
          true
        );
      }}
      className={styles.icon}
    />
  ) : null;
}

export function ParseDataFieldWrapper(
  props: {
    source: string,
    children: ReactElement,
  }
) {

  return React.cloneElement(props.children, {
    afterLabelElement: (<ParseDataFieldWidget source={props.source} />),
  });
}
