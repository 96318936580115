import React, { ReactElement } from 'react';

import { AfterSubmitFunc, FormTemplate, If, SubmitFormFunc } from 'ui-builder';
import { PlanTask, TeamPermission } from 'nekst-api';
import Row3x3x6x from '../../../../shared/web/uitheme/form/Row3x3x6x';
import Checkbox from '../../../../shared/uibuilder/form/input/Checkbox';
import Row6x6x from '../../../../shared/web/uitheme/form/Row6x6x';
import Separator from '../../../../shared/web/uitheme/form/Separator';
import RelativeRow, { EmptyCell } from '../../../../shared/web/uitheme/form/RelativeRow';
import EmailTransactionPartiesInput from './EmailTransactionPartiesInput';
import { ValidationSchema } from '../../../../shared/uibuilder/validation';
import { useProjectTypeAndSetIdContextRequired } from '../../../view/ViewPlan';
import {
  DueDateConfigInput, EmailPlanTaskTitleInput,
  EmailTaskSmartTagsWysiwyg, PlanParentTaskRelationInput, SendFromDropdown,
} from 'features/tasks-forms';
import { PlanPermission, useAuthorizationChecker } from 'authorization-scope';

export interface BaseEmailTaskFormProps {
  title: string,
  submitFormFunc: SubmitFormFunc<PlanTask>,
  afterSubmitFunc?: AfterSubmitFunc,
  validationSchema: ValidationSchema,
  buttons?: ReactElement,
  initialData?: PlanTask
  data?: PlanTask,
}


export default function BaseEmailTaskForm(props: BaseEmailTaskFormProps) {
  const planContext = useProjectTypeAndSetIdContextRequired();

  const { isGranted } = useAuthorizationChecker();

  return (
    <FormTemplate<PlanTask>
      getDataFunc={props.data ? async () => props.data! : undefined}
      submitFormFunc={props.submitFormFunc}
      afterSubmitFunc={props.afterSubmitFunc}
      validationSchema={props.validationSchema}
      title={props.title}
      buttons={props.buttons}
      initialData={props.initialData}
      isDisabled={!isGranted(PlanPermission.UPDATE)}
    >
      <EmailPlanTaskTitleInput
        source="name"
        label="Task Name / Email Subject Line"
        projectType={planContext.projectType}
       />
      <Row3x3x6x>
        <Checkbox source="isKeyDate" label="Key Date" />
        <Checkbox source="isPrivate" label="Private" />
        <Checkbox source="isDuringBusinessWeek" label="Only During Business Week" />
      </Row3x3x6x>
      <Row6x6x>
        <Checkbox source="isAutoSent" label="Autosend" />
      </Row6x6x>
      <RelativeRow
        weights={[7, 1, 4]}
        rowClassName="form-row"
        isVisible={(data) => !data?.parentTaskRelation}
      >
        <DueDateConfigInput source="dueDateConfig" label="Due" />
        <EmptyCell />
        <If conditionFunc={() => isGranted(TeamPermission.ASSIGN_TASKS_TO_OTHER_ROLES)}>
          <SendFromDropdown source="sendFromRoleId" label="Send From" />
        </If>
      </RelativeRow>
      <RelativeRow
        weights={[8, 4]}
        rowClassName="form-row"
        isVisible={(data) => !!data?.parentTaskRelation}
      >
        <PlanParentTaskRelationInput source="parentTaskRelation" label="Due" />
        <If conditionFunc={() => isGranted(TeamPermission.ASSIGN_TASKS_TO_OTHER_ROLES)}>
          <RelativeRow weights={[2, 10]}>
            <EmptyCell />
            <SendFromDropdown source="sendFromRoleId" label="Send From" />
          </RelativeRow>
        </If>
      </RelativeRow>
      <Separator />
      <EmailTaskSmartTagsWysiwyg
        source="description"
        label="Email Message"
        projectType={planContext.projectType}
      />
      <Separator />
      <EmailTransactionPartiesInput source="transactionParties" label="Transaction Parties" />
    </FormTemplate>
  );
}
