import { UrlImageLayoutProps } from './types';
import React from 'react';

export default function UrlImageLayoutWeb(props: UrlImageLayoutProps) {
  return (
    <img
      src={props.imageData}
      alt={props.alt}
      className={props.className}
    />
  );
}
