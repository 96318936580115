import React, { useCallback, useEffect } from 'react';
import { ProfileMentionProps } from '../types';

import styles from './ProfileTextLinkLayout.module.scss';
import { useLayer } from 'react-laag';
import { PersonWidgetWithLoad } from '../../AssigneeWidget';

export default function ProfileMentionLayoutWeb(
  props: ProfileMentionProps
) {

  const [isOpen, setOpen] = React.useState(false);

  const {
    renderLayer,
    triggerProps,
    layerProps,
  } = useLayer({
    isOpen,
    placement: 'bottom-start',
    overflowContainer: false,
    triggerOffset: 1,
    containerOffset: 0,
    auto: false,
    arrowOffset: 0,
    possiblePlacements: [
      'bottom-start',
    ],
  });

  const layersElement = document.getElementById('layers');

  const hideNotifications = useCallback((event: any) => {
    if (layersElement && !layersElement.contains(event.target as Node)) {
      setOpen(false);
    }
  }, [layersElement]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        document.addEventListener('click', hideNotifications);
      }, 100);
    } else {
      document.removeEventListener('click', hideNotifications);
    }

    return () => {
      document.removeEventListener('click', hideNotifications);
    };
  }, [isOpen, hideNotifications]);

  return (
    <span
      className={styles.tag}
      ref={triggerProps.ref}
      onClick={() => {
        setOpen(!isOpen);
      }}
    >
          {props.text}
      {isOpen && renderLayer(
        <div
          {...layerProps}
          style={{
            ...layerProps.style,
          }}
          className={styles.container}
        >
          <PersonWidgetWithLoad id={props.id} />
        </div>
      )}
    </span>

  );
}
