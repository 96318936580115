import React from 'react';
import { MultiSelectDropdownLayoutProps, Option } from 'ui-builder';
import Select, { ActionMeta, StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import useUiTheme from '../../../uiTheme';

export default function MultiSelectDropdownLayout<ValueType extends string | number>(
  props: MultiSelectDropdownLayoutProps<ValueType>
) {

  const Widget = props.optionCreateFunc ? CreatableSelect : Select;

  const currentValue = props.value || [];
  const getValue = (): Option<ValueType>[] => {
    const result = currentValue
      .map((id) => props.options.find((v) => v.value === id)!)
      .map((option) => {
        const item = { ...option };

        if (props.notEditableIds && props.notEditableIds.includes(option.value)) {
          item.isFixed = true;
        }
        return item;
      });

    return result;
  };

  const selectStyles: StylesConfig<Option<ValueType>, true> = {
    multiValue: (base, state) => {
      return state.data.isFixed ? {
        ...base,
        backgroundColor: 'gray',
      } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? {
          ...base,
          paddingRight: 6,
        }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? {
        ...base,
        display: 'none',
      } : base;
    },
  };

  const uiTheme = useUiTheme();

  return (
    <uiTheme.forms.BaseInputLayout
      label={props.label}
      name={props.name}
      errors={props.errors}
      hint={props.hint}
      showLabel={props.showLabel}
      className={props.className}
      hideLabel={props.hideLabel}
      showRequiredIcon={props.showRequiredIcon}
    >
      <Widget<Option<ValueType>, true>
        name={props.name}
        options={props.options}
        className="multiSelectDropdown"
        classNamePrefix="multiSelectDropdown"
        isClearable={false}
        isDisabled={props.isLoading}
        isLoading={props.isLoading}
        value={getValue()}
        placeholder={props.placeholder}
        isMulti
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        styles={selectStyles}
        onChange={(newValue: any, event: ActionMeta<Option<ValueType>>) => {
          const option = event.option || event.removedValue;

          if (option) {
            if (event.action === 'select-option') {
              props.addItemFunc(option.value);
            } else if (event.action === 'deselect-option') {
              props.removeItemFunc(option.value);
            } else if (event.action === 'remove-value') {
              props.removeItemFunc(option.value);
            }
          }
        }}
        onCreateOption={props.optionCreateFunc}
        isOptionDisabled={() => !!props.max && (currentValue.length >= props.max)}
        onMenuClose={() => {
          // ignored
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </uiTheme.forms.BaseInputLayout>
  );
}
