import React, { ReactElement } from 'react';
import { PopupForm } from 'features/nekst-widgets';
import CreateStandardPlanTaskForm from './Standard/CreateStandardPlanTaskForm';
import { CreateEmailTaskForm } from './Email/CreateEmailTaskForm';
import { CreateSmsTaskForm } from './Sms/CreateSmsTaskForm';
import { usePopupManager } from 'ui-builder';
import { PlanTask } from 'nekst-api';

export default function usePlanTaskForms() {
  const popupManager = usePopupManager();

  const openForm = (form: ReactElement) => {
    popupManager.openPopup(
      <PopupForm>
        {form}
      </PopupForm>,
    );
  };

  const openCreateStandardTaskForm = (planId: number, parentTask?: PlanTask) => {
    openForm(<CreateStandardPlanTaskForm planId={planId} parentTask={parentTask} />);
  };

  const openCreateEmailTaskForm = (planId: number, parentTask?: PlanTask) => {
    openForm(
      <CreateEmailTaskForm
        planId={planId}
        parentTask={parentTask}
      />,
    );
  };

  const openCreateSmsTaskForm = (planId: number, parentTask?: PlanTask) => {
    openForm(<CreateSmsTaskForm planId={planId} parentTask={parentTask} />);
  };

  return {
    openCreateStandardTaskForm,
    openCreateEmailTaskForm,
    openCreateSmsTaskForm,
  };
}
