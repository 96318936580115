import React, { useEffect, useState } from 'react';
import Select, { ActionMeta, StylesConfig } from 'react-select';
import {
  useAuthenticationContext,
  useEventsPublisher,
  RoleAssignment,
  useProjectAssignmentsService,
  ContactAssignments,
  PROJECT_ASSIGNMENTS_CHANGED,
  ProjectAssignmentType,
  ProjectPermission,
} from 'nekst-api';
import { Option } from 'ui-builder';
import { useProjectRolesLoader } from 'features/tasks-forms';
import { useAuthorizationChecker } from 'authorization-scope';

export interface ContactAssignmentsDropdownProps {
  contactAssignments: ContactAssignments,
  projectId: number
}

export default function ContactAssignmentsDropdown(props: ContactAssignmentsDropdownProps) {
  const eventsPublisher = useEventsPublisher();

  const [options, setOptions] = useState<Option[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const { roles } = useProjectRolesLoader();

  const [currentValue, setCurrentValue] = useState<Option[]>([]);

  const [deletedItems, setDeletedItems] = useState<Option[]>([]);

  const projectAssignmentsService = useProjectAssignmentsService(false);

  const { isGranted } = useAuthorizationChecker();

  const authenticationContext = useAuthenticationContext();

  useEffect(() => {
    let sourceRoles = roles;

    if (props.contactAssignments.type === ProjectAssignmentType.TRANSACTION_PARTY) {
      sourceRoles = sourceRoles.filter(
        (r) => r.name !== 'Editor' && r.name !== 'Viewer',
      );
    }

    setOptions(sourceRoles.map((option) => {
      return {
        value: option.id,
        label: option.name,
      } as Option;
    }));
  }, [roles.length]);

  function findOptionByRoleId(roleId: number): Option {
    return { ...options.find((option) => option.value === roleId)! };
  }

  function isEditorAndCurrentUser(assignment: RoleAssignment) {
    if (assignment.roleName === 'Editor'
      && authenticationContext?.user.id === props.contactAssignments.personId
    ) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (options.length > 0) {
      const result: Option[] = [];

      props.contactAssignments.roles.forEach((r) => {
        const roleOption = {
          ...findOptionByRoleId(r.roleId),
        };
        roleOption.id = r.id;
        roleOption.isFixed = !r.isEditable || isEditorAndCurrentUser(r);
        result.push(roleOption);
      });

      setCurrentValue(result);
    }
  }, [options.length, JSON.stringify(props.contactAssignments.roles)]);

  function pullOptionFromRemovedItems(roleId: number): Option | null {
    const indexOf = deletedItems.findIndex((v) => v.value === roleId);

    if (indexOf !== -1) {
      const result = deletedItems.splice(indexOf, 1);

      setDeletedItems(deletedItems);

      return result[0];
    } else {
      return null;
    }
  }

  function addAssignment(roleId: number) {
    const removedOption = pullOptionFromRemovedItems(roleId);

    if (removedOption) {
      setCurrentValue((prevValue) => ([
        ...prevValue,
        removedOption,
      ]));
    } else {
      const newOption = findOptionByRoleId(roleId);
      const newValue = [
        ...currentValue,
      ];

      newValue.push(newOption);

      setCurrentValue(newValue);
    }
  }

  function removeAssignment(roleId: number) {
    const assignment = currentValue.find((v) => v.value === roleId);

    if (assignment) {
      if (assignment.isFixed) {
        return;
      }

      if (assignment.id) {
        setDeletedItems((prevValue) => ([
          ...prevValue,
          assignment,
        ]));
      }

      const index = currentValue.indexOf(assignment);

      if (index !== -1) {
        setCurrentValue((prevValue) => {
          const newValue = [...prevValue];
          newValue.splice(index, 1);
          return newValue;
        });
      }
    }
  }

  async function removeAssignmentAndApply(roleId: number) {
    const assignment = currentValue.find((v) => v.value === roleId);

    if (assignment && assignment.id) {
      setLoading(true);

      if (assignment.isFixed) {
        return;
      }

      const index = currentValue.indexOf(assignment);

      if (index !== -1) {
        setCurrentValue((prevValue) => {
          const newValue = [...prevValue];
          newValue.splice(index, 1);
          return newValue;
        });
      }

      await projectAssignmentsService.deleteAssignment(
        props.projectId,
        assignment.id,
      );

      setLoading(false);
    }
  }

  async function applyChanges() {
    setLoading(true);

    const assignmentsToAdd = currentValue.filter((v) => v.id === null || v.id === undefined);

    const assignmentToRemove = deletedItems;

    setDeletedItems([]);

    const promises: Promise<any>[] = [];

    if (assignmentsToAdd.length > 0) {
      if (props.contactAssignments.type === ProjectAssignmentType.TRANSACTION_PARTY) {
        promises.push(projectAssignmentsService.addTransactionParty(
          props.projectId,
          {
            personId: props.contactAssignments.personId,
            rolesIds: assignmentsToAdd.map((v) => v.value) as number[],
          },
        ));
      } else {
        promises.push(projectAssignmentsService.addTaskAssignee(
          props.projectId,
          {
            personId: props.contactAssignments.personId,
            rolesIds: assignmentsToAdd.map((v) => v.value) as number[],
          },
        ));
      }

    }

    if (assignmentToRemove.length > 0) {
      assignmentToRemove.forEach((assignment) => {
        promises.push(
          projectAssignmentsService.deleteAssignment(
            props.projectId,
            assignment.id,
          ),
        );
      });
    }

    await Promise.all(promises);

    eventsPublisher.publish(PROJECT_ASSIGNMENTS_CHANGED, {
      projectId: props.projectId,
    });

    setLoading(false);
  }

  let components = {};

  if (!isGranted(ProjectPermission.CREATE_TASK_ASSIGNEE)
    && props.contactAssignments.type === ProjectAssignmentType.TASK_ASSIGNMENT) {
    components = {
      Input: () => null,
      DropdownIndicator: () => null,
    };
  }

  const selectStyles: StylesConfig<Option, true> = {
    multiValue: (base, state) => {
      return state.data.isFixed ? {
        ...base,
        backgroundColor: 'gray',
      } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? {
          ...base,
          paddingRight: 6,
        }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? {
        ...base,
        display: 'none',
      } : base;
    },
  };

  return (
    <Select<Option, true>
      name={`contact-${props.contactAssignments.personId}-roles`}
      className={`multiSelectDropdown ${loading ? 'loading' : ''}`}
      classNamePrefix="multiSelectDropdown"
      options={options}
      isClearable={false}
      value={currentValue}
      placeholder="Choose assigned roles"
      isMulti
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      styles={selectStyles}
      onChange={(newValue: any, event: ActionMeta<Option>) => {
        const option = event.option || event.removedValue;

        if (option) {
          if (event.action === 'select-option') {
            addAssignment(option.value as number);
          } else if (event.action === 'deselect-option') {
            removeAssignment(option.value as number);
          } else if (event.action === 'remove-value') {
            removeAssignmentAndApply(option.value as number);
          }
        }

      }}
      onMenuClose={() => {
        applyChanges();
      }}
      components={{
        IndicatorSeparator: () => null,
        ...components,
      }}
    />
  );
}
