import React from 'react';
import StepProgressBar, { Step } from '../StepProgressBar';
import useLaunchFromContractNavigator from './launchFromContractNavigator';
import { useProjectContext } from '../../view/ProjectContext';

export enum LaunchFromContractStepId {
  VERIFY_DATA = 1,
  GROUPS = 3,
  KEY_DATES = 4,
  ALL_TASKS = 5,
  TRANSACTION_PARTIES = 6,
}

export interface LaunchFromContractStepsProps {
  currentStep: LaunchFromContractStepId;
}

export default function LaunchFromContractSteps(props: LaunchFromContractStepsProps) {
  const launchFromContractNavigator = useLaunchFromContractNavigator();

  const projectContext = useProjectContext();

  const disabled = !projectContext.data?.isMinimumDataProvided;

  const steps: Step[] = [
    {
      id: LaunchFromContractStepId.VERIFY_DATA,
      name: 'Verify Contract Data',
      onClick: () => launchFromContractNavigator.openVerifyContractDataPage(),
    },
    {
      id: LaunchFromContractStepId.GROUPS,
      name: 'Select Groups',
      onClick: () => {
        launchFromContractNavigator.openSelectGroupsPage();
      },
      disabled,
    },
    {
      id: LaunchFromContractStepId.KEY_DATES,
      name: 'Key Dates',
      onClick: () => {
        launchFromContractNavigator.openKeyDatesPage();
      },
      disabled
    },
    {
      id: LaunchFromContractStepId.ALL_TASKS,
      name: 'All Tasks',
      onClick: () => {
        launchFromContractNavigator.openAllTasksPage();
      },
      disabled,
    },
    {
      id: LaunchFromContractStepId.TRANSACTION_PARTIES,
      name: 'Transaction Parties',
      onClick: () => {
        launchFromContractNavigator.openTransactionPartiesPage();
      },
      disabled,
    },
  ];

  return (
    <StepProgressBar steps={steps} currentStep={props.currentStep} />
  );
}
