import React from 'react';
import ProjectNotesList from './ProjectNotesList';

interface Props {
  projectId: number
}

export default function ProjectNotes(props: Props) {
  return (
    <ProjectNotesList projectId={props.projectId} />
  );
}
