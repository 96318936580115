import { ProjectData } from 'nekst-api';

export default function useTextHelper() {

  const isProjectHistoryPage = () => window.location.pathname.includes('/transactions');

  const addProjectName = (
    text: string,
    project: ProjectData,
  ) => {
    if (isProjectHistoryPage()) {
      return text;
    } else {
      return `${text} (${project.name})`;
    }
  };

  return {
    addProjectName,
    isProjectHistoryPage,
  };
}
