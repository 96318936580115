import React, { ReactElement, useState } from 'react';
import {
  ShowContext,
  OrderingConfiguration,
  useListContext,
  OrderDirection,
} from 'ui-builder';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import LoadingAnimation from '../../LoadingAnimation';

import styles from './GridLayout.module.scss';
import { OrderedListWrapper, useOrderingContext } from '../../../plans/tasks/List/OrderedList';
import { OrderingWrapper } from '../../../plans/tasks/List/RowRepeaterWithSort';
import { Option } from '../form/input';
import FormTemplate, { AfterSubmitFunc } from '../form/FormTemplate';
import CheckboxGroup from '../form/input/CheckboxGroup';
import useDataLoader from '../form/dataLoader';
import Right from '../../web/Right';
import IconButton, { IconType } from '../../widgets/IconButton';
import { usePopupFormManager } from 'features/nekst-widgets';

export enum GridLayoutTheme {
  LIGHT = 'light',
  STANDARD = 'standard',
}

export interface DynamicColumnsService {
  getValue: () => number[],
  setValue: (value: number[]) => Promise<void>,
  getOptionsFunc: () => Promise<Option<number>[]>,
  getLabel: (value: number) => string,
  getCell: (value: | number) => ReactElement,
  getSortKey: (value: number) => string | undefined,
  getTitle: () => string,
  isUpdateAllowed: () => boolean,
}

interface GridLayoutProps {
  children: ReactElement | (ReactElement | boolean)[];
  weights?: number[];
  theme?: GridLayoutTheme,
  noDataMessage?: ReactElement | string,
  title?: string | ReactElement,
  dynamicColumns?: DynamicColumnsService,
  className?: string,
}

function GridRow<T extends { id: number, [key: string]: any }>(props: {
  rowData: T,
  index: number,
  cells: ReactElement[],
  getWidthFunc: (i: number) => string | undefined,
  dynamicColumns?: DynamicColumnsService,
}) {
  const ordering = useOrderingContext();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: props.rowData.id,
    disabled: !ordering?.isEnabled,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const isDraggable = ordering?.isItemDraggable || (() => true);

  let draggableCell = null;

  if (ordering?.isEnabled) {
    if (isDraggable(props.rowData)) {
      draggableCell = (
        <td className={styles.draggableElement} {...attributes} {...listeners}>
          &nbsp;
        </td>
      );
    } else {
      draggableCell = (<td />);
    }
  }

  const rowData = props.rowData as { id: number, [key: string]: any };
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ShowContext data={props.rowData} key={`row-${rowData.id}`}>
      <tr
        className={`${styles.row} ${props.index % 2 === 1 ? 'even' : ''}`}
        ref={setNodeRef}
        style={style}
      >
        {draggableCell}
        {props.cells.map((child, i) => {
          const noWrap = child.props.isNoWrap || false;

          return (
            <td
              key={`cell-${rowData.id}-${child.props.label}`}
              className={styles.cell}
              style={{
                width: props.getWidthFunc(i),
                maxWidth: props.getWidthFunc(i),
              }}
              data-key={child.props.dataKey}
            >
              <div className={[styles.cellInner, noWrap ? styles.nowrap : ''].join(' ')}>
                {child}
              </div>
            </td>
          );
        })}
        {props.dynamicColumns?.getValue()
          .map((item, index) => (
            <td
              className={styles.cell}
              key={`dynamic-cell-${item}`}
              style={{
                width: props.getWidthFunc(props.cells.length + index),
                maxWidth: props.getWidthFunc(props.cells.length + index),
              }}
            >
              <div className={styles.cellInner}>
                {props.dynamicColumns?.getCell(item)}
              </div>
            </td>
          ))}
      </tr>
    </ShowContext>
  );
}

function GridGroup<T extends { id: number, [key: string]: any }>(props: {
  items: T[],
  cells: ReactElement[],
  getWidthFunc: (i: number) => string | undefined,
  dynamicColumns?: DynamicColumnsService,
}) {
  return (
    <OrderingWrapper items={props.items}>
      <tbody>
        {props.items?.map((rowData, index) => {
          return (
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            <GridRow
              rowData={rowData}
              index={index}
              cells={props.cells}
              getWidthFunc={props.getWidthFunc}
              dynamicColumns={props.dynamicColumns}
              key={`key-${rowData.id}-${index}`}
            />
          );
        })}
      </tbody>
    </OrderingWrapper>

  );
}

function EditGridColumnsForm(
  props: {
    getValue: () => number[],
    setValue: (value: number[]) => Promise<void>,
    getOptionsFunc: () => Promise<Option<number>[]>,
    getTitle: () => string,
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {
  const [options, setOptions] = useState<Option<number>[]>();

  useDataLoader(
    props.getOptionsFunc,
    setOptions,
  );

  return (
    <FormTemplate<{ ids: number[] }>
      title={props.getTitle()}
      getDataFunc={async () => ({
        ids: props.getValue(),
      })}
      submitFormFunc={async (data) => {
        await props.setValue(data.ids);

        return data;
      }}
      afterSubmitFunc={props.afterSubmitFunc}
    >
      {options && (<CheckboxGroup options={options} source="ids" hideLabel />)}
      {!options && (<LoadingAnimation />)}
    </FormTemplate>
  );
}

function EditGridColumnsButton(
  props: {
    getValue: () => number[],
    setValue: (value: number[]) => Promise<void>,
    getOptionsFunc: () => Promise<Option<number>[]>,
    getTitle: () => string,
  },
) {
  const popupManager = usePopupFormManager();

  return (
    <IconButton
      className={styles.editColumnsButton}
      type={IconType.GEAR}
      title="Update Columns"
      onClick={() => {
        popupManager.openForm(
          <EditGridColumnsForm
            getValue={props.getValue}
            setValue={props.setValue}
            getOptionsFunc={props.getOptionsFunc}
            getTitle={props.getTitle}
          />,
        );
      }}
    />
  );
}

function SortColumnWrapper(
  props: {
    sortKey?: string,
    children: ReactElement,
  },
) {
  const listContext = useListContext();

  if (props.sortKey) {

    const isActive = listContext.orderByField === props.sortKey;

    const classes = [
      styles.sort,
      listContext.orderDirection === OrderDirection.DESC && isActive ? styles.desc : styles.asc,
      isActive ? styles.active : '',
    ];

    const onClick = () => {
      let direction;
      if (isActive) {
        if (listContext.orderDirection === OrderDirection.ASC) {
          direction = OrderDirection.DESC;
        } else {
          direction = OrderDirection.ASC;
        }
      } else {
        direction = OrderDirection.ASC;
      }

      listContext.sort!(props.sortKey!, direction);
    };

    return (
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div className={classes.join(' ')} onClick={onClick}>
        {props.children}
      </div>
    );
  } else {
    return props.children;
  }
}

export default function GridLayout<T extends { id: number, [key: string]: any }>(
  props: GridLayoutProps,
) {
  const children = React.Children.toArray(props.children) as ReactElement[];

  const listContext = useListContext<T>();

  const data = listContext.data;

  const ordering = useOrderingContext();

  const getLabel = (child: ReactElement) => {
    if (child.props.label) {
      return child.props.label;
    } else {
      return child.props.source;
    }
  };

  let totalWeight = 0;

  const weights = props.weights;
  if (weights) {
    if (props.dynamicColumns) {
      for (let i = 0; i < props.dynamicColumns.getValue().length; i += 1) {
        weights.push(1.5);
      }
    }
    totalWeight = weights.reduce((prev, current) => prev + current, 0);
  }

  const getWidth = (cellNumber: number) => {
    if (weights) {
      return `${(weights[cellNumber] / totalWeight) * 100}%`;
    } else {
      return undefined;
    }
  };

  const theme = props.theme || GridLayoutTheme.STANDARD;

  if (data?.length || !listContext.isLoading) {
    if (data?.length) {
      const getGroupFunc = ordering.getOrderGroupFunc || (() => 'default');

      const groups = data.reduce(
        (prev: Record<string, T[]>, current: T) => {
          const groupName = getGroupFunc(current);

          const result = {
            ...prev,
          };

          if (!result[groupName]) {
            result[groupName] = [];
          }

          result[groupName].push(current);

          return result;
        },
        {},
      );
      return (
        <>

          {props.dynamicColumns && props.dynamicColumns.isUpdateAllowed() && (
            <Right>
              <EditGridColumnsButton
                getValue={props.dynamicColumns.getValue}
                setValue={props.dynamicColumns.setValue}
                getTitle={props.dynamicColumns.getTitle}
                getOptionsFunc={props.dynamicColumns.getOptionsFunc}
              />
            </Right>
          )}
          {props.title}
          <div className={`${styles.gridWrapper} ${props.className || ''}`}>
            <table
              className={`${listContext?.isLoading ? 'loading' : ''} ${styles.grid} ${styles[theme]}`}
              cellPadding={0}
              cellSpacing={0}
            >
              <thead className={`${styles.header}`}>
                <tr className={styles.row}>
                  {ordering?.isEnabled && (<th className={`${styles.cell} ${styles.small}`}>&nbsp;</th>)}
                  {children.map((child) => (
                    <th
                      key={`header-${getLabel(child)}`}
                      className={styles.cell}
                      data-key={child.props.dataKey}
                      data-test={`${child.props.source}-cell`}
                    >
                      <SortColumnWrapper sortKey={child.props.sortName}>
                        <span className={styles.cellInner}>{getLabel(child)}</span>
                      </SortColumnWrapper>
                    </th>
                  ))}
                  {props.dynamicColumns?.getValue()
                    .map((item) => (
                      <th
                        key={`header-${item}`}
                        className={styles.cell}
                        data-test={`header-${item}`}
                      >
                        <SortColumnWrapper sortKey={props.dynamicColumns!.getSortKey(item)}>
                          <span
                            className={styles.cellInner}
                          >
                            {props.dynamicColumns?.getLabel(item)}
                          </span>
                        </SortColumnWrapper>
                      </th>
                    ))}
                </tr>
              </thead>
              {Object.entries(groups)
                .map(([groupName, items]) => {
                  return (
                    <GridGroup
                      items={items}
                      cells={children}
                      getWidthFunc={getWidth}
                      key={`group-${groupName}`}
                      dynamicColumns={props.dynamicColumns}
                    />
                  );
                })}
            </table>
          </div>
        </>
      );
    } else {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{props.noDataMessage}</>;
    }
  } else {
    return (<LoadingAnimation />);
  }
}

interface GridLayoutWithSortProps<T = { id: number, [key: string]: any }> extends GridLayoutProps {
  orderingConfiguration: OrderingConfiguration<T>;
}

export function GridLayoutWithSort<T = { id: number, [key: string]: any }>(
  props: GridLayoutWithSortProps<T>,
) {
  return (
    <OrderedListWrapper<T>
      orderingConfig={props.orderingConfiguration}
    >
      <GridLayout {...props}>
        {props.children}
      </GridLayout>
    </OrderedListWrapper>
  );
}
