import React, { ReactElement } from 'react';
import { Limit } from 'ui-builder';
import { TasksFilter } from 'nekst-api';
import useProjectTasksService from '../projectTasksService';
import TasksList, { ViewOption } from './TasksList';

interface Props {
  projectId: number;
  view: ViewOption,
  title: string | ReactElement
}

export default function ProjectTasksList(props: Props) {
  const tasksService = useProjectTasksService();

  const getDataFunc = async (filter: TasksFilter, limit: Limit) => {
    return tasksService.getAllTasks(
      props.projectId,
      filter,
      limit,
    );
  };

  return (
    <TasksList
      // @ts-ignore
      getDataFunc={getDataFunc}
      view={props.view}
      title={props.title}
      showStatusFilter
    />
  );
}
