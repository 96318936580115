import React from 'react';
import useDocumentLabelsService, { DocumentLabel } from './documentLabelsService';
import IconButton, { IconType } from '../../../shared/widgets/IconButton';
import {  usePopupManager, useShowContext } from 'ui-builder';
import { ConfirmDeletePopup } from 'features/nekst-widgets';

function ConfirmDeleteLabelPopup(props: {
  data: DocumentLabel,
}) {
  const service = useDocumentLabelsService();
  return (
    <ConfirmDeletePopup
      deleteFunc={() => service.deleteLabel(props.data.id)}
      message={
        `Are you sure you want to delete label "${props.data.name}"? It will be deleted from all transactions and templates`
      }
    />
  );
}

export default function DeleteLabelButton() {

  const showContext = useShowContext<DocumentLabel>();
  const popupManager = usePopupManager();
  return (
    <IconButton
      type={IconType.TRASH_CAN}
      onClick={() => popupManager.openPopup(<ConfirmDeleteLabelPopup data={showContext.data!} />)}
      title="Delete Label"
    />
  );
}
