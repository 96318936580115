import React, {
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import { PopupContext } from '../Popup';

type FilterContextData<DataType> = {
  filterValue: DataType | undefined,
  setFilterValue: (value: DataType) => void,
  initialValue?: DataType,
};

const FilterReactContext = React.createContext<FilterContextData<Record<string, any>> | null>(null);

export function useFilterContext<FilterType>() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return useContext<FilterContextData<FilterType>>(FilterReactContext);
}

export function FilterContext<FilterType extends Record<string, any>>(props: {
  initialValue?: FilterType,
  children: ReactNode,
}) {
  const [filterValue, setFilterValue] = useState(props.initialValue || {});

  const contextValue = useMemo(() => ({
    filterValue,
    setFilterValue: (newFilterValue: FilterType) => {
      setFilterValue(newFilterValue);
    },
    initialValue: props.initialValue,
  }), [JSON.stringify(filterValue)]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <FilterReactContext.Provider value={contextValue}>
      <PopupContext>
        {props.children}
      </PopupContext>
    </FilterReactContext.Provider>
  );
}
