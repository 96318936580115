import React, { ReactElement } from 'react';

import { Route } from 'react-router-dom';
import { ApplicationModule } from '../shared/applicationModule';
import RolesSettingsPage from './roles/RolesSettingsPage';
import SettingsPagesWrapper from './SettingsPagesWrapper';
import UpdateProfilePage from './profile/UpdateProfilePage';
import UpdatePasswordPage from './password/UpdatePasswordPage';
import BillingPage from './billing/BillingPage';
import { Breadcrumb, BreadcrumbsWrapper } from '../shared/web/layout/Breadcrumbs';
import ProjectDetailsFieldsList from './projectdetails/List/ProjectDetailsFieldsList';
import ClientPortalDomainSettingsPage from './clientportal/ClientPortalDomainSettingsPage';
import ServiceProvidersSettingsPage from './serviceproviders/ServiceProvidersSettingsPage';
import ImpersonateUsersPage from './administration/impersonateusers/ImpersonateUsersPage';
import DocumentLabelsPage from './documentstorage/labels/DocumentLabelsPage';
import DocumentsTemplatesPage from './documentstorage/templates/DocumentsTemplatesPage';
import AiTrainerPage from './administration/aitrainer/AiTrainerPage';
import CreateTrainingEntryPage from './administration/aitrainer/Create/CreateTrainingEntryPage';
import UpdateTrainingEntryPage from './administration/aitrainer/Update/UpdateTrainingEntryPage';
import PortalConfigurationPage from './clientportal/PortalConfigurationPage';
import NotificationsSettingsPage from './notifications/NotificationsSettingsPage';
import TeamMembersPage from './teammembers/TeamMembersPage';
import UpdateTeamMemberPage from './teammembers/UpdateTeamMemberPage';
import TeamMemberHistoryPage from './teammembers/history/TeamMemberHistoryPage';
import TasksGroupsList from './tasksgroups/TasksGroupsList';
import DetailsFieldsSetPage from './projectdetails/Sets/List/DetailsFieldsSetPage';
import DetailsFieldsPageWrapper from './projectdetails/shared/DetailsFieldsPageWrapper';

export function SettingsBreadcrumbs(
  props: {
    children?: ReactElement,
  },
) {
  return (
    <BreadcrumbsWrapper>
      <Breadcrumb text="Settings" href="/settings/profile">
        {props.children}
      </Breadcrumb>
    </BreadcrumbsWrapper>
  );
}

export default function useSettingsModule(): ApplicationModule {
  const getRouter = () => {
    return (
      <>
        <Route path="/settings" element={<SettingsPagesWrapper title="Settings" />}>
          <Route
            path="/settings/roles"
            element={<RolesSettingsPage />}
          />
          <Route
            path="/settings/profile"
            element={<UpdateProfilePage />}
          />
          <Route
            path="/settings/password"
            element={<UpdatePasswordPage />}
          />
          <Route
            path="/settings/billing"
            element={<BillingPage />}
          />
          <Route
            path="/settings/detailboxes/:projectType"
            element={<DetailsFieldsPageWrapper />}
          >
            <Route path="" element={<ProjectDetailsFieldsList />} />
            <Route path="/settings/detailboxes/:projectType/:setId" element={<DetailsFieldsSetPage />} />
          </Route>
          <Route path="/settings/clientportal" element={<ClientPortalDomainSettingsPage />} />
          <Route path="/settings/clientportal/:projectType/default" element={<PortalConfigurationPage />} />
          <Route path="/settings/serviceproviders" element={<ServiceProvidersSettingsPage />} />
          <Route path="/settings/documentlabels" element={<DocumentLabelsPage />} />
          <Route path="/settings/documentstemplates" element={<DocumentsTemplatesPage />} />
          <Route path="/settings/notifications" element={<NotificationsSettingsPage />} />
          <Route path="/settings/members" element={<TeamMembersPage />} />
          <Route path="/settings/tasksgroups" element={<TasksGroupsList />} />
        </Route>
        <Route path="/settings/members/:id" element={<UpdateTeamMemberPage />} />
        <Route path="/settings/members/:id/history" element={<TeamMemberHistoryPage />} />
        <Route path="/admin" element={<SettingsPagesWrapper title="Admin Panel" hideSettingsMenu />}>
          <Route path="/admin/aitrainer" element={<AiTrainerPage />} />
          <Route path="/admin/aitrainer/create" element={<CreateTrainingEntryPage />} />
          <Route path="/admin/aitrainer/entries/:id" element={<UpdateTrainingEntryPage />} />
          <Route path="/admin/users" element={<ImpersonateUsersPage />} />
        </Route>
      </>

    );
  };

  return {
    getRouter,
    name: 'settings',
  };
}
