import React, { useState } from 'react';
import {
  Project,
  ProjectDetailsField,
  ProjectType,
  useProjectDetailsFieldsService
} from 'nekst-api';
import { LoadingAnimation, usePopupManager, useShowContext } from 'ui-builder';
import useDataLoader from 'data-loader';
import IconButton, { IconType } from '../../../shared/widgets/IconButton';
import { ConfirmDeletePopup } from 'features/nekst-widgets';

function DeleteProjectDetailsFieldPopup(
  props: {
    data: ProjectDetailsField,
    projectType: ProjectType,
  },
) {
  const service = useProjectDetailsFieldsService();

  const [usages, setUsages] = useState<Project[]>();

  const { loading } = useDataLoader(
    () => service.getFieldUsages(props.data.id),
    setUsages,
  );

  const deleteFieldFunc = async () => {
    if (!loading) {
      await service.deleteField(props.projectType, props.data.id);
    }
  };

  let message;
  if (usages) {
    message = (
      <>
        <div>{`Are you sure you want to delete field "${props.data.name}"?`}</div>
        {usages?.length > 0 && (
          <div>
            The field is currently used in the following transactions:
            <ul>
              {usages!.map((usage) => {
                return (
                  <li key={`usage-${usage.id}`}>{usage.name}</li>
                );
              })}
            </ul>
          </div>
        )}
      </>
    );
  } else {
    message = <LoadingAnimation />;
  }

  return (
    <ConfirmDeletePopup
      deleteFunc={deleteFieldFunc}
      messageCentered={false}
      message={message}
    />
  );
}

export default function DeleteProjectDetailsFieldButton(props: {
  projectType: ProjectType,
}) {
  const popupManager = usePopupManager();
  const showContext = useShowContext<ProjectDetailsField>();

  const openPopup = () => {
    popupManager.openPopup(
      <DeleteProjectDetailsFieldPopup data={showContext.data!} projectType={props.projectType} />,
    );
  };

  return (
    <IconButton onClick={openPopup} type={IconType.TRASH_CAN} title="Delete Field" />
  );
}
