import React from 'react';
import SubscriptionPlansWidget from './SubscriptionPlansWidget';
import InnerBlock from '../../shared/web/layout/InnerBlock';
import OpenBillingPortalButton from './OpenBillingPortalButton';
import BillingPageContext from './BillingPageContext';
import BillingErrorMessage from './BillingErrorMessage';
import { SettingsBreadcrumbs } from '../settingsModule';
import { Breadcrumb } from '../../shared/web/layout/Breadcrumbs';
import { DeleteAccountButton } from 'features/tasks-forms';
import useFeatureToggleService from 'feature-toggle';
import ManageSubscriptionOptionsButton from './ManageSubscriptionOptionsButton';
import TeamMembersSeatsWidget from '../teammembers/seat/TeamMembersSeatsWidget';

function ManageSubscriptionButton() {
  const featureToggleService = useFeatureToggleService();


  if (featureToggleService.isTeamMembersManagementSeatsEnabled()) {
    return (<ManageSubscriptionOptionsButton />);
  } else {
    return (<OpenBillingPortalButton />);
  }
}

export default function BillingPage() {
  return (
    <>
      <SettingsBreadcrumbs>
        <Breadcrumb text="Billing" />
      </SettingsBreadcrumbs>
      <BillingPageContext>
        <InnerBlock title="Billing" headerRight={<ManageSubscriptionButton />}>
          <BillingErrorMessage />
          <SubscriptionPlansWidget />
          <TeamMembersSeatsWidget />
          <DeleteAccountButton />
        </InnerBlock>
      </BillingPageContext>
    </>
  );
}
