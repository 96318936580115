import {
  FormAttributes,
  AfterSubmitFunc,
  SubmitFormFunc,
  GetDataFunc,
  FormTemplate,
} from 'ui-builder';

export type {
  AfterSubmitFunc,
  FormAttributes,
  SubmitFormFunc,
  GetDataFunc,
};

export default FormTemplate;
