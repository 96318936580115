import React, { ReactElement } from 'react';

export interface TableDataGridRowProps {
  children: ReactElement[] | ReactElement;
}

function TableDataGridRow(props: TableDataGridRowProps) {
  return (
    <tr>
      {
        React.Children
          .toArray(props.children)
          .map((child) => {
            if (React.isValidElement(child)) {
              return (<td key={child.props.source}>{child}</td>);
            } else {
              return null;
            }
          })
      }
    </tr>
  );
}

export default TableDataGridRow;
