import React from 'react';

import { Route } from 'react-router-dom';
import List from './List';
import { ApplicationModule } from '../shared/applicationModule';

export default function usePlansModule(): ApplicationModule {
  const getRouter = () => {
    return (
      <>
        <Route path="/plans" element={<List />} />
        <Route path="/plans/:id" element={<List />} />
      </>
    );
  };

  return {
    getRouter,
    name: 'plans',
  };
}
