import React, { useState } from 'react';
import useProjectDocumentsService, { ProjectDocument } from './projectDocumentsService';
import IconButton, { IconType } from '../../shared/widgets/IconButton';
import { useShowContext } from '../../shared/uibuilder/show/ShowReactContext';

export default function ShareDocumentButton() {

  const [copied, setCopied] = useState(false);
  const projectDocumentService = useProjectDocumentsService();

  const showContext = useShowContext<ProjectDocument>();

  const setCopiedFunc = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  if (showContext.data!.fileId) {
    if (copied) {
      return (
        <span
          style={{
            color: '#53ac61',
            fontSize: '12px',
          }}
        >
          Link Copied
        </span>
      );
    } else {
      return (
        <IconButton
          onClick={async () => {
            const link = await projectDocumentService.getSharingUrl(
              showContext.data!.id,
            );

            navigator.clipboard.writeText(link);

            setCopiedFunc();
          }}
          type={IconType.SHARE}
          title="Share Document"
        />
      );
    }
  } else {
    return null;
  }
}
