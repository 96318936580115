import React from 'react';
import { Button, ButtonColor, ButtonSize, usePopupManager, useShowContext } from 'ui-builder';
import { Project } from 'nekst-api';
import DataLoader from '../../shared/DataLoader';
import useICalLinkService from './iCalLinkService';

import styles from './OpenICalCalendarButton.module.scss';
import TextWithCopyControl from '../../shared/widgets/TextWithCopyControl';
import { Popup, PopupText } from 'features/nekst-widgets';

function ICalLinkPopup(
  props: {
    getLinkFunc: () => Promise<string>
  },
) {
  return (
    <Popup
      title="Export to iCal"
      content={(
        <DataLoader getDataFunc={props.getLinkFunc}>
          {(link: string) => {
            return (
              <PopupText>
                <>
                  Please use the following address to access your calendar from other
                  applications.
                  You can copy and paste this into any calendar product that supports the iCal
                  format.
                  <br />
                  <br />
                  <br />
                  <div className={styles.links}>
                    <div className={styles.link}>
                      <b className={styles.label}>All Tasks:</b>
                      {' '}
                      <TextWithCopyControl>{link}</TextWithCopyControl>
                    </div>
                    <div className={styles.link}>
                      <b className={styles.label}>Key Dates Only:</b>
                      {' '}
                      <TextWithCopyControl>{`${link}?keydatesonly=1`}</TextWithCopyControl>
                    </div>
                  </div>
                </>
              </PopupText>
            );
          }}
        </DataLoader>
      )}
    />
  );
}

function BaseButton(
  props: {
    getLinkFunc: () => Promise<string>
  },
) {
  const popupManager = usePopupManager();

  return (
    <Button
      dataKey="export-for-ical-button"
      text="Export for iCal"
      color={ButtonColor.BLUE}
      size={ButtonSize.SMALL}
      customClass={styles.icalButton}
      onClick={async () => popupManager.openPopup(
        <ICalLinkPopup
          getLinkFunc={props.getLinkFunc}
        />,
      )}
    />
  );
}

export function OpenProjectTasksICalCalendarButton() {
  const icalLinkService = useICalLinkService();

  const showContext = useShowContext<Project>();

  const getLinkFunc = async () => {
    const result = await icalLinkService.getProjectICalFileLink(showContext.data!.id);

    return result.link;
  };

  return (
    <BaseButton getLinkFunc={getLinkFunc} />
  );
}

export default function OpenAllTasksICalCalendarButton() {

  const icalLinkService = useICalLinkService();

  const getLinkFunc = async () => {
    const result = await icalLinkService.getAllTasksICalFileLink();

    return result.link;
  };

  return (
    <BaseButton getLinkFunc={getLinkFunc} />
  );
}
