import { IconButton, IconType, Popup } from 'features/nekst-widgets';
import React, { ReactNode, useMemo } from 'react';
import { Button, usePopupManager } from 'ui-builder';

export function BaseWidget(
  props: {
    fileText: string,
    usedRows: number[],
    onClick?: (index: number) => void,
    isEditable: boolean,
    buttons?: ReactNode,
  }
) {

  const usedRowsMap = useMemo(() => {
    const map = {} as Record<string, boolean>;
    props.usedRows.forEach((row) => {
      map[row] = true;
    });
    return map;
  }, [JSON.stringify(props.usedRows)]);

  return (
    <Popup
      title="Document"
      isBig
      isLong
      buttons={props.buttons}
      content={(
        <div
          style={{ fontSize: 12 }}
        >
          {
            props.fileText
              .split('\n')
              .map((line, index) => {
                  return (
                    <div
                      key={`line-${index}`}
                      style={{
                        backgroundColor: usedRowsMap[index + 1] !== undefined ? 'yellow' : 'white',
                        cursor: usedRowsMap[index + 1] !== undefined || props.isEditable ? 'pointer' : 'default',
                      }}
                      onClick={() => props.onClick?.(index + 1)}
                    >
                      <pre style={{ margin: 0 }}>
                        {line}
                      </pre>
                    </div>
                  );
                }
              )
          }
        </div>
      )}
    />
  );
}

export function PreviewDocument(
  props: {
    fileText: string,
    usedRows: number[],
    getLineInfo: (row: number) => any,
  }
) {
  return (
    <BaseWidget
      fileText={props.fileText}
      usedRows={props.usedRows}
      onClick={(index: number) => {
        if (props.usedRows.includes(index)) {
          alert(JSON.stringify(props.getLineInfo(index)));
        }
      }}
      isEditable={false}
    />
  );
}

export function ViewContextWidget(props: {
  fileText: string,
  usedRows: number[],
}) {
  return (
    <BaseWidget
      fileText={props.fileText}
      usedRows={props.usedRows}
      isEditable={false}
    />
  );

}

export function EditContextWidget(props: {
  fileText: string,
  usedRows: number[],
  onContextUpdated: (context: number[]) => void,
}) {
  const [usedRows, setUsedRows] = React.useState<number[]>(props.usedRows);

  const popupManager = usePopupManager();

  return (
    <BaseWidget
      fileText={props.fileText}
      usedRows={usedRows}
      onClick={(rowNumber: number) => {
        if (usedRows.includes(rowNumber)) {
          setUsedRows(usedRows.filter((row) => row !== rowNumber));
        } else {
          setUsedRows([...usedRows, rowNumber]);
        }
      }}
      isEditable
      buttons={(
        <Button
          text="Save"
          onClick={() => {
            props.onContextUpdated(usedRows);
            popupManager.closePopup();
          }}
        />
      )}
    />
  );
}

export function EditContextButton(props: {
  fileText: string,
  usedRows: number[],
  onContextUpdated: (context: number[]) => void,
}) {
  const popupManager = usePopupManager();

  return (
    <IconButton
      onClick={() => {
        popupManager.openPopup(
          <EditContextWidget
            fileText={props.fileText}
            usedRows={props.usedRows}
            onContextUpdated={props.onContextUpdated}
          />
        );
      }}
      type={IconType.GEAR}
    />
  );
}
