import React from 'react';
import MassUpdateControlsLayout from '../../../shared/widgets/MassUpdate/MassUpdateControlsLayout';
import DeleteButton from './DeleteButton';
import DownloadButton from './DownloadButton';
import { useMassUpdateServiceRequired } from 'ui-builder';

export default function ProjectDocumentsMassUpdateControls() {

  const massUpdateService = useMassUpdateServiceRequired();

  if (massUpdateService.isEnabled()) {
    return (
      <MassUpdateControlsLayout>
        <DownloadButton />
        <DeleteButton />
      </MassUpdateControlsLayout>
    );
  } else {
    return null;
  }
}
