import { ConstraintValidator, ConstraintValue } from '../index';

export default function usePhoneNumberConstraintValidator(): ConstraintValidator<boolean> {
  const validate = (
    object: Record<string, any>,
    fieldName: string,
  ): boolean => {
    const value = object[fieldName];
    const type = typeof value;

    switch (type) {
      case 'undefined':
        return true;
      case 'object':
        return false;
      default:
        if (value.trim().length > 0) {
          const trimmed = value.trim();
          return trimmed
            .trim()
            .replaceAll(/[_\-\\(\\) \\+]/g, '').length === 10;
        } else {
          return false;
        }
    }
  };

  const getErrorMessage = (config: ConstraintValue) => {
    return (typeof config === 'object' && config.message) || 'Phone number is not valid';
  };

  return {
    getName: () => 'phoneNumber',
    getErrorMessage,
    validate,
  };
}
