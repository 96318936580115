import React from 'react';
import { useMentionOptionsService } from 'nekst-api';
import { BaseInputProps, SmartTagsTextAreaWithLoad } from 'ui-builder';
import { useProjectId } from '../ProjectIdContext';

export function TextAreaWithProjectMemberMention(
  props: BaseInputProps<string>
) {
  const mentionOptionsService = useMentionOptionsService();

  const projectId = useProjectId();

  return (
    <SmartTagsTextAreaWithLoad
      {...props}
      getTagsMethod={async () => {
        let result = [];
        if (projectId) {
          result = await mentionOptionsService.getMentionOptions(projectId);
        }


        return result;
      }}
      dependencies={[projectId]}
      source={props.source}
    />
  );
}
