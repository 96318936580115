import React from 'react';
import useProjectDocumentsService from '../projectDocumentsService';
import {
  Button,
  ButtonColor,
  ButtonSize,
  useMassUpdateServiceRequired,
  usePopupManager,
} from 'ui-builder';
import { ConfirmDeletePopup } from 'features/nekst-widgets';

function DeletePopup(
  props: {
    ids: number[],
  },
) {
  const service = useProjectDocumentsService();
  return (
    <ConfirmDeletePopup
      title="Mass Update: Delete Documents"
      message={`Are you sure you want to delete ${props.ids.length} documents?`}
      deleteFunc={async () => {
        await service.bulkDelete(props.ids);
      }}
    />
  );
}

export default function DeleteButton() {
  const popupManager = usePopupManager();

  const massUpdateService = useMassUpdateServiceRequired();

  const value = massUpdateService.getCheckedIds();

  if (massUpdateService.isEnabled()) {
    return (
      <Button
        text="Delete"
        size={ButtonSize.SMALL}
        isFilled
        isFlat
        isDisabled={value.length === 0}
        onClick={async () => popupManager.openPopup(<DeletePopup ids={value} />)}
        color={ButtonColor.RED}
      />
    );
  } else {
    return null;
  }
}
