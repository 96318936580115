import React from 'react';
import { useShowContext } from 'ui-builder';
import { EmailTask, SendingStatus, TaskType } from 'nekst-api';
import Layout from './Layout';

export function EmailField() {
  const showContext = useShowContext<EmailTask>();

  if (showContext.data) {
    /* eslint-disable prefer-destructuring */
    const type = showContext.data.type;

    if (type === TaskType.EMAIL) {

      const emailSendingStatus = showContext.data.emailSendingStatus;

      let title: string;

      if (emailSendingStatus === SendingStatus.SENT) {
        title = 'Email was sent';
      } else if (emailSendingStatus === SendingStatus.SENT_AND_OPENED) {
        title = 'Email was sent and read';
      } else if (emailSendingStatus === SendingStatus.ERROR_OCCURRED) {
        title = 'Error occurred. Please contact the administrator';
      } else {
        title = 'Email is not sent yet';
      }

      return (
        <Layout title={title} sendingStatus={emailSendingStatus} />
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
}
