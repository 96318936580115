import {
  Button,
  ButtonColor,
  ButtonSize, NewLine, SimpleBlock,
  useFormContext,
  usePopupManager,
  useShowContext,
  Text,
} from 'ui-builder';
import {
  SendingStatus,
  SmsTask,
  TaskPermission,
  TaskType,
  useSendSmsTaskService,
} from 'nekst-api';
import React from 'react';
import { Popup } from 'features/nekst-widgets';
import { SendSmsPreview } from './SendSmsPreview';
import { Spacings } from 'react-native-ui-lib';

function SendSmsPreviewPopup(props: {
  task: SmsTask,
  onSent?: () => void,
}) {

  const popupManager = usePopupManager();
  const sendSmsService = useSendSmsTaskService();
  const sendSms = async () => {
    const result = await sendSmsService.sendSms(
      props.task.id,
    );

    if (result.sendingStatus === SendingStatus.SENT) {
      props.onSent?.();
    }

    popupManager.closePopup();
  };

  const message = (
    <SimpleBlock
      style={{
        gap: Spacings.s4
      }}
    >
      <Text>Please review the sms message(s) preview below before sending:</Text>
      <NewLine />
      <SendSmsPreview task={props.task} />
    </SimpleBlock>
  );
  return (
    <Popup
      title="Send SMS"
      content={message}
      buttons={(
        <Button
          text="Send"
          color={ButtonColor.GREEN}
          size={ButtonSize.MEDIUM}
          onClick={sendSms}
        />
      )}
    />
  );
}

export function SendSmsButton(
  props: {
    className?: string
    addPreviewPrefix?: boolean,
  }
) {
  const popupManager = usePopupManager();

  const formContext = useFormContext<SmsTask>();
  const showContext = useShowContext<SmsTask>();

  const [isSent, setIsSent] = React.useState(false);

  const { data: formData } = formContext;

  const { data: showData } = showContext;

  const data = formData || showData;

  if (
    data?.id
    && data?.permissions.includes(TaskPermission.SEND_SMS)
    && data.type === TaskType.SMS
  ) {
    const isNotSent = (data! as SmsTask).sendingStatus === SendingStatus.NOT_SENT && !isSent;

    let buttonText;
    if (isNotSent) {
      buttonText = 'Send';
    } else {
      buttonText = 'Re-Send';
    }

    if (props.addPreviewPrefix) {
      buttonText = `Preview & ${buttonText}`;
    }

    return (
      <Button
        customClass={props.className}
        text={buttonText}
        color={isNotSent ? ButtonColor.GREEN : ButtonColor.YELLOW}
        isFilled
        size={ButtonSize.SMALL}
        onClick={async () => {
          if (formContext.submitForm) {
            const submitResult = await formContext.submitForm(false);
            if (!submitResult) {
              return;
            }
          }

          popupManager.openPopup(<SendSmsPreviewPopup task={data} onSent={() => setIsSent(true)} />, true);
        }}
      />
    );
  } else {
    return null;
  }
}
