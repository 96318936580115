import React from 'react';

import { get } from 'lodash';
import { useShowContext } from 'ui-builder';
import { ShortProfile } from 'nekst-api';
import { PersonWidget } from 'features/nekst-widgets';

export interface UserLogoFieldProps {
  source: string
}

export default function UserLogoField(props: UserLogoFieldProps) {
  const { data } = useShowContext();

  const profile = get(data, props.source) as ShortProfile;

  return (
    <PersonWidget profile={profile} />
  );
}
