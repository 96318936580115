import { useShowContextRequired } from 'ui-builder';
import Layout from './Layout';
import React from 'react';
import { get } from 'lodash';

export interface BaseTaskRowAssignmentWidgetProps {
  label?: string;
  source: string;
  filterFunc: (assignment: any, allAssignments: any[]) => boolean;
  getNameFunc: (assignment: any) => string;
  getImageFunc?: (assignment: any) => string | undefined;
}

export function BaseTaskRowAssignmentWidget(
  props: BaseTaskRowAssignmentWidgetProps
) {
  const { data } = useShowContextRequired();

  const value = get(data, props.source, []) as { id: number, [key: string]: any }[];

  const formatted = value
    .filter((v) => props.filterFunc(v, value))
    .map((item) => {
      return {
        id: item.id || JSON.stringify(item),
        name: props.getNameFunc(item),
        imageUrl: props.getImageFunc ? props.getImageFunc(item) : undefined,
      };
    });

  if (formatted.length > 0) {
    return (
      <Layout
        label={props.label}
        assignments={formatted}
      />
    );
  } else {
    return null;
  }
}
