import {
  AbstractTask,
  PROJECT_ASSIGNMENTS_CHANGED,
  TasksFilter,
  useAllTasksService
} from 'nekst-api';
import {
  FilterContext,
  Limit, ListWithPagination,
  MassUpdate,
  PageResponse,
  SelectAllUpdater,
  VisibilityStateClientSideFilter,
  ListDataRefresher, PopupContext,
} from 'ui-builder';
import {
  useAssigneeFilterService,
  useDefaultAssigneeFilterValue,
  useDueDateFilterService,
  useNameFilterService,
  useStatusFilterService,
  useTypeFilterService,
} from '../Filter';
import React, { ReactElement } from 'react';
import { ListDataUpdaterOnTasksEvents } from './ListDataUpdaterOnTasksEvents';
import { DueDateCounter } from './DueDateCounter';
import { ViewStyle } from 'react-native';
import { sortTasksIncludingDependentTasks } from './tasksListSortHelper';

interface AllTasksListProps {
  getDataFunc: (filter: TasksFilter, limit: Limit) => Promise<PageResponse<AbstractTask>>,
  byPage?: number,
  filterOverride?: Partial<TasksFilter>
  children: ReactElement,
  style?: ViewStyle,
}

export interface TaskListFilterWrapperProps {
  children: ReactElement | ReactElement[];
}

export function TaskListFilterWrapper(props: TaskListFilterWrapperProps) {
  return (
    <VisibilityStateClientSideFilter
      filters={[
        useNameFilterService(),
        useAssigneeFilterService(),
        useStatusFilterService(),
        useTypeFilterService(),
        useDueDateFilterService(),
      ]}
      parentIdField="parentTaskRelation.parentTaskId"
    >
      {props.children}
    </VisibilityStateClientSideFilter>
  );
}

function formatDate(date: Date) {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
}


export function AllTasksList(props: AllTasksListProps) {
  const tasksService = useAllTasksService();

  const getDataFunc = async (filter: TasksFilter, limit: Limit) => {
    const response = await props.getDataFunc(filter, limit);
    // if the tasks are not sorted by dates
    // it's needed to load the whole tree of dependent tasks
    const idsMap = response.data.reduce(
      (previousValue: Record<number, true>, current: AbstractTask) => {
        return {
          ...previousValue,
          [current.id]: true,
        } as Record<number, true>;
      },
      {},
    );

    const isAlreadyLoaded = (id: number) => idsMap[id];

    const rootTasksIds = response.data
      .filter(
        (item) => {
          const parentTaskId = item.parentTaskRelation?.parentTaskId;
          let rootTaskId;
          if (parentTaskId) {
            rootTaskId = item._rootTaskId;
          } else if (item._hasDependentTasks) {
            rootTaskId = item.id;
          }

          return !!rootTaskId;
        },
      )
      .map((item) => item._rootTaskId || item.id)
      .filter((value, index, array) => array.indexOf(value) === index) as number[];

    const data = [
      ...response.data,
    ];

    if (rootTasksIds.length > 0) {
      const additionalTasks = await tasksService.getTasks({
        rootTasksIds,
      }, {
        limit: 1000,
        offset: 0,
      });

      additionalTasks.data.forEach((item) => {
        if (!isAlreadyLoaded(item.id)) {
          data.push(item);
        }
      });
    }

    return {
      total: response.total,
      limit: response.limit,
      data: sortTasksIncludingDependentTasks(data),
    } as PageResponse<AbstractTask>;
  };

  const { defaultValue: assigneesDefaultValue } = useDefaultAssigneeFilterValue();

  return (
    <MassUpdate>
      <FilterContext<TasksFilter>
        initialValue={{
          showActive: true,
          showCompleted: false,
          assigneesIds: assigneesDefaultValue?.selected,
          dueDateFrom: formatDate(new Date()),
          dueDateTo: formatDate(new Date()),
          ...props.filterOverride,
        }}
      >
        <ListWithPagination<AbstractTask, TasksFilter>
          getDataFunc={getDataFunc}
          byPage={props.byPage}
        >
          <PopupContext>
            <TaskListFilterWrapper>
              <DueDateCounter>
                <ListDataRefresher eventName={PROJECT_ASSIGNMENTS_CHANGED} />
                <ListDataUpdaterOnTasksEvents />
                <SelectAllUpdater />
                {props.children}
              </DueDateCounter>
            </TaskListFilterWrapper>
          </PopupContext>
        </ListWithPagination>
      </FilterContext>
    </MassUpdate>
  );
}
