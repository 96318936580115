import React, { useEffect, useState } from 'react';
import { NOTIFICATIONS_READ_EVENT, useEventsSubscriber, useNotificationsService } from 'nekst-api';
import useDataLoader from 'data-loader';
import { isReactNative } from 'ui-builder';

export const NOTIFICATIONS_UPDATE_INTERVAL_TRIGGER_EVENT = 'NOTIFICATIONS_UPDATED_EVENT';

function useBroadcastUpdater(setUnreadCount: (count: number) => void) {
  const channel = React.useMemo(
    () => isReactNative() ? null :new BroadcastChannel('unreadNotifications'),
    []
  );

  useEffect(() => {
    channel?.addEventListener('message', (event) => {
      setUnreadCount(event.data.count);
    });

    return () => {
      if (channel) {
        channel.close();
      }
    };
  }, []);

  const broadcastUnreadCount = (count: number) => {
    channel?.postMessage({
      count,
    });
  }

  return {
    broadcastUnreadCount,
  }
}

export function useNotificationsBell() {

  const [unreadCount, setUnreadCount] = useState(0);

  const notificationsService = useNotificationsService();

  const [version, setVersion] = useState(0);

  const broadcaster = useBroadcastUpdater(setUnreadCount);


  useEventsSubscriber('NotificationBell', {
    [NOTIFICATIONS_READ_EVENT]: () => {
      setUnreadCount(0);

      broadcaster.broadcastUnreadCount(0);
    },
    [NOTIFICATIONS_UPDATE_INTERVAL_TRIGGER_EVENT]: () => {
      setVersion((prev) => prev + 1);
    },
  });

  useDataLoader(
    async () => {
      const result = await notificationsService.getNumberOfUnread();

      broadcaster.broadcastUnreadCount(result);

      return result;
    },
    setUnreadCount,
    version,
  );

  return {
    unreadCount,
  };
}
