import React from 'react';
import { useListContext } from './ListReactContext';

export default function TotalField() {
  const listContext = useListContext();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{listContext.total}</>
  );
}
