import {
  useProjectTasksService,
} from 'nekst-api';

export {
  TaskStatus,
  DueDateFilterValue,
  TaskPermission,
  CommentSeenStatus,
  EmailRecipientType,
  SendingStatus,
  PROJECT_TASKS_UPDATED,
  TASK_CREATED,
  TASK_DELETED,
  TASK_UPDATED,
} from 'nekst-api';

export type {
  ParentTaskRelation,
  TaskAssignment,
  DueDate,
  AbstractTask,
  StandardTask,
  EmailRecipient,
  EmailTask,
  SmsRecipient,
  SmsTask,
  TaskEvent,
  TaskDeletedEvent,
} from 'nekst-api';

export { useTasksResponseMapper } from 'nekst-api';

export default useProjectTasksService;
