import { useFrontendLoggerService } from 'nekst-api';
import React, { useEffect } from 'react';
import { Popup } from '../Popup';
import { PopupText } from '../PopupText';
import { usePopupManager } from 'ui-builder';

export function UnknownErrorPopup(props: { error?: Error }) {

  const frontEndErrorLogger = useFrontendLoggerService();

  useEffect(() => {
    if (props.error) {
      frontEndErrorLogger.logError(props.error);
    }
  }, []);

  return (
    <Popup
      title="Unknown Error"
      content={(
        <PopupText>
          Apologies for the inconvenience, but an unexpected error has occurred. Please reach out to
          our support team for assistance.
        </PopupText>
      )}
    />
  );
}

export function useErrorPopup() {

  const popupManager = usePopupManager();

  const openPopup = async (error?: Error) => {
    popupManager.openPopup(<UnknownErrorPopup error={error} />);
  };

  return {
    openPopup,
  };
}
