import React, { ReactElement } from 'react';
import {
  FilterContext,
  If,
  ListWithPagination,
  useListContext,
  MassUpdate,
  useMassUpdateServiceRequired, TextLink,
} from 'ui-builder';
import {
  usePeopleService,
  PeopleFilter,
  Person,
  TeamPermission,
  CONTACTS_MASS_DELETED, isPersonCanBeMassDeleted
} from 'nekst-api';

import MainBlock from '../../shared/web/layout/MainBlock';
import TextField from '../../shared/uibuilder/show/field/TextField';
import PaginationWrapper from '../../shared/uibuilder/list/PaginationWrapper';
import GridLayout from '../../shared/uibuilder/list/GridLayout';
import Cell from '../../shared/uibuilder/list/Cell';
import PersonNameFilter from './PersonNameFilter';
import PersonLinkField from './PersonLinkField';
import AssignedRolesField from './AssignedRolesField';
import CreateContactButton from '../Create/CreateContactButton';
import MostRecentPropertyField from './MostRecentPropertyField';
import PeopleStatisticWidget from './PeopleStatisticWidget';
import AccessChecker from '../../shared/authorization/AccessChecker';
import {
  Breadcrumb,
  BreadcrumbsWrapper,
} from '../../shared/web/layout/Breadcrumbs';
import PeopleFilterButton from './PeopleFilterButton';
import Right from '../../shared/web/Right';
import Left from '../../shared/web/Left';
import FilterWidgets from './FilterWidgets';
import SelectForMassUpdateCheckbox
  from '../../shared/widgets/MassUpdate/SelectForMassUpdateCheckbox';
import MassUpdateControls from './MassUpdate/MassUpdateControls';
import SelectAllUpdater from '../../shared/widgets/MassUpdate/SelectAllUpdater';
import { useEventsSubscriber } from 'event-bus';
import { InformationMessage, PhoneNumberWithExtensionField } from 'features/nekst-widgets';
import { MassUpdateCheckbox } from 'features/tasks-list-feature';
import useFeatureToggleService, { TeamMembersManagementToggleWrapper } from 'feature-toggle';
import useSettingsNavigator from '../../settings/settingsNavigator';
import OnboardingWidgetWrapper from '../../shared/onboarding/OnboardingWidgetWrapper';

import styles from './PeopleListPage.module.scss';
import PageHeaderWithIcon, {
  PageIconType
} from '../../shared/widgets/PageTitle/PageHeaderWithIcon';

function ListDataUpdaterOnContactsEvents() {
  const listContext = useListContext<Person>();

  async function onPeopleMassDelete(ids: number[]) {
    const listData = listContext.data!;

    const idsMap = ids.reduce((prev: Record<number, boolean>, currentValue: number) => {
      return {
        ...prev,
        [currentValue]: true,
      };
    }, {});

    const newData = listData.filter((item) => !idsMap[item.id]);

    listContext._setData!(newData);
  }

  useEventsSubscriber(
    'PeopleList',
    {
      [CONTACTS_MASS_DELETED]: (event) => onPeopleMassDelete(event.ids as number[]),
    },
    [listContext.version],
  );
  return null;
}

export function PeopleBreadcrumbs(
  props: {
    children?: ReactElement,
  },
) {
  return (
    <BreadcrumbsWrapper>
      <Breadcrumb text="Contacts" href="/people">
        {props.children}
      </Breadcrumb>
    </BreadcrumbsWrapper>
  );
}

function PeopleMassUpdateCheckbox() {
  const listContext = useListContext<Person>();

  const hasContacts = listContext.data!.find((person) => isPersonCanBeMassDeleted(person));

  if (hasContacts) {
    return <MassUpdateCheckbox />;
  } else {
    return null;
  }
}

function PeopleList() {
  const massUpdateService = useMassUpdateServiceRequired();

  const peopleService = usePeopleService();

  const weights = massUpdateService.isEnabled()
    ? [1, 2, 3, 2, 1.5, 2, 2.5]
    : [2, 3, 2, 1.5, 2, 2.5];

  const featureToggleService = useFeatureToggleService();

  return (
    <ListWithPagination<Person, PeopleFilter>
      getDataFunc={(filter, limit, orderBy) => {
        const appliedFilter = {
          ...filter,
          excludeTeamMembers: featureToggleService.isTeamMembersManagementEnabled(),
        };

        return peopleService.getPeople(appliedFilter, limit, orderBy);
      }}
      byPage={50}
    >
      <PeopleMassUpdateCheckbox />
      <MassUpdateControls />
      <SelectAllUpdater />
      <ListDataUpdaterOnContactsEvents />
      <PaginationWrapper>
        <GridLayout weights={weights} className={styles.list}>
          {massUpdateService.isEnabled() && (
            <If<Person> conditionFunc={(data) => isPersonCanBeMassDeleted(data)}>
              <SelectForMassUpdateCheckbox />
            </If>
          )}
          <Cell label="Name" sortName="name">
            <PersonLinkField />
          </Cell>
          <Cell label="Email" sortName="primaryEmail">
            <TextField source="profile.primaryEmail" />
          </Cell>
          <Cell label="Phone">
            <PhoneNumberWithExtensionField
              source="profile.primaryPhoneNumber.number"
              extensionSource="profile.primaryPhoneNumber.extension"
            />
          </Cell>
          <Cell label="Company" sortName="companyName">
            <TextField source="profile.companyName" />
          </Cell>
          <Cell label="Role(s)">
            <AssignedRolesField />
          </Cell>
          <Cell label="Most Recent Transaction">
            <MostRecentPropertyField />
          </Cell>
        </GridLayout>
      </PaginationWrapper>
    </ListWithPagination>
  );
}

function TeamMembersFeatureInfoMessage() {
  const settingsNavigator = useSettingsNavigator();

  const TEAM_MEMBERS_MANAGEMENT_INFO_TOUR_ID = 'TeamMembersManagement';

  return (
    <AccessChecker permissionToCheck={TeamPermission.ACCESS_TEAM_MEMBERS_MANAGEMENT} show403Error={false}>
      <TeamMembersManagementToggleWrapper>
        <OnboardingWidgetWrapper tourId={TEAM_MEMBERS_MANAGEMENT_INFO_TOUR_ID}>
          <InformationMessage>
            Team Members management is now available in the new
            {' '}
            <TextLink
              href={settingsNavigator.getMembersManagementLink()}
              onClick={() => settingsNavigator.openMembersManagementPage()}
            >
              Team Members section
            </TextLink>
            .
          </InformationMessage>
        </OnboardingWidgetWrapper>
      </TeamMembersManagementToggleWrapper>
    </AccessChecker>
  );
}

export default function PeopleListPage() {
  return (
    <AccessChecker permissionToCheck={TeamPermission.READ_CONTACTS}>
      <PeopleBreadcrumbs />
      <FilterContext<PeopleFilter>
        initialValue={{
          showServiceProvidersOnly: false,
          showTeamMembersOnly: false,
          serviceProvidersGroupsIds: [],
        }}
      >
        <MassUpdate>
          <MainBlock
            title={
              <PageHeaderWithIcon iconType={PageIconType.CONTACTS}>
                Contacts
              </PageHeaderWithIcon>
            }
            headerRight={(
              <>
                <PersonNameFilter />
                <CreateContactButton />
              </>
            )}
          >
            <TeamMembersFeatureInfoMessage />
            <Left>
              <FilterWidgets />
            </Left>
            <Right>
              <PeopleFilterButton />
            </Right>
            <PeopleStatisticWidget />
            <PeopleList />
          </MainBlock>
        </MassUpdate>
      </FilterContext>
    </AccessChecker>
  );
}
