import React from 'react';
import { BlockProps } from './blocksTypes';
import { useNewUiTheme } from '../../index';

export function SimpleBlock(props: BlockProps) {
  const uiTheme = useNewUiTheme();

  return (
    <uiTheme.common.SimpleBlockLayout
      style={props.style}
      className={props.className}
      testID={props.testID}
      onClick={props.onClick}
      title={props.title}
      backgroundColor={props.backgroundColor}
    >
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      <>
        {props.children}
      </>
    </uiTheme.common.SimpleBlockLayout>
  );
}
