import React from 'react';
import { AvatarLayoutProps } from 'ui-builder';
import { ImageWidget } from 'features/nekst-widgets';

export default function AvatarLayout(
  props: AvatarLayoutProps,
) {
  if (props.imageUrl) {
    return (
      <ImageWidget
        src={props.imageUrl}
        className={props.className}
        alt={props.name}
      />
    );
  } else {
    return (
      <div
        className={props.className}
        title={props.name}
      >
        {props.acronym}
      </div>
    );
  }
}
