import React from 'react';
import { ErrorsWrapperLayoutProps } from 'ui-builder';

export default function ErrorsWrapperLayout(props: ErrorsWrapperLayoutProps) {
  const errors = props.errors;
  return (
    <>
      {props.children}
      {errors && errors.length > 0 && (
        <div className={`errors ${props.name}-errors`}>
          {
            errors.map((error) => (
              <div className="error" key={error}>
                {error}
              </div>
            ))
          }
        </div>
      )}
    </>
  );
}
