import React, { ReactNode } from 'react';
import { BaseInputLayoutProps, BaseInputProps, Option } from './types';
import { useInputHelper } from './inputHelper';
import { useNewUiTheme } from '../../newUiTheme';

export interface CheckboxGroupProps<DataType = number> extends BaseInputProps<DataType[]> {
  options: Option<DataType>[];
  // eslint-disable-next-line react/no-unused-prop-types
  itemClassName?: string;
  weights?: number[],
  noOptionsMessage?: ReactNode,
  optionsContainerClassName?: string,
}

export interface CheckboxGroupLayoutProps<DataType extends string | number> extends BaseInputLayoutProps<DataType[]> {
  options: Option<DataType>[]
  isSelectedFunc: (value: DataType) => boolean,
  selectFunc: (value: DataType) => void
  unselectFunc: (value: DataType) => void
  weights: number[],
  noOptionsMessage?: ReactNode,
  optionsContainerClassName?: string,
}

export function CheckboxGroup<DataType = number>(props: CheckboxGroupProps<DataType>) {
  const helper = useInputHelper<DataType[]>(props);

  const weights = props.weights || [12];

  const getValue = () => {
    return helper.getValue() || [];
  };

  const addSelection = (id: DataType) => {
    const newValue = [
      ...getValue(),
    ];

    const index = newValue.indexOf(id);

    if (index === -1) {
      newValue.push(id);
    }

    helper.getOnChangeCallback()({
      target: {
        value: newValue,
      },
    });
  };

  const removeSelection = (id: DataType) => {
    const newValue = [
      ...getValue(),
    ];
    const index = newValue.indexOf(id);

    if (index !== -1) {
      newValue.splice(index, 1);
    }

    helper.getOnChangeCallback()({
      target: {
        value: newValue,
      },
    });
  };

  const isSelected = (id: DataType) => {
    return getValue()
      .includes(id);
  };

  const uiTheme = useNewUiTheme();

  return (
    <uiTheme.inputs.CheckboxGroupLayout
      options={props.options}
      isSelectedFunc={isSelected}
      selectFunc={addSelection}
      unselectFunc={removeSelection}
      weights={weights}
      noOptionsMessage={props.noOptionsMessage}
      optionsContainerClassName={props.optionsContainerClassName}
      {...helper.getBaseInputLayoutProps()}
    />
  );
}
