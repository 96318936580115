import React, { useEffect, useState } from 'react';
import  {
  useProjectDetailsService,
  ProjectDetailsFieldType,
  TASK_CREATED,
  TASK_DELETED,
  TASK_UPDATED,
  useEventsSubscriber,
  TaskType,
} from 'nekst-api';
import DetailBoxesDatesWidgetLayout, {
  DetailBoxesDatesWidgetItem,
} from '../../../../shared/widgets/DetailBoxesDates/DetailBoxesDatesWidgetLayout';
import useTasksNavigator from '../tasksNavigator';

export default function DetailFieldsLinksWidget(props: {
  projectId: number,
}) {
  const projectDetailsService = useProjectDetailsService();

  const tasksNavigator = useTasksNavigator();

  const [fields, setFields] = useState<DetailBoxesDatesWidgetItem[]>();

  const [loading, setLoading] = useState(false);

  async function loadFields() {
    setLoading(true);

    const result: DetailBoxesDatesWidgetItem[] = (await projectDetailsService.getProjectDetails(
      props.projectId,
    ))
      .filter((item) => item.setting.fieldType === ProjectDetailsFieldType.DATE)
      .filter((item) => !!item.linkedTaskId)
      .map((item) => ({
        id: item.setting.id,
        name: item.linkedTask!.name,
        linkedField: {
          id: item.setting.id,
          name: item.setting.name,
        },
        openEditTaskForm: () => {
          tasksNavigator.openTask(item.linkedTaskId!);
        },
      }));

    setFields(result);
    setLoading(false);
  }

  useEventsSubscriber(
    'DetailFieldsLinksWidget',
    {
      [TASK_CREATED]: (eventData) => {
        if (eventData.data.type === TaskType.STANDARD) {
          loadFields();
        }
      },
      [TASK_UPDATED]: (eventData) => {
        if (eventData.data.type === TaskType.STANDARD) {
          loadFields();
        }
      },
      [TASK_DELETED]: () => {
        loadFields();
      },
    },
  );

  useEffect(() => {
    loadFields();
  }, [props.projectId]);

  if (fields && fields.length > 0) {
    return (
      <DetailBoxesDatesWidgetLayout fields={fields} loading={loading} />
    );
  } else {
    return null;
  }
}
