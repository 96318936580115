import { RequestOptInButtonLayoutProps } from '../types';
import { Button, ButtonColor, ButtonSize } from 'ui-builder';
import styles from './RequestOptInButton.module.scss';
import React from 'react';

export default function RequestOptInButtonLayoutWeb(
  props: RequestOptInButtonLayoutProps,
) {

  switch (props.state) {
    case 'default':
      return (
        <Button
          onClick={props.onClick}
          size={ButtonSize.SMALL}
          isFilled
          isFlat
          color={ButtonColor.GREEN}
          text="Send Opt In Request"
        />

      );
    case 'loading':
      return (<span className={styles.locked}>...Loading...</span>);
    case 'requested':
      return (
        <span className={styles.ok}>
          Opt In request sent.
        </span>
      );
    case 'error':
      return (
        <span className={styles.error}>
            An error occurred while requesting opt in.
        </span>
      );
    case 'locked':
      return (
        <Button
          onClick={props.onClick}
          size={ButtonSize.SMALL}
          isDisabled
          isFilled
          isFlat
          color={ButtonColor.GREEN}
          text="Send Opt In Request"
          title="You can only send one Opt In request per day."
        />
      );
    default:
      return null;
  }
}
