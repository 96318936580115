import { get } from 'lodash';
import React, { ReactNode, useMemo } from 'react';
import { ApplyFilterFunc, ListFilterService, VisibilityState } from './types';
import { ListReactContext, useListContext } from './ListContext';

function applyDependentItemsChecker<DataType = Record<string, any>>() {
  const doFilter = (filterFunc: ApplyFilterFunc<DataType>, parentIdField: string) => {
    const filterWrapper = (data: DataType, allItems: DataType[]) => {
      let result = filterFunc(data, allItems);

      if (result === VisibilityState.HIDDEN) {
        const hasNotHiddenChild = !!allItems
          .filter((item) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if ('id' in data) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return get(item, parentIdField) === data.id;
            } else {
              return false;
            }
          })
          .find((item) => {
            const childResult = filterWrapper(item, allItems);
            return childResult !== VisibilityState.HIDDEN;
          });

        if (hasNotHiddenChild) {
          result = VisibilityState.DISABLED;
        }
      }

      return result;
    };

    return filterWrapper;
  };

  return {
    doFilter,
  };
}

const resultWeights = {
  [VisibilityState.VISIBLE]: 0,
  [VisibilityState.DISABLED]: 1,
  [VisibilityState.HIDDEN]: 2,
};

type VisibilityMap = Record<number, VisibilityState>;

export function aggregateFilters<DataType>(
  filters: ListFilterService[],
  initialVisibilityMap: VisibilityMap = {},
) {
  const applyFilter = (item: DataType, allItems: DataType[]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let finalStatus = initialVisibilityMap[item.id] || VisibilityState.VISIBLE;

    filters.forEach((filter) => {
      const filterResult = filter.applyFilter(item, allItems);

      if (resultWeights[filterResult] > resultWeights[finalStatus]) {
        finalStatus = filterResult;
      }
    });

    return finalStatus;
  };

  return {
    applyFilter,
  };
}

export interface ClientSideFilterProps {
  children: ReactNode,
  filters: ListFilterService[],
  parentIdField?: string
}

export function VisibilityStateClientSideFilter<DataType, FilterType>(
  props: ClientSideFilterProps,
) {
  const listContext = useListContext<DataType, FilterType>();

  const filter = aggregateFilters(props.filters);

  const dependencyHelper = applyDependentItemsChecker<DataType>();

  const contextValue = useMemo(() => {
    const { data } = listContext;

    const filterMap: Record<number, VisibilityState> = {};

    if (data) {
      data.forEach((item) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const id = item.id! as number;

        if (props.parentIdField) {
          filterMap[id] = dependencyHelper.doFilter(
            filter.applyFilter,
            props.parentIdField,
          )(item, data);
        } else {
          filterMap[id] = filter.applyFilter(item, data);
        }
      });
    }

    return {
      ...listContext,
      getVisibilityState: (id: number) => filterMap[id],
      hasVisibleItems: () => !!Object
        .values(filterMap)
        .find((item) => item === VisibilityState.VISIBLE),
    };
  }, [
    listContext.data?.length,
    listContext.version,
    listContext.isLoading,
    JSON.stringify(listContext.filterValues),
  ]);

  return (
    <ListReactContext.Provider value={contextValue}>
      {props.children}
    </ListReactContext.Provider>
  );
}

