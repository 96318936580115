import { BaseInputProps, NumberInput, RelativeRow, TextInput, useInputHelper } from 'ui-builder';
import { ParentTaskRelation } from 'nekst-api';
import React from 'react';
import { DueDateConfigInputProps } from './DueDateConfigInput';

interface DisabledTaskNameInputProps extends BaseInputProps<ParentTaskRelation> {

}

function DisabledTaskNameInput(props: DisabledTaskNameInputProps) {
  const helper = useInputHelper<ParentTaskRelation>(props);

  return (
    <TextInput
      label={props.label}
      source={`${props.source}.name`}
      value={helper.getValue().parentTaskName}
      onChangeCallback={() => {
        // ignored
      }}
      isDisabled
    />
  );
}

export function PlanParentTaskRelationInput(props: DueDateConfigInputProps) {
  return (
    <RelativeRow weights={[4, 8]}>
      <NumberInput source={`${props.source}.days`} label={props.label} />
      <DisabledTaskNameInput
        source={`${props.source}`}
        label="Days After This Task Is Marked Complete"
      />
    </RelativeRow>
  );
}
