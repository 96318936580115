import React, { ReactElement, useContext } from 'react';
import { CommentsListMessages } from './CommentsList';

const CommentsListReactContext = React.createContext<CommentsListMessages>(
  {} as CommentsListMessages,
);

export function useCommentsListTheme() {
  return useContext<CommentsListMessages>(CommentsListReactContext);
}
export default function CommentsListTheme(
  props: {
    children: ReactElement | ReactElement[],
    messages: CommentsListMessages,
  },
) {
  return (
    <CommentsListReactContext.Provider value={props.messages}>
      {props.children}
    </CommentsListReactContext.Provider>
  );
}
