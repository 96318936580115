import { useFilterContext, VisibilityState } from 'ui-builder';
import { AbstractTask, TasksFilter, TaskType } from 'nekst-api';
import {
  AdvancedListFilterService
} from './types';

export const TYPE_FILTER_NAME = 'TYPE';

export type TypeFilterValue = {
  showStandardTasks: boolean,
  showEmailTasks: boolean,
  showSmsTasks: boolean,
  showKeyDatesOnly: boolean
}

export function useTypeFilterService(): AdvancedListFilterService<TypeFilterValue> {

  const filterContext = useFilterContext<TasksFilter>();

  const taskFilterValue = filterContext.filterValue!;

  const defaultValue = {
    showStandardTasks: true,
    showEmailTasks: true,
    showSmsTasks: true,
    showKeyDatesOnly: false,
  };

  const isInitialized = () => {
    return taskFilterValue.showStandardTasks !== undefined
      || taskFilterValue.showEmailTasks !== undefined
      || taskFilterValue.showSmsTasks !== undefined
      || taskFilterValue.showKeyDatesOnly !== undefined;
  };

  const getFilterValue = () => {
    if (isInitialized()) {
      return {
        showStandardTasks: taskFilterValue.showStandardTasks,
        showEmailTasks: taskFilterValue.showEmailTasks,
        showSmsTasks: taskFilterValue.showSmsTasks,
        showKeyDatesOnly: taskFilterValue.showKeyDatesOnly,
      } as TypeFilterValue;
    } else {
      return defaultValue;
    }
  };

  const filterFunc = (item: AbstractTask) => {
    const filterValue = getFilterValue();

    const decisionFuncs = [];

    if (!filterValue.showStandardTasks) {
      decisionFuncs.push((task: AbstractTask) => task.type === TaskType.STANDARD);
    }

    if (!filterValue.showEmailTasks) {
      decisionFuncs.push((task: AbstractTask) => task.type === TaskType.EMAIL);
    }

    if (!filterValue.showSmsTasks) {
      decisionFuncs.push((task: AbstractTask) => task.type === TaskType.SMS);
    }

    if (filterValue.showKeyDatesOnly) {
      decisionFuncs.push((task: AbstractTask) => !task.isKeyDate);
    }

    return decisionFuncs.find((func) => func(item)) === undefined
      ? VisibilityState.VISIBLE
      : VisibilityState.HIDDEN;
  };

  const toContextForm = (value: TypeFilterValue | undefined) => {
    return {
      ...value || {},
    } as Partial<TasksFilter>;
  };

  const setFilterValue = (value: TypeFilterValue) => {
    filterContext.setFilterValue({
      ...taskFilterValue,
      ...toContextForm(value),
    });
  };

  return {
    getName: () => TYPE_FILTER_NAME,
    getFilterValue,
    setFilterValue,
    applyFilter: filterFunc,
    getDefaultValue: () => defaultValue,
    toContextForm,
  };
}
