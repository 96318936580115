import React from 'react';
import Checkbox, { CheckboxProps } from '../../../shared/uibuilder/form/input/Checkbox';
import { UiThemeOverrider } from '../../../shared/uibuilder/uiTheme';
import BriefcaseCheckboxLayout from './BriefcaseCheckboxLayout';

export default function BriefcaseCheckbox(props: CheckboxProps) {
  return (
    <UiThemeOverrider
      theme={{
        inputs: {
          CheckboxLayout: BriefcaseCheckboxLayout,
        },
      }}
    >
      {/* eslint-disable react/jsx-props-no-spreading */}
      <Checkbox
        {...props}
      />
    </UiThemeOverrider>
  );
}
