import { PlanPermission, useAuthorizationChecker } from 'authorization-scope';
import { usePopupFormManager } from 'features/nekst-widgets';
import { Plan, usePlansService } from 'nekst-api';
import { ButtonSize, isReactNative, OptionSelectionButton } from 'ui-builder';

import React from 'react';
import { RenamePlanForm } from './RenamePlanForm';
import { ChangePlanTypeForm } from './ChangePlanTypeForm';
import { DeletePlanForm } from './DeletePlanForm';
import { CopyPlanForm } from './CopyPlanForm';
import { SharePlanForm } from './SharePlanForm';
import useFeatureToggleService from 'feature-toggle';
import { UpdatePlanSetForm } from './UpdatePlanSetForm';

enum PlanAction {
  RENAME = 'rename',
  DELETE = 'delete',
  COPY = 'copy',
  SHARE = 'share',
  EXPORT = 'export',
  CHANGE_TYPE = 'change_type',
  CHANGE_SET = 'change_set',
}

export function EditPlanButton(
  props: {
    id: number,
    goToPlansListFunc: () => void,
    goToPlanFunc: (plan: Plan) => void,
    customButton?: React.FC<{ onClick: () => void }>,
  }
) {

  const { isGranted } = useAuthorizationChecker();

  const popupManager = usePopupFormManager();

  const planService = usePlansService();

  const featureToggleService = useFeatureToggleService();

  return (
    <OptionSelectionButton<PlanAction>
      text="Edit"
      options={[
        isGranted(PlanPermission.UPDATE) && {
          value: PlanAction.RENAME,
          label: 'Rename',
          onSelected: () => {
            popupManager.openForm(
              <RenamePlanForm id={props.id} />,
              true,
            );
          },
        },
        isGranted(PlanPermission.UPDATE_TYPE) && {
          value: PlanAction.CHANGE_TYPE,
          label: 'Change type',
          onSelected: async () => {
            const plan = await planService.getById(props.id);

            popupManager.openForm(
              <ChangePlanTypeForm plan={plan} />,
            );
          }
        },
        ...isGranted(PlanPermission.UPDATE) && featureToggleService.isDetailsFieldSetsEnabled() ? [
          {
            value: PlanAction.CHANGE_SET,
            label: 'Change detail boxes set',
            onSelected: async () => {
              popupManager.openForm(
                <UpdatePlanSetForm id={props.id} />
              )
            },
          }
        ] : [],
        isGranted(PlanPermission.DELETE) && {
          value: PlanAction.DELETE,
          label: 'Delete plan',
          onSelected: () => {
            popupManager.openForm(
              <DeletePlanForm id={props.id} afterSubmitFunc={async () => {
                props.goToPlansListFunc();
              }} />,
            );
          }
        },
        {
          value: PlanAction.COPY,
          label: 'Copy plan',
          onSelected: () => {
            popupManager.openForm(
              <CopyPlanForm
                id={props.id}
                afterSubmitFunc={async (plan) => props.goToPlanFunc(plan)}
              />,
              true,
            );
          }
        },
        isGranted(PlanPermission.SHARE) && {
          value: PlanAction.SHARE,
          label: 'Share plan',
          onSelected: () => {
            popupManager.openForm(
              <SharePlanForm id={props.id} />,
              true,
            );
          }
        },
        isGranted(PlanPermission.UPDATE) && !isReactNative() && {
          value: PlanAction.EXPORT,
          label: 'Export plan',
          onSelected: () => {
            planService.exportToCsv(props.id);
          }
        }
      ]}
      size={ButtonSize.MEDIUM}
      customButton={props.customButton}
    />
  );
}
