import { useEventsPublisher } from 'event-bus';
import {
  TASK_UPDATED,
  TASK_COMMENTS_UPDATED,
  useProjectTasksService
} from './projectTasksService';

export function useProjectTaskUpdater() {
  const eventsPublisher = useEventsPublisher();

  const projectTasksService = useProjectTasksService();

  const publishCommentsUpdatedEvent = async (taskId: number) => {
    const task = await projectTasksService.getTask(taskId);

    if (task) {
      eventsPublisher.publish(TASK_COMMENTS_UPDATED, {
        id: task.id,
        data: task,
      });
    }
  };

  return {
    publishCommentsUpdatedEvent,
  };
}
