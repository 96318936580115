import { TasksFilter,  } from './Types';
import {useTasksFilterHelper} from './Helper'
import { useBackendApi } from './backendApi';

export type TasksStatistic = {
  today: number,
  pastDue: number,

  total: number
  noDueDate: number,
  datesWithTasks: Record<string, number>,
}

export function useTasksStatisticService() {
  const backendApi = useBackendApi();

  const filterHelper = useTasksFilterHelper();

  const getStatistic = async (filter: TasksFilter, projectId?: number) => {
    const today = new Date();

    const addZeros = (value: number) => {
      if (value < 10) {
        return `0${value}`;
      } else {
        return value;
      }
    };
    const todayDateString = `${today.getFullYear()}-${addZeros(today.getMonth() + 1)}-${addZeros(today.getDate())}`;

    return await backendApi.get(
      `/tasks/statistic?${filterHelper.buildQueryString(filter)}&todayDate=${encodeURIComponent(todayDateString)}&${projectId ? `&projectId=${projectId}` : ''}`,
    ) as TasksStatistic;
  };

  return {
    getStatistic,
  };
}
