import React from 'react';
import { ToggleWrapper, ToggleWrapperProps } from './ToggleWrapper';
import { ToggledFeature } from './featureToggleService';

export function DetailsFieldsSetsToggleWrapper(
  props: ToggleWrapperProps,
) {
  return (
    <ToggleWrapper feature={ToggledFeature.DETAILS_FIELD_SETS}>
      {props.children}
    </ToggleWrapper>
  );
}
