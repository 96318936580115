import React, { useState } from 'react';
import {
  useProjectDetailsService,
  ProjectDetailsFieldValue,
} from 'nekst-api';

import useDataLoader from '../../shared/uibuilder/form/dataLoader';
import CheckboxGroup from '../../shared/uibuilder/form/input/CheckboxGroup';
import { BaseInputProps } from '../../shared/uibuilder/form/input';
import LoadingAnimation from '../../shared/LoadingAnimation';

export interface DetailBoxesCheckboxGroupProps extends BaseInputProps<number[]> {
  projectId: number,
}

export default function DetailBoxesCheckboxGroup(
  props: DetailBoxesCheckboxGroupProps,
) {
  const projectDetailsService = useProjectDetailsService();

  const [fields, setFields] = useState<ProjectDetailsFieldValue[]>();

  useDataLoader(
    () => projectDetailsService.getProjectDetails(props.projectId),
    setFields,
  );

  if (fields) {
    return (
      <CheckboxGroup
        weights={[3, 3, 3, 3]}
        options={
          fields.map((field) => ({
            label: field.setting.name,
            value: field.setting.id,
            isHighlighted: !!field.value,
          }))
        }
        {...props}
      />
    );
  } else {
    return <LoadingAnimation />;
  }
}
