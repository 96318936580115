import { ListFilterService, VisibilityState } from 'ui-builder';
import { PlanTask } from 'nekst-api';

export function usePlanTasksExclusionFilterService(): ListFilterService<boolean, PlanTask & {
  _renderedName: string;
}> {
  const defaultValue = undefined;

  const getFilterValue = () => {
    return true;
  };

  const setFilterValue = (value: boolean) => {
    // no action
  };

  const applyFilter = (data: PlanTask & { _renderedName: string }) => {
    const filter = getFilterValue();
    if (filter) {
      return data.excluded ? VisibilityState.HIDDEN : VisibilityState.VISIBLE;
    } else {
      return VisibilityState.VISIBLE;
    }
  };

  return {
    getName: () => 'exclusion',
    getFilterValue,
    setFilterValue,
    getDefaultValue: () => defaultValue,
    applyFilter,
  };
}
