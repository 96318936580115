import React from 'react';
import { HistoryEntry, ProjectHistoryFilter, TeamRole } from 'nekst-api';
import { ListFilterService, useListContext, VisibilityState } from 'ui-builder';
import { useAssignmentsLoader } from '../../view/tasks/list/filter/Advanced/assigneeFilterService';
import FilterIncludedExcludedInput from '../../../shared/widgets/FilterIncludedExcludedInput';
import AssignmentNameWithRoles from '../../view/tasks/shared/AssignmentNameWithRoles';
import FilterWidgetLayout from '../../view/tasks/list/filter/FilterWidgetLayout';

export const AUTHOR_FILTER_NAME = 'AUTHOR_FILTER';

export function EventAuthorFilter() {
  const { assignments } = useAssignmentsLoader();

  return (
    <FilterIncludedExcludedInput
      options={
        assignments.filter((v) => (v.profile.teamRole && [
          TeamRole.OWNER,
          TeamRole.ADMINISTRATOR,
          TeamRole.MEMBER].includes(v.profile.teamRole)))
          .map((contactAssignments) => ({
            value: contactAssignments.personId,
            label: (<AssignmentNameWithRoles assignment={contactAssignments} />),
          }))
      }
      filterName="authors"
      title="Team Member"
    />
  );
}

export function AuthorFilterWidget() {
  const { assignments } = useAssignmentsLoader();

  const getNameById = (id: number) => {
    return assignments
      .find((value) => value.personId === id)
      ?.profile.name.fullName || '';
  };

  const {
    filterValues,
    setFilterValues,
  } = useListContext<HistoryEntry, ProjectHistoryFilter>();

  const resetFilter = () => {
    setFilterValues!({
      ...filterValues! || {},
      authors: {
        selectAll: true,
        selected: [],
      },
    });
  };

  if (filterValues?.authors?.selected.length) {
    return (
      <FilterWidgetLayout
        name="Event Author"
        text={
          filterValues
            .authors
            .selected
            .map((v: number) => getNameById(v))
            .join(', ') || ''
        }
        onClick={resetFilter}
      />
    );
  } else {
    return null;
  }
}

export default function useAuthorFilter(): ListFilterService {
  const listContext = useListContext<HistoryEntry, ProjectHistoryFilter>();

  const defaultValue = {
    selectAll: true,
    selected: [],
  };

  const getFilterValue = () => {
    return listContext.filterValues?.authors || defaultValue;
  };

  const setFilterValue = (value: { selectAll: boolean, selected: number[] }) => {
    listContext.setFilterValues!({
      ...listContext.filterValues! || {},
      authors: value,
    });
  };

  const filterFunc = (
    data: HistoryEntry,
  ) => {
    const filterValue = getFilterValue();
    if (filterValue.selectAll) {
      return VisibilityState.VISIBLE;
    } else {
      return filterValue.selected.includes(data.data.person.id)
        ? VisibilityState.VISIBLE
        : VisibilityState.HIDDEN;
    }
  };

  return {
    getName: () => AUTHOR_FILTER_NAME,
    setFilterValue,
    getFilterValue,
    getDefaultValue: () => defaultValue,
    applyFilter: filterFunc,
  };
}
