import { UiTheme } from '../uiTheme';
import BaseDropdownLayout from '../form/input/layout/BaseDropdownLayout';
import RadioButtonLayout from '../form/input/layout/RadioButtonLayout';
import RadioButtonsGroupLayout from '../form/input/layout/RadioButtonsGroupLayout';
import CheckboxLayout from '../form/input/layout/CheckboxLayout';
import TextAreaLayout from '../form/input/layout/TextAreaLayout';
import TextLayout from '../form/input/layout/TextLayout';
import BaseButtonLayout from '../form/button/layout/BaseButtonLayout';
import TableDataGridHead from '../list/layout/TableDataGridHead';
import TableDataGridRow from '../list/layout/TableDataGridRow';
import TableDataGridWrapper from '../list/layout/TableDataGridWrapper';
import FormRowLayout, { FormRowsGroupLayout } from '../form/layout/FormRowLayout';
import BaseInputLayout from '../form/layout/BaseInputLayout';
import DefaultLayout from '../form/layout/DefaultLayout';
import DateInputLayout from './form/input/DateInputLayout';
import TimeInputLayout from './form/input/TimeInputLayout';
import PriceLayout from '../form/input/layout/PriceLayout';
import PasswordLayout from '../form/input/layout/PasswordLayout';
import ButtonLayout from '../../web/button/ButtonLayout';
import SimpleTextLayout from './common/TextLayout';
import LeftLayout from './common/LeftLayout';
import RightLayout from './common/RightLayout';
import CenterLayout from './common/CenterLayout';
import TextLinkLayout from './common/TextLinkLayout';
import LoadingAnimationLayout from './common/LoadingAnimationLayout';
import ShowLayout from '../../web/uitheme/show/ShowLayout';
import TextFieldLayout from './field/TextFieldLayout';
import CheckboxGroupLayout from './CheckboxGroupLayout';
import {
  Header1Layout,
  Header2Layout,
  Header3Layout,
  Header4Layout,
  Header5Layout,
  Header6Layout
} from './common/HeadersLayouts';
import AvatarLayout from './widgets/AvatarLayout';
import { RelativeRowCellLayout, RelativeRowGroupLayout } from './common/RelativeRowLayout';
import ErrorsWrapperLayout from './form/input/ErrorsWrapperLayout';
import BaseBlockLayout, { FormBlockLayout } from './common/BaseBlockLayout';
import DateTimeFieldLayout from './field/DateTimeFieldLayout';
import ItemsListLayout from './widgets/ItemsListLayout';
import OptionSelectionButtonLayout from './widgets/OptionSelectionButtonLayout';

interface DefaultThemeProvider {
  getDefaultTheme: () => UiTheme,
}

function useDefaultThemeProvider(): DefaultThemeProvider {
  const getDefaultTheme = () => {
    return {
      inputs: {
        TextLayout,
        PasswordLayout,
        BaseDropdownLayout,
        TextAreaLayout,
        RadioButtonLayout,
        RadioButtonsGroupLayout,
        CheckboxLayout,
        DateInputLayout,
        TimeInputLayout: TimeInputLayout,
        PriceLayout,
        CheckboxGroupLayout,
        ErrorsWrapperLayout,
      },
      buttons: {
        SubmitButtonLayout: BaseButtonLayout,
        CancelButtonLayout: BaseButtonLayout,
        DefaultButtonLayout: ButtonLayout,
      },
      lists: {
        dataGrid: {
          HeadLayout: TableDataGridHead,
          RowLayout: TableDataGridRow,
          WrapperLayout: TableDataGridWrapper,
        },
      },
      forms: {
        FormRowLayout,
        FormLayout: DefaultLayout,
        BaseInputLayout,
        FormRowsGroupLayout,
      },
      common: {
        TextLayout: SimpleTextLayout,
        LeftLayout,
        RightLayout,
        CenterLayout,
        TextLinkLayout,
        LoadingAnimationLayout,
        Header1Layout,
        Header2Layout,
        Header3Layout,
        Header4Layout,
        Header5Layout,
        Header6Layout,
        RelativeRowGroupLayout,
        RelativeRowCellLayout,
        SimpleBlockLayout: BaseBlockLayout,
        FormBlockLayout,
      },
      show: {
        ShowLayout,
      },
      fields: {
        TextFieldLayout,
        DateTimeFieldLayout,
      },
      widgets: {
        AvatarLayout,
        ItemsListLayout,
        OptionSelectionButtonLayout,
      }
    } as UiTheme;
  };

  return {
    getDefaultTheme,
  };
}

export default useDefaultThemeProvider;
