import React, { ReactNode } from 'react';
import { useFormContext } from '../FormContext';
import { useNewUiTheme } from '../../newUiTheme';

export interface ErrorsWrapperLayoutProps {
  name: string,
  errors?: string[]
  children: ReactNode,
}

export interface ErrorsWrapperProps extends ErrorsWrapperLayoutProps {
}

export function ErrorsWrapper(props: ErrorsWrapperProps) {
  const formContext = useFormContext();

  const errors = formContext.getFieldErrors
    ? formContext.getFieldErrors(props.name)
    : props.errors;

  const uiTheme = useNewUiTheme();

  return (
    <uiTheme.inputs.ErrorsWrapperLayout name={props.name} errors={errors}>
      {props.children}
    </uiTheme.inputs.ErrorsWrapperLayout>
  );
}
