import React from 'react';
import styles from './Buttons.module.scss';
import { IconButton, IconType } from '../IconButton';
import {
  BaseInputProps,
  Dropdown,
  InputLayoutIcon,
  isReactNative,
  RelativeRow,
  SimpleBlock,
  TextInput,
  useFormContext,
  useFormContextRequired,
} from 'ui-builder';
import { PersonPhoneNumberType } from 'nekst-api';
import useValidator, { ValidationSchema } from 'validation-schema';
import { get } from 'lodash';
import { MaskedInput } from '../MaskedInput';
import { Spacings } from 'react-native-ui-lib';

function PhoneNumberTypeDropdown(props: BaseInputProps) {
  return (
    <Dropdown
      isClearable={false}
      options={[
        {
          label: 'Mobile',
          value: PersonPhoneNumberType.MOBILE,
        },
        {
          label: 'Work',
          value: PersonPhoneNumberType.WORK,
        },
        {
          label: 'Home',
          value: PersonPhoneNumberType.HOME,
        },
        {
          label: 'Fax',
          value: PersonPhoneNumberType.FAX,
        },
      ]}
      {...props}
      icon={InputLayoutIcon.PHONE}
    />
  );
}

function usePhoneNumberFormValidator(
  source: string,
) {
  const formContext = useFormContext();

  const validator = useValidator();

  const validate = async (): Promise<boolean> => {
    const item = get(formContext.data!, source) || {};

    const validationSchema = {
      number: {
        type: 'string',
        constraints: {
          required: true,
          phoneNumber: true,
        },
      },
      type: {
        type: 'string',
        constraints: {
          required: true,
        },
      },
    } as ValidationSchema;

    const errors = await validator.validate(item, validationSchema);

    const mappedErrors = Object.entries(errors)
      .reduce((prev: Record<string, string[]>, [key, value]) => {
        const newValue = {
          ...prev,
        };
        newValue[`${source}.${key}`] = value;

        return newValue;
      }, {});

    formContext.__setErrors!(mappedErrors);

    return Object.keys(errors).length === 0;
  };

  return {
    validate,
  };
}

function AddPhoneNumberFormLayout(
  props: {
    typeDropdown: React.ReactNode,
    numberInput: React.ReactNode,
    extensionInput?: React.ReactNode,
    buttons: React.ReactNode,
  }
) {
  if (isReactNative()) {
    return (
      <>
        <RelativeRow weights={[8, 2, 2]}>
          <RelativeRow weights={[5, 7]}>
            {props.typeDropdown}
            {props.numberInput}
          </RelativeRow>
          {props.extensionInput}
        </RelativeRow>
        <RelativeRow weights={[6, 6]}>
          <SimpleBlock style={{
            flexDirection: 'row',
            gap: Spacings.s2,

            justifyContent: 'flex-end',
          }}>
            {props.buttons}
          </SimpleBlock>
        </RelativeRow>
      </>
    );
  } else {
    return (
      <RelativeRow weights={[8, 2, 2, 2]} useFlex centerV>
        <RelativeRow weights={[5, 7]}>
          {props.typeDropdown}
          {props.numberInput}
        </RelativeRow>
        {props.extensionInput}
        <SimpleBlock style={{
          flexDirection: 'row',
          gap: Spacings.s2,

          justifyContent: 'flex-end',
        }}>
          {props.buttons}
        </SimpleBlock>
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        {!props.extensionInput && <></>}
      </RelativeRow>
    );
  }
}

export function AddPhoneNumberForm(
  props: {
    source: string
    submitFunc: () => Promise<void>,
    cancelFunc: () => Promise<void>,
  },
) {

  const { validate } = usePhoneNumberFormValidator(props.source);

  const submitFunc = async () => {
    const isValid = await validate();

    if (isValid) {
      await props.submitFunc();
    }
  };

  const formContext = useFormContextRequired();

  const showExtension = get(formContext.data!, `${props.source}.type`) === PersonPhoneNumberType.WORK;

  return (
    <AddPhoneNumberFormLayout
      typeDropdown={(
        <PhoneNumberTypeDropdown
          source={`${props.source}.type`}
          label={undefined}
          hideLabel
          placeholder="Type"
        />
      )}
      numberInput={(
        <MaskedInput source={`${props.source}.number`} hideLabel />
      )}
      extensionInput={showExtension ?
        <TextInput source={`${props.source}.extension`} placeholder="ext." hideLabel /> : undefined}
      buttons={(
        <>
          <IconButton
            onClick={submitFunc}
            type={IconType.APPLY}
            className={styles.circleButton}
            testID="add-phone-number-button"
            size="big"
          />
          <IconButton
            onClick={props.cancelFunc}
            type={IconType.CANCEL}
            className={styles.circleButton}
            size="big"
          />
        </>
      )}
    />
  );
}
