import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function PageTitle(props: {
  text: string,
}) {
  return (
    <Helmet>
      <title>
        {props.text}
        {' - Nekst'}
      </title>
    </Helmet>
  );
}
