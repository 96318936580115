import { useTokenContext } from '../Authentication';
import { LOGGED_IN } from './loginService';
import { useBackendApi } from './backendApi';
import { Name } from './Types';
import { useEventsPublisher } from 'event-bus';
import {
  SubscriptionCreationResult,
} from './billingService';

export interface SignUpRequest {
  priceId: string,
  name: Name,
  email: string,
  companyName?: string,
}

export interface SignUpResult {
  subscriptionCreationResult: SubscriptionCreationResult,
  accessToken: string,
}

export function useSignUpService() {
  const nekstApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const tokenContext = useTokenContext();

  const signUp = async (
    request: SignUpRequest,
    successReturnUrl: string,
    rewardfulReferralCode?: string
  ) => {
    const result = await nekstApi.post('/signup', {
      ...request,
      successReturnUrl,
      rewardfulReferralCode,
    });

    await tokenContext.setToken(result.accessToken);

    eventsPublisher.publish(LOGGED_IN, {});

    return result as SignUpResult;
  };

  const signUpMobile = async (
    request: SignUpRequest,
  ) => {
    const result = await nekstApi.post('/signup/new', {
      ...request,
    });

    await tokenContext.setToken(result.accessToken);

    eventsPublisher.publish(LOGGED_IN, {});
  };

  return {
    signUp,
    signUpMobile
  };
}
