import React from 'react';
import { HeaderLayoutProps, HeaderProps } from './types';
import { useNewUiTheme } from '../newUiTheme';

function header(props: HeaderProps, Layout: ReactComponent<HeaderLayoutProps>) {
    return (
        <Layout text={props.children} style={props.style} className={props.className} />
    );
}

export function Header1(props: HeaderProps) {
    const uiTheme = useNewUiTheme();

    return header(
        props,
        uiTheme.common.Header1Layout
    );
}
export function Header2(props: HeaderProps) {
    const uiTheme = useNewUiTheme();

    return header(
        props,
        uiTheme.common.Header2Layout
    );
}

export function Header3(props: HeaderProps) {
    const uiTheme = useNewUiTheme();

    return header(
        props,
        uiTheme.common.Header3Layout
    );
}

export function Header4(props: HeaderProps) {
    const uiTheme = useNewUiTheme();

    return header(
        props,
        uiTheme.common.Header4Layout
    );
}

export function Header5(props: HeaderProps) {
    const uiTheme = useNewUiTheme();

    return header(
        props,
        uiTheme.common.Header5Layout
    );
}

export function Header6(props: HeaderProps) {
    const uiTheme = useNewUiTheme();

    return header(
        props,
        uiTheme.common.Header6Layout
    );
}
