import React, {
  ReactElement,
  useContext,
  useMemo,
  useState
} from 'react';

export interface MassUpdateService {
  toggleEnabled: () => void,
  isEnabled: () => boolean,
  toggleChecked: (taskId: number) => void,
  isChecked: (taskId: number) => boolean,
  getCheckedIds: () => number[],
  reset: () => void,
  setIds: (ids: number[]) => void,
  selectAll: (resetIfAllSelected?: boolean) => void,
  areAllSelected: () => boolean,
}

const MassUpdateContext = React.createContext<MassUpdateService | null>(null);

export interface MassUpdateProps {
  children: ReactElement | ReactElement[],
  enabled?: boolean,
}

export function useMassUpdateService(): MassUpdateService | null {
  return useContext(MassUpdateContext);
}

export function useMassUpdateServiceRequired(): MassUpdateService {
  const service = useMassUpdateService();

  if (!service) {
    throw new Error('MassUpdateService is not provided');
  }

  return service;
}

export function MassUpdate(props: MassUpdateProps) {
  const [value, setValue] = useState<Record<number, boolean>>({});

  const [selectAllValue, setSelectAllValue] = useState<Record<number, boolean>>({});

  const [enabled, setEnabled] = useState<boolean>(props.enabled || false);

  const [i, setI] = useState<number>(0);

  const refreshUi = () => {
    const newValue = i + 1;
    setI(newValue);
  };

  const reset = () => {
    setValue({});
  };

  const toggleEnabled = () => {
    const newValue = !enabled;

    if (!newValue) {
      reset();
    }

    setEnabled(newValue);
  };

  const isEnabled = () => {
    return enabled;
  };

  const toggleChecked = (taskId: number) => {
    const newValue = value;
    newValue[taskId] = !newValue[taskId];

    if (!newValue[taskId]) {
      delete newValue[taskId];
    }

    setValue(newValue);
    refreshUi();
  };

  const isChecked = (taskId: number) => value[taskId];

  const getCheckedIds = () => {
    return Object.entries(value || {})
      .filter(([, v]) => v)
      .map(([id]) => parseInt(id, 10));
  };

  function updateCheckedItems(result: Record<number, boolean>) {
    const newValue = value;
    Object.keys(newValue)
      .forEach((key) => {
        const id = parseInt(key, 10);
        if (!result[id]) {
          delete newValue[id];
        }
      });

    setValue(newValue);
  }

  const setIds = (ids: number[]) => {
    const result: Record<number, boolean> = {};
    ids.forEach((id) => {
      result[id] = true;
    });

    refreshUi();
    setSelectAllValue(result);
    updateCheckedItems(result);
  };

  const areAllSelected = () => {
    return Object.keys(value).length > 0
      && Object.keys(value).length === Object.keys(selectAllValue).length;
  };

  const selectAll = (resetIfAllSelected = true) => {
    if (areAllSelected() && resetIfAllSelected) {
      reset();
    } else {
      setValue({
        ...selectAllValue,
      });
    }

    refreshUi();
  };

  const contextValue = useMemo(() => ({
    toggleEnabled,
    isEnabled,
    isChecked,
    toggleChecked,
    getCheckedIds,
    reset,
    setIds,
    selectAll,
    areAllSelected,
  }), [enabled, i]);

  return (
    <MassUpdateContext.Provider
      value={contextValue}
    >
      {props.children}
    </MassUpdateContext.Provider>
  );
}
