import React from 'react';
import useUiTheme from '../../../uiTheme';
import { TextLayoutProps } from '../Text';
import styles from './TextLayout.module.scss';

function TextLayout(props: TextLayoutProps) {
  const uiTheme = useUiTheme();

  return (
    <uiTheme.forms.BaseInputLayout
      label={props.label}
      afterLabelElement={props.afterLabelElement}
      name={props.name}
      errors={props.errors}
      hint={props.hint}
      showLabel={props.showLabel}
      className={props.className}
      hideLabel={props.hideLabel}
      showRequiredIcon={props.showRequiredIcon}
    >
      <input
        disabled={props.isDisabled}
        name={props.name}
        autoComplete="chrome-off"
        placeholder={props.placeholder}
        type={props.inputType || 'text'}
        value={props.value || ''}
        onChange={props.onChangeCallback}
        onFocus={props.onFocusCallback}
        onBlur={props.onBlurCallback}
        data-lpignore="true"
        min={props.min}
        max={props.max}
        className={props.icon ? styles[props.icon] : undefined}
      />
    </uiTheme.forms.BaseInputLayout>
  );
}

export default TextLayout;
