import { useDateHelper } from 'nekst-api';
import { DueDate } from '../projectTasksService';

export default function useDueDateHelper() {
  const dateHelper = useDateHelper();

  const getStringValue = (value?: DueDate) => {
    let str;

    if (value) {
      str = `Due ${dateHelper.toString(value.date)}`;
    } else {
      str = 'No Due Date';
    }

    return str;
  };

  return {
    getStringValue,
  };
}
