import React from 'react';
import { CheckboxGroupLayoutProps, Text } from 'ui-builder';
import Label from '../form/layout/Label';
import RelativeRow from '../../web/uitheme/form/RelativeRow';
import ErrorsWrapper from '../form/input/layout/ErrorsWrapper';
import Checkbox from '../form/input/Checkbox';

import styles from './CheckboxGroupLayout.module.scss';

export default function CheckboxGroupLayout(
  props: CheckboxGroupLayoutProps<any>
) {
  const checkboxes = props.options.map((option) => {
    const itemSource = `${props.name}.${option.value}`;

    return (
      <Checkbox
        hint={option.hint}
        source={itemSource}
        label={option.label}
        key={`value-${option.value}`}
        value={props.isSelectedFunc(option.value)}
        isHighlighted={option.isHighlighted}
        isDisabled={props.isDisabled}
        onChangeCallback={(data) => {
          if (data[itemSource]) {
            props.selectFunc(option.value);
          } else {
            props.unselectFunc(option.value);
          }
        }}
      />
    );
  });

  return (
    <ErrorsWrapper name={props.name}>
      <div className={props.className}>
        {!props.hideLabel && (<Label label={props.label} />)}
        {checkboxes.length ? (
          <div className={`${styles.checkboxes} ${props.optionsContainerClassName}`}>
            <RelativeRow weights={props.weights || []}>
              {checkboxes}
            </RelativeRow>
          </div>
        ) : (<Text className="gray-message">{props.noOptionsMessage}</Text>)}
        {props.hint && (
          <div className="hint">
            {props.hint}
          </div>
        )}
      </div>
    </ErrorsWrapper>
  );
}
