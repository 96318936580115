import {
  ProjectDetailsFieldType,
  ProjectDetailsFieldValue, StandardTask,
  useProjectDetailsService
} from 'nekst-api';
import React, { useMemo, useState } from 'react';
import useDataLoader from 'data-loader';
import { Dropdown, isReactNative, LoadingAnimation, Option, useFormContext } from 'ui-builder';

function useLinkedDetailFieldDropdownHelper(
  projectId?: number,
  taskId?: number,
) {
  const projectDetailsService = useProjectDetailsService();

  const [projectDetailsValues, setProjectDetailsValues] = useState<ProjectDetailsFieldValue[]>();

  const { loading } = useDataLoader(
    async () => {
      if (projectId) {
        return projectDetailsService.getProjectDetails(projectId);
      } else {
        return [];
      }
    },
    setProjectDetailsValues,
    projectId,
  );

  const options = useMemo<Option[]>(() => {
    if (projectDetailsValues) {
      return projectDetailsValues
        .filter((item) => item.setting.fieldType === ProjectDetailsFieldType.DATE)
        .filter((item) => item.linkedTaskId === taskId || !item.linkedTaskId)
        .map((item) => ({
          value: item.setting.id,
          label: item.setting.name,
        }));
    } else {
      return [];
    }
  }, [projectDetailsValues?.length]);

  return {
    loading,
    options,
  };
}

export function ProjectLinkedDetailFieldDropdown(
  props: {
    label: string,
  },
) {
  const formContext = useFormContext<StandardTask>();

  const projectId = formContext.data?.projectId;
  const taskId = formContext.data?.id;

  const {
    loading,
    options,
  } = useLinkedDetailFieldDropdownHelper(projectId, taskId);


  if (!loading) {
    return (
      <Dropdown
        options={options}
        source="linkedFieldId"
        label={props.label}
        placeholder={isReactNative() ? 'Select a detail box for linking' : undefined}
      />
    );
  } else {
    return (
      <LoadingAnimation />
    );
  }
}
