import React from 'react';
import { BaseFieldLayoutProps, BaseFieldProps } from './types';
import { useNewUiTheme } from '../newUiTheme';
import { useFieldHelper } from './fieldHelper';

export type DateTimeFieldProps = BaseFieldProps<string | Date>;

export interface DateTimeFieldLayoutProps extends BaseFieldLayoutProps {
  displayValue?: string;
}

function timeSince(date: Date) {
  const now = new Date();
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  if (seconds <= 30) return "just now";
  if (seconds <= 59) return `${seconds} seconds ago`;

  const dateDay = date.getDate();
  const dateMonth = date.getMonth();
  const dateYear = date.getFullYear();

  const nowDay = now.getDate();
  const nowMonth = now.getMonth();
  const nowYear = now.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12;

  if (dateDay === nowDay && dateMonth === nowMonth && dateYear === nowYear) {
    return `today at ${hours}:${minutes} ${ampm}`;
  }

  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);

  if (dateDay === yesterday.getDate() && dateMonth === yesterday.getMonth() && dateYear === yesterday.getFullYear()) {
    return `yesterday at ${hours}:${minutes} ${ampm}`;
  }

  return `${dateMonth + 1}/${dateDay}/${dateYear} ${hours}:${minutes} ${ampm}`;
}

function addZero(value: number): string {
  return value < 10 ? `0${value}` : value.toString();
}

function toDateTimeView(date: Date){
  const month = addZero(date.getMonth() + 1);
  const day = addZero(date.getDate());
  const year = date.getFullYear();

  let hours = date.getHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12;
  const minutes = addZero(date.getMinutes());

  return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
}

export function DateTimeField(props: DateTimeFieldProps) {
  const uiTheme = useNewUiTheme();

  const fieldHelper = useFieldHelper<string | Date>(props);

  const dateString = fieldHelper.getValue();

  let dateValue;
  if (dateString) {
    dateValue = new Date(dateString);
  }

  let displayValue;

  if (dateValue) {
    displayValue = timeSince(dateValue);
  }

  return (
    <uiTheme.fields.DateTimeFieldLayout
      {...fieldHelper.getBaseLayoutProps()}
      value={dateValue ? toDateTimeView(dateValue) : undefined}
      displayValue={displayValue}
    />
  );
}
