import { useDateHelper } from './Helper';
import { ProjectDetailsFieldType, ProjectDetailsFieldValue } from './Types';
import { useBackendApi } from './backendApi';
import { useEventsPublisher } from 'event-bus';

export const PROJECT_DETAILS_UPDATED = 'PROJECT_DETAILS_UPDATED';

export function useProjectDetailsDateHelper() {

  const dateHelper = useDateHelper();

  const mapList = (rawData: Record<string, any>[]): ProjectDetailsFieldValue[] => {
    return rawData.map((item) => {
      const newItem = {
        ...item,
      };

      if (newItem.setting.fieldType === ProjectDetailsFieldType.DATE
        && newItem.value
        && typeof newItem.value === 'string'
      ) {
        newItem.value = dateHelper.parseDateString(newItem.value);
      }
      return newItem as ProjectDetailsFieldValue;
    });
  };

  return {
    mapList,
  };
}

export function useProjectDetailsService() {
  const projectsApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();
  const projectDetailsDateHelper = useProjectDetailsDateHelper();

  const getProjectDetails = async (projectId: number) => {
    const rawData = await projectsApi.get(
      `/projects/${projectId}/details`,
    ) as Record<string, any>[];

    const result = projectDetailsDateHelper.mapList(rawData);

    return result.sort((a1, a2) => {
      const longTextType = ProjectDetailsFieldType.LONG_TEXT;
      if (a1.setting.fieldType === longTextType && a2.setting.fieldType !== longTextType) {
        return -1;
      } else if (a1.setting.fieldType !== longTextType && a2.setting.fieldType === longTextType) {
        return 1;
      } else {
        return a1.setting.order - a2.setting.order;
      }
    });
  };

  const updateProjectDetails = async (
    projectId: number,
    newValues: Record<string, string | number | Date | null>,
  ) => {
    const result = await projectsApi.patch(
      `/projects/${projectId}/details`,
      newValues,
    ) as ProjectDetailsFieldValue[];

    eventsPublisher.publish(PROJECT_DETAILS_UPDATED, {
      projectId,
    });

    return result;
  };

  return {
    getProjectDetails,
    updateProjectDetails,
  };
}
