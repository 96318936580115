import React from 'react';
import BaseTasksPage from '../BaseTasksPage';
import useLaunchFromContractNavigator from './launchFromContractNavigator';
import LaunchFromContractSteps, { LaunchFromContractStepId } from './LaunchFromContractSteps';

export default function ManageKeyDatesPage() {
  const navigator = useLaunchFromContractNavigator();

  return (
    <BaseTasksPage
      roadmap={<LaunchFromContractSteps currentStep={LaunchFromContractStepId.KEY_DATES} />}
      onPrevClicked={navigator.openSelectGroupsPage}
      onNextClicked={navigator.openAllTasksPage}
      forceShowKeyDatesOnly
    />
  );
}
