import React, { ReactElement } from 'react';
import { IconButtonProps, IconType } from './types';
import Layout from './Layout';
import { usePopupFormManager } from '../PopupForm';
import { usePopupManager } from 'ui-builder';
import { ConfirmDeletePopup } from '../ConfirmDeletePopup';

export function IconButton(props: IconButtonProps) {
  return <Layout {...props} />;
}

export function OpenFormIconButton(
  props: {
    form: ReactElement,
    title?: string,
    type?: IconType,
    className?: string,
    disabled?: boolean
  }
) {
  const popupFormManager = usePopupFormManager();

  const type = props.type || IconType.PENCIL;

  return (
    <IconButton
      onClick={() => popupFormManager.openForm(props.form)}
      type={type}
      title={props.title}
      className={props.className}
      disabled={props.disabled}
    />
  );
}

export function IconDeleteButton(
  props: {
    deleteFunc: () => Promise<void>,
    message: string,
    className?: string
  }
) {
  const popupManager = usePopupManager();

  return (
    <IconButton
      onClick={async () => {
        popupManager.openPopup(
          <ConfirmDeletePopup
            message={props.message}
            deleteFunc={props.deleteFunc}
          />
        );
      }}
      type={IconType.TRASH_CAN}
      className={props.className}
    />
  );
}

