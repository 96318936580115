import { ProjectType, useBackendApi } from 'nekst-api';

type ServiceProviderLink = {
  personId: number
  serviceProviderGroupId: number
}

export type DefaultPortalConfiguration = {
  isEnabled: boolean
  title: string
  showAllTransactionParties: boolean
  detailFieldsIds: number[]
  serviceProvidersLinks: ServiceProviderLink[]
  documentLabelsIds: number[]
}

export default function useDefaultPortalConfigurationService() {

  const backendApi = useBackendApi();

  const getPortalConfiguration = async(projectType: ProjectType) => {
    return await backendApi.get('/settings/clientportal/' + projectType + '/configuration') as DefaultPortalConfiguration;
  };

  const updatePortalConfiguration = async(projectType: ProjectType, request: DefaultPortalConfiguration) => {
    return await backendApi.put('/settings/clientportal/' + projectType + '/configuration', request) as DefaultPortalConfiguration;
  }

  return {
    getPortalConfiguration,
    updatePortalConfiguration
  }
}
