import React, { ReactElement } from 'react';
import styles from '../../projects/view/tasks/list/filter/Advanced/AssigneeFilter.module.scss';
import RelativeRow, { Cell, EmptyCell } from '../web/uitheme/form/RelativeRow';
import {
  AssigneeRowLayoutProps,
} from '../../projects/view/tasks/list/filter/Advanced/assigneeFilterService';
import CheckboxLayout from '../web/uitheme/input/CheckboxLayout';
import useUiTheme from '../uibuilder/uiTheme';
import { useFormContext } from '../uibuilder/form/FormContext';
import ErrorsWrapper from '../uibuilder/form/input/layout/ErrorsWrapper';

function RowLayout(props: AssigneeRowLayoutProps) {

  const uiTheme = useUiTheme();

  return (
    <RelativeRow weights={[8, 4]} rowClassName={styles.row}>
      <span className={styles.label}>{props.name}</span>
      <Cell centered>
        <uiTheme.inputs.CheckboxLayout
          name="includedCheckbox"
          value={props.isChecked}
          onChangeCallback={() => {
            props.changeFunc(!props.isChecked);
          }}
          isVisible
          isDisabled={false}
        />
      </Cell>
    </RelativeRow>
  );
}

interface Props {
  title: string;
  options: {
    value: number | string
    label: ReactElement | string
  }[];
  filterName: string;
  defaultValue?: {
    selectAll: boolean,
    selected: number[],
  };
}

export default function FilterIncludedExcludedInput(props: Props) {

  const formContext = useFormContext();

  const defaultValue = props.defaultValue || {
    selectAll: true,
    selected: [],
  };

  const filterData: {
    selectAll: boolean,
    selected: (number | string)[],
  } = formContext.data![props.filterName] || defaultValue;

  // eslint-disable-next-line react/no-unstable-nested-components
  function SelectAllControl() {
    return (
      <div className={styles.selectAllContainer}>
        <CheckboxLayout
          name="selectAll"
          value={filterData.selectAll}
          onChangeCallback={() => {
            if (filterData.selectAll) {
              formContext.onChangeCallback!({
                [props.filterName]: {
                  selectAll: false,
                  selected: [],
                },
              });
            } else {
              formContext.onChangeCallback!({
                [props.filterName]: {
                  selectAll: true,
                  selected: [],
                },
              });
            }
          }}
          label="Include All"
          isVisible
          isDisabled={false}
        />
      </div>
    );
  }

  function isAssigned(id: number | string) {
    if (filterData.selectAll) {
      return true;
    } else {
      return !!filterData.selected.find((selectedId) => id === selectedId);
    }
  }

  function selectAssignee(userId: number | string) {
    if (!isAssigned(userId)) {
      let newValue = {
        selected: [...filterData.selected],
        selectAll: false,
      };
      newValue.selected.push(userId);

      if (newValue.selected.length === props.options.length) {
        newValue = {
          selectAll: true,
          selected: [],
        };
      }

      formContext.onChangeCallback!({
        [props.filterName]: newValue,
      });
    }
  }

  function unselectAssignee(userId: number | string) {
    if (isAssigned(userId)) {
      let currentlySelected;

      if (filterData.selected && filterData.selected.length > 0) {
        currentlySelected = filterData.selected;
      } else {
        currentlySelected = props.options.map((v) => v.value);
      }

      if (currentlySelected.length > 1) {
        const indexOf = currentlySelected.findIndex((item) => item === userId);

        const newValue = {
          selected: [...currentlySelected],
          selectAll: false,
        };
        newValue.selected.splice(indexOf, 1);

        formContext.onChangeCallback!({
          [props.filterName]: newValue,
        });
      }
    }
  }

  return (
    <ErrorsWrapper name={props.filterName}>
      <>
        <div className={styles.titleRow}>
          <h4>{props.title.toUpperCase()}</h4>
          <SelectAllControl />
        </div>
        <RelativeRow weights={[8, 4]} rowClassName={`${styles.row} ${styles.header}`}>
          <EmptyCell />
          <Cell centered>Included</Cell>
        </RelativeRow>
        {props.options
          .map((option) => {
            return (
              <RowLayout
                key={`filter-${props.filterName}-${option.value}`}
                name={option.label}
                isChecked={isAssigned(option.value!)}
                changeFunc={(value: boolean) => {
                  if (value) {
                    selectAssignee(option.value);
                  } else {
                    unselectAssignee(option.value);
                  }
                }}
              />
            );
          })}
      </>
    </ErrorsWrapper>
  );
}

FilterIncludedExcludedInput.prototype.defaultProps = {
  defaultValue: {
    selectAll: true,
    selected: [],
  },
};
