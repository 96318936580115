import React from 'react';
import DueDateWidget from './DueDate/DueDateWidget';
import TypeWidget from './Advanced/TypeWidget';
import StatusWidget from './Advanced/StatusWidget';
import AssigneesWidget from './Advanced/AssigneesWidget';
import TasksGroupFilter from './Group/TasksGroupFilter';

export default function FilterWidgets(props: {
  showDueDateWidget?: boolean,
  showStatusWidget?: boolean,
}) {
  const showDueDateWidget = props.showDueDateWidget === undefined || props.showDueDateWidget;
  const showStatusWidget = props.showStatusWidget === undefined || props.showStatusWidget;
  return (
    <>
      {showStatusWidget && (<StatusWidget />)}
      <AssigneesWidget />
      <TypeWidget />
      <TasksGroupFilter />
      {showDueDateWidget && (<DueDateWidget />)}
    </>
  );
}
