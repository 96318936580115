import React, { ReactElement, useState } from 'react';
import { useEventsSubscriber } from 'event-bus';
import { PLAN_PROJECT_TYPE_UPDATED, usePlansService } from 'nekst-api';
import { AuthorizationScope } from './index';

export enum PlanPermission {
  UPDATE = 'Plan:UPDATE',
  UPDATE_TYPE = 'Plan:UPDATE_TYPE',
  DELETE = 'Plan:DELETE',
  READ = 'Plan:READ',
  SHARE = 'Plan:SHARE',
  CREATE_TASK = 'Plan:CREATE_TASK',
}

export interface PlanAuthorizationScopeProps {
  children: ReactElement | ReactElement[];
  id: number;
  excludedPermissions?: PlanPermission[];
  permissionToCheck?: string;
}

function useDependencyOnErrors(
  id: string,
  events: string[],
) {
  const [version, setVersion] = useState(0);

  const subscribes: Record<string, () => void> = {};

  events.forEach((event) => {
    subscribes[event] = () => {
      setVersion((prev) => prev + 1);
    };
  });

  useEventsSubscriber(
    id,
    subscribes,
  );

  return {
    version,
  };
}

export function PlanAuthorizationScope(props: PlanAuthorizationScopeProps) {
  const plansService = usePlansService();

  const { version } = useDependencyOnErrors(
    'PlanAuthorizationScope',
    [
      PLAN_PROJECT_TYPE_UPDATED,
    ],
  );

  const getPermissions = async (id: number) => {
    const permissions = await plansService.getPermissions(id);

    const permissionsToExclude = props.excludedPermissions || [];

    try {
      const plan = await plansService.getById(id);

      if (plan && !plan.projectType) {
        permissionsToExclude.push(
          PlanPermission.CREATE_TASK,
          PlanPermission.SHARE,
          PlanPermission.UPDATE,
        );
      }
    } catch (e) {
      // in case plan is not found - not apply this logic
    }

    permissionsToExclude.forEach((permission) => {
      const index = permissions.indexOf(permission.replace('Plan:', ''));

      if (index !== -1) {
        permissions.splice(index, 1);
      }
    });

    return permissions;
  };

  return (
    <AuthorizationScope
      getPermissionsFunc={getPermissions}
      scopeName="Plan"
      permissionToCheck={props.permissionToCheck}
      id={props.id}
      dependencies={[version]}
    >
      {props.children}
    </AuthorizationScope>
  );
}
