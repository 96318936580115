import React from 'react';
import { SeparatorLayoutProps } from '../types';

import styles from './Separator.module.scss';

export default function SeparatorLayoutWeb(
  props: SeparatorLayoutProps,
) {
  const marginSize = props.marginSize || 'medium';

  const classNames = [
    styles.separator,
    `margin-${marginSize}`,
    props.isBold ? styles.bold : '',
  ];

  return (
    <div className={classNames.join(' ')}>&nbsp;</div>
  );
}
