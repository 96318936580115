import React, {
  ReactElement,
  useCallback,
  useEffect, useRef,
  useState,
} from 'react';
import {
  useBlocker,
  useBeforeUnload,
  useParams,
  Location,
} from 'react-router-dom';
import ProjectAuthorizationScope, { ProjectPermission } from '../view/ProjectAuthorizationScope';
import ProjectContext, { ProjectBreadcrumb, useProjectContext } from '../view/ProjectContext';
import MainBlock from '../../shared/web/layout/MainBlock';

import styles from './LaunchProjectWrapper.module.scss';
import { Breadcrumb } from '../../shared/web/layout/Breadcrumbs';
import { DateField, TextField } from 'ui-builder';
import { ParseContractDataContext } from 'features/tasks-forms';
type BlockerFunction = (args: {
  currentLocation: Location;
  nextLocation: Location;
}) => boolean;

export interface LaunchProjectWrapperProps {
  children: ReactElement | ReactElement[];
  withParseContractData?: boolean,
}

function ProjectDateRangeField() {
  return (
    <span className={styles.dateRange}>
      <DateField source="startDate" />
      &nbsp;
      -
      &nbsp;
      <DateField source="endDate" />
    </span>
  );
}

function PlanLoader() {

  const projectContext = useProjectContext();

  useEffect(() => {
    projectContext.loadPlan();
  }, []);

  return null;
}

function PlanName() {
  const projectContext = useProjectContext();

  if (projectContext.data?._plan) {
    return (
      <span className={styles.planName}>
        <b>Plan:</b>
        &nbsp;
        {projectContext.data?._plan.name}
      </span>
    );
  } else {
    return null;
  }
}

export function LaunchProjectBreadcrumbs() {
  return (
    <ProjectBreadcrumb>
      <Breadcrumb text="Launch" />
    </ProjectBreadcrumb>
  );
}

interface BlockerFunctionParams {
  nextLocation: Location,
}

function usePrompt(onLocationChange: BlockerFunction) {
  const blocker = useBlocker(onLocationChange);
  const prevState = useRef(blocker.state);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);
}

export function FormPrompt(props: {
  projectId: number,
}) {
  useBeforeUnload(
    useCallback(
      (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = '';
      },
      [],
    ),
    { capture: true },
  );

  const onLocationChange = useCallback(
    ({ nextLocation }: BlockerFunctionParams) => {
      if (!nextLocation.pathname.startsWith(`/transactions/${props.projectId}/launch`) && !nextLocation.search.includes('?afterLaunch=1')) {
        // eslint-disable-next-line no-alert
        return !window.confirm(
          'By exiting before completing the steps to Launch new tasks, no new tasks will be applied to the transaction.',
        );
      } else {
        return false;
      }
    },
    [],
  );

  usePrompt(onLocationChange);

  return null;
}

export default function LaunchProjectWrapper(props: LaunchProjectWrapperProps) {
  const params = useParams();

  const [projectId, setProjectId] = useState<number>(parseInt(params.id!, 10));

  useEffect(() => {
    setProjectId(parseInt(params.id!, 10));
  }, [params.id!]);

  const ParseDataContext = props.withParseContractData ? ParseContractDataContext : ({ children }: {
    children: React.ReactNode,
    projectId: number
  }) => children;

  return (
    <ProjectAuthorizationScope
      id={projectId}
      permissionToCheck={ProjectPermission.UPDATE}
    >
      <ProjectContext projectId={projectId}>
        <ParseDataContext projectId={projectId}>
          <LaunchProjectBreadcrumbs />
          <PlanLoader />
          <MainBlock
            title={<TextField source="name" />}
            titleLine2={<PlanName />}
            headerRight={<ProjectDateRangeField />}
          >
            {props.children}
          </MainBlock>
          <FormPrompt projectId={projectId} />
        </ParseDataContext>
      </ProjectContext>
    </ProjectAuthorizationScope>
  );
}
