import React from 'react';
import ReactDOM from 'react-dom';

import BorderedBox from '../../../../shared/web/layout/BorderedBox';

import styles from './AssigneeFilter.module.scss';
import { CheckboxGroup, FormTemplate } from 'ui-builder';
import { usePlanTasksTypeFilterService, usePlanTasksSmsRecipientFilterService } from 'features/tasks-forms';

export default function SmsEmailRecipientFilter() {
  const container = document.getElementById('filter2');


  const filterService = usePlanTasksSmsRecipientFilterService();

  const typeFilterService = usePlanTasksTypeFilterService();
  const typeFilterValue = typeFilterService.getFilterValue();


  const setFilterValue = (assignees: number[]) => {
    filterService.setFilterValue(assignees);
  };

  const options = filterService.getOptions();

  if (container && options.length > 0 && (
    typeFilterValue?.showEmailTasks || typeFilterValue?.showSmsTasks
  )) {
    return ReactDOM.createPortal(
      <BorderedBox title="Email/SMS Recipient" className={styles.filter}>
        <FormTemplate
          submitFormFunc={async (data) => {
            setFilterValue(data.assignees as number[]);
          }}
          initialData={{
            assignees: filterService.getFilterValue(),
          }}
          submitOnEachChange
          buttons={null}
        >
          <CheckboxGroup options={options} source="assignees" hideLabel />
        </FormTemplate>
      </BorderedBox>,
      container,
    );
  } else {
    return null;
  }
}
