import React from 'react';
import InnerBlock from '../../shared/web/layout/InnerBlock';
import Right from '../../shared/web/Right';
import { OpenHistoryFilterButton } from '../../projects/history/filter/HistoryFilterForm';
import styles from '../../projects/history/History.module.scss';
import HistoryEventFullTextFilter from '../../projects/history/filter/HistoryEventFullTextFilter';
import FilterWidgets from '../../projects/history/filter/FilterWidgets';
import InfiniteScrollWrapper from '../../shared/uibuilder/list/layout/InfiniteScrollWrapper';
import ByDateGrouper from '../../projects/history/ByDateGrouper';
import LoadMorePaginationList, {
  LoadMoreResponse,
} from '../../shared/uibuilder/list/LoadMorePaginationList';
import { HistoryEntry, TeamMemberHistoryFilter } from 'nekst-api';

export default function HistoryEventsList(
  props: {
    getEventsFunc: (
      filter: TeamMemberHistoryFilter | null,
      nextPageToken: string | number | null,
    ) => Promise<LoadMoreResponse<HistoryEntry>>
  },
) {
  return (
    <LoadMorePaginationList<HistoryEntry, TeamMemberHistoryFilter>
      getDataFunc={props.getEventsFunc}
    >
      <InnerBlock
        title="Account History"
        headerRight={(
          <>
            <Right><OpenHistoryFilterButton showAuthorFilter={false} /></Right>
            <Right className={styles.textSearchBlock}><HistoryEventFullTextFilter /></Right>
          </>
        )}
        showHeaderBorder
      >
        <FilterWidgets />
        <InfiniteScrollWrapper>
          <ByDateGrouper noDataText="There are no events." />
        </InfiniteScrollWrapper>
      </InnerBlock>
    </LoadMorePaginationList>
  );
}
