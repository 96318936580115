import React, { ReactElement } from 'react';
import { HistoryEntry, Permission, useEventsPublisher } from 'nekst-api';
import { List, useShowContext } from 'ui-builder';
import styles
  from '../../../projects/view/tasks/CreateUpdate/comments/TaskCommentsList.module.scss';
import CommentRow from './CommentRow';
import ScrollableY from '../ScrollableY';
import CommentsListTheme from './CommentsListTheme';
import { CommentType, CommentRequestType } from './EditCommentForm';
import { POPUP_DATA_UPDATED, RowRepeater } from 'features/nekst-widgets';

export type CommentsListMessages = {
  textInputLabel: string,
  noDataText: string,
}

interface CommentsListProps<
  Data = CommentType,
  Request extends CommentRequestType = CommentRequestType
> {
  getDataFunc: () => Promise<Data[]>,
  title: ReactElement | string
  children?: ReactElement
  deleteItemFunc: (id: number) => Promise<void>
  updateItemFunc: (id: number, data: Request) => Promise<Data>
  deletePermission: Permission
  updatePermission: Permission
  // eslint-disable-next-line react/require-default-props
  maxCommentsHeight?: number
  messages?: Partial<CommentsListMessages>
  // eslint-disable-next-line react/no-unused-prop-types
  dataKey?: string,
  // eslint-disable-next-line react/no-unused-prop-types
  additionalInputs?: ReactElement,
  // eslint-disable-next-line react/no-unused-prop-types
  viewFields?: ListOrSingle<ReactElement>,
  dateTimeText?: string
  dependencies?: any[]
}

interface CommentsAndEventsListProps<Data = CommentType>
  extends CommentsListProps<Data | HistoryEntry> {
  eventRow: ReactElement,
}

function CommentsAndEventsRow(
  props: {
    commentRow: ReactElement,
    eventRow: ReactElement,
  },
) {
  const showContext = useShowContext<{ _type: 'COMMENT' | 'EVENT' }>();

  if (showContext.data!._type === 'EVENT') {
    return props.eventRow;
  } else {
    return props.commentRow;
  }
}

export function CommentsAndEventsList<
  Data extends CommentType = CommentType,
  Request extends CommentRequestType = CommentRequestType,
>(props: CommentsAndEventsListProps<Data>) {
  const messages: CommentsListMessages = {
    noDataText: props.messages?.noDataText || 'No comments yet',
    textInputLabel: props.messages?.textInputLabel || 'Enter Comment Here',
  };

  let rowRepeater = (
    <RowRepeater
      groupClassName={styles.commentRow}
      noDataMessage={messages.noDataText}
    >
      <CommentsAndEventsRow
        commentRow={(
          <CommentRow<Data, Request>
            deleteItemFunc={props.deleteItemFunc}
            // @ts-ignore
            updateItemFunc={props.updateItemFunc}
            deletePermission={props.deletePermission}
            updatePermission={props.updatePermission}
            textSource="_renderedText"
          />
        )}
        eventRow={props.eventRow}
      />
    </RowRepeater>
  );

  if (props.maxCommentsHeight) {
    rowRepeater = (
      <ScrollableY maxHeight={props.maxCommentsHeight} showViewAll>
        {rowRepeater}
      </ScrollableY>
    );
  }

  const eventsPublisher = useEventsPublisher();

  return (
    <CommentsListTheme messages={messages}>
      <List
        getDataFunc={props.getDataFunc}
        onDataUpdated={() => eventsPublisher.publish(POPUP_DATA_UPDATED, {})}
      >
        <div className={styles.widget}>
          {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
          <>{props.title}</>
          {props.children}
          {rowRepeater}
        </div>
      </List>
    </CommentsListTheme>
  );
}

export default function CommentsList<
  Data extends CommentType = CommentType,
  Request extends CommentRequestType = CommentRequestType
>(props: CommentsListProps<Data, Request>) {
  const messages: CommentsListMessages = {
    noDataText: props.messages?.noDataText || 'No comments yet',
    textInputLabel: props.messages?.textInputLabel || 'Enter Comment Here',
  };

  let rowRepeater = (
    <RowRepeater
      groupClassName={styles.commentRow}
      noDataMessage={messages.noDataText}
    >
      <CommentRow
        deleteItemFunc={props.deleteItemFunc}
        updateItemFunc={props.updateItemFunc}
        deletePermission={props.deletePermission}
        updatePermission={props.updatePermission}
        additionalInputs={props.additionalInputs}
        viewFields={props.viewFields}
        dateTimeText={props.dateTimeText}
      />
    </RowRepeater>
  );

  if (props.maxCommentsHeight) {
    rowRepeater = (
      <ScrollableY maxHeight={props.maxCommentsHeight} showViewAll>
        {rowRepeater}
      </ScrollableY>
    );
  }

  return (
    <CommentsListTheme messages={messages}>
      <List
        getDataFunc={props.getDataFunc}
        dependencies={props.dependencies}
      >
        <div className={styles.widget} data-key={props.dataKey}>
          {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
          <>{props.title}</>
          {props.children}
          {rowRepeater}
        </div>
      </List>
    </CommentsListTheme>
  );
}
