import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  PLAN_CREATED,
  PLAN_DELETED, PLAN_PROJECT_TYPE_UPDATED,
  PLAN_UPDATED,
  ProjectType,
  useEventsSubscriber,
  usePlansService
} from 'nekst-api';
import { Dropdown, Option, TextLink } from 'ui-builder';
import { ProjectTypeDropdown } from 'features/tasks-forms';
import Row1x2x from '../../shared/web/uitheme/form/Row1x2x';

export interface ChoosePlanDropdownProps {
  value?: number;
}

const ALL_VALUE = 'ALL';

export default function ChoosePlanDropdown(props: ChoosePlanDropdownProps) {
  const [loaded, setLoaded] = useState<boolean>(false);

  const [projectType, setProjectType] = useState<ProjectType | 'ALL'>(ALL_VALUE);

  const navigate = useNavigate();

  const plansService = usePlansService();

  const [plansOptions, setPlansOptions] = useState<Option[]>([]);

  const [version, setVersion] = useState(0);

  const loadPlans = async (): Promise<void> => {
    const plans = await plansService.getAllTeamPlans();

    const options = plans.map(
      (plan) => {

        let label = plan.name;

        if (plan._detailsFieldsSet) {
          label += ` (${plan._detailsFieldsSet.name})`;
        }

        return {
          value: plan.id,
          label: label,
          projectType: plan.projectType,
        };
      },
    );

    setPlansOptions(options);

    setVersion((prev) => prev + 1);
    setLoaded(true);
  };

  useEventsSubscriber(
    'ChoosePlanDropdown',
    {
      [PLAN_UPDATED]: loadPlans,
      [PLAN_CREATED]: loadPlans,
      [PLAN_DELETED]: loadPlans,
      [PLAN_PROJECT_TYPE_UPDATED]: (eventData) => {
        loadPlans();
        setProjectType(eventData.projectType);
      }
    },
    [],
    loadPlans,
  );

  const visiblePlanOptions = useMemo(() => {
    if (projectType === 'ALL') {
      return plansOptions;
    } else {
      return plansOptions.filter((item) => {
        return item.projectType === projectType;
      });
    }
  }, [projectType, version]);

  if (loaded) {
    if (!props.value) {
      return (
        <Row1x2x>
          <ProjectTypeDropdown
            label="Transaction Type"
            source="projectType"
            onChangeCallback={(event) => {
              setProjectType(event.projectType);
            }}
            value={projectType}
            additionalOptions={[{
              value: ALL_VALUE,
              label: 'All'
            }]}
          />

          <Dropdown
            options={visiblePlanOptions}
            source="dropdown"
            placeholder={!projectType ? 'Choose transaction type' : 'Choose plan'}
            label="Select an existing plan"
            value={props.value || null}
            onChangeCallback={(v) => {
              if (v.dropdown) {
                navigate(`/plans/${v.dropdown}`);
              } else {
                navigate('/plans');
              }
            }}
          />
        </Row1x2x>
      );
    } else {
      return (
        <TextLink
          href="/plans"
          onClick={() => {
            navigate('/plans');
          }}
        >
          ← Go back to the plans list
        </TextLink>
      )
    }
  } else {
    return (<div>Loading...</div>);
  }
}
