import React from 'react';
import {
  CreateProjectDetailsFieldRequest,
  ProjectDetailsField,
  ProjectType,
  useProjectDetailsFieldsService
} from 'nekst-api';
import ProjectDetailsFieldTypeDropdown from './ProjectDetailsFieldTypeDropdown';
import {
  AfterSubmitFunc,
  Button,
  ButtonColor,
  ButtonSize,
  FormTemplate,
  IconType,
  OptionSelectionButton,
  TextInput,
} from 'ui-builder';
import { usePopupFormManager } from 'features/nekst-widgets';
import useFeatureToggleService from 'feature-toggle';
import CreateDetailsFieldsSetForm from '../Sets/CreateUpdate/CreateDetailsFieldsSetForm';

function CreateProjectDetailsFieldForm(
  props: {
    projectType: ProjectType,
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {
  const projectDetailsFieldsService = useProjectDetailsFieldsService();

  return (
    <FormTemplate<CreateProjectDetailsFieldRequest, ProjectDetailsField>
      title="Add Detail Box"
      submitFormFunc={(request: CreateProjectDetailsFieldRequest) => {
        return projectDetailsFieldsService.createField(props.projectType, request);
      }}
      afterSubmitFunc={props.afterSubmitFunc}
      validationSchema={{
        name: {
          type: 'string',
          constraints: {
            required: true,
            maxLength: 40,
          },
        },
        fieldType: {
          type: 'string',
          constraints: {
            required: true,
          },
        },
      }}
    >
      <TextInput source="name" label="Name" />
      <ProjectDetailsFieldTypeDropdown source="fieldType" label="Field Type" />
    </FormTemplate>
  );
}

export default function CreateProjectDetailsFieldButton(props: {
  projectType: ProjectType,
}) {
  const popupManager = usePopupFormManager();

  const featureToggleService = useFeatureToggleService();

  if (featureToggleService.isDetailsFieldSetsEnabled()) {
    return (
      <OptionSelectionButton
        text="Add"
        options={[
          {
            label: 'Details Box',
            value: 'details_box',
            onSelected: () => {
              popupManager.openForm(
                <CreateProjectDetailsFieldForm projectType={props.projectType} />,
              );
            }
          },
          {
            label: 'Details Box Set',
            value: 'details_box_set',
            onSelected: () => {
              popupManager.openForm(
                <CreateDetailsFieldsSetForm projectType={props.projectType} />,
              );
            }
          }
        ]}
        size={ButtonSize.MEDIUM}
      />
    );
  } else {
    return (
      <Button
        text="Add Detail Box"
        color={ButtonColor.BLUE}
        iconType={IconType.PLUS}
        size={ButtonSize.MEDIUM}
        isFilled
        onClick={() => {
          popupManager.openForm(
            <CreateProjectDetailsFieldForm projectType={props.projectType} />,
          );
        }}
      />
    );
  }
}
