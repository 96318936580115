/* istanbul ignore file */
import React from 'react';
import { FormRowsProps } from 'ui-builder';

import RelativeRow from './RelativeRow';

export default function Row6x6x(props: FormRowsProps) {
  return (
    <RelativeRow
      weights={[6, 6]}
      rowClassName={props.className}
      dataKey={props.dataKey}
    >
      {props.children}
    </RelativeRow>
  );
}
