import React from 'react';
import { Navigate } from 'react-router-dom';
import LoginForm from './LoginForm';
import styles from '../Login.module.scss';
import { useAuthenticationContext } from '../../authentication/AuthenticationContext';
import PageTitle from '../../shared/web/PageTitle';

export default function LoginPage() {

  const authenticationContext = useAuthenticationContext();

  if (!authenticationContext?.user) {
    return (
      <div className={styles.page}>
        <PageTitle text="Login" />
        <LoginForm />
      </div>
    );
  } else {
    return (<Navigate to="/tasks" replace />);
  }
}
