import useImageResizer, { ImageResizerOutput } from 'image-resizer';
import { useBackendApi } from 'nekst-api';

export interface KeyDateLink {
  taskId: number,
  description?: string,
}

export interface ServiceProviderLink {
  serviceProviderGroupId: number,
  personId: number
  taskId?: number
}

export interface ClientPortalConfiguration {
  title?: string
  enabled: boolean,
  detailFieldsIds: number[],
  keyDatesLinks: KeyDateLink[],
  serviceProvidersLinks: ServiceProviderLink[]
  contactsIds: number[],
  documentsIds: number[],
  teamMemberForBrandingId?: number,
  _photo?: {
    resized: string,
    full: string
  },
  _portalUrl?: string,
}

function dataUriToBlob(dataURI: string) {
  const splitDataURI = dataURI.split(',');
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export default function useClientPortalConfigurationService() {

  const imageResizer = useImageResizer();

  const nekstApi = useBackendApi();
  const getConfiguration = async (projectId: number) => {
    return await nekstApi.get(`/projects/${projectId}/clientportal`) as ClientPortalConfiguration;
  };

  const updateConfiguration = async (projectId: number, request: ClientPortalConfiguration) => {
    return await nekstApi.put(
      `/projects/${projectId}/clientportal`,
      request,
    ) as ClientPortalConfiguration;
  };

  const updatePhoto = async (projectId: number, photo: string) => {
    const formData = new FormData();
    const blob = dataUriToBlob(photo);
    const uploadedFile = await imageResizer.resizeImage(
      blob,
      1400,
      1400,
      ImageResizerOutput.BLOB,
    ) as Blob;

    formData.append('file', uploadedFile, 'photo.jpg');

    return await nekstApi.post(`/projects/${projectId}/photo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }) as ClientPortalConfiguration;
  };

  const deletePhoto = async (projectId: number) => {
    return await nekstApi.delete(`/projects/${projectId}/photo`) as ClientPortalConfiguration;
  };

  return {
    getConfiguration,
    updateConfiguration,
    updatePhoto,
    deletePhoto,
  };
}
