import React from 'react';
import RecordLayout, { RecordLayoutProps } from './RecordLayout';
import PersonLink from './PersonLink';
import CommentText from './CommentText';
import TaskLink from './TaskLink';
import { HistoryEventIconType } from './HistoryEventIcon';
import { BaseHistoryEntryData, CommentData, PersonData, TaskData } from 'nekst-api';

interface CommentCreatedData extends BaseHistoryEntryData {
  person: PersonData;
  comment: CommentData;
  task: TaskData;
}

export default function CommentCreatedRecordLayout(props: RecordLayoutProps<CommentCreatedData>) {
  return (
    <RecordLayout
      text={(
        <>
          <PersonLink person={props.record.data.person} />
          {' added the comment '}
          {' to the task '}
          <TaskLink
            task={props.record.data.task}
            project={props.record.data.project}
          />
          {': '}
          <CommentText comment={props.record.data.comment} />
          .
        </>
      )}
      date={props.record.when}
      iconType={HistoryEventIconType.COMMENT_CREATED}
    />
  );
}
