import React from 'react';
import {
  BaseInputProps,
  SmartTagsTextInputWithLoad,
  useFormContext
} from 'ui-builder';
import { TaskType, useSmartTagsService } from 'nekst-api';

export interface EmailTitleSmartTagsInputProps extends BaseInputProps {

}

export function EmailTitleSmartTagsInput(props: EmailTitleSmartTagsInputProps) {
  const smartTagsService = useSmartTagsService();
  const formContext = useFormContext();

  const projectId = formContext.data!.projectId;

  return (
    <SmartTagsTextInputWithLoad
      {...props}
      getTagsMethod={async () => {
        return await smartTagsService.getSmartTags(undefined, TaskType.EMAIL, projectId);
      }}
      dependencies={[projectId]}
    />
  );
}
