import React, { useEffect } from 'react';
import { FilterContext, ListWithPagination } from 'ui-builder';
import { Notification, useNotificationsService } from 'nekst-api';
import { PaginationWrapper, RowRepeater } from 'features/nekst-widgets';
import NotificationListLayout from './Layout/NotificationListLayout';
import { NotificationListProps } from './types';



export function NotificationsList(
  props: NotificationListProps
) {
  const notificationsService = useNotificationsService();

  useEffect(() => {
    const timer = setTimeout(() => {
      notificationsService.trackRead();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <FilterContext>
      <ListWithPagination<Notification, null>
        getDataFunc={async (_, limit) => {
          return notificationsService.getNotifications(limit);
        }}
        byPage={20}
      >
        <PaginationWrapper>
          <RowRepeater
            noDataMessage="Your notifications will appear here. You have not received any notifications yet."
          >
            <NotificationListLayout {...props} />
          </RowRepeater>
        </PaginationWrapper>
      </ListWithPagination>
    </FilterContext>
  );
}
