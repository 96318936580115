import React from 'react';

import { useParams } from 'react-router-dom';
import MainBlock from '../../shared/web/layout/MainBlock';
import TextField from '../../shared/uibuilder/show/field/TextField';
import ProjectNotes from './ProjectNotes';
import UpdateProjectForm from './UpdateProjectForm';
import DetailsFieldsList from './DetailsFieldsList';
import ProjectPagesButtons from '../view/ProjectPagesButtons';
import Separator from '../../shared/web/uitheme/form/Separator';
import { ProjectBreadcrumb } from '../view/ProjectContext';
import { Breadcrumb } from '../../shared/web/layout/Breadcrumbs';
import ProjectDetailsPageTour from './ProjectDetailsPageTour';
import { ProjectTypeField } from 'features/tasks-forms';
import { ProjectIdContext } from 'features/nekst-widgets';

function ProjectDetailsBreadcrumbs() {
  return (
    <ProjectBreadcrumb>
      <Breadcrumb text="Details" />
    </ProjectBreadcrumb>
  );
}

export default function ProjectDetails() {
  const params = useParams();

  const projectId = parseInt(params.id!, 10);
  return (
    <ProjectIdContext projectId={projectId}>
      <ProjectDetailsBreadcrumbs />
      <MainBlock
        title={<TextField source="name" />}
        titleLine2={<ProjectTypeField />}
        headerRight={(
          <ProjectPagesButtons />
        )}
        columnContent={(
          <UpdateProjectForm
            projectId={projectId}
          />
        )}
      >
        <DetailsFieldsList projectId={projectId} />
        <ProjectNotes projectId={projectId} />
        <Separator marginSize="big" />
        <ProjectDetailsPageTour />
      </MainBlock>
    </ProjectIdContext>
  );
}
