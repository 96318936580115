import React from 'react';
import useMyHistoryService from './myHistoryService';
import { useAuthenticationLoader } from '../../../authentication/AuthenticationContext';
import SinglePersonPage from '../../shared/SinglePersonPage';
import OpenUpdateProfilePageButton from '../UpdateProfile/OpenUpdateProfilePageButton';
import HistoryEventsList from '../../shared/HistoryEventsList';

export default function CurrentUserHistory() {
  const myHistoryService = useMyHistoryService();

  const { currentUser } = useAuthenticationLoader();

  return (
    <SinglePersonPage
      headerRight={<OpenUpdateProfilePageButton />}
      person={currentUser}
      columnContent={<div />}
    >
      <HistoryEventsList
        getEventsFunc={async (filter, nextPageToken) => {
          return myHistoryService.getHistoryEvents(filter, nextPageToken as number | undefined);
        }}
      />
    </SinglePersonPage>
  );
}
