import { ProjectType } from "./Types";
import { useBackendApi } from "./backendApi";
import { useEventsPublisher } from 'event-bus';

export type ProjectDatesFieldsConfiguration = {
  startDateFieldId?: number,
  endDateFieldId?: number,
}

export const PROJECT_DATES_FIELDS_UPDATED = 'PROJECT_DATES_FIELDS_UPDATED';

export function useProjectDatesFieldsService() {
  const backendApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const updateProjectDatesFields = async (projectType: ProjectType, configuration: ProjectDatesFieldsConfiguration) => {
    await backendApi.put(`/settings/projects/${projectType.toLowerCase()}/projectdates`, configuration);
    eventsPublisher.publish(PROJECT_DATES_FIELDS_UPDATED, {
      projectType,
    });
  };

  return {
    updateProjectDatesFields,
  }
}
