import { OptInStatus, ShortProfile } from 'nekst-api';
import React from 'react';
import { OptInMessageType } from './types';
import Layout from './Layout';
import { ViewStyle } from 'react-native';

export function SmsOptInStatus(
  props: {
    recipient: ShortProfile,
    style?: ViewStyle,
  }
) {
  const optInStatus = props.recipient._phoneNumberForSms?.optInStatus;

  let message: string;
  let messageType: OptInMessageType;
  let showRequestOptInButton = false;

  switch (optInStatus) {
    case OptInStatus.CONSENT_RECEIVED:
      message = 'The SMS message can be sent.';
      messageType = 'ok';
      break;
    case OptInStatus.NOT_SENT:
    case OptInStatus.WAITING_FOR_CONSENT:
      message = 'We are unable to send the SMS message at this time because the contact has not yet opted in to receive text messages from us.';
      messageType = 'warning';
      showRequestOptInButton = true;
      break;
    case OptInStatus.OPTED_OUT:
      message = 'The contact has opted out of receiving SMS from us, so we cannot send the SMS message at this time.';
      messageType = 'error';
      break;
    default:
      message = 'The SMS message cannot be sent until a valid mobile phone number is provided.';
      messageType = 'error';
  }

  return (
    <Layout
      recipient={props.recipient}
      message={message}
      messageType={messageType}
      showRequestOptInButton={showRequestOptInButton}
      style={props.style}
    />
  );
}
