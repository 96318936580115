import React from 'react';
import { createPortal } from 'react-dom';
import { Button, AfterSubmitFunc, ButtonColor, FormTemplate, IconType, useListContext } from 'ui-builder';
import useProjectDocumentsService, { ProjectDocument } from './projectDocumentsService';
import DocumentLabelsDropdown from '../../settings/documentstorage/labels/DocumentLabelsDropdown';
import { usePopupFormManager } from 'features/nekst-widgets';
import { ProjectPermission, withAccessCheck } from 'authorization-scope';

export function AddLabelsButtonPortal() {
  return <span id="add-labels-button" />;
}

function AddLabelsForm(props: {
  projectId: number,
  usedLabelsIds: number[],
  afterSubmitFunc?: AfterSubmitFunc,
}) {
  const service = useProjectDocumentsService();
  return (
    <FormTemplate<{ labelsIds: number[] }>
      title="Add Labels"
      submitFormFunc={async (data) => {
        await service.addLabels(props.projectId, data.labelsIds);

        return data;
      }}
      afterSubmitFunc={props.afterSubmitFunc}
    >
      <DocumentLabelsDropdown
        source="labelsIds"
        label="Labels"
        excludeIds={props.usedLabelsIds}
      />
    </FormTemplate>
  );
}

function AddLabelsButton(props: {
  projectId: number
}) {

  const listContext = useListContext<ProjectDocument>();

  const usedLabelsIds = listContext.data?.filter((item) => item.labelId)
    .map((item) => item.labelId!) || [];

  const popupManager = usePopupFormManager();

  const portal = document.getElementById('add-labels-button');

  if (portal) {
    return createPortal(
      <Button
        text="Add Labels"
        iconType={IconType.PLUS}
        color={ButtonColor.BLUE}
        onClick={() => {
          popupManager.openForm(
            <AddLabelsForm
              projectId={props.projectId}
              usedLabelsIds={usedLabelsIds}
            />,
          );
        }}
      />,
      portal,
    );
  } else {
    return null;
  }
}

export default withAccessCheck(AddLabelsButton, ProjectPermission.CREATE_DOCUMENTS);
