import React from 'react';
import { createPortal } from 'react-dom';
import FormTemplate, { AfterSubmitFunc } from '../../shared/uibuilder/form/FormTemplate';
import useProjectDocumentsService, { ProjectDocument } from './projectDocumentsService';
import FileUploaderInput from '../../shared/file/FileUploaderInput';
import { Button, Dropdown, IconType, useListContext } from 'ui-builder';
import { usePopupFormManager } from 'features/nekst-widgets';
import { ProjectPermission, useAuthorizationChecker } from 'authorization-scope';
import { FileType } from 'nekst-api';

export function CreateDocumentButtonPortal() {
  return (
    <span id="create-document-button" />
  );
}

function LabelsDropdown(props: {
  documents: ProjectDocument[],
}) {
  if (props.documents.length) {
    return (
      <Dropdown
        options={props.documents.map((item) => ({
          value: item.labelId!,
          label: item._label.name,
        }))}
        source="labelId"
        label="Document Label"
        placeholder="Without Label"
      />
    );
  } else {
    return null;
  }
}

function CreateDocumentForm(props: {
  afterSubmitFunc?: AfterSubmitFunc,
  projectId: number,
  documentsWithoutFile: ProjectDocument[],
}) {
  const service = useProjectDocumentsService();

  return (
    <FormTemplate<{ labelId?: number, fileId: number }>
      afterSubmitFunc={props.afterSubmitFunc}
      title="Upload Document"
      submitFormFunc={async (data) => {
        if (data.labelId) {
          const document = props.documentsWithoutFile
            .find((doc) => doc.labelId === data.labelId);

          if (document) {
            await service.setFile(document.id, data.fileId);
          }
        } else {
          await service.createDocument(props.projectId, data.fileId);
        }
        return data;
      }}
    >
      <FileUploaderInput
        fileType={FileType.PROJECT_DOCUMENT}
        fileDataSource="_file"
        source="fileId"
        label="File"
      />
      <LabelsDropdown documents={props.documentsWithoutFile} />
    </FormTemplate>
  );
}

export default function CreateDocumentButton(props: {
  projectId: number,
}) {
  const portal = document.getElementById('create-document-button');

  const listContext = useListContext<ProjectDocument>();

  const documentsWithoutFile = listContext.data!.filter(
    (item) => item.labelId && !item.fileId,
  );

  const popupManager = usePopupFormManager();

  const { isGranted } = useAuthorizationChecker();

  if (isGranted(ProjectPermission.CREATE_DOCUMENTS) && portal) {
    return createPortal(
      <Button
        text="Upload Document"
        iconType={IconType.PLUS}
        onClick={() => {
          popupManager.openForm(
            <CreateDocumentForm
              documentsWithoutFile={documentsWithoutFile}
              projectId={props.projectId}
            />,
          );
        }}
      />,
      portal,
    );
  } else {
    return null;
  }
}
