import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AiDetailsFieldTrainingData,
  ContractTemplate,
  DetailsFieldsSet,
  ProjectDetailsField,
  ProjectDetailsFieldType,
  ProjectType,
  TRAINING_DATA_REMOVED,
  UpdateDetailsFieldTrainingDataRequest,
  useAiTrainingExampleService,
  useContractAiTrainingDataService,
  useContractTemplatesService,
  useEventsSubscriber,
  useProjectDetailsFieldsService
} from 'nekst-api';
import {
  Button,
  ButtonColor,
  ButtonSize,
  CheckboxInput,
  Dropdown,
  FilterContext,
  FormRows,
  FormTemplate,
  Header3,
  List,
  LoadingAnimation,
  SubmitButton,
  TextArea,
  TextInput,
  useFilterContext,
  useFormContextRequired,
  useListContextRequired
} from 'ui-builder';

import styles from './SelectContractContextWidget.module.scss';
import { IconButton, IconDeleteButton, IconType, Separator } from 'features/nekst-widgets';
import useExampleContentHelper from './exampleContentHelper';
import ExampleValueInput from './ExampleValueInput';

export function SelectLinesWidget(
  props: {
    fileText: string,
    usedStatements: Record<number, {
      id: number
      name: string,
      lines: number[],
    }>,
    activeStatementId?: number,
    onClick?: (index: number) => void,
    onLinesSelected: (lines: number[]) => void,
    onLinesUnselected: (lines: number[]) => void,
  },
) {

  const [selectedStatementId, setSelectedStatementId] = useState<number>();

  const [multiLinesSelection, setMultiLinesSelection] = useState<{
    lines: number[],
    startLine: number,
    mode: 'select' | 'unselect',
  }>();

  const defaultColors = [
    'rgba(83,172,97,0.3)', 'rgba(25,127,155,0.3)', 'rgba(68,56,3,0.3)', 'rgba(10,28,75,0.3)',
    'rgba(74,114,56,0.3)', 'rgba(76,76,76,0.3)', 'rgba(138,34,22,0.3)', 'rgba(71,107,46,0.3)',
    'rgba(141,115,20,0.3)', 'rgba(129,69,136,0.3)', 'rgba(62,67,106,0.3)', 'rgba(74,93,29,0.3)',
    'rgba(69,101,99,0.3)', 'rgba(30,150,124,0.3)', 'rgba(40,139,20,0.3)', 'rgba(41,6,141,0.3)',
    'rgba(164,30,3,0.3)', 'rgba(167,6,85,0.3)', 'rgba(64,1,180,0.3)', 'rgba(124,82,1,0.3)',
    'rgba(21,147,24,0.3)', 'rgba(21,13,28,0.3)', 'rgba(159,60,58,0.3)', 'rgba(140,82,65,0.3)',
    'rgba(54,124,44,0.3)', 'rgba(76,1,143,0.3)', 'rgba(8,33,169,0.3)', 'rgba(27,89,133,0.3)',
    'rgba(93,110,159,0.3)', 'rgba(90,117,113,0.3)', 'rgba(57,70,156,0.3)', 'rgba(128,30,8,0.3)',
    'rgba(0,121,80,0.3)', 'rgba(46,113,147,0.3)', 'rgba(140,67,12,0.3)', 'rgba(50,26,141,0.3)',
    'rgba(159,4,86,0.3)', 'rgba(4,21,129,0.3)', 'rgba(96,85,166,0.3)', 'rgba(113,85,136,0.3)',
    'rgba(0,73,48,0.3)', 'rgba(90,36,12,0.3)', 'rgba(82,101,170,0.3)', 'rgba(182,89,77,0.3)',
    'rgba(35,66,100,0.3)', 'rgba(90,152,10,0.3)', 'rgba(43,80,9,0.3)', 'rgba(106,24,75,0.3)',
    'rgba(116,143,27,0.3)', 'rgba(2,7,152,0.3)', 'rgba(185,72,75,0.3)', 'rgba(97,76,151,0.3)',
    'rgba(22,92,107,0.3)', 'rgba(83,122,132,0.3)', 'rgba(141,69,6,0.3)', 'rgba(54,50,90,0.3)',
    'rgba(0,123,66,0.3)', 'rgba(51,173,15,0.3)', 'rgba(3,26,49,0.3)', 'rgba(164,17,80,0.3)',
    'rgba(106,72,19,0.3)', 'rgba(163,56,78,0.3)', 'rgba(119,5,113,0.3)', 'rgba(29,172,109,0.3)',
    'rgba(119,156,14,0.3)', 'rgba(128,103,110,0.3)', 'rgba(50,61,42,0.3)', 'rgba(62,60,147,0.3)',
    'rgba(5,129,110,0.3)', 'rgba(15,118,12,0.3)', 'rgba(44,92,21,0.3)', 'rgba(15,87,156,0.3)',
    'rgba(10,10,116,0.3)', 'rgba(25,93,142,0.3)', 'rgba(38,96,176,0.3)', 'rgba(70,72,143,0.3)',
    'rgba(97,47,106,0.3)', 'rgba(138,113,62,0.3)', 'rgba(136,111,20,0.3)', 'rgba(137,8,15,0.3)',
    'rgba(87,102,6,0.3)', 'rgba(189,5,59,0.3)', 'rgba(153,83,106,0.3)', 'rgba(17,85,35,0.3)',
    'rgba(31,93,45,0.3)', 'rgba(54,123,45,0.3)', 'rgba(92,56,132,0.3)', 'rgba(23,140,5,0.3)',
    'rgba(102,19,70,0.3)', 'rgba(0,49,123,0.3)', 'rgba(107,148,59,0.3)', 'rgba(78,1,124,0.3)',
    'rgba(130,62,24,0.3)', 'rgba(143,93,81,0.3)', 'rgba(67,26,66,0.3)', 'rgba(31,96,94,0.3)',
    'rgba(3,25,144,0.3)', 'rgba(67,89,107,0.3)', 'rgba(22,95,78,0.3)', 'rgba(1,39,144,0.3)'
  ];

  const usedRowsMap = useMemo(() => {
    const map = {} as Record<string, {
      id: number,
      name: string,
      color: string,
    }[]>;
    Object.values(props.usedStatements)
      .forEach((statement, index) => {
        (statement.lines || []).forEach((row) => {
          if (!map[row]) {
            map[row] = [];
          }
          map[row].push({
            id: statement.id,
            name: statement.name,
            color: defaultColors[statement.id % defaultColors.length],
          });
        });

      });
    return map;
  }, [JSON.stringify(props.usedStatements)]);

  return (
    <div
      style={{ fontSize: 12 }}
      onMouseUp={() => {
        if (multiLinesSelection && multiLinesSelection.lines.length > 1) {
          if (multiLinesSelection.mode === 'select') {
            props.onLinesSelected(multiLinesSelection.lines);
          } else {
            props.onLinesUnselected(multiLinesSelection.lines);
          }
        }

        setMultiLinesSelection(undefined);
      }}
      onMouseLeave={() => setMultiLinesSelection(undefined)}
      className={`${styles.fileWidget} ${props.activeStatementId ? styles.active : ''}`}
    >
      {
        props.fileText
          .split('\n')
          .map((line, index) => {

              const selectedItem = usedRowsMap[index]?.find((one) => one.id === selectedStatementId);

              const isInSelection = multiLinesSelection?.mode === 'select'
                && multiLinesSelection.lines.includes(index);

              const isUnselected = multiLinesSelection?.mode === 'unselect'
                && multiLinesSelection.lines.includes(index);

              const isCommited = usedRowsMap[index]?.find((one) => one.id === props.activeStatementId);

              const hasActiveItem = (isCommited || isInSelection) && !isUnselected;

              return (
                <div
                  key={`line-${index}`}
                  style={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: usedRowsMap[index] !== undefined || props.activeStatementId ? 'pointer' : 'default',
                    backgroundColor: hasActiveItem ? 'yellow' : selectedItem?.color || 'white',
                  }}
                  onClick={() => props.onClick?.(index)}
                  title={selectedItem?.name}
                  onMouseDown={() => {
                    if (props.activeStatementId && !multiLinesSelection) {
                      const mode = hasActiveItem ? 'unselect' : 'select';
                      setMultiLinesSelection({
                        mode,
                        startLine: index,
                        lines: [index],
                      });
                    }
                  }}
                  onMouseOver={() => {
                    if (multiLinesSelection) {
                      const fromLine = Math.min(index, multiLinesSelection.startLine);
                      const toLine = Math.max(index, multiLinesSelection.startLine);

                      setMultiLinesSelection({
                        ...multiLinesSelection,
                        lines: Array.from({ length: toLine - fromLine + 1 }, (_, i) => i + fromLine),
                      });
                    }
                  }}
                >
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '8rem',
                    justifyContent: 'flex-end',
                    marginRight: '2rem',
                  }}>
                    {usedRowsMap[index] ? usedRowsMap[index].map((singleItem) => {
                      return (
                        <div
                          style={{
                            flex: 1,
                            maxWidth: 15,
                            height: 14,
                            backgroundColor: singleItem.color ? singleItem.color.replace('0.3', '0.6') : 'transparent',
                          }}
                          onMouseEnter={() => setSelectedStatementId(singleItem.id)}
                          onMouseLeave={() => setSelectedStatementId(undefined)}
                        />
                      );
                    }) : null}
                  </div>

                  <pre style={{
                    margin: 0,
                  }}>
                        {line}
                  </pre>
                </div>
              );
            }
          )
      }
    </div>
  );
}

export type DetailsFieldsEntry = {
  field: ProjectDetailsField,
  trainingData?: AiDetailsFieldTrainingData
};

function LinesUpdater(
  props: { lines: number[] },
) {
  const formContext = useFormContextRequired();

  useEffect(() => {
    if (JSON.stringify(props.lines) !== JSON.stringify(formContext.data?.lines)) {
      formContext.onChangeCallback({
        lines: props.lines,
      });
    }
  }, [JSON.stringify(props.lines)]);

  return null;
}

function GenerateExplanationButton(
  props: {
    field: ProjectDetailsField,
    template: ContractTemplate,
  }
) {
  const formContext = useFormContextRequired<UpdateDetailsFieldTrainingDataRequest>();

  const formData = formContext.data || {} as UpdateDetailsFieldTrainingDataRequest;

  const aiTrainExampleService = useAiTrainingExampleService();

  const exampleContentHelper = useExampleContentHelper(props.template);

  if (formData.lines && formData.lines.length > 0) {
    return (
      <IconButton
        size="big"
        className={styles.magicButton}
        onClick={async () => {
          const result = await aiTrainExampleService.getExampleValue(
            props.field.name,
            props.field.fieldType,
            !!formData?.dependsOnFieldId,
            formData?.nameSynonyms,
            formData?.exampleValue || undefined,
            exampleContentHelper.getExampleContent(formData?.lines || [])
          );

          formContext.onChangeCallback({
            exampleValue: result.value,
            explanation: result.explanation,
          });
        }}
        type={IconType.MAGIC}
      />
    );
  } else {
    return null;
  }
}

function isChildOf(fieldId: number, parentId: number, entries: DetailsFieldsEntry[]) {

  let result = false;

  let field = entries.find((entry) => entry.field.id === fieldId);
  while (field) {
    const dependsOnFieldId = field.trainingData?.dependsOnFieldId;
    if (dependsOnFieldId === parentId) {
      result = true;
      break;
    }

    if (dependsOnFieldId) {
      field = entries.find((entry) => entry.field.id === dependsOnFieldId);
    } else {
      field = undefined;
    }
  }

  return result;
}

function ParentFieldDropdown(
  props: {
    field: ProjectDetailsField,
    detailsFieldsEntries: DetailsFieldsEntry[],
  }
) {

  if (props.field.fieldType === ProjectDetailsFieldType.DATE) {
    const options = props.detailsFieldsEntries
      .filter((item) => !isChildOf(item.field.id, props.field.id, props.detailsFieldsEntries) && item.field.id !== props.field.id)
      .filter((item) => item.field.fieldType === ProjectDetailsFieldType.DATE)
      .map((item) => ({
        label: item.field.name,
        value: item.field.id,
      }));

    return (
      <Dropdown options={options} source="dependsOnFieldId" label="Depends On" isDisabled />
    );
  } else {
    return null;
  }

}

function SetTrainingDataForm(props: {
  template: ContractTemplate,
  field: ProjectDetailsField,
  detailsFieldsEntries: DetailsFieldsEntry[]
  initialData: UpdateDetailsFieldTrainingDataRequest,
  lines: number[],
  onSubmit: (data: UpdateDetailsFieldTrainingDataRequest) => void,
  onCancel: () => void
}) {

  const service = useContractAiTrainingDataService();

  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <FormTemplate<UpdateDetailsFieldTrainingDataRequest>
      submitFormFunc={async (data) => {
        await service.setDetailsFieldTrainingData(props.template.id, props.field.id, data);

        props.onSubmit(data);
        return data;
      }}
      initialData={props.initialData}
      className={styles.updateForm}
      dependencies={[props.field.id]}
      title={(
        <Header3
          className={styles.collapsable}
        >
          <span onClick={() => setIsCollapsed(!isCollapsed)}>Set Up "{props.field.name}" {isCollapsed ? "↑" : "↓"}</span>
        </Header3>
      )}
      buttons={!isCollapsed ? (
        <>
          <SubmitButton text="Save" />
          <Button
            text="Close"
            onClick={props.onCancel}
            color={ButtonColor.RED}
            size={ButtonSize.BIG}
          />
        </>
      ) : null}
      validationSchema={{
        exampleValue: {
          type: 'string',
          constraints: {
            required: true,
          }
        },
        explanation: {
          type: 'string',
          constraints: {
            required: true,
          }
        }
      }}
    >
      {!isCollapsed && (
        <>
          <TextInput source="nameSynonyms" label="Synonyms" />
          <ParentFieldDropdown
            field={props.field}
            detailsFieldsEntries={props.detailsFieldsEntries}
          />
          <div style={{ position: 'relative' }}>
            <FormRows>
              <ExampleValueInput field={props.field} />
              <TextArea source="explanation" label="Explanation" />
            </FormRows>
            <GenerateExplanationButton field={props.field} template={props.template} />
          </div>
          <LinesUpdater lines={props.lines} />
        </>
      )}

    </FormTemplate>
  );
}

function DeleteFieldConfigurationButton(
  props: {
    templateId: number,
    fieldId: number,
  }
) {
  const aiTemplateService = useContractAiTrainingDataService();

  return (
    <IconDeleteButton
      deleteFunc={() => aiTemplateService.removeEntry(props.templateId, props.fieldId)}
      className={styles.deleteFieldDataButton}
      message={`Are you sure you want to delete field data?`}
    />
  );
}

function DetailsFieldListLayout(
  props: {
    setChosenField: (entry: DetailsFieldsEntry) => void,
    templateId: number,
  }
) {
  const listContext = useListContextRequired<DetailsFieldsEntry>();

  return (
    <div
      className={styles.detailsList}
    >
      {listContext.data.map((entry) => {
        const isConfigured = !!entry.trainingData?.lines;

        return (
          <div
            onClick={() => {
              props.setChosenField(entry);
            }}
            className={`${styles.detailsItem} ${isConfigured ? styles.configured : ''}`}
            key={`field-${entry.field.id}`}
          >
            {entry.field.name}

            {isConfigured && <DeleteFieldConfigurationButton templateId={props.templateId}
                                                             fieldId={entry.field.id} />}
          </div>
        );
      })}
    </div>
  );
}

type DetailFieldsChooserFilterData = {
  showSetUpFields: boolean,
  showNotSetUpFields: boolean,
  projectTypes: ProjectType[]
};

function DetailsFieldsChooserFilter(
  props: {
    visible: boolean
  }
) {
  const filterContext = useFilterContext<DetailFieldsChooserFilterData>();

  const filterValue = filterContext.filterValue || {} as DetailFieldsChooserFilterData;

  return props.visible ? (
    <>
      <div className={styles.filterRow}>
        <CheckboxInput
          source="showSetUpFields"
          label="Show configured fields"
          onChangeCallback={() => {
            filterContext.setFilterValue({
              ...filterValue,
              showSetUpFields: !filterValue.showSetUpFields,
            } as DetailFieldsChooserFilterData);
          }}
          value={filterValue.showSetUpFields}
        />
        <CheckboxInput
          source="showNotSetUpFields"
          label="Show not configured fields"
          onChangeCallback={() => {
            filterContext.setFilterValue({
              ...filterValue,
              showNotSetUpFields: !filterValue.showNotSetUpFields,
            } as DetailFieldsChooserFilterData);
          }}
          value={filterValue.showNotSetUpFields}
        />
      </div>
      <Separator marginSize="small" />
    </>
  ) : undefined;
}

function DetailsFieldsChooser(props: {
  templateId: number,
  detailsFieldsEntries: DetailsFieldsEntry[],
  setChosenField: (entry: DetailsFieldsEntry) => void,
  visible: boolean,
}) {

  const [left, setLeft] = useState<number>();
  const [width, setWidth] = useState<number>();

  const onResizeFunc = useCallback(() => {
    const element = document.querySelector('[data-key="popup-content"]');

    setWidth(element?.clientWidth);
    setLeft(element?.getBoundingClientRect().left);
  }, []);

  useEffect(() => {
    onResizeFunc();

    // and do this on each document resize
    window.addEventListener('resize', onResizeFunc);

    // remove listener
    return () => {
      window.removeEventListener('resize', onResizeFunc);
    };
  }, []);

  if (props.visible) {
    return (
      <div
        className={styles.detailsListWidget}
        style={{
          left,
          width,
        }}
      >
        <FilterContext<DetailFieldsChooserFilterData>
          initialValue={{
            showNotSetUpFields: true,
            showSetUpFields: true,
            projectTypes: [],
          }}
        >
          <List
            getDataFunc={async () => props.detailsFieldsEntries}
            dependencies={[JSON.stringify(props.detailsFieldsEntries)]}
          >
            <DetailsFieldsChooserFilter visible={false} />
            <DetailsFieldListLayout setChosenField={props.setChosenField}
                                    templateId={props.templateId} />
          </List>
        </FilterContext>
      </div>
    );
  } else {
    return null;
  }
}

function ManageDetailsFieldDataWidget(
  props: {
    template: ContractTemplate,
    detailsFieldsEntries: DetailsFieldsEntry[]
  }
) {
  const [chosenField, setChosenField] = useState<DetailsFieldsEntry>();

  const [detailsFieldsEntries, setDetailsFieldsEntries] = useState<DetailsFieldsEntry[]>(
    props.detailsFieldsEntries,
  );

  const initialUsedStatements = useMemo(() => {
    const result: Record<number, {
      id: number
      name: string,
      lines: number[],
    }> = {};

    detailsFieldsEntries.forEach((entry) => {
      if (entry.trainingData) {
        result[entry.field.id] = {
          id: entry.field.id,
          name: entry.field.name,
          lines: entry.trainingData.lines || [],
        };
      }
    });

    return result;
  }, [props.template.id]);

  const [usedStatements, setUsedStatements] = useState<Record<number, {
    id: number
    name: string,
    lines: number[],
  }>>(initialUsedStatements);

  const [currentData, setCurrentData] = useState<AiDetailsFieldTrainingData>();

  useEffect(() => {
    if (chosenField) {
      setCurrentData(chosenField.trainingData || {} as AiDetailsFieldTrainingData);
    }
  }, [chosenField?.field.id]);

  const onLineClicked = (clickedLineNumber: number) => {
    if (currentData && chosenField) {
      const currentLines = usedStatements[chosenField.field.id]?.lines || [];
      let newLines: number[];
      if (currentLines.includes(clickedLineNumber)) {
        newLines = currentLines.filter((line) => line !== clickedLineNumber);
      } else {
        newLines = [...currentLines, clickedLineNumber];
      }

      setUsedStatements({
        ...usedStatements,
        [chosenField.field.id]: {
          id: chosenField.field.id,
          name: chosenField.field.name,
          lines: newLines,
        }
      });
    }
  };

  const onLinesUnselected = (lines: number[]) => {
    if (currentData && chosenField) {
      const currentLines = usedStatements[chosenField.field.id]?.lines || [];
      const newLines = currentLines.filter((line) => !lines.includes(line));

      setUsedStatements({
        ...usedStatements,
        [chosenField.field.id]: {
          id: chosenField.field.id,
          name: chosenField.field.name,
          lines: newLines,
        }
      });
    }
  };

  const onLinesSelected = (lines: number[]) => {
    if (currentData && chosenField) {
      const currentLines = usedStatements[chosenField.field.id]?.lines || [];
      const newLines = Array.from(new Set([...currentLines, ...lines]));

      setUsedStatements({
        ...usedStatements,
        [chosenField.field.id]: {
          id: chosenField.field.id,
          name: chosenField.field.name,
          lines: newLines,
        }
      });
    }
  };

  useEventsSubscriber('SelectContractContextWidget', {
    [TRAINING_DATA_REMOVED]: (eventData) => {
      const newFields = usedStatements;
      delete newFields[eventData.fieldId];

      setUsedStatements(newFields);

      setDetailsFieldsEntries(
        (prev) => prev.map((entry) => {
            if (entry.field.id === eventData.fieldId) {
              return {
                ...entry,
                trainingData: undefined,
              };
            } else {
              return entry;
            }
          }
        ));
    }
  });

  return (
    <>
      <div className={`${styles.container} ${chosenField ? styles.withForm : undefined}`}>
        {chosenField && (
          <SetTrainingDataForm
            template={props.template}
            field={chosenField.field}
            detailsFieldsEntries={detailsFieldsEntries}
            initialData={{
              nameSynonyms: chosenField.trainingData?.nameSynonyms || '',
              exampleValue: chosenField.trainingData?.exampleValue || '',
              explanation: chosenField.trainingData?.explanation || '',
              lines: chosenField.trainingData?.lines || [],
              dependsOnFieldId: chosenField.field.parentFieldId,
            }}
            onCancel={() => {
              setUsedStatements({
                ...usedStatements,
                [chosenField.field.id]: {
                  id: chosenField.field.id,
                  name: chosenField.field.name,
                  lines: chosenField.trainingData?.lines || [],
                }
              });
              setChosenField(undefined);
            }}
            onSubmit={(data) => {
              setDetailsFieldsEntries(
                detailsFieldsEntries.map((entry) => {
                  if (entry.field.id === chosenField.field.id) {
                    return {
                      ...entry,
                      trainingData: {
                        fieldId: chosenField.field.id,
                        ...data,
                      },
                    };
                  } else {
                    return entry;
                  }
                })
              );
              setChosenField(undefined);
            }}
            lines={usedStatements[chosenField.field.id]?.lines || []}
          />
        )}
        <SelectLinesWidget
          fileText={props.template._documentExampleFileText}
          usedStatements={usedStatements || {}}
          onClick={onLineClicked}
          activeStatementId={chosenField?.field.id}
          onLinesSelected={onLinesSelected}
          onLinesUnselected={onLinesUnselected}
        />
      </div>
      <DetailsFieldsChooser
        detailsFieldsEntries={detailsFieldsEntries}
        setChosenField={setChosenField}
        visible={!chosenField}
        templateId={props.template.id}
      />
    </>

  );
}

export default function ManageAiTrainingDataWidget(
  props: {
    set: DetailsFieldsSet,
  }
) {

  const detailsFieldService = useProjectDetailsFieldsService();

  const fieldsDataService = useContractAiTrainingDataService();

  const contractTemplateService = useContractTemplatesService();

  const [template, setTemplate] = useState<ContractTemplate>();

  const [detailsFieldsEntries, setDetailsFieldsEntries] = useState<DetailsFieldsEntry[]>();

  const loadData = async () => {

    const templateData = await contractTemplateService.getContractTemplate(props.set.contractTemplateId);

    const fields = await detailsFieldService.getFieldsInSet(templateData.setId);

    const fieldsData = await fieldsDataService.getEntries(props.set.contractTemplateId);

    const fieldsDataMap = fieldsData.reduce((acc, item) => {
      acc[item.fieldId] = item;
      return acc;
    }, {} as { [fieldId: number]: AiDetailsFieldTrainingData });

    const reducedResult = fields.reduce((acc, field) => {
      if (!acc[field.id]) {
        acc[field.id] = {
          field,
          trainingData: fieldsDataMap[field.id],
        };
      }

      return acc;
    }, {} as {
      [fieldId: number]: {
        field: ProjectDetailsField,
        trainingData?: AiDetailsFieldTrainingData
      }
    });

    setTemplate(templateData);
    setDetailsFieldsEntries(Object.values(reducedResult));
  };

  useEffect(() => {
    loadData();
  }, [props.set.id, props.set.contractTemplateId]);

  if (template && detailsFieldsEntries) {
    return (
      <ManageDetailsFieldDataWidget
        template={template}
        detailsFieldsEntries={detailsFieldsEntries}
      />
    );
  } else {
    return (
      <LoadingAnimation />
    );
  }
}
