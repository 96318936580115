import React, { ReactNode, useContext } from 'react';

const ProjectIdReactContext = React.createContext<{ projectId: number }>({
  projectId: 0
});

export function useProjectId() {
  const context = useContext(ProjectIdReactContext);

  return context.projectId;
}

export function ProjectIdContext(props: {
  projectId: number,
  children: ReactNode,
}) {
  return (
    <ProjectIdReactContext.Provider value={{ projectId: props.projectId }}>
      {props.children}
    </ProjectIdReactContext.Provider>
  );
}
