import React from 'react';
import { ToggleWrapper } from './ToggleWrapper';
import { ToggledFeature } from './featureToggleService';

export function TeamMembersManagementToggleWrapper(
  props: {
    children: React.ReactNode
  }
) {
  return (
    <ToggleWrapper feature={ToggledFeature.TEAM_MEMBERS_MANAGEMENT}>
      {props.children}
    </ToggleWrapper>
  )
}

export function TeamMembersManagementSeatsToggleWrapper(
  props: { children: React.ReactNode }
) {
  return (
    <ToggleWrapper feature={ToggledFeature.TEAM_MEMBERS_MANAGEMENT_SEATS}>
      {props.children}
    </ToggleWrapper>
  )
}
