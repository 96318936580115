import React from 'react';
import UpdateEmailTaskForm from './CreateUpdate/Email/UpdateEmailTaskForm';
import UpdateSmsPlanTaskForm from './CreateUpdate/Sms/UpdateSmsPlanTaskForm';
import UpdateStandardPlanTaskForm from './CreateUpdate/Standard/UpdateStandardPlanTaskForm';
import { usePopupFormManager } from 'features/nekst-widgets';
import { PlanTask, TaskType } from 'nekst-api';

export default function usePlanTasksNavigator() {
  const popupManager = usePopupFormManager();

  const openEditTaskForm = (data: PlanTask) => {
    if (data.type === TaskType.EMAIL) {
      popupManager.openForm(<UpdateEmailTaskForm data={data} />);
    } else if (data.type === TaskType.SMS) {
      popupManager.openForm(<UpdateSmsPlanTaskForm data={data} />);
    } else {
      popupManager.openForm(<UpdateStandardPlanTaskForm data={data} />);
    }
  };

  return {
    openEditTaskForm,
  };
}
