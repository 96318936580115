import React from 'react';
import { InformationMessageProps, MessageType } from './types';
import Layout from './Layout';

export function WarningMessage(
  props: InformationMessageProps
) {
  return (
    <Layout type={MessageType.WARNING} {...props} />
  )
}

export function ErrorMessage(
  props: InformationMessageProps
) {
  return (
    <Layout type={MessageType.ERROR} {...props} />
  )
}

export function InformationMessage(
  props: InformationMessageProps
) {
  return (
    <Layout type={MessageType.INFO} {...props} />
  )
}
