import React, { ReactElement } from 'react';

export interface TableDataGridWrapperProps {
  children: ReactElement | ReactElement[];
}

function TableDataGridWrapper(props: TableDataGridWrapperProps) {
  return (
    <table>
      {props.children}
    </table>
  );
}

export default TableDataGridWrapper;
