import React from 'react';

import { DateField, If, SimpleBlock, useRowVisibilityHelper, useShowContext } from 'ui-builder';
import { PlanTask, RecipientType, TaskType } from 'nekst-api';
import { EmailField, SmsField } from 'features/tasks-list-feature';
import styles from '../../../shared/widgets/TaskRowLayout.module.scss';

import AssignmentsField from './AssignmentsField';
import DueDateField from './DueDateField';
import IsKeyDateField from './IsKeyDateField';
import LinkField from '../../../shared/uibuilder/show/field/LinkField';
import SelectForMassUpdateCheckbox
  from '../../../shared/widgets/MassUpdate/SelectForMassUpdateCheckbox';
import TaskRowLayout from '../../../shared/widgets/TaskRowLayout';
import IsPrivateField from './IsPrivateField';
import useAuthorizationChecker from '../../../shared/authorization/authorizationChecker';
import useDeletePlanTaskPopupManager from './DeletePlanTaskPopup';
import IconButton, { IconType } from '../../../shared/widgets/IconButton';
import usePlanTasksNavigator from '../planTasksNavigator';
import { PlanPermission } from 'authorization-scope';
import TasksGroupField from './TasksGroupField';

function OpenEditFormField() {
  const context = useShowContext<PlanTask>();

  const planTasksNavigator = usePlanTasksNavigator();

  const openEditForm = () => {
    planTasksNavigator.openEditTaskForm(context.data!);
  };

  return (
    <LinkField
      uri={undefined}
      source="_renderedName"
      onClick={openEditForm}
    />
  );
}

function DeletePlanTaskButton() {
  const { isGranted } = useAuthorizationChecker();

  const context = useShowContext<PlanTask>();

  const deletePopupManager = useDeletePlanTaskPopupManager();

  if (isGranted(PlanPermission.UPDATE)) {
    const onClick = () => deletePopupManager.openPopup(context.data!.id, context.data!.name);
    return (
      <IconButton
        type={IconType.TRASH_CAN}
        className={styles.deleteButton}
        onClick={onClick}
        title="Delete Task"
      />
    );
  } else {
    return null;
  }
}

export default function PlanTaskRow(
  props: {
    shortView?: boolean;
  }
) {
  const visibilityHelper = useRowVisibilityHelper();

  return (
    <TaskRowLayout
      isDisabled={visibilityHelper.isDisabled()}
      checkbox={
        <SelectForMassUpdateCheckbox />
      }
      title={(
        <>
          {props.shortView ? (
            <IsKeyDateField source="isKeyDate" />
          ) : null}
          <EmailField />
          <SmsField />
          <OpenEditFormField />
        </>

      )}
      leftLine2={!props.shortView ? (
        <>
          <AssignmentsField source="transactionParties" />
          <If conditionFunc={(data) => data.type === TaskType.EMAIL}>
            <AssignmentsField
              source="emailRecipients"
              label="TO"
              filterFunc={(data) => data.type === RecipientType.RECIPIENT}
            />
            <AssignmentsField
              source="emailRecipients"
              label="CC"
              filterFunc={(data) => data.type === RecipientType.CC}
            />
            <AssignmentsField
              source="emailRecipients"
              label="BCC"
              filterFunc={(data) => data.type === RecipientType.BCC}
            />
          </If>
          <If conditionFunc={(data) => data.type === TaskType.SMS}>
            <AssignmentsField
              source="smsRecipients"
              label="TO"
            />
          </If>
        </>
      ) : undefined}
      rightLine1={(
        <>
          <DueDateField />
          <If conditionFunc={(data) => data.calculatedDate}>
            <div className={styles.calculatedDate}>
              <DateField source="calculatedDate" />
            </div>
          </If>
        </>
      )}
      rightLine2={!props.shortView ? (
        <SimpleBlock className={styles.iconsBlock}>
          <TasksGroupField />
          <IsPrivateField />
          <IsKeyDateField source="isKeyDate" />
        </SimpleBlock>
      ) : undefined}
      deleteButton={!props.shortView ? <DeletePlanTaskButton /> : undefined}
      isCollapsed={props.shortView}
    />
  );
}
