import React from 'react';
import { LoadingAnimationLayoutProps } from 'ui-builder';

import styles from './LoadingAnimation.module.scss';

export default function LoadingAnimationLayout(props: LoadingAnimationLayoutProps) {
  const classes = [
    styles.loader,
    props.isBig ? styles.big : '',
  ];
  return (
    <div className={classes.join(' ')}>
      &nbsp;
    </div>
  );
}
