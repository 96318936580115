import { ValidationSchema } from 'validation-schema';
import { BadRequestError } from 'request-sender';
import { AbstractTask } from './Types';
import { TaskEvent } from './projectTasksService';
import { useBackendApi } from './backendApi';
import { useEventsPublisher } from 'event-bus';
import { useTasksResponseMapper } from './Helper';

export enum CompletionErrorCode {
  SMS_TASK_NOT_SENT_CODE = 'SMS_TASK_NOT_SENT',
  SMS_TASK_NOT_DELIVERED_CODE = 'SMS_TASK_IS_NOT_DELIVERED',
  MASS_COMPLETE_NOT_POSSIBLE = 'MASS_COMPLETE_NOT_POSSIBLE',
}

export type EmailData = {
  subject: string
  recipientsIds: number[]
  text: string
}

export enum MassCompleteErrorAction {
  FORCE = 'force',
  IGNORE = 'ignore',
}

export class SmsTaskNotSentError extends Error {
  constructor() {
    super('');
    Object.setPrototypeOf(this, SmsTaskNotSentError.prototype);
  }
}

export class SmsTaskNotDeliveredError extends Error {
  private notDeliveredToIds: number[];

  constructor(notDeliveredToIds: number[]) {
    super('');

    this.notDeliveredToIds = notDeliveredToIds;
    Object.setPrototypeOf(this, SmsTaskNotDeliveredError.prototype);
  }

  getNotDeliveredToIds(): number[] {
    return this.notDeliveredToIds;
  }
}

export type CompletionErrorCause = {
  taskId: number,
  taskName: string,
  errorCode: CompletionErrorCode,
}

export class MassCompleteNotPossibleError extends Error {
  private causes: CompletionErrorCause[];

  constructor(causes: CompletionErrorCause[]) {
    super('');

    this.causes = causes;
    Object.setPrototypeOf(this, MassCompleteNotPossibleError.prototype);
  }

  getCauses(): CompletionErrorCause[] {
    return this.causes;
  }
}

export interface TaskCompletionService {
  completeTask: (id: number, forced: boolean) => Promise<AbstractTask>,
  sendSmsAndCompleteTask: (id: number) => Promise<AbstractTask>,
  sendAsEmailAndComplete: (id: number, emailRequest: EmailData) => Promise<AbstractTask>,

  massComplete: (ids: number[], errorAction?: MassCompleteErrorAction) => Promise<AbstractTask[]>,

  unCompleteTask: (id: number) => Promise<AbstractTask>,

  getEmailValidationSchema: () => ValidationSchema,
}

export const TASK_COMPLETED = 'TASK_COMPLETED';
export const TASK_UNCOMPLETED = 'TASK_UNCOMPLETED';

export interface TaskCompletedEvent {
  id: number,
  data: AbstractTask
}

export const TASKS_MASS_COMPLETED = 'TASKS_MASS_COMPLETED';

export interface TasksMassCompletedEvent {
  ids: number[],
  data: AbstractTask[],
}

export function useTasksCompletionService(): TaskCompletionService {
  const backendApi = useBackendApi();

  const { mapSingle } = useTasksResponseMapper();

  const eventsPublisher = useEventsPublisher();

  const handleException = (e: any) => {
    if (e instanceof BadRequestError) {
      if (e.getErrorCode() === CompletionErrorCode.SMS_TASK_NOT_SENT_CODE) {
        throw new SmsTaskNotSentError();
      } else if (e.getErrorCode() === CompletionErrorCode.SMS_TASK_NOT_DELIVERED_CODE) {
        throw new SmsTaskNotDeliveredError(
          e.getResponseField<number[]>('notDeliveredToIds', []),
        );
      } else if (e.getErrorCode() === CompletionErrorCode.MASS_COMPLETE_NOT_POSSIBLE) {
        throw new MassCompleteNotPossibleError(
          e.getResponseField<CompletionErrorCause[]>('causes', []),
        );
      } else {
        throw e;
      }
    } else {
      throw e;
    }
  };

  const onCompleteTask = (task: AbstractTask) => {
    eventsPublisher.publish<TaskEvent>(TASK_COMPLETED, {
      id: task.id,
      data: task,
    });
  };

  const onUnCompleteTask = (task: AbstractTask) => {
    eventsPublisher.publish<TaskEvent>(TASK_UNCOMPLETED, {
      id: task.id,
      data: task,
    });
  };

  const completeTask = async (id: number, forced = false) => {
    try {
      const response = await backendApi.put(
        `/tasks/${id}/complete?forced=${forced ? 1 : 0}`,
      );

      const task = mapSingle(response);
      onCompleteTask(task);
      return task;
    } catch (e) {
      return handleException(e);
    }
  };

  const sendSmsAndCompleteTask = async (id: number) => {
    try {
      const response = await backendApi.put(
        `/tasks/${id}/sendsmsandcomplete`,
      );

      const task = mapSingle(response);
      onCompleteTask(task);
      return task;
    } catch (e) {
      return handleException(e);
    }
  };

  const sendAsEmailAndComplete = async (id: number, emailRequest: EmailData) => {
    try {
      const response = await backendApi.put(
        `/tasks/${id}/sendemailandcomplete`,
        emailRequest,
      );
      const task = mapSingle(response);

      onCompleteTask(task);
      return task;
    } catch (e) {
      return handleException(e);
    }
  };

  const massComplete = async (ids: number[], errorAction?: MassCompleteErrorAction) => {
    try {
      const query = errorAction ? `?errorAction=${errorAction}` : '';
      const result = await backendApi.put(`/tasks/bulk/complete${query}`, {
        ids,
      }) as AbstractTask[];

      eventsPublisher.publish<TasksMassCompletedEvent>(TASKS_MASS_COMPLETED, {
        ids,
        data: result,
      });

      return result;
    } catch (e) {
      return handleException(e);
    }
  };

  const unCompleteTask = async (id: number) => {
    const response = await backendApi.put(`/tasks/${id}/uncomplete`);
    const task = mapSingle(response);

    onUnCompleteTask(task);
    return task;
  };

  const getEmailValidationSchema = () => {
    return {
      subject: {
        type: 'string',
        constraints: {
          required: true,
        },
      },
      recipientsIds: {
        type: 'array',
        constraints: {
          required: {
            value: true,
            message: 'Please select at least one recipient',
          },
        },
      },
      text: {
        type: 'string',
        constraints: {
          required: true,
        },
      },
    } as ValidationSchema;
  };

  return {
    completeTask,
    unCompleteTask,
    massComplete,
    sendSmsAndCompleteTask,
    sendAsEmailAndComplete,
    getEmailValidationSchema,
  };
}
