import React from 'react';
import { SubmitButton } from 'ui-builder';
import FormTemplate from '../../../shared/uibuilder/form/FormTemplate';
import Row6x6x from '../../../shared/web/uitheme/form/Row6x6x';
import Password from '../../../shared/uibuilder/form/input/Password';
import usePasswordService, { UpdatePasswordRequest } from './passwordService';

export default function UpdatePasswordForm() {

  const passwordService = usePasswordService();

  return (
    <FormTemplate<UpdatePasswordRequest>
      submitFormFunc={async (data) => {
        await passwordService.updatePassword(data);
        return data;
      }}
      buttons={<SubmitButton text="Update" />}
      validationSchema={passwordService.getUpdatePasswordValidationSchema()}
    >
      <Row6x6x>
        <Password source="oldPassword" label="Old Password" />
      </Row6x6x>
      <Row6x6x>
        <Password
          source="newPassword"
          label="New Password"
          hint="Password strength: minimum 8 characters, including 1 uppercase, 1 number, and 1 special symbol."
        />
        <Password source="newPassword2" label="Repeat New Password" />
      </Row6x6x>
    </FormTemplate>
  );
}
