import React, { ReactElement } from 'react';
import { UiThemeOverrider } from '../../uibuilder/uiTheme';
import DefaultLayout from '../../uibuilder/form/layout/DefaultLayout';
import { useCommentsListTheme } from './CommentsListTheme';
import SubmitCommentButton from './SubmitCommentButton';
import CancelCircleButton from '../CancelCircleButton';
import { CommentRequestType, CommentType } from './EditCommentForm';
import { AfterSubmitFunc, FormTemplate, Right, TextArea, } from 'ui-builder';
import { TextAreaWithProjectMemberMention, useProjectId } from 'features/nekst-widgets';

interface Props<Data extends CommentType, Request extends CommentRequestType> {
  submitFunc: (data: Request) => Promise<Data>
  afterSubmitFunc?: AfterSubmitFunc,
  closeFormFunc?: () => void,
  additionalInputs?: ReactElement,
}

export default function AddCommentForm<
  Data extends CommentType = CommentType,
  Request extends CommentRequestType = CommentRequestType,
>(props: Props<Data, Request>) {

  const theme = useCommentsListTheme();

  const buttons = (
    <>
      <SubmitCommentButton />
      {props.closeFormFunc && (<CancelCircleButton onClick={async () => props.closeFormFunc!()} />)}
    </>
  );

  const projectId = useProjectId();

  const textInput = projectId
    ? (
      <TextAreaWithProjectMemberMention
        source="text"
        label={theme.textInputLabel}
        showRequiredIcon={false}
      />
    )
    : (
      <TextArea source="text"
                label={theme.textInputLabel}
                showRequiredIcon={false} />
    );

  return (
    <UiThemeOverrider
      theme={{
        forms: {
          FormLayout: DefaultLayout,
        },
      }}
    >
      <FormTemplate<Request, Data>
        submitFormFunc={async (request: Request) => {
          return props.submitFunc(
            request,
          );
        }}
        afterSubmitFunc={props.afterSubmitFunc}
        clearAfterSubmit
        buttons={(
          <Right>
            {buttons}
          </Right>
        )}
        validationSchema={{
          text: {
            type: 'string',
            constraints: {
              required: true,
            },
          },
        }}
      >
        {textInput}
        {props.additionalInputs}
      </FormTemplate>
    </UiThemeOverrider>
  );
}

AddCommentForm.prototype.defaultProps = {
  afterSubmitFunc: undefined,
};
