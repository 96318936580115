/* istanbul ignore file */
import React from 'react';
import { FormRowsProps } from '../../../uibuilder/form/FormRows';

import RelativeRow from './RelativeRow';

export default function Row8x4x(props: FormRowsProps) {
  return (
    <RelativeRow weights={[8, 4]} rowClassName={props.className}>
      {props.children}
    </RelativeRow>
  );
}
