import React from 'react';

import PersonLink from './PersonLink';
import ProjectLink from './ProjectLink';
import RecordLayout, { RecordLayoutProps } from './RecordLayout';
import { HistoryEventIconType } from './HistoryEventIcon';
import { BaseHistoryEntryData } from 'nekst-api';

export default function ProjectCreatedRecordLayout(props: RecordLayoutProps<BaseHistoryEntryData>) {
  return (
    <RecordLayout
      text={(
        <>
          <ProjectLink project={props.record.data.project} />
          {' was created by '}
          <PersonLink person={props.record.data.person!} />
          .
        </>
      )}
      date={props.record.when}
      iconType={HistoryEventIconType.PROJECT_CREATED}
    />
  );
}
