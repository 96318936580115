import React from 'react';
import DueDateFilter from './DueDateFilter';
import CalendarFilter from './CalendarFilter';

export default function DueDateFilters() {
  return (
    <>
      <DueDateFilter />
      <CalendarFilter />
    </>
  );
}
