import React from 'react';
import { TeamPermission } from 'nekst-api';
import RolesList from './RolesList';
import AccessChecker from '../../shared/authorization/AccessChecker';
import { SettingsBreadcrumbs } from '../settingsModule';
import { Breadcrumb } from '../../shared/web/layout/Breadcrumbs'

export default function RolesSettingsPage() {
  return (
    <AccessChecker
      permissionToCheck={TeamPermission.MANAGE_ROLES}
    >
      <SettingsBreadcrumbs>
        <Breadcrumb text="Custome Roles" />
      </SettingsBreadcrumbs>
      <RolesList />
    </AccessChecker>
  );
}
