import { useShowContext } from 'ui-builder';
import { AbstractTask, TaskType } from 'nekst-api';
import React from 'react';
import { BaseTaskRowAssignmentWidget } from 'features/nekst-widgets';

export function EmailRecipientsField() {
  const showContext = useShowContext<AbstractTask>();

  if (showContext.data?.type === TaskType.EMAIL) {
    return (
      <>
        <BaseTaskRowAssignmentWidget
          source="recipients"
          filterFunc={(item) => (item.personId && item.type === 'RECIPIENT')}
          label="To"
          getNameFunc={(item) => item._profile?.name.fullName || ''}
          getImageFunc={(item) => item._profile?._avatarUrl?.resized}
        />

        <BaseTaskRowAssignmentWidget
          source="recipients"
          filterFunc={(item) => (item.personId && item.type === 'CC')}
          label="CC"
          getNameFunc={(item) => item._profile?.name.fullName || ''}
          getImageFunc={(item) => item._profile?._avatarUrl?.resized}
        />

        <BaseTaskRowAssignmentWidget
          source="recipients"
          filterFunc={(item) => (item.personId && item.type === 'BCC')}
          label="BCC"
          getNameFunc={(item) => item._profile?.name.fullName || ''}
          getImageFunc={(item) => item._profile?._avatarUrl?.resized}
        />
      </>
    );
  } else {
    return null;
  }
}
