import { FormAttributes, FormTemplate } from 'ui-builder';
import { PersonPhoneNumber, TeamRole } from 'nekst-api';
import { cloneDeep } from 'lodash';
import React from 'react';

export type BasePersonFormTemplateProps<DataType, ResponseType>
  = Omit<FormAttributes<DataType, ResponseType>, 'beforeValidationFunc'>

export function BasePersonFormTemplate<
  DataType extends {
    profile: {
      primaryEmail?: string,
      phoneNumbers: PersonPhoneNumber[],
    },
    teamRole?: TeamRole,
  },
  ResponseType = DataType
>(props: BasePersonFormTemplateProps<DataType, ResponseType>) {
  return (
    <FormTemplate<DataType, ResponseType>
      {...props}
      beforeValidationFunc={(data) => {
        const result = cloneDeep(data);

        if (result.profile.phoneNumbers?.length === 1) {
          const phoneNumber = result.profile.phoneNumbers[0];
          if (!phoneNumber.number && !phoneNumber.type) {
            result.profile.phoneNumbers = [];
          }
        }

        return result;
      }}
    >
      {props.children}
    </FormTemplate>
  );
}
