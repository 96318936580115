/* istanbul ignore file */
import React from 'react';
import { BaseInputLayoutProps, BaseInputProps } from './types';
import { useNewUiTheme } from '../../newUiTheme';
import { useInputHelper } from './inputHelper';

export interface PasswordLayoutProps extends BaseInputLayoutProps {
}

export interface PasswordInputProps extends BaseInputProps {
}

export function PasswordInput(props: PasswordInputProps) {
  const uiTheme = useNewUiTheme();

  const helper = useInputHelper(props);

  return (
    <uiTheme.inputs.PasswordLayout
      {...helper.getBaseInputLayoutProps()}
    />
  );
}
