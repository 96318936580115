import React from 'react';

import ErrorsWrapper from './ErrorsWrapper';
import { InputLabel, RadioButton, RadioButtonsGroupLayoutProps, useFormContext } from 'ui-builder';


function RadioButtonsGroupLayout(props: RadioButtonsGroupLayoutProps<string | number>) {

  const formContext = useFormContext();

  return (
    <ErrorsWrapper name={props.name} errors={props.errors}>
      <>
        <InputLabel
          label={props.label}
          hideLabel={props.hideLabel}
          source={props.name}
          bold
        />
        <div>
          {props.options && props.options.map((option) => {
            return (
              <RadioButton
                key={`radio-button-option-value-${option.value}`}
                value={props.value === option.value}
                groupValue={option.value}
                isDisabled={option.disabled}
                label={option.label}
                source={props.name}
                onChangeCallback={() => {
                  formContext.onChangeCallback!({
                    [props.name]: option.value,
                  });
                }}
                hint={option.description}
                title={option.title}
              />
            );
          })}
        </div>
      </>
    </ErrorsWrapper>
  );
}

export default RadioButtonsGroupLayout;
