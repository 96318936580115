import React from 'react';
import { SubmitButton, AfterSubmitFunc, usePopupManager } from 'ui-builder';
import BaseEmailTaskForm from './BaseEmailTaskForm';
import {
  PlanTask,
  PlanTaskParentTaskRelation,
  TeamPermission,
  usePlanTasksService
} from 'nekst-api';
import { PopupForm } from 'features/nekst-widgets';
import { useAuthorizationChecker } from 'authorization-scope';

export interface CreateEmailPlanTaskFormProps {
  planId: number,
  afterSubmitFunc?: AfterSubmitFunc,
  parentTask?: PlanTask
}

export function CreateEmailTaskForm(props: CreateEmailPlanTaskFormProps) {
  const planTaskService = usePlanTasksService();

  const { isGranted } = useAuthorizationChecker();

  let initialData: PlanTask = {
    description: isGranted(TeamPermission.USE_SMART_TAGS) ? `<p>Hi <span class="mention" data-index="0" data-denotation-char="#" data-value="recipients.to.firstName" data-id="recipients.to.firstName">
          <span contenteditable="false"><span class="ql-mention-denotation-char">#</span>recipients.to.firstName</span></span>,
          </p>
          <p>...email content here...</p>
          <p><span class="mention" data-index="0" data-denotation-char="#" data-value="sender.signature" data-id="sender.signature">
          <span contenteditable="false"><span class="ql-mention-denotation-char">#</span>sender.signature</span>
          </span>
          </p>` : '',
  } as PlanTask;

  if (props.parentTask) {
    initialData = {
      ...initialData,
      parentTaskRelation: {
        parentTaskId: props.parentTask.id,
        parentTaskName: props.parentTask.name,
      } as PlanTaskParentTaskRelation,
    } as PlanTask;
  }

  return (
    <BaseEmailTaskForm
      title={props.parentTask ? 'Add Dependent Email Task' : 'Add Email Task'}
      submitFormFunc={
        (data) => {
          return planTaskService.createEmailTask(props.planId, data);
        }
      }
      afterSubmitFunc={props.afterSubmitFunc}
      validationSchema={planTaskService.getEmailTaskValidationSchema()}
      buttons={<SubmitButton text="Save task" key="save" />}
      initialData={initialData}
    />
  );
}

export default function useCreateEmailTaskForm() {
  const popupManager = usePopupManager();

  const openForm = (planId: number) => {
    popupManager.openPopup(
      <PopupForm>
        <CreateEmailTaskForm planId={planId} />
      </PopupForm>,
    );
  };

  return {
    openForm,
  };
}
