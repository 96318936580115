import React from 'react';
import { SubmitButton, AfterSubmitFunc } from 'ui-builder';
import BaseStandardTaskForm from './BaseStandardTaskForm';
import { PlanTask, PlanTaskParentTaskRelation, usePlanTasksService } from 'nekst-api';

export interface CreateStandardPlanTaskFormProps {
  planId: number,
  afterSubmitFunc?: AfterSubmitFunc,
  parentTask?: PlanTask,
}

export default function CreateStandardPlanTaskForm(props: CreateStandardPlanTaskFormProps) {
  const planTaskService = usePlanTasksService();

  let initialData;
  if (props.parentTask) {
    initialData = {
      parentTaskRelation: {
        parentTaskId: props.parentTask.id,
        parentTaskName: props.parentTask.name,
      } as PlanTaskParentTaskRelation,
    } as PlanTask;
  } else {
    initialData = {} as PlanTask;
  }

  return (
    <BaseStandardTaskForm
      title={props.parentTask ? 'Add dependent task' : 'Add task'}
      submitFormFunc={(data) => planTaskService.createStandardTask(props.planId, data)}
      afterSubmitFunc={props.afterSubmitFunc}
      validationSchema={planTaskService.getStandardTaskValidationSchema()}
      buttons={<SubmitButton text="Save task" key="save" />}
      initialData={initialData}
    />
  );
}
