import React from 'react';
import { BaseInputProps, Option } from '../../../../shared/uibuilder/form/input';
import { UiThemeOverrider } from '../../../../shared/uibuilder/uiTheme';
import NoLabelInputLayout from '../../../../shared/uibuilder/form/layout/NoLabelInputLayout';
import BaseDropdownLayout from '../../../../shared/uibuilder/form/input/layout/BaseDropdownLayout';
import NumberDropdown from '../../../../shared/uibuilder/form/input/NumberDropdown';

export interface ReminderChooserProps extends BaseInputProps<number> {

}

export default function ReminderChooser(props: ReminderChooserProps) {
  const options = [
    {
      value: null,
      label: 'No reminder',
    },
    {
      value: 0,
      label: '0 days before',
    },
    {
      value: 1,
      label: '1 day before',
    },
    {
      value: 2,
      label: '2 days before',
    },
    {
      value: 3,
      label: '3 days before',
    },
  ] as Option<number>[];

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <UiThemeOverrider
      theme={{
        forms: {
          BaseInputLayout: NoLabelInputLayout,
        },
        inputs: {
          BaseDropdownLayout,
        },
      }}
    >
      <NumberDropdown
        hideLabel
        options={options}
        {...props}
      />
    </UiThemeOverrider>

  );
}
