import useRequiredConstraintValidator from './required';
import { ConstraintValidator } from '../index';
import useFilteredRequiredConstraintValidator from './filteredRequired';
import useMinConstraintValidator from './min';
import useMaxConstraintValidator from './max';
import useEmailListConstraintValidator from './emailList';
import useEmailConstraintValidator from './email';
import usePhoneNumberConstraintValidator from './phoneNumber';
import useMaxLengthConstraintValidator from './maxLenght';
import useMatchConstraintValidator from './match';
import useMinLengthConstraintValidator from './minLenght';
import useRegexConstraintValidator from './regex';

export interface ConstraintValidatorProvider {
  getByName: (_name: string) => ConstraintValidator,
}

function useConstraintValidatorProvider(): ConstraintValidatorProvider {
  const hooks: ConstraintValidator[] = [
    useRequiredConstraintValidator(),
    useFilteredRequiredConstraintValidator(),
    useMinConstraintValidator(),
    useMaxConstraintValidator(),
    useEmailListConstraintValidator(),
    useEmailConstraintValidator(),
    usePhoneNumberConstraintValidator(),
    useMaxLengthConstraintValidator(),
    useMinLengthConstraintValidator(),
    useMatchConstraintValidator(),
    useRegexConstraintValidator(),
  ];

  const map: { [key: string]: ConstraintValidator } = {};

  hooks.forEach((hook) => {
    map[hook.getName()] = hook;
  });

  return {
    getByName: (name) => map[name],
  };
}

export default useConstraintValidatorProvider;
