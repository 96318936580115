import {
  TaskType,
} from './commonTypes';
import { OrderDirection, ShortProfile, ShortRole } from './Types';
import { TasksGroup } from '../tasksGroupsService';
import { ProjectPlan } from '../projectPlanService';

export enum ProjectRolePermission {
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}

export type ProjectRole = {
  id: number,
  name: string,
  isAssignable: boolean,
  isOwnerRole: boolean,
  order: number,
  _permissions: ProjectRolePermission[],
}

export type ShortProjectRole = {
  id: number,
  name: string,
}

export enum ProjectType {
  LISTING = 'LISTING',
  PENDING_DUAL = 'PENDING_DUAL',
  OTHER = 'OTHER',
  PENDING_BUYER = 'PENDING_BUYER',
  PENDING_SELLER = 'PENDING_SELLER',
  ACTIVE_BUYER = 'ACTIVE_BUYER',
}

export enum ProjectAssignmentType {
  TASK_ASSIGNMENT = 'TASK_ASSIGNMENT',
  EDITOR = 'EDITOR',
  VIEWER = 'VIEWER',
  TRANSACTION_PARTY = 'TRANSACTION_PARTY',
}

export type ProjectAssignment = {
  id: number,
  personId: number
  projectId: number
  roleId: number,
  type: ProjectAssignmentType,
  isEditable: boolean,
  _person: ShortProfile,
  _role: ShortRole,
  _project: ShortProject,
}

export type ShortProject = {
  id: number,
  name: string,
  type: ProjectType,
}

export enum ProjectStatus {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  ARCHIVED = 'ARCHIVED',
  CLOSED_PAID = 'CLOSED_PAID',
}

export type TotalTasks = {
  pastDue: number,
  active: number,
  all: number
}

export type ShortTaskDto = {
  id: number,
  name: string
  dueDate?: DueDate
}

export type PastDueDatesByMember = {
  personId: number
  count: number
}

export type Statistic = {
  nextKeyDate?: ShortTaskDto
  totalTasks: TotalTasks,
  pastDueTasks: PastDueDatesByMember[]
  details: ProjectDetailsFieldValue[]
}

export interface Project {
  id: number,
  name: string;
  status: ProjectStatus;
  type: ProjectType;
  planId: number,
  projectPlanId?: number,
  startDate: Date,
  endDate: Date,
  assignments: ProjectAssignment[]
  statistic?: Statistic
  isMinimumDataProvided: boolean
  detailsFieldsSetId?: number
  _plan?: ProjectPlan
}



export interface ProjectIdWithName {
  id: number,
  name: string,
  type: ProjectType,
}

export interface ProjectFilter {
  status?: ProjectStatus;
  types: {
    selectAll: boolean,
    selected: ProjectType[]
  };
  assignedTo: {
    selectAll: boolean
    selected: number[]
  };
  nameContains?: string;
  withPastDueOnly?: boolean;
}

export type SortByByTypes = Record<string, Record<string, OrderDirection>>;

export enum ProjectUpdateOption {
  ADJUST_DATES = 'ADJUST_TASKS_DATES',
  DO_NOT_CHANGE_DATES = 'DO_NOT_CHANGE_DATES'
}

export enum ProjectOrderBy {
  NAME = 'name',
  COMMISSION = 'commission',
  CLOSING_DATE = 'closingDate ',
}

export const PROJECTS_UPDATED_IN_BULK = 'PROJECTS_UPDATED_IN_BULK';

export const PROJECT_UPDATED = 'PROJECT_UPDATED';

export enum ProjectDetailsFieldType {
  SHORT_TEXT = 'SHORT_TEXT',
  LONG_TEXT = 'LONG_TEXT',
  PRICE = 'PRICE',
  DATE = 'DATE',
}

export type ProjectDetailsFieldSettingsIdType = number;

export type ProjectDetailsFieldSettings = {
  fieldType: ProjectDetailsFieldType,
  name: string,
  id: ProjectDetailsFieldSettingsIdType,
  order: number,
}

export type ProjectDetailsFieldValue = {
  setting: ProjectDetailsFieldSettings
  value?: string | Date | number
  linkedTaskId?: number
  linkedTask?: ShortTaskDto
  approximate?: boolean
}

// projectTasksService.tsx

export enum TaskStatus {
  COMPLETED = 'COMPLETED',
  PARTIALLY_COMPLETED = 'PARTIALLY_COMPLETED',
  NOT_COMPLETED = 'NOT_COMPLETED'
}

export interface ParentTaskRelation {
  parentTaskId: number;
  days: number;
  parentTaskName: string;
}

export type TaskAssignmentRemindDays = number | undefined;

export interface TaskAssignment {
  id?: number,
  personId?: number,
  roleId?: number,
  remindDays?: TaskAssignmentRemindDays
  _profile?: ShortProfile
  _role?: ProjectRole
}

export enum DueDateFilterValue {
  TOMORROW = 'TOMORROW',
  TODAY = 'TODAY',
  PAST_DUE = 'PAST_DUE'
}

export type DueDate = {
  date: Date
  time?: Date
}

export enum TaskPermission {
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  COMPLETE = 'COMPLETE',
  UNCOMPLETE = 'UNCOMPLETE',
  READ_COMMENTS = 'READ_COMMENTS',
  CREATE_COMMENT = 'CREATE_COMMENT',
  SEND_EMAIL = 'SEND_EMAIL',
  SEND_SMS = 'SEND_SMS',
}

export enum CommentSeenStatus {
  NO_COMMENTS = 'NO_COMMENTS',
  READ = 'READ',
  UNREAD = 'UNREAD'
}

export interface AbstractTask {
  id: number,
  projectId: number,
  name: string,
  description?: string,
  status: TaskStatus,
  dueDate?: DueDate
  isKeyDate: boolean
  isPrivate: boolean
  isDuringBusinessWeekOnly: boolean
  order: number
  parentTaskRelation?: ParentTaskRelation,
  assignments: TaskAssignment[]
  type: TaskType
  commentSeenStatus: CommentSeenStatus
  completedAt?: Date,
  linkedFieldId?: number,
  numberOfComments: number,
  permissions: TaskPermission[]
  _project: ShortProject,
  _rootTaskId?: number,
  _hasDependentTasks: boolean,
  _approximateDueDate?: Date,
  _dependencyLevel?: number
  _renderedName: string
  groupId?: number,
  _group?: TasksGroup,
}

export interface StandardTask extends AbstractTask {

}

export enum EmailRecipientType {
  RECIPIENT = 'RECIPIENT',
  BCC = 'BCC',
  CC = 'CC',
}

export type EmailRecipient = {
  id?: number
  personId?: number
  roleId?: number
  type: EmailRecipientType
  _profile?: ShortProfile
}

export enum SendingStatus {
  NOT_SENT = 'NOT_SENT',
  SENT = 'SENT_OK',

  SENT_AND_OPENED = 'SENT_AND_OPENED',
  ERROR_OCCURRED = 'SENT_ERROR'
}

export interface EmailTask extends AbstractTask {
  senderRoleId?: number;
  senderId?: number;
  isAutoSent: boolean;
  emailSendingStatus: SendingStatus;
  recipients: EmailRecipient[];
  _sender?: ShortProfile,
}

export type SmsRecipient = {
  id?: number
  personId?: number
  roleId?: number
  _profile?: ShortProfile
}

export interface SmsTask extends AbstractTask {
  isAutoSent: boolean;
  sendingStatus: SendingStatus;
  recipients: SmsRecipient[];
}

export const PROJECT_TASKS_UPDATED = 'PROJECT_TASKS_UPDATED';

export type TasksFilter = {
  dueDateFrom?: Date,
  dueDateTo?: Date,
  noDueDate?: boolean,
  nameContains?: string,
  showActive?: boolean,
  showCompleted?: boolean,
  assigneesIds?: number[],
  showStandardTasks?: boolean,
  showEmailTasks?: boolean
  showSmsTasks?: boolean
  showKeyDatesOnly?: boolean,
  types?: string[],
  rootTasksIds?: number[],
  ids?: number[],
  isDependentOnStartDate?: boolean,
  isDependentOnEndDate?: boolean,
  tasksGroupsIds?: number[],
}

export interface RoleAssignment {
  id: number,
  roleId: number,
  roleName: string,
  isEditable: boolean
  isAssignable: boolean
}
