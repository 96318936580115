import React from 'react';
import Row8x4x from '../../shared/web/uitheme/form/Row8x4x';
import UpdatePasswordForm from '../../people/me/UpdateProfile/UpdatePasswordForm';
import InnerBlock from '../../shared/web/layout/InnerBlock';
import { SettingsBreadcrumbs } from '../settingsModule';
import { Breadcrumb } from '../../shared/web/layout/Breadcrumbs';

export default function UpdatePasswordPage() {
  return (
    <>
      <SettingsBreadcrumbs>
        <Breadcrumb text="Update Password" />
      </SettingsBreadcrumbs>
      <InnerBlock title="Update Password">
        <Row8x4x className="no-clear">
          <UpdatePasswordForm />
        </Row8x4x>
      </InnerBlock>
    </>
  );
}
