import React from 'react';
import { useEventsPublisher } from 'event-bus';
import {
  ButtonColor,
  ButtonSize,
  Button,
  useMassUpdateServiceRequired
} from 'ui-builder';
import { MassUpdateFormProps } from '../../../shared/widgets/MassUpdate';
import usePlansApi from '../../plansApi';
import { ConfirmPositiveActionPopup, usePopupFormManager } from 'features/nekst-widgets';
import { PLAN_TASKS_UPDATED } from 'nekst-api';

function usePrivateService() {
  const plansApi = usePlansApi();

  const markAsPrivate = async (ids: number[]) => {
    await plansApi.put('/plantasks/bulk/private', {
      ids,
    });
  };

  return {
    markAsPrivate,
  };
}

function MakePrivateForm(props: MassUpdateFormProps) {
  const service = usePrivateService();
  const eventsPublisher = useEventsPublisher();

  return (
    <ConfirmPositiveActionPopup
      title="Mass Update: Private"
      submitFormFunc={async () => {
        await service.markAsPrivate(props.ids);
        eventsPublisher.publish(PLAN_TASKS_UPDATED, {});
      }}
      message={'Click "Confirm" to make the Selected Tasks each as Private.'}
    />
  );
}

export default function MakePrivateButton() {
  const massUpdateService = useMassUpdateServiceRequired();

  const popupManager = usePopupFormManager();

  const ids = massUpdateService.getCheckedIds();

  return (
    <Button
      text="Private"
      isDisabled={ids.length === 0}
      onClick={async () => {
        popupManager.openForm(<MakePrivateForm ids={ids} />);
      }}
      color={ButtonColor.GRAY}
      size={ButtonSize.SMALL}
      isFlat
      isFilled
    />
  );

}
