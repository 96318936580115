import React from 'react';
import IconButton, { IconType } from '../../../shared/widgets/IconButton';
import { DocumentsTemplate } from './documentsTemplatesService';
import CreateDocumentsTemplateForm from './CreateDocumentsTemplateForm';
import { usePopupFormManager } from 'features/nekst-widgets';
import { useShowContext } from 'ui-builder';

export default function CopyTemplateButton() {

  const popupManager = usePopupFormManager();
  const showContext = useShowContext<DocumentsTemplate>();

  const template = showContext.data!;

  return (
    <IconButton
      onClick={() => {
        popupManager.openForm(
          <CreateDocumentsTemplateForm
            title={`Copy template "${template.name}"`}
            initialData={{
              name: `Copy of ${template.name}`,
              labelsIds: template.labelsIds,
            }}
          />,
        );
      }}
      type={IconType.COPY}
      title="Copy Template"
    />
  );
}
