import React from 'react';
import { FormAttributes, useForm } from './formHelper';
import useDataLoader from 'data-loader';
import { FormContext, FormFieldsData } from './FormContext';
import { CancelButton, SubmitButton } from './Button';
import { FormLayout } from './FormLayout';

/**
 * Base component for all the forms. Includes common behavior of all the forms.
 *
 * @returns {*}
 * @constructor
 */
export function FormTemplate<
  DataType extends Nullable<FormFieldsData
  > = FormFieldsData, ResponseType = DataType>(
  formAttributes: FormAttributes<DataType, ResponseType>,
) {
  const contextData = useForm(formAttributes);

  const setData = (data: DataType) => {
    const { setFormData } = contextData;

    if (data) {
      setFormData(data);
    }
  };

  const {
    getDataFunc,
    children,
  } = formAttributes;

  const buttons = formAttributes.buttons !== undefined
    ? formAttributes.buttons
    : (
      <>
        <SubmitButton text="Submit" key="submit-button" />
        <CancelButton
          text="Cancel"
          key="cancel-button"
          onClick={formAttributes.cancelFunc}
        />
      </>
    );

  const { loading } = useDataLoader(getDataFunc, setData);

  if (!loading) {
    return (
      <FormContext.Provider
        value={contextData}
      >
        <FormLayout
          buttons={buttons}
          title={formAttributes.title}
          className={formAttributes.className}
          testID={formAttributes.testID || 'form'}
          containerStyle={formAttributes.containerStyle}
          buttonsBlockStyle={formAttributes.buttonsBlockStyle}
          formBodyStyle={formAttributes.formBodyStyle}
          isSubmitting={contextData.loading}
        >
          {children}
        </FormLayout>
      </FormContext.Provider>
    );
  } else {
    return null;
  }
}

