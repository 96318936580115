import React from 'react';
import { SubmitButton, AfterSubmitFunc } from 'ui-builder';
import {
  AbstractTask,
  EmailTask,
  ProjectType,
  TeamPermission,
  useProjectTasksService
} from 'nekst-api';
import BaseEmailTaskForm from './BaseEmailTaskForm';
import SmartTagsUpdater from '../../../../../plans/tasks/CreateUpdate/SmartTagsUpdater';
import { useAuthorizationChecker } from 'authorization-scope';

export interface CreateEmailTaskFormProps {
  afterSubmitFunc?: AfterSubmitFunc;
  parentTask?: AbstractTask;
  projectId?: number;
  projectType?: ProjectType;
}

export default function CreateEmailTaskForm(props: CreateEmailTaskFormProps) {
  const projectTasksService = useProjectTasksService();

  const { isGranted } = useAuthorizationChecker();

  let initialData = {
    projectId: props.projectId,
    description: isGranted(TeamPermission.USE_SMART_TAGS) ? `<p>Hi <span class="mention" data-index="0" data-denotation-char="#" data-value="recipients.to.firstName" data-id="recipients.to.firstName">
          <span contenteditable="false"><span class="ql-mention-denotation-char">#</span>recipients.to.firstName</span></span>,
          </p>
          <p>...email content here...</p>
          <p><span class="mention" data-index="0" data-denotation-char="#" data-value="sender.signature" data-id="sender.signature">
          <span contenteditable="false"><span class="ql-mention-denotation-char">#</span>sender.signature</span>
          </span>
          </p>` : '',
  } as EmailTask;

  if (props.parentTask) {
    initialData = {
      ...initialData,
      parentTaskRelation: {
        parentTaskId: props.parentTask.id,
        parentTaskName: props.parentTask.name,
      },
    } as EmailTask;
  }

  return (
    <BaseEmailTaskForm
      submitFormFunc={
        (formData) => {
          return projectTasksService.createEmailTask(formData.projectId, formData);
        }
      }
      title="Add Email Task"
      buttons={<SubmitButton text="Save task" key="save" />}
      afterSubmitFunc={props.afterSubmitFunc}
      initialData={initialData}
      showProjectDropdown={!props.projectId}
      projectType={props.projectType}
    >
      <SmartTagsUpdater />
    </BaseEmailTaskForm>
  );
}
