import { CommentsNumberFieldLayoutProps } from './types';
import React from 'react';

import styles from './CommentsNumberFieldLayout.module.scss';

export default function CommentsNumberFieldWebLayout(props: CommentsNumberFieldLayoutProps) {
  if (props.numberOfComments > 0) {
    const classes = [styles.icon];

    if (props.hasUnseenComments) {
      classes.push(styles.hasUnseenComments);
    }

    const word = props.numberOfComments === 1 ? 'comment' : 'comments';

    return (
      <span className={styles.block}>
        <span className={classes.join(' ')}>
          &nbsp;
        </span>
        <span className={styles.iconText}>
          {`${props.numberOfComments} ${word}`}
        </span>
      </span>

    );
  } else {
    return null;
  }
}
