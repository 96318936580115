import React from 'react';
import {
  ContactAssignments,
} from 'nekst-api';

import IconButton, { IconType } from '../../../shared/widgets/IconButton';
import { useDeleteContactAssignmentsLogic } from 'features/tasks-forms';


export interface Props {
  projectId: number,
  contactAssignments: ContactAssignments,
}

export default function DeleteContactButton(props: Props) {

  const buttonLogic = useDeleteContactAssignmentsLogic();

  if (buttonLogic.hasEditableAssignments(props.contactAssignments)) {
    return (
      <IconButton
        onClick={buttonLogic.getDeleteFunc(props.projectId, props.contactAssignments)}
        type={IconType.TRASH_CAN}
        title="Delete Contact"
      />
    );
  } else {
    return null;
  }
}
