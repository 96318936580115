import React from 'react';
import useProjectDocumentsService, { ProjectDocument } from './projectDocumentsService';
import { usePopupManager, useShowContext } from 'ui-builder';
import IconButton, { IconType } from '../../shared/widgets/IconButton';
import { ConfirmDeletePopup } from 'features/nekst-widgets';

function DeleteDocumentForm(
  props: {
    data: ProjectDocument,
  },
) {
  const projectDocumentsService = useProjectDocumentsService();

  const name = props.data._file?.name || props.data._label?.name;

  return (
    <ConfirmDeletePopup
      deleteFunc={() => projectDocumentsService.deleteProjectDocument(props.data.id)}
      message={`Are you sure you want to delete "${name}" document?`}
    />
  );
}

export default function DeleteDocumentButton() {
  const popupManager = usePopupManager();
  const showContext = useShowContext<ProjectDocument>();

  return (
    <IconButton
      type={IconType.TRASH_CAN}
      onClick={() => popupManager.openPopup(<DeleteDocumentForm data={showContext.data!} />)}
      title="Delete Document"
    />
  );
}
