import React, { ReactNode } from 'react';
import {
  DateTimeField,
  SimpleBlock,
  useShowContextRequired,
  Text,
} from 'ui-builder';
import {
  KeyDateCompletedNotification,
  KeyDateReminderNotification,
  MentionedInCommentNotification, MentionedInNoteNotification,
  Notification,
  NotificationType,
  ProjectAssignmentCreatedNotification,
  ProjectNoteCreatedNotification,
  ProjectStatusUpdatedNotification,
  ProjectTypeUpdatedNotification, TaskCommentCreatedNotification, TaskDueDateReminderNotification,
  useProjectStatusHelper,
} from 'nekst-api';

import styles from './NotificationListItem.module.scss';
import { PersonWidget, UserLogoSize } from 'features/nekst-widgets';
import { NotificationListLayoutProps } from '../types';
import { useProjectTypeHelper } from '../../../Projects';
import NotificationIcon from './NotificationIcon';
import { mobileStyles } from './notificationListStyles.mobile';

function trimText(text: string, maxLength: number) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;

}

const NotificationListReactContext = React.createContext<NotificationListLayoutProps | undefined>(undefined);

function useNotificationListContextRequired() {
  const context = React.useContext(NotificationListReactContext);
  if (!context) {
    throw new Error('NotificationListReactContext is not provided');
  }
  return context;
}



function BaseNotification(
  props: {
    title: ReactNode,
    content: ReactNode,
    notification: Notification,
    onClick: () => void
  }
) {
  const withPhoto = !!props.notification.initiator;

  const isNew = !props.notification.isRead;

  const blockStyles = [
    styles.itemContainer,
    isNew ? styles.notRead : '',
  ];

  const blockMobileStyles = {
    ...mobileStyles.itemContainer,
    ...isNew ? mobileStyles.notRead : {},
  };

  return (
    <SimpleBlock
      className={blockStyles.join(' ')}
      onClick={() => {
        props.onClick();
      }}
      style={blockMobileStyles}
    >
      <SimpleBlock
        className={[styles.iconContainer, withPhoto ? styles.withPhoto : ''].join(' ')}
        style={mobileStyles.iconContainer}
      >
        <SimpleBlock
          className={styles.iconInnerContainer}
          style={mobileStyles.iconInnerContainer}
        >
          {props.notification.initiator ? (
            <PersonWidget
              profile={props.notification.initiator}
              size={UserLogoSize.BIG}
              withName={false}
              style={mobileStyles.avatar}
            />
          ) : null}
          <NotificationIcon
            type={props.notification.type}
            withAvatar={withPhoto}
          />
        </SimpleBlock>
      </SimpleBlock>
      <SimpleBlock
        className={styles.content}
        style={mobileStyles.content}
      >
        <SimpleBlock className={styles.title} style={mobileStyles.titleBlock}>
          <Text style={mobileStyles.title} bold>{props.title}</Text>
        </SimpleBlock>
        <SimpleBlock className={styles.main} style={mobileStyles.mainBlock}>
          <Text>{props.content}</Text>
        </SimpleBlock>
        <SimpleBlock className={styles.date}>
          <Text style={mobileStyles.date}>
            <DateTimeField
              source="date"
              value={props.notification.createdAt}
              style={mobileStyles.date}
            />
          </Text>
        </SimpleBlock>
        {isNew ? (
          <SimpleBlock
            className={styles.newBadge}
            style={mobileStyles.newBadge}
          />
        ) : null}
      </SimpleBlock>
    </SimpleBlock>
  );
}

function KeyDateReminderNotificationLayout(props: {
  notification: KeyDateReminderNotification,
}) {
  const context = useNotificationListContextRequired();
  return (
    <BaseNotification
      title={props.notification.payload.project.name}
      content={(
        <>
          REMINDER: <Text italic>"{props.notification.payload.task.name}"</Text>
          {' is due today'}
        </>
      )}
      notification={props.notification}
      onClick={() => context.openProjectAndTaskFunc(
        props.notification.payload.project.id,
        props.notification.payload.task.id
      )}
    />
  );
}

function ProjectNoteCreatedNotificationLayout(props: {
  notification: ProjectNoteCreatedNotification,
}) {
  const context = useNotificationListContextRequired();
  return (
    <BaseNotification
      title={props.notification.payload.project.name}
      content={(
        <>
          <Text italic>{props.notification.initiator?.name.fullName}</Text>
          {' added a new note: '}
          <Text italic>"{trimText(props.notification.payload.note.text, 20)}"</Text>
        </>
      )}
      notification={props.notification}
      onClick={() => context.openProjectNotesFunc(
        props.notification.payload.project.id,
      )}
    />
  );
}

function ProjectAssignmentCreatedNotificationLayout(props: {
  notification: ProjectAssignmentCreatedNotification,
}) {
  const context = useNotificationListContextRequired();
  return (
    <BaseNotification
      title={props.notification.payload.project.name}
      content={(
        <>
          You have been added to this property
        </>
      )}
      notification={props.notification}
      onClick={() => context.openProjectFunc(
        props.notification.payload.project.id,
      )}
    />
  );
}

function ProjectStatusUpdatedNotificationLayout(
  props: {
    notification: ProjectStatusUpdatedNotification,
  }
) {
  const context = useNotificationListContextRequired();
  const projectStatusHelper = useProjectStatusHelper();

  return (
    <BaseNotification
      title={props.notification.payload.project.name}
      content={(
        <>
          Status has changed
          to <Text
          italic>"{projectStatusHelper.getLabel(props.notification.payload.newStatus)}"</Text>
        </>
      )}
      notification={props.notification}
      onClick={() => context.openProjectFunc(
        props.notification.payload.project.id,
      )}
    />
  );
}

function ProjectTypeUpdatedNotificationLayout(
  props: {
    notification: ProjectTypeUpdatedNotification,
  }
) {
  const context = useNotificationListContextRequired();

  const projectTypeHelper = useProjectTypeHelper();

  return (
    <BaseNotification
      title={props.notification.payload.project.name}
      content={(
        <>
          Type has changed
          to
          <Text italic>"{projectTypeHelper.getLabel(props.notification.payload.newType)}"</Text>
        </>
      )}
      notification={props.notification}
      onClick={() => context.openProjectFunc(
        props.notification.payload.project.id,
      )}
    />
  );
}

function TaskCommentCreatedNotificationLayout(
  props: {
    notification: TaskCommentCreatedNotification,
  }
) {
  const context = useNotificationListContextRequired();
  return (
    <BaseNotification
      title={props.notification.payload.project.name}
      content={(
        <>
          <Text italic>{props.notification.initiator?.name.fullName}</Text>
          {' '}
          commented on the task
          {' '}
          <Text italic>"{props.notification.payload.task.name}"</Text>: <Text
          italic>"{trimText(props.notification.payload.comment.text, 20)}"</Text>
        </>
      )}
      notification={props.notification}
      onClick={() => context.openProjectNotesFunc(
        props.notification.payload.project.id,
      )}
    />
  );
}


function TaskReminderNotificationLayout(
  props: {
    notification: TaskDueDateReminderNotification,
  }
) {
  const context = useNotificationListContextRequired();

  const remindDays = props.notification.payload.remindDays;

  let remindText = '';

  if (remindDays === 0) {
    remindText = 'today';
  } else if (remindDays === 1) {
    remindText = 'tomorrow';
  } else {
    remindText = `in ${remindDays} days`;
  }

  return (
    <BaseNotification
      title={props.notification.payload.project.name}
      content={(
        <>
          REMINDER: <Text italic>"{props.notification.payload.task.name}"</Text>
          {` is due ${remindText}`}
        </>
      )}
      notification={props.notification}
      onClick={() => context.openProjectAndTaskFunc(
        props.notification.payload.project.id,
        props.notification.payload.task.id
      )}
    />
  );
}

function KeyDateCompletedNotificationLayout(
  props: {
    notification: KeyDateCompletedNotification
  },
) {
  const context = useNotificationListContextRequired();
  return (
    <BaseNotification
      title={props.notification.payload.project.name}
      content={(
        <>
          <Text italic>{props.notification.initiator?.name.fullName}</Text>
          {' '}
          completed the key date task
          {' '}
          <Text italic>"{props.notification.payload.task.name}"</Text>
        </>
      )}
      notification={props.notification}
      onClick={() => context.openProjectNotesFunc(
        props.notification.payload.project.id,
      )}
    />
  );
}

function  MentionedInCommentNotificationLayout(
  props: {
    notification: MentionedInCommentNotification
  }
) {
  const context = useNotificationListContextRequired();

  return (
    <BaseNotification
      title={props.notification.payload.project.name}
      content={(
        <>
          <Text italic>{props.notification.initiator?.name.fullName}</Text>
          {' '}
          mentioned you in the comment on the task
          <Text italic>"{props.notification.payload.task.name}"</Text>:
          {' '}
          <Text italic>"{trimText(props.notification.payload.comment.text, 20)}"</Text>
        </>
      )}
      notification={props.notification}
      onClick={() => context.openProjectAndTaskFunc(
        props.notification.payload.project.id,
        props.notification.payload.task.id
      )}
    />
  );
}


function  MentionedInProjectNoteNotificationLayout(
  props: {
    notification: MentionedInNoteNotification
  }
) {
  const context = useNotificationListContextRequired();

  return (
    <BaseNotification
      title={props.notification.payload.project.name}
      content={(
        <>
          <Text italic>{props.notification.initiator?.name.fullName}</Text>
          {' '}
          mentioned you in the note:
          {' '}
          <Text italic>"{trimText(props.notification.payload.note.text, 20)}"</Text>
        </>
      )}
      notification={props.notification}
      onClick={() => context.openProjectNotesFunc(
        props.notification.payload.project.id,
      )}
    />
  );
}

function NotificationItemLayoutChooser() {

  const showContext = useShowContextRequired<Notification>();
  const notification = showContext.data!;

  switch (notification.type) {
    case NotificationType.KEY_DATE_REMINDER:
      return (
        <KeyDateReminderNotificationLayout
          notification={notification as KeyDateReminderNotification}
        />
      );
    case NotificationType.PROJECT_NOTE_CREATED:
      return (
        <ProjectNoteCreatedNotificationLayout
          notification={notification as ProjectNoteCreatedNotification}
        />
      );
    case NotificationType.PROJECT_ASSIGNMENT_CREATED:
      return (
        <ProjectAssignmentCreatedNotificationLayout
          notification={notification as ProjectAssignmentCreatedNotification}
        />
      );
    case NotificationType.PROJECT_STATUS_UPDATED:
      return (
        <ProjectStatusUpdatedNotificationLayout
          notification={notification as ProjectStatusUpdatedNotification}
        />
      );
    case NotificationType.PROJECT_TYPE_UPDATED:
      return (
        <ProjectTypeUpdatedNotificationLayout
          notification={notification as ProjectTypeUpdatedNotification}
        />
      );
    case NotificationType.TASK_COMMENT_CREATED:
      return (
        <TaskCommentCreatedNotificationLayout
          notification={notification as TaskCommentCreatedNotification}
        />
      );
    case NotificationType.TASK_DUE_DATE_REMINDER:
      return (
        <TaskReminderNotificationLayout
          notification={notification as TaskDueDateReminderNotification}
        />
      );
    case NotificationType.KEY_DATE_COMPLETED:
      return (
        <KeyDateCompletedNotificationLayout
          notification={notification as KeyDateCompletedNotification}
        />
      );
    case NotificationType.MENTIONED_IN_COMMENT:
      return (
        <MentionedInCommentNotificationLayout
          notification={notification as MentionedInCommentNotification}
        />
      );
    case NotificationType.MENTIONED_IN_NOTE:
      return (
        <MentionedInProjectNoteNotificationLayout
          notification={notification as MentionedInNoteNotification}
        />
      );
    default:
      return null;
  }
}

export default function NotificationListLayout(props: NotificationListLayoutProps) {
  return (
    <NotificationListReactContext.Provider value={props}>
      <NotificationItemLayoutChooser />
    </NotificationListReactContext.Provider>
  );
}
