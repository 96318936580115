import { ConstraintValidator, ConstraintValue } from '../index';
import useConstraintValidatorHelper from './constaintValidatorHelper';

export interface MaxConstraintValidator extends ConstraintValidator<number> {
}

export default function useMaxConstraintValidator(): MaxConstraintValidator {

  const helperFactory = useConstraintValidatorHelper<number>();
  const getName = () => {
    return 'max';
  };
  const validate = (
    object: Record<string, any>,
    fieldName: string,
    constraintConfiguration: ConstraintValue<number>,
  ): boolean => {

    const helper = helperFactory.createHelper(
      object,
      fieldName,
      constraintConfiguration,
    );
    const value = helper.getValue();

    if (helper.hasValue()) {
      const constraintValue = helper.getConstraintValue();
      if (typeof value === 'number') {
        return value <= constraintValue;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const getErrorMessage = (config: ConstraintValue<number>) => {
    return (typeof config === 'object' && config.message)
      || `Field should be below or equal to ${config}`;
  };

  return {
    getName,
    validate,
    getErrorMessage,
  };
}
