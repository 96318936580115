import React, { ReactNode } from 'react';

import styles from './PageTitle.module.scss';
import useFeatureToggleService from 'feature-toggle';

export enum PageIconType {
  TASKS = 'tasks',
  CALENDAR = 'calendar',
  TRANSACTIONS = 'properties',
  CONTACTS = 'contacts',
  PLANS = 'plans',
}

export default function PageHeaderWithIcon(
  props: {
    children: ReactNode,
    iconType: PageIconType,
  }
) {

  const featureToggleService = useFeatureToggleService();

  return (
    <span className={styles.title}>
      {featureToggleService.isNewUiEnabled() ? (
        <span className={[styles.icon, styles[props.iconType]].join(' ')} />
      ) : null}
      {props.children}
    </span>
  );
}
