import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Button, { ButtonColor, ButtonSize } from '../../shared/web/button/Button';
import { ClientPortalConfiguration } from './clientPortalConfigurationService';
import { useFormContext } from 'ui-builder';

export function CopyClientPortalLinkPortal() {
  return (<span id="copy-client-portal-link" />);
}

export default function CopyClientPortalLinkButton(
  props: {
    data?: ClientPortalConfiguration,
  }
) {
  const [isCopied, setCopied] = useState(false);

  const formContext = useFormContext<ClientPortalConfiguration>();

  const data = formContext.data || props.data;

  let element;

  if (data?.enabled && data._portalUrl) {
    const onClick = () => {
      navigator.clipboard.writeText(data!._portalUrl!);
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 1000);
    };

    element = (
      <Button
        color={isCopied ? ButtonColor.GREEN : ButtonColor.GRAY}
        isFilled={false}
        onClick={onClick}
        size={ButtonSize.MEDIUM}
        text={isCopied ? 'Link Copied' : 'Copy Portal Link'}
      />
    );
  }
  const container = document.getElementById('copy-client-portal-link');

  if (container) {
    return ReactDOM.createPortal(element, container);
  } else {
    return element;
  }
}
