import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectType } from 'nekst-api';
import DefaultPortalNotesList from './DefaultNotes/DefaultPortalNotesList';
import InnerBlock from '../../shared/web/layout/InnerBlock';
import { Separator } from 'features/nekst-widgets';
import { useProjectTypeHelper } from 'features/tasks-forms';
import PortalConfigurationForm, {
  ConfigurationFormButtonPortal
} from './DefaultConfiguration/PortalConfigurationForm';

export default function PortalConfigurationPage() {
  const { projectType: projectTypeString } = useParams<{ projectType: string }>();

  const projectTypeHelper = useProjectTypeHelper();

  const projectType = useMemo(() => {
    return projectTypeString?.toUpperCase() as ProjectType;
  }, [projectTypeString])

  if (projectType) {
    return (
      <InnerBlock
        title={projectTypeHelper.getLabel(projectType)}
        headerRight={<ConfigurationFormButtonPortal />}
      >
        <PortalConfigurationForm projectType={projectType} />
        <Separator />
        <DefaultPortalNotesList projectType={projectType} />
      </InnerBlock>
    );
  } else {
    return null;
  }

}
