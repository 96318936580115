import React from 'react';
import { useNewUiTheme } from '../newUiTheme';
import { TextStyle, ViewStyle } from 'react-native';

export interface TextLinkLayoutProps {
  children: string
  onClick?: () => void,
  isThin?: boolean,
  href?: string,
  labelStyle?: TextStyle,
  style?: ViewStyle,
  className?: string,
  target?: string,
  dataKey?: string,
  title?: string,
}

export interface TextLinkProps {
  children: string;
  labelStyle?: TextStyle,
  style?: ViewStyle
  onClick?: () => void;
  isThin?: boolean,
  href?: string;
  className?: string,
  target?: string,
  dataKey?: string,
  title?: string,
}

export function TextLink(props: TextLinkProps) {
  const uiTheme = useNewUiTheme();

  return (
    <uiTheme.common.TextLinkLayout
      href={props.href}
      onClick={props.onClick}
      isThin={props.isThin}
      className={props.className}
      target={props.target}
      labelStyle={props.labelStyle}
      style={props.style}
      dataKey={props.dataKey}
      title={props.title}
    >
      {props.children}
    </uiTheme.common.TextLinkLayout>
  );
}
