import React from 'react';
import { useShowContext } from 'ui-builder';
import { AbstractTask, SmsTask, TaskType } from 'nekst-api';
import Layout from './Layout';

export function SmsField() {
  const context = useShowContext<AbstractTask>();


  if (context.data?.type === TaskType.SMS) {
    const task = context.data as SmsTask;

    return (
      <Layout sendingStatus={task.sendingStatus} />
    );
  } else {
    return null;
  }
}
