import React from 'react';

import { useShowContext } from 'ui-builder';
import { get } from 'lodash';
import Layout from './Layout';
import { TextStyle } from 'react-native';

export function TextWithLinksField(props: {
  source: string,
  value?: string,
  className?: string,
  style?: TextStyle,
}) {
  const context = useShowContext();

  let result;

  if (props.source) {
    if (context.data) {
      result = get(context.data, props.source) ?? '-';
    } else {
      result = '-';
    }
  } else {
    result = props.value;
  }

  return (
    <Layout text={result} className={props.className} style={props.style} />
  );
}
