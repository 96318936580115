import React, { ReactElement } from 'react';
import RecordLayout, { RecordLayoutProps } from './RecordLayout';
import PersonLink from './PersonLink';
import TaskLink from './TaskLink';
import { HistoryEventIconType } from './HistoryEventIcon';
import { BaseHistoryEntryData, PersonData, TaskData } from 'nekst-api';

interface SmsSentData extends BaseHistoryEntryData {
  task: TaskData,
  recipients: {
    person: PersonData,
  }[];
}

export default function SmsSentRecordLayout(
  props: RecordLayoutProps<SmsSentData>,
) {
  const recipients = props.record.data.recipients
    .map((v) => {
      return (<PersonLink key={`recipient-${v.person.id}`} person={v.person} />);
    });

  const splitWithCommas = (value: ReactElement[]) => {
    const result: ReactElement[] = [];

    value.forEach((recipient, index) => {
      result.push(recipient);
      if (index !== recipients.length - 1) {
        result.push(<React.Fragment key={`${recipient.key}-separator`}>, </React.Fragment>);
      }
    });

    return result;
  };

  const recordData = props.record.data;

  let text;
  if (recordData.person) {
    text = (
      <>
        <PersonLink person={recordData.person} />
        {' sent the sms task '}
        <TaskLink task={props.record.data.task} project={props.record.data.project} />
        {recipients.length > 0 && (
          <>
            {' to the recipient(s) '}
            {splitWithCommas(recipients)}
          </>
        )}
        .
      </>
    );
  } else {
    text = (
      <>
        {'The task '}
        <TaskLink task={props.record.data.task} project={props.record.data.project} />
        {' was automatically sent'}
        {recipients.length > 0 && (
          <>
            {' to the recipient(s) '}
            {splitWithCommas(recipients)}
          </>
        )}
        .
      </>
    );
  }

  return (
    <RecordLayout
      text={text}
      date={props.record.when}
      iconType={HistoryEventIconType.SMS_SENT}
    />
  );
}
