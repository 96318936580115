import React from 'react';
import { useAllTasksService } from 'nekst-api';
import TasksList, { ViewOption } from '../../projects/view/tasks/list/TasksList';
import PageHeaderWithIcon, {
  PageIconType
} from '../../shared/widgets/PageTitle/PageHeaderWithIcon';

export default function AllTasksList() {
  const tasksService = useAllTasksService();

  return (
    <TasksList
      getDataFunc={tasksService.getTasks}
      showProjectName
      view={ViewOption.LIST}
      title={
        <PageHeaderWithIcon iconType={PageIconType.TASKS}>
          Tasks
        </PageHeaderWithIcon>
      }
      showStatusFilter={false}
    />
  );
}
