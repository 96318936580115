import React, {
  ReactElement,
  useContext,
  useMemo,
  useState,
} from 'react';
import { OrderBy } from 'ui-builder';
import { ProjectType } from 'nekst-api';
import useTeamConfigurationService from '../teamConfiguratiolnService';
import useDataLoader from '../../shared/uibuilder/form/dataLoader';
import LoadingAnimation from '../../shared/LoadingAnimation';

export type ProjectTypeListConfiguration = {
  columns?: number[],
  orderBy?: OrderBy,
}

export type ProjectListConfiguration = {
  byPage?: number,
  [ProjectType.OTHER]?: ProjectTypeListConfiguration,
  [ProjectType.LISTING]?: ProjectTypeListConfiguration,
  [ProjectType.PENDING_BUYER]?: ProjectTypeListConfiguration,
  [ProjectType.PENDING_SELLER]?: ProjectTypeListConfiguration,
  [ProjectType.PENDING_DUAL]?: ProjectTypeListConfiguration,
  [ProjectType.ACTIVE_BUYER]?: ProjectTypeListConfiguration,
}

type ContextType = {
  configuration?: ProjectListConfiguration,
  setByPage: (value: number) => Promise<void>,
  setTypeConfiguration: (type: ProjectType, value: ProjectTypeListConfiguration) => Promise<void>,
  getTypeConfiguration: (type: ProjectType) => ProjectTypeListConfiguration,
}

const ProjectListConfigurationReactContext = React.createContext<
  ContextType
>({
  setByPage: async () => {
    // ignored
  },
  setTypeConfiguration: async () => {
    // ignored
  },
  getTypeConfiguration: () => {
    return {};
  },
});

export default function ProjectsListConfigurationContext(
  props: {
    children: ListOrSingle<ReactElement>
  },
) {
  const teamConfigurationService = useTeamConfigurationService();

  const [configuration, setConfiguration] = useState<ProjectListConfiguration>();

  const loadConfiguration = async () => {
    const result = await teamConfigurationService.getConfiguration();

    return result.projects as ProjectListConfiguration || undefined;
  };

  const { loading } = useDataLoader(
    loadConfiguration,
    setConfiguration,
  );

  const contextValue = useMemo<ContextType>(() => {
    return {
      configuration,
      setByPage: async (value) => {
        const newValue = {
          ...configuration || {},
          byPage: value,
        };

        setConfiguration(newValue);

        await teamConfigurationService.updateConfiguration({
          projects: newValue,
        });
      },
      setTypeConfiguration: async (type: ProjectType, value) => {
        const newValue = {
          ...configuration || {},
          [type]: value,
        };

        setConfiguration(newValue);

        await teamConfigurationService.updateConfiguration({
          projects: newValue,
        });
      },
      getTypeConfiguration: (projectType) => {
        if (configuration) {
          return configuration[projectType] as ProjectTypeListConfiguration || {};
        } else {
          return {};
        }
      },
    };
  }, [JSON.stringify(configuration)]);

  if (!loading) {
    return (
      <ProjectListConfigurationReactContext.Provider value={contextValue}>
        {props.children}
      </ProjectListConfigurationReactContext.Provider>
    );
  } else {
    return (<LoadingAnimation />);
  }
}

export function useProjectListConfigurationContext() {
  return useContext(ProjectListConfigurationReactContext);
}
