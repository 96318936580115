import React, { useEffect, useState } from 'react';
import {
  ProjectAssignmentType,
  TaskAssignment,
} from 'nekst-api';
import { useFormContext } from 'ui-builder';
import TransactionPartiesInputLayout, {
  Choice,
} from '../../../../../shared/widgets/TransactionPartiesInputLayout';
import useInputHelper from '../../../../../shared/uibuilder/form/input/inputHelper';
import { BaseInputProps } from '../../../../../shared/uibuilder/form/input';

import useProjectAssignmentsHelper from '../projectAssignmentsHelper';
import AssignmentNameWithRoles from '../../shared/AssignmentNameWithRoles';

export interface TaskAssignmentInputProps extends BaseInputProps<TaskAssignment[]> {

}

type TaskAssignmentInputIdFields = {
  projectRoleId?: number
  personId?: number
}

export default function TaskAssignmentsInput(props: TaskAssignmentInputProps) {

  const helper = useInputHelper<TaskAssignment[]>(props);

  const formContext = useFormContext();
  const formData = formContext.data!;

  const [fieldData, setFormFields] = useState(formData[props.source] as TaskAssignment[] || []);

  useEffect(() => {
    if (fieldData.length === 0) {
      setFormFields(formData[props.source] || []);
    }
  }, [formData[props.source]]);

  const { projectAssignments } = useProjectAssignmentsHelper(
    () => {
      helper.getOnChangeCallback()({
        target: {
          value: [],
        },
      });
    },
  );

  const assignToCurrentUserByDefault = async () => {
    const numberOfCheckedElements = (helper.getValue() || [])
      .filter((item) => item.personId).length;

    if (projectAssignments && numberOfCheckedElements === 0) {
      const defaultAssignee = projectAssignments.find((v) => v.isAssignable)!;
      helper.getOnChangeCallback()({
        target: {
          value: [
            {
              personId: defaultAssignee.personId,
            },
            ...helper.getValue() || [],
          ],
        },
      });
    }
  };

  useEffect(() => {
    if (projectAssignments.length > 0) {
      assignToCurrentUserByDefault();
    }
  }, [projectAssignments.length, JSON.stringify(helper.getValue())]);

  const choices: Choice<TaskAssignmentInputIdFields>[] = projectAssignments
    .filter((item) => item.type === ProjectAssignmentType.TASK_ASSIGNMENT)
    .map((item) => ({
      id: {
        personId: item.personId!,
      },
      name: (<AssignmentNameWithRoles assignment={item} />),
    }));

  fieldData.forEach((assignment) => {
    if (
      assignment.roleId && !assignment.personId
      && !fieldData.find((v) => ((v.roleId === assignment.roleId) && !!v.personId))
    ) {
      choices.push({
        id: {
          projectRoleId: assignment.roleId,
        },
        name: assignment._role!.name,
      });
    }
  });

  return (
    <TransactionPartiesInputLayout<TaskAssignment, TaskAssignmentInputIdFields>
      label={helper.getLabel()}
      isDisabled={helper.getIsDisabled()}
      findFunc={(id, data) => {
        return (!!data.personId && data.personId === id.personId) || (
          !!data.roleId && data.roleId === id.projectRoleId
        );
      }}
      toApiModel={(data) => ({
        roleId: data.id.projectRoleId,
        personId: data.id.personId,
        remindDays: data.remindDays,
      })}
      value={helper.getValue() || []}
      choices={choices}
      onChangeCallback={helper.getOnChangeCallback()}
      source={props.source}
    />
  );
}
