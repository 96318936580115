import React from 'react';
import RecordLayout, { RecordLayoutProps, useRecordLayoutContext } from './RecordLayout';
import PersonLink from './PersonLink';

import TaskLink from './TaskLink';
import { HistoryEventIconType } from './HistoryEventIcon';
import { BaseHistoryEntryData, TaskData } from 'nekst-api';

interface EmailIsOpenedData extends BaseHistoryEntryData {
  task: TaskData,
}

export default function EmailIsOpenedRecordLayout(props: RecordLayoutProps<EmailIsOpenedData>) {
  const { isInTaskContext } = useRecordLayoutContext();

  return (
    <RecordLayout
      text={(
        <>
          <PersonLink person={props.record.data.person!} />

          {isInTaskContext && (' opened this email')}
          {!isInTaskContext && (
            <>
              {' opened the email '}
              <TaskLink
                task={props.record.data.task}
                project={props.record.data.project}
              />
            </>
          )}
          .
        </>
      )}
      date={props.record.when}
      iconType={HistoryEventIconType.EMAIL_OPENED}
    />
  );
}
