import React, { ReactElement, useContext, useMemo, useState } from 'react';
import {
  ProjectFilter,
  ProjectsStatistic,
  useProjectStatisticService
} from 'nekst-api';
import { LoadingAnimation, useFilterContext } from 'ui-builder';
import useDataLoader from 'data-loader';

export interface ProjectStatisticContextData {
  isLoading: boolean,
  statistic?: ProjectsStatistic,
  refreshStatistic: () => void,
}

const ProjectStatisticReactContext = React.createContext<ProjectStatisticContextData | undefined>(undefined);

export function useProjectStatisticContextRequired() {
  const contextData = useContext(ProjectStatisticReactContext);

  if (!contextData) {
    throw new Error('ProjectStatisticContextData is not provided');
  }

  return contextData;
}

export function ProjectStatisticContext(
  props: {
    children: ReactElement | ReactElement[],
    noProjectsView?: ReactElement,
  },
) {
  const filterContext = useFilterContext<ProjectFilter>();

  const [statistic, setStatistic] = useState<ProjectsStatistic | undefined>(undefined);

  const projectStatisticService = useProjectStatisticService();

  const [version, setVersion] = useState(0);

  const refreshStatistic = () => {
    setVersion(version + 1);
  }

  const {
    loading,
  } = useDataLoader(
    async () => {
      return projectStatisticService.getStatistic(filterContext.filterValue!);
    },
    setStatistic,
    JSON.stringify(filterContext.filterValue),
    version,
  );

  const contextValue = useMemo<ProjectStatisticContextData>(() => ({
    isLoading: loading,
    statistic,
    refreshStatistic,
  }), [loading, JSON.stringify(statistic)]);

  if (statistic) {
    const filterValue = filterContext.filterValue!;

    const isFilterApplied = filterValue.nameContains
      || !filterValue.types.selectAll
      || filterValue.withPastDueOnly
      || !filterValue.assignedTo.selectAll;

    const totalNumberOfProperties = statistic.totalByStatus.active
      + statistic.totalByStatus.closed
      + statistic.totalByStatus.archived;

    if (!props.noProjectsView || isFilterApplied || (totalNumberOfProperties > 0)) {
      return (
        <ProjectStatisticReactContext.Provider value={contextValue}>
          {props.children}
        </ProjectStatisticReactContext.Provider>
      );
    } else {
      return props.noProjectsView;
    }
  } else {
    return (<LoadingAnimation />);
  }
}
