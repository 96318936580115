import React from 'react';
import { Route } from 'react-router-dom';
import { ApplicationModule } from '../shared/applicationModule';
import PeopleListPage from './List/PeopleListPage';
import PersonUpdatePage from './Update/PersonUpdatePage';
import PersonHistoryPage from './History/PersonHistoryPage';

export default function usePeopleModule(): ApplicationModule {
  const getRouter = () => {
    return (
      <>
        <Route path="/people" element={<PeopleListPage />} />
        <Route
          path="/people/:id"
          element={<PersonUpdatePage />}
        />
        <Route path="/people/:id/history" element={<PersonHistoryPage />} />
      </>
    );
  };

  return {
    getRouter,
    name: 'people',
  };
}
