import React from 'react';
import { get } from 'lodash';
import { useShowContext } from 'ui-builder';
import {
  RoleAssignment,
} from 'nekst-api';

export interface Props {
  source: string;
}

export default function RolesNamesField(props: Props) {
  const { data } = useShowContext();

  const roles = get(data, props.source) as RoleAssignment[];

  const rolesNames = roles.map((r) => r.roleName).join(', ');

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {rolesNames}
    </>
  );
}
