import React from 'react';
import { AfterSubmitFunc } from 'ui-builder';
import { useAiTrainingEntryContext } from '../ViewParseResultWidget';
import BasePersonParseResultForm from './BasePersonParseResultForm';
import { PersonParseResult } from 'nekst-api';

export default function UpdatePersonParseResultForm(
  props: {
    data: PersonParseResult,
    afterSubmitFunc?: AfterSubmitFunc
  }
) {
  const entryContext = useAiTrainingEntryContext();

  return (
    <BasePersonParseResultForm
      data={props.data}
      submitFormFunc={async (data) => {
        entryContext.updateContact(props.data.firstName, props.data.lastName, data);

        return data;
      }}
      title="Update Person"
      afterSubmitFunc={props.afterSubmitFunc}
    />
  );
}
