import React, { useMemo } from 'react';
import { HistoryEntry, useDateHelper } from 'nekst-api';
import HistoryDayGroup from './HistoryDayGroup';
import { useListContext } from 'ui-builder';

export default function ByDateGrouper(
  props: {
    noDataText?: string,
    noFilteredDataText?: string
  },
) {
  const listContext = useListContext<HistoryEntry>();

  const dateHelper = useDateHelper();

  const groups = useMemo<Record<string, HistoryEntry[]>>(() => {
    return listContext.data!.reduce((map: Record<string, HistoryEntry[]>, val: HistoryEntry) => {
      const dateString = dateHelper.toString(val.when);

      if (!(dateString in map)) {
        // eslint-disable-next-line no-param-reassign
        map[dateString] = [];
      }

      map[dateString].push(val);

      return map;
    }, {});
  }, [listContext.version]);

  if (Object.keys(groups).length > 0) {
    return (
      <div>
        {Object.entries(groups)
          .map(
            ([key, entries]) => (
              <HistoryDayGroup date={key} entries={entries} key={`events-${key}`} />
            ),
          )}
      </div>
    );
  } else {

    const isFilterApplied = Object.entries(listContext.filterValues || {})
      .filter(([, value]) => !!value)
      .length > 0;
    if (!isFilterApplied) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{props.noDataText}</>;
    } else {
      return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {props.noFilteredDataText || 'There are no records matching the current filter criteria.'}
        </>
      );
    }
  }
}
