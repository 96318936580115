import React from 'react';
import { MultiSelectDropdown } from './MultiSelectDropdown';
import { LoadingAnimation } from '../../Common';
import { BaseInputProps, Option } from './types';
import { useState } from 'react';
import useDataLoader from 'data-loader';

export interface AsyncMultiDropdownProps extends BaseInputProps<string[] | number[]> {
  loadOptionsFunc: () => Promise<Option[]>;
  notEditableIds?: string[] | number[];
}

export function AsyncMultiSelectDropdown(props: AsyncMultiDropdownProps) {
  const [options, setOptions] = useState<Option[]>();

  useDataLoader(
    props.loadOptionsFunc,
    setOptions,
  );

  if (options) {
    return (
      <MultiSelectDropdown
        options={options}
        {...props}
        placeholder={props.placeholder}
        notEditableIds={props.notEditableIds}
      />
    );
  } else {
    return (<LoadingAnimation />);
  }
}
