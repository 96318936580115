import React, { ReactNode } from 'react';
import { useNewUiTheme } from '../newUiTheme';
import { TextStyle } from 'react-native';

export interface SimpleTextLayoutProps {
  children: ReactNode
  className?: string,
  style?: TextStyle,
  italic?: boolean,
  bold?: boolean,
}

export interface TextProps {
  children: ReactNode,
  className?: string
  style?: TextStyle,
  italic?: boolean,
  bold?: boolean,
}

export function Text(
  props: TextProps,
) {
  const uiTheme = useNewUiTheme();
  return (
    <uiTheme.common.TextLayout
      className={props.className}
      style={props.style}
      italic={props.italic}
      bold={props.bold}
    >
      {props.children}
    </uiTheme.common.TextLayout>
  );
}
