import React, { ReactElement } from 'react';
import { IconButton, IconType } from 'features/nekst-widgets';
import { SimpleBlock } from 'ui-builder';

import styles from './OnboardingBlockWrapper.module.scss';
import { TourStatus, useOnboardingService } from 'nekst-api';

export default function OnboardingWidgetWrapper(
  props: { tourId: string, children: ReactElement }
) {

  const onboardingService = useOnboardingService();

  const [done, setDone] = React.useState(false);

  if (!done && onboardingService.getTourStatus(props.tourId) !== TourStatus.PASSED) {
    return (
      <SimpleBlock className={styles.block}>
        {props.children}
        <IconButton
          onClick={async () => {
            setDone(true);
            await onboardingService.setTourStatus(props.tourId, TourStatus.PASSED);
          }}
          type={IconType.X}
          className={styles.hideButton}
        />
      </SimpleBlock>
    );
  } else {
    return null;
  }
}
