import React from 'react';
import { Outlet } from 'react-router-dom';
import MainBlock, { ColumnPosition } from '../shared/web/layout/MainBlock';
import SettingsMenu from './SettingsMenu';

export default function SettingsPagesWrapper(
  props: {
    hideSettingsMenu?: boolean,
    title: string
  }
) {
  return (
    <MainBlock
      title={props.title}
      columnPosition={ColumnPosition.LEFT}
      columnContent={!props.hideSettingsMenu ? <SettingsMenu /> : undefined}
    >
      <Outlet />
    </MainBlock>
  );
}
