import React from 'react';
import { Person, getTeamRoleName } from 'nekst-api';

export default function TeamRoleNameWidget  (
  props: { person: Person },
) {
  let label;
  label = getTeamRoleName(props.person.teamRole);

  if (props.person._serviceProviders.length > 0) {
    label = 'Service Provider';
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{label}</>
  );
}
