import React from 'react';
import { ProjectType, TaskType, useSmartTagsService } from 'nekst-api';
import SmartTagsContext from './SmartTagsContext';

export default function SmsSmartTagsContext(
  props: {
    children: React.ReactElement,
    projectType?: ProjectType,
    projectId?: number,
  },
) {
  const smartTagsService = useSmartTagsService();

  return (
    <SmartTagsContext
      projectType={props.projectType}
      getTagsFunc={
        (projectType) => smartTagsService.getSmartTags(projectType, TaskType.SMS, props.projectId)
      }
    >
      {props.children}
    </SmartTagsContext>
  );
}
