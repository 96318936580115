import React from 'react';
import useAiTrainerService, {
  AI_TRAINER_ENTRIES_UPDATED,
  AiTrainingEntry
} from '../aiTrainerService';
import {
  DateTimeField,
  List, ListDataRefresher,
  MassUpdate,
  TextField,
  usePopupManager,
  useShowContext, useShowContextRequired
} from 'ui-builder';
import GridLayout from '../../../../shared/uibuilder/list/GridLayout';
import { ConfirmDeletePopup, IconButton, IconType } from 'features/nekst-widgets';
import useAiTrainerNavigator from '../aiTrainerNavigator';
import Cell from '../../../../shared/uibuilder/list/Cell';
import { AccessChecker } from 'authorization-scope';
import { TeamPermission } from 'nekst-api';

function UpdateEntryButton() {
  const navigator = useAiTrainerNavigator();

  const showContext = useShowContext();

  return (
    <IconButton
      onClick={() => {
        navigator.openUpdatePage(showContext.data!.id);
      }}
      type={IconType.PENCIL}
    />
  );
}

function DeleteEntryButton() {
  const aiTrainerService = useAiTrainerService();

  const popupManager = usePopupManager();

  const showContext = useShowContextRequired<AiTrainingEntry>();

  return (
    <IconButton
      onClick={() => {
        popupManager.openPopup(
          <ConfirmDeletePopup
            deleteFunc={async () => {
              await aiTrainerService.deleteEntry(
                showContext.data!.id
              );
            }}
            message={`Are you sure you want to delete the AI training entry with id ${showContext.data!.id}?`}
          />
        );
      }}
      type={IconType.X}
    />
  );
}

export function AiTrainingEntriesList() {
  const aiTrainerService = useAiTrainerService();

  return (
    <AccessChecker permissionToCheck={TeamPermission.MANAGE_AI_TRAINER}>
      <MassUpdate enabled>
        <List getDataFunc={aiTrainerService.getEntries}>
          <GridLayout noDataMessage="No AI training entries created yet.">
            <TextField source="id" label="Id" />
            <TextField source="usedFor" label="Used For" />
            <TextField source="fileName" label="File Name" />
            <TextField source="comment" label="Comment" />
            <DateTimeField source="createdAt" label="Created At" />
            <Cell label="Actions">
              <UpdateEntryButton />
              <DeleteEntryButton />
            </Cell>
          </GridLayout>
          <ListDataRefresher eventName={AI_TRAINER_ENTRIES_UPDATED} />
        </List>
      </MassUpdate>
    </AccessChecker>


  );
}
