import React, { useContext } from 'react';

export const AuthorizationScopeContext = React.createContext<AuthorizationScopeData>({
  isLoaded: true,
  permissions: [],
});

export type AuthorizationScopeData = {
  permissions: Permission[],
  isLoaded: boolean,
};

export function useAuthorizationScopeContext() {
  return useContext<AuthorizationScopeData>(AuthorizationScopeContext);
}

export type Permission = string;
