import {
  AbstractTask,
  StandardTask,
  EmailTask,
  SmsTask,
  TasksFilter,
  Limit,
  PageResponse,
} from './Types';
import { EventData, useEventsPublisher } from 'event-bus';
import { ValidationSchema } from 'validation-schema';
import { useBackendApi } from './backendApi';
import { useTasksFilterHelper, useTasksResponseMapper } from './Helper';

export interface ProjectTasksService {
  getAllTasks: (projectId: number, filter: TasksFilter, limit: Limit) => Promise<
    PageResponse<AbstractTask>
  >;
  createStandardTask: (projectId: number, task: StandardTask) => Promise<StandardTask>;
  createEmailTask: (projectId: number, task: EmailTask) => Promise<EmailTask>;
  createSmsTask: (projectId: number, task: SmsTask) => Promise<SmsTask>;
  getTask: (taskId: number) => Promise<AbstractTask>;
  updateTask: (id: number, task: AbstractTask) => Promise<AbstractTask>;
  deleteTask: (id: number) => Promise<void>;
  updateDescription: (id: number, description?: string) => Promise<AbstractTask>,
  getStandardTaskValidationSchema: () => ValidationSchema,
  getEmailTaskValidationSchema: () => ValidationSchema,
  getSmsTaskValidationSchema: () => ValidationSchema,
}

export interface TaskEvent extends EventData {
  id: number;
  data: AbstractTask;
}

export interface TaskDeletedEvent {
  id: number;
}

export const TASK_CREATED = 'TASK_CREATED';

export const TASK_UPDATED = 'TASK_UPDATED';

export const TASK_DELETED = 'TASK_DELETED';
export const TASK_COMMENTS_UPDATED = 'TASK_COMMENTS_UPDATED';

export function useProjectTasksService(): ProjectTasksService {
  const projectsApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const filterHelper = useTasksFilterHelper();

  const {
    mapSingle,
    mapList,
  } = useTasksResponseMapper();

  const getAllTasks = async (projectId: number, filter: TasksFilter, limit: Limit) => {
    const filterString = filterHelper.buildQueryString(filter);

    const result = await projectsApi.get(
      `/projects/${projectId}/tasks?limit=${limit.limit}&offset=${limit.offset}&${filterString}`,
    );

    return {
      limit: result.limit,
      data: mapList(result.data),
      total: result.total,
    };
  };

  function onTaskCreated(task: AbstractTask) {
    eventsPublisher.publish<TaskEvent>(TASK_CREATED, {
      id: task.id,
      data: task,
    });
  }

  function onTaskUpdated(task: AbstractTask) {
    eventsPublisher.publish(TASK_UPDATED, {
      id: task.id,
      data: task,
    });
  }

  function onTaskDeleted(id: number) {
    eventsPublisher.publish<TaskDeletedEvent>(TASK_DELETED, {
      id,
    });
  }

  const createStandardTask = async (projectId: number, data: StandardTask) => {
    const result = await projectsApi.post(`/projects/${projectId}/tasks/standard`, data);

    const task = mapSingle(result) as StandardTask;
    onTaskCreated(task);

    return task;
  };

  const createEmailTask = async (projectId: number, data: EmailTask) => {
    const result = await projectsApi.post(`/projects/${projectId}/tasks/email`, data);

    const task = mapSingle(result) as EmailTask;
    onTaskCreated(task);

    return task;
  };

  const createSmsTask = async (projectId: number, data: SmsTask) => {
    const result = await projectsApi.post(`/projects/${projectId}/tasks/sms`, data);

    const task = mapSingle(result) as SmsTask;

    onTaskCreated(task);
    return task;
  };

  const updateTask = async (id: number, data: AbstractTask) => {
    const result = await projectsApi.put(`/tasks/${id}`, data);

    const task = mapSingle(result);
    onTaskUpdated(task);
    return task;
  };

  const updateDescription = async (id: number, message?: string) => {
    const result = await projectsApi.put(`/tasks/${id}/description`, {
      message,
    });

    onTaskUpdated(mapSingle(result));

    return result;
  };

  const getTask = async (id: number) => {
    const result = await projectsApi.get(`/tasks/${id}`);

    return mapSingle(result);
  };

  const deleteTask = async (id: number) => {
    await projectsApi.delete(`/tasks/${id}`);

    onTaskDeleted(id);
  };

  const baseValidationSchema = {
    name: {
      type: 'string',
      constraints: {
        required: true,
      },
    },
    assignments: {
      type: 'array',
      constraints: {
        required: true,
      },
    },
    parentTaskRelation: {
      type: 'object',
      properties: {
        days: {
          type: 'int',
          constraints: {
            required: true,
          },
        },
        parentTaskId: {
          type: 'int',
          constraints: {
            required: true,
          },
        },
      },
      constraints: {},
    },
  } as ValidationSchema;

  const getStandardTaskValidationSchema = () => {
    return baseValidationSchema;
  };

  const getEmailTaskValidationSchema = () => {
    return {
      ...baseValidationSchema,
      recipients: {
        type: 'array',
        constraints: {
          filteredRequired: {
            value: true,
            message: 'At least one email recipient must be selected',
            filter: (item: any) => item.type === 'RECIPIENT',
          },
        },
      },
    } as ValidationSchema;
  };

  const getSmsTaskValidationSchema = () => {
    return {
      ...baseValidationSchema,
      recipients: {
        type: 'array',
        constraints: {
          required: {
            value: true,
            message: 'At least one SMS recipient must be selected',
          },
        },
      },
      description: {
        type: 'html',
        constraints: {
          required: true,
        },
      },
    } as ValidationSchema;
  };

  return {
    getAllTasks,
    createStandardTask,
    createEmailTask,
    createSmsTask,
    getTask,
    updateTask,
    deleteTask,
    updateDescription,
    getStandardTaskValidationSchema,
    getEmailTaskValidationSchema,
    getSmsTaskValidationSchema,
  };
}
