import React, { ReactElement } from 'react';
import RecordLayout, { RecordLayoutProps } from './RecordLayout';
import TaskLink from './TaskLink';
import PersonLink from './PersonLink';
import { HistoryEventIconType } from './HistoryEventIcon';
import { BaseHistoryEntryData, PersonData, TaskData } from 'nekst-api';

interface EmailSentData extends BaseHistoryEntryData {
  task: TaskData,
  subject: string,
  recipients: {
    person: PersonData
  }[];
}

export default function SmsSentAsEmailLayout(
  props: RecordLayoutProps<EmailSentData>,
) {
  const recipients: ReactElement[] = props.record.data.recipients
    .filter((v) => !!v.person)
    .map((v) => {
      return (<PersonLink key={`recipient-${v.person.id}`} person={v.person} />);
    });

  const splitWithCommas = (value: ReactElement[]) => {
    const result: ReactElement[] = [];

    value.forEach((recipient, index) => {
      result.push(recipient);
      if (index !== recipients.length - 1) {
        result.push(<React.Fragment key={`${recipient.key}-separator`}>, </React.Fragment>);
      }
    });

    return result;
  };

  return (
    <RecordLayout
      text={(
        <>
          {'The task '}
          <TaskLink
            task={props.record.data.task}
            project={props.record.data.project}
          />
          {' was sent as email titled '}
          <b>{props.record.data.subject}</b>
          {recipients.length > 0 && (
            <>
              {' to the recipient(s) '}
              {splitWithCommas(recipients)}
            </>
          )}
          .
        </>
      )}
      date={props.record.when}
      iconType={HistoryEventIconType.EMAIL_SENT}
    />
  );
}
