import React, { ReactNode } from 'react';

import styles from './TabSelector.module.scss';

export type TabOption<ValueType> = {
  value: ValueType,
  label: ReactNode,
}|'separator';

export default function TabSelector<ValueType = number>(
  props: {
    dataKey?: string,
    activeOption: ValueType | undefined,
    onSelected: (value: ValueType) => void,
    options: TabOption<ValueType>[],
    withoutMargin?: boolean,
    loading?: boolean,
  },
) {
  return (
    <div
      data-key={props.dataKey}
      className={[
        styles.container,
        props.withoutMargin ? styles.withoutMargin : '',
        props.loading ? styles.loading : '',
      ].join(' ')}
    >
      {props.options.map((option, index) => {
        if (option === 'separator') {
          return (<span className={styles.separator} key={`tab-${index}`}>|</span>)
        } else {
          const classes = [
            styles.tab,
            props.activeOption === option.value ? styles.active : '',
          ];

          return (
            <span
              className={classes.join(' ')}
              onClick={() => props.onSelected(option.value) }
              key={`tab-${index}`}
            >
              {option.label}
            </span>
          )
        }
      })}
    </div>
  );
}
