import React, { ReactElement } from 'react';

import { useMassUpdateServiceRequired } from 'ui-builder';
import SelectAllCheckbox from './SelectAllCheckbox';
import styles from './MassUpdateControlsLayout.module.scss';

export interface MassUpdateControlsLayoutProps {
  children: ListOrSingle<ReactElement | false>;
}

function Separator() {
  return (
    <div className={styles.separator}>
      &nbsp;
    </div>
  );
}

export default function MassUpdateControlsLayout(props: MassUpdateControlsLayoutProps) {
  const massUpdateService = useMassUpdateServiceRequired();

  if (massUpdateService.isEnabled()) {
    return (
      <div className={styles.block}>
        <SelectAllCheckbox />
        <Separator />
        <div className={styles.buttons}>
          {props.children}
        </div>
      </div>
    );
  } else {
    return null;
  }
}
