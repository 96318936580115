import React, { ReactElement } from 'react';
import { useListContext, LoadingAnimation, Button, ButtonColor } from 'ui-builder';

import styles from './GridLayout.module.scss';

export default function PaginationWrapper(
  props: {
    children: ReactElement | ReactElement[]
  },
) {
  const listContext = useListContext();

  return (
    <>
      {props.children}
      {listContext.hasMorePages!() ? (
        <div className={styles.loadMoreButtonWrapper}>
          {listContext.isLoading ? (<LoadingAnimation isBig={false} />) : (
            <Button
              customClass={styles.loadMoreButton}
              color={ButtonColor.BLUE}
              text="Show More"
              onClick={() => listContext.loadNextPage!()}
              isDisabled={listContext.isLoading}
            />
          )}
        </div>
      ) : null}
    </>
  );
}
