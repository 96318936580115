import React, {
  useEffect,
  useRef,
} from 'react';
import 'quill-mention';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-mention/dist/quill.mention.min.css';
import './QuillWysiwygLayout.scss';
import { WysiwygLayoutProps } from 'ui-builder';
import useUiTheme from '../../../uiTheme';

interface QuillWysiwygLayoutProps extends WysiwygLayoutProps {
  modules?: Record<string, any>,
}

function BaseQuillLayout(props: QuillWysiwygLayoutProps) {
  const ref = useRef<ReactQuill>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.editor?.enable(!props.isDisabled);
    }
  }, [ref, props.isDisabled]);

  const modules = {
    toolbar: !props.hideControls ? [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['image'],
    ] : false,
    ...props.modules || {},
  };

  return (
    <ReactQuill
      ref={ref}
      theme="snow"
      modules={modules}
      className={props.isDisabled ? 'disabled' : ''}
      value={props.value || ''}
      onChange={(e) => {
        props.onChangeCallback({
          target: {
            value: e,
          },
        });
      }}
    />
  );
}

export default function QuillWysiwygLayout(props: QuillWysiwygLayoutProps) {
  const uiTheme = useUiTheme();
  return (
    <uiTheme.forms.BaseInputLayout
      label={props.label}
      name={props.name}
      errors={props.errors}
      showRequiredIcon={props.showRequiredIcon}
    >
      <BaseQuillLayout
        {...props}
      />
    </uiTheme.forms.BaseInputLayout>
  );
}
