import React from 'react';
import { BaseHistoryEntryData, ProjectType } from 'nekst-api';
import RecordLayout, { RecordLayoutProps } from './RecordLayout';
import ProjectLink from './ProjectLink';
import PersonLink from './PersonLink';
import { HistoryEventIconType } from './HistoryEventIcon';
import { useProjectTypeHelper } from 'features/tasks-forms';

interface ProjectTypeUpdatedData extends BaseHistoryEntryData {
  oldType: ProjectType,
  newType: ProjectType,
}

export default function ProjectTypeUpdatedRecordLayout(
  props: RecordLayoutProps<ProjectTypeUpdatedData>,
) {
  const newType = props.record.data.newType;

  const projectTypeHelper = useProjectTypeHelper();

  return (
    <RecordLayout
      text={(
        <>
          <ProjectLink project={props.record.data.project} />
          {` has been changed to ${projectTypeHelper.getLabel(newType)}`}
          {' by '}
          <PersonLink person={props.record.data.person!} />
          .
        </>
      )}
      date={props.record.when}
      iconType={HistoryEventIconType.STATUS_UPDATED}
    />
  );
}
