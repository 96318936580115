import React from 'react';
import { usePopupFormManager } from 'features/nekst-widgets';
import {
  AfterSubmitFunc,
  ButtonColor,
  ButtonSize,
  FormTemplate,
  IconType,
  TextInput,
  Button,
} from 'ui-builder';
import { CreateProjectRoleRequest, ProjectRole, useProjectRolesService } from 'nekst-api';

function CreateProjectRoleForm(
  props: {
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {
  const projectRolesService = useProjectRolesService();

  return (
    <FormTemplate<CreateProjectRoleRequest, ProjectRole>
      submitFormFunc={async (request: CreateProjectRoleRequest) => {
        return projectRolesService.createRole(request);
      }}
      title="Create Role"
      afterSubmitFunc={props.afterSubmitFunc}
      validationSchema={{
        name: {
          type: 'string',
          constraints: {
            required: true,
            minLength: 3,
            maxLength: 100,
          },
        },
      }}
    >
      <TextInput source="name" />
    </FormTemplate>
  );
}

export default function CreateProjectRoleButton() {
  const popupManager = usePopupFormManager();

  const onClick = () => {
    popupManager.openForm(<CreateProjectRoleForm />);
  };

  return (
    <Button
      text="Create role"
      color={ButtonColor.BLUE}
      size={ButtonSize.MEDIUM}
      isFilled
      onClick={onClick}
      iconType={IconType.PLUS}
    />
  );
}
