import { useBackendApi } from './backendApi';
import { Limit, PageResponse, ProjectStatus, ProjectType, ShortProfile } from './Types';
import { useEventsPublisher } from 'event-bus';


export enum NotificationType {
  KEY_DATE_REMINDER = 'KEY_DATE_REMINDER',
  TASK_COMMENT_CREATED = 'TASK_COMMENT_CREATED',
  PROJECT_NOTE_CREATED = 'PROJECT_NOTE_CREATED',
  PROJECT_ASSIGNMENT_CREATED = 'PROJECT_ASSIGNMENT_CREATED',
  PROJECT_TYPE_UPDATED = 'PROJECT_TYPE_UPDATED',
  PROJECT_STATUS_UPDATED = 'PROJECT_STATUS_UPDATED',
  TASK_DUE_DATE_REMINDER = 'TASK_DUE_DATE_REMINDER',
  KEY_DATE_COMPLETED = 'KEY_DATE_COMPLETED',
  MENTIONED_IN_COMMENT = 'MENTIONED_IN_COMMENT',
  MENTIONED_IN_NOTE = 'MENTIONED_IN_NOTE',
}

export function useNotificationTypeHelper() {
  const getLabel = (type: NotificationType) => {
    switch (type) {
      case NotificationType.KEY_DATE_REMINDER:
        return 'Reminder for Key Date Due';
      case NotificationType.TASK_COMMENT_CREATED:
        return 'When a Comment is Added to Your Assigned Task';
      case NotificationType.PROJECT_NOTE_CREATED:
        return 'When a Note is Added to a Transaction';
      case NotificationType.PROJECT_ASSIGNMENT_CREATED:
        return 'When You Are Assigned to a Transaction';
      case NotificationType.PROJECT_TYPE_UPDATED:
        return 'When Transaction Type is Updated (Listing, Pending Buyer, etc)';
      case NotificationType.PROJECT_STATUS_UPDATED:
        return 'When Transaction Status is Updated (Active, Closed, Archived)';
      case NotificationType.TASK_DUE_DATE_REMINDER:
        return 'Reminder for Task Due Date';
      case NotificationType.KEY_DATE_COMPLETED:
        return 'When Key Date is Completed on Your Transaction';
      case NotificationType.MENTIONED_IN_COMMENT:
        return 'When You are Mentioned in a Comment';
      case NotificationType.MENTIONED_IN_NOTE:
        return 'When You are Mentioned in a Note';
    }
  };

  return {
    getLabel,
  };
}

type TaskDto = {
  id: number,
  name: string,
  isKeyDate: boolean,
}

type ProjectDto = {
  id: number,
  name: string,
}

export interface BaseNotification {
  id: number,
  initiator?: ShortProfile,
  createdAt: Date,
  isRead: boolean,
}

export interface KeyDateReminderNotification extends BaseNotification {
  type: NotificationType.KEY_DATE_REMINDER,
  payload: {
    task: TaskDto,
    project: ProjectDto,
  }
}

export interface TaskCommentCreatedNotification extends BaseNotification {
  type: NotificationType.TASK_COMMENT_CREATED,
  payload: {
    task: TaskDto,
    project: ProjectDto,
    comment: {
      id: number,
      text: string
    }
  }
}

export interface ProjectNoteCreatedNotification extends BaseNotification {
  type: NotificationType.PROJECT_NOTE_CREATED,
  payload: {
    project: ProjectDto,
    note: {
      id: number,
      text: string
    }
  }
}

export interface ProjectAssignmentCreatedNotification extends BaseNotification {
  type: NotificationType.PROJECT_ASSIGNMENT_CREATED,
  payload: {
    project: ProjectDto,
  }
}

export interface ProjectTypeUpdatedNotification extends BaseNotification {
  type: NotificationType.PROJECT_TYPE_UPDATED,
  payload: {
    project: ProjectDto,
    oldType: ProjectType,
    newType: ProjectType,
  }
}

export interface ProjectStatusUpdatedNotification extends BaseNotification {
  type: NotificationType.PROJECT_STATUS_UPDATED,
  payload: {
    project: ProjectDto,
    oldStatus: ProjectStatus,
    newStatus: ProjectStatus,
  }
}

export interface TaskDueDateReminderNotification extends BaseNotification {
  type: NotificationType.TASK_DUE_DATE_REMINDER,
  payload: {
    task: TaskDto,
    project: ProjectDto,
    remindDays: number
  }
}

export interface KeyDateCompletedNotification extends BaseNotification {
  type: NotificationType.KEY_DATE_COMPLETED,
  payload: {
    task: TaskDto,
    project: ProjectDto,
  }
}

export interface MentionedInCommentNotification extends BaseNotification {
  type: NotificationType.MENTIONED_IN_COMMENT,
  payload: {
    task: TaskDto,
    project: ProjectDto,
    comment: {
      id: number,
      text: string
    }
  }
}

export interface MentionedInNoteNotification extends BaseNotification {
  type: NotificationType.MENTIONED_IN_NOTE,
  payload: {
    task: TaskDto,
    project: ProjectDto,
    note: {
      id: number,
      text: string
    }
  }
}

export type Notification = KeyDateReminderNotification
  | TaskCommentCreatedNotification
  | ProjectNoteCreatedNotification
  | ProjectAssignmentCreatedNotification
  | ProjectTypeUpdatedNotification
  | ProjectStatusUpdatedNotification
  | TaskDueDateReminderNotification
  | KeyDateCompletedNotification
  | MentionedInCommentNotification
  | MentionedInNoteNotification;

export const NOTIFICATIONS_READ_EVENT = 'NOTIFICATIONS_READ_EVENT';

export function useNotificationsService() {
  const backendApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const getNotifications = async (limit: Limit) => {
    return await backendApi.get(`/notifications?limit=${limit.limit}&offset=${limit.offset}`) as PageResponse<Notification>;
  };


  const trackRead = async () => {
    await backendApi.put('/notifications/lastread');

    eventsPublisher.publish(NOTIFICATIONS_READ_EVENT, {});
  }

  const getNumberOfUnread = async () => {
    const result = await backendApi.get('/notifications/unread') as { count: number };
    return result.count;
  }

  return {
    getNotifications,
    trackRead,
    getNumberOfUnread,
  };
}
