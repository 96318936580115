import React from 'react';
import MainMenu from './MainMenu';
import Logo from './Logo';
import UserInformation from './UserInformation';
import useUserSessionStorage from '../../../login/userSessionStorage';
import NotificationsBell from '../../../notifications/NotificationsBell';

export default function Header() {
  const userSessionStorage = useUserSessionStorage();
  return (
    <>
      <Logo />
      {userSessionStorage.hasToken() && (
        <>
          <UserInformation />
          <NotificationsBell />
          <MainMenu />
        </>
      )}
    </>
  );
}
