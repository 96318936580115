import React from 'react';
import UpdateProfileForm from '../../people/me/UpdateProfile/UpdateProfileForm';
import Row8x4x from '../../shared/web/uitheme/form/Row8x4x';
import UpdateProfileAvatarForm from './UpdateProfileAvatarForm';
import { SettingsBreadcrumbs } from '../settingsModule';
import { Breadcrumb } from '../../shared/web/layout/Breadcrumbs';

export default function UpdateProfilePage() {
  return (
    <>
      <SettingsBreadcrumbs>
        <Breadcrumb text="Profile" />
      </SettingsBreadcrumbs>
      <Row8x4x className="no-clear">
        <UpdateProfileForm />
        <UpdateProfileAvatarForm />
      </Row8x4x>
    </>
  );
}
