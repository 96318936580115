import React from 'react';
import { useProjectsService } from 'nekst-api';
import { AsyncDropdown } from 'ui-builder';

interface Props {
  className?: string;
  source: string;
}

export default function ProjectDropdown(
  props: Props,
) {
  const projectService = useProjectsService();
  return (
    <AsyncDropdown
      getDataFunc={projectService.getProjectNames}
      label="Transaction"
      {...props}
    />
  );
}

ProjectDropdown.prototype.defaultProps = {
  className: undefined,
};
