import React from 'react';
import useDocumentLabelsService, { CreateDocumentLabelRequest } from './documentLabelsService';
import FormTemplate, { AfterSubmitFunc } from '../../../shared/uibuilder/form/FormTemplate';
import Text from '../../../shared/uibuilder/form/input/Text';
import FileUploaderInput from '../../../shared/file/FileUploaderInput';
import { usePopupFormManager } from 'features/nekst-widgets';
import { Button, ButtonColor, ButtonSize, IconType } from 'ui-builder';
import { FileType } from 'nekst-api';

export default function CreateDocumentLabelForm(
  props: {
    afterSubmitFunc?: AfterSubmitFunc
  },
) {
  const service = useDocumentLabelsService();
  return (
    <FormTemplate<CreateDocumentLabelRequest>
      title="Create Label"
      submitFormFunc={(data) => service.createLabel(data)}
      afterSubmitFunc={props.afterSubmitFunc}
      validationSchema={{
        name: {
          type: 'string',
          constraints: {
            required: true,
          },
        },
      }}
    >
      <Text source="name" label="Name" />
      <FileUploaderInput
        source="fileId"
        label="Attach Universal Document to this Label"
        fileDataSource="_file"
        fileType={FileType.PROJECT_DOCUMENT}
      />
    </FormTemplate>
  );
}

export function CreateDocumentLabelButton() {
  const popupManager = usePopupFormManager();
  return (
    <Button
      text="Create Label"
      size={ButtonSize.MEDIUM}
      iconType={IconType.PLUS}
      color={ButtonColor.BLUE}
      onClick={() => popupManager.openForm(<CreateDocumentLabelForm />)}
      isFilled
    />
  );
}
