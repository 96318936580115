import React from 'react';
import { SimpleTextLayoutProps } from 'ui-builder';

export default function TextLayout(
  props: SimpleTextLayoutProps,
) {
  return (
    <span
      className={props.className}
      style={{
        ...props.bold ? { fontWeight: 'bold' } : {},
        ...props.italic ? { fontStyle: 'italic' } : {},
      }}
    >{props.children}</span>
  );
}
