import React from 'react';
import { DateTimeFieldLayoutProps } from 'ui-builder';

export default function DateTimeFieldLayout(props: DateTimeFieldLayoutProps) {
  if (props.displayValue) {
    const showTitle = props.displayValue !== props.value;
    return (
      <span className={props.className} title={showTitle ? props.value : undefined}>
        {props.displayValue}
      </span>
    );
  } else {
    return (
      <span className={props.className}>
        -
      </span>
    )
  }
}
