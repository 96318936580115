import React, { useMemo } from 'react';
import { ProjectType, } from 'nekst-api';
import TabSelector, { TabOption } from '../../../shared/widgets/TabSelector/TabSelector';
import { useDetailsFieldsSetsContextRequired } from './DetailsFieldsSetsContext';
import useSettingsNavigator from '../../settingsNavigator';
import { DetailsFieldsSetsToggleWrapper, ToggledFeature, withToggleWrapper } from 'feature-toggle';

export function DetailsFieldsTabs(
  props: {
    projectType: ProjectType,
    currentSetId: number,
  }
) {
  const { sets, loading } = useDetailsFieldsSetsContextRequired();

  const options: TabOption<number>[] = [
    {
      value: 0,
      label: 'Manage All Boxes',
    }
  ];

  const currentProjectTypeSets = useMemo(() => {
    if (sets) {
      return sets
        .filter((set) => set.projectType === props.projectType);
    } else {
      return [];
    }
  }, [props.projectType, sets]);

  if (currentProjectTypeSets.length) {
    options.push(
      'separator',
      ...currentProjectTypeSets.map((set) => ({
        label: set.name,
        value: set.id,
      })),
    );
  }


  const settingsNavigator = useSettingsNavigator();

  return (
    <DetailsFieldsSetsToggleWrapper>
      <TabSelector<number>
        options={options}
        onSelected={(setId: number) => {
          if (setId) {
            settingsNavigator.openDetailsSetPage(props.projectType, setId)
          } else {
            settingsNavigator.openProjectDetailsFieldPage(props.projectType);
          }
        }}
        activeOption={props.currentSetId}
        loading={loading}
      />
    </DetailsFieldsSetsToggleWrapper>

  );
}

export default withToggleWrapper(DetailsFieldsTabs, ToggledFeature.DETAILS_FIELD_SETS);
