import React, { useEffect, useState } from 'react';
import useInputHelper from '../../../../shared/uibuilder/form/input/inputHelper';
import TransactionPartiesInputLayout
  from '../../../../shared/widgets/TransactionPartiesInputLayout';
import { useProjectRolesService } from 'nekst-api';
import { BaseInputProps, FormFieldsData } from 'ui-builder';

type TransactionPartyDto = {
  projectRoleId: number,
  remindDays?: number,
}

export interface TransactionPartiesInputProps extends BaseInputProps<TransactionPartyDto[]> {
}

type TransactionPartyId = number

export default function TransactionPartiesInput(props: TransactionPartiesInputProps) {
  const helper = useInputHelper<TransactionPartyDto[]>(props);

  const onChangeCallback = helper.getOnChangeCallback();

  function getValue() {
    return helper.getValue() || [];
  }

  const projectRolesService = useProjectRolesService();
  const [projectRoles, setProjectRoles] = useState<FormFieldsData[]>();

  const loadRoles = async () => {
    const roles = await projectRolesService.getAllTeamRoles();

    setProjectRoles(roles.filter((role) => role.isAssignable));
  };

  const getManagerRoleId = (): number => {
    return projectRoles?.find((role) => role.isOwnerRole)!.id;
  };

  const assignToManagerByDefault = async () => {
    const numberOfCheckedElements = getValue().length;
    if (projectRoles && numberOfCheckedElements === 0) {
      onChangeCallback({
        target: {
          value: [{
            projectRoleId: getManagerRoleId(),
          }],
        },
      });
    }
  };

  useEffect(() => {
    assignToManagerByDefault();
  }, [projectRoles, JSON.stringify(helper.getValue())]);

  useEffect(() => {
    loadRoles();
  }, []);

  if (projectRoles) {
    return (
      <TransactionPartiesInputLayout<TransactionPartyDto, TransactionPartyId>
        label={props.label}
        findFunc={(id, data) => data.projectRoleId === id}
        toApiModel={(data) => ({
          projectRoleId: data.id,
          remindDays: data.remindDays,
        })}
        value={getValue()}
        choices={projectRoles.map((role) => ({
          id: role.id,
          name: role.name,
          isDefault: false,
        }))}
        onChangeCallback={onChangeCallback}
        source={props.source}
      />
    );
  } else {
    return null;
  }
}
