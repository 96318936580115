import React, { ReactElement } from 'react';
import { Person } from '../peopleService';
import TeamRoleNameWidget from './TeamRoleNameWidget';
import MainBlock from '../../shared/web/layout/MainBlock';
import { useAuthenticationLoader } from '../../authentication/AuthenticationContext';

export default function SinglePersonPage(
  props: {
    headerRight: ReactElement,
    children: ReactElement,
    columnContent?: ReactElement,
    person: Person
  },
) {
  const authenticationLoader = useAuthenticationLoader();

  let fullName = props.person.profile.name.fullName;
  if (props.person.id === authenticationLoader.currentUser.id) {
    fullName += ' (You)';
  }

  return (
    <MainBlock
      title={fullName}
      titleLine2={(
        <TeamRoleNameWidget person={props.person} />
      )}
      headerRight={props.headerRight}
      columnContent={props.columnContent}
    >
      {props.children}
    </MainBlock>
  );
}
