import React from 'react';
import BaseTasksPage from './BaseTasksPage';
import { ProjectPlanTask } from './projectPlanTaskService';
import useLaunchProjectNavigator from './launchProjectNavigator';
import LaunchProjectSteps, { LaunchProjectStepId } from './LaunchProjectSteps';

export default function ManageKeyDateTasksPage() {
  const navigator = useLaunchProjectNavigator();

  return (
    <BaseTasksPage
      filterFunc={(task: ProjectPlanTask) => task.isKeyDate}
      roadmap={<LaunchProjectSteps currentStep={LaunchProjectStepId.KEY_DATES} />}
      onPrevClicked={navigator.openGroupsPage}
      onNextClicked={navigator.openAllTasksPage}
      forceShowKeyDatesOnly
    />
  );
}
