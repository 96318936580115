import React from 'react';
import { usePopupManager, useShowContext } from 'ui-builder';
import { ConfirmDeletePopup, IconButton, IconType } from 'features/nekst-widgets';
import { ServiceProviderGroup, useServiceProvidersGroupsService } from 'nekst-api';
function ConfirmDeleteServiceProviderPopup(
  props: {
    data: ServiceProviderGroup
  },
) {
  const service = useServiceProvidersGroupsService();

  return (
    <ConfirmDeletePopup
      deleteFunc={() => service.remove(props.data.id)}
      message={`Are you sure you want to delete service provider "${props.data.name}"?`}
    />
  );
}

export default function DeleteServiceProviderButton() {
  const popupManager = usePopupManager();

  const showContext = useShowContext<ServiceProviderGroup>();
  return (
    <IconButton
      onClick={() => {
        popupManager.openPopup(
          <ConfirmDeleteServiceProviderPopup data={showContext.data!} />,
        );
      }}
      type={IconType.TRASH_CAN}
      title="Delete Service Provider"
    />
  );
}
