/* istanbul ignore file */

import React from 'react';
import { BaseInputLayoutProps } from '../index';
import ErrorsWrapper from '../input/layout/ErrorsWrapper';

function NoLabelInputLayout(props: BaseInputLayoutProps) {
  return (
    <ErrorsWrapper name={props.name} errors={props.errors}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      {props.children}
    </ErrorsWrapper>
  );
}

export default NoLabelInputLayout;
