import React from 'react';
import { useParams } from 'react-router-dom';
import { List } from 'ui-builder';
import InnerBlock from '../../shared/web/layout/InnerBlock';
import MainBlock from '../../shared/web/layout/MainBlock';
import TextField from '../../shared/uibuilder/show/field/TextField';
import ByDateGrouper from './ByDateGrouper';
import { OpenHistoryFilterButton } from './filter/HistoryFilterForm';
import ProjectHistoryEventWrapper from './ProjectHistoryEventWrapper';
import HistoryEventFullTextFilter from './filter/HistoryEventFullTextFilter';
import Right from '../../shared/web/Right';

import styles from './History.module.scss';
import ProjectPagesButtons from '../view/ProjectPagesButtons';
import { ProjectBreadcrumb } from '../view/ProjectContext';
import { Breadcrumb } from '../../shared/web/layout/Breadcrumbs';
import { ProjectTypeField } from 'features/tasks-forms';
import { HistoryEntry, useProjectHistoryService } from 'nekst-api';

function ProjectHistoryBreadcrumbs() {
  return (
    <ProjectBreadcrumb>
      <Breadcrumb text="History" />
    </ProjectBreadcrumb>
  );
}

export default function ProjectHistory() {

  const params = useParams();

  const projectId = parseInt(params.id!, 10);

  const projectHistoryService = useProjectHistoryService();
  return (
    <>
      <ProjectHistoryBreadcrumbs />
      <MainBlock
        title={<TextField source="name" />}
        titleLine2={<ProjectTypeField />}
        headerRight={(
          <ProjectPagesButtons />
        )}
        columnContent={(<div />)}
      >
        <List<HistoryEntry>
          getDataFunc={async () => projectHistoryService.getProjectHistoryEvents(projectId)}
        >
          <InnerBlock
            title="History"
            headerRight={(
              <>
                <Right><OpenHistoryFilterButton /></Right>
                <Right className={styles.textSearchBlock}><HistoryEventFullTextFilter /></Right>
              </>
            )}
            showHeaderBorder
          >
            <ProjectHistoryEventWrapper>
              <ByDateGrouper />
            </ProjectHistoryEventWrapper>
          </InnerBlock>
        </List>
      </MainBlock>
    </>
  );
}
