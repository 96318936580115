import { useObjectDatesMapper } from './dateHelper';
import { AbstractTask } from '../Types';

export function useTasksResponseMapper() {
  const {
    mapSingle,
    mapList,
  } = useObjectDatesMapper<AbstractTask>(
    [
      'dueDate.time',
      'dueDate.date',
      '_approximateDueDate',
    ],
    [
      'completedAt',
    ],
  );

  return {
    mapSingle,
    mapList,
  };
}
