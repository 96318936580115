import React from 'react';
import { SendingStatus } from 'nekst-api';

import styles from '../../TaskTypeField.module.scss';
import { SmsFieldLayoutProps } from './types';

export default function SmsFieldLayoutWeb(props: SmsFieldLayoutProps) {
  const classes = [
    styles.field,
    styles.sms,
  ];

  if (props.sendingStatus === SendingStatus.SENT) {
    classes.push(styles.sent);
  }
  return (
    <div className={classes.join(' ')} title="SMS task">&nbsp;</div>
  );
}
