import React from 'react';
import { AuthorFilterWidget } from './authorFilter';
import { EventTypeFilterWidget } from './eventTypeFilter';

export default function FilterWidgets() {
  return (
    <>
      <AuthorFilterWidget />
      <EventTypeFilterWidget />
    </>
  );
}
