import React from 'react';
import {
  ContactAssignments,
} from 'nekst-api';

import styles from './ProjectAssignmentsList.module.scss';

import './SingleContactAssignmentsWidget.scss';
import ContactAssignmentsDropdown from './ContactAssignmentsDropdown';
import { PersonWidget } from 'features/nekst-widgets';

export interface ProjectAssignmentInputProps {
  contactAssignments: ContactAssignments;
  projectId: number;
}

export function SingleContactAssignmentWidget(
  props: ProjectAssignmentInputProps,
) {
  return (
    <div className={styles.row}>
      <PersonWidget profile={props.contactAssignments.profile} />
      <ContactAssignmentsDropdown
        contactAssignments={props.contactAssignments}
        projectId={props.projectId}
      />
    </div>
  );
}
