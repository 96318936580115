import React from 'react';
import { ProjectFilter, ProjectStatus } from 'nekst-api';
import { useFilterContext } from 'ui-builder';
import { useProjectStatisticContextRequired } from 'features/tasks-forms';
import TabSelector from '../../shared/widgets/TabSelector/TabSelector';

function TotalNumber(props: { status: ProjectStatus }) {
  const statistic = useProjectStatisticContextRequired().statistic;

  if (statistic) {
    let count;

    switch (props.status) {
      case ProjectStatus.ACTIVE:
        count = statistic.totalByStatus.active;
        break;
      case ProjectStatus.CLOSED:
        count = statistic.totalByStatus.closed;
        break;
      case ProjectStatus.ARCHIVED:
        count = statistic.totalByStatus.archived;
        break;
      default:
        count = undefined;
    }

    if (count !== undefined) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{` (${count})`}</>;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export default function QuickFilters() {
  const filterContext = useFilterContext<ProjectFilter>();

  return (
    <TabSelector
      activeOption={filterContext.filterValue?.status}
      onSelected={(value) => {
        const newFilterValue = {
          ...filterContext.filterValue!,
          status: value,
        };
        filterContext.setFilterValue(newFilterValue);
      }}
      options={[
        {
          value: ProjectStatus.ACTIVE,
          label: <>Active<TotalNumber status={ProjectStatus.ACTIVE} /></>,
        },
        {
          value: ProjectStatus.CLOSED,
          label: <>Closed<TotalNumber status={ProjectStatus.CLOSED} /></>,
        },
        {
          value: ProjectStatus.ARCHIVED,
          label: <>Archived<TotalNumber status={ProjectStatus.ARCHIVED} /></>,
        }
      ]}
      withoutMargin
    />
  );
}
