import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import AccessChecker from '../../shared/authorization/AccessChecker';
import TextField from '../../shared/uibuilder/show/field/TextField';
import MainBlock from '../../shared/web/layout/MainBlock';
import CreateTaskButton from './tasks/CreateUpdate/CreateTaskButton';
import { CalendarFilterContainer } from './tasks/list/filter/DueDate/CalendarFilter';
import ProjectMembersList, { TransactionPartiesList } from './assignments/ProjectMembersList';
import ProjectTasksList from './tasks/list/ProjectTasksList';
import { ViewOption } from './tasks/list/TasksList';
import ProjectPagesButtons from './ProjectPagesButtons';
import KeyDatesWidget from './keydates/KeyDatesWidget';
import { LatestProjectComments } from '../../tasks/list/LatestComments';
import useAuthorizationChecker from '../../shared/authorization/authorizationChecker';
import Error403 from '../../shared/authorization/Error403';
import { ProjectBreadcrumb } from './ProjectContext';
import ViewProjectPageTour from './ViewProjectPageTour';
import DetailFieldsLinksWidget from './tasks/list/DetailFieldsLinksWidget';
import { ProjectTypeField } from 'features/tasks-forms';
import { ProjectPermission } from 'nekst-api';
import { ProjectIdContext } from 'features/nekst-widgets';
import { TasksGroupFilterContainer } from './tasks/list/filter/Group/TasksGroupFilter';

export default function ViewProject() {
  const params = useParams();

  const id = parseInt(params.id!, 10);

  const { isGranted } = useAuthorizationChecker();

  if (isGranted(ProjectPermission.READ_TASKS)) {
    return (
      <ProjectIdContext projectId={id}>
        <ProjectBreadcrumb />
        <MainBlock
          title={<TextField source="name" />}
          titleLine2={<ProjectTypeField />}
          headerRight={(
            <>
              <CreateTaskButton />
              <ProjectPagesButtons />
            </>
          )}
        >
          <KeyDatesWidget projectId={id} />
          <MainBlock
            title=""
            columnContent={(
              <>
                <CalendarFilterContainer />
                <TasksGroupFilterContainer />
                <AccessChecker permissionToCheck={ProjectPermission.READ_ASSIGNMENTS}>
                  <ProjectMembersList />
                  <TransactionPartiesList />
                </AccessChecker>
                <LatestProjectComments projectId={id} />
                <DetailFieldsLinksWidget projectId={id} />
              </>
            )}
          >
            <ProjectTasksList
              projectId={id}
              view={ViewOption.LIST}
              title="Tasks"
            />
          </MainBlock>
        </MainBlock>
        <ViewProjectPageTour />
      </ProjectIdContext>
    );
  } else if (isGranted(ProjectPermission.READ_DETAILS)) {
    return (<Navigate to={`/transactions/${id}/details`} />);
  } else {
    return (<Error403 />);
  }
}
