import React from 'react';
import { RelativeRowCellLayoutProps, RelativeRowGroupLayoutProps } from 'ui-builder';
import styles from './RelativeRow.module.scss';

export function RelativeRowGroupLayout(
  props: RelativeRowGroupLayoutProps,
) {
  const classes = [
    styles.row,
    props.withFlex ? styles.flex : '',
    props.className,
    props.centerV ? styles.centerV : '',
  ];
  return (
    <div
      className={classes.join(' ')}
    >
      {props.children}
    </div>
  );
}

export function RelativeRowCellLayout(props: RelativeRowCellLayoutProps) {
  const centered = props.isCentered ? styles.centered : '';

  return (
    <div
      className={`${styles.cell} ${props.className} x${props.weight} ${centered}`}
    >
      {props.children}
    </div>
  );
}
