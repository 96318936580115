import React, { ReactElement, ReactNode } from 'react';
import { FormFieldsData } from './FormContext';
import { useChildrenProvider } from './childrenProvider';
import { useNewUiTheme } from '../newUiTheme';

export interface FormRowsProps {
  children: ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  isVisible?: boolean | ((data: FormFieldsData) => boolean);
  className?: string;
  dataKey?: string;
}

export function FormRows(props: FormRowsProps) {
  const childrenProvider = useChildrenProvider(props.children);

  const uiTheme = useNewUiTheme();

  return (
    <uiTheme.forms.FormRowsGroupLayout>
      {
        childrenProvider
          .getVisibleChildren()
          .map((child, index) => {
            if (child.props.source) {
              return (
                <uiTheme.forms.FormRowLayout
                  // eslint-disable-next-line react/no-array-index-key
                  key={`row-${index}`}
                  className={props.className}
                  dataKey={props.dataKey}
                >
                  {child}
                </uiTheme.forms.FormRowLayout>
              );
            } else {
              return child;
            }
          })
      }
    </uiTheme.forms.FormRowsGroupLayout>
  );
}
