import {
  PersonParseResult,
  ProjectType,
  PromptParseResult,
  useBackendApi,
  useEventsPublisher
} from 'nekst-api';

export enum TrainingEntryUsedFor {
  TRAINING = 'TRAINING',
  VALIDATION = 'VALIDATION',
}

export type AiTrainingEntry = {
  id: number,
  usedFor: TrainingEntryUsedFor,
  projectType: ProjectType,
  people: PersonParseResult[]
  prompts: PromptParseResult[]
  fileName: string
  fileText: string
  createdAt: Date,
  comment: string,
}

export type CreateAiTrainingEntryRequest = {
  projectType: ProjectType,
  usedFor: TrainingEntryUsedFor
  people: PersonParseResult[]
  prompts: PromptParseResult[]
  fileName: string
  fileText: string
  file: string
  comment: string,
  _model: string,
}

export type UpdateAiTrainingEntryRequest = {
  usedFor: TrainingEntryUsedFor
  people: PersonParseResult[]
  prompts: PromptParseResult[]
  comment: string,
}

export const AI_TRAINER_ENTRIES_UPDATED = 'AI_TRAINER_ENTRIES_UPDATED';

export default function useAiTrainerService() {
  const backendApi = useBackendApi();

  const publisher = useEventsPublisher();

  const getEntries = async (): Promise<AiTrainingEntry[]> => {
    return await backendApi.get('/aitrainer/entries') as AiTrainingEntry[];
  };

  const createEntry = async (request: CreateAiTrainingEntryRequest): Promise<AiTrainingEntry> => {
    return await backendApi.post('/aitrainer/entries', request) as AiTrainingEntry;
  };

  const getEntry = async (id: number): Promise<AiTrainingEntry> => {
    return await backendApi.get(`/aitrainer/entries/${id}`) as AiTrainingEntry;
  };

  const updateEntry = async (id: number, request: UpdateAiTrainingEntryRequest): Promise<AiTrainingEntry> => {
    return await backendApi.put(`/aitrainer/entries/${id}`, request) as AiTrainingEntry;
  };

  const deleteEntry = async (id: number): Promise<void> => {
    await backendApi.delete(`/aitrainer/entries/${id}`);

    publisher.publish(AI_TRAINER_ENTRIES_UPDATED, {});
  };

  const saveFile = async (fileName: string, content: string) => {
    const a = document.createElement('a');
    const blob = new Blob([content], {
      type: 'text/plain',
    });

    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', () => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };

  const downloadTrainingFile = async (): Promise<void> => {
    const fileContent = await backendApi.get('/aitrainer/trainingfile/TRAINING');

    await saveFile('trainingfile.jsonl', fileContent);
  };

  const downloadValidationFile = async (): Promise<void> => {
    const fileContent = await backendApi.get('/aitrainer/trainingfile/VALIDATION');

    await saveFile('validationfile.jsonl', fileContent);
  };

  const getOriginalDocumentDownloadLink = async (id: number): Promise<string> => {
    const result = await backendApi.get(`/aitrainer/entries/${id}/downloadlink`) as {
      link: string
    };

    return result.link;
  };

  return {
    getEntries,
    createEntry,
    getEntry,
    updateEntry,
    deleteEntry,
    downloadTrainingFile,
    downloadValidationFile,
    getOriginalDocumentDownloadLink,
  };
}
