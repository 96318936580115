import { useTasksResponseMapper } from './Helper';
import { useEventsPublisher } from 'event-bus';
import { EmailTask } from './Types';
import { useBackendApi } from './backendApi';

export const EMAIL_TASK_SENT = 'EMAIL_TASK_SENT';

export function useSendEmailTaskService() {
  const backendApi = useBackendApi();

  const { mapSingle } = useTasksResponseMapper();

  const eventsPublisher = useEventsPublisher();

  const sendEmail = async (taskId: number, message?: string) => {
    const result = await backendApi.post(`/tasks/${taskId}/sendemail`, {
      message,
    }) as Record<string, any>;

    const task = mapSingle(result) as EmailTask;

    eventsPublisher.publish(
      EMAIL_TASK_SENT,
      {
        id: taskId,
        data: task,
      },
    );

    return task;
  };

  return {
    sendEmail,
  };
}
