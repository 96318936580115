import React, { useState } from 'react';
import { Button, ButtonColor, ButtonSize, LoadingAnimation, usePopupManager } from 'ui-builder';
import { useParams } from 'react-router-dom';
import MainBlock from '../../shared/web/layout/MainBlock';
import useDownloadLinkService from './downloadLinkService';
import PageTitle from '../../shared/web/PageTitle';

import styles from './DownloadFilePage.module.scss';
import { formatBytes } from '../fileSizeHelper';
import { downloadFile } from '../downloadFileHelper';
import { PDFViewer, Popup } from 'features/nekst-widgets';
import useDataLoader from 'data-loader';
import { DownloadFileDto } from 'nekst-api';

function PreviewPopup(
  props: {
    document: DownloadFileDto,
  },
) {
  return (
    <Popup
      title="Preview Document"
      content={(
        <PDFViewer
          pdfUrl={props.document.downloadUrl}
          fileName={props.document.fileName}
        />
      )}
      isBig
    />
  );
}

function FilePreview(
  props: {
    file: DownloadFileDto,
  },
) {
  const popupManager = usePopupManager();

  return (
    <div className={styles.filePreview}>
      <div className={styles.info}>
        <div className={styles.fileName}>
          {props.file.fileName}
        </div>
        <div className={styles.size}>
          {formatBytes(props.file.size)}
        </div>
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Button
          size={ButtonSize.MEDIUM}
          color={ButtonColor.BLUE}
          text="Preview"
          isFilled
          onClick={async () => {
            popupManager.openPopup(<PreviewPopup document={props.file} />);
          }}
        />
        <Button
          size={ButtonSize.MEDIUM}
          color={ButtonColor.GREEN}
          text="Download"
          isFilled
          onClick={async () => {
            downloadFile(props.file);
          }}
        />
      </div>
    </div>
  );
}

export default function DownloadFilePage() {
  const [fileInfo, setFileInfo] = useState<DownloadFileDto>();

  const service = useDownloadLinkService();

  const params = useParams<{ code: string }>();

  const code = params.code!;

  useDataLoader(
    async () => service.getFileDownloadLink(code),
    setFileInfo,
  );

  if (fileInfo) {
    return (
      <>
        <PageTitle text="Download File" />
        <MainBlock title="Download File">
          <FilePreview file={fileInfo} />
        </MainBlock>
      </>
    );
  } else {
    return (<LoadingAnimation />);
  }
}
