import React, { ReactElement } from 'react';
import { useProjectsService } from 'nekst-api';
import { AuthorizationScope } from 'authorization-scope';

export interface ProjectAuthorizationScopeProps {
  children: ReactElement | ReactElement[];
  permissionToCheck?: string,
  id: number;
}

export { ProjectPermission } from 'nekst-api';

export default function ProjectAuthorizationScope(props: ProjectAuthorizationScopeProps) {
  const projectService = useProjectsService();

  return (
    <AuthorizationScope
      getPermissionsFunc={projectService.getPermissions}
      permissionToCheck={props.permissionToCheck}
      scopeName="Project"
      id={props.id}
    >
      {props.children}
    </AuthorizationScope>
  );
}
