import React, { ReactElement } from 'react';
import AccessChecker from '../shared/authorization/AccessChecker';
import { TeamPermission } from 'nekst-api';

export default function AdminPortalAccessChecker(
  props: {
    children: ReactElement | ReactElement[],
    show403Error?: boolean,
  },
) {
  return (
    <AccessChecker
      permissionToCheck={TeamPermission.MANAGE_PORTAL}
      show403Error={props.show403Error || false}
    >
      {props.children}
    </AccessChecker>
  );
}
