import useProjectsApi from '../../../projects/projectsApi';
import { DocumentLabel } from '../labels/documentLabelsService';
import { useEventsPublisher } from '../../../shared/events/EventBusContext';

export type DocumentsTemplate = {
  id: number,
  name: string,
  labelsIds: number[],
  _labels: DocumentLabel[],
}

export type CreateDocumentsTemplateRequest = {
  name: string,
  labelsIds: number[]
}

export type UpdateDocumentsTemplateRequest = {
  name: string
  labelsIds: number[],
}

export const DOCUMENTS_TEMPLATES_UPDATED = 'DOCUMENTS_TEMPLATES_UPDATED';

export default function useDocumentsTemplatesService() {
  const nekstApi = useProjectsApi();
  const eventsPublisher = useEventsPublisher();

  const getDocumentsTemplates = async () => {
    return await nekstApi.get('/documentstemplates') as DocumentsTemplate[];
  };

  const publishEvent = () => {
    eventsPublisher.publish(DOCUMENTS_TEMPLATES_UPDATED, {});
  };

  const createDocumentsTemplate = async (request: CreateDocumentsTemplateRequest) => {
    const response = await nekstApi.post('/documentstemplates', request) as DocumentsTemplate;
    publishEvent();

    return response;
  };

  const updateDocumentsTemplate = async (id: number, request: UpdateDocumentsTemplateRequest) => {
    const result = await nekstApi.put(`/documentstemplates/${id}`, request) as DocumentsTemplate;
    publishEvent();

    return result;
  };

  const deleteDocumentsTemplate = async (id: number) => {
    await nekstApi.delete(`/documentstemplates/${id}`);

    publishEvent();
  };

  return {
    createDocumentsTemplate,
    getDocumentsTemplates,
    updateDocumentsTemplate,
    deleteDocumentsTemplate,
  };
}
