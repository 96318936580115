import React from 'react';
import { AfterSubmitFunc } from 'ui-builder';
import BasePersonParseResultForm from './BasePersonParseResultForm';
import { useAiTrainingEntryContext } from '../ViewParseResultWidget';

export default function AddPersonParseResultForm(
  props: {
    afterSubmitFunc?: AfterSubmitFunc,
  }
) {
  const entryContext = useAiTrainingEntryContext();

  return (
    <BasePersonParseResultForm
      afterSubmitFunc={props.afterSubmitFunc}
      submitFormFunc={async (data) => {
        entryContext.addContact(data);
        return data;
      }}
      data={{
        context: [],
      }}
      title="Add Person"
    />
  );
}
