import React from 'react';
import { useNewUiTheme } from '../newUiTheme';
import { BlockProps } from './blocksTypes';

export function Right(props: BlockProps) {
  const uiTheme = useNewUiTheme();

  return (
    <uiTheme.common.RightLayout {...props}>
      <>
        {props.children}
      </>
    </uiTheme.common.RightLayout>
  );
}
