import React, { ReactElement } from 'react';
import useAuthorizationChecker from './authorizationChecker';
import Error403 from './Error403';

export interface AccessCheckerProps {
  permissionToCheck: string,
  children: ReactElement | ReactElement[]
  show403Error?: boolean,
}

export default function AccessChecker(props: AccessCheckerProps) {
  const {
    isGranted,
    isLoaded,
  } = useAuthorizationChecker();

  const show403Error = props.show403Error !== undefined ? props.show403Error : true;

  /* eslint-disable react/jsx-no-useless-fragment */
  if (isLoaded) {
    if (isGranted(props.permissionToCheck)) {
      return (
        <>
          {props.children}
        </>
      );
    } else if (show403Error) {
      return <Error403 />;
    } else {
      return null;
    }
  } else {
    return null;
  }
}
