import React from 'react';
import { useAllTasksService } from 'nekst-api';
import TasksList, { ViewOption } from '../../projects/view/tasks/list/TasksList';
import OpenAllTasksICalCalendarButton from '../../projects/view/OpenAllTasksICalCalendarButton';
import { Breadcrumb, BreadcrumbsWrapper, } from '../../shared/web/layout/Breadcrumbs';
import PageHeaderWithIcon, {
  PageIconType
} from '../../shared/widgets/PageTitle/PageHeaderWithIcon';

export default function AllTasksCalendarPage() {
  const tasksService = useAllTasksService();

  return (
    <>
      <BreadcrumbsWrapper>
        <Breadcrumb text="Calendar" href="/calendar" />
      </BreadcrumbsWrapper>
      <TasksList
        getDataFunc={tasksService.getTasks}
        showProjectName
        view={ViewOption.CALENDAR}
        title={(
          <PageHeaderWithIcon iconType={PageIconType.CALENDAR}>
            Calendar
            <OpenAllTasksICalCalendarButton />
          </PageHeaderWithIcon>
        )}
      />
    </>
  );
}
