import React, { useEffect, useState } from 'react';
import { LoadingAnimation } from 'ui-builder';
import { useBackendApi } from 'nekst-api';

function useImageLoader() {
  const nekstApi = useBackendApi();

  function blobToBase64(blob: Blob) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  async function loadImageData(src: string) {
    const data = await nekstApi.get(src, {
      responseType: 'blob',
    }) as Blob;

    return await blobToBase64(data) as string;
  }

  return {
    loadImageData,
  };
}

export default function ImageWidgetLayoutWeb(
  props: {
    src: string,
    className?: string,
    alt?: string
  }
) {
  const imageLoader = useImageLoader();

  const [imageData, setImageData] = useState<string | null>(null);

  useEffect(() => {
    async function loadImageData() {
      const base64 = await imageLoader.loadImageData(props.src);
      setImageData(base64);
    }

    setImageData(null);
    loadImageData();
  }, [props.src]);

  if (imageData) {
    return (
      <img src={imageData} alt={props.alt} className={props.className} />
    );
  } else {
    return <LoadingAnimation />;
  }
}
