import React from 'react';
import PageLink from './PageLink';
import useTextHelper from './pageDefiner';
import { PlanData, ProjectData } from 'nekst-api';

export default function PlanLink(props: {
  plan: PlanData,
  project: ProjectData
}) {
  const pageFinder = useTextHelper();

  return (
    <PageLink
      text={pageFinder.addProjectName(props.plan.name, props.project)}
      action={() => {
        // ignored
      }}
      isDeleted={props.plan.isDeleted}
    />
  );
}
