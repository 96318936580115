import { useEffect, useState } from 'react';
import {
  useProjectsService,
  ProjectType,
  AbstractTask,
  TaskType,
  useSmartTagsService,
} from 'nekst-api';
import { useSmartTagsContext } from '../../../shared/smarttags/SmartTagsContext';
import { useFormContext } from 'ui-builder';

export default function SmartTagsUpdater() {
  const projectService = useProjectsService();

  const smartTagsService = useSmartTagsService();

  const smartTagsContext = useSmartTagsContext();

  const [currentProjectType, setCurrentProjectType] = useState<ProjectType | null>();

  const formContext = useFormContext<AbstractTask>();
  const projectId = formContext.data?.projectId;

  async function updateProjectType() {
    if (projectId) {
      const type = await projectService.getTypeByProjectId(projectId);
      setCurrentProjectType(type);
    } else {
      setCurrentProjectType(undefined);
    }
  }

  useEffect(() => {
    updateProjectType();
  }, [projectId]);

  async function loadTags() {
    if (smartTagsContext && currentProjectType) {
      const tags = await smartTagsService.getSmartTags(currentProjectType, TaskType.SMS, projectId);
      smartTagsContext.setAvailableTags(tags);
    } else {
      smartTagsContext.setAvailableTags([]);
    }
  }

  useEffect(() => {
    loadTags();
  }, [currentProjectType]);

  return null;
}
