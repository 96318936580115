import React from 'react';
import { ProjectDetailsField, ProjectType, useProjectDetailsFieldsService } from 'nekst-api';
import { BaseInputProps, CheckboxGroup, LoadingAnimation } from 'ui-builder';
import useDataLoader from 'data-loader';


interface DetailBoxesCheckboxGroupProps extends BaseInputProps<number[]> {
  projectType: ProjectType,
}

export default function DetailBoxesCheckboxGroup(
  props: DetailBoxesCheckboxGroupProps
) {
  const detailsBoxesService = useProjectDetailsFieldsService();

  const [detailsFields, setDetailsFields] = React.useState<ProjectDetailsField[]>([]);


  useDataLoader(
    () => detailsBoxesService.getFields(props.projectType),
    setDetailsFields,
    props.projectType
  )

  if (detailsFields) {
    return (
      <CheckboxGroup
        weights={[3, 3, 3, 3]}
        options={
          detailsFields.map((field) => ({
            label: field.name,
            value: field.id,
          }))
        }
        {...props}
      />
    );
  } else {
    return <LoadingAnimation />;
  }
}
