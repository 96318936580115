import React, { ReactElement } from 'react';
import { Breadcrumb } from '../../shared/web/layout/Breadcrumbs';
import { SettingsBreadcrumbs } from '../settingsModule';

export function TeamMembersBreadcrumbs(
  props: {
    children?: ReactElement
  },
) {
  return (
    <SettingsBreadcrumbs>
      <Breadcrumb text="Team Members Management" href="/settings/members">
        {props.children}
      </Breadcrumb>
    </SettingsBreadcrumbs>
  );
}
