import React from 'react';
import { AfterSubmitFunc, Button, ButtonColor, IconType } from 'ui-builder';
import BaseTasksGroupForm from './BaseTasksGroupForm';
import { CreateTaskGroupRequest, useTasksGroupsService } from 'nekst-api';
import { usePopupFormManager } from 'features/nekst-widgets';

export function CreateTasksGroupForm(
  props: {
    afterSubmitFunc?: AfterSubmitFunc,
  }
) {
  const tasksGroupsService = useTasksGroupsService();

  return (
    <BaseTasksGroupForm
      submitFormFunc={async (data: CreateTaskGroupRequest) => {
        return await tasksGroupsService.createTaskGroup(data);
      }}
      title="Create Tasks Group"
      afterSubmitFunc={props.afterSubmitFunc}
    />
  );
}


export function CreateTasksGroupButton() {
  const popupManager = usePopupFormManager();

  return (
    <Button
      onClick={() => {
        popupManager.openForm(<CreateTasksGroupForm />)
      }}
      iconType={IconType.PLUS}
      text="Create Group"
      color={ButtonColor.BLUE}
      isFilled
    />
  );
}
