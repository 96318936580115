import React from 'react';
import { SendingStatus } from 'nekst-api';
import { EmailFieldLayoutProps } from '../types';

import styles from '../../../TaskTypeField.module.scss';

export default function EmailFieldLayoutWeb(props: EmailFieldLayoutProps) {
  let statusClass = '';

  const emailSendingStatus = props.sendingStatus;

  if (emailSendingStatus === SendingStatus.SENT) {
    statusClass = styles.sent;

  } else if (emailSendingStatus === SendingStatus.SENT_AND_OPENED) {
    statusClass = styles.sentAndOpened;

  } else if (emailSendingStatus === SendingStatus.ERROR_OCCURRED) {
    statusClass = styles.errorOccurred;
  }

  return (
    <div
      className={`${styles.field} ${styles.email} ${statusClass}`}
      title={props.title}
    >
      &nbsp;
    </div>
  );
}
