import React from 'react';
import { useProjectTasksMassDeleteService } from 'nekst-api';
import { useMassUpdateServiceRequired } from 'ui-builder';
import { ConfirmDeletePopup } from 'features/nekst-widgets';

export default function DeleteForm() {
  const massDeleteService = useProjectTasksMassDeleteService();
  const massUpdateService = useMassUpdateServiceRequired();

  return (
    <ConfirmDeletePopup
      title="Mass Update: Delete Tasks"
      deleteFunc={
        async () => {
          await massDeleteService.massDelete(massUpdateService.getCheckedIds());
        }
      }
      message={'Please "Confirm" to Delete Selected Tasks'}
    />
  );
}
