import React from 'react';
import LinkField from '../../../../shared/uibuilder/show/field/LinkField';
import UpdateStandardTaskForm from './Standard/UpdateStandardTaskForm';
import UpdateEmailTaskForm from './Email/UpdateEmailTaskForm';

import styles from './OpenUpdateFormField.module.scss';
import UpdateSmsTaskForm from './Sms/UpdateSmsTaskForm';
import useTasksNavigator from '../tasksNavigator';
import { usePopupFormManager } from 'features/nekst-widgets';
import { AbstractTask, EmailTask, SmsTask, TaskStatus, TaskType } from 'nekst-api';
import { useShowContext } from 'ui-builder';

export function useOpenUpdateFormHelper() {
  const popupManager = usePopupFormManager();

  const openForm = (task: AbstractTask) => {
    if (task.type === TaskType.STANDARD) {
      popupManager.openForm(<UpdateStandardTaskForm data={task} />);
    } else if (task.type === TaskType.EMAIL) {
      popupManager.openForm(<UpdateEmailTaskForm data={task as EmailTask} />);
    } else if (task.type === TaskType.SMS) {
      popupManager.openForm(<UpdateSmsTaskForm data={task as SmsTask} />);
    }
  };

  return {
    openForm,
  };
}

export default function OpenUpdateFormField() {
  const { data } = useShowContext<AbstractTask>();

  const className = data?.status === TaskStatus.COMPLETED
    ? styles.completed
    : undefined;

  const tasksNavigator = useTasksNavigator();

  if (data) {
    return (
      <LinkField
        source="_renderedName"
        onClick={() => tasksNavigator.openTask(data!.id)}
        uri={tasksNavigator.getOpenTaskUri(data!.id)}
        className={className}
      />
    );
  } else {
    return null;
  }

}
