import React from 'react';
import { useShowContext } from 'ui-builder';
import { AbstractTask } from 'nekst-api';
import LinkField from '../../../../shared/uibuilder/show/field/LinkField';
import useProjectNavigator from '../../../projectNavigator';

export default function OpenProjectField() {
  const showContext = useShowContext<AbstractTask>();

  const projectNavigator = useProjectNavigator();

  const task = showContext.data!;

  return (
    <LinkField
      uri={projectNavigator.getTaskPageLink(task._project.id)}
      onClick={() => projectNavigator.openTasksPage(task._project.id)}
      value={task._project.name}
    />
  );
}
