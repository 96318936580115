import React from 'react';
import { useMassUpdateServiceRequired } from 'ui-builder';
import { useEventsPublisher } from 'event-bus';
import { MassUpdateFormProps } from '../../../shared/widgets/MassUpdate';
import useKeyDateService from './keyDateService';
import { ConfirmPositiveActionPopup, usePopupFormManager } from 'features/nekst-widgets';
import { PLAN_TASKS_UPDATED } from 'nekst-api';

function MakeKeyDateForm(props: MassUpdateFormProps) {
  const keyDateService = useKeyDateService();
  const eventsPublisher = useEventsPublisher();

  return (
    <ConfirmPositiveActionPopup
      title="Mass Update: Key Dates"
      submitFormFunc={async () => {
        await keyDateService.markAsKeyDate(props.ids);
        eventsPublisher.publish(PLAN_TASKS_UPDATED, {});
      }}
      message={'Click "Confirm" to make the Selected Tasks each a Key Date.'}
    />
  );
}

export default function useMakeKeyDateForm() {
  const popupManager = usePopupFormManager();
  const massUpdateService = useMassUpdateServiceRequired();

  const openForm = () => {
    popupManager.openForm(
      <MakeKeyDateForm ids={massUpdateService.getCheckedIds()} />,
      true,
    );
  };

  return {
    openForm,
  };
}
