import React, { useState } from 'react';
import { DateTimeField, List, ListDataRefresher, useShowContext } from 'ui-builder';
import { get } from 'lodash';
import BorderedBox from '../../shared/web/layout/BorderedBox';
import ViewAllWrapper from '../../shared/widgets/ViewAllWrapper';

import LogoAndDataLayout from '../../shared/widgets/LogoAndDataLayout';
import UserLogoField from '../../shared/widgets/UserLogoField';

import styles from './LatestComments.module.scss';
import ProjectLinkField from '../../projects/list/field/ProjectLinkField';
import { ShowMoreTextField } from '../../shared/widgets/ShowMoreTextWrapper';
import useTasksNavigator from '../../projects/view/tasks/tasksNavigator';
import useDataLoader from 'data-loader';
import { COMMENTS_UPDATED_EVENT, useTaskCommentsService, Comment } from 'nekst-api';
import { RowRepeater } from 'features/nekst-widgets';

function TaskLinkField(props: {
  source: string,
  className?: string,
  idSource: string,
}) {
  const showContext = useShowContext();

  const data = showContext.data!;

  const id = get(data, props.idSource);
  const name = get(data, props.source);

  const tasksNavigator = useTasksNavigator();

  return (
    <a
      href={tasksNavigator.getOpenTaskUri(id)}
      className={props.className}
      onClick={() => tasksNavigator.openTask(id)}
    >
      {name}
    </a>
  );
}

export function BaseLatestComments(props: {
  getDataFunc: () => Promise<Comment[]>,
  showProjectName?: boolean
}) {
  const [comments, setComments] = useState<Comment[]>([]);

  useDataLoader<Comment[]>(
    props.getDataFunc,
    setComments,
  );

  const showProjectName = props.showProjectName === undefined || props.showProjectName;

  if (comments?.length > 0) {
    return (
      <BorderedBox title="Recent Comments" className={styles.block}>
        <List getDataFunc={async () => comments}>
          <ListDataRefresher eventName={COMMENTS_UPDATED_EVENT} />
          <ViewAllWrapper numberOfItems={3} buttonText="View More">
            <RowRepeater>
              <LogoAndDataLayout logo={<UserLogoField source="_author" />}>
                {showProjectName && (
                  <ProjectLinkField
                    source="_project.name"
                    idSource="_project.id"
                    className={styles.projectName}
                  />
                )}
                <DateTimeField source="createdAt" className={styles.date} />
                <TaskLinkField
                  source="_task.name"
                  idSource="_task.id"
                  className={styles.taskName}
                />
                <ShowMoreTextField source="_renderedText" className={styles.comment} maxLength={200} />
              </LogoAndDataLayout>
            </RowRepeater>
          </ViewAllWrapper>
        </List>
      </BorderedBox>
    );
  } else {
    return null;
  }
}

export function LatestProjectComments(props: {
  projectId: number
}) {
  const commentsService = useTaskCommentsService();
  return (
    <BaseLatestComments
      getDataFunc={() => commentsService.getLatestProjectComments(props.projectId)}
      showProjectName={false}
    />
  );
}

export default function LatestComments() {
  const commentsService = useTaskCommentsService();

  return (
    <BaseLatestComments getDataFunc={commentsService.getLatestComments} />
  );
}
