import { BaseInputProps, LoadingAnimation, MultiSelectDropdown, Option } from 'ui-builder';
import { useServiceProvidersGroupsService } from 'nekst-api';
import React, { useState } from 'react';
import useDataLoader from 'data-loader';

export interface ServiceProvidersMultiDropdown extends BaseInputProps<number[]> {

}

export function ServiceProvidersMultiDropdown(props: ServiceProvidersMultiDropdown) {
  const serviceProvidersService = useServiceProvidersGroupsService();

  const [options, setOptions] = useState<Option[]>();

  const loadOptions = async () => {
    return (await serviceProvidersService.getAll())
      .map((item) => ({
        label: item.name,
        value: item.id,
      }));
  };

  useDataLoader(
    loadOptions,
    setOptions,
  );

  if (options) {
    return (
      <MultiSelectDropdown
        source={props.source}
        label={props.label || ''}
        options={options}
        placeholder={props.placeholder}
        hideLabel={!props.label}
      />
    );
  } else {
    return (<LoadingAnimation />);
  }
}
