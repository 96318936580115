import React from 'react';
import { useTasksCompletionService } from 'nekst-api';
import { Button, ButtonColor, ButtonSize, isReactNative, usePopupManager, } from 'ui-builder';

export default function ForceCompleteButton(props: { id: number }) {
  const tasksCompletionService = useTasksCompletionService();

  const popupManager = usePopupManager();

  const submit = async () => {
    await tasksCompletionService.completeTask(props.id, true);
    popupManager.closePopup();
  };

  return (
    <Button
      text={isReactNative() ? "Complete" : "Do Not Send SMS & Complete"}
      color={ButtonColor.YELLOW}
      onClick={submit}
      size={isReactNative() ? ButtonSize.MEDIUM : ButtonSize.BIG}
    />
  );
}
