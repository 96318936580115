/* istanbul ignore file */
import React from 'react';
import ErrorsWrapper from '../../../uibuilder/form/input/layout/ErrorsWrapper';
import { CheckboxLayoutProps } from '../../../uibuilder/form/input/Checkbox';

import styles from './CheckboxLayout.module.scss';

function CheckboxLayout(props: CheckboxLayoutProps) {
  const getCheckboxId = (value: string) => {
    return `${value.replace('.', '_')
      .replace(':', '_')}-checkmark`;
  };

  return (
    <ErrorsWrapper name={props.name} errors={props.errors}>
      <>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          className={`${styles.container} ${props.className} ${!props.label || props.hideLabel ? styles.noLabel : ''} ${props.isDisabled ? styles.disabled : ''}`}
          title={props.title}
          data-key={props.dataKey}
        >
          {!props.isHighlighted && !props.hideLabel && props.label}
          {props.isHighlighted && !props.hideLabel && <b>{props.label}</b>}
          <input
            name={props.name}
            type="checkbox"
            placeholder={props.placeholder}
            checked={props.value || false}
            disabled={props.isDisabled}
            onChange={(event) => {
              if (props.onChangeCallback) {
                props.onChangeCallback({
                  target: {
                    value: event.target.checked,
                  },
                });
              }
            }}
          />
          <span
            className={styles.checkmark}
            id={getCheckboxId(props.name)}
          />
          {props.hint && (
            <div className="hint">
              {props.hint}
            </div>
          )}
        </label>
      </>
    </ErrorsWrapper>
  );

}

export default CheckboxLayout;
