import React from 'react';
import AdminPortalAccessChecker from '../../../team/AdminPortalAccessChecker';
import { SettingsBreadcrumbs } from '../../settingsModule';
import { Breadcrumb } from '../../../shared/web/layout/Breadcrumbs';
import InnerBlock from '../../../shared/web/layout/InnerBlock';
import { AiTrainingEntriesList } from './List';
import useAiTrainerNavigator from './aiTrainerNavigator';
import { Button, ButtonSize } from 'ui-builder';
import useAiTrainerService from './aiTrainerService';

function CreateNewEntryButton() {
  const navigator = useAiTrainerNavigator();

  return (
    <Button
      text="Create New Entry"
      onClick={() => navigator.openCreatePage()}
      size={ButtonSize.MEDIUM}
      isFilled
    />
  );
}

function DownloadTrainingFileButton() {
  const aiTrainerService = useAiTrainerService();

  return (
    <Button
      text="Download Training File"
      onClick={async () => {
        await aiTrainerService.downloadTrainingFile();
      }}
    />
  );
}

function DownloadValidationFileButton() {
  const aiTrainerService = useAiTrainerService();

  return (
    <Button
      text="Download Validation File"
      onClick={async () => {
        await aiTrainerService.downloadValidationFile();
      }}
    />
  );
}

export default function AiTrainerPage() {
  return (
    <AdminPortalAccessChecker show403Error>
      <SettingsBreadcrumbs>
        <Breadcrumb text="AI Trainer" />
      </SettingsBreadcrumbs>
      <InnerBlock
        title="AI Trainer"
        headerRight={(
          <>
            <DownloadTrainingFileButton />
            <DownloadValidationFileButton />
            <CreateNewEntryButton />
          </>
        )}
      >
        <AiTrainingEntriesList />
      </InnerBlock>
    </AdminPortalAccessChecker>
  );
}

