import React from 'react';
import { Button, ButtonColor, ButtonSize } from 'ui-builder';

export interface SubmitPopupButtonProps {
  text?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  isDisabled?: boolean;
  onClick: () => Promise<void>;
  size?: ButtonSize;
  color?: ButtonColor;
}

export default function SubmitPopupButtonLayout(props: SubmitPopupButtonProps) {
  const text = props.text || 'Submit';

  const size = props.size || ButtonSize.BIG;
  const color = props.color || ButtonColor.GREEN;

  const onClickFunc = async () => {
    await props.onClick();
  };

  return (
    <Button
      text={text}
      onClick={onClickFunc}
      color={color}
      size={size}
      isSubmitButton
    />
  );
}
