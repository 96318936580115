/* istanbul ignore file */
import React, {
  ReactElement, ReactNode, useEffect, useState,
} from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import styles from './Breadcrumbs.module.scss';
import layoutStyles from './BaseLayout.module.scss';
import PageTitle from '../PageTitle';

export function Breadcrumb(
  props: {
    text: string,
    href?: string,
    children?: ReactNode | false,
  },
) {
  const navigate = useNavigate();
  return (
    <>
      {props.children && (
        <>
          <a
            className={styles.breadcrumb}
            href={props.href}
            onClick={(event) => {
              event.preventDefault();

              if (props.href) {
                navigate(props.href);
              }
            }}
          >
            {props.text}
          </a>
          <span className={styles.separator}>
            &gt;
          </span>
          {props.children}
        </>
      )}
      {!props.children && (
        <>
          <PageTitle text={props.text} />
          <span className={styles.breadcrumb}>
            {props.text}
          </span>
        </>
      )}
    </>
  );
}

export function BreadcrumbsPortal() {
  return (
    <div id="breadcrumbs" />
  );
}

export function BreadcrumbsPortalRenderer(props: { children: ReactElement }) {
  const element = document.getElementById('breadcrumbs');

  if (element) {
    return ReactDOM.createPortal(props.children, element);
  } else {
    return null;
  }
}

export function BreadcrumbsWrapper(
  props: {
    children: ReactElement,
  },
) {
  const [init, setInit] = useState(false);

  useEffect(() => {
    setInit(true);
  }, []);


  if (init) {
    return (
      <BreadcrumbsPortalRenderer>
        <div className={layoutStyles.breadcrumbsBlock}>
          <div className={layoutStyles.blockContent}>
            <div className={styles.breadcrumbs}>
              {props.children}
            </div>
          </div>
          <div className={layoutStyles.shadow}>
            &nbsp;
          </div>
        </div>
      </BreadcrumbsPortalRenderer>
    );
  } else {
    return null;
  }
}
