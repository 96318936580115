import React from 'react';
import useDocumentsTemplatesService, {
  DocumentsTemplate,
  UpdateDocumentsTemplateRequest,
} from './documentsTemplatesService';
import Text from '../../../shared/uibuilder/form/input/Text';
import DocumentLabelsDropdown from '../labels/DocumentLabelsDropdown';
import { AfterSubmitFunc, FormTemplate, useShowContext } from 'ui-builder';
import { OpenFormIconButton } from 'features/nekst-widgets';

export default function UpdateDocumentsTemplateForm(
  props: {
    data: DocumentsTemplate,
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {

  const documentsTemplateService = useDocumentsTemplatesService();
  return (
    <FormTemplate<UpdateDocumentsTemplateRequest>
      title="Update Template"
      submitFormFunc={(data) => documentsTemplateService.updateDocumentsTemplate(
        props.data.id,
        data,
      )}
      initialData={{
        name: props.data.name,
        labelsIds: props.data.labelsIds,
      }}
      afterSubmitFunc={props.afterSubmitFunc}
    >
      <Text source="name" label="Name" />
      <DocumentLabelsDropdown source="labelsIds" label="Document Labels" />
    </FormTemplate>
  );
}

export function UpdateDocumentsTemplateButton() {
  const showContext = useShowContext<DocumentsTemplate>();

  const template = showContext.data!;

  return (
    <OpenFormIconButton
      title="Update Template"
      form={<UpdateDocumentsTemplateForm data={template} />}
    />
  );
}
