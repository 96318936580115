/* eslint-disable react/jsx-props-no-spreading */
import React, {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { debounce } from 'lodash';

import styles from './Autocomplete.module.scss';
import { BaseInputProps,  useFormContext, useInputHelper, useNewUiTheme } from 'ui-builder';
import { Person, usePeopleService } from 'nekst-api';

export type Option = {
  value: number;
  label: string;
  disabled?: boolean;
}

function useContactsSearch() {
  const contactsService = usePeopleService();

  const search = async (query: string, setResultsFunc: (options: Option[]) => void) => {
    const contacts = await contactsService.search(query);

    setResultsFunc(contacts.map((contact: Person) => {
      return {
        value: contact.id,
        label: `${contact.profile.name.firstName} ${contact.profile.name.lastName}`,
        profile: contact.profile,
        id: contact.id,
      } as Option;
    }));
  };

  const debouncedSearch = useCallback(debounce(search, 500), []);

  return {
    search: debouncedSearch,
  };
}

export interface SearchResultsProps<DataType> {
  results: DataType[];
  query: string,
  onSelectedFunc: (option: DataType) => void;
  isSelectedFunc: (position: number) => boolean;
}

export function SearchResults<DataType extends Option>(props: SearchResultsProps<DataType>) {
  return (
    /* eslint-disable react/no-danger */
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <div className={styles.items}>
      {props.results.map((option, index) => {
        return (
          // eslint-disable-next-line max-len
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div
            key={`option-${option.value}`}
            className={`${styles.item} ${props.isSelectedFunc(index) ? styles.active : ''}`}
            onClick={() => {
              props.onSelectedFunc(option);
            }}
            dangerouslySetInnerHTML={{
              __html: option.label.replace(/[^A-Za-z0-9 \\-]/g, '').replace(props.query, `<b>${props.query}</b>`),
            }}
          />
        );
      })}
    </div>
  );
}

export function useKeyboardNavigationBehavior<DataType>(
  results: DataType[],
  selectItem: (item: DataType) => void,
  setResults: (options: DataType[]) => void,
) {

  const [activePosition, setActivePosition] = useState<number>(-1);

  const isActive = (position: number) => activePosition === position;

  const up = () => {
    if (results.length > 0) {
      if (activePosition > -1) {
        setActivePosition(Math.min(activePosition - 1, results.length - 1));
      } else {
        setActivePosition(-1);
      }
    } else {
      setActivePosition(-1);
    }
  };

  const down = () => {
    if (results.length > 0) {
      if (activePosition < results.length - 1) {
        setActivePosition(Math.min(activePosition + 1, results.length - 1));
      } else {
        setActivePosition(results.length - 1);
      }
    } else {
      setActivePosition(-1);
    }
  };

  const onKeyDown: KeyboardEventHandler = (event) => {
    if (event.key === 'ArrowUp') {
      up();
    } else if (event.key === 'ArrowDown') {
      down();
    } else if (event.key === 'Enter') {
      if (activePosition !== -1) {
        selectItem(results[activePosition]);
        setResults([]);
      }
    }
  };

  useEffect(() => {
    setActivePosition(-1);
  }, [results.length]);

  return {
    isActive,
    onKeyDown,
  };
}

export interface AutocompleteInputProps<DataType> extends BaseInputProps {
  loadSuggestions: (query: string) => Promise<DataType[]>,
  selectOptionFunc: (option: DataType) => void
}

export function AutocompleteInput<DataType extends Option = Option>(
  props: AutocompleteInputProps<DataType>,
) {
  const [query, setQuery] = useState('');

  const uiTheme = useNewUiTheme();

  const TextLayout = uiTheme.inputs.TextLayout;

  const inputHelper = useInputHelper(props);
  const [results, setResults] = useState<DataType[]>([]);

  const formContext = useFormContext();

  const selectItem = props.selectOptionFunc;

  const { search } = useContactsSearch();

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const keyboardNavigation = useKeyboardNavigationBehavior(
    results,
    selectItem,
    setResults,
  );

  const searchFunc = useCallback(
    debounce(async (q: string, setResultsFunc: (options: DataType[]) => void) => {
      const result = await props.loadSuggestions(q);
      setResultsFunc(result);
    }, 500),
    [],
  );

  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div
      role="button"
      className={styles.container}
      onKeyDown={keyboardNavigation.onKeyDown}
    >
      <TextLayout
        {...inputHelper.getBaseInputLayoutProps()}
        onChangeCallback={(event) => {
          /* eslint-disable prefer-destructuring */
          const value = event.target.value;

          formContext.onChangeCallback!({
            [props.source]: value
          });

          setQuery(value);

          if (value && value.length > 1) {
            searchFunc(value, setResults);
          } else {
            search.cancel();
            setResults([]);
          }

          props.onChangeCallback?.(event);
        }}
        onFocusCallback={() => setIsFocused(true)}
        onBlurCallback={() => {
          setTimeout(() => {
            setResults([]);
            setIsFocused(false);
          }, 150);
        }}
      />
      {isFocused && results.length > 0 && (
        <SearchResults
          results={results}
          query={query}
          onSelectedFunc={selectItem}
          isSelectedFunc={keyboardNavigation.isActive}
        />
      )}
    </div>

  );
}
