import axios, { AxiosProgressEvent } from 'axios';
import { ValidationError } from 'request-sender';
import { useBackendApi } from './backendApi';

export enum FileType {
  PROJECT_DOCUMENT = 'PROJECT_DOCUMENT',
  PROJECT_PHOTO = 'PROJECT_PHOTO',
}

export interface FileDto {
  id: number,
  name: string,
  mimeType: string,
}

export interface DownloadFileDto {
  downloadUrl: string,
  fileName: string
  size: number
  mimeType: string
}

export interface CreateUploadLinkResponse {
  uploadLink: string,
  file: FileDto,
}

export function useMimeTypesHelper() {

  const isPdfMimeType = (mimeType: string) => {
    return mimeType === 'application/pdf';
  };

  const isWordMimeType = (mimeType: string) => {
    return [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.oasis.opendocument.text',
    ].includes(mimeType);
  };

  return {
    isPdfMimeType,
    isWordMimeType,
  };
}

export async function readImageAsDataUrl(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const dataUrl = reader.result as string;
      resolve(dataUrl);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

async function readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const fileData = reader.result as ArrayBuffer;
      resolve(fileData);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
}

function useFileValidator() {

  const toMb = (size: number) => {
    return size / 1024 / 1024;
  };

  const fromMb = (size: number) => {
    return size * 1024 * 1024;
  };

  const validateFileSize = async (file: File, maxSize: number) => {
    if (file.size > maxSize) {
      return [
        `File size exceeds limit of ${toMb(maxSize)} Mb`,
      ];
    } else {
      return [];
    }
  };

  const validateMimeType = async (
    file: File,
    allowedMimeTypes: string[],
    errorMessage?: string,
  ) => {
    if (!allowedMimeTypes.includes(file.type)) {
      if (errorMessage) {
        return [errorMessage];
      } else {
        return [
          `File type ${file.type} is not allowed. Allowed files: ${allowedMimeTypes.join(', ')}`,
        ];
      }
    } else {
      return [];
    }
  };

  const validateImageSize = async (file: File, minWidth?: number, minHeight?: number): Promise<string[]> => {
    const fileData = await readImageAsDataUrl(file);
    return new Promise((resolve, reject) => {
      if (minHeight || minWidth) {
        const image = new Image();

        // Set up an event listener for when the Image has finished loading
        image.onload = () => {
          // Access the dimensions of the loaded image
          const width = image.width;
          const height = image.height;
          const errors: string[] = [];

          if (minWidth && minWidth > width) {
            errors.push(
              `Please ensure that your image has a width of at least ${minWidth} pixels.`,
            );
          }

          if (minHeight && minHeight > height) {
            errors.push(
              `Please ensure that your image has a height of at least ${minHeight} pixels.`,
            );
          }

          resolve(errors);
        };

        image.onerror = reject;

        // Set the source of the Image object as the result of the FileReader
        image.src = fileData;
      } else {
        resolve([]);
      }
    });
  };

  const validateFile = async (file: File, fileType: FileType) => {
    if (fileType === FileType.PROJECT_DOCUMENT) {
      return [
        ...await validateFileSize(file, fromMb(25)),
        ...await validateMimeType(
          file,
          [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.oasis.opendocument.text',
            'application/rtf',
          ],
          'Only PDF and Microsoft Word files are allowed.',
        ),
      ];

    } else if (fileType === FileType.PROJECT_PHOTO) {
      return [
        ...await validateFileSize(file, fromMb(5)),
        ...await validateMimeType(
          file,
          [
            'image/jpeg',
            'image/png',
          ],
          'Only JPEG and PNG files are allowed.',
        ),
        ...await validateImageSize(file, 1000, 450),
      ];
    } else {
      return [];
    }
  };

  return {
    validateFile,
    validateMimeType,
  };
}

export function useFileService() {
  const nekstApi = useBackendApi();

  const fileValidator = useFileValidator();

  const createFileUploadLink = async (
    fileType: FileType,
    fileName: string,
  ) => {
    return await nekstApi.post('/files/uploadlinks', {
      fileType,
      fileName,
    }) as CreateUploadLinkResponse;
  };

  function getFileContentType(file: File): string {
    return file.type || 'application/octet-stream';
  }

  const doUploadFile = async (
    file: File,
    fileName: string,
    uploadLink: string,
    onProgress?: (value: number) => void,
  ) => {
    const fileData = await readFileAsArrayBuffer(file);

    const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
      const {
        loaded,
        total,
      } = progressEvent;
      if (total) {
        const percent = Math.floor((loaded * 100) / total);
        if (onProgress) {
          onProgress(percent);
        }
      }
    };

    await axios.put(
      uploadLink,
      fileData,
      {
        headers: {
          'Content-Type': getFileContentType(file),
          'Content-Disposition': `attachment; filename="${fileName}"`,
        },
        onUploadProgress,
      },
    );
  };

  const uploadFile = async (
    file: File,
    fileType: FileType,
    fileName: string,
    onUploadProgress?: (value: number) => void,
  ): Promise<FileDto> => {
    const validationErrors = await fileValidator.validateFile(file, fileType);

    if (validationErrors.length === 0) {
      const uploadLinkResponse = await createFileUploadLink(fileType, fileName);

      await doUploadFile(
        file,
        fileName,
        uploadLinkResponse.uploadLink,
        onUploadProgress,
      );

      return uploadLinkResponse.file;
    } else {
      throw new ValidationError({
        fileId: validationErrors,
      });
    }
  };

  return {
    createFileUploadLink,
    uploadFile,
  };
}
