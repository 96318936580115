import React from 'react';

import styles from '../AddTransactionParties.module.scss';
import UsedRoles from '../UsedRoles';
import ProjectAssignmentsList from './ProjectAssignmentsList';
import AddTeamMemberButton from './AddTeamMemberButton';
import useAssignedRolesHelper from '../assignedRolesHelper';

export interface CurrentTransactionPartiesFormProps {
  projectId: number,
}

export default function CurrentTransactionPartiesForm(props: CurrentTransactionPartiesFormProps) {
  const assignedRolesHelper = useAssignedRolesHelper(props.projectId);

  return (
    <div className={styles.block}>
      <div className={styles.title}>
        <AddTeamMemberButton projectId={props.projectId} />
        <h3>Current transaction parties</h3>
      </div>
      <UsedRoles assignedRolesHelper={assignedRolesHelper} />
      <ProjectAssignmentsList
        projectId={props.projectId}
        assignedRolesHelper={assignedRolesHelper}
      />
    </div>
  );
}
