import React, { useState } from 'react';

import { ButtonProps } from './types';
import { useNewUiTheme } from '../newUiTheme';

export function Button(props: ButtonProps) {
  const uiTheme = useNewUiTheme();

  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    if (loading) {
      return;
    }

    if (props.onClick) {
      setLoading(true);

      try {
        await props.onClick();
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <uiTheme.buttons.DefaultButtonLayout
      {...props}
      onClick={onClick}
      isLoading={loading}
      style={props.style}
    />
  );
}
