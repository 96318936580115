import { Crisp } from 'crisp-sdk-web';
import { useEffect, useState } from 'react';
import {
  useAuthenticationContext,
  useEventsSubscriber,
  LOGGED_OUT,
  useConfigurationContext
} from 'nekst-api';

export default function CrispChat() {
  const authenticationContext = useAuthenticationContext();

  const [isInit, setInit] = useState(false);

  const configuration = useConfigurationContext();

  const resetSession = () => {
    if (typeof window.$crisp.do === 'function' && Crisp?.session) {
      Crisp.session.reset(false);
    }
  };

  useEffect(() => {
    if (configuration.isChatEnabled) {
      Crisp.configure(configuration.crispWebsiteId, {
        locale: 'en',
        sessionMerge: true,
      });
      setInit(true);
    }

  }, [configuration.isChatEnabled]);

  useEventsSubscriber(
    'CrispChat',
    {
      [LOGGED_OUT]: () => {
        // @ts-ignore
        delete window.CRISP_TOKEN_ID;
        resetSession();
      },
    },
    [JSON.stringify(authenticationContext?.user), isInit],
  );

  useEffect(() => {
    if (isInit) {
      const user = authenticationContext?.user;

      if (user) {
        resetSession();
        Crisp.user.setEmail(user.profile.primaryEmail, user._crispEmailSignature);
        Crisp.user.setNickname(user.profile.name.fullName);
      }
    }
  }, [JSON.stringify(authenticationContext?.user), isInit]);

  return null;
}
