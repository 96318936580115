import axios, { AxiosRequestTransformer, AxiosResponse, Method } from 'axios';

export class RequestSenderError extends Error {
  public readonly status: number;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;

    Object.setPrototypeOf(this, RequestSenderError.prototype);
  }
}

export class RequestError extends RequestSenderError {
  public readonly response: any;

  constructor(message: string, status: number, response: any) {
    super(message, status);
    this.response = response;

    Object.setPrototypeOf(this, RequestError.prototype);
  }

  getResponseField<T>(fieldName: string, defaultValue: T): T {
    return this.response[fieldName] ?? defaultValue;
  }
}

export class AuthenticationError extends RequestError {
  constructor(response: any) {
    super(
      'Authentication error ocurred',
      401,
      response,
    );

    Object.setPrototypeOf(this, AuthenticationError.prototype);
  }
}

export class ValidationError extends RequestError {
  public readonly errors: Record<string, string[]>;

  constructor(errors: Record<string, string[]>) {
    super('Validation error', 422, errors);
    this.errors = errors;

    Object.setPrototypeOf(this, ValidationError.prototype);
  }
}

export class AccessDeniedError extends RequestError {
  constructor(response: any) {
    super(
      'Access denied',
      403,
      response,
    );

    Object.setPrototypeOf(this, AccessDeniedError.prototype);
  }
}

export class BadRequestError extends RequestError {
  private errorCode: string;

  constructor(response: any) {
    super(
      'Bad Request',
      400,
      response,
    );

    this.errorCode = response.errorCode;

    Object.setPrototypeOf(this, BadRequestError.prototype);
  }

  getErrorCode(): string {
    return this.errorCode;
  }
}

export class PaymentError extends RequestError {
  constructor(response: any) {
    super(
      'Payment error occurred',
      402,
      response,
    );

    Object.setPrototypeOf(this, PaymentError.prototype);
  }
}

export class ServerError extends RequestSenderError {

  constructor(message: string, status: number) {
    super(message, status);

    Object.setPrototypeOf(this, ServerError.prototype);
  }
}

export class ResourceNotFoundError extends RequestError {
  constructor(response: any) {
    super(
      'Resource not found',
      404,
      response,
    );

    Object.setPrototypeOf(this, ResourceNotFoundError.prototype);
  }
}

export class ResourceArchivedError extends RequestError {
  constructor(response: any) {
    super(
      'The resource you requested is no longer available',
      410,
      response,
    );

    Object.setPrototypeOf(this, ResourceArchivedError.prototype);
  }
}

interface RequestConfig {
  headers?: { [key: string]: string | number };
  responseType?: 'json' | 'blob';
}

export interface RequestSender {
  get: (_uri: string, _config?: Nullable<RequestConfig>) => Promise<any>;
  post: (_uri: string, _data?: Nullable<any>, _config?: Nullable<RequestConfig>) => Promise<any>,
  patch: (_uri: string, _data?: Nullable<any>, _config?: Nullable<RequestConfig>) => Promise<any>,
  delete: (_uri: string, _config?: Nullable<RequestConfig>) => Promise<any>,
  put: (_uri: string, _data?: Nullable<any>, _config?: Nullable<RequestConfig>) => Promise<any>,
}

export type Headers = Record<string, string | number | boolean>;

export interface RequestDataProvider {
  getHeaders: () => Headers,
}

export interface RequestSenderProps {
  baseUrl: string,
  dataProviders?: RequestDataProvider[]
}

const addZero = (value: number): string => {
  return value < 10 ? `0${value}` : value.toString();
};
const toDateTimeView = (date: Date) => {
  const month = addZero(date.getMonth() + 1);
  const day = addZero(date.getDate());
  const year = date.getFullYear();

  const hours = addZero(date.getHours());
  const minutes = addZero(date.getMinutes());

  return `${month}/${day}/${year} ${hours}:${minutes}`;
};

const doFormatDate = (data: any): any => {
  if (data instanceof Date) {
    // do your specific formatting here
    return toDateTimeView(data);
  }
  if (Array.isArray(data)) {
    return data.map((val) => doFormatDate(val));
  }
  if (typeof data === 'object' && data !== null) {
    return Object.fromEntries(Object.entries(data)
      .map(([key, val]) => [key, doFormatDate(val)]));
  }

  return data;
};

const dateTransformer: AxiosRequestTransformer = (data, headers) => {
  if (!headers || headers['Content-Type'] !== 'multipart/form-data') {
    return doFormatDate(data);
  } else {
    return data;
  }
};

function useRequestSender({
  baseUrl,
  dataProviders = undefined,
}: RequestSenderProps): RequestSender {
  const axiosApi = axios.create({
    baseURL: baseUrl,
    transformRequest: [dateTransformer]
      .concat(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  });

  const getDefaultHeaders = (): Headers => {
    return {
      'Content-Type': 'application/json',
    };
  };

  const getDataProvidersAndDefaultHeaders = (): Headers => {
    if (dataProviders !== undefined) {
      return dataProviders.reduce((prev, current) => {
        return {
          ...prev,
          ...current.getHeaders(),
        };
      }, getDefaultHeaders());
    } else {
      return getDefaultHeaders();
    }
  };

  const handleError = (e: any) => {
    if ((e as any).response) {
      const response: AxiosResponse = (e as any).response;
      const { status } = response;

      switch (true) {
        case status === 401:
          throw new AuthenticationError(response.data);
        case status === 402:
          throw new PaymentError(response.data);
        case status === 403:
          throw new AccessDeniedError(response.data);
        case status === 404:
          throw new ResourceNotFoundError(response.data);
        case status === 422:
          throw new ValidationError(response.data);
        case status === 410:
          throw new ResourceArchivedError(response.data);
        case status === 400:
          throw new BadRequestError(response.data);
        case status >= 400 && status <= 499:
          throw new RequestError('Request Error', status, response.data);
        default:
          throw new ServerError('Server Error occurred', status);
      }
    } else {
      throw new ServerError('Server Error occurred', 500);
    }
  };

  const baseMethod = async (
    uri: string,
    method: Method,
    data: Nullable<any>,
    config: Nullable<RequestConfig> = null,
  ) => {
    try {

      const requestConfig = {
        url: `${uri}`,
        method,
        data,
        headers: config ? {
          ...getDataProvidersAndDefaultHeaders(),
          ...config?.headers,
        } : getDataProvidersAndDefaultHeaders(),
        responseType: config?.responseType,
      };
      const result = await axiosApi.request(requestConfig);

      return result.data;
    } catch (e) {
      handleError(e);
    }
  };

  const doGet = async (uri: string, config: Nullable<RequestConfig> = null, attemptNumber = 0): Promise<any> => {
    const requestConfig = {
      headers: config ? {
        ...getDataProvidersAndDefaultHeaders(),
        ...config?.headers,
      } : getDataProvidersAndDefaultHeaders(),
      responseType: config?.responseType,
    };

    let requestUri;

    if (uri.startsWith('http')) {
      requestUri = uri;
    } else {
      requestUri = `${uri}`;
    }

    try {
      const result = await axiosApi.get(requestUri, requestConfig);

      return result.data;
    } catch (e) {
      try {
        handleError(e);
      } catch (error) {
        if (error instanceof ServerError && attemptNumber === 0) {
          await new Promise((resolve) => setTimeout(resolve, 100));
          return await doGet(uri, config, 1);
        } else {
          throw error;
        }
      }
    }
  };

  const get = async (uri: string, config: Nullable<RequestConfig> = null) => {

    const cacheKey = `requestSender-${uri}`;

    // the application may request the same resource from the different components
    // as GET requests are idempotent, we may return the same promise for all requests that run in
    // parallel
    (window as any).cache = (window as any).cache || {};
    if ((window as any).cache[cacheKey]) {
      return (window as any).cache[cacheKey];
    } else {
      try {
        const promise = doGet(uri, config);

        (window as any).cache[cacheKey] = promise;

        return await promise;
      } finally {
        delete (window as any).cache[cacheKey];
      }
    }
  };

  const post = async (
    uri: string,
    data: Nullable<any> = null,
    config: Nullable<RequestConfig> = null,
  ) => {
    return baseMethod(uri, 'POST', data, config);
  };

  const patch = async (
    uri: string,
    data: Nullable<any> = null,
    config: Nullable<RequestConfig> = null,
  ) => {
    return baseMethod(uri, 'PATCH', data, config);
  };

  const put = async (
    uri: string,
    data: Nullable<any> = null,
    config: Nullable<RequestConfig> = null,
  ) => {
    return baseMethod(uri, 'PUT', data, config);
  };

  const deleteMethod = async (uri: string, config: Nullable<RequestConfig> = null) => {
    return baseMethod(uri, 'DELETE', null, config);
  };

  return {
    post,
    put,
    get,
    patch,
    delete: deleteMethod,
  };
}

export { useRequestSender };
