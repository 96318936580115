import React from 'react';
import {
  Button,
  ButtonColor,
  ButtonSize,
  useFormContext,
  usePopupManager,
} from 'ui-builder';
import { ConfirmDeletePopup } from 'features/nekst-widgets';
import { PlanTask, usePlanTasksService } from 'nekst-api';

function DeletePlanTaskPopup(props: { taskId: number }) {

  const planTasksService = usePlanTasksService();

  const popupManager = usePopupManager();

  return (
    <ConfirmDeletePopup
      deleteFunc={async () => {
        await planTasksService.deleteTask(props.taskId);
        popupManager.closePopup();
      }}
      message="Are you sure you want to delete this task?"
    />
  );
}

export default function DeletePlanTaskButton() {
  const popupManager = usePopupManager();

  const formContext = useFormContext<PlanTask>();

  return (
    <Button
      text="Delete task"
      onClick={async () => {
        popupManager.openPopup(<DeletePlanTaskPopup taskId={formContext.data!.id} />, true);
      }}
      size={ButtonSize.BIG}
      color={ButtonColor.RED}
    />
  );
}
