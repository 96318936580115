import React from 'react';
import { useShowContext } from 'ui-builder';
import { Project, TotalTasks } from 'nekst-api';

import styles from './TotalTasksField.module.scss';
import { useProjectStatisticContextRequired } from 'features/tasks-forms';

function TotalTasksLayout(props: { value: TotalTasks }) {
  return (
    <div className={styles.field}>
      <span className={styles.active}>{props.value.active}</span>
      {' / '}
      <span className={styles.pastDue}>{props.value.pastDue}</span>
      {' / '}
      <span>{props.value.all}</span>
    </div>
  );
}

export function StatisticTotalTasks() {
  return (
    <TotalTasksLayout value={useProjectStatisticContextRequired().statistic!.totalTasks} />
  );
}

export default function TotalTasksField(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,react/no-unused-prop-types
  props: { label: string },
) {
  const showContext = useShowContext<Project>();

  const value = showContext.data!.statistic!.totalTasks;

  return (
    <TotalTasksLayout value={value} />
  );
}
