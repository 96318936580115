import React, {
  useState,
  useEffect, useRef, useMemo,
} from 'react';
import { useEventsSubscriber } from 'event-bus';

export const useAsyncError = () => {
  const [, setError] = React.useState();
  return React.useCallback(
    (e: any) => {
      setError(() => {
        throw e;
      });
    },
    [setError],
  );
};

export default function useDataLoader<DataType>(
  getDataMethod?: Nullable<() => Promise<DataType>>,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  setData: (data: DataType) => void,
  ...dependencies: any[]
) {
  const [loading, setLoading] = useState<boolean>(true);

  // -1 means that the data wasn't loaded before
  const [loadingDependency, setLoadingDependency] = useState<number>(-1);

  const [version, setVersion] = useState(0);

  const throwError = useAsyncError();

  const lastPromiseRef = useRef<Promise<DataType> | null>(null);

  const refreshData = () => {
    setLoadingDependency((prev) => prev + 1);
  };

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      let thisPromise: Promise<DataType> | null = null;
      try {
        if (getDataMethod) {
          thisPromise = getDataMethod();
          lastPromiseRef.current = thisPromise;
          const res = await thisPromise;

          if (thisPromise === lastPromiseRef.current) {
            setData?.(res);
            setVersion((prevState) => prevState + 1);
          }
        }

      } catch (e) {
        throwError(e);
      } finally {
        if (thisPromise === lastPromiseRef.current) {
          setLoading(false);
        }
      }

    };

    if (getDataMethod != null && loadingDependency >= 0) {
      getData();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingDependency]);

  return {
    loading,
    version,
    refreshData,
    setLoading,
  };
}

export function useDataLoaderNew<DataType>(
  getDataMethod: () => Promise<DataType>,
  refreshOptions?: {
    dependencies?: any[],
    events?: string[],
  },
) {

  const [data, setData] = useState<DataType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  // -1 means that the data wasn't loaded before
  const [loadingDependency, setLoadingDependency] = useState<number>(-1);

  const [version, setVersion] = useState(0);

  const throwError = useAsyncError();

  const lastPromiseRef = useRef<Promise<DataType> | null>(null);

  const refreshData = () => {
    setLoadingDependency((prev) => prev + 1);
  };

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refreshOptions?.dependencies || []);

  const eventsObject = useMemo(() => {
    const obj: Record<string, () => void> = {};
    for (const event of refreshOptions?.events || []) {
      obj[event] = () => {
        refreshData();
      };
    }
    return obj;
  }, [
    JSON.stringify(refreshOptions?.events),
  ]);

  useEventsSubscriber(
    'dataLoader',
    eventsObject,
  );

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      let thisPromise: Promise<DataType> | null = null;
      try {
        if (getDataMethod) {
          thisPromise = getDataMethod();
          lastPromiseRef.current = thisPromise;
          const res = await thisPromise;

          if (thisPromise === lastPromiseRef.current) {
            setData(res);
            setVersion((prevState) => prevState + 1);
          }
        }

      } catch (e) {
        throwError(e);
      } finally {
        if (thisPromise === lastPromiseRef.current) {
          setLoading(false);
        }
      }

    };

    if (getDataMethod != null && loadingDependency >= 0) {
      getData();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingDependency]);

  return {
    data,
    loading,
    version,
    refreshData,
    setLoading,
  };
}
