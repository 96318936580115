import { useEffect } from 'react';
import {
  useListContext,
  VisibilityState,
  useMassUpdateServiceRequired,
} from 'ui-builder';

export default function SelectAllUpdater() {
  const listContext = useListContext();

  const massUpdateService = useMassUpdateServiceRequired();

  useEffect(() => {
    if (!listContext.isLoading) {
      const data = listContext.data || [];

      const ids = data
        .map((item) => item.id)
        .filter((id: number) => listContext.getVisibilityState!(id) === VisibilityState.VISIBLE);

      massUpdateService.setIds(ids);
    }
  }, [listContext.version, JSON.stringify(listContext.filterValues)]);

  return null;
}
