import React, { ReactElement, useContext } from 'react';
import { GetOrderGroupFunc, ListFilterService, UpdateOrderFunc, VisibilityState } from './types';
import { useShowContext } from '../Show';

export type FilterFunc<DataType = Record<string, any>> = (
  data: DataType,
  allItems: DataType[],
  filter: any,
) => VisibilityState;

export type OrderingConfiguration<DataType> = {
  getOrderGroupFunc?: GetOrderGroupFunc<DataType>,
  updateOrderFunc: UpdateOrderFunc,
  isEnabled: boolean
  isItemDraggable?: (item: DataType) => boolean,
};

export interface ListContextData<DataType = Record<string, any>, FilterType = null> {
  data: DataType[]
  onFilterRefreshValue: number
  isLoading: boolean,
  version: number,
  refreshData: () => void,
  orderingConfig?: OrderingConfiguration<DataType>,
  addFilterFunc: (filterType: string, func: FilterFunc<DataType>) => void,
  applyFilter: () => void,
  setFilterValue: (filterName: string, filterValue: any, applyOnChange?: boolean) => void,
  getFilterValue: (filterName: string) => any,
  getVisibilityState: (id: number) => VisibilityState,
  hasVisibleItems: () => boolean,
  setFilters: (filters: () => ListFilterService[]) => void,
  filterValues: FilterType | null,
  setFilterValues: (value: FilterType) => void
    | ((value: ((prev: FilterType) => FilterType)) => void),
  loadNextPage: () => void,
  hasMorePages: () => boolean,
  updateItemsVersions: (ids: number[]) => void,
  getItemVersion: (id: number) => number,
  total: number,
  orderByField?: string,
  orderDirection?: string,
  sort: (field: string, direction: string) => void,
  _setLoading: (value: boolean) => void,
  _setData: (value: DataType[]) => void,
}

export const ListReactContext = React.createContext<any>({});

export function useListContext<DataType = Record<string, any>, FilterType = null>() {
  return useContext<Partial<ListContextData<DataType, FilterType>>>(ListReactContext);
}

export function useListContextRequired<DataType = Record<string, any>, FilterType = null>() {
  const result = useContext<ListContextData<DataType, FilterType>>(ListReactContext);

  if (!result) {
    throw new Error('element must be used within ListContext only');
  }

  return result;
}

export function useRowVisibilityHelper() {
  const showContext = useShowContext();
  const listContext = useListContext();

  const getVisibilityState = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { id } = showContext.data!;

    return listContext.getVisibilityState?.(id);
  };

  const isDisabled = () => {
    return getVisibilityState() === VisibilityState.DISABLED;
  };

  return {
    getVisibilityState,
    isDisabled,
  };
}

export function ListContext(props: {
  data: Record<string, any>[],
  children: ListOrSingle<ReactElement | false | undefined>
}) {
  return (
    <ListReactContext.Provider value={props.data}>
      {props.children}
    </ListReactContext.Provider>
  );
}

