import useProjectsApi from '../projects/projectsApi';

export default function useUnsubscribeService() {

  const nekstApi = useProjectsApi();

  const unsubscribe = async (token: string) => {
    await nekstApi.delete(`/dailymail/unsubscribe/${token}`);
  };

  return {
    unsubscribe,
  };
}
