import React from 'react';
import { SmsTaskPreviewLayoutProps } from '../types';

import styles from './SmsPreview.module.scss';

import { PhoneNumberView } from 'ui-builder';
import { SmsOptInStatus } from '../../SmsOptInStatus';

export default function SmsTaskPreviewLayoutWeb(props: SmsTaskPreviewLayoutProps) {

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {props.previews?.map((preview) => (
        <div key={`sms-preview-${preview.recipient.id}`} className={styles.recipient}>
          <div
            className={styles.preview}
          >
            <b>To:</b>
            {' '}
            <PhoneNumberView
              number={preview.recipient._phoneNumberForSms?.number}
              noNumberText="no phone number"
            />
            {' '}
            {`(${preview.recipient.name.fullName})`}
            <br />
            <b>Message:</b>
            {' '}
            {preview.preview}
          </div>
          <SmsOptInStatus recipient={preview.recipient} />
        </div>
      ))}
    </>
  );
}
