import React, { ReactElement, useContext, useMemo } from 'react';
import {
  AvatarLayoutProps,
  ButtonsTheme,
  CheckboxGroupLayoutProps,
  CheckboxGroupWithSelectAllLayoutProps,
  CommonThemeType,
  DateInputLayoutProps,
  DropdownLayoutProps,
  ErrorsWrapperLayoutProps,
  FieldsTheme,
  FormRowsGroupLayoutProps, ItemsListLayoutProps,
  NewThemeOverrider, OptionSelectionButtonLayoutProps,
  PasswordLayoutProps, PriceLayoutProps, RadioButtonLayoutProps, RadioButtonsGroupLayoutProps,
  ShowLayoutProps,
  TextLayoutProps,
  TimeInputLayoutProps,
  useNewUiTheme,
} from 'ui-builder';

import { TextAreaLayoutProps } from './form/input/layout/TextAreaLayout';
import { TableDataGridWrapperProps } from './list/layout/TableDataGridWrapper';
import { TableDataGridHeadProps } from './list/layout/TableDataGridHead';
import { TableDataGridRowProps } from './list/layout/TableDataGridRow';
import { BaseInputLayoutProps, FormLayoutProps, FormRowLayoutProps } from './form';
import { CheckboxLayoutProps } from './form/input/Checkbox';

export interface InputsTheme {
  ErrorsWrapperLayout: ReactComponent<ErrorsWrapperLayoutProps>,
  TextLayout: ReactComponent<TextLayoutProps>,
  PasswordLayout: ReactComponent<PasswordLayoutProps>,
  BaseDropdownLayout: ReactComponent<DropdownLayoutProps>,
  CheckboxLayout: ReactComponent<CheckboxLayoutProps>,
  CheckboxGroupLayout: ReactComponent<CheckboxGroupLayoutProps<any>>
  CheckboxGroupWithSelectAllLayout: ReactComponent<CheckboxGroupWithSelectAllLayoutProps>
  RadioButtonLayout: ReactComponent<RadioButtonLayoutProps>,
  RadioButtonsGroupLayout: ReactComponent<RadioButtonsGroupLayoutProps<string | number>>,
  TextAreaLayout: ReactComponent<TextAreaLayoutProps>,
  DateInputLayout: ReactComponent<DateInputLayoutProps>,
  TimeInputLayout: ReactComponent<TimeInputLayoutProps>,
  PriceLayout: ReactComponent<PriceLayoutProps>,
}

export interface ListsTheme {
  dataGrid: {
    WrapperLayout: ReactComponent<TableDataGridWrapperProps>,
    HeadLayout: ReactComponent<TableDataGridHeadProps>,
    RowLayout: ReactComponent<TableDataGridRowProps>,
  };
}

export interface FormsTheme {
  FormRowLayout: ReactComponent<FormRowLayoutProps>,
  FormRowsGroupLayout: ReactComponent<FormRowsGroupLayoutProps>
  FormLayout: ReactComponent<FormLayoutProps>,
  BaseInputLayout: ReactComponent<BaseInputLayoutProps>,
}

export interface ShowTheme {
  ShowLayout: ReactComponent<ShowLayoutProps>,
}

export interface WidgetsTheme {
  AvatarLayout: ReactComponent<AvatarLayoutProps>;
  ItemsListLayout: ReactComponent<ItemsListLayoutProps>
  OptionSelectionButtonLayout: ReactComponent<OptionSelectionButtonLayoutProps>,
}

export interface UiTheme {
  inputs: InputsTheme,
  buttons: ButtonsTheme,
  lists: ListsTheme,
  forms: FormsTheme,
  show: ShowTheme,
  common: CommonThemeType,
  fields: FieldsTheme,
  widgets: WidgetsTheme,
}

const UiThemeContext = React.createContext<UiTheme | undefined>(undefined);

const UiThemeOverriderContext = React.createContext<{
  inputs?: Partial<InputsTheme>,
  buttons?: Partial<ButtonsTheme>,
  lists?: Partial<ListsTheme>,
  forms?: Partial<FormsTheme>,
  show?: Partial<ShowTheme>,
  fields?: Partial<FieldsTheme>,
}>({});

export interface UiThemeProps {
  children: ListOrSingle<ReactElement>;
  theme: UiTheme;
}

export function UiThemeWrapper(props: UiThemeProps) {
  return (
    <UiThemeContext.Provider value={props.theme}>
      {props.children}
    </UiThemeContext.Provider>
  );
}

export interface UiThemeOverriderProps {
  children: ListOrSingle<ReactElement>,
  theme: {
    inputs?: Partial<InputsTheme>,
    buttons?: Partial<ButtonsTheme>,
    lists?: Partial<ListsTheme>,
    forms?: Partial<FormsTheme>,
    show?: Partial<ShowTheme>
  },
}

function mergeObjects(obj1?: object, obj2?: object) {
  const o1 = obj1 !== undefined ? obj1 : {};
  const o2 = obj2 !== undefined ? obj2 : {};

  return {
    ...o1,
    ...o2,
  };
}

export function UiThemeOverrider(props: UiThemeOverriderProps) {
  const previousOverrider = useContext(UiThemeOverriderContext);

  const newValue = useMemo(() => ({
    inputs: mergeObjects(previousOverrider.inputs, props.theme.inputs),
    forms: mergeObjects(previousOverrider.forms, props.theme.forms),
    buttons: mergeObjects(previousOverrider.buttons, props.theme.buttons),
    lists: mergeObjects(previousOverrider.lists, props.theme.lists),
    show: mergeObjects(previousOverrider.show, props.theme.show),
  }), []);

  return (
    <UiThemeOverriderContext.Provider value={newValue}>
      <NewThemeOverrider
        theme={{
          forms: newValue.forms,
          buttons: newValue.buttons,
          inputs: newValue.inputs,
        }}
      >
        {props.children}
      </NewThemeOverrider>
    </UiThemeOverriderContext.Provider>
  );
}

export function UiTheme(
  props: {
    value: UiTheme,
    children: ListOrSingle<ReactElement>,
  },
) {
  return (
    <UiThemeContext.Provider value={props.value}>
      {props.children}
    </UiThemeContext.Provider>
  );
}

export default function useUiTheme(): UiTheme {
  const mainContext = useContext(UiThemeContext);
  const overrider = useContext(UiThemeOverriderContext);

  const newContext = useNewUiTheme();

  if (!mainContext) {
    throw new Error('application theme is not set');
  }

  return {
    inputs: overrider.inputs ? {
      ...mainContext.inputs,
      ...newContext.inputs,
      ...overrider.inputs,
    } : {
      ...mainContext.inputs,
      ...newContext.inputs,
    },
    buttons: {
      ...mainContext.buttons || {},
      ...newContext.buttons || {},
      ...overrider.buttons || {},
    },
    lists: overrider.lists ? {
      ...mainContext.lists || {},
      ...overrider.lists,
    } : mainContext.lists,
    forms: {
      ...newContext.forms,
      ...overrider.forms,
      BaseInputLayout: mainContext.forms.BaseInputLayout,
    },
    show: overrider.show ? {
      ...mainContext.show,
      ...overrider.show,
    } : mainContext.show,
    common: newContext.common,
    fields: newContext.fields,
    widgets: newContext.widgets,
  };
}
