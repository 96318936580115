import useProjectsApi from '../../../projects/projectsApi';
import useUserSessionStorage from '../../../login/userSessionStorage';

export default function useImpersonateUsersService() {
  const nekstApi = useProjectsApi();

  const userSessionStorage = useUserSessionStorage();

  const impersonateUser = async (userId: number) => {
    const token = await nekstApi.post('/users/impersonate', {
      userId,
    }) as { token: string };

    userSessionStorage.saveImpersonationToken(token.token);
  };

  return {
    impersonateUser,
  };
}
