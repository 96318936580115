import React, { ReactElement } from 'react';
import { SubmitButton } from 'ui-builder';
import Left from '../../../shared/web/Left';
import Right from '../../../shared/web/Right';
import CreateDependentTaskButton from './CreateDependentTaskButton';
import DeletePlanTaskButton from './DeletePlanTaskButton';
import { PlanPermission, useAuthorizationChecker } from 'authorization-scope';

export default function UpdateTaskButtons() {
  const { isGranted } = useAuthorizationChecker();

  let buttons: ReactElement[];
  if (isGranted(PlanPermission.CREATE_TASK)) {
    buttons = [
      <Left key="left-buttons">
        <SubmitButton text="Update task" key="submit" />
        <CreateDependentTaskButton key="dependent-task" />
      </Left>,
      <Right key="right-buttons">
        <DeletePlanTaskButton />
      </Right>,
    ];
  } else {
    buttons = [
      <SubmitButton text="Update task" key="submit" />,
      <DeletePlanTaskButton key="delete" />,
    ];
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{buttons}</>
  );
}
