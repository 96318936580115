import React from 'react';
import { BaseInputLayoutProps, BaseInputProps } from './types';
import { useInputHelper } from './inputHelper';
import { useNewUiTheme } from '../../newUiTheme';

export interface TimeInputProps extends BaseInputProps<Date> {

}

export interface TimeInputLayoutProps extends BaseInputLayoutProps<Date> {

}

export function TimeInput(props: TimeInputProps) {
  const uiTheme = useNewUiTheme();
  const helper = useInputHelper(props);
  return (
    <uiTheme.inputs.TimeInputLayout
      {...helper.getBaseInputLayoutProps()}
    />
  );
}
