import React, {
  useCallback,
  useEffect,
  useRef, useState,
} from 'react';
import { useLoadMorePaginationContext } from '../LoadMorePaginationList';

import LoadingAnimation from '../../../LoadingAnimation';

interface Props {
  children: JSX.Element | JSX.Element[],
}

function LoadingWidget() {
  const loader = useRef(null);

  const paginationContext = useLoadMorePaginationContext();

  const [observer, setObserver] = useState<IntersectionObserver | null>(null);

  const handleObserver = useCallback((entries: IntersectionObserverEntry[]) => {
    const target = entries[0];
    if (target.isIntersecting) {
      paginationContext.loadNextPage();
    }
  }, [paginationContext.nextPageToken]);

  useEffect(() => {
    if (loader.current) {
      const option = {
        root: null,
        rootMargin: '20px',
        threshold: 0,
      };

      if (observer) {
        observer.unobserve(loader.current);
        observer.disconnect();
      }

      const newObserver = new IntersectionObserver(handleObserver, option);
      newObserver.observe(loader.current);
      setObserver(newObserver);
    }
  }, [handleObserver]);

  return (
    <>
      <div ref={loader} />
      {paginationContext.hasMoreItems && (<LoadingAnimation />)}
    </>
  );
}

export default function InfiniteScrollWrapper(props: Props) {

  return (
    <div>
      {props.children}
      <LoadingWidget />
    </div>
  );
}
