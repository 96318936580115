import { useNavigate, useParams } from 'react-router-dom';
import { useProjectPlanService } from 'nekst-api';
import useFeatureToggleService from 'feature-toggle';

export interface LaunchProjectNavigator {
  openCreateProjectPage: () => void,
  openUpdateProjectPage: () => void,
  openKeyDateTasksPage: (projectId?: number) => void,
  openAllTasksPage: (projectId?: number) => void,
  openTransactionPartiesPage: (projectId?: number) => void,
  openProjectPage: (projectId?: number, afterLaunch?: boolean) => void,
  openGroupsPage: (projectId?: number) => void,
  openSecondStep: (projectId?: number) => Promise<void>,
}

export default function useLaunchProjectNavigator(): LaunchProjectNavigator {
  const navigator = useNavigate();

  const params = useParams();

  const projectPlanService = useProjectPlanService();
  const featureToggleService = useFeatureToggleService();

  const getProjectId = (projectId?: number) => {
    return projectId || parseInt(params.id!, 10);
  };

  const openCreateProjectPage = (): void => {
    navigator('/transactions/new');
  };

  const openUpdateProjectPage = (projectId?: number): void => {
    navigator(`/transactions/${getProjectId(projectId)}/launch/update`);
  };

  const openKeyDateTasksPage = (projectId?: number) => {
    navigator(`/transactions/${getProjectId(projectId)}/launch/keydates`);
  };

  const openAllTasksPage = (projectId?: number) => {
    navigator(`/transactions/${getProjectId(projectId)}/launch/alltasks`);
  };

  const openTransactionPartiesPage = (projectId?: number) => {
    navigator(`/transactions/${getProjectId(projectId)}/launch/parties`);
  };

  const openProjectPage = (projectId?: number, afterLaunch?: boolean) => {
    let uri = `/transactions/${getProjectId(projectId)}`;
    if (afterLaunch) {
      uri += '?afterLaunch=1';
    }
    navigator(uri);
  };

  const openGroupsPage = (projectId?: number) => {
    navigator(`/transactions/${getProjectId(projectId)}/launch/groups`);
  }

  const openSecondStep = async (projectId?: number) => {
    const plan = await projectPlanService.getPlanByProjectId(getProjectId(projectId));
    if (featureToggleService.isTasksGroupsEnabled() && plan.hasGroups) {
      openGroupsPage(projectId);
    } else {
      openKeyDateTasksPage(projectId);
    }
  }

  return {
    openCreateProjectPage,
    openUpdateProjectPage,
    openKeyDateTasksPage,
    openAllTasksPage,
    openTransactionPartiesPage,
    openProjectPage,
    openGroupsPage,
    openSecondStep,
  };
}
