import React from 'react';
import PageLink from './PageLink';
import { ProjectData } from 'nekst-api';

interface Props {
  project: ProjectData;
}

export default function ProjectLink(props: Props) {
  return (
    <PageLink
      text={props.project.name}
      isDeleted={props.project.isDeleted}
      action={
        () => {
          // ignored
        }
      }
    />
  );
}
