import React from 'react';
import {
  Button, ButtonSize,
  List,
  TextField,
  usePopupManager,
  useShowContextRequired
} from 'ui-builder';
import { useAiTrainingEntryContext } from './ViewParseResultWidget';
import GridLayout from '../../../../shared/uibuilder/list/GridLayout';
import {
  ConfirmDeletePopup,
  IconButton, OpenFormIconButton,
  IconType,
  usePopupFormManager
} from 'features/nekst-widgets';
import { CreateTaskParseResultForm } from './TaskParseResult';
import Cell from '../../../../shared/uibuilder/list/Cell';
import UpdateTaskParseResultForm from './TaskParseResult/UpdateTaskParseResultForm';
import { EditContextButton } from './PreviewDocument';
import InnerBlock from '../../../../shared/web/layout/InnerBlock';
import { PromptParseResult } from 'nekst-api';

function AddParsedTaskLink() {
  const popupFormManager = usePopupFormManager();

  return (
    <Button
      onClick={() => {
        popupFormManager.openForm(<CreateTaskParseResultForm />);
      }}
      text="+ Add New Task"
      isFlat
      isFilled
      size={ButtonSize.SMALL}
    />
  );
}

function DeleteTaskResultButton() {
  const entryContext = useAiTrainingEntryContext();

  const taskContext = useShowContextRequired();

  const popupManager = usePopupManager();

  return (
    <IconButton
      onClick={() => {
        popupManager.openPopup(
          <ConfirmDeletePopup
            deleteFunc={async () => {
              entryContext.deleteTask(taskContext.data!.id);
            }}
            message={`Are you sure you want to delete this result?`}
          />
        );
      }}
      type={IconType.X}
    />
  );
}

function UpdateTasksContextButton() {
  const entryContext = useAiTrainingEntryContext();

  const showContext = useShowContextRequired<PromptParseResult>();


  return (
    <EditContextButton
      fileText={entryContext.entry.fileText}
      usedRows={showContext.data!.context}
      onContextUpdated={(newValue) => {
        entryContext.updateTask(
          showContext.data!.id,
          {
            ...showContext.data!,
            context: newValue,
          }
        )
      }}
    />
  );
}

function UpdateTaskResultButton() {
  const taskContext = useShowContextRequired<PromptParseResult>();

  return (
    <OpenFormIconButton
      form={<UpdateTaskParseResultForm data={taskContext.data!} />}
    />
  );
}

export function ParsedTasksList() {

  const context = useAiTrainingEntryContext();

  const prompts = context.entry.prompts;

  const data = prompts.map((value) => {
    return {
      ...value,
      _title: {
        prompt: value.prompt,
        result: value.value,
      }
    };
  });

  return (
    <InnerBlock headerSize={4} headerRight={<AddParsedTaskLink />} title="Prompts Results">
      <List getDataFunc={async () => data} dependencies={[JSON.stringify(context.entry.prompts)]}>
        <GridLayout>
          <TextField source="id" label="ID" />
          <TextField source="prompt" label="Prompt" />
          <TextField source="type" label="Value Type" />
          <TextField source="value" label="Value" />
          <Cell label="Actions">
            <UpdateTaskResultButton />
            <UpdateTasksContextButton />
            <DeleteTaskResultButton />
          </Cell>
        </GridLayout>
      </List>
    </InnerBlock>

  );

}
