import React from 'react';
import {
  ProjectDetailsField,
  ProjectType,
  UpdateProjectDetailsFieldRequest,
  useProjectDetailsFieldsService
} from 'nekst-api';
import {
  AfterSubmitFunc,
  Dropdown,
  FormTemplate,
  TextInput,
  useShowContext
} from 'ui-builder';
import { ProjectDetailsFieldTypeFormField } from './ProjectDetailsFieldTypeDropdown';
import { OpenFormIconButton } from 'features/nekst-widgets';
import { TextFormField } from '../../../shared/uibuilder/form/input/Text';

function HiddenStatusDropdown(props: {
  source: string,
  label: string
}) {
  return (
    <Dropdown
      options={[
        {
          value: 0,
          label: 'Show',
        },
        {
          value: 1,
          label: 'Hide',
        },
      ]}
      source={props.source}
      label={props.label}
    />
  );
}

function UpdatePreexistingFieldForm(
  props: {
    projectType: ProjectType,
    data: ProjectDetailsField,
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {
  const service = useProjectDetailsFieldsService();

  return (
    <FormTemplate<{ prompt: string, isHidden: number }, ProjectDetailsField>
      title="Update Detail Box"
      initialData={{
        isHidden: props.data.isHidden ? 1 : 0,
        prompt: props.data.prompt,
      }}
      afterSubmitFunc={props.afterSubmitFunc}
      submitFormFunc={async (request: { prompt: string, isHidden: number }) => {
        return service.updatePredefinedField(props.projectType, props.data.id, {
          isHidden: !!request.isHidden,
          prompt: request.prompt,
        });
      }}
      validationSchema={{
        prompt: {
          type: 'string',
          constraints: {
            maxLength: 3000,
          },
        },
        isHidden: {
          type: 'int',
          constraints: {
            required: true,
          },
        },
      }}
    >
      <TextFormField source="name" label="Name" value={props.data.name} />
      <ProjectDetailsFieldTypeFormField source="fieldType" label="Field Type"
                                        value={props.data.fieldType} />
      <HiddenStatusDropdown source="isHidden" label="Visibility" />
    </FormTemplate>
  );
}

function UpdateCustomFieldForm(
  props: {
    projectType: ProjectType,
    data: ProjectDetailsField,
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {
  const service = useProjectDetailsFieldsService();

  return (
    <FormTemplate<UpdateProjectDetailsFieldRequest, ProjectDetailsField>
      title="Update Detail Box"
      initialData={props.data}
      afterSubmitFunc={props.afterSubmitFunc}
      submitFormFunc={
        (request: UpdateProjectDetailsFieldRequest) => service.updateField(
          props.projectType,
          props.data.id,
          request,
        )
      }
    >
      <TextInput source="name" label="Name" />
      <ProjectDetailsFieldTypeFormField source="fieldType" label="Field Type" />
    </FormTemplate>
  );
}

function UpdateProjectDetailsFieldForm(
  props: {
    projectType: ProjectType,
    data: ProjectDetailsField,
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {
  if (props.data.isPreexisting) {
    return (
      <UpdatePreexistingFieldForm
        projectType={props.projectType}
        data={props.data}
        afterSubmitFunc={props.afterSubmitFunc}
      />
    );
  } else {
    return (
      <UpdateCustomFieldForm
        projectType={props.projectType}
        data={props.data}
        afterSubmitFunc={props.afterSubmitFunc}
      />
    );
  }
}

export default function UpdateProjectDetailsFieldButton(
  props: {
    projectType: ProjectType,
  },
) {
  const showContext = useShowContext<ProjectDetailsField>();

  if (showContext.data) {
    return (
      <OpenFormIconButton
        form={<UpdateProjectDetailsFieldForm
          projectType={props.projectType}
          data={showContext.data!}
        />}
        title="Update Field"
      />
    );
  } else {
    return null;
  }

}
