import React from 'react';
import { Navigate } from 'react-router-dom';

export default function OldProjectRouteRedirector() {
  const currentUri = window.location.pathname;

  if (currentUri.startsWith('/properties')) {
    let newUri = currentUri.replace('/properties', '/transactions');

    if (window.location.search) {
      newUri = newUri + window.location.search;
    }

    return (
      <Navigate replace to={newUri} />
    )
  } else {
    return null;
  }
}
