import React, { ReactElement } from 'react';
import { StandardTask, useProjectTasksService } from 'nekst-api';
import {
  AfterSubmitFunc,
  CheckboxInput,
  FormTemplate,
  RelativeRow,
  TextArea,
  TextInput,
} from 'ui-builder';
import {
  DueDateInput,
  ProjectLinkedDetailFieldDropdown,
  ProjectParentTaskRelationInput
} from 'features/tasks-forms';
import Row3x3x6x from '../../../../../shared/web/uitheme/form/Row3x3x6x';
import Separator from '../../../../../shared/web/uitheme/form/Separator';
import TaskAssignmentsInput from './TaskAssignmentsInput';
import Row8x4x from '../../../../../shared/web/uitheme/form/Row8x4x';
import TaskCommentsListInput from '../comments/TaskCommentsListInput';
import ProjectDropdown from '../ProjectDropdown';
import { ProjectIdContext } from 'features/nekst-widgets';

export interface BaseStandardTaskFormProps {
  submitFormFunc: (data: StandardTask) => Promise<StandardTask>;
  afterSubmitFunc?: AfterSubmitFunc,
  data?: StandardTask;
  title: string | ReactElement;
  buttons: ReactElement;
  initialData?: StandardTask;
  isDisabled?: boolean | ((data: StandardTask) => boolean)
  showProjectDropdown?: boolean
  children?: ReactElement,
}

export default function BaseStandardTaskForm(props: BaseStandardTaskFormProps) {
  const projectTasksService = useProjectTasksService();

  const isDisabled = props.isDisabled ?? false;

  const showProjectDropdown = props.showProjectDropdown || false;

  return (
    <ProjectIdContext projectId={props.initialData?.projectId || 0}>
      <FormTemplate<StandardTask>
        title={props.title}
        submitFormFunc={props.submitFormFunc}
        afterSubmitFunc={props.afterSubmitFunc}
        getDataFunc={props.data ? async () => props.data! : undefined}
        buttons={props.buttons}
        initialData={props.initialData}
        isDisabled={isDisabled}
        validationSchema={projectTasksService.getStandardTaskValidationSchema()}
      >
        {showProjectDropdown && (<ProjectDropdown className="form-row" source="projectId" />)}
        <TextInput source="name" label="Task Name" />
        <Row3x3x6x>
          <CheckboxInput source="isKeyDate" label="Key Date" />
          <CheckboxInput source="isPrivate" label="Private" />
        </Row3x3x6x>
        <RelativeRow
          weights={[7, 5]}
          isVisible={(data) => !data.parentTaskRelation || data.dueDate}
        >
          <DueDateInput />
          <ProjectLinkedDetailFieldDropdown label="Link To Detail Box" />
        </RelativeRow>
        <Row8x4x isVisible={(data) => !!data.parentTaskRelation && !data.dueDate}>
          <ProjectParentTaskRelationInput source="parentTaskRelation" label="Due" />
          <ProjectLinkedDetailFieldDropdown label="Link To Detail Box" />
        </Row8x4x>
        <Separator />
        <TextArea source="description" label="Task Description" />
        <Separator />
        <TaskAssignmentsInput source="assignments" label="Transaction Parties" />
        <Separator isVisible={(data) => data.id} />
        <TaskCommentsListInput isVisible={(data) => data.id} />
        {props.children || null}
      </FormTemplate>
    </ProjectIdContext>
  );
}
