import { TeamRole } from 'nekst-api';
import useProjectsApi from '../../../projects/projectsApi';
import usePeopleService, { PersonProfile } from '../../peopleService';
import { ValidationSchema } from '../../../shared/uibuilder/validation';

interface SetPasswordRequest {
  password: string,
}

export interface CompleteAccountRequest {
  profile: PersonProfile,
  setPasswordRequest: SetPasswordRequest,
}

type TokenResponse = {
  token: string
}

export default function useCompleteAccountService() {
  const contactsApi = useProjectsApi();

  const peopleService = usePeopleService();

  const completeAccount = async (request: CompleteAccountRequest) => {
    const result = await contactsApi.put('/me/complete', request) as TokenResponse;
    return result.token;
  };

  const getCompleteAccountValidationSchema = (teamRole: TeamRole) => {
    return {
      ...peopleService.getUpdateValidationSchema(teamRole),
      setPasswordRequest: {
        type: 'object',
        constraints: {
          required: true,
        },
        properties: {
          password: {
            type: 'string',
            constraints: {
              required: true,
            },
          },
          password2: {
            type: 'string',
            constraints: {
              required: true,
              match: {
                value: 'password',
                message: 'The passwords you entered do not match.',
              },
            },
          },
        },
      },
      _applyRules: {
        type: 'string',
        constraints: {
          required: true,
        },
      },
    } as ValidationSchema;
  };

  return {
    completeAccount,
    getCompleteAccountValidationSchema,
  };
}
