import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const isMonitoringEnabled = process.env.REACT_APP_SENTRY_ENABLED;

if (isMonitoringEnabled) {
  Sentry.init({
    dsn: 'https://af276a124badcc6913d3a226d509ce20@o4507509414297600.ingest.us.sentry.io/4507509604810752',
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 0.1,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
      /^\//,
      /^https:\/\/api\.nekst\.com/,
      /^http:\/\/127\.0\.0\.1:8080/,
      /^https:\/\/new\\-stg1\.nekstassistant\.com\//
    ],

    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: `frontend@${process.env.REACT_APP_VERSION}`,
  });
}
