import React, { ReactElement, useState } from 'react';
import { BaseInputLayoutProps, BaseInputProps, Option } from './types';
import { useInputHelper } from './inputHelper';
import { useNewUiTheme } from '../../newUiTheme';
import { ViewStyle } from 'react-native';

export interface MultiSelectDropdownProps<Type> extends BaseInputProps<Type[]> {
  options: Option<Type>[],
  notEditableIds?: Type[]
  onOptionCreate?: (value: string) => Promise<Option<Type>>,
  mobile_getValueWidgetFunc?: (item: Type) => ReactElement | null,
  max?: number
}

export interface MultiSelectDropdownLayoutProps<ValueType> extends BaseInputLayoutProps<ValueType[]> {
  options: Option<ValueType>[]
  notEditableIds?: ValueType[]
  addItemFunc: (id: ValueType) => void,
  removeItemFunc: (id: ValueType) => void,
  optionCreateFunc?: (value: string) => Promise<Option<ValueType>>,
  isLoading: boolean,
  max?: number
  getValueWidgetFunc?: (item: ValueType) => ReactElement | null,
  valuesBlockStyle?: ViewStyle,
}

export function MultiSelectDropdown<ValueType>(
  props: MultiSelectDropdownProps<ValueType>
) {
  const helper = useInputHelper<ValueType[]>(props);

  const currentValue = helper.getValue() || [];

  const [newOptions, setNewOptions] = useState<Option<ValueType>[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const options = [
    ...props.options,
    ...newOptions,
  ];

  const addItem = (id: ValueType) => {
    const newValue = [
      ...currentValue,
    ];

    newValue.push(id);
    helper.getOnChangeCallback()({
      target: {
        value: newValue,
      },
    });
  };

  const removeItem = (id: ValueType) => {
    const newValue = currentValue
      .filter((v) => v !== id);

    helper.getOnChangeCallback()({
      target: {
        value: newValue,
      },
    });
  };


  const hint = props.max ? `Maximum ${props.max} options` : props.hint;

  const onOptionCreate = props.onOptionCreate ? async (value: string) => {
    setIsLoading(true);

    try {
      const newOption = await props.onOptionCreate!(value);

      setNewOptions([
        ...newOptions,
        newOption,
      ]);

      addItem(newOption.value);

      return newOption;
    } finally {
      setIsLoading(false);
    }
  } : undefined;

  const uiTheme = useNewUiTheme();

  return (
    <uiTheme.inputs.MultiSelectDropdownLayout
      {...helper.getBaseInputLayoutProps()}
      options={options}
      notEditableIds={props.notEditableIds}
      addItemFunc={addItem}
      removeItemFunc={removeItem}
      isLoading={isLoading}
      optionCreateFunc={onOptionCreate}
      hint={hint}
      max={props.max}
      getValueWidgetFunc={props.mobile_getValueWidgetFunc}
      isDisabled={helper.getIsDisabled() || props.options.length === 0}
    />
  );
}
