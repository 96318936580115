import { useBackendApi } from './backendApi';

export enum CheckTokenResult {
  OK = 'OK',
  NOT_EXIST = 'NOT_EXIST',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
}

export function useResetPasswordService() {

  const nekstApi = useBackendApi();
  const checkToken = async (token: string) => {
    const data = await nekstApi.get(`/resetpassword/${token}`) as { result: CheckTokenResult };

    return data.result;
  };

  const requestReset = async (username: string) => {
    await nekstApi.post('/resetpassword', {
      username,
    });
  };

  const resetPassword = async (token: string, newPassword: string) => {
    await nekstApi.post(`/resetpassword/${token}`, {
      newPassword,
    });
  };

  return {
    checkToken,
    requestReset,
    resetPassword,
  };
}
