import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import InformationBox from '../../shared/web/layout/InformationBox';
import MainBlock from '../../shared/web/layout/MainBlock';
import CreatePlanButton from '../Create/CreatePlanButton';
import ChoosePlanDropdown from './ChoosePlanDropdown';
import ViewPlan from '../view/ViewPlan';
import HttpErrorsBoundary from '../../shared/uibuilder/HttpErrorsBoundary';
import AccessChecker from '../../shared/authorization/AccessChecker';
import PlanFilterContainer from '../tasks/List/Filter/PlanFilterContainer';
import { Breadcrumb, BreadcrumbsWrapper, } from '../../shared/web/layout/Breadcrumbs';
import { TeamPermission } from 'nekst-api';
import PageHeaderWithIcon, {
  PageIconType
} from '../../shared/widgets/PageTitle/PageHeaderWithIcon';

export function PlansListBreadcrumbs(
  props: {
    children?: ReactElement
  },
) {
  return (
    <BreadcrumbsWrapper>
      <Breadcrumb text="Plans" href="/plans">
        {props.children}
      </Breadcrumb>
    </BreadcrumbsWrapper>
  );
}

export default function List() {
  const params = useParams();

  const isPlanPage = params.id !== undefined;

  const columnContent = isPlanPage ? <PlanFilterContainer /> : <div>&nbsp;</div>;

  return (
    <AccessChecker permissionToCheck={TeamPermission.READ_PLANS}>
      <HttpErrorsBoundary>
        {!params.id && (<PlansListBreadcrumbs />)}
        <InformationBox text="Select an existing plan from the dropdown or create a new one." />
        <MainBlock
          title={
            <PageHeaderWithIcon iconType={PageIconType.PLANS}>
              Action Plans
            </PageHeaderWithIcon>
          }
          columnContent={columnContent}
          headerRight={<CreatePlanButton key="create" />}
        >
          <div
            className="form dark"
            style={{
              minHeight: '500px',
            }}
          >
            <div style={{ width: '80%' }}>
              <ChoosePlanDropdown value={(params.id && parseInt(params.id, 10)) || undefined} />
            </div>
            <div style={{ marginTop: '40px' }}>
              {params.id !== undefined && <ViewPlan id={parseInt(params.id, 10)} />}
            </div>
          </div>
        </MainBlock>
      </HttpErrorsBoundary>
    </AccessChecker>
  );
}
