import React, { ReactElement, ReactNode } from 'react';
import { usePeopleService } from 'nekst-api';
import { AuthorizationScope } from './AuthorizationScope';

export function PersonAuthorizationScope(props: {
  id: number,
  children: ReactNode,
}) {
  const personService = usePeopleService();

  return (
    <AuthorizationScope
      getPermissionsFunc={personService.getPermissions}
      scopeName="Person"
      id={props.id}
    >
      {props.children}
    </AuthorizationScope>
  );
}
