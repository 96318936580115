import React from 'react';
import InnerBlock from '../../shared/web/layout/InnerBlock';
import { List, ListDataRefresher, useShowContextRequired } from 'ui-builder';
import {
  TASKS_GROUP_CREATED, TASKS_GROUP_DELETED,
  TASKS_GROUP_UPDATED,
  TasksGroup,
  useTasksGroupsService
} from 'nekst-api';
import GridLayout from '../../shared/uibuilder/list/GridLayout';
import Cell from '../../shared/uibuilder/list/Cell';
import { TasksGroupIcon } from 'features/nekst-widgets';
import { UpdateTasksGroupButton } from './UpdateForm';
import { DeleteGroupButton } from './DeleteForm';
import { CreateTasksGroupButton } from './CreateTasksGroupForm';

function NameField() {
  const showContext = useShowContextRequired<TasksGroup>();

  return (
    <TasksGroupIcon group={showContext.data!} withName />
  )
}

export default function TasksGroupsList() {
  const tasksGroupsService = useTasksGroupsService();

  return (
    <InnerBlock
      title="Tasks Groups"
      headerRight={<CreateTasksGroupButton />}
    >
      <List getDataFunc={() => tasksGroupsService.getTasksGroups()}>
        <GridLayout weights={[10, 2]}>
          <Cell label="Group Name">
            <NameField />
          </Cell>
          <Cell label="Actions">
            <UpdateTasksGroupButton />
            <DeleteGroupButton />
          </Cell>
        </GridLayout>
        <ListDataRefresher eventName={[TASKS_GROUP_CREATED, TASKS_GROUP_UPDATED, TASKS_GROUP_DELETED]} />
      </List>
    </InnerBlock>
  );
}
