import { ViewStyle } from 'react-native';
import { InputLayoutIcon, useFilterContext, useNewUiTheme } from 'ui-builder';
import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

export function TextFilterInput<FilterType extends Record<string, any>>(
  props: {
    style?: ViewStyle,
    source: keyof FilterType,
    placeholder: string,
    testID?: string,
  }
) {
  const filterContext = useFilterContext<FilterType>();
  const filterValue = filterContext.filterValue || {} as FilterType;

  const actualFilterValue = filterValue[props.source] as string | undefined;

  const uiTheme = useNewUiTheme();

  const [value, setValue] = useState<string | undefined>(actualFilterValue);

  const updateFilter = useCallback(
    debounce((text: string | undefined) => filterContext.setFilterValue({
      ...filterContext.filterValue || {},
      [props.source]: text,
    } as FilterType), 500),
    [
      JSON.stringify(filterContext.filterValue),
    ],
  );

  useEffect(() => {
    if (!actualFilterValue && value !== actualFilterValue) {
      setValue(undefined);
    }
  }, [actualFilterValue]);

  useEffect(() => {
    updateFilter(value);
  }, [value]);

  return (
    <uiTheme.inputs.TextLayout
      value={value || ''}
      name={props.source as string}
      onChangeCallback={(event) => {
        setValue(event.target.value);
      }}
      isVisible
      placeholder={props.placeholder}
      isDisabled={false}
      icon={InputLayoutIcon.SEARCH}
      hideLabel
      dataKey={props.testID}
      style={props.style}
    />
  );
}
