import usePlansApi from '../../plansApi';

export default function useSetDueDateService() {

  const plansApi = usePlansApi();

  const setDueDate = async (ids: number[], dueDate: any) => {
    await plansApi.put('/plantasks/bulk/duedate', {
      ids,
      dueDateConfig: dueDate,
    });
  };

  return {
    setDueDate,
  };
}
