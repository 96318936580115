import React, { ReactElement } from 'react';
import { useFilterContext } from 'ui-builder';
import { ProjectFilter, ProjectStatus } from 'nekst-api';
import { StatisticPastDueTasksField } from './field/PastDueTasksField';

import styles from './ActiveTasksStatistic.module.scss';
import StatisticCountField from './StatisticCountField';
import StatisticCommissionField from './field/StatisticCommissionField';
import StatisticTotalPropertiesField from './StatisticTotalPropertiesField';
import LoadingAnimation from '../../shared/LoadingAnimation';
import { StatisticTotalTasks } from './TotalTasksField';
import { useProjectStatisticContextRequired } from 'features/tasks-forms';

function BaseProjectStatistic(
  props: {
    status: ProjectStatus,
    children: ReactElement,
  },
) {
  const statisticContext = useProjectStatisticContextRequired();

  if (statisticContext.statistic) {
    return (
      <table className={styles.table}>
        <tbody>
          <tr className={styles.row}>
            <td className={styles.propertiesCount}>
              <StatisticTotalPropertiesField status={props.status} />
            </td>
            <td className={styles.totalTasks}>
              <StatisticTotalTasks />
            </td>
            <td className={styles.pastDueTasks}>
              <StatisticPastDueTasksField />
            </td>
            <td className={styles.aggregatedInfo} data-key="projects-aggregated-info">
              {props.children}
            </td>
          </tr>
        </tbody>
      </table>
    );
  } else {
    return <LoadingAnimation />;
  }
}

function ActiveProjectsStatistic() {
  return (
    <BaseProjectStatistic status={ProjectStatus.ACTIVE}>
      <table>
        <thead>
          <tr>
            <td />
            <td>
              30 days
            </td>
            <td>
              31-60 days
            </td>
            <td>
              61+ days
            </td>
            <td>
              Total
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className={styles.closingWithin}>
            <td>Closing Within:</td>
            <td><StatisticCountField period="30_DAYS" /></td>
            <td><StatisticCountField period="31_60_DAYS" /></td>
            <td><StatisticCountField period="61_PLUS_DAYS" /></td>
            <td><StatisticCountField period="TOTAL" /></td>
          </tr>
          <tr>
            <td>Commission:</td>
            <td><StatisticCommissionField period="30_DAYS" /></td>
            <td><StatisticCommissionField period="31_60_DAYS" /></td>
            <td><StatisticCommissionField period="61_PLUS_DAYS" /></td>
            <td><StatisticCommissionField period="TOTAL" /></td>
          </tr>
        </tbody>
      </table>
    </BaseProjectStatistic>
  );
}

function ClosedProjectsStatistic() {
  return (
    <BaseProjectStatistic status={ProjectStatus.CLOSED}>
      <table>
        <thead>
          <tr>
            <td />
            <td>
              Month To Date
            </td>
            <td>
              Prior Month
            </td>
            <td>
              Year To Date
            </td>
            <td>
              Prior Year
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className={styles.closingWithin}>
            <td>Closing Within:</td>
            <td><StatisticCountField period="MTD" /></td>
            <td><StatisticCountField period="PRIOR_MONTH" /></td>
            <td><StatisticCountField period="YTD" /></td>
            <td><StatisticCountField period="PRIOR_YEAR" /></td>
          </tr>
          <tr>
            <td>Commission:</td>
            <td><StatisticCommissionField period="MTD" /></td>
            <td><StatisticCommissionField period="PRIOR_MONTH" /></td>
            <td><StatisticCommissionField period="YTD" /></td>
            <td><StatisticCommissionField period="PRIOR_YEAR" /></td>
          </tr>
        </tbody>
      </table>
    </BaseProjectStatistic>
  );
}

export default function ProjectsStatisticWidget() {

  const filterContext = useFilterContext<ProjectFilter>();

  let statistic = null;

  if (filterContext.filterValue?.status === ProjectStatus.ACTIVE) {
    statistic = (
      <ActiveProjectsStatistic />
    );
  } else if (filterContext.filterValue?.status === ProjectStatus.CLOSED) {
    statistic = (
      <ClosedProjectsStatistic />
    );
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {statistic}
    </>
  );
}
