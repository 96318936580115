import React, { ReactNode } from 'react';
import useFeatureToggleService, { ToggledFeature } from './featureToggleService';

export interface ToggleWrapperProps {
  children: ReactNode;
}

export interface BaseToggleWrapperProps extends ToggleWrapperProps {
  feature: ToggledFeature,
}

export function ToggleWrapper(
  props: BaseToggleWrapperProps,
) {
  const featureToggleService = useFeatureToggleService();

  if (featureToggleService.isFeatureEnabled(props.feature)) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {props.children}
      </>
    );
  } else {
    return null;
  }
}
