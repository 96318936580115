import {
  BaseInputProps,
  Dropdown,
  ErrorsWrapper,
  FormRows,
  ifReactNative,
  InputLayoutIcon,
  RelativeRow,
  TextInput,
  useInputHelper
} from 'ui-builder';
import React from 'react';
import useStatesHelper from './states';
import { Address } from 'nekst-api';

interface StateDropdownProps extends BaseInputProps {

}

export function StateDropdown(props: StateDropdownProps) {
  const statesHelper = useStatesHelper();

  const options = [
    {
      label: 'United States',
      options: statesHelper.getUsStates(),
    },
    {
      label: 'Canada',
      options: statesHelper.getCanadaStates(),
    },
  ];
  return (
    <Dropdown
      options={options}
      {...props}
      icon={InputLayoutIcon.ADDRESS}
    />
  );
}

export interface AddressInputProps extends BaseInputProps<Address> {
}

export function AddressInput(props: AddressInputProps) {
  const helper = useInputHelper<Address>(props);
  return (
    <ErrorsWrapper name={props.source} errors={helper.getErrors()}>
      <FormRows>
        <RelativeRow weights={ifReactNative([12], [6, 6])}>
          <TextInput
            source={`${props.source}.addressLine1`}
            label="Address Line 1"
            icon={InputLayoutIcon.ADDRESS}
          />
          <TextInput
            source={`${props.source}.addressLine2`}
            label="Address Line 2"
            icon={InputLayoutIcon.ADDRESS}
          />
        </RelativeRow>
        <RelativeRow weights={ifReactNative([6, 6], [4, 4, 4])}>
          <TextInput
            source={`${props.source}.city`}
            label="City"
            icon={InputLayoutIcon.ADDRESS}
          />
          <StateDropdown source={`${props.source}.state`} label="State" />
          <TextInput source={`${props.source}.zip`} label="Zip" icon={InputLayoutIcon.ADDRESS} />
        </RelativeRow>
      </FormRows>
    </ErrorsWrapper>
  );
}
