import React from 'react';
import { TeamPermission } from 'nekst-api';
import AccessChecker from '../../shared/authorization/AccessChecker';
import { SettingsBreadcrumbs } from '../settingsModule';
import { Breadcrumb } from '../../shared/web/layout/Breadcrumbs';
import ServiceProvidersList from './ServiceProvidersList';

export default function ServiceProvidersSettingsPage() {
  return (
    <AccessChecker
      permissionToCheck={TeamPermission.MANAGE_SERVICE_PROVIDERS}
    >
      <SettingsBreadcrumbs>
        <Breadcrumb text="Service Providers" />
      </SettingsBreadcrumbs>
      <ServiceProvidersList />
    </AccessChecker>
  );
}
