import { DownloadFileDto } from 'nekst-api';

// eslint-disable-next-line import/prefer-default-export
export function downloadFile(file: DownloadFileDto) {
  const {
    downloadUrl,
    fileName,
  } = file;
  return new Promise((resolve, reject) => {
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = fileName;

    link.addEventListener('load', () => {
      URL.revokeObjectURL(link.href);
      link.remove();
    });

    link.addEventListener('error', () => {
      reject(new Error('Failed to download file.'));
    });

    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      resolve(null);
    }, 1000);
  });
}
