import React, { ReactNode } from 'react';
import { TextStyle, ViewStyle } from 'react-native';
import { useNewUiTheme } from '../newUiTheme';

export interface ItemsListProps {
  items: (string | ReactNode)[],
  style?: ViewStyle,
  itemStyle?: TextStyle,
  className?: string,
  itemClassName?: string,
}

export interface ItemsListLayoutProps extends ItemsListProps{

}

export function ItemsList(props: ItemsListProps) {
  const uiTheme = useNewUiTheme();
  return (
    <uiTheme.widgets.ItemsListLayout
      {...props}
    />
  );
}
