import React from 'react';
import useLaunchFromContractNavigator from './launchFromContractNavigator';
import BaseTasksPage from '../BaseTasksPage';
import LaunchFromContractSteps, { LaunchFromContractStepId } from './LaunchFromContractSteps';

export default function AllTasksPage() {
  const navigator = useLaunchFromContractNavigator();

  return (
    <BaseTasksPage
      roadmap={<LaunchFromContractSteps currentStep={LaunchFromContractStepId.ALL_TASKS} />}
      onPrevClicked={navigator.openKeyDatesPage}
      onNextClicked={() => {
        navigator.openTransactionPartiesPage();
      }}
    />
  );
}
