import React from 'react';
import {
  ButtonColor,
  ButtonSize,
  Button,
  usePopupManager,
  useMassUpdateServiceRequired
} from 'ui-builder';
import { SetDueDateForm } from 'features/tasks-list-feature';
import { PopupForm } from 'features/nekst-widgets';

export default function SetDueDateButton() {
  const massUpdateService = useMassUpdateServiceRequired();

  const popupManager = usePopupManager();

  const onClick = () => {
    popupManager.openPopup(
      <PopupForm>
        <SetDueDateForm />
      </PopupForm>
    );
  };

  return (
    <Button
      text="Due"
      isDisabled={massUpdateService.getCheckedIds().length === 0}
      color={ButtonColor.GRAY}
      size={ButtonSize.SMALL}
      isFlat
      isFilled
      onClick={onClick}
    />
  );
}
