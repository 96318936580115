import React from 'react';
import Layout from './Layout';
import { Person } from 'nekst-api';

export function AvatarInput(props: {
  person: Person,
}) {
  return (
    <Layout person={props.person} />
  );
}
