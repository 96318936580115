import { ConstraintValidator, ConstraintValue } from '../index';
import useConstraintValidatorHelper from './constaintValidatorHelper';

export interface MatchConstraintValidator extends ConstraintValidator<string> {
}

export default function useMatchConstraintValidator() {

  const helperFactory = useConstraintValidatorHelper<number>();
  const validate = (
    object: Record<string, any>,
    fieldName: string,
    constraintConfiguration: ConstraintValue<string>,
  ): boolean => {
    const helper = helperFactory.createHelper(
      object,
      fieldName,
      constraintConfiguration,
    );
    const value = helper.getValue();

    const compareField = helper.getConstraintValue();
    const compareFieldValue = object[compareField];
    return value === compareFieldValue;
  };

  const getErrorMessage = (config: ConstraintValue<string>) => {
    return (typeof config === 'object' && config.message)
      || 'The entered fields don\'t match';
  };

  return {
    getName: () => 'match',
    validate,
    getErrorMessage,
  };
}
