import React, { ReactElement } from 'react';
import {
  AfterSubmitFunc, Button, ButtonColor, ButtonSize, CancelButton,
  FormLayoutProps, ifReactNative, ItemsList,
  NewLine,
  SimpleBlock, SubmitButton, Text,
  usePopupManager, useShowContext
} from 'ui-builder';
import {
  AbstractTask,
  Project, ProjectPermission,
  ProjectUpdateOption,
  useProjectsService,
  useProjectTasksService, ValidationError
} from 'nekst-api';
import { ClosePopupButton, Popup } from 'features/nekst-widgets';
import { BaseProjectForm } from './BaseProjectForm';
import { useAuthorizationChecker } from 'authorization-scope';

export interface UpdateProjectFormProps {
  buttons?: ReactElement;
  className?: string;
  projectId: number;
  afterSubmitFunc?: AfterSubmitFunc;
  layout?: React.FC<FormLayoutProps>;
  title?: string;
  checkDateUpdates?: boolean;
  openLaunchProjectPageFunc?: (projectId: number) => Promise<void>;
}

function ChooseUpdateProjectOptionPopup(
  props: {
    data: Project,
    dependentStartDateTasks: AbstractTask[],
    dependentEndDateTasks: AbstractTask[],
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {
  const projectService = useProjectsService();
  const popupManager = usePopupManager();

  return (
    <Popup
      title="Update transaction dates"
      content={(
        <>
          <SimpleBlock className="message">
            <Text>
              You are about to update transaction dates. Please choose an action for Nekst to take
              regarding all Open Tasks that were based on the previous transaction dates.
            </Text>
          </SimpleBlock>
          {props.dependentStartDateTasks.length ? (
            <SimpleBlock>
              <NewLine />
              <Text>
                Tasks dependent on the Start Date:
              </Text>
              <ItemsList
                items={props.dependentStartDateTasks.map((item) => item.name)}
              />
            </SimpleBlock>
          ) : null}
          {props.dependentEndDateTasks.length ? (
            <SimpleBlock>
              <NewLine />
              <Text>Tasks dependent on the End Date:</Text>
              <ItemsList

                items={props.dependentEndDateTasks.map((item) => item.name)}
              />
            </SimpleBlock>
          ) : null}
        </>
      )}
      buttons={(
        <>
          <Button
            text="Adjust Dates"
            size={ifReactNative(ButtonSize.SMALL, ButtonSize.BIG)}
            color={ButtonColor.GREEN}
            onClick={
              async () => {
                await projectService.update(
                  props.data.id,
                  props.data,
                  {
                    onEndDateUpdated: ProjectUpdateOption.ADJUST_DATES,
                    onStartDateUpdated: ProjectUpdateOption.ADJUST_DATES,
                  },
                );
                popupManager.closePopup();
                props.afterSubmitFunc?.(props.data);
              }
            }
          />

          <Button
            text="Do Not Change Dates"
            size={ifReactNative(ButtonSize.SMALL, ButtonSize.BIG)}
            color={ButtonColor.YELLOW}
            onClick={
              async () => {
                await projectService.update(
                  props.data.id,
                  props.data,
                  {
                    onStartDateUpdated: ProjectUpdateOption.DO_NOT_CHANGE_DATES,
                    onEndDateUpdated: ProjectUpdateOption.DO_NOT_CHANGE_DATES,
                  },
                );
                popupManager.closePopup();
                props.afterSubmitFunc?.(props.data);
              }
            }
          />
          <ClosePopupButton
            size={ifReactNative(ButtonSize.SMALL, ButtonSize.BIG)}
            color={ButtonColor.GRAY}
          />
        </>
      )}
    />
  );
}


function UpdateProjectFormImpl(props: UpdateProjectFormProps) {
  const projectsService = useProjectsService();

  const projectTasksService = useProjectTasksService();

  const showContext = useShowContext<Project>();

  const popupManager = usePopupManager();

  const buttons = props.buttons || (
    <>
      <SubmitButton text="Update" key="save" />
      <CancelButton text="Cancel" key="cancel" />
    </>
  );

  const project = showContext.data!;

  const getStartDateDependentTasks = async () => {
    return (
      await projectTasksService.getAllTasks(
        project.id,
        {
          isDependentOnStartDate: true,
          showActive: true,
        },
        {
          limit: 100,
          offset: 0,
        },
      )
    ).data;
  };

  const getEndDateDependentTasks = async () => {
    return (
      await projectTasksService.getAllTasks(
        project.id,
        {
          isDependentOnEndDate: true,
          showActive: true,
        },
        {
          limit: 100,
          offset: 0,
        },
      )
    ).data;
  };

  const checkDateUpdates = props.checkDateUpdates || false;

  return (
    <BaseProjectForm
      dataKey="update-project-form"
      title={props.title}

      submitFormFunc={async (data) => {
        const isPlanChanged = project.planId !== data.planId;

        const isStartDateChanged = project.startDate && project.startDate.getTime() !== data.startDate.getTime();

        const isEndDateChanged = project.endDate && project.endDate.getTime() !== data.endDate.getTime();

        if (checkDateUpdates && (isStartDateChanged || isEndDateChanged) && !isPlanChanged) {
          const dependentStartDateTasks = isStartDateChanged
            ? await getStartDateDependentTasks() : [];

          const dependentEndDateTasks = isEndDateChanged
            ? await getEndDateDependentTasks() : [];

          if (dependentStartDateTasks.length || dependentEndDateTasks.length) {
            popupManager.openPopup(
              <ChooseUpdateProjectOptionPopup
                data={data}
                dependentStartDateTasks={dependentStartDateTasks}
                dependentEndDateTasks={dependentEndDateTasks}
                afterSubmitFunc={props.afterSubmitFunc}
              />,
            );

            throw new ValidationError({});
          } else {
            return projectsService.update(props.projectId, data);
          }
        } else {
          return projectsService.update(props.projectId, data);
        }
      }}
      afterSubmitFunc={async (data) => {
        const needToLaunchProject = project.planId !== data!.planId;

        showContext.updateData!(data!);

        if (props.afterSubmitFunc) {
          await props.afterSubmitFunc(data);
        }

        if (needToLaunchProject) {
          await props.openLaunchProjectPageFunc?.(showContext.data!.id);
        }
      }}
      initialData={showContext.data!}
      buttons={buttons}
      className={props.className}
      layout={props.layout}
    />
  );
}


export function UpdateProjectForm(props: UpdateProjectFormProps) {
  const { isGranted } = useAuthorizationChecker();

  if (isGranted(ProjectPermission.UPDATE)) {
    return (
      <UpdateProjectFormImpl {...props} />
    );
  } else {
    return null;
  }
}
