import React, { ReactNode } from 'react';
import { SimpleBlock, Text } from 'ui-builder';

import styles from './PopupMessage.module.scss';

export interface PopupMessageProps {
  children: string | ReactNode,
  align?: 'center' | 'left'
}

export function PopupText(props: PopupMessageProps) {
  return (
    <SimpleBlock className={`${styles.message} ${props.align || ''}`}>
      <Text>
        {props.children}
      </Text>
    </SimpleBlock>
  )
}
