import React from 'react';
import UpdateStandardTaskForm from './UpdateStandardTaskForm';
import { AfterSubmitFunc } from 'ui-builder';
import { PlanTask } from 'nekst-api';

export interface UpdatePlanTaskFormProps {
  data: PlanTask,
  afterSubmitFunc?: AfterSubmitFunc,
}

export default function UpdateStandardPlanTaskForm(props: UpdatePlanTaskFormProps) {
  return (
    <UpdateStandardTaskForm
      data={props.data}
      afterSubmitFunc={props.afterSubmitFunc}
    />
  );
}
