import React from 'react';
import { Route } from 'react-router-dom';
import { ApplicationModule } from '../shared/applicationModule';
import AllTasksListPage from './list/AllTasksListPage';
import AllTasksCalendarPage from './list/AllTasksCalendarPage';

export default function useTasksModule(): ApplicationModule {

  const getRouter = () => {
    return (
      <>
        <Route
          path="/tasks"
          element={
            <AllTasksListPage />
          }
        />
        <Route
          path="/calendar"
          element={
            <AllTasksCalendarPage />
          }
        />
      </>
    );
  };

  return {
    getRouter,
    name: 'tasks',
  };
}
