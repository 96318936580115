import React from 'react';

import { ShowLayoutProps, useShowContext } from 'ui-builder';
import InnerBlock from '../../layout/InnerBlock';

export default function ShowLayout(props: ShowLayoutProps) {
  const { data } = useShowContext();

  let titleString;
  if (typeof props.title === 'function') {
    titleString = props.title(data);
  } else {
    titleString = props.title;
  }

  let titleLine2String;

  if (props.titleLine2) {
    if (typeof props.titleLine2 === 'function') {
      titleLine2String = props.titleLine2(data);
    } else {
      titleLine2String = props.titleLine2;
    }
  }

  return (
    <InnerBlock title={titleString} titleLine2={titleLine2String} headerRight={props.buttons}>
      {props.children}
    </InnerBlock>
  );
}
