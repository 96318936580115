import React from 'react';
import styles from './AddTransactionParties.module.scss';

import { AssignedRolesHelper } from './assignedRolesHelper';
import { useAuthorizationChecker } from 'authorization-scope';
import { TeamPermission } from 'nekst-api';

export interface UsedRolesProps {
  assignedRolesHelper: AssignedRolesHelper;
}

export default function UsedRoles(props: UsedRolesProps) {
  const { isGranted } = useAuthorizationChecker();

  return (
    <div className={styles.usedRoles}>
      <div>
        <b>Roles with Assigned Tasks:</b>
        &nbsp;
        {props.assignedRolesHelper.getRolesAssignedToTasks()
          .map((v) => v.name)
          .join(', ')}
      </div>
      <div>
        <b>Roles Receiving Emails:</b>
        &nbsp;
        {(props.assignedRolesHelper.getEmailRecipientRoles().length > 0)
          ? props.assignedRolesHelper.getEmailRecipientRoles()
            .map((v) => v.name)
            .join(', ')
          : '-'}
      </div>
      {isGranted(TeamPermission.USE_SMS_TASKS_FEATURE) && (
        <div>
          <b>Roles Receiving SMS:</b>
          &nbsp;
          {(props.assignedRolesHelper.getSmsRecipientRoles().length > 0)
            ? props.assignedRolesHelper.getSmsRecipientRoles()
              .map((v) => v.name)
              .join(', ')
            : '-'}
        </div>

      )}
    </div>
  );
}
