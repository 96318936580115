import React from 'react';
import { AfterSubmitFunc, FormTemplate, SubmitButton, TextInput } from 'ui-builder';
import { CreateTeamMemberRequest, TeamMember, TeamRole, useTeamMembersService } from 'nekst-api';
import { TeamRoleRadio } from '../../People';
import { useSeatUsageLoader } from '../SeatUsage';
import { WarningMessage } from 'features/nekst-widgets';
import useFeatureToggleService from 'feature-toggle';

export function CreateTeamMemberForm(props: {
  afterSubmitFunc?: AfterSubmitFunc<TeamMember>,
  updateSeatButton: React.ReactNode,
}) {
  const teamMembersService = useTeamMembersService();

  const { seatUsage } = useSeatUsageLoader();

  const featureToggleService = useFeatureToggleService();

  let memberOptionDisabled = false;
  let adminOptionDisabled = false;
  let formDisabled = false;

  if (featureToggleService.isTeamMembersManagementSeatsEnabled()) {
    memberOptionDisabled = seatUsage?.membersSeatsAvailable !== undefined && seatUsage.membersSeatsAvailable <= 0;
    adminOptionDisabled = seatUsage?.administratorsSeatsAvailable !== undefined && seatUsage.administratorsSeatsAvailable <= 0;

    formDisabled = memberOptionDisabled && adminOptionDisabled;
  }

  let initialRole: TeamRole | undefined;

  if (!formDisabled) {
    initialRole = adminOptionDisabled ? TeamRole.ADMINISTRATOR : TeamRole.MEMBER;
  }

  return (
    <FormTemplate<CreateTeamMemberRequest, TeamMember>
      title="Add Team Member"
      submitFormFunc={(request: CreateTeamMemberRequest) => teamMembersService.createTeamMember(request)}
      buttons={(
        <SubmitButton text="Save" />
      )}
      validationSchema={teamMembersService.getCreateTeamMemberValidationSchema()}
      afterSubmitFunc={props.afterSubmitFunc}
      initialData={{
        teamRole: initialRole,
      }}
      isDisabled={formDisabled}
    >
      {formDisabled && (
        <WarningMessage>
          To add new team members, you must first increase the number of seats for your account
          {' '}
          {props.updateSeatButton}
        </WarningMessage>
      )}
      <TextInput source="firstName" label="First Name" />
      <TextInput source="lastName" label="Last Name" />
      <TextInput source="email" label="Email" />
      <TeamRoleRadio
        source="teamRole"
        label="Access Level"
        showContactOption={false}
        disabledOptions={{
          ...memberOptionDisabled ? {
            [TeamRole.MEMBER] : 'You have reached the limit of team members',
          } : {},
          ...adminOptionDisabled ? {
            [TeamRole.ADMINISTRATOR] : 'You have reached the limit of team administrators',
          } : {},
        }}
      />
    </FormTemplate>
  );
}
