import React from 'react';

import { useTypeFilterService } from 'features/tasks-list-feature';

import FilterWidgetLayout from '../FilterWidgetLayout';
import { TeamPermission } from 'nekst-api';
import { useAuthorizationChecker } from 'authorization-scope';

function KeyDateWidget() {
  const typeFilterService = useTypeFilterService();

  const currentValue = typeFilterService.getFilterValue();

  if (currentValue && currentValue.showKeyDatesOnly) {
    const resetKeyDate = () => {
      typeFilterService.setFilterValue({
        ...currentValue,
        showKeyDatesOnly: false,
      });
    };

    return (
      <FilterWidgetLayout
        name=""
        text="Key Dates Only"
        onClick={resetKeyDate}
      />
    );
  } else {
    return null;
  }
}

function TaskTypeWidget() {
  const typeFilterService = useTypeFilterService();

  const { isGranted } = useAuthorizationChecker();

  const currentValue = typeFilterService.getFilterValue();

  if (currentValue && (
    !currentValue.showEmailTasks
    || !currentValue.showStandardTasks
    || !currentValue.showSmsTasks
  )) {
    const resetTypes = () => {
      typeFilterService.setFilterValue({
        ...currentValue,
        showStandardTasks: true,
        showEmailTasks: true,
        showSmsTasks: true,
      });
    };

    const types = [];

    if (currentValue.showStandardTasks) {
      types.push('Standard Tasks');
    }

    if (currentValue.showEmailTasks) {
      types.push('Email Tasks');
    }

    if (isGranted(TeamPermission.USE_SMS_TASKS_FEATURE) && currentValue.showSmsTasks) {
      types.push('SMS Tasks');
    }

    return (
      <FilterWidgetLayout
        name="Types"
        text={types.join(', ')}
        onClick={resetTypes}
      />
    );
  } else {
    return null;
  }
}

export default function TypeWidget() {
  return (
    <>
      <KeyDateWidget />
      <TaskTypeWidget />
    </>
  );
}
