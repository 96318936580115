import React from 'react';
import CreateStandardTaskForm from './Standard/CreateStandardTaskForm';
import useAuthorizationChecker from '../../../../shared/authorization/authorizationChecker';
import CreateEmailTaskForm from './Email/CreateEmailTaskForm';
import { useProjectContext } from '../../ProjectContext';
import CreateSmsTaskForm from './Sms/CreateSmsTaskForm';
import { usePopupFormManager } from 'features/nekst-widgets';
import { ProjectPermission, TaskType, TeamPermission } from 'nekst-api';
import { ButtonSize, OptionSelectionButton } from 'ui-builder';

export default function CreateTaskButton() {
  const { isGranted } = useAuthorizationChecker();

  const { isWithinContext: isWithinProjectContext, data: project } = useProjectContext();

  const popupManager = usePopupFormManager();

  const projectId = project?.id;

  const canCreateTasks = () => {
    if (isWithinProjectContext()) {
      return isGranted(ProjectPermission.CREATE_TASKS);
    } else {
      return isGranted(TeamPermission.CREATE_PROJECT_TASKS);
    }
  };

  if (canCreateTasks()) {
    return (
      <OptionSelectionButton<TaskType>
        text="Add task"
        options={[
          {
            label: 'Standard Task',
            value: TaskType.STANDARD,
            onSelected: () => {
              popupManager.openForm(
                <CreateStandardTaskForm projectId={projectId} />,
              );
            },
          },
          {
            label: 'Email Task',
            value: TaskType.EMAIL,
            onSelected: () => {
              popupManager.openForm(
                <CreateEmailTaskForm projectId={projectId} />,
              );
            }
          },
          isGranted(TeamPermission.USE_SMS_TASKS_FEATURE) && {
            label: 'SMS Task',
            value: TaskType.SMS,
            onSelected: () => {
              popupManager.openForm(
                <CreateSmsTaskForm projectId={projectId} />,
              );
            }
          }
        ]}
        size={ButtonSize.MEDIUM}
      />
    );
  } else {
    return null;
  }
}
