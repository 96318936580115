import { useAuthenticationContext } from 'nekst-api';
import { ToggleWrapper } from './ToggleWrapper';
import React from 'react';

export enum ToggledFeature {
  AILAUNCHER = 'AILAUNCHER',
  TEAM_MEMBERS_MANAGEMENT = 'TEAM_MEMBERS_MANAGEMENT',
  TEAM_MEMBERS_MANAGEMENT_SEATS = 'TEAM_MEMBERS_MANAGEMENT_SEATS',
  TASKS_GROUPS = 'TASKS_GROUPS',
  LAUNCHING_FROM_CONTRACT = 'LAUNCHING_FROM_CONTRACT',
  NEW_UI = 'NEW_UI',
  DETAILS_FIELD_SETS = 'DETAILS_FIELD_SETS',
}

export default function useFeatureToggleService() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const teamId = useAuthenticationContext()?.user.teamId;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isForDefaultTeams = [
    1,
    2,
    697,
    18370, // Steeve
  ].includes(teamId || 0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isForDeveloperOnly = teamId === 697;

  const isTeamMembersManagementEnabled = () => {
    return isForDefaultTeams;
  }

  const isTeamMembersManagementSeatsEnabled = () => {
    return isForDefaultTeams;
  }

  const isTasksGroupsEnabled = () => {
    return true;
  }

  const isDetailsFieldSetsEnabled = () => {
    return isForDefaultTeams;
  }

  const isLaunchingFromContractEnabled = () => {
    return isForDefaultTeams;
  };

  const isNewUiEnabled = () => {
    return false;
  }

  const isFeatureEnabled = (feature: ToggledFeature) => {
    switch (feature) {
      case ToggledFeature.AILAUNCHER:
        return isLaunchingFromContractEnabled();
      case ToggledFeature.TEAM_MEMBERS_MANAGEMENT:
        return isTeamMembersManagementEnabled();
      case ToggledFeature.TEAM_MEMBERS_MANAGEMENT_SEATS:
        return isTeamMembersManagementSeatsEnabled();
      case ToggledFeature.TASKS_GROUPS:
        return isTasksGroupsEnabled();
      case ToggledFeature.LAUNCHING_FROM_CONTRACT:
        return isLaunchingFromContractEnabled();
      case ToggledFeature.NEW_UI:
        return isNewUiEnabled();
      case ToggledFeature.DETAILS_FIELD_SETS:
        return isDetailsFieldSetsEnabled();
      default:
        return false;
    }
  };

  return {
    isNewUiEnabled,
    isFeatureEnabled,
    isTeamMembersManagementEnabled,
    isTeamMembersManagementSeatsEnabled,
    isTasksGroupsEnabled,
    isLaunchingFromContractEnabled,
    isDetailsFieldSetsEnabled,
  };
}

export function withToggleWrapper<T>(
  WrappedComponent: React.ComponentType<T>,
  feature: ToggledFeature
) {
  return (props: T) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const component = <WrappedComponent {...props} />

    return (
      <ToggleWrapper feature={feature}>
        {component}
      </ToggleWrapper>
    )
  };
}
