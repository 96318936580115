import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CreateProjectPage from './CreateUpdateProject/CreateProjectPage';
import ManageKeyDateTasksPage from './ManageKeyDateTasksPage';
import LaunchProjectWrapper from './LaunchProjectWrapper';
import ManageAllTasksPage from './ManageAllTasksPage';
import AddTransactionPartiesPage from './AddTransactionParties';
import UpdateProjectPage from './CreateUpdateProject/UpdateProjectPage';
import ArchivedProjectChecker from './ArchivedProjectChecker';
import SelectGroupsPage from './SelectGroupsPage';

function PropertiesWrapperRouter() {
  return (
    <LaunchProjectWrapper>
      <Routes>
        <Route
          path="update"
          element={<UpdateProjectPage />}
        />
        <Route
          path="groups"
          element={<SelectGroupsPage />}
        />
        <Route
          path="keydates"
          element={(
            <ArchivedProjectChecker>
              <ManageKeyDateTasksPage />
            </ArchivedProjectChecker>
          )}
        />
        <Route
          path="alltasks"
          element={(
            <ArchivedProjectChecker>
              <ManageAllTasksPage />
            </ArchivedProjectChecker>
          )}
        />
        <Route
          path="parties"
          element={(
            <ArchivedProjectChecker>
              <AddTransactionPartiesPage />
            </ArchivedProjectChecker>
          )}
        />
      </Routes>
    </LaunchProjectWrapper>
  );
}

export default function useLaunchProjectModule() {
  const getRouter = () => {
    return (
      <>
        <Route path="/transactions/new" element={<CreateProjectPage />} />
        <Route
          path="/transactions/:id/launch/*"
          element={<PropertiesWrapperRouter />}
        />
      </>
    );
  };

  return {
    getRouter,
  };
}
