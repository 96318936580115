import { ReactElement } from 'react';
import { FormFieldsData, VisibilityAware } from '../index';
import { TextStyle, ViewStyle } from 'react-native';

export type OnChangeCallbackType = (_values: Record<string, any>) => void;

export interface BaseInputLayoutProps<ValueType = string> {
  name: string,
  label?: string | ReactElement,
  afterLabelElement?: string | ReactElement,
  hideLabel?: boolean,
  value: Nullable<ValueType>,
  title?: string,
  onChangeCallback: OnChangeCallbackType,
  onFocusCallback?: () => void,
  onBlurCallback?: () => void,
  isVisible: boolean,
  errors?: string[],
  placeholder?: string,
  showLabel?: boolean,
  className?: string,
  isDisabled: boolean,
  hint?: ReactElement | string,
  showRequiredIcon?: boolean
  dataKey?: string,
  style?: ViewStyle,
  icon?: InputLayoutIcon,
}

export interface BaseInputProps<ValueType = string> extends VisibilityAware {
  label?: string | ReactElement;
  afterLabelElement?: string | ReactElement;
  hideLabel?: boolean
  source: string;
  className?: string | ((_source: string) => string);
  value?: Nullable<TypeOrFunction<ValueType>>;
  onChangeCallback?: OnChangeCallbackType;
  onFocusCallback?: () => void,
  onBlurCallback?: () => void,
  defaultValue?: ValueType;
  title?: string;
  placeholder?: string;
  showLabel?: boolean;
  isDisabled?: boolean | ((_data: FormFieldsData) => boolean);
  hint?: string | ReactElement
  showRequiredIcon?: boolean,
  dataKey?: string,
  style?: ViewStyle,
  labelStyle?: TextStyle,
  icon?: InputLayoutIcon,
}

export type Option<ValueType = string | number, DataType = any> = {
  value: ValueType;
  label: string | ReactElement;
  disabled?: boolean;
  isHighlighted?: boolean;
  isFixed?: boolean
  [param: string]: any
  _data?: DataType
}

export enum InputLayoutIcon {
  SEARCH = 'search',
  TEXT = 'text',
  PERSON = 'person',
  WORK = 'work',
  EMAIL = 'email',
  PHONE = 'phone',
  WEB = 'web',
  ADDRESS = 'address',
  ASSIGNMENT = 'assignment',
  COMPLETE = 'complete',
  UPDATE = 'update',
  ADD = 'add',
  PRICE = 'price',
}
