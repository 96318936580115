import React, { ReactElement } from 'react';
import RecordLayout, { RecordLayoutProps, useRecordLayoutContext } from './RecordLayout';
import PersonLink from './PersonLink';
import TaskLink from './TaskLink';
import { HistoryEventIconType } from './HistoryEventIcon';
import { BaseHistoryEntryData, PersonData, RecipientType, TaskData } from 'nekst-api';

interface EmailSentData extends BaseHistoryEntryData {
  sender: PersonData,
  task: TaskData,
  recipients: {
    person: PersonData,
    type: RecipientType,
  }[];
}

function EmailSendRecordText(props: { data: EmailSentData }) {
  const { isInTaskContext } = useRecordLayoutContext();

  const recipients = props.data.recipients
    .map((v) => {
      return (<PersonLink key={`recipient-${v.person.id}`} person={v.person} />);
    });

  const splitWithCommas = (value: ReactElement[]) => {
    const result: ReactElement[] = [];

    value.forEach((recipient, index) => {
      result.push(recipient);
      if (index !== recipients.length - 1) {
        result.push(<React.Fragment key={`${recipient.key}-separator`}>, </React.Fragment>);
      }
    });

    return result;
  };

  return (
    <>
      <PersonLink person={props.data.sender} />
      {!isInTaskContext && (
        <>
          {' sent the email titled '}
          <TaskLink
            task={props.data.task}
            project={props.data.project}
          />
        </>
      )}
      {isInTaskContext && ' sent this email '}
      {recipients.length > 0 && (
        <>
          {' to the recipient(s) '}
          {splitWithCommas(recipients)}
        </>
      )}
      .
    </>
  );
}

export default function EmailSentRecordLayout(
  props: RecordLayoutProps<EmailSentData>,
) {

  return (
    <RecordLayout
      text={<EmailSendRecordText data={props.record.data} />}
      date={props.record.when}
      iconType={HistoryEventIconType.EMAIL_SENT}
    />
  );
}
