import React from 'react';
import { AfterSubmitFunc} from 'ui-builder';
import { Person, } from 'nekst-api';
import usePeopleNavigator from '../peopleNavigator';
import {
  usePopupFormManager
} from 'features/nekst-widgets';
import { CreatePersonForm as BaseCreatePersonForm } from 'features/tasks-forms';


export default function CreatePersonForm(
  props: {
    afterSubmitFunc?: AfterSubmitFunc<Person>,
  },
) {
  const peopleNavigator = usePeopleNavigator();

  const afterSubmitFunc = async (data: Person) => {
    if (props.afterSubmitFunc) {
      await props.afterSubmitFunc(data);
    }

    peopleNavigator.openViewPersonPage(data.id);
  }

  return (
    <BaseCreatePersonForm afterSubmitFunc={afterSubmitFunc} />
  )
}

export function useCreatePersonForm() {
  const popupManager = usePopupFormManager();

  const open = () => {
    popupManager.openNotClosableForm(<CreatePersonForm />);
  };

  return {
    open,
  };
}
