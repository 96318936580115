import React, { useMemo } from 'react';
import {
  useProjectAssignmentsService,
  ProjectAssignment,
  ShortProfile,
  ShortProject,
  TeamRole,
} from 'nekst-api';
import {
  List,
  useListContext,
  usePopupManager,
  useShowContext
} from 'ui-builder';
import GridLayout, { GridLayoutTheme } from '../../shared/uibuilder/list/GridLayout';
import Cell from '../../shared/uibuilder/list/Cell';
import IconButton, { IconType } from '../../shared/widgets/IconButton';

import { useAuthenticationLoader } from '../../authentication/AuthenticationContext';
import ProjectLinkField from '../../projects/list/field/ProjectLinkField';
import { ConfirmDeletePopup } from 'features/nekst-widgets';

type GroupedAssignments = {
  projectId: number,
  assignments: ProjectAssignment[],
  _project: ShortProject,
  _person: ShortProfile
}

function RoleField() {
  const showContext = useShowContext<GroupedAssignments>();

  return (
    <>
      {showContext.data!.assignments.map((v) => v._role.name)
        .join(', ')}
    </>
  );
}

function ConfirmRemoveAssignmentsPopup(props: {
  assignments: GroupedAssignments
}) {
  const projectAssignmentsService = useProjectAssignmentsService();

  const listContext = useListContext<GroupedAssignments>();

  const deleteFunc = async () => {
    listContext._setLoading!(true);

    const assignmentsIds = props.assignments.assignments.map((v) => v.id);

    await projectAssignmentsService.deleteAssignments(
      props.assignments.projectId,
      assignmentsIds,
    );

    listContext._setData!(
      listContext.data!.filter((v) => v.projectId !== props.assignments.projectId),
    );
    listContext._setLoading!(false);
  };

  return (
    <ConfirmDeletePopup
      title="Confirm action"
      deleteFunc={deleteFunc}
      message={
        `Are you sure you want to unassign ${props.assignments._person.name.fullName}
         from the project "${props.assignments._project.name}"?`
      }
    />
  );
}

function RemoveAssignmentsButton() {
  const showContext = useShowContext<GroupedAssignments>();

  const popupManager = usePopupManager();

  return (
    <IconButton
      onClick={() => popupManager.openPopup(
        <ConfirmRemoveAssignmentsPopup
          assignments={showContext.data!}
        />,
      )}
      type={IconType.X}
      title="Delete Assignment"
    />
  );
}

export default function ManageAssignmentsList(
  props: {
    assignments: ProjectAssignment[]
  },
) {

  const { currentUser } = useAuthenticationLoader();

  const grouped = useMemo<GroupedAssignments[]>(() => {
    const map = props.assignments
      .reduce((prev: Record<number, GroupedAssignments>, currentValue: ProjectAssignment) => {
        let newValue = {
          ...prev,
        };
        if (!(currentValue.projectId in prev)) {
          newValue = {
            ...newValue,
            [currentValue.projectId]: {
              projectId: currentValue.projectId,
              _project: currentValue._project,
              _person: currentValue._person,
              assignments: [],
            },
          };
        }

        newValue[currentValue.projectId].assignments.push(currentValue);

        return newValue;
      }, {} as Record<number, GroupedAssignments>);

    return Object.values(map);
  }, []);

  return (
    <List<GroupedAssignments>
      getDataFunc={async () => grouped}
    >
      <GridLayout
        weights={[5, 5, 2]}
        theme={GridLayoutTheme.LIGHT}
        noDataMessage={(
          <div
            style={{
              textAlign: 'center',
              fontSize: '1.6rem',
              marginBottom: '2rem',
            }}
          >
            The person has no assignments to projects.
          </div>
        )}
      >
        <ProjectLinkField source="_project.name" idSource="_project.id" label="Transaction Name" />
        <Cell label="Role">
          <RoleField />
        </Cell>
        {(currentUser.teamRole === TeamRole.OWNER) && (
          <Cell label="Action">
            <RemoveAssignmentsButton />
          </Cell>
        )}
      </GridLayout>
    </List>
  );
}
