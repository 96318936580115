import React from 'react';
import useProjectNavigator from '../projectNavigator';
import { ButtonSize, OptionSelectionButton } from 'ui-builder';
import { ProjectPermission, useAuthorizationChecker } from 'authorization-scope';

enum ProjectPage {
  TASKS = 'tasks',
  DETAILS = 'details',
  CALENDAR = 'calendar',
  HISTORY = 'history',
  CLIENT_PORTAL_CONFIG = 'client-portal-config',
  DOCUMENTS = 'documents',
}
export default function ProjectPagesButtons() {
  const projectNavigator = useProjectNavigator();

  const { isGranted } = useAuthorizationChecker();

  return (
    <OptionSelectionButton<ProjectPage>
      text="Open..."
      size={ButtonSize.MEDIUM}
      options={[
        !projectNavigator.isTasksPage() && {
          value: ProjectPage.TASKS,
          label: "Tasks",
          onSelected: () => {
            projectNavigator.openTasksPage();
          }
        },
        !projectNavigator.isDetailsPage() && {
          value: ProjectPage.DETAILS,
          label: "Details",
          onSelected: () => {
            projectNavigator.openDetailsPage();
          },
        },
        !projectNavigator.isCalendarPage() && {
          value: ProjectPage.CALENDAR,
          label: "Calendar",
          onSelected: () => {
            projectNavigator.openCalendarPage();
          },
        },
        !projectNavigator.isHistoryPage() && {
          value: ProjectPage.HISTORY,
          label: "History",
          onSelected: () => {
            projectNavigator.openHistoryPage();
          },
        },
        !projectNavigator.isClientPortalConfigPage() && {
          value: ProjectPage.CLIENT_PORTAL_CONFIG,
          label: "Client Portal",
          onSelected: () => {
            projectNavigator.openClientPortalConfigPage();
          },
          disabled: !isGranted(ProjectPermission.READ_CLIENT_PORTAL_CONFIGURATION),
          title: !isGranted(ProjectPermission.READ_CLIENT_PORTAL_CONFIGURATION) ? 'Client Portal is not enabled for the transaction' : undefined,
        },
        !projectNavigator.isDocumentsPage() && {
          value: ProjectPage.DOCUMENTS,
          label: "Documents",
          onSelected: () => {
            projectNavigator.openDocumentsPage();
          }
        }
      ]}
    />
  );
}
