import { ValidationSchema } from 'validation-schema';

export function getSignUpValidationSchema(): ValidationSchema {
  return {
    name: {
      type: 'object',
      constraints: {
        required: true,
      },
      properties: {
        firstName: {
          type: 'string',
          constraints: {
            required: true,
          },
        },
        lastName: {
          type: 'string',
          constraints: {
            required: true,
          },
        },
      },
    },
    email: {
      type: 'string',
      constraints: {
        email: true,
        required: true,
      },
    },
    _email2: {
      type: 'string',
      constraints: {
        email: true,
        required: true,
        match: {
          value: 'email',
          message: 'The emails you entered do not match.',
        },
      },
    },
    password: {
      type: 'string',
      constraints: {
        required: true,
      },
    },
    _password2: {
      type: 'string',
      constraints: {
        required: true,
        match: {
          value: 'password',
          message: 'The passwords you entered do not match.',
        },
      },
    },
    _applyRules: {
      type: 'string',
      constraints: {
        required: {
          value: true,
          message: 'Please accept the terms and conditions to proceed with the account creation',
        },
      },
    },
  };
}
