import React from 'react';
import styles from './HistoryEventIcon.module.scss';

export enum HistoryEventIconType {
  TASK_COMPLETED = 'taskCompleted green-bg',
  COMMENT_CREATED = 'commentCreated',
  EMAIL_SENT = 'emailSent green-bg',

  EMAIL_OPENED = 'emailOpened',
  ASSIGNMENT_CREATED = 'assignmentCreated',
  NOTE_CREATED = 'noteCreated',
  PROJECT_LAUNCHED = 'projectLaunched',
  PROJECT_CREATED = 'projectCreated',
  STATUS_UPDATED = 'statusUpdated',
  ASSIGNMENT_REMOVED = 'assignmentRemoved',
  TASK_REOPENED = 'taskReopened yellow-bg',
  TASK_CREATED = 'taskCreated',
  SMS_SENT = 'smsSent'
}

export default function HistoryEventIcon(
  props: {
    iconType: HistoryEventIconType,
    size?: 'BIG' | 'STANDARD',
  },
) {

  const iconClasses = [
    styles.icon,
    props.size === 'BIG' ? 'big' : '',
    ...props.iconType ? [props.iconType] : [],
  ];

  return (
    <div className={iconClasses.join(' ')}>
      &nbsp;
    </div>
  );
}
