import { ListFilterService, VisibilityState, useFilterContext } from 'ui-builder';
import {
  AbstractTask,
  TasksFilter,
} from 'nekst-api';
import {
  useTypeFilterService,
  TypeFilterValue,
  useStatusFilterService,
  StatusFilterValue,
  useAssigneeFilterService,
  AssigneeFilterValue,
} from 'features/tasks-list-feature';

export type AdvancedFilterValue = {
  statusFilter: StatusFilterValue | undefined,
  typeFilter: TypeFilterValue | undefined,
  assigneesFilter: AssigneeFilterValue | undefined,
};

export interface AdvancedListFilterService<FilterType>
  extends ListFilterService<FilterType, AbstractTask> {
  toContextForm: (value: FilterType | undefined) => Partial<TasksFilter>;
}

export interface AdvancedFilterService
  extends ListFilterService<AdvancedFilterValue, AbstractTask> {
  resetFilter: () => void;
}

export default function useAdvancedFilterService(): AdvancedFilterService {
  const filterContext = useFilterContext();

  const statusFilter = useStatusFilterService();
  const typeFilter = useTypeFilterService();

  const assigneeFilter = useAssigneeFilterService();

  const getDefaultValue = (): AdvancedFilterValue => ({
    statusFilter: statusFilter.getDefaultValue(),
    typeFilter: typeFilter.getDefaultValue(),
    assigneesFilter: assigneeFilter.getDefaultValue(),
  });

  const getFilterValue = (): AdvancedFilterValue => {
    return {
      statusFilter: statusFilter.getFilterValue(),
      typeFilter: typeFilter.getFilterValue(),
      assigneesFilter: assigneeFilter.getFilterValue(),
    };
  };

  const setFilterValue = (value: AdvancedFilterValue) => {
    filterContext.setFilterValue({
      ...filterContext.filterValue!,
      ...statusFilter.toContextForm(value.statusFilter),
      ...typeFilter.toContextForm(value.typeFilter),
      ...assigneeFilter.toContextForm(value.assigneesFilter),
    });
  };

  const resetFilter = () => {
    filterContext.setFilterValue!({
      ...filterContext.filterValue!,
      ...statusFilter.toContextForm(statusFilter.getDefaultValue()),
      ...typeFilter.toContextForm(typeFilter.getDefaultValue()),
      ...assigneeFilter.toContextForm(assigneeFilter.getDefaultValue()),
    });
  };

  return {
    getName: () => 'ADVANCED',
    getFilterValue,
    setFilterValue,
    getDefaultValue,
    applyFilter: () => VisibilityState.VISIBLE,
    resetFilter,
  };
}
