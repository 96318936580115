import React, { useState } from 'react';
import { get } from 'lodash';
import TextButton from '../../projects/history/RecordLayout/TextButton';
import { useShowContext } from '../uibuilder/show/ShowReactContext';

export default function ShowMoreTextWrapper(props: {
  text: string,
  maxLength?: number,
  className?: string,
}) {
  const [showAll, setShowAll] = useState(false);

  const maxLength = props.maxLength || 450;

  const limit = maxLength * 1.1;

  const inverse = () => {
    setShowAll((prev) => !prev);
  };
  if (props.text.length > limit) {
    if (showAll) {
      return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <span className={props.className}>
          {props.text}
          {' '}
          <TextButton text="Collapse" onClick={inverse} />
        </span>
      );
    } else {
      return (
        <span className={props.className}>
          {`${props.text.substring(0, maxLength)}...`}
          {' '}
          <TextButton text="Show More" onClick={inverse} />
        </span>
      );
    }
  } else {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <span className={props.className}>
        {props.text}
      </span>
    );
  }
}

export function ShowMoreTextField(
  props: {
    source: string,
    value?: string
    maxLength?: number,
    className?: string,
  },
) {
  const { data } = useShowContext();

  if (data) {
    const value = props.value || get(data, props.source);

    return (
      <ShowMoreTextWrapper
        text={value}
        maxLength={props.maxLength}
        className={props.className}
      />
    );
  } else {
    return null;
  }
}
