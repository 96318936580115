import React from 'react';
import {
  CheckboxInput,
  FormTemplate,
  SubmitButton,
  useMassUpdateServiceRequired
} from 'ui-builder';
import useSetDueDateService from './setDueDateService';

import styles from './SetDueDate.module.scss';
import { MassUpdateFormProps } from '../../../shared/widgets/MassUpdate';
import { useEventsPublisher } from 'event-bus';
import { usePopupFormManager } from 'features/nekst-widgets';
import { DueDateConfig, PLAN_TASKS_UPDATED } from 'nekst-api';
import { DueDateConfigInput } from 'features/tasks-forms';

type FormData = {
  setNoDueDate: boolean,
  dueDateConfig?: DueDateConfig
};

function SetDueDateForm(props: MassUpdateFormProps) {
  const setDueDateService = useSetDueDateService();
  const eventsPublisher = useEventsPublisher();

  const submitFormFunc = async (data: FormData) => {
    await setDueDateService.setDueDate(
      props.ids,
      data.setNoDueDate ? null : data.dueDateConfig
    );

    eventsPublisher.publish(PLAN_TASKS_UPDATED, {});
  };

  return (
    <FormTemplate<FormData, void>
      submitFormFunc={submitFormFunc}
      afterSubmitFunc={props.afterSubmitFunc}
      title="Mass Update: Change Due Date"
      buttons={<SubmitButton text="Save" key="submit" />}
      className={styles.setDueDateForm}
      validationSchema={{
        dueDateConfig: {
          type: 'object',
          constraints: {
            required: {
              value: true,
              when: {
                setNoDueDate: {
                  empty: true,
                }
              }
            },
          },
          properties: {
            days: {
              type: 'int',
              constraints: {
                required: {
                  value: true,
                  message: 'Field is required',
                },
              },
            },
            when: {
              type: 'string',
              constraints: {
                required: {
                  value: true,
                  message: 'Field is required',
                },
              },
            },
          },
        },
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        style={{
          marginBottom: '1rem',
          display: 'block',
        }}
      >
        Choose a New Due/Send Date for the Selected Tasks:
      </label>
      <DueDateConfigInput
        source="dueDateConfig"
        label="Due"
        isDisabled={(data: FormData) => data.setNoDueDate}
      />
      <CheckboxInput source="setNoDueDate" label="No Due Date" />
    </FormTemplate>
  );
}

export default function useSetDueDateForm() {
  const popupManager = usePopupFormManager();

  const massUpdateService = useMassUpdateServiceRequired();

  const openForm = () => {
    popupManager.openForm(
      <SetDueDateForm ids={massUpdateService.getCheckedIds()} />,
      true,
    );
  };

  return {
    openForm,
  };
}
