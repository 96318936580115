import React, { ReactNode, useEffect, useState } from 'react';
import {
  useBillingService,
  BillingData,
  ComplianceCheck,
  ComplianceError,
  useAuthenticationContext,
  useEventsSubscriber,
  useProjectsService,
  PROJECT_UPDATED,
  PROJECTS_UPDATED_IN_BULK,
  TeamPermission,
  usePeopleService,
  PEOPLE_DELETED_IN_BULK,
  PERSON_DELETED, SEAT_LIMITS_UPDATED, useDateHelper,
} from 'nekst-api';

import {
  AfterSubmitFunc,
  Button,
  ButtonColor,
  ButtonSize,
  FormTemplate, isReactNative,
  ItemsList, SimpleBlock, Text, TextLink, usePopupManager
} from 'ui-builder';
import {
  ConfirmDeletePopup,
  Popup,
  usePopupFormManager,
  WarningMessage
} from 'features/nekst-widgets';
import useDataLoader from 'data-loader';
import { useAuthorizationChecker } from 'authorization-scope';
import { Colors, Spacings } from 'react-native-ui-lib';
import { ProjectsMultiDropdown } from '../Shared';

interface BillingNavigator {
  openBillingFunc: () => void,
  isBillingPageFunc: () => boolean,
}

function RemoveAllTeamMembersForm() {
  const peopleService = usePeopleService();

  const message = 'Are you sure you want to delete all team members from your team? '
    + 'This will delete their accounts and contact profiles, and any tasks assigned to them will '
    + 'be automatically reassigned to you.';
  return (
    <ConfirmDeletePopup
      title="Remove All Team Members"
      message={message}
      deleteFunc={peopleService.deleteAllTeamMembers}
    />
  );
}

function RemoveAllTeamMembersButton() {
  const popupManager = usePopupFormManager();

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Button
      size={ButtonSize.SMALL}
      color={ButtonColor.RED}
      isFilled
      isFlat
      onClick={() => {
        popupManager.openForm(<RemoveAllTeamMembersForm />);
      }}
      text="this button"
    />
  );
}

type BulkProjectsArchiveRequest = {
  excludeIds: number[]
}

function BulkProjectsArchiveForm(props: {
  limit: number,
  afterSubmitFunc?: AfterSubmitFunc,
}) {
  const projectsService = useProjectsService();
  return (
    <FormTemplate<BulkProjectsArchiveRequest>
      submitFormFunc={async (request) => {
        await projectsService.archiveAllProjects(request.excludeIds);
        return request;
      }}
      afterSubmitFunc={props.afterSubmitFunc}
      title="Archive Transactions"
    >
      <ProjectsMultiDropdown
        source="excludeIds"
        label="Select Transactions to Keep (all other will be archived)"
        max={props.limit}
      />
    </FormTemplate>
  );
}

function BulkProjectsArchiveButton(props: { limit: number }) {
  const popupManager = usePopupFormManager();

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Button
      size={ButtonSize.SMALL}
      color={ButtonColor.RED}
      isFilled
      isFlat
      onClick={() => {
        popupManager.openForm(<BulkProjectsArchiveForm limit={props.limit} />);
      }}
      text="this button"
    />
  );
}

function TeamMembersComplianceWarning(props: {
  membersCount: number,
  membersLimit: number,
  adminsCount: number,
  adminsLimit: number,
  isNotPaid: boolean,
}) {

  if (props.adminsLimit === 0 && props.membersLimit === 0) {
    return (
      <Text>
        Team members: Your current subscription does not support team members. To ensure you remain
        within your plan&apos;s limits, please remove all the team members by clicking
        {' '}
        <RemoveAllTeamMembersButton />
        . Any
        tasks currently assigned to these team members will be reassigned to you automatically.
      </Text>
    );
  } else {
    if (props.isNotPaid) {

      return (
        <Text>
          There&apos;s an issue with your seat subscription payment. Please resolve it or contact us for assistance.
        </Text>
      );
    } else {
      const membersExceed = props.membersCount > props.membersLimit;
      const adminsExceed = props.adminsCount > props.adminsLimit;
      return (
        <Text>
          {membersExceed ? `Number of team members (${props.membersCount}) exceeds the limit of ${props.membersLimit}. ` : ''}
          {adminsExceed ? `Number of team administrators (${props.adminsCount}) exceeds the limit of ${props.adminsLimit}. ` : ''}
          To ensure you remain within your plan&apos;s limits, please remove any excess team member
          or adjust the number of seats by navigating to the Billing page.
        </Text>
      );
    }

  }
}

function ProjectsComplianceWarning(props: {
  currentCount: number,
  limit: number,
}) {
  return (
    <Text>
      Transactions: The number of transactions currently created on your account (
      {props.currentCount}
      ) exceeds the limit set by your new subscription plan (
      {props.limit}
      ).To stay within your new subscription plan&apos;s limits, we recommend that you go to the
      transactions page and archive any excess transactions. Please keep a maximum of
      {` ${props.limit} `}
      transactions in the &quot;Active&quot; or &quot;Closed&quot; statuses. To quickly select which
      transactions to keep and archive the rest, use
      {' '}
      <BulkProjectsArchiveButton limit={props.limit} />
      .
    </Text>
  );
}

function BillingPageButton(props: BillingNavigator) {

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Button
      size={ButtonSize.SMALL}
      color={ButtonColor.GREEN}
      isFilled
      isFlat
      onClick={() => {
        props.openBillingFunc();
      }}
      text="here"
    />
  );
}

function NavigateToBillingPageAdvice(props: BillingNavigator) {

  if (!props.isBillingPageFunc()) {
    return (
      <Text>
        You can navigate to the Billing page by clicking
        {' '}
        <BillingPageButton {...props} />
      </Text>
    );
  } else {
    return null;
  }
}

interface SubscriptionComplianceLayoutProps {
  billingNavigator: BillingNavigator,
  planName: string,
  complianceErrors: ComplianceError[]
  loading: boolean,
  gracePeriodEndsAt: Date,
}

function SubscriptionComplianceContent(
  props: SubscriptionComplianceLayoutProps,
) {

  let introMessage: ReactNode | null;
  if (props.planName !== 'Team Pro') {
    introMessage = (
      <Text>
        You have successfully downgraded your subscription plan to
        {' '}
        {props.planName!}
        .
        Please note that your new plan has certain limitations that may affect it's ability to be
        used to manage transactions moving forward:
      </Text>
    );
  } else {
    introMessage = (
      <Text>
        It seems there are some issues with your subscription. Specifically:
      </Text>
    );
  }

  const dateHelper = useDateHelper();

  return (
    <SimpleBlock style={{ gap: Spacings.s2 }}>
      {introMessage}
      <ItemsList
        items={props.complianceErrors.map((error) => {
          switch (error.checkId) {
            case ComplianceCheck.TEAM_MEMBERS_COUNT:
              return (
                <TeamMembersComplianceWarning
                  key={`error-${error.checkId}`}
                  {...error}
                />
              );
            case ComplianceCheck.PROJECTS_COUNT:
              return (
                <ProjectsComplianceWarning
                  currentCount={error.currentCount}
                  limit={error.limit}
                  key={`error-${error.checkId}`}
                />
              );
            default:
              return null;
          }
        })}
      />

      <Text>
        Please note that you have a grace period till {dateHelper.toDateTimeView(props.gracePeriodEndsAt!)} to make these changes; after that, the
        application functionality will be locked.
        {' '}
        {props.planName !== 'Team Pro' && (<>
            If you would like to maintain your current data,
            you may consider upgrading your team&apos;s subscription to a plan that better suits
            your needs.
            {' '}
          </>
        )}
        <NavigateToBillingPageAdvice {...props.billingNavigator} />

      </Text>
    </SimpleBlock>
  );
}

function SubscriptionComplianceWebLayout(props: SubscriptionComplianceLayoutProps) {
  return (
    <WarningMessage className={props.loading ? 'loading' : ''}>
      <SubscriptionComplianceContent {...props} />
    </WarningMessage>
  );
}

function SubscriptionComplianceMobileLayout(props: SubscriptionComplianceLayoutProps) {
  const popupManager = usePopupManager();

  function openDetails() {
    popupManager.openPopup(
      <Popup
        title="Subscription Issue"
        content={
          <SubscriptionComplianceContent
            {...props}
            billingNavigator={{
              ...props.billingNavigator,
              openBillingFunc: () => {
                popupManager.closePopup();

                setTimeout(() => {
                  props.billingNavigator.openBillingFunc();
                }, 100);
              }
            }}
          />
        }
      />
    );
  }

  useEffect(() => {
    openDetails();
  }, []);

  if (props.billingNavigator.isBillingPageFunc()) {
    return (
      <WarningMessage>
        There is an issue with your subscription.
        <TextLink onClick={openDetails} labelStyle={{ color: Colors.white }}>
          Get More Details.
        </TextLink>
      </WarningMessage>
    );
  } else {
    return null;
  }
}

function SubscriptionComplianceLayout(props: SubscriptionComplianceLayoutProps) {
  if (isReactNative()) {
    return (<SubscriptionComplianceMobileLayout {...props} />);
  } else {
    return (<SubscriptionComplianceWebLayout {...props} />);
  }
}

function SubscriptionComplianceWidget(
  props: BillingNavigator,
) {

  const billingService = useBillingService();

  const [complianceErrors, setComplianceErrors] = useState<ComplianceError[]>();

  const [billingData, setBillingData] = useState<BillingData>();

  const [version, setVersion] = useState(0);

  const { loading } = useDataLoader(
    billingService.getComplianceErrors,
    setComplianceErrors,
    version,
    billingData?.teamBilling.planName,
  );

  function refresh() {
    setVersion((prev) => prev + 1);
  }

  useEventsSubscriber(
    'SubscriptionComplianceWidget',
    {
      [PROJECT_UPDATED]: refresh,
      [PROJECTS_UPDATED_IN_BULK]: refresh,
      [PEOPLE_DELETED_IN_BULK]: refresh,
      [PERSON_DELETED]: refresh,
      [SEAT_LIMITS_UPDATED]: refresh,
    },
  );

  useDataLoader(
    () => billingService.getBillingData(false),
    setBillingData,
  );

  if (complianceErrors?.length && billingData) {
    const planName = billingData.teamBilling.planName;

    return (
      <SubscriptionComplianceLayout
        billingNavigator={props}
        planName={planName!}
        complianceErrors={complianceErrors}
        loading={loading}
        gracePeriodEndsAt={billingData.teamBilling.complianceGracePeriodEndsAt!}
      />
    );
  } else {
    return null;
  }
}

export function SubscriptionComplianceWarning(props: BillingNavigator) {
  const { isGranted } = useAuthorizationChecker();

  const authenticationContext = useAuthenticationContext();

  if (
    authenticationContext?.user
    && !isGranted(TeamPermission.COMPLIANT_WITH_SUBSCRIPTION)
    && isGranted(TeamPermission.MANAGE_BILLING)
  ) {
    return (
      <SubscriptionComplianceWidget {...props} />
    );
  } else {
    return null;
  }
}
