import { useBackendApi } from './backendApi';
import { useTokenContext } from '../Authentication';
import { useEventsPublisher } from 'event-bus';

export type LoginRequest = {
  username: string,
  password: string
}

export const LOGGED_IN = 'USER_LOGGED_IN';

export function useLoginService() {
  const nekstApi = useBackendApi();
  const tokenContext = useTokenContext();

  const eventsPublisher = useEventsPublisher();

  const login = async (request: LoginRequest): Promise<void> => {
    const response = await nekstApi.post('/login', request) as { token: string };

    await tokenContext.setToken(response.token);

    setTimeout(() => {
      eventsPublisher.publish(LOGGED_IN, {});
    }, 500);
  };

  return {
    login,
  };
}
