import React, { ReactElement } from 'react';

import { ProjectType, useProjectTasksService, EmailTask } from 'nekst-api';
import {
  EmailTaskSmartTagsWysiwyg, EmailTitleSmartTagsInput, ProjectParentTaskRelationInput,
  SendEmailButton,
  SendFromUserInput
} from 'features/tasks-forms';
import FormTemplate, { AfterSubmitFunc } from '../../../../../shared/uibuilder/form/FormTemplate';
import Row3x3x6x from '../../../../../shared/web/uitheme/form/Row3x3x6x';
import Checkbox from '../../../../../shared/uibuilder/form/input/Checkbox';
import RelativeRow, { EmptyCell } from '../../../../../shared/web/uitheme/form/RelativeRow';
import Row8x4x from '../../../../../shared/web/uitheme/form/Row8x4x';
import Separator from '../../../../../shared/web/uitheme/form/Separator';
import EmailTaskAssignmentsInput from './EmailTaskAssignmentsInput';
import TaskCommentsListInput from '../comments/TaskCommentsListInput';
import Right from '../../../../../shared/web/Right';
import OverflowHidden from '../../../../../shared/widgets/OverflowHidden';
import ProjectDropdown from '../ProjectDropdown';
import SendDate from './SendDate';
import { ProjectIdContext } from 'features/nekst-widgets';

export interface BaseEmailTaskFormProps {
  submitFormFunc: (data: EmailTask) => Promise<EmailTask>;
  afterSubmitFunc?: AfterSubmitFunc,
  data?: EmailTask;
  title: string | ReactElement;
  buttons: ReactElement
  initialData?: EmailTask,
  isDisabled?: boolean | ((data: EmailTask) => boolean)
  showProjectDropdown?: boolean,
  children?: ReactElement
  // eslint-disable-next-line react/no-unused-prop-types
  projectType?: ProjectType,
}

export default function BaseEmailTaskForm(props: BaseEmailTaskFormProps) {

  const projectTasksService = useProjectTasksService();

  const isDisabled = props.isDisabled ?? false;

  const showProjectDropdown = props.showProjectDropdown || false;

  return (
    <ProjectIdContext projectId={props.initialData?.projectId || 0}>
      <FormTemplate<EmailTask>
        title={props.title}
        submitFormFunc={props.submitFormFunc}
        afterSubmitFunc={props.afterSubmitFunc}
        buttons={props.buttons}
        isDisabled={isDisabled}
        initialData={props.initialData || props.data}
        validationSchema={projectTasksService.getEmailTaskValidationSchema()}
      >
        {showProjectDropdown && (<ProjectDropdown source="projectId" />)}
        <EmailTitleSmartTagsInput source="name" label="Task Name / Email Subject Line" />
        <Row3x3x6x>
          <Checkbox source="isKeyDate" label="Key Date" />
          <Checkbox source="isPrivate" label="Private" />
          <Checkbox source="isAutoSent" label="Autosend" />
        </Row3x3x6x>
        <RelativeRow
          weights={[7, 5]}
          isVisible={(data) => !data.parentTaskRelation || data.dueDate}
        >
          <RelativeRow weights={[5, 7]}>
            <SendDate />
            <SendFromUserInput />
          </RelativeRow>
        </RelativeRow>
        <Row8x4x
          isVisible={(data) => !!data.parentTaskRelation && !data.dueDate}
        >
          <ProjectParentTaskRelationInput source="parentTaskRelation" label="Send" />
          <RelativeRow weights={[1, 11]}>
            <EmptyCell />
            <SendFromUserInput />
          </RelativeRow>
        </Row8x4x>
        <Separator />
        <EmailTaskSmartTagsWysiwyg
          source="description"
          label="Email message"
          projectId={(data) => data.projectId}
          projectType={props.data?._project.type || props.projectType}
        />
        <OverflowHidden>
          <Right isVisible={(data) => data.id}>
            <SendEmailButton addPreviewPrefix />
          </Right>
        </OverflowHidden>
        <Separator />
        <EmailTaskAssignmentsInput />
        <Separator isVisible={(data) => data.id} />
        <TaskCommentsListInput isVisible={(data) => data.id} />
        {props.children || null}
      </FormTemplate>
    </ProjectIdContext>
  );
}
