import React, { ReactNode } from 'react';

export default function Cell(
  props: {
    // eslint-disable-next-line react/no-unused-prop-types
    label: string,
    // eslint-disable-next-line react/no-unused-prop-types
    weight?: number,
    children: ReactNode,
    // eslint-disable-next-line react/no-unused-prop-types
    sortName?: string,
    isNoWrap?: boolean,
  },
) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{props.children}</>;
}
