import { useEventsPublisher } from 'event-bus';
import { useBackendApi } from './backendApi';
import { useTokenContext } from '../Authentication';

export const LOGGED_OUT = 'USER_LOGGED_OUT';

export function useLogoutService() {
  const nekstApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const userSessionStorage = useTokenContext();

  const logout = async () => {
    try {
      await nekstApi.delete('/logout');
    } catch (e) { /* empty */
    }

    await userSessionStorage.setToken(undefined);
    eventsPublisher.publish(LOGGED_OUT, {});
  };

  return {
    logout,
  };
}
