import React from 'react';
import { Button, ButtonColor, ButtonSize, isReactNative, useShowContext } from 'ui-builder';
import { AbstractTask, TaskPermission, TaskStatus, useTasksCompletionService } from 'nekst-api';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useTaskCompletionErrorHandler } from 'features/tasks-list-feature';

export function CompleteTaskButton() {
  const taskCompletionService = useTasksCompletionService();

  const showContext = useShowContext<AbstractTask>();

  const taskCompletionErrorHandler = useTaskCompletionErrorHandler();

  const isCompleted = showContext.data!.status === TaskStatus.COMPLETED;

  const text = isCompleted ? 'Reopen' : 'Complete';

  if (showContext.data!.permissions.includes(TaskPermission.COMPLETE)) {
    return (
      <Button
        size={ButtonSize.MEDIUM}
        isFilled={isReactNative()}
        color={ButtonColor.GREEN}
        onClick={async () => {
          try {
            if (isCompleted) {
              await taskCompletionService.unCompleteTask(showContext.data!.id);
            } else {
              await taskCompletionService.completeTask(showContext.data!.id, false);
            }
          } catch (e) {
            taskCompletionErrorHandler.handleError(
              showContext.data!,
              e,
            );
          }
        }}
        text={text}
      />
    );
  } else {
    return null;
  }

}
