import React from 'react';
import { useProjectTasksService } from 'nekst-api';
import { usePopupManager } from 'ui-builder';
import { ConfirmDeletePopup } from 'features/nekst-widgets';


export interface DeleteTaskPopupProps {
  taskId: number
  title: string
}

export function DeleteTaskPopup(props: DeleteTaskPopupProps) {
  const tasksService = useProjectTasksService();

  return (
    <ConfirmDeletePopup
      deleteFunc={() => tasksService.deleteTask(props.taskId)}
      message={`Are you sure you want to delete task "${props.title}"?`}
    />
  );
}

export default function useDeleteTaskPopupManager() {
  const popupManager = usePopupManager();

  const openPopup = (taskId: number, taskTitle: string) => {
    popupManager.openPopup(
      <DeleteTaskPopup taskId={taskId} title={taskTitle} />,
    );
  };

  return {
    openPopup,
  };
}
