import React, { ReactElement } from 'react';
import { AssigneeWidgetLayoutProps, UserLogoSize } from './types';
import { UrlImage } from '../../UrlImage';

import styles from './AssigneeWidgetLayout.module.scss';
import { Badge } from '../../Badge';

export default function AssigneeWidgetLayoutWeb(
  props: AssigneeWidgetLayoutProps,
) {
  const withName = props.withName || false;
  const isFloat = true;

  let element: ReactElement;

  const className = `${styles.userLogo} ${props.logoSize || UserLogoSize.BIG} ${isFloat ? styles.float : ''} ${props.withName ? styles.withMargin : ''}`;

  if (props.profile._avatarUrl) {
    element = (
      <div className={className}>
        <UrlImage
          src={props.profile._avatarUrl.resized}
          className={className}
          alt={props.profile.name.fullName}
        />
        {props.badge && (<Badge number={props.badge} withBorder />)}
      </div>

    );
  } else {
    element = (
      <div
        className={className}
        title={props.profile.name.fullName}
      >
        {props.profile.name.firstName.trim()
          .charAt(0)}
        {props.profile.name.lastName.trim()
          .charAt(0)}
        {props.badge && (<Badge number={props.badge} withBorder />)}
      </div>
    );
  }

  const additionalInfo = React.Children.toArray(props.additionalInfo);

  return (
    <>
      {element}
      <div className={styles.detailsContainer}>
        {withName && (
          <div className={styles.userName}>
            {props.profile.name.fullName}
          </div>
        )}
        {props.additionalInfo && (
          <div>
            {additionalInfo.map((item, index) => {
                return (
                  <div key={index} className={styles.additionalInfo}>
                    {item}
                  </div>
                )
              }
            )}
          </div>
        )}
      </div>

    </>
  );
}
