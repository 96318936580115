import React from 'react';
import PageLink from './PageLink';
import { PersonData } from 'nekst-api';
import { useAuthenticationLoader } from '../../../authentication/AuthenticationContext';

interface Props {
  person: PersonData;
}

export default function PersonLink(props: Props) {
  const { currentUser } = useAuthenticationLoader();

  let fullName;
  if (props.person.id === currentUser.id) {
    fullName = 'You';
  } else {
    fullName = props.person.fullName;
  }

  return (
    <PageLink
      text={fullName}
      action={() => {
        // ignored
      }}
      isDeleted={props.person.isDeleted}
    />
  );
}
