// @ts-nocheck

import React from 'react';
import Select, { MultiValueGenericProps } from 'react-select';
import useUiTheme from '../../../uibuilder/uiTheme';
import { MultiDropdownLayoutProps } from '../../../uibuilder/form/input/MultiDropdown';
import { Option } from '../../../uibuilder/form/input';

export default function MultiDropdownLayout(
  props: MultiDropdownLayoutProps,
) {
  const uiTheme = useUiTheme();

  const valueMap: any = [];

  (props.value || []).forEach((item) => {
    valueMap[item as any] = true;
  });

  const currentValue = props.value && props.options
    ? props.options.filter((option) => valueMap[option.value]) : [];

  const formatGroupLabel = (data: any) => (
    <div>
      <span>
        {data.label}
      </span>
      <span>{data.options.length}</span>
    </div>
  );

  const multiValueContainer = ({
    selectProps,
    data,
  }: MultiValueGenericProps) => {
    const { label } = data;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex((selected: Option) => selected.label === label);
    const isLastSelected = index === allSelected.length - 1;
    const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ', ';
    return `${label}${labelSuffix}`;
  };

  return (
    <uiTheme.forms.BaseInputLayout
      name={props.name}
      errors={props.errors}
      label={props.label}
      hideLabel={props.hideLabel}
    >
      <Select
        name={props.name}
        className="nekst-dropdown"
        classNamePrefix="nekst-dropdown"
        options={props.options}
        isClearable
        value={currentValue}
        placeholder={props.placeholder}
        isDisabled={props.isDisabled}
        isMulti
        formatGroupLabel={formatGroupLabel}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        onChange={(newValue: any) => {
          props.onChangeCallback({
            target: {
              value: newValue ? newValue.map((item: Option) => item.value) : null,
            },
          });
        }}
        components={{
          IndicatorSeparator: () => null,
          MultiValueContainer: multiValueContainer,
        }}
        data-testid={`${props.name}-dropdown`}
      />
    </uiTheme.forms.BaseInputLayout>
  );
}
