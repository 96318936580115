import React from 'react';
import { Dropdown, DropdownProps } from 'ui-builder';

export default function NumberDropdown(props: DropdownProps) {
  const onChangeCallback = (event: any) => {
    props.onChangeCallback!({
      remindDays: parseInt(event.remindDays, 10),
    });
  };
  return (
    <Dropdown
      {...props}
      onChangeCallback={onChangeCallback}
    />
  );
}
