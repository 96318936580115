export default function useImageHelper() {

  const toDataUrl = async (file: Blob): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onloadend = async () => {
        resolve(fileReader.result as string);
      };

      fileReader.onerror = reject;

      fileReader.readAsDataURL(file);
    });
  };

  const getImageSize = async (file: string | Blob): Promise<{ width: number, height: number }> => {

    let imageString: string;

    if (typeof file === 'string') {
      imageString = file;
    } else {
      imageString = await toDataUrl(file);
    }

    return new Promise((resolve, reject) => {
      const image = new Image();

      // Set up an event listener for when the Image has finished loading
      image.onload = () => {
        // Access the dimensions of the loaded image
        const width = image.width;
        const height = image.height;

        resolve({
          width,
          height,
        });
      };

      image.onerror = reject;

      // Set the source of the Image object as the result of the FileReader
      image.src = imageString;
    });

  };

  return {
    getImageSize,
    toDataUrl,
  };
}
