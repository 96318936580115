import React, { useState } from 'react';
import { TextInputProps, TextLayoutProps } from './TextInput';
import { Tag } from './SmartTagsWysiwyg';
import { useNewUiTheme } from '../../newUiTheme';
import { useInputHelper } from './inputHelper';
import useDataLoader from 'data-loader';
import { TextAreaProps } from './TextArea';
import { LoadingAnimation } from '../../Common';


export interface SmartTagsTextAreaLayoutProps extends TextLayoutProps {
  availableTags: Tag[],
  hideHowToUseLink?: boolean,
}

interface SmartTagsTextAreaProps extends TextInputProps {
  hideHowToUseLink?: boolean,
  availableTags: Tag[],
}

export function SmartTagsTextArea(props: SmartTagsTextAreaProps) {
  const uiTheme = useNewUiTheme();

  const inputHelper = useInputHelper(props);

  return (
    <uiTheme.inputs.SmartTagsTextAreaLayout
      {...inputHelper.getBaseInputLayoutProps()}
      availableTags={props.availableTags}
      hideHowToUseLink={props.hideHowToUseLink}
      hideLabel={props.hideLabel}
    />
  );
}


interface SmartTagsTextAreaWithLoadProps extends TextAreaProps {
  hideHowToUseLink?: boolean,
  getTagsMethod: () => Promise<Tag[]>,
  dependencies: any[]
}

export function SmartTagsTextAreaWithLoad(props: SmartTagsTextAreaWithLoadProps) {
  const [smartTags, setSmartTags] = useState<Tag[]>();
  useDataLoader(
    props.getTagsMethod,
    setSmartTags,
    ...props.dependencies
  );

  const inputHelper = useInputHelper(props);

  const value = inputHelper.getValue();

  if (smartTags !== undefined || value === undefined) {
    return (
      <SmartTagsTextArea
        {...props}
        availableTags={smartTags || []}
        source={props.source}
      />
    );
  } else {
    return (<LoadingAnimation />);
  }
}
