import React, { ReactElement } from 'react';
import {
  useBillingService,
  IntervalType,
  SubscriptionCreationResultType,
  SubscriptionPlanId,
  SubscriptionStatus, TeamPermission,
} from 'nekst-api';
import { useAuthorizationChecker } from 'authorization-scope';
import { Button, ButtonColor } from 'ui-builder';

import BorderedBox from '../../shared/web/layout/BorderedBox';
import styles from './BillingSettings.module.scss';
import Separator from '../../shared/web/uitheme/form/Separator';
import { useBillingContext } from './BillingPageContext';

function SubscribeButton(
  props: {
    planId: SubscriptionPlanId,
  },
) {
  const { isGranted } = useAuthorizationChecker();

  const billingService = useBillingService();
  const billingContext = useBillingContext();

  if (isGranted(TeamPermission.MANAGE_BILLING)) {
    const billingData = billingContext.getData();

    const plan = billingData.availablePlans.find((item) => item.id === props.planId);
    const monthPrice = plan?.prices.find((item) => item.recurringInterval === IntervalType.MONTH);

    if (monthPrice) {
      return (
        <Button
          text="Choose Plan"
          color={ButtonColor.GREEN}
          isFilled
          onClick={async () => {
            const result = await billingService.createSubscription(monthPrice.id);

            if (result.type === SubscriptionCreationResultType.CHECKOUT_SESSION_CREATED) {
              window.location.href = result.url!;
            } else {
              billingContext.refresh();
            }
          }}
          customClass={styles.subscribeButton}
        />
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
}

function AbstractPlanWidget(
  props: {
    subscriptionPlanId: SubscriptionPlanId
    features: (string | ReactElement)[],
  },
) {
  const billingData = useBillingContext()
    .getData();

  const subscriptionPlan = billingData.availablePlans
    .find((item) => item.id === props.subscriptionPlanId)!;

  const monthPrice = subscriptionPlan.prices.find(
    (item) => item.recurringInterval === IntervalType.MONTH,
  )!;

  const yearPrice = subscriptionPlan.prices.find(
    (item) => item.recurringInterval === IntervalType.YEAR,
  );

  let priceString;

  if (monthPrice.amount) {
    priceString = `$${monthPrice.amount} per month`;
  } else {
    priceString = 'Free';
  }

  if (yearPrice) {
    priceString += ` / $${yearPrice.amount} per year`;
  }

  const isSubscriptionMissing = billingData.teamBilling.status === SubscriptionStatus.NOT_FOUND;

  let title = subscriptionPlan.name;

  if (billingData.teamBilling.planId === props.subscriptionPlanId) {
    title += ' (Current Plan)';
  }

  return (
    <BorderedBox title={title} className={styles.item}>
      <div className={styles.price}>
        {priceString}
      </div>
      <Separator />
      <ul className={styles.features}>
        {props.features.map((feature) => (
          <li className={styles.feature}>
            {feature}
          </li>
        ))}
      </ul>
      {isSubscriptionMissing && (
        <SubscribeButton planId={props.subscriptionPlanId} />
      )}
    </BorderedBox>
  );
}

function AgentLitePlanWidget() {
  return (
    <AbstractPlanWidget
      subscriptionPlanId={SubscriptionPlanId.AGENT_LITE}
      features={[
        'Up to 5 Total Transactions',
        'Intuitive Task Management System',
        'Calendar Integration',
        'Unlimited Contacts',
        '4 Pre-loaded Plans',
        'Unlimited Custom Plans',
        'Email Notifications',
        'Support: Chat',
      ]}
    />
  );
}

function AgentProWidget() {
  return (
    <AbstractPlanWidget
      subscriptionPlanId={SubscriptionPlanId.AGENT_PRO}
      features={[
        <>
          <b>Unlimited</b>
          {' '}
          Transactions
        </>,
        'Intuitive Task Management System',
        'Calendar Integration',
        'Unlimited Contacts',
        '4 Pre-loaded Plans',
        'Unlimited Custom Plans',
        'Email Notifications',
        'Support: Chat',
      ]}
    />
  );
}

function TeamProWidget() {
  return (
    <AbstractPlanWidget
      subscriptionPlanId={SubscriptionPlanId.TEAM_PRO}
      features={[
        <>
          <b>Unlimited</b>
          {' '}
          Transactions
        </>,
        'Intuitive Task Management System',
        <>
          Assign Tasks to
          {' '}
          <b>Any Party</b>
        </>,
        'Calendar Integration',
        'Unlimited Contacts',
        '4 Pre-loaded Plans',
        'Unlimited Custom Plans',
        'Email Notifications',
        'Support: Chat & Phone',
      ]}
    />
  );
}

export default function SubscriptionPlansWidget() {
  const { isGranted } = useAuthorizationChecker();

  if (isGranted(TeamPermission.MANAGE_BILLING)) {
    return (
      <div className={styles.subscriptions}>
        <AgentLitePlanWidget />
        <AgentProWidget />
        <TeamProWidget />
      </div>
    );
  } else {
    return null;
  }
}
