import React from 'react';
import { ConfirmDeletePopupProps } from './types';
import {
  Button,
  ButtonColor,
  ButtonSize,
  isReactNative,
  SimpleBlock,
  Text,
  usePopupManager
} from 'ui-builder';
import { ClosePopupButton, Popup } from '../Popup';
import styles from './ConfirmPopup.module.scss';

export function ConfirmDeletePopup(props: ConfirmDeletePopupProps) {

  const popupManager = usePopupManager();

  const deleteFunc = async () => {
    await props.deleteFunc();
    popupManager.closePopup(true);
  };

  const messageCentered = props.messageCentered !== undefined ? props.messageCentered : true;

  return (
    <Popup
      title={props.title || 'Confirm Action'}
      content={(
        <SimpleBlock
          className={`${styles.message} ${messageCentered ? styles.centered : ''}`}
        >
          <Text>{props.message}</Text>
        </SimpleBlock>
      )}
      isCentered
      buttons={(
        <>
          <Button
            text="Confirm"
            isFilled
            onClick={deleteFunc}
            key="delete"
            color={ButtonColor.RED}
            size={isReactNative() ? ButtonSize.MEDIUM : ButtonSize.BIG}
            dataKey="confirm-delete-button"
          />
          <ClosePopupButton
            key="close"
            size={isReactNative() ? ButtonSize.MEDIUM : ButtonSize.BIG}
          color={ButtonColor.GRAY}
          />
        </>
      )}
    />
  );

}
