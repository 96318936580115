import React, { ReactElement } from 'react';
import { ViewStyle } from 'react-native';
import {  useNewUiTheme } from '../newUiTheme';
import {
  ShowContext,
} from './ShowContext';

export interface ShowLayoutProps {
  title: string | ((data: any) => string),
  titleLine2?: string | ((data: any) => string),
  buttons?: ReactElement[]
  children: ReactElement | ReactElement[]
  className?: string,
  style?: ViewStyle,
}

export interface ShowProps<DataType> {
  id: number,
  title: string | ((_data: DataType) => string),
  titleLine2?: string | ((data: DataType) => string)
  getDataFunc: (_id: number) => Promise<DataType>,
  children: ReactElement | ReactElement[],
  buttons?: ReactElement[],
  className?: string,
  style?: ViewStyle,
}

export function Show<DataType = Record<string, any>>(props: ShowProps<DataType>) {
  const uiTheme = useNewUiTheme();
  return (
    <ShowContext
      getDataFunc={() => props.getDataFunc(props.id)}
      dependencies={[props.id]}
    >
      <uiTheme.show.ShowLayout
        title={props.title}
        titleLine2={props.titleLine2}
        buttons={props.buttons}
        className={props.className}
        style={props.style}
      >
        {props.children}
      </uiTheme.show.ShowLayout>
    </ShowContext>
  );
}

