import {
  DateParseValue,
  NumberParseValue,
  PlanTask,
  ProjectDetailsField, RelativeDateOffsetValue,
  StringParseValue
} from 'nekst-api';

export enum EntryType {
  NAME = 'name',
  DETAILS_FIELD = 'details_field',
}

export type VerifyDataEntry = {
  type: EntryType,
  name: string,
  primary?: boolean,
  detailsField?: ProjectDetailsField,
  parsedValue?: StringParseValue | NumberParseValue | DateParseValue | RelativeDateOffsetValue,
  relatedTask?: PlanTask
}
