import { useEventsPublisher } from 'event-bus';
import { useBackendApi } from './backendApi';

export type ServiceProviderGroup = {
  id: number,
  name: string
}

export type ServiceProviderRequest = {
  name: string,
}

export const SERVICE_PROVIDERS_UPDATED = 'SERVICE_PROVIDERS_UPDATED';

export function useServiceProvidersGroupsService() {
  const nekstApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const getAll = async () => {
    return await nekstApi.get('/serviceprovidersgroups') as ServiceProviderGroup[];
  };

  const getWithAssignmentsOnly = async () => {
    return await nekstApi.get('/serviceprovidersgroups?withAssignmentsOnly=1') as ServiceProviderGroup[];
  };

  const create = async (request: ServiceProviderRequest) => {
    const result = await nekstApi.post('/serviceprovidersgroups', request) as ServiceProviderGroup;

    eventsPublisher.publish(SERVICE_PROVIDERS_UPDATED, {});

    return result;
  };

  const update = async (id: number, request: ServiceProviderRequest) => {
    const result = await nekstApi.put(`/serviceprovidersgroups/${id}`, request) as ServiceProviderGroup;

    eventsPublisher.publish(SERVICE_PROVIDERS_UPDATED, {});
    return result;
  };

  const remove = async (id: number) => {
    await nekstApi.delete(`/serviceprovidersgroups/${id}`);
    eventsPublisher.publish(SERVICE_PROVIDERS_UPDATED, {});
  };

  return {
    getAll,
    getWithAssignmentsOnly,
    create,
    update,
    remove,
  };
}
