import React, { useState } from 'react';

import styles from './PeopleStatistic.module.scss';
import { useFilterContext } from 'ui-builder';
import { PeopleFilter, PERSON_CREATED } from 'nekst-api';
import usePeopleStatisticService, { PeopleStatistic } from './peopleStatisticService';
import { useEventsSubscriber } from 'event-bus';

export default function PeopleStatisticWidget() {
  const [statistic, setStatistic] = useState<PeopleStatistic | null>(null);

  const [loading, setLoading] = useState(false);

  const filterContext = useFilterContext<PeopleFilter>();

  const peopleStatisticService = usePeopleStatisticService();

  async function loadStatistic() {
    setLoading(true);
    setStatistic(await peopleStatisticService.getPeopleStatistic(filterContext.filterValue));
    setLoading(false);
  }

  useEventsSubscriber(
    'PeopleStatisticWidget',
    {
      [PERSON_CREATED]: loadStatistic,
    },
    [JSON.stringify(filterContext.filterValue)],
    loadStatistic,
  );

  if (statistic) {
    return (
      <table className={`${styles.statistic} ${loading && 'loading'}`}>
        <thead>
          <tr>
            <td>
              Total Contacts
            </td>
            <td>
              Contacts Added In Last 30 Days
            </td>
            <td>
              Total Active Contacts
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {statistic.total}
            </td>
            <td>
              {statistic.inLast30Days}
            </td>
            <td>
              {statistic.totalActive}
            </td>
          </tr>
        </tbody>
      </table>
    );
  } else {
    return null;
  }
}
