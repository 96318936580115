import React from 'react';

import styles from './AssignmentFieldLayout.module.scss';
import { ImageWidget } from 'features/nekst-widgets';

export type AssignmentInfo = {
  id?: number | string
  name: string
  imageUrl?: string,
}

export interface AssignmentFieldLayoutProps {
  label?: string;
  assignments: AssignmentInfo[];
}

interface AssignmentWidgetProps {
  info: AssignmentInfo;
}

function AssignmentWidget(props: AssignmentWidgetProps) {
  const getAcronym = (str: string) => {
    return str.split(/\s/)
      .reduce(
        (response, word) => {
          let result = response;
          result += word.slice(0, 1);
          return result;
        },
        '',
      );
  };

  if (props.info?.imageUrl) {
    return (
      <ImageWidget
        src={props.info?.imageUrl}
        className={styles.assignment}
        alt={props.info.name}
      />
    );
  } else {
    return (
      <div className={styles.assignment} title={props.info.name}>
        {getAcronym(props.info.name)}
      </div>
    );
  }
}

export default function AssignmentsFieldLayout(props: AssignmentFieldLayoutProps) {
  return (
    <div className={styles.assignments}>
      {props.label && (
        <span className={styles.label}>
          {props.label}
          :
        </span>
      )}
      {
        props.assignments.map((assignment) => {
          return (
            <AssignmentWidget
              info={assignment}
              key={`assignment-${assignment.id}`}
            />
          );
        })
      }
    </div>
  );
}
