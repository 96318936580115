import { ViewStyle } from 'react-native';

export enum IconType {
  X = 'x',
  PENCIL = 'pencil',
  TRASH_CAN = 'trashCan',
  GEAR = 'gear',
  DOWNLOAD = 'download',
  UPLOAD = 'upload',
  SHARE = 'share',
  COPY = 'copy',
  FILTER = 'filter',
  THREE_DOTS = 'threeDots',
  CALL = 'call',
  WRITE_MESSAGE = 'writeMessage',
  CANCEL = 'cancel',
  APPLY = 'apply',
  PLUS = 'plus',
  MENU = 'squares',
  EMAIL = 'email',
  HELP = 'help',
  AI = 'ai',
  PREV = 'prev',
  NEXT = 'next',
  MAGIC = 'magic',
  MAGNIFYING = 'magnifying',
}

export interface IconButtonProps {
  onClick: () => void,
  className?: string
  type: IconType,
  title?: string,
  style?: ViewStyle,
  disabled?: boolean,
  testID?: string,
  size?: 'big' | 'small',
}
