import React from 'react';
import { useMassUpdateServiceRequired } from 'ui-builder';
import Checkbox from '../../uibuilder/form/input/Checkbox';

import styles from './MassUpdateControlsLayout.module.scss';

export default function SelectAllCheckbox() {
  const massUpdateService = useMassUpdateServiceRequired();

  return (
    <Checkbox
      source="isSelectAll"
      label="Select All"
      className={styles.selectAll}
      value={massUpdateService.areAllSelected()}
      onChangeCallback={() => {
        massUpdateService.selectAll();
      }}
    />
  );
}
