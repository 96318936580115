import React, { ReactElement, useEffect, useState } from 'react';
import useDocumentLabelsService, {
  DocumentLabel,
  UpdateDocumentLabelRequest,
} from './documentLabelsService';
import {
  AfterSubmitFunc, CheckboxInput,
  FormTemplate,
  TextInput,
  useFormContext,
  useShowContext
} from 'ui-builder';
import { OpenFormIconButton } from 'features/nekst-widgets';
import { FileType } from 'nekst-api';
import FileUploaderInput from '../../../shared/file/FileUploaderInput';

function IfFileUpdated(props: {
  children: ReactElement,
}) {
  const formContext = useFormContext<UpdateDocumentLabelRequest>();

  const [initialFileId] = useState(formContext.data!.fileId);

  const [changed, setChanged] = useState(false);

  useEffect(() => {
    if (initialFileId && formContext.data?.fileId !== initialFileId) {
      setChanged(true);
    }
  }, [formContext.data?.fileId]);

  if (changed) {
    return props.children;
  } else {
    return null;
  }
}

function UpdateLabelForm(
  props: {
    data: DocumentLabel,
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {
  const documentLabelsService = useDocumentLabelsService();
  return (
    <FormTemplate<UpdateDocumentLabelRequest>
      title="Update Label"
      submitFormFunc={
        async (data) => documentLabelsService.updateLabel(
          props.data.id,
          data,
        )
      }
      afterSubmitFunc={props.afterSubmitFunc}
      initialData={props.data}
      validationSchema={{
        name: {
          type: 'string',
          constraints: {
            required: true,
          },
        },
      }}
    >
      <TextInput source="name" />
      <FileUploaderInput
        fileType={FileType.PROJECT_DOCUMENT}
        fileDataSource="_file"
        source="fileId"
        label="Attach Universal Document to this Label"
        hint="When a file is assigned a label, it will be automatically added to all transactions associated with that label."
      />
      <IfFileUpdated>
        <CheckboxInput
          source="updateActiveProperties"
          label="Apply the change to active transactions"
          hint="You've updated the file associated with this label. When a label is already linked to a transaction and the checkbox is checked, any changes made to the file will be applied to all associated transactions. If the checkbox is not selected, the file will not be updated. Please note that archived properties will not be affected in any scenario."
        />
      </IfFileUpdated>
    </FormTemplate>
  );
}

export default function UpdateLabelButton() {
  const showContext = useShowContext<DocumentLabel>();

  return (
    <OpenFormIconButton
      form={<UpdateLabelForm data={showContext.data!} />}
      title="Update Label"
    />
  );
}
