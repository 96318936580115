import { get, set } from 'lodash';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function useObjectDatesMapper<Type>(datePaths: string[], dateTimePaths: string[]) {
  const parseDate = (dateString: DateString | Date) => {
    if (dateString instanceof Date) {
      return dateString;
    } else {
      const b = dateString.split(/\D/) as unknown as number[];
      return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
    }
  };
  const mapSingle = (object: Record<string, any>): Type => {
    const newObject = {
      ...object,
    };

    datePaths.forEach((path) => {
      const currentValue = get(newObject, path);
      if (currentValue) {
        set(newObject, path, parseDate(currentValue));
      }
    });

    dateTimePaths.forEach((path) => {
      const currentValue = get(newObject, path);
      if (currentValue) {
        const date = new Date(currentValue);
        set(newObject, path, date);
      }
    });

    return newObject as Type;
  };

  function mapList(source: Record<string, any>[]): Type[] {
    return source.map(mapSingle);
  }

  return {
    mapSingle,
    mapList,
  };
}

export function useDateHelper() {
  const toString = (date: Date) => {
    return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  };

  const addZero = (value: number): string => {
    return value < 10 ? `0${value}` : value.toString();
  };

  const toYmd = (date: Date) => {
    return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}`;
  };

  const toDateTimeView = (date: Date) => {
    const month = addZero(date.getMonth() + 1);
    const day = addZero(date.getDate());
    const year = date.getFullYear();

    const hours = addZero(date.getHours());
    const minutes = addZero(date.getMinutes());

    return `${month}/${day}/${year} ${hours}:${minutes}`;
  };

  const parseDateString = (source: DateString) => {
    const b = source.split(/\D/) as unknown as number[];
    return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
  };

  return {
    toString,
    toYmd,
    toDateTimeView,
    parseDateString,
  };
}
