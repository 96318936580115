import React, { ReactElement } from 'react';
import RelativeRow from '../web/uitheme/form/RelativeRow';

import styles from './LogoAndDataLayout.module.scss';

export default function LogoAndDataLayout(
  props: {
    logo: ReactElement,
    children: ListOrSingle<ReactElement>,
  },
) {
  return (
    <RelativeRow weights={[3, 9]} rowClassName={styles.row}>
      {props.logo}
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      <>
        {props.children}
      </>
    </RelativeRow>
  );

}
