import { ValidationSchema } from 'validation-schema';
import {
  PersonProfile,
  ShortProfile,
  RoleAssignment,
  ProjectAssignmentType,
  ProjectAssignment,
} from './Types';
import { useBackendApi } from './backendApi';
import { useEventsPublisher } from 'event-bus';
import { useCacheService } from './cacheService';

export type CreateNewContactRequest = {
  profile: PersonProfile,
  rolesIds: number[],
}

export type AddTransactionPartyRequest = {
  personId: number,
  rolesIds: number[]
}

export interface ContactAssignments {
  projectId?: number,
  personId: number,
  profile: ShortProfile,
  type: ProjectAssignmentType,
  roles: RoleAssignment[],
  isAssignable: boolean,
}

export interface ProjectAssignmentRequest {
  roleId: number;

  personId: number;
}

export interface ProjectAssignmentsService {
  createNewContact: (
    projectId: number,
    request: CreateNewContactRequest
  ) => Promise<ProjectAssignment[]>;
  getCreateNewContactValidationSchema: () => ValidationSchema,
  addTransactionParty: (
    projectId: number,
    request: AddTransactionPartyRequest,
  ) => Promise<ProjectAssignment[]>,
  addTaskAssignee: (
    projectId: number,
    request: AddTransactionPartyRequest,
  ) => Promise<ProjectAssignment[]>,
  getProjectAssignments: (projectId: number) => Promise<ProjectAssignment[]>,
  getTaskAssignments: (projectId: number) => Promise<ProjectAssignment[]>,
  getContactAssignments: (projectId: number) => Promise<ContactAssignments[]>,
  setTaskAssignees: (projectId: number, requests: ProjectAssignmentRequest[])
    => Promise<ProjectAssignment[]>,
  deleteAssignment: (projectId: number, assignmentId: number) => Promise<void>,
  deleteAssignments: (projectId: number, assignmentsIds: number[]) => Promise<void>,
  getActiveProjectsAssignments: () => Promise<ContactAssignments[]>,
}

export function mapProjectAssignmentsToView(
  assignments: ProjectAssignment[],
  projectId?: number,
): ContactAssignments[] {

  const taskAssignmentsMap: Record<number, ContactAssignments> = [];
  const transactionPartiesMap: Record<number, ContactAssignments> = [];

  assignments.forEach((assignment) => {
    if ([
      ProjectAssignmentType.TASK_ASSIGNMENT,
      ProjectAssignmentType.EDITOR,
      ProjectAssignmentType.VIEWER,
    ].includes(assignment.type)
    ) {
      if (!taskAssignmentsMap[assignment.personId!]) {
        taskAssignmentsMap[assignment.personId!] = {
          personId: assignment.personId!,
          type: assignment.type,
          profile: assignment._person,
          roles: [],
          isAssignable: false,
          projectId,
        };
      }

      const currentType = taskAssignmentsMap[assignment.personId!].type;

      if ([ProjectAssignmentType.EDITOR, ProjectAssignmentType.VIEWER].includes(currentType)
        && (assignment.type === ProjectAssignmentType.TASK_ASSIGNMENT)) {
        taskAssignmentsMap[assignment.personId!].type = assignment.type;
      }

      taskAssignmentsMap[assignment.personId!].roles.push({
        id: assignment.id,
        roleId: assignment.roleId,
        isEditable: assignment.isEditable,
        roleName: assignment._role.name,
        isAssignable: assignment._role.isAssignable,
      });
    } else {
      if (!transactionPartiesMap[assignment.personId!]) {
        transactionPartiesMap[assignment.personId!] = {
          personId: assignment.personId!,
          type: ProjectAssignmentType.TRANSACTION_PARTY,
          profile: assignment._person,
          roles: [],
          isAssignable: false,
          projectId,
        };
      }

      transactionPartiesMap[assignment.personId!].roles.push({
        id: assignment.id,
        roleId: assignment.roleId,
        isEditable: assignment.isEditable,
        roleName: assignment._role.name,
        isAssignable: assignment._role.isAssignable,
      });
    }
  });

  const setIsAssignable = (list: ContactAssignments[]) => {
    list.forEach((item, index) => {
      // eslint-disable-next-line no-param-reassign
      list[index].isAssignable = !!item.roles.find((r) => r.isAssignable);
    });

    return list;
  };

  return [
    ...setIsAssignable(Object.values(taskAssignmentsMap)),
    ...setIsAssignable(Object.values(transactionPartiesMap)),
  ];
}

export const PROJECT_ASSIGNMENTS_CHANGED = 'PROJECT_ASSIGNMENTS_CHANGED';

export type ProjectAssignmentsChangedEventPayload = {
  projectId: number;
}

export function useProjectAssignmentsService(
  publishEvents = true,
): ProjectAssignmentsService {
  const projectsApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const cache = useCacheService('projectAssignments');

  const clearCache = (projectId: number) => {
    cache.clear(`${projectId}`);
    cache.clear('all');
  };

  const loadAll = async (): Promise<ProjectAssignment[]> => {
    return cache.get('all', async () => {
      return await projectsApi.get('/projects/all/assignments') as ProjectAssignment[];
    });
  };

  const publishEvent = (projectId: number) => {
    if (publishEvents) {
      eventsPublisher.publish(PROJECT_ASSIGNMENTS_CHANGED, {
        projectId,
      } as ProjectAssignmentsChangedEventPayload);
    }
  };

  const createNewContact = async (projectId: number, request: CreateNewContactRequest) => {
    const result = await projectsApi.post(
      `/projects/${projectId}/transactionparties/newcontact`,
      request,
    ) as ProjectAssignment[];

    clearCache(projectId);
    publishEvent(projectId);

    return result;
  };

  const addTaskAssignee = async (projectId: number, request: AddTransactionPartyRequest) => {
    const result = await projectsApi.post(
      `/projects/${projectId}/taskassignments`,
      {
        rolesIds: request.rolesIds,
        personId: request.personId,
      },
    ) as ProjectAssignment[];

    clearCache(projectId);
    publishEvent(projectId);

    return result;
  };

  const addTransactionParty = async (projectId: number, request: AddTransactionPartyRequest) => {
    const result = await projectsApi.post(
      `/projects/${projectId}/transactionparties`,
      {
        rolesIds: request.rolesIds,
        personId: request.personId,
      },
    ) as ProjectAssignment[];

    clearCache(projectId);
    publishEvent(projectId);

    return result;
  };

  const getCreateNewContactValidationSchema = () => {
    return {
      profile: {
        type: 'object',
        constraints: {
          required: true,
        },
        properties: {
          name: {
            type: 'object',
            constraints: {
              required: true,
            },
            properties: {
              firstName: {
                type: 'string',
                constraints: {
                  required: true,
                },
              },
              lastName: {
                type: 'string',
                constraints: {
                  required: true,
                },
              },
            },
          },
          primaryEmail: {
            type: 'string',
            constraints: {
              email: true,
            },
          },
          phoneNumbers: {
            type: 'array',
            constraints: {},
            arrayItemSchema: {
              type: {
                type: 'string',
                constraints: {
                  required: true,
                },
              },
              number: {
                type: 'string',
                constraints: {
                  required: true,
                  phoneNumber: true,
                },
              },
            },
          },
        },
      },
      rolesIds: {
        type: 'array',
        constraints: {
          required: true,
        },
      },
    } as ValidationSchema;
  };

  const getProjectAssignments = async (projectId: number) => {
    return await projectsApi.get(
      `/projects/${projectId}/assignments`,
    ) as ProjectAssignment[];
  };

  const getTaskAssignments = async (projectId: number) => {
    const allAssignments = await getProjectAssignments(projectId);

    return allAssignments
      .filter((assignment) => [
        ProjectAssignmentType.TASK_ASSIGNMENT,
        ProjectAssignmentType.VIEWER,
        ProjectAssignmentType.EDITOR,
      ].includes(assignment.type));
  };

  const setTaskAssignees = async (projectId: number, assignments: ProjectAssignmentRequest[]) => {
    const result = await projectsApi.put(
      `/projects/${projectId}/taskassignments`,
      assignments,
    ) as ProjectAssignment[];

    clearCache(projectId);
    publishEvent(projectId);

    return result;
  };

  const getContactAssignments = async (projectId: number) => {
    return cache.get(`${projectId}`, async () => {
      const rawData = await projectsApi.get(
        `/projects/${projectId}/assignments`,
      ) as ProjectAssignment[];

      return mapProjectAssignmentsToView(
        rawData,
        projectId,
      );
    });
  };

  const deleteAssignment = async (
    projectId: number,
    id: number,
    needToPublishEvent = false,
  ) => {
    await projectsApi.delete(`/projects/${projectId}/assignments/${id}`);

    clearCache(projectId);
    if (needToPublishEvent) {
      publishEvent(projectId);
    }
  };

  const deleteAssignments = async (projectId: number, ids: number[]) => {
    await Promise.all(
      ids.map((id) => deleteAssignment(projectId, id, false)),
    );

    clearCache(projectId);
    publishEvent(projectId);
  };

  const getActiveProjectsAssignments = async () => {
    const rawData = await loadAll();

    return mapProjectAssignmentsToView(
      rawData.filter((v) => v.type !== ProjectAssignmentType.TRANSACTION_PARTY),
    );
  };
  return {
    createNewContact,
    getCreateNewContactValidationSchema,
    addTransactionParty,
    getProjectAssignments,
    getContactAssignments,
    getTaskAssignments,
    setTaskAssignees,
    addTaskAssignee,
    deleteAssignment,
    deleteAssignments,
    getActiveProjectsAssignments,
  };
}
