import React from 'react';

import { DropdownLayoutProps, OptionsGroup, Option } from 'ui-builder';
import useUiTheme from '../../../uiTheme';

function BaseDropdownLayout(props: DropdownLayoutProps) {
  const uiTheme = useUiTheme();

  const renderOptions = (options: Option[]) => {
    return options && options.map((option) => (
      <option
        key={`dropdown-${option.value}`}
        value={option.value}
        disabled={option.disabled || false}
      >
        {option.label}
      </option>
    ));
  };

  const renderGroups = () => {
    return (
      <>
        {(props.options as OptionsGroup[])
          .map((group) => {
            return (<optgroup label={group.label}>{renderOptions(group.options)}</optgroup>);
          })}
      </>
    );
  };

  const isGroups = () => {
    return props.options && props.options?.length > 0 && Array.isArray(props.options[0].options);
  };

  return (
    <uiTheme.forms.BaseInputLayout
      name={props.name}
      label={props.label}
      errors={props.errors}
      hideLabel={props.hideLabel}
    >
      <select
        name={props.name}
        onChange={props.onChangeCallback}
        value={props.value !== null ? props.value : undefined}
        disabled={props.isDisabled}
      >
        {isGroups() ? renderGroups() : renderOptions(props.options as Option[])}
      </select>
    </uiTheme.forms.BaseInputLayout>
  );
}

export default BaseDropdownLayout;
