import React from 'react';
import StepProgressBar, { Step } from './StepProgressBar';
import useLaunchProjectNavigator from './launchProjectNavigator';
import useFeatureToggleService, { ToggledFeature } from 'feature-toggle';

export enum LaunchProjectStepId {
  DETAILS = 1,
  GROUPS = 2,
  KEY_DATES = 3,
  ALL_TASKS = 4,
  TRANSACTION_PARTIES = 5,
}

export interface LaunchProjectStepsProps {
  currentStep: LaunchProjectStepId;
  disabled?: boolean;
}

export default function LaunchProjectSteps(props: LaunchProjectStepsProps) {
  const projectLaunchNavigator = useLaunchProjectNavigator();

  const featureToggleService = useFeatureToggleService();

  const steps: Step[] = [
    {
      id: LaunchProjectStepId.DETAILS,
      name: 'Add / Update Details',
      onClick: () => projectLaunchNavigator.openUpdateProjectPage(),
    },
    ...featureToggleService.isFeatureEnabled(ToggledFeature.TASKS_GROUPS) ? [{
      id: LaunchProjectStepId.GROUPS,
      name: 'Select Groups',
      onClick: () => projectLaunchNavigator.openGroupsPage(),
      disabled: props.disabled,
    }] : [],
    {
      id: LaunchProjectStepId.KEY_DATES,
      name: 'Edit Key Dates',
      onClick: () => projectLaunchNavigator.openKeyDateTasksPage(),
      disabled: props.disabled,
    },
    {
      id: LaunchProjectStepId.ALL_TASKS,
      name: 'Edit All Tasks',
      onClick: () => projectLaunchNavigator.openAllTasksPage(),
      disabled: props.disabled,
    },
    {
      id: LaunchProjectStepId.TRANSACTION_PARTIES,
      name: 'Add Transaction Parties',
      onClick: () => projectLaunchNavigator.openTransactionPartiesPage(),
      disabled: props.disabled,
    },
  ];

  return (
    <StepProgressBar steps={steps} currentStep={props.currentStep} />
  );
}
