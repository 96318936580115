import React from 'react';
import { useDateHelper } from 'nekst-api';
import Layout, { WidgetStyle } from './Layout';


export function DateWithIconWidget(props: {
  value?: Date,
  noValueText?: string,
}) {
  const dateHelper = useDateHelper();

  function getFieldStyle(value: Date): WidgetStyle | undefined {
    const date = new Date(value);

    const today = new Date();

    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    let widgetStyle: WidgetStyle | undefined = undefined;

    if (dateHelper.toString(date) === dateHelper.toString(today)) {
      widgetStyle = WidgetStyle.TODAY;
    } else if (date.getTime() < today.getTime()) {
      widgetStyle = WidgetStyle.PAST_DUE;
    } else if (dateHelper.toString(date) === dateHelper.toString(tomorrow)) {
      widgetStyle = WidgetStyle.TOMORROW;
    }

    return widgetStyle;
  }

  let fieldStyle;

  if (props.value) {
    fieldStyle = getFieldStyle(props.value);
  }

  if (props.value || props.noValueText) {
    return (
      <Layout
        widgetStyle={fieldStyle}
        value={props.value ? dateHelper.toString(props.value) : props.noValueText}
      />
    );
  } else {
    return null;
  }


}
