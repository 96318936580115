import React from 'react';

import { AfterSubmitFunc, FormTemplate, TextInput, useShowContext } from 'ui-builder';
import { OpenFormIconButton } from 'features/nekst-widgets';
import useServiceProviderValidationSchema from './serviceProviderValidationSchema';
import {
  ServiceProviderGroup,
  ServiceProviderRequest,
  useServiceProvidersGroupsService
} from 'nekst-api';

function UpdateServiceProviderForm(
  props: {
    data: ServiceProviderGroup,
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {
  const service = useServiceProvidersGroupsService();

  return (
    <FormTemplate<ServiceProviderRequest>
      submitFormFunc={(data) => service.update(props.data.id, data)}
      afterSubmitFunc={props.afterSubmitFunc}
      title={`Update Service Provider - ${props.data.name}`}
      validationSchema={useServiceProviderValidationSchema()}
      initialData={props.data}
    >
      <TextInput source="name" label="Name" />
    </FormTemplate>
  );
}

export default function UpdateServiceProviderButton() {
  const showContext = useShowContext<ServiceProviderGroup>();

  const data = showContext.data!;

  return (
    <OpenFormIconButton
      form={<UpdateServiceProviderForm data={data} />}
      title="Update Service Provider"
    />
  );
}
