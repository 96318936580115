import React from 'react';

import styles from './DueDateField.module.scss';
import { DateField, DateFormat } from 'ui-builder';

export default function CompletedField() {
  return (
    <div className={`${styles.dueDateField} ${styles.completed}`}>
      <i className={styles.icon}>
        &nbsp;
      </i>
      <span>Completed </span>
      <DateField source="completedAt" format={DateFormat.SHORT} />
    </div>
  );
}
