import { useListContext } from './ListContext';
import { EventCallback, useEventsSubscriber } from 'event-bus';

export interface ListDataUpdaterProps {
  eventName: string | string[];
  prefix?: string;
}

export function ListDataRefresher(props: ListDataUpdaterProps) {
  const listContext = useListContext();

  const callback = () => {
    listContext.refreshData?.();
  };

  const eventNames = Array.isArray(props.eventName) ? props.eventName : [props.eventName];

  const subscribesMap = eventNames.reduce<Record<string, EventCallback>>((acc, eventName) => {
    acc[eventName] = callback;
    return acc;
  }, {});

  useEventsSubscriber(
    `${props.prefix || ''}-ListDataRefresher`,
    subscribesMap,
  );

  return null;
}
