import React from 'react';
import { useProjectStatisticContextRequired } from 'features/tasks-forms';
import { usePriceFormatter } from 'ui-builder';

export default function StatisticCommissionField(
  props: {
    period: string,
  },
) {
  const context = useProjectStatisticContextRequired();

  const priceFormatter = usePriceFormatter();

  const value = context.statistic!
    .aggregatedStatistic.find((v) => v.period === props.period)?.commission;

  if (value !== undefined) {
    return (
      <>{priceFormatter.format(value)}</>
    );
  } else {
    return <>-</>;
  }
}
