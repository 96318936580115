import React from 'react';
import UpdateTaskButtons from '../UpdateTaskButtons';
import BaseSmsTaskForm from './BaseSmsTaskForm';
import { PlanTask, usePlanTasksService } from 'nekst-api';
import { AfterSubmitFunc } from 'ui-builder';

export interface UpdateSmsFormProps {
  data: PlanTask,
  afterSubmitFunc?: AfterSubmitFunc,
}

export default function UpdateSmsPlanTaskForm(props: UpdateSmsFormProps) {
  const planTasksService = usePlanTasksService();

  return (
    <BaseSmsTaskForm
      title={props.data.name}
      data={props.data}
      afterSubmitFunc={props.afterSubmitFunc}
      submitFormFunc={async (request) => {
        return planTasksService.updateTask(props.data.id, request);
      }}
      validationSchema={planTasksService.getSmsTaskValidationSchema()}
      buttons={<UpdateTaskButtons />}
    />
  );
}
