import { useBackendApi } from './backendApi';
import { ProjectDetailsFieldType } from './Types';

export type GetExampleValueRequest = {
  exampleContent: string
  prompt: string,
  returnType: 'N' | 'S' | 'D' | 'RDO',
  value?: string
}

export type GetExampleValueResponse = {
  value: string | number | undefined
  cleanExample: string,
  explanation: string,
}

export function useAiTrainingExampleService() {
  const backendApi = useBackendApi();

  const getExampleValue = async (
    fieldName: string,
    fieldType: ProjectDetailsFieldType,
    hasParent: boolean,
    nameSynonyms: string | undefined,
    value: string | undefined,
    exampleContent: string
  ) => {
    let prompt = `Define value of ${fieldName}.`;

    if (nameSynonyms) {
      prompt += `It can be also referenced as ${nameSynonyms}.`;
    }

    const getReturnType = () => {
      if (fieldType === ProjectDetailsFieldType.PRICE) {
        return 'N';
      } else if (fieldType === ProjectDetailsFieldType.DATE) {
        return hasParent ? 'RDO' : 'D';
      } else {
        return 'S';
      }
    }

    const request: GetExampleValueRequest =  {
      exampleContent,
      prompt,
      returnType: getReturnType(),
      value,
    };

    return await backendApi.post(
      '/aitraining/examplevalue',
      request
    ) as GetExampleValueResponse;
  };

  return {
    getExampleValue,
  };
}
