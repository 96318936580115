import React from 'react';
import { useParams } from 'react-router-dom';
import { PreviousButton } from './Buttons';
import useLaunchProjectNavigator from './launchProjectNavigator';
import LaunchProjectSteps, { LaunchProjectStepId } from './LaunchProjectSteps';
import RelativeRow from '../../shared/web/uitheme/form/RelativeRow';

import styles from './AddTransactionParties.module.scss';
import CurrentTransactionPartiesForm
  from './CurrentTransactionPartiesForm/CurrentTransactionPartiesForm';
import InformationBox from '../../shared/web/layout/InformationBox';
import { AddPersonToTransactionForm, LaunchProjectButton } from 'features/tasks-forms';
import { Right } from 'ui-builder';

export default function AddTransactionPartiesPage() {
  const navigator = useLaunchProjectNavigator();
  const params = useParams();

  const projectLaunchNavigator = useLaunchProjectNavigator();

  const getProjectId = (projectId?: number) => {
    return projectId || parseInt(params.id!, 10);
  };

  return (
    <>
      <InformationBox
        text="Assign Team Members To Roles with tasks. Add other parties to the transaction. Then Complete Setup!"
      />
      <LaunchProjectSteps currentStep={LaunchProjectStepId.TRANSACTION_PARTIES} />
      <div
        style={{
          marginBottom: '4rem',
          overflow: 'hidden',
        }}
      >
        <PreviousButton onClick={navigator.openAllTasksPage} />
        <Right>
          <LaunchProjectButton
            projectId={getProjectId()}
            openProjectPageFunc={projectLaunchNavigator.openProjectPage}
          />
        </Right>
      </div>
      <RelativeRow weights={[6, 6]}>
        <AddPersonToTransactionForm
          className={styles.block}
          projectId={getProjectId()}
        />
        <CurrentTransactionPartiesForm projectId={getProjectId()} />
      </RelativeRow>

    </>
  );
}
