import useRequiredConstraintValidator from './required';
import { ConstraintValidator } from '../index';

export default function useFilteredRequiredConstraintValidator(): ConstraintValidator {
  const requiredConstraintValidator = useRequiredConstraintValidator();

  const validate = (
    object: Record<string, any>,
    fieldName: string,
    config: any,
  ): boolean => {
    const modifiedObject = object ? { ...object } : {};
    const value = modifiedObject[fieldName] as any[];

    if (value) {
      modifiedObject[fieldName] = value.filter(config.filter);

      return requiredConstraintValidator.validate(modifiedObject, fieldName, config);
    } else {
      return false;
    }
  };

  return {
    getName: () => 'filteredRequired',
    getErrorMessage: requiredConstraintValidator.getErrorMessage,
    validate,
  };
}
