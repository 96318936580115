import { useBackendApi } from './backendApi';
import { useEventsPublisher } from 'event-bus';

export interface AiDetailsFieldTrainingData {
  fieldId: number;
  nameSynonyms: string;
  exampleValue: string;
  explanation: string;
  lines: number[];
  dependsOnFieldId?: number;
}

export interface UpdateDetailsFieldTrainingDataRequest {
  lines: number[];
  nameSynonyms: string;
  exampleValue: string;
  explanation: string;
  dependsOnFieldId?: number;
}

export const TRAINING_DATA_SET = 'TRAINING_DATA_SET';

export const TRAINING_DATA_REMOVED = 'TRAINING_DATA_REMOVED';

export function useContractAiTrainingDataService() {
  const backendApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const setDetailsFieldTrainingData = async (
    templateId: number,
    fieldId: number,
    request: UpdateDetailsFieldTrainingDataRequest
  ) => {
    const result =  await backendApi.put(
      `/contracttemplates/${templateId}/aitraining/fields/${fieldId}`,
      request
    ) as AiDetailsFieldTrainingData;

    eventsPublisher.publish(TRAINING_DATA_SET, {
      templateId,
      fieldId,
      data: result,
    });

    return result;
  };

  const getEntries = async (templateId: number) => {
    return await backendApi.get(
      `/contracttemplates/${templateId}/aitraining/fields`
    ) as AiDetailsFieldTrainingData[];
  }

  const removeEntry  = async (templateId: number, fieldId: number) => {
    await backendApi.delete(
      `/contracttemplates/${templateId}/aitraining/fields/${fieldId}`
    );

    eventsPublisher.publish(TRAINING_DATA_REMOVED, {
      templateId,
      fieldId,
    });
  }

  return {
    setDetailsFieldTrainingData,
    getEntries,
    removeEntry,
  };
}
