import { ValidationSchema } from 'validation-schema';

export function getLoginFormValidationSchema(): ValidationSchema {
  return {
    username: {
      type: 'string',
      constraints: {
        required: true,
        email: true,
      },
    },
    password: {
      type: 'string',
      constraints: {
        required: true,
      },
    },
  };
}
