import React, { ReactElement } from 'react';

import { RelativeRow } from 'ui-builder';


export interface CellProps {
  children: ReactElement | ReactElement[] | string | boolean;
  className?: string;
  centered?: boolean;
}

export function Cell(props: CellProps) {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <div className={props.className}>
      {props.children}
    </div>
  );
}

export function EmptyCell() {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      &nbsp;
    </>
  );
}

export default RelativeRow;
