import { useApiConfiguration } from './ApiConfigurationContext';
import { useBackendApi } from './backendApi';
import { useAuthenticationContext } from '../Authentication/AuthenticationContext';

export enum TourStatus {
  CANCELED = 'CANCELED',
  PASSED = 'PASSED',
  NOT_PASSED = 'NOT_PASSED',
}

export function useOnboardingService() {

  const { deviceDataRepository } = useApiConfiguration();

  const nekstApi = useBackendApi();

  const authenticationContext = useAuthenticationContext();

  const setTourStatus = async (tourId: string, tourStatus: TourStatus) => {
    await nekstApi.put(`/onboarding/tours/${tourId}`, {
      status: tourStatus,
    });

    deviceDataRepository?.set(`tour-${tourId}`, tourStatus);
  };

  const getTourStatus = (tourId: string) => {
    return (deviceDataRepository?.get(`tour-${tourId}`)
      || authenticationContext?.user._introTours[tourId]
      || TourStatus.NOT_PASSED) as TourStatus;
  };

  return {
    setTourStatus,
    getTourStatus,
  };
}
