import React from 'react';

import styles from './TextWithCopyControl.module.scss';

export default function TextWithCopyControl(
  props: {
    children: string,
  },
) {

  const onClick = () => {
    navigator.clipboard.writeText(props.children);
  };

  return (
    <span>
      {props.children}
      <button type="button" className={styles.button} onClick={onClick}>&nbsp;</button>
    </span>
  );
}
