import React, { ReactElement, useContext } from 'react';
import {
  List,
  ListContextData,
  ListProps,
  OrderingConfiguration,
  useListContext,
} from 'ui-builder';
import { FormFieldsData } from '../../../shared/uibuilder/form/FormContext';

const OrderingContext = React.createContext<OrderingConfiguration<any>>({
  isItemDraggable: () => false,
  isEnabled: false,
  updateOrderFunc: async () => {
    // ignored
  },
});

function useItemsOrdering<DataType = FormFieldsData>(
  listContext: ListContextData<DataType>,
  orderingConfig: OrderingConfiguration<DataType>,
) {
  const orderingConfigWrapped: OrderingConfiguration<DataType> = {
    getOrderGroupFunc: orderingConfig.getOrderGroupFunc,
    updateOrderFunc: async (ids: number[]) => {
      let indexes: number[] = [];
      const items: DataType[] = [];

      const newData = [...listContext.data!];

      ids.forEach((id) => {
        // @ts-ignore
        const elementIndex = newData.findIndex((v) => v.id === id);
        indexes.push(elementIndex);
        items.push(newData[elementIndex]);
      });

      indexes = indexes.sort((i1: number, i2: number) => i1 - i2);

      items.forEach((item, index) => {
        newData[indexes[index]] = item;
      });

      listContext._setLoading(true);
      listContext._setData!(newData);
      await orderingConfig!.updateOrderFunc(ids);
      listContext._setLoading(false);
    },
    isEnabled: orderingConfig.isEnabled,
    isItemDraggable: orderingConfig.isItemDraggable,
  };

  return {
    contextData: {
      ...orderingConfigWrapped,
    },
  };
}

interface OrderedListProps<DataType = FormFieldsData, Filter = null>
  extends ListProps<DataType, Filter> {
}

export function OrderedListWrapper<DataType = FormFieldsData>(props: {
  orderingConfig: OrderingConfiguration<DataType>,
  children: ReactElement | ReactElement[],
}) {
  const listContext = useListContext<DataType>();

  // @ts-ignore
  const ordering = useItemsOrdering(listContext, props.orderingConfig).contextData;

  return (
    <OrderingContext.Provider
      value={ordering}
    >
      {props.children}
    </OrderingContext.Provider>
  );
}

export function useOrderingContext() {
  return useContext(OrderingContext);
}

export default function OrderedList<DataType = FormFieldsData, FilterType = null>(
  props: OrderedListProps<DataType, FilterType>,
) {
  return (
    <List<DataType, FilterType>
      getDataFunc={props.getDataFunc}
      defaultFilterValue={props.defaultFilterValue}
      dependencies={props.dependencies}
    >
      {props.children}
    </List>
  );
}
