import React from 'react';
import ReactDOM from 'react-dom';

import BorderedBox from '../../../../shared/web/layout/BorderedBox';
import CheckboxGroup from '../../../../shared/uibuilder/form/input/CheckboxGroup';

import styles from './AssigneeFilter.module.scss';
import { FormTemplate } from 'ui-builder';
import { usePlanTaskAssigneeFilterService } from 'features/tasks-forms';

export default function AssigneeFilter() {
  const container = document.getElementById('filter2');


  const assigneeFilterService = usePlanTaskAssigneeFilterService();

  const setFilterValue = (assignees: number[]) => {
    assigneeFilterService.setFilterValue(assignees);
  };

  const options = assigneeFilterService.getOptions();
  if (container && options.length > 0) {
    return ReactDOM.createPortal(
      <BorderedBox title="Assignee" className={styles.filter}>
        <FormTemplate
          submitFormFunc={async (data) => {
            setFilterValue(data.assignees as number[]);
          }}
          initialData={{
            assignees: assigneeFilterService.getFilterValue(),
          }}
          submitOnEachChange
          buttons={null}
        >
          <CheckboxGroup options={options} source="assignees" hideLabel />
        </FormTemplate>
      </BorderedBox>,
      container,
    );
  } else {
    return null;
  }
}
