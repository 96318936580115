import React from 'react';
import { INVITATION_RESENT, TEAM_MEMBER_DELETED, TeamMember, usePeopleService } from 'nekst-api';
import {
  DateTimeField,
  List,
  ListDataRefresher,
  TextField,
  useShowContextRequired
} from 'ui-builder';
import GridLayout from '../../shared/uibuilder/list/GridLayout';
import Cell from '../../shared/uibuilder/list/Cell';
import { IconButton, IconType, TeamRoleField } from 'features/nekst-widgets';
import useSettingsNavigator from '../settingsNavigator';
import { DeleteTeamMemberIconButton, ResendInvitationIconButton } from 'features/tasks-forms';

function UpdateTeamMemberButton() {
  const settingsNavigator = useSettingsNavigator();

  const showContext = useShowContextRequired<TeamMember>();

  return (
    <IconButton
      onClick={() => {
        settingsNavigator.openUpdateMemberPage(showContext.data!.id);
      }}
      type={IconType.PENCIL} />
  )
}

export default function TeamMembersList() {
  const peopleService = usePeopleService();

  return (
    <List<TeamMember>
      getDataFunc={async () => {
        return await peopleService.getTeamMembers(true);
      }}
    >
      <>
        <GridLayout>
          <Cell label="Member Name">
            <TextField source="profile.name.fullName" />
          </Cell>
          <Cell label="Access Level">
            <TeamRoleField source="teamRole" />
          </Cell>
          <Cell label="Email Address">
            <TextField source="profile.primaryEmail" />
          </Cell>
          <Cell label="Last Activity">
            <DateTimeField source="lastActiveAt" />
          </Cell>
          <Cell label="Actions" isNoWrap>
            <UpdateTeamMemberButton />
            <DeleteTeamMemberIconButton />
            <ResendInvitationIconButton />
          </Cell>
        </GridLayout>
        <ListDataRefresher eventName={TEAM_MEMBER_DELETED} />
        <ListDataRefresher eventName={INVITATION_RESENT} />
      </>

    </List>
  );
}
