import {
  BaseInputProps,
  ErrorsWrapper,
  List,
  RelativeRow,
  useInputHelper,
  useShowContext,
  InputLabel,
  TextLink,
  Text,
  isReactNative, useFormContext, Right,
} from 'ui-builder';
import {
  Person,
  PersonPermission,
  PersonPhoneNumber,
  PersonPhoneNumberType,
  TeamPermission
} from 'nekst-api';
import { useAuthorizationChecker } from 'authorization-scope';
import React, { useEffect, useState } from 'react';

import styles from './PhoneNumbersInput.module.scss';
import { AddPhoneNumberForm } from './AddPhoneNumberForm';

import ListRowLayout from './ListRowLayout';
import { RowRepeater } from '../RowRepeater';
import { Spacings } from 'react-native-ui-lib';
import { RequestOptInButtonFullPerson } from '../RequestOptInButton';

interface PhoneNumberInputProps extends BaseInputProps<PersonPhoneNumber[]> {
  openCreateForm: boolean,
}

function AddItemButton(props: {
  onClick: () => void
}) {
  return (

    <TextLink
      onClick={props.onClick}
      className={styles.addNew}
    >
      + Add
    </TextLink>
  );
}

function PhoneNumberHeader(props: {
  showActionColumn: boolean,
}) {
  const { isGranted } = useAuthorizationChecker();

  return (
    <RelativeRow weights={[4, 2, 2, 2, 2]} rowClassName={styles.header}>
      <Text>Number</Text>
      <Text>Type</Text>
      <Text>Primary</Text>
      {isGranted(TeamPermission.USE_SMS_TASKS_FEATURE) ? (<Text>Receive SMS</Text>) : null}
      {props.showActionColumn && (<Text>Action</Text>)}
    </RelativeRow>
  );
}

function PhoneNumberRow(props: {
  source: string
  editedItemIndex: number | null,
  closeEditingForm: () => void,
  removeItemFunc: (index: number) => void
  isDisabled: boolean,
}) {
  const { data } = useShowContext();
  const index = data!.__index;

  const addItem = async () => {
    props.closeEditingForm();
  };

  if (index !== props.editedItemIndex) {
    return (
      <ListRowLayout
        isDisabled={props.isDisabled}
        removeItemFunc={() => props.removeItemFunc(index)}
      />
    );
  } else {
    return (
      <AddPhoneNumberForm
        source={`${props.source}[${index}]`}
        submitFunc={addItem}
        cancelFunc={async () => props.removeItemFunc(index)}
      />
    );
  }

}

export function PhoneNumbersInput(props: PhoneNumberInputProps) {
  const helper = useInputHelper<PersonPhoneNumber[]>(props);
  const value = helper.getValue() || [];

  const [editedItemIndex, setEditedItemIndex] = useState<number | null>(null);

  const formContext = useFormContext<Person & { personId: number }>();

  const { isGranted } = useAuthorizationChecker();

  useEffect(() => {
    if (value.length === 0) {
      helper.getOnChangeCallback()({
        target: {
          value: [{}],
        },
      });

      setEditedItemIndex(0);
    } else if (formContext.data!.personId) {
      setEditedItemIndex(null);
    }
  }, [formContext.data!.personId]);

  const addItem = () => {
    const newValue = [
      ...value,
    ];

    newValue.push({} as PersonPhoneNumber);

    setEditedItemIndex(newValue.length - 1);

    helper.getOnChangeCallback()({
      target: {
        value: newValue,
      },
    });
  };

  const removeItem = (index: number) => {
    const newValue = [
      ...value,
    ];

    newValue.splice(index, 1);
    setEditedItemIndex(null);

    helper.getOnChangeCallback()({
      target: {
        value: newValue,
      },
    });
  };

  const setDefaultFlags = () => {
    const newValue = [
      ...value,
    ];

    if (!newValue.find((v) => v.isPrimary)) {
      newValue[0].isPrimary = true;
    }

    if (!newValue.find((v) => v.isUsedForSms)) {
      const mobileNumberIndex = newValue.findIndex((v) => v.type === PersonPhoneNumberType.MOBILE);

      if (mobileNumberIndex !== -1) {
        newValue[mobileNumberIndex].isUsedForSms = true;
      }
    }

    helper.getOnChangeCallback()({
      target: {
        value: newValue,
      },
    });
  };

  const isHeaderShown = value.length > 0 && (value.length !== 1 || editedItemIndex === null);

  return (
    <ErrorsWrapper errors={helper.getErrors()} name={helper.getSource()}>
      <>
        {isGranted(TeamPermission.USE_SMS_TASKS_FEATURE)
          && isGranted(PersonPermission.SEND_OPT_IN)
          && formContext.data?.profile && (<Right>
          <RequestOptInButtonFullPerson person={formContext.data!} />
        </Right>)}
        <InputLabel {...props} />
        <List
          getDataFunc={async () => value}
          dependencies={[
            value.length,
            editedItemIndex,
            value.findIndex((v) => v.isPrimary),
            value.findIndex((v) => v.isUsedForSms),
          ]}
        >
          {isHeaderShown && !isReactNative() && (
            <PhoneNumberHeader showActionColumn={!helper.getIsDisabled()} />)}
          <RowRepeater
            getKeyFunc={(rowData) => `row-${rowData.number}-${rowData.__index}`}
            style={{
              gap: Spacings.s1,
            }}
          >
            <PhoneNumberRow
              source={props.source}
              editedItemIndex={editedItemIndex}
              closeEditingForm={() => {
                setDefaultFlags();
                setEditedItemIndex(null);
              }}
              isDisabled={helper.getIsDisabled()}
              removeItemFunc={removeItem}
            />
          </RowRepeater>
        </List>
        {!helper.getIsDisabled() && editedItemIndex === null && (
          <AddItemButton onClick={addItem} />
        )}
      </>
    </ErrorsWrapper>
  );
}
