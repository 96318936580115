import { CreatePersonRequest, Person, PersonPermission, TeamPermission, TeamRole } from 'nekst-api';
import { useAuthorizationChecker } from 'authorization-scope';
import { PhoneNumbersInput, Separator } from 'features/nekst-widgets';
import {
  FormBlock,
  IfFormData,
  ifReactNative,
  InputLayoutIcon,
  RelativeRow,
  TextInput
} from 'ui-builder';
import React from 'react';
import { AvatarInput } from './Shared';
import { AddressInput, ServiceProvidersInput, TeamRoleRadio } from '../Shared';

export function UpdatePersonFormFields(
  props: {
    person: Person,
  },
) {
  const { isGranted } = useAuthorizationChecker();
  const canManageTeamRole = isGranted(TeamPermission.HAS_TEAM)
    && isGranted(TeamPermission.CREATE_TEAM_MEMBER)
    && [TeamRole.MEMBER, TeamRole.ADMINISTRATOR].includes(props.person.teamRole);

  return (
    <FormBlock>
      {isGranted(PersonPermission.UPDATE_AVATAR) && (
        <>
          <AvatarInput person={props.person} />
          <Separator />
        </>
      )}
      <RelativeRow weights={ifReactNative([12], [6, 6])}>
        <TextInput
          source="profile.name.firstName"
          label="First Name"
          icon={InputLayoutIcon.PERSON}
        />
        <TextInput
          source="profile.name.lastName"
          label="Last name"
          icon={InputLayoutIcon.PERSON}
        />
      </RelativeRow>
      <RelativeRow weights={ifReactNative([12], [6, 6])}>
        <TextInput
          source="profile.primaryEmail"
          label="Primary Email"
          isDisabled={
            [
              TeamRole.ADMINISTRATOR,
              TeamRole.OWNER,
              TeamRole.MEMBER,
            ].includes(props.person.teamRole)
          }
          icon={InputLayoutIcon.EMAIL}
        />
        <TextInput
          source="profile.alternateEmail"
          label="Alternate Email"
          icon={InputLayoutIcon.EMAIL}
        />
      </RelativeRow>
      <RelativeRow weights={ifReactNative([12], [6, 6])}>
        <TextInput
          source="profile.companyName"
          label="Company Name"
          icon={InputLayoutIcon.WORK}
        />
        <TextInput
          source="profile.website"
          label="Website"
          icon={InputLayoutIcon.WEB}
        />
      </RelativeRow>
      <PhoneNumbersInput
        source="profile.phoneNumbers"
        label="Phone Numbers"
        openCreateForm
      />
      <Separator />
      <AddressInput source="profile.address" />
      {canManageTeamRole && (
        <>
          <Separator />
          <TeamRoleRadio source="teamRole" label="" showContactOption={false} />
        </>
      )}
      <IfFormData<CreatePersonRequest>
        conditionFunc={(data) => data.teamRole === TeamRole.CONTACT}
        onUnmount={(callback) => {
          callback({
            serviceProvidersIds: undefined,
          });
        }}
      >
        <Separator />
        <ServiceProvidersInput source="serviceProvidersIds" label="Service Providers" />
      </IfFormData>
    </FormBlock>
  );
}
