import React from 'react';
import { BlockLayoutProps } from 'ui-builder';
import Right from '../../../web/Right';

export default function RightLayout(props: BlockLayoutProps) {
  return (
    <Right className={props.className}>
      {props.children}
    </Right>
  );
}
