import { ReactElement } from 'react';
import { get } from 'lodash';
import { FormFieldsData, useFormContext } from '../FormContext';
import { BaseInputLayoutProps, BaseInputProps } from './types';

export interface InputHelper<ValueType> {
  getValue: () => ValueType;
  getLabel: () => string | ReactElement;
  getAfterLabelElement: () => string | ReactElement | undefined;
  getOnChangeCallback: () => (_event: any) => void;
  getSource: () => string;
  getClassName: () => string | undefined;
  getBaseInputLayoutProps: () => BaseInputLayoutProps<ValueType>,
  getIsVisible: () => boolean,
  getIsDisabled: () => boolean,
  getErrors: () => string[],
  getOnFocusCallback: () => void,
  getOnBlurCallback: () => void,
  getHint: () => string | ReactElement | undefined,
}

const toWords = (input: string) => {
  let result = input.replace(/([A-Z])/g, ' $1');
  result = result.replace(/([0-9]+)/g, ' $1');

  return result.charAt(0)
    .toUpperCase() + result.slice(1);
};

export function useInputHelper<ValueType>(inputProps: BaseInputProps<ValueType>):
  InputHelper<ValueType> {
  const {
    source,
    label,
  } = inputProps;

  const {
    data,
    onChangeCallback: contextOnChangeCallback,
    getFieldErrors,
    isDisabled: isFormDisabled,
  } = useFormContext();

  const getValue = (): ValueType => {
    if (inputProps.value !== undefined) {
      return inputProps.value as ValueType;
    } else {
      return get(data, source);
    }
  };

  const getTitle = () => inputProps.title;

  const getLabel = (): string | ReactElement => {
    const parts = source && source.replace(/^_/, '')
      .split('.');
    const isLabelDefined = label !== undefined && label != null;

    if (isLabelDefined) {
      return label || '';
    }

    if (source) {
      return toWords(parts[parts.length - 1]);
    }

    return '';
  };

  const getAfterLabelElement = () => inputProps.afterLabelElement;

  const getIsDisabled = (): boolean => {
    if (isFormDisabled) {
      if (typeof inputProps.isDisabled === 'function') {
        return isFormDisabled() || inputProps.isDisabled(data || {});
      } else {
        return isFormDisabled() || !!inputProps.isDisabled;
      }

    } else {
      return !!inputProps.isDisabled;
    }
  };

  /**
   * Returns onChangeCallback based on context data and component attribute.
   * @returns {function(...[*]=)}
   */
  const getOnChangeCallback = () => {
    const {
      onChangeCallback: inputOnChangeCallback,
    } = inputProps;

    const onChangeCallback = (
      inputOnChangeCallback || contextOnChangeCallback
    ) as (_values: FormFieldsData) => void;

    if (!getIsDisabled()) {
      return (event: any) => {
        onChangeCallback({
          [source]: event.target.value,
        });
      };
    } else {
      return () => {
        // no-action
      };
    }
  };

  /**
   * Returns source.
   * @returns {*}
   */
  const getSource = () => inputProps.source;

  const getClassName = () => {
    const { className = undefined } = inputProps;

    return typeof className === 'function' ? className(getSource()) : className;
  };

  const getErrors = () => {
    if (getFieldErrors) {
      return getFieldErrors(getSource())
        .map((error) => error.replace('%label%', `"${getLabel()}"`));
    } else {
      return [];
    }
  };

  const getPlaceholder = () => {
    const { placeholder } = inputProps;

    return placeholder;
  };

  const getOnFocusCallback = () => inputProps.onFocusCallback;
  const getOnBlurCallback = () => inputProps.onBlurCallback;

  const getHint = () => {
    return inputProps.hint;
  };


  const getBaseInputLayoutProps = () => {
    return {
      name: getSource(),
      value: getValue(),
      label: getLabel(),
      afterLabelElement: getAfterLabelElement(),
      title: getTitle(),
      onChangeCallback: getOnChangeCallback(),
      errors: getErrors(),
      placeholder: getPlaceholder(),
      className: getClassName(),
      isDisabled: getIsDisabled(),
      onFocusCallback: getOnFocusCallback(),
      onBlurCallback: getOnBlurCallback(),
      hideLabel: inputProps.hideLabel,
      hint: getHint(),
      showRequiredIcon: inputProps.showRequiredIcon,
      dataKey: inputProps.dataKey || inputProps.source,
      style: inputProps.style,
      icon: inputProps.icon,
    } as BaseInputLayoutProps<ValueType>;
  };

  const getIsVisible = () => {
    if (inputProps.isVisible !== undefined) {
      if (typeof inputProps.isVisible === 'function') {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return inputProps.isVisible(data!);
      } else {
        return Boolean(inputProps.isVisible);
      }
    } else {
      return true;
    }
  };

  return {
    getValue,
    getLabel,
    getAfterLabelElement,
    getOnChangeCallback,
    getSource,
    getClassName,
    getBaseInputLayoutProps,
    getIsVisible,
    getErrors,
    getIsDisabled,
    getOnFocusCallback,
    getOnBlurCallback,
    getHint,
  };
}
