import React from 'react';
import { Arrow, useHover, useLayer } from 'react-laag';
import { useShowContext } from 'ui-builder';
import { PastDueDatesByMember, Project } from 'nekst-api';
import usePeopleService, { Person } from '../../../people/peopleService';
import DataLoader from '../../../shared/DataLoader';

import styles from './PastDueTasksField.module.scss';
import { useProjectStatisticContextRequired } from 'features/tasks-forms';
import { PersonWidget, UserLogoSize } from 'features/nekst-widgets';

interface Props {
  // eslint-disable-next-line react/no-unused-prop-types
  label: string;
  maxVisible?: number;
}

function ShowMoreButton(
  props: {
    id: number,
    items: {
      _person: Person,
      personId: number,
      count: number
    }[],
  },
) {
  const [isOpen, hoverProps] = useHover(
    {
      delayLeave: 50,
      hideOnScroll: false,
    },
  );

  const {
    renderLayer,
    triggerProps,
    layerProps,
    arrowProps,
  } = useLayer({
    isOpen,
    placement: 'bottom-end',
    overflowContainer: false,
    triggerOffset: 10,
    containerOffset: 12,
    auto: true,
    possiblePlacements: [
      'bottom-start',
      'bottom-end',
      'bottom-center',
      'top-start',
      'top-center',
      'top-end',
    ],
  });

  return (
    <div {...hoverProps}>
      <div className={styles.showAll} ref={triggerProps.ref}>{props.items.length}</div>
      {isOpen && renderLayer(
        <div
          {...layerProps}
          className={`${styles.contentWrapper}`}
        >
          <div
            className={styles.content}
          >
            {props.items
              .map((v) => {
                return (
                  <PersonWidget
                    key={`${props.id}-past-due-${v.personId}`}
                    profile={v._person!.profile}
                    size={UserLogoSize.MIDDLE}
                    badge={v.count as unknown as string}
                  />
                );
              })}
          </div>
          <Arrow onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} {...arrowProps} />
        </div>,
      )}
    </div>
  );
}

function PastDueTasksFieldLayout(props: {
  value: PastDueDatesByMember[],
  maxVisible?: number,
  id?: number
}) {

  const peopleService = usePeopleService();

  const getMember = (list: Person[], id: number) => {
    return list.find((v) => v.id === id);
  };

  const value = props.value;
  const maxVisible = props.maxVisible || 3;

  return (
    <DataLoader getDataFunc={peopleService.getTeamMembers}>
      {(teamMembers: Person[]) => {

        const mapped = value
          .map((v) => ({
            ...v,
            _person: getMember(teamMembers, v.personId)!,
          }))
          .filter((v) => !!v._person);

        let firstPage;
        let additional: {
          _person: Person,
          personId: number,
          count: number
        }[];

        if (mapped.length > maxVisible) {
          firstPage = mapped.splice(0, maxVisible - 1);
          additional = mapped;
        } else {
          firstPage = mapped;
          additional = [];
        }

        return (
          <>
            {
              firstPage
                .map((v) => {
                  return (
                    <div key={`${props.id || 0}-past-due-${v.personId}`}
                         style={{ marginRight: '1rem', float: 'left' }}>
                      <PersonWidget
                        profile={v._person!.profile}
                        size={UserLogoSize.MIDDLE}
                        badge={v.count as unknown as string}
                      />
                    </div>
                  );
                })
            }
            {additional.length > 0 && (
              <ShowMoreButton id={props.id || 0} items={additional} />
            )}
          </>
        );
      }}
    </DataLoader>
  );
}

export function StatisticPastDueTasksField() {
  const value = useProjectStatisticContextRequired().statistic!.pastDueTasks;

  if (value.length) {
    return <PastDueTasksFieldLayout value={value} maxVisible={5} />;
  } else {
    return <span>-</span>;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function PastDueTasksField(props: Props) {

  const context = useShowContext<Project>();

  const value = context.data?.statistic?.pastDueTasks || [];

  if (value.length) {
    return <PastDueTasksFieldLayout value={value} maxVisible={props.maxVisible} />;
  } else {
    return <span>-</span>;
  }
}
