import React, { useMemo } from 'react';
import { Dropdown, useListContextRequired, useShowContextRequired } from 'ui-builder';
import { ProjectDetailsField, ProjectDetailsFieldType } from 'nekst-api';

export default function ParentFieldDropdown() {
  const listContext = useListContextRequired<ProjectDetailsField>();

  const showContext = useShowContextRequired<ProjectDetailsField>();

  const currentField = showContext.data!;

  const options = useMemo(() => {
    const getChildrenIds = (currentFieldId: number): number[] => {
      const directChildren = listContext.data.filter((field) => field.parentFieldId === currentFieldId);

      return [
        ...directChildren.map((field) => field.id),
        ...directChildren.flatMap((field) => getChildrenIds(field.id)),
      ]
    };

    return listContext.data!
      .filter((item) => item.fieldType === ProjectDetailsFieldType.DATE)
      .filter((item) => item.id !== currentField.id && !getChildrenIds(currentField.id).includes(item.id))
      .map((item) => ({
        value: item.id,
        label: item.name,
      }));

  }, [listContext.version]);


  if (currentField.fieldType === ProjectDetailsFieldType.DATE) {
    return (
      <Dropdown
        options={options}
        source="parentFieldId"
        label="Parent Detail Box"
        isClearable={true}
        isDisabled={options.length === 0}
        title={options.length === 0 ? 'No available parent fields' : undefined}
      />
    );

  } else {
    return null;
  }

}
