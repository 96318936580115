import React from 'react';
import BaseStandardTaskForm from './BaseStandardTaskForm';
import { AfterSubmitFunc } from 'ui-builder';
import UpdateTaskButtons from '../UpdateTaskButtons';
import NameWithCompleteCheckbox from '../NameWithCompleteCheckbox';
import ClosePopupOnTaskCompletion from '../ClosePopupOnTaskCompletion';
import { StandardTask, TaskPermission, useProjectTasksService } from 'nekst-api';

export interface UpdateStandardTaskFormProps {
  data: StandardTask;
  afterSubmitFunc?: AfterSubmitFunc;
}

export default function UpdateStandardTaskForm(props: UpdateStandardTaskFormProps) {
  const projectTasksService = useProjectTasksService();

  return (
    <BaseStandardTaskForm
      afterSubmitFunc={props.afterSubmitFunc}
      submitFormFunc={async (formData) => {
        return projectTasksService.updateTask(props.data.id, formData);
      }}
      data={props.data}
      initialData={props.data}
      title={<NameWithCompleteCheckbox data={props.data} />}
      buttons={<UpdateTaskButtons />}
      isDisabled={(data) => {
        return !data.permissions?.includes(TaskPermission.UPDATE);
      }}
    >
      <ClosePopupOnTaskCompletion />
    </BaseStandardTaskForm>
  );
}
