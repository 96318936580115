import { IntroTourStatus, AuthenticatedPerson } from './Types';
import { useBackendApi } from './backendApi';

export type { IntroTourStatus, AuthenticatedPerson };

export interface AuthenticationService {
  getCurrentUser: () => Promise<AuthenticatedPerson>,
}

export default function useAuthenticationApiService(): AuthenticationService {
  const projectsApi = useBackendApi();

  const getCurrentUser = async () => {
    return await projectsApi.get('/me') as AuthenticatedPerson;
  };

  return {
    getCurrentUser,
  };
}
