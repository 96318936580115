import React from 'react';
import { useParams } from 'react-router-dom';
import { PersonAuthorizationScope } from 'authorization-scope';
import DataLoader from '../../shared/DataLoader';
import { Person, PersonPermission, useTeamMembersService } from 'nekst-api';
import SinglePersonPage from '../../people/shared/SinglePersonPage';

import Row6x6x from '../../shared/web/uitheme/form/Row6x6x';
import styles from '../../people/Update/PersonUpdatePage.module.scss';
import {
  DeleteTeamMemberButton,
  ResendInvitationButton,
  UpdatePersonForm
} from 'features/tasks-forms';
import AccessChecker from '../../shared/authorization/AccessChecker';
import PersonNotesList from '../../people/Notes/PersonNotesList';
import ManageAssignmentsList from '../../people/Update/ManageAssignmentsList';
import { Separator } from 'features/nekst-widgets';
import { TeamMembersBreadcrumbs } from './TeamMembersBreadcrumbs';
import { Breadcrumb } from '../../shared/web/layout/Breadcrumbs';
import OpenHistoryPageButton from './history/OpenHistoryPageButton';

export default function UpdateTeamMemberPage() {
  const teamMembersService = useTeamMembersService();

  const personId = useParams().id! as unknown as number;

  return (
    <PersonAuthorizationScope id={personId}>
      <DataLoader<Person> getDataFunc={async () => teamMembersService.getById(personId)}>
        {(person) => {
          return (
            <>
              <TeamMembersBreadcrumbs>
                <Breadcrumb text={person.profile.name.fullName} />
              </TeamMembersBreadcrumbs>
              <SinglePersonPage
                headerRight={(
                  <>
                    <OpenHistoryPageButton person={person} />
                    <ResendInvitationButton teamMember={person} />
                    <DeleteTeamMemberButton teamMemberId={person.id} />
                  </>
                )}
                person={person}
              >
                <Row6x6x>
                  <div className={styles.leftColumn}>
                    <UpdatePersonForm person={person} />
                  </div>
                  <div className={styles.rightColumn}>
                    <AccessChecker
                      permissionToCheck={PersonPermission.READ_NOTES}
                      show403Error={false}
                    >
                      <PersonNotesList personId={person.id} />
                      <Separator />
                    </AccessChecker>
                    <ManageAssignmentsList assignments={person._assignments!} />
                  </div>
                </Row6x6x>
              </SinglePersonPage>
            </>
          );
        }}
      </DataLoader>
    </PersonAuthorizationScope>
  );
}
