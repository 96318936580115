import React, { useState } from 'react';
import useInputHelper from '../uibuilder/form/input/inputHelper';
import BaseInputLayout from '../uibuilder/form/layout/BaseInputLayout';
import { BaseInputProps } from '../uibuilder/form/input';

import styles from './RenameFileInput.module.scss';

interface RenameFileInputProps extends BaseInputProps {

}

function removeFileExtension(fileName: string): string {
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return fileName; // No extension found
  }
  return fileName.substring(0, lastDotIndex);
}

function getFileExtension(fileName: string): string {
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex === -1 || lastDotIndex === fileName.length - 1) {
    return ''; // No extension found or the dot is the last character
  }
  return fileName.substring(lastDotIndex + 1);
}

export default function RenameFileInput(props: RenameFileInputProps) {
  const helper = useInputHelper(props);

  const value = helper.getValue();

  const [extension] = useState(getFileExtension(value));

  const fileName = removeFileExtension(value);

  return (
    <BaseInputLayout {...helper.getBaseInputLayoutProps()}>
      <div className={styles.input}>
        <input
          type="text"
          value={fileName}
          onChange={(e) => {
            helper.getOnChangeCallback()({
              target: {
                value: `${e.target.value}.${extension}`,
              },
            });
          }}
        />
        <span className={styles.extension}>
          .
          {extension}
        </span>
      </div>
    </BaseInputLayout>
  );
}
