import React, { ReactElement } from 'react';
import {
  AfterSubmitFunc,
  CheckboxInput, FormTemplate,
  GetDataFunc, IfFormData, RelativeRow,
  SubmitFormFunc,
  TextArea,
  TextInput
} from 'ui-builder';
import Separator from '../../../../shared/web/uitheme/form/Separator';
import TransactionPartiesInput from './TransactionPartiesInput';
import useAuthorizationChecker from '../../../../shared/authorization/authorizationChecker';
import { useProjectTypeAndSetIdContextRequired } from '../../../view/ViewPlan';
import {
  DueDateConfigInput,
  PlanLinkedFieldDropdown,
  PlanParentTaskRelationInput,
} from 'features/tasks-forms';
import { PlanTask } from 'nekst-api';
import { ValidationSchema } from 'validation-schema';
import { PlanPermission } from 'authorization-scope';

export interface BaseStandardTaskFormProps {
  title: string,
  submitFormFunc: SubmitFormFunc<PlanTask>,
  afterSubmitFunc?: AfterSubmitFunc<PlanTask>,
  validationSchema: ValidationSchema,
  getDataFunc?: GetDataFunc<PlanTask>,
  buttons?: ReactElement,
  initialData?: PlanTask
}

export default function BaseStandardTaskForm(props: BaseStandardTaskFormProps) {
  const { isGranted } = useAuthorizationChecker();

  const typeAndSetIdContext = useProjectTypeAndSetIdContextRequired();

  return (
    <FormTemplate<PlanTask>
      getDataFunc={props.getDataFunc}
      submitFormFunc={props.submitFormFunc}
      afterSubmitFunc={props.afterSubmitFunc}
      validationSchema={props.validationSchema}
      title={props.title}
      buttons={props.buttons}
      initialData={props.initialData}
      isDisabled={!isGranted(PlanPermission.UPDATE)}
    >
      <TextInput source="name" label="Task Name" />
      <RelativeRow weights={[3, 3, 6]}>
        <CheckboxInput source="isKeyDate" label="Key Date" />
        <CheckboxInput source="isPrivate" label="Private" />
        <CheckboxInput source="isDuringBusinessWeek" label="Only During Business Week" />
      </RelativeRow>
      <IfFormData conditionFunc={(data) => !data.parentTaskRelation}>
        <RelativeRow weights={[6, 6]}>
          <DueDateConfigInput source="dueDateConfig" label="Due" />
          {typeAndSetIdContext.projectType && (
            <PlanLinkedFieldDropdown
              {...typeAndSetIdContext}
              source="linkedFieldId"
              parentTaskIdSource="parentTaskRelation.parentTaskId"
              label="Link to Detail Box"
            />
          )}
        </RelativeRow>
      </IfFormData>
      <IfFormData conditionFunc={(data) => !!data.parentTaskRelation}>
        <RelativeRow weights={[8, 4]}>
          <PlanParentTaskRelationInput source="parentTaskRelation" label="Due" />
          {typeAndSetIdContext.projectType && (
            <PlanLinkedFieldDropdown
              {...typeAndSetIdContext}
              source="linkedFieldId"
              parentTaskIdSource="parentTaskRelation.parentTaskId"
              label="Link to Detail Box"
            />
          )}
        </RelativeRow>
      </IfFormData>

      <Separator />
      <TextArea source="description" label="Task Description" />
      <Separator />
      <TransactionPartiesInput source="transactionParties" label="Transaction Parties" />
    </FormTemplate>
  );
}
