import React from 'react';
import { TeamMember } from 'nekst-api';
import { TeamMembersBreadcrumbs } from './TeamMembersBreadcrumbs';
import { useTeamMemberNavigator } from './teamMemberNavigator';
import { Breadcrumb } from '../../shared/web/layout/Breadcrumbs';

export default function TeamMemberBreadcrumbs(
  props: {
    teamMember: TeamMember,
    children?: React.ReactNode,
  }
) {
  const teamMemberNavigator = useTeamMemberNavigator();
  return (
    <TeamMembersBreadcrumbs>
      <Breadcrumb
        text={props.teamMember.profile.name.fullName}
        href={teamMemberNavigator.getUpdateMemberPageLink(props.teamMember.id)}
      >
        {props.children}
      </Breadcrumb>
    </TeamMembersBreadcrumbs>
  );
}
