import React, { ReactElement } from 'react';
import { useShowContext } from '../Show';

interface IfProps<DataType = Record<string, any>> {
  conditionFunc: (data: DataType) => boolean,
  children: ReactElement | ReactElement[]
  failureContent?: ReactElement,
}

export function If<DataType = Record<string, any>>(props: IfProps<DataType>) {
  const showContext = useShowContext<DataType>();

  if (props.conditionFunc(showContext.data || {} as DataType)) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {props.children}
      </>
    );
  } else {
    return props.failureContent || null;
  }
}
