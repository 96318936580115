import React, { useState } from 'react';
import useProjectDocumentsService, { ProjectDocument } from './projectDocumentsService';
import LoadingAnimation from '../../shared/LoadingAnimation';
import useDataLoader from '../../shared/uibuilder/form/dataLoader';
import TextLink from '../../shared/widgets/TextLink';
import { usePopupManager, useShowContext } from 'ui-builder';
import { PDFViewer, Popup } from 'features/nekst-widgets';
import { DownloadFileDto, useMimeTypesHelper } from 'nekst-api';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function WordDocumentViewer(props: { documentUrl: string }) {
  return (
    <iframe
      title="Preview Document"
      style={{
        width: '100%',
        height: '100%',
      }}
      src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(props.documentUrl)}&ui=en-EN`}
    />
  );
}

function PreviewDocumentPopup(
  props: {
    document: ProjectDocument,
  },
) {
  const [document, setDocument] = useState<DownloadFileDto>();

  const projectDocumentsService = useProjectDocumentsService();

  const mimeTypesHelper = useMimeTypesHelper();

  useDataLoader(
    () => projectDocumentsService.getDownloadLink(props.document.id),
    setDocument,
  );

  let content;

  if (document) {
    if (mimeTypesHelper.isPdfMimeType(document.mimeType)) {
      content = <PDFViewer pdfUrl={document.downloadUrl} fileName={document.fileName} />;
    } else {
      content = (<span>Preview is not available for this file type.</span>);
    }
  } else {
    content = <LoadingAnimation />;
  }

  return (<Popup title="Preview Document" content={content} isBig />);
}

export function usePreviewDocumentPopup() {
  const popupManager = usePopupManager();

  const openPreviewPopup = (document: ProjectDocument) => {
    popupManager.openPopup(<PreviewDocumentPopup document={document} />);
  };

  return {
    openPreviewPopup,
  };
}

export default function PreviewDocumentField() {

  const showContext = useShowContext<ProjectDocument>();

  const document = showContext.data!;

  const previewDocumentPopup = usePreviewDocumentPopup();

  const mimeTypesHelper = useMimeTypesHelper();

  if (document._file) {

    const mimeType = document._file.mimeType;
    const isPreviewAvailable = mimeTypesHelper.isPdfMimeType(mimeType);

    if (isPreviewAvailable) {
      return (
        <TextLink
          action={() => previewDocumentPopup.openPreviewPopup(document)}
          uri="#"
        >
          {document._file.name}
        </TextLink>
      );
    } else {
      return (<span>{document._file.name}</span>);
    }
  } else {
    return <span>-</span>;
  }
}
