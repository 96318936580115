import { Crisp } from 'crisp-sdk-web';
import useProjectsApi from '../../projects/projectsApi';

export default function useCrispEventsPublisher() {
  const nekstApi = useProjectsApi();

  const onSignUp = async () => {
    try {
      await nekstApi.post('/crisp/user');
    } catch (e) { /* empty */
    }

    const sessionId = Crisp.session.getIdentifier();
    if (sessionId) {
      Crisp.session.pushEvent('newuser:signup');
    } else {
      Crisp.session.onLoaded(() => {
        Crisp.session.pushEvent('newuser:signup');
      });
    }
  };

  return {
    onSignUp,
  };
}
