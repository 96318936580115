import React, { ReactElement } from 'react';
import ErrorsWrapper from '../uibuilder/form/input/layout/ErrorsWrapper';
import styles from '../../plans/tasks/CreateUpdate/TransactionParties.module.scss';
import RelativeRow, { Cell, EmptyCell } from '../web/uitheme/form/RelativeRow';
import Checkbox from '../uibuilder/form/input/Checkbox';
import BriefcaseCheckbox from '../../plans/tasks/CreateUpdate/BriefcaseCheckbox';
import { EmailRecipientType } from '../../projects/view/tasks/projectTasksService';
import LoadingAnimation from '../LoadingAnimation';

export type Choice = {
  id: string
  name: string | ReactElement
  title?: string
  isAssignable: boolean
  isAssigned: boolean
  emailRecipientType?: string | null
}

export interface EmailTransactionPartiesInputLayoutProps {
  choices: Choice[]
  isDisabled: boolean
  numberOfSelectedOptions: number,
  onAssignmentChangedCallback: (id: string) => void,
  onEmailRecipientChangedCallback: (id: string, type?: EmailRecipientType) => void,
}

export default function EmailTransactionPartiesInputLayout(
  props: EmailTransactionPartiesInputLayoutProps,
) {
  const title = `Transaction Parties (${props.numberOfSelectedOptions})`;
  return (
    <ErrorsWrapper name="emailRecipients">
      <ErrorsWrapper name="transactionParties">
        <div className={styles.block}>
          <h3>
            {title}
          </h3>
          {!!props.choices.length && (
            <div className={styles.table}>
              <RelativeRow
                weights={[4, 1, 3, 2, 2]}
                rowClassName={styles.tableHead}
                cellClassName={styles.cell}
              >
                <Cell>
                  Assigned To:
                </Cell>
                <EmptyCell />
                <Cell centered>
                  Receive Email
                </Cell>
                <Cell centered>
                  CC
                </Cell>
                <Cell centered>
                  BCC
                </Cell>
              </RelativeRow>
              <div className={styles.tableBody}>
                {props.choices.map(
                  (choice) => {
                    return (
                      <RelativeRow
                        key={`choice-${choice.id}`}
                        weights={[4, 1, 3, 2, 2]}
                        rowClassName={styles.row}
                        cellClassName={styles.cell}
                      >
                        {choice.isAssignable ? (
                          <BriefcaseCheckbox
                            title={choice.title}
                            source={`assigned.${choice.id}`}
                            label={choice.name}
                            value={choice.isAssigned}
                            isDisabled={props.isDisabled}
                            onChangeCallback={() => {
                              props.onAssignmentChangedCallback(choice.id);
                            }}
                          />
                        ) : (
                          <Cell className={styles.noAssignment}>
                            <span title={choice.title}>{choice.name}</span>
                          </Cell>
                        )}
                        <EmptyCell />
                        <Cell centered className={styles.checkboxCell}>
                          <Checkbox
                            source={`receiveEmail.${choice.id}`}
                            label=""
                            value={choice.emailRecipientType === EmailRecipientType.RECIPIENT}
                            onChangeCallback={(data) => {
                              const selected = data[`receiveEmail.${choice.id}`];
                              if (selected) {
                                props.onEmailRecipientChangedCallback(
                                  choice.id,
                                  EmailRecipientType.RECIPIENT,
                                );
                              } else {
                                props.onEmailRecipientChangedCallback(
                                  choice.id,
                                  undefined,
                                );
                              }
                            }}
                          />
                        </Cell>
                        <Cell centered className={styles.checkboxCell}>
                          <Checkbox
                            source={`cc.${choice.id}`}
                            label=""
                            value={choice.emailRecipientType === EmailRecipientType.CC}
                            onChangeCallback={(data) => {
                              const selected = data[`cc.${choice.id}`];
                              if (selected) {
                                props.onEmailRecipientChangedCallback(
                                  choice.id,
                                  EmailRecipientType.CC,
                                );
                              } else {
                                props.onEmailRecipientChangedCallback(
                                  choice.id,
                                  undefined,
                                );
                              }
                            }}
                          />
                        </Cell>
                        <Cell centered className={styles.checkboxCell}>
                          <Checkbox
                            source={`bcc.${choice.id}`}
                            label=""
                            value={choice.emailRecipientType === EmailRecipientType.BCC}
                            onChangeCallback={(data) => {
                              const selected = data[`bcc.${choice.id}`];
                              if (selected) {
                                props.onEmailRecipientChangedCallback(
                                  choice.id,
                                  EmailRecipientType.BCC,
                                );
                              } else {
                                props.onEmailRecipientChangedCallback(
                                  choice.id,
                                  undefined,
                                );
                              }
                            }}
                          />
                        </Cell>
                      </RelativeRow>
                    );
                  },
                )}
              </div>
            </div>
          )}
          {!props.choices.length && (<LoadingAnimation />)}
        </div>
      </ErrorsWrapper>
    </ErrorsWrapper>
  );
}
