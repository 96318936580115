import React from 'react';
import { BaseTaskRowAssignmentWidget } from 'features/nekst-widgets';
export interface TaskAssignmentsFieldProps {
  source: string;
}

export function TaskAssignmentsField(props: TaskAssignmentsFieldProps) {
  return (
    <>
      <BaseTaskRowAssignmentWidget
        source={props.source}
        filterFunc={(item) => item.personId}
        getNameFunc={(item) => item._profile?.name.fullName || ''}
        getImageFunc={(item) => item._profile?._avatarUrl?.resized}
      />
      <BaseTaskRowAssignmentWidget
        source={props.source}
        filterFunc={
          (item, items) => !item.personId && !items.find(
            (i) => i.roleId === item.roleId && i.personId,
          )
        }
        getNameFunc={(item) => item._role.name || ''}
      />
    </>
  );
}
