import React from 'react';
import FormTemplate, { AfterSubmitFunc } from '../../../shared/uibuilder/form/FormTemplate';
import Text from '../../../shared/uibuilder/form/input/Text';
import useDocumentsTemplatesService, {
  CreateDocumentsTemplateRequest,
  DocumentsTemplate,
} from './documentsTemplatesService';
import DocumentLabelsDropdown from '../labels/DocumentLabelsDropdown';
import { usePopupFormManager } from 'features/nekst-widgets';
import { Button, ButtonColor, ButtonSize, IconType } from 'ui-builder';

export default function CreateDocumentsTemplateForm(
  props: {
    afterSubmitFunc?: AfterSubmitFunc,
    title?: string,
    initialData?: CreateDocumentsTemplateRequest,
  },
) {
  const documentsTemplateService = useDocumentsTemplatesService();

  const title = props.title || 'Create Documents Template';

  return (
    <FormTemplate<CreateDocumentsTemplateRequest, DocumentsTemplate>
      title={title}
      submitFormFunc={async (data) => {
        return documentsTemplateService.createDocumentsTemplate(data);
      }}
      afterSubmitFunc={props.afterSubmitFunc}
      initialData={props.initialData}
      validationSchema={{
        name: {
          type: 'string',
          constraints: {
            required: true,
          },
        },
        labelsIds: {
          type: 'array',
          constraints: {
            required: true,
          },
        },
      }}
    >
      <Text source="name" label="Name" />
      <DocumentLabelsDropdown source="labelsIds" label="Document Labels" />
    </FormTemplate>
  );
}

export function CreateDocumentsTemplateButton() {
  const popupManager = usePopupFormManager();

  return (
    <Button
      text="Create Documents Template"
      isFilled
      size={ButtonSize.MEDIUM}
      color={ButtonColor.BLUE}
      iconType={IconType.PLUS}
      onClick={() => {
        popupManager.openForm(<CreateDocumentsTemplateForm />);
      }}
    />
  );
}
