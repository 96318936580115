import React from 'react';
import { FormFieldsData } from 'ui-builder';
import { useNavigate } from 'react-router-dom';
import { Plan } from 'nekst-api';
import { CreatePlanForm } from 'features/tasks-forms';

export interface CreatePlanFormProps {
  afterSubmitFunc?: (_data: FormFieldsData | void) => Promise<void>,
}

export default function CreateForm(props: CreatePlanFormProps) {

  const navigate = useNavigate();

  const afterSubmitFunc = async (data: Plan | void) => {
    if (data) {
      navigate(`/plans/${data.id}`);
    }

    if (props.afterSubmitFunc) {
      await props.afterSubmitFunc(data);
    }
  };

  return (
    <CreatePlanForm afterSubmitFunc={afterSubmitFunc} />
  );
}
