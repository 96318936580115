import React from 'react';
import { get } from 'lodash';
import { useShowContext } from '../../../shared/uibuilder/show/ShowReactContext';

import styles from './ProjectLinkField.module.scss';
import useProjectNavigator from '../../projectNavigator';
import BaseFieldProps from '../../../shared/uibuilder/show/field/BaseFieldProps';

export interface ProjectLinkFieldProps extends BaseFieldProps {
  source: string,
  idSource: string,
}

export default function ProjectLinkField(props: ProjectLinkFieldProps) {

  const showContext = useShowContext();

  const projectNavigator = useProjectNavigator();

  const data = showContext.data!;

  const value = get(data, props.source);

  const id = get(data, props.idSource);

  const classes = [
    styles.link,
    props.className || '',
  ];

  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <a
      href={projectNavigator.getTaskPageLink(id)}
      className={classes.join(' ')}
      onClick={(event) => {
        event.preventDefault();
        projectNavigator.openTasksPage(id)
      }}
      data-test="project-link-field"
    >
      {value}
    </a>
  );
}
