import React, { ReactElement } from 'react';
import { AfterSubmitFunc } from 'ui-builder';
import { CreateProjectForm as BaseCreateProjectForm } from 'features/tasks-forms';
import { Project } from 'nekst-api';
import useLaunchProjectNavigator from '../launchProjectNavigator';

export interface CreateProjectFormProps {
  buttons?: ReactElement;
  className?: string;
  title?: string;
  afterSubmitFunc?: AfterSubmitFunc,
}

export default function CreateProjectForm(props: CreateProjectFormProps) {
  const navigator  = useLaunchProjectNavigator();

  return (
    <BaseCreateProjectForm
      title={props.title}
      buttons={props.buttons}
      afterSubmitFunc={async (project: Project) => {
        const result = props.afterSubmitFunc?.(project);
        await navigator.openSecondStep(project.id);

        return result;
      }}
      className={props.className}
    />
  );
}
