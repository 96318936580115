import React from 'react';
import { MaskedInputLayoutProps } from '../types';
import { ErrorsWrapper } from 'ui-builder';
import ReactInputMask from 'react-input-mask';

export default function MaskedInputLayoutWeb(props: MaskedInputLayoutProps) {

  return (
    <ErrorsWrapper errors={props.errors} name={props.name}>
      <ReactInputMask
        mask="(999) 999-9999"
        onChange={(event) => {
          const value = event.target.value?.replace(/[^0-9]/g, '');
          props.onChangeCallback({
            target: {
              value,
            },
          });
        }}
        value={props.value || undefined}
      />
    </ErrorsWrapper>
  );
}
