import { useNavigate } from 'react-router-dom';

export default function usePeopleNavigator() {
  const navigate = useNavigate();

  const getViewPersonPageLink = (id: number) => `/people/${id}`;

  const openViewPersonPage = (id: number) => {
    navigate(getViewPersonPageLink(id));
  };

  const openViewHistoryPage = (id: number) => {
    navigate(`/people/${id}/history`);
  };

  const openPeoplePage = () => navigate('/people');

  return {
    getViewPersonPageLink,
    openViewPersonPage,
    openViewHistoryPage,
    openPeoplePage,
  };
}
