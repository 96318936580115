import { useBackendApi } from 'nekst-api';

export default function useUsedTasksGroupsService() {

  const backendApi = useBackendApi();

  const getUsedTasksGroupsService = async (projectId: number) => {
    return backendApi.get('/projects/' + projectId + '/usedtasksgroups');
  };

  return {
    getUsedTasksGroupsService,
  };
}
