export function isReactNative() {
  if (typeof jest !== 'undefined') {
    return process.env.RN_ENV === 'react-native'; // You can set this env variable in your test setup if necessary
  } else {
    return typeof navigator !== 'undefined' && navigator.product === 'ReactNative';
  }
}

export function ifReactNative<T>(reactNativeValue: T, webValue: T) {
  return isReactNative() ? reactNativeValue : webValue;
}
