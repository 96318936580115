import React from 'react';
import { get } from 'lodash';

import { useShowContext } from 'ui-builder';
import BaseFieldProps from './BaseFieldProps';

export interface TextFieldProps extends BaseFieldProps {

}

export default function TextField(props: TextFieldProps) {
  const context = useShowContext();

  let result;

  if (props.source) {
    if (context.data) {
      result = get(context.data, props.source) ?? '-';
    } else {
      result = '-';
    }
  } else {
    result = props.value;
  }

  return (<span className={props.className}>{result}</span>);
}
