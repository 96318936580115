import React from 'react';
import OnboardingTour from '../../shared/onboarding/OnboardingTour';
import { ProjectPermission } from 'nekst-api';

export default function ProjectDetailsPageTour() {
  return (
    <OnboardingTour
      tourId="PropertyDetailsPage"
      steps={[
        {
          selector: '[data-key="edit-details"]',
          resizeObservables: ['[data-key="edit-details"]'],
          content: 'Manage all transaction details here so that they display transaction on the “Transactions” page.',
        },
        {
          selector: '[data-key="update-project-form"]',
          content: 'Change the Transaction Type, apply a new Action Plan or adjust the status.',
          permission: ProjectPermission.UPDATE,
        },
        {
          selector: '[data-key="project-start-end-date-fields"]',
          content: 'Change the Start and End Dates to move tasks around based on changes in closing date or contract date',
          permission: ProjectPermission.UPDATE,
        },
        {
          selector: '[data-key="edit-launch-button"]',
          content: 'Get back to the 4 Step Process when launching a new transaction.',
          permission: ProjectPermission.UPDATE,
        },
        {
          selector: '[data-key="project-notes"]',
          content: 'Store general notes about a transaction.',
        },
      ]}
    />
  );
}
