import React from 'react';
import OnboardingTour from '../../shared/onboarding/OnboardingTour';
import { ProjectPermission } from './ProjectAuthorizationScope';
import { TeamPermission } from 'nekst-api';

export default function ViewProjectPageTour() {
  return (
    <OnboardingTour
      tourId="PropertyPage"
      steps={[
        {
          selector: '[data-key="add-task-button"]',
          content: (
            <div>
              <p>Any type of task can easily be applied.</p>
              <img src="/img/img_add_task_form.png" alt="Add task button" />
            </div>
          ),
        },
        {
          selector: '[data-key="project-pages-button"]',
          content: (
            <div style={{ width: '35rem' }}>
              <p>Access the Speciality Pages for each individual transaction.</p>
            </div>
          ),
        },
        {
          selector: '[data-key="project-pages-button"]',
          content: (
            <div style={{ width: '35rem' }}>
              <p>Store Notes, Important Dates and Manage Transaction Details.</p>
              <img src="/img/img_project_pages_details.png" alt="Transaction Details" />
            </div>
          ),
          permission: ProjectPermission.READ_DETAILS,
        },
        {
          selector: '[data-key="project-pages-button"]',
          content: (
            <div style={{ width: '35rem' }}>
              <p>View all transaction tasks in a Calendar View.</p>
              <img src="/img/img_project_pages_calendar.png" alt="Transaction Calendar" />
            </div>
          ),
        },
        {
          selector: '[data-key="project-pages-button"]',
          content: (
            <div style={{ width: '35rem' }}>
              <p>View a History of All Tasks Completed, Emails & SMS messages sent and comments.</p>
              <img src="/img/img_project_pages_history.png" alt="Transaction History" />
            </div>
          ),
          permission: ProjectPermission.READ_HISTORY,
        },
        {
          selector: '[data-key="project-team-members"]',
          content: (
            <div>
              View and Edit Team Members assigned to a transaction.
            </div>
          ),
          permission: TeamPermission.CREATE_TEAM_MEMBER,
        },
        {
          selector: '[data-key="project-transaction-parties"]',
          content: (
            <div>
              Add, Remove and Modify Transaction Parties for a Transaction.
            </div>
          ),
          permission: ProjectPermission.MANAGE_ASSIGNMENTS,
        },
      ]}
    />
  );
}
