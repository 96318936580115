import React from 'react';
import { useParams } from 'react-router-dom';
import MainBlock from '../../shared/web/layout/MainBlock';
import ProjectTasksList from './tasks/list/ProjectTasksList';
import { ViewOption } from './tasks/list/TasksList';
import ProjectPagesButtons from './ProjectPagesButtons';
import { OpenProjectTasksICalCalendarButton } from './OpenAllTasksICalCalendarButton';
import { ProjectBreadcrumb } from './ProjectContext';
import { Breadcrumb } from '../../shared/web/layout/Breadcrumbs';
import { AccessChecker, ProjectPermission } from 'authorization-scope';
import { TextField } from 'ui-builder';
import { ProjectTypeField } from 'features/tasks-forms';

function ProjectCalendarBreadcrumbs() {
  return (
    <ProjectBreadcrumb>
      <Breadcrumb text="Calendar" />
    </ProjectBreadcrumb>
  );
}

export default function CalendarPage() {
  const params = useParams();

  const id = parseInt(params.id!, 10);

  return (
    <AccessChecker permissionToCheck={ProjectPermission.READ_TASKS}>
      <ProjectCalendarBreadcrumbs />
      <MainBlock
        title={<TextField source="name" />}
        titleLine2={<ProjectTypeField />}
        headerRight={(
          <ProjectPagesButtons />
        )}
      >
        <ProjectTasksList
          projectId={id}
          view={ViewOption.CALENDAR}
          title={(
            <>
              Calendar
              <OpenProjectTasksICalCalendarButton />
            </>
          )}
        />
      </MainBlock>
    </AccessChecker>
  );
}
