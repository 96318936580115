import React from 'react';
import { DueDateFieldLayoutProps } from '../types';

import styles from './DueDateField.module.scss';

export default function DueDateFieldLayoutWeb(props: DueDateFieldLayoutProps) {
  const fieldStyle = props.widgetStyle ? styles[props.widgetStyle] : '';

  return (
    <div className={`${styles.dueDateField} ${fieldStyle}`}>
      <i className={styles.icon}>
        &nbsp;
      </i>
      {props.value}
    </div>
  );
}
