import React from 'react';
import { ConfirmDeletePopup, IconButton, IconType } from 'features/nekst-widgets';
import { TasksGroup, useTasksGroupsService } from 'nekst-api';
import { usePopupManager, useShowContextRequired } from 'ui-builder';

function DeleteGroupPopup() {
  const tasksGroupsService = useTasksGroupsService();

  const showContext = useShowContextRequired<TasksGroup>();

  return (
    <ConfirmDeletePopup
      deleteFunc={async () => {
        await tasksGroupsService.deleteTaskGroup(showContext.data!.id);
      }}
      message={`By clicking 'Confirm', the group "${showContext.data!.name}" will be removed. All tasks within this group will be ungrouped.`}

    />
  );
}

export function DeleteGroupButton() {

  const popupManager = usePopupManager();

  return (
    <IconButton
      onClick={() => {
        popupManager.openPopup(<DeleteGroupPopup />);
      }}
      type={IconType.X}
    />
  );
}
