import React from 'react';
import {
  useTasksCompletionService,
  MassCompleteErrorAction,
} from 'nekst-api';
import {
  usePopupManager,
  Button,
  ButtonColor,
  ButtonSize,
  isReactNative,
  useMassUpdateServiceRequired,
} from 'ui-builder';

export default function IgnoreErrorsMassCompleteButton() {
  const tasksCompletionService = useTasksCompletionService();

  const massUpdateService = useMassUpdateServiceRequired();

  const checkedIds = massUpdateService.getCheckedIds();

  const popupManager = usePopupManager();

  const submit = async () => {
    await tasksCompletionService.massComplete(checkedIds, MassCompleteErrorAction.IGNORE);
    popupManager.closePopup();
  };

  return (
    <Button
      text="Skip these tasks"
      color={ButtonColor.GREEN}
      onClick={submit}
      size={isReactNative() ? ButtonSize.MEDIUM : ButtonSize.BIG}
    />
  );
}
