import React from 'react';
import {
  Button,
  ButtonColor,
  ButtonSize,
  IconType,
} from 'ui-builder';
import { usePopupFormManager } from 'features/nekst-widgets';
import { PeopleFilterForm } from 'features/tasks-forms';

export default function PeopleFilterButton() {
  const popupManager = usePopupFormManager();

  return (
    <Button
      text="Filter"
      color={ButtonColor.BLUE}
      iconType={IconType.FILTER}
      size={ButtonSize.MEDIUM}
      onClick={() => {
        popupManager.openForm(<PeopleFilterForm />);
      }}
    />
  );
}
