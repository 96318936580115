import React, { ReactElement, useEffect, useState } from 'react';
import LoadingAnimation from './LoadingAnimation';

export default function DataLoader<DataType>(
  props: {
    getDataFunc: () => Promise<DataType>,
    children: (data: DataType) => ListOrSingle<ReactElement> | null,
  },
) {
  const [data, setData] = useState<DataType | null>(null);

  useEffect(() => {
    async function loadData() {
      setData(await props.getDataFunc());
    }

    loadData();
  }, [props.getDataFunc]);

  if (data) {
    return <>{props.children(data)}</>;
  } else {
    return <LoadingAnimation />;
  }
}
