import React, { useState } from 'react';
import { get } from 'lodash';
import { CheckboxInput, ClearOnDestruct, LoadingAnimation, TextArea } from 'ui-builder';
import If from '../../shared/uibuilder/form/IfFormData';
import ServiceProvidersGroupSelector from './ServiceProvidersGroupSelector';
import Label from '../../shared/uibuilder/form/layout/Label';

import styles from './ClientPortalConfigurationForm.module.scss';
import Row6x6x from '../../shared/web/uitheme/form/Row6x6x';
import { AbstractTask, useProjectTasksService } from 'nekst-api';
import useDataLoader from 'data-loader';

export function KeyDateToDisplayWidget(props: {
  task: AbstractTask,
}) {
  const prefix = `keyDatesLinks.task${props.task.id}`;

  const getPlaceholder = () => {

    let result = props.task.description;

    if (result) {
      result = result.replaceAll('</p>', '</p>\r\n');
      // Create a temporary element to hold the parsed HTML
      const tempElement = document.createElement('div');
      tempElement.innerHTML = result;

      const mentionElements = tempElement.querySelectorAll('span.mention');

      mentionElements.forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.outerHTML = `#${element.getAttribute('data-value')}` || '';
      });

      result = tempElement.innerHTML;

      result = result.replace(/<\/?[^>]+(>|$)/g, '');

    }

    return result;
  };

  return (
    <Row6x6x>
      <>
        <CheckboxInput
          source={`${prefix}.enabled`}
          label={props.task._renderedName}
        />
        <If conditionFunc={(data) => !!get(data, `${prefix}.enabled`)}>
          <TextArea
            source={`${prefix}.description`}
            hideLabel
            className={styles.keyDateDescription}
            placeholder={getPlaceholder()}
            hint="If the description field is left empty, the original task description will be used."
          />
          <ClearOnDestruct source={prefix} />
        </If>
      </>
      <If conditionFunc={(data) => !!get(data, `${prefix}.enabled`)}>
        <ServiceProvidersGroupSelector
          source={`${prefix}.serviceProviders`}
          label="Include Service Providers"
        />
      </If>
    </Row6x6x>
  );
}

export default function KeyDatesToDisplayWidget(
  props: {
    projectId: number,
    label: string,
  },
) {
  const projectTaskService = useProjectTasksService();

  const [
    keyDateTasks,
    setKeyDateTasks,
  ] = useState<AbstractTask[]>();

  async function loadKeyDates() {
    const tasks = await projectTaskService.getAllTasks(
      props.projectId,
      {
        showKeyDatesOnly: true,
      },
      {
        limit: 1000,
        offset: 0,
      },
    );

    return tasks.data;
  }

  useDataLoader(
    loadKeyDates,
    setKeyDateTasks,
  );

  if (keyDateTasks) {
    return (
      <div className={styles.keyDateWidget}>
        <Label label={props.label} />
        {keyDateTasks.map((task) => (
          <KeyDateToDisplayWidget task={task} key={`key-date-group-${task.id}`} />
        ))}
      </div>
    );
  } else {
    return (<LoadingAnimation />);
  }
}
