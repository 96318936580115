import React from 'react';
import BorderedBox from '../../web/layout/BorderedBox';
import styles from './DetailBoxesDatesWidget.module.scss';
import TextLink from '../TextLink';

export type DetailBoxesDatesWidgetItem = {
  id: number,
  name: string,
  linkedField: {
    id: number,
    name: string
  }
  openEditTaskForm: () => void,
}

export default function DetailBoxesDatesWidgetLayout(
  props: {
    fields: DetailBoxesDatesWidgetItem[],
    loading: boolean,
  },
) {
  return (
    <BorderedBox title="Detail Boxes - Dates" className={props.loading ? 'loading' : ''}>
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      <div className={styles.widget}>
        {props.fields.map((item) => (
          <div key={`detail-box-date-${item.id}`} className={styles.link}>
            <div className={styles.title}>{item.linkedField.name}</div>
            <div className={styles.name}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <TextLink
                uri="#"
                action={item.openEditTaskForm}
              >
                {item.name}
              </TextLink>
            </div>
          </div>
        ))}
      </div>
    </BorderedBox>
  );
}
