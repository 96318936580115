import { Route, Routes } from 'react-router-dom';
import React from 'react';
import LaunchProjectWrapper from '../LaunchProjectWrapper';
import SelectGroupsPage from './SelectGroupsPage';
import ManageKeyDatesPage from './ManageKeyDatesPage';
import AllTasksPage from './AllTasksPage';
import TransactionPartiesPage from './TransactionParties/TransactionPartiesPage';
import ValidateDefinedContractDataPage from './ValidateData/ValidateDefinedContractDataPage';

function PropertiesWrapperRouter() {
  return (
    <LaunchProjectWrapper withParseContractData>
      <Routes>
        <Route
          path="verify"
          element={<ValidateDefinedContractDataPage />}
        />
        <Route
          path="groups"
          element={<SelectGroupsPage />}
        />
        <Route
          path="keydates"
          element={<ManageKeyDatesPage />}
        />
        <Route
          path="tasks"
          element={<AllTasksPage />}
        />
        <Route
          path="parties"
          element={<TransactionPartiesPage />}
        />
      </Routes>
    </LaunchProjectWrapper>
  );
}

export default function useLaunchFromContractModule() {
  const getRouter = () => {
    return (
      <>
        <Route
          path="/transactions/:id/launchfromcontract/*"
          element={<PropertiesWrapperRouter />}
        />
      </>
    );
  };

  return {
    getRouter,
  };
}
