import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import {
  AccessDeniedError,
  SEAT_LIMITS_UPDATED,
  SeatUsageInfo, TEAM_MEMBER_DELETED,
  useEventsSubscriber,
  useSeatUsageService
} from 'nekst-api';
import { PopupContext } from 'ui-builder';

type SeatUsageReactContextData = {
  seatUsage?: SeatUsageInfo,
}

const SeatUsageReactContext = React.createContext<SeatUsageReactContextData | undefined>(undefined);

function useSeatUsageContext() {
  return useContext(SeatUsageReactContext);
}

export function useSeatUsageLoader() {
  const seatUsageService = useSeatUsageService();

  const contextData = useSeatUsageContext();

  const [seatUsage, setSeatUsage] = useState<SeatUsageInfo>();


  const loadAndSetData = useCallback(async () => {
    try {
      setSeatUsage(await seatUsageService.getSeatUsage());
    } catch (e) {
      if (e instanceof AccessDeniedError) {
        setSeatUsage(undefined);
      } else {
        throw e;
      }
    }
  }, []);

  useEffect(() => {
    if (contextData) {
      setSeatUsage(contextData.seatUsage);
    } else {
      loadAndSetData();
    }
  }, [
    JSON.stringify(contextData?.seatUsage)
  ]);

  useEventsSubscriber('TeamMembersSeatsWidgetImplementation', {
    [SEAT_LIMITS_UPDATED]: loadAndSetData,
    [TEAM_MEMBER_DELETED]: loadAndSetData,
  });

  return {
    seatUsage,
  };
}

export function SeatUsageContext(
  props: { children: ReactNode }
) {
  const { seatUsage } = useSeatUsageLoader();

  return (
    <SeatUsageReactContext.Provider
      value={{
        seatUsage,
      }}
    >
      <PopupContext>
        {props.children}
      </PopupContext>
    </SeatUsageReactContext.Provider>
  );
}
