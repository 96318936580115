import React, { ReactNode } from 'react';
import { TooltipIconType } from './types';
import Layout from './Layout';

export interface TooltipIconProps {
  tooltipContent: ReactNode,
  iconType: TooltipIconType
}

export function TooltipIcon(props: TooltipIconProps) {
  return (
    <Layout tooltipContent={props.tooltipContent} iconType={props.iconType} />
  );
}
