import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ShowContext, useShowContext } from 'ui-builder';
import {
  useProjectAssignmentsService,
  ContactAssignments,
  PROJECT_ASSIGNMENTS_CHANGED,
  useEventsSubscriber,
  useProjectsService,
  Project,
  useProjectPlanService,
  ProjectPlan, PROJECT_UPDATED,
} from 'nekst-api';
import { useAuthenticationLoader } from '../../authentication/AuthenticationContext';
import { Breadcrumb } from '../../shared/web/layout/Breadcrumbs';
import { ProjectsListBreadcrumbs } from '../list/ProjectsListPage';

export interface ProjectContextProps {
  projectId: number,
  children: ReactElement | ReactElement[]
}

interface ProjectAssignmentsLoader {
  assignments: ContactAssignments[],
  loadAssignments: (forced?: boolean) => Promise<void>,
  areAssignmentsLoaded: () => boolean,
}

interface ProjectPlanLoader {
  plan?: ProjectPlan;
  loadPlan: (forced?: boolean) => Promise<void>;
  isPlanLoaded: () => boolean;
}

interface ProjectContextInnerData extends ProjectAssignmentsLoader, ProjectPlanLoader {

}

export interface ProjectContextData extends ProjectContextInnerData {
  data: Nullable<Project>;
  isWithinContext: () => boolean,
}

export const ProjectReactContext = React.createContext<ProjectContextInnerData>(
  {} as ProjectContextInnerData,
);

export function useProjectContext(): ProjectContextData {
  const projectContext = useContext(ProjectReactContext);

  const { data } = useShowContext<Project>();

  return {
    ...projectContext,
    data: data || null,
    isWithinContext: () => !!projectContext.assignments,
  };
}

function useAssignmentsLoader(projectId: number) {
  const projectAssignmentsService = useProjectAssignmentsService();

  const { currentUser } = useAuthenticationLoader();

  const [assignments, setAssignments] = useState<ContactAssignments[] | null>(null);

  const loadAssignments = async (forced?: boolean) => {
    if (forced || assignments === null) {
      const result = await projectAssignmentsService.getContactAssignments(projectId);
      setAssignments(result.sort((a1, a2) => {
        if (a1.personId === currentUser.id) {
          return -1;
        } else if (a2.personId === currentUser.id) {
          return 1;
        } else {
          return 0;
        }
      }));
    }
  };

  const forcedLoadAssignments = async () => loadAssignments(true);

  useEventsSubscriber(
    'ProjectContext',
    {
      [PROJECT_ASSIGNMENTS_CHANGED]: forcedLoadAssignments,
    },
  );

  return {
    assignments: assignments || [],
    areAssignmentsLoaded: () => assignments !== null,
    loadAssignments,
  };
}

function useProjectPlanLoader(projectId: number): ProjectPlanLoader {
  const planService = useProjectPlanService();

  const [plan, setPlan] = useState<ProjectPlan>();

  const loadPlan = async (forced?: boolean) => {
    if (forced || !plan) {
      const result = await planService.getPlanByProjectId(projectId);
      setPlan(result);
    }
  };

  return {
    plan,
    loadPlan,
    isPlanLoaded: () => !!plan,
  };
}

export function ProjectBreadcrumb(
  props: {
    children?: ReactElement | false,
  },
) {
  const context = useProjectContext();

  return (
    <ProjectsListBreadcrumbs>
      {!!context.data && (
        <Breadcrumb text={context.data!.name} href={`/transactions/${context.data!.id}`}>
          {props.children}
        </Breadcrumb>
      )}
    </ProjectsListBreadcrumbs>
  );
}

export default function ProjectContext(props: ProjectContextProps) {
  const projectService = useProjectsService();
  const assignmentsLoader = useAssignmentsLoader(props.projectId);
  const planLoader = useProjectPlanLoader(props.projectId);

  const [version, setVersion] = useState(0);

  useEventsSubscriber(
    'ProjectContext',
    {
      [PROJECT_UPDATED]: () => setVersion((prev) => prev + 1),
    },
  );

  const projectContextData = useMemo<ProjectContextInnerData>(
    () => ({
      ...assignmentsLoader,
      ...planLoader,
    }),
    [assignmentsLoader.assignments, planLoader.plan],
  );

  useEffect(() => {
    assignmentsLoader.loadAssignments();
  }, []);

  return (
    <ProjectReactContext.Provider value={projectContextData}>
      <ShowContext
        getDataFunc={() => projectService.getById(props.projectId)}
        dependencies={[version]}
      >
        {props.children}
      </ShowContext>
    </ProjectReactContext.Provider>
  );
}
