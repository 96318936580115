import { isTeamMember, Person, ProjectAssignment, TeamRole, User } from './Types';
import { useBackendApi } from './backendApi';
import { CacheItemManager, useCacheService } from './cacheService';
import { ValidationSchema } from 'validation-schema';
import { CreatePersonRequest, usePeopleService } from './peopleService';
import { ResourceNotFoundError, ValidationError } from 'request-sender';
import { useEventsPublisher, useEventsSubscriber } from 'event-bus';
import { INVITATION_RESENT } from './resendInvitationService';

export type TeamMember = Person & {
  _assignments?: ProjectAssignment[]
  _user: User,
  hasActiveAccount: boolean,
  teamRole: TeamRole,
}

export type CreateTeamMemberRequest = {
  firstName: string,
  lastName: string,
  email: string
  teamRole: TeamRole
};

export const TEAM_MEMBER_DELETED = 'TEAM_MEMBER_DELETED';

export function useTeamMembersService() {
  const cacheService = useCacheService('contactsService');

  const peopleService = usePeopleService();

  const backendApi = useBackendApi();

  function removeCache() {
    cacheService.clear('teamMembers');
  }

  const eventsPublisher = useEventsPublisher();

  const getTeamMembers = async () => {
    return cacheService.get<Person[]>('teamMembers', async (cacheItem: CacheItemManager) => {
      cacheItem.setExpireInSeconds(180);
      return await backendApi.get('/teammembers') as Person[];
    });
  };

  useEventsSubscriber('teamMembersService', {
    [INVITATION_RESENT]: removeCache,
  });

  const getCreateTeamMemberValidationSchema = () => {
    return {
      firstName: {
        type: 'string',
        constraints: {
          required: true,
          maxLength: 50,
        },
      },
      lastName: {
        type: 'string',
        constraints: {
          required: true,
          maxLength: 50,
        },
      },
      email: {
        type: 'string',
        constraints: {
          required: true,
          maxLength: 255,
          email: true,
        },
      },
      teamRole: {
        type: 'string',
        constraints: {
          required: true,
        },
      }
    } as ValidationSchema;
  };

  const createTeamMember = async (request: CreateTeamMemberRequest) => {

    const peopleServiceRequest = {
      profile: {
        name: {
          firstName: request.firstName,
          lastName: request.lastName,
        },
        primaryEmail: request.email,
      },
      teamRole: request.teamRole,
    } as CreatePersonRequest;

    try {
      const result = await peopleService.create(peopleServiceRequest);

      removeCache();

      return result;
    } catch (e) {
      if (e instanceof ValidationError) {
        const initialErrors = e.errors;

        const keyMap: Record<string, string> = {
          'profile.primaryEmail': 'email',
          'profile.name.firstName': 'firstName',
          'profile.name.lastName': 'lastName',
          'teamRole': 'teamRole',
        };

        const errors = Object.keys(initialErrors)
          .reduce((acc, key) => {
            const newKey = keyMap[key] || key;
            return {
              ...acc,
              [newKey]: initialErrors[key],
            };
          }, {} as Record<string, string[]>);

        throw new ValidationError(errors);
      } else {
        throw e;
      }
    }
  };

  const getById = async (id: number) => {
    const person = await peopleService.getById(id);

    if (isTeamMember(person.teamRole)) {
      return person as TeamMember;
    } else {
      throw new ResourceNotFoundError({});
    }
  };

  const deleteTeamMember = async (id: number) => {
    await peopleService.deletePerson(id);

    removeCache();

    eventsPublisher.publish(TEAM_MEMBER_DELETED, {
      id
    });
  };

  return {
    getTeamMembers,
    createTeamMember,
    getCreateTeamMemberValidationSchema,
    getById,
    deleteTeamMember,
  };
}
