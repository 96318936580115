import React from 'react';
import { AfterSubmitFunc, useFormContext } from 'ui-builder';
import { SMS_TASK_SENT, useEventsSubscriber } from 'nekst-api';
import useProjectTasksService, {
  SendingStatus,
  SmsTask,
  TaskPermission,
} from '../../projectTasksService';
import NameWithCompleteCheckbox from '../NameWithCompleteCheckbox';
import UpdateTaskButtons from '../UpdateTaskButtons';
import BaseSmsTaskForm from './BaseSmsTaskForm';
import ClosePopupOnTaskCompletion from '../ClosePopupOnTaskCompletion';

export interface UpdateSmsTaskFormProps {
  data: SmsTask;
  afterSubmitFunc?: AfterSubmitFunc;
}

function SmsTaskDataUpdater() {
  const formContext = useFormContext();

  useEventsSubscriber(
    'SmsTaskDataUpdater',
    {
      [SMS_TASK_SENT]: (event) => {
        if (formContext.data!.id === event.id) {
          formContext.setFormData!(event.data);
        }
      },
    },
  );
  return null;
}

export default function UpdateSmsTaskForm(props: UpdateSmsTaskFormProps) {
  const projectTasksService = useProjectTasksService();

  return (
    <BaseSmsTaskForm
      afterSubmitFunc={props.afterSubmitFunc}
      submitFormFunc={async (formData) => {
        const result = await projectTasksService.updateTask(props.data.id, formData);
        return result as SmsTask;
      }}
      data={props.data}
      initialData={props.data}
      title={<NameWithCompleteCheckbox data={props.data} />}
      buttons={<UpdateTaskButtons />}
      isDisabled={(data) => {
        return !data.permissions?.includes(TaskPermission.UPDATE)
          || data.sendingStatus !== SendingStatus.NOT_SENT;
      }}
    >
      <>
        <ClosePopupOnTaskCompletion />
        <SmsTaskDataUpdater />
      </>
    </BaseSmsTaskForm>
  );
}
