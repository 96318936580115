import React, {
  ReactElement,
  useContext,
  useMemo,
  useState,
} from 'react';
import { List } from 'ui-builder';

export interface LoadMoreResponse<DataType> {
  data: DataType[],
  nextPageToken: string | number | null
}

interface LoadMoreListProps<DataType, FilterType> {
  getDataFunc: (
    filter: FilterType | null,
    nextPageToken: string | number | null,
  ) => Promise<LoadMoreResponse<DataType>>,
  children: ReactElement | ReactElement[],
  defaultFilterValue?: FilterType,
  dependencies?: any[],
}

interface LoadMorePaginationData {
  hasMoreItems: boolean;
  nextPageToken: string | number | null,
  loadNextPage: () => void;
}

const LoadMorePaginationContext = React.createContext<LoadMorePaginationData | null>(null);

export function useLoadMorePaginationContext() {
  return useContext(LoadMorePaginationContext)!;
}

export default function LoadMorePaginationList<DataType, FilterType>(
  props: LoadMoreListProps<DataType, FilterType>,
) {
  const [nextPageToken, setNextPageToken] = useState<string | number | null>(null);

  const [version, setVersion] = useState(0);

  const getDataFunc = async (filter: FilterType, previousData: DataType[]) => {
    const response = await props.getDataFunc(filter, nextPageToken);
    let newData;
    if (nextPageToken) {
      newData = [
        ...previousData,
        ...response.data,
      ];
    } else {
      newData = response.data;
    }
    setNextPageToken(response.nextPageToken);
    return newData;
  };

  const contextValue = useMemo<LoadMorePaginationData>(() => {
    return {
      hasMoreItems: !!nextPageToken,
      loadNextPage: () => {
        if (nextPageToken) {
          setVersion((prev: number) => prev + 1);
        }
      },
      nextPageToken,
    };
  }, [nextPageToken]);

  const dependencies = [
    version,
    ...props.dependencies ? props.dependencies : [],
  ];

  return (
    <List<DataType, FilterType>
      getDataFunc={getDataFunc}
      defaultFilterValue={props.defaultFilterValue}
      dependencies={dependencies}
      loadDataOnFilterUpdate
      onFilterUpdate={() => setNextPageToken(null)}
    >
      <LoadMorePaginationContext.Provider value={contextValue}>
        {props.children}
      </LoadMorePaginationContext.Provider>
    </List>
  );
}
