import React, { useEffect, useMemo, useRef, useState } from 'react';
import { InputLabel, SmartTagsTextInputLayoutProps, Tag, TagType } from 'ui-builder';

import styles from './SmartTagsTextInput.module.scss';

function useKeyboardNavigator(
  numberOfItems: number,
  onEnter: (index: number) => void,
) {
  const [focusedIndex, setFocusedIndex] = useState<number>(0);

  const setFocusedIndexClamped = (index: number) => {
    if (index <= 0) {
      setFocusedIndex(0);
    } else if (index >= numberOfItems) {
      setFocusedIndex(numberOfItems - 1);
    } else {
      setFocusedIndex(index);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'ArrowDown') {
      setFocusedIndexClamped(focusedIndex + 1);
    } else if (event.key === 'ArrowUp') {
      setFocusedIndexClamped(focusedIndex - 1);
    } else if (event.key === 'Enter') {

      if (numberOfItems > 0) {
        event.preventDefault();
        if (focusedIndex >= 0) {
          onEnter(focusedIndex);
          setFocusedIndex(0);
        }
      }
    }
  };

  return {
    focusedIndex,
    onKeyDown,
  };
}

function filterHtml(input: string) {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.body.textContent || '';
}

function doHighlightTags(input: string, allTags: Tag[], startSymbol: '#' | '@') {
  let value = input;
  if (value) {
    const regex = startSymbol === '#' ? /#([\w\\.]+)/g : /@([\w\\.]+)/g;

    const tags = value.match(regex) || [];

    const uniqueTags = Array.from(new Set(tags));

    uniqueTags.forEach((tag) => {
      const tagName = tag.substring(1);
      const tagObject = allTags.find((t) => t.id === tagName);

      if (tagObject) {
        const tagElement = document.createElement('span');
        tagElement.setAttribute('contenteditable', 'false');
        tagElement.classList.add(styles.insertedTag);
        tagElement.innerText = tag;

        value = value!.replaceAll(
          tag,
          `<span class="${styles.insertedTag}" contenteditable="false" data-id="${tagObject.id}">${startSymbol}${tagObject.value}</span>`
        );
      }
    });
  }

  return value || '';
}

function highlightSmartTags(input: string, tags: Tag[]) {
  return doHighlightTags(
    input,
    tags.filter((tag) => !tag.type || tag.type === TagType.TAG),
    '#'
  );
}

function highlightMentions(input: string, tags: Tag[]) {
  return doHighlightTags(
    input,
    tags.filter((tag) => tag.type === TagType.MENTION),
    '@'
  );
}

function highlightTags(input: string, tags: Tag[]) {
  let value = filterHtml(input);

  value = highlightMentions(value, tags);
  value = highlightSmartTags(value, tags);

  return value;
}

function splitTextToDivs(input: string) {
  const lines = input.split('\n');

  return lines.map((line) => {
    if (line) {
      return `<div>${line}</div>`;
    } else {
      return `<div><br/ ></div>`;
    }
  }).join('');
}

export default function BaseTagsInputLayout(props: SmartTagsTextInputLayoutProps) {
  const [visibleTags, setVisibleTags] = useState<Tag[]>([]);

  const tagsValueToIdMap = useMemo(() => {
    const result: Record<string, string> = {};

    props.availableTags.forEach((tag) => {
      if (tag.type === TagType.MENTION) {
        result[tag.value] = `@${tag.id}`;
      } else {
        result[tag.value] = `#${tag.id}`;
      }
    });

    return result;
  }, [visibleTags.length]);

  const [inputLeftOffset, setInputLeftOffset] = useState<number>();

  const ref = useRef<HTMLDivElement>(null);

  const hiddenDivRef = useRef<HTMLDivElement>(null);

  const [selectionListLeftOffset, setSelectionListLeftOffset] = useState<number>();

  const [suggestionsListPosition, setSuggestionsListPosition] = useState<{
    left: number,
    top: number
  }>();

  const initialValue = useMemo(() => {
    return splitTextToDivs(highlightTags(props.value || '', props.availableTags));
  }, []);


  function adjustBlockHeight() {
    if (hiddenDivRef.current) {
      hiddenDivRef.current!.innerHTML = ref.current!.innerHTML;

      const height = hiddenDivRef.current.scrollHeight;

      ref.current!.style.height = `${height + 15}px`;
    }
  }

  useEffect(() => {
    adjustBlockHeight();
  }, [hiddenDivRef.current, ref.current]);


  useEffect(() => {
    if (!props.value) {
      ref.current!.innerHTML = '';
    }
  }, [!props.value]);

  const updateCurrentValue = () => {
    let newValue = ref.current!.innerText.replace(/\u00a0/g, ' ');

    // trim blank symbols and new line symbols in the end
    newValue = newValue.replace(/[\s\n]*$/, '');

    const tags = newValue.match(/[#|@]([\w\\.]+)/g) || [];

    const uniqueTags = Array.from(new Set(tags));

    uniqueTags.forEach((tag) => {
      // get tag name without the first character
      const tagName = tag.substring(1);
      if (tagName in tagsValueToIdMap) {
        newValue = newValue.replaceAll(
          tag,
          `${tagsValueToIdMap[tagName]}`
        );
      }
    });

    props.onChangeCallback({
      target: {
        value: newValue.replaceAll("\n\n", "\n")
      }
    });

    adjustBlockHeight();
  };

  useEffect(() => {
    // Set the initial value when the component mounts
    if (ref.current && props.value) {
      ref.current.innerHTML = initialValue;
    }
  }, []);

  function hideSuggestions() {
    setVisibleTags([]);
    setSelectionListLeftOffset(undefined);
  }

  function getCursorCoordinates() {
    const selection = window.getSelection();
    if (!selection || !selection.rangeCount) {
      return {
        x: 0,
        y: 0
      };
    }

    const range = selection.getRangeAt(0)
      .cloneRange();
    const span = document.createElement('span');
    // Ensure the span has content so it takes up space
    span.textContent = '\u200b'; // Zero-width space
    range.insertNode(span);
    const {
      offsetTop,
      offsetLeft
    } = span;

    const coordinates = {
      x: offsetLeft,
      y: offsetTop
    };
    span.remove();
    return coordinates;
  }

  function selectSuggestion(tag: Tag) {
    const selection = window.getSelection();

    if (!selection || !selection.rangeCount) return;

    const value = selection.anchorNode?.textContent || '';

    const valueBeforeCursor = value.substring(0, selection?.anchorOffset || 0);
    const sharpIndex = valueBeforeCursor.lastIndexOf('#');
    const atIndex = valueBeforeCursor.lastIndexOf('@');

    const usedIndex = Math.max(sharpIndex, atIndex);
    const tagType = sharpIndex > atIndex ? TagType.TAG : TagType.MENTION;

    const range = document.createRange();
    range.setStart(selection.anchorNode!, usedIndex);
    range.setEnd(selection.anchorNode!, selection.anchorOffset);

    range.deleteContents(); // Delete the typed '#' | '@' and any selected text

    const tagElement = document.createElement('span');
    tagElement.setAttribute('contenteditable', 'false');
    tagElement.classList.add(styles.insertedTag);

    const startSymbol = tagType === TagType.MENTION ? '@' : '#';
    tagElement.innerText = startSymbol + tag.value;

    range.insertNode(tagElement);
    range.insertNode(document.createTextNode(' '));

    // Move the cursor after the inserted node
    range.setStartAfter(tagElement);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);

    hideSuggestions();

    updateCurrentValue();
  }

  const keyboardNavigator = useKeyboardNavigator(
    visibleTags.length,
    (index) => selectSuggestion(visibleTags[index])
  );

  const getTagsOfType = (tagType: TagType) => {
    if (tagType === TagType.MENTION) {
      return props.availableTags
        .filter((tag) => tag.type === tagType);
    } else {
      return props.availableTags
        .filter((tag) => !tag.type || tag.type === tagType);
    }
  };

  const updateSuggestionsPosition = () => {
    if (ref.current) {
      const selection = window.getSelection();

      if (selection) {
        const value = selection!.anchorNode?.textContent || '';
        const valueBeforeCursor = value.substring(0, selection?.anchorOffset || 0);
        const sharpIndex = valueBeforeCursor.lastIndexOf('#');
        const atIndex = valueBeforeCursor.lastIndexOf('@');

        const usedIndex = Math.max(sharpIndex, atIndex);

        if (usedIndex > -1) {
          const range = document.createRange();
          range.setStart(selection.anchorNode!, usedIndex);
          range.setEnd(selection.anchorNode!, selection.anchorOffset);

          const rect = range.getBoundingClientRect();
          setSuggestionsListPosition({
            left: rect.left,
            top: rect.bottom,
          });
        } else {
          setSuggestionsListPosition(undefined);
        }
      } else {
        setSuggestionsListPosition(undefined);
      }
    }
  };

  const onInput = () => {
    const selection = window.getSelection();

    const value = selection!.anchorNode?.textContent || '';

    const valueBeforeCursor = value.substring(0, selection?.anchorOffset || 0);

    const sharpIndex = valueBeforeCursor.lastIndexOf('#');

    const atIndex = valueBeforeCursor.lastIndexOf('@');

    if (sharpIndex > -1 || atIndex > -1) {
      const usedIndex = Math.max(sharpIndex, atIndex);

      const tagType = sharpIndex > atIndex ? TagType.TAG : TagType.MENTION;

      const query = valueBeforeCursor.substring(usedIndex + 1);

      if (query.length >= 0) {
        setVisibleTags(
          getTagsOfType(tagType)
            .filter((tag) => tag.value.toLowerCase()
              .includes(query.toLowerCase()))
        );

        if (selectionListLeftOffset === undefined) {
          const coordinates = getCursorCoordinates();
          setSelectionListLeftOffset(coordinates.x - (inputLeftOffset || 0));
        }
      } else {
        hideSuggestions();
      }
    } else {
      hideSuggestions();
    }

    updateCurrentValue();
    updateSuggestionsPosition();
  };

  useEffect(() => {
    if (ref.current) {
      setInputLeftOffset(ref.current.offsetLeft + 30);
    }
  }, [ref]);

  const lineClassName = props.isMultiLine ? 'multi-line' : 'single-line';

  return (
    <div>
      {!props.hideLabel && <InputLabel label={props.label} source={props.name} />}
      <div
        className={`block-like-input ${lineClassName}`}
        contentEditable
        suppressContentEditableWarning
        onInput={onInput}
        ref={ref}
        onKeyDown={(event) => {
          keyboardNavigator.onKeyDown(event);

          if (event.key === 'Escape') {
            hideSuggestions();
          }
        }}
        onBlur={() => {
          hideSuggestions();
        }}
      >{initialValue}</div>

      <div
        ref={hiddenDivRef}
        style={{
          visibility: 'hidden',
          position: 'absolute',
          display: 'block',
          top: 0,
          left: 0,
          height: 'auto',
          width: ref.current ? ref.current.offsetWidth : 'auto',
          pointerEvents: 'none',
          fontSize: ref.current ? getComputedStyle(ref.current).fontSize : undefined,
          lineHeight: ref.current ? getComputedStyle(ref.current).lineHeight : undefined,
          padding: ref.current ? getComputedStyle(ref.current).padding : undefined,
        }}
        contentEditable
        id="hidden-div"
      >{initialValue}</div>
      <ul
        style={{
          display: visibleTags.length > 0 ? 'block' : 'none',
          position: 'fixed',
          left: suggestionsListPosition?.left,
          top: suggestionsListPosition?.top,
        }}
        className={styles.suggestionsList}
      >
        {visibleTags.map((tag, index) => (
          <li
            className={index === keyboardNavigator.focusedIndex ? styles.active : ''}
            key={tag.id}
            onClick={() => selectSuggestion(tag)}
            onMouseDown={(event) => {
              event.preventDefault();
            }}
          >
            {tag.value}
          </li>
        ))}
      </ul>
    </div>
  );
}
