import { Plan, ProjectType, usePlansService } from 'nekst-api';
import { AfterSubmitFunc, FormTemplate } from 'ui-builder';
import { FormText } from 'features/nekst-widgets';
import React from 'react';
import { ProjectTypeDropdown } from '../../Projects';

export function ChangePlanTypeForm(
  props: {
    plan: Plan,
    title?: string,
    message?: string,
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {
  const planService = usePlansService();

  return (
    <FormTemplate<{ projectType: ProjectType }, Plan>
      submitFormFunc={async (data) => {
        return planService.changePlanType(props.plan.id, data.projectType);
      }}
      afterSubmitFunc={props.afterSubmitFunc}
      title={props.title || 'Change Plan Type'}
      initialData={{
        projectType: props.plan.projectType,
      }}
      validationSchema={{
        projectType: {
          type: 'string',
          constraints: {
            required: true,
          },
        },
      }}
    >
      <FormText>
        {props.message || (
          <>
            Changing the &quot;Type&quot; will remove all links between tasks and
            the Detail Boxes.
          </>
        )}
      </FormText>
      <ProjectTypeDropdown source="projectType" label="Transaction Type" />
    </FormTemplate>
  );
}
