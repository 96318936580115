import React from 'react';
import { useNewUiTheme } from '../newUiTheme';
import { BlockProps } from './blocksTypes';
import { FormRows } from '../Form/FormRows';

export function FormBlock(props: BlockProps) {
  const uiTheme = useNewUiTheme();

  return (
    <uiTheme.common.FormBlockLayout
      style={props.style}
      className={props.className}
    >
      <FormRows>
        {props.children}
      </FormRows>
    </uiTheme.common.FormBlockLayout>
  );
}
