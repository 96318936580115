import React from 'react';
import { BadgeLayoutProps } from '../types';

import styles from './BadgeLayout.module.scss';

export default function BadgeLayoutWeb(props: BadgeLayoutProps) {
  const { number } = props;

  const classes = [
    styles.badge,
    props.withBorder ? styles.withBorder : ''
  ]

  return (
    <div className={classes.join(' ')}>
      {number}
    </div>
  );
}
