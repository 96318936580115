import { useBackendApi } from './backendApi';
import { useEventsPublisher } from 'event-bus';

export type SeatLimit = {
  freeMembersSeatsLimit: number;
  freeAdministratorsSeatsLimit: number;
  teamMembersLimit: number
  teamAdministratorsLimit: number
}

export type SeatUsage = {
  freeMembersSeatsUsed: number,
  freeAdministratorsSeatsUsed: number,
  teamMembersSeatsUsed: number,
  teamAdministratorsSeatsUsed: number,
}

export type SeatUsageInfo = {
  limits: SeatLimit;
  usage: SeatUsage;
  memberSeatPrice: number,
  administratorSeatPrice: number,
  membersSeatsAvailable: number,
  administratorsSeatsAvailable: number,
}

export type UpdateSeatLimitRequest = {
  membersSeats: number,
  administratorsSeats: number,
}

export type BillingPeriod = {
  start: Date,
  endDate: Date,
}

export type BillingInvoiceLine = {
  description?: string,
  amount: number,
  period: BillingPeriod,

}

export type BillingInvoice = {
  startingBalance: number,
  endingBalance: number,
  invoiceLines: BillingInvoiceLine[],
}

export const SEAT_LIMITS_UPDATED = 'SEAT_LIMITS_UPDATED';

export function useSeatUsageService() {

  const backendService = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const getSeatUsage = async () => {
    return await backendService.get('/billing/seats/usage') as SeatUsageInfo;
  };

  const updateSeatLimit = async (
    request: UpdateSeatLimitRequest,
  ) => {
    const result = await backendService.put('/billing/seats/limits', request) as SeatUsageInfo;

    eventsPublisher.publish(SEAT_LIMITS_UPDATED, result.limits);

    return result;
  };

  const mapDates = (entry: Record<string, any>): BillingInvoice => {
    return {
      ...entry,
      invoiceLines: entry.invoiceLines.map((line: Record<string, any>) => {
        return {
          ...line,
          period: {
            start: new Date(line.period.start),
            endDate: new Date(line.period.endDate),
          }
        }
      }),
    } as BillingInvoice;
  }

  const getFutureInvoice = async (
    request: UpdateSeatLimitRequest,
  ) => {
    return mapDates(await backendService.post('/billing/seats/invoicepreview', request));
  }

  return {
    getSeatUsage,
    updateSeatLimit,
    getFutureInvoice,
  };
}
