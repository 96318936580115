import React from 'react';
import { get } from 'lodash';

import styles from './LinkedTaskField.module.scss';
import { DateField, SimpleBlock, useFormContext, Text } from 'ui-builder';
import { Image, Spacings } from 'react-native-ui-lib';
import { ImageSourcePropType } from 'react-native';

import anchorIcon from './anchorIcon.svg';

export function LinkedTaskField(
  props: {
    fieldId: number,
    // eslint-disable-next-line react/no-unused-prop-types
    label: string,
    dataKey?: string,
  },
) {
  const formContext = useFormContext();
  const data = formContext.data!;

  const value = get(data, `fields.${props.fieldId}.value`);

  const linkedTaskName = get(data, `fields.${props.fieldId}.linkedTask.name`);

  return (
    <SimpleBlock className={styles.widget} style={{
      flexDirection: 'row',
      gap: Spacings.s1
    }} testID={props.dataKey}>
      <Image source={anchorIcon as ImageSourcePropType} />
      <SimpleBlock style={{ flexDirection: 'row' }}>
        <DateField source="" value={value} noValueText="No Due Date" />
        <Text>{` (${linkedTaskName})`}</Text>
      </SimpleBlock>
    </SimpleBlock>
  );
}
