import { RequestDataProvider, RequestSender, useRequestSender } from 'request-sender';
import { useTokenContext } from '../Authentication';
import { useApiConfiguration } from './ApiConfigurationContext';

export function useBackendApi(): RequestSender {
  const tokenContext = useTokenContext();

  const apiConfiguration = useApiConfiguration();

  const dataProviders: RequestDataProvider[] = [
    {
      getHeaders: () => tokenContext.getApiHeaders(),
    },
    {
      getHeaders: () => ({
        'X-NEKST-TIMEZONE': Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    }
  ];

  return useRequestSender({
    baseUrl: apiConfiguration.backendUrl,
    dataProviders,
  });
}
