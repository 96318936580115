import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import useUiTheme from '../../../uiTheme';
import { PriceLayoutProps } from 'ui-builder';

export default function PriceLayout(props: PriceLayoutProps) {
  const uiTheme = useUiTheme();

  return (
    <uiTheme.forms.BaseInputLayout
      name={props.name}
      label={props.label}
      errors={props.errors}
      hideLabel
    >
      <CurrencyInput
        onValueChange={(value) => {
          props.onChangeCallback({
            target: {
              value: value ?? null,
            },
          });
        }}
        placeholder={props.placeholder}
        value={props.value || undefined}
        prefix="$"
        allowDecimals={false}
        decimalSeparator="."
        disableAbbreviations
      />
    </uiTheme.forms.BaseInputLayout>
  );
}
