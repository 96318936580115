import {
  usePeopleService,
} from 'nekst-api';


export type {
  PersonPhoneNumber,
  PersonProfile,
  User,
  Person,
} from 'nekst-api';

export {
  OptInStatus,
  PersonPhoneNumberType,
} from 'nekst-api';

export type {
  CreatePersonRequest,
} from 'nekst-api';

export {
  CURRENT_USER_PROFILE_UPDATED,
  PERSON_DELETED,
  PERSON_CREATED,
  PEOPLE_DELETED_IN_BULK,
} from 'nekst-api';

export default usePeopleService;
