import React, { ReactElement, useMemo, useState } from 'react';
import { FormFieldsData } from '../Form';
import { GetListDataFuncType, VisibilityState } from './types';
import useDataLoader from 'data-loader';
import { ListReactContext } from './ListContext';
import { PopupContext } from '../Popup';
import { LoadingAnimation } from '../Common';
import { useFilterContext } from './FilterContext';

export interface ListProps<DataType = FormFieldsData, Filter = null> {
  getDataFunc: GetListDataFuncType<DataType>,
  onDataUpdated?: (data: DataType[]) => void,
  children: ReactElement | (ReactElement | boolean)[],
  defaultFilterValue?: Filter,
  dependencies?: any[],
  loadDataOnFilterUpdate?: boolean,
  onFilterUpdate?: () => void
}

export function useBaseList<DataType = FormFieldsData,
  Filter = null,
>(
  props: ListProps<DataType, Filter>,
) {
  const [data, setData] = useState<DataType[]>([]);

  const filterContext = useFilterContext<Filter>();

  const [innerFilterValue, setInnerFilterValue] = useState<Filter | null>(
    props.defaultFilterValue || null,
  );

  const [innerVersion, setInnerVersion] = useState(0);

  const dependencies = [
    ...props.dependencies || [],
  ];

  const actualFilterValue = innerFilterValue || filterContext?.filterValue;

  if (props.loadDataOnFilterUpdate) {
    dependencies.push(JSON.stringify(actualFilterValue));
  }

  const doSetData = (data: DataType[]) => {
    setData(data);
    setInnerVersion((prev) => prev + 1);
  };

  const {
    loading,
    setLoading,
    refreshData,
    version,
  } = useDataLoader<DataType[]>(
    async () => {
      return props.getDataFunc(actualFilterValue, data);
    },
    (newData: DataType[]) => {
      setData(newData);
      if (props.onDataUpdated) {
        props.onDataUpdated(newData);
      }
    },
    ...dependencies,
  );

  return useMemo(() => {
    return {
      data,
      isLoading: loading,
      refreshData,
      hasVisibleItems: () => data.length > 0,
      getVisibilityState: () => VisibilityState.VISIBLE,
      filterValues: actualFilterValue,
      version: version + innerVersion,
      hasMorePages: () => false,
      loadNextPage: () => {
        // empty
      },
      setFilterValues: (value: any) => {
        if (props.onFilterUpdate) {
          props.onFilterUpdate();
        }
        setInnerFilterValue((prev) => {
          return ({
            ...prev,
            ...value,
          });
        });
      },
      getItemVersion: () => version + innerVersion,
      _setLoading: setLoading,
      _setData: doSetData,
    };
  }, [
    loading,
    version,
    innerVersion,
    JSON.stringify(actualFilterValue),
  ]);
}

export function List<DataType = FormFieldsData, Filter = null>(props: ListProps<DataType, Filter>) {
  const contextData = useBaseList<DataType, Filter>(props);

  return (
    <ListReactContext.Provider
      value={contextData}
    >
      <PopupContext>
        <>
          {props.children}
          {!contextData?.data.length && contextData.isLoading && <LoadingAnimation />}
        </>
      </PopupContext>
    </ListReactContext.Provider>
  );
}
