import React from 'react';
import { PersonPermission, usePeopleService } from 'nekst-api';
import { Button, ButtonColor, ButtonSize, usePopupManager } from 'ui-builder';
import usePeopleNavigator from '../peopleNavigator';
import useAuthorizationChecker from '../../shared/authorization/authorizationChecker';
import { ConfirmDeletePopup } from 'features/nekst-widgets';

function ConfirmPersonDeletePopup(props: { personId: number }) {
  const peopleService = usePeopleService();

  const peopleNavigator = usePeopleNavigator();

  const message = 'By clicking "Confirm", the chosen user will be permanently removed from your directory and from all associated transactions.';

  return (
    <ConfirmDeletePopup
      deleteFunc={async () => {
        await peopleService.deletePerson(props.personId);
        peopleNavigator.openPeoplePage();
      }}
      message={message}
    />
  );
}

export default function RemovePersonButton(props: { personId: number }) {
  const { isGranted } = useAuthorizationChecker();

  const popupManager = usePopupManager();

  if (isGranted(PersonPermission.DELETE)) {
    return (
      <Button
        text="Remove"
        color={ButtonColor.RED}
        size={ButtonSize.MEDIUM}
        isFilled
        onClick={
          () => popupManager.openPopup(
            <ConfirmPersonDeletePopup
              personId={props.personId}
            />,
          )
        }
      />
    );
  } else {
    return null;
  }
}
