/* istanbul ignore file */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TeamPermission } from 'nekst-api';
import { useAuthorizationChecker } from 'authorization-scope';

import styles from './MainMenu.module.scss';
import useFeatureToggleService from 'feature-toggle';

export interface MainMenuItemProps {
  text: string;
  icon: string;
  uri: string;
}

function MainMenuItem(props: MainMenuItemProps) {
  const navigate = useNavigate();

  const isActive = window.location.pathname.startsWith(props.uri);
  return (
    <a
      href={props.uri}
      className={`${styles.item} ${props.icon} ${isActive ? 'active' : ''}`}
      onClick={(e) =>  {
        e.preventDefault();
        navigate(props.uri);
      }}
      style={{
        textDecoration: 'none'
      }}
      data-test={`nav-${props.icon}`}
    >
      <div className={styles.bg}>
        <span className={styles.text}>{props.text}</span>
        &nbsp;
      </div>
    </a>
  );
}

export default function MainMenu() {
  const { isGranted } = useAuthorizationChecker();

  const featureToggleService = useFeatureToggleService();

  if (isGranted(TeamPermission.ACCESS_FUNCTIONALITY)) {
    return (
      <div
        className={[
          styles.menu,
          featureToggleService.isNewUiEnabled() ? styles.new : '',
        ].join(' ')}
      >
        <div>
          {isGranted(TeamPermission.READ_TASKS) && (
            <MainMenuItem
              text="Tasks"
              icon="tasks"
              uri="/tasks"
            />
          )}
          {isGranted(TeamPermission.READ_TASKS) && (
            <MainMenuItem
              text="Calendar"
              icon="calendar"
              uri="/calendar"
            />
          )}
          <MainMenuItem
            text="Transactions"
            icon="properties"
            uri="/transactions"
          />
          {isGranted(TeamPermission.READ_CONTACTS) && (
            <MainMenuItem
              text="Contacts"
              icon="contacts"
              uri="/people"
            />
          )}
          {isGranted(TeamPermission.READ_PLANS) && (
            <MainMenuItem
              text="Plans"
              icon="plans"
              uri="/plans"
            />
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
}
