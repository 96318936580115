import React, { useCallback, useEffect, useState } from 'react';
import { InputLayoutIcon } from 'ui-builder';
import { debounce } from 'lodash';
import TextLayout from '../../../shared/uibuilder/form/input/layout/TextLayout';
import useTextFilter from './textFilter';

import styles from '../History.module.scss';
import { useListContext } from '../../../shared/uibuilder/list/ListReactContext';

export default function HistoryEventFullTextFilter() {
  const filter = useTextFilter();

  const listContext = useListContext();

  const [value, setValue] = useState<any>(filter.getFilterValue());

  const updateFilter = useCallback(debounce(filter.setFilterValue, 500), [
    JSON.stringify(listContext.filterValues),
  ]);

  useEffect(() => {
    updateFilter(value);
  }, [value]);

  return (
    <TextLayout
      hideLabel
      value={value}
      name="nameFilter"
      onChangeCallback={(event) => {
        setValue(event.target.value);
      }}
      isVisible
      placeholder="Search History"
      isDisabled={false}
      className={styles.tasksSearch}
      icon={InputLayoutIcon.SEARCH}
    />
  );
}
