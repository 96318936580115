import React from 'react';
import MainBlock from '../../../shared/web/layout/MainBlock';
import CompleteAccountForm from './CompleteAccountForm';
import Row6x6x from '../../../shared/web/uitheme/form/Row6x6x';

export default function CompleteProfilePage() {
  return (
    <MainBlock title="Create Account">
      <Row6x6x>
        <CompleteAccountForm />
      </Row6x6x>
    </MainBlock>
  );
}
