import React from 'react';
import { TooltipIconLayoutProps } from '../types';
import { useHover, useLayer } from 'react-laag';
import { IconButton, IconType } from '../../IconButton';

import styles from './TooltipIconLayout.module.scss';

export default function TooltipIconLayoutWeb(
  props: TooltipIconLayoutProps
) {


  const [isOpen, hoverProps] = useHover(
    {
      delayLeave: 50,
      hideOnScroll: true,
    },
  );

  const {
    renderLayer,
    triggerProps,
    layerProps,
  } = useLayer({
    isOpen,
    placement: 'bottom-center',
    overflowContainer: false,
    triggerOffset: 10,
    containerOffset: 12,
    auto: true,
    possiblePlacements: [
      'bottom-center',
      'bottom-start',
      'bottom-end'
    ],
  });

  return (
    <span {...triggerProps} {...hoverProps} className={styles.iconBlock}>
      <IconButton onClick={() => {
        // no action
      }} type={IconType.HELP} />
      {isOpen && renderLayer(
        <>
          <div {...layerProps} className={styles.layer}>
            {props.tooltipContent}
          </div>
        </>
      )}
    </span>
  );
}
