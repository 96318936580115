import React from 'react';
import { useNewUiTheme } from '../newUiTheme';
import { ButtonSize } from '../Button';
import { ImageSourcePropType, TextStyle, ViewStyle } from 'react-native';

interface Option<DataType = string | number> {
  label: string,
  value: DataType,
  onSelected: () => void,
  disabled?: boolean,
  labelStyle?: TextStyle
  title?: string,
}

interface OptionSelectionButtonProps<DataType = string | number> {
  text: string,
  options: (Option<DataType> | null | false)[],
  size: ButtonSize,
  dataKey?: string,
  iconSource?: ImageSourcePropType,
  style?: ViewStyle,
  labelStyle?: TextStyle,

  optionsStyle?: ViewStyle,
  customButton?: React.FC<{onClick: () => void}>,
  mobile_popupTitle?: string,
}

export interface OptionSelectionButtonLayoutProps<DataType = string | number> extends OptionSelectionButtonProps<DataType> {
  text: string,
  options: Option<DataType>[],
  size: ButtonSize,
  dataKey?: string,
  iconSource?: ImageSourcePropType,
  style?: ViewStyle,
  labelStyle?: ViewStyle,
  optionsStyle?: ViewStyle,
  mobile_popupTitle?: string,
  customButton?: React.FC<{onClick: () => void}>,
}

export function OptionSelectionButton<DataType = string | number>(
  props: OptionSelectionButtonProps<DataType>,
) {
  const uiTheme = useNewUiTheme();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const Layout = uiTheme.widgets.OptionSelectionButtonLayout as React.FC<OptionSelectionButtonLayoutProps<DataType>>;

  return (
    <Layout
      {...props}
      options={props.options.filter((option) => !!option) as Option<DataType>[]}
      customButton={props.customButton}
    />
  );
}
