import { useEffect } from 'react';
import { useListContextRequired } from '../ListContext';
import { useMassUpdateServiceRequired } from './MassUpdate';
import { VisibilityState } from '../types';
import { useFilterContext } from '../FilterContext';

export function SelectAllUpdater() {
  const listContext = useListContextRequired();

  const filterContext = useFilterContext();

  const massUpdateService = useMassUpdateServiceRequired();

  useEffect(() => {
    if (!listContext.isLoading) {
      const data = listContext.data || [];

      const ids = data
        .map((item) => item.id)
        .filter((id: number) => listContext.getVisibilityState(id) === VisibilityState.VISIBLE);

      massUpdateService.setIds(ids);
    }
  }, [listContext.version, listContext.isLoading, JSON.stringify(filterContext.filterValue)]);

  return null;
}
