import React from 'react';
import { TextStyle } from 'react-native';
import { Text, useShowContext } from 'ui-builder';
import { useProjectTypeHelper } from './projectTypeHelper';
import { get } from 'lodash';

export function ProjectTypeField(
  props: {
    style?: TextStyle,
    source?: string,
    detailsFieldsSetNameSource?: string
  }
) {
  const showContext = useShowContext();

  const data = showContext.data;

  const value = get(data, props.source || 'type');

  const detailsFieldsSetName = get(data, props.detailsFieldsSetNameSource || '_detailsFieldsSet.name');

  const projectTypeHelper = useProjectTypeHelper();

  let label = projectTypeHelper.getLabel(value);

  if (detailsFieldsSetName) {
    label += ` - ${detailsFieldsSetName}`;
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Text style={props.style}>
      {label}
    </Text>
  );
}
