import React, { ReactNode } from 'react';
import { IsVisibleFunc } from '../uibuilder/form';

export interface LeftProps {
  children: ReactNode;
  className?: string;
  maxWidth?: number,
  // eslint-disable-next-line react/no-unused-prop-types
  isVisible?: IsVisibleFunc;
}

export default function Right({
  children,
  className,
  maxWidth,
}: LeftProps) {
  return (
    <div
      className={className}
      style={{
        float: 'right',
        maxWidth: maxWidth ? `${maxWidth}%` : undefined,
        marginBottom: '1rem',
      }}
    >
      {children}
    </div>
  );
}
