import React, { ReactNode } from 'react';
import { List, ListDataRefresher, MassUpdate } from 'ui-builder';
import useProjectPlanTaskService, { ProjectPlanTask } from './projectPlanTaskService';
import InformationBox from '../../shared/web/layout/InformationBox';
import MassUpdateControls from '../../plans/MassUpdate/MassUpdateControls';
import SelectAllUpdater from '../../shared/widgets/MassUpdate/SelectAllUpdater';
import styles from '../../shared/widgets/TaskRowLayout.module.scss';
import PlanTaskRow from '../../plans/tasks/List/PlanTaskRow';
import MainBlock from '../../shared/web/layout/MainBlock';
import PlanFilterContainer from '../../plans/tasks/List/Filter/PlanFilterContainer';
import AssigneeFilter from '../../plans/tasks/List/Filter/AssigneeFilter';
import TypeFilter from '../../plans/tasks/List/Filter/TypeFilter';
import { useProjectContext } from '../view/ProjectContext';
import { ProjectTypeContext } from '../../plans/view/ViewPlan';
import { RowRepeater } from 'features/nekst-widgets';
import { MassUpdateCheckbox } from 'features/tasks-list-feature';
import {
  PLAN_TASKS_UPDATED,
  replaceTagsIdsWithValues,
  TaskType,
  useSmartTagsService
} from 'nekst-api';
import { PlanTasksClientSideFilter } from 'features/tasks-forms';
import { PlanAuthorizationScope, PlanPermission } from 'authorization-scope';
import { NextButton, PreviousButton } from './Buttons';

export interface BaseTasksPageProps {
  filterFunc?: (task: ProjectPlanTask) => boolean,
  forceShowKeyDatesOnly?: boolean
  onPrevClicked: () => void
  onNextClicked: () => void
  roadmap: ReactNode,
}

export default function BaseTasksPage(props: BaseTasksPageProps) {
  const planTasksService = useProjectPlanTaskService();

  const projectContext = useProjectContext();

  const showKeyDatesOnly = props.forceShowKeyDatesOnly;

  const smartTagsService = useSmartTagsService();

  const getDataFunc = async () => {
    const tasks = await planTasksService.getTasksByPlanId(projectContext.plan!.id);

    let result;

    if (props.filterFunc) {
      result = tasks.filter(props.filterFunc)
        .map((task) =>{
          return {
            ...task,
          }
        });
    } else {
      result = tasks;
    }

    const tags = await smartTagsService.getSmartTags(projectContext.data!.type, TaskType.EMAIL);

    return result
      .map((task) => {
        return {
          ...task,
          _renderedName: task.type === TaskType.EMAIL ? replaceTagsIdsWithValues(task.name, tags) : task.name,
        };
      });
  };

  return (
    <>
      <InformationBox
        text="All tasks are editable. Edit/remove tasks as necessary. New tasks can be added after launch."
      />
      {props.roadmap}
      <div
        style={{
          marginBottom: '4rem',
          overflow: 'hidden',
        }}
      >
        <PreviousButton onClick={props.onPrevClicked} key="previous-button" />
        <NextButton onClick={props.onNextClicked} key="next-button" />
      </div>
      {projectContext.plan && (
        <ProjectTypeContext projectType={projectContext.data!.type}>
          <PlanAuthorizationScope
            id={projectContext.plan.id}
            excludedPermissions={[PlanPermission.CREATE_TASK]}
          >
            <MainBlock
              title=""
              columnContent={<PlanFilterContainer />}
            >
              <MassUpdate>
                <div style={{ clear: 'left' }}>
                  <MassUpdateCheckbox />
                </div>
                <MassUpdateControls />
                <List
                  getDataFunc={getDataFunc}
                >
                  <PlanTasksClientSideFilter>
                    <AssigneeFilter />
                    <TypeFilter forceShowKeyDatesOnly={showKeyDatesOnly} />
                    <ListDataRefresher eventName={PLAN_TASKS_UPDATED} />
                    <SelectAllUpdater />
                    <RowRepeater
                      idField="id"
                      blockClassName={styles.block}
                      groupClassName={styles.group}
                      parentIdField="parentTaskRelation.parentTaskId"
                      noDataMessage="Current plan has no key dates"
                    >
                      <PlanTaskRow />
                    </RowRepeater>
                  </PlanTasksClientSideFilter>
                </List>
              </MassUpdate>
            </MainBlock>
          </PlanAuthorizationScope>
        </ProjectTypeContext>

      )}
    </>
  );
}
