import React, { useState } from 'react';
import { Tag } from './SmartTagsWysiwyg';
import { TextInputProps, TextLayoutProps } from './TextInput';
import { useNewUiTheme } from '../../newUiTheme';
import { useInputHelper } from './inputHelper';
import useDataLoader from 'data-loader';
import { LoadingAnimation } from '../../Common';

export interface SmartTagsTextInputLayoutProps extends TextLayoutProps {
  availableTags: Tag[],
  hideHowToUseLink?: boolean,
  isMultiLine?: boolean,
}

interface SmartTagsTextInputProps extends TextInputProps {
  hideHowToUseLink?: boolean,
  availableTags: Tag[],
}

export function SmartTagsTextInput(props: SmartTagsTextInputProps) {
  const uiTheme = useNewUiTheme();

  const inputHelper = useInputHelper(props);

  return (
    <uiTheme.inputs.SmartTagsTextInputLayout
      {...inputHelper.getBaseInputLayoutProps()}
      availableTags={props.availableTags}
      hideHowToUseLink={props.hideHowToUseLink}
    />
  );
}

interface SmartTagsTextInputWithLoadProps extends TextInputProps {
  hideHowToUseLink?: boolean,
  getTagsMethod: () => Promise<Tag[]>,
  dependencies: any[]
}

export function SmartTagsTextInputWithLoad(props: SmartTagsTextInputWithLoadProps) {
  const [smartTags, setSmartTags] = useState<Tag[]>();
  useDataLoader(
    props.getTagsMethod,
    setSmartTags,
    ...props.dependencies
  );

  const inputHelper = useInputHelper(props);

  const value = inputHelper.getValue();

  if (smartTags !== undefined || value === undefined) {
    return (
      <SmartTagsTextInput
        {...props}
        availableTags={smartTags || []}
        source="name"
      />
    );
  } else {
    return (<LoadingAnimation />);
  }
}
