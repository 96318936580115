import React from 'react';

export default function HowToUseSmartTagsLink() {
  return (
    <div
      style={{
        textAlign: 'right',
        marginBottom: '1rem',
        fontSize: '1.2rem',
      }}
    >
      <a
        href="https://help.nekst.com/en-us/article/smart-tags-pre-fill-your-emails-with-transaction-info-1w4f4be/"
        target="_blank"
        rel="noreferrer"
      >
        How to use smart tags?
      </a>
    </div>
  );
}
