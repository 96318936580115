import React from 'react';
import { Route } from 'react-router-dom';
import { ApplicationModule } from '../shared/applicationModule';
import useLaunchProjectModule from './launch/launchProjectModule';
import CreateProjectPage from './launch/CreateUpdateProject/CreateProjectPage';
import AccessChecker from '../shared/authorization/AccessChecker';
import ViewProject from './view/ViewProject';
import ProjectDetails from './details';
import ProjectWrapper from './ProjectWrapper';
import AllTasksListPage from '../tasks/list/AllTasksListPage';
import ProjectHistory from './history';
import ArchivedProjectChecker from './launch/ArchivedProjectChecker';
import ProjectsListPage from './list/ProjectsListPage';
import CalendarPage from './view/CalendarPage';
import ClientPortalConfigurationPage from './clientportal/ClientPortalConfigurationPage';
import ProjectDocumentsPage from './documents/ProjectDocumentsPage';
import { TeamPermission } from 'nekst-api';
import OldProjectRouteRedirector from './OldProjectRouteRedirector';
import useLaunchFromContractModule from './launch/FromContract/launchFromContractModule';

export default function useProjectsModule(): ApplicationModule {
  const launchProjectModule = useLaunchProjectModule();

  const launchFromContractModule = useLaunchFromContractModule();

  const getRouter = () => {
    return (
      <>
        <Route
          path="/transactions"
          element={(
            <ProjectsListPage />
          )}
        />
        <Route
          path="/transactions/new"
          element={(
            <AccessChecker permissionToCheck={TeamPermission.CREATE_PROJECT}>
              <CreateProjectPage />
            </AccessChecker>
          )}
        />
        {launchProjectModule.getRouter()}
        {launchFromContractModule.getRouter()}
        <Route
          path="/transactions/:id"
          element={<ProjectWrapper />}
        >
          <Route
            path=""
            element={(
              <ArchivedProjectChecker>
                <ViewProject />
              </ArchivedProjectChecker>
            )}
          />
          <Route
            path="/transactions/:id/details"
            element={(
              <ArchivedProjectChecker>
                <ProjectDetails />
              </ArchivedProjectChecker>
            )}
          />
          <Route
            path="/transactions/:id/calendar"
            element={(
              <ArchivedProjectChecker>
                <CalendarPage />
              </ArchivedProjectChecker>
            )}
          />
          <Route
            path="/transactions/:id/history"
            element={(
              <ArchivedProjectChecker>
                <ProjectHistory />
              </ArchivedProjectChecker>
            )}
          />
          <Route
            path="/transactions/:id/clientportal"
            element={(
              <ClientPortalConfigurationPage />
            )}
          />
          <Route
            path="/transactions/:id/documents"
            element={(
              <ProjectDocumentsPage />
            )}
          />
        </Route>
        <Route
          path="/tasks"
          element={<AllTasksListPage />}
        />
        <Route
          path="/properties"
          element={<OldProjectRouteRedirector />}
        />
        <Route
          path="/properties/:firstKey"
          element={<OldProjectRouteRedirector />}
        />
        <Route
          path="/properties/:firstKey/:secondKey"
          element={<OldProjectRouteRedirector />}
        />
        <Route
          path="/properties/:firstKey/:secondKey/:thirdKey"
          element={<OldProjectRouteRedirector />}
        />
      </>
    );
  };

  return {
    getRouter,
    name: 'projects',
  };
}
