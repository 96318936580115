import React from 'react';
import { useDateHelper, Project } from 'nekst-api';
import { useShowContext } from 'ui-builder';

export default function DateDetailsField(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: {
    // eslint-disable-next-line react/no-unused-prop-types
    label: string,
    // eslint-disable-next-line react/no-unused-prop-types
    sortName?: string,
    fieldName?: string
    fieldId?: number
  },
) {
  const context = useShowContext<Project>();

  const details = context.data!.statistic!.details;

  const dateHelper = useDateHelper();

  const field = details
    .find((d) => d.setting.name === props.fieldName || d.setting.id === props.fieldId);

  const date = field?.value;

  if (date) {
    const result = new Date(date);

    return (
      <>
        {field.approximate ? 'approx. ' : ''}
        {dateHelper.toString(result)}
      </>
    );
  } else {
    return <>-</>;
  }
}
