import { useEffect, useState } from 'react';
import { ProjectRole, useProjectRolesService } from 'nekst-api';

export function useProjectRolesLoader() {
  const [roles, setRoles] = useState<ProjectRole[]>([]);

  const projectRolesService = useProjectRolesService();

  useEffect(() => {
    async function loadRoles() {
      setRoles(await projectRolesService.getAllTeamRoles());
    }
    loadRoles();
  }, []);

  const getRoleById = (id: number) => {
    return roles.find((r) => r.id === id);
  };

  const isLoaded = () => {
    return roles.length > 0;
  };

  return {
    roles,
    getRoleById,
    isLoaded,
  };
}
