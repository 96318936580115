import React from 'react';
import { useMassUpdateServiceRequired } from 'ui-builder';
import { TeamPermission } from 'nekst-api';
import { useAuthorizationChecker } from 'authorization-scope';
import Layout from './Layout';

export function MassUpdateCheckbox() {
  const massUpdateService = useMassUpdateServiceRequired();

  const { isGranted } = useAuthorizationChecker();

  const isGrantedUpdate = isGranted(TeamPermission.MANAGE_TASKS);

  if (isGrantedUpdate && isGranted(TeamPermission.USE_MASS_UPDATE_FEATURE)) {
    return (
      <Layout
        name="massUpdate"
        value={massUpdateService.isEnabled()}
        onChange={() => {
          massUpdateService.toggleEnabled();
        }}
      />
    );
  } else {
    return null;
  }
}
