export enum VisibilityState {
  VISIBLE = 'VISIBLE',
  DISABLED = 'DISABLED',
  HIDDEN = 'HIDDEN'
}

export interface ListFilterService<FilterValueType = any, DataType = any> {
  getName: () => string,
  getDefaultValue: () => FilterValueType | undefined,
  getFilterValue: () => FilterValueType | undefined,
  setFilterValue: (value: FilterValueType) => void,
  applyFilter: ApplyFilterFunc<DataType>
}

export type GetListDataFuncType<DataType = Record<string, any>>
  = (filter: any, previousData: DataType[]) => Promise<DataType[]>;

export type GetOrderGroupFunc<DataType> = (item: DataType) => string;

export type UpdateOrderFunc = (ids: number[]) => Promise<void>

export type ApplyFilterFunc<DataType> = (data: DataType, allItems: DataType[]) => VisibilityState
