import React from 'react';
import { Route } from 'react-router-dom';
import { ApplicationModule } from '../shared/applicationModule';
import LoginPage from './Login/LoginPage';
import RequestResetPasswordPage from './ResetPassword/RequestResetPasswordPage';
import ResetPasswordPage from './ResetPassword/ResetPasswordPage';

export default function useLoginModule(): ApplicationModule {
  const getRouter = () => {
    return (
      <>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/resetpassword" element={<RequestResetPasswordPage />} />
        <Route path="/resetpassword/:token" element={<ResetPasswordPage />} />
      </>
    );
  };

  return {
    getRouter,
    name: 'login',
  };
}
