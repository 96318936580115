import React, { ReactElement, useEffect, useState } from 'react';
import { useEventsPublisher, useEventsSubscriber } from 'event-bus';
import { ApiTokenData, AuthenticationTokenContext, TokenStorageInterface } from './index';

export const ACCESS_TOKEN_UPDATED = 'ACCESS_TOKEN_UPDATED';

export function ApiTokenContext(
  props: {
    children: ReactElement | ReactElement[],
    tokenStorage: TokenStorageInterface,
    loadingView?: ReactElement,
  },
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [version, setVersion] = useState(0);

  const eventsPublisher = useEventsPublisher();

  function updateVersion() {
    setVersion((prev) => prev + 1);
  }

  const setToken = async (token: string | undefined) => {
    await props.tokenStorage.saveToken(token);
    updateVersion();
    eventsPublisher.publish(ACCESS_TOKEN_UPDATED, {});
  };

  useEventsSubscriber(
    'ApplicationTokenContext',
    {
      [ACCESS_TOKEN_UPDATED]: updateVersion,
    },
    [version],
  );

  const [contextValue, setContextValue] = useState<ApiTokenData>();

  const loadContextValue = async () => {
    let result: ApiTokenData;
    const token = await props.tokenStorage.getToken();

    if (token) {
      result = {
        getApiHeaders: () => ({
          'X-NEKST-TOKEN': token,
        }),
        isTokenSet: () => true,
        setToken,
      };
    } else {
      result = {
        getApiHeaders: () => ({}),
        isTokenSet: () => false,
        setToken,
      };
    }

    setContextValue(result as ApiTokenData);
  };

  useEffect(() => {
    loadContextValue();
  }, [version]);

  if (contextValue) {
    return (
      <AuthenticationTokenContext
        data={contextValue}
      >
        {props.children}
      </AuthenticationTokenContext>
    );
  } else {
    return props.loadingView || null;
  }
}
