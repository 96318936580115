import React from 'react';
import { useShowContext } from 'ui-builder';
import { AbstractTask } from 'nekst-api';

import { DateWithIconWidget } from 'features/nekst-widgets';

export function DueDateField() {

  const { data } = useShowContext<AbstractTask>();

  if (data?.dueDate || !data?.parentTaskRelation) {
    return (
      <DateWithIconWidget value={data?.dueDate?.date} noValueText="No Due Date" />
    )
  } else {
    return null;
  }
}
