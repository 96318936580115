/* istanbul ignore file */
import React from 'react';
import { FormRowsProps } from 'ui-builder';
import RelativeRow from './RelativeRow';

export default function Row1x2x(props: FormRowsProps) {
  return (
    <RelativeRow weights={[4, 8]}>
      {props.children}
    </RelativeRow>
  );
}
