import React from 'react';
import useLoginNavigator, { LOGIN_PAGE_LINK } from '../loginNavigator';

export default function BackToLoginLink() {
  const loginNavigator = useLoginNavigator();
  return (
    <a
      href={LOGIN_PAGE_LINK}
      onClick={(event) => {
        event.preventDefault();
        loginNavigator.openLoginPage();
      }}
    >
      Back To Login
    </a>
  );
}
