import React from 'react';

export default function Error403() {
  return (
    <>
      <h2>Access Denied</h2>
      <div>You do not have permission to access this page</div>
    </>
  );
}
