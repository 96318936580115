import { useEventsPublisher } from 'event-bus';
import { AbstractTask } from './Types';
import { useBackendApi } from './backendApi';

export const TASKS_MASS_SET_DUE_DATE = 'TASKS_MASS_DUE_DATE';

export interface TasksMassSetDueDateEvent {
  ids: number[],
  data: AbstractTask[],
}

export function useProjectTasksSetDueDateService() {

  const nekstApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const massSetDueDate = async (ids: number[], dueDate?: Date) => {
    const result = await nekstApi.put('/tasks/bulk/duedate', {
      ids,
      dueDate,
    }) as AbstractTask[];

    eventsPublisher.publish<TasksMassSetDueDateEvent>(TASKS_MASS_SET_DUE_DATE, {
      ids,
      data: result,
    });

  };

  return {
    massSetDueDate,
  };
}
