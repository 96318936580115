import { useEffect } from 'react';
import { FormFieldsData } from 'ui-builder';
import { ValidationSchema } from 'validation-schema';
import { Permission, ProjectType } from './Types';
import { useBackendApi } from './backendApi';
import { useEventsPublisher } from 'event-bus';
import { ResourceNotFoundError } from 'request-sender';
import { useCacheService } from './cacheService';

export const PLAN_PROJECT_TYPE_UPDATED = 'PLAN_PROJECT_TYPE_UPDATED';

export const PLAN_UPDATED = 'PLAN_UPDATED';

export const PLAN_CREATED = 'PLAN_CREATED';

export const PLAN_DELETED = 'PLAN_DELETED';

export const PLAN_SHARED = 'PLAN_SHARED';

export type Plan = {
  id: number,
  name: string,
  isGlobal: boolean,
  projectType: ProjectType,
  detailsFieldsSetId?: number,
  _detailsFieldsSet?: {
    id: number,
    name: string,
    contractTemplateId: number,
  }
}

export type PlanProjectTypeUpdatedEvent = {
  id: number,
  projectType: ProjectType,
}

export function usePlansService() {
  const plansApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const cacheService = useCacheService('plans');

  const clearCache = () => {
    (window as any).getAllTeamPlans = null;
  };

  const publishEvent = (event: string, eventData: any) => {
    clearCache();
    eventsPublisher.publish(event, eventData);
  }

  useEffect(() => {
    return () => {
      clearCache();
    };
  }, []);

  const doGetAllTeamPlans = async (): Promise<Plan[]> => {
    const result = await plansApi.get('/plans') as Plan[];

    result.sort((plan1: Plan, plan2: Plan) => {
      if (plan1.isGlobal && !plan2.isGlobal) {
        return -1;
      } else if (plan2.isGlobal && !plan1.isGlobal) {
        return 1;
      } else {
        return plan1.name.localeCompare(plan2.name);
      }
    });
    return result;
  };

  const publishPlanUpdated = (newPlanData: Plan) => {
    clearCache();
    eventsPublisher.publish(PLAN_UPDATED, {
      data: newPlanData,
    });
  };

  const getAllTeamPlans = async (projectType?: ProjectType): Promise<Plan[]> => {
    if (!(window as any).getAllTeamPlans) {
      (window as any).getAllTeamPlans = doGetAllTeamPlans();
    }

    if (projectType) {
      const result = await (window as any).getAllTeamPlans as Plan[];

      return result
        .filter((item) => item.projectType === projectType);
    } else {
      return (window as any).getAllTeamPlans;
    }
  };

  const create = async (data: FormFieldsData) => {
    const result = await plansApi.post('/plans', data);

    publishEvent(PLAN_CREATED, {
      data: result,
      isCopied: true,
    });
    return result;
  };

  const getCreateValidationSchema = () => {
    return {
      name: {
        type: 'string',
        constraints: {
          required: true,
        },
      },
      projectType: {
        type: 'string',
        constraints: {
          required: true,
        },
      },
    } as ValidationSchema;
  };

  const getById = async (id: number) => {
    const plans = await getAllTeamPlans();
    const plan = plans.find((item) => item.id === id);

    if (plan) {
      return plan;
    } else {
      throw new ResourceNotFoundError(`Plan with id ${id} is not found`);
    }
  };

  const rename = async (id: number, data: FormFieldsData): Promise<Plan> => {
    const result = await plansApi.put(`/plans/${id}/name`, data);

    publishPlanUpdated(result);
    return result;
  };

  const updateSet = async (id: number, newSetId: number | undefined): Promise<Plan> => {
    const result = await plansApi.put(`/plans/${id}/detailsfieldsset`, {
      detailsFieldsSetId: newSetId,
    });

    publishPlanUpdated(result);
    return result;
  };

  const changePlanType = async (id: number, projectType: ProjectType): Promise<Plan> => {
    const result = await plansApi.put(`/plans/${id}/projecttype`, {
      projectType,
    });

    publishEvent(PLAN_PROJECT_TYPE_UPDATED, {
      id,
      projectType,
    });
    publishPlanUpdated(result);
    return result;
  };

  const deleteFunc = async (id: number): Promise<void> => {
    await plansApi.delete(`/plans/${id}`);

    publishEvent(PLAN_DELETED, {
      id,
    });
  };

  const getPermissions = async (id: number): Promise<Permission[]> => {
    return cacheService.get(`plan-permissions-${id}`, async () => {
      return plansApi.get(`/plans/${id}/permissions`);
    });
  };

  const copy = async (id: number, newPlanName: string): Promise<Plan> => {
    const result = await plansApi.put(`/plans/${id}/copy`, {
      newPlanName,
    });

    publishEvent(PLAN_CREATED, {
      data: result,
    });

    return result;
  };

  const share = async (id: number, emails: string[]): Promise<void> => {
    await plansApi.put(`/plans/${id}/share`, {
      emails,
    });

    eventsPublisher.publish(PLAN_SHARED, {
      id,
    });
  };

  const saveFile = async (fileName: string, content: string) => {
    const a = document.createElement('a');
    const blob = new Blob([content], {
      type: 'text/plain',
    });

    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', () => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };

  const exportToCsv = async (id: number): Promise<void> => {
    const result = await plansApi.get(`/plans/${id}.csv`) as {
      fileName: string,
      fileContent: string
    };

    await saveFile(result.fileName, result.fileContent);
  };

  return {
    getAllTeamPlans,
    create,
    getCreateValidationSchema,
    getById,
    rename,
    changePlanType,
    delete: deleteFunc,
    getPermissions,
    copy,
    share,
    updateSet,
    exportToCsv,
  };
}
