import { ProjectType } from './Types';
import { useBackendApi } from './backendApi';
import { useEventsPublisher } from 'event-bus';

export type DetailsFieldsSet = {
  id: number;
  name: string;
  projectType: ProjectType,
  contractTemplateId: number,
}

export type CreateDetailsSetFieldRequest = {
  name: string;
  projectType: ProjectType,
  includedFieldsIds: number[],
}

export type UpdateDetailsSetFieldRequest = {
  name: string;
}

export type UpdateFieldInSetRequest = {
  includeToSet: boolean;
  parentFieldId?: number;
}

export const DETAILS_FIELDS_SET_CREATED = 'DETAILS_FIELDS_SET_CREATED';

export const DETAILS_FIELDS_SET_UPDATED = 'DETAILS_FIELDS_SET_UPDATED';

export const DETAILS_FIELDS_SET_DELETED = 'DETAILS_FIELDS_SET_DELETED';

export const FIELD_IN_SET_UPDATED = 'FIELD_IN_SET_UPDATED';

export function useDetailsFieldsSetsService() {
  const backendApi = useBackendApi();

  const eventsPublisher = useEventsPublisher();

  const getAllSets = async (): Promise<DetailsFieldsSet[]> => {
    return await backendApi.get('/detailsfields/sets') as DetailsFieldsSet[];
  };

  const createSet = async (request: CreateDetailsSetFieldRequest): Promise<DetailsFieldsSet> => {
    const result = await backendApi.post('/detailsfields/sets', request) as DetailsFieldsSet;

    eventsPublisher.publish(DETAILS_FIELDS_SET_CREATED, {
      projectType: request.projectType,
    });

    return result;
  };

  const updateSet = async (id: number, request: UpdateDetailsSetFieldRequest): Promise<DetailsFieldsSet> => {
    const result = await backendApi.put(`/detailsfields/sets/${id}`, request) as DetailsFieldsSet;

    eventsPublisher.publish(DETAILS_FIELDS_SET_UPDATED, {
      id,
    });

    return result;
  };

  const deleteSet = async (id: number): Promise<void> => {
    await backendApi.delete(`/detailsfields/sets/${id}`);

    eventsPublisher.publish(DETAILS_FIELDS_SET_DELETED, {
      id,
    });
  };

  const excludeFieldFromSet = async (setId: number, fieldId: number): Promise<void> => {
    await backendApi.delete(`/detailsfields/sets/${setId}/fields/${fieldId}`);

    eventsPublisher.publish(FIELD_IN_SET_UPDATED, {
      setId,
      fieldId,
    });
  };

  const includeFieldInSet = async (setId: number, fieldId: number): Promise<void> => {
    await backendApi.post(`/detailsfields/sets/${setId}/fields`, {
      fieldId,
    });

    eventsPublisher.publish(FIELD_IN_SET_UPDATED, {
      setId,
      fieldId,
    });
  };

  const updateFieldInSet = async (setId: number, fieldId: number, request: UpdateFieldInSetRequest): Promise<void> => {
    await backendApi.put(`/detailsfields/sets/${setId}/fields/${fieldId}`, request);

    eventsPublisher.publish(FIELD_IN_SET_UPDATED, {
      setId,
      fieldId,
    });
  }

  return {
    getAllSets,
    createSet,
    updateSet,
    deleteSet,
    excludeFieldFromSet,
    includeFieldInSet,
    updateFieldInSet,
  };
}
