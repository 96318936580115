import { useListContextRequired, useShowContextRequired } from 'ui-builder';
import { VerifyDataEntry } from './types';
import { ProjectDetailsFieldType, RelativeDateOffset, useDateHelper } from 'nekst-api';

export function ParsedValueField() {
  const showContext = useShowContextRequired<VerifyDataEntry>();

  const listContext = useListContextRequired<VerifyDataEntry>();

  const dateHelper = useDateHelper();

  const field = showContext.data!.detailsField;

  const getRelativeDateOffsetValue = (value: RelativeDateOffset) => {
    const parentField = listContext.data.find((item) => item.detailsField?.id === value.dependsOnFieldId);

    return `${value.days} calendar days ${value.when} "${parentField?.name}"`;
  };

  if (showContext.data!.parsedValue) {
    const value = showContext.data!.parsedValue.value;

    if (value) {
      if (typeof value === 'object' && !(value instanceof Date)) {
        return getRelativeDateOffsetValue(value as RelativeDateOffset);
      } else {
        if (field?.fieldType === ProjectDetailsFieldType.DATE && value instanceof Date) {
          return dateHelper.toString(new Date(value));
        } else {
          if (field?.fieldType === ProjectDetailsFieldType.PRICE) {
            return `$${value}`;
          } else {
            return value.toString();
          }
        }
      }
    } else {
      return 'N/A';
    }
  } else {
    return '-';
  }
}
