import React from 'react';
import { useStatusFilterService } from 'features/tasks-list-feature';
import FilterWidgetLayout from '../FilterWidgetLayout';

export default function StatusWidget() {
  const statusFilterService = useStatusFilterService();

  const currentValue = statusFilterService.getFilterValue();

  if (currentValue) {
    const resetValue = () => {
      statusFilterService.setFilterValue({
        showActive: true,
        showCompleted: true,
      });
    };

    if (currentValue.showCompleted && currentValue.showActive) {
      return null;
    } else {
      let widgetText;

      if (!currentValue.showCompleted) {
        widgetText = 'Active Tasks Only';
      } else {
        widgetText = 'Completed Tasks Only';
      }

      return (
        <FilterWidgetLayout
          name=""
          text={widgetText}
          onClick={resetValue}
        />
      );
    }
  } else {
    return null;
  }
}
