import React from 'react';
import {
  Button,
  ButtonColor,
  ButtonSize,
  useMassUpdateServiceRequired
} from 'ui-builder';
import useDeleteForm from './DeleteForm';

export default function MassDeleteButton() {

  const deleteForm = useDeleteForm();

  const massUpdateService = useMassUpdateServiceRequired();

  return (
    <Button
      text="Delete"
      isDisabled={massUpdateService.getCheckedIds().length === 0}
      size={ButtonSize.SMALL}
      isFilled
      isFlat
      color={ButtonColor.RED}
      onClick={async () => {
        deleteForm.openForm();
      }}
    />
  );
}
