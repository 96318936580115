import { Person, PersonProfile, TeamRole } from './Types';
import { Limit, OrderBy, PageResponse } from 'ui-builder';
import { CacheItemManager, useCacheService } from './cacheService';
import { useEventsPublisher, useEventsSubscriber } from 'event-bus';
import { AVATAR_UPDATED } from './avatarService';
import { PROJECT_ASSIGNMENTS_CHANGED } from './projectAssignmentsService';
import { ValidationSchema } from 'validation-schema';
import { cloneDeep } from 'lodash';
import { useBackendApi } from './backendApi';

export enum PersonPermission {
  UPDATE = 'Person:UPDATE',
  DELETE = 'Person:DELETE',
  CREATE_NOTE = 'Person:CREATE_NOTE',
  READ_NOTES = 'Person:READ_NOTES',
  SEND_INVITATION = 'Person:SEND_INVITATION',
  READ_HISTORY = 'Person:READ_HISTORY',
  SEND_OPT_IN = 'Person:SEND_OPT_IN',
  UPDATE_AVATAR = 'Person:UPDATE_AVATAR',
}

export interface UpdatePersonRequest {
  profile: PersonProfile;
  teamRole?: TeamRole;
}

export interface UpdateOwnProfileRequest extends UpdatePersonRequest {
  isDailyMailEnabled: boolean,
}


export interface PeopleFilter {
  nameContains?: string,
  showServiceProvidersOnly?: boolean
  showTeamMembersOnly?: boolean
  serviceProvidersGroupsIds?: number[],
  excludeTeamMembers?: boolean,
}

export function usePeopleFilterHelper() {
  const getUriQuery = (filter?: PeopleFilter, limit?: Limit) => {
    const result = [];

    if (filter?.nameContains) {
      result.push(`query=${encodeURIComponent(filter.nameContains)}`);
    }

    if (filter?.serviceProvidersGroupsIds) {
      result.push(`serviceProvidersGroupsIds=${filter.serviceProvidersGroupsIds.join(',')}`);
    }

    if (filter?.showServiceProvidersOnly) {
      result.push('showServiceProvidersOnly=1');
    }

    if (!filter?.excludeTeamMembers) {
      if (filter?.showTeamMembersOnly) {
        result.push('teamRoles=OWNER,ADMINISTRATOR,MEMBER');
      }
    } else {
      result.push(`teamRoles=${TeamRole.CONTACT},${TeamRole.INVITED_USER}`);
    }


    if (limit?.limit) {
      result.push(`limit=${limit.limit}`);
    }

    if (limit?.offset) {
      result.push(
        `offset=${limit.offset}`,
      );
    }

    return result.join('&');
  };

  return {
    getUriQuery,
  };
}

export interface CreatePersonRequest extends Person {
  projectsAssignment?: {
    projectsIds: number[],
    roleId: number
  };
}

export const CURRENT_USER_PROFILE_UPDATED = 'CURRENT_USER_PROFILE_UPDATED';

export const PERSON_CREATED = 'PERSON_CREATED';

export const PERSON_UPDATED = 'PERSON_UPDATED';

export const PERSON_DELETED = 'PERSON_DELETED';

export const PEOPLE_DELETED_IN_BULK = 'PEOPLE_DELETED_IN_BULK';

export interface PersonDeletedEvent {
  id: number,
}

export interface PersonCreatedEvent {
  id: number,
  data: Person,
}

export interface PersonUpdatedEvent {
  id: number,
  data: Person,
}

export interface PersonDeletedEvent {
  id: number,
}

export function usePeopleService() {

  const contactsApi = useBackendApi();

  const cacheService = useCacheService('contactsService');
  const filterHelper = usePeopleFilterHelper();

  const eventsPublisher = useEventsPublisher();

  function removeCache() {
    cacheService.clear('teamMembers');
  }

  useEventsSubscriber(
    'peopleService',
    {
      [AVATAR_UPDATED]: removeCache,
      [PROJECT_ASSIGNMENTS_CHANGED]: removeCache,
    },
  );

  const search = async (query: string) => {
    const result = await contactsApi.get(
      `/contacts?query=${encodeURIComponent(query)}`,
    ) as PageResponse<Person>;
    return result.data as Person[];
  };

  const getTeamMembers = async (withoutCache= false) => {
    if (withoutCache) {
      cacheService.clear('teamMembers');
    }

    return cacheService.get<Person[]>('teamMembers', async (cacheItem: CacheItemManager) => {
      cacheItem.setExpireInSeconds(180);
      const result =  await contactsApi.get('/teammembers?orderBy=teamRole') as Person[];

      const orderValue: Record<string, number> = {
        [TeamRole.OWNER]: 1,
        [TeamRole.ADMINISTRATOR]: 2,
        [TeamRole.MEMBER]: 3,
      }

      const getOrderValue = (teamRole: TeamRole) => {
        return orderValue[teamRole.toString()] || 4;
      }

      return result.sort((a, b) => {
        const compareResult = getOrderValue(a.teamRole) - getOrderValue(b.teamRole);

        if (compareResult !== 0) {
          return compareResult;
        } else {
          return a.profile.name.firstName.localeCompare(b.profile.name.firstName);
        }
      });
    });
  };
  const getProjectsAssignees = async () => {
    return await contactsApi.get('/projectsassignees') as Person[];
  };

  const getPeople = async (
    filter: PeopleFilter,
    limit: Limit,
    orderBy?: OrderBy,
  ) => {
    const orderByString = orderBy ? `&orderByField=${orderBy.field}&orderDirection=${orderBy.direction}` : '';

    return await contactsApi.get(
      `/contacts?withAssignments=1&${filterHelper.getUriQuery(filter, limit)}${orderByString}`,
    ) as PageResponse<Person>;
  };

  const baseValidationSchema = {
    profile: {
      type: 'object',
      constraints: {
        required: true,
      },
      properties: {
        name: {
          type: 'object',
          constraints: {
            required: true,
          },
          properties: {
            firstName: {
              type: 'string',
              constraints: {
                required: true,
              },
            },
            lastName: {
              type: 'string',
              constraints: {
                required: true,
              },
            },
          },
        },
        primaryEmail: {
          type: 'string',
          constraints: {
            required: true,
            email: true,
          },
        },
        alternateEmail: {
          type: 'string',
          constraints: {
            email: true,
          },
        },
        phoneNumbers: {
          type: 'array',
          constraints: {
            required: true,
          },
          arrayItemSchema: {
            type: {
              type: 'string',
              constraints: {
                required: true,
              },
            },
            number: {
              type: 'string',
              constraints: {
                required: true,
              },
            },
          },
        },
      },
    },
  } as ValidationSchema;

  const getCreateValidationSchema = (teamRole?: Nullable<TeamRole>): ValidationSchema => {
    let profileValidation = cloneDeep(baseValidationSchema);

    if (teamRole === undefined || teamRole === null || teamRole === TeamRole.CONTACT) {
      profileValidation = {
        ...profileValidation,
        profile: {
          ...baseValidationSchema.profile,
          properties: {
            ...baseValidationSchema.profile.properties,
            primaryEmail: {
              ...baseValidationSchema.profile.properties!.primaryEmail,
              constraints: {
                email: true,
              },
            },
            phoneNumbers: {
              ...baseValidationSchema.profile.properties!.phoneNumbers,
              constraints: {},
            },
          },
        },
      };
    }

    return {
      ...profileValidation,
      projectsAssignment: {
        type: 'object',
        constraints: {},
        properties: {
          roleId: {
            type: 'int',
            constraints: {
              required: {
                value: true,
                when: {
                  projectsIds: {
                    notEmpty: true,
                  },
                },
              },
            },
          },
        },
      },
    };
  };

  const getUpdateValidationSchema = (teamRole?: Nullable<TeamRole>): ValidationSchema => {
    let result = cloneDeep(baseValidationSchema);
    if (teamRole === undefined || teamRole === null || teamRole === TeamRole.CONTACT) {
      result = {
        ...result,
        profile: {
          ...baseValidationSchema.profile,
          properties: {
            ...baseValidationSchema.profile.properties,
            primaryEmail: {
              ...baseValidationSchema.profile.properties!.primaryEmail,
              constraints: {
                email: true,
              },
            },
            phoneNumbers: {
              ...baseValidationSchema.profile.properties!.phoneNumbers,
              constraints: {},
            },
          },
        },
      };
    } else if (teamRole === TeamRole.OWNER) {
      result = {
        ...baseValidationSchema,
        profile: {
          ...baseValidationSchema.profile,
          properties: {
            ...baseValidationSchema.profile.properties,
            address: {
              type: 'object',
              constraints: {
                required: true,
              },
              properties: {
                addressLine1: {
                  type: 'string',
                  constraints: {
                    required: true,
                  },
                },
                city: {
                  type: 'string',
                  constraints: {
                    required: true,
                  },
                },
                zip: {
                  type: 'string',
                  constraints: {
                    required: true,
                  },
                },
              },
            },
          },
        },
      };
    }

    return result;
  };

  const create = async (request: CreatePersonRequest) => {
    const result = await contactsApi.post('/contacts', request) as Person;

    if (request.projectsAssignment) {
      eventsPublisher.publish(PROJECT_ASSIGNMENTS_CHANGED, {});
    }

    eventsPublisher.publish(PERSON_CREATED, {
      id: result.id,
      data: result,
    });

    removeCache();

    return result;
  };

  const update = async (id: number, data: UpdatePersonRequest) => {
    const result = await contactsApi.put(`/contacts/${id}`, data) as Person;

    eventsPublisher.publish(PERSON_UPDATED, {
      id: result.id,
      data: result,
    });

    return result;
  };

  const updateOwnProfile = async (data: UpdateOwnProfileRequest) => {
    const result = await contactsApi.put('/me/profile', data) as Person;
    eventsPublisher.publish(CURRENT_USER_PROFILE_UPDATED, {});
    return result;
  };

  const deletePerson = async (id: number) => {
    const result = await contactsApi.delete(`/contacts/${id}`);

    eventsPublisher.publish(PERSON_DELETED, { id } as PersonDeletedEvent);
    removeCache();
    return result;
  };

  const deleteAllTeamMembers = async () => {
    const result = await contactsApi.delete('/teammembers');

    eventsPublisher.publish(PEOPLE_DELETED_IN_BULK, {});

    return result;
  };

  const getById = async (id: number) => {
    const result = await contactsApi.get(`/contacts/${id}?withAssignments=1`) as Person;

    if (result.teamRole === null) {
      result.teamRole = TeamRole.CONTACT;
    }

    return result;
  };

  const getPermissions = async (id: number) => {
    return await contactsApi.get(`/contacts/${id}/permissions`) as PersonPermission[];
  };

  return {
    create,
    search,
    getTeamMembers,
    getPeople,
    getProjectsAssignees,
    getCreateValidationSchema,
    getUpdateValidationSchema,
    getById,
    update,
    updateOwnProfile,
    deletePerson,
    deleteAllTeamMembers,
    getPermissions,
  };
}
