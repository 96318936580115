import React, { ReactElement } from 'react';

import styles from './TaskRowLayout.module.scss';
import Right from '../web/Right';

export interface TaskRowLayoutProps {
  checkbox?: ReactElement,
  isDisabled?: boolean,
  title?: ReactElement | ReactElement[],
  titleAnnotation?: ReactElement | ReactElement[] | boolean,
  leftLine2?: ReactElement | ReactElement[],
  rightLine1?: ReactElement | ReactElement[],
  rightLine2?: ReactElement | ReactElement[],
  isCompleted?: boolean,
  isLoading?: boolean,
  isCollapsed?: boolean,
  deleteButton?: ReactElement,
  className? : string
}

export default function TaskRowLayout(props: TaskRowLayoutProps) {
  const blockClasses = [
    styles.row,
    props.isDisabled ? 'disabled' : '',
    props.isCompleted ? styles.completed : '',
    props.isLoading ? 'loading' : '',
    props.isCollapsed ? styles.collapsed : '',
    props.className || ''
  ];

  return (
    <div
      className={blockClasses.join(' ')}
    >
      <div className={styles.inner}>
        <div className={styles.checkbox}>
          {props.checkbox && (
            <Right>
              {props.checkbox}
            </Right>
          )}
        </div>
        <div className={styles.left}>
          <div
            className={`${styles.line1}`}
          >
            <div className={styles.title}>{props.title}</div>
            {props.titleAnnotation && (
              <div className={styles.titleAnnotation}>{props.titleAnnotation}</div>
            )}
          </div>
          {!props.isCollapsed && (
            <div className={styles.line2}>
              {props.leftLine2}
            </div>
          )}
        </div>

        <div className={styles.right}>
          <div className={styles.line1}>
            {props.rightLine1}
          </div>
          {!props.isCollapsed && (
            <div className={styles.line2}>
              {props.rightLine2}
            </div>
          )}
        </div>
      </div>
      {props.deleteButton}
    </div>
  );
}
