import React, { ReactElement } from 'react';
import ErrorsWrapper from './ErrorsWrapper';

import styles from './RadioButtonLayout.module.scss';
import { RadioButtonLayoutProps } from 'ui-builder';

function Hint(props: { hint: string | ReactElement }) {
  return (
    <div className={styles.hint}>{props.hint}</div>
  );
}

function RadioButtonLayout(props: RadioButtonLayoutProps) {
  return (
    <ErrorsWrapper name={`${props.name}-${props.groupValue}`} errors={props.errors}>
      <>
        <label
          htmlFor={`${props.name}-${props.groupValue}`}
          className={`${styles.formControl} ${props.isDisabled ? styles.disabled : ''}`}
          title={props.title}
        >
          <input
            value={props.groupValue || ''}
            type="radio"
            id={`${props.name}-${props.groupValue}`}
            checked={props.value || false}
            onChange={props.onChangeCallback}
            name={`${props.name}-${props.groupValue}`}
            disabled={props.isDisabled}
          />
          {!props.hideLabel && props.label}
        </label>
        {props.hint && (<Hint hint={props.hint} />)}
      </>
    </ErrorsWrapper>
  );
}

export default RadioButtonLayout;
