import React from 'react';
import Layout from './Layout';

export function ImageWidget(
  props: {
    src: string,
    className?: string,
    alt?: string
  },
) {
  return (
    <Layout {...props} />
  );
}
