import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useResetPasswordService, CheckTokenResult } from 'nekst-api';

import styles from '../Login.module.scss';
import DataLoader from '../../shared/DataLoader';
import TextLink from '../../shared/widgets/TextLink';
import useLoginNavigator, { RESET_PASSWORD_PAGE_LINK } from '../loginNavigator';
import FormTemplate from '../../shared/uibuilder/form/FormTemplate';
import Password from '../../shared/uibuilder/form/input/Password';
import BackToLoginLink from '../Login/BackToLoginLink';
import PageTitle from '../../shared/web/PageTitle';
import { InformationMessage } from 'features/nekst-widgets';

function ForgotPasswordLink() {
  const loginNavigator = useLoginNavigator();
  return (
    <TextLink
      uri={RESET_PASSWORD_PAGE_LINK}
      action={loginNavigator.openResetPasswordPage}
    >
      forgot password
    </TextLink>
  );
}

function ResetPasswordForm(props: {
  token: string,
}) {
  const resetPasswordService = useResetPasswordService();

  const [notificationMessage, setNotificationMessage] = useState<ReactElement | null>(null);

  return (
    <FormTemplate<{ newPassword: string }>
      title="Reset Password"
      submitFormFunc={async (data) => {
        await resetPasswordService.resetPassword(
          props.token,
          data.newPassword,
        );

        setNotificationMessage(
          <>
            Your password has been successfully reset. You can now log in to your account using your
            new password.
            <br />
            <br />
            <BackToLoginLink />
          </>,
        );

        return data;
      }}
      validationSchema={{
        newPassword: {
          type: 'string',
          constraints: {
            required: true,
            minLength: 8,
          },
        },
        _newPassword2: {
          type: 'string',
          constraints: {
            required: true,
            match: 'newPassword',
          },
        },
      }}
      className={styles.form}
      clearAfterSubmit
    >
      {notificationMessage ? <InformationMessage>{notificationMessage}</InformationMessage> : null}
      <Password
        source="newPassword"
        label="Password"
        hint="Password strength: minimum 8 characters, including 1 uppercase, 1 number, and 1 special symbol."
      />
      <Password source="_newPassword2" label="Repeat Password" />
    </FormTemplate>
  );
}

export default function ResetPasswordPage() {

  const resetPasswordService = useResetPasswordService();

  const { token } = useParams<{ token: string }>();

  return (
    <>
      <PageTitle text="Reset Password" />
      <DataLoader<CheckTokenResult>
        getDataFunc={async () => resetPasswordService.checkToken(token!)}
      >
        {(checkResult) => {
          let content;

          switch (checkResult) {
            case CheckTokenResult.OK:
              content = <ResetPasswordForm token={token!} />;
              break;
            case CheckTokenResult.NOT_EXIST:
              content = (
                <div className={styles.message}>
                  The password reset link you have used is invalid. Please make sure that you have
                  entered the correct link or request a new password reset link by clicking on the
                  {' '}
                  <ForgotPasswordLink />
                  {' '}
                  link on our website. If you continue to experience issues, please
                  contact our customer support team for assistance using the following email:
                  {' '}
                  <a href="mailto:support@nekstapp.com">support@nekstapp.com</a>
                </div>
              );
              break;
            case CheckTokenResult.TOKEN_EXPIRED:
              content = (
                <div className={styles.message}>
                  Unfortunately, the password reset link you have used has expired. Please request a
                  new password reset link by clicking on the
                  {' '}
                  <ForgotPasswordLink />
                  {' '}
                  link on our website. We
                  apologize for the inconvenience.
                </div>
              );
              break;
            default:
              content = (
                <div className={styles.message}>Something went wrong.</div>
              );
          }

          return (
            <div className={styles.page}>
              {content}
            </div>
          );
        }}
      </DataLoader>
    </>
  );
}
