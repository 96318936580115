import React, { ReactElement, useState } from 'react';
import { DateTimeField, useShowContext } from 'ui-builder';
import {
  CommentData,
  EventData,
  Permission,
  useEventsPublisher,
  useEventsSubscriber
} from 'nekst-api';
import RelativeRow from '../../web/uitheme/form/RelativeRow';

import styles
  from '../../../projects/view/tasks/CreateUpdate/comments/TaskCommentsList.module.scss';
import IconButton, { IconType } from '../IconButton';
import EditCommentForm, { CommentType, CommentRequestType } from './EditCommentForm';
import { PersonWidget, TextWithLinksField } from 'features/nekst-widgets';

interface DeleteButtonProps {
  deleteItemFunc: (id: number) => Promise<void>;
}

function DeleteButton(props: DeleteButtonProps) {
  const showContext = useShowContext<CommentData>();

  const onClickFunc = async () => {
    showContext.setLoading!(true);
    await props.deleteItemFunc(showContext.data!.id);
    showContext.setLoading!(false);
  };

  return (
    <IconButton
      title="Delete Comment"
      className={styles.iconButton}
      onClick={onClickFunc}
      type={IconType.X}
    />
  );
}

const FORM_OPENED_EVENT_NAME = 'EDIT_COMMENT_FORM_OPENED';

interface Props<
  DataType extends CommentType = CommentType,
  RequestType extends CommentRequestType = CommentRequestType,
> {
  updatePermission: Permission,
  deletePermission: Permission,
  deleteItemFunc: (id: number) => Promise<void>
  updateItemFunc: (id: number, data: RequestType) => Promise<DataType>
  additionalInputs?: ReactElement,
  viewFields?: ListOrSingle<ReactElement>
  dateTimeText?: string
  textSource?: string
}

export function RowLayout(
  props: {
    icon?: ReactElement,
    mainBlockContent: ReactElement,
    dateSource: string,
    children?: ReactElement,
    dateTimeText?: string
  },
) {
  return (
    <RelativeRow weights={[1, 11]} rowClassName={styles.flex}>
      {props.icon && (<div style={{ alignSelf: 'flex-start' }}>
        {props.icon}
      </div>)}
      <div>
        <RelativeRow weights={[9, 3]}>
          <div className={styles.mainBlock}>
            {props.mainBlockContent}
          </div>
          <div className={styles.dateColumn}>
            <div className={styles.date}>
              {props.dateTimeText || <DateTimeField source={props.dateSource} />}
            </div>
          </div>
        </RelativeRow>
        {props.children}
      </div>
    </RelativeRow>
  );
}

export default function CommentRow<
  Data extends CommentType = CommentType,
  Request extends CommentRequestType = CommentRequestType
>(
  props: Props<Data, Request>,
) {
  const [isEdit, setEdit] = useState<boolean>(false);

  const showContext = useShowContext<Data>();

  const eventsPublisher = useEventsPublisher();

  const closeFormIfAnotherOpened = (data: EventData) => {
    if (data.id !== showContext.data!.id) {
      setEdit(false);
    }
  };

  useEventsSubscriber(
    `CommentRow-${showContext.data?.id}`,
    {
      [FORM_OPENED_EVENT_NAME]: closeFormIfAnotherOpened,
    },
  );

  const openEditForm = () => {
    setEdit(true);
    eventsPublisher.publish(FORM_OPENED_EVENT_NAME, {
      id: showContext.data?.id,
    });
  };

  const comment = showContext.data!;

  const profile = comment._author;

  const fullName = `${profile.name.firstName} ${profile.name.lastName}`;

  const hasPermission = (permission: Permission) => {
    return comment.permissions.includes(permission);
  };

  return (
    <RowLayout
      icon={(
        <PersonWidget
          profile={profile}
        />
      )}
      mainBlockContent={(
        <div className={styles.rowLine}>
          <span className={styles.name}>
            {fullName}
          </span>
          {hasPermission(props.updatePermission) && (
            <IconButton
              title="Update"
              className={styles.iconButton}
              onClick={openEditForm}
              type={IconType.PENCIL}
            />
          )}
          {hasPermission(props.deletePermission) && (
            <DeleteButton deleteItemFunc={props.deleteItemFunc} />
          )}
        </div>
      )}
      dateSource="createdAt"
      dateTimeText={props.dateTimeText}
    >
      <>
        {!isEdit && (
          <div className={styles.content}>
            {props.viewFields || (
              <TextWithLinksField source={props.textSource || 'text'} />
            )}
          </div>
        )}
        {isEdit && (
          <EditCommentForm
            afterSubmitFunc={() => setEdit(false)}
            updateItemFunc={props.updateItemFunc}
            closeFormFunc={() => setEdit(false)}
          >
            {props.additionalInputs}
          </EditCommentForm>
        )}
      </>
    </RowLayout>
  );
}
