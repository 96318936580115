import React, { ReactNode } from 'react';
import {
  DETAILS_FIELDS_SET_CREATED, DETAILS_FIELDS_SET_DELETED, DETAILS_FIELDS_SET_UPDATED,
  DetailsFieldsSet,
  useDetailsFieldsSetsService
} from 'nekst-api';
import { useDataLoaderNew } from 'data-loader';
import { LoadingAnimation } from 'ui-builder';

type DetailsFieldsSetsContextType = {
  sets: DetailsFieldsSet[],
  loading: boolean,
}

const DetailsFieldsSetsReactContext = React.createContext<DetailsFieldsSetsContextType | undefined>(undefined);

export function useDetailsFieldsSetsContextRequired() {
  const context = React.useContext(DetailsFieldsSetsReactContext);

  if (!context) {
    throw new Error('useDetailsFieldsSetsContextRequired must be used within a DetailsFieldsSetsContext');
  }

  return context;
}

export default function DetailsFieldsSetsContext(
  props: { children: ReactNode }
) {
  const setsService = useDetailsFieldsSetsService();

  const {
    data: sets,
    loading,
  } = useDataLoaderNew(setsService.getAllSets, {
    events: [
      DETAILS_FIELDS_SET_CREATED,
      DETAILS_FIELDS_SET_UPDATED,
      DETAILS_FIELDS_SET_DELETED,
    ]
  });

  if (sets) {
    return (
      <DetailsFieldsSetsReactContext.Provider value={{
        sets,
        loading,
      }}>
        {props.children}
      </DetailsFieldsSetsReactContext.Provider>
    )
  } else {
    return (
      <LoadingAnimation />
    );
  }
}
