import React from 'react';
import { get } from 'lodash';
import FilePreview from './FilePreview';
import { useShowContext } from 'ui-builder';
import { FileDto } from 'nekst-api';

interface DocumentFieldProps {
  source: string,
  // eslint-disable-next-line react/no-unused-prop-types
  label: string,
  fileDataSource: string
}

export default function DocumentField(
  props: DocumentFieldProps,
) {
  const showContext = useShowContext();

  const value = get(showContext.data!, props.source);

  const fileData = get(showContext.data!, props.fileDataSource) as FileDto | undefined;

  if (value && fileData) {
    return (
      <FilePreview
        data={{
          name: fileData.name,
          type: fileData.mimeType,
        }}
      />
    );
  } else {
    return <span>-</span>;
  }
}
