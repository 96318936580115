import { ProjectDetailsFieldSettingsIdType, ShortProfile } from './Types';
import { useBackendApi } from './backendApi';
import { useDateHelper } from './Helper';

export type PersonParseResult = {
  internalId: number;
  firstName: string;
  lastName: string;
  addressLine1?: string
  addressLine2?: string
  city?: string
  state?: string
  zip?: string
  phoneNumber?: string
  companyName?: string
  website?: string
  email?: string
  roleId?: number
  context: number[]
  processed: boolean,
  foundMatches: ShortProfile[]
}

export type PromptParseResult = {
  id: string
  type: 'S' | 'N' | 'B' | 'D'
  prompt: string
  value: string
  context: number[]
}

export type PdfParseResult = {
  people: PersonParseResult[],
  tasks: PromptParseResult[],
  fileText: string
}

export type ParsePrompt = {
  type: 'S' | 'N' | 'B' | 'D'
  prompt: string
  id: string
}

export type PdfParseRequest = {
  file: string
  prompts: ParsePrompt[],
  aiModel?: string,
}

export interface RelativeDateOffset {
  days: number,
  when: string,
  dependsOnFieldId: number,
}

export interface ParseValue<T = Date | number | string | RelativeDateOffset, TypeName extends string = 'string'> {
  value: T | null;
  context: number[];
  type: TypeName
}

export type StringParseValue = ParseValue<string, 'string'>;

export type NumberParseValue = ParseValue<number, 'number'>;

export type DateParseValue = ParseValue<Date, 'date'>;

export type RelativeDateOffsetValue = ParseValue<RelativeDateOffset, 'relative_date_offset'>;

export interface ProjectContractParseResult {
  name: StringParseValue,
  startDate: DateParseValue,
  endDate: DateParseValue,
  detailsFields: Record<ProjectDetailsFieldSettingsIdType, StringParseValue | NumberParseValue | DateParseValue | RelativeDateOffsetValue>,
  fileText: string,
  people: PersonParseResult[]
}

export function useAiLauncherService() {
  const backendService = useBackendApi();

  const dateHelper = useDateHelper();

  const parseDocument = async (request: PdfParseRequest) => {
    return await backendService.post(`/projects/launchdata`, {
      ...request,
    }) as PdfParseResult;
  };

  const getContractParseResult = async (projectId: number) => {
    const result = await backendService.get(`/projects/${projectId}/contractparseresult`) as ProjectContractParseResult;


    return {
      ...result,
      detailsFields: Object.fromEntries(
        Object.entries(result.detailsFields).map(([key, value]) => {

          if (value.type === 'date') {
            return [key, {
              ...value,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              value: value.value ? dateHelper.parseDateString(value.value) : undefined,
            }]
          } else {
            return [key, value];
          }

        })
      ),
    };
  };

  const removeContact = async (projectId: number, internalId: number) => {
    return await backendService.delete(`/projects/${projectId}/contractparseresult/people/${internalId}`);
  };

  const updateName = async (projectId: number, name: string) => {
    return await backendService.put(`/projects/${projectId}/contractparseresult/name`, {
      value: name,
    });
  };

  const updateStringFieldValue = async (projectId: number, fieldId: number, value?: string) => {
    return await backendService.put(`/projects/${projectId}/contractparseresult/fields/${fieldId}/string`, {
      value,
    });
  };

  const updateDateFieldValue = async (projectId: number, fieldId: number, value?: Date) => {
    return await backendService.put(`/projects/${projectId}/contractparseresult/fields/${fieldId}/date`, {
      value,
    });
  };

  const updateNumberFieldValue = async (projectId: number, fieldId: number, value?: number) => {
    return await backendService.put(`/projects/${projectId}/contractparseresult/fields/${fieldId}/number`, {
      value,
    });
  };

  const updateRelativeDateOffsetValue = async (projectId: number, fieldId: number, value?: RelativeDateOffset) => {
    return await backendService.put(`/projects/${projectId}/contractparseresult/fields/${fieldId}/relativedateoffset`, {
      value,
    });
  }

  const applyDefinedData = async (projectId: number) => {
    return await backendService.post(`/projects/${projectId}/contractparseresult/apply`, {});
  }

  return {
    parseDocument,
    removeContact,
    getContractParseResult,
    updateName,
    updateStringFieldValue,
    updateDateFieldValue,
    updateNumberFieldValue,
    updateRelativeDateOffsetValue,
    applyDefinedData,
  };
}
