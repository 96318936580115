import { FormFieldsData, FormTemplate, TextInput } from 'ui-builder';
import { usePlansService } from 'nekst-api';
import { FormText, Separator } from 'features/nekst-widgets';
import React from 'react';

export interface SharePlanFormProps {
  id: number;
  afterSubmitFunc?: (_data: FormFieldsData | void) => Promise<void>,
}

export function SharePlanForm(props: SharePlanFormProps) {
  const plansService = usePlansService();

  return (
    <FormTemplate<FormFieldsData, void>
      title="Share"
      submitFormFunc={async (data) => {
        const emails = data
          .emails
          .split(',')
          .map((item: string) => item.trim())
          .filter((item: string) => item.length > 0);

        await plansService.share(props.id, emails);
      }}
      afterSubmitFunc={props.afterSubmitFunc}
      validationSchema={{
        emails: {
          type: 'string',
          constraints: {
            required: true,
            emailList: true,
          },
        },
      }}
    >
      <FormText>
        Enter the email address of a fellow Nekst User to share this action plan.
        Comma separate each email address.
      </FormText>
      <Separator />
      <TextInput source="emails" label="Email address(es)" />
    </FormTemplate>
  );
}
