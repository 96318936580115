import { BaseInputProps, RadioButtonOption, RadioButtonsGroup } from 'ui-builder';
import { TeamRole } from 'nekst-api';
import React from 'react';

interface TeamRoleRadioProps extends BaseInputProps {
  showContactOption?: boolean;
  disabledOptions?: OptionalRecord<TeamRole, string>
}

export function TeamRoleRadio(props: TeamRoleRadioProps) {
  const showContactOption = props.showContactOption !== undefined ? props.showContactOption : true;

  const options: RadioButtonOption[] = [
    {
      label: 'Team Administrator',
      value: TeamRole.ADMINISTRATOR,
      description: 'Can create transactions, assign tasks, add/edit team members and action plans',
      disabled: !!props.disabledOptions?.[TeamRole.ADMINISTRATOR] || false,
      title: props.disabledOptions?.[TeamRole.ADMINISTRATOR]
    },
    {
      label: 'Team Member',
      value: TeamRole.MEMBER,
      description: 'Can be assigned tasks, see transaction details. Cannot create new transactions or manage action plans',
      disabled: !!props.disabledOptions?.[TeamRole.MEMBER] || false ,
      title: props.disabledOptions?.[TeamRole.MEMBER]
    },
  ];

  if (showContactOption) {
    options.unshift({
      label: 'Contact',
      value: TeamRole.CONTACT,
    });
  }

  return (
    <RadioButtonsGroup
      options={options}
      {...props}
    />
  );
}
