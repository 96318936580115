import React, { useState } from 'react';
import useProjectsApi from '../../projects/projectsApi';
import LoadingAnimation from '../../shared/LoadingAnimation';
import useDataLoader from '../../shared/uibuilder/form/dataLoader';
import styles from './LimitWidget.module.scss';
import { formatBytes } from '../../files/fileSizeHelper';
import { useEventsSubscriber } from 'event-bus';
import { BILLING_PLAN_UPDATED } from 'nekst-api';

type Limit = {
  used: number,
  limit: number,
}

function useDocumentStorageLimitService() {
  const nekstApi = useProjectsApi();

  const getLimit = async () => {
    return await nekstApi.get('/documentstorage/limit') as Limit;
  };

  return {
    getLimit,
  };
}

export default function LimitWidget() {

  const service = useDocumentStorageLimitService();

  const [data, setData] = useState<Limit>();

  const [version, setVersion] = useState(0);

  useDataLoader(
    service.getLimit,
    setData,
    version,
  );

  useEventsSubscriber('LimitWidget', {
    [BILLING_PLAN_UPDATED]: () => setVersion((prev) => prev + 1),
  });

  if (data) {

    const percent = (data.used / data.limit) * 100;

    const used = Math.max(percent, 3);

    let grade = '';

    if (used >= 75 && used < 95) {
      grade = styles.warning;
    } else if (used >= 95) {
      grade = styles.danger;
    }

    return (
      <div className={styles.widget}>
        <div className={styles.graph}>
          <div className={`${styles.inner} ${grade}`} style={{ width: `${used}%` }}>
            &nbsp;
          </div>
        </div>
        <div className={styles.text}>
          {formatBytes(data.used)}
          {' / '}
          {formatBytes(data.limit)}
          {' used'}
        </div>
      </div>
    );
  } else {
    return <LoadingAnimation />;
  }
}
