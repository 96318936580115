import { useShowContext } from 'ui-builder';
import {
  DueDateConfig,
  DueDateWhen,
  PlanTask,
  PlanTaskParentTaskRelation,
  Project,
  usePlanTasksService
} from 'nekst-api';

export interface ProjectPlanTask extends PlanTask {
  calculatedDate: Nullable<Date>;
}

export function isInstanceOfProjectPlanTask(object: any): boolean {
  return 'calculatedDate' in object;
}

export interface ProjectPlanTaskService {
  getTasksByPlanId: (planId: number) => Promise<ProjectPlanTask[]>;
}

function getNoDueDateStubDate(): Date {
  return new Date('2099-01-01');
}

export default function useProjectPlanTaskService(): ProjectPlanTaskService {
  const planTasksService = usePlanTasksService();

  const { data: project } = useShowContext<Project>();

  const minusDays = (date: Date, days: number): Date | null => {
    if (date) {
      const obj = new Date(date);
      obj.setDate(obj.getDate() - days);
      return obj;
    } else {
      return null;
    }
  };

  const plusDays = (date: Date, days: number): Date | null => {
    if (date) {
      const obj = new Date(date);
      obj.setDate(obj.getDate() + days);
      return obj;
    } else {
      return null;
    }
  };

  const tasksCompareFunc = (task1: ProjectPlanTask, task2: ProjectPlanTask) => {
    const date1 = task1.calculatedDate ? new Date(task1.calculatedDate) : getNoDueDateStubDate();
    const date2 = task2.calculatedDate ? new Date(task2.calculatedDate) : getNoDueDateStubDate();

    if (date1.getTime() !== date2.getTime()) {
      return date1.getTime() - date2.getTime();
    } else if (task1.order !== task2.order) {
      return task1.order - task2.order;
    } else {
      let compareResult = 0;

      if (task1.parentTaskRelation?.parentTaskId && task1.parentTaskRelation.parentTaskId === task2.parentTaskRelation?.parentTaskId) {
        compareResult = task1.parentTaskRelation.days - task2.parentTaskRelation.days;
      }

      if (compareResult !== 0) {
        return compareResult;
      } else {
        return task1.name.localeCompare(task2.name);
      }
    }
  };

  const getTasksByPlanId = async (planId: number) => {
    const tasks = await planTasksService.getPlanTasks(planId);

    const getTaskById = (taskId: number): PlanTask | null => {
      return tasks.find((task) => task.id === taskId) || null;
    };

    const calculateDate = (
      dueDateConfig?: DueDateConfig,
      parentTaskRelation?: PlanTaskParentTaskRelation,
    ) => {
      let result;

      if (dueDateConfig) {
        switch (dueDateConfig.when) {
          case DueDateWhen.BEFORE_START_DATE:
            result = minusDays(project!.startDate, dueDateConfig.days);
            break;
          case DueDateWhen.AFTER_START_DATE:
            result = plusDays(project!.startDate, dueDateConfig.days);
            break;
          case DueDateWhen.BEFORE_END_DATE:
            result = minusDays(project!.endDate, dueDateConfig.days);
            break;
          case DueDateWhen.AFTER_END_DATE:
            result = plusDays(project!.endDate, dueDateConfig.days);
            break;
          default:
            result = null;
        }
      } else if (parentTaskRelation) {
        const parentTask = getTaskById(parentTaskRelation.parentTaskId);
        if (parentTask) {
          let parentTaskDate;

          if (isInstanceOfProjectPlanTask(parentTask)) {
            parentTaskDate = (parentTask as ProjectPlanTask).calculatedDate;
          } else {
            // eslint-disable-next-line
            parentTaskDate = calculateDate(parentTask!.dueDateConfig, parentTask!.parentTaskRelation);
          }


          if (parentTaskDate) {
            result = plusDays(parentTaskDate, parentTaskRelation.days);
          } else {
            result = null;
          }
        } else {
          result = null;
        }
      } else {
        result = null;
      }

      return result;
    };

    return tasks
      .map((task) => {
        const mapResult = {
          ...task,
        } as ProjectPlanTask;
        mapResult.calculatedDate = calculateDate(mapResult.dueDateConfig);

        return mapResult;
      })
      .sort(tasksCompareFunc);
  };

  return {
    getTasksByPlanId,
  };
}
