import React, { ReactElement, ReactNode } from 'react';

import styles from './MainBlock.module.scss';

export enum ColumnPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT'
}

export interface MainBlockProps {
  title: string | ReactElement,
  titleLine2?: string | ReactElement
  headerRight?: ReactElement | string
  columnContent?: ReactElement | ReactElement[],
  columnPosition?: ColumnPosition,
  children: ReactNode,
}

function getColumnPosition(position?: ColumnPosition): ColumnPosition {
  return position || ColumnPosition.RIGHT;
}

function MainBlock(props: MainBlockProps) {
  const blockStyles = [
    styles.main,
    styles.block,
    props.columnContent
      ? getColumnPosition(props.columnPosition)
        .toLowerCase()
      : styles.noColumn,
  ];
  return (
    <div className={blockStyles.join(' ')}>
      {(props.headerRight || props.title) && (
        <div className={styles.header}>
          {props.headerRight && (
            <div className={styles.headerRight}>
              {props.headerRight}
            </div>
          )}
          <div className={styles.titleRow}>
            <h1>{props.title}</h1>
            {props.titleLine2 && (
              <div className={styles.line2}>
                {props.titleLine2}
              </div>
            )}
          </div>
        </div>
      )}
      <div className={styles.body}>
        {props.columnContent && (
          <div
            className={`${styles.column} ${getColumnPosition(props.columnPosition)
              .toLowerCase()}`}
          >
            {props.columnContent}
          </div>
        )}
        <div className={styles.content}>
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default MainBlock;
