import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Permission, ProjectType, TeamPermission } from 'nekst-api';
import { AccessChecker, useAuthorizationChecker } from 'authorization-scope';
import Separator from '../shared/web/uitheme/form/Separator';
import useSettingsNavigator from './settingsNavigator';

import styles from './SettingsMenu.module.scss';
import AdminPortalAccessChecker from '../team/AdminPortalAccessChecker';
import LimitWidget from './documentstorage/LimitWidget';
import {
  TasksGroupsToggleWrapper,
  TeamMembersManagementToggleWrapper
} from 'feature-toggle';

function MenuLink(
  props: {
    link: string,
    label: string
    permissionToCheck?: TeamPermission
  },
) {
  const { isGranted } = useAuthorizationChecker();

  const navigate = useNavigate();

  const classes = [
    styles.link,
    window.location.pathname === props.link ? styles.active : '',
  ];

  if (!props.permissionToCheck || isGranted(props.permissionToCheck)) {
    return (
      <a
        className={classes.join(' ')}
        href={props.link}
        onClick={(e) => {
          navigate(props.link);
          e.preventDefault();
        }}
      >
        {props.label}
      </a>
    );
  } else {
    return null;
  }
}

function MenuLinkGroup(
  props: {
    name: string,
    permissionToCheck?: Permission,
    children: ReactNode,
  },
) {
  const { isGranted } = useAuthorizationChecker();

  if (!props.permissionToCheck || isGranted(props.permissionToCheck)) {
    return (
      <div className={styles.group}>
        <div className={styles.groupTitle}>{props.name}</div>
        <div className={styles.groupLinks}>
          {props.children}
        </div>
      </div>
    );
  } else {
    return null;
  }
}

function ProjectDetailsMenuGroup() {
  const settingsNavigator = useSettingsNavigator();

  const { isGranted } = useAuthorizationChecker();

  if (isGranted(TeamPermission.MANAGE_PROJECT_DETAILS_FIELDS)) {
    return (
      <MenuLinkGroup name="Transaction Detail Boxes">
        <MenuLink
          link={settingsNavigator.getProjectDetailsLink(ProjectType.LISTING)}
          label="Listings"
        />
        <MenuLink
          link={settingsNavigator.getProjectDetailsLink(ProjectType.PENDING_BUYER)}
          label="Pending Buyers"
        />
        <MenuLink
          link={settingsNavigator.getProjectDetailsLink(ProjectType.PENDING_SELLER)}
          label="Pending Sellers"
        />
        <MenuLink
          link={settingsNavigator.getProjectDetailsLink(ProjectType.PENDING_DUAL)}
          label="Pending Dual"
        />
        <MenuLink
          link={settingsNavigator.getProjectDetailsLink(ProjectType.ACTIVE_BUYER)}
          label="Active Buyers"
        />
        <MenuLink
          link={settingsNavigator.getProjectDetailsLink(ProjectType.OTHER)}
          label="Other"
        />
      </MenuLinkGroup>
    );
  } else {
    return null;
  }
}

function ClientPortalMenuGroup() {
  const settingsNavigator = useSettingsNavigator();

  const { isGranted } = useAuthorizationChecker();

  if (isGranted(TeamPermission.MANAGE_CLIENT_PORTAL_CONFIGURATION)) {
    return (
      <MenuLinkGroup name="Client Portal Setup">
        <>
          <MenuLink
            link={settingsNavigator.getDefaultPortalConfigurationLink(ProjectType.LISTING)}
            label="Listings"
          />
          <MenuLink
            link={settingsNavigator.getDefaultPortalConfigurationLink(ProjectType.PENDING_BUYER)}
            label="Pending Buyers"
          />
          <MenuLink
            link={settingsNavigator.getDefaultPortalConfigurationLink(ProjectType.PENDING_SELLER)}
            label="Pending Sellers"
          />
          <MenuLink
            link={settingsNavigator.getDefaultPortalConfigurationLink(ProjectType.PENDING_DUAL)}
            label="Pending Dual"
          />
          <MenuLink
            link={settingsNavigator.getDefaultPortalConfigurationLink(ProjectType.ACTIVE_BUYER)}
            label="Active Buyers"
          />
          <MenuLink
            link={settingsNavigator.getDefaultPortalConfigurationLink(ProjectType.OTHER)}
            label="Other"
          />
        </>
        <MenuLink
          link={settingsNavigator.getClientPortalSettingsLink()}
          label="Client Portal Domain"
        />
      </MenuLinkGroup>
    );
  } else {
    return null;
  }
}

export default function SettingsMenu() {

  const settingsNavigator = useSettingsNavigator();

  const { isGranted } = useAuthorizationChecker();

  // hide settings on /settings/members/:id page
  if (window.location.pathname.includes('/settings/members/')) {
    return null;
  } else {
    return (
      <>
        <h3>Settings</h3>
        <Separator />
        <h4>Account</h4>
        <MenuLink link={settingsNavigator.getProfileLink()} label="Profile" />
        <MenuLink link={settingsNavigator.getUpdatePasswordPageLink()} label="Update Password" />
        <MenuLink
          link={settingsNavigator.getBillingPageLink()}
          label="Billing"
          permissionToCheck={TeamPermission.MANAGE_BILLING}
        />
        <MenuLink
          link={settingsNavigator.getNotificationsSettingsLink()}
          label="Notifications"
        />

        {isGranted(TeamPermission.ACCESS_SETUP) && (
          <>
            <Separator />
            <h4>Setup</h4>
            <MenuLink
              link={settingsNavigator.getCustomRolesLink()}
              label="Custom Roles"
              permissionToCheck={TeamPermission.MANAGE_ROLES}
            />
            <TeamMembersManagementToggleWrapper>
              <AccessChecker permissionToCheck={TeamPermission.ACCESS_TEAM_MEMBERS_MANAGEMENT}
                             show403Error={false}>
                <MenuLink link={settingsNavigator.getMembersManagementLink()}
                          label="Team Members Management" />
              </AccessChecker>
            </TeamMembersManagementToggleWrapper>
            <MenuLink
              link={settingsNavigator.getServiceProvidersLink()}
              label="Service Providers"
              permissionToCheck={TeamPermission.MANAGE_SERVICE_PROVIDERS}
            />
            <TasksGroupsToggleWrapper>
              <MenuLink
                link={settingsNavigator.getTasksGroupsManagementLink()}
                label="Tasks Groups"
              />
            </TasksGroupsToggleWrapper>
            <ProjectDetailsMenuGroup />
            <ClientPortalMenuGroup />
            <h5>Document Storage</h5>
            <LimitWidget />
            <MenuLink
              link={settingsNavigator.getDocumentLabelsLink()}
              label="Document Labels"
              permissionToCheck={TeamPermission.MANAGE_DOCUMENT_LABELS}
            />
            <MenuLink
              link={settingsNavigator.getDocumentsTemplatesLink()}
              label="Document Templates"
              permissionToCheck={TeamPermission.MANAGE_DOCUMENT_TEMPLATES}
            />
          </>
        )}
        <AdminPortalAccessChecker>
          <Separator />
          <h4>Portal Administration</h4>
          <MenuLink
            link={settingsNavigator.getImpersonateUserLink()}
            label="Impersonate Users"
          />
        </AdminPortalAccessChecker>
      </>
    );
  }
}
