import React from 'react';
import { IsVisibleFunc } from 'ui-builder';
import Layout from './Layout';

export interface SeparatorProps {
  marginSize?: 'small' | 'medium' | 'big';
  isBold?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  isVisible?: boolean | IsVisibleFunc;
}

export function Separator(props: SeparatorProps) {
  return (
    <Layout {...props} />
  );
}
