import { EmailTask, PlanTask, ProjectType, SmsTask } from 'nekst-api';
import React, { useMemo } from 'react';
import { Tag } from '../uibuilder/form/input/SmartTagsWysiwyg';
import LoadingAnimation from '../LoadingAnimation';
import useDataLoader from 'data-loader';

type SmartTagsContextValue = {
  availableTags: Tag[],
  loaded: boolean,
  setAvailableTags: (value: Tag[]) => void,
};

const SmartTagsReactContext = React.createContext<SmartTagsContextValue>({
  availableTags: [],
  loaded: false,
  setAvailableTags: () => {
    // ignored
  },
});

export default function SmartTagsContext(
  props: {
    getTagsFunc: (projectType: ProjectType) => Promise<Tag[]>,
    projectType?: ProjectType,
    children: React.ReactElement,
  },
) {
  const [availableTags, setAvailableTags] = React.useState<Tag[]>([]);

  const { loading } = useDataLoader(
    async () => (props.projectType ? props.getTagsFunc(props.projectType) : []),
    setAvailableTags,
    props.projectType,
  );

  const contextValue = React.useMemo(
    () => ({
      availableTags,
      loaded: !loading,
      setAvailableTags,
    }),
    [availableTags.length],
  );

  if (!loading) {
    return (
      <SmartTagsReactContext.Provider
        value={contextValue}
      >
        {props.children}
      </SmartTagsReactContext.Provider>
    );
  } else {
    return <LoadingAnimation />;
  }
}

export function useSmartTagsContext() {
  return React.useContext(SmartTagsReactContext);
}

export function useSmartTagsHelper() {

  const smartTagsContext = useSmartTagsContext();


  const markUnexistingTags = (text: string) => {
    const el = document.createElement('html');
    el.innerHTML = text;

    const mentions = el.getElementsByClassName('mention');

    for (let i = 0; i < mentions.length; i += 1) {
      const mention = mentions.item(i);

      if (mention) {
        const tagId = mention.getAttribute('data-id');

        let exists = false;

        if (tagId) {
          exists = !!smartTagsContext.availableTags.find((item) => item.id === tagId);
        } else {
          exists = false;
        }

        if (!exists) {
          mention!.replaceWith('');
        }
      }

    }

    return el
      .getElementsByTagName('body')
      .item(0)!.innerHTML;
  };

  return {
    markUnexistingTags,
  };
}

export function useTasksSmartTagsHelper<
  Type extends SmsTask | EmailTask | PlanTask
>(task: Type | undefined) {
  const smartTagsHelper = useSmartTagsHelper();

  const formData = useMemo<Type | undefined>(() => {
    if (task && task?.description) {
      const result = {
        ...task,
      } as Type;
      result.description = smartTagsHelper.markUnexistingTags(task.description);
      return result;
    } else {
      return task;
    }
  }, []);

  return {
    formData,
  };
}
