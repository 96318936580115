import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useCrispEventsPublisher from '../../../thirdpartyscripts/crisp/crispEventsPublisher';
import useFacebookEventsPublisher from '../../../thirdpartyscripts/fbpixel/fbEventsPublisher';
import {
  UpdateProfileForm as BaseUpdateProfileForm
} from 'features/tasks-forms';
import { useAuthenticationContextRequired } from 'nekst-api';

function useRedirectAfterAccountCompletion() {
  const navigate = useNavigate();

  const authenticationContext = useAuthenticationContextRequired();

  const redirectAfterComplete = useMemo(() => {
    return !authenticationContext.user.hasActiveAccount;
  }, []);

  useEffect(() => {
    if (redirectAfterComplete && authenticationContext.user.hasActiveAccount) {
      navigate('/tasks');
    }
  }, [authenticationContext.user.hasActiveAccount]);

  return null;
}

export default function UpdateProfileForm() {
  useRedirectAfterAccountCompletion();

  const crispEventsPublisher = useCrispEventsPublisher();
  const fbEventsPublisher = useFacebookEventsPublisher();

  return (
    <BaseUpdateProfileForm
      onAccountCompleted={async () => {
        await crispEventsPublisher.onSignUp();
        fbEventsPublisher.onSignUp();
      }}
    />
  );
}
