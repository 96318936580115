import React from 'react';
import { MaskedInputProps } from './types';
import { useInputHelper } from 'ui-builder';

import Layout from './Layout';

export function MaskedInput(props: MaskedInputProps) {
  const inputHelper = useInputHelper(props);

  return (
    <Layout
      {...inputHelper.getBaseInputLayoutProps()}
    />
  );
}
