import React from 'react';
import { TextWithLinksFieldLayoutProps } from '../types';
import DOMPurify from 'dompurify';
import { ProfileMention } from '../../ProfileMention';

export default function TextWithLinksFieldLayoutWeb(props: TextWithLinksFieldLayoutProps) {
  let result = props.text
  // escape HTML
  result = DOMPurify.sanitize(result);
  result = result.replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');

  result = result.replace(/(https?:\/\/[0-9A-Za-z\\.\\/\\?]+)/g, '<a href="$1" target="_blank">$1</a>');
  result = result.replace(/(^|[^/])(www\.[\S]+(\b|$))/g, '$1<a href="http://$2" target="_blank">$2</a>');

  // result = result.replace(/@([\w_]+)\((\d+)\)/g, `<span class="${styles.tag}">@$1</span>`);

  const tagRegex = /(@[\w_]+?\(\d+\))/g;
  const tagPartRegex = /^(@[\w_]+)\((\d+)\)$/;

  const tagRegexParts = result.split(tagRegex);


  const resultParts = tagRegexParts.map((tagPart) => {
    const match = tagPart.match(tagPartRegex);
    if (match) {
      return <ProfileMention key={match[2]} id={parseInt(match[2], 10)} text={match[1]} />;
    } else {
      return tagPart;
    }
  });

  return (
    <span className={props.className}>
      {resultParts.map((part, index) => {
        if (typeof part === 'string') {
          return <span key={index} dangerouslySetInnerHTML={{ __html: part }} />;
        } else {
          return part;
        }
      })}
    </span>
  );
}
