export enum WidgetStyle {
  TODAY = 'today',
  PAST_DUE = 'pastDue',
  TOMORROW = 'tomorrow'
}

export interface DueDateFieldLayoutProps {
  widgetStyle?: WidgetStyle,
  value?: string
}
