import React, { useEffect, useState } from 'react';
import { TextLink, useShowContextRequired } from 'ui-builder';
import { Plan, PLAN_PROJECT_TYPE_UPDATED, useEventsSubscriber } from 'nekst-api';
import { usePopupFormManager, WarningMessage } from 'features/nekst-widgets';
import { ChangePlanTypeForm } from './Actions/ChangePlanTypeForm';

export function PlanProjectTypeSetter() {

  const [hidden, setHidden] = useState(false);

  const showContext = useShowContextRequired<Plan>();

  const plan = showContext.data;

  const popupManager = usePopupFormManager();

  const mustHaveProjectType = plan && !plan.projectType && !plan.isGlobal;

  useEventsSubscriber(
    'ProjectTypeSetter',
    {
      [PLAN_PROJECT_TYPE_UPDATED]: () => setHidden(true),
    },
  );

  function openPopup() {
    if (mustHaveProjectType) {
      popupManager.openForm((
        <ChangePlanTypeForm
          plan={plan}
          title="Set Plan Type"
          message={
            'The ability to manage the plan is not available until a transaction type '
            + 'is assigned to the plan'
          }
        />
      ));
    }
  }

  useEffect(() => {
    openPopup();
    setHidden(false);
  }, [plan?.id]);

  if (mustHaveProjectType && !hidden) {
    return (
      <WarningMessage>
        The ability to manage the plan is not available until
        {' '}
        a transaction type is assigned to the plan.
        {' '}
        <TextLink
          onClick={() => {
            openPopup();
          }}
        >
          Click here
        </TextLink>
        {' '}
        to set the type.
      </WarningMessage>
    );
  } else {
    return null;
  }
}
