import React, {
  ReactElement, useCallback,
  useEffect,
  useMemo,
} from 'react';

export interface EventData {
  [key: string]: any;
}

export type EventCallback<Type extends EventData = EventData> =
  ((eventData: Type) => void)
  | ((eventData: Type) => Promise<void>);

interface EventContextData {
  on: <Data extends EventData>(
    eventName: string,
    callback: EventCallback<Data>,
    callbackId: string
  ) => void,
  dispatch: <Data extends EventData>(
    eventName: string,
    eventData: Data,
  ) => void,
  remove: (eventName: string, callbackId: string) => void,
}

const EventReactContext = React.createContext<EventContextData>({
  on: () => {
    // ignored
  },
  dispatch: () => {
    // ignored
  },
  remove: () => {
    // ignored
  },
});

export function useEventReactContext() {
  return React.useContext<EventContextData>(EventReactContext);
}

type CallbackRegistry = Record<string, Record<string, EventCallback>>;

const globalCallbacksRegistry: CallbackRegistry = {};

function getCallbacks(eventName: string) {
  return Object.values(globalCallbacksRegistry[eventName] || {});
}

export function EventBusContext(props: {
  children: ReactElement | ReactElement[]
}) {
  function onFunc<Data extends EventData>(
    eventName: string,
    callback: EventCallback<Data>,
    callbackId: string,
  ) {
    if (!globalCallbacksRegistry[eventName]) {
      globalCallbacksRegistry[eventName] = {};
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    globalCallbacksRegistry[eventName][callbackId] = callback;
  }

  const on = useCallback(onFunc, []);

  function dispatchFunc<Data extends EventData>(
    eventName: string,
    eventData: Data,
  ) {
    getCallbacks(eventName).forEach(callback => callback(eventData));
  }

  const dispatch = useCallback(dispatchFunc, []);

  function removeFunc(eventName: string, callbackId: string) {
    if (globalCallbacksRegistry[eventName]) {
      delete globalCallbacksRegistry[eventName][callbackId];
    }
  }

  const remove = useCallback(removeFunc, []);

  const contextData = useMemo<EventContextData>(() => ({
    on,
    dispatch,
    remove,
  }), []);

  return (
    <EventReactContext.Provider value={contextData}>
      {props.children}
    </EventReactContext.Provider>
  );
}

export function useEventsSubscriber(
  id: string,
  subcribes: Record<string, EventCallback>,
  dependencies: any[] = [],
  onDependenciesChanged: () => void = () => {
    // ignored
  },
) {
  const eventBusContext = useEventReactContext();

  const uniqueId = useMemo(() => Math.random().toString(), []);

  useEffect(() => {
    const finalId = `${id}-${uniqueId}`;

    if (onDependenciesChanged) {
      onDependenciesChanged();
    }

    Object.entries(subcribes)
      .forEach(([eventName, callback]) => {
        eventBusContext.on(eventName, callback, finalId);
      });

    return () => {
      Object.keys(subcribes)
        .forEach((eventName) => {
          eventBusContext.remove(eventName, finalId);
        });
    };
  }, dependencies);

  return null;
}

export function useEventsPublisher() {
  const eventBusContext = useEventReactContext();

  function publish<Event extends EventData>(eventName: string, eventData: Event) {
    eventBusContext.dispatch(eventName, eventData);
  }

  return {
    publish,
  };
}
