import React, { ReactElement, ReactNode } from 'react';

import styles from './InnerBlock.module.scss';

type Element = ReactElement | boolean | undefined | null;

export interface InnerBlockProps {
  title: string | ReactElement,
  titleLine2?: string,
  headerRight?: ListOrSingle<Element>,
  children: ListOrSingle<Element>,
  showHeaderBorder?: boolean
  className?: string
  dataKey?: string
  headerSize?: 1 | 2 | 3 | 4 | 5 | 6
}

function BlockTitle(props: {
  headerSize: 1 | 2 | 3 | 4 | 5 | 6,
  children: ReactNode,
}) {
  const Header = `h${props.headerSize}` as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  return(
    <Header>{props.children}</Header>
  );
}

export default function InnerBlock(props: InnerBlockProps) {
  return (
    <div className={`${styles.block} ${props.className}`} data-key={props.dataKey}>
      <div className={`${styles.header} ${props.showHeaderBorder ? styles.bordered : ''}`}>
        {props.headerRight && (
          <div className={styles.buttons}>
            {props.headerRight}
          </div>
        )}
        <BlockTitle headerSize={props.headerSize || 2}>
          {props.title}
        </BlockTitle>
        {props.titleLine2 && (
          <div className={styles.line2}>
            {props.titleLine2}
          </div>
        )}
      </div>
      <div className={styles.content}>
        {props.children}
      </div>
    </div>
  );
}
