import React from 'react';
import { useShowContext } from 'ui-builder';

import styles from './LinkField.module.scss';
import BaseFieldProps from './BaseFieldProps';

export interface LinkFieldProps extends BaseFieldProps {
  onClick: () => void,
  className?: string
  uri: string | undefined
}

function LinkField(props: LinkFieldProps) {
  const context = useShowContext();

  let value;
  if (props.source && context.data) {
    value = context.data[props.source];
  } else if (props.value) {
    value = props.value;
  }

  if (value) {
    return (
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <a
        href={props.uri}
        onClick={(event) => {
          event.preventDefault();
          props.onClick();
        }}
        className={`${styles.link} ${props.className || ''}`}>
        {value}
      </a>
    );
  } else {
    return null;
  }
}

export default LinkField;
