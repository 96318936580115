import { DownloadFileDto, useBackendApi } from 'nekst-api';

export default function useDownloadLinkService() {
  const nekstApi = useBackendApi();

  const getFileDownloadLink = async (code: string) => {
    return await nekstApi.get(`/files/download/${code}`) as DownloadFileDto;
  };

  return {
    getFileDownloadLink,
  };
}
