import React from 'react';
import { TeamMember } from 'nekst-api';
import { AfterSubmitFunc, Button, ButtonSize } from 'ui-builder';
import {
  CreateTeamMemberForm as BaseCreateTeamMemberForm,
  useSeatUsageLoader
} from 'features/tasks-forms';
import { usePopupFormManager } from 'features/nekst-widgets';
import { UpdateSeatsForm } from './seat/TeamMembersSeatsWidget';

function OpenUpdateSeatsFormButton() {
  const popupFormManager = usePopupFormManager();

  const seatUsageLoader = useSeatUsageLoader();

  const seatUsage = seatUsageLoader.seatUsage;

  return (
    <Button
      text="here"
      onClick={() => {
        if (seatUsage) {
          popupFormManager.openForm(<UpdateSeatsForm seatUsageInfo={seatUsage} />);
        }
      }}
      size={ButtonSize.SMALL}
      isFilled
      isFlat
      isDisabled={!seatUsage}
    />
  );
}

export default function CreateTeamMemberForm(
  props: {
    afterSubmitFunc?: AfterSubmitFunc<TeamMember>,
  }
) {
  return (
    <BaseCreateTeamMemberForm
      afterSubmitFunc={props.afterSubmitFunc}
      updateSeatButton={<OpenUpdateSeatsFormButton />}
    />
  );
}
