import React from 'react';
import ReactDOM from 'react-dom';
import BorderedBox from '../../../../shared/web/layout/BorderedBox';
import FormTemplate from '../../../../shared/uibuilder/form/FormTemplate';
import Checkbox from '../../../../shared/uibuilder/form/input/Checkbox';
import Separator from '../../../../shared/web/uitheme/form/Separator';
import styles from './TypeFilter.module.scss';
import useAuthorizationChecker from '../../../../shared/authorization/authorizationChecker';
import { FormFieldsData } from '../../../../shared/uibuilder/form/FormContext';
import { TeamPermission } from 'nekst-api';
import { PlanTasksTypeFilterValue, usePlanTasksTypeFilterService } from 'features/tasks-forms';
import { TasksGroupsToggleWrapper } from 'feature-toggle';
import { TasksGroupFilterForm } from './TasksGroupFilter';
import { Header5 } from 'ui-builder';

export default function TypeFilter(props: {
  forceShowKeyDatesOnly?: boolean,
}) {
  const container = document.getElementById('filter1');

  const typeFilterService = usePlanTasksTypeFilterService();

  const { isGranted } = useAuthorizationChecker();

  const forceShowKeyDatesOnly = props.forceShowKeyDatesOnly;

  if (container) {
    return ReactDOM.createPortal(
      <BorderedBox title="Display" className={styles.filter}>
        <FormTemplate
          submitFormFunc={async (data: FormFieldsData) => {
            typeFilterService.setFilterValue(data as PlanTasksTypeFilterValue);
          }}
          initialData={typeFilterService.getFilterValue()}
          submitOnEachChange
          buttons={null}
        >
          <Checkbox source="showStandardTasks" label="Standard Tasks" />
          <Checkbox source="showEmailTasks" label="Email Tasks" />
          {
            isGranted(TeamPermission.USE_SMS_TASKS_FEATURE)
              ? (
                <Checkbox source="showSmsTasks" label="SMS Tasks" />
              )
              : null
          }
          <Separator />
          <Checkbox
            source="showKeyDatesOnly"
            label="Key Dates Only"
            value={forceShowKeyDatesOnly ? true : undefined}
            isDisabled={forceShowKeyDatesOnly}
          />
          <Checkbox source="showPrivateOnly" label="Private Tasks Only" />
        </FormTemplate>
        <TasksGroupsToggleWrapper>
          <Separator marginSize="big" />
          <Header5>Groups</Header5>
          <TasksGroupFilterForm />
        </TasksGroupsToggleWrapper>
      </BorderedBox>,
      container,
    );
  } else {
    return null;
  }
}
