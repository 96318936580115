import { useObjectDatesMapper } from './Helper';
import { Permission, ShortProfile } from './Types';
import { useBackendApi } from './backendApi';
import { useEventsPublisher } from 'event-bus';

export interface ProjectNote {
  id: number,
  authorId: number,
  text: string,
  createdAt: Date,
  updatedAt: Date,
  permissions: Permission[],
  isAddedToClientPortal: boolean,
  _author: ShortProfile,
}

export interface ProjectNoteRequest {
  text: string;
  isAddedToClientPortal: boolean;
}

export interface UpdateNoteRequest extends ProjectNoteRequest {
}

export interface CreateNoteRequest extends ProjectNoteRequest {
}

export const PROJECT_NOTES_UPDATED_EVENT = 'PROJECT_NOTES_UPDATED';

export function useProjectNotesService() {
  const projectsApi = useBackendApi();
  const eventsPublisher = useEventsPublisher();

  const onNotesUpdated = (noteId?: number,) => {
    eventsPublisher.publish(PROJECT_NOTES_UPDATED_EVENT, {
      id: noteId,
    });
  };

  const {
    mapSingle,
    mapList,
  } = useObjectDatesMapper<ProjectNote>([], [
    'createdAt',
    'updatedAt',
  ]);

  const getProjectNotes = async (projectId: number) => {
    const result = await projectsApi.get(`/projects/${projectId}/notes`);

    return mapList(result);
  };

  const createNote = async (projectId: number, data: CreateNoteRequest) => {
    const result = await projectsApi.post(`/projects/${projectId}/notes`, {
      text: data.text,
      isAddedToClientPortal: data.isAddedToClientPortal,
    });

    onNotesUpdated(result.id);
    return mapSingle(result);
  };

  const updateNote = async (id: number, data: UpdateNoteRequest) => {
    const result = await projectsApi.put(`/projectnotes/${id}`, {
      text: data.text,
      isAddedToClientPortal: data.isAddedToClientPortal,
    });

    onNotesUpdated(result.id);
    return mapSingle(result);
  };

  const deleteNote = async (id: number) => {
    await projectsApi.delete(`/projectnotes/${id}`);

    onNotesUpdated();
  };

  return {
    createNote,
    getProjectNotes,
    updateNote,
    deleteNote,
  };
}

