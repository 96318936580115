import React from 'react';
import Checkbox from '../../../../../../shared/uibuilder/form/input/Checkbox';
import FormRows from '../../../../../../shared/uibuilder/form/FormRows';

export type StatusFilterValue = {
  showActive: boolean,
  showCompleted: boolean
}

export function StatusFilterForm() {
  return (
    <>
      <h4>TASK STATUS</h4>
      <FormRows>
        <Checkbox source="statusFilter.showActive" label="Show Active Tasks" />
        <Checkbox source="statusFilter.showCompleted" label="Show Completed Tasks" />
      </FormRows>
    </>
  );
}
