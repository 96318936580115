import { ProjectStatus } from 'nekst-api';
import React from 'react';
import { BaseInputProps, Dropdown } from 'ui-builder';

export interface ProjectStatusDropdownProps extends BaseInputProps {

}

export function ProjectStatusDropdown(props: ProjectStatusDropdownProps) {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Dropdown
      {...props}
      options={
        [
          {
            value: ProjectStatus.ACTIVE,
            label: 'Active',
          },
          {
            value: ProjectStatus.CLOSED,
            label: 'Closed',
          },
          {
            value: ProjectStatus.ARCHIVED,
            label: 'Archived',
          },
        ]
      }
    />
  );
}
