import React, {
  ReactElement,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ServiceProviderGroup, useServiceProvidersGroupsService } from 'nekst-api';
import { Option } from 'ui-builder';
import useDataLoader from 'data-loader';

export type ServiceProvidersContextData = {
  providers: ServiceProviderGroup[],
  options: Option<number>[],
  getGroupById: (id: number) => ServiceProviderGroup | null,
  loading: boolean,
}

const ServiceProvidersReactContext = React.createContext<ServiceProvidersContextData>({
  providers: [],
  options: [],
  getGroupById: () => null,
  loading: true,
});

export default function ServiceProvidersContext(
  props: {
    children: ReactElement,
  },
) {
  const groupsService = useServiceProvidersGroupsService();

  const [providersGroups, setProvidersGroups] = useState<ServiceProviderGroup[]>();

  const { loading } = useDataLoader(
    groupsService.getWithAssignmentsOnly,
    setProvidersGroups,
  );

  const contextData = useMemo<ServiceProvidersContextData>(() => {
    const getGroupById = (id: number) => {
      return providersGroups?.find((provider) => provider.id === id) || null;
    };

    const options = providersGroups?.map((group) => ({
      label: group.name,
      value: group.id,
    })) || [];

    return {
      providers: providersGroups || [],
      getGroupById,
      options,
      loading,
    };
  }, [providersGroups?.length, loading]);

  return (
    <ServiceProvidersReactContext.Provider value={contextData}>
      {props.children}
    </ServiceProvidersReactContext.Provider>
  );
}

export function useServiceProvidersContext() {
  return useContext(ServiceProvidersReactContext);
}
