import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useParams } from 'react-router-dom';
import {
  AfterSubmitFunc,
  DateTimeField, Dropdown, FormTemplate,
  List,
  ListDataRefresher,
  MassUpdate,
  useFormContext,
  useListContext,
  useMassUpdateServiceRequired,
  useShowContext,
} from 'ui-builder';
import { ProjectBreadcrumb } from '../view/ProjectContext';
import { Breadcrumb } from '../../shared/web/layout/Breadcrumbs';
import MainBlock from '../../shared/web/layout/MainBlock';
import TextField from '../../shared/uibuilder/show/field/TextField';
import ProjectPagesButtons from '../view/ProjectPagesButtons';
import InnerBlock from '../../shared/web/layout/InnerBlock';
import useProjectDocumentsService, {
  DOCUMENTS_UPDATED,
  ProjectDocument,
} from './projectDocumentsService';
import GridLayout from '../../shared/uibuilder/list/GridLayout';
import { ApplyTemplateButton } from './ApplyTemplateForm';
import Cell from '../../shared/uibuilder/list/Cell';
import DeleteDocumentButton from './DeleteDocumentButton';
import DownloadDocumentButton from './DownloadDocumentButton';
import DeleteFileButton from './DeleteFileButton';
import CreateDocumentButton, { CreateDocumentButtonPortal } from './CreateDocumentButton';
import UploadDocumentButton from './UploadDocumentButton';
import AddLabelsButton, { AddLabelsButtonPortal } from './AddLabelsButton';
import RenameFileButton from './RenameFileButton';
import Right from '../../shared/web/Right';
import OverflowHidden from '../../shared/widgets/OverflowHidden';
import SelectForMassUpdateCheckbox
  from '../../shared/widgets/MassUpdate/SelectForMassUpdateCheckbox';
import ProjectDocumentsMassUpdateControls from './massupdate/ProjectDocumentsMassUpdateControls';
import FileSizeField from './FileSizeField';
import ShareDocumentButton from './ShareDocumentButton';
import PreviewDocumentField from './PreviewDocumentField';
import TextLink from '../../shared/widgets/TextLink';

import styles from './ProjectDocumentsPage.module.scss';
import { DocumentLabel } from '../../settings/documentstorage/labels/documentLabelsService';
import { usePopupFormManager } from 'features/nekst-widgets';
import { MassUpdateCheckbox } from 'features/tasks-list-feature';
import { ProjectTypeField } from 'features/tasks-forms';
import { ProjectPermission, useAuthorizationChecker } from 'authorization-scope';

type AssignLabelFormData = {
  labelId: number,
  _fileName: string,
}

function FileName() {
  const formContext = useFormContext<AssignLabelFormData>();

  return (
    <div>
      <b>Document:</b>
      {' '}
      {formContext.data?._fileName}
    </div>
  );
}

function AssignLabelForm(
  props: {
    document: ProjectDocument,
    availableLabels: DocumentLabel[],
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {
  const projectDocumentsService = useProjectDocumentsService();
  return (
    <FormTemplate<AssignLabelFormData>
      submitFormFunc={async (data) => {
        await projectDocumentsService.assignLabelToDocument(props.document.id, data.labelId);
        return data;
      }}
      afterSubmitFunc={props.afterSubmitFunc}
      title="Add Label"
      initialData={{
        _fileName: props.document._file!.name,
      } as AssignLabelFormData}
    >
      <Dropdown
        label="Label"
        options={props.availableLabels.map((item) => ({
          label: item.name,
          value: item.id,
        }))}
        source="labelId"
      />
      <FileName />
    </FormTemplate>
  );
}

function AddLabelButton() {
  const popupManager = usePopupFormManager();

  const listContext = useListContext<ProjectDocument>();

  const showContext = useShowContext<ProjectDocument>();

  const document = showContext.data!;

  const labels = listContext
    .data!
    .filter((item) => !item._file && item._label)
    .map((item) => item._label!);

  return (
    <TextLink
      uri="#"
      action={() => {
        popupManager.openForm(<AssignLabelForm document={document} availableLabels={labels} />);
      }}
      className={styles.link}
    >
      + Add Label
    </TextLink>
  );
}

function DocumentLabelField(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: {
    // eslint-disable-next-line react/no-unused-prop-types
    label: string,
  },
) {
  const listContext = useListContext<ProjectDocument>();

  const showContext = useShowContext<ProjectDocument>();

  const currentItem = showContext.data!;

  const hasUnassignedLabels = () => {
    return listContext.data!.filter((item) => !item._file && item._label).length > 0;
  };

  if (currentItem._label) {
    return (<span>{currentItem._label.name}</span>);
  } else if (hasUnassignedLabels()) {
    return (<AddLabelButton />);
  } else {
    return (<span>-</span>);
  }
}

function ListFields() {
  const massUpdateContext = useMassUpdateServiceRequired();

  const weights = massUpdateContext.isEnabled() ? [1, 4, 5, 2, 3] : [4, 5, 2, 3];

  const { isGranted } = useAuthorizationChecker();

  const hasManagePermission = isGranted(ProjectPermission.CREATE_DOCUMENTS);

  if (hasManagePermission) {
    weights.push(2);
  }

  return (
    <GridLayout noDataMessage="No Documents created." weights={weights}>
      {massUpdateContext.isEnabled() && <SelectForMassUpdateCheckbox />}
      <DocumentLabelField label="Label" />
      <Cell label="Document">
        <PreviewDocumentField />
        <RenameFileButton />
        <UploadDocumentButton />
        <DownloadDocumentButton />
        <DeleteFileButton />
      </Cell>
      <FileSizeField source="_file.size" label="Size" />
      <DateTimeField source="uploadedAt" label="Date Uploaded" />
      {hasManagePermission && (<Cell label="Actions">
        <ShareDocumentButton />
        <DeleteDocumentButton />
      </Cell>)}
    </GridLayout>
  );
}

function ProjectDocumentList(props: {
  projectId: number,
}) {
  const documentService = useProjectDocumentsService();

  return (
    <MassUpdate>
      <List<ProjectDocument>
        getDataFunc={() => documentService.getProjectDocuments(props.projectId)}
      >
        <OverflowHidden>
          <Right>
            <MassUpdateCheckbox />
          </Right>
        </OverflowHidden>
        <ProjectDocumentsMassUpdateControls />
        <ListFields />
        <ListDataRefresher eventName={DOCUMENTS_UPDATED} />
        <CreateDocumentButton projectId={props.projectId} />
        <AddLabelsButton projectId={props.projectId} />
      </List>
    </MassUpdate>
  );
}

export default function ProjectDocumentsPage() {

  const params = useParams<{ id: string }>();

  const projectId = params.id ? parseInt(params.id, 10) : 0;

  return (
    <>
      <ProjectBreadcrumb>
        <Breadcrumb text="Documents" />
      </ProjectBreadcrumb>
      <MainBlock
        title={<TextField source="name" />}
        titleLine2={<ProjectTypeField />}
        headerRight={(
          <ProjectPagesButtons />
        )}
      >
        <InnerBlock
          title="Documents"
          headerRight={(
            <>
              <AddLabelsButtonPortal />
              <CreateDocumentButtonPortal />
              <ApplyTemplateButton projectId={projectId} />
            </>
          )}
        >
          <ProjectDocumentList projectId={projectId} />
        </InnerBlock>
      </MainBlock>
    </>
  );
}
