import React, { ReactElement, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useBillingService,
  IntervalType,
  SubscriptionPlan,
  SubscriptionPlanId,
} from 'nekst-api';
import {
  useFormContext,
} from 'ui-builder';
import useDataLoader from 'data-loader';

import textSwitcherStyles from './TextSwitcher.module.scss';
import blockSwitcherStyles from './BlockSwitcher.module.scss';
import RelativeRow from '../shared/web/uitheme/form/RelativeRow';
import LoadingAnimation from '../shared/LoadingAnimation';
import useSubscriptionPlanFeaturesHelper from '../settings/billing/subscriptionPlanFeaturesHelper';

import styles from './SubscriptionPriceInput.module.scss';

function TextSwitcherItem(props: {
  label: string,
  onClick: () => void,
  isActive: boolean,
}) {
  const className = `${textSwitcherStyles.item} ${props.isActive ? textSwitcherStyles.active : ''}`;
  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <span onClick={props.onClick} className={className}>
      {props.label}
    </span>
  );
}

function TextSwitcherWidget<Type>(props: {
  value: Type,
  options: { value: Type, label: string }[],
  setValue: (newValue: Type) => void,
}) {
  return (
    <div className={textSwitcherStyles.widget}>
      {props.options.map((option) => (
        <TextSwitcherItem
          key={`option-${option.value}`}
          label={option.label}
          onClick={() => props.setValue(option.value)}
          isActive={props.value === option.value}
        />
      ))}
    </div>
  );
}

function IntervalSwitcher(
  props: { interval: IntervalType, setInterval: (value: IntervalType) => void },
) {
  return (
    <TextSwitcherWidget
      value={props.interval}
      setValue={props.setInterval}
      options={[
        {
          label: 'Pay Monthly',
          value: IntervalType.MONTH,
        },
        {
          label: 'Pay Yearly',
          value: IntervalType.YEAR,
        },
      ]}
    />
  );
}

function BlockSwitcherItem(
  props: {
    element: ReactElement,
    onClick: () => void,
    isActive: boolean,
  },
) {
  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      onClick={props.onClick}
      className={`${blockSwitcherStyles.item} ${props.isActive && blockSwitcherStyles.active}`}
    >
      {props.element}
    </div>
  );
}

function BlockSwitcher<ValueType>(
  props: {
    value: ValueType,
    options: { value: ValueType, element: ReactElement }[],
    setValue: (newValue: ValueType) => void,
  },
) {
  return (
    <div className={blockSwitcherStyles.widget}>
      {props.options.map((option) => {
        return (
          <BlockSwitcherItem
            element={option.element}
            onClick={() => props.setValue(option.value)}
            isActive={option.value === props.value}
          />
        );
      })}
    </div>
  );
}

function SubscriptionPlanInformation(
  props: {
    planId: SubscriptionPlanId,
    subscriptionPlans: SubscriptionPlan[],
    priceInterval: IntervalType,
  },
) {

  const plan = props.subscriptionPlans.find((item) => item.id === props.planId);

  const subscriptionPlanFeaturesHelper = useSubscriptionPlanFeaturesHelper();

  const features = subscriptionPlanFeaturesHelper.getFeatures(props.planId);

  if (plan) {
    let priceBlock;
    if (props.planId === SubscriptionPlanId.AGENT_LITE) {
      priceBlock = (
        <div className={styles.priceBlock}>
          <span className={styles.free}>Free</span>
        </div>
      );
    } else {

      const price = plan.prices
        .find((item) => item.recurringInterval === props.priceInterval)!;

      priceBlock = (
        <div className={styles.priceBlock}>
          <span className={styles.price}>{price.amount}</span>
          <span
            className={styles.interval}
          >
            {price.recurringInterval === IntervalType.YEAR ? ' / year' : ' / month'}
          </span>
        </div>
      );
    }
    return (
      <RelativeRow weights={[4, 8]}>
        <div>
          <div className={styles.planName}>{plan.name}</div>
          {priceBlock}
        </div>
        <div>
          {features.map((feature) => (<div>{feature}</div>))}
        </div>
      </RelativeRow>
    );
  } else {
    return null;
  }
}

function SubscriptionPlanSwitcher(
  props: {
    value: SubscriptionPlanId,
    setValue: (newValue: SubscriptionPlanId) => void,
    priceInterval: IntervalType,
    subscriptionPlans: SubscriptionPlan[],
  },
) {
  const subscriptionPlans = props.subscriptionPlans;

  return (
    <BlockSwitcher<SubscriptionPlanId>
      value={props.value}
      options={[
        {
          value: SubscriptionPlanId.AGENT_LITE,
          element: (
            <SubscriptionPlanInformation
              planId={SubscriptionPlanId.AGENT_LITE}
              subscriptionPlans={subscriptionPlans}
              priceInterval={props.priceInterval}
            />
          ),
        },
        {
          value: SubscriptionPlanId.AGENT_PRO,
          element: (
            <SubscriptionPlanInformation
              planId={SubscriptionPlanId.AGENT_PRO}
              subscriptionPlans={subscriptionPlans}
              priceInterval={props.priceInterval}
            />
          ),
        },
        {
          value: SubscriptionPlanId.TEAM_PRO,
          element: (
            <SubscriptionPlanInformation
              planId={SubscriptionPlanId.TEAM_PRO}
              subscriptionPlans={subscriptionPlans}
              priceInterval={props.priceInterval}
            />
          ),
        },
      ]}
      setValue={props.setValue}
    />
  );
}

function useInitialValuesHelper() {
  const [searchParams] = useSearchParams();

  const queryPlan = searchParams.get('planId');
  const queryInterval = searchParams.get('interval');

  const getInitialPlan = () => {
    if (queryPlan && (Object.values(SubscriptionPlanId) as string[]).includes(queryPlan)) {
      return queryPlan as SubscriptionPlanId;
    } else {
      return SubscriptionPlanId.AGENT_PRO;
    }
  };

  const getInitialInterval = () => {
    if (queryInterval && (Object.values(IntervalType) as string[]).includes(queryInterval)) {
      return queryInterval as IntervalType;
    } else {
      return IntervalType.MONTH;
    }
  };

  return {
    getInitialPlan,
    getInitialInterval,
  };
}

export default function SubscriptionPriceInput() {

  const initialValueHelper = useInitialValuesHelper();

  const [interval, setInterval] = useState<IntervalType>(initialValueHelper.getInitialInterval());
  const [subscriptionPlan, setSubscriptionPlan] = useState<SubscriptionPlanId>(
    initialValueHelper.getInitialPlan(),
  );

  const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionPlan[]>();
  const billingService = useBillingService();

  useDataLoader(
    billingService.getSubscriptionPlans,
    setSubscriptionPlans,
  );

  const formContext = useFormContext();

  useEffect(() => {
    if (subscriptionPlans) {
      const plan = subscriptionPlans.find((item) => item.id === subscriptionPlan);

      let priceId;
      if (plan) {
        if (plan.id === SubscriptionPlanId.AGENT_LITE) {
          priceId = plan.prices[0].id;
        } else {
          priceId = plan.prices.find(
            (item) => item.recurringInterval === interval,
          )?.id;
        }
      }

      formContext.onChangeCallback!({
        priceId,
      });
    }
  }, [
    subscriptionPlans,
    subscriptionPlan,
    interval,
  ]);

  if (subscriptionPlans) {
    return (
      <>
        <IntervalSwitcher interval={interval} setInterval={setInterval} />
        <SubscriptionPlanSwitcher
          value={subscriptionPlan}
          setValue={setSubscriptionPlan}
          priceInterval={interval}
          subscriptionPlans={subscriptionPlans}
        />
      </>
    );
  } else {
    return (
      <LoadingAnimation />
    );
  }

}
