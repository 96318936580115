import React from 'react';
import { Breadcrumb } from '../../shared/web/layout/Breadcrumbs';
import { SettingsBreadcrumbs } from '../settingsModule';
import InnerBlock from '../../shared/web/layout/InnerBlock';
import ClientPortalConfigurationForm from './Domain/ClientPortalConfigurationForm';
import Row6x6x from '../../shared/web/uitheme/form/Row6x6x';

export default function ClientPortalDomainSettingsPage() {
  return (
    <>
      <SettingsBreadcrumbs>
        <Breadcrumb text="Client Portal" />
      </SettingsBreadcrumbs>
      <InnerBlock title="Client Portal">
        <Row6x6x className="no-clear">
          <ClientPortalConfigurationForm />
        </Row6x6x>
      </InnerBlock>
    </>
  );
}
