import React, { ReactElement, ReactNode } from 'react';
import { ViewStyle } from 'react-native';
import { useNewUiTheme } from '../newUiTheme';

export interface RelativeRowProps {
  weights: number[],
  children: ReactNode
  rowClassName?: string,
  cellClassName?: string,
  isVisible?: ((data: Record<string, any>) => boolean) | boolean
  dataKey?: string
  rowStyle?: ViewStyle,
  useFlex?: boolean
  centerV?: boolean
}

export interface CellProps {
  children: ReactElement | ReactElement[] | string | boolean;
  className?: string;
  centered?: boolean;
}

export interface RowsHelper {
  splitByChunks: (_input: ReactElement[], _perChunk: number) => ReactElement[][]
}

export default function useRowsHelper(): RowsHelper {

  const splitByChunks = (input: ReactElement[], perChunk: number): ReactElement[][] => {

    return input.reduce((prevValue: ReactElement[][], item, index) => {
      const chunkIndex = Math.floor(index / perChunk);

      const resultArray = prevValue;

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
  };

  return {
    splitByChunks,
  };
}

export interface RelativeRowGroupLayoutProps {
  className?: string,
  style?: ViewStyle,
  children: ListOrSingle<ReactElement>
  withFlex?: boolean
  centerV?: boolean
}

export interface RelativeRowCellLayoutProps {
  isCentered?: boolean,
  className?: string,
  style?: ViewStyle,
  children: ReactElement,
  weight: number
}

export function  RelativeRow(props: RelativeRowProps) {
  const rowsHelper = useRowsHelper();
  const uiTheme = useNewUiTheme();

  const components = React.Children.toArray(props.children) as ReactElement[];

  const chunks = rowsHelper.splitByChunks(components, props.weights.length);

  return (
    <>
      {chunks.map((chunk, chunkIndex: number) => {
        return (
          <uiTheme.common.RelativeRowGroupLayout
            // eslint-disable-next-line react/no-array-index-key
            key={`chunk-${chunkIndex}`}
            className={props.rowClassName}
            data-key={props.dataKey}
            style={props.rowStyle}
            withFlex={props.useFlex}
            centerV={props.centerV}
          >
            {chunk.map((element, index) => {
              const isCentered = element.props.centered;

              return (
                <uiTheme.common.RelativeRowCellLayout
                  weight={props.weights[index]}
                  isCentered={isCentered}
                  className={props.cellClassName}
                  key={`chunk-item-${chunkIndex}-${index}`}
                >
                  {element}
                </uiTheme.common.RelativeRowCellLayout>
              );
            })}
          </uiTheme.common.RelativeRowGroupLayout>
        );
      })}
    </>
  );
}

export function Row6x6x(props: Omit<RelativeRowProps, 'weights'>) {
  return (
    <RelativeRow {...props} weights={[6, 6]}>
      {props.children}
    </RelativeRow>
  );
}

export function Row4x4x4x(props: Omit<RelativeRowProps, 'weights'>) {
  return (
    <RelativeRow {...props} weights={[4, 4, 4]}>
      {props.children}
    </RelativeRow>
  );
}
