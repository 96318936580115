import React from 'react';
import { useNewUiTheme } from '../newUiTheme';
import { ViewStyle } from 'react-native';

export interface LoadingAnimationLayoutProps {
  isBig?: boolean,
  style?: ViewStyle,
}

export function LoadingAnimation(props: {
  isBig?: boolean,
  style?: ViewStyle,
}) {
  const uiTheme = useNewUiTheme();
  return (
    <uiTheme.common.LoadingAnimationLayout isBig={props.isBig} style={props.style} />
  );
}
