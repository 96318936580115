import React from 'react';
import { AuthorizationScope } from './AuthorizationScope';
import { useAuthorizationChecker } from './authorizationChecker';
import {
  ACCESS_TOKEN_UPDATED,
  BILLING_PLAN_UPDATED,
  CURRENT_USER_PROFILE_UPDATED,
  TeamPermission,
  useAuthenticationContext,
  useEventsSubscriber,
  useTeamService,
} from 'nekst-api';
import { ReactNode, useState } from 'react';
import { isReactNative, LoadingAnimation, SimpleBlock, Text } from 'ui-builder';

export interface TeamAuthorizationScopeProps {
  children: ReactNode;
  loadingView?: ReactNode;
  mobile_routeName?: string;
  openBillingPageFunc: () => void
}

function AccessFunctionalityFilter(
  props: {
    children: ListOrSingle<ReactNode>,
    mobile_routeName?: string,
    openBillingPageFunc: () => void
  },
) {
  const location = window.location?.pathname;

  const {
    isGranted,
    isLoaded,
  } = useAuthorizationChecker();

  if (isLoaded) {
    if (
      isGranted(TeamPermission.ACCESS_FUNCTIONALITY)
      || location?.startsWith('/settings')
      || props.mobile_routeName?.startsWith('Settings')
    ) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{props.children}</>;
    } else {
      props.openBillingPageFunc();

      return props.children;
    }
  } else {
    return (
      <SimpleBlock style={{ flex: 1, backgroundColor: 'white' }}>
        <LoadingAnimation />
      </SimpleBlock>
    );
  }
}

export function TeamAuthorizationScope(
  props: TeamAuthorizationScopeProps
) {
  const teamService = useTeamService();

  const [version, setVersion] = useState(0);

  const authenticationContext = useAuthenticationContext();

  useEventsSubscriber(
    'TeamAuthorizationScope',
    {
      [BILLING_PLAN_UPDATED]: () => setVersion((prev) => prev + 1),
      [CURRENT_USER_PROFILE_UPDATED]: () => setVersion((prev) => prev + 1),
      [ACCESS_TOKEN_UPDATED]: () => setVersion((prev) => prev + 1),
    },
  );

  if (authenticationContext?.user) {
    return (
      <AuthorizationScope<undefined>
        getPermissionsFunc={teamService.getPermissions}
        scopeName="Team"
        id={undefined}
        dependencies={[version]}
      >
        <AccessFunctionalityFilter openBillingPageFunc={props.openBillingPageFunc}>
          {props.children}
        </AccessFunctionalityFilter>
      </AuthorizationScope>
    );
  } else {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{props.children}</>;
  }
}
