import { FormBlock, FormTemplate, SubmitButton, TextInput } from 'ui-builder';
import { Plan, usePlansService } from 'nekst-api';
import React from 'react';
import { ProjectTypeDropdown } from '../../Projects';
import { DetailsFieldsSetsToggleWrapper } from 'feature-toggle';
import { DetailFieldsSetDropdown } from '../DetailFieldsSetDropdown';

export interface CreatePlanFormProps {
  afterSubmitFunc?: (_data: any) => Promise<void>,
}

export function CreatePlanForm(props: CreatePlanFormProps) {
  const plansService = usePlansService();


  return (
    <FormTemplate<Plan>
      submitFormFunc={plansService.create}
      afterSubmitFunc={props.afterSubmitFunc}
      validationSchema={plansService.getCreateValidationSchema()}
      title="Create new plan"
      buttons={
        <SubmitButton text="Save" key="save" />
      }
    >
      <FormBlock>
        <TextInput source="name" label="Please Enter a Name for This Action Plan" />
        <ProjectTypeDropdown source="projectType" label="Transaction Type" />
        <DetailsFieldsSetsToggleWrapper>
          <DetailFieldsSetDropdown
            projectTypeSource="projectType"
            source="detailsFieldsSetId"
            label="Details Boxes Set"
          />
        </DetailsFieldsSetsToggleWrapper>
      </FormBlock>
    </FormTemplate>
  );
}
