import { Project, useProjectsService } from 'nekst-api';
import {
  AfterSubmitFunc,
  DateInput, FormBlock,
  FormLayoutProps,
  FormTemplate,
  Row6x6x,
  TextInput
} from 'ui-builder';
import React, { ReactElement } from 'react';
import { ProjectTypeDropdown } from '../ProjectTypeDropdown';
import { ProjectPlanOfTypeDropdown } from './ProjectPlanOfTypeDropdown';
import { ProjectTypeChangeBehavior } from './ProjectTypeChangeBehavior';
import { ProjectStatusDropdown } from './ProjectStatusDropdown';
import { ParseDataFieldWrapper } from '../Launching/FromContract/ParseDataFieldWrapper';

export interface BaseProjectFormProps {
  submitFormFunc: (data: Project) => Promise<Project>,
  afterSubmitFunc?: AfterSubmitFunc<Project>,
  initialData?: Project,
  buttons?: ReactElement,
  className?: string
  layout?: React.FC<FormLayoutProps>
  title?: string
  dataKey?: string
  children?: ReactElement
}

export function BaseProjectForm(props: BaseProjectFormProps) {
  const projectsService = useProjectsService();

  return (
    <FormTemplate<Project>
      submitFormFunc={props.submitFormFunc}
      afterSubmitFunc={props.afterSubmitFunc}
      validationSchema={projectsService.getCreateValidationSchema()}
      title={props.title}
      buttons={props.buttons}
      className={props.className}
      layout={props.layout}
      testID={props.dataKey}
      initialData={props.initialData}
    >
      <FormBlock>
        <ParseDataFieldWrapper source="name">
          <TextInput source="name" label="Transaction Name" />
        </ParseDataFieldWrapper>
        <ProjectTypeDropdown
          source="type"
          label="Type"
        />
        <ProjectPlanOfTypeDropdown
          projectTypeSource="type"
          source="planId"
          label="Action Plan"
        />
        <ProjectTypeChangeBehavior />
        <ProjectStatusDropdown
          source="status"
          label="Status"
          isVisible={(data) => !!data.id}
        />
        <Row6x6x dataKey="project-start-end-date-fields">
          <ParseDataFieldWrapper source="startDate">
            <DateInput source="startDate" label="Start Date" />
          </ParseDataFieldWrapper>
          <ParseDataFieldWrapper source="endDate">
            <DateInput source="endDate" label="End Date" />
          </ParseDataFieldWrapper>
        </Row6x6x>
        {props.children}
      </FormBlock>
    </FormTemplate>
  );
}
