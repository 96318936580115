import { BaseInputProps, Dropdown, LoadingAnimation, Option } from 'ui-builder';
import React, { useState } from 'react';
import { useProjectRolesService } from 'nekst-api';
import useDataLoader from 'data-loader';

interface ProjectRoleDropdownProps extends BaseInputProps<number> {

}

export function ProjectRoleDropdown(props: ProjectRoleDropdownProps) {

  const [options, setOptions] = useState<Option[]>();

  const projectRolesService = useProjectRolesService();

  useDataLoader(
    async () => {
      const roles = await projectRolesService.getAllTeamRoles();
      return roles.map((v) => ({
        value: v.id,
        label: v.name,
      }));
    },
    setOptions,
  );

  if (options) {
    return (<Dropdown options={options} {...props} />);
  } else {
    return (
      <LoadingAnimation />
    );
  }

}
