import React from 'react';
import BaseTaskParseResultForm from './BaseTaskParseResultForm';
import { useAiTrainingEntryContext } from '../ViewParseResultWidget';
import { AfterSubmitFunc } from 'ui-builder';

export function CreateTaskParseResultForm(
  props: {
    afterSubmitFunc?: AfterSubmitFunc,
  }
) {
  const entryContext = useAiTrainingEntryContext();

  return (
    <BaseTaskParseResultForm
      afterSubmitFunc={props.afterSubmitFunc}
      submitFormFunc={async (data) => {
        entryContext.addTask(data);
      }}
      initialData={{
        context: []
      }}
      title="Create Task"
    />
  )
}
