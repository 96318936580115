import React from 'react';
import { usePopupManager, useShowContext } from 'ui-builder';
import useDocumentsTemplatesService, { DocumentsTemplate } from './documentsTemplatesService';
import IconButton, { IconType } from '../../../shared/widgets/IconButton';
import { ConfirmDeletePopup } from 'features/nekst-widgets';

function ConfirmTemplateDeletePopup(
  props: {
    data: DocumentsTemplate,
  },
) {
  const service = useDocumentsTemplatesService();

  return (
    <ConfirmDeletePopup
      deleteFunc={() => service.deleteDocumentsTemplate(props.data.id)}
      message={`Are you sure you want to delete template "${props.data.name}"?`}
    />
  );
}

export default function DeleteTemplateButton() {
  const showContext = useShowContext<DocumentsTemplate>();

  const popupManager = usePopupManager();

  return (
    <IconButton
      title="Delete Template"
      onClick={
        () => popupManager.openPopup(
          <ConfirmTemplateDeletePopup data={showContext.data!} />,
        )
      }
      type={IconType.TRASH_CAN}
    />
  );
}
