import React from 'react';
import { UpdatePlanTaskFormProps } from './UpdateStandardPlanTaskForm';
import BaseStandardTaskForm from './BaseStandardTaskForm';
import UpdateTaskButtons from '../UpdateTaskButtons';
import { usePlanTasksService } from 'nekst-api';

export default function UpdateStandardTaskForm(props: UpdatePlanTaskFormProps) {
  const planTasksService = usePlanTasksService();

  return (
    <BaseStandardTaskForm
      title={props.data.name}
      getDataFunc={async () => props.data}
      afterSubmitFunc={props.afterSubmitFunc}
      submitFormFunc={async (request) => {
        return planTasksService.updateTask(props.data.id, request);
      }}
      validationSchema={planTasksService.getStandardTaskValidationSchema()}
      buttons={<UpdateTaskButtons />}
    />
  );
}
