import React, {
  ReactElement,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useFilterContext, useShowContext } from 'ui-builder';
import {
  TasksFilter,
  useEventsSubscriber,
  TASK_COMPLETED,
  TASKS_MASS_COMPLETED,
  TASK_CREATED,
  TASK_DELETED,
  TASK_UPDATED,
  TASKS_MASS_DELETED,
  TASKS_MASS_UPDATED_EVENT,
  TASKS_MASS_SET_DUE_DATE,
  Project,
  useTasksStatisticService,
  TasksStatistic, TASK_UNCOMPLETED,
} from 'nekst-api';

export type DueDateCounterContextData = {
  getNumberOfTodayTasks: () => number,
  getNumberOfPastDueTasks: () => number,
  getNumberOfNoDueDateTasks: () => number,
  getTotalNumberOfTasks: () => number,
  getNumberOfTasksAtDate: (date: Date) => number,
  loading: boolean,
};

const DueDateCounterReactContext = React.createContext<DueDateCounterContextData | undefined>(
  undefined,
);

export interface DueDateCounterProps {
  children: ReactElement | ReactElement[];
}

export function useDueDateCounterService() {
  const result = useContext(DueDateCounterReactContext);

  if (!result) {
    throw new Error('The element must be used within DueDateCounter context');
  }

  return result;
}

export function DueDateCounter(props: DueDateCounterProps) {
  const filterContext = useFilterContext<TasksFilter>();

  const [statistic, setStatistic] = useState<TasksStatistic>();

  const [loading, setLoading] = useState(false);

  const showContext = useShowContext<Project>();

  const statisticService = useTasksStatisticService();

  const filterValue = filterContext.filterValue!;

  const filterWithoutDates = {
    ...filterValue,
  };
  delete filterWithoutDates.dueDateTo;
  delete filterWithoutDates.dueDateFrom;
  delete filterWithoutDates.noDueDate;

  async function loadStatistics() {
    setLoading(true);
    setStatistic(
      await statisticService.getStatistic(filterWithoutDates, showContext.data?.id),
    );

    setLoading(false);
  }

  useEventsSubscriber(
    'DueDateCounter',
    {
      [TASK_UPDATED]: loadStatistics,
      [TASK_COMPLETED]: loadStatistics,
      [TASK_UNCOMPLETED]: loadStatistics,
      [TASK_CREATED]: loadStatistics,
      [TASK_DELETED]: loadStatistics,
      [TASKS_MASS_COMPLETED]: loadStatistics,
      [TASKS_MASS_DELETED]: loadStatistics,
      [TASKS_MASS_UPDATED_EVENT]: loadStatistics,
      [TASKS_MASS_SET_DUE_DATE]: loadStatistics,
    },
    [JSON.stringify(filterWithoutDates)],
    loadStatistics,
  );

  const getNumberOfTodayTasks = () => {
    return statistic?.today || 0;
  };

  const dateToYyyyMmDd = (date: Date) => {
    const year = date.getFullYear();

    const month = date.getMonth() + 1;

    const monthString = month >= 10 ? month.toString() : `0${month}`;

    return `${year}-${monthString}-${date.getDate()}`;
  };

  const getNumberOfTasksAtDate = (date: Date) => {
    const formatted = dateToYyyyMmDd(date);

    return statistic?.datesWithTasks[formatted] || 0;
  };

  const getNumberOfPastDueTasks = () => {
    return statistic?.pastDue || 0;
  };

  const getNumberOfNoDueDateTasks = () => {
    return statistic?.noDueDate || 0;
  };

  const getTotalNumberOfTasks = () => {
    return statistic?.total || 0;
  };

  const contextValue = useMemo<DueDateCounterContextData>(() => ({
    getNumberOfNoDueDateTasks,
    getNumberOfTodayTasks,
    getNumberOfPastDueTasks,
    getTotalNumberOfTasks,
    getNumberOfTasksAtDate,
    loading,
  }), [JSON.stringify(statistic), loading]);

  return (
    <DueDateCounterReactContext.Provider value={contextValue}>
      {props.children}
    </DueDateCounterReactContext.Provider>
  );
}
