import React from 'react';
import { BaseHistoryEntryData, PersonData, ProjectAssignmentType, RoleData } from 'nekst-api';
import RecordLayout, { RecordLayoutProps } from './RecordLayout';
import PersonLink from './PersonLink';
import ProjectLink from './ProjectLink';
import useTextHelper from './pageDefiner';
import { HistoryEventIconType } from './HistoryEventIcon';

interface ProjectAssignmentRemovedData extends BaseHistoryEntryData {
  assignment: {
    person: PersonData,
    role: RoleData,
    type: ProjectAssignmentType,
  },
  hasOtherAssignments: boolean,
}

export default function ProjectAssignmentRemovedRecordLayout(
  props: RecordLayoutProps<ProjectAssignmentRemovedData>,
) {
  const textHelper = useTextHelper();
  return (
    <RecordLayout
      text={(
        <>
          <PersonLink person={props.record.data.assignment.person} />
          {' was removed from the transaction by '}
          <PersonLink person={props.record.data.person!} />
          {!textHelper.isProjectHistoryPage() && (
            <>
              {' ('}
              <ProjectLink project={props.record.data.project} />
              )
            </>
          )}
          .
        </>
      )}
      date={props.record.when}
      iconType={HistoryEventIconType.ASSIGNMENT_REMOVED}
    />
  );
}
