import { ProjectDetailsFieldType } from 'nekst-api';

export default function useProjectDetailsFieldTypeHelper() {
  const getLabel = (type: ProjectDetailsFieldType) => {
    const map = {
      [ProjectDetailsFieldType.DATE]: 'Date',
      [ProjectDetailsFieldType.PRICE]: 'Currency',
      [ProjectDetailsFieldType.SHORT_TEXT]: 'Text',
      [ProjectDetailsFieldType.LONG_TEXT]: 'Text',
    };

    return map[type];
  };

  return {
    getLabel,
  };
}
