import React, { ReactNode } from 'react';
import { ButtonSize, SubmitButton } from 'ui-builder';
import { Project, useProjectDetailsService } from 'nekst-api';
import FormTemplate from '../../shared/uibuilder/form/FormTemplate';
import useClientPortalConfigurationService, {
  ClientPortalConfiguration,
  KeyDateLink,
  ServiceProviderLink,
} from './clientPortalConfigurationService';
import DetailBoxesCheckboxGroup from './DetailBoxesCheckboxGroup';
import ContactsCheckboxGroup from './ContactsCheckboxGroup';
import KeyDatesToDisplayWidget from './KeyDatesToDisplayWidget';
import ServiceProvidersContext from './ServiceProvidersContext';
import ServiceProvidersGroupSelector from './ServiceProvidersGroupSelector';
import Separator from '../../shared/web/uitheme/form/Separator';
import Row6x6x from '../../shared/web/uitheme/form/Row6x6x';
import TeamMembersRadioButtons from '../shared/TeamMembersRadioButtons';
import usePeopleService from '../../people/peopleService';
import useProjectTasksService, { AbstractTask } from '../view/tasks/projectTasksService';
import Checkbox from '../../shared/uibuilder/form/input/Checkbox';
import ImageUploaderInput from '../../shared/uibuilder/form/input/ImageUploaderInput';
import CopyClientPortalLinkButton from './CopyClientPortalLinkButton';
import OpenPortalPreview from './OpenPortalPreview';
import DocumentsCheckboxGroup from './DocumentsCheckboxGroup';
import useProjectDocumentsService from '../documents/projectDocumentsService';
import PortalTitleInput from '../../settings/clientportal/DefaultConfiguration/PortalTitleInput';
import { createPortal } from 'react-dom';

type FormKeyDateLink = {
  enabled: boolean,
  description?: string,
  serviceProviders: Record<number, number[]>,
}

export type ClientConfigurationFormData = {
  title?: string,
  enabled: boolean,
  detailFieldsIds: number[],
  contactsIds: number[],
  teamMemberForBrandingId?: number,
  keyDatesLinks: Record<string, FormKeyDateLink>
  documentsIds: number[],
  additionalServiceProviders: Record<number, number[]>
  photo?: string,
  _portalUrl?: string
  _isNew?: boolean
};

function useDefaultValueLoader(projectId: number) {
  const projectDetailsService = useProjectDetailsService();
  const peopleService = usePeopleService();

  const projectTasksService = useProjectTasksService();

  const projectDocumentService = useProjectDocumentsService();

  const getProjectDetails = async () => {
    return projectDetailsService.getProjectDetails(projectId);
  };

  const getTeamMembers = async () => {
    return peopleService.getTeamMembers();
  };

  const getProjectDocuments = async () => {
    return projectDocumentService.getProjectDocuments(projectId);
  };

  const getKeyDates = async () => {
    const result = await projectTasksService.getAllTasks(
      projectId,
      {
        showKeyDatesOnly: true,
      },
      {
        limit: 100,
        offset: 0,
      },
    );

    return result.data;
  };

  const getDefaultValue = async (): Promise<ClientConfigurationFormData> => {
    const promises = await Promise.all([
      getProjectDetails(),
      getTeamMembers(),
      getKeyDates(),
      getProjectDocuments(),
    ]);

    return {
      enabled: false,
      detailFieldsIds: promises[0].map((item) => item.setting.id),
      teamMemberForBrandingId: promises[1][0].id,
      keyDatesLinks: promises[2].reduce(
        (prev: Record<string, FormKeyDateLink>, current: AbstractTask) => {
          const result = {
            ...prev,
          };

          result[`task${current.id}`] = {
            enabled: true,
            serviceProviders: {},
          };

          return result;
        },
        {},
      ),
      contactsIds: promises[1].map((item) => item.id),
      additionalServiceProviders: {},
      documentsIds: promises[3].map((item) => item.id),
      _isNew: true,
    };
  };

  return {
    getDefaultValue,
  };
}

function useFormRequestMapper() {
  const toApiView = (data: ClientConfigurationFormData): ClientPortalConfiguration => {
    const serviceProvidersLinks: ServiceProviderLink[] = [];
    const keyDatesLinks: KeyDateLink[] = [];

    Object.entries(data.keyDatesLinks)
      .filter(([, item]) => !!item)
      .forEach(([taskKey, taskData]) => {
        if (taskData.enabled) {
          const re = /^task([0-9]+)$/;
          const matches = taskKey.match(re);

          if (matches) {
            const taskId = parseInt(matches[1], 10);
            keyDatesLinks.push({
              taskId,
              description: taskData.description,
            });

            Object.entries(taskData.serviceProviders || {})
              .filter(([, providersIds]) => !!providersIds)
              .forEach(([providerKey, providersIds]) => {
                const providerGroupId = parseInt(providerKey, 10);

                providersIds.forEach((providerId) => {
                  serviceProvidersLinks.push({
                    taskId,
                    serviceProviderGroupId: providerGroupId,
                    personId: providerId,
                  });
                });
              });
          }
        }
      });

    Object.entries(data.additionalServiceProviders)
      .filter(([, ids]) => !!ids)
      .forEach(([providerIdKey, peopleIds]) => {
        const providerId = parseInt(providerIdKey, 10);

        peopleIds.forEach((newPersonId) => {
          serviceProvidersLinks.push({
            serviceProviderGroupId: providerId,
            personId: newPersonId,
          });
        });
      });

    return {
      title: data.title,
      enabled: data.enabled,
      detailFieldsIds: data.detailFieldsIds,
      serviceProvidersLinks,
      keyDatesLinks,
      contactsIds: data.contactsIds,
      teamMemberForBrandingId: data.teamMemberForBrandingId,
      documentsIds: data.documentsIds,
    };
  };

  const toFormView = (data: ClientPortalConfiguration): ClientConfigurationFormData => {
    const reduceServiceProvidersFunc = (
      prevServiceProvider: Record<number, number[]>,
      link: ServiceProviderLink,
    ) => {
      const newResult = { ...prevServiceProvider };

      if (!newResult[link.serviceProviderGroupId]) {
        newResult[link.serviceProviderGroupId] = [];
      }

      newResult[link.serviceProviderGroupId].push(link.personId);

      return newResult;
    };

    const keyDatesLinks = data.keyDatesLinks.reduce(
      (prev: Record<string, FormKeyDateLink>, current: KeyDateLink) => {
        const result = {
          ...prev,
        };

        result[`task${current.taskId}`] = {
          enabled: true,
          description: current.description,
          serviceProviders: data.serviceProvidersLinks
            .filter((item) => item.taskId === current.taskId)
            .reduce(reduceServiceProvidersFunc, {}),
        };

        return result;
      },
      {},
    );

    const additionalServiceProviders = data.serviceProvidersLinks
      .filter((item) => !item.taskId)
      .reduce(reduceServiceProvidersFunc, {});

    return {
      enabled: data.enabled,
      title: data.title,
      detailFieldsIds: data.detailFieldsIds,
      keyDatesLinks,
      contactsIds: data.contactsIds,
      teamMemberForBrandingId: data.teamMemberForBrandingId || 0,
      additionalServiceProviders,
      photo: data._photo?.resized,
      _portalUrl: data._portalUrl,
      documentsIds: data.documentsIds,
    };
  };

  return {
    toApiView,
    toFormView,
  };
}

const PORTAL_ID = 'client-portal-form';

export function FormButtonsPortal() {
  return (
    <span id={PORTAL_ID} />
  );
}

export function FormButtonsPortalWrapper(props: { children: ReactNode }) {
  const container = document.getElementById(PORTAL_ID);

  if (container) {
    return createPortal(props.children, container);
  } else {
    throw new Error('portal FormButtonsPortalWrapper not found');
  }
}

export default function ClientPortalConfigurationForm(
  props: {
    project: Project,
  },
) {
  const service = useClientPortalConfigurationService();
  const defaultValueLoader = useDefaultValueLoader(props.project.id);

  const formRequestMapper = useFormRequestMapper();

  return (
    <ServiceProvidersContext>
      <FormTemplate<ClientConfigurationFormData>
        submitFormFunc={async (data) => {
          const photoData = data.photo;

          const request = formRequestMapper.toApiView(data);
          let response = await service.updateConfiguration(props.project.id, request);

          if (photoData) {
            if (!photoData.startsWith('http')) {
              response = await service.updatePhoto(props.project.id, photoData);
            }
          } else {
            response = await service.deletePhoto(props.project.id);
          }

          return formRequestMapper.toFormView(response);
        }}
        getDataFunc={async () => {
          try {
            const configuration = await service.getConfiguration(props.project.id);
            return formRequestMapper.toFormView(configuration);
          } catch (e) {
            return defaultValueLoader.getDefaultValue();
          }
        }}
        updateFormDataAfterSubmit
        buttons={(
          <FormButtonsPortalWrapper>
            <SubmitButton text="Update" size={ButtonSize.MEDIUM} />
          </FormButtonsPortalWrapper>
        )}
      >
        <CopyClientPortalLinkButton />
        <OpenPortalPreview />
        <Checkbox source="enabled" label="Client Portal Enabled" />
        <ImageUploaderInput
          source="photo"
          label="Transaction Photo"
          minHeight={450}
          minWidth={1000}
          hint="Please ensure that the uploaded file has a minimum width of 1000 pixels and a minimum height of 450 pixels."
        />
        <PortalTitleInput projectType={props.project.type} source="title" label="Title" />
        <Separator />
        <DetailBoxesCheckboxGroup
          projectId={props.project.id}
          source="detailFieldsIds"
          label="Detail Boxes"
          hint="Fields that have values are indicated in bold."
        />
        <Separator />
        <KeyDatesToDisplayWidget projectId={props.project.id} label="Key Dates To Display" />
        <Separator />
        <ContactsCheckboxGroup
          projectId={props.project.id}
          source="contactsIds"
          label="Contacts To Display"
        />
        <Separator />
        <Row6x6x>
          <ServiceProvidersGroupSelector
            source="additionalServiceProviders"
            label="Additional Service Providers To Include"
          />
        </Row6x6x>
        <Separator />
        <DocumentsCheckboxGroup projectId={props.project.id} />
        <Separator />
        <TeamMembersRadioButtons
          withNoneOption
          projectId={props.project.id}
          source="teamMemberForBrandingId"
          label="Agent/Team Branding"
        />
      </FormTemplate>
    </ServiceProvidersContext>
  );
}
