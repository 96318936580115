import React from 'react';
import {
  PLAN_TASKS_UPDATED,
  ProjectRole,
  TeamPermission,
  useBackendApi,
  useEventsPublisher
} from 'nekst-api';
import { useAuthorizationChecker } from 'authorization-scope';
import {
  AfterSubmitFunc,
  Button,
  ButtonColor,
  ButtonSize,
  CheckboxGroup,
  FormTemplate,
  LoadingAnimation,
  useMassUpdateServiceRequired,
} from 'ui-builder';
import { PopupText, Separator, usePopupFormManager } from 'features/nekst-widgets';
import { useProjectRolesLoader } from 'features/tasks-forms';

function useAssignService() {
  const nekstApi = useBackendApi();

  const assignToRoles = async (tasksIds: number[], rolesIds: number[]) => {
    await nekstApi.put('/plantasks/bulk/transactionparties', {
      tasksIds,
      rolesIds,
    });
  };

  return {
    assignToRoles,
  };
}

function SelectRolesCheckboxes(
  props: {
    source: string,
    label: string,
  },
) {
  const {
    roles,
    isLoaded,
  } = useProjectRolesLoader();
  if (isLoaded()) {
    return (
      <CheckboxGroup
        options={
          roles.filter((role) => role.isAssignable)
            .map((role: ProjectRole) => ({
              value: role.id,
              label: role.name,
            }))
        }
        source={props.source}
        label={props.label}
      />
    );
  } else {
    return <LoadingAnimation />;
  }
}

function AssignToRolesForm(
  props: {
    tasksIds: number[],
    afterSubmitFunc?: AfterSubmitFunc,
  },
) {

  const assignService = useAssignService();

  const eventsPublisher = useEventsPublisher();

  return (
    <FormTemplate<{ rolesIds: number[] }>
      title="Mass Assign: Roles"
      afterSubmitFunc={props.afterSubmitFunc}
      submitFormFunc={async (formData) => {
        await assignService.assignToRoles(props.tasksIds, formData.rolesIds);
        eventsPublisher.publish(PLAN_TASKS_UPDATED, {});
        return formData;
      }}
      validationSchema={{
        rolesIds: {
          type: 'array',
          constraints: {
            required: {
              value: true,
              message: 'At least one role must be selected',
            },
          },
        },
      }}
    >
      <PopupText>Select roles to be assigned the selected tasks.</PopupText>
      <Separator />
      <SelectRolesCheckboxes source="rolesIds" label="Select Roles" />
    </FormTemplate>
  );
}

export default function AssignToRolesButton() {
  const popupManager = usePopupFormManager();

  const { isGranted } = useAuthorizationChecker();

  const massUpdateService = useMassUpdateServiceRequired();

  const ids = massUpdateService.getCheckedIds();

  if (isGranted(TeamPermission.USE_TEAM_MEMBERS_FEATURE)) {
    return (
      <Button
        text="Assign To"
        isDisabled={ids.length === 0}
        onClick={async () => {
          popupManager.openForm(<AssignToRolesForm tasksIds={ids} />);
        }}
        color={ButtonColor.BLUE}
        size={ButtonSize.SMALL}
        isFlat
        isFilled
      />
    );
  } else {
    return null;
  }
}
