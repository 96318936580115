import React from 'react';
import { useShowContext } from '../../shared/uibuilder/show/ShowReactContext';
import { Person } from '../peopleService';

export default function AssignedRolesField() {
  const showContext = useShowContext<Person>();

  const person = showContext.data!;
  if (person._assignments?.length) {

    const rolesNames = person._assignments.map((v) => v._role.name)
      .filter((value, index, self) => self.indexOf(value) === index)
      .join(', ');

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{rolesNames}</>;
  } else {
    return <>-</>;
  }
}
