import React, { ReactElement, useEffect, useState } from 'react';
import {
  FilterContext,
  ListWithPagination,
  OrderBy,
  OrderDirection,
  PageResponse,
  useFilterContext,
  useListContext,
} from 'ui-builder';
import {
  Project,
  ProjectDetailsField,
  ProjectDetailsFieldType,
  ProjectFilter,
  ProjectStatus,
  ProjectType,
  TeamPermission,
  useProjectDetailsFieldsService,
  useProjectsService,
} from 'nekst-api';
import MainBlock from '../../shared/web/layout/MainBlock';
import CreateProjectButton from '../launch/CreateUpdateProject/CreateProjectButton';
import GridLayout, { DynamicColumnsService } from '../../shared/uibuilder/list/GridLayout';
import PaginationWrapper from '../../shared/uibuilder/list/PaginationWrapper';
import PastDueTasksField from './field/PastDueTasksField';
import TotalTasksField from './TotalTasksField';
import ProjectLinkField from './field/ProjectLinkField';
import NextKeyDateField from './field/NextKeyDateField';

import styles from './ProjectListPage.module.scss';
import TotalField from '../../shared/uibuilder/list/TotalField';
import ProjectsStatisticWidget from './ProjectsStatisticWidget';
import QuickFilters from './QuickFilters';
import ProjectNameFilter from './ProjectNameFilter';
import Right from '../../shared/web/Right';
import { OpenProjectsFilterButton } from './ProjectsFilterForm';
import FilterWidgets from './FilterWidgets';
import { Breadcrumb, BreadcrumbsWrapper } from '../../shared/web/layout/Breadcrumbs';
import ProjectsListConfigurationContext, {
  useProjectListConfigurationContext,
} from './ProjectsListConfigurationContext';
import ByPageDropdown from './ByPageDropdown';

import DateDetailsField from './field/DateDetailsField';
import PriceDetailsField from './field/PriceDetailsField';
import TextDetailsField from './field/TextDetailsField';
import AccessChecker from '../../shared/authorization/AccessChecker';
import { useAuthorizationChecker } from 'authorization-scope';
import useDataLoader from 'data-loader';
import { ProjectStatisticContext, useProjectTypeHelper } from 'features/tasks-forms';
import ProjectsListPageTour from './ProjectsListPageTour';
import PageHeaderWithIcon, {
  PageIconType
} from '../../shared/widgets/PageTitle/PageHeaderWithIcon';

function FirstPageLoader(
  props: {
    onFilterChange: (filter: ProjectFilter) => Promise<void>,
    children: ReactElement | ReactElement[],
  },
) {
  const filterContext = useFilterContext<ProjectFilter>();

  const [loading, setLoading] = useState(false);

  const projectsListConfiguration = useProjectListConfigurationContext();

  const byPage = projectsListConfiguration.configuration?.byPage;

  useEffect(() => {
    setLoading(true);
    props.onFilterChange(filterContext.filterValue!)
      .then(() => setLoading(false));
  }, [JSON.stringify(filterContext?.filterValue), byPage]);

  return (
    <div className={loading ? 'loading' : ''}>
      {props.children}
    </div>
  );
}

function useSortOrderPersister() {
  const getKey = (projectType: ProjectType) => {
    return `project-list-order-${projectType}`;
  };

  const saveOrderBy = (projectType: ProjectType, value: OrderBy) => {
    const cacheKey = getKey(projectType);
    localStorage.setItem(cacheKey, JSON.stringify(value));
  };

  const getOrderBy = (projectType: ProjectType) => {
    const cacheKey = getKey(projectType);

    const cachedItem = localStorage.getItem(cacheKey);

    const defaultValue: OrderBy = {
      field: 'name',
      direction: OrderDirection.ASC,
    };

    if (cachedItem) {
      try {
        return JSON.parse(cachedItem) as OrderBy;
      } catch (e) {
        return defaultValue;
      }
    } else {
      return defaultValue;
    }
  };

  return {
    saveOrderBy,
    getOrderBy,
  };
}

function useProjectListColumnsHelper(projectType: ProjectType): DynamicColumnsService {
  const projectDetailsFieldService = useProjectDetailsFieldsService();

  const configurationContext = useProjectListConfigurationContext();

  const projectTypesHelper = useProjectTypeHelper();

  const { isGranted } = useAuthorizationChecker();

  const [fields, setFields] = useState<ProjectDetailsField[]>();

  useDataLoader(
    async () => projectDetailsFieldService.getFields(projectType),
    setFields,
  );

  const getFieldById = (fieldId: number) => {
    return fields?.find((item) => item.id === fieldId);
  };

  const getFieldByName = (fieldName: string) => {
    return fields?.find((item) => item.name === fieldName);
  };

  const getDefaultValue = () => {
    let defaultFields: ProjectDetailsField[];
    if (projectType === ProjectType.LISTING) {
      defaultFields = [
        getFieldByName('Listing Expiration'),
        getFieldByName('Listing Price'),
      ].filter((item) => !!item) as ProjectDetailsField[];
    } else {
      defaultFields = [
        getFieldByName('Closing Date'),
        getFieldByName('Commission Received'),
      ].filter((item) => !!item) as ProjectDetailsField[];
    }

    return defaultFields.map((item) => item.id);
  };

  const getValue = () => {
    if (fields) {
      const fieldsOrderMap: Record<number, number> = {};

      fields.forEach((item) => {
        fieldsOrderMap[item.id] = item.order;
      });

      const typeConfiguration = configurationContext.configuration
        ? configurationContext.configuration[projectType]
        : {};

      const value = (typeConfiguration?.columns || getDefaultValue())
        .filter((item) => item in fieldsOrderMap);

      value.sort((a1, a2) => {
        const a1Weight = fieldsOrderMap[a1];
        const a2Weight = fieldsOrderMap[a2];

        return a1Weight - a2Weight;
      });

      return value;
    } else {
      return [];
    }
  };

  const setValue = async (value: number[]) => {
    if (fields) {
      await configurationContext.setTypeConfiguration(projectType, {
        columns: value,
      });
    }
  };

  const getOptionsFunc = async () => {
    return (await projectDetailsFieldService.getFields(projectType))
      .map((item) => ({
        value: item.id,
        label: item.name,
      }));
  };

  const getLabel = (columnId: number) => {
    return fields?.find((item) => item.id === columnId)?.name || '';
  };

  const getCell = (columnId: number) => {
    const field = getFieldById(columnId);

    if (field) {
      switch (field.fieldType) {
        case ProjectDetailsFieldType.DATE:
          return (
            <DateDetailsField label={field.name} fieldId={field.id} />
          );
        case ProjectDetailsFieldType.PRICE:
          return (
            <PriceDetailsField label={field.name} fieldId={field.id} />
          );
        case ProjectDetailsFieldType.SHORT_TEXT:
        case ProjectDetailsFieldType.LONG_TEXT:
          return (
            <TextDetailsField label={field.name} fieldId={field.id} />
          );
        default:
          return (<>-</>);
      }
    } else {
      return (<>No field found</>);
    }
  };

  const getSortKey = (columnId: number) => {

    const field = getFieldById(columnId);
    if (field) {
      if (field.fieldType !== ProjectDetailsFieldType.LONG_TEXT) {
        return `detailsField:${columnId}`;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  return {
    getValue,
    setValue,
    getOptionsFunc,
    getLabel,
    getCell,
    getSortKey,
    getTitle: () => `Display Columns - ${projectTypesHelper.getLabel(projectType)}`,
    isUpdateAllowed: () => {
      return isGranted(TeamPermission.MANAGE_TEAM_CONFIGURATION);
    },
  };
}

function ProjectTypeField(
  props: {
    value: ProjectType,
    className: string,
    total: number | ReactElement,
  },
) {
  const projectTypeHelper = useProjectTypeHelper();
  return (
    <div className={props.className}>
      {projectTypeHelper.getLabel(props.value)}
      {' ('}
      {props.total}
      )
    </div>
  );
}

function SaveSortSettingsBehavior(
  props: {
    projectType: ProjectType,
  },
) {
  const sortOrderPersister = useSortOrderPersister();
  const listContext = useListContext();

  const orderBy = sortOrderPersister.getOrderBy(props.projectType);

  useEffect(() => {
    const savedField = orderBy?.field || 'name';
    const savedDirection = orderBy?.direction || OrderDirection.ASC;

    if (savedField !== listContext.orderByField || savedDirection !== listContext.orderDirection) {
      sortOrderPersister.saveOrderBy(props.projectType, {
        field: listContext.orderByField || 'name',
        direction: (listContext.orderDirection || OrderDirection.ASC) as OrderDirection,
      });
    }
  }, [listContext.orderByField, listContext.orderDirection]);

  return null;
}

function TypeTable(props: {
  projectType: ProjectType,
}) {
  const helper = useProjectListColumnsHelper(props.projectType);

  return (
    <>
      <GridLayout
        dynamicColumns={helper}
        title={(
          <ProjectTypeField
            value={props.projectType}
            className={styles.title}
            total={<TotalField />}
          />
        )}
        weights={[2, 1.5, 1.7, 2]}
      >
        <ProjectLinkField
          source="name"
          idSource="id"
          label="Name"
          sortName="name"
        />
        <TotalTasksField label="Task Status" />
        <PastDueTasksField label="Past Due" />
        <NextKeyDateField
          label="Next Key Date"
          source="statistic.nextKeyDate"
          dataKey="next-key-date-field"
        />
      </GridLayout>
      <AccessChecker
        permissionToCheck={TeamPermission.MANAGE_TEAM_CONFIGURATION}
        show403Error={false}
      >
        <SaveSortSettingsBehavior projectType={props.projectType} />
      </AccessChecker>
    </>
  );
}

function Tables() {
  const filterContext = useFilterContext<ProjectFilter>();

  const projectTypeHelper = useProjectTypeHelper();

  const [firstPageData, setFirstPageData] = useState<Record<ProjectType, PageResponse<Project>>>();

  const sortOrderPersister = useSortOrderPersister();

  let types: ProjectType[];
  if (filterContext.filterValue?.types.selected.length) {
    types = filterContext.filterValue.types.selected;
  } else {
    types = [
      ProjectType.PENDING_BUYER,
      ProjectType.PENDING_SELLER,
      ProjectType.PENDING_DUAL,
      ProjectType.LISTING,
      ProjectType.ACTIVE_BUYER,
      ProjectType.OTHER,
    ];
  }

  const [allSortBy, setAllSortBy] = useState<Record<string, Record<string, OrderDirection>>>(
    types
      .reduce((
        prevValue: Record<string, Record<string, OrderDirection>>,
        currentValue: ProjectType,
      ) => {
        const newValue = {
          ...prevValue,
        };

        const savedOrderBy = sortOrderPersister.getOrderBy(currentValue);
        if (savedOrderBy) {
          newValue[currentValue.toLowerCase()] = {
            [savedOrderBy.field]: savedOrderBy.direction,
          };
        }

        return newValue;
      }, {}),
  );

  types.sort(projectTypeHelper.compare);

  const projectService = useProjectsService();

  const projectsListConfiguration = useProjectListConfigurationContext();

  const byPage = projectsListConfiguration.configuration?.byPage || 10;

  async function loadData(filter: ProjectFilter) {
    setFirstPageData(await projectService.getFirstPagesByTypes(filter, allSortBy, byPage));
  }

  return (
    <FirstPageLoader
      onFilterChange={(filter: ProjectFilter) => loadData(filter)}
    >
      <>
        <Right maxWidth={70}>
          <Right><OpenProjectsFilterButton /></Right>
          <FilterWidgets />
        </Right>
        <QuickFilters />
        <ProjectsStatisticWidget />
        <ByPageDropdown />
        {firstPageData && types.map((type) => {
          const table = (<TypeTable projectType={type} />);

          if (firstPageData[type]) {
            const initialSortBy = sortOrderPersister
              .getOrderBy(type);
            return (
              <div
                key={`list-${type}`}
                className={styles.list}
                style={{ marginBottom: '30px' }}
                data-test={`projects-list-${type}`}
              >
                <ListWithPagination<Project, ProjectFilter>
                  firstPageData={firstPageData[type]}
                  byPage={byPage}
                  getDataFunc={async (filter: ProjectFilter, limit, sortBy) => {
                    return projectService.getProjects({
                      ...filter,
                      types: {
                        selectAll: false,
                        selected: [type],
                      },
                    }, limit, true, sortBy);
                  }}
                  initialSortBy={initialSortBy}
                  onSortChanged={(field, direction) => {
                    setAllSortBy((prevState) => ({
                      ...prevState,
                      [type.toLowerCase()]: {
                        [field]: direction,
                      },
                    }));
                  }}
                >
                  <PaginationWrapper>
                    {table}
                  </PaginationWrapper>
                </ListWithPagination>
              </div>
            );
          } else {
            return null;
          }
        })}
      </>
    </FirstPageLoader>
  );
}

export function ProjectsListBreadcrumbs(props: {
  children?: ReactElement | false,
}) {
  return (
    <BreadcrumbsWrapper>
      <Breadcrumb text="Transactions" href="/transactions">
        {props.children}
      </Breadcrumb>
    </BreadcrumbsWrapper>
  );
}

export default function ProjectsListPage() {

  const { isGranted } = useAuthorizationChecker();

  return (
    <>
      <ProjectsListBreadcrumbs />
      <FilterContext
        initialValue={{
          status: ProjectStatus.ACTIVE,
          types: {
            selectAll: true,
            selected: [],
          },
          assignedTo: {
            selectAll: true,
            selected: [],
          },
        } as ProjectFilter}
      >
        <ProjectsListConfigurationContext>
          <ProjectStatisticContext
            noProjectsView={(
              <MainBlock
                title={
                  <PageHeaderWithIcon iconType={PageIconType.TRANSACTIONS}>
                    Transactions
                  </PageHeaderWithIcon>
                }
              >
                <h3>Welcome to Nekst!</h3>
                {isGranted(TeamPermission.CREATE_PROJECT) && (<div>
                  Let&apos;s start by adding a new transaction.
                  <br />
                  <br />
                  <CreateProjectButton />
                </div>)}

                {!isGranted(TeamPermission.CREATE_PROJECT) && (<div>
                  You are not assigned to any transactions yet.
                </div>)}
              </MainBlock>
            )}
          >
            <MainBlock
              title={
                <PageHeaderWithIcon iconType={PageIconType.TRANSACTIONS}>
                  Transactions
                </PageHeaderWithIcon>
              }
              headerRight={(
                <>
                  <ProjectNameFilter />
                  <CreateProjectButton />
                </>
              )}
            >
              <Tables />
              <ProjectsListPageTour />
            </MainBlock>
          </ProjectStatisticContext>
        </ProjectsListConfigurationContext>
      </FilterContext>
    </>
  );
}
