import React, {
  ReactNode, useEffect,
} from 'react';
import {
  useAuthenticationContext,
  NotAuthenticatedViewProps,
  AuthenticationContext as BaseAuthenticationContext,
} from 'nekst-api';
import { Navigate } from 'react-router-dom';
import LoadingAnimation from '../shared/LoadingAnimation';
import useMeNavigator from '../people/me/meNavigator';
import BaseLayout from '../shared/web/layout/BaseLayout';
import useSettingsNavigator from '../settings/settingsNavigator';

export interface AuthenticationContextProps {
  children: ReactNode;
}

export function useAuthenticationLoader() {
  const authenticationContext = useAuthenticationContext();

  return {
    currentUser: authenticationContext!.user,
  };
}

export {
  useAuthenticationContext,
} from 'nekst-api';

function isRouteAvailableAnonymously() {
  const route = window.location.pathname;

  const routes = [
    '/login',
    '/resetpassword',
    '/signup',
  ];

  return routes.find((item) => route.startsWith(item));
}

function isUnsubscribePage() {
  const route = window.location.pathname;
  return route.startsWith('/unsubscribe/');
}

function NotAuthenticatedView(
  props: NotAuthenticatedViewProps,
) {
  const apiTokenData = props.tokenContext;

  const meNavigator = useMeNavigator();

  if (!apiTokenData.isTokenSet() && !isRouteAvailableAnonymously() && !isUnsubscribePage()) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Navigate to="/login" />;
  } else if (apiTokenData.isTokenSet() && isRouteAvailableAnonymously()) {
    return <Navigate to="/" />;
  } else if (!apiTokenData.isTokenSet()) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{props.children}</>;
  } else if (!props.isErrorLoadingData) {
    return (<LoadingAnimation />);
  } else if (meNavigator.isInvitationPage()) {
    return (
      <BaseLayout>
        The invitation link you&apos;re attempting to access has either expired or has already
        been used. Please get in touch with your administrator for further assistance.
      </BaseLayout>
    );
  } else {
    return <BaseLayout>Something went wrong. Please try to reload the page.</BaseLayout>;
  }
}

function RedirectToUpdateProfilePageIfNeeded() {
  const authenticationContext = useAuthenticationContext();

  const currentUser = authenticationContext?.user;

  const meNavigator = useMeNavigator();
  const settingsNavigator = useSettingsNavigator();

  useEffect(() => {
    if (currentUser
      && !currentUser.hasActiveAccount
      && !meNavigator.isInvitationPage()
    ) {
      settingsNavigator.openUpdateProfilePage();
    }
  }, [currentUser, window.location.pathname]);

  return null;
}

export default function AuthenticationContext(props: AuthenticationContextProps) {
  return (
    <BaseAuthenticationContext
      notAuthenticatedView={NotAuthenticatedView}
    >
      <>
        <RedirectToUpdateProfilePageIfNeeded />
        {props.children}
      </>
    </BaseAuthenticationContext>
  );
}
