import React from 'react';

import styles from '../History.module.scss';

interface Props {
  text: string;
  action: () => void;
  isDeleted: boolean;
}

export default function PageLink(props: Props) {
  const classes = [
    styles.link,
  ];

  if (props.isDeleted) {
    classes.push(styles.isDeleted);
  }

  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <span
      onClick={props.action}
      className={classes.join(' ')}
    >
      {props.text}
    </span>
  );
}
