import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import App from './App';

const nekstAppElement = document.getElementById('nekst-app');

if (nekstAppElement != null) {
  const root = createRoot(nekstAppElement);

  root.render(
    <App />,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
