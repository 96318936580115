import { ShortProfile, useBackendApi } from 'nekst-api';

export type SmsTaskPreview = {
  preview: string,
  recipient: ShortProfile,
}

export function useSmsTaskPreviewService() {
  const nekstApi = useBackendApi();
  const getSmsTaskPreview = async (id: number) => {
    return await nekstApi.get(`/tasks/${id}/smspreview`) as SmsTaskPreview[];
  };

  return {
    getSmsTaskPreview,
  };
}
