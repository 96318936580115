import React, { useContext } from 'react';

type FormFieldsData = Record<string, any>;

type OnFormDataChangedCallbackType<DataType>
  = (_values: Partial<DataType> | Record<string, any>, _isFieldInitializing?: boolean) => void;

export interface FormContextData<DataType = FormFieldsData> {
  data: DataType | null;
  onChangeCallback: OnFormDataChangedCallbackType<DataType>;
  setFormData: (_data: DataType) => void;
  submitForm: (callAfterSubmitFunc?: boolean) => Promise<boolean>;
  clearForm: () => Promise<void>;
  getFieldErrors: (_source: string) => string[];
  isDisabled?: () => boolean;
  isSubmitDisabled?: () => boolean;
  getConstraintValue: (fieldName: string, constraintName: string) => any,
  isFieldRequired: (fieldName: string) => boolean;
  setDisabled: (value: boolean) => void;
  setSubmitDisabled: (value: boolean) => void;
  loading: boolean,
  __setErrors: (errors: Record<string, string[]>) => void;
}

const FormContext = React.createContext<any>({});

export function useFormContext<DataType = FormFieldsData>(): Partial<FormContextData<DataType>> {
  return useContext<Partial<FormContextData<DataType>>>(FormContext);
}

export function useFormContextRequired<DataType = FormFieldsData>(): FormContextData<DataType> {
  const result = useContext<FormContextData<DataType>>(FormContext);

  if (!result) {
    throw new Error('Element must be used within FormContext');
  }

  return result;
}

export type { FormFieldsData, OnFormDataChangedCallbackType };

export { FormContext };
