import React, { useEffect, useState } from 'react';
import {
  Dropdown,
  FormTemplate,
  Header4,
  Row6x6x,
  SimpleBlock,
  useFormContextRequired,
  useListContextRequired
} from 'ui-builder';
import {
  ProjectDatesFieldsConfiguration,
  ProjectDetailsField,
  ProjectDetailsFieldType,
  ProjectType,
  useProjectDatesFieldsService
} from 'nekst-api';
import { get } from 'lodash';

import styles from './SetProjectDatesFieldsForm.module.scss';
import { ToggledFeature, withToggleWrapper } from 'feature-toggle';

function ChooseDateDropdown(
  props: {
    source: string,
    label: string,
    allFields: ProjectDetailsField[],
    exceptFieldSource: string,
  }
) {

  const formContext = useFormContextRequired<ProjectDatesFieldsConfiguration>();

  const data = formContext.data;

  const exceptFieldId = get(data, props.exceptFieldSource);

  return (
    <Dropdown
      source={props.source}
      label={props.label}
      options={props.allFields.filter((item) => item.id !== exceptFieldId)
        .map((item) => ({
          value: item.id,
          label: item.name,
        }))}
    />
  );
}

function ProjectDataUpdater(
  props: {
    projectType: ProjectType,
  }
) {
  const formContext = useFormContextRequired<ProjectDatesFieldsConfiguration>();

  const listContext = useListContextRequired<ProjectDetailsField>();

  useEffect(() => {
    formContext.setFormData({
      startDateFieldId: listContext.data.find((item) => item.isProjectStartDate)?.id,
      endDateFieldId: listContext.data.find((item) => item.isProjectEndDate)?.id,
    });
  }, [props.projectType, listContext.version]);

  return null;
}

function SetProjectDatesFieldsForm(
  props: {
    projectType: ProjectType,
  }
) {

  const listContext = useListContextRequired<ProjectDetailsField>();

  const projectDatesFieldsService = useProjectDatesFieldsService();

  const [initialData, setInitialData] = useState<ProjectDatesFieldsConfiguration>();

  const [dateFields, setDateFields] = useState<ProjectDetailsField[]>();

  useEffect(() => {
    if (listContext.data.length && !listContext.isLoading) {
      setDateFields(listContext
        .data
        .filter((item) => !item.isHidden)
        .filter((item) => item.fieldType === ProjectDetailsFieldType.DATE)
      );

      if (!initialData) {
        setInitialData({
          startDateFieldId: listContext.data.find((item) => item.isProjectStartDate)?.id,
          endDateFieldId: listContext.data.find((item) => item.isProjectEndDate)?.id,
        });
      }
    }
  }, [listContext.version]);

  if (dateFields) {
    return (
      <SimpleBlock className={styles.block}>
        <Header4>For Creating Transactions Using Contracts:</Header4>
        <FormTemplate<ProjectDatesFieldsConfiguration>
          submitFormFunc={async (data) => {
            await projectDatesFieldsService.updateProjectDatesFields(props.projectType, data);
            return data;
          }}
          buttons={null}
          submitOnEachChange
        >
          <Row6x6x>
            <ChooseDateDropdown
              source="startDateFieldId"
              label="Start Date"
              allFields={dateFields}
              exceptFieldSource="endDateFieldId"
            />
            <ChooseDateDropdown
              source="endDateFieldId"
              label="End Date"
              allFields={dateFields}
              exceptFieldSource="startDateFieldId"
            />
            <ProjectDataUpdater projectType={props.projectType} />
          </Row6x6x>
        </FormTemplate>
      </SimpleBlock>
    );
  } else {
    return null;
  }
}

export default withToggleWrapper(SetProjectDatesFieldsForm, ToggledFeature.AILAUNCHER);
