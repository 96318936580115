import React from 'react';
import RecordLayout, { RecordLayoutProps, useRecordLayoutContext } from './RecordLayout';
import PersonLink from './PersonLink';
import TaskLink from './TaskLink';
import { TaskType } from '../../../shared/commonTypes';
import { HistoryEventIconType } from './HistoryEventIcon';
import { BaseHistoryEntryData, TaskData } from 'nekst-api';

interface TaskCompletedRecordData extends BaseHistoryEntryData {
  task: TaskData;
}

export default function TaskCompletedRecordLayout(
  props: RecordLayoutProps<TaskCompletedRecordData>,
) {
  const { isInTaskContext } = useRecordLayoutContext();

  let text;

  if (props.record.data.task.type === TaskType.EMAIL) {
    text = ' completed the email task ';
  } else {
    text = ' completed the task ';
  }
  return (
    <RecordLayout
      text={(
        <>
          <PersonLink person={props.record.data.person!} />
          {isInTaskContext && ' completed the task.'}
          {!isInTaskContext && (
            <>
              {text}
              <TaskLink
                task={props.record.data.task}
                project={props.record.data.project}
              />
              .
            </>
          )}

        </>
      )}
      date={props.record.when}
      iconType={HistoryEventIconType.TASK_COMPLETED}
    />
  );
}
