import React from 'react';

import styles from './ProjectNotesList.module.scss';
import { ProjectNote } from 'nekst-api';
import { useShowContext } from 'ui-builder';

export default function IsAddedToClientPortalField() {
  const context = useShowContext<ProjectNote>();

  const data = context.data!;

  if (data.isAddedToClientPortal) {
    return (
      <div className={styles.clientPortalField}>
        Client Portal
      </div>
    );
  } else {
    return null;
  }
}
