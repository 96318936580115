import React, { ReactElement, useMemo, useState } from 'react';
import styles from '../../projects/view/assignments/ProjectMembersList.module.scss';
import ListReactContext, { useListContext } from '../uibuilder/list/ListReactContext';

interface ViewAllWrapperProps {
  numberOfItems: number;
  children: ReactElement | ReactElement[];
  buttonText?: string
}

export interface ViewAllProps {
  showAll: boolean,
  setShowAll: (value: boolean) => void
  text?: string
}

function ViewAllButton(props: ViewAllProps) {
  const onClick = () => {
    props.setShowAll(!props.showAll);
  };

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  if (!props.showAll) {
    return (
      <span onClick={onClick} className={styles.viewAll}>{props.text || 'View All'}</span>
    );
  } else {
    return (
      <span onClick={onClick} className={styles.viewAll}>Collapse</span>
    );
  }
}

export default function ViewAllWrapper(props: ViewAllWrapperProps) {
  const listContext = useListContext();

  const [showAll, setShowAll] = useState<boolean>(false);

  const newContextValue = useMemo(() => {
    let data;

    if (!showAll) {
      data = listContext.data!.slice(0, props.numberOfItems);
    } else {
      data = listContext.data!;
    }

    return {
      ...listContext,
      data,
    };
  }, [showAll, JSON.stringify(listContext.data)]);

  return (
    <ListReactContext.Provider value={newContextValue}>
      {props.children}
      {listContext.data!.length > props.numberOfItems && (
        <ViewAllButton showAll={showAll} setShowAll={setShowAll} text={props.buttonText} />
      )}
    </ListReactContext.Provider>
  );
}
