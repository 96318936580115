import React, { ReactElement, ReactNode } from 'react';
import { useFormContext } from './FormContext';

export interface ChildrenProvider {
  getVisibleChildren: () => ReactElement[]
}

export function useChildrenProvider(
  children: ListOrSingle<ReactNode>,
): ChildrenProvider {

  const { data } = useFormContext();

  const isVisible = (element: React.ReactElement) => {
    const value = element.props.isVisible;
    if (value !== undefined) {
      if (typeof value === 'function') {
        return value(data);
      } else {
        return !!element.props.isVisible;
      }
    } else {
      return true;
    }
  };

  const getVisibleChildren = () : React.ReactElement[] => {
    return React.Children.toArray(children)
      .filter((child: any) => {
        return child && React.isValidElement(child) && isVisible(child);
      }) as React.ReactElement[];
  };

  return {
    getVisibleChildren,
  };
}

